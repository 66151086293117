import { Icon } from "@iconify/react";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useParams } from "react-router";

import { Select } from "../../components/shared/Select";

import { HStack, VStack, Error, Box } from "../../components/utils";
import { getCurrencyType } from "../../utils/currencyFormatter";
import { Input, Label } from "../../components/shared/InputField";
import {
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../quickRound/CTAButtonComponents";

import { ShowNumberInWords } from "../../components/shared/UiElement";
import {
  useAddEsopDetails,
  useUpdateEsopDetails,
} from "../../queries/shareholders";
import { EsopPlanDetailsModel } from "./EsopPlanDetailsAgTable";

type EsopPlanDetailsProps = {
  onPrimaryAction: () => void;
  onSecondaryAction: () => void;
  data: any;
  mode: string;
};

export default function EsopPlanDetailsPopUp({
  onPrimaryAction,
  onSecondaryAction,
  data,
  mode,
}: EsopPlanDetailsProps) {
  const currencyType = getCurrencyType();
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [onHoverReservedPool, setOnHoverReservedPool] = useState(false);
  const [onHoverAllocatedPool, setOnHoverAllocatedPool] = useState(false);
  const [onHoverUnAllocatedPool, setOnHoverUnAllocatedPool] = useState(false);

  const { mutate: addEsopDetail } = useAddEsopDetails();
  const { mutate: updateEsopDetail } = useUpdateEsopDetails();

  const esopInitialValues: EsopPlanDetailsModel = {
    planName: data?.planName || "",
    planType: data?.planType || "",
    reservedPool: data?.reservedPool || 0,
    allocatedPool: data?.allocatedPool || 0,
    unallocatedPool: data?.unallocatedPool || 0,
  };

  const esopValidationSchema = Yup.object({
    planName: Yup.string().required().label("Plan Name"),
    planType: Yup.string().required().label("Plan Type"),
    reservedPool: Yup.number().when(["allocatedPool", "unallocatedPool"], {
      is: (allocatedPool: number, unallocatedPool: number) =>
        allocatedPool + unallocatedPool > 0,
      then: Yup.number()
        .min(0, "Reserved Pool must be greater than or equal to 0")
        .required("Reserved Pool is required")
        .label("Reserved Pool")
        .test(
          "reserved-pool-validation",
          "Reserved Pool must equal the sum of Allocated Pool and Unallocated Pool",
          function validateReservedPool(reservedPool) {
            const { allocatedPool, unallocatedPool } = this.parent;
            return reservedPool === allocatedPool + unallocatedPool;
          }
        ),
      otherwise: Yup.number().notRequired(),
    }),

    allocatedPool: Yup.number().min(
      0,
      "Allocated Pool must be greater than or equal to 0"
    ),
    unallocatedPool: Yup.number()
      .min(0, "Reserved Pool must be greater than or equal to 0")
      .required()
      .label("Unallocated Pool"),
  });

  const handleSubmit = async (values: EsopPlanDetailsModel) => {
    if (mode === "Add") {
      addEsopDetail(
        { planDetails: values, cInvestorId: id || "" },
        {
          onSuccess: (data) => {
            toast("ESOP Detail Added Successfully", {
              type: "success",
              autoClose: 2000,
            });
            onPrimaryAction();
          },
          onError: (err: any) => {
            toast(err.response.data.errorMessage, {
              type: "error",
              autoClose: 2000,
            });
          },
        }
      );
    } else if (mode === "Update") {
      const updatedData = { ...values, id: data.id };
      updateEsopDetail(updatedData, {
        onSuccess: (data) => {
          toast("ESOP Detail Updated Successfully", {
            type: "success",
            autoClose: 2000,
          });
          onPrimaryAction();
        },
        onError: (err: any) => {
          toast(err.response.data.errorMessage, {
            type: "error",
            autoClose: 2000,
          });
        },
      });
    }
  };

  const formik = useFormik({
    initialValues: esopInitialValues,
    enableReinitialize: mode.toLowerCase() === "edit",
    onSubmit: (values: EsopPlanDetailsModel) => {
      handleSubmit(values);
    },
    validationSchema: esopValidationSchema,
  });

  return (
    <VStack className="w-full py-3 gap-9">
      <HStack className="justify-between px-5 border-b">
        <p className="pb-3 text-base font-medium">{mode} ESOP Plan Details</p>
        <XMarkIcon className="w-6 h-6" onClick={() => onSecondaryAction()} />
      </HStack>

      <form onSubmit={formik.handleSubmit} className="form">
        <VStack className="w-full gap-6 px-2 ">
          <VStack className="gap-8">
            <HStack className="flex-col gap-8 px-4 md:flex-row">
              <div className="flex-1 w-72">
                <Label className="text-sm font-normal">Plan Name</Label>
                <Input
                  type="text"
                  placeholder="Enter Plan Name"
                  className={`lg:w-72 sm:w-44 `}
                  {...formik.getFieldProps("planName")}
                />
                {formik.touched.planName && formik.errors.planName && (
                  <Error text={formik.errors.planName as string} />
                )}
              </div>
              <div className="flex-1 w-72">
                <Label className="text-sm font-normal">Plan Type</Label>
                <Select
                  options={["ESOP", "SAR", "RSU"]}
                  {...formik.getFieldProps("planType")}
                />
                {formik.touched?.planType && formik.errors?.planType && (
                  <Error text={formik.errors?.planType} />
                )}
              </div>
            </HStack>
            <HStack className="flex-col gap-8 px-4 md:flex-row">
              <div className="flex-1 w-72">
                <HStack className="justify-between">
                  <Label className="text-sm font-normal">Reserved Pool</Label>
                  <div className="relative group">
                    <svg
                      className="w-4 h-4 text-orange-500"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                    <div className="absolute hidden px-2 py-1 mb-2 text-xs text-white transform -translate-x-1/2 bg-gray-700 rounded w-44 bottom-full left-1/2 group-hover:block">
                      Pool to be reserved for this plan
                    </div>
                  </div>
                </HStack>
                <Input
                  type="text"
                  onMouseEnter={() => {
                    setOnHoverReservedPool(true);
                  }}
                  onMouseLeave={() => {
                    setOnHoverReservedPool(false);
                  }}
                  onChange={(e: any) => {
                    const values = parseInt(
                      e.target.value?.replaceAll(",", "") || 0,
                      10
                    );
                    formik.setFieldValue("reservedPool", values);
                    formik.handleChange("reservedPool");
                  }}
                  value={Intl.NumberFormat(currencyType).format(
                    formik.values.reservedPool
                  )}
                />
                {onHoverReservedPool && (
                  <ShowNumberInWords
                    value={formik.values.reservedPool}
                    width={38}
                    currency={currencyType}
                  />
                )}

                {formik.touched.reservedPool && formik.errors?.reservedPool && (
                  <Error text={formik.errors?.reservedPool as string} />
                )}
              </div>
              <div className="flex-1 w-72">
                <Label className="text-sm font-normal">Allocated Pool</Label>
                <Input
                  type="text"
                  onMouseEnter={() => {
                    setOnHoverAllocatedPool(true);
                  }}
                  onMouseLeave={() => {
                    setOnHoverAllocatedPool(false);
                  }}
                  onChange={(e: any) => {
                    const values = parseInt(
                      e.target.value?.replaceAll(",", "") || 0,
                      10
                    );
                    formik.setFieldValue("allocatedPool", values);
                    formik.handleChange("allocatedPool");
                  }}
                  value={Intl.NumberFormat(currencyType).format(
                    formik.values.allocatedPool
                  )}
                />
                {onHoverAllocatedPool && (
                  <ShowNumberInWords
                    value={formik.values.allocatedPool}
                    width={38}
                    currency={currencyType}
                  />
                )}

                {formik.touched.reservedPool && formik.errors?.reservedPool && (
                  <Error text={formik.errors?.reservedPool as string} />
                )}
              </div>
            </HStack>
            <HStack className="flex-col gap-8 px-4 md:flex-row">
              <div className=" w-72">
                <Label className="text-sm font-normal">Unallocated Pool</Label>
                <Input
                  type="text"
                  onMouseEnter={() => {
                    setOnHoverUnAllocatedPool(true);
                  }}
                  onMouseLeave={() => {
                    setOnHoverUnAllocatedPool(false);
                  }}
                  onChange={(e: any) => {
                    const values = parseInt(
                      e.target.value?.replaceAll(",", "") || 0,
                      10
                    );
                    formik.setFieldValue("unallocatedPool", values);
                    formik.handleChange("unallocatedPool");
                  }}
                  value={Intl.NumberFormat(currencyType).format(
                    formik.values.unallocatedPool
                  )}
                />
                {onHoverUnAllocatedPool && (
                  <ShowNumberInWords
                    value={formik.values.unallocatedPool}
                    width={38}
                    currency={currencyType}
                  />
                )}

                {formik.touched.unallocatedPool &&
                  formik.errors?.unallocatedPool && (
                    <Error text={formik.errors?.unallocatedPool as string} />
                  )}
              </div>
            </HStack>
          </VStack>

          <HStack className="justify-between px-4 mt-10">
            <SecondaryCTAButton
              event-name="Cancel Bonus Modal"
              onClick={() => onSecondaryAction()}
              type="reset"
              className="text-red-500"
            >
              Cancel
            </SecondaryCTAButton>
            <PrimaryCTAButton event-name="Save Bonus Modal" type="submit">
              {mode}
            </PrimaryCTAButton>
          </HStack>
        </VStack>
      </form>
    </VStack>
  );
}
