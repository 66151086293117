import { useMutation, useQuery } from "react-query";
import { getOnFilterState, postOnFilterState } from "./AgGridCache";

export function useGetOnFilterState(userId: string, companyId: string) {
  return useQuery({
    queryKey: ["getFilterState", userId, companyId],
    queryFn: getOnFilterState,
    // select: (data) => data.data,
    enabled: false,
  });
}

export function usePostOnFilterState() {
  return useMutation({
    mutationKey: "postFilterState",
    mutationFn: postOnFilterState,
    onSuccess: () => {},
    onError: () => {},
  });
}
