import { useMutation } from "react-query";
import {
  captableSignIn,
  captableSignUp,
  forgotPassword,
} from "../api/captableLogin";
import { newCapTableResetApi } from "../api/resetCaptableLoginApi";
import { ResetPostPasswordDetailModel } from "../types/CaptableLoginModel";
import { handleEventForTracking } from "../amplitudeAnalytics";

export async function resetPassword(
  resetPasswordDetail: ResetPostPasswordDetailModel
): Promise<{ message: string; data: any }> {
  return newCapTableResetApi.post(
    `v1/auth/resetPassword`,
    resetPasswordDetail,
    {
      headers: { authorization: resetPasswordDetail.jwt },
    }
  );
}

export function useForgotPassword() {
  return useMutation({
    mutationKey: "forgotPassword",
    mutationFn: forgotPassword,
    onSuccess: () => {
      handleEventForTracking({
        eventName: "forgotPassword",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "forgotPassword",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function useCaptableSignIn() {
  return useMutation({
    mutationKey: "captableSignIn",
    mutationFn: captableSignIn,
    onSuccess: () => {
      handleEventForTracking({
        eventName: "captableSignIn",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "captableSignIn",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function useCaptableSignUp() {
  return useMutation({
    mutationKey: "submitSignUp",
    mutationFn: captableSignUp,
    onSuccess: () => {
      handleEventForTracking({
        eventName: "submitSignUp",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "submitSignUp",
        success: false,
        eventType: "API",
      });
    },
  });
}
