export const optionsPool = {
  rotation: 270,
  circumference: 180,
  maintainAspectRatio: false,
  responsive: true,
  cutout: 68,
  plugins: {
    tooltip: {
      displayColors: false,
    },
    legend: {
      display: false,
    },
    title: {
      display: false,
      text: "Doughnut Chart",
      color: "blue",
      font: {
        size: 24,
      },
      responsive: true,
      animation: {
        animateScale: true,
      },
    },
  },
  elements: {
    arc: {
      spacing: -7,
      borderWidth: 0,
      borderRadius: 25,
    },
  },
};

export const shareholderHoverOptions = {
  maintainAspectRatio: false,
  aspectRatio: 25,
  responsive: true,
  radius: "100%",
  cutout: "70%",
  plugins: {
    tooltip: {
      displayColors: false,
    },
    legend: {
      display: false,
    },
    title: {
      display: false,
      text: "Doughnut Chart",
      color: "blue",
      font: {
        size: 24,
      },
      responsive: true,
      animation: {
        animateScale: true,
      },
    },
  },
  elements: {
    arc: {
      spacing: 0,
      borderWidth: 0,
      borderRadius: 0,
      radius: 20, // Adjust this value to change the overall size of the doughnut
    },
  },
};

export const financeBarChartOptions = {
  plugins: {
    legend: {
      display: true,
    },
    tooltip: {
      callbacks: {
        label: (context: any) => {
          const labels: any[] = [];
          const formattedValue = context.raw.toLocaleString();
          labels.push(`${context.dataset?.label} : ${formattedValue}`);
          return labels;
        },
      },
    },
  },

  barThickness: 12,
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      grid: {
        display: true,
      },
      ticks: {
        font: { size: 12 },
        callback(value: any, index: any, ticks: any) {
          return `${value}`;
        },
      },
    },
  },
  responsive: true,
  borderSkipped: "bottom",
  maintainAspectRatio: true,
  borderRadius: 2,
};

export const financeLineChartoptions = {
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      grid: {
        display: true,
        lineWidth: (context: { tick: { value: number } }) =>
          context.tick.value === 0 ? 1 : 0,
        color: "grey",
      },
      ticks: {
        font: { size: 12 },
        callback(value: any, index: any, ticks: any) {
          return `${value}`;
        },
      },
    },
  },
  plugins: {
    legend: {
      display: true,
    },
    title: {
      display: false,
      text: "Revenue vs Expenses YoY",
    },
    tooltip: {
      callbacks: {
        label: (context: any) => {
          const labels: any[] = [];
          const formattedValue = context.raw.toLocaleString();
          labels.push(`${context.dataset?.label} : ${formattedValue}`);
          return labels;
        },
      },
    },
  },
  responsive: true,
  borderSkipped: "bottom",
  maintainAspectRatio: true,
};
