import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { useParams } from "react-router";
import { Dialog } from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { Icon } from "@iconify/react";
import { getNames } from "country-list";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { HStack, VStack, Error } from "../utils";
import WarningModal from "../../pages/modeling/WarningModel";
import { SwitchButton } from "./SwitchButton";
import { Input, Label, TextArea } from "./InputField";
import { Select } from "./Select";
import { ShareholderProfile } from "../../types/Shareholder";
import {
  useAddNewShareHolder,
  useEditShareholder,
  useGetShareholderGroups,
} from "../../queries/shareholders";
import {
  ShareholderSubTypes,
  shareholderTypeLabelValue,
} from "../../constants/ShareholderConstants";
import { useAuthStore } from "../../store";
import { queryClient } from "../../queries";
import {
  ButtonSize,
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../../pages/quickRound/CTAButtonComponents";
import { useGetListOfNewInvestorModels } from "../../queries/captable";

type AddShareholderProfileProps = {
  initialValue?: ShareholderProfile;
  mode: "Add" | "Edit" | "View";
};

const AddShareholderProfile = ({
  initialValue,
  mode,
}: AddShareholderProfileProps) => {
  const companyId = useAuthStore.getState().companyId || "";

  const viewOnly = mode === "View";

  const { mutate: addNewShareHolder } = useAddNewShareHolder();
  const { mutate: editShareholder } = useEditShareholder();

  const [dialog, setDialog] = useState<{
    open: boolean;
    message?: string;
    data?: any;
    index?: number;
    mode?: "Warning";
    action?: "Ok" | "Cancel";
  }>({
    open: false,
  });

  const { id } = useParams();

  const { data: listOfNewInvestorModel } = useGetListOfNewInvestorModels(
    id || ""
  );

  const [action, setAction] = useState<ShareholderProfile>();
  const [clicked, setClicked] = useState<boolean>(false);
  const [shareholderType, setShareholderType] = useState<string>(
    initialValue?.type || ""
  );

  const [shareholderName, setShareholderName] = useState(
    initialValue?.name || ""
  );

  const warningPopUp = () => {
    setDialog({
      open: true,
      mode: "Warning",
      action: "Cancel",
    });
  };

  useEffect(() => {
    if (dialog.action === "Ok") {
      setClicked(true);
    } else {
      setClicked(false);
    }
  }, [dialog.action]);

  const intialValues: ShareholderProfile = {
    id: initialValue?.id || "",
    name: initialValue?.name || "",
    address: initialValue?.address || "",
    residence: initialValue?.residence || "",
    nationality: initialValue?.nationality || "",
    depositoryName: initialValue?.depositoryName || "",
    isPromoter: initialValue?.isPromoter || false,
    type: initialValue?.type || "",
    groupName: initialValue?.groupName || "",
    email: initialValue?.email || "",
    contactNumber: initialValue?.contactNumber || "",
    dpId: initialValue?.dpId || "",
    clientId: initialValue?.clientId || "",
    uniqueId: initialValue?.uniqueId || "",
    groupDetails: initialValue?.groupDetails || [],
    investmentVehicleType: initialValue?.investmentVehicleType || "",
    isRelativeOfDirector: initialValue?.isRelativeOfDirector || false,
  };

  const handleSubmit = (values: ShareholderProfile) => {
    values.companyId = companyId;
    if (selectedGroupName) values.groupDetails = [{ name: selectedGroupName }];
    if (mode === "Add") {
      addNewShareHolder(values, {
        onSuccess: () => {
          queryClient.invalidateQueries("getShareholderOverview");
          toast("Added Shareholder successfully!", {
            type: "success",
            autoClose: 2000,
          });
        },
        onError: () => {
          toast("Something went wrong", { type: "error", autoClose: 2000 });
        },
      });
    } else {
      editShareholder(values, {
        onSuccess: () => {
          toast("Edited Shareholder successfully!", {
            type: "success",
            autoClose: 2000,
          });
        },
        onError: () => {
          toast("Something went wrong", { type: "error", autoClose: 2000 });
        },
      });
    }
  };

  const shareholderProfileSchema = Yup.object({
    id: Yup.string().label("Shareholder Profile ID"),
    name: Yup.string().required().label("Legal Name"),
    registeredAddress: Yup.string().label("Registered Address"),
    residence: Yup.string().required().label("Country Of Residence"),
    nationality: Yup.string().required().label("Nationality"),
    depositoryName: Yup.string().label("Depository Name"),
    isPromoter: Yup.boolean().label("Is Promoter"),
    type: Yup.string().required().label("Type of shareholder"),
    groupName: Yup.string().when(["type"], {
      is: (typeOfShareholder: string) =>
        ["AngelNetwork", "VC", "PE", "FinancialInstitution"].includes(
          typeOfShareholder
        ),
      then: Yup.string().label("Group Name"),
    }),
    email: Yup.string().email().label("Email"),
    contactNumber: Yup.string().label("Contact Number"),
    dpId: Yup.string().label("DP ID"),
    clientId: Yup.string().label("Client ID"),
    uniqueId: Yup.string().label("PAN"),
    investmentVehicleType: Yup.string().label("Shareholder Sub Type"),
    isRelativeOfDirector: Yup.boolean().label("Is Relative Of Director"),
  });

  const [selectedGroupName, setSelectedGroupName] = useState(
    initialValue?.groupName || ""
  );

  const { data: shareholderGroups } = useGetShareholderGroups();

  return (
    <div className="bg-white">
      <VStack className="w-full px-10 py-7 gap-9">
        <Dialog open={dialog.open} maxWidth="md">
          {dialog.mode === "Warning" && (
            <WarningModal
              onPrimaryAction={() => {
                setDialog({ open: false, action: "Ok" });
                editShareholder(action!, {
                  onSuccess: () => {
                    toast("Edited Shareholder successfully!", {
                      type: "success",
                      autoClose: 2000,
                    });
                  },
                  onError: () => {
                    toast("Something went wrong", {
                      type: "error",
                      autoClose: 2000,
                    });
                  },
                });
              }}
              onSecondaryAction={() =>
                setDialog({ open: false, action: "Cancel" })
              }
              data={`${shareholderName} is new investor in round models: ${listOfNewInvestorModel
                ?.map((model: any) => model.modelName)
                .join(", ")}.
Changes for type of shareholder made here will not reflect in the models.
Please visit the model(s) and update the type of shareholder.`}
              title={"Warning"}
              buttonTitle={"Ok"}
            />
          )}
        </Dialog>
        <Formik
          initialValues={intialValues}
          key="addShareholderProfile"
          enableReinitialize={true}
          onSubmit={(values) => {
            if (
              (listOfNewInvestorModel?.length || 0) > 0 &&
              shareholderType !== initialValue?.type
            ) {
              warningPopUp();
              setAction(values);
            } else {
              handleSubmit(values);
            }
          }}
          validationSchema={shareholderProfileSchema}
        >
          {(formik) => (
            <>
              <Form key="addShareholderProfile">
                <VStack className="w-full gap-4">
                  <HStack className="gap-8">
                    <div className="flex-1">
                      <Label className="text-sm font-normal">
                        Type of shareholder
                      </Label>
                      <Select
                        {...formik.getFieldProps("type")}
                        placeholder="Enter the type of shareholder"
                        className="lg:w-72 sm:w-44"
                        options={shareholderTypeLabelValue}
                        valueGetter={(o) => o.label}
                        textGetter={(o) => o.value}
                        disabled={viewOnly}
                        onChange={(e) => {
                          formik.setFieldValue("type", e.target.value);
                          setShareholderType(e.target.value);
                        }}
                      />
                      {formik.touched.type && formik.errors.type && (
                        <Error text={formik.errors.type} />
                      )}
                    </div>
                    <div className="flex-1">
                      {formik.values.type.toLowerCase() ===
                      "financialinstitution" ? (
                        <div>
                          <Label className="text-sm font-normal">
                            Shareholder Sub Type
                          </Label>
                          <Select
                            {...formik.getFieldProps("investmentVehicleType")}
                            placeholder="Enter Shareholder Sub Type"
                            className="lg:w-72 sm:w-44"
                            options={ShareholderSubTypes}
                            valueGetter={(o) => o.value}
                            textGetter={(o) => o.text}
                            disabled={viewOnly}
                          />
                          {formik.touched.investmentVehicleType &&
                            formik.errors.investmentVehicleType && (
                              <Error
                                text={formik.errors.investmentVehicleType}
                              />
                            )}
                        </div>
                      ) : ["Founder", "Angel"].includes(formik.values.type) ? (
                        <div>
                          <Label className="text-sm font-normal">
                            Is Relative Of Director
                          </Label>
                          <SwitchButton
                            className={`text-xs font-normal items-center m-1 ${
                              viewOnly ? "cursor-not-allowed" : ""
                            }`}
                            value={formik.values.isRelativeOfDirector}
                            label=""
                            onClick={() => {
                              if (!viewOnly) {
                                formik.setFieldValue(
                                  "isRelativeOfDirector",
                                  !formik.values.isRelativeOfDirector
                                );
                              }
                            }}
                          />
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </div>
                  </HStack>
                  <HStack className="gap-10">
                    <div className="flex-1 ">
                      <Label className="text-sm font-normal">Group Name</Label>
                      <Autocomplete
                        id="autocomplete-search"
                        className={`${viewOnly ? "cursor-not-allowed" : ""}`}
                        popupIcon={
                          <Icon
                            icon="akar-icons:chevron-down"
                            className="p-1 text-slate-600"
                          />
                        }
                        options={
                          shareholderGroups?.map((group) => group.name) || []
                        }
                        onChange={(e, value) => {
                          if (value) setSelectedGroupName(value);
                        }}
                        value={selectedGroupName}
                        getOptionLabel={(option) => option}
                        sx={{
                          "& .MuiInput-input": {
                            padding: "4px 4px 4px 14px !important",
                            fontFamily: "Inter",
                            color: "rgb(102, 107, 128, 1)",
                          },
                          "& .MuiSvgIcon-root": {
                            fill: "none",
                          },
                        }}
                        renderInput={(params) => (
                          <div className="lg:w-72 sm:w-44">
                            <TextField
                              hiddenLabel
                              type="text"
                              className={`border-0 form-input px-20  outline-none border-none rounded-lg form-input items-center`}
                              placeholder={"Enter Group Name"}
                              variant="standard"
                              {...params}
                              InputProps={{
                                disableUnderline: true,
                                ...params.InputProps,
                              }}
                              value={selectedGroupName}
                              onChange={(e) =>
                                setSelectedGroupName(e.target.value)
                              }
                            />
                          </div>
                        )}
                        disabled={viewOnly}
                      />
                      {formik.touched.groupName && formik.errors.groupName && (
                        <Error text={formik.errors.groupName} />
                      )}
                    </div>
                    <div className="flex-1">
                      <Label className="text-sm font-normal">Legal Name</Label>
                      <Input
                        type="text"
                        placeholder="Enter Legal Name"
                        className={`lg:w-72 sm:w-44 ${
                          viewOnly ? "cursor-not-allowed" : ""
                        }`}
                        {...formik.getFieldProps("name")}
                        disabled={viewOnly}
                        onChange={(e) => {
                          formik.setFieldValue("name", e.target.value);
                          setShareholderName(e.target.value);
                        }}
                      />
                      {formik.touched.name && formik.errors.name && (
                        <Error text={formik.errors.name} />
                      )}
                    </div>
                  </HStack>
                  <HStack className="gap-8">
                    <div className="flex-1">
                      <Label className="text-sm font-normal">
                        Registered Address
                      </Label>
                      <TextArea
                        placeholder="Enter Registered Address"
                        className={`lg:w-72 sm:w-44 ${
                          viewOnly ? "cursor-not-allowed" : ""
                        }`}
                        rows={3}
                        {...formik.getFieldProps("address")}
                        disabled={viewOnly}
                      />
                      {formik.touched.address && formik.errors.address && (
                        <Error text={formik.errors.address} />
                      )}
                    </div>
                    <div className="flex-1">
                      <Label className="text-sm font-normal">
                        Contact Number
                      </Label>
                      <Input
                        type="contact"
                        className={`lg:w-72 sm:w-44 ${
                          viewOnly ? "cursor-not-allowed" : ""
                        }`}
                        placeholder="Enter Contact Number"
                        {...formik.getFieldProps("contactNumber")}
                        disabled={viewOnly}
                      />
                      {formik.touched.contactNumber &&
                        formik.errors.contactNumber && (
                          <Error text={formik.errors.contactNumber} />
                        )}
                    </div>
                  </HStack>
                  <HStack className="gap-8">
                    <div className="flex-1">
                      <Label className="text-sm font-normal">PAN</Label>
                      <Input
                        type="text"
                        className={`lg:w-72 sm:w-44 ${
                          viewOnly ? "cursor-not-allowed" : ""
                        }`}
                        placeholder="Enter PAN"
                        {...formik.getFieldProps("uniqueId")}
                        disabled={viewOnly}
                      />
                      {formik.touched.uniqueId && formik.errors.uniqueId && (
                        <Error text={formik.errors.uniqueId} />
                      )}
                    </div>
                    <div className="flex-1">
                      <Label className="text-sm font-normal">Email</Label>
                      <Input
                        type="email"
                        className={`lg:w-72 sm:w-44 ${
                          viewOnly ? "cursor-not-allowed" : ""
                        }`}
                        placeholder="Enter Email"
                        {...formik.getFieldProps("email")}
                        disabled={viewOnly}
                      />
                      {formik.touched.email && formik.errors.email && (
                        <Error text={formik.errors.email} />
                      )}
                    </div>
                  </HStack>
                  <HStack className="gap-8">
                    <div className="flex-1">
                      <Label className="text-sm font-normal">
                        Country Of Residence
                      </Label>
                      <Select
                        options={getNames().sort()}
                        placeholder="Enter Country Of Residence"
                        className={`lg:w-72 sm:w-44 ${
                          viewOnly ? "cursor-not-allowed" : ""
                        }`}
                        {...formik.getFieldProps("residence")}
                        disabled={viewOnly}
                      />
                      {formik.touched.residence && formik.errors.residence && (
                        <Error text={formik.errors.residence} />
                      )}
                    </div>
                    <div className="flex-1">
                      <Label className="text-sm font-normal">Nationality</Label>
                      <Select
                        options={getNames().sort()}
                        placeholder="Enter Nationality"
                        className={`lg:w-72 sm:w-44 ${
                          viewOnly ? "cursor-not-allowed" : ""
                        }`}
                        {...formik.getFieldProps("nationality")}
                        disabled={viewOnly}
                      />
                      {formik.touched.nationality &&
                        formik.errors.nationality && (
                          <Error text={formik.errors.nationality} />
                        )}
                    </div>
                  </HStack>
                  <HStack className="gap-8 ">
                    <div className="flex-1">
                      <Label className="text-sm font-normal">
                        Depository Name
                      </Label>
                      <Input
                        type="text"
                        placeholder="Enter Depository Name"
                        className={`lg:w-72 sm:w-44 ${
                          viewOnly ? "cursor-not-allowed" : ""
                        }`}
                        {...formik.getFieldProps("depositoryName")}
                        disabled={viewOnly}
                      />
                      {formik.touched.depositoryName &&
                        formik.errors.depositoryName && (
                          <Error text={formik.errors.depositoryName} />
                        )}
                    </div>
                    <div className="flex-1">
                      <Label className="text-sm font-normal">Is Promoter</Label>
                      <SwitchButton
                        className={`text-xs font-normal items-center m-1 ${
                          viewOnly ? "cursor-not-allowed" : ""
                        }`}
                        value={formik.values.isPromoter}
                        label=""
                        onClick={() => {
                          if (!viewOnly) {
                            formik.setFieldValue(
                              "isPromoter",
                              !formik.values.isPromoter
                            );
                          }
                        }}
                      />
                      {formik.touched.isPromoter &&
                        formik.errors.isPromoter && (
                          <Error text={formik.errors.isPromoter} />
                        )}
                    </div>
                  </HStack>
                  <HStack className="gap-8 ">
                    <div className="flex-1">
                      <Label className="text-sm font-normal">DP ID</Label>
                      <Input
                        type="text"
                        className={`lg:w-72 sm:w-44 ${
                          viewOnly ? "cursor-not-allowed" : ""
                        }`}
                        placeholder="Enter DP ID"
                        {...formik.getFieldProps("dpId")}
                        disabled={viewOnly}
                      />
                      {formik.touched.dpId && formik.errors.dpId && (
                        <Error text={formik.errors.dpId} />
                      )}
                    </div>
                    <div className="flex-1">
                      <Label className="text-sm font-normal">Client ID</Label>
                      <Input
                        type="text"
                        className={`lg:w-72 sm:w-44 ${
                          viewOnly ? "cursor-not-allowed" : ""
                        }`}
                        placeholder="Enter Client ID"
                        {...formik.getFieldProps("clientId")}
                        disabled={viewOnly}
                      />
                      {formik.touched.clientId && formik.errors.clientId && (
                        <Error text={formik.errors.clientId} />
                      )}
                    </div>
                  </HStack>
                  <HStack className="justify-between h-8 mt-10">
                    <SecondaryCTAButton
                      buttonSize={ButtonSize.SMALL}
                      type="reset"
                      className="text-red-500"
                      onClick={() => {
                        formik.resetForm({
                          values: initialValue,
                        });
                      }}
                    >
                      Reset
                    </SecondaryCTAButton>

                    <PrimaryCTAButton
                      event-name="Update Shareholder Profile"
                      buttonSize={ButtonSize.SMALL}
                      type="submit"
                    >
                      Update
                    </PrimaryCTAButton>
                  </HStack>
                </VStack>
              </Form>
            </>
          )}
        </Formik>
      </VStack>
    </div>
  );
};

export default AddShareholderProfile;
