import React, { useEffect, useRef, useState } from "react";
import { Icon } from "@iconify/react";
import { toast } from "react-toastify";
import { sort } from "../../utils/arrays";
import { HStack } from "../../components/utils";

function SearchAutoCompleteOnInput<T>({
  getSelectedValue,
  changeShowSearch,
  data,
  selectedOption,
  field,
  groupId,
}: {
  getSelectedValue: (item: T, groupId: string) => void;
  changeShowSearch: () => void;
  data: T[];
  selectedOption?: T;
  field: keyof T;
  groupId: string;
}) {
  const sortedData = sort(data || [], field);
  const selectedText = selectedOption ? selectedOption[field] : "";
  const [searchText, setSearchText] = useState(
    selectedText as unknown as string
  );

  const [searched, setSearched] = useState<T[]>(data);

  const [open, setOpen] = useState(true);
  function searchOptions(text: string) {
    const match = sortedData.filter((d) => {
      const regex = new RegExp(`${text}`, "gi");
      return (d[field] as any).match(regex);
    });
    setSearched(match);
  }
  const contextRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const checkIfClickOutside = (e: any) => {
      if (!searchText) setSearchText(selectedText as unknown as string);
      if (!(open && contextRef.current?.contains(e.target))) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickOutside);
    };
  }, [open, searchText]);
  return (
    <div
      ref={contextRef}
      className="z-10 relative min-h-[45px] min-w-[200px] bg-white drop-shadow-2xl"
    >
      <HStack className="items-center rounded shadow-sm bg-inherit text-slate-dark">
        <input
          type="text"
          className="w-full px-2 text-xs font-medium outline-none bg-inherit form-input"
          value={searchText}
          placeholder="Select"
          onChange={(e) => {
            if (!e.target.value) setSearched(sortedData);
            setSearchText(e.target.value);
            searchOptions(e.target.value);
          }}
          onFocus={() => {
            setOpen(true);
            setSearched(sortedData);
          }}
        />
        {open ? (
          <Icon
            icon="ic:round-search"
            width="24"
            className="mr-2 "
            onClick={() => setOpen(!open)}
          />
        ) : (
          <HStack>
            <Icon
              icon="ic:round-search"
              width="24"
              className="mr-2"
              onClick={() => setOpen(!open)}
            />
          </HStack>
        )}
      </HStack>
      <div className="overflow-auto border divide-y divide-gray-100 text-stone-600 rounded shadow-sm max-h-60 ">
        {open &&
          searched?.map((o, index) => {
            const option = o as unknown as T;
            return (
              <div
                className="flex gap-2 p-2 divide-y rounded "
                key={index}
                onClick={() => {
                  getSelectedValue(option, groupId);
                  setOpen(!open);
                }}
              >
                {option[field] as unknown as string}
              </div>
            );
          })}
        {open && searched?.length === 0 && (
          <p className="text-center py-2">No Result Found</p>
        )}
      </div>
    </div>
  );
}

export default SearchAutoCompleteOnInput;
