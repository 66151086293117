import create from "zustand";
import { io } from "socket.io-client";
import { configurePersist } from "zustand-persist";
import { RoundCreationModel } from "../pages/modeling/RoundCreationModel";
import { useAuthStore } from ".";

const { persist: _persist } = configurePersist({
  storage: localStorage,
  rootKey: "root",
});

const persist = _persist as any;

type RMCreationState = {
  roundCreation: RoundCreationModel;
  setRoundCreation: (data: RoundCreationModel) => void;
  connect: () => void;
  sendMessage: (message: RoundCreationModel) => void;
  recieveMessage: (key: string) => void;
  getMessage: (key: string, callbackFn: Function) => void;
  disconnect: () => void;
  reset: (data: RoundCreationModel) => void;
};

const initialState = {
  roundCreation: {
    currentTab: 1,
    maxTab: 1,
    errors: [],
    notifications: [],
    isAntidultion: false,
    isSummaryPageViewed: false,
    hasToDiscard: false,
    baseCapTableId: "",
    companyId: "",
    id: "",
    name: "",
    tab3: { existingInvestor: [] },
    tab4: {
      newInvestor: [],
    },
    listOfShareHolders: [],
    buyersList: [],
    sellersList: [],
  },
};

const URL =
  process.env.REACT_APP_NEW_CAPTABLE_SOCKET_API || "ws://localhost:4000/";
let socket: any;

const rmCreationStore = create<RMCreationState>(
  persist(
    {
      key: "roundCreation",
    },
    (set: any) => ({
      ...initialState,
      connect: () => {
        if (
          useAuthStore.getState().accessToken &&
          useAuthStore.getState().companyId
        ) {
          socket = io(URL, {
            extraHeaders: {
              accesstoken: useAuthStore.getState().accessToken || "-",
              companyId: useAuthStore.getState().companyId || "-",
            },
          });
          socket.connect();
        }
      },
      disconnect: () => {
        set(() => initialState);
        socket.disconnect();
      },
      sendMessage: async (message: any) => {
        if (socket) {
          socket.emit("message", message);
          socket.off("message");
          socket.on("message", (data: any) => {
            set((state: any) => ({ roundCreation: JSON.parse(data) }));
          });
        }
      },
      recieveMessage: async (key: any) => {
        if (socket) {
          await socket.on(key, (data: any) => {
            set((state: any) => ({ roundCreation: JSON.parse(data) }));
          });
        }
      },
      getMessage: async (key: any, callbackFn: Function) => {
        if (socket) {
          await socket.on(key, callbackFn);
        }
      },
      reset: (message: any) => {
        if (socket) {
          const discardData = {
            ...message,
            hasToDiscard: true,
          };

          socket.emit("message", discardData);
          set(() => initialState);
        }
      },
      setRoundCreation: (data: any) => {
        set((state: any) => ({ roundCreation: data }));
      },
    })
  )
);

export const useRMCreationStore = rmCreationStore;
