import create from "zustand";
import { SecondaryTransactionDataModel } from "../types/quickRound";

type SecondaryTransactionState = {
  secondaries: SecondaryTransactionDataModel[];
  setSecondaries: (secondary: SecondaryTransactionDataModel) => void;
  setAllSecondaries: (secondaries: SecondaryTransactionDataModel[]) => void;
  removeSecondaries: (secondary: SecondaryTransactionDataModel) => void;
  reset: () => void;
};

const initialState = {
  secondaries: [],
};

const quickSecondariesStore = create<SecondaryTransactionState>((set) => ({
  ...initialState,
  setSecondaries: (secondary) => {
    set((state) => ({ secondaries: [...state.secondaries, secondary] }));
  },
  setAllSecondaries: (secondaries) => {
    set((state) => ({ secondaries }));
  },
  removeSecondaries: (secondary) => {
    set((state) => {
      const array = [...state.secondaries]; // make a separate copy of the array
      const index = array.indexOf(secondary);
      if (index !== -1) {
        array.splice(index, 1);
      }

      return { secondaries: array };
    });
  },
  reset: () => {
    set(() => initialState);
  },
}));

export const useQuickSecondariesStore = quickSecondariesStore;
