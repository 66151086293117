import _ from "lodash";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { HStack, Error, VStack } from "../components/utils";
import {
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../pages/quickRound/CTAButtonComponents";
import { Input, Label, TextArea } from "../components/shared/InputField";
import { useUpdateCertificateFdb } from "../queries/shareholders";
import { getCurrencyType } from "../utils/currencyFormatter";

type UpdateFdbShareCertificate = {
  certificateId: string;
  fdbShares: number;
  remarks: string;
  onPrimaryAction: () => void;
  onSecondaryAction: () => void;
};

export function UpdateCertificateFDB({
  certificateId,
  fdbShares,
  remarks,
  onPrimaryAction = () => {},
  onSecondaryAction = () => {},
}: UpdateFdbShareCertificate) {
  const { mutate: updateCertificateFdb } = useUpdateCertificateFdb();
  const fdbUpdateSchema = Yup.object().shape({
    fdbShares: Yup.number()
      .integer()
      .positive()
      .required()
      .label("Override FDB Shares"),
    remarks: Yup.string().label("Remarks"),
  });

  const currencyType = getCurrencyType();

  const initialValues = {
    fdbShares,
    remarks,
  };
  return (
    <div className="w-full p-2">
      <HStack className="justify-between border-b">
        <p className="font-semibold text-lg mb-3 ml-4">
          Override FDB Shares for ShareCertificate
        </p>
        <span onClick={() => onSecondaryAction()} className="cursor-pointer">
          X
        </span>
      </HStack>
      <Formik
        enableReinitialize={true}
        validationSchema={fdbUpdateSchema}
        initialValues={initialValues}
        onSubmit={(values) => {
          updateCertificateFdb(
            {
              certificateId,
              fdbShares: values.fdbShares || null,
              remarks: values.remarks,
            },
            {
              onSuccess: () => {
                toast("FDB Shares Updated Successfully", {
                  type: "success",
                  autoClose: 2000,
                });
                onPrimaryAction();
              },
              onError: (err: any) => {
                toast(err.response.errorMessage, {
                  type: "error",
                  autoClose: 2000,
                });
                onPrimaryAction();
              },
            }
          );
        }}
      >
        {(formik) => (
          <Form>
            <VStack>
              <HStack className="justify-between m-2">
                <Label className="text-sm font-normal items-center pr-10 m-2">
                  Current FDB Shares
                </Label>
                <p className="text-sm font-normal items-center pr-10 m-2">
                  {fdbShares.toLocaleString(currencyType)}
                </p>
              </HStack>
              <HStack className="justify-between m-2">
                <Label className="text-sm font-normal items-center pr-10 m-2">
                  Override FDB Shares
                </Label>
                <VStack>
                  <Input
                    type="number"
                    placeholder="Enter FDB to Override"
                    className="w-72"
                    {...formik.getFieldProps(`fdbShares`)}
                  />
                  {formik.touched?.fdbShares && formik.errors?.fdbShares && (
                    <Error text={formik.errors?.fdbShares} />
                  )}
                </VStack>
              </HStack>
              <HStack className="justify-between m-2">
                <Label className="text-sm font-normal items-center pr-10 m-2">
                  Remarks
                </Label>
                <VStack className="w-72">
                  <TextArea
                    placeholder="Enter Remarks"
                    {...formik.getFieldProps("remarks")}
                  />
                  {formik.touched?.remarks && formik.errors?.remarks && (
                    <Error text={formik.errors?.remarks} />
                  )}
                </VStack>
              </HStack>
              <HStack className="justify-between mt-10">
                <SecondaryCTAButton
                  event-name="Back Update Shaertificate FDB"
                  onClick={() => {
                    onSecondaryAction();
                  }}
                >
                  Back
                </SecondaryCTAButton>
                <PrimaryCTAButton
                  event-name="Save Update Shaertificate FDB"
                  type="submit"
                >
                  Save
                </PrimaryCTAButton>
              </HStack>
            </VStack>
          </Form>
        )}
      </Formik>
    </div>
  );
}
