import { useState } from "react";
import { NavigateFunction } from "react-router";
import randomColor from "randomcolor";
import Tooltip from "../components/shared/Tooltip";
import { HStack } from "../components/utils";
import StatusLabelColors from "../constants/StatusLabelColors";
import { RoundDetailModel } from "../types/GenericCaptableModel";

function RoundTag({
  roundNames,
  itemsToDisplay = 2,
  navigate,
  stage,
}: {
  roundNames: RoundDetailModel[];
  itemsToDisplay?: number;
  navigate: (url: string) => void;
  stage?: string;
}) {
  const totalItems = roundNames.length;
  const [expanded, setExpanded] = useState(false);

  const listToDisplay = roundNames.slice(0, itemsToDisplay);

  const remaining = totalItems - itemsToDisplay;

  return (
    <>
      {expanded ? (
        <HStack
          onClick={() => setExpanded(false)}
          className="flex flex-wrap w-fit"
        >
          {roundNames.map((round) => (
            <div
              className="h-fit capitalize rounded-sm font-medium text-center text-[11px] leading-4 pr-1"
              key={round.roundId}
              navigation-name="Round Tag"
              onClick={() =>
                round.roundType.toLowerCase() !== "conversion" &&
                round.roundType.toLowerCase() !== "redemption" &&
                round.roundType.toLowerCase() !== "update_class" &&
                round.roundType.toLowerCase() !== "esoppool" &&
                stage !== "Three"
                  ? navigate(
                      `/transactions/${round.roundType.toLowerCase()}/${
                        round.roundId
                      }`
                    )
                  : null
              }
            >
              {round.roundIdentifier &&
              (round.roundIdentifier?.length || 0) > 3 ? (
                <p className="items-center py-0.5 cursor-pointer border border-gray-300 px-1.5 my-0.5">
                  {round.roundIdentifier.slice(0, 2)}
                </p>
              ) : (
                round.roundIdentifier && (
                  <p className="items-center py-0.5 cursor-pointer border border-gray-300 px-1.5 my-0.5">
                    {round.roundIdentifier}
                  </p>
                )
              )}
            </div>
          ))}
        </HStack>
      ) : (
        <>
          <HStack>
            {listToDisplay.map((round, _listIndex) => (
              <div
                className="h-fit capitalize rounded-sm font-medium text-center text-[11px] leading-4 pr-1"
                key={round.roundId}
                navigation-name="Round Tag"
                onClick={() =>
                  round.roundType.toLowerCase() !== "conversion" &&
                  round.roundType.toLowerCase() !== "redemption" &&
                  round.roundType.toLowerCase() !== "update_class" &&
                  round.roundType.toLowerCase() !== "esoppool" &&
                  stage !== "Three"
                    ? navigate(
                        `/transactions/${round.roundType.toLowerCase()}/${
                          round.roundId
                        }`
                      )
                    : null
                }
              >
                {round.roundIdentifier &&
                (round.roundIdentifier?.length || 0) > 3 ? (
                  <p className="items-center py-0.5 cursor-pointer border border-gray-300 px-1.5">
                    {round.roundIdentifier.slice(0, 3)}
                  </p>
                ) : (
                  round.roundIdentifier && (
                    <p className="items-center py-0.5 cursor-pointer border px-1.5  border-gray-300">
                      {round.roundIdentifier}
                    </p>
                  )
                )}
              </div>
            ))}
            {remaining > 0 && (
              <div className="cursor-pointer" onClick={() => setExpanded(true)}>
                <div className="h-fit capitalize rounded-sm font-medium text-center text-[11px] leading-4 pr-1">
                  <p className="items-center py-0.5 border border-gray-300 px-1.5">
                    + {remaining}
                  </p>
                </div>
              </div>
            )}
          </HStack>
        </>
      )}
    </>
  );
}
export default RoundTag;
