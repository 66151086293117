import { tableEntryStyle } from "../../components/TableComponent";
import { HStack } from "../../components/utils";
import { formatToReadableNumber } from "../../utils/currencyRoboto";
import { formatDisplayDate } from "../../utils/date";
import { getSecurityColor } from "../../utils/historicUtilities";

export function NameRender(data: any) {
  return (
    <div className="whitespace-nowrap py-2">
      <p className={tableEntryStyle}>{data.data?.name}</p>
      <p
        className={`${getSecurityColor(
          data.data.securityType
        )} text-xs text-center w-24 align-middle font-semibold leading-5 rounded-sm mb-1 pt-1`}
      >
        {data.data.securityType}
      </p>
    </div>
  );
}

export function TypeRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-2">
      <p className={tableEntryStyle}>{data.data?.transactionType}</p>
    </div>
  );
}

export function UpdatedValueRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-2">
      <p className={tableEntryStyle}>
        {data.data.transactionType.toLowerCase() === "redemption" ? (
          <HStack>
            <div>{data.data.currencySymbol}</div>
            <div>
              {formatToReadableNumber({
                value: Number(data.data?.updatedValue) || 0,
                format: data.data.currencyType,
              })}
            </div>
          </HStack>
        ) : (
          data.data?.updatedValue
        )}
      </p>
    </div>
  );
}

export function DateRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-2">
      <p className={tableEntryStyle}>{formatDisplayDate(data.data.date)}</p>
    </div>
  );
}
