import { QueryFunctionContext } from "react-query";
import api from "./capTableApi";
import {
  FinancialDataModel,
  FinancialResponseModel,
} from "../pages/finance/FinanceWrapper";

export async function getFinancialData(
  context: QueryFunctionContext
): Promise<FinancialResponseModel> {
  const companyId = context.queryKey[1];
  return api
    .get(`v1/finance/data?companyId=${companyId}`)
    .then((res) => res.data.data);
}
