import { useMutation, useQuery } from "react-query";
import {
  addNewShareHolder,
  convertDematShare,
  editShareholder,
  getAllBeneficialCertificates,
  getAllBeneficialInvestors,
  getConversionDetails,
  getIndividualShareholderOverview,
  getRedemptionDetails,
  getShareholderGroups,
  getShareholderOverview,
  getShareholderRanges,
  updateBeneficiaryInvestors,
  updateBeneficiayCertificates,
  updateCertificateFdb,
  updateConversionDetails,
  updateRedemptionDetails,
  uploadCompanyExcel,
  uploadCustomShareCertificate,
  uploadCustomTemplate,
  uploadDefaultTemplate,
  validateBeneficiaryCertificates,
  uploadMultipleCompanyFile,
  updateTransaction,
  splitShareRanges,
  getPartlyPaidDetails,
  updatePartlyPaidDetails,
  addEsopDetails,
  updateEsopDetails,
  deleteEsopDetails,
} from "../api/shareholders";
import { queryClient } from "./client";
import {
  randomIndividualShareholderOverview,
  randomShareholderOverview,
  randomShareholderRanges,
} from "./randomPlaceholderData";
import { SchedulePay } from "../types/Shareholder";
import { handleEventForTracking } from "../amplitudeAnalytics";

export function useGetShareholderOverview() {
  return useQuery({
    queryKey: "getShareholderOverview",
    queryFn: getShareholderOverview,
    placeholderData: randomShareholderOverview(),
  });
}

export function useGetConversion(
  securityClassId: string,
  shareholderId: string,
  certificateId: string
) {
  return useQuery({
    queryKey: [
      "getConversionDetails",
      securityClassId,
      shareholderId,
      certificateId,
    ],
    queryFn: getConversionDetails,
  });
}

export function useGetIndividualShareholderOverview(cInvestorId: string) {
  return useQuery({
    queryKey: ["getIndividualShareholderOverview", cInvestorId],
    queryFn: getIndividualShareholderOverview,
    placeholderData: randomIndividualShareholderOverview(),
  });
}

export function useGetRedemption(
  securityClassId: string,
  shareholderId: string,
  certificateId: string
) {
  return useQuery({
    queryKey: [
      "getRedemptionDetails",
      securityClassId,
      shareholderId,
      certificateId,
    ],
    queryFn: getRedemptionDetails,
  });
}

export function useGetShareholderGroups() {
  return useQuery({
    queryKey: "getShareholderGroups",
    queryFn: getShareholderGroups,
  });
}

export function useUpdateConversionDetails() {
  return useMutation({
    mutationKey: "updateConversionDetails",
    mutationFn: updateConversionDetails,
    onSuccess: () => {
      handleEventForTracking({
        eventName: "updateConversionDetails",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "updateConversionDetails",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function useAddNewShareHolder() {
  return useMutation({
    mutationKey: "addNewShareHolder",
    mutationFn: addNewShareHolder,
    onSuccess: () => {
      queryClient.invalidateQueries("getInstrumentAndShareHolder");
      queryClient.refetchQueries("getInstrumentAndShareHolder");
      handleEventForTracking({
        eventName: "addNewShareHolder",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "addNewShareHolder",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function useUpdateRedemptionDetails() {
  return useMutation({
    mutationKey: "updateRedemptionDetails",
    mutationFn: updateRedemptionDetails,
    onSuccess: () => {
      handleEventForTracking({
        eventName: "updateRedemptionDetails",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "updateRedemptionDetails",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function useEditShareholder() {
  return useMutation({
    mutationKey: "editShareholder",
    mutationFn: editShareholder,
    onSuccess: () => {
      queryClient.invalidateQueries("getIndividualShareholderOverview");
      queryClient.refetchQueries("getIndividualShareholderOverview");
      handleEventForTracking({
        eventName: "editShareholder",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "editShareholder",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function useConvertDematShare() {
  return useMutation({
    mutationKey: "convertDematShare",
    mutationFn: convertDematShare,
    onSuccess: () => {
      queryClient.invalidateQueries("getIndividualShareholderOverview");
      queryClient.refetchQueries("getIndividualShareholderOverview");
      handleEventForTracking({
        eventName: "convertDematShare",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "convertDematShare",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function useUploadCustomShareCertificate() {
  return useMutation({
    mutationKey: "UploadCustomShareCertificate",
    mutationFn: uploadCustomShareCertificate,
    onSuccess: () => {
      queryClient.invalidateQueries("getIndividualShareholderOverview");
      queryClient.refetchQueries("getIndividualShareholderOverview");
      handleEventForTracking({
        eventName: "UploadCustomShareCertificate",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "UploadCustomShareCertificate",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function useUploadCustomTemplate() {
  return useMutation({
    mutationKey: "uploadCustomTemplate",
    mutationFn: uploadCustomTemplate,
    onSuccess: () => {
      handleEventForTracking({
        eventName: "uploadCustomTemplate",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "uploadCustomTemplate",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function useUploadDefaultTemplate() {
  return useMutation({
    mutationKey: "uploadDefaultTemplate",
    mutationFn: uploadDefaultTemplate,
    onSuccess: () => {
      handleEventForTracking({
        eventName: "uploadDefaultTemplate",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "uploadDefaultTemplate",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function useUpdateCertificateFdb() {
  return useMutation({
    mutationKey: "updateCertificateFdb",
    mutationFn: updateCertificateFdb,

    onSuccess: () => {
      queryClient.invalidateQueries("getIndividualShareholderOverview");
      queryClient.refetchQueries("getIndividualShareholderOverview");
      handleEventForTracking({
        eventName: "uploadDefaultTemplate",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "uploadDefaultTemplate",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function useUpdateBeneficialInvestors() {
  return useMutation({
    mutationKey: "updateBeneficiaryInvestors",
    mutationFn: updateBeneficiaryInvestors,
    onSuccess: () => {
      queryClient.invalidateQueries("getAllBeneficialInvestors");
      queryClient.refetchQueries("getAllBeneficialInvestors");
      handleEventForTracking({
        eventName: "updateBeneficiaryInvestors",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "updateBeneficiaryInvestors",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function useGetAllBeneficialInvestors(cInvestorId: string) {
  return useQuery({
    queryKey: ["getAllBeneficialInvestors", cInvestorId],
    queryFn: getAllBeneficialInvestors,
  });
}

export function useUpdateBeneficialCertificates() {
  return useMutation({
    mutationKey: "updateBeneficiayCertificates",
    mutationFn: updateBeneficiayCertificates,
    onSuccess: () => {
      queryClient.invalidateQueries("getAllBeneficialCertificates");
      queryClient.refetchQueries("getAllBeneficialCertificates");
      queryClient.invalidateQueries("getIndividualShareholderOverview");
      queryClient.refetchQueries("getIndividualShareholderOverview");
      handleEventForTracking({
        eventName: "updateBeneficiayCertificates",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "updateBeneficiayCertificates",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function useGetAllBeneficialCertificates(certificateId: string) {
  return useQuery({
    queryKey: ["getAllBeneficialCertificates", certificateId],
    queryFn: getAllBeneficialCertificates,
  });
}

export function useValidateBeneficialCertificates() {
  return useMutation({
    mutationKey: "validateBeneficiaryCertificates",
    mutationFn: validateBeneficiaryCertificates,
    onSuccess: () => {
      handleEventForTracking({
        eventName: "validateBeneficiaryCertificates",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "validateBeneficiaryCertificates",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function useUpdateTransaction() {
  return useMutation({
    mutationKey: "updateTransaction",
    mutationFn: updateTransaction,

    onSuccess: () => {
      queryClient.invalidateQueries("getIndividualShareholderOverview");
      queryClient.refetchQueries("getIndividualShareholderOverview");
      handleEventForTracking({
        eventName: "updateTransaction",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "updateTransaction",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function useSplitShareRaanges() {
  return useMutation({
    mutationKey: "splitShareRanges",
    mutationFn: splitShareRanges,

    onSuccess: () => {
      queryClient.invalidateQueries("getIndividualShareholderOverview");
      queryClient.refetchQueries("getIndividualShareholderOverview");
      handleEventForTracking({
        eventName: "splitShareRanges",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "splitShareRanges",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function useGetPartlyPaidDetails(certificateId: string) {
  return useQuery({
    queryKey: ["getPartlyPaidDetails", certificateId],
    queryFn: getPartlyPaidDetails,
  });
}

export function useUpdatePartlyPaidDetails() {
  return useMutation({
    mutationKey: "updatePartlyPaidDetails",
    mutationFn: updatePartlyPaidDetails,
    onSuccess: () => {
      queryClient.invalidateQueries("getPartlyPaidDetails");
      queryClient.refetchQueries("getPartlyPaidDetails");
      queryClient.invalidateQueries("getIndividualShareholderOverview");
      queryClient.refetchQueries("getIndividualShareholderOverview");
      handleEventForTracking({
        eventName: "updatePartlyPaidDetails",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "updatePartlyPaidDetails",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function useAddEsopDetails() {
  return useMutation({
    mutationKey: "addEsopDetails",
    mutationFn: addEsopDetails,

    onSuccess: () => {
      queryClient.invalidateQueries("getIndividualShareholderOverview");
      queryClient.refetchQueries("getIndividualShareholderOverview");
      handleEventForTracking({
        eventName: "addEsopDetails",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "addEsopDetails",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function useUpdateEsopDetails() {
  return useMutation({
    mutationKey: "updateEsopDetails",
    mutationFn: updateEsopDetails,

    onSuccess: () => {
      queryClient.invalidateQueries("getIndividualShareholderOverview");
      queryClient.refetchQueries("getIndividualShareholderOverview");
      handleEventForTracking({
        eventName: "updateEsopDetails",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "updateEsopDetails",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function useDeleteEsopDetails() {
  return useMutation({
    mutationKey: "deleteEsopDetails",
    mutationFn: deleteEsopDetails,

    onSuccess: () => {
      queryClient.invalidateQueries("getIndividualShareholderOverview");
      queryClient.refetchQueries("getIndividualShareholderOverview");
      handleEventForTracking({
        eventName: "deleteEsopDetails",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "deleteEsopDetails",
        success: false,
        eventType: "API",
      });
    },
  });
}
