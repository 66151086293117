import { useEffect, useMemo, useState } from "react";
import { Icon } from "@iconify/react";
import { Form, Formik } from "formik";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
import { HStack, VStack, Error } from "../../components/utils";
import {
  formatCurrency,
  getCurrency,
  getCurrencySymbol,
  getCurrencyType,
  getKeyByValue,
} from "../../utils/currencyFormatter";
import { Input, Label } from "../../components/shared/InputField";
import {
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../quickRound/CTAButtonComponents";
import { Select } from "../../components/shared/Select";
import { validationShareHolderSchema } from "./newTransactionValidationSchema";
import { useNewShareholderInstrumentStore } from "../../store/newShareholderAndInstrumentStore";
import { EmptyTable } from "../../components/shared/EmptyTable";
import { getSecurityColor } from "../../utils/historicUtilities";
import Tooltip from "../../components/shared/Tooltip";
import { limitString } from "../../utils/string";
import SortingComponent from "../../components/SortingVariation";
import {
  AddTransactionModalType,
  PrimaryTransactionDataModel,
} from "../../types/quickRound";
import { useShareholderInstrumentStore } from "../../store/shareholderInstrumentStore";
import { CaptableTag } from "../../components/AllotmentTag";
import {
  FormatNumberSpan,
  getRoundCurrencySymbol,
} from "../../utils/currencyRoboto";
import { currencies, currencyTypes } from "../../constants/CompanyCurrencyType";
import { ShowNumberInWords } from "../../components/shared/UiElement";
import { PopupHeader } from "../../components/Headings";
import {
  convertToNumber,
  filterOnlyNumbers,
  numberWithCommas,
} from "../../utils/numUtils";

type AddPrimaryPopupProps = {
  pricedRound: boolean;
  roundCurrency: string;
  onPrimaryAction: () => void;
  onSecondaryAction: () => void;
  data?: any;
  mode?: string;
  pps?: number;
  roundSize?: number;
  totalAmountInvested?: number;
};

const AddPrimaryPopup = ({
  pricedRound,
  roundCurrency,
  onPrimaryAction,
  onSecondaryAction,
  data,
  mode,
  pps,
  totalAmountInvested,
  roundSize,
}: AddPrimaryPopupProps) => {
  const [sortField, setSortField] = useState<{
    field: keyof PrimaryTransactionDataModel | "" | undefined;
    ascending: boolean;
  }>({ field: "name", ascending: false });
  const currencySymbol = getCurrencySymbol();
  const currency = getCurrencyType();
  const [onHoverExchangeRate, setOnHoverExchangeRate] = useState(false);
  const [onHoverNumberOfshares, setOnHoverNumberOfShares] = useState(false);
  const [onHoverSharePrice, setOnHoverSharePrice] = useState(false);
  const [onHoverAmountInvested, setOnHoverAmountInvested] = useState(false);
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const primaryTransactionStore = useNewShareholderInstrumentStore();
  const [totalInvestedAmount, setTotalInvestedAmount] = useState<number>(
    totalAmountInvested || 0
  );

  const [formatExchangeRate, setFormatExchangeRate] = useState("");
  const [formatNumberOfshares, setFormatNumberOfshares] = useState("");
  const [formatSharePrice, setFormatSharePrice] = useState("");
  const [formatAmount, setFormatAmount] = useState("");
  const shareholderInstruments = useShareholderInstrumentStore();

  const initialShareHolderValues: AddTransactionModalType = {
    uuid: data?.uuid || "",
    name: data?.name || "",
    security: data?.security || "",
    sharePrice: data?.sharePrice || (pricedRound ? 0 : pps),
    numberOfShares: data?.numberOfShares || 0,
    amountInvested: data?.amountInvested || 0,
    investmentCurrency: data?.currency || "INR - ₹",
    exchangeRate: data?.exchangeRate || 1,
    securityType:
      shareholderInstruments.instrumentClasses.find(
        (instrument) => instrument.name === data?.security
      )?.subType || "",
  };

  useEffect(() => {
    if (initialShareHolderValues.exchangeRate) {
      setFormatExchangeRate(`${initialShareHolderValues.exchangeRate}`);
    }
    if (initialShareHolderValues.numberOfShares) {
      setFormatNumberOfshares(`${initialShareHolderValues.numberOfShares}`);
    }
    if (initialShareHolderValues.sharePrice) {
      setFormatSharePrice(`${initialShareHolderValues.sharePrice}`);
    }
    if (initialShareHolderValues.amountInvested) {
      setFormatAmount(`${initialShareHolderValues.amountInvested}`);
    }
  }, [
    initialShareHolderValues.exchangeRate,
    initialShareHolderValues.numberOfShares,
    initialShareHolderValues.sharePrice,
    initialShareHolderValues.amountInvested,
  ]);

  useEffect(() => {
    if (mode === "Edit") {
      totalAmountInvested = totalInvestedAmount - data.amountInvested;
      setTotalInvestedAmount(totalAmountInvested);
    }
  }, []);

  const currencyType = getCurrencyType();

  const shareHolders: string[] = shareholderInstruments.shareholders.map(
    (shareHolderDetail) => shareHolderDetail.name
  );

  const pricedRoundInstrumentClasses = shareholderInstruments.instrumentClasses
    .filter((item) => item.pricedRound)
    .map((item) => item.name);
  const nonPricedRoundInstrumentClasses =
    shareholderInstruments.instrumentClasses
      .filter((item) => !item.pricedRound)
      .map((item) => item.name);

  const handleSubmit = (values: AddTransactionModalType) => {
    const exists = primaryTransactionStore.primary.some(
      (transaction) =>
        transaction.name === values.name &&
        transaction.security === values.security &&
        transaction.sharePrice === values.sharePrice &&
        transaction.numberOfShares === values.numberOfShares &&
        transaction.amountInvested === values.amountInvested &&
        transaction.currency === values.investmentCurrency &&
        transaction.exchangeRate === values.exchangeRate
    );

    if (exists) {
      toast("Transaction already exists!", { type: "error", autoClose: 2000 });
      return;
    }

    const existingIndex = primaryTransactionStore.primary.findIndex(
      (transaction: any) => transaction.uuid === values.uuid
    );
    const investorDetail = shareholderInstruments.shareholders.find(
      (shareHolderDetail) => shareHolderDetail.name === values.name
    );
    const instrumentDetail = shareholderInstruments.instrumentClasses.find(
      (instrumentDetail) => instrumentDetail.name === values.security
    );

    let numberOfShares = values.numberOfShares;

    if (values.securityType === "Note" && !values.numberOfShares)
      numberOfShares = 1;

    const transaction: PrimaryTransactionDataModel = {
      uuid: uuidv4(),
      name: values.name,
      investorType: investorDetail?.type,
      security: values.security,
      image: "",
      sharePrice: values.sharePrice,
      instrumentClassId: instrumentDetail?.id,
      par: instrumentDetail?.parValue,
      conversionRatio: `1: ${instrumentDetail?.conversionRatio}`,
      investorId: investorDetail?.id,
      numberOfShares,
      amountInvested: values.amountInvested,
      currency: values.investmentCurrency,
      exchangeRate: values.exchangeRate,
    };

    if (existingIndex !== -1) {
      primaryTransactionStore.primary[existingIndex] = transaction;
      primaryTransactionStore.setPrimary([...primaryTransactionStore.primary]);
      toast("Transaction updated successfully", {
        type: "success",
        autoClose: 2000,
      });
    } else {
      primaryTransactionStore.setPrimary([
        ...primaryTransactionStore.primary,
        transaction,
      ]);
      toast("Transaction added successfully", {
        type: "success",
        autoClose: 2000,
      });
    }
    onPrimaryAction();
  };

  return (
    <VStack className="">
      <PopupHeader
        text={`${mode} Primary Transaction`}
        onClick={() => onSecondaryAction()}
      />

      <div className="px-4 py-4 bg-white rounded-lg">
        <VStack className="justify-between">
          <Formik
            key="shareHolder"
            enableReinitialize={true}
            initialValues={initialShareHolderValues}
            onSubmit={(values, { resetForm }) => {
              handleSubmit(values);
              resetForm();
            }}
            validationSchema={validationShareHolderSchema}
          >
            {(formik) => (
              <Form key="shareholder">
                <VStack className="w-full gap-6 px-2 py-4">
                  <VStack className="gap-8">
                    <HStack className="grid w-full grid-cols-1 gap-8 mx-auto md:grid-cols-2">
                      <div className="flex-1 w-72">
                        <Label className="text-sm text-[#464E5F] font-medium">
                          Shareholder Name
                        </Label>
                        <Select
                          options={shareHolders.filter(
                            (shareHolder) =>
                              shareHolder.toLowerCase() !== "ESOP pool"
                          )}
                          {...formik.getFieldProps("name")}
                        />
                        {formik.touched?.name && formik.errors?.name && (
                          <Error text={formik.errors?.name} />
                        )}
                      </div>
                      <div className="flex-1 w-72">
                        <Label className="text-sm text-[#464E5F] font-medium">
                          Security
                        </Label>
                        <Select
                          options={
                            pricedRound
                              ? pricedRoundInstrumentClasses
                              : nonPricedRoundInstrumentClasses
                          }
                          {...formik.getFieldProps("security")}
                          onChange={(e) => {
                            formik.handleChange(e);
                            const securityType =
                              shareholderInstruments.instrumentClasses.find(
                                (instrument) =>
                                  instrument.name === e.target.value
                              )?.subType || "";
                            formik.setFieldValue("securityType", securityType);
                          }}
                        />
                        {formik.touched.security && formik.errors?.security && (
                          <Error text={formik.errors?.security} />
                        )}
                      </div>
                    </HStack>
                    <HStack className="grid w-full grid-cols-1 gap-8 mx-auto md:grid-cols-2">
                      <div className="flex-1 w-72">
                        <Label className="text-sm text-[#464E5F] font-medium">
                          Investment Currency
                        </Label>
                        <Select
                          options={currencies}
                          {...formik.getFieldProps("investmentCurrency")}
                        />
                        {formik.touched?.investmentCurrency &&
                          formik.errors?.investmentCurrency && (
                            <Error text={formik.errors?.investmentCurrency} />
                          )}
                      </div>

                      {formik.values.investmentCurrency &&
                        roundCurrency !== formik.values.investmentCurrency && (
                          <div className="flex-1 w-72">
                            <Label className="text-sm text-[#464E5F] font-medium">
                              Exchange Rate
                            </Label>
                            <Input
                              placeholder="Enter Exchange Rate"
                              onKeyUpCapture={() => {
                                formik.setFieldValue(
                                  "amountInvested",
                                  (formik?.values?.sharePrice || 0) *
                                    (formik?.values?.numberOfShares || 0)
                                );
                              }}
                              type="text"
                              onMouseEnter={() => {
                                setOnHoverExchangeRate(true);
                              }}
                              onMouseLeave={() => {
                                setOnHoverExchangeRate(false);
                              }}
                              onChange={(e: any) => {
                                const filteredValue = filterOnlyNumbers(
                                  e.target.value
                                );
                                setFormatExchangeRate(filteredValue);
                                const values = convertToNumber(filteredValue);

                                formik.setFieldValue("exchangeRate", values);
                                formik.handleChange("exchangeRate");
                              }}
                              value={numberWithCommas(
                                formatExchangeRate,
                                (currencyTypes as any)[
                                  (
                                    formik.values.investmentCurrency ??
                                    currencyType
                                  ).split(" - ")[0] as string
                                ]
                              )}
                            />
                            {onHoverExchangeRate && (
                              <ShowNumberInWords
                                value={formik.values.exchangeRate!}
                                width={38}
                                currency={currencyType}
                              />
                            )}

                            {formik.touched.exchangeRate &&
                              formik.errors?.exchangeRate && (
                                <Error text={formik.errors?.exchangeRate} />
                              )}

                            {!formik.errors?.exchangeRate && (
                              <div className=" flex justify-end text-[9px] text-gray-500 my-0.5">
                                1{" "}
                                {getKeyByValue(
                                  currencyTypes,
                                  getCurrency(
                                    formik.values.investmentCurrency
                                  ) || currency
                                )}{" "}
                                = {formik.values.exchangeRate}{" "}
                                {getKeyByValue(
                                  currencyTypes,
                                  getCurrency(data?.roundCurrency) || currency
                                )}
                              </div>
                            )}
                          </div>
                        )}
                      {(formik.values.investmentCurrency === "" ||
                        data?.roundCurrency ===
                          formik.values.investmentCurrency) && (
                        <div className="flex-1 w-72"></div>
                      )}
                    </HStack>
                    {formik.values.securityType !== "Note" && (
                      <HStack className="grid w-full grid-cols-1 gap-8 mx-auto md:grid-cols-2">
                        <div className="flex-1 w-72">
                          <Label className="text-sm text-[#464E5F] font-medium">
                            No. of Securities
                          </Label>
                          <Input
                            placeholder="Enter No.of Shares"
                            onKeyUpCapture={() => {
                              const amountInvest =
                                (formik.values.sharePrice || 0) *
                                (formik.values.numberOfShares || 0);
                              setFormatAmount(amountInvest.toString());
                              formik.setFieldValue(
                                "amountInvested",
                                (formik?.values?.sharePrice || 0) *
                                  (formik?.values?.numberOfShares || 0)
                              );
                            }}
                            {...formik.getFieldProps("numberOfShares")}
                            type="text"
                            onMouseEnter={() => {
                              setOnHoverNumberOfShares(true);
                            }}
                            onMouseLeave={() => {
                              setOnHoverNumberOfShares(false);
                            }}
                            onChange={(e: any) => {
                              const values = parseInt(
                                e.target.value?.replaceAll(",", "") || 0,
                                10
                              );
                              formik.setFieldValue("numberOfShares", values);
                              formik.handleChange("numberOfShares");
                              setTotalAmount(
                                (formik?.values?.sharePrice ?? 0) *
                                  (values ?? 0) +
                                  totalInvestedAmount!
                              );
                            }}
                            value={Intl.NumberFormat(
                              (currencyTypes as any)[
                                (
                                  formik.values.investmentCurrency ??
                                  currencyType
                                ).split(" - ")[0] as string
                              ]
                            ).format(formik.values.numberOfShares!)}
                          />
                          {onHoverNumberOfshares && (
                            <ShowNumberInWords
                              value={formik.values.numberOfShares}
                              currency={currencyType}
                            />
                          )}

                          {formik.touched?.numberOfShares &&
                            formik.errors?.numberOfShares && (
                              <Error text={formik.errors?.numberOfShares} />
                            )}
                        </div>
                        <div className="flex-1 w-72">
                          <Label className="text-sm text-[#464E5F] font-medium">
                            Share Price (
                            {getRoundCurrencySymbol(
                              formik.values.investmentCurrency ||
                                data?.roundCurrency
                            ) || currencySymbol}
                            )
                          </Label>
                          <Input
                            placeholder="Enter Share Price"
                            onKeyUpCapture={() => {
                              const amountInvest =
                                (formik.values.sharePrice || 0) *
                                (formik.values.numberOfShares || 0);
                              setFormatAmount(amountInvest.toString());
                              formik.setFieldValue(
                                "amountInvested",
                                (formik?.values?.sharePrice || 0) *
                                  (formik?.values?.numberOfShares || 0)
                              );
                            }}
                            type="text"
                            onMouseEnter={() => {
                              setOnHoverSharePrice(true);
                            }}
                            onMouseLeave={() => {
                              setOnHoverSharePrice(false);
                            }}
                            onChange={(e: any) => {
                              const filteredValue = filterOnlyNumbers(
                                e.target.value
                              );
                              setFormatSharePrice(filteredValue);
                              const values = convertToNumber(filteredValue);

                              formik.setFieldValue("sharePrice", values);
                              formik.handleChange("sharePrice");
                              setTotalAmount(
                                values * (formik?.values?.numberOfShares ?? 0) +
                                  totalInvestedAmount!
                              );
                            }}
                            value={numberWithCommas(
                              formatSharePrice,
                              (currencyTypes as any)[
                                (
                                  formik.values.investmentCurrency ??
                                  currencyType
                                ).split(" - ")[0] as string
                              ]
                            )}
                          />
                          {onHoverSharePrice && (
                            <ShowNumberInWords
                              value={formik.values.sharePrice}
                              width={38}
                              currency={currencyType}
                            />
                          )}

                          {formik.touched?.sharePrice &&
                            formik.errors?.sharePrice && (
                              <Error text={formik.errors?.sharePrice} />
                            )}
                        </div>
                      </HStack>
                    )}
                    <HStack className="grid w-full grid-cols-1 gap-8 mx-auto md:grid-cols-2">
                      <div className="flex-1 w-72">
                        <Label className="text-sm text-[#464E5F] font-medium">
                          Amount Invested (
                          {getRoundCurrencySymbol(
                            formik.values.investmentCurrency ||
                              data?.roundCurrency
                          ) || currencySymbol}
                          )
                        </Label>

                        <Input
                          type="text"
                          onMouseEnter={() => {
                            setOnHoverAmountInvested(true);
                          }}
                          onMouseLeave={() => {
                            setOnHoverAmountInvested(false);
                          }}
                          onChange={(e: any) => {
                            const filteredValue = filterOnlyNumbers(
                              e.target.value
                            );
                            setFormatAmount(filteredValue);
                            const values = convertToNumber(filteredValue);

                            formik.setFieldValue("amountInvested", values);
                            formik.handleChange("amountInvested");
                            const totalAmount = totalInvestedAmount! + values;
                            setTotalAmount(totalAmount);
                          }}
                          value={numberWithCommas(
                            formatAmount,
                            (currencyTypes as any)[
                              (
                                formik.values.investmentCurrency ?? currencyType
                              ).split(" - ")[0] as string
                            ]
                          )}
                        />
                        {onHoverAmountInvested && (
                          <ShowNumberInWords
                            value={formik.values.amountInvested}
                            width={38}
                            currency={currencyType}
                          />
                        )}

                        {formik.touched?.amountInvested &&
                          formik.errors?.amountInvested && (
                            <Error text={formik.errors?.amountInvested} />
                          )}
                        {totalAmount > roundSize! && (
                          <p className="flex justify-start pt-2 text-red-400 text-xxs">
                            Total amount invested should not larger than Round
                            Size.
                          </p>
                        )}

                        {/* reusable code {data?.roundCurrency !==
                          formik.values.investmentCurrency &&
                          formik.values.amountInvested > 0 && (
                            <div className=" flex justify-end text-[10px] text-gray-500 my-0.5">
                              <span className="pr-1">
                                {getRoundCurrencySymbol(data?.roundCurrency) ||
                                  currencySymbol}
                              </span>
                              {(
                                formik.values.amountInvested *
                                (formik.values.exchangeRate || 1)
                              ).toLocaleString(
                                getCurrency(data?.roundCurrency || currency)
                              )}
                            </div>
                          )} */}
                      </div>
                      <div className="flex-1 w-72"></div>
                    </HStack>
                  </VStack>

                  <HStack className="justify-between mt-10">
                    <SecondaryCTAButton
                      event-name="Cancel Primary Modal"
                      type="reset"
                      className="text-red-500"
                      onClick={() => onSecondaryAction()}
                    >
                      Cancel
                    </SecondaryCTAButton>
                    <PrimaryCTAButton
                      event-name="Save Primary Modal"
                      type="submit"
                      disabled={
                        totalAmount > roundSize! ||
                        (formik?.values?.sharePrice ?? 0) *
                          (formik?.values?.numberOfShares ?? 0) +
                          totalInvestedAmount! >
                          roundSize!
                      }
                    >
                      {mode}
                    </PrimaryCTAButton>
                  </HStack>
                </VStack>
              </Form>
            )}
          </Formik>
        </VStack>
      </div>
    </VStack>
  );
};

export default AddPrimaryPopup;
