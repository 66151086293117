import { RoundHeaderType } from "./RoundsConstant";

export const bonusHeaderCardList = [
  RoundHeaderType.RoundNameHeader,
  RoundHeaderType.RoundSharePriceHeader,
  RoundHeaderType.RoundNewBonusShareHeader,
  RoundHeaderType.RoundNewFDBShareHeader,
];

export const buybackHeaderCardList = [
  RoundHeaderType.RoundDetailHeader,
  RoundHeaderType.RoundSecurityNameHeader,
  RoundHeaderType.RoundPriceShareHeader,
  RoundHeaderType.RoundOnlyDilutionNameHeader,
];

export const roundHeaderCardList = [
  RoundHeaderType.RoundNameHeader,
  RoundHeaderType.RoundPostMoneyHeader,
  RoundHeaderType.RoundPriceShareHeader,
  RoundHeaderType.RoundDilutionHeader,
];

export const esopExerciseHeaderCardList = [
  RoundHeaderType.RoundNameHeader,
  RoundHeaderType.RoundPostMoneyHeader,
  RoundHeaderType.RoundPriceShareHeader,
  RoundHeaderType.RoundDilutionHeaderExercise,
];

export const secondaryHeaderCardList = [
  RoundHeaderType.SecondaryDetailHeader,
  RoundHeaderType.RoundSecurityNameHeader,
  RoundHeaderType.RoundPriceShareHeader,
  RoundHeaderType.RoundBuyerSellerHeader,
];

export const splitHeaderCardList = [
  RoundHeaderType.RoundSplitShareHeader,
  RoundHeaderType.RoundSecurityNameHeader,
];
