import { Form, Formik } from "formik";
import _ from "lodash";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Icon } from "@iconify/react";
import { Input, Label } from "../components/shared/InputField";
import { Select } from "../components/shared/Select";
import { SwitchButton } from "../components/shared/SwitchButton";
import { HStack, VStack, Error, Box } from "../components/utils";
import {
  CapTableBarChart,
  ShareHolderCaptableChartModel,
} from "../pages/newQuickTransaction/CapTableBarChart";
import { ShareHolderCapTableQuickRound } from "../pages/newQuickTransaction/ShareHolderCapTableQuickRound";
import { useGetQuickRoundPreview } from "../queries/quickRound";
import {
  useGetSecurityConversion,
  useGetUpdatedConversionDetails,
  useUpdateSecurityConversionDetails,
} from "../queries/securities";
import { useAuthStore } from "../store";
import {
  SecurityConversionDetails,
  SecurityConversionUpdateDetails,
  SecurityTableDetail,
  UpdatedConversionDetails,
} from "../types/SecurityModel";
import { formatCurrency, getCurrencyType } from "../utils/currencyFormatter";
import { getSecurityColor } from "../utils/historicUtilities";
import RoundIdentifiers from "../shared/RoundIdentifer";
import { useApproveTransactionPreview } from "../queries/transactionRound";
import {
  ButtonSize,
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../pages/quickRound/CTAButtonComponents";
import MsgBanner from "../pages/modeling/MsgBanner";
import { PopupHeader } from "../components/Headings";
import { FormatNumberSpan } from "../utils/currencyRoboto";
import NonPricedAgCaptable from "../pages/rounds/NonPricedAGCapTable";
import Loader from "../shared/Loader";

export function NonPricedRoundConversion({
  securities,
  onPrimaryAction = () => {},
  onSecondaryAction = () => {},
  isProforma = false,
}: {
  securities: SecurityTableDetail[];
  onPrimaryAction: () => void;
  onSecondaryAction: () => void;
  isProforma?: boolean;
}) {
  const [isApprove, setIsApprove] = useState(false);
  const currencyType = getCurrencyType();

  const companyId = useAuthStore.getState().companyId || "";

  const [eventId, setEventId] = useState();

  const { mutate: updateConversion, data: conversionDetails } =
    useUpdateSecurityConversionDetails();

  const { mutate: getUpdatedConversion } = useGetUpdatedConversionDetails();

  const { refetch: getQuickRoundPreview, data: previewData } =
    useGetQuickRoundPreview(eventId || "");

  const { mutate: approveSecurityConversion } = useApproveTransactionPreview();

  const [updatedConversionDetails, setUpdatedConversionDetails] =
    useState<UpdatedConversionDetails>();

  const [securityDetails, setSecurityDetails] = useState(securities[0]);

  const handleSave = (values: SecurityConversionUpdateDetails) => {
    setLoadingTab(true);
    const conversionDetails: SecurityConversionUpdateDetails = {
      securityClassId: securityDetails?.id || "",
      conversionDate: values.conversionDate,
      conversionType: values.conversionType,
      newSharePrice: values.newSharePrice,
      updateConversionRatio: values.updateConversionRatio,
      conversionSecurityClass: values.conversionSecurityClass,
      convertibleSecurityClassId:
        securityClasses?.find(
          (securityClass) =>
            securityClass.securityClassName === values.conversionSecurityClass
        )?.securityClassId || "",
      useValuationAtConversion: values.useValuationAtConversion,
      valuation: values.valuation,
      isConvertingRound: true,
    };

    updateConversion(conversionDetails, {
      onSuccess: async (data) => {
        setEventId(data.id);
        setTimeout(() => {
          setLoadingTab(false);
        }, 500);
        setIsApprove(true);
        toast("Conversion Details Saved Successfully!", {
          type: "success",
          autoClose: 2000,
        });
      },
      onError: (err: any) => {
        setTimeout(() => {
          setLoadingTab(false);
        }, 500);

        toast(err.response.data.errorMessage, {
          type: "error",
          autoClose: 2000,
        });
      },
    });
  };

  useEffect(() => {
    getQuickRoundPreview();
  }, [conversionDetails]);

  const { data: securityConversionModel } = useGetSecurityConversion(
    securityDetails?.id || ""
  );

  const securityTypes = securityConversionModel?.securityType.map(
    (security) => security.securityType
  );
  const [selectedSecurityType, setSelectedSecurityType] = useState<string>("");
  const [securityClasses, setSecurityClasses] = useState<
    {
      securityClassId: string;
      securityClassName: string;
    }[]
  >();

  useEffect(() => {
    const securityList =
      securityConversionModel?.securityType.find(
        (security) => security.securityType === selectedSecurityType
      )?.securityList || [];
    setSecurityClasses(securityList);
  }, [selectedSecurityType]);

  const initialValues = {
    companyId: "",
    conversionDate: "",
    conversionType: "",
    updateConversionRatio: true,
    conversionSecurityClass: "",
    convertibleSecurityClassId: "",
    securityClassId: "",
    shareholderId: "",
    useValuationAtConversion: true,
    valuation: 0,
    newSharePrice: 0,
    securityType: "",
  };

  const [selectedSecurity, setSelectedSecurity] = useState(
    securityDetails.className
  );

  const securityConversionInputSchema = Yup.object({
    conversionDate: Yup.string().required().label("Conversion Date"),
    conversionType: Yup.string().required().label("Conversion Type"),
    useValuationAtConversion: Yup.boolean().label("useValuationAtConversion"),
    updateConversionRatio: Yup.boolean().label("Update ConversionRatio"),
    valuation: Yup.number().when("useValuationAtConversion", {
      is: (val: boolean) => val === true,
      then: Yup.number().required().label("Valuation"),
      otherwise: Yup.number().label("Valuation"),
    }),
    newSharePrice: Yup.number().when("useValuationAtConversion", {
      is: (val: boolean) => val === false,
      then: Yup.number().required().label("Share Price"),
      otherwise: Yup.number().label("Share Price"),
    }),
    conversionSecurityClass: Yup.string().when("updateConversionRatio", {
      is: (val: boolean) => val === false,
      then: Yup.string().required().label("Security Class"),
      otherwise: Yup.string().label("Security Class"),
    }),
    securityType: Yup.string().when("updateConversionRatio", {
      is: (val: boolean) => val === false,
      then: Yup.string().required().label("Security Type"),
      otherwise: Yup.string().label("Security Type"),
    }),
  });
  const [loading, setLoading] = useState(false);
  const [calculated, setCalculated] = useState(false);
  const [loadingTab, setLoadingTab] = useState(false);
  return (
    <div className="md:w-[720px] w-full bg-white rounded-md overflow-x-hidden">
      <MsgBanner message="This round will be converted to priced round." />

      <PopupHeader
        text={" Non Priced Round Conversion"}
        subText={`Round Name : ${securityDetails.roundIdentifierDetails[0].roundName}  |   Security Name : ${securityDetails.type}`}
        onClick={() => onSecondaryAction()}
      />
      <VStack className="p-4 font-medium text-left">
        {securityConversionModel && (
          <div>
            <div className="hidden md:block">
              <AddSecurityConversionDetailCards
                securityConversion={securityConversionModel}
                currencyType={currencyType}
              />
            </div>
            <div className="block p-2 border border-gray-400 rounded-md md:hidden shadow-box">
              <ConversionDetailsMobileCard
                value={`1:${securityConversionModel.originalConversionRatio.toLocaleString(
                  currencyType
                )}`}
                label="Original CR"
              />

              <ConversionDetailsMobileCard
                value={securityConversionModel.baseValuation}
                label="Base Valuation"
                currencyType={currencyType}
              />

              <ConversionDetailsMobileCard
                value={securityConversionModel.sharePrice}
                label="Original Share Price"
                currencyType={currencyType}
              />

              <ConversionDetailsMobileCard
                value={securityConversionModel.valuationCap}
                label="Valuation Cap"
                currencyType={currencyType}
              />
            </div>
          </div>
        )}

        {!isApprove ? (
          <Formik
            key="addQuickBuybackParticipant"
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={securityConversionInputSchema}
            onSubmit={(values) => {
              handleSave(values);
            }}
          >
            {(formik) => (
              <Form key="shareholder">
                <VStack className="w-full gap-4 px-4 py-4">
                  <HStack className="flex-col gap-8 md:flex-row">
                    <VStack className="flex-1">
                      <Label>Security Class</Label>
                      <Select
                        placeholder="Select Security"
                        value={selectedSecurity}
                        options={securities.map(
                          (security) => security.className
                        )}
                        onChange={(e) => {
                          const security = securities.find(
                            (security) =>
                              security.className === selectedSecurity
                          );
                          if (security) setSecurityDetails(security);
                        }}
                      />
                    </VStack>

                    <VStack className="flex-1">
                      <Label>Conversion Date</Label>
                      <Input
                        type="date"
                        placeholder="Eg:DD/MM/YYYY"
                        {...formik.getFieldProps("conversionDate")}
                      />
                      {formik.touched?.conversionDate &&
                        formik.errors?.conversionDate && (
                          <Error text={formik.errors?.conversionDate} />
                        )}
                    </VStack>
                  </HStack>
                  <VStack>
                    <HStack>
                      <div className="flex flex-row-reverse items-center text-sm font-medium text-gray-600 ">
                        <SwitchButton
                          className="items-center mr-2 text-sm font-medium"
                          value={formik.values.useValuationAtConversion}
                          label="Use Valuation At Conversion"
                          onClick={() => {
                            formik.setFieldValue(
                              "useValuationAtConversion",
                              !formik.values.useValuationAtConversion
                            );
                          }}
                        />
                      </div>
                    </HStack>
                    <HStack className="flex-col gap-8 md:flex-row">
                      {formik.values.useValuationAtConversion && (
                        <VStack className="flex-1 w-1/2">
                          <Label>Valuation</Label>

                          <Input
                            type="number"
                            placeholder="Enter Valuation"
                            className={`${
                              !formik.values.useValuationAtConversion
                                ? "cursor-not-allowed"
                                : ""
                            }`}
                            {...formik.getFieldProps("valuation")}
                            disabled={!formik.values.useValuationAtConversion}
                          />
                          {formik.touched?.valuation &&
                            formik.errors?.valuation && (
                              <Error text={formik.errors?.valuation} />
                            )}
                        </VStack>
                      )}

                      {!formik.values.useValuationAtConversion && (
                        <VStack className="flex-1 w-1/2">
                          <Label>Share Price</Label>
                          <Input
                            type="number"
                            className={`${
                              formik.values.useValuationAtConversion
                                ? "cursor-not-allowed"
                                : ""
                            }`}
                            placeholder="Enter Share Price"
                            {...formik.getFieldProps("newSharePrice")}
                            disabled={formik.values.useValuationAtConversion}
                          />
                          {formik.touched?.newSharePrice &&
                            formik.errors?.newSharePrice && (
                              <Error text={formik.errors?.newSharePrice} />
                            )}
                        </VStack>
                      )}

                      <VStack className="flex-1 w-1/2">
                        <Label className="text-sm font-normal">
                          Conversion Type
                        </Label>
                        <Select
                          className=""
                          options={[
                            "Preferential Allotment",
                            "Private Placement",
                          ]}
                          {...formik.getFieldProps("conversionType")}
                        />
                        {formik.touched?.conversionType &&
                          formik.errors?.conversionType && (
                            <Error text={formik.errors?.conversionType} />
                          )}
                      </VStack>
                    </HStack>
                  </VStack>
                  <VStack>
                    <HStack>
                      <div className="flex flex-row-reverse items-center text-sm font-medium text-gray-600 ">
                        <SwitchButton
                          className="items-center mr-2 text-sm font-medium"
                          value={formik.values.updateConversionRatio}
                          label="Update Conversion Ratio"
                          onClick={() => {
                            formik.setFieldValue(
                              "updateConversionRatio",
                              !formik.values.updateConversionRatio
                            );
                          }}
                        />
                      </div>
                    </HStack>

                    {!formik.values.updateConversionRatio && (
                      <HStack className="flex-col gap-4 md:flex-row">
                        <VStack className="flex-1">
                          <Label>Security Type</Label>

                          <Select
                            options={securityTypes || []}
                            placeholder="Select Security Type"
                            className=""
                            {...formik.getFieldProps("securityType")}
                            onChange={(e) => {
                              setSelectedSecurityType(e.target.value);
                              formik.handleChange(e);
                            }}
                            disabled={formik.values.updateConversionRatio}
                          />
                          {formik.touched?.securityType &&
                            formik.errors?.securityType && (
                              <Error text={formik.errors?.securityType} />
                            )}
                        </VStack>

                        <VStack className="flex-1">
                          <Label>Security Class</Label>

                          <Select
                            options={
                              securityClasses?.map(
                                (securityClass) =>
                                  securityClass.securityClassName
                              ) || []
                            }
                            placeholder="Select Security Class"
                            className="w-45"
                            {...formik.getFieldProps("conversionSecurityClass")}
                            disabled={formik.values.updateConversionRatio}
                          />
                          {formik.touched?.conversionSecurityClass &&
                            formik.errors?.conversionSecurityClass && (
                              <Error
                                text={formik.errors?.conversionSecurityClass}
                              />
                            )}
                        </VStack>
                      </HStack>
                    )}
                  </VStack>
                  <VStack>
                    <div className="justify-center">
                      {updatedConversionDetails && !loading && (
                        <UpdatedConversionDetailsCards
                          currencyType={currencyType}
                          updatedConversionDetails={updatedConversionDetails}
                        />
                      )}
                    </div>
                  </VStack>

                  <HStack className="justify-between mt-6">
                    <SecondaryCTAButton
                      event-name="Back Non Priced Conversion Modal"
                      buttonSize={ButtonSize.SMALL}
                      onClick={() => {
                        onSecondaryAction();
                      }}
                    >
                      Back
                    </SecondaryCTAButton>
                    <HStack className="gap-4">
                      <SecondaryCTAButton
                        event-name="Calculate Non Priced Conversion Modal"
                        buttonSize={ButtonSize.SMALL}
                        loading={loading}
                        onClick={() => {
                          setLoading(true);
                          const conversionDetails: SecurityConversionUpdateDetails =
                            {
                              securityClassId: securityDetails?.id || "",
                              conversionDate: formik.values.conversionDate,
                              conversionType:
                                formik.values.conversionType || "",
                              newSharePrice: formik.values.newSharePrice,
                              updateConversionRatio:
                                formik.values.updateConversionRatio,
                              conversionSecurityClass:
                                formik.values.conversionSecurityClass,
                              convertibleSecurityClassId:
                                securityClasses?.find(
                                  (securityClass) =>
                                    securityClass.securityClassName ===
                                    formik.values.conversionSecurityClass
                                )?.securityClassId || "",
                              useValuationAtConversion:
                                formik.values.useValuationAtConversion,
                              valuation: formik.values.valuation,
                            };
                          getUpdatedConversion(conversionDetails, {
                            onSuccess: async (data) => {
                              setTimeout(() => {
                                setLoading(false);
                                setCalculated(true);
                              }, 500);

                              setUpdatedConversionDetails(data);
                            },

                            onError: (err: any) => {
                              setTimeout(() => {
                                setLoading(false);
                              }, 500);
                              setLoading(false);
                              toast(err.response.data.errorMessage, {
                                type: "error",
                                autoClose: 2000,
                              });
                            },
                          });
                        }}
                      >
                        Calculate
                      </SecondaryCTAButton>

                      <PrimaryCTAButton
                        event-name="Save Non Priced Conversion Modal"
                        disabled={!calculated}
                        buttonSize={ButtonSize.SMALL}
                        type="submit"
                        loading={loadingTab}
                      >
                        Save & Continue
                      </PrimaryCTAButton>
                    </HStack>
                  </HStack>
                </VStack>
              </Form>
            )}
          </Formik>
        ) : (
          <VStack>
            <VStack className="gap-4 my-4 bg-white border rounded-lg shadow-box border-borderColor">
              {loadingTab ? (
                <Loader />
              ) : (
                previewData?.holdingDifference && (
                  <NonPricedAgCaptable
                    capTableData={previewData?.holdingDifference || []}
                  />
                )
              )}
            </VStack>
            <HStack className="justify-between">
              <SecondaryCTAButton
                event-name="Back from approve Non Priced Conversion Modal"
                buttonSize={ButtonSize.SMALL}
                onClick={() => {
                  setIsApprove(false);
                }}
              >
                Back
              </SecondaryCTAButton>
              <PrimaryCTAButton
                event-name="Approve Non Priced Conversion Modal"
                buttonSize={ButtonSize.SMALL}
                onClick={() => {
                  approveSecurityConversion(
                    {
                      companyId,
                      eventId: eventId || "",
                      valuation: previewData?.eventInfo?.valuation || 0,
                      pricePerShare: previewData?.eventInfo?.pps || 0,
                    },
                    {
                      onSuccess: (data) => {
                        onPrimaryAction();
                        toast("Conversion Details Updated Successfully!", {
                          type: "success",
                          autoClose: 2000,
                        });
                      },
                      onError: (err: any) => {
                        toast(err.response.data.reason, {
                          type: "error",
                          autoClose: 2000,
                        });
                      },
                    }
                  );
                }}
              >
                Approve
              </PrimaryCTAButton>
            </HStack>
          </VStack>
        )}
      </VStack>
    </div>
  );
}

function ConversionDetailsCard({
  value,
  label,
  currencyType,
}: {
  value: any;
  label: string;
  currencyType?: string;
}) {
  return (
    <Box className="flex-wrap w-full px-2 py-4 mt-3 bg-white border border-gray-300 rounded-md shadow-box">
      <VStack>
        {label === "Original CR" ? (
          <div className="text-sm font-semibold text-center">{value}</div>
        ) : (
          <div className="text-sm font-semibold text-center">
            <FormatNumberSpan value={value ?? 0} format={currencyType} />
          </div>
        )}
        <div className="font-medium text-center text-xxs1 text-descriptionColor">
          {label}
        </div>
      </VStack>
    </Box>
  );
}

function ConversionDetailsMobileCard({
  value,
  label,
  currencyType,
}: {
  value: any;
  label: string;
  currencyType?: string;
}) {
  return (
    <HStack className="items-center justify-between">
      <div className="text-xs font-medium text-center text-descriptionColor">
        {label}
      </div>
      {label === "Original CR" ? (
        <div className="text-sm font-semibold text-center">{value}</div>
      ) : (
        <div className="text-sm font-semibold text-center">
          <FormatNumberSpan value={value ?? 0} format={currencyType} />
        </div>
      )}
    </HStack>
  );
}

function AddSecurityConversionDetailCards({
  securityConversion,
  currencyType,
}: {
  securityConversion: SecurityConversionDetails;
  currencyType: string;
}) {
  return (
    <Box className="border-b">
      <HStack className="justify-between gap-12 pb-8">
        <ConversionDetailsCard
          value={`1:${securityConversion.originalConversionRatio.toLocaleString(
            currencyType
          )}`}
          label="Original CR"
        />

        <ConversionDetailsCard
          value={securityConversion.baseValuation}
          label="Base Valuation"
          currencyType={currencyType}
        />

        <ConversionDetailsCard
          value={securityConversion.sharePrice}
          label="Original Share Price"
          currencyType={currencyType}
        />

        <ConversionDetailsCard
          value={securityConversion.valuationCap}
          label="Valuation Cap"
          currencyType={currencyType}
        />
      </HStack>
    </Box>
  );
}

function UpdatedConversionDetailsCards({
  currencyType,
  updatedConversionDetails,
}: {
  currencyType: string;
  updatedConversionDetails: any;
}) {
  return (
    <Box className="">
      <HStack className="flex-col px-3 m-4 md:flex-row">
        <ConversionDetailsCard
          value={`1:${(
            updatedConversionDetails.updatedConversionRatio ?? 0
          ).toLocaleString(currencyType)}`}
          label="Updated CR"
        />
        <ConversionDetailsCard
          value={updatedConversionDetails.conversionPPS}
          label="Conversion Share Price"
          currencyType={currencyType}
        />
      </HStack>
    </Box>
  );
}
