/* eslint-disable object-property-newline */
import { Icon } from "@iconify/react";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { useFormik } from "formik";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useDebounce } from "use-debounce";
import * as Yup from "yup";
import { CheckBoxWithLabel, Input } from "../../components/shared/InputField";
import { Box, HStack, VStack } from "../../components/utils";
import {
  useCompanyRoles,
  useSearchUsers,
  useUpdateCompanyRole,
  useCreateCompanyRole,
  useGetUser,
  useCompanyUsers,
  useAssignRoleToUser,
  useCreateCustomRoleForUser,
  useStandardAcls,
} from "../../queries/newCapTable/userRoles";
import { Acl, RoleGrant } from "../../types/User";
import { PrimaryCTAButton } from "../quickRound/CTAButtonComponents";

export function RolesManager() {
  const { data: _standardAclList } = useStandardAcls();
  const standardAclList = _standardAclList || [];
  const [searchText, setSearchText] = useState("");
  const [debouncedSearchText, __] = useDebounce(searchText, 200);
  const { data: _users, isFetching } = useSearchUsers(debouncedSearchText);
  const users = _users || [];
  const { data: _roles } = useCompanyRoles();
  const roles = [...(_roles || []), { name: "Create New", id: 0, acl: [] }];
  const [acls, setAcls] = useState<Acl[]>([]);

  const { mutate: updateCompanyRole } = useUpdateCompanyRole();
  const { mutate: createCompanyRole } = useCreateCompanyRole();

  const formik = useFormik({
    initialValues: {
      name: "",
      id: 0,
      acl: [] as RoleGrant[],
    },
    onSubmit: (values) => {
      if (values.id === 0) {
        createCompanyRole({ ...values, acl: acls as unknown as RoleGrant[] });
      } else {
        updateCompanyRole({ ...values, acl: acls as unknown as RoleGrant[] });
      }
    },
  });

  return (
    <VStack className="gap-4">
      <HStack className="w-full gap-4">
        <Box className="bg-white  rounded-md shadow-2xl w-full p-8">
          {/* <Autocomplete
          id="asynchronous-demo"
          className="mb-4 "
          sx={{ width: 300 }}
          filterOptions={(x) => x}
          renderOption={(props, option, state) =>
            <li className="p-4 " {...props}>{option.fullName}<span className="ml-2 text-xxs">
            {option.emailId}</span></li>}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={(option) => option.fullName}
          options={users}
          loading={isFetching}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select User"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isFetching ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            >
            </TextField>
          )}
        /> */}
          <Autocomplete
            id="asynchronous-demo"
            className="mb-4 "
            sx={{ width: 300 }}
            filterOptions={(x) => x}
            renderOption={(props, option, state) => (
              <li className="p-4 " {...props}>
                {option.name}
              </li>
            )}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option.name}
            options={roles}
            loading={isFetching}
            onChange={(e, value, reson) => {
              if (!value) return;
              const role = roles.find((r) => r.id === value.id);
              const grants = role?.acl;
              if (!grants) return;
              const acls = generateAclsFromGrants(standardAclList, grants);
              setAcls(acls);
              formik.setFieldValue("name", role.name);
              formik.setFieldValue("id", role.id);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Role"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {isFetching ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              ></TextField>
            )}
          />
          <Input
            type="text"
            className="w-96"
            {...formik.getFieldProps("name")}
          />
          <Roles acls={acls} setAcls={setAcls} />
          <PrimaryCTAButton
            type="submit"
            event-name="Save Roles"
            className="w-96"
            onClick={() => formik.handleSubmit()}
          >
            Save
          </PrimaryCTAButton>
        </Box>
      </HStack>
    </VStack>
  );
}

export function UserManager() {
  const { data: _standardAclList } = useStandardAcls();
  const standardAclList = _standardAclList || [];
  const [searchText, setSearchText] = useState("");
  const { data: _users, isFetching } = useCompanyUsers();
  const users = _users || [];
  const { data: _roles } = useCompanyRoles();
  const roles = [...(_roles || []), { name: "Create New", id: 0, acl: [] }];
  const { mutate: assignRoleToUser } = useAssignRoleToUser();
  const { mutate: createCustomRoleForUser } = useCreateCustomRoleForUser();

  const [emailId, setEmailId] = useState("");
  const [debouncedEmailId, ___] = useDebounce(emailId, 200);
  const {
    data: user,
    refetch,
    isFetching: isFetchingUser,
  } = useGetUser(debouncedEmailId);
  const [acls, setAcls] = useState<Acl[]>([]);
  const roleInputRef = useRef(null) as any;

  const formik = useFormik({
    initialValues: {
      name: "",
      id: 0,
      acl: [] as RoleGrant[],
    },
    onSubmit: (roleInput) => {
      if (!user?.id) return;
      if (roleInput.id === 0) {
        createCustomRoleForUser({
          userId: Number(user.id),
          acl: acls as unknown as RoleGrant[],
        });
      } else {
        assignRoleToUser({ userId: Number(user.id), roleId: roleInput.id });
      }
    },
  });

  useEffect(() => {
    if (!user?.role?.id) return;
    const role = roles.find((r) => r.id === user?.role?.id);
    const grants = role?.acl;
    if (!grants) return;
    const acls = generateAclsFromGrants(standardAclList, grants);
    setAcls(acls);
    formik.setFieldValue("name", role.name);
    formik.setFieldValue("id", role.id);
    setSearchText(role.name);
  }, [user]);

  return (
    <VStack className="gap-4">
      <HStack className="w-full gap-4">
        <Box className="bg-white  rounded-md shadow-2xl w-full p-8">
          <HStack className="items-center">
            <Input
              placeholder="user email"
              type="text"
              className="w-96 mr-4 mb-4"
              onChange={(e) => {
                setEmailId(e.target.value);
              }}
            />
            {isFetchingUser ? (
              <CircularProgress color="inherit" size={24} />
            ) : user && Yup.string().email().isValidSync(emailId) && emailId ? (
              <Icon
                icon="fluent-mdl2:completed-solid"
                height={24}
                className="text-green-600"
              />
            ) : (
              <Icon
                icon="iconoir:file-not-found"
                height={24}
                className="text-red-600"
              />
            )}
            <></>
          </HStack>
          {/* <Autocomplete
            id="asynchronous-demo"
            className="mb-4 "
            sx={{ width: 300 }}
            renderOption={(props, option, state) => (
              <li className="p-4 " {...props}>
                {option.fullName}
                <span className="ml-2 text-xxs">{option.emailId}</span>
              </li>
            )}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option.fullName}
            filterOptions={(options, state) =>
              options.filter((option) => option?.emailId?.includes(state.inputValue))}
            options={users}
            loading={isFetching}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select User"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {isFetching ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              ></TextField>
            )}
          /> */}
          <Autocomplete
            id="role"
            className="mb-4 "
            sx={{ width: 300 }}
            filterOptions={(x) => x}
            renderOption={(props, option, state) => (
              <li className="p-4 " {...props}>
                {option.name}
              </li>
            )}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option.name}
            options={roles}
            loading={isFetching}
            onChange={(e, value, reson) => {
              if (!value) return;
              const role = roles.find((r) => r.id === value.id);
              const grants = role?.acl;
              if (!grants) return;
              const acls = generateAclsFromGrants(standardAclList, grants);
              setAcls(acls);
              formik.setFieldValue("name", role.name);
              formik.setFieldValue("id", role.id);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Role"
                value={searchText}
                ref={roleInputRef}
                onChange={(e) => setSearchText(e.target.value)}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {isFetching ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              ></TextField>
            )}
          />
          <Roles acls={acls} setAcls={setAcls} />
          <PrimaryCTAButton
            event-name="Save User Role"
            type="submit"
            className="w-96"
            onClick={() => formik.handleSubmit()}
          >
            Save
          </PrimaryCTAButton>
        </Box>
      </HStack>
    </VStack>
  );
}

type RolesPropType = {
  acls: Acl[];
  setAcls: React.Dispatch<React.SetStateAction<Acl[]>>;
};

function Roles(props: RolesPropType) {
  const { data: _standardAclList } = useStandardAcls();
  const standardAclList = _standardAclList || [];
  const acls = props.acls as Acl[];
  const setAcls = props.setAcls;
  const resources = Object.entries(
    _.groupBy(standardAclList, (r) => r.resourceName || r.resource)
  );
  return (
    <>
      {resources.map(([resourceName, actions]) => (
        <div key={resourceName} className="mb-8">
          <h6 className="font-semibold text-sm mb-2">{resourceName}</h6>
          <HStack className="gap-4">
            {actions.map((action) => (
              <div key={action.action} className="pl-4">
                <CheckBoxWithLabel
                  label={action.actionName}
                  labelclassname="pl-2"
                  checked={Boolean(
                    acls.find(
                      (acl) =>
                        (acl.action === "*" && acl.resource === "*") ||
                        (acl.action === "*" &&
                          acl.resource === action.resource) ||
                        (acl.action === action.action &&
                          acl.resource === action.resource)
                    )
                  )}
                  onChange={handleAclsChange(action, acls, setAcls)}
                />
              </div>
            ))}
          </HStack>
        </div>
      ))}
    </>
  );
}

function handleAclsChange(
  action: Acl,
  acls: Acl[],
  setAcls: React.Dispatch<React.SetStateAction<Acl[]>>
) {
  return () => {
    if (
      acls.find(
        (acl) =>
          acl.action === action.action && acl.resource === action.resource
      )
    ) {
      setAcls((prev) =>
        prev.filter(
          (acl) =>
            !(acl.action === action.action && acl.resource === action.resource)
        )
      );
    } else {
      setAcls((prev) => [
        ...prev,
        {
          action: action.action,
          actionName: action.actionName,
          resource: action.resource,
          resourceName: action.resourceName,
          attributes: action.attributes,
        },
      ]);
    }
  };
}

function generateAclsFromGrants(standardAclList: Acl[], grants: RoleGrant[]) {
  const resourceMap = new Map<string, string>();
  const actionMap = new Map<string, string>();
  for (const acl of standardAclList) {
    resourceMap.set(acl.resource, acl.resourceName);
    actionMap.set(acl.action, acl.actionName);
  }
  return grants.map((grant) => ({
    ...grant,
    resourceName: resourceMap.get(grant.resource),
    actionName: actionMap.get(grant.action),
  })) as unknown as Acl[];
}
