import { Bar, Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  ChartType,
} from "chart.js";
import _ from "lodash";
import pattern from "patternomaly";
import { Box } from "../../components/utils";
import { prefrenceColorList } from "../../constants/ShareholderSecurityTypes";
import { getCurrencyType } from "../../utils/currencyFormatter";
import { formatToReadableNumber } from "../../utils/currencyRoboto";

ChartJS.register(ArcElement, Tooltip, Legend);

export default function PrePostPieChart({
  labels,
  values,
  grayedOutIndex,
  header,
}: {
  labels: string[];
  values: number[];
  grayedOutIndex?: number[];
  header: string;
}) {
  const currencyType = getCurrencyType();

  const exitedTextList: number[] = [];
  const plugins = [
    {
      id: "htmlLegend",
      afterUpdate(chart: ChartJS) {
        const items =
          chart.options.plugins?.legend?.labels?.generateLabels?.(chart);
        const ul = document.createElement("ul");
        ul.style.display = "flex";
        ul.style.flexWrap = "wrap";
        ul.style.listStyleType = "none"; // Remove default list styles

        items?.forEach((item, index) => {
          const li = document.createElement("li");
          li.style.alignItems = "center";
          li.style.cursor = "pointer";
          li.style.display = "flex";
          li.style.flexDirection = "row";
          li.style.paddingBottom = "5px";
          li.style.marginRight = "10px"; // Add margin between list items
          li.style.marginBottom = "10px"; // Add margin between rows

          const boxSpan = document.createElement("span");
          const fillColor = index % prefrenceColorList.length;
          const backgroundColor = grayedOutIndex?.includes(index + 1)
            ? pattern.draw("line", prefrenceColorList[fillColor], "white", 5)
            : prefrenceColorList[fillColor];

          if (typeof backgroundColor === "string") {
            boxSpan.style.background = backgroundColor;
          } else {
            boxSpan.style.background = prefrenceColorList[fillColor];
          }

          boxSpan.style.borderColor = item.strokeStyle?.toString()!;
          boxSpan.style.borderWidth = `${item.lineWidth || 1}"px"`;
          boxSpan.style.borderRadius = "5px";
          boxSpan.style.display = "inline-block";
          boxSpan.style.height = "15px";
          boxSpan.style.width = "15px";
          boxSpan.style.marginRight = "5px"; // Add margin between box and text

          const textContainer = document.createElement("p");
          textContainer.style.color = item.fontColor?.toString()!;
          textContainer.style.margin = "0";
          textContainer.style.padding = "0";
          textContainer.style.textDecoration = item.hidden
            ? "line-through"
            : "";
          textContainer.appendChild(document.createTextNode(item.text));

          li.appendChild(boxSpan);
          li.appendChild(textContainer);

          li.onclick = () => {
            const i = (item as any).index;
            const exitIndex = exitedTextList.indexOf(i);
            if (exitIndex !== -1) exitedTextList.splice(exitIndex, 1);
            else exitedTextList.push(i);
            const dataset = chart.data.datasets[0].data;
            let exitedCount = 0;
            exitedTextList.forEach((e) => {
              exitedCount += (dataset[e] as number) || 0;
            });

            chart.toggleDataVisibility(index);
            item.hidden = !item.hidden;
            chart.update();
          };

          ul.appendChild(li);
        });

        const jsLegend = document.getElementById(
          `chart-legend- ${header.replaceAll(" ", "")}`
        );
        if (jsLegend) {
          jsLegend.innerHTML = "";
          jsLegend!.appendChild(ul);
        }
      },
    },
  ];

  const options: any = {
    rotation: 0,
    circumference: 360,
    maintainAspectRatio: false,
    responsive: true,
    radius: "100%",
    plugins: {
      tooltip: {
        enabled: true,
        usePointStyle: true,
        bodyFont: {
          weight: "bold",
          size: 14,
        },
        callbacks: {
          afterLabel: (context: any) =>
            grayedOutIndex?.includes(context.dataIndex)
              ? "(New Shareholder)"
              : "",
          label: (context: any) => {
            const label = context.label || "";
            const value = context.raw || 0;
            return grayedOutIndex?.includes(context.dataIndex + 1)
              ? `${label}: ${formatToReadableNumber({
                  value,
                  format: currencyType,
                })}`
              : `${label}: ${formatToReadableNumber({
                  value,
                  format: currencyType,
                })}`;
          },
        },
        titleColor: "#464E5F",
        bodyColor: "#464E5F",
        footerColor: "#464E5F",
        footerFont: {
          weight: "bold",
          size: 10,
        },
        displayColors: true,
        backgroundColor: "#FFFFFF",
      },
      legend: {
        display: false,
        position: "bottom",
        labels: {
          boxWidth: 10,
        },
      },
    },
  };

  const data = {
    maintainAspectRatio: false,
    responsive: true,
    labels,
    datasets: [
      {
        data: values,
        backgroundColor: values.map((element, index) => {
          const colorIndex = index % prefrenceColorList.length;
          return grayedOutIndex?.includes(index)
            ? pattern.draw("line", prefrenceColorList[colorIndex], "white", 10)
            : prefrenceColorList[colorIndex];
        }),
        borderWidth: 0.8,
        borderRadius: 5,
      },
    ],
  };
  return (
    <Box className="w-full h-64 py-2 px-4 align-center">
      {options ? (
        <>
          <Pie options={options} data={data} plugins={plugins} />
          <div
            className="h-20 mt-2 overflow-y-scroll scrollbar-hide"
            id={`chart-legend- ${header.replaceAll(" ", "")}`}
            style={{ overflowY: "auto", scrollbarWidth: "none" }}
          ></div>
        </>
      ) : (
        <p></p>
      )}
    </Box>
  );
}
