import { useFormikContext } from "formik";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { Select } from "../../components/shared/Select";
import { HStack, VStack, Error } from "../../components/utils";
import { Input, Label } from "../../components/shared/InputField";
import { EditSecurityModel } from "../../types/SecurityModel";
import {
  Security,
  SecurityTextDetail,
} from "../../constants/SecurityConstantContent";
import SecurityDescriptionText from "./SecurityDescriptionText";
import { classNames } from "../../utils/string";
import { useSecurityOverviewDetails } from "../../queries/securities";

const AddConversionDetails = ({
  keepOpen,
  viewOnly,
}: {
  keepOpen: boolean;
  viewOnly: boolean;
}) => {
  const formikk = useFormikContext<EditSecurityModel>();
  const { values, errors, touched, getFieldProps } = formikk;
  const { isPlaceholderData, data: _securityDetail } =
    useSecurityOverviewDetails();
  return (
    <Disclosure as="div" key="Conversion" className="" defaultOpen={keepOpen}>
      {({ open }) => (
        <>
          <dt
            className={`text-lg border ${
              open ? "border-b-0 rounded-t-md" : "rounded-md"
            } p-4`}
          >
            <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-400">
              <span className="font-medium text-slate-900 text-base">
                Conversion
              </span>
              <span className="ml-6 flex h-7 items-center">
                <ChevronDownIcon
                  className={classNames(
                    open ? "-rotate-180" : "rotate-0",
                    "h-6 w-6 transform text-slate-500"
                  )}
                  aria-hidden="true"
                />
              </span>
            </Disclosure.Button>
          </dt>
          <Disclosure.Panel as="dd" className="border p-4 rounded-b-md">
            <VStack className="gap-8">
              <SecurityDescriptionText
                description={SecurityTextDetail.Conversion}
              />

              {values.securityType === Security.Note && (
                <HStack className="gap-4 md:flex-row flex-col justify-between">
                  <VStack className="flex-1 justify-start">
                    <Label className="text-slate-900 text-sm font-medium whitespace-nowrap mr-4">
                      Conversion Security
                    </Label>
                    <VStack>
                      <Select
                        options={[
                          ...(_securityDetail?.tableDetails.map(
                            (ele) => ele?.className ?? ""
                          ) ?? []),
                          "Next Round Summary",
                        ]}
                        {...getFieldProps("conversionSecurity")}
                        disabled={viewOnly}
                      />
                      {touched?.conversionSecurity &&
                        errors?.conversionSecurity && (
                          <Error text={errors?.conversionSecurity} />
                        )}
                      {touched.conversionSecurity &&
                        errors?.conversionSecurity && (
                          <Error text={errors?.conversionSecurity} />
                        )}
                    </VStack>
                  </VStack>
                  <div className="flex-1"></div>
                </HStack>
              )}
              {(values.securityType === Security.CCPS ||
                values.securityType === Security.OCPS ||
                values.securityType === Security.CCD ||
                values.securityType === Security.OCD) && (
                <HStack className="gap-4 md:flex-row flex-col justify-between">
                  <VStack className="flex-1 justify-start">
                    <Label className="text-slate-900 text-sm font-medium whitespace-nowrap mr-4">
                      Conversion Ratio
                    </Label>
                    <VStack>
                      <HStack className="items-center">
                        <Label className="text-slate-900 text-sm font-medium whitespace-nowrap mr-4">
                          1 :
                        </Label>

                        <Input
                          type="number"
                          placeholder="Enter Conversion Ratio"
                          {...getFieldProps("conversionRatio")}
                          disabled={viewOnly}
                        />
                      </HStack>

                      {touched.conversionRatio && errors?.conversionRatio && (
                        <Error text={errors?.conversionRatio} />
                      )}
                    </VStack>
                  </VStack>

                  <VStack className="flex-1 justify-start">
                    <Label className="text-slate-900 text-sm font-medium whitespace-nowrap mr-4">
                      Conversion period - Max (Years)
                    </Label>
                    <VStack>
                      <Input
                        type="number"
                        placeholder="Enter Conversion Period"
                        {...getFieldProps("conversionPeriod")}
                        disabled={viewOnly}
                      />
                      {touched.conversionPeriod && errors?.conversionPeriod && (
                        <Error text={errors?.conversionPeriod} />
                      )}
                    </VStack>
                  </VStack>
                </HStack>
              )}

              <HStack className="gap-4 md:flex-row flex-col justify-between">
                {values.securityType === Security.OCPS ||
                values.securityType === Security.Note ||
                values.securityType === Security.CCPS ||
                values.securityType === Security.CCD ||
                values.securityType === Security.OCD ? (
                  <VStack className="flex-1 justify-start">
                    <Label className="text-slate-900 text-sm font-medium whitespace-nowrap mr-4">
                      Conversion Term
                    </Label>
                    <VStack>
                      <textarea
                        rows={2}
                        name="conversionTerm"
                        id="conversionTerm"
                        placeholder="Enter the Conversion Terms"
                        className={`bg-slate-50 block w-full p-4 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ${
                          viewOnly ? "cursor-not-allowed" : ""
                        }`}
                        defaultValue=""
                        value={formikk.values.conversionTerm ?? ""}
                        onChange={(conversionTerm: any) => {
                          formikk.setFieldValue(
                            "conversionTerm",
                            conversionTerm.target.value
                          );
                        }}
                        disabled={viewOnly}
                      />

                      {touched.conversionTerm && errors?.conversionTerm && (
                        <Error text={errors?.conversionTerm} />
                      )}
                    </VStack>
                  </VStack>
                ) : (
                  <div className="flex-1"></div>
                )}
              </HStack>
            </VStack>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default AddConversionDetails;
