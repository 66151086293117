import { useRef } from "react";
import _ from "lodash";
import { useNavigate } from "react-router";
import { Box, HStack, VStack } from "../../components/utils";
import {
  tableEntryStyle,
  tableFirstColStyle,
} from "../../components/TableComponent";

import { limitString } from "../../utils/string";
import { getSecurityColor } from "../../utils/historicUtilities";
import { formatToReadableNumber } from "../../utils/currencyRoboto";
import RoundTag from "../../shared/RoundTag";
import { checkVoid } from "../../utils/transactionUtils";
import ShareholderIdentifier from "../../shared/ShareholderIdentifier";
import { formatDisplayDate } from "../../utils/date";

export function SecurityClassRender(data: any) {
  return (
    <td className="pt-2 whitespace-nowrap">
      {data.data.securityClass?.length > 24 ? (
        <p className={`${tableFirstColStyle}`}>
          {limitString(data.data?.securityClass || "", 24)}
        </p>
      ) : (
        <p className={`${tableFirstColStyle}`}>
          {limitString(data.data?.securityClass || "", 24)}
        </p>
      )}

      <p className="text-left font-semibold text-xxs1 text-[#464E5F]/[0.5]">
        {data.data.icin}
      </p>
      {data.data.isConverted && (
        <p className="w-16 px-1 mt-2 text-red-500 border border-red-500 rounded-sm font-xxs">
          Converted
        </p>
      )}
    </td>
  );
}

export function AmountInvestedRender(data: any) {
  return (
    <div className="pt-2 whitespace-nowrap">
      <p className={tableEntryStyle}>
        {data.data.type?.toLowerCase() === "forfeited" &&
        data.data.amountInvested === 0 ? (
          <span>-</span>
        ) : (
          formatToReadableNumber({
            value: data.data?.amountInvested,
            format: data.data.currencyType,
          })
        )}
      </p>
    </div>
  );
}

export function RoundsRender(data: any) {
  const navigate = useNavigate();
  const onClickNavigate = (url: string) => navigate(url);
  return (
    <div className="pt-1 whitespace-nowrap">
      {!checkVoid(data.data.rounds) ? (
        <RoundTag
          roundNames={data.data.rounds || []}
          navigate={data.data.cin ? () => {} : onClickNavigate}
        />
      ) : (
        <p className={`${tableEntryStyle} pt-1`}>-</p>
      )}
    </div>
  );
}

export function ConversionRatioRender(data: any) {
  return (
    <div className="pt-2 whitespace-nowrap">
      <p className={tableEntryStyle}>
        {" "}
        {data.data.conversionRatio > 0
          ? `1 : ${data.data.conversionRatio}`
          : "-"}
      </p>
    </div>
  );
}

export function NewConversionRatioRender(data: any) {
  return (
    <div className="pt-2 whitespace-nowrap">
      <p className={tableEntryStyle}>
        {" "}
        {data.data.newConversionRatio
          ? `1 : ${Number(
              data.data.newConversionRatio.split(":")[1]
            ).toLocaleString(data.data.currencyType)}`
          : "-"}
      </p>
    </div>
  );
}

export function MethodRender(data: any) {
  const navigate = useNavigate();
  const onClickNavigate = (url: string) => navigate(url);
  return data.data?.method ? (
    <div className="pt-2 whitespace-nowrap">
      <p className={tableEntryStyle}>{data.data?.method}</p>
    </div>
  ) : (
    <div>-</div>
  );
}

export function ValuationAppliedRender(data: any) {
  const navigate = useNavigate();
  const onClickNavigate = (url: string) => navigate(url);
  return (
    <div className="pt-2 whitespace-nowrap">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.data?.valuationApplied,
          format: data.data.currencyType,
        })}
      </p>
    </div>
  );
}

export function HoldingPercentageRender(data: any) {
  return (
    <div className="pt-3 whitespace-nowrap">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.data?.holdingPercentage || 0,
          format: data.data.currencyType,
        })}
      </p>
    </div>
  );
}
