import { lazy, Suspense } from "react";
import Box from "@mui/material/Box";
import { XMarkIcon } from "@heroicons/react/24/outline";

const PdfDocument = lazy(() => import("./PdfDocument"));

function LazyPdfDocument({
  url,
  name,
  onClose,
}: {
  url: string;
  name?: string;
  onClose: Function;
}) {
  return (
    <div>
      <Box className="px-9 text-lg font-medium border-b py-7">
        <h6 className="flex justify-between">
          <p className="text-lg truncate w-96 font-medium text-[#464E5F] mr-5 pl-4">
            {name}
          </p>
          <XMarkIcon
            className="cursor-pointer h-5 font-medium"
            onClick={() => onClose()}
          />
        </h6>
      </Box>
      <div className="bg-white shadow rounded-md">
        <Suspense fallback={<div>Document loading...</div>}>
          <PdfDocument url={url} name={name} />
        </Suspense>
      </div>
    </div>
  );
}

export default LazyPdfDocument;
