import create from "zustand";
import {
  PrimaryTransactionDataModel,
  SecondaryTransactionDataModel,
} from "../types/quickRound";

type TransactionState = {
  transaction: PrimaryTransactionDataModel[];
  secondary: SecondaryTransactionDataModel[];
  setTransaction: (transaction: PrimaryTransactionDataModel) => void;
  setAllTransaction: (transaction: PrimaryTransactionDataModel[]) => void;
  removeTransaction: (transaction: PrimaryTransactionDataModel) => void;
  setSecondary: (transaction: SecondaryTransactionDataModel) => void;
  setAllSecondary: (transaction: SecondaryTransactionDataModel[]) => void;
  removeSecondary: (transaction: SecondaryTransactionDataModel) => void;
  reset: () => void;
};

const initialState = {
  transaction: [],
  secondary: [],
};

const transactionStore = create<TransactionState>((set) => ({
  ...initialState,
  setTransaction: (transaction) => {
    set((state) => ({ transaction: [...state.transaction, transaction] }));
  },
  setAllTransaction: (transaction) => {
    set((state) => ({ transaction }));
  },
  removeTransaction: (transaction) => {
    set((state) => {
      const array = [...state.transaction]; // make a separate copy of the array
      const index = array.indexOf(transaction);
      if (index !== -1) {
        array.splice(index, 1);
      }

      return { transaction: array };
    });
  },
  setSecondary: (secondary) => {
    set((state) => ({ secondary: [...state.secondary, secondary] }));
  },
  setAllSecondary: (secondary) => {
    set((state) => ({ secondary }));
  },
  removeSecondary: (secondary) => {
    set((state) => {
      const array = [...state.secondary]; // make a separate copy of the array
      const index = array.indexOf(secondary);
      if (index !== -1) {
        array.splice(index, 1);
      }

      return { secondary: array };
    });
  },
  reset: () => {
    set((state) => initialState);
  },
}));

export const useTransactionStore = transactionStore;
