import { Badge } from "@mui/material";
import Tooltip from "../../components/shared/Tooltip";
import { HStack, VStack } from "../../components/utils";

export const ExtraConditions = ({
  condition,
  expanded,
  currentTab,
  fallAwayRightText,
  isFallAwayRight,
}: {
  condition: string;
  expanded: boolean;
  currentTab?: string;
  fallAwayRightText?: string;
  isFallAwayRight?: boolean;
}) => {
  const conditionText =
    condition === "No Restriction" ||
    condition === "Lockin" ||
    condition === "Restricted" ||
    condition === "Reserved Shares" ||
    condition === "Drag Rights" ||
    condition === "Exit" ||
    condition === "Yes" ||
    condition === "No"
      ? condition
      : "Conditional";

  let className = "";
  switch (conditionText) {
    case "Yes":
      className =
        currentTab === "Rights"
          ? expanded
            ? "text-[#ffffff] bg-[#37A012]  border-[#ffffff]"
            : "text-[#37A012] bg-[#37A012] bg-opacity-5 border-[#37A012]"
          : expanded
          ? "text-[#ffffff] bg-[#B92020] border-[#ffffff]"
          : "text-[#B92020] bg-[#B92020] bg-opacity-5 border-[#B92020]";
      break;
    case "No":
      className =
        currentTab === "Rights"
          ? expanded
            ? "text-[#ffffff] bg-[#B92020] border-[#ffffff]"
            : "text-[#B92020] bg-[#B92020] bg-opacity-5 border-[#B92020]"
          : expanded
          ? "text-[#ffffff] bg-[#37A012]  border-[#ffffff]"
          : "text-[#37A012] bg-[#37A012] bg-opacity-5 border-[#37A012]";
      break;
    case "No Restriction":
      className = expanded
        ? "text-[#ffffff] bg-[#37A012]  border-[#ffffff]"
        : "text-[#37A012] bg-[#37A012] bg-opacity-5 border-[#37A012]";
      break;
    case "Restricted":
      className = expanded
        ? "text-[#ffffff] bg-[#B92020] border-[#ffffff]"
        : "text-[#B92020] bg-[#B92020] bg-opacity-5 border-[#B92020]";
      break;
    default:
      className =
        currentTab === "Rights"
          ? expanded
            ? "text-[#ffffff] bg-[#37A012]  border-[#ffffff]"
            : "text-[#37A012] bg-[#37A012] bg-opacity-5 border-[#37A012]"
          : expanded
          ? "text-[#ffffff] bg-[#B92020] border-[#ffffff]"
          : "text-[#B92020] bg-[#B92020] bg-opacity-5 border-[#B92020]";
      break;
  }

  return (
    <HStack>
      <div>
        <p className={`${className} border px-2 min-w-min rounded-sm`}>
          {conditionText === "Conditional" ? "Yes" : conditionText}
        </p>
      </div>

      <VStack className="justify-between">
        <Badge
          badgeContent={
            <Tooltip text={fallAwayRightText || ""}>
              <span>F</span>
            </Tooltip>
          }
          invisible={!isFallAwayRight || false}
          variant="standard"
          color="secondary"
          sx={{
            "& .MuiBadge-badge": {
              fontSize: 8,
              height: 10,
              minWidth: 4,
              maxWidth: 4,
            },
          }}
        ></Badge>
        <Badge
          badgeContent={
            <Tooltip text={"Conditional"}>
              <span>!</span>
            </Tooltip>
          }
          invisible={conditionText !== "Conditional" || false}
          variant="standard"
          color="primary"
          sx={{
            "& .MuiBadge-badge": {
              fontSize: 8,
              height: 10,
              minWidth: 4,
              maxWidth: 4,
            },
          }}
        ></Badge>
      </VStack>
    </HStack>
  );
};

export const RightsTypeDisplay = ({ rightType }: { rightType: string }) => {
  let className = "";
  switch (rightType) {
    case "Buy":
      className = "text-[#37A012] bg-[#37A012] bg-opacity-10 ";
      break;
    case "Sell":
      className = "text-[#B92020] bg-[#B92020] bg-opacity-10 ";
      break;
    default:
      className = "text-[#37A012] bg-[#37A012] bg-opacity-10 ";
      break;
  }

  return (
    <p className={`text-center py-1.5 w-full ${className} `}>{rightType}</p>
  );
};

export const RightsTextDisplay = ({
  rightName,
  type,
  expanded,
}: {
  rightName: string;
  type: string;
  expanded: boolean;
}) => {
  let className = "";
  switch (type) {
    case "Rights":
      className = expanded
        ? "text-[#FFFFFF] bg-[#0718B0]  border-[#0718B0]"
        : "text-[#0718B0] bg-[#FFFFFF] bg-opacity-5 border-[#0718B0]";
      break;
    case "No Restriction":
      className = "text-[#37A012] bg-[#37A012] bg-opacity-5 border-[#37A012]";
      break;
    case "No":
      className = "text-[#B92020] bg-[#B92020] bg-opacity-5 border-[#B92020]";
      break;
    default:
      className = "text-[#B09507] bg-[#B09507] bg-opacity-5 border-[#B09507]";
      break;
  }

  return (
    <HStack className="items-center whitespace-nowrap">
      <p className={`${className} border px-2 min-w-min rounded-sm`}>
        {rightName}
      </p>
      <Tooltip text={rightName}>
        <p className="ml-1 px-1.5 text-xxs text-[#464E5F] border rounded-full border-[#464E5F]">
          i
        </p>
      </Tooltip>
    </HStack>
  );
};

export function EmptyShareHolderRightTable({ message }: { message: string }) {
  return (
    <div className="text-center border-t-2 border-dotted py-24">
      <svg
        className="mx-auto h-12 w-12 text-gray-400"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          vectorEffect="non-scaling-stroke"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
        />
      </svg>
      <h3 className="mt-2 text-sm font-medium text-gray-900">
        No Shareholder Rights Found
      </h3>
      <p className="mt-1 text-sm text-gray-500">{message}</p>
    </div>
  );
}
