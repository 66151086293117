import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useCaptableSignIn } from "../../queries/captableLogin";
import { clearCredentials, Error, VStack } from "../../components/utils";
import { SignInDetailsModel } from "../../types/CaptableLoginModel";
import { Input, Label } from "../../components/shared/InputField";
import { loginPageImageUrl } from "../../constants/LoginPageImage";
import { useAuthorizationStore, useAuthStore } from "../../store";
import { getUserContext } from "../../api/auth";
import useIsMobile from "../../utils/detectDevice";
import {
  ButtonSize,
  PrimaryCTAButton,
} from "../quickRound/CTAButtonComponents";

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Wrong email format")
    .required("Please enter valid email"),
  password: Yup.string().required("Password is required"),
});

const initialValues: SignInDetailsModel = {
  email: "",
  password: "",
};

export default function CaptableLoginPage() {
  const [loading, setLoading] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const { mutate: captableSignIn } = useCaptableSignIn();

  const imageUrl = loginPageImageUrl;
  const length = 3;
  let count = 0;
  const { setAuth } = useAuthStore();
  const { clearAuthorization } = useAuthorizationStore();

  useEffect(() => {
    //timer to change the login image on right side
    const intervalId = setInterval(() => {
      count++;
      setActiveIndex(count % length);
    }, 5000);
    return () => clearInterval(intervalId);
  }, []);

  const { isMobile } = useIsMobile(1025);

  return (
    <>
      {!isMobile ? (
        <div className="flex h-screen bg-white w-full">
          <div className="flex flex-col justify-between flex-1 px-4 pb-12 mx-auto sm:px-6 lg:flex-none  md:w-5/12 w-full">
            <div className="pt-[32px] ">
              <img
                className="h-12 mx-auto w-28"
                src={
                  "https://equion-dev.s3.us-west-2.amazonaws.com/logonew.svg"
                }
                alt="hissa_logo"
              />
            </div>
            <div className="w-full max-w-sm lg:w-96  mx-auto">
              <h2 className="text-center text-[28px] font-semibold tracking-tight text-[#181C32]">
                Sign In
              </h2>

              <div className="mt-8">
                <div className="mt-6">
                  <Formik
                    enableReinitialize={true}
                    validationSchema={loginSchema}
                    initialValues={initialValues}
                    onSubmit={(values) => {
                      setLoading(true);
                      clearAuthorization();
                      clearCredentials();
                      captableSignIn(
                        {
                          email: values.email,
                          password: values.password,
                        },
                        {
                          onSuccess: (data) => {
                            setLoading(false);

                            if (data.data.token) {
                              localStorage.setItem(
                                "accessToken",
                                data.data.token
                              );

                              setAuth({
                                isAuthenticated: false,
                                accessToken: data.data.token,
                                user: undefined,
                              });
                              getUserContext()
                                .then((data) => {
                                  if (data.companyId) {
                                    localStorage.setItem(
                                      "companyId",
                                      data.companyId
                                    );
                                    setAuth({
                                      isAuthenticated: true,
                                      companyId: data.companyId,
                                      user: undefined,
                                    });
                                    navigate("/landing/dashboard");
                                  } else {
                                    toast("Error Logging In", {
                                      type: "error",
                                      autoClose: 2000,
                                    });
                                  }
                                })
                                .catch((err) => {
                                  if (err.response.status === 404) {
                                    setAuth({
                                      isAuthenticated: true,
                                      user: undefined,
                                    });
                                    navigate("/excelOnboarding");
                                  } else {
                                    toast("Error Logging In", {
                                      type: "error",
                                      autoClose: 2000,
                                    });
                                  }
                                });
                            }
                          },
                          onError: (err: any) => {
                            setLoading(false);
                            toast(err.response.data.errors[0], {
                              type: "error",
                              autoClose: 2000,
                            });
                          },
                        }
                      );
                    }}
                  >
                    {(formik) => (
                      <Form>
                        <VStack className="gap-4">
                          <div>
                            <Label htmlFor="email">Email</Label>
                            <Input
                              id="email"
                              type="email"
                              autoComplete="email"
                              {...formik.getFieldProps(`email`)}
                            />
                            {formik.touched?.email && formik.errors?.email && (
                              <Error text={formik.errors?.email} />
                            )}
                          </div>
                          <div>
                            <Label htmlFor="password">Password</Label>

                            <Input
                              id="password"
                              type={`${showPassword ? "text" : "password"}`}
                              autoComplete="current-password"
                              {...formik.getFieldProps(`password`)}
                            />

                            {formik.touched?.password &&
                              formik.errors?.password && (
                                <Error text={formik.errors?.password} />
                              )}
                          </div>
                          <div className="relative flex items-start">
                            <div className="flex h-6 items-center">
                              <input
                                id="comments"
                                aria-describedby="comments-description"
                                name="comments"
                                type="checkbox"
                                onClick={() => {
                                  setShowPassword(!showPassword);
                                }}
                              />
                            </div>
                            <div className="ml-3 text-sm leading-6">
                              <label
                                htmlFor="comments"
                                className="font-medium text-gray-900"
                              >
                                Show Password
                              </label>
                            </div>
                          </div>
                          <div className="flex items-center justify-between">
                            <div
                              event-name="Register User"
                              onClick={() => {
                                navigate("/signUp");
                              }}
                              className="text-sm font-semibold text-[#181C32] cursor-pointer"
                            >
                              Register User
                            </div>
                            <div
                              event-name="Forgot Password"
                              onClick={() => {
                                navigate("/forgotpassword");
                              }}
                              className="text-sm font-semibold text-[#EF5B2D] cursor-pointer"
                            >
                              Forgot Password?
                            </div>
                          </div>

                          <div className="mx-auto mt-16">
                            <PrimaryCTAButton
                              event-name="Sign In button"
                              buttonSize={ButtonSize.MEDIUM}
                              loading={loading}
                              type="submit"
                            >
                              Sign In
                            </PrimaryCTAButton>
                          </div>
                        </VStack>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
            <div></div>
          </div>
          <VStack className="md:w-7/12 md:visible invisible bg-gradient-to-tl from-[#030093] to-[#2E4D9B]/[.54] justify-evenly">
            <VStack className="gap-8">
              <img
                className="inset-0 md:visible invisible object-cover mx-auto w-fit px-32"
                src={imageUrl[activeIndex]}
                alt=""
              />

              {activeIndex === 0 ? (
                <div className="px-10 pb-10 text-2xl font-semibold text-center text-white">
                  Elevate your equity management with{" "}
                  <span className="text-orange-501">Hissa™</span> !
                </div>
              ) : activeIndex === 1 ? (
                <div className="px-10 pb-10 text-2xl font-semibold text-center text-white">
                  <span className="text-orange-501">Hissa™</span> takes the
                  chaos out of the Cap Table!
                </div>
              ) : (
                <div className="px-10 pb-10 text-2xl font-semibold text-center text-white">
                  Value creation through Stock Options just got easier with{" "}
                  <span className="text-orange-501">Hissa™</span> !
                </div>
              )}
            </VStack>
          </VStack>
        </div>
      ) : (
        <div className="flex h-screen bg-white w-full">
          <div className="flex flex-col justify-between flex-1 px-4 pb-12 mx-auto sm:px-6 lg:flex-none w-full">
            <div className="pt-[32px] ">
              <img
                className="h-12 mx-auto w-28"
                src={
                  "https://equion-dev.s3.us-west-2.amazonaws.com/logonew.svg"
                }
                alt="hissa_logo"
              />
            </div>
            <div className="w-full max-w-sm lg:w-96 border border-gray-200 px-4 py-8 rounded-md shadow-box mx-auto">
              <h2 className="text-center text-[28px] font-semibold tracking-tight text-[#181C32]">
                Sign In
              </h2>

              <div className="mt-8">
                <div className="mt-6">
                  <Formik
                    enableReinitialize={true}
                    validationSchema={loginSchema}
                    initialValues={initialValues}
                    onSubmit={(values) => {
                      setLoading(true);
                      clearAuthorization();
                      clearCredentials();
                      captableSignIn(
                        {
                          email: values.email,
                          password: values.password,
                        },
                        {
                          onSuccess: (data) => {
                            setLoading(false);

                            if (data.data.token) {
                              localStorage.setItem(
                                "accessToken",
                                data.data.token
                              );
                              setAuth({
                                isAuthenticated: false,
                                accessToken: data.data.token,
                                user: undefined,
                              });
                              getUserContext()
                                .then((data) => {
                                  if (data.companyId) {
                                    localStorage.setItem(
                                      "companyId",
                                      data.companyId
                                    );
                                    setAuth({
                                      isAuthenticated: true,
                                      companyId: data.companyId,
                                      user: undefined,
                                    });
                                    navigate("/landing/dashboard");
                                  }
                                })
                                .catch((err) => {
                                  if (err.response.status === 404) {
                                    setAuth({
                                      isAuthenticated: true,
                                      user: undefined,
                                    });
                                    navigate("/excelOnboarding");
                                  } else {
                                    toast("Error Logging In", {
                                      type: "error",
                                      autoClose: 2000,
                                    });
                                  }
                                });
                            }
                          },
                          onError: (err: any) => {
                            setLoading(false);
                            toast(err.response.data.errors[0], {
                              type: "error",
                              autoClose: 2000,
                            });
                          },
                        }
                      );
                    }}
                  >
                    {(formik) => (
                      <Form>
                        <VStack className="gap-4">
                          <div>
                            <Label htmlFor="email">Email</Label>
                            <Input
                              id="email"
                              type="email"
                              autoComplete="email"
                              {...formik.getFieldProps(`email`)}
                            />
                            {formik.touched?.email && formik.errors?.email && (
                              <Error text={formik.errors?.email} />
                            )}
                          </div>
                          <div>
                            <Label
                              htmlFor="password"
                              className="block text-sm font-semibold text-[#181C32]"
                            >
                              Password
                            </Label>

                            <Input
                              id="password"
                              type={`${showPassword ? "text" : "password"}`}
                              autoComplete="current-password"
                              {...formik.getFieldProps(`password`)}
                            />

                            {formik.touched?.password &&
                              formik.errors?.password && (
                                <Error text={formik.errors?.password} />
                              )}
                          </div>
                          <div className="relative flex items-start">
                            <div className="flex h-6 items-center">
                              <input
                                id="comments"
                                aria-describedby="comments-description"
                                name="comments"
                                type="checkbox"
                                onClick={() => {
                                  setShowPassword(!showPassword);
                                }}
                              />
                            </div>
                            <div className="ml-3 text-sm leading-6">
                              <label
                                htmlFor="comments"
                                className="font-medium text-gray-900"
                              >
                                Show Password
                              </label>
                            </div>
                          </div>
                          <div className="flex items-center justify-between">
                            <div
                              event-name="Register User"
                              onClick={() => {
                                navigate("/signUp");
                              }}
                              className="text-sm font-semibold text-[#181C32] cursor-pointer"
                            >
                              Register User
                            </div>
                            <div
                              event-name="Forgot Password"
                              onClick={() => {
                                navigate("/forgotpassword");
                              }}
                              className="text-sm font-semibold text-[#EF5B2D] cursor-pointer"
                            >
                              Forgot Password?
                            </div>
                          </div>

                          <div className="mx-auto mt-16">
                            <PrimaryCTAButton
                              type="submit"
                              buttonSize={ButtonSize.MEDIUM}
                              loading={loading}
                              event-name="Sign In button"
                            >
                              Sign In
                            </PrimaryCTAButton>
                          </div>
                        </VStack>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      )}
    </>
  );
}
