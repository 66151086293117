import * as Yup from "yup";
import _ from "lodash";

const equityInputSchema = Yup.object({
  securityType: Yup.string().required().label("Security Type"),
  securityName: Yup.string().required().label("Security Name"),
  hasIsinNumber: Yup.string().required().label("Security Demated"),
  valuationCap: Yup.number()
    .when(["isPriced"], {
      is: (type: boolean) => type === false,
      then: Yup.number()
        .positive()
        .required()
        .label("Valuation Cap")
        .nullable(),
    })
    .nullable(),
  discount: Yup.number()
    .when(["isPriced"], {
      is: (type: boolean) => type === false,
      then: Yup.number()
        .positive()
        .required()
        .max(100)
        .label("Discount")
        .nullable(),
    })
    .nullable(),
  conversionMethod: Yup.string()
    .when(["isPriced"], {
      is: (type: boolean) => type === false,
      then: Yup.string().required().label("Conversion Method"),
    })
    .nullable(),
  cdslIsinNumber: Yup.string()
    .when(["hasIsinNumber"], {
      is: (type: string) => ["CDSL", "BOTH"].includes(type),
      then: Yup.string().required().label("CDSL ISIN NUMBER"),
    })
    .nullable(),
  nsdlIsinNumber: Yup.string()
    .when(["hasIsinNumber"], {
      is: (type: string) => ["NSDL", "BOTH"].includes(type),
      then: Yup.string().required().label("NSDL ISIN NUMBER"),
    })
    .nullable(),
});

const featureInputSchema = Yup.object({
  featureParValue: Yup.number()
    .when(["securityType"], {
      is: (securityType: string) =>
        ["Equity", "CCPS", "OCPS", "CCD", "OCD", "RPS", "NCD"].includes(
          securityType
        ),
      then: Yup.number().required().label("PAR").nullable(),
    })
    .nullable(),
  featureVotingRatio: Yup.string()
    .when(["securityType"], {
      is: (securityType: string) =>
        ["Equity", "CCPS", "OCPS", "CCD", "OCD", "RPS", "NCD"].includes(
          securityType
        ),
      then: Yup.string().required().label("Voting Ratio").nullable(),
    })
    .nullable(),
  featureNotePeriod: Yup.number()
    .when(["securityType"], {
      is: (securityType: string) => securityType === "Note",
      then: Yup.number().required().label("Period").nullable(),
    })
    .nullable(),
});

const interestInputSchema = Yup.object({
  interestType: Yup.string()
    .when(["securityType"], {
      is: (securityType: string) =>
        ["OCPS", "RPS", "CCD", "NCD", "Note"].includes(securityType),
      then: Yup.string().required().label("Interest Type").nullable(),
    })
    .nullable(),
  interestRate: Yup.number()
    .when(["securityType"], {
      is: (securityType: string) =>
        ["OCPS", "RPS", "CCD", "NCD", "Note"].includes(securityType),
      then: Yup.number()
        .positive()
        .required()
        .label("Interest Rate")
        .nullable(),
    })
    .nullable(),
  interestPeriod: Yup.string()
    .when(["securityType"], {
      is: (securityType: string) =>
        ["OCPS", "RPS", "CCD", "NCD", "Note"].includes(securityType),
      then: Yup.string().required().label("Interest Period").nullable(),
    })
    .nullable(),
  interestAppliesOn: Yup.string()
    .when(["securityType"], {
      is: (securityType: string) =>
        ["OCPS", "RPS", "CCD", "NCD", "Note"].includes(securityType),
      then: Yup.string().required().label("Interest Applies on").nullable(),
    })
    .nullable(),
});

const liquidationInputSchema = Yup.object({
  liquidationRank: Yup.number()
    .when(["securityType"], {
      is: (securityType: string) =>
        ["CCPS", "OCPS", "CCD", "OCD"].includes(securityType),
      then: Yup.number()
        .positive()
        .integer()
        .required()
        .label("Liquidation Rank")
        .nullable(),
    })
    .nullable(),
  liquidationInvestmentMultiple: Yup.string()
    .when(["securityType"], {
      is: (securityType: string) =>
        ["CCPS", "OCPS", "CCD", "OCD"].includes(securityType),
      then: Yup.string()
        .required()
        .label("Liquidation Investment Multiple")
        .nullable(),
    })
    .nullable(),
  liquidationParticipationCap: Yup.string()
    .when(["securityType"], {
      is: (securityType: string) =>
        ["CCPS", "OCPS", "CCD", "OCD"].includes(securityType),
      then: Yup.string()
        .required()
        .label("Liquidation Participation Cap")
        .nullable(),
    })
    .nullable(),
});

const conversionInputSchema = Yup.object({
  conversionSecurity: Yup.string()
    .when(["securityType"], {
      is: (securityType: string) => ["Note"].includes(securityType),
      then: Yup.string().required().label("Conversion Security").nullable(),
    })
    .nullable(),
  conversionPeriod: Yup.number()
    .when(["securityType"], {
      is: (securityType: string) =>
        ["CCPS", "OCD", "OCPS", "CCD"].includes(securityType),
      then: Yup.number()
        .positive()
        .integer()
        .required()
        .label("Conversion Period")
        .nullable(),
    })
    .nullable(),
  conversionRatio: Yup.string()
    .when(["securityType"], {
      is: (securityType: string) =>
        ["CCPS", "OCD", "OCPS", "CCD"].includes(securityType),
      then: Yup.string().required().label("Conversion Ratio").nullable(),
    })
    .nullable(),
});

const antiDilutionInputSchema = Yup.object({
  antiDilutionType: Yup.string()
    .when(["securityType"], {
      is: (securityType: string) =>
        ["CCPS", "CCD", "OCD"].includes(securityType),
      then: Yup.string().required().label("Anti Dilution Type"),
    })
    .nullable(),
});

const redemptionInputSchema = Yup.object({
  redemptionTerm: Yup.string()
    .when(["securityType"], {
      is: (securityType: string) =>
        ["OCPS", "RPS", "NCD", "Note"].includes(securityType),
      then: Yup.string().required().label("Redemption Term").nullable(),
    })
    .nullable(),
});

const adjustmentInputSchema = Yup.object({
  adjustmentTerm: Yup.string()
    .when(["securityType"], {
      is: (securityType: string) =>
        ["OCPS", "RPS", "NCD", "Note"].includes(securityType),
      then: Yup.string().required().label("Adjustment Term").nullable(),
    })
    .nullable(),
});

export const securityInputSchema = equityInputSchema
  .concat(featureInputSchema)
  .concat(interestInputSchema)
  .concat(liquidationInputSchema)
  .concat(conversionInputSchema)
  .concat(antiDilutionInputSchema)
  .concat(redemptionInputSchema)
  .concat(adjustmentInputSchema);
