import { useLocation, useNavigate } from "react-router";
import { useSelectedSecurityTypeStoreStore } from "../store/selectedSecurityTypeStore";
import { Select } from "../components/shared/Select";
import { Box } from "../components/utils";
import { sort } from "../utils/arrays";
import { BCHeaderType } from "../utils/interfaces/BCHeader";
import useIsMobile from "../utils/detectDevice";

const BCHeader = (props: BCHeaderType) => {
  const pathname = useLocation().pathname;
  const navigate = useNavigate();
  const {
    className,
    onClick,
    selectOption,
    bcTitle,
    bcMiddleTitle,
    bcSubTitle,
    buttonName,
    dropdown,
    selectedValue,
    token,
    darkMode,
    ...rest
  } = props;

  const securityType = useSelectedSecurityTypeStoreStore();

  const goBack = () => {
    if (
      pathname.includes("rights/new") ||
      pathname.includes("rights/shareholder")
    ) {
      navigate("/rights/overview");
    } else if (pathname.includes("inv/security")) {
      navigate(`/inv/security/${props.cin}/${props.token}`);
    } else if (pathname.includes("shareholders/individual")) {
      navigate("/shareholders/overview");
    } else if (pathname.includes("security/individual")) {
      navigate("/security/overview");
    } else if (pathname.includes("addNewSecurity")) {
      securityType.setSecurityType("All");
      navigate("/security/overview");
    } else if (pathname.includes("securityClass")) {
      securityType.setSecurityType("All");
      navigate("/security/overview");
    } else if (pathname.includes("termsheet")) {
      navigate("/termsheet/overview");
    } else if (pathname.includes("rights/overview")) {
      navigate("/transactions/allTransactions");
    } else if (pathname.includes("/modeling")) {
      navigate("/modeling/overview");
    } else if (pathname.includes("rounds")) {
      navigate(`/rounds/allTransactions`);
    } else if (pathname.includes("rights/article")) {
      navigate("/rights/shareholder");
    } else if (pathname.includes("inv/transaction")) {
      navigate(`/inv/transaction/allTransactions/${props.cin}/${props.token}`);
    } else if (pathname.includes("transaction"))
      navigate(`/transactions/allTransactions`);
    else if (pathname.includes("financials")) navigate(`/financials/overview`);
  };
  const { isMobile } = useIsMobile();
  return (
    <>
      <Box
        className={`flex ${
          isMobile
            ? "flex-col text-xs font-medium"
            : "font-medium text-base flex-row justify-between"
        }   text-gray-600 ${className}`}
      >
        <Box
          className={`${
            isMobile ? "" : "h-6"
          } flex flex-row items-baseline gap-2 pl-2 border-l-4 border-solid border-orange-501`}
        >
          <div
            className={`${
              darkMode
                ? "text-white"
                : bcSubTitle
                ? "cursor-pointer text-orange-501 hover:underline"
                : ""
            }`}
            navigation-name={`BreadCrum ${bcTitle}`}
            onClick={goBack}
          >
            {bcTitle}
          </div>
          {bcMiddleTitle && <Box>{bcMiddleTitle}</Box>}
          {bcSubTitle && (
            <>
              <Box className="font-semibold"> {" > "} </Box>
              <Box>
                {" "}
                <p>{bcSubTitle}</p>
              </Box>
            </>
          )}
        </Box>
        <Box className={`flex flex-row ${isMobile ? "mb-4 gap-2 " : ""}`}>
          {dropdown && dropdown.length > 0 ? (
            <Box className="bg-white rounded w-44">
              <Select
                className="bg-white w-44"
                value={selectedValue?.id}
                options={sort(dropdown || [], "name")}
                valueGetter={(o) => o.id}
                textGetter={(o) => `${o.name} `}
                onChange={selectOption}
              />
            </Box>
          ) : (
            ""
          )}
        </Box>
      </Box>
    </>
  );
};

export default BCHeader;
