export default function numToWords(
  num: number,
  locale: string = "en-IN"
): string {
  if (num === 0) return "zero";

  // Helper function to convert a number less than 1000 into words
  function convertHundreds(n: number): string {
    const ones = [
      "",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
      "Ten",
      "Eleven",
      "Twelve",
      "Thirteen",
      "Fourteen",
      "Fifteen",
      "Sixteen",
      "Seventeen",
      "Eighteen",
      "Nineteen",
    ];
    const tens = [
      "",
      "",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ];

    let word = "";
    if (n > 99) {
      word += `${ones[Math.floor(n / 100)]} Hundred `;
      n %= 100;
    }
    if (n > 19) {
      word += `${tens[Math.floor(n / 10)]} `;
      n %= 10;
    }
    word += ones[n];
    return word.trim();
  }

  let words = "";
  if (locale === "en-IN") {
    const crores = Math.floor(num / 10000000);
    num %= 10000000;
    const lakhs = Math.floor(num / 100000);
    num %= 100000;
    const thousands = Math.floor(num / 1000);
    num %= 1000;

    if (crores > 0) words += `${convertHundreds(crores)} Crore `;
    if (lakhs > 0) words += `${convertHundreds(lakhs)} Lakh `;
    if (thousands > 0) words += `${convertHundreds(thousands)} Thousand `;
    words += convertHundreds(num);
  } else {
    const billions = Math.floor(num / 1000000000);
    num %= 1000000000;
    const millions = Math.floor(num / 1000000);
    num %= 1000000;
    const thousands = Math.floor(num / 1000);
    num %= 1000;
    if (billions > 0) words += `${convertHundreds(billions)} Billion `;
    if (millions > 0) words += `${convertHundreds(millions)} Million `;
    if (thousands > 0) words += `${convertHundreds(thousands)} Thousand `;
    words += convertHundreds(num);
  }

  return words.trim();
}
