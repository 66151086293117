import React from "react";

type SwitchButtonType = {
  className?: string;
  toggleClassName?: string;
  value: boolean;
  label: string;
  leftLabel?: string;
} & React.HTMLAttributes<HTMLDivElement>;

export function SwitchButton(props: SwitchButtonType): React.ReactElement {
  const {
    label,
    className,
    toggleClassName,
    value,
    leftLabel,
    onClick,
    ...rest
  } = props;
  const toggleClass = `transform translate-x-full`;
  const _className = className;
  return (
    <>
      {leftLabel && <label className={`${_className}`}>{leftLabel}</label>}
      <div
        className={`md:w-12 mt-1.5 md:h-6 w-12 h-6 flex items-center p-1 rounded-full cursor-pointer ${
          value ? "bg-orange-500" : "bg-slate-300"
        }  ${_className}`}
        onClick={onClick}
      >
        <div
          className={`bg-white md:w-5 md:h-5 h-5 w-5 rounded-full shadow-md transform duration-500 ${toggleClassName} ease-in-out ${
            value ? toggleClass : null
          } `}
        ></div>
      </div>
      <label className={`${_className}`}>{label}</label>
    </>
  );
}

export function SwitchToggleButton(
  props: SwitchButtonType
): React.ReactElement {
  const { label, className, value, leftLabel, onClick, ...rest } = props;
  const toggleClass = " transform translate-x-full";
  const _className = className;
  return (
    <>
      {leftLabel && <label className={`${_className}`}>{leftLabel}</label>}
      <div
        className={`md:w-12 mt-1.5 md:h-6 w-12 h-6 flex items-center p-1 rounded-full cursor-pointer ${"bg-orange-500"}  ${_className}`}
        onClick={onClick}
      >
        <div
          className={`bg-white md:w-5 md:h-5 h-5 w-5 rounded-full shadow-md transform duration-500 ease-in-out ${
            value ? toggleClass : null
          } `}
        ></div>
      </div>
      <label className={`${_className}`}>{label}</label>
    </>
  );
}
