import React from "react";
import { formatCurrency } from "../../utils/currencyFormatter";
import { formatDisplayDate } from "../../utils/date";

const CompanyOverviewCard = ({
  name,
  value,
  currency,
  isDate = false,
  companyCurrency = "INR",
}: {
  name: string;
  value: number | string;
  currency?: string;
  isDate?: boolean;
  className?: string;
  companyCurrency: string;
}) => (
  <div>
    <p className="text-gray-500 font-medium">{name}</p>
    <p className="text-lg pt-2 font-medium">
      {isDate
        ? formatDisplayDate(value as string)
        : typeof value === "number"
        ? formatCurrency(value, "INR - ₹")
        : value}
    </p>
    {currency && <span className="text-gray-500">{currency}</span>}
  </div>
);

export default CompanyOverviewCard;
