import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Label } from "../../components/shared/InputField";
import { Select } from "../../components/shared/Select";
import { Box, HStack, VStack } from "../../components/utils";
import {
  useLiquidationPreference,
  useSecurityOverviewDetails,
} from "../../queries/securities";
import BCHeader from "../../shared/BCHeader";
import { useAuthStore } from "../../store";
import { useSelectedSecurityTypeStoreStore } from "../../store/selectedSecurityTypeStore";
import { useCanUserAccess } from "../../store/useRoleGrantStore";
import { SecurityDetail } from "../../types/SecurityModel";
import { PrimaryCTAButton } from "../quickRound/CTAButtonComponents";
import {
  getCurrencySymbol,
  getCurrencyType,
} from "../../utils/currencyFormatter";

import SecurityClassTable from "./SecurityClassTable";
import DashboardSecurityBarChart from "../dashboardPage/DashboardSecurityBarChart";
import { useInvSecurityOverviewDetails } from "../../queries/investorPortal";
import AGGridSecurityClassTable from "./SecurityClassAgTable";
import PercentageCardDesign from "../../components/PercentageCardDesign";
import {
  FirstCardComponent,
  SecondCardComponent,
} from "../../components/shared/InnerPecentageCardDesign";
import AGGridSecurityTransactionTable from "./SecurityTransactionAgGridTable";

export default function IndividualSecurityTypePage() {
  const { cId, token } = useParams();
  const companyId = useAuthStore.getState().companyId || "";
  const { isPlaceholderData, data: _securityDetail } = cId
    ? useInvSecurityOverviewDetails(cId, token || "")
    : useSecurityOverviewDetails();

  const [selectedSecurity, setSelectedSecurity] = useState<SecurityDetail>();
  const securityType = useParams();

  const canUserAddSecurity = useCanUserAccess("create", "instrumentclass");

  const navigate = useNavigate();
  const securityList = useMemo(() => {
    if (isPlaceholderData) return _securityDetail?.tableDetails || [];
    if (securityType.type === "All") {
      return _securityDetail?.tableDetails;
    } else {
      const selectedSecurityDetail = _securityDetail?.securityDetails.find(
        (security) => security.securityType === securityType.type
      );

      const filteredSecurityList = _securityDetail?.tableDetails.filter(
        (security) => security.type === securityType.type
      );

      const filteredSecurityTransactions =
        _securityDetail?.securityTransactions.filter(
          (security) => security.securityType === securityType.type
        );

      setSelectedSecurity(selectedSecurityDetail);
      return filteredSecurityList;
    }
  }, [securityType.type, isPlaceholderData, _securityDetail]);

  const securityTransactions = useMemo(() => {
    if (securityType.type === "All") {
      return _securityDetail?.securityTransactions;
    } else {
      const selectedSecurityDetail = _securityDetail?.securityDetails.find(
        (security) => security.securityType === securityType.type
      );

      const filteredSecurityTransactions =
        _securityDetail?.securityTransactions.filter(
          (security) => security.securityType === securityType.type
        );

      setSelectedSecurity(selectedSecurityDetail);
      return filteredSecurityTransactions;
    }
  }, [securityType.type, isPlaceholderData]);
  return (
    <div
      className={`${cId ? "bg-white px-4" : ""} ${
        isPlaceholderData ? "loading min-h-full" : "min-h-full"
      }`}
    >
      {(securityList?.length || 0) > 0 && (
        <VStack className="gap-8">
          <HStack className="items-center justify-between">
            <BCHeader
              className="items-baseline"
              bcTitle="Security"
              bcSubTitle={securityType.type}
              cin={cId}
              token={token}
            />
            {canUserAddSecurity && !cId && (
              <PrimaryCTAButton
                event-name="Add New Security Class"
                className="h-8 text-xs2"
                onClick={() => {
                  navigate("/security/addNewSecurity");
                }}
              >
                New Security Class
              </PrimaryCTAButton>
            )}
          </HStack>

          <HStack className="pb-1 overflow-auto scrollbar-hide">
            {selectedSecurity && (
              <HStack className="justify-center flex-1">
                <FirstCardComponent
                  data={{
                    name: selectedSecurity.securityType,
                    type: selectedSecurity.securityType,
                    percentage: selectedSecurity.holding * 100,
                    isInstitutional: false,
                    isPromoter: false,
                  }}
                  isInvestorPortal={!!cId}
                />

                <SecondCardComponent
                  isInvestorPortal={!!cId}
                  cardDetails={selectedSecurity!}
                  cardType="security"
                />
              </HStack>
            )}
          </HStack>

          <div className="bg-white rounded-md shadow-box">
            {_securityDetail && (
              <AGGridSecurityClassTable
                isInvestorPortal={!!cId}
                securityType={securityType.type!}
                securityList={securityList || []}
              />
            )}
          </div>
          <div className="bg-white rounded-md shadow-box">
            <AGGridSecurityTransactionTable
              securityTransactionTableData={securityTransactions || []}
            />
          </div>
        </VStack>
      )}
    </div>
  );
}
