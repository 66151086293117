import { Icon } from "@iconify/react";
import { useEffect, useMemo, useRef, useState } from "react";
import { Center, HStack } from "../../components/utils";
import {
  ColumnOptions,
  EventComparisonModel,
} from "../../types/DashboardModel";
import { sort } from "../../utils/arrays";

export function CaptableColumnChooser({
  columnOptions,
  className = "text-gray-400",
  dropDownClassName = "",
  onAction,
}: {
  columnOptions: ColumnOptions[];
  onAction: (action: ColumnOptions[]) => void;
  className?: string;
  dropDownClassName?: string;
}) {
  const [dropdownOpen, setDropDownOpen] = useState(false);
  const contextRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const checkIfClickOutside = (e: any) => {
      if (!(dropdownOpen && contextRef.current?.contains(e.target))) {
        setDropDownOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickOutside);
    };
  }, [dropdownOpen]);

  const handleAction = (action: ColumnOptions) => {
    if (!action.isDisabled) {
      const updatedOptions = columnOptions.map((options) => {
        if (action.name === options.name) {
          options.isVisible = !options.isVisible;
        }
        return options;
      });
      onAction(updatedOptions);
    }
  };

  return (
    <div className=" flex flex-col overflow-auto items-end" ref={contextRef}>
      <button
        className="inline-flex text-xs text-center rounded-lg peer"
        type="button"
        onClick={() => setDropDownOpen((state) => !state)}
      >
        <Icon
          icon="carbon:overflow-menu-horizontal"
          className={`rounded hover:bg-gray-300 rotate-90 items-center ${className}`}
          height={20}
        />
      </button>
      {dropdownOpen && (
        <div
          id="dropdown"
          className={`absolute whitespace-nowrap z-10  mt-8 bg-white border divide-y divide-gray-100 rounded shadow-box`}
        >
          <p className="px-4 py-1 text-stone-300">More actions</p>
          <ul
            className={`py-1 text-xs font-normal divide-y text-dark w-fit overflow-auto ${dropDownClassName}`}
            aria-labelledby="dropdownDefault"
          >
            {columnOptions.map((action, index) => (
              <li key={index}>
                <HStack
                  className="justify-between px-4 py-2"
                  onClick={() => handleAction(action)}
                >
                  <a
                    className={` block hover:bg-slate-50 text-xxs1  ${
                      action.isDisabled
                        ? "text-gray-300 cursor-not-allowed "
                        : action.isVisible
                        ? "text-gray-800"
                        : "text-gray-800 hover:text-red-500 "
                    } `}
                    onMouseDown={(e) => e.preventDefault()}
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    {action.displayName}
                  </a>
                  <input
                    type="checkbox"
                    className={`ml-1 accent-orange-501 cursor-pointer outline-hidden ${
                      action.isDisabled ? "cursor-not-allowed " : ""
                    }`}
                    readOnly
                    checked={action.isVisible}
                  ></input>
                </HStack>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}
export function CaptableEventComparisonColumnChooser({
  label,
  columnOptions,
  shareHoldersList,
  onAction,
}: {
  label?: string;
  columnOptions: EventComparisonModel[];
  shareHoldersList: EventComparisonModel[];
  onAction: (action: EventComparisonModel[]) => void;
}) {
  const sortedData = sort(columnOptions || [], "name");

  const [searchText, setSearchText] = useState("");
  const [searched, setSearched] = useState<any[]>(sortedData);

  const [dropdownOpen, setDropDownOpen] = useState(false);
  const contextRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkIfClickOutside = (e: any) => {
      if (!(dropdownOpen && contextRef.current?.contains(e.target))) {
        setDropDownOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickOutside);
    };
  }, [dropdownOpen]);

  useEffect(() => {
    const checkIfClickOutside = (e: any) => {
      if (!searchText) setSearchText("");
      if (!(dropdownOpen && contextRef.current?.contains(e.target))) {
        setDropDownOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickOutside);
    };
  }, [dropdownOpen, searchText]);

  const handleAction = (action: EventComparisonModel) => {
    if (!action.isDisabled) {
      const updatedOptions = sortedData.map((options) => {
        if (action.name === options.name) {
          options.isVisible = !options.isVisible;
        }
        return options;
      });
      onAction(updatedOptions);
    }
  };

  function searchOptions(text: string) {
    const match = sortedData.filter((d) => {
      const regex = new RegExp(`${text}`, "gi");
      return (d.name as any).match(regex);
    });
    setSearched(match);
  }

  return (
    <div className="flex flex-col overflow-auto items-end" ref={contextRef}>
      <input
        type="text"
        className="w-full px-2 text-xs font-medium outline-none shadow-box form-input"
        value={searchText}
        placeholder="Filter Shareholder"
        onChange={(e) => {
          if (!e.target.value) setSearched(sortedData);
          setSearchText(e.target.value);
          searchOptions(e.target.value);
        }}
        onFocus={() => {
          setDropDownOpen(true);
          setSearched(sortedData);
        }}
      />
      {dropdownOpen && (
        <div
          id="dropdown"
          className={`absolute z-10 w-48 mt-10 bg-white border divide-y divide-gray-100 rounded shadow-box`}
        >
          <ul
            className="py-1 text-xs font-normal divide-y text-dark w-48 h-60 overflow-auto"
            aria-labelledby="dropdownDefault"
          >
            {searchText === "" && (
              <HStack
                onClick={() =>
                  !shareHoldersList.find(
                    (columnOption) => columnOption.isVisible !== false
                  ) || false
                    ? shareHoldersList.forEach((columnOption) => {
                        if (columnOption.isVisible === false)
                          handleAction(columnOption);
                      })
                    : shareHoldersList.forEach((columnOption) => {
                        if (columnOption.isVisible !== false)
                          handleAction(columnOption);
                      })
                }
                className="justify-between px-2 py-2 cursor-pointer hover:bg-slate-50"
              >
                <div className="flex gap-2 divide-y rounded text-stone-500 ">
                  All Shareholder
                </div>
                <input
                  type="checkbox"
                  className={`ml-1 accent-orange-501 outline-hidden`}
                  readOnly
                  checked={
                    !shareHoldersList.find(
                      (columnOption) => columnOption.isVisible === false
                    ) || false
                  }
                ></input>
              </HStack>
            )}
            {searched?.map((option, index) => (
              <HStack
                key={option.name + index}
                onClick={() => handleAction(option)}
                className="justify-between px-2 py-2 cursor-pointer hover:bg-slate-50"
              >
                <div className="flex gap-2 divide-y rounded">
                  {option.name || ""}
                </div>
                <input
                  type="checkbox"
                  className={`ml-1 accent-orange-501 outline-hidden ${
                    option.isDisabled ? "cursor-not-allowed " : ""
                  }`}
                  readOnly
                  checked={option.isVisible}
                ></input>
              </HStack>
            ))}
            {searched.length === 0 && (
              <p className="text-stone-500 text-center mx-auto pt-4">
                No Result Found
              </p>
            )}
          </ul>
          <Center>
            <a
              className={` block hover:bg-slate-50 w-full cursor-pointer text-center text-red-500 px-4 py-2`}
              onMouseDown={(e) => e.preventDefault()}
              onClick={(e) => {
                e.preventDefault();
                setDropDownOpen(false);
              }}
            >
              Done
            </a>
          </Center>
        </div>
      )}
    </div>
  );
}
