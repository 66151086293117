import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Select } from "../../components/shared/Select";
import { HStack, VStack, Error } from "../../components/utils";
import { QuickBonusSecurityModel } from "../../types/quickRound";
import { useQuickBonusSecurityStore } from "../../store/QuickBonusSecuritiesStore";
import { useShareholderInstrumentStore } from "../../store/shareholderInstrumentStore";
import { Input, Label } from "../../components/shared/InputField";
import {
  SecondaryCTAButton,
  PrimaryCTAButton,
} from "../quickRound/CTAButtonComponents";
import { ShowNumberInWords } from "../../components/shared/UiElement";
import { getCurrencyType } from "../../utils/currencyFormatter";
import { useNewShareholderInstrumentStore } from "../../store/newShareholderAndInstrumentStore";
import { useGetSecondaryInvestors } from "../../queries/transactionRound";

type AddBonusSecurityModalProps = {
  onPrimaryAction: () => void;
  onSecondaryAction: () => void;
  data: any;
  mode: string;
};

type TypeSharesPerSecurity = {
  instrumentId: string;
  instrumentName: string;
  noOfShares: number;
  investorId: string;
};

const AddBonusPopup = ({
  onPrimaryAction = () => {},
  onSecondaryAction = () => {},
  data,
  mode,
}: AddBonusSecurityModalProps) => {
  const shareholderInstruments = useShareholderInstrumentStore();
  const [onHoverNewShares, setOnHoverNewShares] = useState(false);
  const currencyType = getCurrencyType();
  const secondaryDetails = useGetSecondaryInvestors();
  const [selectedSecurity, setSelectedSecurity] = useState<string[]>([]);
  const shareHolders: string[] =
    shareholderInstruments?.shareholders
      .filter(
        (shareholder: any) => shareholder.name.toLowerCase() !== "esop pool"
      )
      .map((shareHolderDetail) => shareHolderDetail.name) || [];
  const instruments: string[] =
    _.uniq(
      shareholderInstruments?.instrumentClasses.map(
        (instrument) => instrument.name
      )
    ) || [];

  const currentInstruments: string[] = _.uniq(
    shareholderInstruments.instrumentClasses
      .filter(
        (instrument) =>
          instrument.isCurrent === true || instrument.isCurrent === undefined
      )
      .map((instrument) => instrument.name)
  );

  const initialShareHolderValues: QuickBonusSecurityModel = {
    uuid: data?.uuid || "",
    type: data?.type || "",
    sourceSecurity: data?.sourceSecurity || "",
    bonusRatio: data?.bonusRatio || "1:1",
    newShares: data?.newShares || 0,
    conversionRatio: data?.conversionRatio || "1:1",
    finalSecurity: data?.finalSecurity || "",
    investorName: data?.investorName || "",
  };

  const quickBonusTransactionInputSchema = Yup.object({
    sourceSecurity: Yup.string().required().label("Source Security"),
    investorName: Yup.string().required().label("Investor Name"),
    newShares: Yup.number()
      .integer()
      .min(1)
      .required()
      .label("New No.of Shares"),
    finalSecurity: Yup.string().required().label("Bonus Security"),
  });

  const bonusSecurityStore = useNewShareholderInstrumentStore();

  const handleSubmit = (values: QuickBonusSecurityModel) => {
    const exists = bonusSecurityStore.bonus.some(
      (transaction) =>
        transaction.investorName === values.investorName &&
        transaction.sourceSecurity === values.sourceSecurity &&
        transaction.newShares === values.newShares &&
        transaction.finalSecurity === values.finalSecurity
    );

    if (exists) {
      toast("Transaction already exists!", { type: "error", autoClose: 2000 });
      return;
    }

    const existingIndex = bonusSecurityStore.bonus.findIndex(
      (transaction: any) => transaction.uuid === values.uuid
    );

    const instrumentClass = shareholderInstruments?.instrumentClasses.find(
      (instrument) => instrument.name === values.sourceSecurity
    );

    const investorId =
      shareholderInstruments?.shareholders.find(
        (instrument) => instrument.name === values.investorName
      )?.id || "";
    const instrumentClassId =
      shareholderInstruments?.instrumentClasses.find(
        (instrument) => instrument.name === values.sourceSecurity
      )?.id || "";
    const finalConversionInstrumentClassId =
      shareholderInstruments?.instrumentClasses.find(
        (instrument) => instrument.name === values.finalSecurity
      )?.id || "";
    const bonusSecurity: QuickBonusSecurityModel = {
      uuid: uuidv4(),
      instrumentClassId,
      finalConversionInstrumentClassId,
      investorId,
      type: instrumentClass?.subType || "",
      sourceSecurity: values.sourceSecurity || "",
      bonusRatio: values.bonusRatio || "1:1",
      newShares: values.newShares || 0,
      conversionRatio: `1:${instrumentClass?.conversionRatio || 1}`,
      finalSecurity: values.finalSecurity || "",
      investorName: values.investorName || "",
    };
    if (existingIndex !== -1) {
      bonusSecurityStore.bonus[existingIndex] = bonusSecurity;
      bonusSecurityStore.setBonus([...bonusSecurityStore.bonus]);
      toast("Transaction updated successfully", {
        type: "success",
        autoClose: 2000,
      });
    } else {
      bonusSecurityStore.setBonus([...bonusSecurityStore.bonus, bonusSecurity]);
      toast("Transaction added successfully", {
        type: "success",
        autoClose: 2000,
      });
    }
    onPrimaryAction();
  };

  return (
    <VStack>
      <HStack className="justify-between px-6 border-b py-4">
        <p className="text-base font-medium">{mode} Bonus</p>
        <XMarkIcon className="h-6 w-6" onClick={() => onSecondaryAction()} />
      </HStack>

      <div className="bg-white rounded-lg px-4 py-4">
        <VStack className="justify-between">
          <Formik
            key="addBonusSecurity"
            enableReinitialize={true}
            initialValues={initialShareHolderValues}
            validationSchema={quickBonusTransactionInputSchema}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            {(formik) => (
              <Form key="shareholder">
                <VStack className="w-full  px-2 py-4 gap-6">
                  <VStack className="gap-8">
                    <HStack className="gap-8  md:flex-row flex-col">
                      <div className="flex-1 w-72">
                        <Label className="text-sm font-normal">
                          Investor Name
                        </Label>
                        <Select
                          options={shareHolders}
                          {...formik.getFieldProps("investorName")}
                          onChange={(selectedOption) => {
                            data = secondaryDetails.data;
                            formik.setFieldValue(
                              "investorName",
                              selectedOption.target.value
                            );

                            formik.setFieldValue("sourceSecurity", "");

                            const selectedInvestor = data.find(
                              (seller: any) =>
                                seller.name === selectedOption.target.value
                            );
                            if (selectedInvestor) {
                              const sharesPerSecurityArray =
                                selectedInvestor.sharesPerSecurity;
                              const instrumentNamesArray =
                                sharesPerSecurityArray.map(
                                  (share: TypeSharesPerSecurity) =>
                                    share.instrumentName
                                );
                              const uniqueInstrumentNames: string[] =
                                Array.from(new Set(instrumentNamesArray));
                              setSelectedSecurity(uniqueInstrumentNames);
                            }
                          }}
                        />
                        {formik.touched?.investorName &&
                          formik.errors?.investorName && (
                            <Error text={formik.errors?.investorName} />
                          )}
                      </div>
                      <div className="flex-1 w-72">
                        <Label className="text-sm font-normal">
                          Source Security
                        </Label>
                        <Select
                          options={selectedSecurity}
                          {...formik.getFieldProps("sourceSecurity")}
                        />
                        {formik.touched?.sourceSecurity &&
                          formik.errors?.sourceSecurity && (
                            <Error text={formik.errors?.sourceSecurity} />
                          )}
                      </div>
                    </HStack>
                    <HStack className="w-full gap-8 mx-auto grid grid-cols-1 md:grid-cols-2">
                      <div className="flex-1 w-72">
                        <Label className="text-sm font-normal">
                          New Shares
                        </Label>
                        <Input
                          type="text"
                          onMouseEnter={() => {
                            setOnHoverNewShares(true);
                          }}
                          onMouseLeave={() => {
                            setOnHoverNewShares(false);
                          }}
                          onChange={(e: any) => {
                            const values = parseInt(
                              e.target.value?.replaceAll(",", "") || 0,
                              10
                            );
                            formik.setFieldValue("newShares", values);
                            formik.handleChange("newShares");
                          }}
                          value={Intl.NumberFormat(currencyType).format(
                            formik.values.newShares
                          )}
                        />
                        {onHoverNewShares && (
                          <ShowNumberInWords
                            value={formik.values.newShares}
                            width={38}
                            currency={currencyType}
                          />
                        )}

                        {formik.touched.newShares &&
                          formik.errors?.newShares && (
                            <Error text={formik.errors?.newShares} />
                          )}
                      </div>
                      <div className="flex-1 w-72">
                        <Label className="text-sm font-normal">
                          Bonus Security
                        </Label>
                        <Select
                          options={instruments}
                          {...formik.getFieldProps("finalSecurity")}
                        />
                        {formik.touched.finalSecurity &&
                          formik.errors?.finalSecurity && (
                            <Error text={formik.errors?.finalSecurity} />
                          )}
                      </div>
                    </HStack>
                  </VStack>

                  <HStack className="justify-between mt-10">
                    <SecondaryCTAButton
                      event-name="Cancel Bonus Modal"
                      onClick={() => onSecondaryAction()}
                      type="reset"
                      className="text-red-500"
                    >
                      Cancel
                    </SecondaryCTAButton>
                    <PrimaryCTAButton
                      event-name="Save Bonus Modal"
                      type="submit"
                    >
                      {mode}
                    </PrimaryCTAButton>
                  </HStack>
                </VStack>
              </Form>
            )}
          </Formik>
        </VStack>
      </div>
    </VStack>
  );
};

export default AddBonusPopup;
