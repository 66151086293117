import React from "react";
import {
  FormatNumberSpan,
  getRoundCurrencySymbol,
} from "../../utils/currencyRoboto";
import {
  getCurrency,
  getCurrencySymbol,
  getCurrencyType,
} from "../../utils/currencyFormatter";
import { HStack, VStack } from "../../components/utils";

interface ProgressBarProps {
  percentage: number; // Expected to be between 0 and 100
  raisedAmount: number;
  leftToRaise: number;
  currency: string;
}

const DarkProgressBar: React.FC<ProgressBarProps> = ({
  percentage,
  raisedAmount,
  leftToRaise,
  currency,
}) => {
  const currencySymbol =
    getRoundCurrencySymbol(currency || "INR - ₹") || getCurrencySymbol();
  const currencyType = getCurrency(currency || getCurrencyType());

  return (
    <>
      <VStack className="flex justify-between h-full text-xs py-3">
        <HStack className="items-center justify-between">
          <HStack>
            <div className="w-3 h-3 bg-green-500 mr-1 border border-green-600">
              {" "}
            </div>
            <p className="text-white font-medium whitespace-nowrap">
              Money Raised:
            </p>{" "}
          </HStack>
          <HStack className="font-bold text-white items-baseline  whitespace-nowrap">
            <p className="pr-1 text-xxs">{currencySymbol}</p>

            <FormatNumberSpan value={raisedAmount} format={currencyType} />
          </HStack>
        </HStack>
        <HStack className="items-center justify-between">
          <HStack>
            <div className="w-3 h-3 bg-gray-200 mr-1 border border-gray-300">
              {" "}
            </div>
            <p className="text-white font-medium whitespace-nowrap">
              Left to raise:
            </p>{" "}
          </HStack>
          <HStack className="font-bold text-white items-baseline  whitespace-nowrap">
            <p className="pr-1 text-xxs">{currencySymbol}</p>
            <FormatNumberSpan value={leftToRaise} format={currencyType} />
          </HStack>
        </HStack>
        <div className="bg-gray-200 rounded-md overflow-hidden h-4">
          <HStack
            className="bg-green-500 overflow-hidden animate-pulse item-center h-4 rounded-md text-white text-xs font-semibold text-center items-center"
            style={{
              width: `${
                percentage >= 100 ? 100 : percentage > 15 ? percentage : 15
              }%`,
            }}
          >
            <HStack className="mx-auto">
              <FormatNumberSpan value={percentage} format={currencyType} />
              <p>%</p>
            </HStack>
          </HStack>
        </div>
      </VStack>
    </>
  );
};

export default DarkProgressBar;
