import { Icon } from "@iconify/react";
import { useState } from "react";
import { Box, HStack, VStack } from "../../components/utils";
import { useRMCreationStore } from "../../store/roundCreationStore";

interface StepperModel {
  heading: string;
  description: string;
  subHeading: string[];
}

function RoundCreationStepper({ templateNumber }: { templateNumber: number }) {
  const [expanded, setExpanded] = useState(false);
  const { roundCreation: data, sendMessage: onNext } = useRMCreationStore();
  const stepper: StepperModel[] = [
    {
      heading: "Round Details",
      description: "Enter the round details",
      subHeading: [],
    },
    {
      heading: "Outstanding Convertible",
      description: "Enter the discount details",
      subHeading: [],
    },
    {
      heading: "Investors",
      description: "Fill in the Investors details",
      subHeading: ["Existing Investor", "New Investors"],
    },
    {
      heading: "Security Allotment",
      description: "Update the security allotment",
      subHeading: [],
    },
    {
      heading: "Secondary",
      description: "Fill in the Secondary Transaction",
      subHeading: [],
    },
    {
      heading: "Summary",
      description: "See the final Summary to confirm",
      subHeading: [],
    },
  ];

  const updateCurrentTab = (num: number) => {
    onNext({
      ...data,
      currentTab: num,
      isNavigate: true,
    });
  };

  return (
    <>
      {expanded ? (
        <Box className="w-8 p-2 bg-white border rounded-lg h-fit border-borderColor">
          <div className="w-12 mx-auto">
            <HStack className="justify-between">
              <Icon
                icon="radix-icons:double-arrow-left"
                className={`text-[#464E5F]`}
                onClick={() => {
                  setExpanded(!expanded);
                }}
                rotate={expanded ? 90 : 0}
                height={20}
              />
            </HStack>

            <StepperUiPoint
              onNext={updateCurrentTab}
              templateNumber={templateNumber}
              index={1}
              stepperData={stepper[0]}
            />
            {/* dirty commit {data.tab2?.convertibleTransaction.length !== 0 && (
              <StepperUiPoint
                onNext={updateCurrentTab}
                templateNumber={templateNumber}
                index={2}
                stepperData={stepper[1]}
              />
            )} */}
            <MainStepperUIPoint
              onNext={updateCurrentTab}
              templateNumber={templateNumber}
              index={3}
              index2={4}
              stepperData={stepper[2]}
            />
            <SubStepperUIPoint
              onNext={updateCurrentTab}
              templateNumber={templateNumber}
              index={3}
              stepperData={stepper[2].subHeading[0]}
            />
            <SubStepperUIPoint
              onNext={updateCurrentTab}
              templateNumber={templateNumber}
              index={4}
              stepperData={stepper[2].subHeading[1]}
            />

            <StepperUiPoint
              onNext={updateCurrentTab}
              templateNumber={templateNumber}
              index={5}
              stepperData={stepper[3]}
            />
            <StepperUiPoint
              onNext={updateCurrentTab}
              templateNumber={templateNumber}
              index={6}
              stepperData={stepper[4]}
            />
            <StepperUiPoint
              onNext={updateCurrentTab}
              templateNumber={templateNumber}
              index={7}
              stepperData={stepper[5]}
            />
          </div>
        </Box>
      ) : (
        <Box className="p-2 bg-white border rounded-lg h-fit w-52 border-borderColor">
          <div className="px-4 mx-auto">
            <HStack className="justify-between">
              <div></div>
              <Icon
                icon="radix-icons:double-arrow-left"
                className={`text-[#464E5F]`}
                onClick={() => {
                  setExpanded(!expanded);
                }}
                rotate={expanded ? 90 : 0}
                height={20}
              />
            </HStack>

            <StepperUi
              onNext={updateCurrentTab}
              templateNumber={templateNumber}
              index={1}
              stepperData={stepper[0]}
            />
            {data.tab2?.convertibleTransaction.length !== 0 && (
              <StepperUi
                onNext={updateCurrentTab}
                templateNumber={templateNumber}
                index={2}
                stepperData={stepper[1]}
              />
            )}
            <MainStepperUI
              onNext={updateCurrentTab}
              templateNumber={templateNumber}
              index={3}
              index2={4}
              stepperData={stepper[2]}
            />
            <SubStepperUI
              onNext={updateCurrentTab}
              templateNumber={templateNumber}
              index={3}
              stepperData={stepper[2].subHeading[0]}
            />
            <SubStepperUI
              onNext={updateCurrentTab}
              templateNumber={templateNumber}
              index={4}
              stepperData={stepper[2].subHeading[1]}
            />

            <StepperUi
              onNext={updateCurrentTab}
              templateNumber={templateNumber}
              index={5}
              stepperData={stepper[3]}
            />
            <StepperUi
              onNext={updateCurrentTab}
              templateNumber={templateNumber}
              index={6}
              stepperData={stepper[4]}
            />
            <StepperUi
              onNext={updateCurrentTab}
              templateNumber={templateNumber}
              index={7}
              stepperData={stepper[5]}
            />
          </div>
        </Box>
      )}
    </>
  );
}

export default RoundCreationStepper;

function StepperUi({
  templateNumber,
  index,
  stepperData,
  onNext,
}: {
  templateNumber: number;
  index: number;
  stepperData: StepperModel;
  onNext: (num: number) => void;
}) {
  return (
    <HStack className="items-baseline py-4">
      {templateNumber > index ? (
        <Icon icon="charm:tick" className="w-4 h-4 scale-125 text-orange-501" />
      ) : (
        <Icon
          icon="material-symbols:circle-outline"
          className="w-3 h-3 scale-125 text-orange-501"
        />
      )}

      <VStack
        onClick={() => (templateNumber < index ? () => {} : onNext(index))}
        className={`align-top ${
          templateNumber < index ? "cursor-not-allowed" : "cursor-pointer"
        }`}
      >
        <p
          className={`${
            templateNumber === index
              ? " pl-2 font-semibold text-sm  text-orange-501 "
              : "pl-2 font-semibold text-sm  text-gray-dark "
          }`}
        >
          {stepperData.heading}
        </p>
        <p className={`pl-2 leading-5 text-xxs text-gray-light align-top`}>
          {stepperData.description}
        </p>
      </VStack>
    </HStack>
  );
}

function MainStepperUI({
  templateNumber,
  index,
  index2,
  stepperData,
  onNext,
}: {
  templateNumber: number;
  index: number;
  index2: number;
  stepperData: StepperModel;
  onNext: (num: number) => void;
}) {
  return (
    <HStack className="pt-8 cursor-pointer">
      <Icon
        icon="ic:baseline-keyboard-arrow-down"
        className="w-5 h-5 scale-125 text-orange-501"
      />

      <VStack
        onClick={() => (templateNumber < index ? () => {} : onNext(index))}
        className={`align-top ${
          templateNumber < index ? "cursor-not-allowed" : "cursor-pointer"
        }`}
      >
        <p
          className={`  ${
            templateNumber === index || templateNumber === index2
              ? "font-semibold text-sm  text-orange-501 pl-2 "
              : "pl-2 font-semibold text-sm  text-gray-dark "
          }`}
        >
          {stepperData.heading}
        </p>
        <p className={`pl-2 leading-5 text-xxs text-gray-light align-top`}>
          {stepperData.description}
        </p>
      </VStack>
    </HStack>
  );
}

function SubStepperUI({
  templateNumber,
  index,
  stepperData,
  onNext,
}: {
  templateNumber: number;
  index: number;
  stepperData: string;
  onNext: (num: number) => void;
}) {
  return (
    <HStack className="items-baseline pt-2 pl-12 cursor-pointer">
      {templateNumber > index ? (
        <Icon icon="charm:tick" className="w-4 h-4 scale-125 text-orange-501" />
      ) : (
        <Icon
          icon="material-symbols:circle-outline"
          className="w-3 h-3 scale-125 text-orange-501"
        />
      )}

      <VStack
        onClick={() => (templateNumber < index ? () => {} : onNext(index))}
        className={`align-top pt-4 ${
          templateNumber < index ? "cursor-not-allowed" : "cursor-pointer"
        }`}
      >
        <p
          className={`  ${
            templateNumber === index
              ? "font-semibold text-sm  text-orange-501 pl-2 "
              : "pl-2 font-semibold text-sm  text-gray-dark "
          }`}
        >
          {stepperData}
        </p>
      </VStack>
    </HStack>
  );
}

function StepperUiPoint({
  templateNumber,
  index,
  stepperData,
  onNext,
}: {
  templateNumber: number;
  index: number;
  stepperData: StepperModel;
  onNext: (num: number) => void;
}) {
  return (
    <>
      <HStack className={`py-4 items-baseline`}>
        <VStack
          onClick={() => (templateNumber < index ? () => {} : onNext(index))}
          className={`align-top ${
            templateNumber < index ? "cursor-not-allowed" : "cursor-pointer"
          }`}
        >
          {templateNumber === index ? (
            <Icon
              icon="clarity:dot-circle-line"
              className="w-4 h-4 scale-125 text-orange-501"
            />
          ) : templateNumber > index ? (
            <Icon
              icon="material-symbols:circle"
              className="w-4 h-4 scale-125 text-orange-501"
            />
          ) : (
            <Icon
              icon="charm:circle"
              className="w-4 h-4 scale-125 text-orange-501"
            />
          )}
        </VStack>
      </HStack>
    </>
  );
}

function MainStepperUIPoint({
  templateNumber,
  index,
  index2,
  stepperData,
  onNext,
}: {
  templateNumber: number;
  index: number;
  index2: number;
  stepperData: StepperModel;
  onNext: (num: number) => void;
}) {
  return (
    <HStack className="pt-8 cursor-pointer">
      <VStack
        onClick={() => (templateNumber < index ? () => {} : onNext(index))}
        className={`align-top ${
          templateNumber < index ? "cursor-not-allowed" : "cursor-pointer"
        }`}
      >
        {templateNumber >= index || templateNumber >= index2 ? (
          <Icon
            icon="material-symbols:circle"
            className="w-4 h-4 scale-125 text-orange-501"
          />
        ) : (
          <Icon
            icon="charm:circle"
            className="w-4 h-4 scale-125 text-orange-501"
          />
        )}
      </VStack>
    </HStack>
  );
}

function SubStepperUIPoint({
  templateNumber,
  index,
  stepperData,
  onNext,
}: {
  templateNumber: number;
  index: number;
  stepperData: string;
  onNext: (num: number) => void;
}) {
  return (
    <HStack className="items-baseline justify-start pt-2 cursor-pointer">
      <VStack
        onClick={() => (templateNumber < index ? () => {} : onNext(index))}
        className={`align-top justify-start pt-4 ${
          templateNumber < index ? "cursor-not-allowed" : "cursor-pointer"
        }`}
      >
        {templateNumber === index ? (
          <Icon
            icon="clarity:dot-circle-line"
            className="w-4 h-4 scale-125 text-orange-501"
          />
        ) : templateNumber >= index ? (
          <Icon
            icon="material-symbols:circle"
            className="w-3 h-3 pl-1 scale-125 text-orange-501"
          />
        ) : (
          <Icon
            icon="charm:circle"
            className="w-3 h-3 pl-1 scale-125 text-orange-501"
          />
        )}
      </VStack>
    </HStack>
  );
}
