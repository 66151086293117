import { QueryFunctionContext } from "react-query";
import { PreviewQuickRoundResponseModel } from "../types/previewQuickRound";
import {
  QuickBonusSubmitModel,
  QuickRoundPreviewApproveModel,
  QuickRoundSubmitResponse,
  QuickSplitRoundPreviewApproveModel,
  ShareholderInstrumentModel,
} from "../types/quickRound";
import api from "./capTableApi";

export async function getInstrumentAndShareHolder(
  companyId: string,
  cinNumber?: string | null
): Promise<ShareholderInstrumentModel> {
  return api
    .get("v1/company/shareholdersAndInstruments", {
      params: { companyId, cinNumber },
    })
    .then((res) => res.data.data);
}

export async function approveQuickSplitRoundPreview(
  quickRoundDetail: QuickSplitRoundPreviewApproveModel
): Promise<void> {
  return api
    .post(`v1/company/approveQuickSplit`, quickRoundDetail)
    .then((res) => res.data);
}

export async function approveQuickRoundPreview(
  quickRoundDetail: QuickRoundPreviewApproveModel
): Promise<void> {
  return api
    .post(`v1/company/approveQuickRound`, quickRoundDetail)
    .then((res) => res.data);
}

export async function approveQuickBuyback(
  quickBonusPreview: QuickRoundPreviewApproveModel
): Promise<void> {
  return api
    .post(`v1/company/approveQuickBuyback`, quickBonusPreview)
    .then((res) => res.data);
}
export async function approveQuickSecondary(
  quickSecondaries: QuickRoundPreviewApproveModel
): Promise<void> {
  return api
    .post(`v1/company/approveQuickSecondary`, quickSecondaries)
    .then((res) => res.data);
}

export async function getQuickRoundPreview(
  context: QueryFunctionContext
): Promise<{ message: string; data: PreviewQuickRoundResponseModel }> {
  const eventId = context.queryKey[1];
  return api
    .get(`v1/company/proformaEventSummary/?eventId=${eventId}`)
    .then((res) => res.data);
}
