import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { AgGridReact } from "ag-grid-react";
import {
  CellClickedEvent,
  ColDef,
  SideBarDef,
  GridApi,
} from "ag-grid-community";
import { useNavigate } from "react-router";
import { Action, CTADropdown } from "../../components/shared/Dropdown";
import { Box, HStack, VStack, useGetCompanyName } from "../../components/utils";
import { AgGridTransactionsCaptable } from "../../types/AllTransactionModel";
import { agConfigDashboardSideBar } from "../dashboardPage/AgGridConfig";

import {
  AmountInvestedRender,
  NetworthRender,
  NumberOfSharesRender,
  PostHoldingCommonSharesRender,
  PostHoldingDebRender,
  PostHoldingFdbPercentage,
  PostHoldingFdbSharesRender,
  PostHoldingPrefRender,
  PreHoldingCommonSharesRender,
  PreHoldingDebRender,
  PreHoldingFdbPercentage,
  PreHoldingFdbSharesRender,
  PreHoldingPrefRender,
  ShareholderNameRender,
  TotalAmountInvestedRender,
  TransactionAmountRender,
} from "../rounds/RoundsAGComponent";
import {
  getCurrencySymbol,
  getCurrencyType,
} from "../../utils/currencyFormatter";
import GenericTableHeader from "../../shared/TableHeader";
import { IconCTAButton } from "../quickRound/CTAButtonComponents";
import { useAuthStore } from "../../store/useAuthStore";
import {
  useGetOnFilterState,
  usePostOnFilterState,
} from "../dashboardPage/AgGridCacheQuery";
import { ExportImport } from "../Utility/GrantsTable";
import {
  exportIndividualRoundSummaryReport,
  exportPAS4Report,
} from "../../api/Report";
import { getIndividualRoundDetailReport } from "../../api/Reports";
import { downloadExcel } from "../../utils/DownloadFile";
import { downloadFile } from "../../utils/downloadBlob";
import { tableCellStyle } from "../../components/TableComponent";
import { useAgGridTableState } from "../../store/agGridTableStore";
import SearchInput from "../../components/shared/SearchInput";
import { CumulativeHoldingQuickRound } from "../../types/CapTable";
import { handleEventForTracking } from "../../amplitudeAnalytics";

function AGGridRoundsSummaryTable({
  capTableData,
}: {
  capTableData: CumulativeHoldingQuickRound[];
}) {
  const currencySymbol = getCurrencySymbol();
  const companyId = useAuthStore().companyId ?? "";
  const companyName = useGetCompanyName();

  const userId = useAuthStore().user?.userId ?? 0;
  const gridApi = useRef<any>(null);
  const { refetch, data: filterState } = useGetOnFilterState(
    `${userId}`,
    companyId
  );

  const displayedRowCount = gridApi.current?.api.getDisplayedRowCount();
  //Dirty Commit const agTableStore = useAgGridTableState();
  //Dirty Commit useEffect(() => {
  //   refetch().then((data) => {
  //     if (data.data) agTableStore.setState(data.data);
  //     setColumnSetting(data.data?.splitCaptableColumnModel);
  //     setFilterSetting(data.data?.splitCaptableFilterModel);
  //   });
  // }, []);

  //Dirty Commit const { mutate: postOnFilter } = usePostOnFilterState();

  //Dirty Commit function getColumnSetting() {
  //   if (gridApi.current) return gridApi.current.columnApi?.getColumnState();
  //   return {};
  // }

  //Dirty Commit function setColumnSetting(model: any) {
  //   if (gridApi.current)
  //     gridApi.current.columnApi.applyColumnState({ state: model });
  //   return {};
  // }

  // Gets filter model via the grid API
  const getFilterSetting = () => {
    if (gridApi.current) return gridApi.current.api?.getFilterModel();
    return {};
  };

  const setFilterSetting = (model: any) => {
    if (gridApi.current) return gridApi.current.api?.setFilterModel(model);
    return {};
  };

  //Dirty Commit const uploadFilterAndColumn = async () => {
  //   const columnState = await getColumnSetting();
  //   const filterState = await getFilterSetting();

  //Dirty Commit   postOnFilter({
  //     userId: `${userId}`,
  //     companyId,
  //     filterData: {
  //       ...filterState,
  //       secondaryCaptableColumnModel: columnState,
  //       secondaryCaptableFilterModel: filterState,
  //     },
  //   });
  // };

  const cin = "";
  const componentsRegistery = useMemo(
    () => ({
      shareholderName: memo(ShareholderNameRender),
      preHoldingFdbShares: memo(PreHoldingFdbSharesRender),
      postHoldingFdbShares: memo(PostHoldingFdbSharesRender),
      preHoldingFdbPercentage: memo(PreHoldingFdbPercentage),
      postHoldingFdbPercentage: memo(PostHoldingFdbPercentage),
      amountInvested: memo(AmountInvestedRender),
      totalAmountInvested: memo(TotalAmountInvestedRender),
      numberOfShares: memo(NumberOfSharesRender),
      networth: memo(NetworthRender),
      transactionAmount: memo(TransactionAmountRender),
      preHoldingCommonShares: memo(PreHoldingCommonSharesRender),
      postHoldingCommonShares: memo(PostHoldingCommonSharesRender),
      preHoldingPref: memo(PreHoldingPrefRender),
      postHoldingPref: memo(PostHoldingPrefRender),
      preHoldingDeb: memo(PreHoldingDebRender),
      postHoldingDeb: memo(PostHoldingDebRender),
    }),
    []
  );

  const navigate = useNavigate();

  const onClickShareholderName = (id: string) => {
    handleEventForTracking({ eventName: `Shareholder Name` });
    navigate(`/shareholders/individual/${id}`);
  };

  const isValidDate = (date: string) =>
    Number.isNaN(date) &&
    new Date(date).toString() !== "Invalid Date" &&
    !Number.isNaN(Date.parse(date));

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      autoHeight: true,
      wrapHeaderText: true,
      suppressColumnVirtualisation: true,
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      filterParams: {
        buttons: ["reset"],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
      flex: 1,
    }),
    []
  );

  const columnDefs: any[] = [
    {
      headerName: "Name",
      field: "shareholderName",
      getQuickFilterText: (params: any) => params.value.name,
      filter: "agMultiColumnFilter",
      comparator(valueA: any, valueB: any, ..._: any[]) {
        return valueA.name > valueB.name ? 1 : -1;
      },
      filterParams: {
        maxNumConditions: 5,
        buttons: ["reset"],
        filters: [
          {
            title: "Name",
            display: "subMenu",
            filter: "agTextColumnFilter",
            buttons: ["reset"],
            valueGetter: (params: any) => params.value.name,
            filterValueGetter: (params: any) => params.value.name,
            filterParams: {
              buttons: ["reset"],
              valueGetter: (params: any) =>
                params.getValue("shareholderName").name,
            },
          },
          {
            title: "Type",
            filter: "agSetColumnFilter",
            display: "subMenu",
            buttons: ["reset"],
            valueGetter: (params: any) => params.value.name,
            filterParams: {
              buttons: ["reset"],
              keyCreator: (params: any) => {
                const shareholderType = params.value.type;
                return shareholderType;
              },
              valueFormatter: (params: any) => {
                const shareholderType = params.value.type;
                return shareholderType;
              },
            },
          },
        ],
      },
      cellRenderer: "shareholderName",
      initialWidth: 300,
      sortable: true,
      autoHeight: true,
      cellStyle: tableCellStyle,
      wrapText: true,
      suppressSizeToFit: true,
      menuTabs: ["filterMenuTab"],
      pinned: "left",
      tooltipField: "tooltipShareholderName",
    },
    {
      headerName: "Pre-Holding FDB Shares",
      field: "preHoldingFdbShares",
      filter: "agNumberColumnFilter",
      cellRenderer: "preHoldingFdbShares",
      cellStyle: tableCellStyle,
      sortable: true,
      menuTabs: ["filterMenuTab"],
      tooltipField: "tooltipPreHoldingFDBShares",
    },
    {
      headerName: "Post-Holding FDB Shares",
      field: "postHoldingFdbShares",
      filter: "agNumberColumnFilter",
      cellRenderer: "postHoldingFdbShares",
      cellStyle: tableCellStyle,
      sortable: true,
      menuTabs: ["filterMenuTab"],
      tooltipField: "tooltipPostHoldingFDBShares",
    },
    {
      headerName: "Pre-Holding FDB %",
      field: "preHoldingFdbPercentage",
      filter: "agNumberColumnFilter",
      cellRenderer: "preHoldingFdbPercentage",
      cellStyle: tableCellStyle,
      sortable: true,
      menuTabs: ["filterMenuTab"],
      tooltipField: "tooltipPreHoldingFdbPercentage",
    },
    {
      headerName: "Post-Holding FDB %",
      field: "postHoldingFdbPercentage",
      filter: "agNumberColumnFilter",
      cellRenderer: "postHoldingFdbPercentage",
      cellStyle: tableCellStyle,
      sortable: true,
      menuTabs: ["filterMenuTab"],
      tooltipField: "tooltipPostHoldingFdbPercentage",
    },
  ];

  const currencyType = getCurrencyType();

  const rowData = useMemo(
    () =>
      capTableData?.map((template) => ({
        id: template.id,
        preHoldingFdbShares: template.preHoldingFdbShares,
        tooltipPreHoldingFDBShares:
          template.preHoldingFdbShares?.toLocaleString(currencyType),
        preHoldingFdbPercentage: Number(
          (Math.abs(template.preHoldingFdbPercentage) * 100).toFixed(2)
        ),
        tooltipPreHoldingFdbPercentage:
          Math.abs(template.preHoldingFdbPercentage) * 100,

        postHoldingFdbShares: template.postHoldingFdbShares,
        tooltipPostHoldingFDBShares:
          template.postHoldingFdbShares?.toLocaleString(currencyType),
        postHoldingFdbPercentage: Number(
          (Math.abs(template.postHoldingFdbPercentage) * 100).toFixed(2)
        ),
        tooltipPostHoldingFdbPercentage: (
          Math.abs(template.postHoldingFdbPercentage) * 100
        ).toLocaleString(),
        shareholderName: {
          name: template.name,
          type: template.type,
        },
        tooltipShareholderName: template.name,
        shareholderType: template.type,
        preHoldingPref: template.preHoldingPrefShares,
        tooltipPreHoldingPref:
          template.preHoldingPrefShares?.toLocaleString(currencyType),
        postHoldingPref: template.postHoldingPrefShares,
        tooltipPostHoldingPref:
          template.postHoldingPrefShares?.toLocaleString(currencyType),
        preHoldingDeb: template.preHoldingDebentures,
        tooltipPreHoldingDeb:
          template.preHoldingDebentures?.toLocaleString(currencyType),
        postHoldingDeb: template.postHoldingDebentures,
        tooltipPostHoldingDeb:
          template.postHoldingDebentures?.toLocaleString(currencyType),
        currencySymbol,
        currencyType,
      })),
    [capTableData]
  );

  function setTableHeight() {
    if (displayedRowCount === 1) {
      return (displayedRowCount + 5) * 60;
    } else if (displayedRowCount === 2) {
      return (displayedRowCount + 3) * 60;
    } else if (displayedRowCount === 3) {
      return (displayedRowCount + 3) * 60;
    } else if (displayedRowCount === 4) {
      return (displayedRowCount + 2.55) * 60;
    } else if (displayedRowCount === 5) {
      return (displayedRowCount + 2.48) * 60;
    } else if (displayedRowCount === 6) {
      return (displayedRowCount + 2.42) * 60;
    } else if (displayedRowCount === 7) {
      return (displayedRowCount + 2.35) * 60;
    } else if (displayedRowCount === 8) {
      return (displayedRowCount + 2.28) * 60;
    } else if (displayedRowCount === 9) {
      return (displayedRowCount + 2.22) * 60;
    } else {
      return 10 * 60;
    }
  }

  const handleCellClick = (cellParams: CellClickedEvent<any, any>) => {
    if (cellParams.column.getColId() !== "action-column") {
      const template = cellParams.data;
      if (cellParams.column.getColId() === "shareholderName") {
        onClickShareholderName(template.id);
      }
    }
  };

  const [isColumnOpen, setIsColumnOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const openToolPanel = (key: any) => {
    if (key === "columns") {
      if (gridApi) {
        if (!isColumnOpen) gridApi?.current?.api?.openToolPanel(key);
        else gridApi?.current?.api?.closeToolPanel();
        setIsColumnOpen((state) => !state);
        setIsFilterOpen(false);
      }
    } else if (key === "filters") {
      if (gridApi) {
        if (!isFilterOpen) gridApi?.current?.api?.openToolPanel(key);
        else gridApi?.current?.api?.closeToolPanel();
        setIsFilterOpen((state) => !state);
        setIsColumnOpen(false);
      }
    }
  };

  function setPinnedBottomRowData({ api }: { api: any }) {
    const data = api.rowModel.rootNode;
    const filteredData = data.childrenAfterAggFilter.map(
      (element: any) => element.data
    );
    const totalPreholdingFdbShares = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + data.preHoldingFdbShares,
      0
    );

    const totalPostholdingFdbShares = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + data.postHoldingFdbShares,
      0
    );

    const totalAmountInvest = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + data.totalAmountInvested,
      0
    );

    const amountInvest = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + data.amountInvested,
      0
    );

    const totalNumberOfShares = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + data.numberOfShares,
      0
    );

    const totalNetWorth = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + data.netWorth,
      0
    );

    const totalTransactionAmount = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + data.transactionValue,
      0
    );

    const totalPreHoldingCommonShares = filteredData?.reduce(
      (accumulator: any, data: any) =>
        accumulator + data.preHoldingCommonShares,
      0
    );

    const totalPostholdingCommonShares = filteredData?.reduce(
      (accumulator: any, data: any) =>
        accumulator + data.postHoldingCommonShares,
      0
    );

    const totalpreholdinngPrefShares = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + data.preHoldingPrefShares,
      0
    );

    const totalPostholdingPrefShares = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + data.postHoldingPrefShares,
      0
    );

    const totalPreholdingDeb = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + data.preHoldingDebentures,
      0
    );

    const totalPostholdingDeb = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + data.postHoldingDebentures,
      0
    );

    const totalPreholdingFdbPercentage = filteredData?.reduce(
      (accumulator: any, data: any) =>
        accumulator + data.preHoldingFdbPercentage,
      0
    );

    const totalPostholdingFdbPercentage = filteredData?.reduce(
      (accumulator: any, data: any) =>
        accumulator + data.postHoldingFdbPercentage,
      0
    );

    api.setPinnedBottomRowData([
      {
        shareholderName: {
          name: "Total",
          type: null,
        },

        rounds: null,
        preHoldingFdbShares: totalPreholdingFdbShares,
        tooltipPreHoldingFDBShares:
          totalPreholdingFdbShares.toLocaleString(currencyType),
        preHoldingFdbPercentage:
          totalPreholdingFdbPercentage > 99.98
            ? 100
            : Number(totalPreholdingFdbPercentage.toFixed(2)),
        tooltipPreHoldingFdbPercentage:
          totalPreholdingFdbPercentage > 99.98
            ? 100
            : Number(totalPreholdingFdbPercentage.toFixed(2)),

        postHoldingFdbShares: totalPostholdingFdbShares,
        tooltipPostHoldingFDBShares:
          totalPostholdingFdbShares.toLocaleString(currencyType),
        postHoldingFdbPercentage: Number(
          totalPostholdingFdbPercentage > 99.98
            ? 100
            : Number(totalPostholdingFdbPercentage.toFixed(2))
        ),
        tooltipPostHoldingFdbPercentage:
          totalPostholdingFdbPercentage > 99.98
            ? 100
            : Number(totalPostholdingFdbPercentage.toFixed(2)),

        amountInvested: amountInvest,
        tooltipAmountInvested: amountInvest?.toLocaleString(currencyType),
        totalAmountInvested: totalAmountInvest,
        tooltipTotalAmountInvested:
          totalAmountInvest?.toLocaleString(currencyType),

        numberOfShares: totalNumberOfShares,
        tooltipNoOfShares: totalNumberOfShares.toLocaleString(currencyType),
        networth: totalNetWorth,
        tooltipNetworth: totalNetWorth?.toLocaleString(currencyType),
        transactionAmount: totalTransactionAmount,
        tooltipTransactionAmount:
          totalTransactionAmount?.toLocaleString(currencyType),
        preHoldingCommonShares: totalPreHoldingCommonShares,
        tooltipPreHoldingCommonShares:
          totalPreHoldingCommonShares?.toLocaleString(currencyType),
        postHoldingCommonShares: totalPostholdingCommonShares,
        tooltipPostHoldingCommonShares:
          totalPostholdingCommonShares?.toLocaleString(currencyType),
        preHoldingPref: totalpreholdinngPrefShares,
        tooltipPreHoldingPref:
          totalpreholdinngPrefShares?.toLocaleString(currencyType),
        postHoldingPref: totalPostholdingPrefShares,
        tooltipPostHoldingPref:
          totalPostholdingPrefShares?.toLocaleString(currencyType),
        preHoldingDeb: totalPreholdingDeb,
        tooltipPreHoldingDeb: totalPreholdingDeb?.toLocaleString(currencyType),
        postHoldingDeb: totalPostholdingDeb,
        tooltipPostHoldingDeb:
          totalPostholdingDeb?.toLocaleString(currencyType),
        currencyType,
      },
    ]);
  }

  const [filteredRowData, setFilteredRowData] = useState<any>([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const onAgGridFilterChanged = (grid: any) => {
    const filtersApplied = grid.api.isAnyFilterPresent();
    setIsFilterApplied(filtersApplied);

    const filteredData = grid.api
      .getModel()
      .rowsToDisplay.map((node: any) => node.data);
    setFilteredRowData(filteredData);
    setPinnedBottomRowData(grid);
    //Dirty Commit uploadFilterAndColumn();
  };

  return (
    <div className="bg-white rounded-md border border-borderColor shadow-box">
      <HStack className="flex-col lg:flex-row justify-start lg:justify-between  py-2 lg:items-center items-start">
        <GenericTableHeader
          heading={"Cap Table"}
          subHeading={"Shareholders"}
          count={
            isFilterApplied === true ? filteredRowData.length : rowData.length
          }
        />
        <HStack className="justify-between w-full lg:w-auto lg:justify-end py-2 items-center gap-4 px-6">
          <SearchInput
            placeholder={`Search`}
            onChange={(e: any) => {
              gridApi.current.api.setQuickFilter(e.target.value);
            }}
          />
          <IconCTAButton
            value={"Columns"}
            onClick={() => openToolPanel("columns")}
            iconName={"fluent:column-triple-edit-20-regular"}
            className={`px-4 font-medium items-center flex flex-row ${
              isColumnOpen ? "text-orange-501" : "text-gray-400"
            }`}
            selected={isColumnOpen}
          />
          <IconCTAButton
            value={"Filters"}
            onClick={() => openToolPanel("filters")}
            iconName={"material-symbols:filter-alt"}
            className={`px-4 font-medium items-center flex flex-row ${
              isFilterOpen ? "text-orange-501" : "text-gray-400"
            }`}
            selected={isFilterOpen}
          />
        </HStack>
      </HStack>
      <HStack className="justify-between w-full">
        <Box
          style={{
            height: setTableHeight(),
          }}
          className="w-full max-h-full overflow-x-auto ag-theme-material h-full"
        >
          <AgGridReact
            sideBar={agConfigDashboardSideBar}
            onGridReady={(params) => {
              // Store the grid API referen
              gridApi.current = params;
            }}
            components={componentsRegistery}
            suppressScrollOnNewData={true}
            alwaysShowHorizontalScroll={false}
            alwaysShowVerticalScroll={false}
            alwaysMultiSort
            animateRows={true}
            defaultColDef={defaultColDef}
            onRowDataUpdated={setPinnedBottomRowData}
            onFilterChanged={onAgGridFilterChanged}
            //Dirty Commit onColumnEverythingChanged={uploadFilterAndColumn}
            rowData={rowData}
            enableCharts={true}
            enableRangeSelection={true}
            onCellClicked={handleCellClick}
            columnDefs={columnDefs}
            pagination={true}
            suppressRowTransform={true}
            suppressCopyRowsToClipboard={true}
            suppressCopySingleCellRanges={true}
            //Dirty Commit onColumnResized={uploadFilterAndColumn}
            suppressCellFocus={true}
            suppressMenuHide={false}
            tooltipShowDelay={1000}
            tooltipInteraction={true}
            rowClass={"border-t border-dashed "}
            overlayNoRowsTemplate={
              '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow; margin-top: 50px;">No Rows To Show</span>'
            }
            getRowStyle={(params) => {
              if (params.rowIndex % 2 === 0) {
                return { background: "#f8f8f8" };
              } else {
                return { background: "#ffffff" };
              }
            }}
          ></AgGridReact>
        </Box>
      </HStack>
    </div>
  );
}

export default AGGridRoundsSummaryTable;
