import { useRef, useState } from "react";
import { Box, HStack, VStack } from "../../components/utils";
import {
  tableEntryStyle,
  tableFirstColStyle,
} from "../../components/TableComponent";
import { formatDisplayDate } from "../../utils/date";

import {
  formatToReadableNumber,
  getCurrencySymbolRoboto,
} from "../../utils/currencyRoboto";
import { getCurrencyType } from "../../utils/currencyFormatter";
import { limitString } from "../../utils/string";
import { checkVoid } from "../../utils/transactionUtils";

export function SecurityNameRender(data: any) {
  return (
    <div className="pt-2 whitespace-nowrap">
      <p className={tableFirstColStyle}>
        {limitString(data.data.securityName, 15)}
      </p>
    </div>
  );
}

export function RankRender(data: any) {
  return (
    <div className="pt-2 whitespace-nowrap">
      <p className={tableEntryStyle}>{data.data.rank}</p>
    </div>
  );
}

export function MultipleRender(data: any) {
  return (
    <div className="pt-2 whitespace-nowrap">
      <p className={tableEntryStyle}>
        {data.rowIndex > 0 && data.node.level !== 0
          ? `${data.data.multiple} X`
          : "-"}
      </p>
    </div>
  );
}

export function LpValueRender(data: any) {
  return (
    <div className="pt-2 whitespace-nowrap">
      {formatToReadableNumber({
        value: data.data?.lpValue,
        format: data.data.currency,
      })}
    </div>
  );
}

export function ParticipationRender(data: any) {
  return data.data.isParticipation && data.node.level !== 0 ? (
    <div className="whitespace-nowrap">
      <p>Yes</p>

      <p className={` text-xxs ${tableEntryStyle} text-gray-500`}>
        {checkVoid(data.data.participation) ? (
          "-"
        ) : (
          <HStack>
            {data.rowIndex > 0 && (
              <p className="pr-2">CAP : {data.data.currencySymbol}</p>
            )}
            {data.rowIndex > 0 &&
              formatToReadableNumber({
                value: data.data?.participation,
                format: data.data.currency,
              })}
          </HStack>
        )}
      </p>
    </div>
  ) : (
    <div className="whitespace-nowrap">
      {data.rowIndex > 0 && data.node.level !== 0 ? (
        <p className={`${tableEntryStyle} pt-2`}>No</p>
      ) : (
        <p className={`${tableEntryStyle} pt-2`}>-</p>
      )}
    </div>
  );
}
