import { useState } from "react";
import { Dialog } from "@mui/material";
import { isNumber } from "lodash";
import { HStack, VStack } from "../../components/utils";
import { Label } from "../../components/shared/InputField";
import { RoundCreationModel } from "./RoundCreationModel";
import {
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../quickRound/CTAButtonComponents";
import DeleteModal from "./DeleteModal";
import AddNewSecurity from "./AddNewSecurity";
import NewSecondaryTable from "./NewSecondaryTable";
import AntiDilutionCaptable from "./AntiDilutionCaptable";
import CardDetailHeader from "./CardDetailHeader";
import { FormatNumberSpan } from "../../utils/currencyRoboto";

export default function AntiDilutionPage({
  data,
  onBack,
}: {
  data: RoundCreationModel;
  onBack: () => void;
}) {
  const [dialog, setDialog] = useState<{
    open: boolean;
    mode?: "Add" | "Edit" | "Delete";
    data?: string;
  }>({
    open: false,
  });
  return (
    <VStack className=" bg-white justify-between rounded-lg px-4 py-4 min-h-full">
      <Dialog open={dialog.open} maxWidth="lg">
        {dialog.mode === "Add" && <div></div>}
        {dialog.mode === "Edit" && <div></div>}
        {dialog.mode === "Delete" && (
          <DeleteModal
            onPrimaryAction={() => setDialog({ open: false })}
            onSecondaryAction={() => setDialog({ open: false })}
            data={
              "Are you sure you want to delete the entry? Data will be permanently removed. This action cannot be undone."
            }
          />
        )}
      </Dialog>

      <div>
        <Label className="text-xl font-semibold text-gray-dark">
          Anti Dilution
        </Label>

        <p className="font-medium text-gray-light mb-4">
          Select Method of Anti dilution
        </p>
        <VStack className="mx-4 flex-wrap shadow-box justify-evenly mb-8 px-4 py-2 rounded-sm bg-white">
          <Label className="text-lg font-medium text-[#464E5F] ">
            Anti Dilution Details
          </Label>

          <HStack className="justify-between py-6">
            {[
              {
                name: "Additional Dilution(%)",
                value: 15,
              },
              {
                name: "No. of Shares added",
                value: 234243,
              },
              {
                name: "Method",
                value: "Board Based Weighted Average",
              },
            ].map((element) => (
              <VStack key={element.name}>
                <p className="text-sm text-[#464E5F] font-medium whitespace-nowrap pb-2">
                  {element.name}
                </p>

                <HStack className="items-center">
                  <p className="text-lg text-[#464E5F] font-medium">
                    {isNumber(element.value) ? (
                      <FormatNumberSpan value={element.value} />
                    ) : (
                      element.value
                    )}
                  </p>
                </HStack>
              </VStack>
            ))}
          </HStack>
        </VStack>
        <VStack className="mx-12">
          <Label className="text-lg font-medium text-[#464E5F]">
            Cap Table post Anti Dilution
          </Label>
          {/* {data.tab8 && ( */}
          <AntiDilutionCaptable data={[]} />
          {/* )} */}
        </VStack>
      </div>
      <HStack className="justify-between gap-4">
        <HStack>
          <SecondaryCTAButton
            type="reset"
            className="text-red-500 shadow-box"
            onClick={() => {}}
          >
            Back
          </SecondaryCTAButton>
        </HStack>
        <PrimaryCTAButton className="min-w-max" type="submit">
          Next
        </PrimaryCTAButton>
      </HStack>
    </VStack>
  );
}
