import {
  getCurrencySymbol,
  getCurrencyType,
  getCurrencyTypeforDecimals,
} from "../../../../utils/currencyFormatter";

export function TimeLineRenderer(data: any) {
  const keys = Object.keys(data.data);

  const filteredKeys = keys.filter(
    (key) => key !== "orgHierarchy" && key !== "tooltipText"
  );

  filteredKeys.sort((a, b) => parseInt(b, 10) - parseInt(a, 10));

  const differences: any = {};

  for (let index = 0; index < filteredKeys.length - 1; index++) {
    const key = filteredKeys[index];
    const nextKey = filteredKeys[index + 1];

    const currentValue = parseFloat(data.data[key]);
    const previousValue = parseFloat(data.data[nextKey]);

    if (
      !Number.isNaN(currentValue) &&
      !Number.isNaN(previousValue) &&
      previousValue !== 0
    ) {
      const difference =
        ((currentValue - previousValue) / Math.abs(previousValue)) * 100;
      differences[key] = difference;
    }
  }

  return (
    <div className="whitespace-nowrap py-1">
      <p
        className={`text-left font-medium text-xs2 text-[#464E5F] ${
          data.node.level === 0 ? "text-black" : ""
        }`}
      >
        {" "}
        {getCurrencyTypeforDecimals(data.value)}
      </p>
      {filteredKeys.map(
        (key, index) =>
          data.column.colId === key &&
          differences[key] !== undefined &&
          index + 1 !== undefined &&
          differences[key] !== 0 && (
            <div
              key={key}
              className={`${
                differences[key] > 0
                  ? "text-green-600 text-xxs"
                  : "text-red-600 text-xxs"
              }`}
            >
              <p>{getCurrencyTypeforDecimals(differences[key])}%</p>
            </div>
          )
      )}
    </div>
  );
}
