import { useMutation, useQuery } from "react-query";
import {
  getSharedUserAndInitializeShare,
  submitEmailForVerification,
  submitOtpForVerification,
  submitSharedUser,
  updatePermission,
  updateResourceLive,
} from "../api/share";
import { queryClient } from "./client";
import { handleEventForTracking } from "../amplitudeAnalytics";

export function useGetSharedUserAndInitializeShare(
  resourceId: string,
  resourceType: string
) {
  return useQuery({
    queryKey: ["getSharedUserAndInitializeShare", resourceId, resourceType],
    queryFn: getSharedUserAndInitializeShare,
  });
}

export function useSubmitSharedEmail() {
  return useMutation({
    mutationKey: "submitSharedEmail",
    mutationFn: submitSharedUser,
    onSuccess: () => {
      handleEventForTracking({
        eventName: "inviteSharedUser",
        success: true,
        eventType: "API",
      });
      queryClient.invalidateQueries("getSharedUserAndInitializeShare");
      queryClient.refetchQueries("getSharedUserAndInitializeShare");
    },
    onError: () => {
      handleEventForTracking({
        eventName: "inviteSharedUser",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function useSubmitEmailForVerification() {
  return useMutation({
    mutationKey: "submitEmailForVerification",
    mutationFn: submitEmailForVerification,
    onSuccess: () => {
      handleEventForTracking({
        eventName: "Submit Email For Verification",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "Submit Email For Verification",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function useSubmitOtpForVerification() {
  return useMutation({
    mutationKey: "submitOtpForVerification",
    mutationFn: submitOtpForVerification,
    onSuccess: () => {
      handleEventForTracking({
        eventName: "Submit Otp For Verification",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "Submit Otp For Verification",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function useUpdatePermission() {
  return useMutation({
    mutationKey: "updatePermission",
    mutationFn: updatePermission,
    onSuccess: () => {
      handleEventForTracking({
        eventName: "updatedSharedUser",
        success: true,
        eventType: "API",
      });
      queryClient.invalidateQueries("getSharedUserAndInitializeShare");
      queryClient.refetchQueries("getSharedUserAndInitializeShare");
    },
    onError: () => {
      handleEventForTracking({
        eventName: "updatedSharedUser",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function useUpdateResourceLive() {
  return useMutation({
    mutationKey: "updateResourceLive",
    mutationFn: updateResourceLive,
    onSuccess: () => {
      handleEventForTracking({
        eventName: "updatedResourceLive",
        success: true,
        eventType: "API",
      });
      queryClient.invalidateQueries("getSharedUserAndInitializeShare");
      queryClient.refetchQueries("getSharedUserAndInitializeShare");
    },
    onError: () => {
      handleEventForTracking({
        eventName: "updatedResourceLive",
        success: false,
        eventType: "API",
      });
    },
  });
}
