import create from "zustand";

import { ConvertibleModel } from "../../types/Modeling";

type NewConvertibleModelState = {
  convertibleModel: ConvertibleModel[];

  setConvertibleModel: (convertibleModel: ConvertibleModel[]) => void;

  reset: () => void;
};
const initialState = {
  convertibleModel: [],
};

const newConvertibleModelStore = create<NewConvertibleModelState>((set) => ({
  ...initialState,
  setConvertibleModel: (value) => {
    set((state) => ({
      ...state,
      convertibleModel: value,
    }));
  },

  reset: () => {
    set((state) => ({
      ...state,
      convertibleModel: [],
    }));
  },
}));

export const useNewConvertibleModelStore = newConvertibleModelStore;
