export const SecurityTypeList = [
  "Equity",
  "CCPS",
  "CCD",
  "OCD",
  "Note",
  "NCD",
  "RPS",
  "OCPS",
  "Warrant",
];

export const NonPricedSecurityTypeList = ["CCPS", "CCD", "OCD", "Note"];

export const prefrenceColorList = [
  "rgba(102, 197, 204, 0.8)",
  "rgba(246, 207, 113, 0.8)",
  "rgba(248, 156, 116, 0.8)",
  "rgba(220, 176, 242, 0.8)",
  "rgba(135, 197, 95, 0.8)",
  "rgba(158, 185, 243, 0.8)",
  "rgba(254, 136, 177, 0.8)",
  "rgba(201, 219, 116, 0.8)",
  "rgba(139, 224, 164, 0.8)",
  "rgba(180, 151, 231, 0.8)",
  "rgba(211, 180, 132, 0.8)",
  "rgba(179, 179, 179, 0.8)",
];
