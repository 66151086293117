import { useQuery } from "react-query";
import {
  getAllRoundModelNames,
  getEvents,
  getListOfNewInvestorModels,
  getRoundModelCaptable,
  getSubsidiaryCompanies,
  getSubsidiaryCompanyCaptable,
} from "../api/capTable";
import {
  randomAllRoundModelNames,
  randomRoundModelCaptable,
} from "./randomPlaceholderData";

export function useGetAllEvents(companyId: string, cinNumber?: string | null) {
  return useQuery({
    queryKey: ["getAllEvents", companyId, cinNumber],
    queryFn: () => getEvents(companyId, cinNumber),
  });
}

export function useGetAllRoundModelNames(roundModelState?: string) {
  return useQuery({
    queryKey: ["getAllRoundModelNames", roundModelState],
    queryFn: getAllRoundModelNames,
    placeholderData: randomAllRoundModelNames(),
  });
}

export function useGetRoundModelCaptable(modelId: string) {
  return useQuery({
    queryKey: ["getRoundModelCaptable", modelId],
    queryFn: getRoundModelCaptable,
    enabled: false,
    placeholderData: randomRoundModelCaptable(),
  });
}

export function useGetSubsidiaryCompanies() {
  return useQuery({
    queryKey: "getSubsidiaryCompanies",
    queryFn: () => getSubsidiaryCompanies(),
  });
}

export function useGetSubsidiaryCompanyCaptable(subsidiaryCompanyId: string) {
  return useQuery({
    queryKey: ["getSubsidiaryCompanyCaptable", subsidiaryCompanyId],
    queryFn: () => getSubsidiaryCompanyCaptable(subsidiaryCompanyId),
    enabled: false,
  });
}

export function useGetListOfNewInvestorModels(investorId?: string) {
  return useQuery({
    queryKey: ["getListOfNewInvestorModels", investorId],
    queryFn: getListOfNewInvestorModels,
  });
}
