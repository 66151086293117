import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Input, Label } from "../../components/shared/InputField";
import { Select } from "../../components/shared/Select";
import { HStack, VStack, Error } from "../../components/utils";
import { useNewEntryStore } from "../../store/newlyAddedShareholderStore";
import { useShareholderInstrumentStore } from "../../store/shareholderInstrumentStore";
import {
  AddSecondaryTransactionModalType,
  SecondaryTransactionDataModel,
} from "../../types/quickRound";
import { getCurrencySymbol } from "../../utils/currencyFormatter";
import {
  ButtonSize,
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "./CTAButtonComponents";

type AddSecondaryTransactionModalProps = {
  initialValue?: SecondaryTransactionDataModel;
  onPrimaryAction: (secondary: AddSecondaryTransactionModalType) => void;
  onSecondaryAction: () => void;
  mode: "Add" | "Edit";
};

const AddSecondaryTransactionModal = ({
  initialValue,
  onPrimaryAction = () => {},
  onSecondaryAction = () => {},
  mode,
}: AddSecondaryTransactionModalProps) => {
  const currencySymbol = getCurrencySymbol();
  const newEntryStore = useNewEntryStore();
  const shareholderInstruments = useShareholderInstrumentStore();
  const shareHolders: string[] = shareholderInstruments.shareholders.map(
    (shareHolderDetail) => shareHolderDetail.name
  );
  const instruments: string[] = shareholderInstruments.instrumentClasses.map(
    (instrument) => instrument.name
  );

  const initialShareHolderValues: AddSecondaryTransactionModalType = {
    buyerName: initialValue?.buyerName || "",
    sellerName: initialValue?.sellerName || "",
    date: initialValue?.date || "",
    security: initialValue?.security || "",
    numberOfShares: initialValue?.numberOfShares || 0,
    amountInvested: initialValue?.amountInvested || 0,
  };

  const validationShareHolderSchema = Yup.object().shape({
    buyerName: Yup.string().required().label("Buyer Name"),
    sellerName: Yup.string().required().label("Seller Name"),
    date: Yup.date().required().label("Date"),
    security: Yup.string().required().label("Security"),
    numberOfShares: Yup.number()
      .integer()
      .positive()
      .min(1)
      .required()
      .label("No.of Shares"),
    amountInvested: Yup.number()
      .positive()
      .required()
      .label("Transaction Amount"),
  });

  const handleSubmit = (values: AddSecondaryTransactionModalType) => {
    const transaction: SecondaryTransactionDataModel = {
      buyerName: values.buyerName,
      sellerName: values.sellerName,
      investorType: shareholderInstruments.shareholders.find(
        (shareHolderDetail) => shareHolderDetail.name === values.buyerName
      )?.name,
      security: values.security,
      image: "",
      instrumentClassId: shareholderInstruments.instrumentClasses.find(
        (instrumentDetail) => instrumentDetail.name === values.security
      )?.id,
      par: shareholderInstruments.instrumentClasses.find(
        (instrumentDetail) => instrumentDetail.name === values.security
      )?.parValue,
      conversionRatio: `1: ${
        shareholderInstruments.instrumentClasses.find(
          (instrumentDetail) => instrumentDetail.name === values.security
        )?.conversionRatio
      }`,
      buyerInvestorId: shareholderInstruments.shareholders.find(
        (shareHolderDetail) => shareHolderDetail.name === values.buyerName
      )?.id,
      sellerInvestorId: shareholderInstruments.shareholders.find(
        (shareHolderDetail) => shareHolderDetail.name === values.sellerName
      )?.id,
      numberOfShares: values.numberOfShares,
      amountInvested: values.amountInvested,
      date: values.date,
    };
    onPrimaryAction(transaction);
  };

  return (
    <Formik
      key="secondaryShareHolder"
      enableReinitialize={true}
      initialValues={initialShareHolderValues}
      onSubmit={(values) => {
        handleSubmit(values);
      }}
      validationSchema={validationShareHolderSchema}
    >
      {(formik) => (
        <Form key="shareholder">
          <div className="w-full bg-white rounded-md px-2 border">
            <HStack className="text-lg text-left font-medium border-b py-4">
              <h6 className="flex-1">{mode} Transaction</h6>
            </HStack>
            <VStack className="w-full  px-2 py-4 gap-6">
              <VStack className="gap-8">
                <HStack className="gap-4 md:flex-row flex-col">
                  <div className="flex-1">
                    <Label className="text-sm font-normal">Buyer Name</Label>
                    <Select
                      options={shareHolders.filter(
                        (shareHolder) =>
                          shareHolder.toLowerCase().trim() !== "esop pool" &&
                          shareHolder !== formik.values.sellerName
                      )}
                      {...formik.getFieldProps("buyerName")}
                    />
                    {formik.touched?.buyerName && formik.errors?.buyerName && (
                      <Error text={formik.errors?.buyerName} />
                    )}
                  </div>
                  <div className="flex-1">
                    <Label className="text-sm font-normal">Seller Name</Label>
                    <Select
                      options={shareHolders.filter(
                        (shareHolder) =>
                          shareHolder !== "ESOP pool" &&
                          !newEntryStore.newEntry.includes(shareHolder) &&
                          shareHolder !== formik.values.buyerName
                      )}
                      {...formik.getFieldProps("sellerName")}
                    />
                    {formik.touched?.sellerName &&
                      formik.errors?.sellerName && (
                        <Error text={formik.errors?.sellerName} />
                      )}
                  </div>
                </HStack>
                <HStack className="gap-4  md:flex-row flex-col">
                  <div className="flex-1">
                    <Label className="text-sm font-normal">Security</Label>
                    <Select
                      options={instruments}
                      {...formik.getFieldProps("security")}
                    />
                    {formik.touched.security && formik.errors?.security && (
                      <Error text={formik.errors?.security} />
                    )}
                  </div>
                  <div className="flex-1">
                    <Label className="text-sm font-normal">Date</Label>
                    <Input
                      type="date"
                      placeholder="Eg:DD/MM/YYYY"
                      {...formik.getFieldProps("date")}
                    />
                    {formik.touched?.date && formik.errors?.date && (
                      <Error text={formik.errors?.date} />
                    )}
                  </div>
                </HStack>
                <HStack className="gap-4  md:flex-row flex-col">
                  <div className="flex-1">
                    <Label className="text-sm font-normal">No.of Shares</Label>
                    <Input
                      type="number"
                      placeholder="Enter No.of Shares"
                      {...formik.getFieldProps("numberOfShares")}
                    />
                    {formik.touched?.numberOfShares &&
                      formik.errors?.numberOfShares && (
                        <Error text={formik.errors?.numberOfShares} />
                      )}
                  </div>
                  <div className="flex-1">
                    <Label className="text-sm font-normal">
                      Transaction Amount ({currencySymbol})
                    </Label>
                    <Input
                      type="number"
                      placeholder="Enter Transaction Amount"
                      {...formik.getFieldProps("amountInvested")}
                    />
                    {formik.touched?.amountInvested &&
                      formik.errors?.amountInvested && (
                        <Error text={formik.errors?.amountInvested} />
                      )}
                  </div>
                </HStack>
              </VStack>

              <HStack className="justify-between h-8">
                <SecondaryCTAButton
                  event-name="Cancel Secondary Transaction Modal"
                  buttonSize={ButtonSize.SMALL}
                  onClick={() => {
                    if (mode === "Edit") {
                      handleSubmit({
                        buyerName: initialValue?.buyerName || "",
                        sellerName: initialValue?.sellerName || "",
                        date: initialValue?.date || "",
                        security: initialValue?.security || "",
                        numberOfShares: initialValue?.numberOfShares || 0,
                        amountInvested: initialValue?.amountInvested || 0,
                      });
                    } else {
                      onSecondaryAction();
                    }
                  }}
                >
                  Cancel
                </SecondaryCTAButton>
                <PrimaryCTAButton
                  event-name="Save Secondary Transaction Modal"
                  buttonSize={ButtonSize.SMALL}
                  type="submit"
                >
                  Save
                </PrimaryCTAButton>
              </HStack>
            </VStack>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddSecondaryTransactionModal;
