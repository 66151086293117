import { Line } from "react-chartjs-2";
import { financeLineChartoptions } from "../../../shared/chart-data";
import {
  formatCurrency,
  getCurrencySymbol,
  getCurrencyType,
} from "../../../utils/currencyFormatter";
import { formatNumberCroreAndLakhInNegativeAndPositive } from "../../../utils/currencyRoboto";

function LineChart(financialReportDetails: any) {
  const financialTableData: any[] = financialReportDetails.data;

  financialTableData.sort((a, b) => {
    if (a.timelineType === "Year" && b.timelineType === "Year") {
      return parseInt(b.year, 10) - parseInt(a.year, 10);
    } else if (
      a.timelineType === "Quarterly" &&
      b.timelineType === "Quarterly"
    ) {
      if (a.year !== b.year) {
        return b.year - a.year;
      } else {
        const quartersOrder = ["Q1", "Q2", "Q3", "Q4"];
        return (
          quartersOrder.indexOf(b.timeline) - quartersOrder.indexOf(a.timeline)
        );
      }
    } else if (a.timelineType === "Monthly" && b.timelineType === "Monthly") {
      if (a.year !== b.year) {
        return b.year - a.year;
      } else {
        const months = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        return months.indexOf(b.timeline) - months.indexOf(a.timeline);
      }
    } else {
      // If timelineType is different, maintain the current order
      return 0;
    }
  });
  const currencyType = getCurrencyType();
  const labels = financialTableData?.map((item) =>
    item.timelineType !== "Year" ? `${item.year}-${item.timeline}` : item.year
  );
  const revenues = financialTableData.map((item) =>
    Number(item?.data.totalRevenue)
  );
  const profits = financialTableData.map((item) =>
    Number(item?.data.profitOrLoss)
  );

  // const labels = plData.map((item) => item.year);
  // const plRecs: ProfitAndLoss[] = plData.map((item) => item.data[0]);
  // const revenues = plRecs.map((item) => item?.totalRevenue);
  // const profits = plRecs.map((item) => item?.profitOrLoss);
  const data = {
    labels,
    datasets: [
      {
        label: "Revenue",
        data: revenues,
        borderColor: "#3498db",
        backgroundColor: "#3498db",
      },
      {
        label: "Profit",
        data: profits,
        borderColor: "#07bc0c",
        backgroundColor: "#07bc0c",
      },
    ],
  };
  const lineChartOptions = financeLineChartoptions;
  const currency = getCurrencySymbol();
  lineChartOptions.scales.y.ticks.callback = (
    value: any,
    index: any,
    ticks: any
  ) => `${formatNumberCroreAndLakhInNegativeAndPositive(value, currencyType)}`;

  lineChartOptions.plugins.tooltip.callbacks.label = (context: any) => {
    const labels: any[] = [];
    const formattedValue = formatCurrency(context.raw, currencyType);
    labels.push(`${context.dataset?.label} : ${formattedValue}`);
    return labels;
  };

  return <Line data={data} options={financeLineChartoptions} />;
}

export default LineChart;
