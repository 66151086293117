import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CellClickedEvent, ColDef } from "ag-grid-community";
import { useNavigate } from "react-router";

import { AgGridReact } from "ag-grid-react";
import { agConfigDashboardSideBar } from "../dashboardPage/AgGridConfig";
import {
  getCurrency,
  getCurrencySymbol,
  getCurrencyType,
} from "../../utils/currencyFormatter";

import { Box, HStack, useGetCompanyName } from "../../components/utils";
import GenericTableHeader from "../../shared/TableHeader";
import { IconCTAButton } from "../quickRound/CTAButtonComponents";
import QuickSearch from "../rounds/QuickSearch";
import { useAuthStore } from "../../store";
import {
  useGetOnFilterState,
  usePostOnFilterState,
} from "../dashboardPage/AgGridCacheQuery";
import { tableCellStyle } from "../../components/TableComponent";
import {
  EventHolding,
  IndividualTermSheetPreCapTable,
  IndividualTermsheet,
} from "../../types/TermsheetModel";
import { useAgGridTableState } from "../../store/agGridTableStore";
import SearchInput from "../../components/shared/SearchInput";
import { handleEventForTracking } from "../../amplitudeAnalytics";
import { getRoundCurrencySymbol } from "../../utils/currencyRoboto";
import {
  AmountRender,
  PostHoldingPercentageRender,
  PostHoldingSharesRender,
  PreHoldingPercentageRender,
  PreHoldingSharesRender,
  ShareHolderNameRender,
} from "./TermsheetPreCaptableAgTableComponents";

export function GetPrePostCaptableData(captableData: IndividualTermsheet) {
  const captable: IndividualTermSheetPreCapTable[] = [];
  if (!captableData) {
    return captable;
  }
  captableData?.prePostCaptable?.preEventHoldings.forEach(
    (obj: EventHolding) => {
      captable.push({
        id: obj.id,
        preHoldingFdbShares: obj.fdbShares,
        postHoldingFdbShares: 0,
        preHoldingFdbPercentage: obj.fdbPercentage,
        postHoldingFdbPercentage: 0,
        amountInvestedInRound: 0,
        shareholderName: obj.name,
        shareholderType: obj.type,
      });
    }
  );

  if (
    captableData?.prePostCaptable?.postEventHoldings &&
    captableData?.prePostCaptable?.postEventHoldings.length > 0
  ) {
    captableData?.prePostCaptable.postEventHoldings[
      captableData?.prePostCaptable?.postEventHoldings &&
        captableData.prePostCaptable.postEventHoldings.length - 1
    ].postCaptable.forEach((obj: EventHolding) => {
      const index = captable.findIndex(
        (value) => value.shareholderName === obj.name
      );
      if (index > -1) {
        captable[index].postHoldingFdbPercentage = obj.fdbPercentage;
        captable[index].postHoldingFdbShares = obj.fdbShares;
        const investors = captableData?.termsheetData.termsheetData.investors;
        if (investors) {
          const investor = investors.find(
            (value) => value.shareholderName === obj.name
          );
          if (investor) {
            captable[index].amountInvestedInRound =
              investor.investmentAmount || 0;
          }
        }
      } else {
        captable.push({
          id: obj.id,
          preHoldingFdbShares: 0,
          postHoldingFdbShares: obj.fdbShares,
          preHoldingFdbPercentage: 0,
          postHoldingFdbPercentage: obj.fdbPercentage,
          amountInvestedInRound: obj.investedAmount,
          shareholderName: obj.name,
          shareholderType: obj.type,
        });
      }
    });
  }
  return captable;
}
function TermsheetPreCaptableAgTable({
  captableData,
}: {
  captableData: IndividualTermsheet;
}) {
  const _captableData: IndividualTermsheet = captableData;
  const companyName = useGetCompanyName();
  const companyId = useAuthStore().companyId ?? "";
  const userId = useAuthStore().user?.userId ?? 0;
  const gridApi = useRef<any>(null);
  const { refetch, data: filterState } = useGetOnFilterState(
    `${userId}`,
    companyId
  );

  const finalCaptableData = GetPrePostCaptableData(_captableData);
  const agTableStore = useAgGridTableState();
  useEffect(() => {
    refetch().then((data) => {
      if (data.data) agTableStore.setState(data.data);
      setColumnSetting(data.data?.termsheetColumnModel);
      setFilterSetting(data.data?.termsheetFilterModel);
    });
  }, []);

  const { mutate: postOnFilter } = usePostOnFilterState();

  function getColumnSetting() {
    if (gridApi.current) return gridApi.current.columnApi?.getColumnState();
    return {};
  }

  function setColumnSetting(model: any) {
    if (gridApi.current)
      gridApi.current.columnApi.applyColumnState({ state: model });
    return {};
  }

  // Gets filter model via the grid API
  const getFilterSetting = () => {
    if (gridApi.current) return gridApi.current.api?.getFilterModel();
    return {};
  };

  const setFilterSetting = (model: any) => {
    if (gridApi.current) return gridApi.current.api?.setFilterModel(model);
    return {};
  };

  const uploadFilterAndColumn = async () => {
    const columnState = await getColumnSetting();
    const filterState = await getFilterSetting();
    postOnFilter({
      userId: `${userId}`,
      companyId,
      filterData: {
        ...agTableStore.state,
        termsheetColumnModel: columnState,
        termsheetFilterModel: filterState,
      },
    });
  };

  const cin = "";

  const componentsRegistery = useMemo(
    () => ({
      shareholderName: memo(ShareHolderNameRender),
      preHoldingPercentage: memo(PreHoldingPercentageRender),
      postHoldingPercentage: memo(PostHoldingPercentageRender),
      preHoldingShares: memo(PreHoldingSharesRender),
      postHoldingShares: memo(PostHoldingSharesRender),
      amountInvested: memo(AmountRender),
    }),
    []
  );

  const navigate = useNavigate();

  const onClickTermsheetName = (id: string) => {
    handleEventForTracking({ eventName: "Term Sheet Name" });
    navigate(`/termsheet/overview/${id}`);
  };

  const isValidDate = (date: string) =>
    Number.isNaN(date) &&
    new Date(date).toString() !== "Invalid Date" &&
    !Number.isNaN(Date.parse(date));

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      autoHeight: true,
      wrapHeaderText: true,
      suppressColumnVirtualisation: true,
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      filterParams: {
        buttons: ["reset"],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
      flex: 1,
    }),
    []
  );
  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        headerName: "Shareholder Name",
        field: "shareholderName",
        cellRenderer: "shareholderName",
        initialWidth: 350,
        sortable: true,
        autoHeight: true,
        cellStyle: tableCellStyle,
        wrapText: true,
        suppressSizeToFit: true,
        filter: "agSetColumnFilter",
        menuTabs: ["filterMenuTab"],
        pinned: "left",
        tooltipField: "tooltipTermsheetName",
      },
      {
        headerName: "Pre Holding %",
        field: "preHoldingPercentage",
        cellRenderer: "preHoldingPercentage",
        initialWidth: 250,
        filter: "agNumberColumnFilter",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipPreHoldingPercentage",
      },
      {
        headerName: "Post Holding %",
        field: "postHoldingPercentage",
        cellRenderer: "postHoldingPercentage",
        initialWidth: 250,
        filter: "agNumberColumnFilter",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipPostHoldingPercentage",
      },
      {
        headerName: "Pre Holding FDB Shares",
        field: "preHoldingShares",
        cellRenderer: "preHoldingShares",
        initialWidth: 250,
        filter: "agNumberColumnFilter",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipPreHoldingShares",
      },
      {
        headerName: "Post Holding FDB Shares",
        field: "postHoldingShares",
        cellRenderer: "postHoldingShares",
        initialWidth: 250,
        filter: "agNumberColumnFilter",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipPostHoldingShares",
      },
      {
        headerName: "Amount Invested Via Termsheet",
        field: "amountInvested",
        cellRenderer: "amountInvested",
        initialWidth: 250,
        filter: "agNumberColumnFilter",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipAmountInvested",
      },
    ],
    []
  );
  const gridOptions = {
    suppressRowVirtualisation: true, // Add this line to disable row virtualization
    paginationAutoPageSize: false, // Optional: Set to false if using custom pagination
    suppressScrollOnNewData: true, // Optional: Set to true to prevent scrolling to the top on
  };
  const currencyType = getCurrencyType();
  const currencySymbol = getCurrencySymbol();
  const rowData = useMemo(
    () =>
      finalCaptableData
        ?.filter(
          (template) =>
            template.postHoldingFdbPercentage !== 0 &&
            template.postHoldingFdbShares !== 0
        )
        ?.sort((a, b) => b.amountInvestedInRound - a.amountInvestedInRound)
        ?.map((template) => ({
          id: template.id,
          shareholderName: template.shareholderName
            .toLowerCase()
            .includes("esop")
            ? template.shareholderName.replace(/esop/gi, "ESOP")
            : template.shareholderName,
          preHoldingPercentage:
            template.preHoldingFdbPercentage &&
            template.preHoldingFdbPercentage * 100,
          postHoldingPercentage:
            template.postHoldingFdbPercentage &&
            template.postHoldingFdbPercentage * 100,
          preHoldingShares: template.preHoldingFdbShares,
          postHoldingShares: template.postHoldingFdbShares,
          amountInvested: template.amountInvestedInRound,
          tooltipAmountInvested:
            template.amountInvestedInRound &&
            template.amountInvestedInRound.toLocaleString(currencyType),
          tooltipPreHoldingPercentage:
            template.preHoldingFdbPercentage &&
            (template.preHoldingFdbPercentage * 100).toLocaleString(
              currencyType
            ),
          tooltipPostHoldingPercentage:
            template.postHoldingFdbPercentage &&
            (template.postHoldingFdbPercentage * 100).toLocaleString(
              currencyType
            ),
          tooltipPreHoldingShares:
            template.preHoldingFdbShares &&
            template.preHoldingFdbShares.toLocaleString(currencyType),
          tooltipPostHoldingShares:
            template.postHoldingFdbShares &&
            template.postHoldingFdbShares.toLocaleString(currencyType),
          currencySymbol,
          currencyType,
        })),
    [finalCaptableData]
  );

  const onPageSizeChanged = useCallback(() => {
    const value = (document.getElementById("page-size") as HTMLInputElement)
      .value;
    gridApi.current?.paginationSetPageSize(Number(value));
  }, []);

  // const handleCellClick = (cellParams: CellClickedEvent<any, any>) => {
  //   if (cellParams.column.getColId() !== "action-column") {
  //     const template = cellParams.data;
  //     if (cellParams.column.getColId() === "shareholderName") {
  //       onClickTermsheetName(template.id);
  //     }
  //   }
  // };
  const [isColumnOpen, setIsColumnOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const openToolPanel = (key: any) => {
    if (key === "columns") {
      if (gridApi) {
        if (!isColumnOpen) gridApi?.current?.api?.openToolPanel(key);
        else gridApi?.current?.api?.closeToolPanel();
        setIsColumnOpen((state) => !state);
        setIsFilterOpen(false);
      }
    } else if (key === "filters") {
      if (gridApi) {
        if (!isFilterOpen) gridApi?.current?.api?.openToolPanel(key);
        else gridApi?.current?.api?.closeToolPanel();
        setIsFilterOpen((state) => !state);
        setIsColumnOpen(false);
      }
    }
  };

  const [filteredRowData, setFilteredRowData] = useState<any>([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const onAgGridFilterChanged = (grid: any) => {
    const filtersApplied = grid.api.isAnyFilterPresent();
    setIsFilterApplied(filtersApplied);

    const filteredData = grid.api
      .getModel()
      .rowsToDisplay.map((node: any) => node.data);
    setFilteredRowData(filteredData);
    uploadFilterAndColumn();
  };

  function setPinnedBottomRowData({ api }: { api: any }) {
    const data = api.rowModel.rootNode;
    const filteredData = data.childrenAfterAggFilter.map(
      (element: any) => element.data
    );

    const totalPreHoldingPercentage = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + data.preHoldingPercentage,
      0
    );
    const totalPostHoldingPercentage = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + data.postHoldingPercentage,
      0
    );
    const totalPreHoldingShares = filteredData?.reduce(
      (accumulator: any, data: any) =>
        accumulator + Number(data.preHoldingShares),
      0
    );
    const totalPostHoldingShares = filteredData?.reduce(
      (accumulator: any, data: any) =>
        accumulator + Number(data.postHoldingShares),
      0
    );
    const totalInvested = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + data.amountInvested,
      0
    );
    api.setPinnedBottomRowData([
      {
        shareholderName: "Total",
        preHoldingPercentage: totalPreHoldingPercentage,
        postHoldingPercentage: totalPostHoldingPercentage,
        preHoldingShares: totalPreHoldingShares,
        postHoldingShares: totalPostHoldingShares,
        amountInvested: totalInvested,
        tooltipAmountInvested: totalInvested.toLocaleString(currencyType),
        tooltipPreHoldingPercentage:
          totalPreHoldingPercentage.toLocaleString(currencyType),
        tooltipPostHoldingPercentage:
          totalPostHoldingPercentage.toLocaleString(currencyType),
        tooltipPreHoldingShares:
          totalPreHoldingShares.toLocaleString(currencyType),
        tooltipPostHoldingShares:
          totalPostHoldingShares.toLocaleString(currencyType),
        currencySymbol,
        currencyType,
      },
    ]);
  }

  return (
    <>
      <HStack className="justify-between py-2 items-center  bg-white rounded-md">
        <GenericTableHeader
          heading={"Cap Table"}
          subHeading={"Shareholders"}
          count={
            isFilterApplied === true ? filteredRowData.length : rowData.length
          }
        />
        <HStack className="justify-between w-full lg:w-auto lg:justify-end py-2 items-center gap-4 px-8">
          <SearchInput
            placeholder={`Search`}
            onChange={(e: any) => {
              gridApi.current.api.setQuickFilter(e.target.value);
            }}
          />

          <IconCTAButton
            value={"Columns"}
            onClick={() => openToolPanel("columns")}
            iconName={"fluent:column-triple-edit-20-regular"}
            className={`px-4 font-medium items-center flex flex-row ${
              isColumnOpen ? "text-orange-501" : "text-gray-400"
            }`}
          />
          <IconCTAButton
            value={"Filters"}
            onClick={() => openToolPanel("filters")}
            iconName={"material-symbols:filter-alt"}
            className={`px-4 font-medium items-center flex flex-row ${
              isFilterOpen ? "text-orange-501" : "text-gray-400"
            }`}
          />
        </HStack>
      </HStack>
      <HStack className="justify-between w-full">
        <Box
          style={{
            height: `${
              (rowData.length >= 10 ? 10 : rowData.length + 3) * 58
            }px`,
          }}
          className="w-full max-h-full overflow-x-auto ag-theme-material h-full "
        >
          <AgGridReact
            sideBar={agConfigDashboardSideBar}
            onGridReady={(params) => {
              // Store the grid API referen
              gridApi.current = params;
            }}
            components={componentsRegistery}
            alwaysShowHorizontalScroll
            alwaysMultiSort
            animateRows={true}
            enableCharts={true}
            enableRangeSelection={true}
            defaultColDef={defaultColDef}
            onFilterChanged={onAgGridFilterChanged}
            onRowDataUpdated={setPinnedBottomRowData}
            onColumnEverythingChanged={uploadFilterAndColumn}
            rowData={rowData}
            columnDefs={columnDefs}
            pagination={false}
            suppressRowTransform={true}
            suppressCopyRowsToClipboard={true}
            suppressCopySingleCellRanges={true}
            suppressCellFocus={true}
            suppressMenuHide={false}
            onColumnResized={uploadFilterAndColumn}
            tooltipShowDelay={1000}
            rowClass={"border-t border-dashed"}
            gridOptions={gridOptions}
            overlayNoRowsTemplate={
              '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow; margin-top: 50px;">No Rows To Show</span>'
            }
            getRowStyle={(params) => {
              if (params.rowIndex % 2 === 0) {
                return { background: "#f8f8f8" };
              } else {
                return { background: "#ffffff" };
              }
            }}
          ></AgGridReact>
        </Box>
      </HStack>
    </>
  );
}

export default TermsheetPreCaptableAgTable;
