// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  getAnalytics,
  logEvent,
  setUserId,
  setUserProperties,
} from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAB7BYeR_jkZ4H5DTST79HolVi7JBThCbU",
  authDomain: "hissa-2c4b7.firebaseapp.com",
  projectId: "hissa-2c4b7",
  storageBucket: "hissa-2c4b7.appspot.com",
  messagingSenderId: "78744987733",
  appId: "1:78744987733:web:55bc3011a3147b684e0cdc",
  measurementId: "G-6QZX84YMHL",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const setUserProperTiesOnFirebase = (
  userName: string,
  company?: string
) => {
  setUserProperties(analytics, { userName, company });
};
export const setUserIdOnFirebase = (username: string) => {
  setUserId(analytics, username);
};
export const logEventToFirebase = (event: string) => {
  logEvent(analytics, event);
};
