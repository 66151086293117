import {
  ForgotPasswordDetailModel,
  ResetPasswordDetailModel,
  ResetPostPasswordDetailModel,
  SignInDetailsModel,
  SignResponseModel,
  SignUpModel,
  SignUpResponseModel,
} from "../types/CaptableLoginModel";
import api from "./capTableApi";

export async function forgotPassword(
  forgotPasswordDetail: ForgotPasswordDetailModel
): Promise<{ message: string; data: any }> {
  return api
    .post(`v1/auth/forgotPassword`, forgotPasswordDetail)
    .then((res) => res.data);
}

export async function captableSignIn(
  signInDetails: SignInDetailsModel
): Promise<{ message: string; data: SignResponseModel }> {
  return api.post(`v1/auth/login`, signInDetails).then((res) => res.data);
}

export async function captableSignUp(
  signUpDetails: SignUpModel
): Promise<{ message: string; data: SignUpResponseModel }> {
  return api
    .post(`v1/auth/globalSignUp`, signUpDetails)
    .then((res) => res.data);
}
