import create from "zustand";

type NewEntryState = {
  newEntry: string[];
  setNewEntry: (transaction: string) => void;
  reset: () => void;
};

const initialState = {
  newEntry: [],
};

const newEntryStore = create<NewEntryState>((set) => ({
  ...initialState,
  setNewEntry: (transaction) => {
    set((state) => ({ newEntry: [...state.newEntry, transaction] }));
  },
  reset: () => {
    set((state) => initialState);
  },
}));

export const useNewEntryStore = newEntryStore;
