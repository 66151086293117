import React, { useRef, useState } from "react";
import { Dialog } from "@mui/material";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { useUploadCompanyExcel } from "../../queries/newCapTable/companyOnboarding";
import { PrimaryCTAButton } from "../quickRound/CTAButtonComponents";
import ValidationErrorsDialog from "../../modals/ValidationErrorsDialog";

function ExcelOnboarding() {
  const fileRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const { mutate: uploadCompanyOnboardingExcel } = useUploadCompanyExcel();
  const navigate = useNavigate();
  const [dialog, setDialog] = useState<{
    open: boolean;
    message?: string;
    data?: string[];
  }>({
    open: false,
  });
  const displayErrors = (errors: string[]) => {
    setDialog({
      open: true,
      data: errors,
    });
  };
  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (!e?.target?.files?.[0]) return;
    const file = e.target.files[0];
    const allowedFileExtensions = [".xlsm", ".xlsx"];
    if (
      allowedFileExtensions.some((extension) => file.name.endsWith(extension))
    ) {
      const formData = new FormData();
      formData.append("file", file);
      uploadCompanyOnboardingExcel(
        {
          formData,
        },
        {
          onSuccess: () => {
            toast("Uploaded Successfully!", {
              type: "success",
              autoClose: 2000,
            });
            navigate("/landing/dashboard");
          },
          onError: (error: any) => {
            if (
              error.response.data.errors &&
              error.response.data.errors.length > 0
            )
              displayErrors(error.response.data.errors);
            else
              toast(`${error.response.data.errorMessage}`, {
                type: "error",
                autoClose: 2000,
              });
          },
        }
      );
    } else {
      toast(
        ` Invalid file type, allowed file types are ${allowedFileExtensions.join(
          ", "
        )}`,
        { type: "error" }
      );
    }
    fileRef.current.value = "";
  }

  return (
    <main className="grid h-screen place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div className="text-center">
        <p className="text-base font-semibold text-indigo-600">Welcome to</p>
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
          Hissa
        </h1>
        <p className="mt-6 text-base leading-7 text-gray-600">
          Please upload the excel sheet to view your captable
        </p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <div>
            <input
              ref={fileRef}
              onChange={handleChange}
              multiple={false}
              type="file"
              accept=".xlsx, .xlsm"
              hidden
            />
            <PrimaryCTAButton
              event-name="Upload Onboarding Excel"
              onClick={() => {
                fileRef.current.click();
              }}
            >
              Upload Excel
            </PrimaryCTAButton>
          </div>
          <Dialog open={dialog.open} maxWidth="lg">
            <ValidationErrorsDialog
              errors={dialog.data || []}
              onPrimaryAction={() => setDialog({ open: false })}
              onSecondaryAction={() => setDialog({ open: false })}
            />
          </Dialog>
        </div>
      </div>
    </main>
  );
}

export default ExcelOnboarding;
