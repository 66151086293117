import Tooltip from "../../components/shared/Tooltip";
import { HStack, VStack } from "../../components/utils";
import { useRMCreationStore } from "../../store/roundCreationStore";
import { getCurrencyType } from "../../utils/currencyFormatter";
import {
  FormatNumberSpan,
  getCurrencySymbolRoboto,
} from "../../utils/currencyRoboto";
import { formatDisplayDate } from "../../utils/date";
import { Headers } from "./RoundCreationModel";

const RoundCreationHeader = ({
  currentCompanyDetail,
}: {
  currentCompanyDetail: Headers;
}) => {
  const roundCreationState = useRMCreationStore();
  const symbol = getCurrencySymbolRoboto();
  const currency = getCurrencyType();
  return (
    <>
      <div className="grid grid-cols-1 my-6 gap-6 sm:grid-cols-3 lg:grid-cols-6">
        <VStack className="px-2 py-3 rounded-md shadow-box bg-white w-full">
          <p className="text-sm text-[#464E5F] font-medium whitespace-nowrap pb-4">
            Round Size
          </p>
          <HStack className="items-baseline">
            <span className="pr-1 text-xs font-normal">{symbol}</span>
            <p className="text-lg font-medium">
              <FormatNumberSpan
                value={currentCompanyDetail.roundSize}
                format={currency}
              />
            </p>
          </HStack>
        </VStack>
        <VStack className="px-2 py-3 rounded-md shadow-box  bg-white w-full">
          <p className="text-sm text-[#464E5F] font-medium whitespace-nowrap pb-4">
            Pre-money Valuation
          </p>
          <HStack className="items-baseline">
            <span className="pr-1 text-xs font-normal">{symbol}</span>
            <p className="text-lg whitespace-nowrap font-medium">
              <FormatNumberSpan
                value={currentCompanyDetail.valuation}
                format={currency}
              />
            </p>
            <p className="text[#A1A5B7] text-xxs1 whitespace-nowrap">
              {/* {formatDisplayDate(currentCompanyDetail.)} */}
            </p>
          </HStack>
        </VStack>
        <VStack className="px-2 py-3 rounded-md shadow-box  bg-white w-full">
          <p className="text-sm text-[#464E5F] font-medium whitespace-nowrap pb-4">
            FDB Share Price
          </p>
          <HStack className="items-baseline">
            <span className="pr-1 text-xs font-normal">{symbol}</span>
            <p className="text-lg font-medium">
              <FormatNumberSpan
                value={currentCompanyDetail.pps}
                format={currency}
              />
            </p>
          </HStack>
          {currentCompanyDetail.convertiblePps > 0 && (
            <HStack>
              <p className="text-sm text-[#464E5F] font-medium whitespace-nowrap pb-4">
                convertible PPS:
              </p>
              <span> </span>
              <p className="text-sm font-medium">
                <FormatNumberSpan
                  value={currentCompanyDetail.convertiblePps}
                  format={currency}
                />
              </p>
            </HStack>
          )}
        </VStack>
        <VStack className="px-2 py-3 rounded-md shadow-box  bg-white w-full">
          <p className="text-sm text-[#464E5F] font-medium whitespace-nowrap pb-4">
            Money Raised
          </p>
          <HStack className="items-baseline">
            <span className="pr-1 text-xs font-normal">{symbol}</span>
            <p className="text-lg whitespace-nowrap font-medium">
              <FormatNumberSpan
                value={currentCompanyDetail.moneyRaised}
                format={currency}
              />
            </p>
          </HStack>
        </VStack>
        <VStack className="px-2 py-3 rounded-md shadow-box  bg-white w-full">
          <p className="text-sm text-[#464E5F] font-medium whitespace-nowrap pb-4">
            Left to Raise
          </p>
          <HStack className="items-baseline">
            <span className="pr-1 text-xs font-normal">{symbol}</span>
            <p className="text-lg whitespace-nowrap  font-medium">
              <FormatNumberSpan
                value={currentCompanyDetail.leftToRaise}
                format={currency}
              />
            </p>
          </HStack>
        </VStack>
        <VStack className="px-2 py-3 rounded-md shadow-box  bg-white w-full">
          <p className="text-sm text-[#464E5F] font-medium whitespace-nowrap pb-4">
            Lead Investor
          </p>
          <Tooltip
            text={currentCompanyDetail.leadInvestor}
            _position="top-6 right-0"
          >
            <p className="text-lg text-ellipsis whitespace-nowrap overflow-clip font-medium">
              {currentCompanyDetail.leadInvestor}
            </p>
          </Tooltip>
        </VStack>
      </div>
    </>
  );
};

export default RoundCreationHeader;
