export function kFormatter(num: number) {
  if (Math.abs(num) > 9999999999) {
    return `${Math.sign(num) * Math.round(Math.abs(num) / 10000000)} K Cr`;
  } else if (Math.abs(num) > 9999999) {
    return `${(Math.sign(num) * Math.round(Math.abs(num) / 1000000)) / 10} Cr`;
  } else if (Math.abs(num) > 99999) {
    return `${(Math.sign(num) * Math.round(Math.abs(num) / 1000)) / 100} L`;
  } else if (Math.abs(num) > 999) {
    return `${(Math.sign(num) * Math.round(Math.abs(num) / 10)) / 100} k`;
  } else {
    return `${Number((Math.sign(num) * Math.abs(num)).toFixed(2))}`;
  }
}
