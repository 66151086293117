import { HStack } from "../components/utils";

const GenericTableHeader = ({
  heading,
  subHeading,
  count,
  className,
  currencytype,
  lastUpdatedDetailsContent,
}: {
  heading: string;
  subHeading: string;
  count?: number;
  className?: string;
  currencytype?: string;
  lastUpdatedDetailsContent?: string;
}) => (
  <div className={`flex flex-col px-6 lg:justify-start ${className}`}>
    <p className="md:text-lg text-base font-medium text-headerColor whitespace-nowrap">
      {heading}
    </p>
    <HStack className="mr-4 md:mr-12">
      <p className="text-xs1 font-medium text-descriptionColor whitespace-nowrap">
        {count
          ? `${count.toLocaleString(currencytype)} ${subHeading}`
          : `${subHeading}`}
      </p>
      {lastUpdatedDetailsContent && (
        <p className="text-xs1 font-medium text-descriptionColor whitespace-nowrap">
          &nbsp;{lastUpdatedDetailsContent}
        </p>
      )}
    </HStack>
  </div>
);

export default GenericTableHeader;
