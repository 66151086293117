import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import {
  getCompanyRoles,
  getCompanyUsers,
  searchUsers,
  updateCompanyRole,
  createCompanyRole,
  getUser,
  assignRoleToUser,
  createCustomRoleForUser,
  getStandardTemplate,
} from "../../api/userRoles";
import { queryClient } from "../client";
import { handleEventForTracking } from "../../amplitudeAnalytics";

export function useSearchUsers(text: string) {
  return useQuery({
    queryKey: ["users", text],
    queryFn: searchUsers,
    select: (data) => data.data,
    staleTime: 20,
  });
}

export function useStandardAcls() {
  return useQuery({
    queryKey: ["getStandardTemplate"],
    queryFn: getStandardTemplate,
    select: (data) => data.data,
    staleTime: 2000,
  });
}

export function useGetUser(emailId: string) {
  return useQuery({
    queryKey: ["users", emailId],
    queryFn: getUser,
    select: (data) => data.data,
    staleTime: 20,
    retry: 0,
  });
}

export function useCompanyUsers() {
  return useQuery({
    queryKey: ["companyUsers"],
    queryFn: getCompanyUsers,
    select: (data) => data.data,
  });
}

export function useCompanyRoles() {
  return useQuery({
    queryKey: ["companyRoles"],
    queryFn: getCompanyRoles,
    select: (data) => data.data,
  });
}

export function useUpdateCompanyRole() {
  return useMutation({
    mutationKey: ["updateCompanyRole"],
    mutationFn: updateCompanyRole,
    onSuccess: () => {
      toast("Role updated successfully", { type: "success" });
      queryClient.refetchQueries("companyRoles");
      handleEventForTracking({
        eventName: "updateCompanyRole",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "updateCompanyRole",
        success: false,
        eventType: "API",
      });
    },
  });
}
export function useCreateCompanyRole() {
  return useMutation({
    mutationKey: ["createCompanyRole"],
    mutationFn: createCompanyRole,
    onSuccess: () => {
      toast("Role Created successfully", { type: "success" });
      queryClient.refetchQueries("companyRoles");
      handleEventForTracking({
        eventName: "createCompanyRole",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "createCompanyRole",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function useAssignRoleToUser() {
  return useMutation({
    mutationKey: ["assignRoleToUser"],
    mutationFn: assignRoleToUser,
    onSuccess: () => {
      toast("Role Assigned successfully", { type: "success" });
      queryClient.refetchQueries("companyRoles");
      handleEventForTracking({
        eventName: "assignRoleToUser",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "assignRoleToUser",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function useCreateCustomRoleForUser() {
  return useMutation({
    mutationKey: ["createCustomRoleForUser"],
    mutationFn: createCustomRoleForUser,
    onSuccess: () => {
      toast("User Role Created successfully", { type: "success" });
      queryClient.refetchQueries("companyRoles");
      handleEventForTracking({
        eventName: "createCustomRoleForUser",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "createCustomRoleForUser",
        success: false,
        eventType: "API",
      });
    },
  });
}
