import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import { useState } from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { HStack, VStack, Error } from "../../components/utils";
import { Input, Label } from "../../components/shared/InputField";
import {
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../quickRound/CTAButtonComponents";
import { Select } from "../../components/shared/Select";
import {
  NonPricedSecurityTypeList,
  SecurityTypeList,
} from "../../constants/ShareholderSecurityTypes";
import { initialInstrumentData } from "./newTransactionInitialValue";
import { newInstrumentValidationSchema } from "./newTransactionValidationSchema";
import { useAuthStore } from "../../store";
import { usePostBulkInstruments } from "../../queries/transactionRound";
import { useShareholderInstrumentStore } from "../../store/shareholderInstrumentStore";
import { ShowNumberInWords } from "../../components/shared/UiElement";
import {
  getCurrencySymbol,
  getCurrencyType,
} from "../../utils/currencyFormatter";
import {
  convertToNumber,
  filterOnlyNumbers,
  numberWithCommas,
} from "../../utils/numUtils";

type AddInstrumentPopupProps = {
  onPrimaryAction: () => void;
  onSecondaryAction: () => void;
  data: any;
  isPricedRound: boolean;
};

export default function AddInstrumentPopup({
  onPrimaryAction,
  onSecondaryAction,
  data,
  isPricedRound,
}: AddInstrumentPopupProps) {
  const companyId = useAuthStore.getState().companyId ?? "";
  const { mutate: postBulkInstrument } = usePostBulkInstruments();

  const [onHoverPar, setOnHoverPar] = useState(false);
  const [formatParValue, setFormatParValue] = useState(``);

  const [onHoverValuationCap, setOnHoverValuationCap] = useState(false);
  const [formatValuationCap, setFormatValuationCap] = useState(``);

  const currencyType = getCurrencyType();
  const currencySymbol = getCurrencySymbol();

  return (
    <VStack>
      <HStack className="justify-between px-8 py-4 border-b">
        <p className="text-base font-medium">Add Security</p>
        <XMarkIcon className="w-6 h-6" onClick={() => onSecondaryAction()} />
      </HStack>

      <div className="px-4 py-4 bg-white rounded-lg">
        <VStack className="justify-between">
          <Formik
            initialValues={{
              ...initialInstrumentData,
              companyId: companyId || "",
            }}
            key="addNewInstrument"
            enableReinitialize={true}
            onSubmit={(values, { resetForm }) => {
              const instrumentData = {
                companyId: values.companyId,
                securityName: values.securityName,
                securityType: values.securityType,
                conversionRatio: `1:${values.conversionRatio}`,
                par: values.par,
                valuationCap: values.valuationCap,
                discount: values.discount,
                pricedRound: isPricedRound,
              };

              postBulkInstrument(instrumentData, {
                onSuccess: (data) => {
                  resetForm();
                  onPrimaryAction();
                  toast("Instrument Added Successfully!", {
                    type: "success",
                    autoClose: 2000,
                  });
                },
                onError: (err: any) => {
                  resetForm();
                  onPrimaryAction();
                  toast(err.response.data.errorMessage, {
                    type: "error",
                    autoClose: 2000,
                  });
                },
              });
            }}
            validationSchema={newInstrumentValidationSchema}
          >
            {(formik) => (
              <>
                <Form key="addInstrumentProfile" className="gap-6 px-2 py-4">
                  <VStack className="w-full gap-8">
                    <HStack className="grid w-full grid-cols-1 gap-8 mx-auto md:grid-cols-2">
                      <div className="flex-1 w-72">
                        <Label className="text-sm text-[#464E5F] font-medium">
                          Security Name
                        </Label>
                        <Input
                          type="text"
                          placeholder="Enter Security Name"
                          {...formik.getFieldProps("securityName")}
                        />
                        {formik.touched.securityName &&
                          formik.errors.securityName && (
                            <Error text={formik.errors.securityName} />
                          )}
                      </div>
                      <div className="flex-1 w-72">
                        <Label className="text-sm text-[#464E5F] font-medium">
                          Security Type
                        </Label>

                        <Select
                          options={
                            isPricedRound
                              ? SecurityTypeList
                              : NonPricedSecurityTypeList
                          }
                          placeholder="Select Security Type"
                          {...formik.getFieldProps("securityType")}
                        />
                        {formik.touched.securityType &&
                          formik.errors.securityType && (
                            <Error text={formik.errors.securityType} />
                          )}
                      </div>
                    </HStack>
                    {!isPricedRound && (
                      <HStack className="grid w-full grid-cols-1 gap-8 mx-auto md:grid-cols-2">
                        <div className="flex-1 w-72">
                          <Label className="text-sm text-[#464E5F] font-medium">
                            Valuation Cap ({currencySymbol})
                          </Label>
                          <HStack className="items-center">
                            <Input
                              type="text"
                              placeholder="Enter Valuation Cap"
                              {...formik.getFieldProps("valuationCap")}
                              onMouseEnter={() => {
                                setOnHoverValuationCap(true);
                              }}
                              onMouseLeave={() => {
                                setOnHoverValuationCap(false);
                              }}
                              onChange={(e: any) => {
                                const filteredValue = filterOnlyNumbers(
                                  e.target.value
                                );
                                setFormatValuationCap(filteredValue);
                                const values = convertToNumber(filteredValue);
                                formik.setFieldValue("valuationCap", values);
                                formik.handleChange("valuationCap");
                              }}
                              value={numberWithCommas(
                                formatValuationCap,
                                currencyType
                              )}
                            />
                          </HStack>
                          {onHoverValuationCap && (
                            <ShowNumberInWords
                              value={formik.values.valuationCap!}
                              width={38}
                              currency={currencyType}
                            />
                          )}

                          {formik.touched.valuationCap &&
                            formik.errors.valuationCap && (
                              <Error text={formik.errors.valuationCap} />
                            )}
                        </div>
                        <div className="flex-1 w-72">
                          <Label className="text-sm text-[#464E5F] font-medium">
                            Discount (%)
                          </Label>
                          <HStack className="items-center">
                            <Input
                              type="text"
                              placeholder="Enter Discount %"
                              {...formik.getFieldProps("discount")}
                            />
                          </HStack>

                          {formik.touched.discount &&
                            formik.errors.discount && (
                              <Error text={formik.errors.discount} />
                            )}
                        </div>
                      </HStack>
                    )}
                    <HStack className="grid w-full grid-cols-1 gap-8 mx-auto md:grid-cols-2">
                      {formik.values.securityType !== "Equity" &&
                        formik.values.securityType !== "Note" && (
                          <div className="flex-1 w-72">
                            <Label className="text-sm text-[#464E5F] font-medium">
                              Conversion Ratio
                            </Label>
                            <HStack className="items-center">
                              <p className="text-sm font-medium  pt-0.5">1</p>
                              <p className="text-sm font-medium px-1 pt-0.5">
                                :
                              </p>
                              <Input
                                type="text"
                                placeholder="Enter Conversion Ratio"
                                {...formik.getFieldProps("conversionRatio")}
                              />
                            </HStack>

                            {formik.touched.conversionRatio &&
                              formik.errors.conversionRatio && (
                                <Error text={formik.errors.conversionRatio} />
                              )}
                          </div>
                        )}
                      {formik.values.securityType !== "Note" && (
                        <div className="flex-1 w-72">
                          <Label className="text-sm text-[#464E5F] font-medium">
                            PAR ({currencySymbol})
                          </Label>
                          <Input
                            placeholder="Enter PAR"
                            type="text"
                            onMouseEnter={() => {
                              setOnHoverPar(true);
                            }}
                            onMouseLeave={() => {
                              setOnHoverPar(false);
                            }}
                            onChange={(e: any) => {
                              const filteredValue = filterOnlyNumbers(
                                e.target.value
                              );
                              setFormatParValue(filteredValue);
                              const values = convertToNumber(filteredValue);

                              formik.setFieldValue("par", values);
                              formik.handleChange("par");
                            }}
                            value={numberWithCommas(
                              formatParValue,
                              currencyType
                            )}
                          />
                          {onHoverPar && (
                            <ShowNumberInWords
                              value={formik.values.par!}
                              width={38}
                              currency={currencyType}
                            />
                          )}

                          {formik.touched.par && formik.errors.par && (
                            <Error text={formik.errors.par} />
                          )}
                        </div>
                      )}
                    </HStack>
                    <HStack className="justify-between mt-10">
                      <SecondaryCTAButton
                        onClick={() => {
                          onSecondaryAction();
                        }}
                        type="reset"
                        event-name="Cancel Instrument Modal"
                        className="text-red-500"
                      >
                        Cancel
                      </SecondaryCTAButton>
                      <PrimaryCTAButton
                        event-name="Save Instrument Modal"
                        type="submit"
                      >
                        Add +
                      </PrimaryCTAButton>
                    </HStack>
                  </VStack>
                </Form>
              </>
            )}
          </Formik>
        </VStack>
      </div>
    </VStack>
  );
}
