import React, { useEffect, useRef, useState } from "react";
import { Icon } from "@iconify/react";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router";
import { Badge, Dialog } from "@mui/material";
import { Box, HStack, VStack } from "../../components/utils";
import { ExportImport } from "../Utility/GrantsTable";
import { Action } from "../../components/shared/Dropdown";
import { downloadS3File } from "../../utils/DownloadFile";
import {
  useDownloadShareHolderRightsExcelSheet,
  useShareHolderRightsTemplate,
  useUploadImportedShareHolderRightsTemplate,
} from "../../queries/shareHolderRights";
import RightsTable from "./RightsTable";
import {
  RightsMultiSelectModel,
  useShareHolderFilterTypeStore,
} from "../../store/rightsfilterStore";
import { RightsDropDownChooser } from "./SelectDropdownChooser";
import {
  ShareholderModel,
  ShareRightModel,
} from "../../types/ShareHolderRightsModel";
import { useShareHolderTransferFilterTypeStore } from "../../store/transferRightStore";
import { EmptyShareHolderRightTable } from "./RightsAtomicWidgets";
import { sort } from "../../utils/arrays";
import ValidationErrorsDialog from "../../modals/ValidationErrorsDialog";
import { useAuthStore } from "../../store/useAuthStore";
import Tooltip from "../../components/shared/Tooltip";
import { useRightsStore } from "../../store/rightsStore";
import { useCanUserAccess } from "../../store/useRoleGrantStore";
import { SwitchDropDownModel } from "../rounds/RoundsAllTransactions";
import SearchAutoCompleteDropDown from "../../components/shared/SearchAutoCompleteDropDown";
import { classNames } from "../../utils/string";

export default function RightsSubPage({
  shareholders,
  rightsDataList,
  transferDataList,
  tableType = "rights",
}: {
  shareholders: ShareholderModel[];
  rightsDataList: ShareRightModel[];
  transferDataList: ShareRightModel[];
  tableType?: string;
}) {
  const [dataList, setDataList] = useState<ShareRightModel[]>(rightsDataList);
  const [currentTab, setCurrentTab] = useState("Rights");
  const canEditRights = useCanUserAccess("update", "rights");
  const tabs: string[] = ["Rights", "Transfer Restrictions"];
  const { id, cin } = useParams();

  const companyId = id || useAuthStore.getState().companyId || "";

  const [shareHolderRightsExcel, setShareHolderRightsExcel] = useState("");

  const [globalFilterText, setGlobalFilterText] = useState("");
  const fileRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  const [investorDropdownOptions, setInvestorDropdownOptions] = useState<
    RightsMultiSelectModel[]
  >([]);
  const [conditionalDropdownOptions, setConditionalDropdownOptions] = useState<
    RightsMultiSelectModel[]
  >([]);
  const [rightsDropdownOptions, setRightsDropdownOptions] = useState<
    RightsMultiSelectModel[]
  >([]);
  const [rightsTypeDropdownOptions, setRightsTypeDropdownOptions] = useState<
    RightsMultiSelectModel[]
  >([]);

  const [dialog, setDialog] = useState<{
    open: boolean;
    message?: string;
    data?: string[];
  }>({
    open: false,
  });

  const [selectedTransaction, setSelectedTransaction] =
    useState<SwitchDropDownModel>({ name: "", id: "", type: "" });

  const [dropdownValuesList, setDropdownValues] = useState<
    SwitchDropDownModel[]
  >([]);

  useEffect(() => {
    setDataList(currentTab === "Rights" ? rightsDataList : transferDataList);
  }, [currentTab]);

  useEffect(() => {
    if (dataList.length > 0) {
      const dropdownValues: SwitchDropDownModel[] = [];
      shareholders.forEach((shareholder) =>
        shareholder.shareholderName?.forEach((element) => {
          dropdownValues.push({
            name: element,
            type: element,
            id: element,
          });
        })
      );
      setDropdownValues(dropdownValues);
      const sortResult = sort(dataList, "orderNumber", true);
      const rightsOptions: RightsMultiSelectModel[] = [];
      const conditionalOptions: RightsMultiSelectModel[] = [];
      const conditionalString: string[] = [];
      const rightTypeString: string[] = [];
      const investorOptions: RightsMultiSelectModel[] = [];
      const rightsTypeOptions: RightsMultiSelectModel[] = [];
      sortResult.forEach((rights) => {
        rights.investors.forEach((investors, index) => {
          const condition =
            investors.condition === "No Restriction" ||
            investors.condition === "Lockin" ||
            investors.condition === "Restricted" ||
            investors.condition === "Reserved Shares" ||
            investors.condition === "Drag Rights" ||
            investors.condition === "Exit" ||
            investors.condition === "Yes" ||
            investors.condition === "No"
              ? investors.condition
              : "Conditional";

          if (!conditionalString.includes(condition || "")) {
            conditionalString.push(condition);
          }
        });
        if (!rightTypeString.includes(rights.rightType || "")) {
          rightTypeString.push(rights.rightType || "");
        }

        rightsOptions.push({
          name: rights.rightName || "",
          isVisible: true,
          isDisabled: false,
        });
      });

      conditionalString.forEach((conditonName) =>
        conditionalOptions.push({
          name: conditonName || "",
          isVisible: true,
          isDisabled: false,
        })
      );

      rightTypeString.forEach((rightType) =>
        rightsTypeOptions.push({
          name: rightType || "",
          isVisible: true,
          isDisabled: false,
        })
      );

      sortResult[0].investors.forEach((investor) => {
        investorOptions.push({
          name: investor.investorType || "",
          isVisible: true,
          isDisabled: false,
        });
      });
      setRightsDropdownOptions(rightsOptions);
      setInvestorDropdownOptions(investorOptions);
      setConditionalDropdownOptions(conditionalOptions);
      setRightsTypeDropdownOptions(rightsTypeOptions);

      filterDataStore.setConditionalType(conditionalOptions);
      filterDataStore.setInvestorType(investorOptions);
      filterDataStore.setRightsType(rightsOptions);
      filterDataStore.setRightsOptionsType(rightsTypeOptions);
    }
  }, [dataList]);

  const filterDataStore =
    currentTab === "Rights"
      ? useShareHolderFilterTypeStore()
      : useShareHolderTransferFilterTypeStore();
  const [templateFile, setTemplateFile] = useState("");

  const { mutate: importShareHolderRightsExcel } =
    useUploadImportedShareHolderRightsTemplate();

  const handleAction = (action: Action) => {
    if (action.name === "Export To Excel") {
      downloadS3File(shareHolderRightsExcel);
    } else if (action.name === "Import From Excel") {
      fileRef.current.click();
    } else if (action.name === "Download Template") {
      downloadS3File(templateFile);
    }
  };

  const displayErrors = (errors: string[]) => {
    setDialog({
      open: true,
      data: errors,
    });
  };

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (!e?.target?.files?.[0]) return;
    const file = e.target.files[0];
    const allowedFileExtensions = ["xlsx", "xls", "ods"];
    if (
      allowedFileExtensions.some((extension) => file.name.endsWith(extension))
    ) {
      const formData = new FormData();
      formData.append("file", file);
      importShareHolderRightsExcel(
        { formData, companyId, cinNumber: cin || "" },
        {
          onSuccess: () => {
            toast("Excel Sheet Uploaded Successfully!", {
              type: "success",
              autoClose: 2000,
            });
          },
          onError: (error: {
            response: { data: { errorMessage: string; errors: string[] } };
          }) => {
            if (
              error.response.data.errors &&
              error.response.data.errors.length > 0
            )
              displayErrors(error.response.data.errors);
            else
              toast(`${error.response.data.errorMessage}`, {
                type: "error",
                autoClose: 2000,
              });
          },
        }
      );
    } else {
      toast(
        ` Invalid file type, allowed file types are ${allowedFileExtensions.join(
          ", "
        )}`,
        { type: "error" }
      );
    }
    fileRef.current.value = "";
  }
  const [dropdownOpen, setDropDownOpen] = useState(false);
  const contextRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const checkIfClickOutside = (e: any) => {
      if (!(dropdownOpen && contextRef.current?.contains(e.target))) {
        setDropDownOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickOutside);
    };
  }, [dropdownOpen]);

  function getSelectedValue(transaction?: SwitchDropDownModel) {
    if (transaction) setGlobalFilterText(transaction.name);
    else setGlobalFilterText("");
  }

  return (
    <VStack>
      <HStack className="justify-between px-4 mb-2">
        <p className="font-medium text-gray-600 text-lg md:mr-12 mr-4 ">
          {currentTab === "Rights" ? "Shareholder Rights" : "Transfers"}
        </p>

        <HStack className="justify-end">
          <HStack className="justify-between px-4 md:flex-row flex-col">
            <input
              ref={fileRef}
              onChange={handleChange}
              multiple={false}
              type="file"
              accept=".xlsx, .xls, .ods"
              hidden
            />
            <Dialog open={dialog.open} maxWidth="md">
              <ValidationErrorsDialog
                errors={dialog.data || []}
                onPrimaryAction={() => setDialog({ open: false })}
                onSecondaryAction={() => setDialog({ open: false })}
              />
            </Dialog>
            <HStack className="items-center">
              <div ref={contextRef}>
                <div
                  className={`flex flex-col overflow-auto p-3 px-3 mx-2 text-white rounded cursor-pointer bg-slate-light`}
                >
                  <Icon
                    onClick={() => setDropDownOpen((state) => !state)}
                    icon="el:filter"
                    className="text-orange-501 rounded"
                    height={20}
                  />
                </div>

                {dropdownOpen && (
                  <div
                    id="dropdown"
                    className={`absolute z-10  mt-1 bg-white border divide-y divide-gray-100 rounded shadow-box`}
                  >
                    <p className="px-4 py-1 text-stone-300">Filter Options</p>
                    <ul
                      className="py-1 text-xs font-normal divide-y text-dark min-h-min overflow-auto whitespace-nowrap"
                      aria-labelledby="dropdownDefault"
                    >
                      <RightsDropDownChooser
                        label="Select Investor Type"
                        columnOptions={investorDropdownOptions}
                        onAction={(action) => {
                          filterDataStore.setInvestorType(action);
                        }}
                      />
                      <RightsDropDownChooser
                        label="Select Rights"
                        columnOptions={rightsDropdownOptions}
                        onAction={(action) => {
                          filterDataStore.setRightsType(action);
                        }}
                      />
                      <RightsDropDownChooser
                        label="Select Conditional"
                        columnOptions={conditionalDropdownOptions}
                        onAction={(action) => {
                          filterDataStore.setConditionalType(action);
                        }}
                      />
                      <RightsDropDownChooser
                        label="Select Right Type"
                        columnOptions={rightsTypeDropdownOptions}
                        onAction={(action) => {
                          filterDataStore.setRightsOptionsType(action);
                        }}
                      />
                    </ul>
                  </div>
                )}
              </div>
              {dropdownValuesList.length > 0 && (
                <Box className="w-72  mb-12">
                  <SearchAutoCompleteDropDown
                    data={dropdownValuesList as SwitchDropDownModel[]}
                    className={"bg-slate-light w-72 rounded-md border-none"}
                    placeholder="Search Investor..."
                    getSelectedValue={getSelectedValue}
                    selectedOption={selectedTransaction as SwitchDropDownModel}
                    field={"name" as keyof SwitchDropDownModel}
                  />
                </Box>
              )}
            </HStack>
          </HStack>
          {tabs.map((tab, index) => (
            <>
              <div
                key={tab + index}
                className={classNames(
                  "inline-flex items-center px-3 py-2 text-xs font-semibold leading-4 mr-2 hover:cursor-pointer",
                  tab === currentTab
                    ? " border-transparent text-orange-p border border-b-red-500"
                    : " bg-white text-gray-400  focus:opacity-90"
                )}
                aria-current={tab === currentTab ? "page" : undefined}
                onClick={() => {
                  setCurrentTab(tab);
                }}
              >
                <span>{tab}</span>
                <span aria-hidden="true" />
              </div>
            </>
          ))}
        </HStack>
      </HStack>

      {dataList.length > 0 && (
        <RightsTable
          shareholders={shareholders}
          rightsTableDataList={dataList}
          currentTab={currentTab}
          globalFilterText={globalFilterText}
          type={tableType}
        />
      )}
      {dataList.length === 0 && (
        <EmptyShareHolderRightTable message="Please upload the Shareholderrights template to view the rights" />
      )}
    </VStack>
  );
}
