import { Form, Formik } from "formik";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { AgGridReact } from "ag-grid-react";
import { useEffect, useRef, useState } from "react";
import _ from "lodash";
import { toast } from "react-toastify";
import { HStack, VStack, Error, Box } from "../components/utils";
import {
  CurrentlyPaid,
  SchedulePay,
  ShareholderSecurityModel,
} from "../types/Shareholder";
import { getCurrencySymbol, getCurrencyType } from "../utils/currencyFormatter";
import {
  ButtonSize,
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../pages/quickRound/CTAButtonComponents";
import AGGridCurrentlyPaidTable from "./CurrentlyPaidAgGridTable";
import AGGridSchedulePayDetailsTable from "./SchedulePayAgGridTable";
import { getSecurityColor } from "../utils/historicUtilities";
import {
  useGetPartlyPaidDetails,
  useUpdatePartlyPaidDetails,
} from "../queries/shareholders";
import { currencies, currencyTypes } from "../constants/CompanyCurrencyType";
import {
  FormatNumberSpan,
  formatToReadableNumber,
} from "../utils/currencyRoboto";
import { queryClient } from "../queries/client";
import { useGetUserRoleGrants } from "../queries/auth";

export default function AddPartlyPaidDetails({
  shareholderSecurity,
  onPrimaryAction = () => {},
  onSecondaryAction = () => {},
}: {
  shareholderSecurity: ShareholderSecurityModel;
  onPrimaryAction: () => void;
  onSecondaryAction: () => void;
}) {
  const gridRefCurrentlyPaid = useRef<AgGridReact>(null);
  const gridRefSchedulePaid = useRef<AgGridReact>(null);

  const { mutate: updatePartlyPaidDetails } = useUpdatePartlyPaidDetails();
  const [deletedRows, setDeletedRows] = useState<SchedulePay[]>([]);
  const currencySymbol = getCurrencySymbol();
  const currencyType = getCurrencyType();
  const { refetch, data: userRoleData } = useGetUserRoleGrants();
  const { data: partlyPaidDetails } = useGetPartlyPaidDetails(
    shareholderSecurity.certificateId
  );

  const [amountInvested, setAmountInvested] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (partlyPaidDetails?.amountInvested)
      setAmountInvested(partlyPaidDetails?.amountInvested || 0);
  }, [partlyPaidDetails]);

  const totalCurrentlyPaidUpSharePrice =
    amountInvested / shareholderSecurity.numberOfShares;

  const handleSubmit = () => {
    const currentlyPaidDetails: CurrentlyPaid[] = [];
    gridRefCurrentlyPaid.current?.api.forEachNode((node: any) => {
      currentlyPaidDetails.push(node.data);
    });

    const schedulePay: SchedulePay[] = [];

    gridRefSchedulePaid.current?.api.forEachNode((node: any) => {
      schedulePay.push(node.data);
    });

    const rowsWithZeroPaidUpSharePrice = schedulePay.filter(
      (row) => row.paidUpSharePrice === 0
    );

    if (rowsWithZeroPaidUpSharePrice.length > 0) {
      toast("Error: There are rows with zero paid up share price.", {
        type: "error",
        autoClose: 2000,
      });
      return;
    }

    deletedRows.forEach((row: any) => {
      schedulePay.push({
        id: row.id,
        paidUpSharePrice: row.paidUpSharePrice,
        scheduledDate: row.scheduledDate,
        paidUpDate: row.paidUpDate || new Date().toISOString().slice(0, 10),
        toBePaid:
          row.totalPaid ||
          row.paidUpSharePrice * shareholderSecurity.numberOfShares,
        isPaid: row.isPaid,
        isDeleted: row.isDeleted,
      });
    });

    for (const currentlyPaid of currentlyPaidDetails) {
      schedulePay.push({
        id: currentlyPaid.id,
        paidUpSharePrice: currentlyPaid.paidUpSharePrice,
        scheduledDate: currentlyPaid.scheduledDate,
        paidUpDate:
          currentlyPaid.paidUpDate || new Date().toISOString().slice(0, 10),
        toBePaid:
          currentlyPaid.totalPaid ||
          currentlyPaid.paidUpSharePrice * shareholderSecurity.numberOfShares,
        isPaid: true,
        isDeleted: false,
      });
    }

    setLoading(true);
    updatePartlyPaidDetails(
      {
        certificateId: shareholderSecurity.certificateId,
        scheduleDetails: schedulePay.map((details) =>
          _.omit(details, "actions")
        ),
      },
      {
        onSuccess: () => {
          toast("Schedule Details updated successfully!", {
            type: "success",
            autoClose: 2000,
          });
          onPrimaryAction();
          setLoading(false);
          queryClient.refetchQueries("getInstrumentAndShareHolder");
          queryClient.refetchQueries("getSecurityOverviewDetails");
          queryClient.refetchQueries("getAllEvents");
        },
        onError: (err: any) => {
          toast(err.response.data.errorMessage, {
            type: "error",
            autoClose: 2000,
          });
          setLoading(false);
        },
      }
    );
  };

  return (
    <div className="w-full bg-white border-2 border-dashed rounded-md ">
      <VStack className="font-medium text-left">
        <VStack className="p-5 text-lg font-medium border-b">
          <HStack className="justify-between">
            <p className="mb-3 text-lg font-semibold">Partly Paid Shares</p>
            <XMarkIcon
              onClick={() => onSecondaryAction()}
              className="w-6 h-6 cursor-pointer"
            />
          </HStack>
          <HStack className="justify-start text-base font-medium">
            <p className="mr-3">{shareholderSecurity?.securityClass}</p>
            <HStack>
              <p>Rounds -</p>
              <p className="px-2 pt-1 mr-2 border text-md text-xxs">
                {shareholderSecurity?.round}
              </p>
            </HStack>
            <p>Security -</p>
            <p
              className={`${getSecurityColor(
                shareholderSecurity?.securityType
              )} text-xs text-center w-24 align-middle font-semibold leading-5 rounded-sm pt-0.5`}
            >
              {shareholderSecurity?.securityType}
            </p>
          </HStack>
        </VStack>
        <Box className="border-b">
          <VStack className="justify-start px-5 py-7">
            <HStack className="justify-start">
              <div className="mr-15">
                <PartlyPaidDetailsCard
                  value={partlyPaidDetails?.sharePrice || 0}
                  label={`Share Price (${currencySymbol})`}
                />
              </div>
              <div>
                <PartlyPaidDetailsCard
                  value={partlyPaidDetails?.amountInvested || 0}
                  label={`Amount Invested (${currencySymbol})`}
                />
              </div>
              <div>
                <PartlyPaidDetailsCard
                  value={partlyPaidDetails?.amountDue || 0}
                  label={`Amount Due (${currencySymbol})`}
                />
              </div>
            </HStack>
          </VStack>
        </Box>
        <div className="py-3 ">
          <AGGridCurrentlyPaidTable
            currentlyPaidTableData={
              partlyPaidDetails?.currentlyPaidDetails || []
            }
            gridApi={gridRefCurrentlyPaid}
          />

          <AGGridSchedulePayDetailsTable
            schedulePayTableData={partlyPaidDetails?.schedulePayDetails || []}
            totalCurrentlyPaidUpSharePrice={totalCurrentlyPaidUpSharePrice}
            sharePrice={partlyPaidDetails?.sharePrice || 0}
            gridRefCurrentlyPaid={gridRefCurrentlyPaid}
            gridRefSchedulePaid={gridRefSchedulePaid}
            numberOfShares={shareholderSecurity.numberOfShares}
            amountInvested={amountInvested}
            setAmountInvested={setAmountInvested}
            setDeletedRows={setDeletedRows}
          />
        </div>
        <HStack className="justify-end px-6 py-4">
          <PrimaryCTAButton
            event-name="Save Director Modal"
            onClick={handleSubmit}
            loading={loading}
            disabled={userRoleData[0].role === "Admin Viewer"}
          >
            Save
          </PrimaryCTAButton>
        </HStack>
      </VStack>
    </div>
  );
}

function PartlyPaidDetailsCard({
  value,
  label,
}: {
  value: number | string;
  label: string;
}) {
  const currencyType = getCurrencyType();

  return (
    <Box className="flex-wrap py-4 mx-6 mt-3 bg-white rounded-md shadow-box">
      <VStack className="px-5">
        <div className="text-sm font-semibold text-center">
          <FormatNumberSpan value={Number(value)} format={currencyType} />
        </div>
        <div className="text-xs font-medium text-center">{label}</div>
      </VStack>
    </Box>
  );
}
