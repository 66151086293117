import React from "react";
import { FormatNumberSpan } from "../../utils/currencyRoboto";
import {
  getCurrencySymbol,
  getCurrencyType,
} from "../../utils/currencyFormatter";
import { HStack } from "../../components/utils";

interface ProgressBarProps {
  percentage: number; // Expected to be between 0 and 100
  raisedAmount: number;
  leftAmount: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  percentage,
  raisedAmount,
  leftAmount,
}) => {
  const currencyType = getCurrencyType();
  const currencySymbol = getCurrencySymbol();
  return (
    <div className="bg-gray-200 rounded-md h-4 mx-3">
      <div className="bg-gray-200 rounded-md h-4">
        <HStack
          className="bg-green-500 overflow-hidden placeholderLoader animate-pulse item-center h-4 rounded-md text-white text-xs font-semibold text-center items-center"
          style={{
            width: `${
              percentage >= 100 ? 100 : percentage > 10 ? percentage : 10
            }%`,
          }}
        >
          <HStack className="mx-auto">
            <FormatNumberSpan value={percentage} format={currencyType} />
            <p>%</p>
          </HStack>
        </HStack>
      </div>
      <div className="flex justify-between text-xs pt-2">
        <HStack className="items-center placeholderLoader">
          <div className="w-3 h-3 bg-green-500 placeholderLoader mr-1 border border-green-600"></div>
          <p className="text-gray-500 font-semibold">Money Raised:</p>
          <HStack className="font-bold">
            <span className="font-medium">{currencySymbol}</span>
            <FormatNumberSpan value={raisedAmount} format={currencyType} />
          </HStack>
        </HStack>
        <HStack className="items-center placeholderLoader">
          <p className="text-gray-500 font-semibold">Left to raise:</p>{" "}
          <HStack className="font-bold">
            <span className="font-medium">{currencySymbol}</span>
            <FormatNumberSpan value={leftAmount} format={currencyType} />
          </HStack>
        </HStack>
      </div>
    </div>
  );
};

export default ProgressBar;
