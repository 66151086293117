import { HStack } from "../../../components/utils";
import { BalanceSheet, ProfitAndLoss } from "../../../types/finance/Finance";
import { FinancialDataModel } from "../FinanceWrapper";
import CardComponent from "./CardComponent";

function FinancialCards({
  financialReportDetails,
}: {
  financialReportDetails: FinancialDataModel[];
}) {
  const currentYear = financialReportDetails[0];
  const previousYear = financialReportDetails[1];
  const timelineType = financialReportDetails[0]?.timelineType;
  const currentTimelineYear = financialReportDetails[0]?.year;
  const previousTimelineYear = financialReportDetails[1]?.year;

  return (
    <HStack className="flex-wrap justify-between mt-8 gap-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4">
      <CardComponent
        cardname="Revenue"
        currentYearValue={currentYear?.data?.totalRevenue}
        previousYearValue={previousYear?.data?.totalRevenue}
        timelineType={timelineType}
        currentTimelineYear={currentTimelineYear}
        previousTimelineYear={previousTimelineYear}
        latestYear={currentYear?.timeline}
        previousYear={previousYear?.timeline}
      />

      <CardComponent
        cardname="Expenses"
        currentYearValue={currentYear?.data?.totalExpenses}
        previousYearValue={previousYear?.data?.totalExpenses}
        timelineType={timelineType}
        currentTimelineYear={currentTimelineYear}
        previousTimelineYear={previousTimelineYear}
        latestYear={currentYear?.timeline}
        previousYear={previousYear?.timeline}
      />

      <CardComponent
        cardname="Cash Positions"
        currentYearValue={
          currentYear?.timeline && currentYear.data?.cashAndCashEquivalents
        }
        previousYearValue={
          previousYear?.timeline && previousYear.data?.cashAndCashEquivalents
        }
        timelineType={timelineType}
        currentTimelineYear={currentTimelineYear}
        previousTimelineYear={previousTimelineYear}
        latestYear={currentYear?.timeline}
        previousYear={previousYear?.timeline}
      />

      <CardComponent
        cardname="Debt"
        currentYearValue={
          currentYear?.timeline && currentYear.data?.totalBorrowings
        }
        previousYearValue={
          previousYear?.timeline && previousYear.data?.totalBorrowings
        }
        timelineType={timelineType}
        currentTimelineYear={currentTimelineYear}
        previousTimelineYear={previousTimelineYear}
        latestYear={currentYear?.timeline}
        previousYear={previousYear?.timeline}
      />
    </HStack>
  );
}

export default FinancialCards;
