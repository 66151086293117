import {
  getShareholderTagColor,
  getShareholderValue,
} from "../constants/ShareholderConstants";
import { getRoundIdentifierColor } from "../utils/colorUtils";

export function CaptableTag({
  dataEventName,
  tagName,
}: {
  dataEventName?: string;
  tagName: string;
}) {
  const textColor = getShareholderTagColor(tagName);
  const tagDisplayName = getShareholderValue(tagName);
  return (
    <div
      className={`w-fit h-fit px-1 mb-1  text-xxs rounded-sm font-semibold bg-white border  ${textColor}`}
    >
      <p data-event-name={dataEventName}>{tagDisplayName ?? tagName}</p>
    </div>
  );
}

export function ShareholderTag({
  dataEventName,
  tagName,
  shareholderType,
}: {
  dataEventName?: string;
  tagName: string;
  shareholderType: string;
}) {
  const textColor = getShareholderTagColor(shareholderType);
  return (
    <p
      data-event-name={dataEventName}
      className={`w-fit mr-4 h-fit px-1 py-0.5 text-xs rounded-md font-semibold bg-inherit border  ${textColor}`}
    >
      {tagName}
    </p>
  );
}

export function RoundTag({ roundIdentifier }: { roundIdentifier: string }) {
  const tagStyle = getRoundIdentifierColor(roundIdentifier);
  return (
    <div
      className={`w-fit px-1 mb-1  text-xs rounded-sm font-semibold ${tagStyle}`}
    >
      <p>{roundIdentifier}</p>
    </div>
  );
}
