/* eslint-disable no-undef */
import React from "react";

type TooltipProps = {
  text: string;
  children: JSX.Element;
  _className?: string;
  _position?: "right-0" | "left-0" | "right-1/2" | "bottom-0" | "left-1/2";
};
function DashboardTooltip(props: TooltipProps) {
  const { text, children, _className, _position } = props;
  return (
    <div className="relative group min-w-[32px]">
      {children}
      <div
        className={`absolute bottom-0 flex flex-col items-center hidden mb-6 rounded-md group-hover:flex ${_position}`}
      >
        <div
          className={`relative z-20 px-2 py-1 text-xs text-black text-center whitespace-normal bg-[#f6f9fb] shadow-lg rounded-lg ${_className}`}
        >
          {text}
        </div>
        <div className="w-3 h-3 -mt-2 rotate-45 bg-[#f6f9fb]"></div>
      </div>
    </div>
  );
}

export default DashboardTooltip;
