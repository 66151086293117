import {
  GlobalAccount,
  GlobalInvestorLinkInput,
  UpdateGlobalInvestorStateInput,
} from "../types/GlobalInvestors";
import { InvestorUserAccess } from "../types/Investors";

import api from "./capTableApi";

export async function getGlobalInvestors(): Promise<GlobalAccount[]> {
  return api.get("v1/company/globalInvestors/").then((res) => res.data.data);
}

export async function addGlobalInvestor(
  data: GlobalInvestorLinkInput
): Promise<void> {
  return api
    .post(`v1/company/globalInvestors/linkInvestor`, data)
    .then((res) => res.data);
}
export async function generateInvestorCredentials(
  data: InvestorUserAccess
): Promise<void> {
  return api
    .put(`v1/company/companyInvestor/invPortalAccess`, data)
    .then((res) => res.data);
}
