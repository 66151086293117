import { Icon } from "@iconify/react";
import Slider from "@mui/material/Slider";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { useState } from "react";
import { Box, Center, HStack, VStack } from "../../components/utils";
import { StackedBarChart } from "./InvestorCaptableChart";
import { limitString } from "../../utils/string";

ChartJS.register(ArcElement, Tooltip, Legend);

export interface ShareHolderCaptableChartModel {
  name: string;
  preHoldingFdbPercentage: number;
  postHoldingFdbPercentage: number;
}

export function CapTableBarChart({
  shareHolderChartData,
  dilutedPercentage,
  toggleChartAndToggle,
}: {
  shareHolderChartData: ShareHolderCaptableChartModel[];
  dilutedPercentage: number;
  toggleChartAndToggle: () => void;
}) {
  const [count, setCount] = useState<number>(3);
  const _shareHolderChartData: ShareHolderCaptableChartModel[] =
    shareHolderChartData.slice(0, count);
  const values = _shareHolderChartData.map((plan) => [
    plan.preHoldingFdbPercentage * 100,
    plan.postHoldingFdbPercentage - plan.preHoldingFdbPercentage > 0
      ? plan.preHoldingFdbPercentage * (1 - dilutedPercentage) * 100
      : plan.postHoldingFdbPercentage * 100,
    plan.postHoldingFdbPercentage - plan.preHoldingFdbPercentage > 0
      ? Math.abs(
          (plan.postHoldingFdbPercentage -
            plan.preHoldingFdbPercentage * dilutedPercentage) *
            100
        )
      : 0,
    `${limitString(plan.name || "", 14)}`,
  ]);

  const preHoldingFdbPercentageValue = values.map(
    (preHoldingFdbPercentages) => preHoldingFdbPercentages[0]
  );
  const postHoldingFdbPercentageValue = values.map(
    (postHoldingFdbPercentages) => postHoldingFdbPercentages[1]
  );
  const increasedPercentageValue = values.map(
    (increasedPercentages) => increasedPercentages[2]
  );
  const investorNames = values.map((investors) => investors[3]);

  const chartData = {
    labels: investorNames,
    datasets: [
      {
        label: "Old Investment Percentage",
        data: preHoldingFdbPercentageValue,
        backgroundColor: "#869EDD",
        borderColor: "#869EDD",
        stack: "Stack 0",
      },
      {
        label: "Diluted Percentage",
        data: postHoldingFdbPercentageValue,
        backgroundColor: "#FF8863",
        borderColor: "#FF8863",

        stack: "Stack 1",
      },
      {
        label: "Increased Percentage",
        data: increasedPercentageValue,
        backgroundColor: "#44954C",
        borderColor: "#44954C",
        stack: "Stack 1",
      },
    ],
  };

  const updateRange = (e: any, data: any) => {
    setCount(data);
  };

  return (
    <VStack className={`h-ful`}>
      <HStack className="justify-between">
        <VStack className="min-w-max">
          <p className="text-lg font-medium text-gray-dark">Cap Table</p>
          <p className="text-xs font-medium text-gray-light">
            {shareHolderChartData.length} Shareholders
          </p>
        </VStack>
        <HStack>
          <div className="w-60 hidden md:block px-8 mr-4">
            <VStack>
              <p>Number of Investor</p>
              <Slider
                value={count}
                min={1}
                defaultValue={count}
                max={shareHolderChartData.length}
                onChange={updateRange}
                valueLabelDisplay="auto"
              />
            </VStack>
          </div>
          <Center
            className="p-2 h-10 text-white rounded bg-orange-501 cursor-pointer"
            onClick={toggleChartAndToggle}
          >
            <Icon
              icon="material-symbols:table-chart-outline"
              className="text-white rounded "
              height={32}
              width={32}
            />
          </Center>
        </HStack>
      </HStack>

      <Box className="w-full h-96  align-center">
        {chartData && <StackedBarChart chartData={chartData} />}
      </Box>
      <div className="px-2 mx-2 md:hidden">
        <Slider
          value={count}
          min={1}
          defaultValue={count}
          max={shareHolderChartData.length}
          onChange={updateRange}
          valueLabelDisplay="auto"
        />
      </div>
    </VStack>
  );
}
