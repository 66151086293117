import { Icon } from "@iconify/react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Dialog } from "@mui/material";
import { useNavigate, useParams } from "react-router";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { toast } from "react-toastify";
import { Input, Label } from "../../components/shared/InputField";
import { SwitchButton } from "../../components/shared/SwitchButton";
import { Box, Error, HStack, VStack } from "../../components/utils";
import {
  useDeleteOnGoingTransaction,
  usePostOnGoingTransaction,
  usePostTransactionRoundDetail,
  useRoundExcelUpload,
  useDiscardQuickRound2,
  useGetOngoingNonPricedRound,
} from "../../queries/transactionRound";
import {
  ButtonSize,
  LoaderCTAButton,
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../quickRound/CTAButtonComponents";
import {
  QuickRoundTransactionModel,
  TransactionRoundInputModel,
  PrimaryTransaction,
  SecondaryTransaction,
  TransactionDetail,
  ConvertibleDetails,
} from "./RoundTransactionModel";
import { useAuthStore } from "../../store";
import {
  initialRoundState,
  useQuickRoundTransactionStore,
} from "../../store/useQuickTransactionStore";
import AddTransactionRound from "./AddTransactionRound";
import { useNewShareholderInstrumentStore } from "../../store/newShareholderAndInstrumentStore";
import { Select } from "../../components/shared/Select";
import convertToBase64 from "../../utils/convertToBase64";
import {
  getCompanyCurrency,
  getCurrencySymbol,
  getCurrencyType,
} from "../../utils/currencyFormatter";
import { currencies, currencyTypes } from "../../constants/CompanyCurrencyType";
import { useGetInstrumentAndShareHolder } from "../../queries/quickRound";
import { useShareholderInstrumentStore } from "../../store/shareholderInstrumentStore";
import FileUploadedUI from "../shareHolderRights/FileUploadedUI";
import { getRoundCurrencySymbol } from "../../utils/currencyRoboto";
import { downloadS3File } from "../../utils/DownloadFile";
import { ShowNumberInWords } from "../../components/shared/UiElement";
import DeleteModal from "../modeling/DeleteModal";
import QuickRoundStack from "./QuickRoundStack";
import {
  useGetListOfModeling,
  useGetModelingDetails,
} from "../../queries/modeling";
import BCHeader from "../../shared/BCHeader";
import EmptyTableIllustration from "../../shared/EmptyTableIllustration";
import { NonPricedRoundList } from "../../modals/NonPricedList";
import { useConversionStore } from "../../store/conversionStore";
import {
  extractSecondaryTransaction,
  extractTransaction,
} from "./ExtractionUtility";
import { useRoundModelStore } from "../../store/roundModelingStore";
import {
  convertToNumber,
  filterOnlyNumbers,
  numberWithCommas,
} from "../../utils/numUtils";
import WarningModal from "../modeling/WarningModel";

const transactionSchema = Yup.object().shape({
  roundName: Yup.string().required().label("Round Name"),
  roundId: Yup.string().required().label("Round Identifier"),
  roundCurrency: Yup.string().required().label("Round Currency"),
  exchangeRate: Yup.number().moreThan(0).required().label("Exchange Rate"),
  roundSize: Yup.number().positive().required().label("Round Size"),
  valuation: Yup.number().positive().required().label("Valuation"),
  pricedRound: Yup.boolean().required().label("Priced Round"),
  issuanceType: Yup.string().when("pricedRound", {
    is: (val: boolean) => val === true,
    then: Yup.string().required().label("Issuance Type"),
    otherwise: Yup.string().label("Issuance Type"),
  }),
  paymentType: Yup.string().when("pricedRound", {
    is: (val: boolean) => val === true,
    then: Yup.string().required().label("Payment Type"),
    otherwise: Yup.string().label("Payment Type"),
  }),
  allotmentType: Yup.string().when("pricedRound", {
    is: (val: boolean) => val === true,
    then: Yup.string().required().label("Allotment Type"),
    otherwise: Yup.string().label("Allotment Type"),
  }),
});

function QuickAddRound() {
  const { id } = useParams() as {
    id: string;
  };
  const companyId = useAuthStore.getState().companyId || "";
  const {
    state: template,
    setState: setTemplate,
    reset: resetTemplate,
  } = useQuickRoundTransactionStore();
  const [selectedModelingId, setSelectedModelingId] = useState(id ?? "");
  const { data: _modelingData2 } = useGetListOfModeling();
  const { refetch: fetchModelingDetails, data: modelDetail } =
    useGetModelingDetails(id ?? selectedModelingId);

  const [modelDetails, setModelDetails] = useState(modelDetail);
  const { refetch, data: nonPricedRoundDetail } = useGetOngoingNonPricedRound();

  const [minimum, setMinimum] = useState(false);
  const { mutate: deleteOnGoingTransaction } =
    useDeleteOnGoingTransaction(companyId);
  const { mutate: postOnGoingTransaction } = usePostOnGoingTransaction();
  const { mutate: postRoundTransaction } =
    usePostTransactionRoundDetail(companyId);
  const { mutate: roundExcelUpload, data: excelData } =
    useRoundExcelUpload(companyId);

  const { roundCreation: roundModel } = useRoundModelStore();

  const pathName = window.location.href.split("/");
  const roundType = pathName[4];
  const companyCurrency = getCompanyCurrency();
  const companyCurrencySymbol = getCurrencySymbol();
  const navigate = useNavigate();
  const { mutate: discardQuickRound } = useDiscardQuickRound2();
  const displayDiscardQuickRoundPopUp = () => {
    setDialog({
      open: true,
      mode: "Delete",
      message: `Do you want to discard the current ongoing quick ${roundType} ?`,
    });
  };

  const convertibleWarningPopUp = (nonPricedRoundDetail: any) => {
    if (
      ((template?.roundType as any) === "MANUAL" ||
        (template?.roundType as any) === "ROUNDMODELING") &&
      formik.values.pricedRound &&
      (nonPricedRoundDetail?.length || 0) > 0 &&
      (template.newRoundInput?.convertibles?.length || 0) === 0
    )
      setDialog({
        open: true,
        mode: "Warning",
      });
    else {
      setDialog({
        open: false,
        mode: "Warning",
      });
      setCreateRound(true);
    }
  };

  const currencyType = getCurrencyType();

  const [onHoverRoundSize, setOnHoverRoundSize] = useState(false);
  const [onHoverValuation, setOnHoverValuation] = useState(false);
  const [onHoverExchangeRate, setOnHoverExchangeRate] = useState(false);

  const [formatExchangeRate, setFormatExchangeRate] = useState("");
  const [formatValuation, setFormatValuation] = useState("");
  const [formatRoundSize, setFormatRoundSize] = useState("");

  useEffect(() => {
    setCreateRound(false);
    setFormatValuation(``);
    setFormatExchangeRate(``);
    setFormatRoundSize(``);
    primaryTransactionStore.setPrimary([]);
    primaryTransactionStore.setSecondary([]);
  }, []);
  useEffect(() => {
    if (
      template.newRoundDetails?.closeDate &&
      ((template.newRoundInput?.primaryTransactions?.length ?? 0) > 0 ||
        (template.newRoundInput?.secondaryTransactions?.length ?? 0) > 0)
    ) {
      setCreateRound(true);
    }
  }, [
    template.newRoundInput?.primaryTransactions?.length,
    template.newRoundInput?.secondaryTransactions?.length,
  ]);

  const handleDiscard = () => {
    setDialog({ open: false });
    discardQuickRound();
    resetTemplate();
    deleteOnGoingTransaction(companyId);
    navigate("/transactions/allTransactions");
  };

  const [initialValues, setInitialValues] = useState({
    roundName: template.newRoundDetails?.roundName || "",
    roundId: template.newRoundDetails?.roundId || "",
    roundCurrency: template.newRoundDetails?.roundCurrency || companyCurrency,
    exchangeRate: template.newRoundDetails?.exchangeRate || 1,
    closeDate: template.newRoundDetails?.closeDate || "",
    pricedRound: template.newRoundDetails?.pricedRound || true,
    roundSize: template.newRoundDetails?.roundSize || 0,
    postRoundEsop: template.newRoundDetails?.postRoundEsop || 0,
    autoDiluteEsop: template.newRoundDetails?.autoDiluteEsop || true,
    valuation: template.newRoundDetails?.valuation || 0,
    issuanceType: template.newRoundDetails?.issuanceType || "",
    paymentType: template.newRoundDetails?.paymentType || "",
    allotmentType: template.newRoundDetails?.allotmentType || "",
  });

  useEffect(() => {
    setInitialValues({
      roundName: template.newRoundDetails?.roundName || "",
      roundId: template.newRoundDetails?.roundId || "",
      roundCurrency: template.newRoundDetails?.roundCurrency || companyCurrency,
      exchangeRate: template.newRoundDetails?.exchangeRate || 1,
      closeDate: template.newRoundDetails?.closeDate || "",
      pricedRound: template.newRoundDetails?.pricedRound || true,
      roundSize: template.newRoundDetails?.roundSize || 0,
      postRoundEsop: template.newRoundDetails?.postRoundEsop || 0,
      autoDiluteEsop: template.newRoundDetails?.autoDiluteEsop || true,
      valuation: template.newRoundDetails?.valuation || 0,
      issuanceType: template.newRoundDetails?.issuanceType || "",
      paymentType: template.newRoundDetails?.paymentType || "",
      allotmentType: template.newRoundDetails?.allotmentType || "",
    });
  }, [template]);

  useEffect(() => {
    if (initialValues.exchangeRate) {
      setFormatExchangeRate(`${initialValues.exchangeRate}`);
    }
    if (initialValues.roundSize) {
      setFormatRoundSize(`${initialValues.roundSize}`);
    }
    if (initialValues.valuation) {
      setFormatValuation(`${initialValues.valuation}`);
    }
  }, [template, modelDetail, initialValues]);

  const primaryTransactionStore = useNewShareholderInstrumentStore();

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: transactionSchema,
    initialValues: template.newRoundDetails || initialValues,
    onSubmit: (values) => {
      convertibleWarningPopUp(nonPricedRoundDetail);
      setTemplate({ ...template, newRoundDetails: formik.values });
      setIsProformaStarted(true);
    },
  });

  const shareholderAndInstrumentstore = useShareholderInstrumentStore();
  const {
    refetch: getInstrumentAndShareHolder,
    isPlaceholderData,
    data: instrumentShareholderInfoData,
  } = useGetInstrumentAndShareHolder(companyId);

  useEffect(() => {
    if (!isPlaceholderData) {
      if (
        instrumentShareholderInfoData?.shareholders.length !==
        shareholderAndInstrumentstore.shareholders.length
      )
        shareholderAndInstrumentstore.setShareholders(
          instrumentShareholderInfoData?.shareholders || []
        );
      if (
        instrumentShareholderInfoData?.instrumentClasses.length !==
        shareholderAndInstrumentstore.instrumentClasses.length
      )
        shareholderAndInstrumentstore.setInstrumentClass(
          instrumentShareholderInfoData?.instrumentClasses || []
        );
    }
  }, [instrumentShareholderInfoData, isPlaceholderData]);

  const fileRef2 = useRef() as React.MutableRefObject<HTMLInputElement>;

  const onClickAoaFunction = () => {
    fileRef2.current.click();
  };
  const initialVal = {
    name: "",
    size: 0,
    type: "",
  };
  const onClickAoaDelete = () => {
    fileRef2.current.value = "";
    setTemplate({ ...template, fileType: initialVal });
  };
  const shareholderInstruments = useShareholderInstrumentStore();
  const companyCurrencyString = getCompanyCurrency();

  function updatePrimaryStore() {
    const primary = extractTransaction(
      template.newRoundInput?.primaryTransactions || [],
      shareholderInstruments.shareholders,
      shareholderInstruments.instrumentClasses,
      companyCurrencyString
    );

    const secondary = extractSecondaryTransaction(
      template.newRoundInput?.secondaryTransactions || [],
      shareholderInstruments.shareholders,
      shareholderInstruments.instrumentClasses
    );
    if (template.roundType === "MANUAL") {
      for (const value of primaryTransactionStore.primary) {
        if (
          !primary.find(
            (ele) =>
              ele.investorId === value.investorId &&
              ele.instrumentClassId === value.instrumentClassId
          )
        ) {
          primary.push(value);
        }
      }
      for (const value of primaryTransactionStore.secondary) {
        if (
          !secondary.find(
            (ele) =>
              ele.buyerInvestorId === value.buyerInvestorId &&
              ele.sellerInvestorId === value.sellerInvestorId &&
              value.instrumentClassId === ele.instrumentClassId
          )
        ) {
          secondary.push(value);
        }
      }
    }

    primaryTransactionStore.setPrimary(primary);
    primaryTransactionStore.setSecondary(secondary);
  }

  useEffect(() => {
    updatePrimaryStore();
  }, [shareholderInstruments, template.newRoundInput]);

  const handleSaveProforma = () => {
    const primaryTransactions: PrimaryTransaction[] =
      primaryTransactionStore.primary.map((element) => ({
        uuid: element.uuid ?? "",
        amount: element.amountInvested || 0,
        sharePrice: element.sharePrice || 0,
        numberOfShares: element.numberOfShares || 0,
        companyInvestorId: element.investorId || "",
        instrumentClassId: element.instrumentClassId || "",
        currency: element.currency || companyCurrency,
        exchangeRate: element.exchangeRate || 1,
      })) || [];
    const secondaryTransactions: SecondaryTransaction[] =
      primaryTransactionStore.secondary.map((element) => ({
        uuid: element.uuid ?? "",
        date: element.date || "",
        amount: element.amountInvested || 0,
        sharePrice: element.sharePrice || 0,
        numberOfShares: element.numberOfShares || 0,
        shareNumber: element.shareNumber || "",
        companyBuyerInvestorId: element.buyerInvestorId || "",
        companySellerInvestorId: element.sellerInvestorId || "",
        instrumentClassId: element.instrumentClassId || "",
      })) || [];

    const transactions: TransactionDetail = {
      primaryTransactions,
      secondaryTransactions,
      esopPostRoundPercentage: formik.values.postRoundEsop ?? 0,
      convertibles: template.newRoundInput?.convertibles,
    };
    const templateData = {
      ...template,
      newRoundInput: transactions,
    };

    setTemplate(templateData);
    postOnGoingTransaction(
      {
        ...templateData,
      },
      {
        onSuccess: (data) => {
          toast("Proforma Saved Successfully!", {
            type: "success",
            autoClose: 2000,
          });
        },
        onError: (err: any) => {
          toast(err.response.data.errorMessage, {
            type: "error",
            autoClose: 2000,
          });
        },
      }
    );
  };
  const [excelUpload, setExcelUpload] = useState(false);
  const extractPrimaryDetails = () =>
    primaryTransactionStore.primary.map((element) => ({
      amount: element.amountInvested || 0,
      sharePrice: element.sharePrice || 0,
      numberOfShares: element.numberOfShares || 0,
      companyInvestorId: element.investorId || "",
      instrumentClassId: element.instrumentClassId || "",
      currency: element.currency || companyCurrency,
      exchangeRate: element.exchangeRate || 1,
    })) || [];

  const extractSecondaryDetails = () =>
    primaryTransactionStore.secondary.map((element) => ({
      date: element.date || "",
      amount: element.amountInvested || 0,
      sharePrice: element.sharePrice || 0,
      numberOfShares: element.numberOfShares || 0,
      shareNumber: element.shareNumber || "",
      companyBuyerInvestorId: element.buyerInvestorId || "",
      companySellerInvestorId: element.sellerInvestorId || "",
      instrumentClassId: element.instrumentClassId || "",
    })) || [];

  const submitPostRoundTransaction = (finalData: any) => {
    postRoundTransaction(finalData, {
      onSuccess: (data) => {
        postOnGoingTransaction({
          ...finalData,
          fileType: initialVal,
          roundType: template.roundType,
          eventId: data.id,
        });

        setCreateRoundClicked(false);

        setTemplate({
          ...finalData,
          fileType: initialVal,
          eventId: data.id,
          roundType: template.roundType,
        });
        toast("Round Detail Uploaded Successfully!", {
          type: "success",
          autoClose: 2000,
        });
      },
      onError: (err: any) => {
        setCreateRoundClicked(false);
        toast(err.response.data.errorMessage, {
          type: "error",
          autoClose: 2000,
        });
      },
    });
  };

  const buildRoundDetailModel = () => ({
    stepNo: 2,
    transactionType: "round",
    companyId,
    newRoundDetails: formik.values,
  });

  const handleSubmitCall = () => {
    setCreateRoundClicked(true);
    const roundDetails: QuickRoundTransactionModel = buildRoundDetailModel();
    const primaryT: PrimaryTransaction[] = extractPrimaryDetails();
    const secondaryT: SecondaryTransaction[] = extractSecondaryDetails();

    const transactions: TransactionDetail = {
      primaryTransactions: primaryT,
      secondaryTransactions: secondaryT,
      esopPostRoundPercentage: formik.values.postRoundEsop ?? 0,
      convertibles: template.newRoundInput?.convertibles,
    };

    const finalData = {
      ...roundDetails,
      newRoundInput: transactions,
    };

    submitPostRoundTransaction(finalData);
  };

  function updateAoa(e: React.ChangeEvent<HTMLInputElement>) {
    if (!e?.target?.files?.[0]) return;
    const file = e.target.files[0];
    const allowedFileExtensions = [".xls", ".xlsx"];
    if (
      allowedFileExtensions.some((extension) => file.name.endsWith(extension))
    ) {
      setAoaFile(file);
      setTemplate({
        ...template,
        fileType: {
          name: file.name,
          size: file.size,
          type: file.type,
        },
      });
    } else {
      toast(
        `Invalid file type, allowed file types are ${allowedFileExtensions.join(
          ", "
        )}`,
        { type: "error" }
      );
    }
  }

  useEffect(() => {
    if (id && !template.eventId) {
      setSelectedModelingId(id ?? "");
      fetchModelingDetails().then((data) => {
        setModelDetails(data.data);
      });
    }
  }, [id]);

  const [loading, setLoading] = useState(false);
  const [aoaFile, setAoaFile] = useState<File>();
  const [createRound, setCreateRound] = useState(false);

  useEffect(() => {
    if (!template.eventId && !isPlaceholderData) {
      fetchModelingDetails().then((data) => {
        if (data.data?.newRoundDetails) {
          setModelDetails(data.data);
          setTemplate({
            ...data.data,
            newRoundDetails: {
              ...data.data.newRoundDetails,
              postRoundEsop: data.data.newRoundDetails.postRoundEsop ?? 0,
            },
            newRoundInput: {
              primaryTransactions:
                data.data.newRoundInput.primaryTransactions.map((ele: any) => ({
                  ...ele,
                  uuid: ele.id,
                })),
              secondaryTransactions:
                data.data.newRoundInput.secondaryTransactions.map(
                  (ele: any) => ({
                    ...ele,
                    uuid: ele.id,
                  })
                ),
              esopPostRoundPercentage:
                data.data.newRoundInput.esopPostRoundPercentage,
              convertibles: data.data.newRoundInput.convertibles,
            },
          });
          const valuationValue = data.data.newRoundDetails?.valuation ?? 0;
          const exhangeRateValue = data.data.newRoundDetails?.exchangeRate ?? 0;
          const roundSizeValue = data.data.newRoundDetails?.roundSize ?? 0;
          setFormatValuation(`${valuationValue}`);
          setFormatExchangeRate(`${exhangeRateValue}`);
          setFormatRoundSize(`${roundSizeValue}`);
          setInitialValues({
            roundName: data.data.newRoundDetails?.roundName || "",
            roundId: data.data.newRoundDetails?.roundId || "",
            roundCurrency:
              data.data.newRoundDetails?.roundCurrency || companyCurrency,
            exchangeRate: data.data.newRoundDetails?.exchangeRate || 1,
            closeDate: data.data.newRoundDetails?.closeDate || "",
            pricedRound: data.data.newRoundDetails?.pricedRound || true,
            roundSize: data.data.newRoundDetails?.roundSize || 0,
            postRoundEsop: data.data.newRoundDetails?.postRoundEsop || 0,
            autoDiluteEsop: data.data.newRoundDetails?.autoDiluteEsop || true,
            valuation: data.data.newRoundDetails?.valuation || 0,
            issuanceType: data.data.newRoundDetails?.issuanceType || "",
            paymentType: data.data.newRoundDetails?.paymentType || "",
            allotmentType: data.data.newRoundDetails?.allotmentType || "",
          });
          setAllFeildTouched();
        }
      });
    }
  }, [selectedModelingId]);

  const setAllFeildTouched = () => {
    setTimeout(() => {
      formik.validateForm();
      formik.setFieldTouched("roundName", true);
      formik.setFieldTouched("roundId", true);
      formik.setFieldTouched("roundCurrency", true);
      formik.setFieldTouched("exchangeRate", true);
      formik.setFieldTouched("closeDate", true);
      formik.setFieldTouched("pricedRound", true);
      formik.setFieldTouched("postRoundEsop", true);
      formik.setFieldTouched("roundSize", true);
      formik.setFieldTouched("autoDiluteEsop", true);
      formik.setFieldTouched("valuation", true);
      formik.setFieldTouched("issuanceType", true);
      formik.setFieldTouched("paymentType", true);
      formik.setFieldTouched("allotmentType", true);
    }, 1000);
  };

  const [isProformaStarted, setIsProformaStarted] = useState(false);

  useEffect(() => {
    if (!template.eventId) {
      if (modelDetails && selectedModelingId && !id) {
        setTemplate({
          ...modelDetails,
          newRoundDetails: {
            ...modelDetails.newRoundDetails,
            postRoundEsop: modelDetails.newRoundDetails.postRoundEsop ?? 0,
          },
          newRoundInput: {
            primaryTransactions:
              modelDetails.newRoundInput.primaryTransactions.map(
                (ele: any) => ({
                  ...ele,
                  uuid: ele.id,
                })
              ),
            secondaryTransactions:
              modelDetails.newRoundInput.secondaryTransactions.map(
                (ele: any) => ({
                  ...ele,
                  uuid: ele.id,
                })
              ),
            esopPostRoundPercentage:
              modelDetails.newRoundInput.esopPostRoundPercentage,
          },
        });
        setInitialValues({
          roundName: modelDetails.newRoundDetails?.roundName || "",
          roundId: modelDetails.newRoundDetails?.roundId || "",
          roundCurrency:
            modelDetails.newRoundDetails?.roundCurrency || companyCurrency,
          exchangeRate: modelDetails.newRoundDetails?.exchangeRate || 1,
          closeDate: modelDetails.newRoundDetails?.closeDate || "",
          pricedRound: modelDetails.newRoundDetails?.pricedRound || true,
          roundSize: modelDetails.newRoundDetails?.roundSize || 0,
          postRoundEsop: modelDetails.newRoundDetails?.postRoundEsop || 0,
          autoDiluteEsop: modelDetails.newRoundDetails?.autoDiluteEsop || true,
          valuation: modelDetails.newRoundDetails?.valuation || 0,
          issuanceType: modelDetails.newRoundDetails?.issuanceType || "",
          paymentType: modelDetails.newRoundDetails?.paymentType || "",
          allotmentType: modelDetails.newRoundDetails?.allotmentType || "",
        });
        setAllFeildTouched();
      } else if (excelData) {
        const primaryT = excelData.newRoundInput?.primaryTransactions?.map(
          (ele: any) => ({
            ...ele,
            uuid: ele.id,
          })
        );
        const secondaryT = excelData.newRoundInput?.secondaryTransactions?.map(
          (ele: any) => ({
            ...ele,
            uuid: ele.id,
          })
        );

        setTemplate({
          ...excelData,
          newRoundDetails: {
            ...excelData.newRoundDetails,
            autoDiluteEsop: excelData.newRoundDetails?.autoDiluteEsop ?? false,
            pricedRound: excelData.newRoundDetails?.pricedRound ?? false,
            postRoundEsop: excelData.newRoundDetails?.postRoundEsop ?? 0,
          },
          newRoundInput: {
            primaryTransactions: primaryT,
            secondaryTransactions: secondaryT,
            esopPostRoundPercentage:
              excelData.newRoundInput?.esopPostRoundPercentage ?? 0,
          },
        });
        setAllFeildTouched();
      }
    }
  }, [modelDetails, excelData]);

  const [dialog, setDialog] = useState<{
    open: boolean;
    message?: string;
    data?: any;
    index?: number;
    mode?: "Non Priced Round Conversion" | "Delete" | "Warning";
    action?: "Yes, Continue" | "Cancel";
  }>({
    open: false,
  });
  const [createRoundClicked, setCreateRoundClicked] = useState(false);
  let oneTime = true;
  useEffect(() => {
    if (oneTime) {
      refetch();
      oneTime = false;
    }
  }, []);

  useEffect(() => {
    if (dialog.action === "Yes, Continue") {
      setCreateRound(true);
    } else if (dialog.action === "Cancel") {
      setCreateRound(false);
    }
  }, [dialog.action]);

  return (
    <VStack>
      <Dialog open={dialog.open} maxWidth="md">
        {dialog.mode === "Non Priced Round Conversion" &&
        nonPricedRoundDetail ? (
          <NonPricedRoundList
            rounds={[nonPricedRoundDetail[1], nonPricedRoundDetail[2]] || []}
            onPrimaryAction={() => setDialog({ open: false })}
            onSecondaryAction={() => setDialog({ open: false })}
          />
        ) : (
          <div></div>
        )}
        {dialog.mode === "Delete" && (
          <DeleteModal
            data={dialog.message}
            onPrimaryAction={handleDiscard}
            onSecondaryAction={() => setDialog({ open: false })}
          />
        )}
        {dialog.mode === "Warning" && (
          <WarningModal
            onPrimaryAction={() => {
              setDialog({ open: false, action: "Yes, Continue" });
            }}
            onSecondaryAction={() =>
              setDialog({ open: false, action: "Cancel" })
            }
            data={
              "There are pending convertibles on your cap table. You can visit the securities page and convert them before adding this transaction. Do you want to add this round without converting the pending convertibles ?"
            }
            title={"Warning"}
            buttonTitle={"Yes, Continue"}
          />
        )}
      </Dialog>

      <Box className="">
        {createRound ? (
          <QuickRoundStack
            currentCompanyDetail={template.newRoundDetails}
            moneyRaised={primaryTransactionStore.primary?.reduce(
              (accumulator: any, data) =>
                accumulator + data.amountInvested * (data.exchangeRate || 1),
              0
            )}
            onEdit={() => {
              setCreateRound(false);
            }}
          />
        ) : (
          <VStack className="p-6 transition duration-1000 ease-in delay-1000 bg-white border rounded-lg border-borderColor shadow-box">
            <HStack className="justify-between pb-8">
              <VStack>
                <HStack className="justify-between">
                  <p className="text-xl font-semibold text-gray-dark">
                    Round Details
                  </p>
                </HStack>
                <Box className="text-xs font-medium text-gray-light">
                  Fill the round details to create a quick round
                </Box>
              </VStack>
              {(template.roundType === "MANUAL" ||
                (template.roundType === "ROUNDMODELING" &&
                  selectedModelingId)) && (
                <SecondaryCTAButton
                  loading={loading}
                  buttonSize={ButtonSize.SMALL}
                  event-name="Discard Proforma Round"
                  className="h-8 min-w-max"
                  onClick={displayDiscardQuickRoundPopUp}
                >
                  Discard
                </SecondaryCTAButton>
              )}
            </HStack>
            {!isProformaStarted && !template.newRoundDetails && (
              <HStack>
                <VStack className={`mb-4 gap-8`}>
                  <Label className="text-sm font-medium">
                    Select method of round creation
                  </Label>
                  <HStack className="flex items-center gap-4">
                    <Icon
                      event-name="Upload Excel with round details"
                      onClick={() =>
                        selectedModelingId
                          ? {}
                          : setTemplate({
                              ...template,
                              roundType: "EXCEL",
                            })
                      }
                      icon={
                        template.roundType === "EXCEL"
                          ? "ic:round-radio-button-checked"
                          : "ic:round-radio-button-unchecked"
                      }
                      className={
                        selectedModelingId
                          ? "cursor-not-allowed text-primary"
                          : " text-primary"
                      }
                      width="18"
                      height="18"
                    />

                    <Label className="text-sm font-medium">
                      Upload Excel with round details
                    </Label>
                  </HStack>

                  <HStack className="flex items-center gap-4">
                    <Icon
                      event-name="Import from modeling"
                      onClick={() =>
                        selectedModelingId
                          ? {}
                          : setTemplate({
                              ...template,
                              roundType: "ROUNDMODELING",
                              fileName: "",
                              stepNo: 1,
                            })
                      }
                      icon={
                        template.roundType === "ROUNDMODELING"
                          ? "ic:round-radio-button-checked"
                          : "ic:round-radio-button-unchecked"
                      }
                      className={
                        selectedModelingId
                          ? "cursor-not-allowed text-primary"
                          : " text-primary"
                      }
                      width="18"
                      height="18"
                    />
                    <Label className="text-sm font-medium">
                      Import from modeling
                    </Label>
                  </HStack>
                  <HStack className="flex items-center gap-4">
                    <Icon
                      event-name="Enter Manually"
                      onClick={() =>
                        selectedModelingId
                          ? {}
                          : setTemplate({
                              ...template,
                              roundType: "MANUAL",
                              fileName: "",
                              stepNo: 1,
                            })
                      }
                      icon={
                        template.roundType === "MANUAL"
                          ? "ic:round-radio-button-checked"
                          : "ic:round-radio-button-unchecked"
                      }
                      className={
                        selectedModelingId
                          ? "cursor-not-allowed text-primary"
                          : " text-primary"
                      }
                      width="18"
                      height="18"
                    />
                    <Label className="text-sm font-medium">
                      Enter Manually
                    </Label>
                  </HStack>
                </VStack>
                <VStack className="py-12 mx-20 ">
                  <VStack
                    className={`${
                      template.roundType === "ROUNDMODELING" ? "" : "hidden"
                    }`}
                  >
                    <Label> Select the model to import details</Label>
                    <Select
                      placeholder="Select Modeling"
                      className="w-72"
                      options={_modelingData2 ?? []}
                      valueGetter={(o) => o.modelId}
                      textGetter={(o) => o.modelName}
                      value={selectedModelingId}
                      onChange={(e: any) => {
                        if (e.target.value) {
                          setSelectedModelingId(e.target.value);
                        } else {
                          setSelectedModelingId("");

                          setTemplate({
                            ...initialRoundState,
                            roundType: "ROUNDMODELING",
                          });
                        }
                      }}
                    />
                  </VStack>
                  <VStack
                    className={`${
                      template.roundType !== "EXCEL" ? "hidden" : ""
                    }`}
                  >
                    <input
                      ref={fileRef2}
                      onChange={updateAoa}
                      multiple={false}
                      type="file"
                      accept=".xls, .xlsx"
                      hidden
                    />
                    <Label className="py-10">Upload Round Template Excel</Label>
                    <FileUploadedUI
                      uploadText={"Upload Excel Sheet"}
                      fileDetail={
                        template.fileType ?? {
                          name: "",
                          size: 0,
                          type: "",
                        }
                      }
                      callBack={onClickAoaFunction}
                      onClickDelete={onClickAoaDelete}
                    />
                    <p className="pt-1 text-xs">
                      download the template{" "}
                      <span
                        event-name="proforma excel template download"
                        onClick={() =>
                          downloadS3File(
                            "https://equion-dev.s3.us-west-2.amazonaws.com/RoundExcelTemplate.xlsx"
                          )
                        }
                        className="font-medium text-blue-600 underline cursor-pointer"
                      >
                        here
                      </span>
                    </p>
                  </VStack>
                  {template.roundType === "EXCEL" && (
                    <HStack className="justify-end">
                      <PrimaryCTAButton
                        loading={excelUpload}
                        event-name="upload proforma excel"
                        onClick={async () => {
                          setExcelUpload(true);
                          if (template.roundType === "EXCEL" && aoaFile) {
                            const base64 = await convertToBase64(aoaFile);
                            roundExcelUpload(
                              { file: base64 },
                              {
                                onSuccess: async (data) => {
                                  await getInstrumentAndShareHolder().then(
                                    (data1) => {
                                      shareholderAndInstrumentstore.setInstrumentClass(
                                        data1?.data?.instrumentClasses || []
                                      );
                                      shareholderAndInstrumentstore.setShareholders(
                                        data1?.data?.shareholders || []
                                      );
                                      shareholderAndInstrumentstore.setInstrumentClassSubTypes(
                                        data1?.data?.instrumentClassSubTypes ||
                                          {}
                                      );

                                      const primaryT =
                                        data.newRoundInput?.primaryTransactions?.map(
                                          (ele: any) => ({
                                            ...ele,
                                            uuid: ele.id,
                                          })
                                        );
                                      const secondaryT =
                                        data.newRoundInput?.secondaryTransactions?.map(
                                          (ele: any) => ({
                                            ...ele,
                                            uuid: ele.id,
                                          })
                                        );

                                      setTemplate({
                                        ...data,
                                        fileType: template.fileType,
                                        newRoundDetails: {
                                          ...data.newRoundDetails,

                                          autoDiluteEsop:
                                            data.newRoundDetails
                                              ?.autoDiluteEsop ?? false,
                                          pricedRound:
                                            data.newRoundDetails?.pricedRound ??
                                            false,
                                          postRoundEsop:
                                            data.newRoundDetails
                                              ?.postRoundEsop ?? 0,
                                        },
                                        newRoundInput: {
                                          primaryTransactions: primaryT,
                                          secondaryTransactions: secondaryT,
                                          esopPostRoundPercentage:
                                            data.newRoundInput
                                              ?.esopPostRoundPercentage ?? 0,
                                        },
                                      });
                                      // setTemplate(
                                      //   template
                                      //   //   {
                                      //   //   ...template,
                                      //   //   stepNo: 1,
                                      //   //   roundType: "EXCEL",
                                      //   //   fileType: template.fileType,
                                      //   //   transactionType: "round",

                                      //   // }
                                      // );
                                      toast("Excel Uploaded Successfully", {
                                        type: "success",
                                        autoClose: 2000,
                                      });
                                      setExcelUpload(false);
                                    }
                                  );
                                },
                                onError: (err: any) => {
                                  setExcelUpload(false);
                                  toast(err.response.data.errorMessage, {
                                    type: "error",
                                    autoClose: 2000,
                                  });
                                },
                              }
                            );
                          } else {
                            setExcelUpload(false);
                          }
                        }}
                        className="h-8 min-w-max"
                      >
                        Upload Excel
                      </PrimaryCTAButton>
                    </HStack>
                  )}
                </VStack>
              </HStack>
            )}

            {((template.roundType === "ROUNDMODELING" &&
              selectedModelingId &&
              modelDetails) ||
              template.roundType === "MANUAL" ||
              (template.roundType === "EXCEL" && excelData)) && (
              <VStack className="gap-8 py-8">
                <HStack className="grid justify-start grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-4">
                  <VStack className="">
                    <Label className="w-48 text-sm font-medium whitespace-nowrap">
                      Round Name
                    </Label>
                    <VStack>
                      <Input
                        type="text"
                        placeholder="Enter Round Name"
                        className="w-60"
                        {...formik.getFieldProps("roundName")}
                      />
                      {formik.touched?.roundName &&
                        formik.errors?.roundName && (
                          <Error text={formik.errors?.roundName} />
                        )}
                    </VStack>
                  </VStack>
                  <VStack className="">
                    <Label className="w-48 text-sm font-medium whitespace-nowrap">
                      Round Identifier
                    </Label>
                    <VStack>
                      <Input
                        type="text"
                        placeholder="Enter Round Id"
                        className="w-60"
                        {...formik.getFieldProps("roundId")}
                      />
                      {formik.touched?.roundId && formik.errors?.roundId && (
                        <Error text={formik.errors?.roundId} />
                      )}
                    </VStack>
                  </VStack>
                  <VStack className="">
                    <Label className="w-48 text-sm font-medium whitespace-nowrap">
                      Round Currency
                    </Label>
                    <VStack>
                      <Select
                        className="w-60"
                        options={currencies}
                        {...formik.getFieldProps("roundCurrency")}
                      />
                      {formik.touched?.roundCurrency &&
                        formik.errors?.roundCurrency && (
                          <Error text={formik.errors?.roundCurrency} />
                        )}
                    </VStack>
                  </VStack>
                  {companyCurrency !== formik.values.roundCurrency &&
                    formik.values.roundCurrency && (
                      <VStack className="">
                        <Label className="w-48 text-sm font-medium whitespace-nowrap">
                          Exchange Rate
                        </Label>
                        <VStack>
                          <Input
                            placeholder="Enter Exchange Rate"
                            className="w-60"
                            type="text"
                            onMouseEnter={() => {
                              setOnHoverExchangeRate(true);
                            }}
                            onMouseLeave={() => {
                              setOnHoverExchangeRate(false);
                            }}
                            onChange={(e: any) => {
                              const filteredValue = filterOnlyNumbers(
                                e.target.value
                              );
                              setFormatExchangeRate(filteredValue);
                              const values = convertToNumber(filteredValue);

                              formik.setFieldValue("exchangeRate", values);
                              formik.handleChange("exchangeRate");
                            }}
                            value={numberWithCommas(
                              formatExchangeRate,
                              (currencyTypes as any)[
                                (formik.values.roundCurrency ?? currencyType)
                                  .toString()
                                  .split(" - ")[0] as string
                              ]
                            )}
                          />
                          {onHoverExchangeRate && (
                            <ShowNumberInWords
                              value={formik.values.exchangeRate!}
                              width={38}
                              currency={currencyType}
                            />
                          )}

                          {formik.touched?.exchangeRate &&
                            formik.errors?.exchangeRate && (
                              <Error text={formik.errors?.exchangeRate} />
                            )}
                        </VStack>
                      </VStack>
                    )}
                </HStack>
                <HStack className="grid justify-start grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-4">
                  <VStack className="justify-self-start">
                    <div className="flex flex-row-reverse items-center gap-4 py-5 text-sm font-medium text-gray-600 justify-items-start ">
                      <SwitchButton
                        className="items-center m-1 text-sm font-medium"
                        value={formik.values.pricedRound}
                        label="Priced Round"
                        onClick={() => {
                          formik.setFieldValue(
                            "pricedRound",
                            !formik.values.pricedRound
                          );
                        }}
                      />
                    </div>
                  </VStack>

                  <VStack className="">
                    <Label className="w-48 text-sm font-medium whitespace-nowrap">
                      Round Size(
                      {getRoundCurrencySymbol(
                        formik.values.roundCurrency || companyCurrency
                      ) || companyCurrencySymbol}
                      )
                    </Label>
                    <VStack>
                      <Input
                        placeholder="Enter Round Size"
                        className="w-60"
                        type="text"
                        onMouseEnter={() => {
                          setOnHoverRoundSize(true);
                        }}
                        onMouseLeave={() => {
                          setOnHoverRoundSize(false);
                        }}
                        onChange={(e: any) => {
                          const filteredValue = filterOnlyNumbers(
                            e.target.value
                          );
                          setFormatRoundSize(filteredValue);
                          const values = convertToNumber(filteredValue);
                          formik.setFieldValue("roundSize", values);
                          formik.handleChange("roundSize");
                        }}
                        value={numberWithCommas(
                          formatRoundSize,
                          (currencyTypes as any)[
                            (formik.values.roundCurrency ?? currencyType)
                              .toString()
                              .split(" - ")[0] as string
                          ]
                        )}
                      />
                      {onHoverRoundSize && (
                        <ShowNumberInWords
                          value={formik.values.roundSize!}
                          width={38}
                          currency={currencyType}
                        />
                      )}

                      {formik.touched?.roundSize &&
                        formik.errors?.roundSize && (
                          <Error text={formik.errors?.roundSize} />
                        )}
                    </VStack>
                  </VStack>

                  <VStack className="">
                    <Label className="w-48 text-sm font-medium whitespace-nowrap">
                      {formik.values.pricedRound
                        ? "Pre-Money Valuation"
                        : "Base Valuation"}{" "}
                      (
                      {getRoundCurrencySymbol(
                        formik.values.roundCurrency || companyCurrency
                      ) || companyCurrencySymbol}
                      )
                    </Label>
                    <VStack>
                      <Input
                        placeholder="Enter valuation"
                        className="w-60"
                        type="text"
                        onMouseEnter={() => {
                          setOnHoverValuation(true);
                        }}
                        onMouseLeave={() => {
                          setOnHoverValuation(false);
                        }}
                        onChange={(e: any) => {
                          const filteredValue = filterOnlyNumbers(
                            e.target.value
                          );
                          setFormatValuation(filteredValue);
                          const values = convertToNumber(filteredValue);

                          formik.setFieldValue("valuation", values);
                          formik.handleChange("valuation");
                        }}
                        value={numberWithCommas(
                          formatValuation,
                          (currencyTypes as any)[
                            (formik.values.roundCurrency ?? currencyType)
                              .toString()
                              .split(" - ")[0] as string
                          ]
                        )}
                      />
                      {onHoverValuation && (
                        <ShowNumberInWords
                          value={formik.values.valuation!}
                          width={38}
                          currency={currencyType}
                        />
                      )}

                      {formik.touched?.valuation &&
                        formik.errors?.valuation && (
                          <Error text={formik.errors?.valuation} />
                        )}
                    </VStack>
                  </VStack>
                  <VStack className="">
                    <Label className="w-48 text-sm font-medium whitespace-nowrap">
                      Close Date
                    </Label>
                    <VStack>
                      <Input
                        type="date"
                        className="w-60"
                        placeholder="12-12-2022"
                        {...formik.getFieldProps("closeDate")}
                      />
                      {formik.touched?.closeDate &&
                        formik.errors?.closeDate && (
                          <Error text={formik.errors?.closeDate} />
                        )}
                    </VStack>
                  </VStack>
                </HStack>
                <HStack className="grid justify-start grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-4">
                  {formik.values.pricedRound && (
                    <VStack className="">
                      <Label className="w-48 text-sm font-medium whitespace-nowrap">
                        Allotment Type
                      </Label>
                      <VStack>
                        <Select
                          options={["Conversion", "Allotment"]}
                          placeholder="Select Allotment Type"
                          className="w-60"
                          {...formik.getFieldProps("allotmentType")}
                        />
                        {formik.touched?.allotmentType &&
                          formik.errors?.allotmentType && (
                            <Error text={formik.errors?.allotmentType} />
                          )}
                      </VStack>
                    </VStack>
                  )}
                  {formik.values.pricedRound && (
                    <VStack className="">
                      <Label className="w-48 text-sm font-medium whitespace-nowrap">
                        Issuance Type
                      </Label>
                      <VStack>
                        <Select
                          options={[
                            "Rights",
                            "Private Placement",
                            "Preferential Allotment",
                          ]}
                          placeholder="Select Issuance Type"
                          className="w-60"
                          {...formik.getFieldProps("issuanceType")}
                        />
                        {formik.touched?.issuanceType &&
                          formik.errors?.issuanceType && (
                            <Error text={formik.errors?.issuanceType} />
                          )}
                      </VStack>
                    </VStack>
                  )}
                  {formik.values.pricedRound && (
                    <div className="flex flex-row-reverse items-center justify-end gap-4 text-sm font-medium text-gray-600 ">
                      <SwitchButton
                        className="items-center m-1 text-sm font-medium whitespace-nowrap"
                        value={formik.values.autoDiluteEsop}
                        label="Auto Dilute ESOP"
                        onClick={() => {
                          formik.setFieldValue(
                            "autoDiluteEsop",
                            !formik.values.autoDiluteEsop
                          );
                        }}
                      />
                    </div>
                  )}
                  {!formik.values.autoDiluteEsop && formik.values.pricedRound && (
                    <VStack className="flex-1">
                      <Label className="w-48 text-sm font-medium whitespace-nowrap">
                        Post Round ESOP(%)
                      </Label>
                      <VStack>
                        <Input
                          type="number"
                          placeholder="Enter Post Round ESOP(%)"
                          className="w-60"
                          {...formik.getFieldProps("postRoundEsop")}
                        />
                        {formik.touched?.postRoundEsop &&
                          formik.errors?.postRoundEsop && (
                            <Error text={formik.errors?.postRoundEsop} />
                          )}
                      </VStack>
                    </VStack>
                  )}
                </HStack>
                <HStack className="grid justify-start grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-4">
                  {formik.values.pricedRound && (
                    <VStack className="">
                      <Label className="w-48 text-sm font-medium whitespace-nowrap">
                        Payment Type
                      </Label>
                      <VStack>
                        <Select
                          options={["Cash", "Non-Cash"]}
                          placeholder="Select Payment Type"
                          className="w-60"
                          {...formik.getFieldProps("paymentType")}
                        />
                        {formik.touched?.paymentType &&
                          formik.errors?.paymentType && (
                            <Error text={formik.errors?.paymentType} />
                          )}
                      </VStack>
                    </VStack>
                  )}
                </HStack>
              </VStack>
            )}

            <HStack className="justify-end gap-4 mt-8">
              {((template.roundType === "ROUNDMODELING" &&
                selectedModelingId) ||
                template.roundType === "MANUAL" ||
                (template.roundType === "EXCEL" && excelData)) && (
                <HStack className="justify-end h-8 ">
                  <PrimaryCTAButton
                    loading={loading}
                    event-name="Save & Continue Round Details"
                    type="submit"
                    onClick={() => {
                      formik.handleSubmit();
                    }}
                    className="h-8 min-w-max "
                  >
                    Save & Continue
                  </PrimaryCTAButton>
                </HStack>
              )}
            </HStack>
          </VStack>
        )}
        {createRound && (
          <div className="mt-8">
            {/* {nonPricedRoundDetail &&
            template.newRoundDetails?.pricedRound &&
            conversionstore.conversionDetails?.length !==
              nonPricedRoundDetail.length ? (
              <VStack className="p-6 bg-white border rounded-lg border-borderColor shadow-box">
                <EmptyTableIllustration
                  text={
                    "Need to convert the round before proceeding to a new round"
                  }
                />
                <PrimaryCTAButton
                  onClick={handleConvertRound}
                  className="mx-auto mt-2 w-fit"
                >
                  Convert Last Round
                </PrimaryCTAButton>
              </VStack>
            ) : ( */}
            <AddTransactionRound
              roundData={formik.values}
              roundType={template.roundType!}
            />
            {/* )} */}
          </div>
        )}
        {createRound && (
          <HStack className="justify-between gap-4 mt-8">
            <SecondaryCTAButton
              type="reset"
              event-name="Discard Proforma Round"
              className="h-8 text-red-500 shadow-box"
              onClick={() => setCreateRound(false)}
            >
              Back
            </SecondaryCTAButton>
            <HStack className="h-8 gap-4">
              {createRound && (
                <PrimaryCTAButton
                  event-name="Save Proforma Round"
                  buttonSize={ButtonSize.SMALL}
                  onClick={() => {
                    handleSaveProforma();
                  }}
                >
                  Save Proforma
                </PrimaryCTAButton>
              )}
              <PrimaryCTAButton
                loading={createRoundClicked}
                event-name="Create Proforma Round"
                onClick={() => {
                  if (
                    primaryTransactionStore.primary.length > 0 &&
                    formik.errors
                  ) {
                    setMinimum(false);
                    handleSubmitCall();
                  } else {
                    setMinimum(true);
                  }
                }}
                className="h-8 min-w-max"
              >
                Create Round
              </PrimaryCTAButton>
            </HStack>
          </HStack>
        )}

        {minimum && (
          <p className="text-xs font-medium text-right text-red-500 ">
            * Required minimum 1 primary transaction
          </p>
        )}
      </Box>
    </VStack>
  );
}

export default QuickAddRound;
