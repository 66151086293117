/* eslint-disable max-len */
import { useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import { Icon } from "@iconify/react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { HStack, VStack, Error } from "../../components/utils";
import {
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../quickRound/CTAButtonComponents";
import { Input, Label } from "../../components/shared/InputField";
import {
  OngoingSecurityAllotmentDetails,
  NewInvestorState,
  RoundCreationModel,
} from "./RoundCreationModel";
import { useAuthStore } from "../../store";
import { useRMCreationStore } from "../../store/roundCreationStore";
import { Select } from "../../components/shared/Select";
import { shareholderTypeLabelValue } from "../../constants/ShareholderConstants";
import { getCurrencySymbol } from "../../utils/currencyFormatter";
import { SwitchDropDownModel } from "../rounds/RoundsAllTransactions";
import { ShareholderDropDown } from "./AddNewInvestors";
import DropDownInput from "../../components/shared/DropDownInput";
import Tooltip from "../../components/shared/Tooltip";

type AddNewInvestorModel = {
  id: string;
  shareholderName: string;
  shareholderId: string;
  securityType: string;
  securityId: string;
  noOfShares?: number;
  postHolding?: number;
  investmentAmount?: number;
  state: string;
};

const NewSecurityAllotment = ({
  data,
  actionMode = "New",
  onBack,
  onNext,
}: {
  data?: OngoingSecurityAllotmentDetails;
  actionMode?: string;
  onBack: () => void;
  onNext: (data: RoundCreationModel) => void;
}) => {
  const [mode, setMode] = useState(actionMode);
  const roundCreationState = useRMCreationStore();
  const companyId = useAuthStore().companyId || "";
  const [loading, setLoading] = useState(false);
  const [shareholderName, setShareholderName] = useState("");
  const [shareholderType, setShareholderType] = useState("");
  const [holding, setHolding] = useState<string>(
    data?.postholdingPercentage?.toString() || ""
  );
  const [amount, setAmount] = useState<string>(
    data?.totalAmountToInvest.toString() || ""
  );
  const [selectedShareholder, setSelectedShareholder] =
    useState<ShareholderDropDown>({
      name: data?.name || "",
      type: "",
      id: data?.id || "",
    });
  const [isAmtLocked, setIsAmtLocked] = useState(false);
  const [isLoaded, setLoaded] = useState(false);
  const currencySymbol = getCurrencySymbol();

  const handleSubmit = async (values: AddNewInvestorModel) => {
    setLoading(true);
    setLoading(false);
    const ongoingSecurityAllotmentDetails: OngoingSecurityAllotmentDetails = {
      id: values.id ?? "",
      shareholderId: values.shareholderId ?? "",
      name: values.shareholderName,
      totalAmountToInvest: values.investmentAmount || 0,
      postholdingPercentage: values.postHolding || 0,
      shares: values.noOfShares || 0,
      instrumentName: values.securityType,
      instrumentId: values.securityId,
      fdbShares: 0,
      unLockedInputFeild: {
        hasToCalculateAmt: isAmtLocked,
        value: isAmtLocked ? Number(holding) || 0 : Number(amount) || 0,
      },
      currencyType: "",
      state: NewInvestorState.ToBeSaved,
    };
    onNext({
      ...roundCreationState.roundCreation,
      companyId,
      tab5: {
        investorDetails:
          roundCreationState.roundCreation.tab5?.investorDetails ?? [],
        newInstruments:
          roundCreationState.roundCreation.tab5?.newInstruments ?? [],
        listOfInstruments:
          roundCreationState.roundCreation.tab5?.listOfInstruments ?? [],
        nonPricedInstruments:
          roundCreationState.roundCreation.tab5?.nonPricedInstruments ?? [],
        listOfShareHolders:
          roundCreationState.roundCreation.tab5?.listOfShareHolders ?? [],
        ongoingSecurityAllotmentDetails,
      },
    });
    onBack();
  };

  const investorInitialValues = {
    id: data?.id || "",
    shareholderId: data?.shareholderId || "",
    shareholderName: data?.name || "",
    securityType: data?.instrumentName || "",
    securityId: data?.instrumentId || "",
    postHolding: data?.postholdingPercentage,
    noOfShares: data?.shares,
    investmentAmount: data?.totalAmountToInvest,
    state: NewInvestorState.Update,
  };

  const investorValidationSchema = Yup.object({
    shareholderId: Yup.string().label("Shareholder Id"),
    shareholderName: Yup.string().required().label("Shareholder Name"),
    postHolding: Yup.number().moreThan(0).max(100).label("Post Holding"),
    noOfShares: Yup.number().moreThan(0).label("No. of shares"),
    investmentAmount: Yup.number().moreThan(0).label("Investment Amount"),
    state: Yup.string().label("State"),
  });

  const [shareholders, setShareholder] = useState<ShareholderDropDown[]>(
    roundCreationState?.roundCreation?.tab5?.listOfShareHolders || []
  );

  const formik = useFormik({
    initialValues: investorInitialValues,
    enableReinitialize: actionMode.toLowerCase() === "edit",
    onSubmit: (values: AddNewInvestorModel) => {
      handleSubmit(values);
    },
    validationSchema: investorValidationSchema,
  });

  useEffect(() => {
    const values = formik.values;
    const ongoingSecurityAllotmentDetails: OngoingSecurityAllotmentDetails = {
      id: values.id || "",
      shareholderId: values.shareholderId,
      instrumentId: values.securityId || "",
      shares: values.noOfShares || 0,
      fdbShares: undefined,
      name: values.shareholderName,
      totalAmountToInvest: values.investmentAmount || 0,
      instrumentName: values.securityType,
      postholdingPercentage: values.postHolding || 0,
      unLockedInputFeild: {
        hasToCalculateAmt: isAmtLocked,
        value: isAmtLocked ? Number(holding) || 0 : Number(amount) || 0,
      },
      currencyType: "",
      state: NewInvestorState.Update,
    };
    onNext({
      ...roundCreationState.roundCreation,
      companyId,
      tab5: {
        investorDetails:
          roundCreationState.roundCreation.tab5?.investorDetails ?? [],
        newInstruments:
          roundCreationState.roundCreation.tab5?.newInstruments ?? [],
        listOfInstruments:
          roundCreationState.roundCreation.tab5?.listOfInstruments ?? [],
        nonPricedInstruments:
          roundCreationState.roundCreation.tab5?.nonPricedInstruments ?? [],
        listOfShareHolders:
          roundCreationState.roundCreation.tab5?.listOfShareHolders ?? [],
        ongoingSecurityAllotmentDetails,
      },
    });
  }, [
    formik.values.noOfShares,
    formik.values.investmentAmount,
    amount,
    holding,
  ]);

  useEffect(() => {
    const data = roundCreationState.roundCreation;
    if (data.errors && data.errors.length > 0) {
      for (const error of data.errors) {
        toast(error, {
          type: "error",
          autoClose: 2000,
        });
      }
      roundCreationState.setRoundCreation({
        ...data,
        errors: [],
      });
    }
  }, []);

  const resetNewInverstor = () => {
    const data = roundCreationState.roundCreation;
    const postData = {
      ...data,
      tab5: {
        investorDetails:
          roundCreationState.roundCreation.tab5?.investorDetails ?? [],
        newInstruments:
          roundCreationState.roundCreation.tab5?.newInstruments ?? [],
        listOfInstruments:
          roundCreationState.roundCreation.tab5?.listOfInstruments ?? [],
        nonPricedInstruments:
          roundCreationState.roundCreation.tab5?.nonPricedInstruments ?? [],
        listOfShareHolders:
          roundCreationState.roundCreation.tab5?.listOfShareHolders ?? [],
        ongoingSecurityAllotmentDetails: undefined,
      },
    };
    onNext(postData);
  };

  const updateLocalStates = () => {
    setIsAmtLocked((prev) => !prev);
    setAmount(
      (
        roundCreationState.roundCreation.tab5?.ongoingSecurityAllotmentDetails
          ?.totalAmountToInvest || 0
      ).toFixed(2)
    );
    setHolding(
      (
        roundCreationState.roundCreation.tab5?.ongoingSecurityAllotmentDetails
          ?.shares || 0
      ).toFixed(2)
    );
  };

  const instrumentName: string[] =
    roundCreationState.roundCreation.tab5?.listOfInstruments?.map(
      (element) => element.name ?? ""
    ) || [];

  return (
    <div className="w-full">
      <Box className="px-4 text-lg font-medium border-b pt-4 pb-2">
        <h6 className="flex justify-between">
          <VStack>
            <p className="font-medium text-lg">
              {actionMode.toLowerCase() === "edit"
                ? "Add New Transaction"
                : "Edit New Transaction"}
            </p>
          </VStack>
          <XMarkIcon
            className="h-6 w-6 cursor-pointer"
            onClick={() => {
              resetNewInverstor();
              onBack();
            }}
          />
        </h6>
      </Box>
      <VStack className="w-full py-7 gap-9">
        <VStack className="w-full px-10">
          <HStack className="gap-4">
            <VStack className="flex-1">
              <HStack className="items-center">
                <Label className="font-medium text-sm whitespace-nowrap mt-3 mb-1.5 mr-4">
                  Shareholder Name
                </Label>
                {(roundCreationState.roundCreation.headers?.leftToRaise ?? 0) <
                (roundCreationState.roundCreation.headers?.pps ?? 0) ? (
                  <Tooltip
                    _position="right-1/2"
                    text={"Shareholder name cant be edited"}
                  >
                    <Icon
                      onClick={() => {}}
                      icon="ic:outline-lock"
                      width={20}
                      className="text-gray-400"
                      height={20}
                    />
                  </Tooltip>
                ) : (
                  <div></div>
                )}
              </HStack>

              <Box className="w-72 mb-12">
                <DropDownInput
                  data={shareholders.filter((ele) => ele.name !== "Esop pool")}
                  className={
                    "bg-white w-72 rounded-md border-inputBorder border"
                  }
                  placeholder="Search Investor..."
                  getSelectedValue={(data: any) => {
                    formik.setFieldValue("shareholderName", data.name);
                    formik.setFieldValue("shareholderId", data.id);
                    setSelectedShareholder(data);
                  }}
                  selectedOption={
                    selectedShareholder as unknown as ShareholderDropDown
                  }
                  field={"name" as keyof SwitchDropDownModel}
                  disabled={mode === "Edit"}
                />
              </Box>
              {formik.touched.shareholderName &&
                formik.errors.shareholderName && (
                  <Error text={formik.errors.shareholderName} />
                )}
            </VStack>
            {/* <VStack className="flex-1 mb-12">
              <Label className="font-medium text-sm whitespace-nowrap mr-4">
                Shareholder Name
              </Label>
              // {/* <Input
              //   type="text"
              //   placeholder="Enter Name"
              //   className="w-72"
              //   value={formik.values.shareholderName}
              //   disabled={mode.toLowerCase() === "edit"}
              //   onChange={(e) => {
              //     formik.setFieldValue("shareholderName", e.target.value);
              //     setShareholderName(e.target.value);
              //   }}
              // /> */}
            {/* <DropDownInput
                data={shareholders}
                className={"bg-slate-light w-72 rounded-md border-none"}
                placeholder="Search Investor..."
                getSelectedValue={(data: any) => {
                  formik.setFieldValue("shareholderName", data.name);
                  formik.setFieldValue("shareholderId", data.id);
                  setSelectedShareholder(data);
                  // updatelocalStates();
                }}
                selectedOption={
                  selectedShareholder as unknown as ShareholderDropDown
                }
                field={"name" as keyof SwitchDropDownModel}
                disabled={mode === "Edit"}
              />
              {formik.touched.shareholderName &&
                formik.errors.shareholderName && (
                  <Error text={formik.errors.shareholderName} />
                )} */}
            {/* </VStack> */}
            <VStack className="flex-1">
              <Label className="font-medium text-sm whitespace-nowrap mt-3 mr-4">
                Security type
              </Label>
              <Box className="w-72 mb-12">
                <Select
                  // valueGetter={(o) => o.label}
                  // textGetter={(o) => o.value}
                  options={instrumentName}
                  value={formik.values.securityType}
                  onChange={(e) => {
                    const instrument =
                      roundCreationState.roundCreation.tab5?.listOfInstruments?.find(
                        (element) => element.name === e.target.value
                      );
                    formik.setFieldValue("securityType", instrument?.name);
                    formik.setFieldValue("securityId", instrument?.id);
                  }}
                />
              </Box>
              {/* <Select
                options={shareholderTypeLabelValue}
                valueGetter={(o) => o.label}
                textGetter={(o) => o.value}
                placeholder="Select Shareholder Type"
                className="w-72"
                disabled={mode.toLowerCase() === "edit"}
                value={formik.values.shareholderType}
                onChange={(e) => {
                  formik.setFieldValue("shareholderType", e.target.value);
                  setShareholderType(e.target.value);
                }}
              /> */}
              {formik.touched.securityType && formik.errors.securityType && (
                <Error text={formik.errors.securityType} />
              )}
            </VStack>
          </HStack>
          <HStack className="gap-4">
            <VStack className="flex-1">
              <HStack>
                <Label className="font-medium text-sm whitespace-nowrap mr-4">
                  No. of Securities
                </Label>
                {isAmtLocked ? (
                  <Icon
                    onClick={updateLocalStates}
                    icon="material-symbols:lock-open-outline-rounded"
                    width={20}
                    className="text-[#E75935]"
                    height={20}
                  />
                ) : (
                  <Icon
                    onClick={updateLocalStates}
                    icon="ic:outline-lock"
                    width={20}
                    className="text-gray-400"
                    height={20}
                  />
                )}
              </HStack>

              <Input
                type="number"
                placeholder={!isAmtLocked ? "" : "Enter No.  of Share"}
                readOnly={!isAmtLocked}
                className="w-72"
                value={
                  !isAmtLocked
                    ? roundCreationState.roundCreation.tab5
                        ?.ongoingSecurityAllotmentDetails?.shares
                    : holding
                }
                onChange={(e) => {
                  formik.setFieldValue("shares", Number(e.target.value));
                  setHolding(e.target.value);
                }}
              />
              {!isAmtLocked && (
                <p className="text-xxs text-[#A6A6A6] font-medium pt-1">
                  ~final holding % :
                  {roundCreationState.roundCreation.tab5?.ongoingSecurityAllotmentDetails?.postholdingPercentage?.toFixed(
                    2
                  )}
                </p>
              )}
              {isAmtLocked && (
                <p className="text-xxs text-[#A6A6A6] font-medium pt-1">
                  ~adjusted securities :
                  {roundCreationState.roundCreation.tab5?.ongoingSecurityAllotmentDetails?.shares?.toFixed(
                    2
                  )}
                </p>
              )}

              {formik.touched.noOfShares && formik.errors.noOfShares && (
                <Error text={formik.errors.noOfShares} />
              )}
            </VStack>
            <VStack className="flex-1">
              <HStack>
                <Label className="font-medium text-sm whitespace-nowrap mr-4">
                  Investment Amount {` (${currencySymbol})`}
                </Label>
                {!isAmtLocked ? (
                  <Icon
                    onClick={updateLocalStates}
                    icon="material-symbols:lock-open-outline-rounded"
                    width={20}
                    className="text-[#E75935]"
                    height={20}
                  />
                ) : (
                  <Icon
                    onClick={updateLocalStates}
                    icon="ic:outline-lock"
                    width={20}
                    className="text-gray-400"
                    height={20}
                  />
                )}
              </HStack>

              <Input
                type="number"
                placeholder={isAmtLocked ? "" : "Enter Amount"}
                readOnly={isAmtLocked}
                className="w-72"
                value={
                  isAmtLocked
                    ? roundCreationState.roundCreation.tab5
                        ?.ongoingSecurityAllotmentDetails?.totalAmountToInvest
                    : amount
                }
                onChange={(e) => {
                  formik.setFieldValue("investmentAmount", e.target.value);
                  setAmount(e.target.value);
                }}
              />
              {formik.touched.investmentAmount &&
                formik.errors.investmentAmount && (
                  <Error text={formik.errors.investmentAmount} />
                )}
              {isAmtLocked && (
                <p className="text-xxs text-[#A6A6A6] font-medium pt-1">
                  ~final holding % :
                  {roundCreationState.roundCreation.tab5?.ongoingSecurityAllotmentDetails?.postholdingPercentage?.toFixed(
                    2
                  )}
                </p>
              )}
              {!isAmtLocked && (
                <p className="text-xxs text-[#A6A6A6] font-medium pt-1">
                  ~calculated based on entered amount and share price:
                  {roundCreationState.roundCreation.tab5?.ongoingSecurityAllotmentDetails?.totalAmountToInvest?.toFixed(
                    2
                  )}
                </p>
              )}
            </VStack>
          </HStack>
        </VStack>
        <HStack className="justify-between mt-10 px-10">
          <SecondaryCTAButton
            onClick={() => {
              resetNewInverstor();
              onBack();
            }}
          >
            Cancel
          </SecondaryCTAButton>
          <PrimaryCTAButton
            className="p-1"
            type="submit"
            onClick={() => {
              formik.validateForm();
              if (
                !formik.errors.investmentAmount &&
                !formik.errors.noOfShares &&
                !formik.errors.shareholderName &&
                !formik.errors.securityType
              ) {
                formik.handleSubmit();
              }
            }}
          >
            {!loading && <span>Save</span>}

            {loading && (
              <HStack>
                <p className="indicator-progress" style={{ display: "block" }}>
                  Please wait...
                </p>
                <Icon
                  className="animate-spin"
                  icon="lucide:loader-2"
                  width={18}
                />
              </HStack>
            )}
          </PrimaryCTAButton>
        </HStack>
      </VStack>
    </div>
  );
};

export default NewSecurityAllotment;
