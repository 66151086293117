import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router";
import useIsMobile from "../../utils/detectDevice";
import { Box } from "../../components/utils";
import Footer from "../../components/shared/Footer";
import ShareNavbar from "./ShareNavbar";
import { useShareAuthStore } from "../../store/useShareAuthStore";
import { useGetSharedCompanyDetail } from "../../queries/sharedResouces";

function ShareMainLayout() {
  const { isShareAuthenticated, shareAccessToken } = useShareAuthStore();
  const navigate = useNavigate();
  const [dialogOpen, setDialogOpen] = useState(false);
  const handleDialogOpen = (value: boolean) => {
    setDialogOpen(!dialogOpen);
  };
  const { id } = useParams() as {
    id: string;
  };
  const { data: companyDetail } = useGetSharedCompanyDetail();
  const location = useLocation();
  const { isMobile } = useIsMobile(1024);
  useEffect(() => {
    const scroller = document.querySelector("#outlet");
    scroller?.scrollTo(0, 0);
  }, [location]);
  useEffect(() => {
    if (!isShareAuthenticated) {
      navigate(`/share/login/${id}`);
    }
  }, [isShareAuthenticated]);
  return (
    <>
      {isMobile ? (
        <div className="flex">
          <div className="flex-1 h-screen overflow-y-auto" id="outlet">
            <div className="relative w-full min-h-screen">
              <div className={`sticky top-0 z-20`}>
                <ShareNavbar
                  companyName={companyDetail?.name ?? ""}
                  cin={companyDetail?.cinNumber ?? ""}
                />
              </div>
              <Box className="max-w-screen-xl py-2 px-4 pb-32 mx-auto">
                <Outlet />
              </Box>
              <div></div>
              <div className="absolute bottom-0 w-full mt-6 ">
                <Footer />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex">
          <div className="flex-1 h-screen overflow-y-auto" id="outlet">
            <div className="relative w-full min-h-screen">
              <div className={`sticky top-0 z-20`}>
                <ShareNavbar
                  companyName={companyDetail?.name ?? ""}
                  cin={companyDetail?.cinNumber ?? ""}
                />
              </div>
              <Box className="max-w-screen-xl py-4 px-8 pb-32 mx-auto">
                <Outlet />
              </Box>
              <div></div>
              <div className="absolute bottom-0 w-full mt-6 ">
                <Footer />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default ShareMainLayout;
