import { Icon } from "@iconify/react";
import { HStack } from "../../components/utils";
import {
  FormatNumberSpan,
  getCurrencySymbolRoboto,
} from "../../utils/currencyRoboto";
import { getCurrencyType } from "../../utils/currencyFormatter";
import { DashboardCardDetails } from "../../types/quickRound";
import { getAllHeader } from "../../utils/dashboardUtils";

export default function DashboardCard({
  dashboardCardDetails,
}: {
  dashboardCardDetails: DashboardCardDetails;
}) {
  const symbol = getCurrencySymbolRoboto();
  const currency = getCurrencyType();
  const cardDetails = getAllHeader(dashboardCardDetails);
  return (
    <div className="pb-8 mx-auto max-w-7xl">
      <dl className="grid grid-cols-1 gap-8 mt-5 sm:grid-cols-2 lg:grid-cols-4">
        {cardDetails.map((cardDetail) => (
          <div
            key={cardDetail.name}
            className="relative px-4 py-2 bg-white rounded-lg  border border-borderColor shadow-box"
          >
            <dt>
              <p className=" truncate text-base font-medium text-[#464E5F]">
                {cardDetail.name}
              </p>
            </dt>
            <dd className="flex flex-col items-baseline pt-2 pb-1 text-3xl font-medium text-[#464E5F]">
              <HStack>
                <span className="pr-1 text-xl font-normal">
                  {cardDetail.name === "Stakeholders" ? "" : symbol}
                </span>
                <FormatNumberSpan value={cardDetail.value} format={currency} />
              </HStack>
              <HStack>
                <p>
                  {Number(cardDetail.value.toPrecision(2)) ===
                  Number(cardDetail.preValue.toPrecision(2)) ? (
                    <Icon
                      icon="octicon:dash-16"
                      width={18}
                      rotate={
                        Number(cardDetail.value.toPrecision(2)) >=
                        Number(cardDetail.preValue.toPrecision(2))
                          ? 0
                          : 90
                      }
                      color="#a79b30"
                      height={24}
                    />
                  ) : (
                    <Icon
                      icon="ph:trend-up-bold"
                      width={18}
                      rotate={
                        Number(cardDetail.value.toPrecision(2)) >=
                        Number(cardDetail.preValue.toPrecision(2))
                          ? 0
                          : 90
                      }
                      color={
                        Number(cardDetail.value.toPrecision(2)) >=
                        Number(cardDetail.preValue.toPrecision(2))
                          ? "#078B04"
                          : "#D10000"
                      }
                      height={24}
                    />
                  )}
                </p>
                <div
                  className={`pl-1 text-base font-semibold  ${
                    Number(cardDetail.value.toPrecision(2)) ===
                    Number(cardDetail.preValue.toPrecision(2))
                      ? "text-[#a79b30]"
                      : Number(cardDetail.value.toPrecision(2)) >=
                        Number(cardDetail.preValue.toPrecision(2))
                      ? "text-green-700"
                      : "text-red-700"
                  }`}
                >
                  <FormatNumberSpan
                    value={cardDetail.preValue}
                    format={currency}
                  />
                </div>
              </HStack>
            </dd>
          </div>
        ))}
      </dl>
    </div>
  );
}
