import { useFormikContext } from "formik";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { Select } from "../../components/shared/Select";
import { HStack, VStack, Error } from "../../components/utils";
import { Input, Label } from "../../components/shared/InputField";
import { EditSecurityModel } from "../../types/SecurityModel";
import { classNames } from "../../utils/string";

const AddInterestDetails = ({
  keepOpen,
  viewOnly,
}: {
  keepOpen: boolean;
  viewOnly: boolean;
}) => {
  const formikk = useFormikContext<EditSecurityModel>();
  const { errors, touched, getFieldProps } = formikk;
  return (
    <Disclosure as="div" key="Interests" className="" defaultOpen={keepOpen}>
      {({ open }) => (
        <>
          <dt
            className={`text-lg border ${
              open ? "border-b-0 rounded-t-md" : "rounded-md"
            } p-4`}
          >
            <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-400">
              <span className="font-medium text-slate-900 text-base">
                Interests
              </span>
              <span className="ml-6 flex h-7 items-center">
                <ChevronDownIcon
                  className={classNames(
                    open ? "-rotate-180" : "rotate-0",
                    "h-6 w-6 transform text-slate-500"
                  )}
                  aria-hidden="true"
                />
              </span>
            </Disclosure.Button>
          </dt>
          <Disclosure.Panel as="dd" className="border  p-4 rounded-b-md">
            <VStack className="gap-8">
              <HStack className="gap-4 md:flex-row flex-col justify-between">
                <VStack className="flex-1 items-start">
                  <Label className="text-slate-900 text-sm font-medium whitespace-nowrap mr-8">
                    Interest Type
                  </Label>

                  <Select
                    options={["Simple", "Compound"]}
                    {...getFieldProps("interestType")}
                    disabled={viewOnly}
                  />
                  {touched?.interestType && errors?.interestType && (
                    <Error text={errors?.interestType} />
                  )}
                </VStack>

                <VStack className="flex-1 items-start">
                  <Label className="text-slate-900 text-sm font-medium whitespace-nowrap mr-8">
                    Interest Rate %
                  </Label>
                  <Input
                    type="number"
                    placeholder="Enter Interest Rate"
                    {...getFieldProps("interestRate")}
                    disabled={viewOnly}
                  />
                  {touched?.interestRate && errors?.interestRate && (
                    <Error text={errors?.interestRate} />
                  )}
                </VStack>
              </HStack>

              <HStack className="gap-4 md:flex-row flex-col justify-between">
                <VStack className="flex-1 items-start">
                  <Label className="text-slate-900 text-sm font-medium whitespace-nowrap mr-4">
                    Interest Period
                  </Label>

                  <Select
                    options={["Monthly", "Quarterly", "Half Yearly", "Annual"]}
                    {...getFieldProps("interestPeriod")}
                    disabled={viewOnly}
                  />
                  {touched?.interestPeriod && errors?.interestPeriod && (
                    <Error text={errors?.interestPeriod} />
                  )}
                </VStack>

                <VStack className="flex-1 items-start">
                  <Label className="text-slate-900 text-sm font-medium whitespace-nowrap mr-4">
                    Interest Applies on
                  </Label>

                  <Select
                    options={["Conversion", "Redemption", "Both"]}
                    {...getFieldProps("interestAppliesOn")}
                    disabled={viewOnly}
                  />
                  {touched?.interestAppliesOn && errors?.interestAppliesOn && (
                    <Error text={errors?.interestAppliesOn} />
                  )}
                </VStack>
              </HStack>
            </VStack>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default AddInterestDetails;
