import { useEffect } from "react";
import BCHeader from "../../shared/BCHeader";
import { useQuickBuybackTransactionStore } from "../../store/useQuickTransactionStore";
import { useGetOnGoingTransaction } from "../../queries/transactionRound";
import { useAuthStore } from "../../store";
import TransactionPreviewPage from "./TransactionPreviewPage";
import QuickAddBuyback from "./QuickAddBuyback";

function TransactionBuybackTemplate() {
  const { state: template, setState: setTemplate } =
    useQuickBuybackTransactionStore();
  const companyId = useAuthStore.getState().companyId || "";
  const { refetch, data: onGoingTransaction } =
    useGetOnGoingTransaction(companyId);
  useEffect(() => {
    refetch();
  }, []);
  useEffect(() => {
    if (onGoingTransaction) setTemplate(onGoingTransaction);
    else {
      setTemplate({ stepNo: 1, transactionType: "buyback", companyId });
    }
  }, [onGoingTransaction]);

  return (
    <div className="min-h-full">
      <BCHeader
        className="items-baseline mb-2"
        bcTitle="Transaction Dashboard"
        bcSubTitle="Add Buyback"
      />
      {template.stepNo !== 2 ? <QuickAddBuyback /> : null}
      {template.stepNo === 2 ? <TransactionPreviewPage /> : null}
    </div>
  );
}

export default TransactionBuybackTemplate;
