import { useMutation, useQuery } from "react-query";
import {
  getInvEvents,
  getInvAllTransactions,
  getInvBonusTransactions,
  getInvBuybackTransactions,
  getInvInstrumentAndShareHolder,
  getInvRoundsTransactions,
  getInvSecondaryTransactions,
  getInvSecurityOverviewDetails,
  getInvSplitTransactions,
  allGetInvShareHolderRight,
  getInvArticleDescription,
  putInvArticleDescription,
  getInvPortalIframeToken,
  getArticleDescription,
  putArticleDescription,
  getInvLiquidationPreferenceDetails,
  getInvEsopExerciseTransactions,
  getInvFinancialData,
  getInvSecuritySummary,
} from "../api/investorPortal";
import {
  randomAllTransactions,
  randomIndividualBonusTransactions,
  randomIndividualBuyBackTransactions,
  randomIndividualSecondaryTransactions,
  randomIndividualSplitTransactions,
  randomIndividualTransactions,
  randomLiquidationPreferenceOverview,
  randomSecurityOverview,
  shareholderInstrumentDummyData,
} from "./randomPlaceholderData";
import { randomDataFinancial } from "./financialPlaceholderData";
import { handleEventForTracking } from "../amplitudeAnalytics";

export function useInvGetInstrumentAndShareHolder(cId: string, token: string) {
  return useQuery({
    queryKey: ["getInvInstrumentAndShareHolder", cId, token],
    queryFn: getInvInstrumentAndShareHolder,
    placeholderData: shareholderInstrumentDummyData(),
  });
}

export function useInvGetAllEvents(cId: string, token: string) {
  return useQuery({
    queryKey: ["getInvEvents", cId, token],
    queryFn: getInvEvents,
  });
}

export function useAllGetInvShareHolderRight(cId: string, token: string) {
  return useQuery({
    queryKey: ["allGetInvShareHolderRight", cId, token],
    queryFn: allGetInvShareHolderRight,
  });
}

export function useInvSecurityOverviewDetails(cId: string, token: string) {
  return useQuery({
    queryKey: ["getInvSecurityOverviewDetails", cId, token],
    queryFn: getInvSecurityOverviewDetails,
    placeholderData: randomSecurityOverview(),
  });
}

export function useInvLiquidationPreference(cId: string, token: string) {
  return useQuery({
    queryKey: ["getInvLiquidationPreferenceDetails", cId, token],
    queryFn: getInvLiquidationPreferenceDetails,
    placeholderData: randomLiquidationPreferenceOverview(),
  });
}

export function useInvGetSecuritySummary(
  securityStringId: string,
  cId: string,
  token: string
) {
  return useQuery({
    queryKey: ["getInvSecuritySummary", cId, token, securityStringId],
    queryFn: getInvSecuritySummary,
  });
}

export function useInvGetAllTransactions(cId: string, token: string) {
  return useQuery({
    queryKey: ["getInvAllTransactions", cId, token],
    queryFn: getInvAllTransactions,
    placeholderData: randomAllTransactions(),
  });
}

export function useInvGetIndividualRoundDetail(
  eventId: string,
  cId: string,
  token: string
) {
  return useQuery({
    queryKey: ["getInvRoundTransactions", eventId, cId, token],
    queryFn: getInvRoundsTransactions,
    placeholderData: randomIndividualTransactions(),
  });
}

export function useInvGetIndividualEsopExerciseDetail(
  eventId: string,
  cId: string,
  token: string
) {
  return useQuery({
    queryKey: ["getInvEsopExerciseTransactions", eventId, cId, token],
    queryFn: getInvEsopExerciseTransactions,
    placeholderData: randomIndividualTransactions(),
  });
}

export function useInvGetIndividualSecondaryDetail(
  eventId: string,
  cId: string,
  token: string
) {
  return useQuery({
    queryKey: ["getIndividualSecondary", eventId, cId, token],
    queryFn: getInvSecondaryTransactions,
    placeholderData: randomIndividualSecondaryTransactions,
  });
}

export function useInvGetIndividualSplitDetail(
  eventId: string,
  cId: string,
  token: string
) {
  return useQuery({
    queryKey: ["getInvIndividualSplitDetail", eventId, cId, token],
    queryFn: getInvSplitTransactions,
    placeholderData: randomIndividualSplitTransactions(),
  });
}

export function useInvGetIndividualBonusDetail(
  eventId: string,
  cId: string,
  token: string
) {
  return useQuery({
    queryKey: ["getinvIndividualBonusDetail", eventId, cId, token],
    queryFn: getInvBonusTransactions,
    placeholderData: randomIndividualBonusTransactions,
  });
}

export function useInvGetIndividualBuybackDetail(
  eventId: string,
  cId: string,
  token: string
) {
  return useQuery({
    queryKey: ["getIndividualBuybackDetail", eventId, cId, token],
    queryFn: getInvBuybackTransactions,
    placeholderData: randomIndividualBuyBackTransactions,
  });
}

export function useGetInvArticleDescription(
  rightsId?: string,
  companyId?: string,
  cId?: string,
  token?: string
) {
  return useQuery({
    queryKey: ["getInvArticleDescription", rightsId, cId, companyId, token],
    queryFn: getInvArticleDescription,
  });
}

export function useUpdateInvArticleDescription() {
  return useMutation({
    mutationKey: "updateInvArticleDescription",
    mutationFn: putInvArticleDescription,
    onSuccess: () => {
      handleEventForTracking({
        eventName: "updateInvArticleDescription",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "updateInvArticleDescription",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function useGetArticleDescription(
  rightsId?: string,
  companyId?: string,
  cId?: string,
  token?: string
) {
  return useQuery({
    queryKey: ["getArticleDescription", rightsId, cId, companyId, token],
    queryFn: getArticleDescription,
  });
}

export function useUpdateArticleDescription() {
  return useMutation({
    mutationKey: "updateArticleDescription",
    mutationFn: putArticleDescription,
    onSuccess: () => {
      handleEventForTracking({
        eventName: "updateArticleDescription",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "updateArticleDescription",
        success: false,
        eventType: "API",
      });
    },
  });
}
export function useGetInvPortalIframeToken() {
  return useQuery({
    queryKey: ["getInvPortalIframeToken"],
    queryFn: getInvPortalIframeToken,
  });
}

export function useGetInvFinancialData(cId: string, token: string) {
  return useQuery({
    queryKey: ["getInvFinancialData", cId, token],
    queryFn: getInvFinancialData,
    placeholderData: randomDataFinancial,
  });
}
