import create from "zustand";

type ShareholderFilterState = {
  shareholderType: string;
  securityType: string;
  setShareholderType: (shareholderType: string) => void;
  setSecurityType: (securityType: string) => void;
  reset: () => void;
};

const initialState = {
  shareholderType: "All",
  securityType: "All",
};

const selectedShareholderFilterStore = create<ShareholderFilterState>(
  (set) => ({
    ...initialState,
    setShareholderType: (value) => {
      set((state) => ({ ...state, shareholderType: value }));
    },
    setSecurityType: (value) => {
      set((state) => ({ ...state, securityType: value }));
    },
    reset: () => {
      set(() => initialState);
    },
  })
);

export const useSelectedShareholderFilterStore = selectedShareholderFilterStore;
