import { useEffect, useState } from "react";
import { Box, HStack, VStack } from "../../components/utils";
import {
  EventComparisonModel,
  InvestmentShareHolderModel,
  RoundsDetailModel,
  SecurityDetails,
} from "../../types/DashboardModel";
import { CaptableEventComparisonColumnChooser } from "./CaptableColumnChooser";
import DashboardRoundsBarChart from "./DashboardRoundsBarChart";
import DashboardSecurityBarChart from "./DashboardSecurityBarChart";
import { DashboardShareHolderChart } from "./DashboardShareHolderChart";
import SecurityBarChartLegend from "../../components/SecurityBarChartLegend";
import { useGraphLoaderStore } from "../../store/graphLoaderStore";
import { CheckBoxWithLabel } from "../../components/shared/InputField";
import Loader from "../../shared/Loader";

function DashboardChart({
  investorPortalView,
  roundChartData,
  investmentData,
  shareHoldersList,
  securityDetails,
}: {
  investorPortalView: boolean;
  roundChartData: RoundsDetailModel[];
  investmentData: InvestmentShareHolderModel[];
  shareHoldersList: EventComparisonModel[];
  securityDetails: SecurityDetails[];
}) {
  const [shareHoldersDetail, setShareHoldersDetail] = useState<
    EventComparisonModel[]
  >([]);
  const [selectedTab, setSelectedTab] = useState("Round");
  const [showSecondary, setShowSecondary] = useState(false);
  useEffect(() => {
    setShareHoldersDetail(shareHoldersList);
  }, [shareHoldersList]);

  const handleTabChange = (tabName: string) => {
    setSelectedTab(tabName);
  };
  const graphStore = useGraphLoaderStore();

  const isLoading = graphStore.isLoading
    ? graphStore.isLoading
    : investmentData.length === 0;

  return (
    <VStack className="rounded-lg bg-white border border-borderColor shadow-box h-fit">
      {isLoading || graphStore.isLoading ? (
        <Loader />
      ) : (
        <>
          {investorPortalView ? (
            <HStack className="justify-between flex md:flex-row flex-col w-full">
              <HStack className="px-4 pt-4">
                {[
                  {
                    name: "Round",
                    displayName: "Rounds",
                  },
                  {
                    name: "Shareholder",
                    displayName: "Shareholders",
                  },
                  {
                    name: "Securities",
                    displayName: "Securities",
                  },
                ].map((element) => (
                  <p
                    key={element.name}
                    className={`font-medium px-4 ${
                      selectedTab === element.name
                        ? "text-red-500 border-b border-red-500"
                        : "text-gray-600"
                    } text-sm cursor-pointer  pb-2`}
                    onClick={() => handleTabChange(element.name)}
                  >
                    {element.displayName}
                  </p>
                ))}
              </HStack>

              <Box className="bg-white flex flex-row rounded w-fit min-h-[45px] mr-10 mt-2">
                {selectedTab === "Shareholder" && (
                  <CheckBoxWithLabel
                    label={"Include Secondary"}
                    labelclassname="text-xs1 font-medium text-gray-500 p-1 mr-2"
                    checked={showSecondary}
                    onChange={(e) => setShowSecondary(!showSecondary)}
                  />
                )}
                {selectedTab === "Shareholder" && (
                  <CaptableEventComparisonColumnChooser
                    label="Select Shareholder"
                    shareHoldersList={shareHoldersList}
                    columnOptions={shareHoldersDetail}
                    onAction={setShareHoldersDetail}
                  />
                )}
              </Box>
            </HStack>
          ) : (
            <VStack className="px-2  lg:justify-start sm:px-6 lg:px-8">
              <p className="text-lg font-medium text-gray-600 ">Rounds Chart</p>
              <p className="mr-4 text-sm font-medium text-gray-500 md:mr-12">
                {`${roundChartData.length} Rounds`}
              </p>
            </VStack>
          )}
          {selectedTab === "Round" && (
            <DashboardRoundsBarChart roundsDetail={roundChartData} />
          )}
          {selectedTab === "Securities" && (
            <VStack className="w-full h-96 pt-10 px-10">
              <DashboardSecurityBarChart
                securityDetails={securityDetails || []}
              />
              <SecurityBarChartLegend />
            </VStack>
          )}
          {selectedTab === "Shareholder" && (
            <VStack className="w-full h-96 pt-10 px-10">
              <DashboardShareHolderChart
                shareHoldersDetail={shareHoldersDetail}
                investmentData={investmentData.filter(
                  (element) =>
                    element.type.toLowerCase() !== "secondary" || showSecondary
                )}
              />
            </VStack>
          )}
        </>
      )}
    </VStack>
  );
}

export default DashboardChart;
