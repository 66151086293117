import { Box } from "@mui/material";
import { getNames } from "country-list";
import { useFormikContext } from "formik";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Select } from "../components/shared/Select";
import {
  Helper,
  Input,
  Label,
  Required,
  TextArea,
} from "../components/shared/InputField";
import { VStack, HStack, Error } from "../components/utils";
import { designationDropDownValues } from "../constants/DropDownValues";
import {
  CompanyDetailsPostModel,
  FounderDetailDataModel,
} from "../types/OnBoardDataModel";
import { PopupHeader, PopupHeadings } from "../components/Headings";
import {
  ButtonSize,
  PrimaryCTAButton,
  SecondaryCTAButton,
  TertiaryCTAButton,
} from "../pages/quickRound/CTAButtonComponents";

type AddEditFounderProps = {
  founder?: FounderDetailDataModel;
  founderIndex: number;
  onPrimaryAction?: () => void;
  onSecondaryAction?: () => void;
  mode?: string;
};

const AddEditFounder = ({
  founder,
  founderIndex,
  onPrimaryAction = () => {},
  onSecondaryAction = () => {},
  mode,
}: AddEditFounderProps) => {
  const formik = useFormikContext<CompanyDetailsPostModel>();

  const {
    values,
    touched,
    getFieldProps,
    setFieldValue,
    setFieldTouched,
    resetForm,
    errors,
    initialErrors,
    setFieldError,
  } = formik;

  const fieldTouched = touched?.founders?.[founderIndex];
  const err = errors?.founders?.[founderIndex] as FounderDetailDataModel;
  const basicDetailErrors =
    err?.fullName || err?.emailId || err?.designation || err?.nationality;

  const handleCancel = () => {
    if (mode === "Add") {
      if (!founder) {
        setFieldValue(
          "founders",
          values?.founders?.filter((_, index) => index !== founderIndex)
        );
        resetForm();
      }
    }
    onSecondaryAction();
  };
  const handleClose = () => {
    onSecondaryAction();
  };

  const doPartialTouch = () => {
    setFieldTouched(`founders[${founderIndex}].fullName`);
    setFieldTouched(`founders[${founderIndex}].emailId`);
    setFieldTouched(`founders[${founderIndex}].designation`);
    setFieldTouched(`founders[${founderIndex}].nationality`);
  };

  const handleSubmit = () => {
    if (founder) {
      setFieldValue(`founders[${founderIndex}].isEdit`, true);
    }
    onPrimaryAction();
  };
  return (
    <div className="transition ease-in duration-2000 opacity-100 enter:opacity-0">
      <PopupHeader
        text={!founder ? "Add Founder Details" : "Edit Founder Details"}
        onClick={() => {
          handleClose();
        }}
      />
      <VStack className="w-full gap-8 px-14 py-8 pb-14">
        <HStack className="gap-8 ">
          <div className="flex-1">
            <Label>
              Founder Name
              <Required />
            </Label>
            <Input
              type="text"
              error={!!(fieldTouched?.fullName && err?.fullName)}
              placeholder="Enter Name"
              className="w-96"
              {...getFieldProps(`founders[${founderIndex}].fullName`)}
            />
            <Helper>Enter the name of founder</Helper>
            {fieldTouched?.fullName && err?.fullName && (
              <Error text={err?.fullName} />
            )}
          </div>
          <div className="flex-1">
            <Label>Designation</Label>
            <Select
              error={!!(fieldTouched?.designation && err?.designation)}
              options={designationDropDownValues}
              {...getFieldProps(`founders[${founderIndex}].designation`)}
            />
            {fieldTouched?.designation && err?.designation && (
              <Error text={err?.designation} />
            )}
          </div>
        </HStack>
        <HStack className="gap-8">
          <div className="flex-1">
            <Label>
              Email Id
              <Required />
            </Label>
            <Input
              type="email"
              placeholder="Eg: example@domain.com"
              className="w-96"
              {...getFieldProps(`founders[${founderIndex}].emailId`)}
            />
            {fieldTouched?.emailId && err?.emailId && (
              <Error text={err?.emailId} />
            )}
          </div>
          <div className="flex-1">
            <Label>Contact Number</Label>
            <Input
              type="text"
              placeholder="Eg:8045804850"
              className="w-96"
              {...getFieldProps(`founders[${founderIndex}].contactNumber`)}
            />
          </div>
        </HStack>
        <HStack className="gap-8">
          {" "}
          <div className="flex-1">
            <Label>Address</Label>
            <TextArea {...getFieldProps(`founders[${founderIndex}].address`)} />
          </div>
          <div className="flex-1">
            <Label>Date of Birth</Label>
            <Input
              type="date"
              placeholder="Eg:DD/MM/YYYY"
              className="w-96"
              {...getFieldProps(`founders[${founderIndex}].dateOfBirth`)}
            />
            {fieldTouched?.dateOfBirth && err?.dateOfBirth && (
              <Error text={err?.dateOfBirth} />
            )}
          </div>
        </HStack>
        <HStack className="gap-8">
          <div className="flex-1">
            <Label>
              Nationality
              <Required />
            </Label>
            <Select
              className="w-96"
              options={getNames().sort()}
              {...getFieldProps(`founders[${founderIndex}].nationality`)}
            />
            {fieldTouched?.nationality && err?.nationality && (
              <Error text={err?.nationality} />
            )}
          </div>
          <div className="flex-1">
            <Label>Linkedin Profile</Label>
            <Input
              type="String"
              placeholder="Linkedin Profile"
              className="w-96"
              {...getFieldProps(`founders[${founderIndex}].linkedInProfile`)}
            />
          </div>
        </HStack>
        <HStack className="gap-8">
          <div className="flex-1">
            <Label>Twitter handle</Label>
            <Input
              type="text"
              placeholder="Twitter Profile"
              className="w-96"
              {...getFieldProps(`founders[${founderIndex}].twitterHandle`)}
            />
          </div>

          <div className="flex-1"></div>
        </HStack>
        <HStack className="gap-8"></HStack>
        <HStack className="justify-between h-8">
          <SecondaryCTAButton
            event-name="Cancel Founder Modal"
            buttonSize={ButtonSize.MEDIUM}
            onClick={() => {
              handleCancel();
            }}
          >
            Cancel
          </SecondaryCTAButton>
          <PrimaryCTAButton
            event-name="Save Founder Modal"
            buttonSize={ButtonSize.SMALL}
            onClick={() => {
              if (basicDetailErrors) {
                doPartialTouch();
              } else {
                handleSubmit();
              }
            }}
          >
            Save
          </PrimaryCTAButton>
        </HStack>
      </VStack>
    </div>
    // </div>
  );
};

export default AddEditFounder;
