/* eslint-disable max-len */
import { useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import { Icon } from "@iconify/react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { toast } from "react-toastify";
import { HStack, VStack, Error, Notification } from "../../components/utils";
import {
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../quickRound/CTAButtonComponents";
import { Input, Label } from "../../components/shared/InputField";
import {
  ExistingInvestorDetail,
  RoundCreationModel,
  OngoingExistingInvestorDetails,
  NewInvestorState,
} from "./RoundCreationModel";
import { SwitchDropDownModel } from "../rounds/RoundsAllTransactions";
import DropDownInput from "../../components/shared/DropDownInput";
import { useShareholderInstrumentStore } from "../../store/shareholderInstrumentStore";
import { useRMCreationStore } from "../../store/roundCreationStore";
import Tooltip from "../../components/shared/Tooltip";
import { useGetInstrumentAndShareHolder } from "../../queries/quickRound";
import { useAuthStore } from "../../store";
import { getCurrencySymbol } from "../../utils/currencyFormatter";
import { currencyDropDownValues } from "../../constants/DropDownValues";

export interface ShareholderDropDown {
  name: string;
  type: string;
  id: string;
  sharesPerSecurity?: SharesPerSecurity[];
}

interface SharesPerSecurity {
  investorId: string;
  instrumentId: string;
  instrumentName: string;
  noOfShares: number;
}

type AddNewInvestorModel = {
  shareholderName: string;
  shareholderId: string;
  shareholderType: string;
  investorRights: string;
  postHolding: number;
  investmentAmount: number;
  state: string;
};

interface DropDownValueModel {
  name: string;
  value: string;
}

const AddNewInvestors = ({
  data,
  actionMode = "New",
  onBack,
  onNext,
}: {
  data?: ExistingInvestorDetail;
  actionMode?: string;
  onBack: () => void;
  onNext: (data: RoundCreationModel) => void;
}) => {
  const roundCreationState = useRMCreationStore();
  const [mode, setMode] = useState(actionMode);

  const [loading, setLoading] = useState(false);
  const companyId = useAuthStore().companyId || "";
  useGetInstrumentAndShareHolder(companyId);
  const currencySymbol = getCurrencySymbol();
  const [shareholders, setShareholder] = useState<ShareholderDropDown[]>(
    roundCreationState?.roundCreation?.listOfShareHolders || []
  );

  const [selectedShareholder, setSelectedShareholder] =
    useState<ShareholderDropDown>({
      name: data?.name || "",
      type: data?.type || "",
      id: data?.id || "",
    });

  const [isAmtLocked, setIsAmtLocked] = useState(false);
  const [holding, setHolding] = useState(
    data?.postholdingPercentage?.toString() ?? ""
  );
  const [amount, setAmount] = useState(
    data?.totalAmountToInvest?.toString() ?? ""
  );

  const shareholderDetail = useMemo(
    () =>
      roundCreationState.roundCreation.tab3?.existingInvestors.find(
        (sh) => sh.id === selectedShareholder.id
      ),
    [roundCreationState.roundCreation.tab3, selectedShareholder]
  );

  const handleSubmit = async (values: AddNewInvestorModel, action: string) => {
    const ongoingExistingInvestorDetails: OngoingExistingInvestorDetails = {
      id: values.shareholderId,
      name: values.shareholderName,
      totalAmountToInvest: Number(amount),
      postholdingPercentage: Number(holding),
      unLockedInputFeild: {
        hasToCalculateAmt: isAmtLocked,
        value: isAmtLocked ? Number(holding) : Number(amount),
      },
      currencyType: "",
      investorRightsType: values.investorRights,
      state: NewInvestorState.ToBeSaved,
    };

    hitSocketApi(ongoingExistingInvestorDetails);
    setLoading(false);
    onBack();
  };

  const handleDelete = async () => {
    setLoading(true);
    hitSocketApi();
    setLoading(false);
    onBack();
  };

  const [investorRightType, setInvestorRightType] = useState(
    data?.investorRightsType || "None"
  );

  const [changeType, setChangeType] = useState(false);

  const investorInitialValues = {
    shareholderId: shareholderDetail?.id || "",
    shareholderName: shareholderDetail?.name || "",
    postHolding: shareholderDetail?.postholdingPercentage || 0,
    investmentAmount: shareholderDetail?.totalAmountToInvest || 0,
    shareholderType: shareholderDetail?.type || "",
    investorRights: shareholderDetail?.investorRightsType || investorRightType,
    state: actionMode || "",
  };

  const hitSocketApi = (
    ongoingExistingInvestorDetails?: OngoingExistingInvestorDetails
  ) => {
    const data = {
      ...roundCreationState.roundCreation,
      tab3: {
        existingInvestors:
          roundCreationState.roundCreation.tab3?.existingInvestors || [],
        ongoingExistingInvestorDetails,
      },
    };
    onNext(data);
  };

  const investorValidationSchema = Yup.object({
    shareholderId: Yup.string().label("Shareholder Id"),
    shareholderName: Yup.string().required().label("Shareholder Name"),
    investorRights: Yup.string()
      .oneOf(["Maintain Capital", "None", "Pro Rata Investment"])
      .required()
      .label("Investor Rights"),
    postHolding: Yup.number()
      .required()
      .moreThan(0)
      .max(100)
      .label("Post Holding"),
    investmentAmount: Yup.number()
      .required()
      .moreThan(0)
      .label("Investment Amount"),
    shareholderType: Yup.string().label("Shareholder Type"),
    state: Yup.string().label("State"),
  });

  useEffect(() => {
    if (!selectedShareholder.id) return;
    const ongoingExistingInvestorDetails: OngoingExistingInvestorDetails = {
      id: selectedShareholder.id,
      name: selectedShareholder.name,
      totalAmountToInvest: Number(amount),
      postholdingPercentage: Number(holding),
      unLockedInputFeild: {
        hasToCalculateAmt: isAmtLocked,
        value: isAmtLocked ? Number(holding) : Number(amount),
      },
      currencyType: "",
      investorRightsType: investorRightType,
      state: NewInvestorState.Update,
    };
    hitSocketApi(ongoingExistingInvestorDetails);
    formik.setFieldValue("shareholderId", selectedShareholder.id);
    formik.setFieldValue("shareholderName", selectedShareholder.name);
    formik.setFieldValue("postHolding", Number(isAmtLocked));
    formik.setFieldValue("investmentAmount", Number(amount));
    formik.setFieldValue("investorRights", investorRightType);
    setInvestorRightType(investorRightType);
  }, [amount, holding, selectedShareholder, changeType]);

  useEffect(() => {
    const data = roundCreationState.roundCreation;
    if (data.errors && data.errors.length > 0) {
      for (const error of data.errors) {
        toast(error, {
          type: "error",
          autoClose: 2000,
        });
      }
      roundCreationState.setRoundCreation({
        ...data,
        errors: [],
      });
    }
  }, []);

  useEffect(() => {
    const socketData =
      roundCreationState.roundCreation.tab3?.ongoingExistingInvestorDetails;
    formik.setFieldValue("shareholderId", socketData?.id);
    formik.setFieldValue("shareholderName", socketData?.name);
    formik.setFieldValue(
      "postHolding",
      Number(socketData?.postholdingPercentage)
    );
    formik.setFieldValue(
      "investmentAmount",
      Number(socketData?.totalAmountToInvest)
    );
    formik.setFieldValue(
      "investorRights",
      socketData?.investorRightsType || "None"
    );
    setInvestorRightType(socketData?.investorRightsType || "None");
  }, [roundCreationState.roundCreation.tab3?.ongoingExistingInvestorDetails]);
  const formik = useFormik({
    initialValues: investorInitialValues,
    validationSchema: investorValidationSchema,
    onSubmit: (values) => {
      handleSubmit(values, "Closed");
    },
  });
  const toggleAmtLock = () => {
    setIsAmtLocked((prev) => !prev);
  };
  const updatelocalStates = () => {
    setAmount(
      (
        roundCreationState.roundCreation.tab3?.ongoingExistingInvestorDetails
          ?.totalAmountToInvest || 0
      ).toFixed(2)
    );
    setHolding(
      (
        roundCreationState.roundCreation.tab3?.ongoingExistingInvestorDetails
          ?.postholdingPercentage || 0
      ).toFixed(2)
    );
  };

  return (
    <div className="max-w-2xl">
      <Box className="px-4 text-lg font-medium border-b pt-4 pb-2">
        <h6 className="flex justify-between">
          <VStack>
            {actionMode === "New" ? (
              <p className="font-medium text-lg">Add Existing Shareholder</p>
            ) : (
              <p className="font-medium text-lg">Edit Existing Shareholder</p>
            )}
          </VStack>
          <XMarkIcon
            className="cursor-pointer h-6"
            onClick={() => {
              handleDelete();
            }}
          />
        </h6>
      </Box>
      <VStack className="w-full py-7 gap-9">
        <VStack className="w-full gap-6 px-10">
          <HStack className="gap-4">
            <VStack className="flex-1">
              <Label className="font-medium text-sm whitespace-nowrap mt-3 mr-4">
                Shareholder Name
              </Label>
              <Box className="w-72 mb-12">
                <DropDownInput
                  data={shareholders.filter((ele) => ele.name !== "Esop pool")}
                  className={"bg-slate-light w-72 rounded-md border-none"}
                  placeholder="Search Investor..."
                  getSelectedValue={(data: any) => {
                    formik.setFieldValue("shareholderName", data.name);
                    formik.setFieldValue("shareholderId", data.id);
                    setSelectedShareholder(data);
                    updatelocalStates();
                  }}
                  selectedOption={
                    selectedShareholder as unknown as ShareholderDropDown
                  }
                  field={"name" as keyof SwitchDropDownModel}
                  disabled={mode === "Edit"}
                />
              </Box>
              {formik.touched.shareholderName &&
                formik.errors.shareholderName && (
                  <Error text={formik.errors.shareholderName} />
                )}
            </VStack>
            <VStack className="flex-1">
              <Label className="font-medium text-sm whitespace-nowrap mt-3 mr-4">
                Investor Rights
              </Label>
              <Box className="w-72 mb-12">
                {investorRightType === "None" ? (
                  <DropDownInput
                    data={
                      [
                        {
                          name: "Pro Rata Investment",
                          value: "Pro Rata Investment",
                        },
                        {
                          name: "Maintain Capital",
                          value: "Maintain Capital",
                        },
                        {
                          name: "None",
                          value: "None",
                        },
                      ] as DropDownValueModel[]
                    }
                    className={"bg-slate-light w-72 rounded-md border-none"}
                    placeholder="Search Rights..."
                    getSelectedValue={(data: any) => {
                      if (data.value !== "") {
                        formik.setFieldValue("investorRights", data.value);
                        setInvestorRightType(data.value);
                        setChangeType(!changeType);
                        updatelocalStates();
                      }
                    }}
                    selectedOption={{
                      name: "None",
                      value: "None",
                    }}
                    field={"value" as keyof DropDownValueModel}
                  />
                ) : (
                  <DropDownInput
                    data={
                      [
                        {
                          name: "Pro Rata Investment",
                          value: "Pro Rata Investment",
                        },
                        {
                          name: "Maintain Capital",
                          value: "Maintain Capital",
                        },
                        {
                          name: "None",
                          value: "None",
                        },
                      ] as DropDownValueModel[]
                    }
                    className={"bg-slate-light w-72 rounded-md border-none"}
                    placeholder="Search Rights..."
                    getSelectedValue={(data: any) => {
                      if (data.value !== "") {
                        formik.setFieldValue("investorRights", data.value);
                        setInvestorRightType(data.value);
                        setChangeType(!changeType);
                        updatelocalStates();
                      }
                    }}
                    selectedOption={{
                      name:
                        roundCreationState.roundCreation.tab3
                          ?.ongoingExistingInvestorDetails
                          ?.investorRightsType ?? investorRightType,
                      value:
                        roundCreationState.roundCreation.tab3
                          ?.ongoingExistingInvestorDetails
                          ?.investorRightsType ?? investorRightType,
                    }}
                    field={"value" as keyof DropDownValueModel}
                  />
                )}
              </Box>
              {roundCreationState.roundCreation.notifications && (
                <Notification
                  text={roundCreationState.roundCreation.notifications[0] ?? ""}
                />
              )}
              {formik.touched.investorRights &&
                formik.errors.investorRights && (
                  <Error text={formik.errors.investorRights} />
                )}
            </VStack>
          </HStack>
          <HStack className="gap-4">
            <VStack className="flex-1">
              <HStack>
                <Label className="font-medium text-sm whitespace-nowrap mr-4">
                  Post Holding %
                </Label>
                {investorRightType.toString().toLowerCase() === "none" ? (
                  isAmtLocked ? (
                    <Icon
                      onClick={() => {
                        toggleAmtLock();
                        updatelocalStates();
                      }}
                      icon="material-symbols:lock-open-outline-rounded"
                      width={20}
                      className="text-[#E75935]"
                      height={20}
                    />
                  ) : (
                    <Tooltip
                      _className={`w-32`}
                      text={
                        "Change Investor Rights to 'None' to edit the Post Holding % and unlock the input field"
                      }
                    >
                      <Icon
                        onClick={() => {
                          toggleAmtLock();
                          updatelocalStates();
                        }}
                        icon="ic:outline-lock"
                        width={20}
                        className="text-gray-400"
                        height={20}
                      />
                    </Tooltip>
                  )
                ) : (
                  <Tooltip
                    _className={`w-32`}
                    text={
                      "Change Investor Rights to 'None' to edit the Post Holding % and unlock the input field"
                    }
                  >
                    <Icon
                      icon="ic:outline-lock"
                      width={20}
                      className="text-gray-400"
                      height={20}
                    />
                  </Tooltip>
                )}
              </HStack>
              <Input
                type="number"
                placeholder={!isAmtLocked ? "" : "Enter Holding %"}
                readOnly={!isAmtLocked}
                className="w-72"
                value={
                  !isAmtLocked ||
                  formik.values.investorRights.toLowerCase() !== "none"
                    ? roundCreationState.roundCreation.tab3
                        ?.ongoingExistingInvestorDetails?.postholdingPercentage
                    : holding
                }
                onChange={(e) => {
                  formik.setFieldValue("postHolding", Number(e.target.value));
                  setHolding(e.target.value);
                }}
              />
              {isAmtLocked &&
                formik.values?.investorRights?.toString().toLowerCase() ===
                  "none" && (
                  <p className="text-xxs text-[#A6A6A6] font-medium pt-1">
                    ~final holding considering existing holding and round size :
                    {roundCreationState.roundCreation.tab3?.ongoingExistingInvestorDetails?.postholdingPercentage?.toFixed(
                      2
                    )}
                  </p>
                )}
              {formik.touched.postHolding && formik.errors.postHolding && (
                <Error text={formik.errors.postHolding} />
              )}
            </VStack>
            <VStack className="flex-1">
              <HStack>
                <Label className="font-medium text-sm whitespace-nowrap mr-4">
                  Investment Amount {` (${currencySymbol})`}
                </Label>
                {formik.values.investorRights.toString().toLowerCase() ===
                "none" ? (
                  !isAmtLocked ? (
                    <Icon
                      onClick={() => {
                        toggleAmtLock();
                        updatelocalStates();
                      }}
                      icon="material-symbols:lock-open-outline-rounded"
                      width={20}
                      className="text-[#E75935]"
                      height={20}
                    />
                  ) : (
                    <Icon
                      onClick={() => {
                        toggleAmtLock();
                        updatelocalStates();
                      }}
                      icon="ic:outline-lock"
                      width={20}
                      className="text-gray-400"
                      height={20}
                    />
                  )
                ) : (
                  <Tooltip
                    _className={`w-32`}
                    text={
                      "Change Investor Rights to 'None' to edit the Post Holding % and unlock the input field"
                    }
                  >
                    <Icon
                      icon="ic:outline-lock"
                      width={20}
                      className="text-gray-400"
                      height={20}
                    />
                  </Tooltip>
                )}
              </HStack>
              <Input
                type="number"
                placeholder={isAmtLocked ? "" : "Enter Amount"}
                readOnly={
                  isAmtLocked || formik.values.investorRights !== "None"
                }
                className="w-72"
                value={
                  isAmtLocked || formik.values.investorRights !== "None"
                    ? roundCreationState.roundCreation.tab3
                        ?.ongoingExistingInvestorDetails?.totalAmountToInvest
                    : amount
                }
                onChange={(e) => {
                  setAmount(e.target.value);
                }}
              />
              {!isAmtLocked &&
                formik.values.investorRights.toString().toLowerCase() ===
                  "none" && (
                  <p className="text-xxs text-[#A6A6A6] font-medium pt-1">
                    ~calculated based on entered amount and share price:
                    {
                      roundCreationState.roundCreation.tab3
                        ?.ongoingExistingInvestorDetails?.totalAmountToInvest
                    }
                  </p>
                )}
              <p className="text-xxs text-white font-medium pt-1">.</p>

              {formik.touched.investmentAmount &&
                formik.errors.investmentAmount && (
                  <Error text={formik.errors.investmentAmount} />
                )}
            </VStack>
          </HStack>
        </VStack>
        <HStack className="justify-between mt-10 px-10">
          <SecondaryCTAButton
            event-name="Cancel New Investors"
            onClick={() => {
              handleDelete();
            }}
          >
            Cancel
          </SecondaryCTAButton>
          <PrimaryCTAButton
            event-name="Save New Investors"
            className="p-1"
            type="submit"
            onClick={() => {
              formik.handleSubmit();
            }}
          >
            {!loading && <span>Save</span>}

            {loading && (
              <HStack>
                <p className="indicator-progress" style={{ display: "block" }}>
                  Please wait...
                </p>
                <Icon
                  className="animate-spin"
                  icon="lucide:loader-2"
                  width={18}
                />
              </HStack>
            )}
          </PrimaryCTAButton>
        </HStack>
      </VStack>
    </div>
  );
};

export default AddNewInvestors;
