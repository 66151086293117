import { QueryFunctionContext } from "react-query";
import { CustomResponse } from "../types/CustomResponse";
import {
  CompanyRole,
  CompanyUser,
  RoleGrant,
  User,
  CompanyRoleUpdateInput,
  Acl,
} from "../types/User";
import api from "./capTableApi";

export async function searchUsers(
  context: QueryFunctionContext
): Promise<CustomResponse<User[]>> {
  return api
    .get("/v1/auth/users", { params: { text: context.queryKey[1] } })
    .then((res) => res.data);
}

export async function getStandardTemplate(
  context: QueryFunctionContext
): Promise<CustomResponse<Acl[]>> {
  return api.get("/v1/auth/template").then((res) => res.data);
}

export async function getUser(
  context: QueryFunctionContext
): Promise<CustomResponse<User>> {
  const emailId = context.queryKey[1];
  return api.get(`/v1/auth/users/${emailId}`).then((res) => res.data);
}

export async function getCompanyUsers(
  context: QueryFunctionContext
): Promise<CustomResponse<CompanyUser[]>> {
  return api.get("/v1/auth/companyUsers").then((res) => res.data);
}

export async function getCompanyRoles(
  context: QueryFunctionContext
): Promise<CustomResponse<CompanyRole[]>> {
  return api.get(`/v1/auth/roles`).then((res) => res.data);
}

export async function updateCompanyRole(
  data: CompanyRoleUpdateInput
): Promise<CustomResponse<CompanyRole>> {
  return api.put("/v1/auth/roles", data).then((res) => res.data);
}

export async function createCompanyRole(
  data: CompanyRoleUpdateInput
): Promise<CustomResponse<CompanyRole>> {
  return api.post("/v1/auth/roles", data).then((res) => res.data);
}

export async function assignRoleToUser(data: {
  userId: number;
  roleId: number;
}): Promise<CustomResponse<CompanyRole>> {
  const { userId, roleId } = data;
  return api
    .put(`/v1/auth/users/${userId}/roles/${roleId}`)
    .then((res) => res.data);
}

export async function createCustomRoleForUser(data: {
  userId: number;
  acl: RoleGrant[];
}): Promise<CustomResponse<CompanyRole>> {
  return api
    .post(`/v1/auth/users/${data.userId}/roles`, { acl: data.acl })
    .then((res) => res.data);
}
