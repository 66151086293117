import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Select } from "../../components/shared/Select";
import { HStack, VStack, Error } from "../../components/utils";
import { QuickBonusSecurityModel } from "../../types/quickRound";
import { useQuickBonusSecurityStore } from "../../store/QuickBonusSecuritiesStore";
import { useShareholderInstrumentStore } from "../../store/shareholderInstrumentStore";
import { Input, Label } from "../../components/shared/InputField";
import {
  SecondaryCTAButton,
  PrimaryCTAButton,
} from "../quickRound/CTAButtonComponents";
import { ShowNumberInWords } from "../../components/shared/UiElement";
import {
  getCurrencySymbol,
  getCurrencyType,
} from "../../utils/currencyFormatter";
import { useNewShareholderInstrumentStore } from "../../store/newShareholderAndInstrumentStore";
import { useGetSecondaryInvestors } from "../../queries/transactionRound";
import { ConvertibleModel } from "../../types/Modeling";
import {
  filterOnlyNumbers,
  convertToNumber,
  numberWithCommas,
} from "../../utils/numUtils";
import { useNewConvertibleModelStore } from "./ConvertibleModelStore";

type convertibleModalProps = {
  onPrimaryAction: () => void;
  onSecondaryAction: () => void;
  data: any;
  mode: string;
  setConvertiblesDataChanged: Function;
};

type TypeSharesPerSecurity = {
  instrumentId: string;
  instrumentName: string;
  noOfShares: number;
  investorId: string;
};

const AddConvertibleDetailsPopup = ({
  onPrimaryAction = () => {},
  onSecondaryAction = () => {},
  data,
  mode,
  setConvertiblesDataChanged,
}: convertibleModalProps) => {
  const [onHoverInvestment, setOnHoverInvestment] = useState(false);
  const [onHoverParValue, setOnHoverParValue] = useState(false);
  const [onHoverValuationCap, setOnHoverValuationCap] = useState(false);
  const [onHoverDiscount, setOnHoverDiscount] = useState(false);
  const [formatInvestment, setFormatInvestment] = useState("");
  const [formatParValue, setFormatParValue] = useState("");
  const [formatValuationCap, setFormatValuationCap] = useState("");
  const [formatDiscount, setFormatDiscount] = useState("");

  const [onHoverConversionRatio, setOnHoverConversionRatio] = useState(false);
  const currencyType = getCurrencyType();
  const currencySymbol = getCurrencySymbol();

  const convertibleModelStore = useNewConvertibleModelStore();

  const initialValues: ConvertibleModel = {
    uuid: data?.uuid || "",
    securityName: data?.securityName || "",
    securityType: data?.securityType || "",
    investment: data?.investment || "",

    valuationCap: data?.valuationCap || 0,
    discount: data?.discount || 0,
    isModel: false,
    baseValuation: 0,
    excluded: false,
  };

  useEffect(() => {
    if (initialValues.investment) {
      setFormatInvestment(`${initialValues.investment}`);
    }

    if (initialValues.valuationCap) {
      setFormatValuationCap(`${initialValues.valuationCap}`);
    }
    if (initialValues.discount) {
      setFormatDiscount(`${initialValues.discount}`);
    }
  }, [
    initialValues.investment,
    initialValues.valuationCap,
    initialValues.discount,
  ]);
  const validationSchema = Yup.object({
    securityName: Yup.string().required().label("Security Name"),
    securityType: Yup.string().required().label("Security Type"),
    investment: Yup.number().required().label("Investment"),

    valuationCap: Yup.number().required().label("Valuation Cap"),
    discount: Yup.number().required().label("Discount"),
  });

  const handleSubmit = (values: any) => {
    const exists = convertibleModelStore.convertibleModel.some(
      (convertible) =>
        convertible.securityName === values.securityName &&
        convertible.securityType === values.securityType &&
        convertible.method === values.method &&
        convertible.investment === values.investment &&
        convertible.conversionRatio === values.conversionRatio &&
        convertible.parValue === values.parValue &&
        convertible.valuationCap === values.valuationCap &&
        convertible.discount === values.discount
    );

    if (exists) {
      toast("convertible Model already exists!", {
        type: "error",
        autoClose: 2000,
      });
      return;
    }

    const existingIndex = convertibleModelStore.convertibleModel.findIndex(
      (convertibleModel: any) => convertibleModel.uuid === values.uuid
    );

    const convertibleModelDetails: ConvertibleModel = {
      uuid: uuidv4(),
      securityName: values.securityName,
      securityType: values.securityType,
      method: values.method,
      investment: values.investment,
      conversionRatio: values.conversionRatio,
      parValue: values.parValue,
      valuationCap: values.valuationCap,
      discount: values.discount,
      isModel: true,
      baseValuation: 0,
      excluded: false,
    };

    if (existingIndex !== -1) {
      convertibleModelStore.convertibleModel[existingIndex] =
        convertibleModelDetails;
      convertibleModelStore.setConvertibleModel([
        ...convertibleModelStore.convertibleModel,
      ]);
      toast("Convertible Model updated successfully", {
        type: "success",
        autoClose: 2000,
      });
    } else {
      convertibleModelStore.setConvertibleModel([
        ...convertibleModelStore.convertibleModel,
        convertibleModelDetails,
      ]);
      toast("Convertible added successfully", {
        type: "success",
        autoClose: 2000,
      });
    }

    setConvertiblesDataChanged(true);
    onPrimaryAction();
  };

  const securityTypeOptions = ["Notes", "CCPS", "OCD", "CCD"];
  const methodOptions = ["Pre-Money", "Post-Money"];

  return (
    <VStack>
      <HStack className="justify-between px-6 py-4 border-b">
        <p className="text-base font-medium">{mode} Convertible Details</p>
        <XMarkIcon className="w-6 h-6" onClick={() => onSecondaryAction()} />
      </HStack>

      <div className="px-4 py-4 bg-white rounded-lg">
        <VStack className="justify-between">
          <Formik
            key="convertible"
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            {(formik) => (
              <Form key="convertible">
                <VStack className="w-full gap-6 px-2 py-4">
                  <VStack className="gap-8">
                    <HStack className="grid w-full grid-cols-1 gap-8 mx-auto md:grid-cols-2">
                      <div className="flex-1 w-72">
                        <Label className="text-sm font-normal">
                          Security Name
                        </Label>
                        <Input
                          type="text"
                          placeholder="Enter security name"
                          onChange={(e: any) => {
                            formik.setFieldValue(
                              "securityName",
                              e.target.value
                            );
                            formik.handleChange("securityName");
                          }}
                          value={formik.values.securityName}
                        />

                        {formik.touched.securityName &&
                          formik.errors?.securityName && (
                            <Error text={formik.errors.securityName} />
                          )}
                      </div>
                      <div className="flex-1 w-72">
                        <Label className="text-sm font-normal">
                          Security Type
                        </Label>
                        <Select
                          options={securityTypeOptions}
                          {...formik.getFieldProps("securityType")}
                        />
                        {formik.touched?.securityType &&
                          formik.errors?.securityType && (
                            <Error text={formik.errors.securityType} />
                          )}
                      </div>
                    </HStack>
                    <HStack className="flex-col gap-8 md:flex-row">
                      <div className="flex-1 w-72">
                        <Label className="text-sm font-normal">
                          Investment ({currencySymbol})
                        </Label>
                        <Input
                          type="text"
                          onMouseEnter={() => {
                            setOnHoverInvestment(true);
                          }}
                          onMouseLeave={() => {
                            setOnHoverInvestment(false);
                          }}
                          onChange={(e: any) => {
                            const filteredValue = filterOnlyNumbers(
                              e.target.value
                            );
                            setFormatInvestment(filteredValue);
                            const values = convertToNumber(filteredValue);
                            formik.setFieldValue("investment", values);
                            formik.handleChange("investment");
                          }}
                          value={numberWithCommas(
                            formatInvestment,
                            currencyType
                          )}
                        />
                        {onHoverInvestment && (
                          <ShowNumberInWords
                            value={formik.values.investment}
                            width={38}
                            currency={currencyType}
                          />
                        )}

                        {formik.touched.investment &&
                          formik.errors?.investment && (
                            <Error text={formik.errors?.investment} />
                          )}
                      </div>
                      <div className="flex-1 w-72">
                        <Label className="text-sm font-normal">
                          Valuation Cap ({currencySymbol})
                        </Label>
                        <Input
                          type="text"
                          onMouseEnter={() => {
                            setOnHoverValuationCap(true);
                          }}
                          onMouseLeave={() => {
                            setOnHoverValuationCap(false);
                          }}
                          onChange={(e: any) => {
                            const filteredValue = filterOnlyNumbers(
                              e.target.value
                            );
                            setFormatValuationCap(filteredValue);
                            const values = convertToNumber(filteredValue);
                            formik.setFieldValue("valuationCap", values);
                            formik.handleChange("valuationCap");
                          }}
                          value={numberWithCommas(
                            formatValuationCap,
                            currencyType
                          )}
                        />
                        {onHoverValuationCap && (
                          <ShowNumberInWords
                            value={formik.values.valuationCap}
                            width={38}
                            currency={currencyType}
                          />
                        )}

                        {formik.touched.valuationCap &&
                          formik.errors?.valuationCap && (
                            <Error text={formik.errors?.valuationCap} />
                          )}
                      </div>
                    </HStack>
                    <HStack className="grid w-full grid-cols-1 gap-8 mx-auto md:grid-cols-2">
                      <div className="flex-1 w-72">
                        <Label className="text-sm font-normal">
                          Discount (%)
                        </Label>
                        <Input
                          type="text"
                          onMouseEnter={() => {
                            setOnHoverDiscount(true);
                          }}
                          onMouseLeave={() => {
                            setOnHoverDiscount(false);
                          }}
                          onChange={(e: any) => {
                            const filteredValue = filterOnlyNumbers(
                              e.target.value
                            );
                            setFormatDiscount(filteredValue);
                            const values = convertToNumber(filteredValue);
                            formik.setFieldValue("discount", values);
                            formik.handleChange("discount");
                          }}
                          value={numberWithCommas(formatDiscount, currencyType)}
                        />
                        {onHoverDiscount && (
                          <ShowNumberInWords
                            value={formik.values.discount}
                            width={38}
                            currency={currencyType}
                          />
                        )}

                        {formik.touched.discount && formik.errors?.discount && (
                          <Error text={formik.errors?.discount} />
                        )}
                      </div>
                    </HStack>
                  </VStack>

                  <HStack className="justify-between mt-10">
                    <SecondaryCTAButton
                      event-name="Cancel Convertible"
                      onClick={() => onSecondaryAction()}
                      type="reset"
                      className="text-red-500"
                    >
                      Cancel
                    </SecondaryCTAButton>
                    <PrimaryCTAButton
                      event-name="Add Convertible details"
                      type="submit"
                    >
                      {mode}
                    </PrimaryCTAButton>
                  </HStack>
                </VStack>
              </Form>
            )}
          </Formik>
        </VStack>
      </div>
    </VStack>
  );
};

export default AddConvertibleDetailsPopup;
