import randomColor from "randomcolor";

export function getRandomColor(seedValuesForColor: string[]): string[] {
  const backgroundColor: string[] = [];
  seedValuesForColor.forEach((seedValue) => {
    const color = randomColor({
      luminosity: "bright",
      alpha: 0.3,
      seed: seedValue,
    });
    backgroundColor.push(color);
  });
  return backgroundColor;
}

export function getSecurityColor(securityType: string): string {
  switch (securityType?.toUpperCase()) {
    case "EQUITY":
      return "bg-[#241DBA]/[0.05] text-[#241DBA]/[0.7]";
    case "PREFERENCE":
      return "bg-[#2DA900]/[0.05] text-[#2DA900]/[0.7]";
    case "CCD":
      return "bg-[#00A3C7]/[0.05] text-[#00A3C7]";
    case "OCD":
      return "bg-[#E79212]/[0.05] text-[#E79212]";
    case "NOTE":
      return "bg-[#F86161]/[0.05] text-[#F86161]";
    case "WARRANT":
      return "bg-[#6904DC]/[0.2] text-[#6904DC]";
    case "RPS":
      return "bg-[#BAA8ED]/[0.05] text-[#D5CBF4]";
    case "NCD":
      return "bg-[#A6A900]/[0.05] text-[#A6A900]/[0.6]";
    case "OCPS":
      return "bg-[#00AD6F]/[0.05] text-[#00AD6F]/[0.6]";
    case "OCRPS":
      return "bg-[#E79212]/[0.05] text-[#E79212]/[0.6]";
    case "DEBENTURE":
      return "bg-[#FFD467]/[0.05] text-[#FFD467]";
    case "OPTIONS":
      return "bg-[#F86161]/[0.05] text-[#F86161]/[0.6]";
    case "CCPS":
      return "bg-[#2DA900]/[0.05] text-[#2DA900]";
    case "FORFEITED":
      return "bg-[#068077]/[0.05] text-[#068077]";
    default:
      return "bg-[#E8FFF3] text-[#48fa9d]";
  }
}
