import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Box, HStack } from "../../components/utils";
import { useAuthStore } from "../../store";
import { RoundHeaderCards } from "./RoundHeaderCard";
import BCHeader from "../../shared/BCHeader";
import { useGetIndividualBuybackDetail } from "../../queries/transactionRound";
import { useQuickRoundTransactionTypeStore } from "../../store/quickRoundTransactionType";
import { IndividualRoundDetailModel } from "../../types/RoundsModel";
import DashBoardGenericBuybackCaptable from "./DashBoardGenericBuybackCaptable";
import {
  AgGridTransactionsCaptable,
  IndividualBuybackCapTable,
} from "../../types/AllTransactionModel";
import { buybackHeaderCardList } from "../../constants/TransactionConstants";
import { useInternalSwitchStateStore } from "../../store/searchDropDown";
import { SwitchDropDownModel } from "./RoundsAllTransactions";
import SearchAutoCompleteDropDown from "../../components/shared/SearchAutoCompleteDropDown";
import { useShareholderInstrumentStore } from "../../store/shareholderInstrumentStore";
import { useGetInstrumentAndShareHolder } from "../../queries/quickRound";
import {
  useInvGetIndividualBuybackDetail,
  useInvGetInstrumentAndShareHolder,
} from "../../queries/investorPortal";
import AGGridRoundsTable from "./RoundsAGTable";
import { handleEventForTracking } from "../../amplitudeAnalytics";

export default function RoundsBuyback() {
  const { id, cId, token } = useParams();
  const eventId = id || "";

  const companyId = useAuthStore.getState().companyId || "";

  const { isPlaceholderData, data: roundDetail } = cId
    ? useInvGetIndividualBuybackDetail(eventId, cId, token || "")
    : useGetIndividualBuybackDetail(eventId);

  const _captableData: IndividualBuybackCapTable[] =
    roundDetail?.capTable || [];
  const navigate = useNavigate();
  const transactionSwitchStore = useInternalSwitchStateStore();
  const [selectedTransaction, setSelectedTransaction] =
    useState<SwitchDropDownModel>({ name: "", id: "", type: "" });

  function getSelectedValue(transaction?: SwitchDropDownModel) {
    if (transaction) {
      if (cId) {
        handleEventForTracking({ eventName: "Inv Transactions Search" });
        navigate(
          `/inv/transactions/${transaction.type?.toLowerCase()}/${
            transaction.id
          }/${cId}/${token}`
        );
      } else {
        handleEventForTracking({ eventName: "Transactions Search" });
        navigate(
          `/transactions/${transaction.type?.toLowerCase()}/${transaction.id}`
        );
      }
    }
  }

  const {
    refetch: getInstrumentAndShareHolder,
    data: instrumentShareholderInfoData,
  } = cId
    ? useInvGetInstrumentAndShareHolder(cId, token || "")
    : useGetInstrumentAndShareHolder(companyId, cId);

  const shareholderAndInstrumentstore = useShareholderInstrumentStore();

  useEffect(() => {
    if (
      cId ||
      (shareholderAndInstrumentstore.shareholders.length === 0 &&
        shareholderAndInstrumentstore.instrumentClasses.length === 0)
    )
      getInstrumentAndShareHolder().then((data) => {
        shareholderAndInstrumentstore.setInstrumentClass(
          data?.data?.instrumentClasses || []
        );
        shareholderAndInstrumentstore.setShareholders(
          data?.data?.shareholders || []
        );
        shareholderAndInstrumentstore.setInstrumentClassSubTypes(
          data?.data?.instrumentClassSubTypes || {}
        );
      });
  }, []);

  useEffect(() => {
    if (!isPlaceholderData && roundDetail)
      setSelectedTransaction({
        name: `${roundDetail.name}`,
        id: roundDetail.id,
        type: roundDetail.type,
      });
  }, [roundDetail]);

  return (
    <div
      className={`${cId ? "bg-white px-4" : ""} ${
        isPlaceholderData ? "loading" : "min-h-full"
      }`}
    >
      <HStack className="justify-between min-h-[45px] items-center">
        <BCHeader
          className="items-baseline "
          bcTitle="Transaction"
          bcSubTitle={roundDetail?.name ?? "Buy-Back"}
          cin={cId}
          token={token}
        />
        <HStack>
          {selectedTransaction ? (
            <Box className="bg-white rounded-md w-48 ml-4 h-8">
              <SearchAutoCompleteDropDown
                placeholder="Switch Transaction"
                data={
                  transactionSwitchStore.transaction as SwitchDropDownModel[]
                }
                getSelectedValue={getSelectedValue}
                selectedOption={selectedTransaction as SwitchDropDownModel}
                field={"name" as keyof SwitchDropDownModel}
              />
            </Box>
          ) : (
            ""
          )}
        </HStack>
      </HStack>

      <div className="mx-auto max-w-full pb-8">
        {roundDetail && (
          <dl className="mt-5 grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-4">
            {buybackHeaderCardList.map((headertype) => (
              <RoundHeaderCards
                key={headertype}
                roundDetail={
                  roundDetail as unknown as IndividualRoundDetailModel
                }
                headerType={headertype}
              />
            ))}
          </dl>
        )}
      </div>

      <AGGridRoundsTable
        capTableData={_captableData as unknown as AgGridTransactionsCaptable[]}
        isInvestorPortal={!!cId}
        roundName={roundDetail?.name || ""}
        eventId={eventId}
        mode={"buyback"}
      />

      {/*Dirty Commit <div className=" bg-white shadow-box rounded-lg">
        {_captableData && (
          <DashBoardGenericBuybackCaptable captableData={_captableData || []} />
        )}
      </div> */}
      {/*Dirty Commit <IndividualNotes data={"dataText"} /> */}
    </div>
  );
}
