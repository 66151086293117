import axios, { AxiosRequestHeaders } from "axios";
import { useAuthStore } from "../store";

const newEsopApi = axios.create({
  baseURL:
    localStorage.getItem("apiUrl") ||
    process.env.REACT_APP_NEW_OPTIONS_API ||
    "http://localhost:8080",
});

newEsopApi.defaults.headers.post["Content-Type"] = "application/json";
newEsopApi.interceptors.request.use((config) => {
  const accesstoken =
    localStorage.getItem("accessToken") ||
    localStorage.getItem("accesstoken") ||
    useAuthStore.getState().accessToken;
  if (accesstoken && !config.url?.endsWith("/login")) {
    (config.headers as AxiosRequestHeaders).Authorization = accesstoken;
  }

  return config;
});

export default newEsopApi;
