import { QueryFunctionContext } from "react-query";
import api from "./sharedCapTableApi";
import captableApi from "./capTableApi";
import { SharedUserModel } from "../pages/share/ShareModal";

export async function getSharedUserAndInitializeShare(
  context: QueryFunctionContext
): Promise<any> {
  const resourceId = context.queryKey[1];
  const resourceType = context.queryKey[2];
  return captableApi
    .get(
      `v1/share/sharedUser?resourceId=${resourceId}&resourceType=${resourceType}`
    )
    .then((res) => res.data.data);
}

export async function submitSharedUser(users: any): Promise<any> {
  return captableApi
    .post(
      `v1/share/sharedUser?shareId=${users.shareId}&resourceType=${users.resourceType}`,
      users.users
    )
    .then((res) => res.data.data);
}

export async function submitEmailForVerification(data: any): Promise<any> {
  return api
    .post(`v1/share/emailVerify?shareId=${data.shareId}`, data.data)
    .then((res) => res.data.data);
}

export async function submitOtpForVerification(data: any): Promise<any> {
  return api
    .post(
      `v1/share/otpCode?shareId=${data.shareId}&email=${data.email}`,
      data.data
    )
    .then((res) => res.data.data);
}

export async function updatePermission(users: any): Promise<any> {
  return captableApi
    .post(
      `v1/share/access?shareId=${users.shareId}&resourceType=${users.resourceType}`,
      users.users
    )
    .then((res) => res.data.data);
}

export async function updateResourceLive(users: any): Promise<any> {
  return captableApi
    .post(`v1/share/resourceLive?shareId=${users.shareId}`, users.resource)
    .then((res) => res.data.data);
}
