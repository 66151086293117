import { Dialog } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import AlertDialog from "../../components/shared/AlertDialog";
import { HStack, VStack, Box } from "../../components/utils";
import {
  useDeleteOnGoingTransaction,
  useDiscardQuickRound,
  usePostTransactionRoundDetail,
} from "../../queries/transactionRound";
import { useAuthStore } from "../../store";
import { useShareholderInstrumentStore } from "../../store/shareholderInstrumentStore";
import { extractBuybackTransaction } from "./ExtractionUtility";
import { useNewShareholderInstrumentStore } from "../../store/newShareholderAndInstrumentStore";
import { useQuickBuybackTransactionStore } from "../../store/useQuickTransactionStore";
import { PrimaryCTAButton } from "../quickRound/CTAButtonComponents";
import AddBuybackPopup from "./AddBuybackPopup";
import AddBuybackAgTable from "./AddBuybackAgTable";

export default function AddTransactionBuyback() {
  const [selected, setSelected] = useState(false);
  const companyId = useAuthStore.getState().companyId || "";
  const {
    state: template,
    setState: setTemplate,
    reset: resetTemplate,
  } = useQuickBuybackTransactionStore();

  const { mutate: deleteOnGoingTransaction } =
    useDeleteOnGoingTransaction(companyId);
  const { mutate: postRoundTransaction } =
    usePostTransactionRoundDetail(companyId);

  const pathName = window.location.href.split("/");
  const roundType = pathName[4];

  const [dialog, setDialog] = useState<{
    open: boolean;
    message?: string;
  }>({
    open: false,
  });

  const buybackTransactionStore = useNewShareholderInstrumentStore();
  const shareholderInstruments = useShareholderInstrumentStore();

  useEffect(() => {
    const buybackTransaction = extractBuybackTransaction(
      template.quickBuybackInput?.quickBuybackParticipants || [],
      shareholderInstruments.instrumentClasses,
      shareholderInstruments.shareholders
    );
    buybackTransactionStore.setBuyback(buybackTransaction || []);
  }, [template]);

  const navigate = useNavigate();
  const { refetch: discardQuickRound } = useDiscardQuickRound();

  const handleDiscard = () => {
    setDialog({ open: false });
    discardQuickRound();
    resetTemplate();
    deleteOnGoingTransaction(companyId);
    navigate("/transactions/allTransactions");
  };

  const [addDialog, setAddDialog] = useState<{
    open: boolean;
    name?: string;
    data?: any;
  }>({
    open: false,
  });

  return (
    <VStack>
      <Box className="bg-white rounded-lg px-8 ">
        <HStack className="pt-10 pb-4">
          <Dialog open={addDialog.open} maxWidth="md">
            {addDialog.open && addDialog.name === "buyback" && (
              <AddBuybackPopup
                onPrimaryAction={() =>
                  setAddDialog({ open: false, name: "buyback" })
                }
                onSecondaryAction={() =>
                  setAddDialog({ open: false, name: "buyback" })
                }
                data={addDialog.data}
                mode={"Add"}
              />
            )}
          </Dialog>
          <HStack className="w-full gap-4">
            <p
              //   onClick={() => {
              //     setSelected(true);
              //   }}
              className={"text-lg"}
            >
              Buyback
            </p>
          </HStack>

          <HStack className="gap-4">
            <PrimaryCTAButton
              className="text-xs2 h-8 whitespace-nowrap"
              onClick={() => {
                setAddDialog({ open: true, name: "buyback" });
              }}
            >
              Add Buyback
            </PrimaryCTAButton>
          </HStack>
        </HStack>
        <Dialog open={dialog.open} maxWidth="md">
          <AlertDialog
            onClose={() => setDialog({ open: false })}
            open={dialog.open}
            message={dialog.message}
            onPrimaryAction={handleDiscard}
            onSecondaryAction={() => setDialog({ open: false })}
          />
        </Dialog>

        <AddBuybackAgTable />
      </Box>
    </VStack>
  );
}
