import { HStack } from "../../../components/utils";
import { CardComponentProps } from "../../../types/finance/Finance";
import {
  getCurrencySymbol,
  getCurrencyType,
} from "../../../utils/currencyFormatter";
import { kFormatter } from "../../../utils/finance/CurrencyFormatter";
import Tooltip from "../../../components/shared/Tooltip";

const CardComponent = (props: CardComponentProps) => {
  const currency = getCurrencyType();

  const currentYear = Number(
    props?.currentYearValue?.toString().replaceAll(",", "")
  );
  const currencySymbol = getCurrencySymbol();
  return (
    <div
      className={`relative   px-4 py-4 bg-gradient-to-b  border border-borderColor rounded-lg hover:cursor-pointer flex flex-col   bg-white  hover:-translate-y-1 hover:duration-500 mb-2`}
    >
      <p className=" truncate text-lg font-medium text-[#464E5F]">
        {props.cardname}
      </p>

      <HStack className="justify-between items-center">
        <p className="py-2 pr-2 font-medium text-sm whitespace-nowrap">{`${
          props.timelineType === "Quarterly" || props.timelineType === "Monthly"
            ? `FY : ${props.currentTimelineYear}-${props.latestYear}`
            : `FY : ${props.latestYear}`
        }`}</p>
        <div className="text-end m-2 text-purple-900 text-sm whitespace-nowrap">
          {Number(props.currentYearValue) === 0 ? (
            "NA"
          ) : (
            <Tooltip
              text={currentYear.toLocaleString(currency)}
              _position="left-1/2"
            >
              <p className="font-bold text-base whitespace-nowrap">
                <span className="font-sans font-bold">{currencySymbol} </span>
                {props.currentYearValue && kFormatter(props.currentYearValue)}
              </p>
            </Tooltip>
          )}
        </div>
      </HStack>

      <HStack className="justify-between items-center">
        {props.previousYear && (
          <p className="py-2 pr-2 font-medium text-sm whitespace-nowrap">{`${
            props.timelineType === "Quarterly" ||
            props.timelineType === "Monthly"
              ? `FY : ${props.previousTimelineYear}-${props.previousYear}`
              : `FY : ${props.previousYear}`
          }`}</p>
        )}

        {props.previousYear && (
          <p className="text-end m-2 text-blue-800 text-sm whitespace-nowrap">
            {Number(props.previousYearValue) === 0 ? (
              "NA"
            ) : (
              <Tooltip
                text={Number(props.previousYearValue).toLocaleString(currency)}
                _position="left-1/2"
              >
                <p className="font-bold text-base whitespace-nowrap">
                  <span className="font-sans font-bold">{currencySymbol} </span>
                  {(props.previousYearValue &&
                    kFormatter(Number(props.previousYearValue))) ||
                    ""}
                </p>
              </Tooltip>
            )}
          </p>
        )}
      </HStack>
    </div>
  );
};

export default CardComponent;
