import { Bar } from "react-chartjs-2";
import { financeBarChartOptions } from "../../../shared/chart-data";
import {
  formatCurrency,
  getCurrencySymbol,
  getCurrencyType,
} from "../../../utils/currencyFormatter";
import { formatToReadableNumber } from "../../../utils/currencyRoboto";

function Barchart(financialReportDetails: any) {
  const currencyType = getCurrencyType();

  const financialTableData: any[] = financialReportDetails.data;
  financialTableData.sort((a, b) => {
    if (a.timelineType === "Year" && b.timelineType === "Year") {
      return parseInt(b.year, 10) - parseInt(a.year, 10);
    } else if (
      a.timelineType === "Quarterly" &&
      b.timelineType === "Quarterly"
    ) {
      if (a.year !== b.year) {
        return b.year - a.year;
      } else {
        const quartersOrder = ["Q1", "Q2", "Q3", "Q4"];
        return (
          quartersOrder.indexOf(b.timeline) - quartersOrder.indexOf(a.timeline)
        );
      }
    } else if (a.timelineType === "Monthly" && b.timelineType === "Monthly") {
      if (a.year !== b.year) {
        return b.year - a.year;
      } else {
        const months = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        return months.indexOf(b.timeline) - months.indexOf(a.timeline);
      }
    } else {
      // If timelineType is different, maintain the current order
      return 0;
    }
  });

  const labels = financialTableData?.map((item) =>
    item.timelineType !== "Year" ? `${item.year}-${item.timeline}` : item.year
  );
  const revenues = financialTableData.map((item) =>
    Number(item?.data.totalRevenue)
  );
  const expenses = financialTableData.map((item) =>
    Number(item?.data.totalExpenses)
  );

  revenues.forEach((item) => {
    Number(item?.toLocaleString(currencyType));
  });
  const data = {
    labels,
    datasets: [
      {
        label: "Revenue",
        backgroundColor: "#3498db",
        data: revenues,
      },
      {
        label: "Expenses",
        backgroundColor: "red",
        data: expenses,
      },
    ],
  };

  const barChartOptions = financeBarChartOptions;
  const currency = getCurrencySymbol();
  barChartOptions.scales.y.ticks.callback = (
    value: any,
    index: any,
    ticks: any
  ) =>
    `${currency} ${formatToReadableNumber({
      value,
      format: currencyType,
    })}`;

  barChartOptions.plugins.tooltip.callbacks.label = (context: any) => {
    const labels: any[] = [];
    const formattedValue = formatCurrency(context.raw, currencyType);
    labels.push(`${context.dataset?.label} : ${formattedValue}`);
    return labels;
  };
  //Dirty commit barChartOptions.plugins.title.text = `Company Name : ${
  //   financialReportDetails.data[0]?.companyName !== undefined
  //     ? financialReportDetails.data[0]?.companyName
  //     : financialReportDetails.data[0]?.publicCompanyName
  // }`;

  return <Bar data={data} options={barChartOptions!} />;
}
export default Barchart;
