import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { hexToRGBA } from "../../utils/colorUtils";
import { getDougnutTagColor } from "../../constants/ShareholderConstants";

ChartJS.register(ArcElement, Tooltip, Legend);

interface ChartData {
  datasets: {
    data: (string | number)[];
    backgroundColor: string[];
    borderColor: string[];
  }[];
}

function HalfDoughnutChart({
  data: chartData,
  type,
}: {
  data: ChartData;
  type: string;
}) {
  const color = getDougnutTagColor(type);
  const rgbaColor = hexToRGBA(color.text, 0.1);

  const optionsSeries: Object = {
    rotation: -90,
    circumference: 180,
    maintainAspectRatio: false,
    responsive: true,
    radius: "67%",
    cutout: "80%",
    aspectRatio: 1,
    plugins: {
      tooltip: {
        display: true,
        displayColors: true,
        opacity: 4, // Set the desired opacity value
      },
      legend: {
        display: false,
        displayColors: false,
        position: "bottom",
        align: "start",
        labels: {
          display: false,
          usePointStyle: false,
          boxWidth: 500,
          boxHeight: 100,
        },
      },
    },
    elements: {
      arc: {
        borderAlign: "center",
      },
    },
  };
  const plugins = [
    {
      id: "0",
      afterDraw(chart: ChartJS) {
        const { width, height, ctx } = chart;
        ctx.restore();
        const fontSize = (height / 80).toFixed(2);
        ctx.font = `${fontSize}em poppins`;
        const countTextColor = color.text;
        ctx.fillStyle = countTextColor;

        ctx.textBaseline = "top";
        const count = Number(chart.data.datasets[0].data[0]).toFixed(2) || 0;
        const text2 = count?.toLocaleString();
        const textX = Math.round(width - ctx.measureText(text2).width) / 2;
        const textY = 100 / 1.9;
        ctx.fillText(text2, textX, textY);
        const fontSize1 = (height / 1).toFixed(2);
        ctx.font = `${fontSize1}em poppins`;
        const circleRadius = 22;
        const circleX = width / 2;
        const circleY = height / 1.4;

        ctx.beginPath();
        ctx.arc(circleX, circleY, circleRadius, 0, 2 * Math.PI);
        ctx.fillStyle = rgbaColor;
        ctx.fill();
        ctx.save();
      },
    },
  ];
  return (
    <div className="w-24 h-20 -mt-4">
      <Doughnut data={chartData} plugins={plugins} options={optionsSeries} />
    </div>
  );
}

export default HalfDoughnutChart;
