import { useEffect, useState } from "react";
import { Dialog } from "@mui/material";
import { toast } from "react-toastify";
import { Box, HStack, VStack } from "../../components/utils";
import { Label } from "../../components/shared/InputField";
import {
  ButtonSize,
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../quickRound/CTAButtonComponents";
import NewSecurityTable from "./NewSecurityTable";
import { useRMCreationStore } from "../../store/roundCreationStore";
import AddNewSecurity from "./AddNewSecurity";
import {
  NewNonPricedSecurity,
  RoundCreationModel,
  NewSecurity,
} from "./RoundCreationModel";
import NewSecurityAllotmentTable from "./NewSecurityAllotmentTable";
import { useGetInstrumentAndShareHolder } from "../../queries/quickRound";
import { useShareholderInstrumentStore } from "../../store/shareholderInstrumentStore";
import { useAuthStore } from "../../store";
import NewNonPricedSecurityTable from "./NewNonPricedSecurityTable";
import AlertModal from "./AlertModal";

export default function SecurityAllotment() {
  const {
    roundCreation: data,
    sendMessage: onNext,
    reset: disCardRoundCreation,
    setRoundCreation: setRoundState,
  } = useRMCreationStore();
  const [dialog, setDialog] = useState<{
    open: boolean;
    mode?: "Add" | "Edit" | "Delete" | "Alert";
    data?: NewSecurity;
  }>({
    open: false,
  });

  const companyId = useAuthStore().companyId || "";
  const { isPlaceholderData, data: instrumentShareholderInfoData } =
    useGetInstrumentAndShareHolder(companyId);
  const shareholderAndInstrumentstore = useShareholderInstrumentStore();
  useEffect(() => {
    if (!isPlaceholderData) {
      shareholderAndInstrumentstore.setInstrumentClass(
        instrumentShareholderInfoData?.instrumentClasses || []
      );
      shareholderAndInstrumentstore.setShareholders(
        instrumentShareholderInfoData?.shareholders || []
      );
      shareholderAndInstrumentstore.setInstrumentClassSubTypes(
        instrumentShareholderInfoData?.instrumentClassSubTypes || {}
      );
    }
  }, [instrumentShareholderInfoData, isPlaceholderData]);

  function scrollToTop() {
    const scroller = document.querySelector("#outlet");
    scroller?.scrollTo(0, 0);
  }

  useEffect(() => {
    if (data.errors && data.errors.length > 0) {
      for (const error of data.errors) {
        toast(error, {
          type: "error",
          autoClose: 2000,
        });
      }
      setRoundState({
        ...data,
        errors: [],
      });
    }
  }, [data.errors]);

  return (
    <VStack className=" bg-white justify-between rounded-lg px-4 py-4 min-h-full">
      {
        <Dialog open={dialog.open} maxWidth="md">
          {dialog.mode === "Add" && (
            <AddNewSecurity
              data={dialog.data}
              onBack={() => {
                setDialog({ open: false });
              }}
              actionMode="New"
            />
          )}
          {dialog.mode === "Alert" && (
            <AlertModal
              onPrimaryAction={() => {
                onNext({
                  ...data,
                  currentTab: 4,
                });
                scrollToTop();
              }}
              onSecondaryAction={() => setDialog({ open: false })}
              data={
                "On moving back the newly added transaction in security allotment will be discarded"
              }
            />
          )}
        </Dialog>
      }

      <div>
        <Box className="border-b mb-4">
          <HStack className="justify-between">
            <VStack>
              <Label className="text-xl font-semibold text-gray-dark">
                Security Allotment
              </Label>

              <p className="font-medium text-gray-light">
                Fill in the Security Details
              </p>
            </VStack>
            <div></div>
          </HStack>
        </Box>
        <VStack>
          <HStack className="justify-between mt-4">
            <VStack>
              <Label className="text-base font-semibold text-gray-dark">
                New Security
              </Label>

              <p className="font-medium text-gray-light">
                Create new securities
              </p>
            </VStack>
            <SecondaryCTAButton
              buttonSize={ButtonSize.SMALL}
              className="h-8"
              onClick={() => {
                if (data.tab1?.inputDetails?.pricedRound) {
                  setDialog({ open: true, mode: "Add" });
                } else {
                  onNext({ ...data, isNavigate: true, screen: "New_Security" });
                }
              }}
            >
              + Add Security
            </SecondaryCTAButton>
          </HStack>

          {data.tab1?.inputDetails?.pricedRound ? (
            <NewSecurityTable instruments={data.tab5?.newInstruments || []} />
          ) : (
            <NewNonPricedSecurityTable
              instruments={data.tab5?.nonPricedInstruments || []}
            />
          )}

          {data.tab5 && (
            <NewSecurityAllotmentTable
              securityAllotmentList={data.tab5?.investorDetails}
            />
          )}
        </VStack>
      </div>
      <HStack className="justify-between gap-4">
        <HStack className="gap-4">
          <SecondaryCTAButton
            type="reset"
            buttonSize={ButtonSize.SMALL}
            onClick={() => {
              setDialog({ mode: "Alert", open: true });
            }}
          >
            Back
          </SecondaryCTAButton>
          <SecondaryCTAButton
            buttonSize={ButtonSize.SMALL}
            onClick={() => {
              disCardRoundCreation(data);
            }}
          >
            Discard
          </SecondaryCTAButton>
        </HStack>
        <PrimaryCTAButton
          buttonSize={ButtonSize.SMALL}
          type="submit"
          onClick={() => {
            setRoundState({
              ...data,
              isLoader: true,
            });
            onNext({
              ...data,
              currentTab: 6,
            });
            scrollToTop();
          }}
          loading={data.isLoader}
        >
          Next
        </PrimaryCTAButton>
      </HStack>
    </VStack>
  );
}
