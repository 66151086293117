import React from "react";
import { limitString } from "../../utils/string";
import Tooltip from "../../components/shared/Tooltip";

function TextToolTip({
  text,
  className,
  limit = 24,
}: {
  text: string;
  className: string;
  limit?: number;
}) {
  return (
    <div>
      {text.length > 24 ? (
        <Tooltip text={text}>
          <p className={`${className}`}>{limitString(text || "", limit)}</p>
        </Tooltip>
      ) : (
        <p className={`${className}`}>{limitString(text || "", limit)}</p>
      )}
    </div>
  );
}

export default TextToolTip;
