export enum SecurityTextDetail {
  Features = "Define here the basic features like par value, dividend, voting rights etc.",
  Liquidation = "In an event of liquidation of the company, the preference shareholders are entitled to return of capital invested by them. Enter the terms of the liquidation preference applicable to the CCPS here.",
  Conversion = "Conversion formula and price are determined based on the price per share at which the convertible converts to equity. If the round is a priced round, enter the conversion ratio and say ‘None’ for the conversion formula. In case of a non-priced round, the conversion ratio must be provided assuming the base valuation. Complete the conversion formula section based on the agreed commercial understanding.",
  AntiDilution = "The anti-dilution provision protects the investors in an event of a down round. Accordingly, select the anti-dilution method based on which the investors price per share will be adjusted.",
}

export enum SecurityWiseTextDetail {
  EquityFeatures = "Enter the intrinsic value of the share or the value at which the shares have been created. Once all the details are filled, click on ‘Save’ to save all the terms of the new security class.",
  CCPSFeatures = "Define here the basic features like par value, dividend, voting rights etc.",
  OCPSFeatures = "Define here the basic features like par value, dividend, voting rights etc.",
  RPSFeatures = "Define the basic features of the security like par value, dividend etc.",
  CCDFeatures = "Define here the basic features like par value, interest, voting rights etc.",
  OCDFeatures = "Define/Specify the features of the security & class here. Key features are voting rights, dividend, liquidation preference & anti-dilution. If the security is convertible to equity, specify conversion mechanism too.",
  NCDFeatures = "Define here the basic features like par value, interest, voting rights etc.",
  NoteFeatures = "Enter the terms on the basis of which the convertible note will convert to equity.",

  EquityLiquidation = "",
  CCPSLiquidation = "In an event of liquidation of the company, the preference shareholders are entitled to return of capital invested by them. Enter the terms of the liquidation preference applicable to the CCPS here.",
  OCPSLiquidation = "Define/Specify the features of the security & class here. Key features are voting rights, dividend, liquidation preference & anti-dilution. If the security is convertible to equity, specify conversion mechanism too.",
  RPSLiquidation = "",
  CCDLiquidation = "In an event of liquidation of the company, the CCD holders are entitled to return of capital invested by them. Enter the terms of the liquidation preference applicable to the CCD holders of this round.",
  OCDLiquidation = "Define/Specify the features of the security & class here. Key features are voting rights, dividend, liquidation preference & anti-dilution. If the security is convertible to equity, specify conversion mechanism too.",
  NCDLiquidation = "Define/Specify the features of the security & class here. Key features are voting rights, dividend, liquidation preference & anti-dilution. If the security is convertible to equity, specify conversion mechanism too.",
  NoteLiquidation = "In an event of liquidation of the company, the convertible note holders are entitled to return of capital invested by them. Enter the terms of liquidation preference applicable to the note holders of this round.",

  EquityConversion = "",
  CCPSConversion = "Conversion formula and price are determined based on the price per share at which the convertible converts to equity. If the round is a priced round, enter the conversion ratio and say ‘None’ for the conversion formula. In case of a non-priced round, the conversion ratio must be provided assuming the base valuation. Complete the conversion formula section based on the agreed commercial understanding.",
  OCPSConversion = "Define/Specify the features of the security & class here. Key features are voting rights, dividend, liquidation preference & anti-dilution. If the security is convertible to equity, specify conversion mechanism too.",
  RPSConversion = "",
  CCDConversion = "Enter the terms based on which the debenture will convert to equity. Trigger is the factor that leads to the conversion; ratio is how many equity shares the debentures will convert to; conversion period is the maximum period within which the security has to convert.",
  OCDConversion = "Define/Specify the features of the security & class here. Key features are voting rights, dividend, liquidation preference & anti-dilution. If the security is convertible to equity, specify conversion mechanism too.",
  NCDConversion = "Define/Specify the features of the security & class here. Key features are voting rights, dividend, liquidation preference & anti-dilution. If the security is convertible to equity, specify conversion mechanism too.",
  NoteConversion = "Specify the trigger factor that converts the note to equity.",

  EquityAntiDilution = "",
  CCPSAntiDilution = "The anti-dilution provision protects the investors in an event of a down round. Accordingly, select the anti-dilution method based on which the investors price per share will be adjusted.",
  OCPSAntiDilution = "",
  RPSAntiDilution = "",
  CCDAntiDilution = "Conversion ratio will require adjustments for various corporate/commercial considerations. Standard adjustments are Anti-dilution and adjustments for stock restructuring including stock splits, stock consolidations, bonus issuance or issuance of share dividends etc. Sometimes conversion ratio is also subject to adjustments based on commercial consideration like valuation adjustment based on operational metrics or pricing of the next round etc",
  OCDAntiDilution = "Define/Specify the features of the security & class here. Key features are voting rights, dividend, liquidation preference & anti-dilution. If the security is convertible to equity, specify conversion mechanism too.",
  NCDAntiDilution = "",
  NoteAntiDilution = "",

  Redemption = "Enter the terms based on which the Security converts to equity.",

  AdjustmentAmount = "Conversion ratio will require adjustments for various corporate/commercial considerations. The formula for adjustment is determined based on the price per share at which the convertible converts to equity. Complete the conversion formula section based on the agreed commercial understanding.",
}

export enum Security {
  Equity = "Equity",
  CCPS = "CCPS",
  OCPS = "OCPS",
  RPS = "RPS",
  CCD = "CCD",
  OCD = "OCD",
  NCD = "NCD",
  Note = "Note",
  Warrant = "Warrant",
  OCRPS = "OCRPS",
  Options = "Options",
  Forfeited = "Forfeited",
}

export const redeemableSecurities = [
  "OCD",
  "Warrant",
  "RPS",
  "NCD",
  "OCPS",
  "OCRPS",
];
export const convertibleSecurities = [
  "CCPS",
  "CCD",
  "Note",
  "Warrant",
  "OCD",
  "OCPS",
  "OCRPS",
];

export const beneficiaryAllowedSecurities = ["Equity", "CCPS", "CCD"];

export function getTypeOfSecurity(securityType: string): string {
  if (securityType === Security.Equity) {
    return "Equity";
  } else if (
    securityType === Security.CCPS ||
    securityType === Security.OCPS ||
    securityType === Security.OCRPS ||
    securityType === Security.RPS
  ) {
    return "Preference";
  } else if (
    securityType === Security.CCD ||
    securityType === Security.NCD ||
    securityType === Security.OCD
  ) {
    return "Debenture";
  } else if (securityType === Security.Options) {
    return "Options";
  } else if (securityType === Security.Warrant) {
    return "Warrant";
  } else if (securityType === Security.Note) {
    return "Note";
  } else if (securityType === Security.Forfeited) {
    return "Forfeited";
  } else {
    return "Equity";
  }
}
