import { isNumber } from "lodash";
import { HStack, VStack } from "../../components/utils";
import { getCurrencyType } from "../../utils/currencyFormatter";
import {
  FormatNumberSpan,
  getCurrencySymbolRoboto,
} from "../../utils/currencyRoboto";
import { Tab1RoundHeader } from "./RoundCreationModel";

const CardDetailHeader = ({
  name,
  value,
  currency,
  className,
}: {
  name: string;
  value: number | string;
  currency?: string;
  className?: string;
}) => {
  const symbol = getCurrencySymbolRoboto();
  return (
    <>
      <VStack
        className={`p-2 rounded-md shadow-box border border-borderColor bg-white ${className} w-full mx-4`}
      >
        <p className="text-sm text-[#464E5F] font-medium whitespace-nowrap pb-2">
          {name}
        </p>
        {currency ? (
          <HStack className="items-center">
            <span className="pr-1 text-xs font-normal">{symbol}</span>
            <p className="text-lg font-medium">
              {isNumber(value) ? (
                <FormatNumberSpan value={value} format={currency} />
              ) : (
                value
              )}
            </p>
          </HStack>
        ) : (
          <div className="text-lg font-medium">
            {isNumber(value) ? (
              <FormatNumberSpan value={value} format={currency} />
            ) : (
              value
            )}
          </div>
        )}
      </VStack>
    </>
  );
};

export default CardDetailHeader;
