import React from "react";
import create from "zustand";
import produce from "immer";

type ActionableInsightFilter = {
  label: string;
  id: number;
  enabled: boolean;
};

type RangeOptions = {
  equityShares: string[];
  prefShares: string[];
  debentures: string[];
  warrants: string[];
  investedAmount: string[];
  fdbShares: string[];
  fdbPercentage: string[];
};

type CaptableTableFilterState = {
  typeOfShareHolder: string;
  shareholder: string[];
  round: string;
  transactionType: string;
  dateOfInvestment: Date;
  inColumnFilter: RangeOptions;
  actionableInsightFilters: ActionableInsightFilter[];
  setTypeOfShareHolder: (text: string) => void;
  setShareHolder: (text: string[]) => void;
  setTransactionType: (text: string) => void;
  setRound: (text: string) => void;
  setDateOfInvestment: (date: Date) => void;
  toggleActionableInsightFilter: (
    insightFilter: ActionableInsightFilter
  ) => void;
  setIncolumnFilter: (value: string, field: keyof RangeOptions) => void;
  uncheckedSlice: (
    e: React.ChangeEvent<HTMLElement>,
    value: string,
    field: keyof RangeOptions
  ) => void;
  reset: () => void;
};

const initialState = {
  typeOfShareHolder: "",
  shareholder: [],
  round: "",
  transactionType: "All",
  dateOfInvestment: new Date(2000, 1, 1),
  inColumnFilter: {
    equityShares: [],
    prefShares: [],
    debentures: [],
    warrants: [],
    fdbShares: [],
    fdbPercentage: [],
    investedAmount: [],
  },
  actionableInsightFilters: [
    {
      label: "Shareholder ( more than 5% )",
      id: 0,
      enabled: false,
    },
    {
      label: "Non-Residential Status",
      id: 1,
      enabled: false,
    },
  ],
};

const resetState = {
  typeOfShareHolder: "",
  shareholder: [],
  transactionType: "All",
  dateOfInvestment: new Date(2000, 1, 1),
  inColumnFilter: {
    equityShares: [],
    prefShares: [],
    debentures: [],
    warrants: [],
    fdbShares: [],
    fdbPercentage: [],
    investedAmount: [],
  },
  actionableInsightFilters: [
    {
      label: "Shareholder ( more than 5% )",
      id: 0,
      enabled: false,
    },
    {
      label: "Non-Residential Status",
      id: 1,
      enabled: false,
    },
  ],
};
const useStore = create<CaptableTableFilterState>((set) => ({
  ...initialState,
  setTypeOfShareHolder: (typeOfShareHolder) => {
    set((state) => ({ ...state, typeOfShareHolder }));
  },
  setTransactionType: (transactionType) => {
    set((state) => ({ ...state, transactionType }));
  },
  setRound: (round) => {
    set((state) => ({ ...state, round }));
  },
  setDateOfInvestment: (date) => {
    set((state) => ({ ...state, dateOfInvestment: date }));
  },
  setShareHolder: (shareholder: string[]) => {
    set((state) => ({ ...state, shareholder }));
  },
  setIncolumnFilter: (value, field) => {
    set(
      produce((state: CaptableTableFilterState) => {
        state.inColumnFilter[field].push(value);
      })
    );
  },
  uncheckedSlice: (e, value, field: keyof RangeOptions) => {
    set(
      produce((state: CaptableTableFilterState) => {
        const indexToBeDeleted = state.inColumnFilter[field].indexOf(value);
        state.inColumnFilter[field].splice(indexToBeDeleted, 1);
      })
    );
  },
  toggleActionableInsightFilter: (insightFilter) => {
    set(
      produce((state: CaptableTableFilterState) => {
        const insightFilters = state.actionableInsightFilters;
        const filterToToggle = insightFilters.find(
          (insight) => insight.id === insightFilter.id
        );
        if (filterToToggle) {
          filterToToggle.enabled = !filterToToggle.enabled;
        }
      })
    );
  },
  reset: () => {
    set(() => resetState);
  },
}));

export const useCapTableFilterStore = useStore;
