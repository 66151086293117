import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  CellClickedEvent,
  ColDef,
  GridApi,
  SideBarDef,
} from "ag-grid-community";
import { useNavigate } from "react-router";

import { AgGridReact } from "ag-grid-react";
import { Action, CTADropdown } from "../../components/shared/Dropdown";
import { agConfigDashboardSideBar } from "../dashboardPage/AgGridConfig";

import { ShareholderDataModel } from "../../types/Shareholder";

import {
  getCurrencySymbol,
  getCurrencyType,
} from "../../utils/currencyFormatter";

import { Box, HStack, useGetCompanyName } from "../../components/utils";

import { useAuthStore } from "../../store";
import {
  useGetOnFilterState,
  usePostOnFilterState,
} from "../dashboardPage/AgGridCacheQuery";
import { tableCellStyle } from "../../components/TableComponent";
import { ExportImport } from "../Utility/GrantsTable";
import {
  exportSharecertificateReport,
  exportShareholderReport,
} from "../../api/Report";
import { downloadExcel, downloadS3File } from "../../utils/DownloadFile";
import { useAgGridTableState } from "../../store/agGridTableStore";

import { ConvertibleHoldings, ConvertibleModel } from "../../types/Modeling";

function AgGridConvertibleHoldings({
  convertibleHoldingsTableData,
}: {
  convertibleHoldingsTableData: ConvertibleHoldings[];
}) {
  const currencySymbol = getCurrencySymbol();
  const companyName = useGetCompanyName();
  const gridApi = useRef<any>(null);

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      autoHeight: true,
      wrapHeaderText: true,
      suppressColumnVirtualisation: true,
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      filterParams: {
        buttons: ["reset"],
        maxNumConditions: 5,
      },
      minWidth: 100,
      filter: true,
      resizable: true,
      flex: 1,
    }),
    []
  );
  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        headerName: "Catagory",
        field: "catagory",
        filter: "agSetColumnFilter",
        cellRenderer: "catagory",
        cellStyle: tableCellStyle,
        sortable: false,
        menuTabs: [],
        tooltipField: "tooltipSecurityName",
      },
      {
        headerName: "Percentage (%)",
        field: "percentage",
        filter: "agSetColumnFilter",
        cellRenderer: "percentage",
        cellStyle: tableCellStyle,
        sortable: false,
        menuTabs: [],
        tooltipField: "tooltipSecurityType",
      },
      {
        headerName: "FDB Shares",
        field: "fdbShares",
        filter: "agSetColumnFilter",
        cellRenderer: "fdbShares",
        cellStyle: tableCellStyle,
        sortable: false,
        menuTabs: [],
      },
    ],
    []
  );

  const gridOptions = {
    suppressRowVirtualisation: true, // Add this line to disable row virtualization
    paginationAutoPageSize: false, // Optional: Set to false if using custom pagination
    suppressScrollOnNewData: true, // Optional: Set to true to prevent scrolling to the top on
  };

  const currencyType = getCurrencyType();

  const rowData = useMemo(
    () =>
      convertibleHoldingsTableData?.map((template) => ({
        catagory: template.category,
        percentage: Number(template.percentage).toLocaleString(currencyType),
        fdbShares: template.fdbShares.toLocaleString(currencyType),

        currencySymbol,
        currencyType,
      })),
    [convertibleHoldingsTableData]
  );

  const [isColumnOpen, setIsColumnOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const openToolPanel = (key: any) => {
    if (key === "columns") {
      if (gridApi) {
        if (!isColumnOpen) gridApi?.current?.api?.openToolPanel(key);
        else gridApi?.current?.api?.closeToolPanel();
        setIsColumnOpen((state) => !state);
        setIsFilterOpen(false);
      }
    } else if (key === "filters") {
      if (gridApi) {
        if (!isFilterOpen) gridApi?.current?.api?.openToolPanel(key);
        else gridApi?.current?.api?.closeToolPanel();
        setIsFilterOpen((state) => !state);
        setIsColumnOpen(false);
      }
    }
  };

  async function handleExportAction(action: Action) {
    if (action.disabled) return;

    if (action.name === "Export Share Certificate Data") {
      exportSharecertificateReport().then((res) => {
        const fileName = `Sharecertificate Report_${companyName}`;
        if (res.isCustomShareCertificate) {
          downloadS3File(res?.signedUrl ?? "", fileName);
        } else {
          downloadExcel(res?.base64 ?? "", fileName);
        }
      });
    } else if (action.name === "Export Shareholder Cap Table") {
      exportShareholderReport().then((res) => {
        const fileName = `Shareholder Cap Table Report_${companyName}`;
        downloadExcel(res, fileName);
      });
    }
  }

  const [filteredRowData, setFilteredRowData] = useState<any>([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const onAgGridFilterChanged = (grid: any) => {
    const filtersApplied = grid.api.isAnyFilterPresent();
    setIsFilterApplied(filtersApplied);

    const filteredData = grid.api
      .getModel()
      .rowsToDisplay.map((node: any) => node.data);
    setFilteredRowData(filteredData);
  };
  return (
    <div className="w-full bg-white border rounded-md shadow-box border-borderColor">
      <HStack className="justify-between w-full">
        <Box
          style={{
            width: "100%",
            height: 5 * 60,
          }}
          className="w-full h-full max-h-full overflow-x-auto ag-theme-material "
        >
          <AgGridReact
            sideBar={agConfigDashboardSideBar}
            onGridReady={(params) => {
              // Store the grid API referen
              gridApi.current = params;
            }}
            alwaysShowHorizontalScroll={false}
            alwaysMultiSort
            animateRows={true}
            enableCharts={true}
            enableRangeSelection={true}
            defaultColDef={defaultColDef}
            onFilterChanged={onAgGridFilterChanged}
            rowData={rowData}
            columnDefs={columnDefs}
            pagination={false}
            suppressRowTransform={true}
            suppressCopyRowsToClipboard={true}
            suppressCopySingleCellRanges={true}
            suppressCellFocus={true}
            suppressMenuHide={false}
            tooltipShowDelay={1000}
            tooltipInteraction={true}
            rowClass={"border-t border-dashed "}
            overlayNoRowsTemplate={
              '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow; margin-top: 50px;">No Rows To Show</span>'
            }
            gridOptions={gridOptions}
            getRowStyle={(params) => {
              if (params.rowIndex % 2 === 0) {
                return { background: "#f8f8f8" };
              } else {
                return { background: "#ffffff" };
              }
            }}
          ></AgGridReact>
        </Box>
      </HStack>
    </div>
  );
}

export default AgGridConvertibleHoldings;
