import { useEffect, useState } from "react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import Box from "@mui/material/Box";
import { Icon } from "@iconify/react";
import { Formik, Form, useFormik } from "formik";
import * as Yup from "yup";
import { HStack, VStack, Error } from "../../components/utils";
import {
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../quickRound/CTAButtonComponents";
import { Input, Label } from "../../components/shared/InputField";
import { Select } from "../../components/shared/Select";
import { useRMCreationStore } from "../../store/roundCreationStore";
import { useAuthStore } from "../../store";
import { RoundCreationModel, SecondaryTransaction } from "./RoundCreationModel";
import DropDownInput from "../../components/shared/DropDownInput";
import { ShareholderDropDown } from "./AddNewInvestors";
import { useShareholderInstrumentStore } from "../../store/shareholderInstrumentStore";
import { SwitchDropDownModel } from "../rounds/RoundsAllTransactions";
import { extractSecurityByName, extractSecurityDetails } from "./utility";
import { getCurrencySymbol } from "../../utils/currencyFormatter";

type AddNewSecondaryProps = {
  onNext: (data: RoundCreationModel) => void;
  onBack: () => void;
  data?: SecondaryTransaction;
  actionMode: string;
};

type AddNewSecondaryModel = {
  id: string;
  buyerId: string;
  sellerId: string;
  securityId: string;
  buyerName: string;
  sellerName: string;
  security: string;
  shares: number;
  amount: number;
};

const AddNewSecondary = ({
  onNext = () => {},
  onBack = () => {},
  data,
  actionMode,
}: AddNewSecondaryProps) => {
  const shareholderInstrumentStore = useShareholderInstrumentStore();
  const roundCreationState = useRMCreationStore();

  const [maximumShare, setMaximumShare] = useState(0);
  const currencySymbol = getCurrencySymbol();
  const secondaryInitialValues = {
    id: data?.id || "",
    buyerId: data?.buyerId || "",
    sellerId: data?.sellerId || "",
    securityId: data?.instrumentId || "",
    buyerName:
      roundCreationState.roundCreation.listOfShareHolders.find(
        (e) => e.id === data?.buyerId
      )?.name ?? "",
    sellerName:
      roundCreationState.roundCreation.listOfShareHolders.find(
        (e) => e.id === data?.sellerId
      )?.name ?? "",
    security:
      extractSecurityDetails(
        data?.instrumentId || "",
        shareholderInstrumentStore.instrumentClasses
      )?.name || "",
    shares: data?.noOfShares || 0,
    amount: data?.amountInvested || 0,
  };

  const secondaryValidationSchema = Yup.object({
    buyerName: Yup.string().required().label("Buyer Name"),
    sellerName: Yup.string().required().label("Seller Name"),
    security: Yup.string().required().label("Security"),
    // eslint-disable-next-line newline-per-chained-call
    shares: Yup.number().max(maximumShare).min(1).required().label("Shares"),
    amount: Yup.number().required().label("Amount"),
  });

  const formik = useFormik<AddNewSecondaryModel>({
    initialValues: secondaryInitialValues,
    validationSchema: secondaryValidationSchema,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  useEffect(() => {
    const maximum =
      sellersShareholderlist
        .find((ele) => ele.id === formik.values.sellerId)
        ?.sharesPerSecurity?.find(
          (ele) => ele.instrumentName === formik.values.security
        )?.noOfShares ?? 0;
    setMaximumShare(maximum);
  }, [formik.values.security]);

  const companyId = useAuthStore().companyId || "";
  const [loading, setLoading] = useState(false);
  const [sellersShareholderlist, setSellersShareholder] = useState<
    ShareholderDropDown[]
  >([]);
  const [buyerShareholderlist, setBuyerShareholder] = useState<
    ShareholderDropDown[]
  >([]);

  const [instruments, setIntruments] = useState<string[]>([]);
  const [selectedBuyer, setSelectedBuyer] = useState<ShareholderDropDown>({
    name:
      roundCreationState.roundCreation.buyersList.find(
        (e) => e.id === data?.buyerId
      )?.name ?? "",
    type: "",
    id: data?.buyerId || "",
  });

  const [selectedSeller, setSelectedSeller] = useState<ShareholderDropDown>({
    name:
      roundCreationState.roundCreation.listOfShareHolders.find(
        (e) => e.id === data?.sellerId
      )?.name ?? "",
    type: "",
    id: data?.sellerId || "",
  });

  const [selectedSecurity, setSelectedSecurity] = useState<ShareholderDropDown>(
    {
      name:
        extractSecurityDetails(
          data?.instrumentId || "",
          shareholderInstrumentStore.instrumentClasses
        )?.name || "",
      type: "",
      id: data?.instrumentId || "",
    }
  );

  useEffect(() => {
    const sellersShareholder = roundCreationState.roundCreation.sellersList;
    const buyerShareholderlist = roundCreationState.roundCreation.buyersList;
    const instrumentList = shareholderInstrumentStore.instrumentClasses.map(
      (instrument) => instrument.name
    );
    setIntruments(instrumentList);
    setSellersShareholder(sellersShareholder);
    setBuyerShareholder(buyerShareholderlist);
  }, [
    shareholderInstrumentStore.instrumentClasses,
    roundCreationState.roundCreation.sellersList,
  ]);

  const handleSubmit = async (values: AddNewSecondaryModel) => {
    setLoading(true);
    setLoading(false);
    const securityId =
      extractSecurityByName(
        values.security,
        shareholderInstrumentStore.instrumentClasses
      )?.id || "";
    const shareholders =
      roundCreationState.roundCreation.tab6?.transaction ||
      ([] as SecondaryTransaction[]);
    if (actionMode === "New") {
      shareholders.push({
        buyerName: values.buyerName,
        sellerName: values.sellerName,
        securityName: values.security,
        amountInvested: values.amount,
        buyerId: values.buyerId,
        currencyType: "INR",
        instrumentId: securityId,
        noOfShares: values.shares,
        sellerId: values.sellerId,
        state: "New",
      });
      onNext({
        ...roundCreationState.roundCreation,
        companyId,
        tab6: {
          transaction: shareholders,
          id: isNewBuyer ? values.id : "",
          newBuyerName: isNewBuyer ? values.buyerName : "",
          isValuesChanged: isNewBuyer,
        },
      });
    } else if (actionMode === "Edit") {
      const shareholderList = shareholders.map((element) => {
        if (element.id === values.id) {
          return {
            buyerName: values.buyerName,
            sellerName: values.sellerName,
            securityName: values.security,
            amountInvested: values.amount,
            buyerId: values.buyerId,
            currencyType: "INR",
            instrumentId:
              extractSecurityByName(
                values.security,
                shareholderInstrumentStore.instrumentClasses
              )?.id || "",
            noOfShares: values.shares,
            sellerId: values.sellerId,
            state: "Edit",
          };
        } else {
          return element;
        }
      });
      onNext({
        ...roundCreationState.roundCreation,
        companyId,
        tab6: {
          transaction: shareholderList,
          id: isNewBuyer ? values.id : "",
          newBuyerName: isNewBuyer ? values.buyerName : "",
          isValuesChanged: isNewBuyer,
        },
      });
    }
    onBack();
  };

  const [isNewBuyer, setIsNewBuyer] = useState(false);

  return (
    <div className="w-full">
      <Box className="px-4 text-lg font-medium border-b pt-4 pb-2">
        <h6 className="flex justify-between">
          <VStack>
            <p className="font-medium text-lg">Add Secondary</p>
          </VStack>
          <XMarkIcon
            onClick={() => onBack()}
            className="cursor-pointer h-6 w-6"
          />
        </h6>
      </Box>
      <VStack className="w-full py-7 gap-9">
        <form
          onSubmit={formik.handleSubmit}
          id="exportSheetDetails"
          className="form"
          noValidate
        >
          <VStack className="w-full gap-6 px-10">
            <HStack className="gap-4">
              <VStack className="flex-1">
                <Label className="font-medium text-sm whitespace-nowrap mt-3 mr-4">
                  Buyer Name
                </Label>
                <Box className="w-72 mb-12">
                  <DropDownInput
                    data={buyerShareholderlist.filter(
                      (shareHolder) =>
                        shareHolder.name.toLowerCase().trim() !== "esop pool" &&
                        shareHolder.name !== selectedSeller.name
                    )}
                    className={"bg-slate-light w-72 rounded-md border-none"}
                    placeholder="Search Buyer..."
                    getEnteredValue={(data: any) => {
                      formik.setFieldValue("buyerName", data);
                      setIsNewBuyer(true);
                    }}
                    getSelectedValue={(data: any) => {
                      if (
                        data.name !== "" &&
                        data.id !== "" &&
                        data.type !== ""
                      ) {
                        formik.setFieldValue("buyerName", data.name);
                        formik.setFieldValue("buyerId", data.id);
                        setSelectedBuyer(data);
                        setIsNewBuyer(false);
                      }
                    }}
                    selectedOption={
                      selectedBuyer as unknown as ShareholderDropDown
                    }
                    field={"name" as keyof SwitchDropDownModel}
                  />
                </Box>
                {formik.touched.buyerName && formik.errors.buyerName && (
                  <Error text={formik.errors.buyerName} />
                )}
              </VStack>
              <VStack className="flex-1">
                <Label className="font-medium text-sm whitespace-nowrap mt-3 mr-4">
                  Seller Name
                </Label>
                <Box className="w-72 mb-12">
                  <DropDownInput
                    data={sellersShareholderlist.filter(
                      (ele) =>
                        ele.name.toLowerCase().trim() !== "esop pool" &&
                        ele.id !== selectedBuyer.id
                    )}
                    className={"bg-slate-light w-72 rounded-md border-none"}
                    placeholder="Search Seller..."
                    getSelectedValue={(data: any) => {
                      if (
                        data.name !== "" &&
                        data.id !== "" &&
                        data.type !== ""
                      ) {
                        formik.setFieldValue("sellerName", data.name);
                        formik.setFieldValue("sellerId", data.id);
                        setSelectedSeller(data);
                      }
                    }}
                    selectedOption={
                      selectedSeller as unknown as ShareholderDropDown
                    }
                    field={"name" as keyof SwitchDropDownModel}
                  />
                </Box>
                {formik.touched.sellerName && formik.errors.sellerName && (
                  <Error text={formik.errors.sellerName} />
                )}
              </VStack>
            </HStack>
            <HStack className="gap-4">
              <VStack className="flex-1">
                <Label className="font-medium text-sm whitespace-nowrap mt-3 mr-4">
                  Security
                </Label>
                <Select
                  options={
                    sellersShareholderlist
                      .find((ele) => ele.id === formik.values.sellerId)
                      ?.sharesPerSecurity?.map((e) => e.instrumentName) ?? []
                  }
                  placeholder="Select Security Type"
                  className="w-72"
                  {...formik.getFieldProps("security")}
                />
                {formik.touched.security && formik.errors.security && (
                  <Error text={formik.errors.security} />
                )}
              </VStack>
              <VStack className="flex-1">
                <Label className="font-medium text-sm whitespace-nowrap mt-3 mr-4">
                  Amount{` (${currencySymbol})`}
                </Label>
                <Input
                  type="number"
                  placeholder="Amount"
                  className="w-72"
                  {...formik.getFieldProps("amount")}
                />

                {formik.touched.amount && formik.errors.amount && (
                  <Error text={formik.errors.amount} />
                )}
              </VStack>
            </HStack>
            <HStack className="gap-4">
              <VStack className="flex-1">
                <Label className="font-medium text-sm whitespace-nowrap mt-3 mr-4">
                  No of Shares
                </Label>
                <Input
                  type="number"
                  placeholder="Shares"
                  className="w-72"
                  {...formik.getFieldProps("shares")}
                  onInput={(e) => {
                    const inputElement = e.target as HTMLInputElement;
                    inputElement.value = Math.round(
                      Number(inputElement.value)
                    ).toString();
                  }}
                />
                {formik.touched.shares && formik.errors.shares && (
                  <Error text={formik.errors.shares} />
                )}
                <p className="text-xxs text-[#A6A6A6] font-medium pt-1">
                  ~Maximum shares you can transfer:
                  {
                    sellersShareholderlist
                      .find((ele) => ele.id === formik.values.sellerId)
                      ?.sharesPerSecurity?.find(
                        (ele) => ele.instrumentName === formik.values.security
                      )?.noOfShares
                  }
                </p>
              </VStack>
              <VStack className="flex-1"></VStack>
            </HStack>
          </VStack>
          <HStack className="justify-between mt-10 px-10">
            <SecondaryCTAButton
              event-name="Cancel New Secondary"
              onClick={() => onBack()}
            >
              Cancel
            </SecondaryCTAButton>
            <PrimaryCTAButton
              event-name="Save New Secondary"
              className="p-1"
              type="submit"
            >
              {!loading && <span>Save</span>}

              {loading && (
                <HStack>
                  <p
                    className="indicator-progress"
                    style={{ display: "block" }}
                  >
                    Please wait...
                  </p>
                  <Icon
                    className="animate-spin"
                    icon="lucide:loader-2"
                    width={18}
                  />
                </HStack>
              )}
            </PrimaryCTAButton>
          </HStack>
        </form>
      </VStack>
    </div>
  );
};

export default AddNewSecondary;
