import { useFormikContext } from "formik";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { EditSecurityModel } from "../../types/SecurityModel";
import { classNames } from "../../utils/string";

const AddNotesDetails = ({ viewOnly }: { viewOnly: boolean }) => {
  const formikk = useFormikContext<EditSecurityModel>();
  const { setFieldValue } = formikk;

  return (
    <Disclosure as="div" key="notes" className="">
      {({ open }) => (
        <>
          <dt
            className={`text-lg border ${
              open ? "border-b-0 rounded-t-md" : "rounded-md"
            } p-4`}
          >
            <Disclosure.Button className="flex items-start justify-between w-full text-left text-gray-400">
              <span className="text-base font-medium text-slate-900">
                Additional Terms
              </span>
              <span className="flex items-center ml-6 h-7">
                {open && (
                  <span
                    className="text-xs font-medium cursor-pointer text-slate-900"
                    onClick={(e) => {
                      setFieldValue("notes", "");
                      e.stopPropagation();
                    }}
                  >
                    Clear
                  </span>
                )}
                <ChevronDownIcon
                  className={classNames(
                    open ? "-rotate-180" : "rotate-0",
                    "h-6 w-6 transform text-slate-500"
                  )}
                  aria-hidden="true"
                />
              </span>
            </Disclosure.Button>
          </dt>
          <Disclosure.Panel as="dd" className="p-4 border rounded-b-md">
            <div className="mt-1">
              <textarea
                rows={4}
                name="comment"
                id="comment"
                className={`bg-slate-50 block w-full p-4 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ${
                  viewOnly ? "cursor-not-allowed" : ""
                }`}
                value={formikk.values.notes ?? ""}
                defaultValue=""
                onChange={(note: any) => {
                  setFieldValue("notes", note.target.value);
                }}
                disabled={viewOnly}
              />
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default AddNotesDetails;
