import { useState } from "react";
import { Action, CTADropdown } from "../../components/shared/Dropdown";
import {
  BeneficialInvestor,
  BeneficiaryMethodType,
  BeneficiaryUpdateModel,
} from "../../types/Shareholder";
import {
  TableHeader,
  THead,
  tableEntryStyle,
} from "../../components/TableComponent";
import { transformRangesArraytoString } from "../../modals/AddBeneficiaryDetails";

export default function TransactionBeneficiariesTable({
  isPreviewOnly = false,
  beneficiaryMethodType,
  beneficiaries,
  handleAction,
  securityType,
}: {
  isPreviewOnly?: boolean;
  beneficiaryMethodType: string;
  beneficiaries: BeneficiaryUpdateModel[];
  handleAction: Function;
  securityType: string;
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [globalFilterText, setGlobalFilterText] = useState("");
  const [sortField, setSortField] = useState<{
    field: keyof BeneficiaryUpdateModel | "" | undefined;
    ascending: boolean;
  }>({ field: "name", ascending: true });

  return (
    <div className="p-2 bg-white rounded-lg mt-8 flex flex-col">
      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8 mb-4">
          <div className="overflow-hidden md:rounded-lg mb-4">
            <div>
              <table className="min-w-full bg-white">
                <thead>
                  <TableHeader>
                    <THead
                      fieldDisplayName="Beneficiary"
                      fieldName="name"
                      className=""
                      isFirstFeild={true}
                      selectedFieldName={sortField?.field || ""}
                      isAscending={sortField?.ascending || false}
                      onChangeSort={() => {
                        setSortField({
                          field: "name",
                          ascending: !sortField?.ascending,
                        });
                      }}
                    />
                    {beneficiaryMethodType ===
                      BeneficiaryMethodType.percentage && (
                      <THead
                        fieldDisplayName="Holding %"
                        fieldName="percentage"
                        isFirstFeild={true}
                        selectedFieldName={sortField?.field || ""}
                        isAscending={sortField?.ascending || false}
                        onChangeSort={() => {
                          setSortField({
                            field: "percentage",
                            ascending: !sortField?.ascending,
                          });
                        }}
                      />
                    )}
                    {beneficiaryMethodType === BeneficiaryMethodType.ranges && (
                      <THead
                        fieldDisplayName="Share Ranges"
                        fieldName="ranges"
                        isFirstFeild={true}
                        selectedFieldName={sortField?.field || ""}
                        isAscending={sortField?.ascending || false}
                        onChangeSort={() => {
                          setSortField({
                            field: "ranges",
                            ascending: !sortField?.ascending,
                          });
                        }}
                      />
                    )}
                    {beneficiaryMethodType ===
                      BeneficiaryMethodType.numberOfShares && (
                      <THead
                        fieldDisplayName="No. Of Shares"
                        fieldName="numberOfShares"
                        isFirstFeild={true}
                        selectedFieldName={sortField?.field || ""}
                        isAscending={sortField?.ascending || false}
                        onChangeSort={() => {
                          setSortField({
                            field: "numberOfShares",
                            ascending: !sortField?.ascending,
                          });
                        }}
                      />
                    )}
                  </TableHeader>
                </thead>
                <tbody>
                  {beneficiaries.map((beneficiary) => (
                    <tr
                      key={beneficiary.name}
                      className="border-t border-dashed hover:bg-slate-50"
                    >
                      <td className="whitespace-nowrap py-4">
                        <p className={tableEntryStyle}>{beneficiary.name}</p>
                      </td>
                      {beneficiaryMethodType ===
                        BeneficiaryMethodType.percentage && (
                        <td className="whitespace-nowrap py-4">
                          <p className={tableEntryStyle}>
                            {beneficiary.percentage}
                          </p>
                        </td>
                      )}
                      {beneficiaryMethodType ===
                        BeneficiaryMethodType.ranges && (
                        <td className="whitespace-nowrap py-4">
                          <p className={tableEntryStyle}>
                            {beneficiary.ranges?.map((share, index) => (
                              <p
                                key={share.toString()}
                                className={`${tableEntryStyle}`}
                              >
                                {transformRangesArraytoString(
                                  [share],
                                  securityType
                                )}
                              </p>
                            ))}
                          </p>
                        </td>
                      )}
                      {beneficiaryMethodType ===
                        BeneficiaryMethodType.numberOfShares && (
                        <td className="whitespace-nowrap py-4">
                          <p className={tableEntryStyle}>
                            {beneficiary.numberOfShares}
                          </p>
                        </td>
                      )}
                      {!isPreviewOnly && (
                        <td className="px-2 py-4 align-top">
                          <CTADropdown
                            actions={[
                              {
                                name: "Edit",
                                disabled: false,
                              },
                              {
                                name: "Delete",
                                disabled: false,
                              },
                            ]}
                            onAction={(action) =>
                              handleAction(action, beneficiary)
                            }
                          />
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
