import hissaApi from "./hissaApi";
import api from "./capTableApi";

export async function getSecurityBasedReport(): Promise<any> {
  return hissaApi
    .get(
      `report-generation/generateReportBeforeApproval?basedOnSecurity=true?isBlob=true`
    )
    .then((res) => res);
}

export async function getRoundBasedReport(): Promise<any> {
  return hissaApi
    .get(
      "report-generation/generateReportBeforeApproval?date=null&basedOnRound=true&roundName=undefined?isBlob=true"
    )
    .then((res) => res);
}

export async function getSummaryBasedReport(): Promise<any> {
  return hissaApi
    .get(
      "dashboard/shareholder/get?exportDataIntoFile=true&includePerforma=false&date=null?isBlob=true"
    )
    .then((res) => res);
}
export async function getScenarioCaptableSummaryReport(
  companyId: string,
  modelId: string,
  scenarioCaptable = true
): Promise<{ data: string }> {
  const url = `v1/company/report/captableSummary?companyId=${companyId}&roundModel=${scenarioCaptable}&modelId=${modelId}`;
  return api.get(url).then((res) => res.data);
}

export async function getOwnershipOverViewReport(
  companyId: string
): Promise<string> {
  const url = `v1/company/report/ownershipOverview`;
  return api.get(url).then((res) => res.data.data);
}

export async function getRoundSummaryReport(): Promise<any> {
  return hissaApi
    .get("report-generation/round?isOverview=true?isBlob=true")
    .then((res) => res);
}

export async function getRoundDetailReport(): Promise<any> {
  return hissaApi
    .get(
      "report-generation/generateReportAfterApproval?date=null&basedOnRound=true&roundName=undefined?isBlob=true"
    )
    .then((res) => res);
}

export async function getSecondaryBasedReport(): Promise<any> {
  return hissaApi
    .get("report-generation/secondary?isBlob=true")
    .then((res) => res);
}

export async function getIndividualRoundSummaryReport(
  roundName: string
): Promise<any> {
  return hissaApi
    .get(`report-generation/round?roundName=${roundName}?isBlob=true`)
    .then((res) => res);
}

export async function getIndividualRoundDetailReport(
  roundName: string
): Promise<any> {
  return hissaApi
    .get(
      `/report-generation/generateReportAfterApproval?date=null&basedOnRound=true&roundName=${roundName}?isBlob=true`
    )
    .then((res) => res);
}

export async function getIndividualRoundPAS4Report(
  roundName: string
): Promise<any> {
  return hissaApi
    .get(`report-generation/round/pas4?roundName=${roundName}?isBlob=true`)
    .then((res) => res);
}
