import { useEffect, useState } from "react";
import { Box, Dialog } from "@mui/material";
import { HStack, VStack } from "../../components/utils";
import { getCurrency, getCurrencyType } from "../../utils/currencyFormatter";
import {
  FormatNumberSpan,
  getCurrencySymbolRoboto,
  getRoundCurrencySymbol,
} from "../../utils/currencyRoboto";
import { formatDate, formatDisplayDate } from "../../utils/date";
import { TermSheetType } from "../../constants/TermsheetConstants";
import { Investor, TermsheetData } from "../../types/TermsheetModel";
import LazyPdfDocument from "../../components/shared/LazyPdfDocument";
import Tooltip from "../../components/shared/Tooltip";

export const TermSheetHeaderCard = ({
  termSheetDetail,
  headerType,
}: {
  termSheetDetail: TermsheetData;
  headerType: TermSheetType;
}) => {
  const [dialog, setDialog] = useState<{
    open: boolean;
    message?: string;
    data?: any;
    index?: number;
    mode?: "View";
  }>({
    open: false,
  });

  const roundCurrency =
    termSheetDetail?.termsheetData?.roundDetails?.roundCurrency;

  const symbol = getCurrencySymbolRoboto();
  const currency = getCurrencyType();

  const leadInvestor = termSheetDetail.termsheetData.investors.reduce(
    (lead, currentInvestor) =>
      (lead.investmentAmount ?? 0) > (currentInvestor.investmentAmount ?? 0)
        ? lead
        : currentInvestor
  );

  switch (headerType) {
    case TermSheetType.roundSize:
      return (
        <div
          key={termSheetDetail?.id || ""}
          className="whitespace-nowrap relative rounded-lg bg-white px-4 py-2 border border-borderColor shadow-box h-32"
        >
          <dt className="flex flex-row justify-between items-center">
            <p className=" truncate text-lg font-medium text-[#464E5F] mr-5">
              {termSheetDetail?.termsheetName || ""}
            </p>
          </dt>
          {termSheetDetail?.termsheetData?.roundDetails?.roundSize && (
            <HStack className="text-3xl items-baseline font-medium text-[#464E5F] pt-2 pb-1 justify-between whitespace-nowrap">
              <HStack className="items-baseline font-medium pt-2 pb-1 whitespace-nowrap">
                <span className="text-xl">{symbol}</span>
                <FormatNumberSpan
                  value={Math.abs(
                    termSheetDetail?.termsheetData?.roundDetails?.roundSize!
                  )}
                  format={currency}
                />
              </HStack>
              <p className="text-xs1 text-[#464E5F]">(Round Size)</p>
            </HStack>
          )}
        </div>
      );
    case TermSheetType.valuation:
      return (
        <div
          key={termSheetDetail?.id || ""}
          className="whitespace-nowrap relative rounded-lg bg-white px-4 py-2 border border-borderColor shadow-box h-32"
        >
          <dt className="flex flex-row justify-between items-center">
            <p className=" truncate text-lg font-medium text-[#464E5F] mr-5">
              Pre-Money Valuation
            </p>
          </dt>

          {termSheetDetail?.termsheetData?.roundDetails?.preMoneyValuation! && (
            <HStack className="text-3xl items-baseline font-medium text-[#464E5F] pt-4 pb-1 whitespace-nowrap">
              <span className="text-xl">{symbol}</span>
              <FormatNumberSpan
                value={Math.abs(
                  termSheetDetail?.termsheetData?.roundDetails
                    ?.preMoneyValuation!
                )}
                format={currency}
              />
            </HStack>
          )}
          <HStack className="justify-end whitespace-nowrap">
            <div className="text-xs text-[#464E5F] font-medium pt-2 pb-1 whitespace-nowrap">
              Exp :{" "}
            </div>
            <p className="text-xs text-[#464E5F] font-medium pt-2 pb-1 pl-1 whitespace-nowrap">
              {formatDisplayDate(termSheetDetail.expiryDate || new Date())}
            </p>
          </HStack>
        </div>
      );
    case TermSheetType.dilution:
      return (
        <div
          key={termSheetDetail?.id || ""}
          className="whitespace-nowrap relative rounded-lg bg-white px-4 py-2 border border-borderColor shadow-box h-32"
        >
          <VStack className="justify-around gap-2 py-2">
            <HStack className="grid grid-cols-3 gap-4 items-center">
              <p className=" col-span-2 truncate text-base font-medium text-[#464E5F] mr-5">
                Priced Round :
              </p>
              <p className=" truncate text-base font-medium text-[#464E5F] flex justify-end">
                {termSheetDetail?.termsheetData?.roundDetails?.pricedRound
                  ? "YES"
                  : "No"}
              </p>
            </HStack>

            <HStack className="grid grid-cols-3 gap-4 items-center">
              <p className=" col-span-2 text-base font-medium text-[#464E5F] mr-5">
                Post Round ESOP % :
              </p>
              {termSheetDetail?.termsheetData?.roundDetails
                ?.postRoundEsopPercentage &&
              termSheetDetail?.termsheetData?.roundDetails
                ?.postRoundEsopPercentage > 0 ? (
                <p className=" text-base font-medium text-[#464E5F] mr-5 grid justify-items-end">
                  <FormatNumberSpan
                    value={Math.abs(
                      termSheetDetail.termsheetData.roundDetails
                        .postRoundEsopPercentage
                    )}
                    format={currency}
                  />
                </p>
              ) : (
                <p className=" text-base font-medium text-[#464E5F] mr-5">0</p>
              )}
            </HStack>
          </VStack>
        </div>
      );

    case TermSheetType.leadInvestor:
      return (
        <div
          key={termSheetDetail?.id || ""}
          className="whitespace-nowrap relative rounded-lg bg-white px-4 py-2 border border-borderColor shadow-box h-32"
        >
          <VStack className="justify-around gap-2 py-2">
            {leadInvestor && leadInvestor.shareholderName && (
              <HStack className="grid grid-cols-2 gap-4 items-center">
                <p className="text-base font-medium text-[#464E5F] mr-5">
                  Lead Investor :
                </p>
                <Tooltip text={leadInvestor.shareholderName}>
                  <p className=" text-base truncate font-medium text-[#464E5F] mr-5">
                    {leadInvestor.shareholderName}
                  </p>
                </Tooltip>
              </HStack>
            )}
            {termSheetDetail.documentUrl && termSheetDetail.documentUrl !== "" && (
              <HStack
                className="cursor-pointer pt-4"
                onClick={() => {
                  setDialog({
                    open: true,
                    mode: "View",
                    data: termSheetDetail.documentUrl,
                  });
                }}
              >
                <div className="flex h-8 shrink-0 items-center justify-start gap-2">
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/8/87/PDF_file_icon.svg"
                    alt="pdf_icon"
                    width={32}
                    height={32}
                  />
                </div>
                <p className="text-base truncate font-medium text-[#464E5F] mr-5 pl-4">
                  {termSheetDetail.documentUrl.split("/").pop()?.split("?")[0]}
                </p>
              </HStack>
            )}
            <Dialog open={dialog.open} maxWidth="lg">
              <LazyPdfDocument
                url={dialog?.data}
                name={
                  termSheetDetail.documentUrl.split("/").pop()?.split("?")[0]
                }
                onClose={() => setDialog({ open: false })}
              />
            </Dialog>
          </VStack>
        </div>
      );

    default:
      return <></>;
  }
};

export const TermSheetMainHeaderCard = ({
  termSheetDetail,
}: {
  termSheetDetail: TermsheetData;
}) => {
  const roundCurrency =
    termSheetDetail?.termsheetData?.roundDetails?.roundCurrency;

  const symbol = getCurrencySymbolRoboto();
  const currency = getCurrencyType();

  return termSheetDetail?.termsheetData?.roundDetails?.roundSize ? (
    <div
      key={termSheetDetail?.id || ""}
      className="whitespace-nowrap w-72 relative rounded-lg bg-white px-4 py-2 border border-borderColor shadow-box hover:-translate-y-1 hover:duration-500"
    >
      <dt className="flex flex-row justify-between items-center">
        <p className=" truncate text-lg font-medium text-[#464E5F] mr-5">
          {termSheetDetail?.termsheetName || ""}
        </p>
      </dt>
      <dd className=" flex items-baseline flex-col whitespace-nowrap">
        <HStack className="text-3xl items-baseline font-medium text-[#078b04b3] pt-2 pb-1 whitespace-nowrap">
          <span className="text-xl">{symbol}</span>
          <FormatNumberSpan
            value={Math.abs(
              termSheetDetail?.termsheetData?.roundDetails?.roundSize
            )}
            format={currency}
          />
        </HStack>
        <HStack className="pt-2">
          <p className={`text-xs text-[#464E5F] font-medium`}>
            {formatDisplayDate(termSheetDetail?.proposalDate || new Date())}
          </p>
        </HStack>
      </dd>
    </div>
  ) : (
    <></>
  );
};
