import { Form, Formik } from "formik";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { Select } from "../../components/shared/Select";
import { HStack, VStack, Error, Box } from "../../components/utils";
import { Input, Label } from "../../components/shared/InputField";
import { EditSecurityModel } from "../../types/SecurityModel";
import {
  useGetSecuritySummary,
  useSecurityOverviewDetails,
  useSubmitSecurity,
  useUpdateSecurity,
} from "../../queries/securities";
import { Security } from "../../constants/SecurityConstantContent";
import BCHeader from "../../shared/BCHeader";
import AddFeaturesDetail from "./AddFeaturesDetail";
import AddInterestDetails from "./AddInterestDetails";
import AddLiquidationDetails from "./AddLiquidationDetails";
import AddConversionDetails from "./AddConversionDetails";
import AddNotesDetails from "./AddNotesDetails";
import AddAntiDilutionDetails from "./AddAntiDilutionDetails";
import AddRedemptionDetails from "./AddRedemptionDetails";
import AddAdjustmentDetails from "./AddAdjustmentDetails";
import {
  ButtonSize,
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../quickRound/CTAButtonComponents";
import { securityInputSchema } from "./SecurityValidation";
import { useAuthStore } from "../../store";
import { SwitchButton } from "../../components/shared/SwitchButton";
import { SecurityClass } from "./NewModel";
import { ShowNumberInWords } from "../../components/shared/UiElement";
import {
  getCurrencySymbol,
  getCurrencyType,
} from "../../utils/currencyFormatter";
import AlertMessage from "../../shared/AlertMessage";
import {
  convertToNumber,
  filterOnlyNumbers,
  numberWithCommas,
} from "../../utils/numUtils";
import { useInvGetSecuritySummary } from "../../queries/investorPortal";

type EditSecurityModalProps = {
  securityStringId?: string;
  onPrimaryAction?: () => void;
  onSecondaryAction?: () => void;
  mode?: string;
};

const AddNewSecurity = ({
  securityStringId,
  onPrimaryAction = () => {},
  onSecondaryAction = () => {},
  mode,
}: EditSecurityModalProps) => {
  const { cId, token } = useParams();

  const companyId = useAuthStore.getState().companyId || "";
  const navigate = useNavigate();
  const { mutate: submitSecurity } = useSubmitSecurity();
  const { mutate: updateSecurity } = useUpdateSecurity();

  const { data: securityDetails } = cId
    ? useInvGetSecuritySummary(securityStringId || "", cId, token || "")
    : useGetSecuritySummary(securityStringId || "");
  const [onHoverValuationCap, setOnHoverValuationCap] = useState(false);
  const [formatValuationCap, setFormatValuationCap] = useState("");
  const [onHoverBaseValuation, setOnHoverBaseValuation] = useState(false);
  const [formatBaseValuation, setFormatBaseValuation] = useState("");

  const viewOnly = mode === "VIEW";

  const initialShareHolderValues: EditSecurityModel = {
    companyId,
    isPriced:
      securityDetails?.isPriced !== undefined ? securityDetails.isPriced : true,
    hasIsinNumber: securityDetails?.depositoryName || "NONE",
    nsdlIsinNumber: securityDetails?.nsdlIcinNumber || "",
    cdslIsinNumber: securityDetails?.cdslIcinNumber || "",
    //  securityDetails.isPriced ?? true,
    id: securityStringId || null,
    securityType: securityDetails?.securityType || "",
    securityName: securityDetails?.securityName || "",
    valuationCap: securityDetails?.nonPriceSecurity?.valuationCap || null,
    discount: securityDetails?.nonPriceSecurity?.discount || null,
    conversionMethod: securityDetails?.nonPriceSecurity?.conversionMethod || "",
    featureParValue: securityDetails?.parValue || null,
    featureVotingRatio: securityDetails?.votingRatio || null,
    featureNotePeriod: securityDetails?.notePeriod || null,
    conversionSecurity: securityDetails?.conversion?.securityType || null,
    featureRepaymentPrePayment: securityDetails?.isPrePayment || false,
    interestType: securityDetails?.interest?.interestType || "",
    interestRate: securityDetails?.interest?.interestRate || null,
    interestPeriod: securityDetails?.interest?.interestPeriod || null,
    interestAppliesOn: securityDetails?.interest?.interestAppliesOn || null,
    liquidationIsParriPassu: securityDetails?.liquidation?.pariPassu || false,
    liquidationIsParticipant:
      securityDetails?.liquidation?.participation || false,
    liquidationRank: securityDetails?.liquidation?.rank || 0,
    liquidationInvestmentMultiple: securityDetails?.liquidation?.multiple || 0,
    liquidationParticipationCap:
      securityDetails?.liquidation?.participationCap || 0,
    conversionPeriod: securityDetails?.conversion?.conversionPeriod || null,
    conversionRatio: securityDetails?.conversionRatio || null,
    conversionTerm: securityDetails?.conversion?.terms || null,
    redemptionTerm: securityDetails?.redemptionTerms || null,
    adjustmentTerm: securityDetails?.adjustmentTerms || null,
    antiDilutionType: securityDetails?.antidilution || "",
    notes: securityDetails?.note || "",
    baseValuation: securityDetails?.nonPriceSecurity?.baseValuation || null,
  };

  useEffect(() => {
    if (initialShareHolderValues.valuationCap) {
      setFormatValuationCap(`${initialShareHolderValues.valuationCap}`);
    }
    if (initialShareHolderValues.baseValuation) {
      setFormatBaseValuation(`${initialShareHolderValues.baseValuation}`);
    }
  }, [initialShareHolderValues.valuationCap]);

  const handleSubmit = (values: EditSecurityModel) => {
    const submitValue: SecurityClass = {
      id: values.id,
      companyId,
      depositoryName: values.hasIsinNumber ?? "NONE",
      securityType: values.securityType,
      securityName: values.securityName,
      templateId: "",
      cdslIcinNumber: values.cdslIsinNumber || "",
      nsdlIcinNumber: values.nsdlIsinNumber || "",
      // empty
      isPriced: values.isPriced,
      parValue: values.featureParValue ?? 0,
      votingRatio: values.featureVotingRatio,
      votingRights: true,
      // empty
      conversionRatio: values.conversionRatio,
      antidilution: values.antiDilutionType,
      redemptionTerms: values.redemptionTerm,
      adjustmentTerms: values.adjustmentTerm,
      note: values.notes || "",
      notePeriod: values.featureNotePeriod,
      isPrePayment: values.featureRepaymentPrePayment,
      conversion: {
        conversionPeriod: values.conversionPeriod,
        securityType: values.conversionSecurity,
        terms: values.conversionTerm ?? "",
      },
      liquidation: {
        rank: values.liquidationRank,
        pariPassu: values.liquidationIsParriPassu,
        participation: values.liquidationIsParticipant,
        participationCap: values.liquidationParticipationCap,
        multiple: values.liquidationInvestmentMultiple,
      },
      interest: {
        interestType: values.interestType,
        interestRate: values.interestRate,
        interestPeriod: values.interestPeriod,
        interestAppliesOn: values.interestAppliesOn,
      },
      nonPriceSecurity: {
        valuationCap: values.valuationCap,
        discount: values.discount,
        conversionMethod: values.conversionMethod,
        baseValuation: values.baseValuation,
      },
    };
    if (viewOnly) return;
    if (mode === "EDIT") {
      updateSecurity(submitValue, {
        onSuccess: (data) => {
          toast("Security Updated Successfully", {
            type: "success",
            autoClose: 2000,
          });

          onPrimaryAction();
        },
        onError: (err: any) => {
          toast(err.response.data.errorMessage, {
            type: "error",
            autoClose: 2000,
          });
        },
      });
    } else {
      submitSecurity(submitValue, {
        onSuccess: (data) => {
          toast("Security Created Successfully", {
            type: "success",
            autoClose: 2000,
          });
          navigate("/security/overview");
        },
        onError: (err: any) => {
          toast(err.response.data.errorMessage, {
            type: "error",
            autoClose: 2000,
          });
        },
      });
    }
  };
  const currencyType = getCurrencyType();
  const currencySymbol = getCurrencySymbol();

  return (
    <div
      className={`min-h-full  ${
        mode === "EDIT" || viewOnly ? "w-200 h-full" : ""
      }`}
    >
      {mode !== "EDIT" && !viewOnly && (
        <HStack className="items-center justify-between">
          <BCHeader
            className="items-baseline"
            bcTitle="Security"
            bcSubTitle="Add New Security"
          />
        </HStack>
      )}

      <div className="w-full px-2 mt-8 bg-white border rounded-md">
        <HStack className="py-4 pl-4 text-lg font-medium text-left border-b">
          {securityDetails ? (
            <h6 className="flex-1">
              {mode === "EDIT"
                ? "Edit Security Class"
                : viewOnly
                ? `View ${securityDetails?.securityName}`
                : "Add Security Class"}
            </h6>
          ) : (
            <VStack className="pt-2">
              <div className="text-[20px] font-semibold leading-6 text-[#071437]">
                New Security Class
              </div>
              <div className="text-xs1 font-medium leading-6 text-[#99A1B7]">
                select the security type to create a security class
              </div>
            </VStack>
          )}
        </HStack>
        <Formik
          key="editSecurity"
          enableReinitialize={true}
          initialValues={initialShareHolderValues}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
          validationSchema={securityInputSchema}
        >
          {(formik) => (
            <Form key="editSecurity">
              <VStack className="w-full gap-2 px-2 py-4">
                <Box className="p-4">
                  <VStack className="gap-8">
                    <HStack className="flex-col justify-between gap-4 md:flex-row">
                      <VStack className="items-start flex-1">
                        <Label className="mr-2 text-sm font-medium text-slate-900">
                          Type
                        </Label>
                        <Select
                          options={[
                            "Equity",
                            "CCPS",
                            "OCPS",
                            "RPS",
                            "CCD",
                            "OCD",
                            "NCD",
                            "Note",
                          ]}
                          {...formik.getFieldProps("securityType")}
                          disabled={viewOnly}
                        />
                        {formik.touched.securityType &&
                          formik.errors?.securityType && (
                            <Error text={formik.errors?.securityType} />
                          )}
                      </VStack>

                      {formik.values.securityType ? (
                        <VStack className="items-start flex-1">
                          <Label className="mr-2 text-sm font-medium text-slate-900">
                            Name
                          </Label>

                          <Input
                            type="text"
                            placeholder="Enter Type of Class"
                            {...formik.getFieldProps("securityName")}
                            disabled={viewOnly}
                          />

                          {formik.touched?.securityName &&
                            formik.errors?.securityName && (
                              <Error text={formik.errors?.securityName} />
                            )}
                        </VStack>
                      ) : (
                        <div className="flex-1"></div>
                      )}
                      {formik.values.securityType &&
                      formik.values.securityType !== "Equity" ? (
                        <HStack className="flex-row-reverse items-center flex-1 gap-4">
                          <SwitchButton
                            className={`font-medium ${
                              viewOnly ? "cursor-not-allowed" : ""
                            }`}
                            value={formik.values.isPriced ?? false}
                            label="Is Priced   "
                            onClick={() => {
                              if (viewOnly) return;
                              formik.setFieldValue(
                                `isPriced`,
                                !formik.values.isPriced ?? false
                              );
                            }}
                          />
                        </HStack>
                      ) : (
                        <div className="flex-1"></div>
                      )}
                    </HStack>
                    <HStack className="flex-col justify-between gap-4 md:flex-row">
                      {formik.values.securityType ? (
                        <VStack className="items-start flex-1">
                          <Label className="mr-2 text-sm font-medium text-slate-900">
                            Security Demated ?
                          </Label>

                          <Select
                            options={[
                              { label: "ONLY NSDL", value: "NSDL" },
                              { label: "ONLY CDSL", value: "CDSL" },
                              { label: "BOTH NSDL & CDSL", value: "BOTH" },
                              { label: "NOT DEMATED", value: "NONE" },
                            ]}
                            valueGetter={(option) => option.value}
                            textGetter={(option) => option.label}
                            {...formik.getFieldProps("hasIsinNumber")}
                            disabled={viewOnly}
                          />
                          {formik.touched.hasIsinNumber &&
                            formik.errors?.hasIsinNumber && (
                              <Error text={formik.errors?.hasIsinNumber} />
                            )}
                        </VStack>
                      ) : (
                        <div></div>
                      )}

                      {formik.values.hasIsinNumber === "NSDL" ||
                      formik.values.hasIsinNumber === "BOTH" ? (
                        <VStack className="items-start flex-1">
                          <Label className="mr-2 text-sm font-medium text-slate-900">
                            NSDL ISIN
                          </Label>

                          <Input
                            type="text"
                            placeholder="Enter NSDL's ISIN"
                            {...formik.getFieldProps("nsdlIsinNumber")}
                            disabled={viewOnly}
                          />

                          {formik.touched?.nsdlIsinNumber &&
                            formik.errors?.nsdlIsinNumber && (
                              <Error text={formik.errors?.nsdlIsinNumber} />
                            )}
                        </VStack>
                      ) : (
                        <div className="flex-1"></div>
                      )}
                      {formik.values.hasIsinNumber === "CDSL" ||
                      formik.values.hasIsinNumber === "BOTH" ? (
                        <VStack className="items-start flex-1">
                          <Label className="mr-2 text-sm font-medium text-slate-900">
                            CDSL ICIN
                          </Label>

                          <Input
                            type="text"
                            placeholder="Enter CDSL's ICIN"
                            {...formik.getFieldProps("cdslIsinNumber")}
                            disabled={viewOnly}
                          />

                          {formik.touched?.cdslIsinNumber &&
                            formik.errors?.cdslIsinNumber && (
                              <Error text={formik.errors?.cdslIsinNumber} />
                            )}
                        </VStack>
                      ) : (
                        <div className="flex-1"></div>
                      )}
                    </HStack>

                    {formik.values.isPriced === false && (
                      <VStack className="gap-8">
                        <HStack className="grid w-full grid-cols-1 gap-8 mx-auto md:grid-cols-2">
                          <VStack className="items-start flex-1">
                            <Label className="mr-2 text-sm font-medium text-slate-900">
                              Valuation Cap ({currencySymbol})
                            </Label>

                            <Input
                              placeholder="Enter Valuation Cap"
                              type="text"
                              onMouseEnter={() => {
                                setOnHoverValuationCap(true);
                              }}
                              onMouseLeave={() => {
                                setOnHoverValuationCap(false);
                              }}
                              onChange={(e: any) => {
                                const value = filterOnlyNumbers(e.target.value);
                                setFormatValuationCap(value);
                                const values = convertToNumber(value);

                                formik.setFieldValue("valuationCap", values);
                                formik.handleChange("valuationCap");
                              }}
                              value={numberWithCommas(
                                formatValuationCap,
                                currencyType
                              )}
                              disabled={viewOnly}
                            />
                            {onHoverValuationCap && (
                              <ShowNumberInWords
                                value={formik.values.valuationCap!}
                                width={38}
                                currency={currencyType}
                              />
                            )}

                            {formik.touched.valuationCap &&
                              formik.errors?.valuationCap && (
                                <Error text={formik.errors?.valuationCap} />
                              )}
                          </VStack>

                          {formik.values.securityType ? (
                            <VStack className="items-start flex-1">
                              <Label className="mr-2 text-sm font-medium text-slate-900">
                                Discount (%)
                              </Label>

                              <Input
                                type="number"
                                placeholder="Enter Discount %"
                                {...formik.getFieldProps("discount")}
                                disabled={viewOnly}
                              />

                              {formik.touched?.discount &&
                                formik.errors?.discount && (
                                  <Error text={formik.errors?.discount} />
                                )}
                            </VStack>
                          ) : (
                            <div className="flex-1"></div>
                          )}
                          {formik.values.securityType ? (
                            <VStack className="items-start flex-1">
                              <Label className="mr-2 text-sm font-medium text-slate-900">
                                Valuation Cap Type
                              </Label>
                              <Select
                                options={[
                                  "Pre-Money",
                                  "Post-Money",
                                  "Investment Method",
                                ]}
                                {...formik.getFieldProps("conversionMethod")}
                                disabled={viewOnly}
                              />

                              {formik.touched?.conversionMethod &&
                                formik.errors?.conversionMethod && (
                                  <Error
                                    text={formik.errors?.conversionMethod}
                                  />
                                )}
                            </VStack>
                          ) : (
                            <div className="flex-1"></div>
                          )}
                          {formik.values.securityType ? (
                            <VStack className="items-start flex-1">
                              <Label className="mr-2 text-sm font-medium text-slate-900">
                                Base Valuation ({currencySymbol})
                              </Label>

                              <Input
                                type="text"
                                placeholder="Enter Base Valuation"
                                onMouseEnter={() => {
                                  setOnHoverBaseValuation(true);
                                }}
                                onMouseLeave={() => {
                                  setOnHoverBaseValuation(false);
                                }}
                                onChange={(e: any) => {
                                  const filteredValue = filterOnlyNumbers(
                                    e.target.value
                                  );
                                  setFormatBaseValuation(filteredValue);
                                  const values = convertToNumber(filteredValue);

                                  formik.setFieldValue("baseValuation", values);
                                  formik.handleChange("baseValuation");
                                }}
                                value={numberWithCommas(
                                  formatBaseValuation,
                                  currencyType
                                )}
                                disabled={viewOnly}
                              />
                              {onHoverBaseValuation && (
                                <ShowNumberInWords
                                  value={formik.values.baseValuation!}
                                  width={38}
                                  currency={currencyType}
                                />
                              )}

                              {formik.touched?.baseValuation &&
                                formik.errors?.baseValuation && (
                                  <Error text={formik.errors?.baseValuation} />
                                )}
                            </VStack>
                          ) : (
                            <div className="flex-1"></div>
                          )}
                        </HStack>
                      </VStack>
                    )}
                  </VStack>
                </Box>

                {formik.values.securityType && (
                  <AddFeaturesDetail
                    keepOpen={mode !== "EDIT"}
                    viewOnly={viewOnly}
                    currencySymbol={currencySymbol}
                  />
                )}

                {(formik.values.securityType === Security.OCPS ||
                  formik.values.securityType === Security.RPS ||
                  formik.values.securityType === Security.CCD ||
                  formik.values.securityType === Security.NCD ||
                  formik.values.securityType === Security.Note) && (
                  <AddInterestDetails
                    keepOpen={mode !== "EDIT"}
                    viewOnly={viewOnly}
                  />
                )}
                {formik.values.securityType !== Security.Equity &&
                  formik.values.securityType !== Security.RPS &&
                  formik.values.securityType !== Security.Note &&
                  formik.values.securityType !== Security.NCD &&
                  formik.values.securityType && (
                    <AddLiquidationDetails
                      keepOpen={mode !== "EDIT"}
                      viewOnly={viewOnly}
                      currencySymbol={currencySymbol}
                    />
                  )}

                {(formik.values.securityType === Security.CCPS ||
                  formik.values.securityType === Security.OCPS ||
                  formik.values.securityType === Security.OCD ||
                  formik.values.securityType === Security.CCD ||
                  formik.values.securityType === Security.Note) && (
                  <AddConversionDetails
                    keepOpen={mode !== "EDIT"}
                    viewOnly={viewOnly}
                  />
                )}
                {(formik.values.securityType === Security.CCPS ||
                  formik.values.securityType === Security.CCD ||
                  formik.values.securityType === Security.OCD) && (
                  <AddAntiDilutionDetails
                    keepOpen={mode !== "EDIT"}
                    viewOnly={viewOnly}
                  />
                )}
                {(formik.values.securityType === Security.OCPS ||
                  formik.values.securityType === Security.RPS ||
                  formik.values.securityType === Security.NCD ||
                  formik.values.securityType === Security.Note) && (
                  <AddRedemptionDetails
                    keepOpen={mode !== "EDIT"}
                    viewOnly={viewOnly}
                  />
                )}
                {(formik.values.securityType === Security.OCPS ||
                  formik.values.securityType === Security.RPS ||
                  formik.values.securityType === Security.NCD ||
                  formik.values.securityType === Security.Note) && (
                  <AddAdjustmentDetails
                    keepOpen={mode !== "EDIT"}
                    viewOnly={viewOnly}
                  />
                )}
                {formik.values.securityType && (
                  <AddNotesDetails viewOnly={viewOnly} />
                )}
              </VStack>
              {/* </div> */}
              <HStack className="justify-end">
                {!formik.isValid && (
                  <AlertMessage
                    disableX={true}
                    heading="Missing Required Field"
                    data="Please complete all required fields before submitting"
                  />
                )}
              </HStack>
              {formik.values.securityType && (
                <HStack
                  className={`justify-end gap-4 py-8 ${
                    mode === "EDIT" || viewOnly ? "p-4" : ""
                  }`}
                >
                  <SecondaryCTAButton
                    buttonSize={ButtonSize.SMALL}
                    onClick={() => {
                      if (mode !== "EDIT" && !viewOnly)
                        navigate("/security/overview");
                      else onSecondaryAction();
                    }}
                    className="mr-8"
                  >
                    {mode === "EDIT" ? "Close" : "Go Back"}
                  </SecondaryCTAButton>
                  {!viewOnly && (
                    <PrimaryCTAButton
                      buttonSize={ButtonSize.SMALL}
                      event-name="Save Security Class"
                      type="submit"
                    >
                      Save Security Class
                    </PrimaryCTAButton>
                  )}
                </HStack>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AddNewSecurity;
