import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Slider } from "@mui/material";
import { useAuthStore } from "../../store";
import { useAllIndividualShareHolderRight } from "../../queries/shareHolderRights";
import RightsSubPage from "../shareHolderRights/RightsSubPage";
import { HStack, VStack } from "../../components/utils";
import EmptyRightsPage from "../shareHolderRights/EmptyRightsPage";
import { formatDisplayDate } from "../../utils/date";
import { classNames } from "../../utils/string";
import { ShareHolderRightsModel } from "../../types/ShareHolderRightsModel";
import { MarkLabel } from "../dashboardPage/DashboardCapTableTable";
import { sliderRightsStyle } from "../../constants/DashboardConstants";
import RightsSlider, {
  RightsSliderModel,
} from "../shareHolderRights/RightsSlider";
import Loader from "../../components/shared/Loader";

const ShareholderRightsTable = ({
  shareHolderData,
}: {
  shareHolderData: ShareHolderRightsModel[];
}) => {
  const [marksLabels, setMarkLabels] = useState<MarkLabel[]>([]);
  const [shareHolder, setShareholder] = useState<ShareHolderRightsModel[]>(
    shareHolderData ?? []
  );
  useEffect(() => {
    if (shareHolderData) setShareholder(shareHolderData);
  }, [shareHolderData]);

  const [selectedShareholderRights, setSelectedShareholderRights] =
    useState<ShareHolderRightsModel>();

  const [currentTab, setCurrentTab] = useState("Rights");

  useEffect(() => {
    const markLabel: MarkLabel[] = [];
    if (shareHolder.length > 0) {
      shareHolder.forEach((element, index) => {
        markLabel.push({
          value: index,
          label: formatDisplayDate(element.date),
        });
      });
      const length = shareHolder.length - 1 || 0;
      setMarkLabels(markLabel);
      setSelectedShareholderRights(shareHolder[length]);
    }
  }, [shareHolder]);
  const [count, setCount] = useState<number>(shareHolder.length - 1);

  useEffect(() => {
    const markLabel: MarkLabel[] = [];
    if (shareHolder.length > 0) {
      shareHolder.forEach((element, index) => {
        markLabel.push({
          value: index,
          label: formatDisplayDate(element.date),
        });
      });
      const length = shareHolder.length - 1 || 0;
      setSelectedShareholderRights(shareHolder[length]);
    }
  }, [shareHolder]);

  const updateRange = (countNumber: any) => {
    if (shareHolder) {
      setCount(countNumber);
      setSelectedShareholderRights(shareHolder[countNumber]);
    }
  };

  function getRightsList(): RightsSliderModel[] {
    const headers: RightsSliderModel[] = [];
    shareHolderData?.forEach((rights) =>
      headers.push({
        name: rights.roundName || "",
        date: rights.date,
        rightsId: rights.rightsId,
      })
    );
    return headers;
  }

  return (
    <VStack className="px-2 sm:px-4 lg:px-6 bg-white rounded pb-8 py-6">
      {!selectedShareholderRights ? (
        <Loader />
      ) : (
        <>
          {shareHolder.length !== 0 ? (
            <div>
              {shareHolder.length >= 1 && (
                <HStack className="justify-center mx-auto w-full pb-4 pt-2 px-8 rounded-md items-center font-semibold border border-gray-100 bg-white mb-4">
                  <RightsSlider
                    rightsDetails={getRightsList()}
                    onChangeRange={(countNumber: any) => {
                      updateRange(countNumber);
                    }}
                  />
                </HStack>
              )}

              <RightsSubPage
                shareholders={selectedShareholderRights?.shareholders || []}
                rightsDataList={
                  selectedShareholderRights?.shareholderRights || []
                }
                transferDataList={selectedShareholderRights?.transfer || []}
                tableType="shareholder"
              />
            </div>
          ) : (
            <EmptyRightsPage isInvestorPortal={true} />
          )}
        </>
      )}
    </VStack>
  );
};

export default ShareholderRightsTable;
