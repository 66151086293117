import { Fragment, useRef, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { LockClosedIcon } from "@heroicons/react/20/solid";
import { Icon } from "@iconify/react";
import { useSetDefaultProduct } from "../queries";
import { useAuthStore } from "../store";
import { HStack } from "../components/utils";
import esopLogo from "../utils/assets/esop.svg";
import esopLogoGrayed from "../utils/assets/esop-grayed.svg";

export default function FlyoutSwitch() {
  const [show, setShown] = useState(false);

  const {
    role,
    user,
    accessToken,
    canAccessEsop: isEsopExists,
  } = useAuthStore();

  const { mutate: setDefaultEsop } = useSetDefaultProduct();

  const redirectToEsop = async () => {
    const esopCompanyId = user?.isNewEsop
      ? user?.newEsopCompanyId
      : user?.legacyCompanyId;
    if (esopCompanyId && isEsopExists) {
      const optionsUrl = user?.isNewEsop
        ? process.env.REACT_APP_NEW_ESOP_URL
        : process.env.REACT_APP_OPTIONS_URL;
      await setDefaultEsop(user?.isNewEsop ? null : BigInt(esopCompanyId));
      window.location.href = `${
        optionsUrl || "http://localhost:3001"
      }/options/esopOverview?logintoken=${
        localStorage.getItem("accessToken") ||
        localStorage.getItem("accesstoken") ||
        accessToken
      }&companyId=${esopCompanyId}`;
    }
  };

  const solutions = [
    {
      name: "Cap Table",
      enabled: true,
      current: true,
      description: "Equity Ownership of Company",
      onClick: () => {},
      icon: (
        <div>
          <Icon
            icon="raphael:piechart"
            color="#E85936"
            width="30"
            height="30"
          ></Icon>
        </div>
      ),
    },
    {
      name: "ESOP",
      enabled: isEsopExists,
      current: false,
      description: "ESOP Management of Company",
      onClick: redirectToEsop,
      icon: (
        <div>
          <img
            className="h-8"
            src={isEsopExists ? esopLogo : esopLogoGrayed}
            alt="esop"
            color="#E85936"
          />
        </div>
      ),
    },
  ];

  return (
    <Popover className="relative">
      <Popover.Button
        onMouseEnter={() => {
          setShown(true);
        }}
        onMouseLeave={() => {
          setShown(false);
        }}
        onClick={() => setShown(!show)}
        className="text-gray-400 hover:text-gray-500"
      >
        <span className="sr-only">Switch</span>
        <Icon id="switch" icon="mdi:dots-grid" className={`h-6 w-6`} />
      </Popover.Button>

      <Transition
        show={show}
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel
          onMouseEnter={() => {
            setShown(true);
          }}
          onMouseLeave={() => {
            setShown(false);
          }}
          className="absolute z-10 mt-5 flex w-screen max-w-max  -right-8"
        >
          <div className="w-screen max-w-xs flex-auto overflow-hidden rounded-lg bg-white text-sm leading-6 shadow-lg">
            <div className="bg-gray-50">
              <div
                key={"Switch"}
                className="flex items-center justify-center gap-x-1 p-2 font-semibold text-gray-900 hover:bg-gray-100"
              >
                {"Switch Platform"}
              </div>
            </div>

            <div className="p-4  cursor-pointer">
              {solutions.map((item) => (
                <div
                  key={item.name}
                  onClick={() => {
                    item.onClick();
                  }}
                  className="group relative flex gap-x-2 rounded-lg p-2 hover:bg-gray-50 mb-2"
                >
                  <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                    {item.icon}
                  </div>
                  <div className="flex-1">
                    <HStack className="justify-between">
                      <a
                        className={`font-semibold  ${
                          item.current
                            ? "text-primary"
                            : item.enabled
                            ? "text-gray-600 group-hover:text-primary"
                            : "text-gray-300"
                        }`}
                      >
                        {item.name}
                        <span className="absolute inset-0" />
                      </a>
                      {!item.enabled && (
                        <LockClosedIcon
                          className={`h-4 w-4 ${
                            item.current
                              ? "text-primary"
                              : item.enabled
                              ? "text-gray-600 group-hover:text-primary"
                              : "text-gray-300"
                          }`}
                          aria-hidden="true"
                        />
                      )}
                    </HStack>

                    <p
                      className={
                        item.enabled ? "text-gray-600" : "text-gray-300"
                      }
                    >
                      {item.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
