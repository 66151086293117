import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CellClickedEvent, ColDef } from "ag-grid-community";
import { useNavigate, useParams } from "react-router";
import { Dialog } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import { Action, CTADropdown } from "../../components/shared/Dropdown";
import { agConfigDashboardSideBar } from "../dashboardPage/AgGridConfig";
import {
  getCurrencySymbol,
  getCurrencyType,
} from "../../utils/currencyFormatter";
import { Box, HStack, useGetCompanyName } from "../../components/utils";
import GenericTableHeader from "../../shared/TableHeader";
import { IconCTAButton } from "../quickRound/CTAButtonComponents";
import QuickSearch from "../rounds/QuickSearch";
import { useAuthStore } from "../../store";
import {
  useGetOnFilterState,
  usePostOnFilterState,
} from "../dashboardPage/AgGridCacheQuery";
import { tableCellStyle } from "../../components/TableComponent";
import { ExportImport } from "../Utility/GrantsTable";
import {
  exportIndividualSecuritySummaryReport,
  exportSecurityBasedReport,
  exportSecuritySummaryReport,
} from "../../api/Report";
import { downloadExcel } from "../../utils/DownloadFile";
import { SecurityTableDetail } from "../../types/SecurityModel";
import {
  AmountInvestedRender,
  DateOfSecurityRender,
  LiquidationPreferencesRender,
  NumberOfSharesRender,
  PARValueRender,
  RoundsRender,
  SecurityClassRender,
  SecurityTypeRender,
  ShareHolderRender,
} from "./SecurityClassAgComponent";
import {
  convertibleSecurities,
  redeemableSecurities,
} from "../../constants/SecurityConstantContent";
import { useCanUserAccess } from "../../store/useRoleGrantStore";
import { AddSecurityConversion } from "../../modals/AddSecurityConversion";
import { AddSecurityRedemption } from "../../modals/AddSecurityRedemption";
import AddNewSecurity from "./AddNewSecurity";
import { useAgGridTableState } from "../../store/agGridTableStore";
import SearchInput from "../../components/shared/SearchInput";
import {
  RoundsToolTip,
  tooltipLiquidationPreferences,
} from "../dashboardPage/customTooltip";
import { DateOfTransactionRender } from "../rounds/TransactionAGComponent";
import { compareSecurityDate } from "../../utils/transactionUtils";
import { queryClient } from "../../queries/client";
import { LiquidationPreferenceData } from "./LiquidationPreferenceAgGridTable";
import { useLiquidationPreference } from "../../queries/securities";
import { useInvLiquidationPreference } from "../../queries/investorPortal";

function AGGridSecurityClassTable({
  isInvestorPortal,
  securityType,
  securityList,
}: {
  isInvestorPortal?: boolean;
  securityType: string;
  securityList: SecurityTableDetail[];
}) {
  const { cId, token } = useParams();

  const currencySymbol = getCurrencySymbol();
  const companyName = useGetCompanyName();
  const companyId = useAuthStore().companyId ?? "";
  const userId = useAuthStore().user?.userId ?? 0;
  const gridApi = useRef<any>(null);
  const { data: liquidation } = cId
    ? useInvLiquidationPreference(cId, token || "")
    : useLiquidationPreference();

  const { refetch, data: filterState } = useGetOnFilterState(
    `${userId}`,
    companyId
  );

  const displayedRowCount = gridApi.current?.api.getDisplayedRowCount();

  const agTableStore = useAgGridTableState();
  useEffect(() => {
    refetch().then((data) => {
      if (data.data) agTableStore.setState(data.data);
      setColumnSetting(data.data?.securitiesClassColumnModel);
      setFilterSetting(data.data?.securitiesClassFilterModel);
    });
  }, []);

  const { mutate: postOnFilter } = usePostOnFilterState();

  function getColumnSetting() {
    if (gridApi.current) return gridApi.current.columnApi?.getColumnState();
    return {};
  }

  function setColumnSetting(model: any) {
    if (gridApi.current)
      gridApi.current.columnApi.applyColumnState({ state: model });
    return {};
  }

  // Gets filter model via the grid API
  const getFilterSetting = () => {
    if (gridApi.current) return gridApi.current.api?.getFilterModel();
    return {};
  };

  const setFilterSetting = (model: any) => {
    if (gridApi.current) return gridApi.current.api?.setFilterModel(model);
    return {};
  };

  const uploadFilterAndColumn = async () => {
    const columnState = await getColumnSetting();
    const filterState = await getFilterSetting();
    postOnFilter({
      userId: `${userId}`,
      companyId,
      filterData: {
        ...agTableStore.state,
        securitiesClassColumnModel: columnState,
        securitiesClassFilterModel: filterState,
      },
    });
  };

  const cin = "";

  const componentsRegistery = useMemo(
    () => ({
      securityClass: memo(SecurityClassRender),
      securityType: memo(SecurityTypeRender),
      rounds: memo(RoundsRender),
      shareholder: memo(ShareHolderRender),
      numberOfShares: memo(NumberOfSharesRender),
      parValue: memo(PARValueRender),
      amountInvested: memo(AmountInvestedRender),
      date: memo(DateOfSecurityRender),
      liquidationPreferences: memo(LiquidationPreferencesRender),
    }),
    []
  );

  async function handleExportAction(action: Action) {
    if (action.name === "Export Summary Report") {
      exportSecuritySummaryReport().then((res) => {
        const fileName = `Security Summary Report_${companyName}`;
        downloadExcel(res, fileName);
      });
    } else if (action.name === "Export Details Report") {
      exportSecurityBasedReport("").then((res) => {
        const fileName = `Security Details Report_${companyName}`;
        downloadExcel(res, fileName);
      });
    } else if (action.name === "Export Security Report") {
      exportIndividualSecuritySummaryReport(securityType).then((res) => {
        const fileName = `Security Details Report_${companyName}`;
        downloadExcel(res, fileName);
      });
    }
  }

  const isValidDate = (date: string) =>
    Number.isNaN(date) &&
    new Date(date).toString() !== "Invalid Date" &&
    !Number.isNaN(Date.parse(date));

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      autoHeight: true,
      wrapHeaderText: true,
      suppressColumnVirtualisation: true,
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      filterParams: {
        buttons: ["reset"],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
      flex: 1,
    }),
    []
  );
  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        headerName: "Security Class",
        field: "securityClass",
        filter: "agSetColumnFilter",
        cellRenderer: "securityClass",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipSecurityClass",
        pinned: true,
      },
      {
        headerName: "Date",
        field: "date",
        filter: "agDateColumnFilter",
        filterParams: {
          comparator: (dateFromFilter: Date, cellValue: any) => {
            if (cellValue == null) {
              return 0;
            }
            const dateParts = cellValue.split("-");
            const day = Number(dateParts[2]);
            const month = Number(dateParts[1]) - 1;
            const year = Number(dateParts[0]);
            const cellDate = new Date(year, month, day);
            if (cellDate < dateFromFilter) {
              return -1;
            } else if (cellDate > dateFromFilter) {
              return 1;
            }
            return 0;
          },
        },
        cellRenderer: "date",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
      },
      {
        headerName: "Round",
        field: "rounds",
        cellRenderer: "rounds",
        initialWidth: 250,
        cellStyle: tableCellStyle,
        sortable: false,
        tooltipField: "tooltipNumberOfShares",
        tooltipComponent: RoundsToolTip,
        filter: "agSetColumnFilter",
        menuTabs: ["filterMenuTab"],
        valueGetter: (params) =>
          params.data.rounds?.map((value: any) => value.roundName),
        valueFormatter: (params) =>
          params.data.rounds?.map((value: any) => value.roundName),
      },
      {
        headerName: "Security Type",
        field: "securityType",
        filter: "agSetColumnFilter",
        cellRenderer: "securityType",
        cellStyle: tableCellStyle,
        sortable: false,
        pinned: false,
        menuTabs: ["filterMenuTab"],
      },
      {
        headerName: "Shareholder",
        field: "shareholder",
        cellRenderer: "shareholder",
        cellStyle: tableCellStyle,
        sortable: false,
        filter: false,
        menuTabs: [],
      },
      {
        headerName: "No. of Securities",
        field: "numberOfShares",
        filter: "agNumberColumnFilter",
        cellRenderer: "numberOfShares",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipNumberOfShares",
      },
      {
        headerName: `PAR Value (${currencySymbol})`,
        field: "parValue",
        filter: "agNumberColumnFilter",
        cellRenderer: "parValue",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipParValue",
      },
      {
        headerName: `Amount Invested (${currencySymbol})`,
        field: "amountInvested",
        filter: "agNumberColumnFilter",
        cellRenderer: "amountInvested",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipAmountInvested",
      },
      {
        headerName: `Liquidation Preference (${currencySymbol})`,
        field: "liquidationPreferences",
        filter: "agNumberColumnFilter",
        cellRenderer: "liquidationPreferences",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipLiquidationPreferences",
        tooltipComponent: tooltipLiquidationPreferences,
      },
      {
        headerName: "",
        field: "actions",
        pinned: "right",
        hide: false,
        width: 80,
        maxWidth: 80,
        filter: false,
        menuTabs: [],
        colId: "action-column",
        suppressNavigable: true,
        suppressColumnsToolPanel: true,
        cellStyle: { "padding-top": "12px", "line-height": "16px" },
        resizable: false,
        sortable: false,
        cellRendererParams: ({ value }: { value: any }) => value?.props,
        cellRenderer: CTADropdown, //Dirty commit using params.node.data.securityClass
      },
    ],
    []
  );
  const gridOptions = {
    suppressRowVirtualisation: true, // Add this line to disable row virtualization
    paginationAutoPageSize: false, // Optional: Set to false if using custom pagination
    suppressScrollOnNewData: true, // Optional: Set to true to prevent scrolling to the top on
  };
  const canEditSecurity = useCanUserAccess("update", "instrumentclass");
  const securities: SecurityTableDetail[] = securityList;

  const [dialog, setDialog] = useState<{
    open: boolean;
    message?: string;
    data?: SecurityTableDetail;
    index?: number;
    mode?: "Edit" | "Delete" | "Add" | "Conversion" | "Redemption" | "View";
  }>({
    open: false,
  });

  function handleAction(
    action: Action,
    securityTableData: SecurityTableDetail
  ) {
    const index = securities.findIndex(
      (individualInvestor) => individualInvestor === securityTableData
    );

    if (action.name === "Edit" && !action.disabled) {
      setDialog({
        open: true,
        mode: "Edit",
        data: securityTableData,
        index,
      });
    } else if (action.name === "Add" && !action.disabled) {
      setDialog({
        open: true,
        mode: "Add",
        data: securityTableData,
      });
    } else if (action.name === "Conversion" && !action.disabled) {
      setDialog({
        open: true,
        mode: "Conversion",
        data: securityTableData,
      });
    } else if (action.name === "Redemption" && !action.disabled) {
      setDialog({
        open: true,
        mode: "Redemption",
        data: securityTableData,
      });
    } else if (action.name === "View" && !action.disabled) {
      setDialog({
        open: true,
        mode: "View",
        data: securityTableData,
      });
    }
  }

  const currencyType = getCurrencyType();
  const rowData = useMemo(
    () =>
      securityList?.sort(compareSecurityDate).map((template) => ({
        id: template.id,
        securityClass: template.className,
        tooltipSecurityClass: template.className,
        securityType: template.type,
        date: template.date ?? undefined,
        rounds: template.roundIdentifierDetails,
        shareholder: template.shareHolder,
        numberOfShares: template.noOfShares,
        tooltipNumberOfShares: template.noOfShares.toLocaleString(currencyType),
        parValue: template.parValue,
        tooltipParValue: template.parValue.toLocaleString(currencyType),
        amountInvested: template.amountInvested,
        tooltipAmountInvested:
          template.amountInvested.toLocaleString(currencyType),
        icin: template.icin,
        liquidationPreferences: liquidation?.find(
          (data) => data.securityClassId === template.id
        )?.lpValue,
        investmentMultiple: liquidation?.find(
          (data) => data.securityClassId === template.id
        )?.investmentMultiple,
        isParticipation: liquidation?.find(
          (data) => data.securityClassId === template.id
        )
          ? liquidation?.find((data) => data.securityClassId === template.id)
              ?.isParticipation
          : false,
        participationCap: liquidation?.find(
          (data) => data.securityClassId === template.id
        )?.participationCap,
        tooltipLiquidationPreferences: liquidation
          ?.find((data) => data.securityClassId === template.id)
          ?.lpValue.toLocaleString(currencyType),
        isInvestorPortal,
        currencySymbol,
        currencyType,
        actions: (
          <CTADropdown
            dropdownClassName="bottom-auto"
            actions={[
              {
                name: "View",
                disabled: false,
              },
              {
                name: "Edit",
                disabled: !canEditSecurity || isInvestorPortal,
              },
              {
                name: "Conversion",
                disabled:
                  !convertibleSecurities.includes(template.type) ||
                  !canEditSecurity ||
                  isInvestorPortal,
              },
              {
                name: "Redemption",
                disabled:
                  !redeemableSecurities.includes(template.type) ||
                  !canEditSecurity ||
                  isInvestorPortal,
              },
            ]}
            onAction={(action) => handleAction(action, template)}
          />
        ),
      })),
    [securityList]
  );

  function setTableHeight() {
    if (displayedRowCount === 1) {
      return (displayedRowCount + 2.8) * 60;
    } else if (displayedRowCount === 2) {
      return (displayedRowCount + 2.4) * 60;
    } else if (displayedRowCount === 3) {
      return (displayedRowCount + 3) * 60;
    } else if (displayedRowCount === 4) {
      return (displayedRowCount + 2) * 60;
    } else if (displayedRowCount === 5) {
      return (displayedRowCount + 1.8) * 60;
    } else if (displayedRowCount === 6) {
      return (displayedRowCount + 1.6) * 60;
    } else if (displayedRowCount === 7) {
      return (displayedRowCount + 1.4) * 60;
    } else if (displayedRowCount === 8) {
      return (displayedRowCount + 1.2) * 60;
    } else if (displayedRowCount === 9) {
      return (displayedRowCount + 1) * 60;
    } else {
      return 10 * 60;
    }
  }

  const handleCellClick = (cellParams: CellClickedEvent<any, any>) => {
    if (cellParams.column.getColId() !== "action-column") {
      const template = cellParams.data;
      if (cellParams.column.getColId() === "shareholderName") {
        //Dirty commit onClickShareholderName(template.id);
      }
    }
  };
  const [isColumnOpen, setIsColumnOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const openToolPanel = (key: any) => {
    if (key === "columns") {
      if (gridApi) {
        if (!isColumnOpen) gridApi?.current?.api?.openToolPanel(key);
        else gridApi?.current?.api?.closeToolPanel();
        setIsColumnOpen((state) => !state);
        setIsFilterOpen(false);
      }
    } else if (key === "filters") {
      if (gridApi) {
        if (!isFilterOpen) gridApi?.current?.api?.openToolPanel(key);
        else gridApi?.current?.api?.closeToolPanel();
        setIsFilterOpen((state) => !state);
        setIsColumnOpen(false);
      }
    }
  };

  function setPinnedBottomRowData({ api }: { api: any }) {
    const data = api.rowModel.rootNode;
    const filteredData = data.childrenAfterAggFilter.map(
      (element: any) => element.data
    );
    const totalNumberOfShares = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + data.numberOfShares,
      0
    );

    const totalAmountInvested = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + data.amountInvested,
      0
    );

    api.setPinnedBottomRowData([
      {
        securityClass: "Total",
        securityType: null,
        rounds: null,
        shareholder: null,
        isParticipation: false,
        numberOfShares: totalNumberOfShares,
        tooltipNumberOfShares: totalNumberOfShares.toLocaleString(currencyType),
        parValue: null,
        amountInvested: totalAmountInvested,
        tooltipAmountInvested: totalAmountInvested.toLocaleString(currencyType),
        actions: null,
        currencyType,
      },
    ]);
  }

  const [filteredRowData, setFilteredRowData] = useState<any>([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const onAgGridFilterChanged = (grid: any) => {
    const filtersApplied = grid.api.isAnyFilterPresent();
    setIsFilterApplied(filtersApplied);

    const filteredData = grid.api
      .getModel()
      .rowsToDisplay.map((node: any) => node.data);
    setFilteredRowData(filteredData);
    setPinnedBottomRowData(grid);
    uploadFilterAndColumn();
  };

  return (
    <div className="bg-white border border-borderColor shadow-box">
      <HStack className="flex-col items-start justify-start py-2 bg-white rounded-md lg:flex-row lg:justify-between lg:items-center">
        <GenericTableHeader
          heading={"Security Class Table"}
          subHeading={"Securities"}
          count={
            isFilterApplied === true ? filteredRowData.length : rowData.length
          }
        />
        <HStack className="items-center justify-end gap-4 py-2">
          {/* <QuickSearch
            placeholder={`Search`}
            onChange={(e) => {
              gridApi.current.api.setQuickFilter(e.target.value);
            }}
          /> */}
          <SearchInput
            placeholder={`Search`}
            onChange={(e: any) => {
              gridApi.current.api.setQuickFilter(e.target.value);
            }}
          />
          {!isInvestorPortal && (
            <div>
              {securityType === "All" ? (
                <ExportImport
                  actions={[
                    {
                      name: "Export Summary Report",
                    },
                    {
                      name: "Export Details Report",
                    },
                  ]}
                  onAction={(action) => handleExportAction(action)}
                />
              ) : (
                <ExportImport
                  actions={[
                    {
                      name: "Export Security Report",
                    },
                  ]}
                  onAction={(action) => handleExportAction(action)}
                />
              )}
            </div>
          )}

          <IconCTAButton
            value={"Columns"}
            onClick={() => openToolPanel("columns")}
            iconName={"fluent:column-triple-edit-20-regular"}
            className={`px-4 font-medium items-center flex flex-row ${
              isColumnOpen ? "text-orange-501" : "text-gray-400"
            }`}
          />
          <IconCTAButton
            value={"Filters"}
            onClick={() => openToolPanel("filters")}
            iconName={"material-symbols:filter-alt"}
            className={`px-4 font-medium items-center flex flex-row ${
              isFilterOpen ? "text-orange-501" : "text-gray-400"
            }`}
          />
        </HStack>
      </HStack>
      <Dialog
        open={dialog.open}
        onClose={() => setDialog({ open: false })}
        maxWidth="md"
      >
        {dialog.mode === "Edit" ? (
          <AddNewSecurity
            securityStringId={dialog.data?.id}
            onPrimaryAction={() => {
              setDialog({ open: false });
              setTimeout(() => {
                queryClient.invalidateQueries("getSecurityOverviewDetails");
                queryClient.invalidateQueries(
                  "getLiquidationPreferenceDetails"
                );
                queryClient.invalidateQueries("getShareholderOverview");
              }, 2000);
            }}
            onSecondaryAction={() => setDialog({ open: false })}
            mode="EDIT"
          />
        ) : dialog.mode === "Conversion" && dialog.data ? (
          <AddSecurityConversion
            securityDetails={dialog.data}
            onPrimaryAction={() => setDialog({ open: false })}
            onSecondaryAction={() => setDialog({ open: false })}
          />
        ) : dialog.mode === "Redemption" && dialog.data ? (
          <AddSecurityRedemption
            securityDetails={dialog.data}
            onPrimaryAction={() => setDialog({ open: false })}
            onSecondaryAction={() => setDialog({ open: false })}
          />
        ) : dialog.mode === "View" ? (
          <AddNewSecurity
            securityStringId={dialog.data?.id}
            onPrimaryAction={() => {
              setDialog({ open: false });
            }}
            onSecondaryAction={() => setDialog({ open: false })}
            mode="VIEW"
          />
        ) : (
          <div></div>
        )}
      </Dialog>
      <HStack className="justify-between w-full">
        <Box
          style={{
            height: setTableHeight(),
          }}
          className="w-full h-full max-h-full overflow-x-auto ag-theme-material "
        >
          <AgGridReact
            sideBar={agConfigDashboardSideBar}
            onGridReady={(params) => {
              // Store the grid API referen
              gridApi.current = params;
            }}
            components={componentsRegistery}
            alwaysShowHorizontalScroll
            alwaysMultiSort
            animateRows={true}
            enableCharts={true}
            enableRangeSelection={true}
            defaultColDef={defaultColDef}
            onRowDataUpdated={setPinnedBottomRowData}
            onFilterChanged={onAgGridFilterChanged}
            onColumnEverythingChanged={uploadFilterAndColumn}
            rowData={rowData}
            onCellClicked={handleCellClick}
            columnDefs={columnDefs}
            pagination={false}
            suppressRowTransform={true}
            suppressCopyRowsToClipboard={true}
            suppressCopySingleCellRanges={true}
            suppressCellFocus={true}
            suppressMenuHide={false}
            onColumnResized={uploadFilterAndColumn}
            tooltipShowDelay={1000}
            tooltipInteraction={true}
            rowClass={"border-t border-dashed"}
            overlayNoRowsTemplate={
              '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow; margin-top: 50px;">No Rows To Show</span>'
            }
            gridOptions={gridOptions}
            getRowStyle={(params) => {
              if (params.rowIndex % 2 === 0) {
                return { background: "#f8f8f8" };
              } else {
                return { background: "#ffffff" };
              }
            }}
          ></AgGridReact>
        </Box>
      </HStack>
    </div>
  );
}

export default AGGridSecurityClassTable;
