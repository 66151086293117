import {
  AddSecondaryDistinctShareTransactionType,
  AddTransactionModalType,
} from "../../types/quickRound";
import { AddBuyBackTransactionModel } from "./AddBuybackAgTable";
import {
  NewInstrumentModel,
  NewShareholderModel,
  TransactionSplitInputModel,
} from "./RoundTransactionModel";

export const initialInvestorData: NewShareholderModel = {
  companyId: "",
  name: "",
  email: "",
  pan: "",
  type: "",
  isPromoter: false,
  residence: "India",
};

export const initialInstrumentData: NewInstrumentModel = {
  companyId: "",
  securityName: "",
  securityType: "",
  conversionRatio: 1,
  par: 0,
  valuationCap: 0,
  discount: 0,
};

export const initialShareHolderValues: AddTransactionModalType = {
  uuid: "",
  name: "",
  security: "",
  sharePrice: 0,
  numberOfShares: 0,
  amountInvested: 0,
  investmentCurrency: "",
  exchangeRate: 1,
};

export const initialSecondaryShareHolderValues: AddSecondaryDistinctShareTransactionType =
  {
    uuid: "",
    numberOfShares: 0,
    buyerName: "",
    sellerName: "",
    date: "",
    security: "",
    //dirty commit distinctiveShareNumber: "",
    amountInvested: 0,
    transactionCurrency: "",
    exchangeRate: 1,
  };

export const initialBuybackShareHolderValues: AddBuyBackTransactionModel = {
  uuid: "",
  name: "",
  noOfShares: 0,
  securityClass: "",
  amount: 0,
};
