import { QueryFunctionContext } from "react-query";
import {
  AllTermsheetModel,
  IndividualTermSheetModel,
  IndividualTermsheet,
  TermsheetData,
} from "../types/TermsheetModel";
import api from "./capTableApi";
import { CompanyDetailModel } from "../pages/companyProfile/CompanyProfileModel";
import { CompanyConfig } from "../types/OnBoardDataModel";

export async function getAllTermsheet(): Promise<TermsheetData[]> {
  return api
    .get(`/v1/company/termSheet/termsheets`)
    .then((res) => res.data.data);
}

export async function getIndividualTermsheet(
  context: QueryFunctionContext
): Promise<IndividualTermsheet> {
  const termSheetId = context.queryKey[1];
  return api
    .get(`/v1/company/termSheet/termsheets`, { params: { termSheetId } })
    .then((res) => res.data.data);
}

export async function getAllIndividualTermsheet(
  context: QueryFunctionContext
): Promise<IndividualTermSheetModel> {
  const termSheetId = context.queryKey[1];
  return api
    .get(`/v1/company/termSheet/?termSheetId=${termSheetId}`)
    .then((res) => res.data.data);
}

export async function getCompanyDetails(): Promise<CompanyDetailModel> {
  return api.get(`/v1/company/companyDetail/`).then((res) => res.data.data);
}

export async function createCompanyDetails(formData: any): Promise<any> {
  return api
    .post(
      `/v1/company/companyDetail/save?id=${formData.capitalDetails.companyId}`,
      formData
    )
    .then((res) => res.data);
}

export async function setCompanyConfig(
  configData: CompanyConfig
): Promise<any> {
  return api.post("/v1/company/config", configData).then((res) => res.data);
}
export async function getCompanyConfig(companyId: string): Promise<any> {
  return api
    .get(`/v1/company/config`, { params: { companyId } })
    .then((res) => res.data.data);
}
