import { useQuery } from "react-query";
import { getFinancialData } from "../api/finance";
import { randomDataFinancial } from "./financialPlaceholderData";

export function useGetFinancialData(cId: string) {
  return useQuery({
    queryKey: ["getFinancialData", cId],
    queryFn: getFinancialData,
    placeholderData: randomDataFinancial,
  });
}
