import create from "zustand";
import { QuickBuybackParticipantDetails } from "../types/quickRound";

type BuybackParticipantDetailsState = {
  buybackParticipantDetails: QuickBuybackParticipantDetails[];
  setBuyBackParticipantDetails: (
    buybackParticipantDetails: QuickBuybackParticipantDetails
  ) => void;
  setAllBuyBackParticipantDetails: (
    buybackParticipantDetails: QuickBuybackParticipantDetails[]
  ) => void;
  removeBuyBackParticipantDetails: (
    buybackParticipantDetails: QuickBuybackParticipantDetails
  ) => void;
  reset: () => void;
};

const initialState = {
  buybackParticipantDetails: [],
};

const buybackParticipantDetailsStore = create<BuybackParticipantDetailsState>(
  (set) => ({
    ...initialState,
    setBuyBackParticipantDetails: (buybackParticipant) => {
      set((state) => ({
        buybackParticipantDetails: [
          ...state.buybackParticipantDetails,
          buybackParticipant,
        ],
      }));
    },
    setAllBuyBackParticipantDetails: (buybackParticipantDetails) => {
      set((state) => ({ buybackParticipantDetails }));
    },
    removeBuyBackParticipantDetails: (buybackParticipantDetails) => {
      set((state) => {
        const array = [...state.buybackParticipantDetails]; // make a separate copy of the array
        const index = array.indexOf(buybackParticipantDetails);
        if (index !== -1) {
          array.splice(index, 1);
        }

        return { buybackParticipantDetails: array };
      });
    },
    reset: () => {
      set(() => initialState);
    },
  })
);

export const useQuickBuybackParticipantDetailsStore =
  buybackParticipantDetailsStore;
