import create from "zustand";
import { QuickBonusSecurityModel } from "../types/quickRound";

type BonusSecuritiesState = {
  bonusSecurities: QuickBonusSecurityModel[];
  setBonusSecurities: (bonuSecurities: QuickBonusSecurityModel) => void;
  setAllBonusSecurities: (bonuSecurities: QuickBonusSecurityModel[]) => void;
  removeBonusSecurities: (bonuSecurities: QuickBonusSecurityModel) => void;
  reset: () => void;
};

const initialState = {
  bonusSecurities: [],
};

const bonusSecurityStore = create<BonusSecuritiesState>((set) => ({
  ...initialState,
  setBonusSecurities: (bonusSecurity) => {
    set((state) => ({
      bonusSecurities: [...state.bonusSecurities, bonusSecurity],
    }));
  },
  setAllBonusSecurities: (bonusSecurities) => {
    set((state) => ({ bonusSecurities }));
  },
  removeBonusSecurities: (bonusSecurities) => {
    set((state) => {
      const array = [...state.bonusSecurities]; // make a separate copy of the array
      const index = array.indexOf(bonusSecurities);
      if (index !== -1) {
        array.splice(index, 1);
      }

      return { bonusSecurities: array };
    });
  },
  reset: () => {
    set(() => initialState);
  },
}));

export const useQuickBonusSecurityStore = bonusSecurityStore;
