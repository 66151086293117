/* eslint-disable linebreak-style */
import React from "react";

type InputProps = {
  error?: boolean;
  label?: string;
  helperText?: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

type InputTextAreaWithLabelProps = {
  label?: string;
  errorMessage?: string;
  error?: boolean;
  helperText?: string;
} & React.TextareaHTMLAttributes<HTMLTextAreaElement>;

type CheckBoxWithLabelProps = {
  label: string;
  labelclassname: string;
  errorMessage?: string;
  error?: boolean;
  helperText?: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

export function CheckBoxWithLabel(props: CheckBoxWithLabelProps) {
  const { label, error, errorMessage, ...rest } = props;
  const className = props.className;
  const _labelClass = props.labelclassname;
  return (
    <div className="flex flex-row items-center">
      <input type="checkbox" className={className} {...rest} />
      <label htmlFor={props.id} className={` ${_labelClass}`}>
        {label}
      </label>
      {Boolean(error || errorMessage) && (
        <p className="mt-2 text-sm text-red-600 ">
          <span className="font-medium">Error !</span> {errorMessage}
        </p>
      )}
    </div>
  );
}

export function Input(props: InputProps) {
  const { className: _className, placeholder, ...rest } = props;
  return (
    <input
      {...rest}
      id={props.label}
      className={`form-input w-full leading-4 rounded-md border bg-transparent md:py-2 py-1 pl-2 text-[#4b5675] border-inputBorder focus:border-inputBorderFocus placeholder:text-gray-400 focus:duration-800 sm:text-sm text-xs1 sm:leading-6 mt-1.5 ${
        props.disabled ? "bg-slate-100 cursor-not-allowed" : ""
      } ${_className}`}
      placeholder={placeholder}
      aria-label={props.label}
      aria-describedby={props.helperText}
      onWheel={(e) => e.currentTarget.blur()}
      autoComplete="off"
    ></input>
  );
}

export function TextArea(props: InputTextAreaWithLabelProps) {
  const { className: _className, ...rest } = props;
  const className = `form-input w-full leading-5 rounded-md border bg-transparent py-2 pl-2 text-[#4b5675] border-inputBorder focus:border-inputBorderFocus placeholder:text-gray-400 focus:duration-800 sm:text-sm sm:leading-6 mt-1.5 ${_className} ${
    props.error ? "border-red-500" : ""
  }`;
  return (
    <textarea {...rest} className={className} autoComplete="off"></textarea>
  );
}

export function Required(props: React.HTMLAttributes<HTMLSpanElement>) {
  const { className: _className, ...rest } = props;
  return <span className={`text-rose-400 ${_className}`}>*</span>;
}

export function Label(props: React.LabelHTMLAttributes<HTMLLabelElement>) {
  const { className: _className, ...rest } = props;
  const defaultClass = `block md:text-[13.5px] text-xs1 font-medium items-center leading-5 text-labelColor`;
  return <label {...rest} className={`${_className + defaultClass} `}></label>;
}

export function SubLabel(props: React.LabelHTMLAttributes<HTMLLabelElement>) {
  const { className: _className, ...rest } = props;
  const defaultClass = `block sm:text-xs2 text-xs text-gray-500 font-medium items-center leading-5`;
  return <label {...rest} className={`${_className + defaultClass} `}></label>;
}

export function Description(
  props: React.LabelHTMLAttributes<HTMLLabelElement> & { text: string }
) {
  const { text, className: _className, ...rest } = props;
  const defaultClass = `block md:text-xs2 text-[9px] font-medium text-left md:leading-6 leading-4 text-descriptionColor ${_className}`;
  return (
    <label {...rest} className={`${defaultClass} `}>
      {text}
    </label>
  );
}

export function Helper(props: React.HTMLAttributes<HTMLParagraphElement>) {
  const { className: _className, ...rest } = props;
  const defaultClass = `mt-1 text-xs1 text-[#99A1B7]`;
  return <p className={`${_className + defaultClass} `}></p>;
}
