import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import _ from "lodash";
import { ColDef } from "ag-grid-community";
import { Box, Dialog } from "@mui/material";
import { Icon } from "@iconify/react";
import { AgGridReact } from "ag-grid-react";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { getCurrencySymbol, getCurrencyType } from "../utils/currencyFormatter";
import { tableCellStyle } from "../components/TableComponent";
import { useGetCompanyName, HStack, VStack } from "../components/utils";
import {
  useGetOnFilterState,
  usePostOnFilterState,
} from "../pages/dashboardPage/AgGridCacheQuery";
import { agConfigDashboardSideBar } from "../pages/dashboardPage/AgGridConfig";
import {
  ButtonSize,
  IconCTAButton,
  PrimaryCTAButton,
} from "../pages/quickRound/CTAButtonComponents";
import { useAuthStore } from "../store";
import { useAgGridTableState } from "../store/agGridTableStore";
import { Action, CTADropdown } from "../components/shared/Dropdown";
import {
  PaidUpDateRender,
  PaidUpSharePriceRender,
  ScheduledDateRender,
  ToBePaidRender,
} from "./SchedulePayAgComponent";
import { SchedulePay } from "../types/Shareholder";

function AGGridSchedulePayDetailsTable({
  schedulePayTableData,
  totalCurrentlyPaidUpSharePrice,
  sharePrice,
  gridRefCurrentlyPaid,
  gridRefSchedulePaid,
  numberOfShares,
  setDeletedRows,
  amountInvested,
  setAmountInvested,
}: {
  schedulePayTableData: SchedulePay[];
  totalCurrentlyPaidUpSharePrice: number;
  sharePrice: number;
  gridRefSchedulePaid: any;
  gridRefCurrentlyPaid: any;
  numberOfShares: number;
  setDeletedRows: Function;
  amountInvested: number;
  setAmountInvested: Function;
}) {
  const currencySymbol = getCurrencySymbol();
  const companyId = useAuthStore().companyId ?? "";
  const userId = useAuthStore().user?.userId ?? 0;

  const displayedRowCount =
    gridRefSchedulePaid.current?.api.getDisplayedRowCount();

  const { refetch, data: filterState } = useGetOnFilterState(
    `${userId}`,
    companyId
  );
  const agTableStore = useAgGridTableState();
  useEffect(() => {
    refetch().then((data) => {
      if (data.data) agTableStore.setState(data.data);
      setColumnSetting(data.data?.schedulePayColumnModel);
      setFilterSetting(data.data?.schedulePayFilterModel);
    });
  }, []);

  const { mutate: postOnFilter } = usePostOnFilterState();

  function getColumnSetting() {
    if (gridRefSchedulePaid.current)
      return gridRefSchedulePaid.current.columnApi?.getColumnState();
    return {};
  }

  function setColumnSetting(model: any) {
    if (gridRefSchedulePaid.current)
      gridRefSchedulePaid.current.columnApi.applyColumnState({ state: model });
    return {};
  }

  // Gets filter model via the grid API
  const getFilterSetting = () => {
    if (gridRefSchedulePaid.current)
      return gridRefSchedulePaid.current.api?.getFilterModel();
    return {};
  };

  const setFilterSetting = (model: any) => {
    if (gridRefSchedulePaid.current)
      return gridRefSchedulePaid.current.api?.setFilterModel(model);
    return {};
  };

  const uploadFilterAndColumn = async () => {
    const columnState = await getColumnSetting();
    const filterState = await getFilterSetting();
    postOnFilter({
      userId: `${userId}`,
      companyId,
      filterData: {
        ...agTableStore.state,
        schedulePayColumnModel: columnState,
        schedulePayFilterModel: filterState,
      },
    });
  };

  const componentsRegistery = useMemo(
    () => ({
      paidUpSharePrice: memo(PaidUpSharePriceRender),
      scheduledDate: memo(ScheduledDateRender),
      paidUpDate: memo(PaidUpDateRender),
      toBePaid: memo(ToBePaidRender),
    }),
    []
  );

  const navigate = useNavigate();

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      autoHeight: true,
      wrapHeaderText: true,
      suppressColumnVirtualisation: true,
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      filterParams: {
        buttons: ["reset"],
        maxNumConditions: 5,
      },
      minWidth: 160,
      filter: true,
      resizable: true,
      flex: 1,
      editable: true,
    }),
    []
  );

  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        headerName: `Paid Up Share Price (${currencySymbol})`,
        field: "paidUpSharePrice",
        filter: "agNumberColumnFilter",
        cellRenderer: "paidUpSharePrice",
        sortable: false,
        autoHeight: true,
        cellStyle: tableCellStyle,
        wrapText: true,
        suppressSizeToFit: true,
        menuTabs: ["filterMenuTab"],
      },

      {
        headerName: "Scheduled Date",
        field: "scheduledDate",
        filter: "agDateColumnFilter",
        filterParams: {
          comparator: (dateFromFilter: Date, cellValue: any) => {
            if (cellValue == null) {
              return 0;
            }
            const dateParts = cellValue.split("-");
            const day = Number(dateParts[2]);
            const month = Number(dateParts[1]) - 1;
            const year = Number(dateParts[0]);
            const cellDate = new Date(year, month, day);
            if (cellDate < dateFromFilter) {
              return -1;
            } else if (cellDate > dateFromFilter) {
              return 1;
            }
            return 0;
          },
        },
        cellRenderer: "scheduledDate",
        cellStyle: tableCellStyle,
        sortable: false,
        menuTabs: ["filterMenuTab"],
      },

      {
        headerName: "Paid Up Date",
        field: "paidUpDate",
        filter: "agDateColumnFilter",
        filterParams: {
          comparator: (dateFromFilter: Date, cellValue: any) => {
            if (cellValue == null) {
              return 0;
            }
            const dateParts = cellValue.split("-");
            const day = Number(dateParts[2]);
            const month = Number(dateParts[1]) - 1;
            const year = Number(dateParts[0]);
            const cellDate = new Date(year, month, day);
            if (cellDate < dateFromFilter) {
              return -1;
            } else if (cellDate > dateFromFilter) {
              return 1;
            }
            return 0;
          },
        },
        cellRenderer: "paidUpDate",
        cellStyle: tableCellStyle,
        sortable: false,
        menuTabs: ["filterMenuTab"],
      },

      {
        headerName: `To Be Paid (${currencySymbol})`,
        field: "toBePaid",
        filter: "agNumberColumnFilter",
        cellRenderer: "toBePaid",
        cellStyle: tableCellStyle,
        sortable: false,
        menuTabs: ["filterMenuTab"],
        editable: false,
        suppressAutoSize: true,
      },
      {
        headerName: "",
        field: "actions",
        pinned: "right",
        hide: false,
        width: 80,
        maxWidth: 80,
        filter: false,
        menuTabs: [],
        colId: "action-column",
        suppressNavigable: true,
        suppressColumnsToolPanel: true,
        cellStyle: { "padding-top": "16px", "line-height": "20px" },
        resizable: false,
        sortable: false,
        editable: false,
        cellRendererParams: ({ value }: { value: any }) => value?.props,
        cellRenderer: CTADropdown,
      },
    ],
    []
  );

  function handleModifyAction(
    action: string,
    scheduleDetails: SchedulePay[],
    uuid: string
  ) {
    const deletedData: SchedulePay[] = [];
    const selectedRow = scheduleDetails.find((item) => item.uuid === uuid);
    const totalPaidUpSharePrice = selectedRow!.paidUpSharePrice;
    const updatedTotalPaidUpSharePrice =
      totalPaidUpSharePrice + totalCurrentlyPaidUpSharePrice;
    selectedRow!.toBePaid = numberOfShares * selectedRow!.paidUpSharePrice;

    if (action === "Mark As Paid") {
      if (selectedRow?.paidUpSharePrice === 0) {
        toast("Paid up share price should not be zero.", {
          type: "error",
          autoClose: 2000,
        });
        return;
      }

      if (updatedTotalPaidUpSharePrice > sharePrice) {
        toast.error("Total paid up share price exceeds the share price limit!");
        return;
      }

      if (
        sharePrice * numberOfShares <
        amountInvested + selectedRow!.toBePaid
      ) {
        toast.error("Total paid up share price exceeds the share price limit!");
        return;
      }

      selectedRow!.isPaid = true;

      setAmountInvested(amountInvested + selectedRow!.toBePaid);

      gridRefSchedulePaid.current.api.applyTransaction({
        remove: [selectedRow],
      });

      gridRefCurrentlyPaid.current.api.applyTransaction({
        add: [
          {
            id: selectedRow!.id,
            paidUpSharePrice: selectedRow!.paidUpSharePrice,
            scheduledDate: selectedRow!.scheduledDate,
            paidUpDate:
              selectedRow!.paidUpDate || new Date().toISOString().slice(0, 10),
            totalPaid: selectedRow!.paidUpSharePrice * numberOfShares,
          },
        ],
      });
    } else if (action === "Delete") {
      selectedRow!.isDeleted = true;
      if (gridRefSchedulePaid.current) {
        gridRefSchedulePaid.current.api.forEachNode((node: any) => {
          if (node.data.isDeleted) {
            deletedData.push(node.data);
          }
        });
      }
      gridRefSchedulePaid.current.api.applyTransaction({
        remove: [selectedRow],
      });
      setDeletedRows(deletedData);
    }
  }

  function handleCellValueChanged(event: any) {
    const rowIndex = event.rowIndex;
    const updatedRowData = event.data;

    event.data.toBePaid = event.data.paidUpSharePrice * numberOfShares;
    gridRefSchedulePaid.current.api.applyTransaction({
      update: [{ rowIndex, data: updatedRowData }],
    });
    gridRefSchedulePaid.current.api.refreshCells({ force: true });
  }

  const gridOptions = {
    suppressRowVirtualisation: true,
    paginationAutoPageSize: false,
    suppressScrollOnNewData: true,
    enableCellChangeFlash: true,
  };

  const currencyType = getCurrencyType();

  const rowData = useMemo(
    () =>
      schedulePayTableData?.map((template, rowIndex) => {
        const uuid = uuidv4();
        return {
          uuid,
          id: template.id,
          paidUpSharePrice: template.paidUpSharePrice,
          scheduledDate: template.scheduledDate,
          paidUpDate:
            template.paidUpDate || new Date().toISOString().slice(0, 10),
          toBePaid: template.toBePaid,
          isPaid: template.isPaid,
          isDeleted: template.isDeleted,
          currencySymbol,
          currencyType,
          actions: (
            <CTADropdown
              actions={[
                {
                  name: "Mark As Paid",
                  disabled: false,
                },
                {
                  name: "Delete",
                  disabled: false,
                },
              ]}
              onAction={(action) => {
                const schedulePayTableData: SchedulePay[] = [];
                gridRefSchedulePaid.current?.api.forEachNode((node: any) => {
                  schedulePayTableData.push(node.data);
                });

                handleModifyAction(action.name, schedulePayTableData, uuid);
              }}
            />
          ),
        };
      }),
    [schedulePayTableData]
  );

  const addRow = () => {
    const uuid = uuidv4();
    gridRefSchedulePaid.current.api.applyTransaction({
      add: [
        {
          uuid,
          paidUpSharePrice: 0,
          scheduledDate: new Date().toISOString().slice(0, 10),
          toBePaid: 0,
          paidUpDate: new Date().toISOString().slice(0, 10),
          currencySymbol,
          currencyType,
          actions: (
            <CTADropdown
              actions={[
                {
                  name: "Mark As Paid",
                  disabled: false,
                },
                {
                  name: "Delete",
                  disabled: false,
                },
              ]}
              onAction={(action) => {
                const schedulePayTableData: SchedulePay[] = [];
                gridRefSchedulePaid.current?.api.forEachNode((node: any) => {
                  schedulePayTableData.push(node.data);
                });

                handleModifyAction(action.name, schedulePayTableData, uuid);
              }}
            />
          ),
        },
      ],
    });
  };

  return (
    <>
      <HStack className="flex-col lg:flex-row justify-start lg:justify-between  py-2 lg:items-center items-start  bg-white rounded-md">
        <VStack>
          <div className={`flex flex-col px-6 lg:justify-start `}>
            <p className="text-lg font-medium text-headerColor whitespace-nowrap">
              Schedule Pay
            </p>
          </div>
          <div className="w-full lg:w-auto lg:justify-end py-2 items-center gap-4 px-6 text-gray-400 text-xs">
            Edit the table values by clicking on the cell
          </div>
        </VStack>
      </HStack>
      <HStack className="justify-between w-full">
        <Box
          style={{
            height: (displayedRowCount + 3) * 60,
          }}
          className="w-full max-h-full overflow-x-auto ag-theme-material h-full "
        >
          <AgGridReact
            ref={gridRefSchedulePaid}
            sideBar={agConfigDashboardSideBar}
            onGridReady={(params) => {
              gridRefSchedulePaid.current = params;
            }}
            components={componentsRegistery}
            alwaysShowHorizontalScroll={false}
            alwaysMultiSort
            animateRows={true}
            defaultColDef={defaultColDef}
            onColumnEverythingChanged={uploadFilterAndColumn}
            rowData={rowData}
            onCellValueChanged={(event) => handleCellValueChanged(event)}
            columnDefs={columnDefs}
            pagination={false}
            suppressRowTransform={true}
            suppressCopyRowsToClipboard={true}
            suppressCopySingleCellRanges={true}
            suppressCellFocus={true}
            suppressMenuHide={false}
            onColumnResized={uploadFilterAndColumn}
            tooltipShowDelay={1000}
            tooltipInteraction={true}
            singleClickEdit={true}
            rowClass={"border-t border-dashed"}
            gridOptions={gridOptions}
            suppressAutoSize={true}
            stopEditingWhenCellsLoseFocus={true}
            overlayNoRowsTemplate={
              '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow; margin-top: 50px;">No Rows To Show</span>'
            }
            getRowStyle={(params) => {
              if (params.rowIndex % 2 === 0) {
                return { background: "#f8f8f8" };
              } else {
                return { background: "#ffffff" };
              }
            }}
          ></AgGridReact>
        </Box>
      </HStack>
      <HStack className="justify-start mt-3 px-6">
        <PrimaryCTAButton
          event-name="Add Row Scheduled Pay"
          buttonSize={ButtonSize.SMALL}
          onClick={addRow}
          disabled={amountInvested >= sharePrice * numberOfShares}
        >
          Add Row
        </PrimaryCTAButton>
      </HStack>
    </>
  );
}

export default AGGridSchedulePayDetailsTable;
