import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { Dialog } from "@mui/material";
import { useNavigate, useParams } from "react-router";
import {
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../quickRound/CTAButtonComponents";
import { Box, HStack, VStack } from "../../components/utils";
import { useUploadImportedShareHolderRightsFile } from "../../queries/shareHolderRights";
import { useAuthStore } from "../../store";
import ValidationErrorsDialog from "../../modals/ValidationErrorsDialog";
import { useRightsStore } from "../../store/rightsStore";
import BCHeader from "../../shared/BCHeader";
import FileUploadedUI from "./FileUploadedUI";
import SearchAutoCompleteDropDown from "../../components/shared/SearchAutoCompleteDropDown";
import { SwitchDropDownModel } from "../rounds/RoundsAllTransactions";
import { useInternalSwitchStateStore } from "../../store/searchDropDown";
import { formatDate } from "../../utils/date";
import { useGetAllTransactions } from "../../queries/transactionRound";
import { useGetCompanyDetails } from "../../queries/termsheet";
import { CompanyStages } from "../../store/useRoleGrantStore";

export type FileType = {
  name: string;
  size: number;
  type: string;
};

function AddNewCompanyRights() {
  const { cin } = useParams();
  const [loading, setLoading] = useState(false);
  const { companyId, user: companyStage } = useAuthStore.getState();
  const { data: nodeCompanyDetail } = useGetCompanyDetails();
  const cinNumber = nodeCompanyDetail?.companyDetails.cinNumber;
  const rightsStore = useRightsStore();
  const fileRef1 = useRef() as React.MutableRefObject<HTMLInputElement>;
  const fileRef2 = useRef() as React.MutableRefObject<HTMLInputElement>;
  const [excelFile, setExcelFile] = useState<File>();
  const [aoaFile, setAoaFile] = useState<File>();
  const navigate = useNavigate();
  const [dialog, setDialog] = useState<{
    open: boolean;
    message?: string;
    data?: string[];
  }>({
    open: false,
  });

  const { isPlaceholderData, data: _alltransactions } = useGetAllTransactions();
  const [dropDown, setDropDown] = useState<SwitchDropDownModel[]>([]);
  useEffect(() => {
    const dropDownOptions: SwitchDropDownModel[] = [];
    const allTranactionsData =
      _alltransactions?.map((transaction: any) => {
        if (
          transaction.transactionType.toLocaleLowerCase() !== "conversion" &&
          transaction.transactionType.toLocaleLowerCase() !== "redemption" &&
          transaction.transactionType.toLocaleLowerCase() !== "update_class" &&
          transaction.transactionType.toLocaleLowerCase() !== "esoppool"
        ) {
          const transactionName =
            transaction.transactionType !== "split"
              ? transaction.name
              : transaction.name + transaction.splitRatio;
          dropDownOptions.push({
            name: transactionName,
            type: transaction.transactionType,
            id: transaction.id,
          });
        }

        return {
          ...transaction,
          dateOfTransaction: formatDate(transaction.dateOfTransaction),
        };
      }) || [];
    setDropDown(dropDownOptions);
  }, [_alltransactions]);

  const [selectedTransaction, setSelectedTransaction] =
    useState<SwitchDropDownModel>({ name: "", id: "", type: "" });
  const transactionSwitchStore = useInternalSwitchStateStore();

  const initialValue = {
    name: "",
    size: 0,
    type: "",
  };

  const [excelFileName, setExcelFileName] = useState<FileType>(initialValue);
  const [aoaFileName, setAoaFileName] = useState<FileType>(initialValue);
  const { mutate: uploadShareHolderRightsFile } =
    useUploadImportedShareHolderRightsFile();

  const displayErrors = (errors: string[]) => {
    setDialog({
      open: true,
      data: errors,
    });
  };

  function updateAoa(e: React.ChangeEvent<HTMLInputElement>) {
    if (!e?.target?.files?.[0]) return;
    const file = e.target.files[0];
    const allowedFileExtensions = [".pdf"];
    if (
      allowedFileExtensions.some((extension) => file.name.endsWith(extension))
    ) {
      setAoaFile(file);
      setAoaFileName({
        name: file.name,
        size: file.size,
        type: file.type,
      });
    } else {
      toast(
        `Invalid file type, allowed file types are ${allowedFileExtensions.join(
          ", "
        )}`,
        { type: "error" }
      );
    }
  }

  function updateExcel(e: React.ChangeEvent<HTMLInputElement>) {
    if (!e?.target?.files?.[0]) return;
    const file = e.target.files[0];
    const allowedFileExtensions = [".xlsx"];
    if (
      allowedFileExtensions.some((extension) => file.name.endsWith(extension))
    ) {
      setExcelFile(file);
      setExcelFileName({
        name: file.name,
        size: file.size,
        type: file.type,
      });
    } else {
      toast(
        ` Invalid file type, allowed file types are ${allowedFileExtensions.join(
          ", "
        )}`,
        { type: "error" }
      );
    }
  }

  function handleChange() {
    if (!excelFile && !aoaFile) return;
    const allowedFileExtensions = ["xlsx", "xls", "ods", "pdf"];
    if (excelFile !== undefined && aoaFile !== undefined) {
      if (
        allowedFileExtensions.some((extension) =>
          excelFile.name.endsWith(extension)
        ) &&
        allowedFileExtensions.some((extension) =>
          aoaFile.name.endsWith(extension)
        )
      ) {
        const formData = new FormData();
        formData.append("excelFile", excelFile);
        formData.append("aoaFile", aoaFile);

        formData.append("roundId", selectedTransaction?.id || "");
        setLoading(true);
        uploadShareHolderRightsFile(
          {
            formData,
            companyId: companyId || "",
            cinNumber: cinNumber || "",
            rightsDetailId: "",
          },
          {
            onSuccess: (data) => {
              rightsStore.setRightsId(data);
              setLoading(false);
              toast("Excel Sheet Uploaded Successfully!", {
                type: "success",
                autoClose: 2000,
              });
              navigate("/rights/shareholder");
            },
            onError: (error: {
              response: {
                data: { errorMessage: string; errors: string[] };
              };
            }) => {
              setLoading(false);
              if (
                error.response.data.errors &&
                error.response.data.errors.length > 0
              )
                displayErrors(error.response.data.errors);
              else
                toast(`${error.response.data.errorMessage}`, {
                  type: "error",
                  autoClose: 2000,
                });
            },
          }
        );
      } else {
        setLoading(false);
        toast(
          ` Invalid file type, allowed file types are ${allowedFileExtensions.join(
            ", "
          )}`,
          { type: "error" }
        );
      }
    } else {
      toast(`Please upload both document and select relevant round`, {
        type: "error",
      });
    }
    fileRef1.current.value = "";
    fileRef2.current.value = "";
  }

  const onClickExcelFunction = () => {
    fileRef1.current.click();
  };
  const onClickAoaFunction = () => {
    fileRef2.current.click();
  };
  const onClickExcelDelete = () => {
    fileRef1.current.value = "";
    setExcelFileName(initialValue);
  };
  const onClickAoaDelete = () => {
    fileRef2.current.value = "";
    setAoaFileName(initialValue);
  };

  function getSelectedValue(transaction?: SwitchDropDownModel) {
    setSelectedTransaction(transaction!);
  }

  return (
    <div>
      <div className="min-h-full">
        <BCHeader
          className="items-baseline mb-2"
          bcTitle="Rights And Transfer Restrictions"
          bcSubTitle="Add New AOA"
        />
        <Box className="my-auto bg-white rounded-lg h-full w-full shadow-box">
          <main className="h-full place-items-center px-6 lg:px-8 w-full">
            <div className="text-center">
              <div className="py-32">
                <p className="mt-6 text-base leading-7 text-gray-600">
                  Please upload the AoA and Excel sheet to view your Rights
                </p>
                <div className="mt-10 flex items-center justify-center gap-x-6">
                  <input
                    ref={fileRef1}
                    onChange={updateExcel}
                    multiple={false}
                    type="file"
                    accept=".xlsx"
                    hidden
                  />
                  <input
                    ref={fileRef2}
                    onChange={updateAoa}
                    multiple={false}
                    type="file"
                    accept=".pdf"
                    hidden
                  />
                  <Dialog open={dialog.open} maxWidth="md">
                    <ValidationErrorsDialog
                      errors={dialog.data || []}
                      onPrimaryAction={() => setDialog({ open: false })}
                      onSecondaryAction={() => setDialog({ open: false })}
                    />
                  </Dialog>
                  <VStack className="w-full">
                    <HStack className="w-full gap-4 items-center justify-center">
                      <FileUploadedUI
                        uploadText={"Upload Excel"}
                        fileDetail={excelFileName}
                        callBack={onClickExcelFunction}
                        onClickDelete={onClickExcelDelete}
                      />
                      <p>and</p>
                      <FileUploadedUI
                        uploadText={"Upload Aoa"}
                        fileDetail={aoaFileName}
                        callBack={onClickAoaFunction}
                        onClickDelete={onClickAoaDelete}
                      />
                    </HStack>
                    <HStack className="w-full gap-4 items-center justify-center">
                      {companyStage?.stage !== CompanyStages.THREE && !cin ? (
                        <VStack className="bg-white rounded-md items-center mt-10">
                          <p className="text-sm leading-7 text-gray-600 my-2">
                            Select the Round
                          </p>
                          <Box className="bg-white rounded-md w-48 h-10 shadow-box">
                            <SearchAutoCompleteDropDown
                              data={dropDown as SwitchDropDownModel[]}
                              getSelectedValue={getSelectedValue}
                              selectedOption={
                                selectedTransaction as SwitchDropDownModel
                              }
                              field={"name" as keyof SwitchDropDownModel}
                            />
                          </Box>
                        </VStack>
                      ) : (
                        ""
                      )}
                    </HStack>
                  </VStack>
                </div>
              </div>
              <HStack className="justify-end text-center pb-8 gap-4">
                <SecondaryCTAButton
                  event-name="Back from Add New Rights"
                  onClick={() => {
                    navigate("/rights/overview");
                  }}
                >
                  Back
                </SecondaryCTAButton>
                <PrimaryCTAButton
                  event-name="Uploaded New Rights"
                  className={loading ? "animate-pulse" : ""}
                  onClick={() => {
                    handleChange();
                  }}
                >
                  {!loading && <span>Submit</span>}
                  {loading && (
                    <HStack>
                      <p
                        className="indicator-progress mr-2"
                        style={{ display: "block" }}
                      >
                        Uploading...
                      </p>
                    </HStack>
                  )}
                </PrimaryCTAButton>
              </HStack>
            </div>
          </main>
        </Box>
      </div>
    </div>
  );
}

export default AddNewCompanyRights;
