import { useMutation, useQuery } from "react-query";
import { queryClient } from ".";
import {
  allGetShareHolderRight,
  discardRightsTable,
  downloadShareHolderRightsExcelSheet,
  downloadShareHolderRightsTemplate,
  getAllIndividualShareHolderRight,
  getCaptableShareholderMapingTable,
  updateCaptableShareholderMapingTable,
  uploadImportedShareHolderRightsFile,
  uploadImportedShareHolderRightsTemplate,
} from "../api/shareHolderRights";
import { handleEventForTracking } from "../amplitudeAnalytics";

export function useAllGetShareHolderRight(
  companyId: string | null,
  cinNumber: string | null
) {
  return useQuery({
    queryKey: ["allGetShareHolderRight", companyId, cinNumber],
    queryFn: allGetShareHolderRight,
  });
}

export function useAllIndividualShareHolderRight(
  companyId: string | null,
  shareholderId: string | null,
  cin?: string
) {
  return useQuery({
    queryKey: ["allIndividualShareHolderRight", companyId, shareholderId, cin],
    queryFn: getAllIndividualShareHolderRight,
    enabled: false,
  });
}

export function useShareHolderRightsTemplate() {
  return useQuery({
    queryKey: "shareHolderRightsTemplate",
    queryFn: downloadShareHolderRightsTemplate,
    select: (data) => data.data,
    enabled: false,
  });
}

export function useDownloadShareHolderRightsExcelSheet(url?: string) {
  return useQuery({
    queryKey: ["shareHolderRightsExcel", url],
    queryFn: downloadShareHolderRightsExcelSheet,
    select: (data) => data.data,
    enabled: false,
  });
}

export function useUploadImportedShareHolderRightsTemplate() {
  return useMutation({
    mutationKey: "uploadImportedShareHolderRightsTemplate",
    mutationFn: uploadImportedShareHolderRightsTemplate,
    onSuccess: () => {
      queryClient.invalidateQueries("getShareHolderRight");
      queryClient.refetchQueries("getShareHolderRight");
      handleEventForTracking({
        eventName: "uploadImportedShareHolderRightsTemplate",
        success: true,
        eventType: "API",
      });
    },
    onError: (error: {
      response: { data: { errorMessage: string; errors: string[] } };
    }) => {
      handleEventForTracking({
        eventName: "uploadImportedShareHolderRightsTemplate",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function useUploadImportedShareHolderRightsFile() {
  return useMutation({
    mutationKey: "uploadImportedShareHolderRightsFile",
    mutationFn: uploadImportedShareHolderRightsFile,
    onSuccess: () => {
      handleEventForTracking({
        eventName: "uploadImportedShareHolderRightsFile",
        success: true,
        eventType: "API",
      });
    },
    onError: (error: {
      response: { data: { errorMessage: string; errors: string[] } };
    }) => {
      handleEventForTracking({
        eventName: "uploadImportedShareHolderRightsFile",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function useGetCaptableShareholderMapingTable({
  rightsId,
  companyId,
  cin,
}: {
  rightsId?: string;
  companyId?: string;
  cin?: string;
}) {
  return useQuery({
    queryKey: ["captableShareholderMapingTable", companyId, rightsId, cin],
    queryFn: getCaptableShareholderMapingTable,
  });
}

export function useUpdateCaptableShareholderMapingTable() {
  return useMutation({
    mutationKey: "updateCaptableShareholderMapingTable",
    mutationFn: updateCaptableShareholderMapingTable,
    onSuccess: () => {
      handleEventForTracking({
        eventName: "updateCaptableShareholderMapingTable",
        success: true,
        eventType: "API",
      });
      queryClient.invalidateQueries("allGetShareHolderRight");
      queryClient.resetQueries("allGetShareHolderRight");
    },
    onError: () => {
      handleEventForTracking({
        eventName: "updateCaptableShareholderMapingTable",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function useDiscardRightsTable({
  rightsId,
  companyId,
  cin,
}: {
  rightsId?: string;
  companyId?: string;
  cin?: string;
}) {
  return useQuery({
    queryKey: ["discardRightsTable", rightsId, companyId, cin],
    queryFn: discardRightsTable,
    onSuccess: (data) => {
      queryClient.invalidateQueries("allGetShareHolderRight");
      queryClient.resetQueries("allGetShareHolderRight");
    },
    enabled: false,
  });
}
