import { useFormikContext } from "formik";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { Select } from "../../components/shared/Select";
import { SwitchButton } from "../../components/shared/SwitchButton";
import { HStack, VStack, Error } from "../../components/utils";
import { Label } from "../../components/shared/InputField";
import { EditSecurityModel } from "../../types/SecurityModel";
import { SecurityTextDetail } from "../../constants/SecurityConstantContent";
import SecurityDescriptionText from "./SecurityDescriptionText";
import { classNames } from "../../utils/string";

const AddAntiDilutionDetails = ({
  keepOpen,
  viewOnly,
}: {
  keepOpen: boolean;
  viewOnly: boolean;
}) => {
  const formikk = useFormikContext<EditSecurityModel>();
  const { values, errors, touched, getFieldProps, setFieldValue } = formikk;
  return (
    <Disclosure
      as="div"
      key="Anti-Dilution"
      className=""
      defaultOpen={keepOpen}
    >
      {({ open }) => (
        <>
          <dt
            className={`text-lg border ${
              open ? "border-b-0 rounded-t-md" : "rounded-md"
            }  p-4`}
          >
            <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-400">
              <span className="font-medium text-slate-900 text-base">
                Anti Dilution
              </span>
              <span className="ml-6 flex h-7 items-center">
                <ChevronDownIcon
                  className={classNames(
                    open ? "-rotate-180" : "rotate-0",
                    "h-6 w-6 transform text-slate-500"
                  )}
                  aria-hidden="true"
                />
              </span>
            </Disclosure.Button>
          </dt>
          <Disclosure.Panel as="dd" className="border  p-4 rounded-b-md">
            <VStack className="gap-8">
              <SecurityDescriptionText
                description={SecurityTextDetail.AntiDilution}
              />
              <HStack className="gap-4 md:flex-row flex-col justify-between">
                <VStack className="flex-1 justify-start">
                  <Label className="text-slate-900 text-sm font-medium whitespace-nowrap mr-4">
                    Anti Dilution
                  </Label>
                  <VStack>
                    <Select
                      options={[
                        "Narrow Based Weighted Average",
                        "Broad Based Weighted Average",
                        "Full Ratchet",
                        "No Anti Dilution",
                      ]}
                      {...getFieldProps("antiDilutionType")}
                      disabled={viewOnly}
                    />
                    {touched.antiDilutionType && errors?.antiDilutionType && (
                      <Error text={errors?.antiDilutionType} />
                    )}
                  </VStack>
                </VStack>
                <VStack className="flex-1 justify-start"></VStack>
              </HStack>
            </VStack>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default AddAntiDilutionDetails;
