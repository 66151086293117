import { HStack, VStack } from "../../components/utils";
import { PreCaptableHeaders } from "../../types/Modeling";
import { getCurrencyType } from "../../utils/currencyFormatter";
import {
  FormatNumberSpan,
  getCurrencySymbolRoboto,
} from "../../utils/currencyRoboto";
import { formatDisplayDate } from "../../utils/date";

const RoundModelHeaderDetailCard = ({
  currentCompanyDetail,
}: {
  currentCompanyDetail: PreCaptableHeaders;
}) => {
  const symbol = getCurrencySymbolRoboto();
  const currency = getCurrencyType();
  return (
    <>
      <HStack className="-mx-2 flex flex-wrap justify-between mb-8 gap-4">
        <VStack className="p-2 flex-1 rounded-md shadow-box bg-white border border-borderColor">
          <p className="text-sm text-[#464E5F] font-medium whitespace-nowrap pb-2">
            Amount Raised{" "}
            <span> ({currentCompanyDetail.numberOfRounds} Round)</span>
          </p>
          <HStack className="items-center">
            <span className="pr-1 text-xs font-normal">{symbol}</span>
            <p className="text-lg font-medium">
              <FormatNumberSpan
                value={currentCompanyDetail.moneyRaised}
                format={currency}
              />
            </p>
          </HStack>
        </VStack>
        <VStack className="p-2 flex-1 flex rounded-md shadow-box  bg-white border border-borderColor">
          <p className="text-sm text-[#464E5F] font-medium whitespace-nowrap pb-2">
            Equity Shares
          </p>
          <p className="text-lg font-medium">
            <FormatNumberSpan
              value={currentCompanyDetail.equityShares}
              format={currency}
            />
          </p>
        </VStack>
        <VStack className="p-2 flex flex-1 rounded-md shadow-box  bg-white border border-borderColor">
          <p className="text-sm text-[#464E5F] font-medium whitespace-nowrap pb-2">
            Pref. Shares
          </p>
          <p className="text-lg font-medium">
            <FormatNumberSpan
              value={currentCompanyDetail.prefShares}
              format={currency}
            />
          </p>
        </VStack>

        <VStack className="p-2 flex-1 rounded-md shadow-box bg-white border border-borderColor">
          <p className="text-sm text-[#464E5F] font-medium whitespace-nowrap pb-2">
            Valuation (Post)
          </p>
          <HStack className="items-baseline justify-between">
            <HStack className="items-baseline">
              <p className="pr-1 text-xs font-normal">{symbol}</p>
              <div className="text-lg font-medium">
                <FormatNumberSpan
                  value={currentCompanyDetail.valuation}
                  format={currency}
                />
              </div>
            </HStack>
            <p className="text[#A1A5B7] text-xxs1 whitespace-nowrap">
              {formatDisplayDate(currentCompanyDetail.lastTransactionDate)}
            </p>
          </HStack>
        </VStack>
        <VStack className="p-2 flex-1 rounded-md shadow-box bg-white border border-borderColor">
          <p className="text-sm text-[#464E5F] font-medium whitespace-nowrap pb-2">
            Last Raise
          </p>
          <HStack className="items-baseline justify-between">
            <HStack className="items-baseline">
              <p className="pr-1 text-xs font-normal">{symbol}</p>
              <div className="text-lg font-medium">
                <FormatNumberSpan
                  value={currentCompanyDetail.lastRoundAmountRaised}
                  format={currency}
                />
              </div>
            </HStack>
            <p className="text[#A1A5B7] text-xxs1 whitespace-nowrap">
              {formatDisplayDate(currentCompanyDetail.lastTransactionDate)}
            </p>
          </HStack>
        </VStack>
      </HStack>
    </>
  );
};

export default RoundModelHeaderDetailCard;
