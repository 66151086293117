import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import { HStack, VStack } from "../../components/utils";
import { tableEntryStyle } from "../../components/TableComponent";
import { formatDisplayDate } from "../../utils/date";

export function DateComponent(data: any) {
  return (
    <div className="whitespace-nowrap pt-3">
      {data.value ? (
        <p className={tableEntryStyle}>{formatDisplayDate(data.value)}</p>
      ) : (
        <p className={tableEntryStyle}>-</p>
      )}
    </div>
  );
}

export const ReportsComponent = (data: any) => (
  <HStack className="items-center w-fit rounded-md pb-4">
    {data.data.reportType !== "excel" ? (
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/8/87/PDF_file_icon.svg"
        alt="pdf_icon"
        width={32}
        height={32}
      />
    ) : (
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/3/34/Microsoft_Office_Excel_%282019%E2%80%93present%29.svg"
        alt="excel_icon"
        width={32}
        height={32}
      />
    )}
  </HStack>
);
export const DownloadComponent = (data: any) => (
  <p className="text-blue-600 text-sm font-medium underline pt-2.5 cursor-pointer">
    download
  </p>
);

export const DownloadIconComponent = (data: any) => (
  <div className="text-blue-600  pt-2 cursor-pointer">
    <ArrowDownTrayIcon className="w-6 h-6" />
  </div>
);

export const ReportNameComponent = (data: any) => (
  <VStack>
    <p className="text-labelColor md:text-sm text-xs  font-medium">
      {data.data.reportName}
    </p>
    <p className="text-descriptionColor md:text-xs text-xxs font-medium">
      {data.data.reportDescription}
    </p>
  </VStack>
);
