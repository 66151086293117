import { Icon } from "@iconify/react";
import { useEffect, useState, Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import {
  ArrowLeftOnRectangleIcon,
  ArrowsRightLeftIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  UserCircleIcon,
} from "@heroicons/react/20/solid";
import { useLocation, useMatch, useNavigate } from "react-router";
import * as React from "react";
import { toast } from "react-toastify";
import { useAuthStore, useAuthorizationStore } from "../store";
import { useGetAllUserCompanies, useGetUserContext } from "../queries/auth";
import { useGraphLoaderStore } from "../store/graphLoaderStore";
import { useShareholderInstrumentStore } from "../store/shareholderInstrumentStore";
import { useInternalSwitchStateStore } from "../store/searchDropDown";
import { setUserProperTiesOnFirebase } from "../firebaseAnalytics";
import { AssociatedCompany } from "../types/UserDetailModel";
import { queryClient } from "../queries";
import { HStack } from "../components/utils";
import Avatar from "../pages/Utility/Avatar";
import { Input } from "../components/shared/InputField";
import { useGetUserProfile } from "../queries/userProfile";
import { doLogout } from "../api/Esop";
import { globalFilter } from "../utils/arrays";
import { switchCompanyApi } from "../api/auth";
import { useRoundModelStore } from "../store/roundModelingStore";
import { useSelectedSecurityTypeStoreStore } from "../store/selectedSecurityTypeStore";

export function FlyoutMobileProfile() {
  const [show, setShown] = useState(false);
  const { setAuth, role, user, clear } = useAuthStore();
  const [showCompanies, setShowCompanies] = useState(false);
  const { data: userContext } = useGetUserContext();
  const [loading, setLoading] = useState(false);
  const graphStore = useGraphLoaderStore();
  const shareholderInstrument = useShareholderInstrumentStore();
  const transactionList = useInternalSwitchStateStore();

  const navigate = useNavigate();
  const { data: userProfileDetails } = useGetUserProfile();

  const [searchInput, setSearchInput] = useState("");

  const contextRef = React.useRef<HTMLDivElement>(null);

  const { data: companyData } = useGetAllUserCompanies();

  const companies = React.useMemo(() => {
    if (!companyData) return [];
    const filterResult = globalFilter(companyData, searchInput, [
      "nameOfTheCompany",
      "dba",
    ]);
    return filterResult;
  }, [searchInput, companyData]);
  const profileMatch = useMatch(`/${"/user/profile".split("/")[1]}/*`);
  const roundCreationStore = useRoundModelStore();
  useEffect(() => {
    setUserProperTiesOnFirebase(user?.fullName || "", user?.nameOfTheCompany);
  }, [user, userContext]);
  const securityType = useSelectedSecurityTypeStoreStore();
  const pathname = useLocation().pathname;
  const goBack = () => {
    if (pathname.includes("planView")) {
      navigate("/options/allPlans");
    } else if (pathname.includes("employeeDetails")) {
      navigate("/options/allEmployees");
    } else if (
      pathname.includes("rights/new") ||
      pathname.includes("rights/shareholder")
    ) {
      navigate("/rights/overview");
    } else if (pathname.includes("shareholders/individual")) {
      navigate("/shareholders/overview");
    } else if (pathname.includes("security/individual")) {
      navigate("/security/overview");
    } else if (pathname.includes("addNewSecurity")) {
      securityType.setSecurityType("All");
      navigate("/security/overview");
    } else if (pathname.includes("securityClass")) {
      securityType.setSecurityType("All");
      navigate("/security/overview");
    } else if (pathname.includes("termsheet")) {
      navigate("/termsheet/overview");
    } else if (pathname.includes("rights/overview")) {
      navigate("/transactions/allTransactions");
    } else if (pathname.includes("/modeling")) {
      navigate("/modeling/overview");
    } else if (
      pathname.includes("view-option-buy-back") ||
      pathname.includes("start-buy-back") ||
      pathname.includes("create-option-buyback") ||
      pathname.includes("edit-options-buyback")
    ) {
      navigate("/options/optionBuyback");
    } else if (pathname.includes("add-edit-pool")) {
      navigate("/options/esopOverview");
    } else if (pathname.includes("rounds")) {
      navigate(`/rounds/allTransactions`);
    } else if (pathname.includes("rights/article")) {
      navigate("/rights/shareholder");
    } else if (pathname.includes("transaction"))
      navigate(`/transactions/allTransactions`);
    else if (pathname.includes("financials")) navigate(`/financials/overview`);
  };

  async function switchCompany(company: AssociatedCompany) {
    if (company.nameOfTheCompany === user?.nameOfTheCompany) return;
    graphStore.setIsLoading(true);
    roundCreationStore.disconnect();
    await queryClient.resetQueries("userDetails");
    setLoading(true);
    localStorage.setItem("companyId", company.id.toString());
    setAuth({
      companyId: company.uuid,
    });
    shareholderInstrument.reset();
    roundCreationStore.reset1();
    transactionList.reset();
    switchCompanyApi(company.uuid)
      .then(async (_) => {
        setShowCompanies(false);
        setShown(false);
        queryClient.invalidateQueries();
        queryClient.refetchQueries("userContext");
        toast("Company switched", {
          type: "success",
          autoClose: 2000,
        });
        setLoading(false);
        graphStore.setIsLoading(false);
        goBack();
        window.location.reload();
      })
      .catch(async (err) => {
        queryClient.refetchQueries("userContext");
        toast("Company switched", {
          type: "success",
          autoClose: 2000,
        });
        graphStore.setIsLoading(false);
        setLoading(false);
        window.location.reload();
      });
  }

  const routes = [
    {
      name: "Profile",
      onClick: () => {
        navigate("/user/profile");
        setShown(false);
        setShowCompanies(false);
      },
      icon: UserCircleIcon,
      conponent: <div></div>,
    },
    {
      name: "Switch Company",
      onClick: () => {},
      icon: ArrowsRightLeftIcon,
      component: (
        <div className="w-screen pr-3 max-w-xs flex-auto overflow-hidden text-sm leading-6">
          <div className="group relative flex gap-x-2 w-full rounded-lg">
            {showCompanies && (
              <>
                <ul className="bg-white border my-2 divide-y w-full divide-gray-100 rounded dropdown-menu ">
                  <HStack className="items-center rounded justify-start bg-slate-light text-slate-dark w-full">
                    <Icon icon="fe:search" width="24" />
                    <Input
                      type="text"
                      value={searchInput}
                      className="w-full font-medium border-0 outline-none text-xs2 bg-inherit"
                      placeholder="Search Company"
                      onChange={(e: any) => setSearchInput(e.target.value)}
                    />
                  </HStack>
                  {companies.map((company, index) => (
                    <li
                      onMouseDown={(e) => {
                        e.preventDefault();
                        switchCompany(company);
                      }}
                      key={company.id}
                      className="block px-4 py-2 hover:bg-gray-100 w-full"
                    >
                      <HStack className="items-center justify-between w-full">
                        <button
                          type="button"
                          className={`${
                            company.nameOfTheCompany ===
                              user?.nameOfTheCompany &&
                            "text-gray-300 cursor-not-allowed "
                          } `}
                        >
                          {company.dba !== ""
                            ? `${company.nameOfTheCompany} (${company.dba})`
                            : company.nameOfTheCompany}
                        </button>
                        {company.uuid && (
                          <div className="w-2 h-2 rounded-full bg-green-600"></div>
                        )}
                      </HStack>
                    </li>
                  ))}
                  {companies.length === 0 ? (
                    <ul className="bg-white rounded shadow ">
                      <li className="block px-4 py-2 hover:bg-gray-100">
                        No companies availables
                      </li>
                    </ul>
                  ) : (
                    ""
                  )}
                </ul>
              </>
            )}
          </div>
        </div>
      ),
    },
    {
      name: "Log Out",
      onClick: () => {
        logout();
        setShown(false);
        setShowCompanies(false);
      },
      icon: ArrowLeftOnRectangleIcon,
      component: <div></div>,
    },
  ];
  const { clearAuthorization } = useAuthorizationStore();
  function logout() {
    localStorage.removeItem("accesstoken");
    localStorage.removeItem("currentUser");
    localStorage.removeItem("companyId");
    localStorage.removeItem("hissaUrl");
    queryClient.clear();
    clear();
    clearAuthorization();
    doLogout();
    window.location.href = `${
      process.env.REACT_APP_LEGACY_URL ||
      localStorage.getItem("hissaUrl") ||
      "https://dev.hissa.com"
    }`;
  }

  // useEffect(() => {
  //   const checkIfClickOutside = (e: any) => {
  //     if (!(show && contextRef.current?.contains(e.target))) {
  //       // setShown(false);
  //       // setShowCompanies(false);
  //     }
  //     if (!(showCompanies && contextRef2.current?.contains(e.target))) {
  //       // setShowCompanies(false);
  //       // setShown(false);
  //     }
  //   };
  //   document.addEventListener("mousedown", checkIfClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", checkIfClickOutside);
  //   };
  // }, []);

  return (
    <div>
      {loading ? (
        <div className="items-center px-6 py-2">
          <p className="text-sm animate-pulse font-medium">Switching...</p>
        </div>
      ) : (
        <Popover className="relative">
          <div>
            <Popover.Button
              onClick={() => setShown(!show)}
              onMouseEnter={() => {
                setShown(true);
              }}
              onMouseLeave={() => {
                setShown(false);
                setShowCompanies(false);
              }}
              className="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900 "
            >
              <span className="sr-only">Open user menu</span>
              <Avatar name={userProfileDetails?.fullName || ""} />
              <span className="hidden lg:flex lg:items-center">
                <ChevronDownIcon
                  className="ml-2 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Popover.Button>
          </div>
          <Transition
            show={show}
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel
              onMouseEnter={() => {
                setShown(true);
              }}
              onMouseLeave={() => {
                setShown(false);
                setShowCompanies(false);
              }}
              ref={contextRef}
              className="absolute z-10 mt-5 flex w-screen max-w-max  right-0 "
            >
              <div className="w-screen max-w-xs flex-auto overflow-hidden rounded-lg bg-white text-sm leading-6 shadow-lg">
                <div className=" divide-x divide-gray-900/5 bg-gray-50">
                  <div className="group relative flex gap-x-2 rounded-lg p-4 hover:bg-gray-50">
                    <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                      <Avatar name={userProfileDetails?.fullName || ""} />
                    </div>
                    <div>
                      <a className="font-semibold text-gray-900 whitespace-nowrap">
                        {userProfileDetails?.fullName}
                        <span className="absolute inset-0" />
                      </a>
                      <p className="text-gray-600 whitespace-nowrap">
                        {userProfileDetails?.emailAddress}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="p-2  cursor-pointer">
                  {routes.map((item) => (
                    <>
                      <div
                        key={item.name}
                        onClick={() =>
                          item.name === "Switch Company"
                            ? setShowCompanies(!showCompanies)
                            : item.onClick()
                        }
                        className="group relative flex gap-x-4 rounded-lg p-2 hover:bg-gray-50 items-center"
                      >
                        <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                          <item.icon
                            className={`h-6 w-6 text-gray-600 group-hover:text-primary ${
                              profileMatch && item.name === "Profile"
                                ? "text-primary"
                                : ""
                            }`}
                            aria-hidden="true"
                          />
                        </div>
                        <div>
                          <a
                            className={`font-semibold text-gray-900 group-hover:text-primary ${
                              profileMatch && item.name === "Profile"
                                ? "text-primary"
                                : ""
                            }`}
                          >
                            {item.name}
                            <span className="absolute inset-0" />
                          </a>
                        </div>
                        {item.name === "Switch Company" && (
                          <div>
                            {showCompanies ? (
                              <ChevronUpIcon
                                className="ml-2 h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            ) : (
                              <ChevronDownIcon
                                className="ml-2 h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            )}
                          </div>
                        )}
                      </div>
                      {item.component}
                    </>
                  ))}
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </Popover>
      )}
    </div>
  );
}
