import React from "react";

type SelectInputProps<T> = {
  options: T[];
  error?: boolean;
  placeholder?: string;
  valueGetter?: (option: T) => any;
  textGetter?: (option: T) => string;
} & React.SelectHTMLAttributes<HTMLSelectElement>;

export function Select<T>(props: SelectInputProps<T>) {
  const {
    options,
    value,
    placeholder,
    valueGetter: _valueGetter,
    textGetter: _textGetter,
    className: _className,
    ...rest
  } = props;
  const defaultGetter = (option: any) => option?.toString();
  const valueGetter = _valueGetter || defaultGetter;
  const textGetter = _textGetter || defaultGetter;

  const className = `form-inputSelect w-full leading-5 rounded-md border bg-transparent py-2 pl-2 text-[#4b5675] border-inputBorder focus:border-inputBorderFocus placeholder:text-gray-400 focus:duration-800 sm:text-sm sm:leading-6 mt-1.5 ${
    props.disabled ? "cursor-not-allowed" : ""
  } ${_className} ${props.error ? "border-red-500" : ""}`;
  return (
    <select className={`${className} `} value={value} {...rest}>
      <option className="py-2 bg-white w-64" value={""}>
        {!placeholder ? "--Select--" : placeholder}
      </option>
      {options.map((option, i) => (
        <option className="w-64" key={i} value={valueGetter(option)}>
          {textGetter(option)}
        </option>
      ))}
    </select>
  );
}

export function FilterSelect<T>(props: SelectInputProps<T>) {
  const {
    options,
    value,
    placeholder,
    valueGetter: _valueGetter,
    textGetter: _textGetter,
    className: _className,
    ...rest
  } = props;
  const defaultGetter = (option: any) => option?.toString();
  const valueGetter = _valueGetter || defaultGetter;
  const textGetter = _textGetter || defaultGetter;

  const className = `h-8 px-2 w-32 border border-borderColor leading-2 bg-transparent text-[#4b5675] border-inputBorder focus:border-inputBorderFocus py-0 bg-white rounded-sm  sm:text-sm ${
    props.disabled ? "cursor-not-allowed" : ""
  } ${props.error ? "border-red-500" : ""}`;
  const classnam = `${className} ${_className}`;
  return (
    <select className={`${classnam}`} value={value} {...rest}>
      <option className="py-2 bg-white" value={""}>
        {!placeholder ? "--Select--" : placeholder}
      </option>
      {options.map((option, i) => (
        <option key={i} value={valueGetter(option)}>
          {textGetter(option)}
        </option>
      ))}
    </select>
  );
}

export function SelectWithoutPlaceholder<T>(props: SelectInputProps<T>) {
  const {
    options,
    value,
    placeholder,
    valueGetter: _valueGetter,
    textGetter: _textGetter,
    className: _className,
    ...rest
  } = props;
  const defaultGetter = (option: any) => option?.toString();
  const valueGetter = _valueGetter || defaultGetter;
  const textGetter = _textGetter || defaultGetter;
  return (
    <select
      className={` form-select ${_className} ${
        props.disabled ? "cursor-not-allowed" : ""
      }`}
      value={value}
      {...rest}
    >
      {options.map((option, i) => (
        <option key={i} value={valueGetter(option)}>
          {textGetter(option)}
        </option>
      ))}
    </select>
  );
}
