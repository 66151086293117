export const equityLabelList = [
  "Share Capital",
  "Reserves And Surplus",
  "Money Received Against Share Warrants",
];

export const equityKeyList = [
  "shareCapital",
  "reservesAndSurplus",
  "moneyReceivedAgainstWarrant",
];

export const borrowingLabelList = [
  "Long Term Borrowings",
  "Short Term Borrowings",
  "Unsecured Long",
  "Unsecured Short",
];
export const borrowingKeyList = [
  "longTermBorrowing",
  "shortTermBorrowing",
  "unsecuredLong",
  "unsecuredShort",
];

export const otherLiabilitiesLabelList = [
  "Trade Payables",
  "Provisons",
  "Other Liabilities",
];

export const otherLiabilitiesKeyList = [
  "tradePayables",
  "provisions",
  "otherLiabilities",
];

export const totalLiabilitiesLabelList = ["Total Liabilities"];

export const totalLiabilitiesKeyList = ["totalLiabilities"];

export const fixedAssetsLabelList = ["Tangible Assets", "InTangible Assets"];

export const fixedAssetsKeyList = ["tangibleAsset", "inTangibleAsset"];

export const assetsLabelList = [
  "Capital Work In Progress",
  "Investments",
  "Loans And Advances",
  "Trade Receivables",
  "Cash And Cash Equivalent",
  "Inventories",
  "Others",
  "Total Assets",
];

export const assetsKeyList = [
  "capitalWorkInProgress",
  "investments",
  "loansAndAdvances",
  "tradeReceivables",
  "cashAndCashEquivalents",
  "inventories",
  "otherAssets",
  "totalAssets",
];

export const revenueFromOpertionsLabelList = [
  "Domestic Turnover",
  "Export Turnover",
];

export const revenueFromOpertionsKeyList = [
  "domesticTurnover",
  "exportTurnover",
];

export const otherRevenueLabelList = [" Other Income", "Total Revenue"];
export const otherRevenueKeyList = ["otherIncome", "totalRevenue"];

export const expensesLabelList = [
  "Cost of Materials Consumed",
  "Payroll",
  "Depreciation and Amortization",
  "Others",
  "Finance Costs",
];
export const expensesKeyList = [
  "costOfMaterialsConsumed",
  "employeeBenefitExpense",
  "depreciationAndAmortization",
  "others",
  "financeCosts",
];

export const totalExpensesLabelList = ["Total Expenses", "Profit Before Tax"];
export const totalExpensesKeyList = ["totalExpense", "profitBeforeTax"];

export const taxExpensesLabelList = [
  "Current Tax",
  "Deferred Tax",
  "Total Tax Expense",
];
export const taxExpensesKeyList = [
  "currentTax",
  "deferredTax",
  "totalTaxExpense",
];

export const earningsPerShareLabelList = ["Basic", "Diluted"];
export const earningsPerShareKeyList = ["basic", "diluted"];

export const grossTransactionValueLabelList = [
  "Arms Length",
  "Not Arms Length",
];
export const grossTransactionValueKeyList = [
  "numberOfMaterialContracts",
  "numberOfContracts",
];

export const unsecuredBorrowingsLabelList = ["Long Term", "Short Term"];
export const unsecuredBorrowingsKeyList = [
  "longTermLoansAndAdvances",
  "shortTermLoansAndAdvances",
];

export const badDebtsLabelList = ["Bad Debts"];
export const badDebtsKeyList = ["badDebts"];

export const loansAndAdvancesLabelList = ["Due By Directors", "Others"];

export const provisionsLabelList = ["Unsecured But Good", "Doubtful"];
export const provisionsKeyList = ["provisionsUnsecured", "provisionsDoubtful"];

export const armsLengthHeaders = [
  "Name Of Related Party",
  "Nature Of Relationship",
  "Nature Of Contracts",
  "Duration Of Contracts",
  "Date Of Approval",
  "Amount Paid",
];

export const notArmsLengthHeaders = [
  "Name Of Related Party",
  "Nature Of Relationship",
  "Nature Of Contracts",
  "Duration Of Contracts",
  "Date Of Approval",
  "Amount Paid",
  "Date On Special Resolution",
];

export const keysList = [
  "shareCapital",
  "reservesAndSurplus",
  "moneyReceivedAgainstWarrant",
  "totalEquity",
  "longTermBorrowing",
  "shortTermBorrowing",
  "unsecuredLong",
  "unsecuredShort",
  "totalBorrowings",
  "tradePayables",
  "provisions",
  "otherLiabilities",
  "totalOtherLiabilities",
  "tangibleAsset",
  "inTangibleAsset",
  "totalFixedAssets",
  "totalLiabilities",
  "capitalWorkInProgress",
  "investments",
  "loansAndAdvances",
  "cashAndCashEquivalents",
  "tradeReceivables",
  "inventories",
  "otherAssets",
  "totalAssets",
  "domesticTurnover",
  "exportTurnover",
  "totalRevenueFromOperations",
  "costOfMaterialsConsumed",
  "employeeBenefitExpense",
  "financeCosts",
  "depreciationAndAmortization",
  "others",
  "totalExpenses",
  "currentTax",
  "deferredTax",
  "totalTaxExpense",
  "basic",
  "diluted",
  "profitBeforeTax",
  "profitOrLoss",
  "otherIncome",
  "totalRevenue",
  "currentRatio",
  "quickRatio",
  "cashRatio",
  "debtToEquityRatio",
  "debtToAssetRatio",
  "assetToEquityRatio",
  "netProfitMarginRatio",
  "returnOnEquity",
  "returnOnCapitalEmployed",
  "numberOfMaterialContracts",
  "numberOfContracts",
  "grossTransactionValue",
  "armsLengthNameOfRelatedParty",
  "armsLengthNatureOfRelationship",
  "armsLengthNatureOfContract",
  "armsLengthDurationOfContract",
  "armsLengthDateOfApproval",
  "armsLengthAmountPaid",
  "notArmsLengthNameOfRelatedParty",
  "notArmsLengthNatureOfRelationship",
  "notArmsLengthNatureOfContract",
  "notArmsLengthDurationOfContract",
  "notArmsLengthDateOfApproval",
  "notArmsLengthAmountPaid",
  "notArmsLengthDateOfSpecialResolution",
  "badDebts",
  "longTermLoansAndAdvances",
  "shortTermLoansAndAdvances",
  "totalUnsecuredBorrowings",
  "directorsUnsecured",
  "directorsDoubtful",
  "othersUnsecured",
  "othersDoubtful",
  "provisionsUnsecured",
  "provisionsDoubtful",
  "totalProvisions",
];

export const months = [
  { label: "Jan", value: 1 },
  { label: "Feb", value: 2 },
  { label: "Mar", value: 3 },
  { label: "Apr", value: 4 },
  { label: "May", value: 5 },
  { label: "Jun", value: 6 },
  { label: "Jul", value: 7 },
  { label: "Aug", value: 8 },
  { label: "Sep", value: 9 },
  { label: "Oct", value: 10 },
  { label: "Nov", value: 11 },
  { label: "Dec", value: 12 },
];

export const quarters = [
  { label: "Q1", value: 1 },
  { label: "Q2", value: 2 },
  { label: "Q3", value: 3 },
  { label: "Q4", value: 4 },
];

export const years = [
  { label: "2014-2015", value: 2015 },
  { label: "2015-2016", value: 2016 },
  { label: "2016-2017", value: 2017 },
  { label: "2017-2018", value: 2018 },
  { label: "2018-2019", value: 2019 },
  { label: "2019-2020", value: 2020 },
  { label: "2020-2021", value: 2021 },
  { label: "2021-2022", value: 2022 },
  { label: "2022-2023", value: 2023 },
  { label: "2023-2024", value: 2024 },
];
