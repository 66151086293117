import React, { useEffect, useRef, useState } from "react";
import { Icon } from "@iconify/react";
import { Input } from "./InputField";
import { sort } from "../../utils/arrays";
import { HStack } from "../utils";

function DropDownInput<T>({
  getSelectedValue,
  getEnteredValue,
  className,
  placeholder,
  data,
  selectedOption,
  field,
  width,
  disabled = false,
}: {
  getSelectedValue: (item?: T) => void;
  getEnteredValue?: (item?: string) => void;
  data: T[];
  placeholder?: string;
  className?: string;
  width?: string;
  selectedOption: T;
  field: keyof T;
  disabled?: boolean;
}) {
  const sortedData = sort(data || [], field);
  const [searchText, setSearchText] = useState(
    selectedOption ? selectedOption[field] : ("" as any)
  );
  const [searched, setSearched] = useState<T[]>();

  const [open, setOpen] = useState(false);
  function searchOptions(text: string) {
    const match = sortedData.filter((d) => {
      const regex = new RegExp(`${text}`, "gi");
      return (d[field] as any).match(regex);
    });
    setSearched(match);
  }
  const contextRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const checkIfClickOutside = (e: any) => {
      if (!searchText) setSearchText(selectedOption[field]);
      if (!(open && contextRef.current?.contains(e.target))) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickOutside);
    };
  }, [open, searchText]);
  return (
    <div
      ref={contextRef}
      className={`bg-inherit z-10 absolute w-72 rounded-md`}
    >
      <div className={`relative mt-2 rounded-md shadow-sm ${width} `}>
        <input
          type="text"
          value={searchText}
          placeholder={placeholder ?? "Select"}
          className={`form-input w-full leading-5 rounded-md border bg-transparent py-2 pl-2 text-[#4b5675] border-inputBorder focus:border-inputBorderFocus placeholder:text-gray-400 focus:duration-800 sm:text-sm sm:leading-6 mt-1.5 ${
            disabled ? "bg-slate-100 cursor-not-allowed" : ""
          }`}
          onChange={(e) => {
            if (!e.target.value) setSearched(sortedData);
            setSearchText(e.target.value);
            searchOptions(e.target.value);
            if (getEnteredValue) getEnteredValue(e.target.value);
          }}
          onFocus={() => {
            setOpen(true);
            setSearched(sortedData);
          }}
          // onBlur={() => getSelectedValue(undefined)}
          disabled={disabled}
        />
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          {open ? (
            <Icon
              icon="material-symbols:keyboard-arrow-up-rounded"
              width="24"
              className="cursor-pointer"
              onClick={() => !disabled && setOpen(!open)}
            />
          ) : (
            <Icon
              icon="material-symbols:keyboard-arrow-down-rounded"
              width="24"
              className="cursor-pointer"
              onClick={() => !disabled && setOpen(!open)}
            />
          )}
        </div>
      </div>
      {open && (
        <div
          className={`overflow-auto bg-white border divide-y divide-gray-100 rounded shadow-sm max-h-60 cursor-pointer  ${className}  ${width}`}
        >
          {searched?.map((o, index) => {
            const option = o as unknown as T;
            return (
              <div
                className="flex gap-2 p-2 divide-y rounded "
                key={index}
                onClick={() => {
                  setSearchText(option[field]);
                  getSelectedValue(option);
                  setOpen(!open);
                }}
              >
                {option[field] as unknown as string | number}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default DropDownInput;
