import { QueryFunctionContext } from "react-query";
import {
  SecurityConversionDetails,
  SecurityConversionUpdateDetails,
  SecurityDataModel,
  UpdatedConversionDetails,
  RedemptionDetails,
  ShareholderRedemptionSubmitDetails,
  LiquidationStack,
  TransactionConversionUpdateDetails,
} from "../types/SecurityModel";
import api from "./capTableApi";
import { SecurityClass } from "../pages/securities/NewModel";

export async function getSecuritySummary(
  context: QueryFunctionContext
): Promise<SecurityClass> {
  const securityId = context.queryKey[1];
  return api
    .get(`v1/company/instrumentClass?securityId=${securityId}`)
    .then((res) => res.data.data);
}

export async function getSecurityOverviewDetails(): Promise<SecurityDataModel> {
  return api
    .get(`v1/company/instrumentClass/overview`)
    .then((res) => res.data.data);
}

export async function getLiquidationPreferenceDetails(
  context: QueryFunctionContext
): Promise<LiquidationStack[]> {
  const companyId = context.queryKey[1];
  const token = context.queryKey[2];
  return api
    .get("v1/company/instrumentClass/liquidation")
    .then((res) => res.data.data);
}

export async function getSecurityConversion(
  context: QueryFunctionContext
): Promise<SecurityConversionDetails> {
  const securityClassId = context.queryKey[1];
  return api
    .get("v1/company/instrumentClass/conversion", {
      params: { securityClassId },
    })
    .then((res) => res.data.data);
}

export async function getSecurityRedemption(
  context: QueryFunctionContext
): Promise<RedemptionDetails> {
  const securityClassId = context.queryKey[1];
  return api
    .get("v1/company/instrumentClass/redemption", {
      params: { securityClassId },
    })
    .then((res) => res.data.data);
}

export async function updateSecurityConversionDetails(
  newSecurityDetail: SecurityConversionUpdateDetails
): Promise<any> {
  return api
    .post(`v1/company/instrumentClass/conversion`, newSecurityDetail)
    .then((res) => res.data.data);
}

export async function updateTransactionConversionDetails(
  newTransactionConversionDetail: TransactionConversionUpdateDetails
): Promise<any> {
  return api
    .post(
      `v1/company/instrumentClass/updateConversion`,
      newTransactionConversionDetail
    )
    .then((res) => res.data.data);
}

export async function getUpdatedConversionDetails(
  newSecurityDetail: SecurityConversionUpdateDetails
): Promise<UpdatedConversionDetails> {
  return api
    .post(
      "v1/company/instrumentClass/calculateConversionDetails",
      newSecurityDetail
    )
    .then((res) => res.data.data);
}

export async function updateSecurityRedemptionDetails(
  newSecurityDetail: ShareholderRedemptionSubmitDetails
): Promise<any> {
  return api
    .post(`v1/company/instrumentClass/redemption`, newSecurityDetail)
    .then((res) => res.data.data);
}

export async function submitNewSecurity(newSecurityDetail: any): Promise<any> {
  return api
    .post(`v1/company/instrumentClass/`, newSecurityDetail)
    .then((res) => res.data.data);
}

export async function updateNewSecurity(newSecurityDetail: any): Promise<any> {
  return api
    .put(`v1/company/instrumentClass/`, newSecurityDetail)
    .then((res) => res.data.data);
}
