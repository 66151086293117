import { useMemo, useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { VStack, HStack } from "../../components/utils";
import { useGetInstrumentAndShareHolder } from "../../queries/quickRound";
import {
  useSharedGetIndividualRoundDetail,
  useSharedGetInstrumentAndShareHolder,
  useSharedPricedRoundModel,
} from "../../queries/sharedResouces";
import { useGetOnGoingTransaction } from "../../queries/transactionRound";
import GenericTableHeader from "../../shared/TableHeader";
import { useAuthStore } from "../../store";
import { useRoundModelStore } from "../../store/roundModelingStore";
import { useShareholderInstrumentStore } from "../../store/shareholderInstrumentStore";
import { useShareAuthStore } from "../../store/useShareAuthStore";
import {
  OutstandingConvertible,
  SecondaryAllotmentDetails,
} from "../../types/Modeling";
import AlertMessage from "../newModeling/Alert";
import ModelingHeadersStack from "../newModeling/ModelingHeadersStack";
import ModelSummaryAGGridTable from "../newModeling/ModelSummaryAgTable";
import NewInvestorAGGridTable from "../newModeling/NewInvestorAgTable";
import SecondaryAgTable from "../newModeling/SecondaryAGTable";
import ModelConvertibleAGGridTable from "../newModeling/ModelConvertibleAGGridTable";

function PricedRoundSharedSummaryPage() {
  const { shareId, shareCompanyId, shareResourceId } = useShareAuthStore();

  const { data: pricedRoundModelDetail } = useSharedPricedRoundModel(
    shareResourceId!
  );

  const { isPlaceholderData, data: shareholdersAndInstruments } =
    useSharedGetInstrumentAndShareHolder(
      shareId ?? "",
      shareResourceId ?? "",
      shareCompanyId ?? ""
    );

  const [currentTab, setCurrentTab] = useState("Primary");

  const primaryInvestments = [
    ...(pricedRoundModelDetail?.existingInvestorDetails?.investorDetails ?? []),
    ...(pricedRoundModelDetail?.newInvestorDetails?.investorDetails ?? []),
  ];

  const companyId = useAuthStore.getState().companyId || "";

  const navigate = useNavigate();
  function scrollToTop() {
    const scroller = document.querySelector("#outlet");
    scroller?.scrollTo(0, 0);
  }

  const [secondaryDetails, setSecondaryDetails] = useState<
    SecondaryAllotmentDetails[]
  >([]);

  const [convertibleDetails, setConvertibleDetails] = useState<
    OutstandingConvertible[]
  >([]);

  useEffect(() => {
    const convertibleDetailsList: any[] = [];

    pricedRoundModelDetail?.convertibles?.convertibles.forEach(
      (convertible: any) => {
        const investorList: any[] = [];

        convertible.investors.forEach((investorDetail: any) => {
          investorList.push(investorDetail);
        });

        investorList.forEach((investorDetail) => {
          const investor =
            pricedRoundModelDetail?.convertibles?.convertiblesCaptable?.investors.find(
              (inv: any) => inv.investorId === investorDetail.investorId
            );
          const shareholder = shareholdersAndInstruments?.shareholders.find(
            (shareholder) => shareholder.id === investor?.investorId
          );

          const instrumentClass =
            shareholdersAndInstruments?.instrumentClasses.find(
              (instrument) => instrument.id === convertible.instrumentClassId
            );
          convertibleDetailsList.push({
            id: investor?.investorId || "",
            investorName: investor?.investorName || "",
            securityName: convertible?.securityName || "",
            amountInvested: investor?.investmentAmount || 0,
            fdbShares: investor?.postHoldingFdbShares || 0,
            numberOfShares: investorDetail.postNumberOfShares || 0,
            investorType: shareholder?.type,
            securityType: instrumentClass?.subType,
          });
        });
      }
    );
    setConvertibleDetails(convertibleDetailsList);
  }, [
    pricedRoundModelDetail?.convertibles?.convertibles,
    shareholdersAndInstruments,
  ]);

  useEffect(() => {
    const secondaries: SecondaryAllotmentDetails[] = [];
    pricedRoundModelDetail?.secondaries?.secondaryAllotmentDetails?.forEach(
      (secondary: SecondaryAllotmentDetails) => {
        if (secondary.isBlendedSecondary && secondary) {
          secondary?.sellerDetails?.forEach((element) => {
            const secondariesDetails = {
              id: secondary.id,
              sellerId: element.sellerId,
              buyerId: secondary.buyerId,
              sellerName: element.sellerName,
              buyerName: secondary.buyerName,
              fdbShares: element.fdbShares,
              amountInvested: element.amount,
              sharePrice: secondary.sharePrice,
              secondaryHoldingPercentage: element.holdingPercentage,
              postHoldingPercentage: 0,
              isBlendedSecondary: true,
            };
            secondaries.push(secondariesDetails);
          });
        } else {
          secondaries.push(secondary);
        }
      }
    );
    setSecondaryDetails(secondaries);
  }, [pricedRoundModelDetail?.secondaries?.secondaryAllotmentDetails]);

  return (
    <VStack
      className={`mt-4 ${pricedRoundModelDetail?.modelId ? "" : "loading"}`}
    >
      {pricedRoundModelDetail?.onGoingRoundHeaders &&
      pricedRoundModelDetail?.currentTab !== 8 ? (
        <ModelingHeadersStack
          currentCompanyDetail={pricedRoundModelDetail?.onGoingRoundHeaders}
          roundName={pricedRoundModelDetail?.roundDetailsTab?.roundName ?? ""}
          newPPS={pricedRoundModelDetail?.convertibles?.newPPS}
        />
      ) : (
        <HStack className="w-full h-20 ">
          <div className="flex-1 w-full h-20 placeholderLoader">
            <div className="w-full"></div>
          </div>
        </HStack>
      )}
      {pricedRoundModelDetail?.modelId && (
        <HStack className="flex-col w-full gap-4 mt-4 lg:flex-row">
          <div className="w-full">
            {pricedRoundModelDetail?.currentTab === 6 ? (
              <div className="gap-4">
                {(primaryInvestments.length > 0 ||
                  secondaryDetails.length > 0) && (
                  <div className="bg-white border rounded-md border-borderColor shadow-box ">
                    <HStack className="justify-between py-4">
                      <GenericTableHeader
                        heading={"Transaction"}
                        subHeading={
                          "summary of all the transaction in modelling"
                        }
                        count={undefined}
                      />
                      {secondaryDetails.find(
                        (secondary) =>
                          !secondary.buyerName || !secondary.sellerName
                      ) && (
                        <div className="px-6">
                          <AlertMessage
                            heading="Pending Secondary"
                            data="Generated Captable might be inaccurate due to unassigned secondary"
                          />{" "}
                        </div>
                      )}
                    </HStack>

                    {
                      <HStack className="items-center justify-between px-6 -mb-12 lg:justify-start">
                        <HStack className="w-full gap-4">
                          {primaryInvestments?.length > 0 && (
                            <p
                              aria-current={
                                currentTab === "Primary" ? "page" : undefined
                              }
                              onClick={() => {
                                setCurrentTab("Primary");
                              }}
                              className={`${
                                currentTab === "Primary"
                                  ? "text-primary font-medium border-b border-primary"
                                  : "text-gray-500 font-medium"
                              } text-base cursor-pointer`}
                            >
                              Primary
                            </p>
                          )}
                          {secondaryDetails?.length > 0 && (
                            <p
                              aria-current={
                                currentTab === "Secondary" ? "page" : undefined
                              }
                              onClick={() => {
                                setCurrentTab("Secondary");
                              }}
                              className={`${
                                currentTab === "Primary"
                                  ? " text-gray-500 font-medium"
                                  : "text-primary font-medium border-b border-primary"
                              } text-base  cursor-pointer`}
                            >
                              Secondary
                            </p>
                          )}
                          {convertibleDetails.length > 0 && (
                            <p
                              aria-current={
                                currentTab === "Convertible"
                                  ? "page"
                                  : undefined
                              }
                              onClick={() => {
                                setCurrentTab("Convertible");
                              }}
                              className={`${
                                currentTab === "Convertible"
                                  ? "text-primary font-medium border-b border-primary"
                                  : "text-gray-500 font-medium"
                              } text-base cursor-pointer`}
                            >
                              Convertible
                            </p>
                          )}
                        </HStack>
                      </HStack>
                    }

                    <div className="pt-4">
                      <div className="pt-4">
                        {currentTab === "Primary" && (
                          <NewInvestorAGGridTable
                            data={primaryInvestments ?? []}
                          />
                        )}
                        {currentTab === "Secondary" && (
                          <SecondaryAgTable
                            secondaryDetails={secondaryDetails}
                          />
                        )}
                        {currentTab === "Convertible" && (
                          <ModelConvertibleAGGridTable
                            outstandingConvertibles={convertibleDetails ?? []}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                )}
                <div className="my-8">
                  <ModelSummaryAGGridTable
                    data={
                      pricedRoundModelDetail?.onGoingCaptable?.investors ?? []
                    }
                  />
                </div>
              </div>
            ) : null}
          </div>
        </HStack>
      )}
    </VStack>
  );
}

export default PricedRoundSharedSummaryPage;
