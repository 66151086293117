import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Dialog } from "@mui/material";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { HStack, VStack } from "../../components/utils";
import { useAllGetShareHolderRight } from "../../queries/shareHolderRights";
import RightsSubPage from "./RightsSubPage";
import { useAuthStore } from "../../store";
import { ShareHolderRightsModel } from "../../types/ShareHolderRightsModel";
import { formatDisplayDate } from "../../utils/date";
import {
  ButtonSize,
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../quickRound/CTAButtonComponents";
import BCHeader from "../../shared/BCHeader";
import { useRightsStore } from "../../store/rightsStore";
import EmptyRightsPage from "./EmptyRightsPage";
import { useCanUserAccess } from "../../store/useRoleGrantStore";
import { useAllGetInvShareHolderRight } from "../../queries/investorPortal";
import RightsCard, { NameValuePair } from "./RightsCard";
import LazyPdfDocument from "../../components/shared/LazyPdfDocument";
import RightsSlider, { RightsSliderModel } from "./RightsSlider";
import {
  useGetCompanyConfig,
  useGetCompanyDetails,
} from "../../queries/termsheet";

interface MarkLabel {
  value: number;
  label: string;
}

export default function AllRightsMainPage() {
  const { id, cId, token } = useParams();
  const navigate = useNavigate();
  const canEditRights = useCanUserAccess("update", "rights");
  const companyId = useAuthStore.getState().companyId;
  const { data: nodeCompanyDetail } = useGetCompanyDetails();
  const cin = nodeCompanyDetail?.companyDetails?.cinNumber;
  const { data: configData, isFetched } = useGetCompanyConfig(companyId || "");

  const rightsStore = useRightsStore();
  const [dialog, setDialog] = useState<{
    open: boolean;
    message?: string;
    data?: any;
    index?: number;
    mode?: "view";
  }>({
    open: false,
  });

  const [marksLabels, setMarkLabels] = useState<MarkLabel[]>([]);
  const { refetch: getShareHolderRightsData, data: shareHolderData } = cId
    ? useAllGetInvShareHolderRight(cId, token || "")
    : useAllGetShareHolderRight(companyId || "", cin || "");
  const [shareHolder, setShareholder] = useState<ShareHolderRightsModel[]>(
    shareHolderData ?? []
  );
  useEffect(() => {
    if (shareHolderData) setShareholder(shareHolderData);
  }, [shareHolderData]);

  const [selectedShareholderRights, setSelectedShareholderRights] =
    useState<ShareHolderRightsModel>();
  const [enableEdit, SetEnableEdit] = useState<boolean>(false);
  useEffect(() => {
    if (configData?.isPrivate && selectedShareholderRights?.publicRights) {
      SetEnableEdit(true);
    } else {
      SetEnableEdit(false);
    }
  }, [selectedShareholderRights, isFetched]);
  useEffect(() => {
    getShareHolderRightsData();
  }, []);

  useEffect(() => {
    const markLabel: MarkLabel[] = [];
    if (shareHolder.length > 0) {
      shareHolder.forEach((element, index) => {
        markLabel.push({
          value: index,
          label: formatDisplayDate(element.date),
        });
      });
      const length = shareHolder.length - 1 || 0;
      setMarkLabels(markLabel);
      setSelectedShareholderRights(shareHolder[length]);
      rightsStore.setRightsId(shareHolder[length].rightsId);
    }
  }, [shareHolder]);
  const [count, setCount] = useState<number>(shareHolder.length - 1);

  const updateRange = (countNumber: any) => {
    if (shareHolder) {
      setCount(countNumber);
      setSelectedShareholderRights(shareHolder[countNumber]);
      rightsStore.setRightsId(shareHolder[countNumber].rightsId);
    }
  };

  function handleModifyAction(action: string, data: any) {
    if (action === "view") {
      setDialog({
        open: true,
        mode: "view",
        data,
      });
    }
  }

  function getHeader(): NameValuePair[] {
    const headers: NameValuePair[] = [];
    if (selectedShareholderRights?.roundName)
      headers.push({
        name: "Round Name",
        value: selectedShareholderRights?.roundName || "",
        type: "text",
      });

    if (selectedShareholderRights?.date)
      headers.push({
        name: "Amendment Date",
        value: selectedShareholderRights?.date || "",
        type: "date",
      });

    if (selectedShareholderRights?.aoaUrl)
      headers.push({
        name: "AOA Document",
        value: selectedShareholderRights?.aoaUrl || "",
        type: "document",
      });

    return headers;
  }

  function getRightsList(): RightsSliderModel[] {
    const headers: RightsSliderModel[] = [];
    shareHolderData?.forEach((rights) =>
      headers.push({
        name: rights.roundName || "",
        date: rights.date,
        rightsId: rights.rightsId,
      })
    );
    return headers;
  }

  return (
    <div className=" w-full mb-96">
      <HStack className="justify-between items-center">
        {!cId && shareHolder.length >= 1 && (
          <BCHeader
            className="items-baseline mb-2"
            bcTitle="Transaction"
            bcSubTitle="Rights And Transfer Restrictions"
          />
        )}
        {selectedShareholderRights && (
          <HStack className="items-center  md:flex-row flex-col gap-4">
            <HStack>
              {!cId && canEditRights && (
                <SecondaryCTAButton
                  event-name="Edit Rights"
                  buttonSize={ButtonSize.SMALL}
                  className={`${enableEdit ? "cursor-not-allowed" : ""}`}
                  onClick={() => {
                    if (
                      configData?.isPrivate &&
                      selectedShareholderRights?.publicRights
                    ) {
                      toast("Cannot Edit Public Rights", {
                        type: "error",
                        autoClose: 2000,
                      });
                    } else if (rightsStore.rightsId !== "")
                      navigate("/rights/shareholder");
                  }}
                >
                  Edit Rights
                </SecondaryCTAButton>
              )}
              {!cId && canEditRights && (
                <PrimaryCTAButton
                  event-name="Add New Rights"
                  className="text-xs2 h-8 ml-4 whitespace-nowrap"
                  onClick={() => navigate("/rights/new")}
                >
                  Add New Rights
                </PrimaryCTAButton>
              )}
            </HStack>
          </HStack>
        )}
      </HStack>

      <>
        {dialog.mode === "view" && dialog.data && (
          <Dialog
            open={dialog.open}
            onClose={() => setDialog({ open: false })}
            maxWidth="lg"
          >
            <LazyPdfDocument
              url={dialog?.data}
              onClose={() => {
                setDialog({ open: false });
              }}
            />
          </Dialog>
        )}

        <RightsCard
          handleModifyAction={handleModifyAction}
          rightsCardDetail={getHeader()}
        />

        {shareHolder.length > 1 && (
          <HStack className="justify-center mx-auto w-full pb-4 pt-2 px-8 rounded-md items-center font-semibold border border-gray-100 bg-white mb-4">
            <RightsSlider
              rightsDetails={getRightsList()}
              onChangeRange={(countNumber: any) => {
                updateRange(countNumber);
              }}
            />
          </HStack>
        )}

        {selectedShareholderRights ? (
          <VStack className="px-2 sm:px-4 lg:px-6 bg-white rounded pb-8 py-6">
            <RightsSubPage
              shareholders={selectedShareholderRights?.shareholders || []}
              rightsDataList={
                selectedShareholderRights?.shareholderRights || []
              }
              transferDataList={selectedShareholderRights?.transfer || []}
            />
          </VStack>
        ) : (
          <EmptyRightsPage isInvestorPortal={!cId} />
        )}
      </>
    </div>
  );
}
