import create from "zustand";
import {
  PrimaryTransactionDataModel,
  QuickBonusSecurityModel,
  SecondaryTransactionDataModel,
} from "../types/quickRound";
import {
  NewInstrumentModel,
  NewShareholderModel,
} from "../pages/newQuickTransaction/RoundTransactionModel";
import { BuybackTransactionDataModel } from "../pages/newQuickTransaction/AddBuybackAgTable";

type NewShareholderInstrumentState = {
  shareholder: NewShareholderModel[];
  instrument: NewInstrumentModel[];
  primary: PrimaryTransactionDataModel[];
  secondary: SecondaryTransactionDataModel[];
  buyback: BuybackTransactionDataModel[];
  bonus: QuickBonusSecurityModel[];
  setInstrument: (instrument: NewInstrumentModel[]) => void;
  setShareholder: (shareholder: NewShareholderModel[]) => void;
  setPrimary: (primary: PrimaryTransactionDataModel[]) => void;
  setBuyback: (primary: BuybackTransactionDataModel[]) => void;
  setBonus: (primary: QuickBonusSecurityModel[]) => void;
  setSecondary: (secondary: SecondaryTransactionDataModel[]) => void;
  reset: () => void;
};
const initialState = {
  shareholder: [],
  instrument: [],
  primary: [],
  secondary: [],
  bonus: [],
  buyback: [],
};

const newShareholderInstrumentStore = create<NewShareholderInstrumentState>(
  (set) => ({
    ...initialState,
    setShareholder: (value) => {
      set((state) => ({
        ...state,
        shareholder: value,
      }));
    },
    setBuyback: (value) => {
      set((state) => ({
        ...state,
        buyback: value,
      }));
    },
    setInstrument: (value) => {
      set((state) => ({
        ...state,
        instrument: value,
      }));
    },
    setBonus: (value) => {
      set((state) => ({
        ...state,
        bonus: value,
      }));
    },
    setPrimary: (value) => {
      set((state) => ({
        ...state,
        primary: value,
      }));
    },
    setSecondary: (value) => {
      set((state) => ({
        ...state,
        secondary: value,
      }));
    },
    reset: () => {
      set((state) => ({
        ...state,
        shareholder: [],
        instrument: [],
        primary: [],
        secondary: [],
        bonus: [],
        buyback: [],
      }));
    },
  })
);

export const useNewShareholderInstrumentStore = newShareholderInstrumentStore;
