/* eslint-disable */
import createStore from "zustand";
import { configurePersist } from "zustand-persist";
import { UserDetailModel } from "./../types/UserDetailModel";
import { clearSharedCredentials } from "../components/utils";

const { persist: _persist } = configurePersist({
  storage: localStorage,
  rootKey: "root",
});

const persist = _persist as any;

interface ShareAuth {
  shareId?: string;
  isShareAuthenticated?: boolean;
  shareAccessToken?: string;
  shareResourceId?: string;
  shareCompanyId?: string;
  shareResourceType?: string;
  shareUserMail?: string;
  shareUserId?: string;
}
interface ShareAuthStore extends ShareAuth {
  setShareAuth: (auth: ShareAuth | ((auth: ShareAuth) => ShareAuth)) => void;
  clear: () => void;
}

export const useShareAuthStore = createStore<ShareAuthStore>(
  persist(
    {
      key: "shareAuth",
    },
    (set: any) => ({
      isShareAuthenticated: false,
      shareAccessToken: undefined,
      shareId: undefined,
      shareResourceId: undefined,
      shareCompanyId: undefined,
      shareResourceType: undefined,
      shareUserMail: undefined,
      shareUserId: undefined,
      setShareAuth: (auth: ShareAuth | ((auth: ShareAuth) => ShareAuth)) => {
        if (typeof auth === "function") {
          set((_state: ShareAuth) => auth(_state));
        } else {
          set((_state: ShareAuth) => auth);
        }
      },
      clear: () => {
        set((_state: any) => ({
          isShareAuthenticated: false,
          shareAccessToken: undefined,
          shareId: undefined,
          shareResourceId: undefined,
          shareCompanyId: undefined,
          shareResourceType: undefined,
          shareUserMail: undefined,
          shareUserId: undefined,
        }));
        clearSharedCredentials();
      },
    })
  )
);
