import { useEffect, useState } from "react";
import { Dialog } from "@mui/material";
import { toast } from "react-toastify";
import { Box, HStack, VStack } from "../../components/utils";
import { Label } from "../../components/shared/InputField";
import { ExistingInvestorDetail } from "./RoundCreationModel";
import InvestorTable from "./InvestorTable";
import {
  ButtonSize,
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../quickRound/CTAButtonComponents";
import DeleteModal from "./DeleteModal";
import CreateNewnvestor from "./CreateNewInvestors";
import { useRMCreationStore } from "../../store/roundCreationStore";
import { EmptyTable } from "../quickRound/EmptyTableComponents";
import NewInvestorTable from "./NewInvestorTable";

export default function NewInvestors() {
  const [dialog, setDialog] = useState<{
    open: boolean;
    mode?: string;
    data?: ExistingInvestorDetail;
  }>({
    open: false,
  });
  const {
    roundCreation: data,
    sendMessage: onNext,
    setRoundCreation: setRoundState,
    reset: disCardRoundCreation,
  } = useRMCreationStore();
  useEffect(() => {
    if (data.errors && data.errors.length > 0) {
      for (const error of data.errors) {
        toast(error, {
          type: "error",
          autoClose: 2000,
        });
      }
      setRoundState({
        ...data,
        errors: [],
      });
    }
  }, [data.errors]);
  const updateCurrentTab = (num: number) => {
    onNext({
      ...data,
      currentTab: num,
      isNavigate: true,
    });
  };

  function scrollToTop() {
    const scroller = document.querySelector("#outlet");
    scroller?.scrollTo(0, 0);
  }

  return (
    <VStack className=" bg-white justify-between rounded-lg px-4 py-4 min-h-full">
      <Dialog open={dialog.open} maxWidth="lg">
        {dialog.mode === "Add" && (
          <CreateNewnvestor
            data={dialog.data}
            onBack={() => {
              setDialog({
                open: false,
              });
            }}
            onNext={onNext}
            actionMode="New"
          />
        )}
        {dialog.mode === "Edit" && (
          <CreateNewnvestor
            data={dialog.data}
            onBack={() => {
              setDialog({
                open: false,
              });
            }}
            onNext={onNext}
            actionMode="Edit"
          />
        )}
        {dialog.mode === "Delete" && (
          <DeleteModal
            onPrimaryAction={() => setDialog({ open: false })}
            onSecondaryAction={() => setDialog({ open: false })}
            data={
              "Are you sure you want to delete the entry? Data will be permanently removed. This action cannot be undone."
            }
          />
        )}
      </Dialog>

      <div>
        <Box className="border-b mb-4">
          <HStack className="justify-between">
            <VStack>
              <Label className="text-xl font-semibold text-gray-dark">
                New Investor
              </Label>

              <p className="font-medium text-gray-light">
                Fill in the New Investor Details
              </p>
            </VStack>
            <SecondaryCTAButton
              className="h-8"
              buttonSize={ButtonSize.SMALL}
              onClick={() => {
                setDialog({ open: true, mode: "Add" });
              }}
              disabled={
                data.headers?.leftToRaise !== undefined &&
                data.headers.leftToRaise <= 0
              }
            >
              + Add
            </SecondaryCTAButton>
          </HStack>
        </Box>
        <VStack>
          <NewInvestorTable
            captableData={data.tab4 ? data.tab4.newInvestors : []}
            onNext={(data: any) => {
              onNext(data);
            }}
          />
        </VStack>
      </div>
      <HStack className="justify-between gap-4">
        <HStack className="gap-4">
          <SecondaryCTAButton
            type="reset"
            event-name="New Investor Back"
            buttonSize={ButtonSize.SMALL}
            onClick={() => {
              onNext({ ...data, currentTab: 3 });
              scrollToTop();
            }}
          >
            Back
          </SecondaryCTAButton>
          <SecondaryCTAButton
            event-name="New Investor Discard"
            buttonSize={ButtonSize.SMALL}
            onClick={() => {
              disCardRoundCreation(data);
            }}
          >
            Discard
          </SecondaryCTAButton>
        </HStack>
        <PrimaryCTAButton
          event-name="New Investor Next"
          buttonSize={ButtonSize.SMALL}
          onClick={() => {
            setRoundState({
              ...data,
              isLoader: true,
            });
            onNext({
              ...data,
              currentTab: 5,
              tab5: {
                ongoingSecurityAllotmentDetails: undefined,
                listOfShareHolders: data.tab5?.listOfShareHolders ?? [],
                nonPricedInstruments: data.tab5?.nonPricedInstruments,
                listOfInstruments: data.tab5?.listOfInstruments ?? [],
                newInstruments: data.tab5?.newInstruments ?? [],
                investorDetails: [],
              },
            });
            scrollToTop();
          }}
          loading={data.isLoader}
          className="min-w-max"
          type="submit"
        >
          Next
        </PrimaryCTAButton>
      </HStack>
    </VStack>
  );
}
