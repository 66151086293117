import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";

interface ChartData {
  labels: (string | number)[];
  datasets: {
    label: string;
    data: (string | number)[];
    backgroundColor: string[];
    borderColor: string[];
  }[];
}

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip);

export function SemiDoughnutGraph({
  data,
  options,
  graph,
  text2,
  text3,
  label = "Of Options Used",
}: {
  data: ChartData;
  options: Object;
  graph?: string;
  text2?: string;
  text3?: string;
  label?: string;
}) {
  const percent = data.datasets[0].data[0];
  const plugins = [
    {
      id: "0",
      beforeDraw(chart: ChartJS) {
        const { width } = chart;
        const { height } = chart;
        const { ctx } = chart;
        ctx.restore();
        const fontSize = (height / 80).toFixed(2);
        ctx.font = `22px Inter`;
        ctx.textBaseline = "top";
        const issued = chart.data.datasets[0].data[0] as number;
        const availaible = chart.data.datasets[0].data[1] as number;
        const text =
          graph === "ESOP-Modelling"
            ? `${chart.data.datasets[0].data[0]}%`
            : `${((issued * 100) / (issued + availaible)).toFixed(2)}%`;
        const textX = Math.round((width - ctx.measureText(text).width) / 2);
        const textY = height / 1.8;
        ctx.fillStyle = "#000000";
        ctx.fillText(text, textX, textY);
        ctx.save();
      },
      afterDraw(chart: ChartJS) {
        const { width } = chart;
        const { height } = chart;
        const { ctx } = chart;
        ctx.restore();
        const fontSize = (height / 200).toFixed(2);
        ctx.font = `12px Inter`;
        ctx.textBaseline = "top";
        const textX = Math.round(
          (width - ctx.measureText(text2 || label).width) / 2
        );
        const textY = height / 1.5;
        ctx.fillStyle = "#B5B5C3";
        ctx.fillText(text2 || label, textX, textY + 20);
        ctx.fillText(text3 || "", textX, textY + 35);
        ctx.save();
      },
    },
  ];
  return (
    <div>
      {percent && (
        <Doughnut
          redraw={false}
          options={options}
          data={data}
          plugins={plugins}
        />
      )}
    </div>
  );
}
