import { Dialog } from "@mui/material";
import { useEffect, useState } from "react";
import AlertDialog from "../../components/shared/AlertDialog";
import { Action, CTADropdown } from "../../components/shared/Dropdown";
import { HStack } from "../../components/utils";
import { QuickBonusSecurityModel } from "../../types/quickRound";
import { getSecurityColor } from "../../utils/historicUtilities";
import { useShareholderInstrumentStore } from "../../store/shareholderInstrumentStore";
import { useQuickBonusSecurityStore } from "../../store/QuickBonusSecuritiesStore";
import AddBonusSecurityDetails from "../../modals/AddBonusSecurityDetails";
import { getCurrencyType } from "../../utils/currencyFormatter";
import { PrimaryCTAButton } from "../quickRound/CTAButtonComponents";
import { EmptyTable } from "../quickRound/EmptyTableComponents";
import TextToolTip from "../shareHolderRights/TextToolTip";

export default function QuickBonusSecurityDetails({
  readOnly = false,
  bonusSecurityDetails,
}: {
  readOnly?: boolean;
  bonusSecurityDetails?: QuickBonusSecurityModel[];
}) {
  const currency = getCurrencyType();
  const instrumentStore = useShareholderInstrumentStore();
  const instruments = instrumentStore.instrumentClasses;

  const bonusSecurityStore = useQuickBonusSecurityStore();

  useEffect(() => {
    if (bonusSecurityDetails && bonusSecurityDetails.length > 0)
      bonusSecurityStore.setAllBonusSecurities(
        bonusSecurityDetails as QuickBonusSecurityModel[]
      );
  }, [bonusSecurityDetails]);

  const [dialog, setDialog] = useState<{
    open: boolean;
    message?: string;
    data?: QuickBonusSecurityModel;
    index?: number;
    mode?: "Edit" | "Delete" | "Add";
  }>({
    open: false,
  });

  const handleAction = (
    action: Action,
    bonusSecurity: QuickBonusSecurityModel,
    index: number
  ) => {
    if (action.name === "Edit" && !action.disabled) {
      bonusSecurityStore.removeBonusSecurities(bonusSecurity);
      setDialog({
        open: true,
        mode: "Edit",
        data: bonusSecurity,
        index: index || 0,
      });
    } else if (action.name === "Delete" && !action.disabled) {
      setDialog({
        open: true,
        mode: "Delete",
        data: bonusSecurity,
        message: `Do you want to delete the bonus transaction?`,
      });
    }
  };

  return (
    <div className="px-7  py-4 border border-borderColor pb-10 mx-4 shadow-box bg-white rounded-md">
      <HStack className="text-lg text-left font-medium border-b py-4 justify-between">
        <p className="mr-12 font-medium text-[#464E5F] text-lg">
          Bonus Security Details
        </p>
        <PrimaryCTAButton
          event-name="Add Bonus Security Details"
          className={readOnly ? `hidden` : ``}
          onClick={() => {
            setDialog({
              open: true,
              mode: "Add",
            });
          }}
        >
          Add Security Details
        </PrimaryCTAButton>
      </HStack>
      <Dialog open={dialog.open} maxWidth="md">
        {dialog.mode === "Delete" ? (
          <AlertDialog
            onClose={() => setDialog({ open: false })}
            open={dialog.open}
            message={dialog.message}
            onPrimaryAction={() => {
              if (dialog?.data) {
                bonusSecurityStore.removeBonusSecurities(
                  dialog?.data as QuickBonusSecurityModel
                );
              }
              setDialog({ open: false });
            }}
            onSecondaryAction={() => setDialog({ open: false })}
          />
        ) : dialog.mode === "Add" ? (
          <AddBonusSecurityDetails
            mode="Add"
            onPrimaryAction={() => {
              setDialog({ open: false });
            }}
            onSecondaryAction={() => {
              setDialog({ open: false });
            }}
          />
        ) : dialog.mode === "Edit" ? (
          <AddBonusSecurityDetails
            mode="Edit"
            initialValue={dialog.data}
            onPrimaryAction={() => {
              setDialog({ open: false });
            }}
            onSecondaryAction={() => {
              setDialog({ open: false });
            }}
          />
        ) : (
          <div></div>
        )}
      </Dialog>
      <div className="inline-block w-full py-2 align-middle mb-4">
        <div className="overflow-x-auto md:rounded-lg mb-4">
          <div>
            <table className="w-full bg-white">
              <thead>
                <tr className="text-[#464E5F]">
                  <th
                    scope="col"
                    className="py-3.5 pr-3 text-left text-sm font-semibold hover:cursor-pointer"
                  >
                    Type
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold hover:cursor-pointer"
                  >
                    Security
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold hover:cursor-pointer"
                  >
                    Investor Name
                  </th>

                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold hover:cursor-pointer"
                  >
                    New Shares
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold hover:cursor-pointer"
                  >
                    Conversion Ratio
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold hover:cursor-pointer"
                  >
                    Post Conversion Security
                  </th>
                </tr>
              </thead>
              <tbody>
                {bonusSecurityStore.bonusSecurities.map(
                  (bonusSecurity, index) => (
                    <tr
                      key={`${bonusSecurity.sourceSecurity}${bonusSecurity.investorName}${bonusSecurity.finalSecurity}}`}
                      className="border-t border-dashed hover:bg-slate-50"
                    >
                      <td className="py-4 pr-3 text-sm">
                        <span
                          className={`inline-flex px-2 ${getSecurityColor(
                            instruments.find(
                              (instrument) =>
                                instrument.name === bonusSecurity.sourceSecurity
                            )?.subType || ""
                          )} text-xs font-semibold leading-5`}
                        >
                          {bonusSecurity.type}
                        </span>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {bonusSecurity.sourceSecurity}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <TextToolTip
                          text={bonusSecurity.investorName}
                          className={"text-gray-900"}
                        />
                      </td>

                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {bonusSecurity.newShares.toLocaleString(currency)}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {`1:${
                          instrumentStore.instrumentClasses.find(
                            (instrument) =>
                              instrument.id ===
                              bonusSecurity.finalConversionInstrumentClassId
                          )?.conversionRatio || 1
                        }`}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {bonusSecurity.finalSecurity}
                      </td>
                      <td className="px-2 py-4 align-top">
                        {!readOnly && (
                          <CTADropdown
                            actions={[
                              {
                                name: "Edit",
                                disabled: false,
                              },
                              {
                                name: "Delete",
                                disabled: false,
                              },
                            ]}
                            onAction={(action) =>
                              handleAction(action, bonusSecurity, index)
                            }
                          />
                        )}
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
            {bonusSecurityStore.bonusSecurities.length === 0 && (
              <EmptyTable
                header="No Bonus Available"
                subHeader="Get started by adding a new Bonus."
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
