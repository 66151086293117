import { InstrumentClass } from "../../types/DashboardModel";

export const extractSecurityDetails = (
  securityId: string,
  instrumentClasses: InstrumentClass[]
) => instrumentClasses.find((element) => element.id === securityId);

export const extractSecurityByName = (
  securityName: string,
  instrumentClasses: InstrumentClass[]
) => instrumentClasses.find((element) => element.name === securityName);
