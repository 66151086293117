import { useState } from "react";
import { VStack } from "../components/utils";
import Tooltip from "../components/shared/Tooltip";
import { limitString } from "../utils/string";
import { formatDisplayDate } from "../utils/date";
import { useRightsStore } from "../store/rightsStore";
import { IndividualRoundsModel } from "../types/AllTransactionModel";
import { useConversionStore } from "../store/conversionStore";

export interface RightsSliderModel {
  name: string;
  date: string;
  rightsId: string;
}
function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export default function ConversionSlider({
  rounds,
  onChangeRange,
}: {
  rounds: IndividualRoundsModel[];
  onChangeRange: (countNumber: any) => void;
}) {
  const conversionStore = useConversionStore();

  return (
    <div aria-label="Progress">
      <ol role="list" className="flex items-center">
        {rounds.map((step, stepIdx) => (
          <li
            key={step.id}
            // onClick={() => {
            //   onChangeRange(step.id);
            //   conversionStore.setIndex(stepIdx);
            //   conversionStore.setEventId(step.id);
            // }}
            className={classNames(
              stepIdx !== rounds.length - 1 ? "pr-8 sm:pr-20" : "",
              "relative"
            )}
          >
            {conversionStore.index > stepIdx ? (
              <>
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="h-0.5 mb-7 w-full bg-[#E85936]" />
                </div>
                <VStack>
                  <a
                    href="#"
                    className="flex mb-1 h-4 w-4 items-center justify-center rounded-full bg-[#E85936] hover:bg-[#E85936]"
                  ></a>
                  {step.name.length > 14 ? (
                    <Tooltip text={step.name}>
                      <p
                        className={
                          "-ml-4 text-xs w-4 h-2 font-medium text-[#464E5F] whitespace-nowrap"
                        }
                      >
                        {limitString(step.name || "", 14)}
                      </p>
                    </Tooltip>
                  ) : (
                    <p
                      className={
                        "-ml-4 text-xs w-4 h-2 font-medium text-[#464E5F] whitespace-nowrap"
                      }
                    >
                      {limitString(step.name || "", 14)}
                    </p>
                  )}

                  <p className="-ml-4 text-xxs w-4  mt-2 h-2 font-medium text-[#464E5F] whitespace-nowrap">
                    {formatDisplayDate(step.dateOfTransaction)}
                  </p>
                </VStack>
              </>
            ) : conversionStore.index === stepIdx ? (
              <>
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="h-0.5 mb-7 w-full bg-gray-200" />
                </div>
                <VStack>
                  <a
                    href="#"
                    className="relative flex mb-1 h-4 w-4 items-center justify-center rounded-full border-2 border-[#E85936] bg-white"
                    aria-current="step"
                  >
                    <span
                      className="h-1 w-1 rounded-full bg-[#E85936]"
                      aria-hidden="true"
                    />
                  </a>
                  {step.name.length > 14 ? (
                    <Tooltip text={step.name}>
                      <p
                        className={
                          "-ml-4 text-xs w-4 h-2 font-medium text-[#E85936] whitespace-nowrap"
                        }
                      >
                        {limitString(step.name || "", 14)}
                      </p>
                    </Tooltip>
                  ) : (
                    <p
                      className={
                        "-ml-4 text-xs w-4 h-2 font-medium text-[#E85936]  whitespace-nowrap"
                      }
                    >
                      {limitString(step.name || "", 14)}
                    </p>
                  )}
                  <p className="-ml-4 text-xxs mt-2 w-4 h-2 font-medium text-[#E85936] whitespace-nowrap">
                    {formatDisplayDate(step.dateOfTransaction)}
                  </p>
                </VStack>
              </>
            ) : (
              <>
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="h-0.5 mb-7  w-full bg-gray-200" />
                </div>
                <VStack>
                  <a
                    href="#"
                    className="group relative  mb-1 flex h-4 w-4 items-center justify-center rounded-full border-2 border-gray-300 bg-white hover:border-gray-400"
                  >
                    <span
                      className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300"
                      aria-hidden="true"
                    />
                  </a>
                  {step.name.length > 14 ? (
                    <Tooltip text={step.name}>
                      <p
                        className={
                          "-ml-4 text-xs w-4 h-2 font-medium text-[#464E5F]  whitespace-nowrap"
                        }
                      >
                        {limitString(step.name || "", 14)}
                      </p>
                    </Tooltip>
                  ) : (
                    <p
                      className={
                        "-ml-4 text-xs w-4 h-2 font-medium text-[#464E5F]  whitespace-nowrap"
                      }
                    >
                      {limitString(step.name || "", 14)}
                    </p>
                  )}
                  <p className="-ml-5 text-xxs  mt-2 w-4 h-2 font-medium text-[#464E5F] whitespace-nowrap">
                    {formatDisplayDate(step.dateOfTransaction)}
                  </p>
                </VStack>
              </>
            )}
          </li>
        ))}
      </ol>
    </div>
  );
}
