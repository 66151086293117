import { useEffect, useRef, useState } from "react";
import zoomPlugin from "chartjs-plugin-zoom";
import { Chart, getElementAtEvent } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  ChartType,
} from "chart.js";
import { NewNonPricedSecurity, RoundCreationModel } from "./RoundCreationModel";
import { Box } from "../../components/utils";
import { limitString } from "../../utils/string";

import { useShareHolderChartStore } from "../../store/shareholderChart";
import { useRMCreationStore } from "../../store/roundCreationStore";
import { useRoundCreationStore } from "../../store/useRoundCreationStore";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
  // zoomPlugin
);

function SecurityChart({ securityData }: { securityData: any }) {
  const roundCreateState = useRMCreationStore();

  const [xNumber, setXNumber] = useState<number[]>([]);

  function generateMixedNumbers(value: number): number[] {
    const mixedNumbers: number[] = [value];
    let factor = 0.2;
    for (let i = 0; i < 5; i++) {
      if (factor <= 0.8) {
        mixedNumbers.push(Math.ceil(value - factor * value));
      }

      // Generate 5 numbers less than the given value
      mixedNumbers.push(Math.ceil(value + factor * value));
      // Generate 5 numbers greater than the given value
      factor += 0.2;
    }
    const sortedNumber = mixedNumbers.sort((a, b) => a - b);

    return sortedNumber;
  }

  useEffect(() => {
    const valuation = generateMixedNumbers(securityData.valuation || 0);
    setXNumber(valuation);
  }, [securityData.valuation]);

  const chartData = [
    {
      label: "Pre Round",
      type: "line" as ChartType,
      data: xNumber.map(
        (element) =>
          calculateNoteHoldings(
            roundCreateState.roundCreation?.tab1?.tab1RoundHeader?.fdbShares ||
              0,
            securityData,
            element
          ).preRoundHolding * 100
      ),
      backgroundColor: "#66C5CC",
      borderColor: "#66C5CC",
      borderWidth: 2,
    },
    {
      label: "Convertible Holders",
      type: "line" as ChartType,
      data: xNumber.map(
        (element) =>
          calculateNoteHoldings(
            roundCreateState.roundCreation?.tab1?.tab1RoundHeader?.fdbShares ||
              0,
            securityData,
            element
          ).convertibleInvestorsHolding * 100
      ),
      backgroundColor: "#F6CF71",
      borderColor: "#F6CF71",
      borderWidth: 2,
    },
    {
      label: "New Investors",
      type: "line" as ChartType,
      data: xNumber.map(
        (element) =>
          calculateNoteHoldings(
            roundCreateState.roundCreation?.tab1?.tab1RoundHeader?.fdbShares ||
              0,
            securityData,
            element
          ).newInvestorHolding * 100
      ),
      backgroundColor: "#F89C74",
      borderColor: "#F89C74",
      borderWidth: 2,
    },
  ];

  const data = {
    labels: xNumber,
    datasets: chartData,
  };

  const chartRef = useRef<ChartJS>(null);

  const onClick = (event: any) => {
    const { current: chart } = chartRef;
    if (chart) {
      const selectedChart = getElementAtEvent(chart, event);

      if (selectedChart[0]) {
        chart.ctx.save();
        chart.update();
      }
    }
  };

  const dashboardShareholderOption = {
    responsive: true,
    maintainAspectRatio: false,
    categoryPercentage: 0.5,
    barPercentage: 0.9,
    borderRadius: 10,
    legend: {
      display: true,
    },
    plugins: {
      tooltip: {
        usePointStyle: true,
        bodyFont: {
          weight: "bold",
          size: 14,
        },
        titleColor: "#464E5F",
        bodyColor: "#464E5F",
        displayColors: false,
        backgroundColor: "#FFFFFF",
        callbacks: {
          title: (context: any) => context[0].label.replace(",", " "),
          label: (tooltipItem: any) => {
            const chartData = tooltipItem.chart.config._config
              .data as unknown as any;
            const label: string[] = [];

            for (let i = 0; i < chartData.datasets.length; i++) {
              const total = (
                chartData.datasets[i].data[tooltipItem.dataIndex] || 0
              ).toFixed(2);
              label.push(
                `${chartData.datasets[i].label.split(" ")[0]}: ${total} %`
              );
            }
            return label.map((item: string) => item);
          },
        },
        legend: {
          display: false,
          position: "bottom" as const,
        },
        title: {
          display: false,
          text: "Chart.js Bar Chart",
        },
      },
    },
    scales: {
      x: {
        display: true,
        grid: {
          display: false,
          offset: true,
        },
        ticks: {
          font: { size: 12 },
          padding: 10,
        },
      },
      y: {
        display: true,

        beginAtZero: true,
        grid: {
          borderDash: [1, 3],
          color: "#e8e8e8",
        },
        ticks: {
          beginAtZero: false,
          min: 0,
          font: { size: 12 },
          callback(value: any, index: any, ticks: any) {
            return `${value}%`;
          },
        },
      },
    },
  };

  return (
    <div>
      <p className="text-base font-medium">Next Round Holding Percentages</p>
      <Box className="w-full h-80 align-center">
        <Chart
          type="line"
          id="chart"
          ref={chartRef}
          onClick={onClick}
          options={dashboardShareholderOption as unknown as any}
          data={data}
        />
      </Box>
    </div>
  );
}

export default SecurityChart;

function calculateNoteHoldings(
  fdbShares: number,
  security: NewNonPricedSecurity,
  valuation: number
) {
  let newPreMoney = 0;
  let postMoney = 0;
  let newPPS = 0;

  const preMoney = valuation || 0;
  const discount = security.discount / 100;
  const postEsop = (security.postEsop || 0) / 100;

  const noteCurrentValue =
    preMoney *
    (Number(security.convertibleAmount || 0) /
      Math.min((1 - discount) * preMoney, security.valuationCap));

  const roundSize = security.roundSize;

  if (security?.discountType === "Pre - Money") {
    postMoney = preMoney + (roundSize || 0) + noteCurrentValue;

    newPreMoney = preMoney;
  } else if (security?.discountType === "Post - Money") {
    postMoney = preMoney + (roundSize || 0);

    newPreMoney = postMoney - (roundSize || 0) - noteCurrentValue;
  } else if (security?.discountType === "Investment Method") {
    postMoney =
      preMoney + (roundSize || 0) + Number(security.convertibleAmount || 0);

    newPreMoney = postMoney - (roundSize || 0) - noteCurrentValue;
  }

  let pps = newPreMoney / (fdbShares || 1);

  newPPS = Math.min(
    pps,
    pps * (1 - discount),
    pps * (security.valuationCap / preMoney)
  );

  let totalFdb =
    (fdbShares || 0) +
    Math.floor((security.convertibleAmount || 0) / newPPS) +
    Math.floor((roundSize || 0) / pps);

  totalFdb = Math.floor(fdbShares / (fdbShares / totalFdb - (postEsop || 0)));

  pps = newPreMoney / ((fdbShares || 1) + totalFdb * (postEsop || 0));

  newPPS = Math.min(
    pps,
    pps * (1 - discount),
    pps * (security.valuationCap / preMoney)
  );

  return {
    preRoundHolding:
      1 -
      Math.floor((roundSize || 0) / pps) / totalFdb -
      Math.floor((security.convertibleAmount || 0) / newPPS) / totalFdb -
      (postEsop || 0),
    newInvestorHolding: Math.floor((roundSize || 0) / pps) / totalFdb,
    convertibleInvestorsHolding:
      Math.floor((security.convertibleAmount || 0) / newPPS) / totalFdb,
  };
}

//Commit function calculateNoteHoldings({
//   security,
// }: {
//   security: NewNonPricedSecurity;
// }) {
//   let convertibleValue = 0;
//   let newPreMoney = 0;
//   let postMoney = 0;
//   if (security.discountType === "Pre - Money") {
//     newPreMoney =
//       (security.valuation || 0) +
//       (security.convertibleAmount || 0);
//     postMoney = 0;
//   } else if (security.discountType === "Post - Money") {
//     newPreMoney = security.valuation || 0;
//     postMoney = (security.valuation || 0) + (security.roundSize || 0);
//   } else if (security.discountType === "Investment Method") {
//     newPreMoney = (security.valuation || 0) + (security.convertibleAmount || 0);
//     postMoney =
//       (security.valuation || 0) +
//       (security.roundSize || 0) +
//       (security.convertibleAmount || 0);
//   }
//   let discountValue =
//     (security.convertibleAmount || 0) /
//     (newPreMoney * (security.discount / 100));
//   let valuationCap =
//     discountValue < security.valuationCap
//       ? discountValue
//       : newPreMoney / security.valuationCap;
//   const newInvestorHolding =
//     (security.roundSize || 0) / (newPreMoney || 0 + (security.roundSize || 0));
//   const convertibleholding =
//     (valuationCap) / (newPreMoney || 0);
//   const preRoundHolding = 1 - newInvestorHolding - convertibleholding;
//   return {
//     preRoundHolding: preRoundHolding,
//     newInvestorHolding: newInvestorHolding,
//     convertibleInvestorsHolding: convertibleholding,
//   };
// }
