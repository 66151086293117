import { useState, useEffect, useMemo } from "react";
import Box from "@mui/material/Box";
import { Icon } from "@iconify/react";
import _ from "lodash";
import { useNavigate } from "react-router";
import { PostConversationCaptable, Tab8 } from "./RoundCreationModel";
import { HStack, VStack } from "../../components/utils";
import { getCurrencyType } from "../../utils/currencyFormatter";
import { globalFilter, sort } from "../../utils/arrays";
import { useSelectedShareholderFilterStore } from "../../store/selectedShareholderTypeStore";
import { EmptyTable } from "../../components/shared/EmptyTable";
import BasicMenu from "../../components/shared/Menu";
import Pagination from "../../components/shared/Pagination";
import {
  THead,
  TableHeader,
  tableEntryStyle,
  tableFirstColStyle,
} from "../../components/TableComponent";
import { FormatNumberSpan } from "../../utils/currencyRoboto";
import { CaptableTag } from "../../components/AllotmentTag";
import Avatar from "../Utility/Avatar";

const AntiDilutionCaptable = ({
  data,
}: {
  data: PostConversationCaptable[];
}) => {
  let _captableData: PostConversationCaptable[] = [
    {
      shareholderId: "2",
      shareholderName: "2",
      shareholderType: "2",
      shareholderGroup: "2",
      amountInvested: 2,
      preHoldingPercentage: 4,
      postHoldingPercentage: 90,
    },
    {
      shareholderId: "1",
      shareholderName: "2",
      shareholderType: "2",
      shareholderGroup: "2",
      amountInvested: 2,
      preHoldingPercentage: 4,
      postHoldingPercentage: 90,
    },
  ];
  // data;

  const shareholderFilter = useSelectedShareholderFilterStore();
  const navigate = useNavigate();
  const currency = getCurrencyType();
  const [globalFilterText, setGlobalFilterText] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [sortField, setSortField] = useState<{
    field: keyof PostConversationCaptable | "" | undefined;
    ascending: boolean;
  }>({ field: "shareholderName", ascending: false });

  _captableData = useMemo(() => {
    try {
      if (!_captableData) return [];
      const filterResult = globalFilter(_captableData, globalFilterText);

      const sortResult = sort(
        filterResult,
        sortField?.field,
        sortField?.ascending
      );
      return sortResult;
    } catch (e) {
      return _captableData;
    }
  }, [_captableData, globalFilterText, sortField]);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return _captableData.slice(firstPageIndex, lastPageIndex);
  }, [_captableData, currentPage, pageSize]);

  useEffect(() => {
    setCurrentPage(1);
  }, [globalFilterText]);

  return (
    <div className="bg-white rounded-lg px-4">
      <HStack className="justify-between">
        <div></div>
        <HStack className="items-center p-2 mr-4 rounded w-72 bg-slate-light text-slate-dark">
          <Icon icon="fe:search" width="24" className="mr-2 " />
          <input
            type="text"
            className="w-full font-medium border-0 outline-none text-xs2 bg-inherit"
            placeholder="Search..."
            value={globalFilterText}
            onChange={(e) => {
              setGlobalFilterText(e.target.value);
            }}
          ></input>
        </HStack>
      </HStack>
      {/* </div> */}
      <div className="flex flex-col mt-8">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="md:rounded-lg">
              <table className="min-w-full bg-white">
                <thead>
                  <TableHeader>
                    <THead
                      fieldName="shareholderName"
                      fieldDisplayName="Name"
                      isFirstFeild={true}
                      selectedFieldName={sortField?.field || ""}
                      isAscending={sortField?.ascending || false}
                      isColumnFilter={false}
                      onChangeSort={() => {
                        setSortField({
                          field: "shareholderName",
                          ascending: !sortField?.ascending,
                        });
                      }}
                    />
                    <THead
                      fieldName="preHoldingPercentage"
                      fieldDisplayName="Pre Holding %"
                      selectedFieldName={sortField?.field || ""}
                      isAscending={sortField?.ascending || false}
                      isColumnFilter={false}
                      onChangeSort={() => {
                        setSortField({
                          field: "preHoldingPercentage",
                          ascending: !sortField?.ascending,
                        });
                      }}
                    />
                    <THead
                      fieldName="postHoldingPercentage"
                      fieldDisplayName="Post Holding %"
                      selectedFieldName={sortField?.field || ""}
                      isAscending={sortField?.ascending || false}
                      isColumnFilter={false}
                      onChangeSort={() => {
                        setSortField({
                          field: "postHoldingPercentage",
                          ascending: !sortField?.ascending,
                        });
                      }}
                    />
                  </TableHeader>
                </thead>
                <tbody>
                  {currentTableData.map(
                    (captable: PostConversationCaptable) => (
                      <tr
                        key={`${captable.shareholderId}`}
                        className="border-t whitespace-nowrap border-dashed hover:bg-slate-50 text-[#030303] text-xs2 font-medium "
                      >
                        <td className="py-4 pr-3 text-sm whitespace-nowrap">
                          <div className="flex gap-4">
                            <div className="flex-shrink-0 w-10 h-10">
                              <Avatar name={captable.shareholderName || ""} />
                            </div>
                            <div className="cursor-pointer">
                              <p
                                data-event-name="captableChartShareholderDrilling"
                                className={tableFirstColStyle}
                              >
                                {captable.shareholderName || "Others"}
                              </p>
                              <div
                                navigation-name="Shareholder Type"
                                onClick={() => {
                                  shareholderFilter.setShareholderType(
                                    captable.shareholderType ?? "Others"
                                  );
                                  navigate("/shareholders/overview");
                                }}
                              >
                                <CaptableTag
                                  tagName={captable.shareholderType ?? "Others"}
                                  dataEventName={
                                    "captableShareholderTypeDrilling"
                                  }
                                />
                                v
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="px-3 py-4 whitespace-nowrap">
                          <VStack className="items-center">
                            <p className={tableEntryStyle}>
                              {(
                                captable.preHoldingPercentage * 100
                              ).toLocaleString()}
                            </p>
                          </VStack>
                        </td>
                        <td className="px-3 py-4 whitespace-nowrap">
                          <VStack className="items-center">
                            <p className={tableEntryStyle}>
                              {(
                                captable.postHoldingPercentage * 100
                              ).toLocaleString()}
                            </p>
                          </VStack>
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
              {currentTableData.length === 0 && (
                <EmptyTable
                  header="No Transaction Entry Found"
                  subHeader="Try Changing Filter and Check Again"
                />
              )}
            </div>
          </div>
        </div>
        <Box className="flex justify-between my-4 ">
          {_captableData.length >= 5 && (
            <BasicMenu
              defaultValue={pageSize}
              options={[5, 10, 20, 50, 100]}
              onOptionChange={(value) => {
                setCurrentPage(1);
                setPageSize(value);
              }}
            />
          )}
          <Pagination
            currentPage={currentPage}
            totalCount={_captableData?.length}
            pageSize={pageSize}
            onPageChange={(page: number) => setCurrentPage(page)}
          />
        </Box>
      </div>
    </div>
  );
};

export default AntiDilutionCaptable;
