import create from "zustand";

export type RightsMultiSelectModel = {
  name: string;
  isVisible: boolean;
  isDisabled: boolean;
};

type ShareholderRightsState = {
  conditionalType: RightsMultiSelectModel[];
  setConditionalType: (index: RightsMultiSelectModel[]) => void;
  investorType: RightsMultiSelectModel[];
  setInvestorType: (index: RightsMultiSelectModel[]) => void;
  rightsType: RightsMultiSelectModel[];
  setRightsType: (index: RightsMultiSelectModel[]) => void;
  rightsTypeOptions: RightsMultiSelectModel[];
  setRightsOptionsType: (index: RightsMultiSelectModel[]) => void;
  reset: () => void;
};

const initialState = {
  conditionalType: [],
  investorType: [],
  rightsType: [],
  rightsTypeOptions: [],
};

const shareholderRightsStore = create<ShareholderRightsState>((set) => ({
  ...initialState,
  setConditionalType: (value) => {
    set((state) => ({ conditionalType: value }));
  },
  setInvestorType: (value) => {
    set((state) => ({ investorType: value }));
  },
  setRightsType: (value) => {
    set((state) => ({ rightsType: value }));
  },
  setRightsOptionsType: (value) => {
    set((state) => ({ rightsTypeOptions: value }));
  },
  reset: () => {
    set(() => initialState);
  },
}));

export const useShareHolderFilterTypeStore = shareholderRightsStore;
