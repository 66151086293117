import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import ReactDOMServer from "react-dom/server";

import {
  CellClickedEvent,
  ColDef,
  GridApi,
  SideBarDef,
} from "ag-grid-community";
import { useNavigate } from "react-router";
import { AgGridReact } from "ag-grid-react";
import { Dialog } from "@mui/material";
import { toast } from "react-toastify";
import AddPrimaryPopup from "./AddPrimaryPopup";
import { Action, CTADropdown } from "../../components/shared/Dropdown";
import { agConfigDashboardSideBar } from "../dashboardPage/AgGridConfig";
import {
  getCurrencySymbol,
  getCurrencyType,
} from "../../utils/currencyFormatter";

import { Box, HStack, useGetCompanyName } from "../../components/utils";

import { useAuthStore } from "../../store";
import {
  useGetOnFilterState,
  usePostOnFilterState,
} from "../dashboardPage/AgGridCacheQuery";
import { tableCellStyle } from "../../components/TableComponent";

import { useAgGridTableState } from "../../store/agGridTableStore";

import { useNewShareholderInstrumentStore } from "../../store/newShareholderAndInstrumentStore";
import { PrimaryTransactionDataModel } from "../../types/quickRound";

import { EmptyTable } from "../../components/shared/EmptyTable";
import { handleEventForTracking } from "../../amplitudeAnalytics";

import { useShareholderInstrumentStore } from "../../store/shareholderInstrumentStore";
import {
  SecurityNameRender,
  SecurityTypeRender,
  FDBSharesRender,
  InvestorNameRender,
  NumberOfSharesRender,
  AmountRender,
} from "./OutstandingConvertibleAgComponent";
import { OutstandingConvertible } from "../../types/Modeling";
import { useRoundModelStore } from "../../store/roundModelingStore";

function AgGridOutstandingConvertibleTable({
  outstandingConvertibles,
}: {
  outstandingConvertibles: OutstandingConvertible[];
}) {
  const currencySymbol = getCurrencySymbol();
  const companyName = useGetCompanyName();
  const companyId = useAuthStore().companyId ?? "";
  const userId = useAuthStore().user?.userId ?? 0;
  const gridApi = useRef<any>(null);
  const shareholderAndInstrumentstore = useShareholderInstrumentStore();

  const { roundCreation: roundModel } = useRoundModelStore();

  const [displayedRowCount, setDisplayedRowCount] = useState<number>(0);

  const primaryTransactionStore = useNewShareholderInstrumentStore();

  useEffect(() => {
    const count = gridApi.current?.api.getDisplayedRowCount();

    if (count !== undefined) {
      setDisplayedRowCount(count);
    }
  }, [primaryTransactionStore.primary]);

  const [addDialog, setAddDialog] = useState<{
    open: boolean;
    name?: string;
    data?: any;
  }>({
    open: false,
  });

  const { refetch, data: filterState } = useGetOnFilterState(
    `${userId}`,
    companyId
  );
  const agTableStore = useAgGridTableState();
  useEffect(() => {
    refetch().then((data) => {
      if (data.data) {
        agTableStore.setState(data.data);
        setColumnSetting(data.data?.quickPrimaryColumnModel);
        setFilterSetting(data.data?.quickPrimaryFilterModel);
      }
    });
  }, []);

  const { mutate: postOnFilter } = usePostOnFilterState();

  function getColumnSetting() {
    if (gridApi.current) return gridApi.current.columnApi?.getColumnState();
    return {};
  }

  function setColumnSetting(model: any) {
    if (gridApi.current)
      gridApi.current.columnApi.applyColumnState({ state: model });
    return {};
  }

  // Gets filter model via the grid API
  const getFilterSetting = () => {
    if (gridApi.current) return gridApi.current.api?.getFilterModel();
    return {};
  };

  const setFilterSetting = (model: any) => {
    if (gridApi.current) return gridApi.current.api?.setFilterModel(model);
    return {};
  };

  const uploadFilterAndColumn = async () => {
    const columnState = await getColumnSetting();
    const filterState = await getFilterSetting();
    postOnFilter({
      userId: `${userId}`,
      companyId,
      filterData: {
        ...agTableStore.state,
        quickPrimaryColumnModel: columnState,
        quickPrimaryFilterModel: filterState,
      },
    });
  };

  const cin = "";

  const componentsRegistery = useMemo(
    () => ({
      investorName: memo(InvestorNameRender),
      securityName: memo(SecurityNameRender),
      securityType: memo(SecurityTypeRender),
      numberOfShares: memo(NumberOfSharesRender),
      amountInvested: memo(AmountRender),
      fdbShares: memo(FDBSharesRender),
    }),
    []
  );

  const navigate = useNavigate();

  const onClickShareholderName = (id: string) => {
    handleEventForTracking({ eventName: `Shareholder Name` });
    navigate(`/shareholders/individual/${id}`);
  };

  const isValidDate = (date: string) =>
    Number.isNaN(date) &&
    new Date(date).toString() !== "Invalid Date" &&
    !Number.isNaN(Date.parse(date));

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      autoHeight: true,
      wrapHeaderText: true,
      suppressColumnVirtualisation: true,
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      filterParams: {
        buttons: ["reset"],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
      flex: 1,
    }),
    []
  );
  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        headerName: "Investor Name",
        field: "investorName",
        filter: "agMultiColumnFilter",
        getQuickFilterText: (params) => params.value.name,
        comparator(valueA: any, valueB: any, ..._: any[]) {
          return valueA.name > valueB.name ? 1 : -1;
        },
        filterParams: {
          maxNumConditions: 5,
          buttons: ["reset"],
          filters: [
            {
              title: "Name",
              display: "subMenu",
              filter: "agTextColumnFilter",
              buttons: ["reset"],
              filterValueGetter: (params: any) => params.value.name,
              filterParams: {
                buttons: ["reset"],
                valueGetter: (params: any) => params.data.name,
              },
            },
            {
              title: "Type",
              filter: "agSetColumnFilter",
              display: "subMenu",
              buttons: ["reset"],
              filterParams: {
                buttons: ["reset"],

                valueGetter: (params: any) => {
                  const investorType = params.data.investorType;
                  return investorType;
                },
              },
            },
          ],
        },
        cellRenderer: "investorName",
        initialWidth: 200,
        sortable: true,
        autoHeight: true,
        cellStyle: tableCellStyle,
        wrapText: true,
        suppressSizeToFit: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipBuyerName",
      },
      {
        headerName: "Security Name",
        field: "securityName",
        filter: "agSetColumnFilter",
        cellRenderer: "securityName",
        cellStyle: tableCellStyle,
        sortable: false,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipSecurity",
      },
      {
        headerName: "Security Type",
        field: "securityType",
        filter: "agSetColumnFilter",
        cellRenderer: "securityType",
        cellStyle: tableCellStyle,
        sortable: false,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipSecurity",
      },
      {
        headerName: "No. of Shares",
        field: "numberOfShares",
        filter: "agNumberColumnFilter",
        cellRenderer: "numberOfShares",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipNumberOfShares",
      },
      {
        headerName: "FDB Shares",
        field: "fdbShares",
        filter: "agNumberColumnFilter",
        cellRenderer: "fdbShares",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipFdbShares",
      },
      {
        headerName: `Amount (${currencySymbol})`,
        field: "amountInvested",
        filter: "agNumberColumnFilter",
        cellRenderer: "amountInvested",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipAmountInvested",
      },
    ],
    []
  );

  const gridOptions = {
    suppressRowVirtualisation: true, // Add this line to disable row virtualization
    paginationAutoPageSize: false, // Optional: Set to false if using custom pagination
    suppressScrollOnNewData: true, // Optional: Set to true to prevent scrolling to the top on
  };
  const currencyType = getCurrencyType();
  const rowData = useMemo(
    () =>
      outstandingConvertibles?.map((template, index) => ({
        investorName: {
          name: shareholderAndInstrumentstore.shareholders.find(
            (shareholder: any) => shareholder.id === template.investorId
          )?.name,
          type: shareholderAndInstrumentstore.shareholders.find(
            (shareholder: any) => shareholder.id === template.investorId
          )?.type,
        },
        securityName: template.securityName,
        securityType: template.securityType,
        numberOfShares: template.numberOfShares,
        fdbShares: template.fdbShares,
        amountInvested: template.amountInvested,
        tooltipAmountInvested:
          template.amountInvested.toLocaleString(currencyType),
        tooltipFdbShares: template.fdbShares.toLocaleString(currencyType),
        tooltipNumberOfShares:
          template.numberOfShares.toLocaleString(currencyType),
        discountedValuation:
          template.discountedValuation.toLocaleString(currencyType),
        discountedPPS: template.discountedPPS.toLocaleString(currencyType),
        currencySymbol,
        currencyType,
      })),
    [outstandingConvertibles]
  );

  function setTableHeight() {
    if (displayedRowCount === 1) {
      return (displayedRowCount + 2.0) * 60;
    } else if (displayedRowCount === 2) {
      return (displayedRowCount + 2.0) * 60;
    } else if (displayedRowCount === 3) {
      return (displayedRowCount + 2.0) * 60;
    } else if (displayedRowCount === 4) {
      return (displayedRowCount + 2.0) * 60;
    } else if (displayedRowCount === 5) {
      return (displayedRowCount + 2.0) * 60;
    } else if (displayedRowCount === 6) {
      return (displayedRowCount + 2.0) * 60;
    } else if (displayedRowCount === 7) {
      return (displayedRowCount + 2.0) * 60;
    } else if (displayedRowCount === 8) {
      return (displayedRowCount + 2.0) * 60;
    } else if (displayedRowCount === 9) {
      return (displayedRowCount + 2.0) * 60;
    } else {
      return 10 * 60;
    }
  }

  const [filteredRowData, setFilteredRowData] = useState<any>([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const onAgGridFilterChanged = (grid: any) => {
    const filtersApplied = grid.api.isAnyFilterPresent();
    setIsFilterApplied(filtersApplied);

    const filteredData = grid.api
      .getModel()
      .rowsToDisplay.map((node: any) => node.data);
    setFilteredRowData(filteredData);
    uploadFilterAndColumn();
  };
  return (
    <div>
      <HStack className="flex-col items-start justify-start py-2 bg-white rounded-md lg:flex-row lg:justify-between lg:items-center"></HStack>
      <HStack className="justify-between w-full">
        <Box
          style={{
            height: setTableHeight(),
          }}
          className="w-full h-full max-h-full overflow-x-auto ag-theme-material "
        >
          <AgGridReact
            sideBar={agConfigDashboardSideBar}
            onGridReady={(params) => {
              // Store the grid API referen
              gridApi.current = params;
            }}
            components={componentsRegistery}
            alwaysShowHorizontalScroll
            alwaysMultiSort
            animateRows={true}
            defaultColDef={defaultColDef}
            onFilterChanged={onAgGridFilterChanged}
            onColumnEverythingChanged={uploadFilterAndColumn}
            rowData={rowData}
            columnDefs={columnDefs}
            pagination={false}
            suppressRowTransform={true}
            suppressCopyRowsToClipboard={true}
            suppressCopySingleCellRanges={true}
            suppressCellFocus={true}
            suppressMenuHide={false}
            onColumnResized={uploadFilterAndColumn}
            tooltipShowDelay={1000}
            tooltipInteraction={true}
            rowClass={"border-t border-dashed "}
            gridOptions={gridOptions}
            overlayNoRowsTemplate={ReactDOMServer.renderToString(
              <EmptyTable
                header="No Transaction"
                subHeader="Get started by adding a new transaction"
              />
            )}
            getRowStyle={(params) => {
              if (params.rowIndex % 2 === 0) {
                return { background: "#f8f8f8" };
              } else {
                return { background: "#ffffff" };
              }
            }}
          ></AgGridReact>
        </Box>
      </HStack>
    </div>
  );
}

export default AgGridOutstandingConvertibleTable;
