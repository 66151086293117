import { Form, Formik } from "formik";
import _, { values } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Action } from "../components/shared/Dropdown";
import {
  Description,
  Input,
  Label,
  SubLabel,
} from "../components/shared/InputField";
import { Select } from "../components/shared/Select";
import { HStack, VStack, Box, Error } from "../components/utils";
import {
  getTypeOfSecurity,
  Security,
} from "../constants/SecurityConstantContent";
import TransactionBeneficiariesTable from "../pages/shareholders/TransactionBeneficiariesTable";
import { queryClient } from "../queries";
import {
  useGetAllBeneficialCertificates,
  useGetAllBeneficialInvestors,
  useUpdateBeneficialCertificates,
  useUpdateBeneficialInvestors,
  useValidateBeneficialCertificates,
} from "../queries/shareholders";
import {
  BeneficialInvestor,
  BeneficiaryCertificatesInput,
  BeneficiaryMethodType,
  BeneficiaryModel,
  BeneficiaryUpdateModel,
  ShareholderSecurityModel,
} from "../types/Shareholder";
import { getCurrencyType } from "../utils/currencyFormatter";
import { ShowNumberInWords } from "../components/shared/UiElement";
import {
  ButtonSize,
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../pages/quickRound/CTAButtonComponents";
import { PopupHeader } from "../components/Headings";
import EmptyTableIllustration from "../shared/EmptyTableIllustration";

export function AddBeneficiaryDetails({
  shareholderSecurity,
  shareholderId,
  onPrimaryAction = () => {},
  onSecondaryAction = () => {},
}: {
  shareholderSecurity: ShareholderSecurityModel;
  shareholderId: string;
  onPrimaryAction: () => void;
  onSecondaryAction: () => void;
}) {
  const [beneficiaryMethodType, setBeneficiaryMethodType] = useState(
    BeneficiaryMethodType.ranges
  );

  const { refetch: getAllBeneficialInvestors, data: beneficialInvestors } =
    useGetAllBeneficialInvestors(shareholderId);

  const [selectedBeneficiary, setSelectedBeneficiary] =
    useState<BeneficialInvestor | null>();
  const [selectedBeneficiaryDetails, setSelectedBeneficiaryDetails] =
    useState<BeneficiaryUpdateModel>({
      name: "",
      uniqueId: "",
      bInvestorId: "",
      numberOfShares: 0,
      isDeleted: false,
      inputRanges: "",
      percentage: 0,
    });

  const formDefaultValues = {
    name: "",
    uniqueId: "",
    bInvestorId: "",
    numberOfShares: 0,
    isDeleted: false,
    inputRanges: "",
    percentage: 0,
    ranges: null,
  };

  const [saveBeneficiary, setSaveBeneficiary] = useState(false);
  const [onHoverNumberOfShares, setOnHoverNumberOfShares] = useState(false);
  const currencyType = getCurrencyType();

  const {
    refetch: getAllBeneficiaryCertificates,
    data: beneficiaryCertificatesResponse,
  } = useGetAllBeneficialCertificates(shareholderSecurity.certificateId);

  const { mutate: updateBeneficialInvestors } = useUpdateBeneficialInvestors();
  const { mutate: updateBeneficiaryCertificates } =
    useUpdateBeneficialCertificates();

  const { mutate: validateBeneficiaryCertificates } =
    useValidateBeneficialCertificates();

  const [beneficialCertificates, setBeneficialCertificates] = useState<
    BeneficiaryUpdateModel[]
  >([]);

  useEffect(() => {
    const beneficiaryCertificatesToSet = (
      beneficiaryCertificatesResponse || []
    ).map((certificate) => {
      const bInvestor = beneficialInvestors?.find(
        (investor) => investor.id === certificate.bInvestorId
      );
      const inputRanges = certificate.ranges
        ? transformRangesArraytoString(certificate.ranges)
        : "";
      return {
        ...certificate,
        name: bInvestor?.name || "",
        inputRanges,
        percentage: certificate.percentage || 0,
        numberOfShares: certificate.numberOfShares || 0,
      };
    });
    setBeneficialCertificates(beneficiaryCertificatesToSet);
    if (
      beneficiaryCertificatesResponse &&
      beneficiaryCertificatesResponse[0]?.methodType
    )
      setBeneficiaryMethodType(
        beneficiaryCertificatesResponse[0].methodType as BeneficiaryMethodType
      );
  }, [beneficiaryCertificatesResponse]);

  const beneficiaryInput: BeneficiaryCertificatesInput = {
    certificateId: shareholderSecurity.certificateId,
    methodType: beneficiaryMethodType,
    beneficiaryCertificates: [],
  };

  useEffect(() => {
    if (selectedBeneficiary) {
      const beneficiary = beneficialCertificates
        .filter((certificate) => !certificate.isDeleted)
        .find(
          (certificate) => certificate.bInvestorId === selectedBeneficiary.id
        );
      if (beneficiary) {
        const investor = beneficialInvestors?.find(
          (investor) => investor.id === beneficiary.bInvestorId
        );
        if (!beneficiary.uniqueId && investor?.uniqueId)
          beneficiary.uniqueId = investor.uniqueId;
        if (!beneficiary.name && investor?.name)
          beneficiary.name = investor.name;
        beneficiary.numberOfShares = beneficiary.numberOfShares || 0;
        beneficiary.percentage = beneficiary.percentage || 0;
        beneficiary.ranges = beneficiary.ranges || null;
        beneficiary.inputRanges = beneficiary.ranges
          ? transformRangesArraytoString(beneficiary.ranges)
          : "";
        setSelectedBeneficiaryDetails(beneficiary);
      } else {
        const investor = beneficialInvestors?.find(
          (investor) => investor.id === selectedBeneficiary.id
        );
        setSelectedBeneficiaryDetails({
          name: investor?.name || "",
          uniqueId: investor?.uniqueId || "",
          bInvestorId: investor?.id || "",
          numberOfShares: 0,
          isDeleted: false,
          inputRanges: "",
          percentage: 0,
          methodType: beneficiaryMethodType,
        });
      }
    }
  }, [selectedBeneficiary, beneficialCertificates, beneficialInvestors]);

  useEffect(() => {
    if (selectedBeneficiaryDetails?.name) setSaveBeneficiary(true);
  }, [selectedBeneficiaryDetails]);

  const beneficiaryInputSchema = Yup.object({
    id: Yup.string().label("Beneficiary ID"),
    ranges: Yup.array()
      .of(Yup.array().of(Yup.number()))
      .nullable(true)
      .label("Share Distinctive Numbers"),
    percentage: Yup.number().when("methodType", {
      is: (val: string) => val === BeneficiaryMethodType.percentage,
      then: Yup.number().required().label("Percentage"),
      otherwise: Yup.number().label("Percentage"),
    }),
    bInvestorId: Yup.string().label("Beneficiary Investor ID"),
    name: Yup.string().required().label("Beneficiary Name"),
    numberOfShares: Yup.number().when("methodType", {
      is: (val: string) => val === BeneficiaryMethodType.numberOfShares,
      then: Yup.number().required().label("Number Of Shares"),
      otherwise: Yup.number().label("Number Of Shares"),
    }),
    isDeleted: Yup.boolean().label("Is Deleted"),
    methodType: Yup.string().label("Method Type"),
    uniqueId: Yup.string().label("PAN Number"),
    inputRanges: Yup.string()
      .matches(
        /^[EPFDOWNE]*\d+-[EPFDOWNE]*\d+(,[EPFDOWNE]*\d+-[EPFDOWNE]*\d+)*$/,
        "Ranges exp: P1-100,101-150"
      )
      .nullable(true)
      .when("methodType", {
        is: (val: string) => val === BeneficiaryMethodType.ranges,
        then: Yup.string().required().label("Share Distinctive Number Ranges"),
        otherwise: Yup.string().label("Share Distinctive Number Ranges"),
      }),
  });

  const saveBeneficiaries = () => {
    beneficiaryInput.beneficiaryCertificates = beneficialCertificates.map(
      (certificate) => ({
        id: certificate.id,
        ranges: certificate.ranges,
        percentage: certificate.percentage,
        bInvestorId: certificate.bInvestorId,
        numberOfShares: certificate.numberOfShares,
        isDeleted: certificate.isDeleted,
      })
    );
    updateBeneficiaryCertificates(beneficiaryInput, {
      onSuccess: (data) => {
        setSaveBeneficiary(false);
        setSelectedBeneficiary(null);
        toast("Beneficiaries Saved Successfully", {
          type: "success",
          autoClose: 2000,
        });
        onPrimaryAction();
      },
      onError: (err: any) => {
        toast(err.response.data.errorMessage, {
          type: "error",
          autoClose: 2000,
        });
      },
    });
  };

  function handleAction(action: Action, data: BeneficiaryUpdateModel) {
    const index = beneficialCertificates.findIndex(
      (individualInvestor) =>
        individualInvestor.bInvestorId === data.bInvestorId
    );

    if (action.name === "Edit" && !action.disabled) {
      const investor = beneficialInvestors?.find(
        (investor) => investor.id === data.bInvestorId
      );
      if (investor) setSelectedBeneficiary(investor);
    } else if (action.name === "Delete" && !action.disabled) {
      beneficialCertificates[index] = {
        ...beneficialCertificates[index],
        isDeleted: true,
      };
      setBeneficialCertificates(_.cloneDeep(beneficialCertificates));
    }
  }

  function handleSubmit(values: any, resetForm: Function) {
    const certificateIndex = beneficialCertificates.findIndex(
      (certificate) => certificate.bInvestorId === values.bInvestorId
    );

    const certificatesToValidate = _.clone(beneficialCertificates);
    if (certificateIndex === -1)
      certificatesToValidate.push({
        ranges: values.inputRanges
          ? transformRangesToArray(values.inputRanges)
          : null,
        percentage: values.percentage,
        bInvestorId: values.bInvestorId,
        name: values.name,
        numberOfShares: values.numberOfShares,
        isDeleted: values.isDeleted,
        methodType: values.methodType,
        uniqueId: values.uniqueId,
        inputRanges: values.inputRanges,
      });
    else {
      certificatesToValidate[certificateIndex] = {
        ...values,
        ranges: values.inputRanges
          ? transformRangesToArray(values.inputRanges)
          : null,
      };
    }

    validateBeneficiaryCertificates(
      {
        ...beneficiaryInput,
        beneficiaryCertificates: certificatesToValidate.map((certificate) => ({
          id: certificate.id,
          ranges: certificate.ranges,
          percentage: certificate.percentage,
          bInvestorId: certificate.bInvestorId,
          numberOfShares: certificate.numberOfShares,
          isDeleted: certificate.isDeleted,
        })),
      },
      {
        onSuccess: (data) => {
          const clearForm = () => {
            setSaveBeneficiary(false);
            setSelectedBeneficiary(null);
            resetForm({
              values: formDefaultValues,
            });
          };

          if (
            !beneficialInvestors?.find(
              (investor) => investor.name === values.name
            )
          ) {
            updateBeneficialInvestors(
              [
                {
                  uniqueId: values.uniqueId,
                  name: values.name || "",
                  companyInvestorId: shareholderId,
                } as BeneficialInvestor,
              ],
              {
                onSuccess: (data) => {
                  clearForm();
                  const updatedInvestors = data.data as BeneficialInvestor[];

                  const index = certificatesToValidate.findIndex(
                    (certificate) => !certificate.bInvestorId
                  );

                  if (index >= 0) {
                    certificatesToValidate[index] = {
                      ...certificatesToValidate[index],
                      bInvestorId:
                        updatedInvestors?.find(
                          (investor) =>
                            investor.name === certificatesToValidate[index].name
                        )?.id || "",
                    };
                    setBeneficialCertificates(
                      _.cloneDeep(certificatesToValidate)
                    );
                  }
                },
                onError: (err: any) => {
                  toast(err.response.data.errorMessage, {
                    type: "error",
                    autoClose: 2000,
                  });
                },
              }
            );
          } else {
            clearForm();
            setBeneficialCertificates(certificatesToValidate);
          }
        },
        onError: (err: any) => {
          toast(err.response.data.errorMessage, {
            type: "error",
            autoClose: 2000,
          });
        },
      }
    );
  }

  return (
    <div className="bg-white rounded-md w-full">
      <VStack className="text-left font-medium">
        <PopupHeader
          text={"Adding Beneficiaries"}
          onClick={() => onSecondaryAction()}
        />

        <Box className="border-b">
          <HStack className="mt-5 mb-10 justify-start">
            <BeneficiaryDetailsCard
              label="No. Of Shares"
              value={shareholderSecurity.numberOfShares}
            />
            {shareholderSecurity.ranges && (
              <BeneficiaryDetailsCard
                label="Distinctive Share Numbers"
                value={transformRangesArraytoString(
                  shareholderSecurity.ranges,
                  shareholderSecurity.securityType
                )}
              />
            )}
          </HStack>
        </Box>
        <VStack className="w-full px-5 py-4 gap-6">
          <Formik
            key="addBeneficiaryDetails"
            enableReinitialize={true}
            initialValues={selectedBeneficiaryDetails}
            onSubmit={(values, { resetForm }) =>
              handleSubmit(values, resetForm)
            }
            validationSchema={beneficiaryInputSchema}
          >
            {(formik) => (
              <Form key="shareholder">
                <VStack className="gap-3 py-5">
                  <SubLabel>Select Method of Beneficiary Addition</SubLabel>
                  <HStack className="gap-8 sm:flex-row flex-col">
                    <HStack className="items-center">
                      <input
                        type="radio"
                        className="accent-orange-501 outline-hidden mr-1
                cursor-pointer"
                        checked={
                          beneficiaryMethodType ===
                          BeneficiaryMethodType.percentage
                        }
                        onChange={(e) => {
                          setBeneficiaryMethodType(
                            BeneficiaryMethodType.percentage
                          );
                          formik.setFieldValue(
                            "methodType",
                            BeneficiaryMethodType.percentage
                          );
                          formik.setFieldTouched("numberOfShares");
                          formik.setFieldTouched("ranges");
                        }}
                        disabled={
                          beneficialCertificates.filter(
                            (certificate) => !certificate.isDeleted
                          ).length > 0
                        }
                      />
                      <Label>By Percentage of Shares</Label>
                    </HStack>
                    <HStack className="items-center">
                      <input
                        type="radio"
                        className="accent-orange-501 outline-hidden mr-1
                cursor-pointer"
                        checked={
                          beneficiaryMethodType === BeneficiaryMethodType.ranges
                        }
                        onChange={(e) => {
                          setBeneficiaryMethodType(
                            BeneficiaryMethodType.ranges
                          );
                          formik.setFieldValue(
                            "methodType",
                            BeneficiaryMethodType.ranges
                          );
                          formik.setFieldTouched("numberOfShares");
                          formik.setFieldTouched("percentage");
                        }}
                        disabled={
                          beneficialCertificates.filter(
                            (certificate) => !certificate.isDeleted
                          ).length > 0
                        }
                      />
                      <Label>By Distinctive No. of Shares</Label>
                    </HStack>
                    <HStack className="items-center">
                      <input
                        type="radio"
                        className="accent-orange-501 outline-hidden mr-1
                cursor-pointer"
                        checked={
                          beneficiaryMethodType ===
                          BeneficiaryMethodType.numberOfShares
                        }
                        onChange={(e) => {
                          setBeneficiaryMethodType(
                            BeneficiaryMethodType.numberOfShares
                          );
                          formik.setFieldValue(
                            "methodType",
                            BeneficiaryMethodType.numberOfShares
                          );
                          formik.setFieldTouched("ranges");
                          formik.setFieldTouched("percentage");
                        }}
                        disabled={
                          beneficialCertificates.filter(
                            (certificate) => !certificate.isDeleted
                          ).length > 0
                        }
                      />
                      <Label>By No. of Shares</Label>
                    </HStack>
                  </HStack>
                </VStack>
                {selectedBeneficiaryDetails && (
                  <div>
                    <div>
                      <HStack className="justify-between">
                        <div></div>
                        {beneficialCertificates.filter(
                          (certificate) => !certificate.isDeleted
                        ).length > 0 ? (
                          <PrimaryCTAButton
                            className="h-8"
                            event-name="Add New beneficiary button"
                            buttonSize={ButtonSize.SMALL}
                            onClick={() => {
                              setSelectedBeneficiary(null);
                              setSaveBeneficiary(true);
                              formik.resetForm({
                                values: formDefaultValues,
                              });
                            }}
                          >
                            Add New
                          </PrimaryCTAButton>
                        ) : (
                          <div></div>
                        )}
                      </HStack>
                    </div>
                    {saveBeneficiary && (
                      <div className="border rounded-md p-5 mt-5 shadow-box gap-4">
                        <HStack className="justify-between sm:flex-row flex-col gap-4">
                          <div>
                            <Label>Beneficiary Name</Label>
                            <Input
                              type="text"
                              placeholder="Enter Beneficiary Name"
                              className="w-full"
                              {...formik.getFieldProps("name")}
                            />
                            {formik.touched?.name && formik.errors?.name && (
                              <Error text={formik.errors?.name} />
                            )}
                          </div>
                          <div>
                            <Label>PAN Number</Label>
                            <Input
                              type="text"
                              placeholder="Enter Beneficiary PAN"
                              className=""
                              {...formik.getFieldProps("uniqueId")}
                            />
                            {formik.touched?.uniqueId &&
                              formik.errors?.uniqueId && (
                                <Error text={formik.errors?.uniqueId} />
                              )}
                          </div>
                        </HStack>
                        <HStack className="justify-between">
                          {beneficiaryMethodType ===
                            BeneficiaryMethodType.percentage && (
                            <div className="mt-8">
                              <Label>Enter Percentage Of Shares</Label>
                              <Input
                                type="number"
                                placeholder="Enter Percentage Of Shares"
                                className=""
                                {...formik.getFieldProps("percentage")}
                              />
                              {formik.touched?.percentage &&
                                formik.errors?.percentage && (
                                  <Error text={formik.errors?.percentage} />
                                )}
                            </div>
                          )}
                          {beneficiaryMethodType ===
                            BeneficiaryMethodType.ranges && (
                            <div className="mt-8 justify-start">
                              <Label>Enter Distinctive No. Of Shares</Label>
                              <Input
                                type="text"
                                placeholder="Enter Distinctive No. Of Shares"
                                className=""
                                {...formik.getFieldProps("inputRanges")}
                              />
                              <Description
                                className="w-fit"
                                text="Eg: if distinctive share is E123-E4567, then enter 123-150"
                              />
                              {formik.touched?.inputRanges &&
                                formik.errors?.inputRanges && (
                                  <Error text={formik.errors?.inputRanges} />
                                )}
                            </div>
                          )}
                          {beneficiaryMethodType ===
                            BeneficiaryMethodType.numberOfShares && (
                            <div className="mt-8">
                              <Label>Enter No. Of Shares</Label>
                              <Input
                                placeholder="Enter No. Of Shares"
                                className=""
                                type="text"
                                onMouseEnter={() => {
                                  setOnHoverNumberOfShares(true);
                                }}
                                onMouseLeave={() => {
                                  setOnHoverNumberOfShares(false);
                                }}
                                onChange={(e: any) => {
                                  const values = parseInt(
                                    e.target.value?.replaceAll(",", "") || 0,
                                    10
                                  );
                                  formik.setFieldValue(
                                    "numberOfShares",
                                    values
                                  );
                                  formik.handleChange("numberOfShares");
                                }}
                                value={Intl.NumberFormat(currencyType).format(
                                  formik.values.numberOfShares!
                                )}
                              />
                              {onHoverNumberOfShares && (
                                <ShowNumberInWords
                                  value={formik.values.numberOfShares!}
                                  width={38}
                                  currency={currencyType}
                                />
                              )}

                              {formik.touched?.numberOfShares &&
                                formik.errors?.numberOfShares && (
                                  <Error text={formik.errors?.numberOfShares} />
                                )}
                            </div>
                          )}
                          <div></div>
                        </HStack>
                        <div className="flex flex-row justify-end gap-4 h-8">
                          <SecondaryCTAButton
                            buttonSize={ButtonSize.SMALL}
                            event-name="Cancel New beneficiary"
                            className=""
                            onClick={() => {
                              setSaveBeneficiary(false);
                            }}
                          >
                            Cancel
                          </SecondaryCTAButton>
                          <PrimaryCTAButton
                            event-name="Save New beneficiary"
                            type="submit"
                          >
                            Save
                          </PrimaryCTAButton>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {beneficialCertificates.filter(
                  (certificate) => !certificate.isDeleted
                ).length > 0 ? (
                  <TransactionBeneficiariesTable
                    beneficiaries={beneficialCertificates.filter(
                      (certificate) => !certificate.isDeleted
                    )}
                    beneficiaryMethodType={beneficiaryMethodType}
                    handleAction={handleAction}
                    securityType={shareholderSecurity.securityType}
                  />
                ) : beneficialCertificates.filter(
                    (certificate) => !certificate.isDeleted
                  ).length === 0 && saveBeneficiary ? (
                  <div></div>
                ) : (
                  <VStack>
                    <EmptyTableIllustration
                      text={
                        "You don't have any beneficiaries listed yet. Add them now"
                      }
                    />
                    <PrimaryCTAButton
                      event-name="Add New beneficiary"
                      className="h-8 mt-4 w-fit mx-auto"
                      buttonSize={ButtonSize.SMALL}
                      onClick={() => {
                        setSelectedBeneficiary(null);
                        setSaveBeneficiary(true);
                        formik.resetForm({
                          values: formDefaultValues,
                        });
                      }}
                    >
                      Add New
                    </PrimaryCTAButton>
                  </VStack>
                )}
              </Form>
            )}
          </Formik>

          <HStack className="justify-between mt-10 h-8">
            <SecondaryCTAButton
              buttonSize={ButtonSize.SMALL}
              event-name="Cancel Beneficiary Modal"
              onClick={() => {
                onSecondaryAction();
              }}
            >
              Back
            </SecondaryCTAButton>
            <PrimaryCTAButton
              event-name="Save Beneficiary Modal"
              buttonSize={ButtonSize.SMALL}
              onClick={saveBeneficiaries}
            >
              Save & Close
            </PrimaryCTAButton>
          </HStack>
        </VStack>
      </VStack>
    </div>
  );
}

function BeneficiaryDetailsCard({
  value,
  label,
}: {
  value: number | string;
  label: string;
}) {
  return (
    <Box className="py-4 shadow-box bg-white rounded-md mt-3 flex-wrap mx-10 px-2 border border-borderColor">
      <VStack>
        <div className="text-center text-sm font-semibold">{value}</div>
        <SubLabel className="text-center text-xs font-medium">{label}</SubLabel>
      </VStack>
    </Box>
  );
}

function transformRangesToArray(ranges: string) {
  return ranges
    .split(",")
    .map((range) => [Number(range.split("-")[0]), Number(range.split("-")[1])]);
}

export function transformRangesArraytoString(
  ranges: number[][],
  securityType?: string
) {
  let type = securityType ? getTypeOfSecurity(securityType)[0] : "";
  type = type === "E" ? "" : type;
  return (
    ranges
      ?.map((range) => type + range.toString().replace(",", `-${type}`))
      ?.toString() || ""
  );
}
