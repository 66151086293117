import { HStack, VStack } from "../components/utils";
import { PrimaryCTAButton } from "../pages/quickRound/CTAButtonComponents";

type ArticleModalProps = {
  name: string;
  description: string;
  onPrimaryAction: () => void;
};

function ArticleModal({
  name,
  description,
  onPrimaryAction = () => {},
}: ArticleModalProps) {
  return (
    <div className="w-full bg-white rounded-md px-8 border-dashed border-2">
      <HStack className="text-lg text-left font-medium border-b py-4">
        <h6 className="flex-1">{name}</h6>
      </HStack>
      <VStack className="w-full  px-2 py-4 gap-6">
        <p>{description}</p>
        <HStack className="justify-end h-8">
          <PrimaryCTAButton
            event-name="Close Artcle Modal"
            onClick={() => {
              onPrimaryAction();
            }}
          >
            close
          </PrimaryCTAButton>
        </HStack>
      </VStack>
    </div>
  );
}

export default ArticleModal;
