import { useEffect, useMemo, useState } from "react";
import { Icon } from "@iconify/react";
import { Dialog } from "@mui/material";
import _ from "lodash";
import { useNavigate } from "react-router";
import { Box, HStack, VStack } from "../../components/utils";
import { globalFilter, sort } from "../../utils/arrays";
import BasicMenu from "../../components/shared/Menu";
import Pagination from "../../components/shared/Pagination";
import { _trimAll, limitString } from "../../utils/string";

import { getCurrencyType } from "../../utils/currencyFormatter";
import {
  FormatNumberSpan,
  getCurrencySymbolRoboto,
} from "../../utils/currencyRoboto";
import {
  THead,
  TableHeader,
  tableEntryStyle,
  tableFirstColStyle,
} from "../../components/TableComponent";
import { useAuthStore } from "../../store";
import { Action, CTADropdown } from "../../components/shared/Dropdown";
import DeleteModal from "./DeleteModal";
import AddNewSecondary from "./AddNewSecondary";
import {
  InvestorDetails,
  ListOfInstruments,
  NewInvestorState,
  OngoingSecurityAllotmentDetails,
  RoundCreationModel,
  SecondaryTransaction,
} from "./RoundCreationModel";
import { EmptyTable } from "../quickRound/EmptyTableComponents";
import Tooltip from "../../components/shared/Tooltip";
import { Select } from "../../components/shared/Select";
import { Input, Label } from "../../components/shared/InputField";
import { useRMCreationStore } from "../../store/roundCreationStore";
import {
  ButtonSize,
  SecondaryCTAButton,
} from "../quickRound/CTAButtonComponents";
import { useShareholderInstrumentStore } from "../../store/shareholderInstrumentStore";
import NewSecurityAllotment from "./NewSecurityAllotment";

export default function NewSecurityAllotmentTable({
  securityAllotmentList,
}: {
  securityAllotmentList: InvestorDetails[];
}) {
  const roundCreationState = useRMCreationStore();
  const shareholderHolderInstrument = useShareholderInstrumentStore();
  const companyId = useAuthStore.getState().companyId || "";
  let _captableData: InvestorDetails[] = securityAllotmentList;
  const [investorDetail, setInvestorDetail] = useState<InvestorDetails>();
  const [dialog, setDialog] = useState<{
    open: boolean;
    data?: OngoingSecurityAllotmentDetails;
    mode?: "Delete" | "Add" | "Edit";
  }>({
    open: false,
  });

  const [globalFilterText, setGlobalFilterText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [sortField, setSortField] = useState<{
    field: keyof InvestorDetails | "" | undefined;
    ascending: boolean;
  }>({ field: "", ascending: false });

  _captableData = useMemo(() => {
    try {
      if (!_captableData) return [];
      const filterResult = globalFilter(_captableData, globalFilterText);

      const sortResult = sort(
        filterResult,
        sortField?.field,
        sortField?.ascending
      );
      return sortResult;
    } catch (e) {
      return _captableData;
    }
  }, [_captableData, globalFilterText, sortField]);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return _captableData.slice(firstPageIndex, lastPageIndex);
  }, [_captableData, currentPage, pageSize]);

  useEffect(() => {
    setCurrentPage(1);
  }, [globalFilterText]);

  function handleAction(action: Action, data: OngoingSecurityAllotmentDetails) {
    if (action.name === "Delete" && !action.disabled) {
      setDialog({
        open: true,
        data,
        mode: "Delete",
      });
    } else if (action.name === "Edit" && !action.disabled) {
      setDialog({
        open: true,
        data,
        mode: "Edit",
      });
    }
  }
  const currency = getCurrencyType();
  const instrumentName: string[] =
    roundCreationState.roundCreation.tab5?.listOfInstruments?.map(
      (element) => element.name
    ) || [];

  const handleSubmit = async (id: string) => {
    const ongoingSecurityAllotmentDetails: OngoingSecurityAllotmentDetails = {
      id,
      name: "",
      instrumentId: "",
      instrumentName: "",
      shareholderId: "",
      shares: 0,
      fdbShares: 0,
      totalAmountToInvest: 0,
      postholdingPercentage: 0,
      state: NewInvestorState.Delete,
      unLockedInputFeild: {
        hasToCalculateAmt: false,
        value: 0,
      },
      currencyType: "",
    };
    const roundModellingDetailwithDeletedInvestorDetails = {
      ...roundCreationState.roundCreation,
      tab5: {
        investorDetails:
          roundCreationState.roundCreation.tab5?.investorDetails ?? [],
        newInstruments:
          roundCreationState.roundCreation.tab5?.newInstruments ?? [],
        listOfInstruments:
          roundCreationState.roundCreation.tab5?.listOfInstruments ?? [],
        nonPricedInstruments:
          roundCreationState.roundCreation.tab5?.nonPricedInstruments ?? [],
        listOfShareHolders:
          roundCreationState.roundCreation.tab5?.listOfShareHolders ?? [],
        ongoingSecurityAllotmentDetails,
      },
    };

    roundCreationState.sendMessage(
      roundModellingDetailwithDeletedInvestorDetails
    );
    setDialog({ open: false });
  };

  return (
    <VStack className="border-t pt-4 mt-4">
      <Box className="mb-4">
        <HStack className="justify-between">
          <VStack>
            <Label className="text-base font-semibold text-gray-dark">
              Investor List
            </Label>

            <p className="font-medium text-gray-light">
              Map the securities for specific shareholder.
            </p>
          </VStack>

          {/*Dirty Commit <HStack className="items-center gap-4">
            {(roundCreationState.roundCreation.headers?.leftToRaise ?? 0) <
            (roundCreationState.roundCreation.headers?.pps ?? 0) ? (
              <Tooltip
                _position="right-1/2"
                _className="w-32"
                text={`Left to raise should \nexceed FDB share price \nfor addition of transaction`}
              >
                <Icon
                  onClick={() => {}}
                  icon="ic:outline-lock"
                  width={20}
                  className="text-gray-400"
                  height={20}
                />
              </Tooltip>
            ) : (
              <div></div>
            )}
            <SecondaryCTAButton
              buttonSize={ButtonSize.SMALL}
              className={`${
                (roundCreationState.roundCreation.headers?.leftToRaise ?? 0) >
                (roundCreationState.roundCreation.headers?.pps ?? 0)
                  ? "border-gray-400"
                  : ""
              }`}
              onClick={
                (roundCreationState.roundCreation.headers?.leftToRaise ?? 0) >
                (roundCreationState.roundCreation.headers?.pps ?? 0)
                  ? () => {
                      setDialog({ open: true, mode: "Add" });
                    }
                  : () => {}
              }
            >
              Add Transaction
            </SecondaryCTAButton>
          </HStack> */}
        </HStack>
      </Box>
      <div className="bg-white rounded-lg">
        <Dialog open={dialog.open} maxWidth="lg">
          {dialog.mode === "Delete" && (
            <DeleteModal
              onPrimaryAction={() => {
                handleSubmit(dialog.data?.id || "");
              }}
              onSecondaryAction={() => setDialog({ open: false })}
              data={
                "Are you sure you want to delete the entry? Data will be permanently removed. This action cannot be undone."
              }
            />
          )}
          {dialog.mode === "Add" && (
            <NewSecurityAllotment
              onNext={roundCreationState.sendMessage}
              onBack={() => setDialog({ open: false })}
              data={undefined}
            />
          )}
          {dialog.mode === "Edit" && (
            <NewSecurityAllotment
              actionMode="Edit"
              onNext={roundCreationState.sendMessage}
              onBack={() => setDialog({ open: false })}
              data={dialog.data}
            />
          )}
        </Dialog>
        <div className="flex flex-col">
          <div className="overflow-y-auto">
            <div className="inline-block min-w-full py-2 align-middle">
              <div className="md:rounded-lg">
                <table className="min-w-full bg-white">
                  <thead>
                    <TableHeader>
                      <THead
                        fieldName="name"
                        fieldDisplayName="Name"
                        isFirstFeild={true}
                        selectedFieldName={sortField?.field || ""}
                        isAscending={sortField?.ascending || false}
                        isColumnFilter={false}
                        onChangeSort={() => {
                          setSortField({
                            field: "name",
                            ascending: !sortField?.ascending,
                          });
                        }}
                      />
                      <THead
                        fieldName="holdingPercentage"
                        fieldDisplayName="Holding %"
                        showCurrency={false}
                        selectedFieldName={sortField?.field || ""}
                        isAscending={sortField?.ascending || false}
                        isColumnFilter={false}
                        onChangeSort={() => {
                          setSortField({
                            field: "holdingPercentage",
                            ascending: !sortField?.ascending,
                          });
                        }}
                      />
                      <THead
                        fieldName="noOfShares"
                        fieldDisplayName="No.of Securities"
                        selectedFieldName={sortField?.field || ""}
                        isAscending={sortField?.ascending || false}
                        isColumnFilter={false}
                        onChangeSort={() => {
                          setSortField({
                            field: "noOfShares",
                            ascending: !sortField?.ascending,
                          });
                        }}
                      />
                      <THead
                        fieldName="amount"
                        fieldDisplayName="Amount"
                        showCurrency={true}
                        selectedFieldName={sortField?.field || ""}
                        isAscending={sortField?.ascending || false}
                        isColumnFilter={false}
                        onChangeSort={() => {
                          setSortField({
                            field: "amount",
                            ascending: !sortField?.ascending,
                          });
                        }}
                      />

                      <THead
                        fieldName="instrumentName"
                        fieldDisplayName="Security"
                        selectedFieldName={sortField?.field || ""}
                        isAscending={sortField?.ascending || false}
                        isColumnFilter={false}
                        onChangeSort={() => {
                          setSortField({
                            field: "instrumentName",
                            ascending: !sortField?.ascending,
                          });
                        }}
                      />
                      <th></th>
                    </TableHeader>
                  </thead>
                  <tbody>
                    {currentTableData.map((captable: InvestorDetails) => (
                      <tr
                        key={`${captable.id}`}
                        className="border-t whitespace-nowrap border-dashed hover:bg-slate-50 text-[#030303] text-xs2 font-medium "
                      >
                        <td className="py-4 pr-3 text-sm whitespace-nowrap font-medium">
                          <VStack className="flex items-start">
                            {captable.name && (
                              <div className={tableFirstColStyle}>
                                {captable.name.length > 24 ? (
                                  <Tooltip text={captable.name}>
                                    <p
                                      className={`${tableEntryStyle} font-semibold`}
                                    >
                                      {limitString(captable.name || "", 24)}
                                    </p>
                                  </Tooltip>
                                ) : (
                                  <p
                                    className={`${tableEntryStyle} font-semibold`}
                                  >
                                    {captable.name[0].toUpperCase() +
                                      captable.name.slice(1)}
                                  </p>
                                )}
                              </div>
                            )}
                          </VStack>
                        </td>
                        <td className="px-3 py-4 whitespace-nowrap">
                          <VStack className="items-center">
                            <p className={tableEntryStyle}>
                              {captable?.holdingPercentage && (
                                <FormatNumberSpan
                                  value={captable.holdingPercentage}
                                />
                              )}
                            </p>
                          </VStack>
                        </td>
                        <td className="px-3 py-4 whitespace-nowrap">
                          <VStack className="items-center">
                            <p className={tableEntryStyle}>
                              {captable?.noOfShares && (
                                <FormatNumberSpan value={captable.noOfShares} />
                              )}
                            </p>
                          </VStack>
                        </td>
                        <td className="px-3 py-4 whitespace-nowrap">
                          <VStack className="items-center">
                            <p className={tableEntryStyle}>
                              {captable?.noOfShares && (
                                <FormatNumberSpan
                                  value={Number(captable.amount.toFixed(2))}
                                />
                              )}
                            </p>
                          </VStack>
                        </td>
                        <td className="px-3 py-4 w-64  whitespace-nowrap">
                          <VStack className="items-center">
                            <Select
                              className=""
                              options={instrumentName}
                              value={captable.instrumentName}
                              onChange={(e) => {
                                const instrument =
                                  roundCreationState.roundCreation.tab5?.listOfInstruments?.find(
                                    (element) => element.name === e.target.value
                                  );
                                const investorDetails =
                                  roundCreationState.roundCreation.tab5?.investorDetails.map(
                                    (element) => {
                                      if (element.id === captable.id) {
                                        return {
                                          ...element,
                                          instrumentId: instrument?.id || "",
                                          instrumentName:
                                            instrument?.name || "",
                                        };
                                      } else {
                                        return element;
                                      }
                                    }
                                  );
                                roundCreationState.sendMessage({
                                  ...roundCreationState.roundCreation,
                                  tab5: {
                                    listOfShareHolders:
                                      roundCreationState.roundCreation.tab5
                                        ?.listOfShareHolders || [],
                                    investorDetails: investorDetails || [],
                                    listOfInstruments:
                                      roundCreationState.roundCreation.tab5
                                        ?.listOfInstruments || [],
                                    newInstruments:
                                      roundCreationState.roundCreation.tab5
                                        ?.newInstruments || [],
                                    nonPricedInstruments:
                                      roundCreationState.roundCreation.tab5
                                        ?.nonPricedInstruments,
                                  },
                                });
                              }}
                            />
                          </VStack>
                        </td>

                        <td className="px-2 py-4 align-top items-center">
                          {/* <CTADropdown
                            dropdownClassName="bottom-auto z-10 absolute"
                            actions={[
                              {
                                name: "Edit",
                                disabled: false,
                              },
                              {
                                name: "Delete",
                                disabled: false,
                              },
                            ]}
                            onAction={(action) =>
                              handleAction(action, {
                                id: captable.id,
                                name: captable.name,
                                instrumentId: captable.instrumentId,
                                instrumentName: captable.instrumentName,
                                shareholderId: captable.shareholderId,
                                shares: captable.noOfShares,
                                fdbShares: captable.fdbShares,
                                totalAmountToInvest: captable.amount,
                                postholdingPercentage:
                                  captable.holdingPercentage,
                                state: NewInvestorState.Update,
                                unLockedInputFeild: {
                                  hasToCalculateAmt: false,
                                  value: 0,
                                },
                                currencyType: "",
                              })
                            }
                          /> */}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {currentTableData.length === 0 && (
                  <EmptyTable
                    header="No Investor List Available"
                    subHeader=""
                  />
                )}
              </div>
            </div>
          </div>
          <Box className="flex justify-between my-4 ">
            {_captableData.length >= 5 && (
              <BasicMenu
                defaultValue={pageSize}
                options={[5, 10, 20, 50, 100]}
                onOptionChange={(value) => {
                  setCurrentPage(1);
                  setPageSize(value);
                }}
              />
            )}
            <Pagination
              currentPage={currentPage}
              totalCount={_captableData?.length}
              pageSize={pageSize}
              onPageChange={(page: number) => setCurrentPage(page)}
            />
          </Box>
        </div>
      </div>
    </VStack>
  );
}
