import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { HStack, Error, VStack } from "../../components/utils";
import { useForgotPassword } from "../../queries/captableLogin";
import {
  LoaderCTAButton,
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../quickRound/CTAButtonComponents";
import { Input, Label } from "../../components/shared/InputField";
import { useAuthStore } from "../../store";
import { loginPageImageUrl } from "../../constants/LoginPageImage";
import useIsMobile from "../../utils/detectDevice";

const initialValues = {
  email: "",
};

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email("Wrong email format")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Please enter valid email"),
});

export default function ForgotPasswordPage() {
  const [loading, setLoading] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  let currentImageCount = 0;
  const { mutate: submitForgotPassword } = useForgotPassword();
  const navigate = useNavigate();

  const companyId = useAuthStore.getState().companyId || "";
  const imageUrl = loginPageImageUrl;
  const imageLength = imageUrl.length;
  const { isMobile } = useIsMobile(1025);

  useEffect(() => {
    //timer to change the login image on right side
    const intervalId = setInterval(() => {
      currentImageCount++;
      setActiveIndex(currentImageCount % imageLength);
    }, 5000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      {!isMobile ? (
        <div className="flex h-screen bg-white w-full">
          <div className="flex flex-col justify-between flex-1 px-4 pb-12 mx-auto sm:px-6 lg:flex-none  md:w-5/12 w-full">
            <div className="pt-[32px] ">
              <img
                className="h-12 mx-auto w-28"
                src={
                  "https://equion-dev.s3.us-west-2.amazonaws.com/logonew.svg"
                }
                alt="hissa_logo"
              />
            </div>
            <div className="w-full max-w-sm mx-auto lg:w-96">
              <div>
                <h2 className="mt-6 text-2xl text-center font-semibold tracking-tight text-[#181C32]">
                  Forgot Password ?
                </h2>
                <p className="mt-2 px-12 text-sm text-center font-medium text-[#7E8299]">
                  Enter the email you used when you joined to reset password
                </p>
              </div>

              <div className="mt-8">
                <div className="mt-6">
                  <Formik
                    enableReinitialize={true}
                    validationSchema={forgotPasswordSchema}
                    initialValues={initialValues}
                    onSubmit={(values) => {
                      setLoading(true);
                      submitForgotPassword(
                        {
                          companyId,
                          email: values.email,
                          senderOrigin: "captable",
                        },
                        {
                          onSuccess: (data) => {
                            toast(
                              "Request to change password sent, Please Check the Mail.",
                              {
                                type: "success",
                                autoClose: 2000,
                              }
                            );
                            setLoading(false);
                          },
                          onError: (err: any) => {
                            setLoading(false);
                            toast(err.response.data.errors[0], {
                              type: "error",
                              autoClose: 2000,
                            });
                          },
                        }
                      );
                    }}
                  >
                    {(formik) => (
                      <Form>
                        <VStack className="gap-4">
                          <div>
                            <Label htmlFor="email">Email</Label>
                            <Input
                              id="email"
                              type="email"
                              autoComplete="email"
                              {...formik.getFieldProps(`email`)}
                            />
                            {formik.touched?.email && formik.errors?.email && (
                              <Error text={formik.errors?.email} />
                            )}
                          </div>

                          <HStack className="justify-center gap-8">
                            {!loading && (
                              <PrimaryCTAButton
                                event-name="Forgot Password Submit"
                                type="submit"
                              >
                                Submit
                              </PrimaryCTAButton>
                            )}
                            {loading && <LoaderCTAButton />}
                            <SecondaryCTAButton
                              event-name="Forgot Password Cancel"
                              onClick={() => {
                                navigate("/login");
                              }}
                            >
                              Cancel
                            </SecondaryCTAButton>
                          </HStack>
                        </VStack>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
            <div></div>
          </div>
          <VStack className="md:w-7/12 md:visible invisible bg-gradient-to-tl from-[#030093] to-[#2E4D9B]/[.54] justify-evenly">
            <VStack className="gap-8">
              <img
                className="inset-0 md:visible invisible object-cover mx-auto w-fit px-32"
                src={imageUrl[activeIndex]}
                alt=""
              />

              {activeIndex === 0 ? (
                <div className="px-10 pb-10 text-2xl font-semibold text-center text-white">
                  Elevate your equity management with{" "}
                  <span className="text-orange-501">Hissa™</span> !
                </div>
              ) : activeIndex === 1 ? (
                <div className="px-10 pb-10 text-2xl font-semibold text-center text-white">
                  <span className="text-orange-501">Hissa™</span> takes the
                  chaos out of the Cap Table!
                </div>
              ) : (
                <div className="px-10 pb-10 text-2xl font-semibold text-center text-white">
                  Value creation through Stock Options just got easier with{" "}
                  <span className="text-orange-501">Hissa™</span> !
                </div>
              )}
            </VStack>
          </VStack>
        </div>
      ) : (
        <div className="flex h-screen bg-white w-full">
          <div className="flex flex-col justify-between flex-1 px-4 pb-12 mx-auto sm:px-6 lg:flex-none w-full">
            <div className="pt-[32px] ">
              <img
                className="h-12 mx-auto w-28"
                src={
                  "https://equion-dev.s3.us-west-2.amazonaws.com/logonew.svg"
                }
                alt="hissa_logo"
              />
            </div>
            <div className="w-full max-w-sm lg:w-96 border border-gray-200 px-4 py-8 rounded-md shadow-box mx-auto">
              <h2 className="text-center text-[28px] font-semibold tracking-tight text-[#181C32]">
                Forgot Password ?
              </h2>
              <p className="mt-2 px-12 text-sm text-center font-medium text-[#7E8299]">
                Enter the email you used when you joined to reset password
              </p>
              <div className="mt-8">
                <div className="mt-6">
                  <Formik
                    enableReinitialize={true}
                    validationSchema={forgotPasswordSchema}
                    initialValues={initialValues}
                    onSubmit={(values) => {
                      setLoading(true);
                      submitForgotPassword(
                        {
                          companyId,
                          email: values.email,
                          senderOrigin: "captable",
                        },
                        {
                          onSuccess: (data) => {
                            toast(
                              "Request to change password sent, Please Check the Mail.",
                              {
                                type: "success",
                                autoClose: 2000,
                              }
                            );
                            setLoading(false);
                          },
                          onError: (err: any) => {
                            setLoading(false);
                            toast(err.response.data.errors[0], {
                              type: "error",
                              autoClose: 2000,
                            });
                          },
                        }
                      );
                    }}
                  >
                    {(formik) => (
                      <Form>
                        <VStack className="gap-4">
                          <div>
                            <Label htmlFor="email">Email</Label>
                            <Input
                              id="email"
                              type="email"
                              autoComplete="email"
                              {...formik.getFieldProps(`email`)}
                            />
                            {formik.touched?.email && formik.errors?.email && (
                              <Error text={formik.errors?.email} />
                            )}
                          </div>

                          <HStack className="justify-center gap-8">
                            {!loading && (
                              <PrimaryCTAButton
                                event-name="Forgot Password Submit"
                                type="submit"
                              >
                                Submit
                              </PrimaryCTAButton>
                            )}
                            {loading && <LoaderCTAButton />}
                            <SecondaryCTAButton
                              event-name="Forgot Password Cancel"
                              onClick={() => {
                                navigate("/login");
                              }}
                            >
                              Cancel
                            </SecondaryCTAButton>
                          </HStack>
                        </VStack>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      )}
    </>
  );
}
