import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { Box } from "../../components/utils";
import { getRandomColor } from "../../utils/historicUtilities";
import { ChartDatamodel } from "../../types/DashboardModel";
import { shareholderHoverOptions } from "../../shared/chart-data";

ChartJS.register(ArcElement, Tooltip, Legend);
export function ShareholderHoverChart({
  chartModel,
}: {
  chartModel: ChartDatamodel[];
}) {
  const _shareHolderChartData = chartModel;
  const values = _shareHolderChartData.map((shareHolderChartDetail) => [
    shareHolderChartDetail.value * 100,
    shareHolderChartDetail.name,
  ]);
  const percent = `${(chartModel[0].value * 100).toFixed(2)}%`;
  const holdingPercentageValues = values.map((value) => value[0]);
  const shareHolderNames = values.map((value) => value[1]);
  const backgroundColor = getRandomColor(shareHolderNames as string[]);
  const planData = {
    labels: shareHolderNames,
    datasets: [
      {
        label: "Shareholders",
        data: holdingPercentageValues,
        backgroundColor: ["#FFC140", "#9D6E48"],
        borderColor: backgroundColor,
        spacing: 0,
        hoverOffset: 1,
      },
    ],
  };

  const plugins = [
    {
      id: "0",
      afterDraw(chart: ChartJS) {
        const { width, height, ctx } = chart;
        ctx.restore();
        const fontSize = (height / 100).toFixed(2);
        ctx.font = `${fontSize}em Inter`;
        ctx.textBaseline = "top";
        const text2 = percent;
        const textX = Math.round((width - ctx.measureText(percent).width) / 2);
        const textY = height / 4;
        ctx.fillText(text2.toString(), textX, textY + 20);
        ctx.save();
      },
    },
  ];

  return (
    <Box className={`lg:w-48 ml-24 w-44 h-fit mx-auto`}>
      {planData && (
        <Doughnut
          options={shareholderHoverOptions}
          data={planData}
          plugins={plugins}
        />
      )}
    </Box>
  );
}
