import jwtDecode from "jwt-decode";
import * as React from "react";
import { currencyTypes } from "../constants/CompanyCurrencyType";
import { useAuthorizationStore, useAuthStore } from "../store";
import { useShareAuthStore } from "../store/useShareAuthStore";
/*
Wrapper components around regular divs to improve code readability
with nested divs
*/

export function HStack(
  props: React.HTMLAttributes<HTMLDivElement>
): React.ReactElement {
  const className = `flex flex-row ${props.className}`;
  return <div {...props} className={className}></div>;
}

export function VStack(
  props: React.HTMLAttributes<HTMLDivElement>
): React.ReactElement {
  const className = `flex flex-col ${props.className}`;
  return <div {...props} className={className}></div>;
}

export function Center(
  props: React.HTMLAttributes<HTMLDivElement>
): React.ReactElement {
  const className = `flex items-center justify-center ${props.className}`;
  return <div {...props} className={className}></div>;
}

export function Box(
  props: React.HTMLAttributes<HTMLDivElement>
): React.ReactElement {
  const className = `${props.className}`;
  return <div {...props} className={className}></div>;
}

export function Error({ text }: { text?: string }) {
  return <p className="text-[9px] text-red-500 my-0.5">{text}</p>;
}

export function Notification({ text }: { text?: string }) {
  return <p className="text-[9px] text-yellow-700 my-0.5">{text}</p>;
}

export function BoxContainer(
  props: React.HTMLAttributes<HTMLDivElement>
): React.ReactElement {
  const className = `flex flex-col bg-white shadow-box border border-borderColor rounded-md px-8 py-10 ${props.className}`;
  return <div {...props} className={className}></div>;
}

function getAccessToken(): string {
  const token = localStorage.getItem("accesstoken") as string;
  return token;
}
export function redirectToHissa(path: string, postLogout = false) {
  const hissaUrl =
    process.env.REACT_APP_LEGACY_URL ||
    localStorage.getItem("hissaUrl") ||
    "https://dev.hissa.com";
  if (postLogout) {
    window.location.href = hissaUrl;
    return;
  }
  window.location.href = `${hissaUrl}/${path}?accessToken=${getAccessToken()}&companyId=${localStorage.getItem(
    "companyId"
  )}`;
}

export function redirectToSharedHissa(id: string) {
  const hissaUrl = `${process.env.REACT_APP_URL}/share/${id}`;
  window.location.href = hissaUrl;
}

export function clearCredentials() {
  const credentialKeys = [
    "accesstoken",
    "hissaUrl",
    "companyId",
    "sharedAccesstoken",
    "x-usr-token",
  ];
  credentialKeys.forEach((key) => {
    localStorage.removeItem(key);
  });
}

export function clearSharedCredentials() {
  const credentialKeys = ["sharedAccesstoken"];
  credentialKeys.forEach((key) => {
    localStorage.removeItem(key);
  });
}

export function decodeAuthToken(accesstoken: string) {
  const decodedToken = jwtDecode(accesstoken) as {
    xUserToken: string;
    exp: number;
  };
  return decodedToken;
}

export function decodeCaptableAuthToken(accesstoken: string) {
  const decodedToken = jwtDecode(accesstoken) as {
    id: string;
    fullName: string;
    emailId: string;
    companyId: string;
    role: string;
    employeeId: string;
    type: string;
  };
  return decodedToken;
}

export function decodeShareAuthToken(shareAccesstoken: string) {
  const decodedToken = jwtDecode(shareAccesstoken) as {
    userId: string;
    emailId: string;
    companyId?: string;
    resourceId?: string;
    id?: string;
    resourceType: string;
    role?: string;
    expiry?: Date | null;
    exp: number;
    iat: number;
  };
  return decodedToken;
}

export function checkTokenValidity() {
  const CLOCK_SKEW_MARGIN = 1000 * 60 * 5; // 5 minutes
  const token = localStorage.getItem("accesstoken");
  if (!token) return true;
  const decodedToken = decodeAuthToken(token);
  const currentDate = new Date();
  if (decodedToken.exp * 1000 - currentDate.getTime() < CLOCK_SKEW_MARGIN)
    return false;
  return true;
}

export function checkSharedTokenValidity() {
  const CLOCK_SKEW_MARGIN = 1000 * 60 * 5; // 5 minutes
  const token = localStorage.getItem("sharedAccesstoken");
  if (!token) return true;
  const decodedToken = decodeShareAuthToken(token);
  const currentDate = new Date();
  if (decodedToken.exp * 1000 - currentDate.getTime() < CLOCK_SKEW_MARGIN)
    return false;
  return true;
}

export function revalidateAuth() {
  if (!checkTokenValidity()) {
    useAuthStore.setState((store) => ({ ...store, isAuthenticated: false }));
  }
}

export function revalidateSharedAuth() {
  if (!checkSharedTokenValidity()) {
    useShareAuthStore.setState((store) => ({
      ...store,
      isShareAuthenticated: false,
    }));
  }
}

export function useGetCompanyName() {
  const { user } = useAuthStore();
  return user?.company?.nameOfTheCompany || user?.nameOfTheCompany;
}

export function isAdminViewer() {
  const { authority } = useAuthorizationStore();
  return authority === "ADMIN_VIEWER";
}

export function isTokenValid(token: string | undefined) {
  const CLOCK_SKEW_MARGIN = 1000 * 60 * 5; // 5 minutes
  if (!token) return false;
  const decodedToken = decodeAuthToken(token);
  const currentDate = new Date();
  if (decodedToken.exp * 1000 - currentDate.getTime() < CLOCK_SKEW_MARGIN)
    return false;
  return true;
}
