export function _trimAll(str: string) {
  return str.trim().replace(/ +(?= )/g, "");
}

export function limitString(str: string, limit: number) {
  if (str.length > limit) {
    return str.substring(0, limit).concat(" . . .");
  } else {
    return str.substring(0, limit);
  }
}

export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}
