import React from "react";
import { VStack } from "../components/utils";

const EmptyPageIllustration = ({ text }: { text: string }) => (
  <VStack className="w-full mx-auto my-auto placeholderLoader">
    <img
      src="/emptyPage.png"
      className="h-44 my-5 w-56 mx-auto placeholderLoader"
      alt="no data"
    />
    <p className="mx-auto text-labelColor ">{text}</p>
  </VStack>
);

export default EmptyPageIllustration;
