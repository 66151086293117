import { useEffect, useState } from "react";
import { Dialog } from "@mui/material";
import { toast } from "react-toastify";
import { HStack, VStack } from "../../components/utils";
import {
  ButtonSize,
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../quickRound/CTAButtonComponents";
import AddNewSecondaryModal from "./SecondaryModal";
import { useRoundModelStore } from "../../store/roundModelingStore";
import { ModelState, SecondaryAllotmentDetails } from "../../types/Modeling";
import AddBlendedSecondaryModal from "./SecondaryBlendedModal";
import SecondaryAgTable from "./SecondaryAGTable";
import { queryClient } from "../../queries";

export default function SecondaryPageTab() {
  const {
    roundCreation: data,
    sendMessage: onNext,
    setRoundCreation: setRoundState,
    connect,
    saveModel,
  } = useRoundModelStore();
  const [dialog, setDialog] = useState<{
    open: boolean;
    mode?: "Add" | "Blended";
    data?: SecondaryAllotmentDetails;
  }>({
    open: false,
  });
  //  Write a code to print all th element in the array

  function scrollToTop() {
    const scroller = document.querySelector("#outlet");
    scroller?.scrollTo(0, 0);
  }
  const [secondaryDetails, setSecondaryDetails] = useState<
    SecondaryAllotmentDetails[]
  >([]);
  useEffect(() => {
    const secondaries: SecondaryAllotmentDetails[] = [];
    data.secondaries?.secondaryAllotmentDetails.forEach(
      (secondary: SecondaryAllotmentDetails) => {
        if (secondary.isBlendedSecondary && secondary) {
          secondary?.sellerDetails?.forEach((element) => {
            const secondariesDetails = {
              id: secondary.id,
              sellerId: element.sellerId,
              buyerId: secondary.buyerId,
              sellerName: element.sellerName,
              buyerName: secondary.buyerName,
              fdbShares: element.fdbShares,
              amountInvested: element.amount,
              sharePrice: secondary.sharePrice,
              secondaryHoldingPercentage: element.holdingPercentage,
              postHoldingPercentage: 0,
              isBlendedSecondary: true,
            };
            secondaries.push(secondariesDetails);
          });
        } else {
          secondaries.push(secondary);
        }
      }
    );
    setSecondaryDetails(secondaries);
  }, [data.secondaries?.secondaryAllotmentDetails]);
  return (
    <VStack className="justify-between min-h-full py-4 bg-white rounded-lg ">
      <Dialog open={dialog.open} maxWidth="lg">
        {dialog.mode === "Add" && (
          <AddNewSecondaryModal
            data={dialog?.data}
            actionMode={"New"}
            onBack={() => setDialog({ open: false })}
            onNext={onNext}
          />
        )}
        {dialog.mode === "Blended" && (
          <AddBlendedSecondaryModal
            data={dialog?.data}
            actionMode={"New"}
            onBack={() => setDialog({ open: false })}
            onNext={onNext}
          />
        )}
      </Dialog>

      <div className="px-2">
        <SecondaryAgTable secondaryDetails={secondaryDetails} />
      </div>
      <HStack className="justify-between gap-4 px-6 mt-8">
        <HStack className="gap-4">
          <SecondaryCTAButton
            event-name="Secondary Tab Back"
            type="reset"
            buttonSize={ButtonSize.SMALL}
            onClick={() => {
              onNext({ ...data, currentTab: 4 });
              scrollToTop();
            }}
          >
            Back
          </SecondaryCTAButton>
        </HStack>
        <HStack>
          <div className="justify-end pr-4 ">
            <PrimaryCTAButton
              className="h-8"
              event-name="Save Model Button"
              onClick={() => {
                connect();
                saveModel(data);

                toast(
                  `Saved ${data?.roundDetailsTab?.roundName} round model successfully`,
                  {
                    type: "success",
                    autoClose: 2000,
                  }
                );
                queryClient.invalidateQueries("listofmodeling");
              }}
              buttonSize={ButtonSize.SMALL}
            >
              Save Model
            </PrimaryCTAButton>
          </div>
          <PrimaryCTAButton
            event-name="Secondary Tab Next"
            onClick={() => {
              setRoundState({
                ...data,
                isNavigating: true,
              });
              onNext({
                ...data,
                currentTab: 6,
                modelState: ModelState.ToBeSaved,
              });
              queryClient.invalidateQueries("listofmodeling");
              scrollToTop();
            }}
            loading={data.isNavigating}
            buttonSize={ButtonSize.SMALL}
            type="submit"
          >
            Next
          </PrimaryCTAButton>
        </HStack>
      </HStack>
    </VStack>
  );
}
