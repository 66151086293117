import { Box } from "@mui/material";
import _ from "lodash";
import { HStack, VStack } from "../../components/utils";
import { PreviewQuickRoundResponseModel } from "../../types/previewQuickRound";
import { ShareholderInstrumentModel } from "../../types/quickRound";
import {
  getCurrencySymbol,
  getCurrencyType,
} from "../../utils/currencyFormatter";
import { formatDisplayDate } from "../../utils/date";
import { getSecurityColor } from "../../utils/historicUtilities";
import { InstrumentClass } from "../../types/DashboardModel";

export default function QuickBonusPreviewDetails({
  quickBonusDetails,
  instrumentClasses,
}: {
  quickBonusDetails: PreviewQuickRoundResponseModel | undefined;
  instrumentClasses: InstrumentClass[];
}) {
  const currency = getCurrencyType();
  const currencySymbol = getCurrencySymbol();
  const quickBonusInstruments =
    quickBonusDetails?.transactions?.quickBonusTransactions?.map(
      (transaction) => transaction.instrumentClassId
    );
  const instruments =
    instrumentClasses.filter((instrumentClass) =>
      quickBonusInstruments?.includes(instrumentClass.id)
    ) || [];

  const eventInfo = quickBonusDetails?.eventInfo;
  const newFdbShares =
    (eventInfo?.valuation || 0) / (eventInfo?.pps || 1) -
    (quickBonusDetails?.preEventFdb || 0);
  const newShares = _.sum(
    quickBonusDetails?.transactions?.quickBonusTransactions?.map(
      (transaction) => transaction.newNumberOfShares
    )
  );

  return (
    <Box className="px-7 py-4 pb-10 mx-4 border border-borderColor shadow-box bg-white rounded">
      <VStack>
        <p className="text-base font-semibold text-[#464E5F]">
          {quickBonusDetails?.eventInfo?.name}
        </p>
        <HStack>
          {instruments.map((instrument) => (
            <span
              key={instrument.id}
              className={`inline-flex px-2 ${getSecurityColor(
                instrument.subType
              )} text-xs font-semibold leading-5`}
            >
              {instrument.subType}
            </span>
          ))}
        </HStack>
      </VStack>
      <HStack className="w-full items-center justify-start gap-8 md:flex-row flex-col text-[#464E5F] flex-wrap">
        <BonusDetailsCard
          label="Bonus Date"
          value={formatDisplayDate(quickBonusDetails?.eventDate || "")}
        />
        <BonusDetailsCard
          label="New Paid Up Shares"
          value={newShares.toLocaleString(currency)}
        />
        <BonusDetailsCard
          label="New FDB Shares"
          value={newFdbShares.toLocaleString(currency)}
        />
        <Box className="p-1 shadow-box border border-borderColor bg-white rounded mt-5 mr-10 whitespace-nowrap">
          <HStack className="justify-center px-5">
            <VStack className="items-center mr-3">
              <p className="text-sm1 font-semibold text-[#464E5F]">
                {`${currencySymbol} ${quickBonusDetails?.eventInfo?.pps.toLocaleString(
                  currency
                )}`}
              </p>
              <p className="text-xxs">(Post Bonus)</p>
            </VStack>
            <VStack className="items-center">
              <p className="text-xxs1">
                {`${currencySymbol} ${quickBonusDetails?.preEventPps.toLocaleString(
                  currency
                )}`}
              </p>
              <p className="text-xxs">(Pre Bonus)</p>
            </VStack>
          </HStack>
          <VStack className="px-5 items-center">
            <p className="font-medium text-xxs1">Price Per Share</p>
          </VStack>
        </Box>
      </HStack>
    </Box>
  );
}

function BonusDetailsCard({
  value,
  label,
}: {
  value: number | string;
  label: string;
}) {
  return (
    <Box className="px-5 py-3 shadow-box border border-borderColor bg-white rounded mt-5">
      <VStack className="items-center">
        <p className="font-semibold whitespace-nowrap text-[#464E5F]">
          {value}
        </p>
        <p className="font-medium text-xxs1 whitespace-nowrap">{label}</p>
      </VStack>
    </Box>
  );
}
