import { Form, Formik } from "formik";
import _, { map } from "lodash";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { RoundTag } from "../components/AllotmentTag";
import { Input, Label } from "../components/shared/InputField";
import { Select } from "../components/shared/Select";
import { SwitchButton } from "../components/shared/SwitchButton";
import { HStack, VStack, Error, Box } from "../components/utils";

import {
  CapTableBarChart,
  ShareHolderCaptableChartModel,
} from "../pages/newQuickTransaction/CapTableBarChart";
import { ShareHolderCapTableQuickRound } from "../pages/newQuickTransaction/ShareHolderCapTableQuickRound";
import { useGetQuickRoundPreview } from "../queries/quickRound";
import {
  useGetConversion,
  useUpdateConversionDetails,
} from "../queries/shareholders";
import { useApproveTransactionPreview } from "../queries/transactionRound";
import { useAuthStore } from "../store";

import { PreviewQuickRoundResponseModel } from "../types/previewQuickRound";
import {
  SecurityConversionModel,
  SecurityConversionUpdateModel,
  ShareholderSecurityModel,
} from "../types/Shareholder";
import { formatCurrency, getCurrencyType } from "../utils/currencyFormatter";
import { getSecurityColor } from "../utils/historicUtilities";
import {
  ButtonSize,
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../pages/quickRound/CTAButtonComponents";

export function AddConversionDetails({
  shareholderSecurity,
  onPrimaryAction = () => {},
  onSecondaryAction = () => {},
  shareholderId,
  certificateId,
}: {
  shareholderSecurity: ShareholderSecurityModel;
  onPrimaryAction: () => void;
  onSecondaryAction: () => void;
  shareholderId: string;
  certificateId: string;
}) {
  const [isApprove, setIsApprove] = useState(false);
  const currencyType = getCurrencyType();

  const companyId = useAuthStore.getState().companyId || "";

  const [eventId, setEventId] = useState();

  const { mutate: updateConversion, data: conversionDetails } =
    useUpdateConversionDetails();

  const { refetch: getQuickRoundPreview, data: previewData } =
    useGetQuickRoundPreview(eventId || "");

  const { mutate: approveShareholderConversion } =
    useApproveTransactionPreview();

  const handleSave = (values: SecurityConversionUpdateModel) => {
    const conversionDetails: SecurityConversionUpdateModel = {
      companyId,
      securityClassId: shareholderSecurity?.securityClassId || "",
      shareholderId: shareholderId || "",
      conversionDate: values.conversionDate,
      convertibleShares: values.convertibleShares,
      useFdbAtConversion: values.useFdbAtConversion,
      fdbShares: values.fdbShares,
      newSharePrice: values.newSharePrice,
      updateConversionRatio: values.updateConversionRatio,
      conversionSecurityClass: values.conversionSecurityClass,
      convertibleSecurityClassId:
        securityClasses?.find(
          (securityClass) =>
            securityClass.securityClassName === values.conversionSecurityClass
        )?.securityClassId || "",
    };

    updateConversion(conversionDetails, {
      onSuccess: async (data) => {
        setEventId(data.id);
        setIsApprove(true);
        setconversionFormValues(values);
        toast("Conversion Details Saved Successfully!", {
          type: "success",
          autoClose: 2000,
        });
      },

      onError: (err: any) => {
        toast(err.response.data.errorMessage, {
          type: "error",
          autoClose: 2000,
        });
      },
    });
  };

  useEffect(() => {
    getQuickRoundPreview();
  }, [conversionDetails]);

  const { data: securityConversionModel } = useGetConversion(
    shareholderSecurity.securityClassId || "",
    shareholderId || "",
    certificateId || ""
  );

  const securityTypes = securityConversionModel?.securityType.map(
    (security) => security.securityType
  );
  const [selectedSecurityType, setSelectedSecurityType] = useState<string>("");
  const [securityClasses, setSecurityClasses] = useState<
    {
      securityClassId: string;
      securityClassName: string;
    }[]
  >();

  useEffect(() => {
    setSecurityClasses(
      securityConversionModel?.securityType.find(
        (security) => security.securityType === selectedSecurityType
      )?.securityList || []
    );
  }, [selectedSecurityType]);

  const [conversionFormValues, setconversionFormValues] =
    useState<SecurityConversionUpdateModel>({
      companyId: "",
      conversionDate: "",
      convertibleShares: 0,
      updateConversionRatio: false,
      conversionSecurityClass: "",
      convertibleSecurityClassId: "",
      securityClassId: "",
      shareholderId: "",
      useFdbAtConversion: true,
      fdbShares: 0,
      newSharePrice: 0,
      securityType: "",
    });

  const [showChart, setShowChart] = useState(false);
  const [roundShares, setRoundShare] = useState<number>(0);
  const [chartData, setChartData] = useState<ShareHolderCaptableChartModel[]>(
    []
  );

  useEffect(() => {
    previewData?.holdingDifference?.forEach((shareholder) => {
      setChartData((state) => [
        ...state,
        {
          name: shareholder.name,
          preHoldingFdbPercentage: shareholder.preHoldingFdbPercentage,
          postHoldingFdbPercentage: shareholder.postHoldingFdbPercentage,
        },
      ]);
    });
  }, [previewData, isApprove]);

  const securityConversionInputSchema = Yup.object({
    conversionDate: Yup.string().required().label("Conversion Date"),
    convertibleShares: Yup.number().required().label("Convertible Shares"),
    useFdbAtConversion: Yup.boolean().label("useFdbAtConversion"),
    updateConversionRatio: Yup.boolean().label("Update ConversionRatio"),
    fdbShares: Yup.number().when("useFdbAtConversion", {
      is: (val: boolean) => val === true,
      then: Yup.number().required().label("FDB Shares"),
      otherwise: Yup.number().label("FDB Shares"),
    }),
    newSharePrice: Yup.number().when("useFdbAtConversion", {
      is: (val: boolean) => val === false,
      then: Yup.number().required().label("Share Price"),
      otherwise: Yup.number().label("Share Price"),
    }),
    conversionSecurityClass: Yup.string().when("updateConversionRatio", {
      is: (val: boolean) => val === false,
      then: Yup.string().required().label("Security Class"),
      otherwise: Yup.string().label("Security Class"),
    }),
    securityType: Yup.string().when("updateConversionRatio", {
      is: (val: boolean) => val === false,
      then: Yup.string().required().label("Security Type"),
      otherwise: Yup.string().label("Security Type"),
    }),
  });

  return (
    <div className="w-full bg-white rounded-md">
      <VStack className="text-left font-medium">
        <VStack className="text-lg font-medium border-b p-5">
          <HStack className="justify-between">
            <p className="font-semibold text-lg mb-3">Conversion</p>
            <span
              onClick={() => onSecondaryAction()}
              className="cursor-pointer"
            >
              X
            </span>
          </HStack>
          <HStack className="justify-start font-medium text-base">
            <p className="mr-10">{shareholderSecurity?.securityClass}</p>
            <HStack className="mr-2">
              <p className="mr-3">Rounds - </p>
              <div className="mt-1">
                <RoundTag roundIdentifier={shareholderSecurity?.round} />
              </div>
            </HStack>
            <p>
              Security -
              <span
                className={`inline-flex px-2 ${getSecurityColor(
                  shareholderSecurity.securityType
                )} text-xs font-semibold leading-5`}
              >
                {shareholderSecurity.securityType}
              </span>
            </p>
          </HStack>
        </VStack>
        {securityConversionModel && (
          <AddConversionDetailCards
            securityConversion={securityConversionModel}
            shareholderSecurity={shareholderSecurity}
            currencyType={currencyType}
          />
        )}

        {!isApprove ? (
          <div>
            <Formik
              key="addQuickBuybackParticipant"
              enableReinitialize={true}
              initialValues={conversionFormValues}
              validationSchema={securityConversionInputSchema}
              onSubmit={(values) => {
                handleSave(values);
              }}
            >
              {(formik) => (
                <Form key="shareholder">
                  <VStack className="w-full px-5 py-4 gap-6">
                    <HStack className="gap-5 px-20">
                      <div className="w-2/5">
                        <Label className="text-sm font-medium">
                          Conversion Date
                        </Label>
                        <Input
                          type="date"
                          placeholder="Eg:DD/MM/YYYY"
                          className="w-80"
                          {...formik.getFieldProps("conversionDate")}
                        />
                        {formik.touched?.conversionDate &&
                          formik.errors?.conversionDate && (
                            <Error text={formik.errors?.conversionDate} />
                          )}
                      </div>
                      <div className="w-1/5"></div>
                      <div className="w-2/5">
                        <Label className="text-sm font-medium">
                          Convertible Shares
                        </Label>
                        <Input
                          type="number"
                          placeholder="Enter Convertible Shares"
                          className="w-80"
                          {...formik.getFieldProps("convertibleShares")}
                        />
                        {formik.touched?.convertibleShares &&
                          formik.errors?.convertibleShares && (
                            <Error text={formik.errors?.convertibleShares} />
                          )}
                      </div>
                    </HStack>
                    <div>
                      <HStack>
                        <div className="flex flex-row-reverse items-center  text-sm text-gray-600 font-medium ">
                          <SwitchButton
                            className="text-sm font-medium items-center m-1"
                            value={formik.values.useFdbAtConversion}
                            label="Use FDB At Conversion"
                            onClick={() => {
                              formik.setFieldValue(
                                "useFdbAtConversion",
                                !formik.values.useFdbAtConversion
                              );
                            }}
                          />
                        </div>
                      </HStack>
                    </div>
                    <HStack className="gap-5 px-20">
                      <div className="w-2/5">
                        <Label className="text-sm font-medium">
                          FDB Shares
                        </Label>
                        <Input
                          type="number"
                          placeholder="Enter FDB Shares"
                          className="w-80"
                          {...formik.getFieldProps("fdbShares")}
                          disabled={!formik.values.useFdbAtConversion}
                        />
                        {formik.touched?.fdbShares &&
                          formik.errors?.fdbShares && (
                            <Error text={formik.errors?.fdbShares} />
                          )}
                      </div>
                      <div className="w-1/5"></div>
                      <div className="w-2/5">
                        <Label className="text-sm font-medium">
                          Share Price
                        </Label>
                        <Input
                          type="number"
                          placeholder="Enter Share Price"
                          className="w-80"
                          {...formik.getFieldProps("newSharePrice")}
                          disabled={formik.values.useFdbAtConversion}
                        />
                        {formik.touched?.newSharePrice &&
                          formik.errors?.newSharePrice && (
                            <Error text={formik.errors?.newSharePrice} />
                          )}
                      </div>
                    </HStack>
                    <div>
                      <HStack>
                        <div className="flex flex-row-reverse items-center  text-sm text-gray-600 font-medium ">
                          <SwitchButton
                            className="text-sm font-medium items-center m-1"
                            value={formik.values.updateConversionRatio}
                            label="Update Conversion Ratio"
                            onClick={() => {
                              formik.setFieldValue(
                                "updateConversionRatio",
                                !formik.values.updateConversionRatio
                              );
                            }}
                          />
                        </div>
                      </HStack>
                    </div>
                    <HStack className="gap-5 px-20">
                      <div className="w-2/5">
                        <Label className="text-sm font-normal">
                          Security Type
                        </Label>
                        <Select
                          options={securityTypes || []}
                          placeholder="Select Security Type"
                          className="w-80"
                          {...formik.getFieldProps("securityType")}
                          onChange={(e) => {
                            setSelectedSecurityType(e.target.value);
                            formik.handleChange(e);
                          }}
                          disabled={formik.values.updateConversionRatio}
                        />
                        {formik.touched?.securityType &&
                          formik.errors?.securityType && (
                            <Error text={formik.errors?.securityType} />
                          )}
                      </div>
                      <div className="w-1/5"></div>
                      <div className="w-2/5">
                        <Label className="text-sm font-normal">
                          Security Class
                        </Label>
                        <Select
                          options={
                            securityClasses?.map(
                              (securityClass) => securityClass.securityClassName
                            ) || []
                          }
                          placeholder="select Security Class"
                          className="w-80"
                          {...formik.getFieldProps("conversionSecurityClass")}
                          disabled={formik.values.updateConversionRatio}
                        />
                        {formik.touched?.conversionSecurityClass &&
                          formik.errors?.conversionSecurityClass && (
                            <Error
                              text={formik.errors?.conversionSecurityClass}
                            />
                          )}
                      </div>
                    </HStack>

                    <HStack className="justify-between mt-10 h-8">
                      <SecondaryCTAButton
                        event-name="Back Conversion Details"
                        buttonSize={ButtonSize.SMALL}
                        onClick={() => {
                          onSecondaryAction();
                        }}
                      >
                        Back
                      </SecondaryCTAButton>
                      <PrimaryCTAButton
                        event-name="Save Conversion Details"
                        buttonSize={ButtonSize.SMALL}
                        type="submit"
                      >
                        Save & Continue
                      </PrimaryCTAButton>
                    </HStack>
                  </VStack>
                </Form>
              )}
            </Formik>
          </div>
        ) : (
          <VStack className="p-5">
            <VStack className="gap-4 my-4 mx-2 bg-white shadow-box rounded-lg">
              <div>
                {showChart && chartData && (
                  <div className="flex-1">
                    <CapTableBarChart
                      shareHolderChartData={chartData}
                      dilutedPercentage={
                        roundShares /
                        ((previewData?.preEventFdb || 0) + roundShares)
                      }
                      toggleChartAndToggle={() => {
                        setShowChart(!showChart);
                      }}
                    />
                  </div>
                )}
                {!showChart && (
                  <HStack className="w-full">
                    {previewData?.holdingDifference && (
                      <ShareHolderCapTableQuickRound
                        data={previewData?.holdingDifference || []}
                        toggle={() => {
                          setShowChart(!showChart);
                        }}
                      />
                    )}
                  </HStack>
                )}
              </div>
            </VStack>
            <HStack className="justify-between mt-10 h-8">
              <SecondaryCTAButton
                event-name="Back From Approve Conversion Details"
                buttonSize={ButtonSize.SMALL}
                onClick={() => {
                  setIsApprove(false);
                }}
              >
                Back
              </SecondaryCTAButton>
              <PrimaryCTAButton
                event-name="Approve Conversion Details"
                buttonSize={ButtonSize.SMALL}
                onClick={() => {
                  approveShareholderConversion(
                    {
                      companyId,
                      eventId: eventId || "",
                      valuation: previewData?.eventInfo?.valuation || 0,
                      pricePerShare: previewData?.eventInfo?.pps || 0,
                    },
                    {
                      onSuccess: (data) => {
                        onPrimaryAction();
                        toast("Conversion Details Updated Successfully!", {
                          type: "success",
                          autoClose: 2000,
                        });
                      },
                      onError: (err: any) => {
                        toast(err.response.data.errorMessage, {
                          type: "error",
                          autoClose: 2000,
                        });
                      },
                    }
                  );
                }}
              >
                Approve
              </PrimaryCTAButton>
            </HStack>
          </VStack>
        )}
      </VStack>
    </div>
  );
}

function ConversionDetailsCard({
  value,
  label,
}: {
  value: number | string;
  label: string;
}) {
  return (
    <Box className="py-4 shadow-box bg-white rounded-md mt-3 flex-wrap mx-10 px-2">
      <VStack>
        <div className="text-center text-sm font-semibold">{value}</div>
        <div className="text-center text-xs font-medium">{label}</div>
      </VStack>
    </Box>
  );
}

function AddConversionDetailCards({
  securityConversion,
  shareholderSecurity,
  currencyType,
}: {
  securityConversion: SecurityConversionModel;
  shareholderSecurity: ShareholderSecurityModel;
  currencyType: string;
}) {
  return (
    <Box className="border-b">
      <VStack className="px-14 mt-5 mb-10">
        <HStack>
          <div className="w-1/3">
            <ConversionDetailsCard
              value={`1:${securityConversion.originalConversionRatio.toLocaleString(
                currencyType
              )}`}
              label="Original conversion Ratio"
            />
          </div>
          <div className="w-1/3">
            <ConversionDetailsCard
              value={formatCurrency(
                securityConversion.baseValuation,
                currencyType
              )}
              label="Base Valuation"
            />
          </div>
          <div className="w-1/3">
            <ConversionDetailsCard
              value={formatCurrency(
                securityConversion.sharePrice,
                currencyType
              )}
              label="Share Price"
            />
          </div>
        </HStack>
        <HStack>
          <div className="w-1/3">
            <ConversionDetailsCard
              value={formatCurrency(
                shareholderSecurity.amountInvested,
                currencyType
              )}
              label="Investment Amount"
            />
          </div>
          <div className="w-1/3">
            <ConversionDetailsCard
              value={shareholderSecurity.numberOfShares.toLocaleString(
                currencyType
              )}
              label="No.of Shares"
            />
          </div>
          <div className="w-1/3">
            <ConversionDetailsCard
              value={formatCurrency(
                securityConversion.valuationCap,
                currencyType
              )}
              label="Valuation Cap"
            />
          </div>
        </HStack>
      </VStack>
    </Box>
  );
}
