import { QueryFunctionContext } from "react-query";
import { ChangePasswordDetails, UserProfile } from "../types/UserDetailModel";
import api from "./capTableApi";

export async function getUserProfile(
  context: QueryFunctionContext
): Promise<UserProfile> {
  return api.get("/v1/auth/userDetails").then((res) => res.data.data);
}

export async function saveUserProfile(data: UserProfile): Promise<void> {
  return api.put("/v1/auth/userDetails", data);
}

export async function changePassword(data: ChangePasswordDetails) {
  return api.post("/v1/auth/changePassword", data).then((res) => res.data);
}
