import { useEffect, useMemo, useState } from "react";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router";
import { Action } from "../../components/shared/Dropdown";
import { HStack } from "../../components/utils";
import { globalFilter, sort } from "../../utils/arrays";
import Avatar from "../Utility/Avatar";
import { getSecurityColor } from "../../utils/historicUtilities";
import {
  PrimaryTransactionDataModel,
  SecondaryTransactionDataModel,
} from "../../types/quickRound";
import { limitString } from "../../utils/string";
import { useQuickSecondariesStore } from "../../store/QuickSecondaryTransactionStore";
import {
  formatCurrency,
  getCurrencySymbol,
  getCurrencyType,
} from "../../utils/currencyFormatter";
import { formatDisplayDate } from "../../utils/date";
import { EmptyTable } from "../quickRound/EmptyTableComponents";
import { CaptableTag } from "../../components/AllotmentTag";
import { useSelectedShareholderFilterStore } from "../../store/selectedShareholderTypeStore";
import {
  TableHeader,
  THead,
  tableEntryStyle,
} from "../../components/TableComponent";
import TextToolTip from "../shareHolderRights/TextToolTip";
import { handleEventForTracking } from "../../amplitudeAnalytics";

function QuickSecondariesTransactions({
  isPreviewOnly = false,
  quickSecondaries = [],
}: {
  isPreviewOnly?: boolean;
  quickSecondaries?: SecondaryTransactionDataModel[];
}) {
  const currency = getCurrencyType();
  const navigate = useNavigate();
  const currencySymbol = getCurrencySymbol();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [globalFilterText, setGlobalFilterText] = useState("");
  const [sortField, setSortField] = useState<{
    field: keyof PrimaryTransactionDataModel | "" | undefined;

    ascending: boolean;
  }>({ field: "name", ascending: false });
  const [sortSecondaryField, setSecondarySortField] = useState<{
    field: keyof SecondaryTransactionDataModel | "" | undefined;
    ascending: boolean;
  }>({ field: "buyerName", ascending: false });
  const quickSecondariesStore = useQuickSecondariesStore();
  const shareholderFilter = useSelectedShareholderFilterStore();
  useEffect(() => {
    if (quickSecondaries.length > 0)
      quickSecondariesStore.setAllSecondaries(quickSecondaries);
  }, [quickSecondaries]);

  const [dialog, setDialog] = useState<{
    open: boolean;
    message?: string;
    data?: PrimaryTransactionDataModel | SecondaryTransactionDataModel;
    index?: number;
    mode?: "Edit" | "Delete";
  }>({
    open: false,
  });

  const secondaryTransactions = useMemo(() => {
    if (!quickSecondariesStore.secondaries) return [];
    const filterResult = globalFilter(
      quickSecondariesStore.secondaries,
      globalFilterText,
      [
        "buyerName",
        "sellerName",
        "security",
        "numberOfShares",
        "amountInvested",
        "date",
      ]
    );
    const sortResult = sort(
      filterResult,
      sortSecondaryField?.field,
      sortSecondaryField?.ascending
    );
    return sortResult;
  }, [quickSecondariesStore.secondaries, globalFilterText, sortField]);

  const currentSecondaryTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return secondaryTransactions.slice(firstPageIndex, lastPageIndex);
  }, [secondaryTransactions, currentPage, pageSize]);

  function handleAction(
    action: Action,
    data: PrimaryTransactionDataModel | SecondaryTransactionDataModel
  ) {
    const secondaryIndex = secondaryTransactions.findIndex(
      (individualInvestor) => individualInvestor === data
    );

    if (action.name === "Edit" && !action.disabled) {
      quickSecondariesStore.removeSecondaries(
        data as SecondaryTransactionDataModel
      );
      setDialog({
        open: true,
        mode: "Edit",
        data,
      });
    } else if (action.name === "Delete" && !action.disabled) {
      setDialog({
        open: true,
        mode: "Delete",
        data,
        message: `Do you want to delete the secondary transaction?`,
        index: secondaryIndex,
      });
    }
  }
  return (
    <div className="px-7  py-4 border border-borderColor pb-10 mx-4 shadow-box bg-white rounded-md">
      <div className="mx-2">
        <HStack className="text-lg text-left font-medium border-b py-4 justify-between">
          <p className="mr-12 font-medium text-[#464E5F] text-lg">
            Transaction
          </p>

          <HStack className="mt-4 lg:mt-0 sm:ml-16 justify-end">
            <HStack className="items-center p-2 rounded w-72 bg-slate-light text-slate-dark mr-8">
              <Icon icon="fe:search" width="24" className="mr-2 " />
              <input
                type="text"
                className="w-full font-medium border-0 outline-none text-xs2 bg-inherit"
                placeholder="Search..."
                value={globalFilterText}
                onChange={(e) => {
                  setGlobalFilterText(e.target.value);
                }}
              ></input>
            </HStack>
          </HStack>
        </HStack>
      </div>
      <div>
        <div className="inline-block w-full py-2 align-middle mb-4">
          <div className="overflow-x-auto md:rounded-lg mb-4">
            <table className="min-w-full bg-white">
              <thead>
                <TableHeader>
                  <THead
                    fieldName="buyerName"
                    className="pl-0 ml-0"
                    isFirstFeild={true}
                    fieldDisplayName="Buyer Name"
                    selectedFieldName={sortSecondaryField?.field || ""}
                    isAscending={sortSecondaryField?.ascending || false}
                    onChangeSort={() => {
                      setSecondarySortField({
                        field: "buyerName",
                        ascending: !sortSecondaryField?.ascending,
                      });
                    }}
                  />

                  <THead
                    fieldName="sellerName"
                    fieldDisplayName="Seller Name"
                    selectedFieldName={sortSecondaryField?.field || ""}
                    isAscending={sortSecondaryField?.ascending || false}
                    onChangeSort={() => {
                      setSecondarySortField({
                        field: "sellerName",
                        ascending: !sortSecondaryField?.ascending,
                      });
                    }}
                  />

                  <THead
                    fieldName="security"
                    fieldDisplayName="Security"
                    selectedFieldName={sortSecondaryField?.field || ""}
                    isAscending={sortSecondaryField?.ascending || false}
                    onChangeSort={() => {
                      setSecondarySortField({
                        field: "security",
                        ascending: !sortSecondaryField?.ascending,
                      });
                    }}
                  />

                  <THead
                    fieldName="numberOfShares"
                    fieldDisplayName="No.of shares"
                    selectedFieldName={sortSecondaryField?.field || ""}
                    isAscending={sortSecondaryField?.ascending || false}
                    onChangeSort={() => {
                      setSecondarySortField({
                        field: "numberOfShares",
                        ascending: !sortSecondaryField?.ascending,
                      });
                    }}
                  />

                  <THead
                    fieldName="amountInvested"
                    fieldDisplayName="Amount"
                    showCurrency={true}
                    selectedFieldName={sortSecondaryField?.field || ""}
                    isAscending={sortSecondaryField?.ascending || false}
                    onChangeSort={() => {
                      setSecondarySortField({
                        field: "amountInvested",
                        ascending: !sortSecondaryField?.ascending,
                      });
                    }}
                  />

                  <THead
                    fieldName="date"
                    fieldDisplayName="Date"
                    selectedFieldName={sortSecondaryField?.field || ""}
                    isAscending={sortSecondaryField?.ascending || false}
                    onChangeSort={() => {
                      setSecondarySortField({
                        field: "date",
                        ascending: !sortSecondaryField?.ascending,
                      });
                    }}
                  />
                </TableHeader>
              </thead>
              <tbody>
                {currentSecondaryTableData.map((transactionData) => (
                  <tr
                    key={`${transactionData.buyerInvestorId}${transactionData.sellerInvestorId}}`}
                    className="border-t border-dashed hover:bg-slate-50"
                  >
                    <td className="whitespace-nowrap py-4 pr-3 text-sm">
                      <div className="flex items-center">
                        <div className="h-10 w-10 flex-shrink-0">
                          <Avatar name={transactionData.buyerName || ""} />
                        </div>
                        <div className="ml-4 cursor-pointer">
                          {transactionData && (
                            <div
                              onClick={() => {
                                handleEventForTracking({
                                  eventName: `Shareholder Name`,
                                });
                                navigate(
                                  `/shareholders/individual/${transactionData.buyerInvestorId}`
                                );
                              }}
                              className={tableEntryStyle}
                            >
                              <TextToolTip
                                text={transactionData.buyerName}
                                className={""}
                              />
                            </div>
                          )}
                          <div
                            onClick={() => {
                              shareholderFilter.setShareholderType(
                                transactionData.investorType ?? "Others"
                              );
                              handleEventForTracking({
                                eventName: `Shareholder Type`,
                              });
                              navigate("/shareholders/overview");
                            }}
                          >
                            <CaptableTag
                              tagName={transactionData.investorType ?? "Others"}
                            />
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="px-3 py-4 whitespace-nowrap">
                      <div className={tableEntryStyle}>
                        <TextToolTip
                          text={transactionData.sellerName}
                          className={""}
                        />
                      </div>
                    </td>

                    <td className="whitespace-nowrap justify-center py-4 text-sm text-gray-500">
                      <span
                        className={`inline-flex px-2 ${getSecurityColor(
                          transactionData.security
                        )} text-xs font-semibold leading-5`}
                      >
                        {limitString(transactionData.security || "", 40)}
                      </span>
                      <p className="text-gray-500 text-center justify-center  text-xs1">
                        <span>
                          PAR:{" "}
                          {formatCurrency(transactionData.par || 0, currency)}
                        </span>
                        <span className="text-black"> | </span>
                        <span>
                          Conversion Ratio: {transactionData.conversionRatio}
                        </span>
                      </p>
                    </td>
                    <td className="px-3 py-4 whitespace-nowrap">
                      <p className={tableEntryStyle}>
                        {transactionData.numberOfShares.toLocaleString(
                          currency
                        )}
                      </p>
                    </td>

                    <td className="px-3 py-4 whitespace-nowrap">
                      <p className={tableEntryStyle}>
                        {transactionData.amountInvested.toLocaleString(
                          currency
                        )}
                      </p>
                    </td>

                    <td className="px-3 py-4 whitespace-nowrap">
                      <p className={tableEntryStyle}>
                        {formatDisplayDate(transactionData.date)}
                      </p>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {currentSecondaryTableData.length <= 0 && (
              <EmptyTable
                header="No Transaction"
                subHeader="Get started by adding a new transaction."
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuickSecondariesTransactions;
