import { memo, useEffect, useMemo, useRef, useState } from "react";
import _ from "lodash";
import {
  CellClickedEvent,
  ColDef,
  SideBarDef,
  GridApi,
} from "ag-grid-community";
import { Box, Dialog } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import { useNavigate } from "react-router";
import { getCurrencySymbol, getCurrencyType } from "../utils/currencyFormatter";

import { tableCellStyle } from "../components/TableComponent";
import { useGetCompanyName, HStack } from "../components/utils";
import {
  useGetOnFilterState,
  usePostOnFilterState,
} from "../pages/dashboardPage/AgGridCacheQuery";
import { agConfigDashboardSideBar } from "../pages/dashboardPage/AgGridConfig";
import { useAuthStore } from "../store";
import { useAgGridTableState } from "../store/agGridTableStore";
import ExportMultidateSheetDetails from "./ExportMultidateSheetDetails";
import {
  PaidUpDateRender,
  PaidUpSharePriceRender,
  ScheduledDateRender,
  TotalPaidRender,
} from "./CurrentlyPaidAgComponent";
import { CurrentlyPaid } from "../types/Shareholder";

function AGGridCurrentlyPaidTable({
  currentlyPaidTableData,
  gridApi,
}: {
  currentlyPaidTableData: CurrentlyPaid[];
  gridApi: any;
}) {
  const currencySymbol = getCurrencySymbol();
  const companyId = useAuthStore().companyId ?? "";
  const userId = useAuthStore().user?.userId ?? 0;
  const [dialog, setDialog] = useState<{
    open: boolean;
    data?: string;
  }>({
    open: false,
  });

  const displayedRowCount = gridApi.current?.api.getDisplayedRowCount();

  const { refetch, data: filterState } = useGetOnFilterState(
    `${userId}`,
    companyId
  );
  const agTableStore = useAgGridTableState();
  useEffect(() => {
    refetch().then((data) => {
      if (data.data) agTableStore.setState(data.data);
      setColumnSetting(data.data?.currentlyPaidColumnModel);
      setFilterSetting(data.data?.currentlyPaidFilterModel);
    });
  }, []);

  const { mutate: postOnFilter } = usePostOnFilterState();

  function getColumnSetting() {
    if (gridApi.current) return gridApi.current.columnApi?.getColumnState();
    return {};
  }

  function setColumnSetting(model: any) {
    if (gridApi.current)
      gridApi.current.columnApi.applyColumnState({ state: model });
    return {};
  }

  // Gets filter model via the grid API
  const getFilterSetting = () => {
    if (gridApi.current) return gridApi.current.api?.getFilterModel();
    return {};
  };

  const setFilterSetting = (model: any) => {
    if (gridApi.current) return gridApi.current.api?.setFilterModel(model);
    return {};
  };

  const uploadFilterAndColumn = async () => {
    const columnState = await getColumnSetting();
    const filterState = await getFilterSetting();
    postOnFilter({
      userId: `${userId}`,
      companyId,
      filterData: {
        ...agTableStore.state,
        currentlyPaidColumnModel: columnState,
        currentlyPaidFilterModel: filterState,
      },
    });
  };

  const componentsRegistery = useMemo(
    () => ({
      paidUpSharePrice: memo(PaidUpSharePriceRender),
      scheduledDate: memo(ScheduledDateRender),
      paidUpDate: memo(PaidUpDateRender),
      totalPaid: memo(TotalPaidRender),
    }),
    []
  );

  const navigate = useNavigate();

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      autoHeight: true,
      wrapHeaderText: true,
      suppressColumnVirtualisation: true,
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      filterParams: {
        buttons: ["reset"],
        maxNumConditions: 5,
      },
      minWidth: 100,
      filter: true,
      resizable: true,
      flex: 1,
    }),
    []
  );

  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        headerName: `Paid Up Share Price (${currencySymbol})`,
        field: "paidUpSharePrice",
        filter: "agNumberColumnFilter",
        cellRenderer: "paidUpSharePrice",
        sortable: true,
        autoHeight: true,
        cellStyle: tableCellStyle,
        wrapText: true,
        suppressSizeToFit: true,
        menuTabs: ["filterMenuTab"],
      },

      {
        headerName: "Scheduled Date",
        field: "scheduledDate",
        filter: "agDateColumnFilter",

        filterParams: {
          comparator: (dateFromFilter: Date, cellValue: any) => {
            if (cellValue == null) {
              return 0;
            }
            const dateParts = cellValue.split("-");
            const day = Number(dateParts[2]);
            const month = Number(dateParts[1]) - 1;
            const year = Number(dateParts[0]);
            const cellDate = new Date(year, month, day);
            if (cellDate < dateFromFilter) {
              return -1;
            } else if (cellDate > dateFromFilter) {
              return 1;
            }
            return 0;
          },
        },
        cellRenderer: "scheduledDate",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
      },
      {
        headerName: "Paid Up Date",
        field: "paidUpDate",
        filter: "agDateColumnFilter",
        filterParams: {
          comparator: (dateFromFilter: Date, cellValue: any) => {
            if (cellValue == null) {
              return 0;
            }
            const dateParts = cellValue.split("-");
            const day = Number(dateParts[2]);
            const month = Number(dateParts[1]) - 1;
            const year = Number(dateParts[0]);
            const cellDate = new Date(year, month, day);
            if (cellDate < dateFromFilter) {
              return -1;
            } else if (cellDate > dateFromFilter) {
              return 1;
            }
            return 0;
          },
        },
        cellRenderer: "paidUpDate",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
      },

      {
        headerName: `Total Paid (${currencySymbol})`,

        field: "totalPaid",
        filter: "agNumberColumnFilter",
        cellRenderer: "totalPaid",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
      },
    ],
    []
  );

  const gridOptions = {
    suppressRowVirtualisation: true,
    paginationAutoPageSize: false,
    suppressScrollOnNewData: true,
  };

  const currencyType = getCurrencyType();

  const rowData = useMemo(
    () =>
      currentlyPaidTableData?.map((template) => ({
        id: template.id,
        paidUpSharePrice: template.paidUpSharePrice,
        scheduledDate: template.scheduledDate,
        paidUpDate: template.paidUpDate,
        totalPaid: template.totalPaid,
        currencySymbol,
        currencyType,
      })),
    [currentlyPaidTableData]
  );

  function setTableHeight() {
    if (displayedRowCount === 1) {
      return (displayedRowCount + 1) * 60;
    } else if (displayedRowCount === 2) {
      return (displayedRowCount + 1) * 60;
    } else if (displayedRowCount === 3) {
      return (displayedRowCount + 1) * 60;
    } else if (displayedRowCount === 4) {
      return (displayedRowCount + 1) * 60;
    } else if (displayedRowCount === 5) {
      return (displayedRowCount + 1) * 60;
    } else if (displayedRowCount === 6) {
      return (displayedRowCount + 1) * 60;
    } else if (displayedRowCount === 7) {
      return (displayedRowCount + 1) * 60;
    } else if (displayedRowCount === 8) {
      return (displayedRowCount + 1) * 60;
    } else if (displayedRowCount === 9) {
      return (displayedRowCount + 1) * 60;
    } else {
      return 10 * 20;
    }
  }

  return (
    <>
      <HStack className="flex-col lg:flex-row justify-start lg:justify-between  py-2 lg:items-center items-start  bg-white rounded-md">
        <div className={`flex flex-col px-6 lg:justify-start `}>
          <p className="text-lg font-medium text-headerColor whitespace-nowrap">
            Currently Paid
          </p>
        </div>
        <HStack className="w-full lg:w-auto lg:justify-end py-2 items-center gap-4 px-8"></HStack>
      </HStack>
      <Dialog open={dialog.open} maxWidth="md">
        <ExportMultidateSheetDetails
          reportName={dialog.data || ""}
          onPrimaryAction={() => setDialog({ open: false })}
          onSecondaryAction={() => setDialog({ open: false })}
        />
      </Dialog>
      <HStack className="justify-between w-full">
        <Box
          style={{
            height: setTableHeight(),
          }}
          className="w-full max-h-full overflow-x-auto ag-theme-material h-full "
        >
          <AgGridReact
            sideBar={agConfigDashboardSideBar}
            onGridReady={(params) => {
              // Store the grid API referen
              gridApi.current = params;
            }}
            components={componentsRegistery}
            alwaysShowHorizontalScroll={false}
            alwaysMultiSort
            animateRows={true}
            defaultColDef={defaultColDef}
            onColumnEverythingChanged={uploadFilterAndColumn}
            rowData={rowData}
            columnDefs={columnDefs}
            pagination={false}
            enableCharts={true}
            enableRangeSelection={true}
            suppressRowTransform={true}
            suppressCopyRowsToClipboard={true}
            suppressCopySingleCellRanges={true}
            suppressCellFocus={true}
            suppressMenuHide={false}
            onColumnResized={uploadFilterAndColumn}
            tooltipShowDelay={1000}
            tooltipInteraction={true}
            rowClass={"border-t border-dashed"}
            gridOptions={gridOptions}
            overlayNoRowsTemplate={
              '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow; margin-top: 50px;">No Rows To Show</span>'
            }
            getRowStyle={(params) => {
              if (params.rowIndex % 2 === 0) {
                return { background: "#f8f8f8" };
              } else {
                return { background: "#ffffff" };
              }
            }}
          ></AgGridReact>
        </Box>
      </HStack>
    </>
  );
}

export default AGGridCurrentlyPaidTable;
