import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Select } from "../../components/shared/Select";
import { HStack, VStack, Error } from "../../components/utils";
import { QuickBonusSecurityModel } from "../../types/quickRound";
import { useQuickBonusSecurityStore } from "../../store/QuickBonusSecuritiesStore";
import { useShareholderInstrumentStore } from "../../store/shareholderInstrumentStore";
import { Input, Label } from "../../components/shared/InputField";
import {
  SecondaryCTAButton,
  PrimaryCTAButton,
} from "../quickRound/CTAButtonComponents";
import { ShowNumberInWords } from "../../components/shared/UiElement";
import { getCurrencyType } from "../../utils/currencyFormatter";
import { useNewShareholderInstrumentStore } from "../../store/newShareholderAndInstrumentStore";
import { useGetSecondaryInvestors } from "../../queries/transactionRound";
import {
  useTransactionUpdateConversionDetails,
  useUpdateSecurityConversionDetails,
} from "../../queries/securities";
import { TransactionConversionUpdateDetails } from "../../types/SecurityModel";

type updateConversionProps = {
  onPrimaryAction: () => void;
  onSecondaryAction: () => void;
  data: any;
  onClose: () => void;
};

const UpdateConversionPopUp = ({
  onPrimaryAction = () => {},
  onSecondaryAction = () => {},
  data,
  onClose = () => {},
}: updateConversionProps) => {
  const [conversionFormValues, setConversionFormValues] =
    useState<TransactionConversionUpdateDetails>({
      conversionType: "",
    });
  const { mutate: updateConversion } = useTransactionUpdateConversionDetails();

  const handleSave = (values: TransactionConversionUpdateDetails) => {
    const conversionDetails: TransactionConversionUpdateDetails = {
      eventId: data.id,

      conversionType: values.conversionType,
    };

    updateConversion(conversionDetails, {
      onSuccess: async (data) => {
        setConversionFormValues(values);
        toast("Conversion Details Saved Successfully!", {
          type: "success",
          autoClose: 2000,
        });
      },

      onError: (err: any) => {
        toast(err.response.data.errorMessage, {
          type: "error",
          autoClose: 2000,
        });
      },
    });
    onPrimaryAction();
  };

  const transactionConversionValidationSchema = Yup.object({
    conversionType: Yup.string().required().label("conversion Type"),
  });

  return (
    <VStack>
      <HStack className="justify-between px-6 py-4 border-b">
        <p className="text-base font-medium">Update Conversion</p>
        <XMarkIcon className="w-6 h-6" onClick={() => onClose()} />
      </HStack>

      <div className="px-4 py-4 bg-white rounded-lg">
        <VStack className="justify-between">
          <Formik
            key="securityConversion"
            enableReinitialize={true}
            initialValues={conversionFormValues}
            validationSchema={transactionConversionValidationSchema}
            onSubmit={(values) => {
              handleSave(values);
            }}
          >
            {(formik) => (
              <Form key="securityConversion">
                <VStack className="w-full gap-6 px-2 py-4">
                  <VStack className="gap-8">
                    <HStack className="gap-5 ">
                      <div className="w-72">
                        <Label className="text-sm font-normal">
                          Conversion Type
                        </Label>
                        <Select
                          className="w-45"
                          options={[
                            "Preferential Allotment",
                            "Private Placement",
                          ]}
                          {...formik.getFieldProps("conversionType")}
                        />
                        {formik.touched?.conversionType &&
                          formik.errors?.conversionType && (
                            <Error text={formik.errors?.conversionType} />
                          )}
                      </div>
                      <div className="hidden w-2/5"></div>
                    </HStack>
                  </VStack>

                  <HStack className="justify-between mt-10">
                    <SecondaryCTAButton
                      event-name="Cancel Transaction Update Conversion"
                      onClick={() => onClose()}
                      type="reset"
                      className="text-red-500"
                    >
                      Cancel
                    </SecondaryCTAButton>
                    <PrimaryCTAButton
                      event-name="Save Transaction Update Conversion"
                      type="submit"
                    >
                      Update
                    </PrimaryCTAButton>
                  </HStack>
                </VStack>
              </Form>
            )}
          </Formik>
        </VStack>
      </div>
    </VStack>
  );
};

export default UpdateConversionPopUp;
