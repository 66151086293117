const GenericChartHeader = ({
  heading,
  subHeading,
  count,
}: {
  heading: string;
  subHeading?: string;
  count?: number;
}) => (
  <div className="flex flex-col px-8 lg:justify-start">
    <p className="text-lg font-medium text-headerColor whitespace-nowrap">
      {heading}
    </p>
    {count && subHeading && (
      <p className="mr-4 text-xs1 font-medium text-descriptionColor whitespace-nowrap md:mr-12">
        {`${count} ${subHeading}`}
      </p>
    )}
  </div>
);

export default GenericChartHeader;
