import { useRef } from "react";
import _ from "lodash";
import { Box, HStack, VStack } from "../../components/utils";
import {
  tableEntryStyle,
  tableFirstColStyle,
} from "../../components/TableComponent";
import { formatDisplayDate } from "../../utils/date";
import Tooltip from "../../components/shared/Tooltip";
import { limitString } from "../../utils/string";
import { getSecurityColor } from "../../utils/historicUtilities";
import {
  formatCurrencyRoboto,
  formatToReadableNumber,
  getCurrencySymbolRoboto,
  getRoundCurrencySymbol,
} from "../../utils/currencyRoboto";
import { getCurrency, getCurrencyType } from "../../utils/currencyFormatter";
import { checkVoid } from "../../utils/transactionUtils";

function checkTransactionType(transactionType: string): boolean {
  if (checkVoid(transactionType)) return false;
  return (
    transactionType.toLowerCase() !== "conversion" &&
    transactionType.toLowerCase() !== "redemption" &&
    transactionType.toLowerCase() !== "update_class" &&
    transactionType.toLowerCase() !== "esoppool" &&
    transactionType.toLowerCase() !== "forfeiture"
  );
}

export function RenderCount(sampleData: any) {
  const renderCount = useRef(1);
  return (
    <>
      <b>{renderCount.current++}</b>
      {sampleData.value}
    </>
  );
}
export function TransactionNameRender(data: any) {
  return (
    <td
      className={`flex flex-col text-sm text-left pt-2 ${
        checkTransactionType(data.value.type) ? "cursor-pointer" : ""
      }`}
    >
      {data.value.name.length > 24 ? (
        <>
          <p className="text-left font-semibold text-xs2 text-[#464E5F]">
            {limitString(data.value.name || "", 24)}
          </p>
        </>
      ) : (
        <p className="text-left font-semibold text-xs2 text-[#464E5F]">
          {data.value.name[0].toUpperCase() + data.value.name.slice(1)}
        </p>
      )}

      {data.value.name !== "Total" && (
        <HStack>
          <p className="text-xxs1 px-2 font-medium text-[#C2C2C2]">
            {data.value.type.toLowerCase() === "esoppool"
              ? "ESOP Pool"
              : data.value.type
              ? data.value.type[0].toUpperCase() + data.value.type.slice(1)
              : "-"}
          </p>
          {data.data.issuanceType && (
            <p className="text-xxs1 font-medium text-[#C2C2C2]">
              ({data.data.issuanceType})
            </p>
          )}
        </HStack>
      )}
      {!data.data.isPricedRound && data.value.name !== "Total" && (
        <span className="px-1 font-xxs rounded-sm border-red-500 text-red-500 border w-16 mt-2">
          Non Priced
        </span>
      )}
    </td>
  );
}

export function ValuationRender(data: any) {
  const currencySymbol = getCurrencySymbolRoboto();
  const currencyType = getCurrencyType();
  return (
    <div className="whitespace-nowrap pt-2">
      <VStack className="gap-2">
        <HStack className="font-medium text-[#030303]">
          {formatToReadableNumber({
            value: data.data?.valuation,
            format: data.data.currencyType,
          })}
        </HStack>
        <div>
          {data.data.pps && (
            <div className="text-xxs1 text-gray-400">
              PPS ({currencySymbol}):{" "}
              {formatToReadableNumber({
                value: data.data.pps,
                format: data.data.currencyType,
              })}
            </div>
          )}
        </div>
      </VStack>
    </div>
  );
}

export function DilutionRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-2">
      {!checkVoid(data.data.dilution) ? (
        <HStack className="font-medium text-[#030303]">
          {formatToReadableNumber({
            value: data.data?.dilution || 0,
            format: data.data.currencyType,
          })}{" "}
          %
        </HStack>
      ) : (
        "-"
      )}
    </div>
  );
}

export function SplitRatioRender(data: any) {
  return (
    <VStack>
      <div className="whitespace-nowrap pt-2">
        <p className={tableEntryStyle}>
          {(data.data.transactionType ?? "-").toLowerCase() === "split" &&
          !checkVoid(data.data.splitRatio)
            ? data.data.splitRatio
            : "-"}
        </p>
      </div>
    </VStack>
  );
}

export function BonusRatioRender(data: any) {
  return (
    <VStack>
      <div className="whitespace-nowrap pt-2">
        <p className={tableEntryStyle}>
          {(data.data.transactionType ?? "-").toLowerCase() === "bonus" &&
          !checkVoid(data.data.transactionType)
            ? data.data?.bonusRatio
            : "-"}
        </p>
      </div>
    </VStack>
  );
}

export function CompanyNameRender(sampleData: any) {
  const value = sampleData.data;
  const renderCount = useRef(1);
  return (
    <VStack>
      <p>{renderCount.current++ + value.name}</p>
      <p>{"Tag"}</p>
    </VStack>
  );
}

function SecurityTag({ security }: { security: string }) {
  return (
    <p
      className={`${getSecurityColor(
        security
      )} text-xs text-center w-24 align-middle font-semibold leading-5 rounded-sm mb-1`}
    >
      {security}
    </p>
  );
}

export function SecurityTypeRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-2">
      {!checkVoid(data.data.securityType)
        ? data.data.securityType.map((element: any) => (
            <VStack key={element.securityType}>
              <SecurityTag security={element.securityType} />
              <HStack className="text-[#C2C2C2] text-xxs1 text-center">
                {element.parValue ? (
                  <p>
                    Face Value:
                    {formatCurrencyRoboto(
                      element.parValue || 0,
                      data.data.currencyType,
                      data.data.currencySymbol
                    )}
                  </p>
                ) : (
                  <span></span>
                )}
                {element.premium && element.parValue ? "|" : ""}
                {element.premium ? (
                  <p>
                    Premium:
                    {formatCurrencyRoboto(
                      element.premium || 0,
                      data.data.currency,
                      data.data.currencySymbol
                    )}
                  </p>
                ) : (
                  <span></span>
                )}
              </HStack>
            </VStack>
          ))
        : "-"}
    </div>
  );
}

export function SecurityClassRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-2 justify-start">
      {data.data.securityClass !== "" && !checkVoid(data.data.securityClass) ? (
        data.data?.securityClass.join(", ").length > 24 ? (
          <p className={tableEntryStyle}>
            {limitString(data.data?.securityClass.join(", "), 24)}
          </p>
        ) : (
          <p className={tableEntryStyle}>
            {data.data?.securityClass.join(", ")}
          </p>
        )
      ) : (
        <p className={tableEntryStyle}>-</p>
      )}
    </div>
  );
}

export function DateOfTransactionRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-2">
      <p className={tableEntryStyle}>
        {formatDisplayDate(data.data.dateOfTransaction)}
      </p>
    </div>
  );
}

export function NumberOfSharesRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-2">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: Math.abs(data.data?.numberOfShares),
          format: data.data.currencyType,
        })}
      </p>
    </div>
  );
}

export function PPSRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-2">
      <p className={tableEntryStyle}>
        {(data.data.transactionType ?? "").toLowerCase() !== "split" ? (
          data.data.roundPPS && data.data.debtSecurityForPPS ? (
            <VStack>
              <div>
                {formatToReadableNumber({
                  value: Number(data.data?.pps),
                  format: data.data.currencyType,
                })}
                <div>(Price For {data.data.debtSecurityForPPS})</div>
              </div>
            </VStack>
          ) : (
            formatToReadableNumber({
              value: data.data?.pps,
              format: data.data.currencyType,
            })
          )
        ) : (
          "-"
        )}
      </p>
    </div>
  );
}

export function AmountRender(data: any) {
  const roundSymbol = getRoundCurrencySymbol(data.data.amount.roundCurrency);
  const roundCurrency = getCurrency(data.data.amount.roundCurrency);
  const currencyType = getCurrencyType();
  return (
    <div className="whitespace-nowrap pt-2">
      <p className={tableEntryStyle}>
        {(data.data.transactionType ?? "-").toLowerCase() === "forfeiture" &&
        data.data?.amount.name === 0 ? (
          <span>-</span>
        ) : (
          formatToReadableNumber({
            value: data.data?.amount.name,
            format: currencyType,
          })
        )}
        {data.value.type !== "-" && roundCurrency !== data.data.currencyType && (
          <p className="text-xxs1 font-medium text-[#C2C2C2]">
            <span>{roundSymbol}</span>
            {formatToReadableNumber({
              value: data.value.type,
              format: roundCurrency || data.data.currencyType,
            })}
            <span> (Round)</span>
          </p>
        )}
      </p>
    </div>
  );
}

export function PaymentTypeRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-2">
      <p className={tableEntryStyle}>
        {checkVoid(data.data.paymentType) ? "-" : data.data.paymentType}
      </p>
    </div>
  );
}

export function LeadInvestorRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-2">
      {(data.data.transactionType ?? "").toLowerCase() === "round" &&
      !checkVoid(data.data.leadInvestor) ? (
        data.data?.leadInvestor.length > 24 ? (
          <p className={tableEntryStyle}>
            {limitString(data.data?.leadInvestor, 24)}
          </p>
        ) : (
          <p className={tableEntryStyle}>{data.data?.leadInvestor}</p>
        )
      ) : (
        <p className={tableEntryStyle}>-</p>
      )}
    </div>
  );
}

export function TransactionPriceRender(data: any) {
  const currencyType = getCurrencyType();
  const transactionPrice: number[] = _.uniq(data?.data?.transactionPrice || []);
  return (
    <div className="whitespace-nowrap pt-2">
      <p className={tableEntryStyle}>
        {(data.data.transactionType ?? "").toLowerCase() !== "split" ? (
          data.data.roundPPS && data.data.debtSecurityForPPS ? (
            <VStack>
              <div>
                {formatToReadableNumber({
                  value: Number(data.data?.pps),
                  format: data.data.currencyType,
                })}
                <div>(Price For {data.data.debtSecurityForPPS})</div>
              </div>
            </VStack>
          ) : (
            <div>
              {checkVoid(transactionPrice) || checkVoid(transactionPrice[0])
                ? "-"
                : `${formatToReadableNumber({
                    value: transactionPrice[0],
                    format: data.data.currencyType,
                  })}
        ${
          transactionPrice[1]
            ? ` - ${formatToReadableNumber({
                value: transactionPrice[1],
                format: data.data.currencyType,
              })}`
            : ""
        }`}
            </div>
          )
        ) : (
          "-"
        )}
      </p>
    </div>
  );
}
