import { useEffect } from "react";
import BCHeader from "../../shared/BCHeader";
import { useQuickSecondaryTransactionStore } from "../../store/useQuickTransactionStore";
import { useAuthStore } from "../../store";
import { useGetOnGoingTransaction } from "../../queries/transactionRound";
import TransactionPreviewPage from "./TransactionPreviewPage";
import QuickAddSecondary from "./QuickAddSecondary";

function TransactionSecondaryTemplate() {
  const { state: template, setState: setTemplate } =
    useQuickSecondaryTransactionStore();
  const companyId = useAuthStore.getState().companyId || "";
  const stepNo = template.stepNo;
  const { refetch, data: onGoingTransaction } =
    useGetOnGoingTransaction(companyId);
  useEffect(() => {
    refetch();
  }, []);
  useEffect(() => {
    if (onGoingTransaction) setTemplate(onGoingTransaction);
    else {
      setTemplate({ stepNo: 1, transactionType: "secondary", companyId });
    }
  }, [onGoingTransaction]);

  return (
    <div className="min-h-full">
      <BCHeader
        className="items-baseline mb-2"
        bcTitle="Transaction Dashboard"
        bcSubTitle="Add Secondary"
      />
      {template.stepNo !== 2 ? <QuickAddSecondary /> : null}
      {template.stepNo === 2 ? <TransactionPreviewPage /> : null}
    </div>
  );
}

export default TransactionSecondaryTemplate;
