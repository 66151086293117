import { useEffect, useMemo, useState } from "react";
import { Dialog } from "@mui/material";
import _ from "lodash";
import { useNavigate } from "react-router";
import Tooltip from "../../components/shared/Tooltip";
import { Box, HStack, VStack } from "../../components/utils";
import { globalFilter, sort } from "../../utils/arrays";
import BasicMenu from "../../components/shared/Menu";
import Pagination from "../../components/shared/Pagination";
import { limitString, _trimAll } from "../../utils/string";
import {
  THead,
  TableHeader,
  tableEntryStyle,
  tableFirstColStyle,
} from "../../components/TableComponent";
import { Action, CTADropdown } from "../../components/shared/Dropdown";
import DeleteModal from "./DeleteModal";
import { NewNonPricedSecurity } from "./RoundCreationModel";
import { EmptyTable } from "../quickRound/EmptyTableComponents";
import { useRMCreationStore } from "../../store/roundCreationStore";
import { getCurrencySymbolRoboto } from "../../utils/currencyRoboto";

export default function NewNonPricedSecurityTable({
  instruments,
}: {
  instruments: NewNonPricedSecurity[];
}) {
  let _captableData: NewNonPricedSecurity[] = instruments;
  const roundCreationState = useRMCreationStore();
  const [globalFilterText, setGlobalFilterText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [sortField, setSortField] = useState<{
    field: keyof NewNonPricedSecurity | "" | undefined;
    ascending: boolean;
  }>({ field: "name", ascending: false });

  const [dialog, setDialog] = useState<{
    open: boolean;
    data?: NewNonPricedSecurity;
    mode?: "Edit" | "Delete";
  }>({
    open: false,
  });

  _captableData = useMemo(() => {
    try {
      if (!_captableData) return [];
      const filterResult = globalFilter(_captableData, globalFilterText);
      const sortResult = sort(
        filterResult,
        sortField?.field,
        sortField?.ascending
      );
      return sortResult;
    } catch (e) {
      return _captableData;
    }
  }, [_captableData, globalFilterText, sortField]);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return _captableData.slice(firstPageIndex, lastPageIndex);
  }, [_captableData, currentPage, pageSize]);

  useEffect(() => {
    setCurrentPage(1);
  }, [globalFilterText]);

  function handleAction(action: Action, data: NewNonPricedSecurity) {
    if (action.name === "Edit" && !action.disabled) {
      setDialog({
        open: true,
        data,
        mode: "Edit",
      });
    } else if (action.name === "Delete" && !action.disabled) {
      setDialog({
        open: true,
        data,
        mode: "Delete",
      });
    }
  }

  const handleSubmit = async (id: string) => {
    const instrument =
      roundCreationState.roundCreation.tab5?.nonPricedInstruments || [];
    const instrumentList = instrument.map((element) => {
      if (element.id === id) {
        return {
          ...element,
          state: "Delete",
        } as unknown as NewNonPricedSecurity;
      } else {
        return element;
      }
    });
    const newList = {
      ...roundCreationState.roundCreation,
      tab5: {
        listOfShareHolders:
          roundCreationState.roundCreation.tab5?.listOfShareHolders ?? [],
        nonPricedInstruments: instrumentList,
        listOfInstrument:
          roundCreationState.roundCreation.tab5?.listOfInstruments || [],
        newInstruments:
          roundCreationState.roundCreation.tab5?.newInstruments || [],
        investorDetails:
          roundCreationState.roundCreation.tab5?.investorDetails || [],
      },
    };
    roundCreationState.sendMessage(newList);
    setDialog({ open: false });
  };

  const symbol = getCurrencySymbolRoboto();

  return (
    <div className="bg-white rounded-lg">
      <Dialog open={dialog.open} maxWidth="lg">
        {dialog.mode === "Delete" && (
          <DeleteModal
            onPrimaryAction={() => {
              handleSubmit(dialog.data?.id || "");
            }}
            onSecondaryAction={() => setDialog({ open: false })}
            data={
              "Are you sure you want to delete the entry? Data will be permanently removed. This action cannot be undone."
            }
          />
        )}
      </Dialog>
      <div className="flex flex-col">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="md:rounded-lg">
              <table className="min-w-full bg-white">
                <thead>
                  <TableHeader>
                    <THead
                      fieldName="name"
                      fieldDisplayName="Name"
                      isFirstFeild={true}
                      selectedFieldName={sortField?.field || ""}
                      isAscending={sortField?.ascending || false}
                      isColumnFilter={false}
                      onChangeSort={() => {
                        setSortField({
                          field: "name",
                          ascending: !sortField?.ascending,
                        });
                      }}
                    />
                    <THead
                      fieldName="conversionRatio"
                      fieldDisplayName="Conversion Ratio"
                      showCurrency={false}
                      selectedFieldName={sortField?.field || ""}
                      isAscending={sortField?.ascending || false}
                      isColumnFilter={false}
                      onChangeSort={() => {
                        setSortField({
                          field: "conversionRatio",
                          ascending: !sortField?.ascending,
                        });
                      }}
                    />
                    <THead
                      fieldName="parValue"
                      fieldDisplayName="PAR"
                      showCurrency={true}
                      selectedFieldName={sortField?.field || ""}
                      isAscending={sortField?.ascending || false}
                      isColumnFilter={false}
                      onChangeSort={() => {
                        setSortField({
                          field: "parValue",
                          ascending: !sortField?.ascending,
                        });
                      }}
                    />
                    <THead
                      fieldName="type"
                      fieldDisplayName="Type"
                      selectedFieldName={sortField?.field || ""}
                      isAscending={sortField?.ascending || false}
                      isColumnFilter={false}
                      onChangeSort={() => {
                        setSortField({
                          field: "type",
                          ascending: !sortField?.ascending,
                        });
                      }}
                    />
                    <THead
                      fieldName="valuationCap"
                      fieldDisplayName="Valuation Cap / Discount %"
                      selectedFieldName={sortField?.field || ""}
                      isAscending={sortField?.ascending || false}
                      isColumnFilter={false}
                    />
                    <THead
                      fieldName="discountType"
                      fieldDisplayName="Discount Type"
                      selectedFieldName={sortField?.field || ""}
                      isAscending={sortField?.ascending || false}
                      isColumnFilter={false}
                    />
                    <th></th>
                  </TableHeader>
                </thead>
                <tbody>
                  {currentTableData.map((captable: NewNonPricedSecurity) => (
                    <tr
                      key={`${captable.id}`}
                      className="border-t whitespace-nowrap border-dashed hover:bg-slate-50 text-[#030303] text-xs2 font-medium "
                    >
                      <td className="py-4 pr-3 text-sm whitespace-nowrap ">
                        <VStack className="flex items-start">
                          {captable.name.length > 24 ? (
                            <Tooltip text={captable.name}>
                              <p className={tableFirstColStyle}>
                                {limitString(captable.name || "", 24)}
                              </p>
                            </Tooltip>
                          ) : (
                            <p className={tableFirstColStyle}>
                              {captable.name[0].toUpperCase() +
                                captable.name.slice(1)}
                            </p>
                          )}
                        </VStack>
                      </td>
                      <td className="px-3 py-4 whitespace-nowrap">
                        <VStack className="items-center">
                          <p className={tableEntryStyle}>
                            {captable.conversionRatio}
                          </p>
                        </VStack>
                      </td>
                      <td className="px-3 py-4 whitespace-nowrap">
                        <VStack className="items-center">
                          <p className={tableEntryStyle}>{captable.parValue}</p>
                        </VStack>
                      </td>
                      <td className="px-3 py-4 whitespace-nowrap">
                        <VStack className="items-center">
                          <p className={tableEntryStyle}>{captable.type}</p>
                        </VStack>
                      </td>

                      <td className="px-3 py-4 whitespace-nowrap items-center">
                        <VStack className="items-center">
                          <HStack>
                            <p className={tableEntryStyle}>
                              {symbol} {captable.valuationCap.toLocaleString()}
                            </p>
                            <p className="px-2 text-gray-300">|</p>
                            <p className={tableEntryStyle}>
                              {captable.discount}%
                            </p>
                          </HStack>
                        </VStack>
                      </td>
                      <td className="px-3 py-4 whitespace-nowrap">
                        <VStack className="items-center">
                          <p className={tableEntryStyle}>
                            {captable.discountType}
                          </p>
                        </VStack>
                      </td>

                      <td className="px-2 py-4 align-top">
                        <CTADropdown
                          dropdownClassName="bottom-auto"
                          actions={[
                            {
                              name: "Delete",
                              disabled: false,
                            },
                          ]}
                          onAction={(action) => handleAction(action, captable)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {currentTableData.length === 0 && (
                <EmptyTable
                  header="No Security Available"
                  subHeader="start by adding new security"
                />
              )}
            </div>
          </div>
        </div>
        <Box className="flex justify-between my-4 ">
          {_captableData.length >= 5 && (
            <BasicMenu
              defaultValue={pageSize}
              options={[5, 10, 20, 50, 100]}
              onOptionChange={(value) => {
                setCurrentPage(1);
                setPageSize(value);
              }}
            />
          )}
          <Pagination
            currentPage={currentPage}
            totalCount={_captableData?.length}
            pageSize={pageSize}
            onPageChange={(page: number) => setCurrentPage(page)}
          />
        </Box>
      </div>
    </div>
  );
}
