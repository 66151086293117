import randomColor from "randomcolor";
import Avatar from "../Utility/Avatar";
import { formatDisplayDate } from "../../utils/date";
import { formatCurrency, getCurrencyType } from "../../utils/currencyFormatter";
import { EventInfo } from "./RoundTransactionModel";

const TransactionDetails = ({
  transactionDetails,
  dilutionPercentage,
  roundAmountRaised,
  roundShares,
  issuanceType,
  paymentType,
  allotmentType,
  totalShares,
  esopShares,
  date,
}: {
  transactionDetails: EventInfo;
  dilutionPercentage: string;
  roundAmountRaised: number;
  issuanceType: string;
  paymentType: string;
  allotmentType: string;
  roundShares: number;
  totalShares: number;
  esopShares: number;
  date: string;
}) => {
  const currency = getCurrencyType();
  const backgroundColor = randomColor({
    luminosity: "dark",
    format: "rgba",
    alpha: 0.2,
    seed: transactionDetails.name || "A",
  });
  const color = randomColor({
    luminosity: "dark",
    format: "rgba",
    alpha: 1,
    seed: transactionDetails.name || "A",
  });
  return (
    <div className="px-7 py-4 pb-5 shadow-box bg-white rounded-md border border-borderColor">
      <div className="flex items-end">
        <div className="h-10 w-10 flex-shrink-0">
          <Avatar name={transactionDetails.name || "A"} />
        </div>
        <div className="ml-4">
          <div className="font-semibold text-lg text-gray-900">
            {transactionDetails.name || "A"}
          </div>
          <div className="font-semibold text-xs2 text-gray-500">
            {formatDisplayDate(date)}
          </div>
        </div>
      </div>
      <div>
        <dl className="mt-5 grid grid-cols-1 gap-4 sm:grid-cols-3">
          <div className="overflow-hidden rounded-lg bg-white px-4 py-2 sm:p-4 shadow-box mb-2 border border-borderColor">
            <dd className="mt-1 text-sm font-semibold tracking-tight text-gray-900">
              {`${roundShares.toLocaleString(currency)}`}
            </dd>
            <dt className="truncate text-xs font-medium text-gray-500">
              Securities
            </dt>
          </div>
          <div className="overflow-hidden rounded-lg bg-white px-4 py-2 sm:p-4 shadow-box mb-2 border border-borderColor">
            <dd className="mt-1 text-sm font-semibold tracking-tight text-gray-900">
              {formatCurrency(roundAmountRaised, currency)}
            </dd>
            <dt className="truncate text-xs font-medium text-gray-500">
              Amount Raised
            </dt>
          </div>
          {transactionDetails.pricedRound && (
            <div className="overflow-hidden rounded-lg bg-white px-4 py-2 sm:p-4 shadow-box mb-2 border border-borderColor">
              <dd className="mt-1 text-sm font-semibold tracking-tight text-gray-900">
                {esopShares.toLocaleString(currency)}
              </dd>
              <dt className="truncate text-xs font-medium text-gray-500">
                ESOP Pool size
              </dt>
            </div>
          )}
          {transactionDetails.pricedRound && (
            <>
              <div className="overflow-hidden rounded-lg bg-white px-4 py-2 sm:p-4 shadow-box mb-2 border border-borderColor">
                <dd className="mt-1 text-sm font-semibold tracking-tight text-gray-900">
                  {allotmentType}
                </dd>
                <dt className="truncate text-xs font-medium text-gray-500">
                  Allotment type
                </dt>
              </div>
              <div className="overflow-hidden rounded-lg bg-white px-4 py-2 sm:p-4 shadow-box mb-2 border border-borderColor">
                <dd className="mt-1 text-sm font-semibold tracking-tight text-gray-900">
                  {paymentType}
                </dd>
                <dt className="truncate text-xs font-medium text-gray-500">
                  Payment type
                </dt>
              </div>
              <div className="overflow-hidden rounded-lg bg-white px-4 py-2 sm:p-4 shadow-box mb-2 border border-borderColor">
                <dd className="mt-1 text-sm font-semibold tracking-tight text-gray-900">
                  {issuanceType}
                </dd>
                <dt className="truncate text-xs font-medium text-gray-500">
                  Issuance type
                </dt>
              </div>
            </>
          )}
        </dl>
      </div>
    </div>
  );
};

export default TransactionDetails;
