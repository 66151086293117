import { useEffect, useState } from "react";
import { Dialog } from "@mui/material";
import { toast } from "react-toastify";
import { HStack, VStack } from "../../components/utils";
import {
  ButtonSize,
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../quickRound/CTAButtonComponents";
import { ExistingInvestorDetails, ModelState } from "../../types/Modeling";
import { useRoundModelStore } from "../../store/roundModelingStore";
import AddNewInvestorsModal from "./AddNewInvestorModal";
import NewInvestorAGGridTable from "./NewInvestorAgTable";
import { queryClient } from "../../queries";

export default function ExistingInvestorTab() {
  const {
    roundCreation: data,
    sendMessage: onNext,
    setRoundCreation: setRoundState,
    connect,
    saveModel,
  } = useRoundModelStore();
  const [dialog, setDialog] = useState<{
    open: boolean;
    mode?: "Add";
    data?: ExistingInvestorDetails;
  }>({
    open: false,
  });
  useEffect(() => {
    if (data.errorMessage) {
      toast(data.errorMessage, {
        type: "error",
        autoClose: 2000,
      });
    }
    setRoundState({
      ...data,
      errorMessage: "",
    });
  }, []);

  function scrollToTop() {
    const scroller = document.querySelector("#outlet");
    scroller?.scrollTo(0, 0);
  }

  return (
    <VStack className="justify-between min-h-full py-4 bg-white rounded-lg ">
      <Dialog open={dialog.open} maxWidth="lg">
        {dialog.mode === "Add" && (
          <AddNewInvestorsModal
            onBack={() => {
              setDialog({ open: false });
            }}
            onNext={onNext}
          />
        )}
      </Dialog>
      <div>
        <VStack className="px-2">
          <NewInvestorAGGridTable
            data={data.existingInvestorDetails?.investorDetails || []}
          />
        </VStack>
      </div>
      <HStack className="justify-between gap-4 px-6 mt-8">
        <HStack className="gap-4">
          <SecondaryCTAButton
            type="reset"
            event-name="Existing Investor Back"
            buttonSize={ButtonSize.SMALL}
            onClick={() => {
              onNext({
                ...data,
                currentTab:
                  data?.convertibles?.convertibles.length! > 0 ? 2 : 1,
                isNavigating: true,
              });
              scrollToTop();
            }}
          >
            Back
          </SecondaryCTAButton>
        </HStack>
        <HStack>
          <div className="justify-end pr-4 ">
            <PrimaryCTAButton
              className="h-8"
              event-name="Save Model Button"
              onClick={() => {
                connect();
                saveModel(data);

                toast(
                  `Saved ${data?.roundDetailsTab?.roundName} round model successfully`,
                  {
                    type: "success",
                    autoClose: 2000,
                  }
                );
                queryClient.invalidateQueries("listofmodeling");
              }}
              buttonSize={ButtonSize.SMALL}
            >
              Save Model
            </PrimaryCTAButton>
          </div>
          <PrimaryCTAButton
            event-name="Existing Investor Next"
            className="min-w-max"
            buttonSize={ButtonSize.SMALL}
            loading={data.isNavigating}
            type="submit"
            onClick={() => {
              setRoundState({
                ...data,
                isNavigating: true,
              });
              onNext({
                ...data,
                currentTab: 4,
              });
              queryClient.invalidateQueries("listofmodeling");
              scrollToTop();
            }}
          >
            Next
          </PrimaryCTAButton>
        </HStack>
      </HStack>
    </VStack>
  );
}
