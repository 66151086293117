import create from "zustand";

type GraphLoaderState = {
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  reset: () => void;
};

const initialState = {
  isLoading: false,
};

const graphLoaderStore = create<GraphLoaderState>((set) => ({
  ...initialState,
  setIsLoading: (indexValue) => {
    set((state) => ({ isLoading: indexValue }));
  },
  reset: () => {
    set(() => initialState);
  },
}));

export const useGraphLoaderStore = graphLoaderStore;
