import { useMutation } from "react-query";
import { uploadCompanyExcel } from "../../api/companyExcelOnboarding";
import { handleEventForTracking } from "../../amplitudeAnalytics";

export function useUploadCompanyExcel() {
  return useMutation({
    mutationKey: "uploadCompanyExcel",
    mutationFn: uploadCompanyExcel,
    onSuccess: () => {
      handleEventForTracking({
        eventName: "uploadCompanyExcel",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "uploadCompanyExcel",
        success: false,
        eventType: "API",
      });
    },
  });
}
