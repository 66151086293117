import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  CellClickedEvent,
  ColDef,
  GridApi,
  SideBarDef,
} from "ag-grid-community";
import { useNavigate } from "react-router";

import { AgGridReact } from "ag-grid-react";
import { Action, CTADropdown } from "../../components/shared/Dropdown";
import { agConfigDashboardSideBar } from "../dashboardPage/AgGridConfig";

import { ShareholderDataModel } from "../../types/Shareholder";

import {
  getCurrencySymbol,
  getCurrencyType,
} from "../../utils/currencyFormatter";
import {
  AvgPPSRender,
  CurrentValueRender,
  HoldingPercentageRender,
  NumberOfSharesRender,
  RoundsRender,
  SecurityTypeRender,
  ShareholderNameRender,
} from "./ShareholderAGComponent";
import { Box, HStack, useGetCompanyName } from "../../components/utils";
import GenericTableHeader from "../../shared/TableHeader";
import { IconCTAButton } from "../quickRound/CTAButtonComponents";
import QuickSearch from "../rounds/QuickSearch";
import { useAuthStore } from "../../store";
import {
  useGetOnFilterState,
  usePostOnFilterState,
} from "../dashboardPage/AgGridCacheQuery";
import { tableCellStyle } from "../../components/TableComponent";
import { ExportImport } from "../Utility/GrantsTable";
import {
  exportSharecertificateReport,
  exportShareholderReport,
} from "../../api/Report";
import { downloadExcel, downloadS3File } from "../../utils/DownloadFile";
import { useAgGridTableState } from "../../store/agGridTableStore";
import SearchInput from "../../components/shared/SearchInput";
import { RoundsToolTip } from "../dashboardPage/customTooltip";
import { getShareholderValue } from "../../constants/ShareholderConstants";
import { handleEventForTracking } from "../../amplitudeAnalytics";

function AGGridShareholderTable({
  isPreviewOnly,
  shareholderData,
}: {
  isPreviewOnly: boolean;
  shareholderData: ShareholderDataModel[];
}) {
  const currencySymbol = getCurrencySymbol();
  const companyName = useGetCompanyName();
  const companyId = useAuthStore().companyId ?? "";
  const userId = useAuthStore().user?.userId ?? 0;
  const gridApi = useRef<any>(null);

  const displayedRowCount = gridApi.current?.api.getDisplayedRowCount();

  const { refetch, data: filterState } = useGetOnFilterState(
    `${userId}`,
    companyId
  );
  const agTableStore = useAgGridTableState();
  useEffect(() => {
    refetch().then((data) => {
      if (data.data) agTableStore.setState(data.data);
      setColumnSetting(data.data?.shareholderColumnModel);
      setFilterSetting(data.data?.shareholderFilterModel);
    });
  }, []);

  const { mutate: postOnFilter } = usePostOnFilterState();

  function getColumnSetting() {
    if (gridApi.current) return gridApi.current.columnApi?.getColumnState();
    return {};
  }

  function setColumnSetting(model: any) {
    if (gridApi.current)
      gridApi.current.columnApi.applyColumnState({ state: model });
    return {};
  }

  // Gets filter model via the grid API
  const getFilterSetting = () => {
    if (gridApi.current) return gridApi.current.api?.getFilterModel();
    return {};
  };

  const setFilterSetting = (model: any) => {
    if (gridApi.current) return gridApi.current.api?.setFilterModel(model);
    return {};
  };

  const uploadFilterAndColumn = async () => {
    const columnState = await getColumnSetting();
    const filterState = await getFilterSetting();
    postOnFilter({
      userId: `${userId}`,
      companyId,
      filterData: {
        ...agTableStore.state,
        shareholderColumnModel: columnState,
        shareholderFilterModel: filterState,
      },
    });
  };

  const cin = "";

  const componentsRegistery = useMemo(
    () => ({
      shareholderName: memo(ShareholderNameRender),
      rounds: memo(RoundsRender),
      securityType: memo(SecurityTypeRender),
      numberOfShares: memo(NumberOfSharesRender),
      holdingPercentage: memo(HoldingPercentageRender),
      avgPPS: memo(AvgPPSRender),
      currentValue: memo(CurrentValueRender),
    }),
    []
  );

  const navigate = useNavigate();

  const onClickShareholderName = (id: string) => {
    handleEventForTracking({ eventName: "Shareholder Name" });
    navigate(`/shareholders/individual/${id}`);
  };

  const isValidDate = (date: string) =>
    Number.isNaN(date) &&
    new Date(date).toString() !== "Invalid Date" &&
    !Number.isNaN(Date.parse(date));

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      autoHeight: true,
      wrapHeaderText: true,
      suppressColumnVirtualisation: true,
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      filterParams: {
        buttons: ["reset"],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
      flex: 1,
    }),
    []
  );
  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        headerName: "Name",
        field: "shareholderName",
        filter: "agMultiColumnFilter",
        getQuickFilterText: (params) => params.value.name,
        comparator(valueA: any, valueB: any, ..._: any[]) {
          return valueA.name > valueB.name ? 1 : -1;
        },
        filterParams: {
          maxNumConditions: 5,
          buttons: ["reset"],
          filters: [
            {
              title: "Name",
              display: "subMenu",
              filter: "agTextColumnFilter",
              buttons: ["reset"],
              filterValueGetter: (params: any) => params.value.name,
              filterParams: {
                buttons: ["reset"],
                valueGetter: (params: any) =>
                  params.getValue("shareholderName").name,
              },
            },
            {
              title: "Type",
              filter: "agSetColumnFilter",
              display: "subMenu",
              buttons: ["reset"],
              filterParams: {
                buttons: ["reset"],
                keyCreator: (params: any) => {
                  const shareholderType = params.value.type;
                  return shareholderType;
                },
                valueFormatter: (params: any) => {
                  const shareholderType = getShareholderValue(
                    params.value.type
                  );
                  return shareholderType;
                },
              },
            },
          ],
        },
        pinned: "left",
        cellRenderer: "shareholderName",
        initialWidth: 300,
        sortable: true,
        autoHeight: true,
        cellStyle: tableCellStyle,
        wrapText: true,
        suppressSizeToFit: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipShareholderName",
      },
      {
        headerName: "Round",
        field: "rounds",
        cellRenderer: "rounds",
        initialWidth: 250,
        cellStyle: tableCellStyle,
        sortable: false,
        filter: "agSetColumnFilter",
        tooltipField: "tooltipShareholderName",
        tooltipComponent: RoundsToolTip,
        menuTabs: ["filterMenuTab"],
        valueGetter: (params) =>
          params.data.rounds?.map((value: any) => value.roundName),
        valueFormatter: (params) =>
          params.data.rounds?.map((value: any) => value.roundName),
        suppressCellClick: true, // Add this line to make the column non-clickable
      },
      {
        headerName: "Security Type",
        field: "securityType",
        filter: "agSetColumnFilter",
        cellRenderer: "securityType",
        cellStyle: tableCellStyle,
        sortable: false,
        menuTabs: ["filterMenuTab"],
      },
      {
        headerName: "Holding (%)",
        field: "holdingPercentage",
        filter: "agNumberColumnFilter",
        cellRenderer: "holdingPercentage",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipHoldingPercentage",
      },

      {
        headerName: "No. of Securities",
        field: "numberOfShares",
        filter: "agNumberColumnFilter",
        cellRenderer: "numberOfShares",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipNumberOfShares",
      },
      {
        headerName: `Avg.PPS (${currencySymbol})`,
        field: "avgPPS",
        filter: "agNumberColumnFilter",
        cellRenderer: "avgPPS",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipPPS",
      },
      {
        headerName: `Current Value (${currencySymbol})`,
        field: "currentValue",
        filter: "agNumberColumnFilter",
        cellRenderer: "currentValue",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipCurrentValue",
      },
    ],
    []
  );

  const gridOptions = {
    suppressRowVirtualisation: true, // Add this line to disable row virtualization
    paginationAutoPageSize: false, // Optional: Set to false if using custom pagination
    suppressScrollOnNewData: true, // Optional: Set to true to prevent scrolling to the top on
  };

  const currencyType = getCurrencyType();

  const rowData = useMemo(
    () =>
      shareholderData?.map((template) => ({
        id: template.id,
        shareholderName: {
          name: template.name,
          type: template.type,
        },
        tooltipShareholderName: template.name,
        rounds: template.roundIdentifierDetails,
        securityType: template.securityTypes,
        numberOfShares: template.shares,
        tooltipNumberOfShares: template.shares.toLocaleString(currencyType),
        holdingPercentage: Number(template.holdingPercentage.toFixed(2)),
        tooltipHoldingPercentage: template.holdingPercentage,
        avgPPS: template.pricePerShare,
        tooltipPPS: template.pricePerShare?.toLocaleString(currencyType),
        currentValue: template.networth,
        tooltipCurrentValue: template.networth.toLocaleString(currencyType),
        currencySymbol,
        currencyType,
      })),
    [shareholderData]
  );

  function setTableHeight() {
    if (displayedRowCount === 1) {
      return (displayedRowCount + 2.75) * 60;
    } else if (displayedRowCount === 2) {
      return (displayedRowCount + 2.68) * 60;
    } else if (displayedRowCount === 3) {
      return (displayedRowCount + 3) * 60;
    } else if (displayedRowCount === 4) {
      return (displayedRowCount + 2.55) * 60;
    } else if (displayedRowCount === 5) {
      return (displayedRowCount + 2.48) * 60;
    } else if (displayedRowCount === 6) {
      return (displayedRowCount + 2.42) * 60;
    } else if (displayedRowCount === 7) {
      return (displayedRowCount + 2.35) * 60;
    } else if (displayedRowCount === 8) {
      return (displayedRowCount + 2.28) * 60;
    } else if (displayedRowCount === 9) {
      return (displayedRowCount + 2.22) * 60;
    } else {
      return 10 * 60;
    }
  }

  const handleCellClick = (cellParams: CellClickedEvent<any, any>) => {
    if (cellParams.column.getColId() !== "action-column") {
      const template = cellParams.data;
      if (
        cellParams.value.name !== "Total" &&
        cellParams.column.getColId() === "shareholderName"
      ) {
        onClickShareholderName(template.id);
      }
    }
  };
  const [isColumnOpen, setIsColumnOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const openToolPanel = (key: any) => {
    if (key === "columns") {
      if (gridApi) {
        if (!isColumnOpen) gridApi?.current?.api?.openToolPanel(key);
        else gridApi?.current?.api?.closeToolPanel();
        setIsColumnOpen((state) => !state);
        setIsFilterOpen(false);
      }
    } else if (key === "filters") {
      if (gridApi) {
        if (!isFilterOpen) gridApi?.current?.api?.openToolPanel(key);
        else gridApi?.current?.api?.closeToolPanel();
        setIsFilterOpen((state) => !state);
        setIsColumnOpen(false);
      }
    }
  };

  async function handleExportAction(action: Action) {
    if (action.disabled) return;

    if (action.name === "Export Share Certificate Data") {
      exportSharecertificateReport().then((res) => {
        const fileName = `Sharecertificate Report_${companyName}`;
        if (res.isCustomShareCertificate) {
          downloadS3File(res?.signedUrl ?? "", fileName);
        } else {
          downloadExcel(res?.base64 ?? "", fileName);
        }
      });
    } else if (action.name === "Export Shareholder Cap Table") {
      exportShareholderReport().then((res) => {
        const fileName = `Shareholder Cap Table Report_${companyName}`;
        downloadExcel(res, fileName);
      });
    }
  }
  function setPinnedBottomRowData({ api }: { api: any }) {
    const data = api.rowModel.rootNode;
    const filteredData = data.childrenAfterAggFilter.map(
      (element: any) => element.data
    );
    const totalNumberOfShares = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + data.numberOfShares,
      0
    );
    const totalHoldingPercentage = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + data.holdingPercentage,
      0
    );
    const totalNetworth = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + data.currentValue,
      0
    );
    api.setPinnedBottomRowData([
      {
        shareholderName: {
          name: "Total",
          type: null,
        },
        rounds: null,
        securityType: null,
        numberOfShares: totalNumberOfShares,
        tooltipNumberOfShares: totalNumberOfShares.toLocaleString(currencyType),
        holdingPercentage:
          totalHoldingPercentage.toFixed(2) > 99.98
            ? 100
            : totalHoldingPercentage.toFixed(2),
        tooltipHoldingPercentage:
          totalHoldingPercentage.toFixed(2) > 99.98
            ? 100
            : totalHoldingPercentage.toFixed(2),
        avgPPS: null,
        currentValue: totalNetworth,
        tooltipCurrentValue: totalNetworth.toLocaleString(currencyType),
        currencyType,
      },
    ]);
  }

  const [filteredRowData, setFilteredRowData] = useState<any>([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const onAgGridFilterChanged = (grid: any) => {
    const filtersApplied = grid.api.isAnyFilterPresent();
    setIsFilterApplied(filtersApplied);

    const filteredData = grid.api
      .getModel()
      .rowsToDisplay.map((node: any) => node.data);
    setFilteredRowData(filteredData);
    setPinnedBottomRowData(grid);
    uploadFilterAndColumn();
  };
  return (
    <div className="shadow-box border border-borderColor bg-white rounded-md">
      <HStack className="flex-col lg:flex-row justify-start lg:justify-between  py-2 lg:items-center items-start">
        <GenericTableHeader
          heading={"Shareholders Table"}
          subHeading={"Shareholders"}
          count={
            isFilterApplied === true ? filteredRowData.length : rowData.length
          }
        />
        <HStack className="justify-between w-full lg:w-auto lg:justify-end py-2 items-center gap-4 px-8">
          <SearchInput
            placeholder={`Search`}
            onChange={(e: any) => {
              gridApi.current.api.setQuickFilter(e.target.value);
            }}
          />
          <HStack className="gap-4">
            <ExportImport
              actions={[
                {
                  name: "Export Shareholder Cap Table",
                },
                {
                  name: "Export Share Certificate Data",
                },
              ]}
              onAction={(action) => handleExportAction(action)}
            />
            <IconCTAButton
              value={"Columns"}
              onClick={() => openToolPanel("columns")}
              iconName={"fluent:column-triple-edit-20-regular"}
              className={`px-4 font-medium items-center flex flex-row ${
                isColumnOpen ? "text-orange-501" : "text-gray-400"
              }`}
            />
            <IconCTAButton
              value={"Filters"}
              onClick={() => openToolPanel("filters")}
              iconName={"material-symbols:filter-alt"}
              className={`px-4 font-medium items-center flex flex-row ${
                isFilterOpen ? "text-orange-501" : "text-gray-400"
              }`}
            />
          </HStack>
        </HStack>
      </HStack>
      <HStack className="justify-between w-full">
        <Box
          style={{
            height: setTableHeight(),
          }}
          className="w-full max-h-full overflow-x-auto ag-theme-material h-full "
        >
          <AgGridReact
            sideBar={agConfigDashboardSideBar}
            onGridReady={(params) => {
              // Store the grid API referen
              gridApi.current = params;
            }}
            components={componentsRegistery}
            alwaysShowHorizontalScroll
            alwaysMultiSort
            animateRows={true}
            enableCharts={true}
            enableRangeSelection={true}
            defaultColDef={defaultColDef}
            onRowDataUpdated={setPinnedBottomRowData}
            onFilterChanged={onAgGridFilterChanged}
            onColumnEverythingChanged={uploadFilterAndColumn}
            rowData={rowData}
            onCellClicked={handleCellClick}
            columnDefs={columnDefs}
            pagination={false}
            suppressRowTransform={true}
            suppressCopyRowsToClipboard={true}
            suppressCopySingleCellRanges={true}
            suppressCellFocus={true}
            suppressMenuHide={false}
            onColumnResized={uploadFilterAndColumn}
            tooltipShowDelay={1000}
            tooltipInteraction={true}
            rowClass={"border-t border-dashed "}
            overlayNoRowsTemplate={
              '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow; margin-top: 50px;">No Rows To Show</span>'
            }
            gridOptions={gridOptions}
            getRowStyle={(params) => {
              if (params.rowIndex % 2 === 0) {
                return { background: "#f8f8f8" };
              } else {
                return { background: "#ffffff" };
              }
            }}
          ></AgGridReact>
        </Box>
      </HStack>
    </div>
  );
}

export default AGGridShareholderTable;
