import _ from "lodash";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { LightBulbIcon, XMarkIcon } from "@heroicons/react/24/solid";
import React, { useState } from "react";

type AlertModalModel = {
  data?: string;
  heading?: string;
  disableX?: boolean;
  type?: AlertType;
};

export enum AlertType {
  INSIGHT,
  ALERT,
  ERROR,
}

export default function AlertMessage({
  data,
  disableX = false,
  heading,
  type = AlertType.ALERT,
}: AlertModalModel) {
  const [hide, setHide] = useState(false);

  const textColor =
    type === AlertType.ALERT
      ? "text-red-700"
      : type === AlertType.INSIGHT
      ? "text-yellow-700"
      : type === AlertType.ERROR
      ? "text-red-700"
      : "";
  const iconColor =
    type === AlertType.ALERT
      ? "text-red-500"
      : type === AlertType.INSIGHT
      ? "text-yellow-500"
      : type === AlertType.ERROR
      ? "text-red-500"
      : "";
  return (
    <div
      className={`bg-white px-4 border w-fit border-borderColor rounded-md hover:shadow-lg flex flex-col min-h-full items-end justify-center p-2 text-center sm:items-center ${
        hide ? "hidden" : "block"
      }`}
    >
      <div className="sm:flex sm:items-center">
        {type === AlertType.ALERT && (
          <div className="mx-auto flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:h-8 sm:w-8">
            <ExclamationTriangleIcon
              className={`h-6 w-6 animate-pulse ${iconColor}`}
              aria-hidden="true"
            />
          </div>
        )}
        {type === AlertType.INSIGHT && (
          <div className="mx-auto flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full bg-yellow-100 sm:h-8 sm:w-8">
            <LightBulbIcon
              className={`h-6 w-6  animate-pulse ${iconColor}`}
              aria-hidden="true"
            />
          </div>
        )}
        {type === AlertType.ERROR && (
          <div className="mx-auto flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:h-8 sm:w-8">
            <XMarkIcon
              className={`h-6 w-6 animate-pulse ${textColor}`}
              aria-hidden="true"
            />
          </div>
        )}
        <div className="text-center sm:ml-4 sm:text-left">
          <h3 className={`text-xs2 font-semibold leading-6 ${textColor}`}>
            {heading}
          </h3>
          <div className="mt-0.5">
            <p className="text-xxs text-gray-400 w-80">{data}</p>
          </div>
        </div>
        <div className="h-full justify-start pb-8">
          {!disableX && (
            <XMarkIcon
              onClick={() => {
                setHide(true);
              }}
              className="h-4 w-4 text-secondary cursor-pointer"
              aria-hidden="true"
            />
          )}
        </div>
      </div>
    </div>
  );
}
