import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Icon } from "@iconify/react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Dialog } from "@mui/material";

import {
  HStack,
  VStack,
  Error,
  useGetCompanyName,
} from "../../components/utils";
import {
  ButtonSize,
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../quickRound/CTAButtonComponents";
import { Input, Label } from "../../components/shared/InputField";
import { OutstandingConvertibleModelTransaction } from "./RoundCreationModel";
import { FormatNumberSpan } from "../../utils/currencyRoboto";
import {
  getCurrencySymbol,
  getCurrencyType,
} from "../../utils/currencyFormatter";
import { tableEntryStyle } from "../../components/TableComponent";
import { useRMCreationStore } from "../../store/roundCreationStore";
import OutstandingConvertibleThirdPopup from "./ConvertibleThirdPopUp";
import {
  ConvertibleDetails,
  ConvertibleType,
  IndividualConvertiblesState,
} from "../../types/Modeling";
import { useRoundModelStore } from "../../store/roundModelingStore";
import { Select } from "../../components/shared/Select";

type OutstandingConvertibleProps = {
  onPrimaryAction: () => void;
  onSecondaryAction: () => void;
  onClose: () => void;
  convertible: ConvertibleDetails;
};

const OutstandingConvertiblePopup = ({
  onPrimaryAction,
  onSecondaryAction,
  onClose,
  convertible,
}: OutstandingConvertibleProps) => {
  const {
    roundCreation,
    setRoundCreation,
    sendMessage,
    reset: discardRoundCreation,
  } = useRoundModelStore();
  const companyName = useGetCompanyName();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    // const convertibleList =

    onPrimaryAction();
  };

  const symbol = getCurrencySymbol();
  const currency = getCurrencyType();

  return (
    <div className="w-full">
      <Box className="w-full px-4 pt-4 pb-2 text-lg font-medium border-b">
        <h6 className="flex justify-between">
          <div></div>
          <VStack>
            <p className="text-lg font-medium">Calculated Share Price</p>
          </VStack>
          <XMarkIcon
            className="h-5 font-medium cursor-pointer"
            onClick={() => onClose()}
          />
        </h6>
      </Box>
      <HStack>
        <VStack className="justify-start w-full px-10 pt-4">
          <p className="text-sm text-[#464E5F] font-medium whitespace-nowrap pb-2">
            Security Name
          </p>
          <p className="text-lg font-medium">{convertible?.securityName}</p>
        </VStack>
        <VStack className="justify-start w-full px-10 pt-4">
          <Label className="text-[#464E5F] font-medium whitespace-nowrap pb-2">
            Conversion Method
          </Label>
          <Select
            options={["Pre-Money", "Post-Money", "Investment Method"]}
            className="w-72"
            value={
              roundCreation.convertibles!.convertibles.find(
                (item) =>
                  item.instrumentClassId === convertible.instrumentClassId
              )!.method
            }
            onChange={(e: any) => {
              roundCreation.convertibles!.convertibles.find(
                (item) =>
                  item.instrumentClassId === convertible.instrumentClassId
              )!.method = e.target.value as ConvertibleType;
              roundCreation.convertibles!.convertibles.find(
                (item) =>
                  item.instrumentClassId === convertible.instrumentClassId
              )!.state = IndividualConvertiblesState.SettleConvertible;

              sendMessage(roundCreation);
            }}
            // disabled={formik.values.updateConversionRatio}
          />
        </VStack>
      </HStack>
      <VStack className="w-full px-10 py-7 gap-9">
        <HStack className="justify-between gap-4">
          {convertible?.conversionRatio > 0 && (
            <VStack className="p-2 bg-white rounded-md shadow-box">
              <p className="text-sm text-[#464E5F] font-medium whitespace-nowrap pb-2">
                Original Conv. Ratio
              </p>
              <HStack className="items-center">
                <p className="text-lg font-medium">
                  {`1: ${convertible?.conversionRatio}`}
                </p>
              </HStack>
            </VStack>
          )}

          <HStack className="gap-8 p-2 bg-white rounded-md shadow-box">
            <VStack className="">
              <p className="text-sm text-[#464E5F] font-medium whitespace-nowrap pb-2">
                Base Valuation
              </p>
              <HStack className="items-center">
                <span className="pr-1 text-xs font-normal">{symbol}</span>
                <p className="text-lg font-medium">
                  <FormatNumberSpan
                    value={convertible?.baseValuation ?? 0}
                    format={currency}
                  />
                </p>
              </HStack>
            </VStack>
            <VStack className="items-center justify-center">
              <p className="text-sm text-[#464E5F] font-medium whitespace-nowrap text-center">
                {(convertible && convertible.discount * 100)?.toLocaleString()}%
              </p>
              <p className="text-sm text-[#464E5F] font-medium whitespace-nowrap  text-center">
                Discount
              </p>
            </VStack>
            <VStack className="">
              <p className="text-sm text-[#464E5F] font-medium whitespace-nowrap pb-2">
                Cap Valuation
              </p>
              <HStack className="items-center">
                <span className="pr-1 text-xs font-normal">{symbol}</span>
                <p className="text-lg font-medium">
                  <FormatNumberSpan
                    value={convertible?.valuationCap}
                    format={currency}
                  />
                </p>
              </HStack>
            </VStack>
          </HStack>

          <VStack className="p-2 bg-white rounded-md shadow-box">
            <p className="text-sm text-[#464E5F] font-medium whitespace-nowrap pb-2">
              Pre Money Valuation
            </p>
            <HStack className="items-center">
              <span className="pr-1 text-xs font-normal">{symbol}</span>
              <p className="text-lg font-medium">
                <FormatNumberSpan
                  value={convertible?.currentValuation}
                  format={currency}
                />
              </p>
            </HStack>
          </VStack>
        </HStack>

        <Box className="p-2 bg-white shadow-box">
          <table className="w-full">
            <thead className=" text-center font-medium text-xs2 text-[#002685] whitespace-nowrap border-b border-[#E4E6EF] border-dashed">
              <th></th>
              <th className="font-medium px-2 py-3.5 text-left">
                Cap Price ({symbol})
              </th>
              <th className="font-medium px-2 py-3.5 text-left">
                Discount Price ({symbol})
              </th>
              <th className="font-medium px-2 py-3.5 text-left">
                Current Price ({symbol})
              </th>
              <th className="font-medium px-2 py-3.5 text-left">
                Min Price ({symbol})
              </th>
            </thead>
            <tbody>
              <tr className="whitespace-nowrap hover:bg-slate-50 h-16 text-[#030303] text-xs2 font-medium border-b border-[#E4E6EF] border-dashed text-center">
                <td className={`px-2 ${tableEntryStyle}`}>Convertible price</td>
                <td className={`px-2 ${tableEntryStyle}`}>
                  {convertible?.capPPS ? (
                    <FormatNumberSpan
                      value={convertible?.capPPS}
                      format={currency}
                    />
                  ) : (
                    "-"
                  )}
                </td>
                <td className={`px-2 ${tableEntryStyle}`}>
                  {convertible?.discountPPS ? (
                    <FormatNumberSpan
                      value={convertible?.discountPPS}
                      format={currency}
                    />
                  ) : (
                    "-"
                  )}
                </td>
                <td className={`px-2 ${tableEntryStyle}`}>
                  {convertible?.roundPPS ? (
                    <FormatNumberSpan
                      value={convertible?.roundPPS}
                      format={currency}
                    />
                  ) : (
                    "-"
                  )}
                </td>
                <td className={`px-2 ${tableEntryStyle}`}>
                  {convertible?.minPPS ? (
                    <FormatNumberSpan
                      value={convertible?.minPPS}
                      format={currency}
                    />
                  ) : (
                    "-"
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </Box>
      </VStack>
      <HStack className="items-center justify-start w-full px-10 pt-4">
        <p className="w-56 text-sm font-medium">
          Final Conversion Price ({symbol})
        </p>
        <div className="mr-2">:</div>
        <p className="text-sm font-medium">
          {convertible?.finalConversionPPS ? (
            <FormatNumberSpan
              value={convertible?.finalConversionPPS}
              format={currency}
            />
          ) : (
            ""
          )}
        </p>
      </HStack>
      <HStack className="items-center justify-start w-full px-10 pt-4">
        <p className="w-56 text-sm font-medium">Final Conversion Ratio</p>
        <div className="mr-2">:</div>
        <p className="text-sm font-medium">
          {Number.isNaN(Number(convertible?.newConversionRatio.split(":")[1]))
            ? ""
            : `1 : ${Number(
                convertible?.newConversionRatio.split(":")[1]
              ).toFixed(2)}`}
        </p>
      </HStack>
      <HStack className="justify-between w-full px-10 mt-10 mb-10">
        <SecondaryCTAButton
          event-name="revert changes"
          buttonSize={ButtonSize.SMALL}
          onClick={() => onClose()}
        >
          Back
        </SecondaryCTAButton>
        <PrimaryCTAButton
          event-name="Convertible Preview Details"
          buttonSize={ButtonSize.SMALL}
          className="p-1"
          onClick={() => handleSubmit()}
          disabled={!(convertible.discount && convertible.valuationCap)}
        >
          {!loading && <span>Next</span>}

          {loading && (
            <HStack>
              <p className="indicator-progress" style={{ display: "block" }}>
                Please wait...
              </p>
              <Icon
                className="animate-spin"
                icon="lucide:loader-2"
                width={18}
              />
            </HStack>
          )}
        </PrimaryCTAButton>
      </HStack>
    </div>
  );
};

export default OutstandingConvertiblePopup;
