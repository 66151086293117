import create from "zustand";
import { configurePersist } from "zustand-persist";
import { SwitchDropDownModel } from "../pages/rounds/RoundsAllTransactions";

const { persist: _persist } = configurePersist({
  storage: localStorage,
  rootKey: "root",
});

const persist = _persist as any;

type InternalSwitchState = {
  shareholders: SwitchDropDownModel[];
  transaction: SwitchDropDownModel[];
  security: SwitchDropDownModel[];
  setShareholders: (list: SwitchDropDownModel[]) => void;
  setTransaction: (list: SwitchDropDownModel[]) => void;
  setSecurity: (list: SwitchDropDownModel[]) => void;
  reset: () => void;
};

const initialState = {
  shareholders: [],
  transaction: [],
  security: [],
};

const store = create<InternalSwitchState>(
  persist(
    {
      key: "transaction",
    },
    (set: any) => ({
      ...initialState,
      setShareholders: (list: any) => {
        set((state: any) => ({ ...state, shareholders: list }));
      },
      setTransaction: (list: any) => {
        set((state: any) => ({ ...state, transaction: list }));
      },
      setSecurity: (list: any) => {
        set((state: any) => ({ ...state, security: list }));
      },
      reset: () => {
        set(() => initialState);
      },
    })
  )
);

export const useInternalSwitchStateStore = store;
