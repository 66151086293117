type TooltipProps = {
  text: string | null;
  children: any;
  _className?: string;
  _position?:
    | "right-0"
    | "left-0"
    | "right-1/2"
    | "bottom-0"
    | "left-1/2"
    | "bottom-1"
    | "bottom-4"
    | "top-6 right-0";
};

function Tooltip(props: TooltipProps) {
  const { text, children, _className, _position } = props;
  return (
    <div className="relative group">
      {children}
      {text && (
        <div
          className={`absolute items-center hidden group-hover:flex left-0 ${_position}`}
        >
          <p
            className={` z-10 text-justify w-fit   max-w-96 whitespace-normal px-2 py-2 text-xs text-black overflow-visible bg-white shadow-lg ${_className}`}
          >
            {text}
          </p>
        </div>
      )}
    </div>
  );
}
export default Tooltip;
