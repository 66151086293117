import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import { PrimaryCTAButton } from "../quickRound/CTAButtonComponents";
import { HStack, VStack } from "../../components/utils";
import BCHeader from "../../shared/BCHeader";
import GridExample from "./GridExample";
import EmptyTableIllustration from "../../shared/EmptyTableIllustration";
import Footer from "../../components/shared/Footer";

export type FileType = {
  name: string;
  size: number;
  type: string;
};

function CustomTable() {
  const [loading, setLoading] = useState(false);
  const fileRef1 = useRef() as React.MutableRefObject<HTMLInputElement>;
  const [excelFile, setExcelFile] = useState<File>();
  const [excelData, setExcelData] = useState<String[][]>([]);
  const initialValue = {
    name: "",
    size: 0,
    type: "",
  };

  const [excelFileName, setExcelFileName] = useState<FileType>(initialValue);

  async function updateExcel(e: React.ChangeEvent<HTMLInputElement>) {
    if (!e?.target?.files?.[0]) return;
    const file = e.target.files[0];
    const allowedFileExtensions = [".xlsx"];
    if (
      allowedFileExtensions.some((extension) => file.name.endsWith(extension))
    ) {
      setExcelFile(file);
      setExcelFileName({
        name: file.name,
        size: file.size,
        type: file.type,
      });
    } else {
      toast(
        ` Invalid file type, allowed file types are ${allowedFileExtensions.join(
          ", "
        )}`,
        { type: "error" }
      );
    }
  }

  const parseExcel = async () => {
    const data = await excelFile?.arrayBuffer();
    const workbook = XLSX.read(data);
    await parseAndSaveTable(workbook);
  };

  const extractSheetData = (workbook: XLSX.WorkBook, sheetNo: number) => {
    const sheet = workbook.Sheets[workbook.SheetNames[sheetNo - 1]];
    const sheetData: string[][] = XLSX.utils.sheet_to_json(sheet, {
      header: 1,
      blankrows: false,
      defval: "",
    });

    sheetData.forEach((row, i) => {
      row.forEach((cell, j) => {
        sheetData[i][j] = sheetData[i][j].toString().trim();
      });
    });
    return sheetData;
  };

  const parseAndSaveTable = async (workbook: XLSX.WorkBook) => {
    const excelData: string[][] = extractSheetData(workbook, 1);
    setExcelData(excelData);
  };

  useEffect(() => {
    handleChange();
  }, [excelFile]);

  function handleChange() {
    if (!excelFile) return;
    const allowedFileExtensions = ["xlsx", "xls", "ods"];
    if (excelFile !== undefined) {
      if (
        allowedFileExtensions.some((extension) =>
          excelFile.name.endsWith(extension)
        )
      ) {
        const formData = new FormData();
        formData.append("excelFile", excelFile);
        setLoading(true);
        parseExcel();
      } else {
        setLoading(false);
        toast(
          ` Invalid file type, allowed file types are ${allowedFileExtensions.join(
            ", "
          )}`,
          { type: "error" }
        );
      }
    } else {
      toast(`Please upload both document and select relevant round`, {
        type: "error",
      });
    }
    fileRef1.current.value = "";
  }

  const onClickExcelFunction = () => {
    fileRef1.current.value = "";
    fileRef1.current.click();
  };

  return (
    <div className="justify-around h-screen">
      <div className="min-h-full">
        <HStack className="sticky top-0 z-10 justify-between w-full px-8 py-4 bg-secondary">
          <VStack>
            <BCHeader
              darkMode={true}
              className="items-baseline mb-2"
              bcTitle="Custom Dashboard"
              bcSubTitle=""
            />
            <p className="ml-3 -mt-4 font-medium sm:mt-0 sm:text-xs1 text-xxs text-descriptionColor whitespace-nowrap md:mr-12">
              Upload the table template and build your own table.
            </p>
          </VStack>
          {!(excelData && excelData.length > 0) ? (
            <div></div>
          ) : (
            <PrimaryCTAButton
              className="h-8 w-fit"
              onClick={() => {
                onClickExcelFunction();
              }}
            >
              Upload Excel
            </PrimaryCTAButton>
          )}
        </HStack>
        {!(excelData && excelData.length > 0) && (
          <VStack className="justify-center">
            <input
              ref={fileRef1}
              onChange={updateExcel}
              multiple={false}
              type="file"
              accept=".xlsx"
              hidden
            />
            <div className="pt-24">
              <EmptyTableIllustration text={" Please upload the excel sheet"} />
            </div>

            <HStack className="w-full pt-12">
              <PrimaryCTAButton
                className="mx-auto w-fit"
                onClick={() => {
                  onClickExcelFunction();
                }}
              >
                Upload Excel
              </PrimaryCTAButton>
            </HStack>
          </VStack>
        )}
        {excelData && excelData.length > 0 && <GridExample data={excelData} />}
      </div>
      <Footer />
    </div>
  );
}

export default CustomTable;
