import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import ReactDOMServer from "react-dom/server";

import {
  CellClickedEvent,
  ColDef,
  GridApi,
  SideBarDef,
} from "ag-grid-community";
import { useNavigate } from "react-router";
import { AgGridReact } from "ag-grid-react";
import { Dialog } from "@mui/material";
import { toast } from "react-toastify";
import AddPrimaryPopup from "./AddPrimaryPopup";
import { Action, CTADropdown } from "../../components/shared/Dropdown";
import { agConfigDashboardSideBar } from "../dashboardPage/AgGridConfig";
import {
  getCurrencySymbol,
  getCurrencyType,
} from "../../utils/currencyFormatter";

import { Box, HStack, useGetCompanyName } from "../../components/utils";

import { useAuthStore } from "../../store";
import {
  useGetOnFilterState,
  usePostOnFilterState,
} from "../dashboardPage/AgGridCacheQuery";
import { tableCellStyle } from "../../components/TableComponent";

import { useAgGridTableState } from "../../store/agGridTableStore";

import { useNewShareholderInstrumentStore } from "../../store/newShareholderAndInstrumentStore";
import { PrimaryTransactionDataModel } from "../../types/quickRound";

import { EmptyTable } from "../../components/shared/EmptyTable";
import { handleEventForTracking } from "../../amplitudeAnalytics";

import { useShareholderInstrumentStore } from "../../store/shareholderInstrumentStore";

import {
  OutstandingConvertible,
  QuickRoundOutstandingConvertible,
} from "../../types/Modeling";
import { useRoundModelStore } from "../../store/roundModelingStore";
import {
  SecurityNameRender,
  SecurityTypeRender,
} from "./OutstandingConvertibleAgComponent";
import {
  ShareholderNameRender,
  MethodRender,
  ValuationAppliedRender,
  ConversionPriceRender,
  NewConversionRatioRender,
} from "./QuickRoundConvertibleAgComponent";
import OutstandingConvertibleSecondPopup from "../modeling/ConvertibleSecondPopUp";
import { useQuickRoundTransactionStore } from "../../store/useQuickTransactionStore";

function AgGridQuickRoundConvertibleTable({ roundDetails }: any) {
  const currencySymbol = getCurrencySymbol();
  const companyName = useGetCompanyName();
  const companyId = useAuthStore().companyId ?? "";
  const userId = useAuthStore().user?.userId ?? 0;
  const gridApi = useRef<any>(null);
  const shareholderAndInstrumentstore = useShareholderInstrumentStore();

  const { state: template, setState: setTemplate } =
    useQuickRoundTransactionStore();
  const [index, setIndex] = useState(-1);

  const [outstandingConvertibles, setOutstandingConvertibles] = useState<
    QuickRoundOutstandingConvertible[]
  >([]);

  useEffect(() => {
    const convertibles: QuickRoundOutstandingConvertible[] = [];
    for (const convertible of template.newRoundInput?.convertibles || []) {
      const instrument = shareholderAndInstrumentstore.instrumentClasses.find(
        (instrument) => instrument.id === convertible.instrumentClassId
      );
      convertibles.push({
        instrumentClassId: convertible.instrumentClassId,
        securityName: instrument?.name || "",
        securityType: instrument?.subType || "",
        shareholders: [],
        method: convertible.conversionMethod || "",
        valuationApplied: convertible.valuationApplied || 0,
        newConversionRatio: `1:${convertible.newConversionRatio}`,
        conversionPrice: convertible.conversionPrice || 0,
      });
    }
    setOutstandingConvertibles(convertibles);
  }, [template]);

  const [displayedRowCount, setDisplayedRowCount] = useState<number>(0);

  const primaryTransactionStore = useNewShareholderInstrumentStore();

  useEffect(() => {
    const count = gridApi.current?.api.getDisplayedRowCount();

    if (count !== undefined) {
      setDisplayedRowCount(count);
    }
  }, [primaryTransactionStore.primary]);

  const [dialog, setDialog] = useState<{
    open: boolean;
    data?: any;
    name: "Update Security" | "";
  }>({
    open: false,
    name: "",
  });
  const { refetch, data: filterState } = useGetOnFilterState(
    `${userId}`,
    companyId
  );
  const agTableStore = useAgGridTableState();
  useEffect(() => {
    refetch().then((data) => {
      if (data.data) agTableStore.setState(data.data);
      setColumnSetting(
        data.data?.outstandingQuickConvertiblesHoldersColumnModel
      );
      setFilterSetting(
        data.data?.outstandingQuickConvertiblesHoldersFilterModel
      );
    });
  }, []);

  const { mutate: postOnFilter } = usePostOnFilterState();

  function getColumnSetting() {
    if (gridApi.current) return gridApi.current.columnApi?.getColumnState();
    return {};
  }

  function setColumnSetting(model: any) {
    if (gridApi.current)
      gridApi.current.columnApi.applyColumnState({ state: model });
    return {};
  }

  // Gets filter model via the grid API
  const getFilterSetting = () => {
    if (gridApi.current) return gridApi.current.api?.getFilterModel();
    return {};
  };

  const setFilterSetting = (model: any) => {
    if (gridApi.current) return gridApi.current.api?.setFilterModel(model);
    return {};
  };

  const uploadFilterAndColumn = async () => {
    const columnState = await getColumnSetting();
    const filterState = await getFilterSetting();
    postOnFilter({
      userId: `${userId}`,
      companyId,
      filterData: {
        ...agTableStore.state,
        outstandingQuickConvertiblesHoldersColumnModel: columnState,
        outstandingConvertiblesHoldersFilterModel: filterState,
      },
    });
  };

  const cin = "";

  const componentsRegistery = useMemo(
    () => ({
      shareholderName: memo(ShareholderNameRender),
      securityName: memo(SecurityNameRender),
      securityType: memo(SecurityTypeRender),
      method: memo(MethodRender),
      valuationApplied: memo(ValuationAppliedRender),
      newConversionRatio: memo(NewConversionRatioRender),
      conversionPrice: memo(ConversionPriceRender),
    }),
    []
  );

  function handleUpdateSecurityAction(action: string, data: any) {
    if (action === "Update Security") {
      setDialog({
        open: true,
        name: "Update Security",
        data,
      });
    }
  }

  const navigate = useNavigate();

  const onClickShareholderName = (id: string) => {
    handleEventForTracking({ eventName: `Shareholder Name` });
    navigate(`/shareholders/individual/${id}`);
  };

  const isValidDate = (date: string) =>
    Number.isNaN(date) &&
    new Date(date).toString() !== "Invalid Date" &&
    !Number.isNaN(Date.parse(date));

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      autoHeight: true,
      wrapHeaderText: true,
      suppressColumnVirtualisation: true,
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      filterParams: {
        buttons: ["reset"],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
      flex: 1,
    }),
    []
  );
  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        headerName: "Security Name",
        field: "securityName",
        filter: "agSetColumnFilter",
        cellRenderer: "securityName",
        cellStyle: tableCellStyle,
        sortable: false,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipSecurity",
      },
      {
        headerName: "Security Type",
        field: "securityType",
        filter: "agSetColumnFilter",
        cellRenderer: "securityType",
        cellStyle: tableCellStyle,
        sortable: false,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipSecurity",
      },
      {
        headerName: "Method",
        field: "method",
        filter: "agSetColumnFilter",
        cellRenderer: "method",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
      },
      {
        headerName: `Valuation Applied (${currencySymbol})`,
        field: "valuationApplied",
        filter: "agNumberColumnFilter",
        cellRenderer: "valuationApplied",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "valuationAppliedTooltip",
      },
      {
        headerName: "New Conversion Ratio",
        field: "newConversionRatio",
        cellRenderer: "newConversionRatio",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: [],
      },
      {
        headerName: `Conversion Price (${currencySymbol})`,
        field: "conversionPrice",
        filter: "agNumberColumnFilter",
        cellRenderer: "conversionPrice",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "conversionPriceTooltip",
      },
      {
        headerName: "",
        field: "actions",
        pinned: "right",
        hide: false,
        width: 80,
        maxWidth: 80,
        filter: false,
        menuTabs: [],
        colId: "action-column",
        suppressNavigable: true,
        suppressColumnsToolPanel: true,
        cellStyle: { "padding-top": "16px", "line-height": "20px" },
        resizable: false,
        sortable: false,
        editable: false,
        cellRendererParams: ({ value }: { value: any }) => value?.props,
        cellRenderer: CTADropdown,
      },
    ],
    []
  );

  const gridOptions = {
    suppressRowVirtualisation: true, // Add this line to disable row virtualization
    paginationAutoPageSize: false, // Optional: Set to false if using custom pagination
    suppressScrollOnNewData: true, // Optional: Set to true to prevent scrolling to the top on
  };
  const currencyType = getCurrencyType();
  const rowData = useMemo(
    () =>
      outstandingConvertibles?.map((template, index) => ({
        insrumentClassId: template.instrumentClassId,
        securityName: template.securityName,
        securityType: template.securityType,
        newConversionRatio: template.newConversionRatio,
        conversionPrice: template.conversionPrice,
        method: template.method,
        valuationApplied: template.valuationApplied,
        valuationAppliedTooltip:
          template.valuationApplied.toLocaleString(currencyType),
        conversionPriceTooltip:
          template.conversionPrice.toLocaleString(currencyType),
        currencySymbol,
        currencyType,
        actions: (
          <CTADropdown
            actions={[
              {
                name: "Update Security",
                disabled: false,
              },
            ]}
            onAction={(action) => {
              setIndex(index);

              handleUpdateSecurityAction(action.name, template);
            }}
          />
        ),
      })),
    [outstandingConvertibles]
  );

  function setTableHeight() {
    if (displayedRowCount === 1) {
      return (displayedRowCount + 2.0) * 60;
    } else if (displayedRowCount === 2) {
      return (displayedRowCount + 2.0) * 60;
    } else if (displayedRowCount === 3) {
      return (displayedRowCount + 2.0) * 60;
    } else if (displayedRowCount === 4) {
      return (displayedRowCount + 2.0) * 60;
    } else if (displayedRowCount === 5) {
      return (displayedRowCount + 2.0) * 60;
    } else if (displayedRowCount === 6) {
      return (displayedRowCount + 2.0) * 60;
    } else if (displayedRowCount === 7) {
      return (displayedRowCount + 2.0) * 60;
    } else if (displayedRowCount === 8) {
      return (displayedRowCount + 2.0) * 60;
    } else if (displayedRowCount === 9) {
      return (displayedRowCount + 2.0) * 60;
    } else {
      return 10 * 60;
    }
  }

  const handleCellClick = (cellParams: CellClickedEvent<any, any>) => {
    if (cellParams.column.getColId() !== "action-column") {
      const template = cellParams.data;
      if (cellParams.column.getColId() === "shareholderName") {
        onClickShareholderName(template.id);
      }
    }
  };

  const [filteredRowData, setFilteredRowData] = useState<any>([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const onAgGridFilterChanged = (grid: any) => {
    const filtersApplied = grid.api.isAnyFilterPresent();
    setIsFilterApplied(filtersApplied);

    const filteredData = grid.api
      .getModel()
      .rowsToDisplay.map((node: any) => node.data);
    setFilteredRowData(filteredData);
    uploadFilterAndColumn();
  };
  return (
    <div>
      <HStack className="flex-col items-start justify-start py-2 bg-white rounded-md lg:flex-row lg:justify-between lg:items-center">
        <Dialog open={dialog.open} maxWidth="md">
          {dialog.name === "Update Security" &&
            template.newRoundInput?.convertibles && (
              <OutstandingConvertibleSecondPopup
                data={template.newRoundInput?.convertibles[index]!}
                onPrimaryAction={() =>
                  setDialog({ open: false, name: "Update Security" })
                }
                onSecondaryAction={() =>
                  setDialog({
                    open: false,
                    name: "Update Security",
                  })
                }
                onClose={() =>
                  setDialog({
                    open: false,
                    name: "Update Security",
                  })
                }
              />
            )}
        </Dialog>
      </HStack>
      <HStack className="justify-between w-full">
        <Box
          style={{
            height: setTableHeight(),
          }}
          className="w-full h-full max-h-full overflow-x-auto ag-theme-material "
        >
          <AgGridReact
            sideBar={agConfigDashboardSideBar}
            onGridReady={(params) => {
              // Store the grid API referen
              gridApi.current = params;
            }}
            components={componentsRegistery}
            alwaysShowHorizontalScroll
            alwaysMultiSort
            animateRows={true}
            defaultColDef={defaultColDef}
            onFilterChanged={onAgGridFilterChanged}
            onColumnEverythingChanged={uploadFilterAndColumn}
            rowData={rowData}
            onCellClicked={handleCellClick}
            columnDefs={columnDefs}
            pagination={false}
            suppressRowTransform={true}
            suppressCopyRowsToClipboard={true}
            suppressCopySingleCellRanges={true}
            suppressCellFocus={true}
            suppressMenuHide={false}
            onColumnResized={uploadFilterAndColumn}
            tooltipShowDelay={1000}
            tooltipInteraction={true}
            rowClass={"border-t border-dashed "}
            gridOptions={gridOptions}
            overlayNoRowsTemplate={ReactDOMServer.renderToString(
              <EmptyTable
                header="No Transaction"
                subHeader="Get started by adding a new transaction"
              />
            )}
            getRowStyle={(params) => {
              if (params.rowIndex % 2 === 0) {
                return { background: "#f8f8f8" };
              } else {
                return { background: "#ffffff" };
              }
            }}
          ></AgGridReact>
        </Box>
      </HStack>
    </div>
  );
}

export default AgGridQuickRoundConvertibleTable;
