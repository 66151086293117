export const initialFounderData = {
  fullName: "",
  emailId: "",
  designation: "",
  contactNumber: 0,
  address: "",
  dateOfBirth: "",
  nationality: "India",
  linkedInProfile: "",
  twitterHandle: "",
  isEdit: false,
  isSelected: false,
  shouldShowDialog: false,
};

export const initialValuationData = {
  name: "",
  dateOfValuation: "",
  typeOfValuation: "",
  valuationPps: 0,
  valuationMethodology: "",
  bookValue: 0,
  valuer: "",
  valuerRgNumber: "",
  valuationReportBase64: "",
  udinNumber: "",
  fileType: "",
};

export const initialDirectorData = {
  fullName: "",
  typeOfDirector: "",
  emailId: "",
  din: "",
  address: "",
  contactNumber: "",
  nationality: "India",
  dateOfBirth: "",
  countryOfResidence: "",
  dateOfAppointement: "",
  dateOfBoardResoluitonForAppointement: "",
  isEdit: false,
  isSelected: false,
  shouldShowDialog: false,
};
