import { useRef, useState } from "react";
import { Box, HStack, VStack } from "../../components/utils";
import {
  tableEntryStyle,
  tableFirstColStyle,
} from "../../components/TableComponent";
import { formatDisplayDate } from "../../utils/date";

import {
  formatToReadableNumber,
  getCurrencySymbolRoboto,
  getRoundCurrencySymbol,
} from "../../utils/currencyRoboto";
import { getCurrency, getCurrencyType } from "../../utils/currencyFormatter";

export function TypeRender(data: any) {
  return (
    <div className="pt-2 whitespace-nowrap">
      <VStack className="gap-1">
        <p className={tableFirstColStyle}>{data.data.type}</p>
        <div>
          {data.data?.isPartlyPaid && (
            <div className="px-1 mb-2 text-red-500 border border-red-500 rounded-sm font-xxs w-fit ">
              Partly Paid
            </div>
          )}
        </div>
      </VStack>
    </div>
  );
}

export function SecurityClassRender(data: any) {
  return (
    <div className="pt-2 whitespace-nowrap">
      <p className={tableEntryStyle}>{data.data.securityClass}</p>
    </div>
  );
}

export function DateRender(data: any) {
  return (
    <div className="pt-2 whitespace-nowrap">
      <p className={tableEntryStyle}>{formatDisplayDate(data.data.date)}</p>
    </div>
  );
}

export function NumberOfSharesRender(data: any) {
  const currencySymbol = getCurrencySymbolRoboto();
  const currency = getCurrencyType();
  return (
    <div className="pt-2 whitespace-nowrap">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.data?.numberOfShares,
          format: currency,
        })}
      </p>
    </div>
  );
}

export function ShareNumbersRender(data: any) {
  const [viewMore, setViewMore] = useState<boolean>(false);
  return (
    <td className="pt-2 whitespace-nowrap">
      <Box
        className={`${
          viewMore && data.data?.shareNumber?.length > 4 ? "h-20" : ""
        } overflow-y-auto`}
      >
        {data.data?.shareNumber?.map((share: any, index: any) =>
          viewMore ? (
            <p key={share} className={`${tableEntryStyle}`}>
              {share}
            </p>
          ) : (
            index < 3 && (
              <p key={share} className={tableEntryStyle}>
                {share}
              </p>
            )
          )
        )}

        {data.data.shareNumber?.length > 3 && (
          <p
            className={`cursor-pointer ${tableEntryStyle}`}
            onClick={() => {
              setViewMore(!viewMore);
            }}
          >
            {viewMore ? "View Less" : "View More"}
          </p>
        )}
      </Box>
    </td>
  );
}

export function PreHoldingSharesRender(data: any) {
  const currencySymbol = getCurrencySymbolRoboto();
  const currency = getCurrencyType();
  return (
    <div className="pt-2 whitespace-nowrap">
      <p className={tableEntryStyle}>
        {data.data.preHoldingShares &&
          Object.entries(data.data.preHoldingShares).map((values) => (
            <p key={values[0] + values[1]}>
              {values[1] ? (
                <HStack className="justify-center">
                  {formatToReadableNumber({
                    value: values[1] as number,
                    format: currency,
                  })}{" "}
                  <div>&#40;{values[0]}&#41;</div>
                </HStack>
              ) : (
                ""
              )}
            </p>
          ))}
      </p>
    </div>
  );
}

export function PostHoldingSharesRender(data: any) {
  const currencySymbol = getCurrencySymbolRoboto();
  const currency = getCurrencyType();
  return (
    <td className="pt-2 whitespace-nowrap">
      <p className={tableEntryStyle}>
        {data.data.postHoldingShares &&
          Object.entries(data.data.postHoldingShares).map((values) => (
            <p key={values[0] + values[1]}>
              {" "}
              {values[1] ? (
                <HStack className="justify-center">
                  {formatToReadableNumber({
                    value: values[1] as number,
                    format: currency,
                  })}
                  <div>&#40;{values[0]}&#41;</div>
                </HStack>
              ) : (
                ""
              )}
            </p>
          ))}
      </p>
    </td>
  );
}

export function PPSRender(data: any) {
  const currencySymbol = getCurrencySymbolRoboto();
  const currency = getCurrencyType();
  return (
    <div className="pt-2 whitespace-nowrap">
      <p className={tableEntryStyle}>
        <VStack className="gap-1">
          <div>
            {formatToReadableNumber({
              value: Math.abs(data.data.pps),
              format: currency,
            })}
          </div>
          {data.data.isPartlyPaid && (
            <div className="text-gray-400 text-xxs1">
              PPS Value ({currencySymbol}): {data.data.actualSharePrice}
            </div>
          )}
        </VStack>
      </p>
    </div>
  );
}

export function AmountRender(data: any) {
  const currencySymbol = getCurrencySymbolRoboto();
  const currency = getCurrencyType();
  return (
    <div className="pt-2 whitespace-nowrap">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({ value: data.data?.amount, format: currency })}{" "}
      </p>
    </div>
  );
}
export function AmountInTransactionRender(data: any) {
  const invCurrencySymbol = getRoundCurrencySymbol(
    data.data.amountInTransaction.currency
  );

  const invCurrency = getCurrency(data.data.amountInTransaction.currency);

  return (
    <div className="pt-2 whitespace-nowrap">
      {data.data.amountInTransaction.amount && (
        <p className={tableEntryStyle}>
          <span className="pr-1">{invCurrencySymbol}</span>
          {formatToReadableNumber({
            value: data.data.amountInTransaction.amount,
            format: invCurrency,
          })}{" "}
        </p>
      )}
      {!data.data.amountInTransaction.amount && "-"}
    </div>
  );
}

export function BuyerOrSellerRender(data: any) {
  const currencySymbol = getCurrencySymbolRoboto();
  const currency = getCurrencyType();
  return (
    <div className="pt-2 whitespace-nowrap">
      <p className={tableEntryStyle}>
        {data.data.buyerOrSeller ? data.data.buyerOrSeller : "-"}
      </p>
    </div>
  );
}
