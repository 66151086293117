import create from "zustand";
import { AgGridStateModel } from "../pages/dashboardPage/AgGridCaptable";

type AgGridTableState = {
  state: AgGridStateModel;
  setState: (state: AgGridStateModel) => void;
  reset: () => void;
};

const initialState = {
  state: {},
};

const agGridTableState = create<AgGridTableState>((set) => ({
  ...initialState,
  setState: (value) => {
    set((state) => ({ state: value }));
  },
  reset: () => {
    set(() => initialState);
  },
}));

export const useAgGridTableState = agGridTableState;
