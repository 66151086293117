import React, { useEffect, useRef, useState } from "react";
import { Icon } from "@iconify/react";
import { HStack, VStack } from "../components/utils";
import esopLogo from "../utils/assets/esop.svg";
import esopLogoGrayed from "../utils/assets/esop-grayed.svg";
import { useAuthStore } from "../store";
import { useSetDefaultProduct } from "../queries/login";
import { getCompanyExists } from "../api/Esop";

function SwitchProduct({ setDialogOpen }: any) {
  const {
    role,
    user,
    accessToken,
    canAccessEsop: isEsopExists,
  } = useAuthStore();

  const { mutate: setDefaultEsop } = useSetDefaultProduct();

  const esopRole = "";

  const dialog = useRef<HTMLDivElement>(null);

  const checkIfClickOutside = (e: any) => {
    if (dialog?.current && !dialog?.current?.contains(e.target)) {
      setDialogOpen({ mode: "switch", open: false });
    }
  };
  document.addEventListener("mousedown", checkIfClickOutside);

  const redirectToEsop = async () => {
    const esopCompanyId = user?.isNewEsop
      ? user?.newEsopCompanyId
      : user?.legacyCompanyId;
    if (esopCompanyId && isEsopExists) {
      const optionsUrl = user?.isNewEsop
        ? process.env.REACT_APP_NEW_ESOP_URL
        : process.env.REACT_APP_OPTIONS_URL;
      await setDefaultEsop(user?.isNewEsop ? null : BigInt(esopCompanyId));
      window.location.href = `${
        optionsUrl || "http://localhost:3001"
      }/options/esopOverview?logintoken=${
        localStorage.getItem("accessToken") ||
        localStorage.getItem("accesstoken") ||
        accessToken
      }&companyId=${esopCompanyId}`;
    }
  };

  return (
    <div>
      <div
        onMouseEnter={() => {
          setDialogOpen({ mode: "switch", open: true });
        }}
        onMouseLeave={() => {
          setDialogOpen({ mode: "switch", open: false });
        }}
        // onMouseOver={() => {
        //   setDialogOpen({ mode: "switch", open: true });
        // }}
        id="dropdown"
        className="absolute z-40 bg-white mt-12 text-black border divide-y divide-gray-500 rounded shadow w-72"
        ref={dialog}
      >
        <ul
          className="py-1 text-xs font-normal text-dark w-full"
          aria-labelledby="dropdownDefault"
        >
          <HStack>
            <div className="font-medium text-base p-3">Switch Platform</div>
          </HStack>
          <HStack className="justify-between m-1 py-2 hover:bg-gray-100 items-center">
            <HStack className="items-center">
              <div>
                <Icon
                  icon="raphael:piechart"
                  color="#E85936"
                  width="30"
                  height="30"
                ></Icon>
              </div>
              <p className="font-semibold text-base p-1 text-orange-600">
                Cap Table
              </p>
            </HStack>
          </HStack>
          <HStack
            className={`justify-between m-1 p-1 hover:bg-gray-100 ${
              isEsopExists ? "cursor-pointer" : "bg-gray-100"
            } items-center`}
            onClick={redirectToEsop}
          >
            <HStack className="items-center">
              <div>
                <img
                  className="h-8"
                  src={isEsopExists ? esopLogo : esopLogoGrayed}
                  alt="esop"
                  color="#E85936"
                />
              </div>
              <p
                className={`font-semibold text-base p-2 ${
                  isEsopExists ? "text-orange-600" : "text-gray-400"
                }`}
              >
                ESOP
              </p>
            </HStack>
            <div className="p-1">
              {isEsopExists ? (
                <div>
                  {esopRole && (
                    <p
                      className={`w-fit mr-4 h-fit px-1 py-0.5 text-xs rounded-md font-semibold bg-inherit border text-blue-600 border-blue-600 px-2`}
                    >
                      {esopRole}
                    </p>
                  )}
                </div>
              ) : (
                <div>
                  <Icon
                    icon="il:lock"
                    width="18"
                    height="18"
                    className="mr-10"
                    color="gray"
                  />
                </div>
              )}
            </div>
          </HStack>
        </ul>
      </div>
    </div>
  );
}

export default SwitchProduct;
