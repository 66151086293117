import React from "react";
import CreatableSelect from "react-select/creatable";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();

export default function AnimatedMultiSelect({
  value,
  hasError,
  _className,
  onChange,
  options,
}: {
  value?: any;
  hasError?: boolean;
  _className?: string;
  onChange: Function;
  options: any;
}) {
  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      width: "100%",
      lineHeight: "1.25rem",
      marginTop: "0.375rem",
      borderRadius: "0.375rem",
      border: "1px solid #DBDFE9",
      backgroundColor: "transparent",
      padding: "0.25rem",
      paddingLeft: "1rem",
      focus: {
        ...provided.focus,
        borderColor: "#C6CCDC",
        outline: "none",
      },
    }),
    multiValue: (provided: any) => ({
      ...provided,
      borderColor: "#DBDFE9",
      borderRadius: "0.375rem",
      backgroundColor: "#F1F1F4",
      color: "#464E5F",
    }),
    multiValueLabel: (provided: any) => ({
      ...provided,
      color: "#464E5F",
      fontWeight: "600",
    }),
  };

  const handleChange = (newValue: any, actionMeta: any) => {
    onChange(newValue);
  };

  return (
    <CreatableSelect
      className={_className}
      closeMenuOnSelect={false}
      components={animatedComponents}
      value={value}
      isMulti
      options={options}
      styles={customStyles}
      onChange={handleChange}
    />
  );
}
