import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  CellClickedEvent,
  ColDef,
  GridApi,
  SideBarDef,
} from "ag-grid-community";
import { useNavigate } from "react-router";

import { AgGridReact } from "ag-grid-react";
import { Dialog } from "@mui/material";
import { Action, CTADropdown } from "../../components/shared/Dropdown";
import { agConfigDashboardSideBar } from "../dashboardPage/AgGridConfig";

import { ShareholderDataModel } from "../../types/Shareholder";

import {
  getCurrencySymbol,
  getCurrencyType,
} from "../../utils/currencyFormatter";

import { Box, HStack, useGetCompanyName } from "../../components/utils";
import GenericTableHeader from "../../shared/TableHeader";
import { IconCTAButton } from "../quickRound/CTAButtonComponents";
import QuickSearch from "../rounds/QuickSearch";
import { useAuthStore } from "../../store";
import {
  useGetOnFilterState,
  usePostOnFilterState,
} from "../dashboardPage/AgGridCacheQuery";
import { tableCellStyle } from "../../components/TableComponent";
import { ExportImport } from "../Utility/GrantsTable";
import {
  exportSharecertificateReport,
  exportShareholderReport,
} from "../../api/Report";
import { downloadExcel, downloadS3File } from "../../utils/DownloadFile";
import { useAgGridTableState } from "../../store/agGridTableStore";
import SearchInput from "../../components/shared/SearchInput";
import { RoundsToolTip } from "../dashboardPage/customTooltip";
import { getShareholderValue } from "../../constants/ShareholderConstants";
import { handleEventForTracking } from "../../amplitudeAnalytics";

import {
  ConvertibleDetails,
  ConvertibleType,
  IndividualConvertiblesState,
  ModelBuilder,
  OutStandingConvertibleDetails,
} from "../../types/Modeling";
import {
  SecurityClassRender,
  RoundsRender,
  MethodRender,
  ValuationAppliedRender,
  ConversionRatioRender,
  AmountInvestedRender,
  HoldingPercentageRender,
  NewConversionRatioRender,
} from "./ConvertibleAGgridComponent";
import OutstandingConvertiblePopup from "./ConvertiblePopup";
import OutstandingConvertibleThirdPopup from "./ConvertibleThirdPopUp";
import OutstandingConvertibleSecondPopup from "./ConvertibleSecondPopUp";
import { useRoundModelStore } from "../../store/roundModelingStore";

function AGGridConvertibleTable({
  convertibleDetails,
  roundModel,
}: {
  convertibleDetails: ConvertibleDetails[];
  roundModel: ModelBuilder;
}) {
  const {
    roundCreation: data,
    setRoundCreation: setRoundState,
    sendMessage,
    reset: discardRoundCreation,
  } = useRoundModelStore();

  const currencySymbol = getCurrencySymbol();
  const companyName = useGetCompanyName();
  const companyId = useAuthStore().companyId ?? "";
  const userId = useAuthStore().user?.userId ?? 0;
  const gridApi = useRef<any>(null);

  const displayedRowCount = gridApi.current?.api.getDisplayedRowCount();

  const { refetch, data: filterState } = useGetOnFilterState(
    `${userId}`,
    companyId
  );
  const agTableStore = useAgGridTableState();
  useEffect(() => {
    refetch().then((data) => {
      if (data.data) agTableStore.setState(data.data);
      setColumnSetting(data.data?.convertiblesColumnModel);
      setFilterSetting(data.data?.convertiblesFilterModel);
    });
  }, []);

  const { mutate: postOnFilter } = usePostOnFilterState();

  function getColumnSetting() {
    if (gridApi.current) return gridApi.current.columnApi?.getColumnState();
    return {};
  }

  function setColumnSetting(model: any) {
    if (gridApi.current)
      gridApi.current.columnApi.applyColumnState({ state: model });
    return {};
  }

  // Gets filter model via the grid API
  const getFilterSetting = () => {
    if (gridApi.current) return gridApi.current.api?.getFilterModel();
    return {};
  };

  const setFilterSetting = (model: any) => {
    if (gridApi.current) return gridApi.current.api?.setFilterModel(model);
    return {};
  };

  const uploadFilterAndColumn = async () => {
    const columnState = await getColumnSetting();
    const filterState = await getFilterSetting();
    postOnFilter({
      userId: `${userId}`,
      companyId,
      filterData: {
        ...agTableStore.state,
        convertiblesColumnModel: columnState,
        convertiblesFilterModel: filterState,
      },
    });
  };

  const [dialog, setDialog] = useState<{
    open: boolean;
    data?: any;
    name: "Settle Convertible" | "Second Popup" | "Third Popup" | "";
  }>({
    open: false,
    name: "",
  });
  function handleModifyAction(action: string, data: any, index: number) {
    if (action === "Settle Convertible") {
      setDialog({
        open: true,
        name: "Settle Convertible",
        data,
      });

      roundModel.convertibles!.convertibles[index].state =
        IndividualConvertiblesState.SettleConvertible;

      sendMessage(roundModel);
    }
    if (action === "Reset") {
      roundModel.convertibles!.convertibles[index].state =
        IndividualConvertiblesState.Reset;
      sendMessage(roundModel);
    }
  }

  const cin = "";

  const componentsRegistery = useMemo(
    () => ({
      securityClass: memo(SecurityClassRender),
      rounds: memo(RoundsRender),
      method: memo(MethodRender),
      valuationApplied: memo(ValuationAppliedRender),
      conversionRatio: memo(ConversionRatioRender),
      amountInvested: memo(AmountInvestedRender),
      holdingPercentage: memo(HoldingPercentageRender),
      newConversionRatio: memo(NewConversionRatioRender),
    }),
    []
  );

  const navigate = useNavigate();

  const onClickShareholderName = (id: string) => {
    handleEventForTracking({ eventName: "Shareholder Name" });
    navigate(`/shareholders/individual/${id}`);
  };

  const isValidDate = (date: string) =>
    Number.isNaN(date) &&
    new Date(date).toString() !== "Invalid Date" &&
    !Number.isNaN(Date.parse(date));

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      autoHeight: true,
      wrapHeaderText: true,
      suppressColumnVirtualisation: true,
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      filterParams: {
        buttons: ["reset"],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
      flex: 1,
    }),
    []
  );
  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        headerName: "Security Class",
        field: "securityClass",
        filter: "agTextColumnFilter",
        cellRenderer: "securityClass",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipSecurityClass",
      },
      {
        headerName: "Round",
        field: "rounds",
        cellRenderer: "rounds",
        initialWidth: 250,
        cellStyle: tableCellStyle,
        sortable: false,
        filter: "agSetColumnFilter",
        tooltipField: "tooltipSecurityClass",
        tooltipComponent: RoundsToolTip,
        menuTabs: ["filterMenuTab"],
        valueGetter: (params) =>
          params.data.rounds?.map((value: any) => value.roundName),
        valueFormatter: (params) =>
          params.data.rounds?.map((value: any) => value.roundName),
        suppressCellClick: true, // Add this line to make the column non-clickable
      },
      {
        headerName: "Conversion Method",
        field: "method",
        filter: "agSetColumnFilter",
        cellRenderer: "method",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
      },
      {
        headerName: `Valuation Applied (${currencySymbol})`,
        field: "valuationApplied",
        filter: "agNumberColumnFilter",
        cellRenderer: "valuationApplied",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipValuationApplied",
      },
      {
        headerName: "New Conversion Ratio",
        field: "newConversionRatio",
        cellRenderer: "newConversionRatio",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: [],
        tooltipField: "tooltipNewConversionRatio",
      },
      {
        headerName: `Amount Invested (${currencySymbol})`,
        field: "amountInvested",
        filter: "agNumberColumnFilter",
        cellRenderer: "amountInvested",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipAmountInvested",
      },
      {
        headerName: "Holding (%)",
        field: "holdingPercentage",
        filter: "agNumberColumnFilter",
        cellRenderer: "holdingPercentage",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipHoldingPercentage",
      },
      {
        headerName: "Conversion Ratio",
        field: "conversionRatio",
        cellRenderer: "conversionRatio",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: [],
        tooltipField: "tooltipConversionRatio",
        hide: true,
      },
      {
        headerName: "",
        field: "actions",
        pinned: "right",
        hide: false,
        width: 80,
        maxWidth: 80,
        filter: false,
        menuTabs: [],
        colId: "action-column",
        suppressNavigable: true,
        suppressColumnsToolPanel: true,
        cellStyle: { "padding-top": "10px", "line-height": "20px" },
        resizable: false,
        sortable: false,
        cellRendererParams: ({ value }: { value: any }) => value?.props,
        cellRenderer: CTADropdown,
      },
    ],
    []
  );

  const gridOptions = {
    suppressRowVirtualisation: true, // Add this line to disable row virtualization
    paginationAutoPageSize: false, // Optional: Set to false if using custom pagination
    suppressScrollOnNewData: true, // Optional: Set to true to prevent scrolling to the top on
  };

  const currencyType = getCurrencyType();
  const [index, setIndex] = useState(-1);
  const rowData = useMemo(
    () =>
      convertibleDetails?.map((template, index) => ({
        securityClass: template.securityName,
        tooltipSecurityClass: template.securityName,
        rounds: template.roundIds,
        method: template.method,
        valuationApplied: template.valuationApplied,
        tooltipValuationApplied:
          template.valuationApplied?.toLocaleString(currencyType),
        conversionRatio: template.conversionRatio,
        newConversionRatio: Number.isNaN(
          Number(template.newConversionRatio.split(":")[1])
        )
          ? ""
          : template.newConversionRatio,
        tooltipConversionRatio: Number.isNaN(
          Number(template.newConversionRatio.split(":")[1])
        )
          ? ""
          : template.newConversionRatio,
        tooltipNewConversionRatio: Number.isNaN(
          Number(template.newConversionRatio.split(":")[1])
        )
          ? ""
          : template.newConversionRatio,
        amountInvested: template.amountInvested,
        tooltipAmountInvested:
          template.amountInvested?.toLocaleString(currencyType),
        holdingPercentage: (Math.abs(template.holdingPercentage) * 100).toFixed(
          2
        ),
        isConverted: template.isConverted,
        tooltipHoldingPercentage: Math.abs(template.holdingPercentage) * 100,
        currencySymbol,
        currencyType,

        actions: (
          <CTADropdown
            actions={[
              {
                name: "Settle Convertible",
                disabled: !template.method,
              },
              {
                name: "Reset",
                disabled: false,
              },
            ]}
            onAction={(action) => {
              setIndex(index);
              handleModifyAction(action.name, template, index);
            }}
          />
        ),
      })),
    [convertibleDetails]
  );

  function setTableHeight() {
    if (displayedRowCount === 1) {
      return (displayedRowCount + 2.75) * 60;
    } else if (displayedRowCount === 2) {
      return (displayedRowCount + 2.68) * 60;
    } else if (displayedRowCount === 3) {
      return (displayedRowCount + 3) * 60;
    } else if (displayedRowCount === 4) {
      return (displayedRowCount + 2.55) * 60;
    } else if (displayedRowCount === 5) {
      return (displayedRowCount + 2.48) * 60;
    } else if (displayedRowCount === 6) {
      return (displayedRowCount + 2.42) * 60;
    } else if (displayedRowCount === 7) {
      return (displayedRowCount + 2.35) * 60;
    } else if (displayedRowCount === 8) {
      return (displayedRowCount + 2.28) * 60;
    } else if (displayedRowCount === 9) {
      return (displayedRowCount + 2.22) * 60;
    } else {
      return 10 * 60;
    }
  }

  const [isColumnOpen, setIsColumnOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const openToolPanel = (key: any) => {
    if (key === "columns") {
      if (gridApi) {
        if (!isColumnOpen) gridApi?.current?.api?.openToolPanel(key);
        else gridApi?.current?.api?.closeToolPanel();
        setIsColumnOpen((state) => !state);
        setIsFilterOpen(false);
      }
    } else if (key === "filters") {
      if (gridApi) {
        if (!isFilterOpen) gridApi?.current?.api?.openToolPanel(key);
        else gridApi?.current?.api?.closeToolPanel();
        setIsFilterOpen((state) => !state);
        setIsColumnOpen(false);
      }
    }
  };

  const [filteredRowData, setFilteredRowData] = useState<any>([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const onAgGridFilterChanged = (grid: any) => {
    const filtersApplied = grid.api.isAnyFilterPresent();
    setIsFilterApplied(filtersApplied);

    const filteredData = grid.api
      .getModel()
      .rowsToDisplay.map((node: any) => node.data);
    setFilteredRowData(filteredData);
    uploadFilterAndColumn();
  };
  return (
    <div className="bg-white border rounded-md shadow-box border-borderColor">
      <HStack className="flex-col items-end justify-end py-2 lg:flex-row lg:justify-end lg:items-end">
        <Dialog open={dialog.open} maxWidth="md">
          {dialog.name === "Settle Convertible" ? (
            <OutstandingConvertiblePopup
              convertible={data.convertibles?.convertibles[index]!}
              onPrimaryAction={() => {
                setDialog({
                  open: true,
                  name: "Second Popup",
                  data: dialog.data,
                });
              }}
              onSecondaryAction={() => {}}
              onClose={() =>
                setDialog({
                  open: false,
                  name: "Settle Convertible",
                })
              }
            />
          ) : dialog.name === "Second Popup" ? (
            <OutstandingConvertibleThirdPopup
              data={data.convertibles?.convertibles[index]!}
              onPrimaryAction={() => {
                setDialog({
                  open: false,
                  name: "Second Popup",
                  data: dialog.data,
                });
              }}
              onSecondaryAction={() => {
                setDialog({
                  open: true,
                  name: "Settle Convertible",
                  data: dialog.data,
                });
              }}
              onClose={() =>
                setDialog({
                  open: false,
                  name: "Second Popup",
                })
              }
              roundModel={roundModel}
              sendMessage={sendMessage}
            />
          ) : (
            <></>
          )}
        </Dialog>
        <HStack className="items-center justify-end w-full gap-4 px-8 py-2 lg:w-auto lg:justify-end">
          <SearchInput
            placeholder={`Search`}
            onChange={(e: any) => {
              gridApi.current.api.setQuickFilter(e.target.value);
            }}
          />
          <HStack className="gap-4">
            <IconCTAButton
              value={"Columns"}
              onClick={() => openToolPanel("columns")}
              iconName={"fluent:column-triple-edit-20-regular"}
              className={`px-4 font-medium items-center flex flex-row ${
                isColumnOpen ? "text-orange-501" : "text-gray-400"
              }`}
            />
            <IconCTAButton
              value={"Filters"}
              onClick={() => openToolPanel("filters")}
              iconName={"material-symbols:filter-alt"}
              className={`px-4 font-medium items-center flex flex-row ${
                isFilterOpen ? "text-orange-501" : "text-gray-400"
              }`}
            />
          </HStack>
        </HStack>
      </HStack>
      <HStack className="justify-between w-full">
        <Box
          style={{
            height: setTableHeight(),
          }}
          className="w-full h-full max-h-full overflow-x-auto ag-theme-material "
        >
          <AgGridReact
            sideBar={agConfigDashboardSideBar}
            onGridReady={(params) => {
              // Store the grid API referen
              gridApi.current = params;
            }}
            components={componentsRegistery}
            alwaysShowHorizontalScroll={false}
            alwaysMultiSort
            animateRows={true}
            enableCharts={true}
            enableRangeSelection={true}
            defaultColDef={defaultColDef}
            onFilterChanged={onAgGridFilterChanged}
            onColumnEverythingChanged={uploadFilterAndColumn}
            rowData={rowData}
            columnDefs={columnDefs}
            pagination={false}
            suppressRowTransform={true}
            suppressCopyRowsToClipboard={true}
            suppressCopySingleCellRanges={true}
            suppressCellFocus={true}
            suppressMenuHide={false}
            onColumnResized={uploadFilterAndColumn}
            tooltipShowDelay={1000}
            tooltipInteraction={true}
            rowClass={"border-t border-dashed "}
            overlayNoRowsTemplate={
              '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow; margin-top: 50px;">No Rows To Show</span>'
            }
            gridOptions={gridOptions}
            getRowStyle={(params) => {
              if (params.rowIndex % 2 === 0) {
                return { background: "#f8f8f8" };
              } else {
                return { background: "#ffffff" };
              }
            }}
          ></AgGridReact>
        </Box>
      </HStack>
    </div>
  );
}

export default AGGridConvertibleTable;
