import { useMutation, useQuery } from "react-query";
import {
  getLiquidationPreferenceDetails,
  getSecurityConversion,
  getSecurityOverviewDetails,
  getSecurityRedemption,
  getSecuritySummary,
  getUpdatedConversionDetails,
  submitNewSecurity,
  updateNewSecurity,
  updateSecurityConversionDetails,
  updateSecurityRedemptionDetails,
  updateTransactionConversionDetails,
} from "../api/securities";
import { queryClient } from "./client";
import {
  randomLiquidationPreferenceOverview,
  randomSecurityOverview,
} from "./randomPlaceholderData";
import { handleEventForTracking } from "../amplitudeAnalytics";

export function useGetSecuritySummary(securityId: string) {
  return useQuery({
    queryKey: ["getSecuritySummary", securityId],
    queryFn: getSecuritySummary,
  });
}

export function useSecurityOverviewDetails() {
  return useQuery({
    queryKey: "getSecurityOverviewDetails",
    queryFn: getSecurityOverviewDetails,
    placeholderData: randomSecurityOverview(),
  });
}

export function useLiquidationPreference() {
  return useQuery({
    queryKey: "getLiquidationPreferenceDetails",
    queryFn: getLiquidationPreferenceDetails,
  });
}

export function useSubmitSecurity() {
  return useMutation({
    mutationKey: "submitSecurity",
    mutationFn: submitNewSecurity,
    onSuccess: () => {
      handleEventForTracking({
        eventName: "submitSecurity",
        success: true,
        eventType: "API",
      });
      queryClient.invalidateQueries("getSecurityOverviewDetails");
      queryClient.refetchQueries("getSecurityOverviewDetails");
    },
    onError: () => {
      handleEventForTracking({
        eventName: "submitSecurity",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function useUpdateSecurity() {
  return useMutation({
    mutationKey: "updateSecurity",
    mutationFn: updateNewSecurity,
    onSuccess: () => {
      handleEventForTracking({
        eventName: "updateSecurity",
        success: true,
        eventType: "API",
      });
      queryClient.invalidateQueries("getSecurityOverviewDetails");
      queryClient.refetchQueries("getSecurityOverviewDetails");
      queryClient.invalidateQueries("getSecuritySummary");
    },
    onError: () => {
      handleEventForTracking({
        eventName: "updateSecurity",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function useGetSecurityConversion(securityClassId: string) {
  return useQuery({
    queryKey: ["getSecurityConversion", securityClassId],
    queryFn: getSecurityConversion,
  });
}

export function useGetSecurityRedemption(securityClassId: string) {
  return useQuery({
    queryKey: ["getSecurityRedemption", securityClassId],
    queryFn: getSecurityRedemption,
  });
}
export function useUpdateSecurityConversionDetails() {
  return useMutation({
    mutationKey: "updateSecurityConversionDetails",
    mutationFn: updateSecurityConversionDetails,
    onSuccess: () => {
      handleEventForTracking({
        eventName: "updateSecurityConversionDetails",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "updateSecurityConversionDetails",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function useTransactionUpdateConversionDetails() {
  return useMutation({
    mutationKey: "useTransactionUpdateConversionDetails",
    mutationFn: updateTransactionConversionDetails,
    onSuccess: () => {
      handleEventForTracking({
        eventName: "useTransactionUpdateConversionDetails",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "useTransactionUpdateConversionDetails",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function useUpdateSecurityRedemptionDetails() {
  return useMutation({
    mutationKey: "updateSecurityRedemptionDetails",
    mutationFn: updateSecurityRedemptionDetails,
    onSuccess: () => {
      handleEventForTracking({
        eventName: "updateSecurityRedemptionDetails",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "updateSecurityRedemptionDetails",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function useGetUpdatedConversionDetails() {
  return useMutation({
    mutationKey: "getUpdatedConversionDetails",
    mutationFn: getUpdatedConversionDetails,
    onSuccess: () => {
      handleEventForTracking({
        eventName: "getUpdatedConversionDetails",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "getUpdatedConversionDetails",
        success: false,
        eventType: "API",
      });
    },
  });
}
