import { useEffect, useMemo, useState } from "react";
import { Dialog } from "@mui/material";
import _ from "lodash";
import { useNavigate } from "react-router";
import { Box, VStack } from "../../components/utils";
import { globalFilter, sort } from "../../utils/arrays";
import BasicMenu from "../../components/shared/Menu";
import Pagination from "../../components/shared/Pagination";
import { _trimAll, limitString } from "../../utils/string";
import {
  THead,
  TableHeader,
  tableEntryStyle,
  tableFirstColStyle,
} from "../../components/TableComponent";
import { useAuthStore } from "../../store";
import { Action, CTADropdown } from "../../components/shared/Dropdown";
import DeleteModal from "./DeleteModal";
import AddNewSecondary from "./AddNewSecondary";
import { RoundCreationModel, SecondaryTransaction } from "./RoundCreationModel";
import { EmptyTable } from "../quickRound/EmptyTableComponents";
import Tooltip from "../../components/shared/Tooltip";
import { useRMCreationStore } from "../../store/roundCreationStore";

export default function NewSecondaryTable({
  onNext,
}: {
  secondary: SecondaryTransaction[];
  onNext: (data: RoundCreationModel) => void;
}) {
  const roundCreationStore = useRMCreationStore();
  let _captableData: SecondaryTransaction[] =
    roundCreationStore.roundCreation.tab6?.transaction || [];
  const [globalFilterText, setGlobalFilterText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [sortField, setSortField] = useState<{
    field: keyof SecondaryTransaction | "" | undefined;
    ascending: boolean;
  }>({ field: "", ascending: false });

  const [dialog, setDialog] = useState<{
    open: boolean;
    data?: SecondaryTransaction;
    mode?: "Edit" | "Delete" | "Add";
  }>({
    open: false,
  });

  _captableData = useMemo(() => {
    try {
      if (!_captableData) return [];
      const filterResult = globalFilter(_captableData, globalFilterText);

      const sortResult = sort(
        filterResult,
        sortField?.field,
        sortField?.ascending
      );
      return sortResult;
    } catch (e) {
      return _captableData;
    }
  }, [_captableData, globalFilterText, sortField]);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return _captableData.slice(firstPageIndex, lastPageIndex);
  }, [_captableData, currentPage, pageSize]);

  useEffect(() => {
    setCurrentPage(1);
  }, [globalFilterText]);

  function handleAction(action: Action, data: SecondaryTransaction) {
    if (action.name === "Edit" && !action.disabled) {
      setDialog({
        open: true,
        data,
        mode: "Edit",
      });
    } else if (action.name === "Delete" && !action.disabled) {
      setDialog({
        open: true,
        data,
        mode: "Delete",
      });
    }
  }
  return (
    <div className="bg-white rounded-lg">
      <Dialog open={dialog.open} maxWidth="lg">
        {dialog.mode === "Delete" && (
          <DeleteModal
            onPrimaryAction={() => {
              roundCreationStore.sendMessage({
                ...roundCreationStore.roundCreation,
                tab6: {
                  transaction:
                    roundCreationStore.roundCreation.tab6?.transaction.filter(
                      (ele) => ele !== dialog.data
                    ) || [],
                },
              });
              setDialog({ open: false });
            }}
            onSecondaryAction={() => setDialog({ open: false })}
            data={
              "Are you sure you want to delete the entry? Data will be permanently removed. This action cannot be undone."
            }
          />
        )}
        {dialog.mode === "Add" && (
          <AddNewSecondary
            onBack={() => setDialog({ open: false })}
            onNext={onNext}
            actionMode={"New"}
            data={dialog.data}
          />
        )}
      </Dialog>
      <div className="flex flex-col">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="md:rounded-lg">
              <table className="min-w-full bg-white">
                <thead>
                  <TableHeader>
                    <THead
                      fieldName="buyerName"
                      fieldDisplayName="Buyer Name"
                      isFirstFeild={true}
                      selectedFieldName={sortField?.field || ""}
                      isAscending={sortField?.ascending || false}
                      isColumnFilter={false}
                      onChangeSort={() => {
                        setSortField({
                          field: "buyerName",
                          ascending: !sortField?.ascending,
                        });
                      }}
                    />
                    <THead
                      fieldName="sellerName"
                      fieldDisplayName="Seller Name"
                      showCurrency={false}
                      selectedFieldName={sortField?.field || ""}
                      isAscending={sortField?.ascending || false}
                      isColumnFilter={false}
                      onChangeSort={() => {
                        setSortField({
                          field: "sellerName",
                          ascending: !sortField?.ascending,
                        });
                      }}
                    />
                    <THead
                      fieldName="securityName"
                      fieldDisplayName="Securtiy"
                      showCurrency={false}
                      selectedFieldName={sortField?.field || ""}
                      isAscending={sortField?.ascending || false}
                      isColumnFilter={false}
                      onChangeSort={() => {
                        setSortField({
                          field: "securityName",
                          ascending: !sortField?.ascending,
                        });
                      }}
                    />
                    <THead
                      fieldName="noOfShares"
                      fieldDisplayName="No.of Securities"
                      selectedFieldName={sortField?.field || ""}
                      isAscending={sortField?.ascending || false}
                      isColumnFilter={false}
                      onChangeSort={() => {
                        setSortField({
                          field: "noOfShares",
                          ascending: !sortField?.ascending,
                        });
                      }}
                    />
                    <THead
                      fieldName="amountInvested"
                      fieldDisplayName="Amount"
                      showCurrency={true}
                      selectedFieldName={sortField?.field || ""}
                      isAscending={sortField?.ascending || false}
                      isColumnFilter={false}
                      onChangeSort={() => {
                        setSortField({
                          field: "amountInvested",
                          ascending: !sortField?.ascending,
                        });
                      }}
                    />
                    <th></th>
                  </TableHeader>
                </thead>
                <tbody>
                  {currentTableData.map((captable: SecondaryTransaction) => (
                    <tr
                      key={`${
                        captable.buyerId +
                        captable.sellerId +
                        captable.instrumentId
                      }`}
                      className="border-t whitespace-nowrap border-dashed hover:bg-slate-50 text-[#030303] text-xs2 font-medium "
                    >
                      <td className="py-4 pr-3 text-sm whitespace-nowrap ">
                        <VStack className="flex items-start">
                          {captable.buyerName && (
                            <div className={tableFirstColStyle}>
                              {captable.buyerName.length > 24 ? (
                                <Tooltip text={captable.buyerName}>
                                  <p className={tableEntryStyle}>
                                    {limitString(captable.buyerName || "", 24)}
                                  </p>
                                </Tooltip>
                              ) : (
                                <p className={tableEntryStyle}>
                                  {captable.buyerName[0].toUpperCase() +
                                    captable.buyerName.slice(1)}
                                </p>
                              )}
                            </div>
                          )}
                        </VStack>
                      </td>
                      <td className="px-3 py-4 whitespace-nowrap">
                        <VStack className="items-center">
                          {captable.sellerName && (
                            <div className={tableEntryStyle}>
                              {captable.sellerName.length > 24 ? (
                                <Tooltip text={captable.sellerName}>
                                  <p className={tableEntryStyle}>
                                    {limitString(captable.sellerName || "", 24)}
                                  </p>
                                </Tooltip>
                              ) : (
                                <p className={tableEntryStyle}>
                                  {captable.sellerName[0].toUpperCase() +
                                    captable.sellerName.slice(1)}
                                </p>
                              )}
                            </div>
                          )}
                        </VStack>
                      </td>
                      <td className="px-3 py-4 whitespace-nowrap">
                        <VStack className="items-center">
                          {captable.securityName && (
                            <div className={tableEntryStyle}>
                              {captable.securityName.length > 24 ? (
                                <Tooltip text={captable.securityName}>
                                  <p className={tableEntryStyle}>
                                    {limitString(
                                      captable.securityName || "",
                                      24
                                    )}
                                  </p>
                                </Tooltip>
                              ) : (
                                <p className={tableEntryStyle}>
                                  {captable.securityName[0].toUpperCase() +
                                    captable.securityName.slice(1)}
                                </p>
                              )}
                            </div>
                          )}
                        </VStack>
                      </td>
                      <td className="px-3 py-4 whitespace-nowrap">
                        <VStack className="items-center">
                          <p className={tableEntryStyle}>
                            {captable.noOfShares}
                          </p>
                        </VStack>
                      </td>
                      <td className="px-3 py-4 whitespace-nowrap">
                        <VStack className="items-center">
                          <p className={tableEntryStyle}>
                            {captable.amountInvested}
                          </p>
                        </VStack>
                      </td>

                      <td className="px-2 py-4 align-top">
                        <CTADropdown
                          dropdownClassName="bottom-auto z-10 absolute"
                          actions={[
                            //Dirty Commit {
                            //   name: "Edit",
                            //   disabled: false,
                            // },
                            {
                              name: "Delete",
                              disabled: false,
                            },
                          ]}
                          onAction={(action) => handleAction(action, captable)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {currentTableData.length === 0 && (
                <EmptyTable
                  header="No Secondary Transaction available"
                  subHeader="start by adding new secondary transaction"
                />
              )}
            </div>
          </div>
        </div>
        <Box className="flex justify-between my-4 ">
          {_captableData.length >= 5 && (
            <BasicMenu
              defaultValue={pageSize}
              options={[5, 10, 20, 50, 100]}
              onOptionChange={(value) => {
                setCurrentPage(1);
                setPageSize(value);
              }}
            />
          )}
          <Pagination
            currentPage={currentPage}
            totalCount={_captableData?.length}
            pageSize={pageSize}
            onPageChange={(page: number) => setCurrentPage(page)}
          />
        </Box>
      </div>
    </div>
  );
}
