import { Icon } from "@iconify/react";
import { Box, Center, HStack, VStack } from "../../components/utils";
import { optionsPool } from "../../shared/chart-data";
import { SemiDoughnutGraph } from "../../shared/SemiDoughnutChart";
import { _sum } from "../../utils/arrays";

function ShareSummary({
  totalShare,
  issuedShare,
}: {
  totalShare: number;
  issuedShare: number;
}) {
  const data = [
    {
      esopPlanId: 1,
      planName: "Plan Name",
      totalShares: totalShare,
      issuedShares: issuedShare,
      optionsReserved: totalShare - issuedShare,
    },
  ];
  const header = "FDB Share Summary";
  const percent = (100 * data[0].issuedShares) / data[0].totalShares;
  const rows = [
    {
      title: data[0].issuedShares.toLocaleString(),
      subtitle: "Total FDB shares issued this round",
    },
    {
      title: data[0].totalShares.toLocaleString(),
      subtitle: "Total FDB Shares in Company",
    },
  ];
  const optionsData = {
    labels: ["Diluted", "Other Shares"],
    datasets: [
      {
        label: "# of Votes",
        data: [
          data[0].issuedShares,
          data[0].totalShares - data[0].issuedShares,
        ],
        backgroundColor: ["rgba(255,168,0,1)", "rgba(255,168,0,.15"],
        borderColor: ["rgba(255,168,0,1)", "rgba(255,168,0,.15"],
      },
    ],
  };
  return (
    <VStack
      className={`p-6 bg-white border border-borderColor shadow-box rounded-lg flex`}
    >
      <VStack className="mb-0">
        <p className="font-medium text-gray-600 text-lg pb-4">{header}</p>
      </VStack>
      <Box className="align-center">
        {percent && (
          <SemiDoughnutGraph
            options={optionsPool}
            data={optionsData}
            label="Diluted Shares %"
          />
        )}
      </Box>
      {rows.map((row) => (
        <HStack key={row.subtitle} className="justify-between p-2">
          <HStack>
            <Center
              className="w-6 h-6 p-1 items-center"
              style={{ backgroundColor: "#F3F6F9", borderRadius: "50%" }}
            >
              <Icon icon="mdi:octahedron" style={{ color: "gray" }} />
            </Center>
            <VStack className="pl-4">
              <p className="font-medium capitalize text-xs2 text-gray-dark">
                {row.title}
              </p>
              <p className="capitalize text-xs1 font-semi-medium text-gray-light">
                {row.subtitle}
              </p>
            </VStack>
          </HStack>
        </HStack>
      ))}
    </VStack>
  );
}
export default ShareSummary;
