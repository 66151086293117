import { useState } from "react";
import { HStack } from "../../components/utils";
import { IndividualTermsheet } from "../../types/TermsheetModel";
import { getCurrencyType } from "../../utils/currencyFormatter";
import { FormatNumberSpan } from "../../utils/currencyRoboto";

export function OtherTerms({ data }: { data: IndividualTermsheet }) {
  const otherTerms = data.termsheetData.termsheetData.otherTerms;
  const currency = getCurrencyType();

  const toggle: {
    name: string;
    values: { label: string; value: string }[];
  }[] = [];
  otherTerms?.forEach((item, index) => {
    const checkIndex = toggle.findIndex((obj) => obj.name === item.sectionName);
    if (checkIndex === -1)
      toggle.push({
        name: item.sectionName,
        values: item.values,
      });
  });
  const [selectedTerms, setSelectedTerms] = useState<
    { label: string; value: string }[]
  >(toggle.length > 0 ? toggle[0].values : []);
  const [dataToggle, setDataToggle] = useState<string>(
    toggle.length > 0 ? toggle[0].name : ""
  );

  return (
    <div className="w-3/4 pt-6">
      <div className="justify-start px-4">
        <HStack className="mt-2 mb-8 overflow-x-auto scrollbar-hide">
          {toggle.map((element, index) => (
            <div
              key={element.name}
              className={`font-medium text-base  ${
                dataToggle === element.name
                  ? "text-orange-600 border-b border-orange-600 font-semibold"
                  : "text-gray-600"
              } ${
                index === 0 ? "pr-6" : "px-6"
              } cursor-pointer whitespace-nowrap h-8`}
              onClick={() => {
                setDataToggle(element.name);
                setSelectedTerms(element.values);
              }}
            >
              {element.name}
            </div>
          )) || <></>}
        </HStack>
      </div>

      <div className="justify-start py-2 items-center bg-white rounded-md h-96 px-2 overflow-y-scroll scrollbar-hide">
        {selectedTerms?.map((item, index) => (
          <div
            key={index}
            className="flex flex-col justify-start px-4 py-2 bg-white rounded-md"
          >
            {item.value && (
              <HStack className="grid grid-cols-3 gap-4 items-center">
                <p className="text-base font-medium text-[#464E5F] mr-5">
                  {item.label} :
                </p>
                <p className=" text-base font-medium text-[#464E5F] mr-5 z-index-10">
                  {typeof item.value === "boolean" ? (
                    item.value ? (
                      "Yes"
                    ) : (
                      "No"
                    )
                  ) : Number.isInteger(item.value) ? (
                    <FormatNumberSpan
                      value={Math.abs(Number(item.value))}
                      format={currency}
                    />
                  ) : (
                    item.value
                  )}
                </p>
              </HStack>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
