import { useFormikContext } from "formik";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { HStack, VStack, Error } from "../../components/utils";
import { Input, Label } from "../../components/shared/InputField";
import { EditSecurityModel } from "../../types/SecurityModel";
import { SecurityWiseTextDetail } from "../../constants/SecurityConstantContent";
import SecurityDescriptionText from "./SecurityDescriptionText";
import { classNames } from "../../utils/string";

const AddAdjustmentDetails = ({
  keepOpen,
  viewOnly,
}: {
  keepOpen: boolean;
  viewOnly: boolean;
}) => {
  const formikk = useFormikContext<EditSecurityModel>();
  const { errors, touched, getFieldProps } = formikk;
  return (
    <Disclosure
      as="div"
      key="Adjustments Amounts"
      className=""
      defaultOpen={keepOpen}
    >
      {({ open }) => (
        <>
          <dt
            className={`text-lg border ${
              open ? "border-b-0 rounded-t-md" : "rounded-md"
            } p-4`}
          >
            <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-400">
              <span className="font-medium text-slate-900 text-base">
                Adjustments Amounts
              </span>
              <span className="ml-6 flex h-7 items-center">
                <ChevronDownIcon
                  className={classNames(
                    open ? "-rotate-180" : "rotate-0",
                    "h-6 w-6 transform"
                  )}
                  aria-hidden="true"
                />
              </span>
            </Disclosure.Button>
          </dt>
          <Disclosure.Panel as="dd" className="border p-4 rounded-b-md">
            <VStack className="gap-8">
              <SecurityDescriptionText
                description={SecurityWiseTextDetail.AdjustmentAmount}
              />
              <HStack className="gap-4 md:flex-row flex-col justify-between">
                <VStack className="flex-1 items-start">
                  <Label className="text-slate-900 text-sm font-medium whitespace-nowrap mr-4">
                    Adjustment Term
                  </Label>
                  <textarea
                    rows={2}
                    name="adjustmentTerm"
                    id="adjustmentTerm"
                    placeholder="Enter the Adjustment Terms"
                    className={`bg-slate-50 block w-full p-4 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ${
                      viewOnly ? "cursor-not-allowed" : ""
                    }`}
                    defaultValue=""
                    value={formikk.values.adjustmentTerm ?? ""}
                    onChange={(adjustmentTerm: any) => {
                      formikk.setFieldValue(
                        "adjustmentTerm",
                        adjustmentTerm.target.value
                      );
                    }}
                    disabled={viewOnly}
                  />

                  {touched?.adjustmentTerm && errors?.adjustmentTerm && (
                    <Error text={errors?.adjustmentTerm} />
                  )}
                </VStack>
              </HStack>
            </VStack>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default AddAdjustmentDetails;
