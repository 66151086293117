import { useState } from "react";
import Box from "@mui/material/Box";
import { Icon } from "@iconify/react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { format } from "date-fns";
import { HStack, VStack, Error, useGetCompanyName } from "../components/utils";
import { Input, Label } from "../components/shared/InputField";
import {
  exportCaptableSummaryReport,
  getAllotmentWindowReport,
  getAllTransactionBasedReport,
} from "../api/Report";
import { downloadExcel } from "../utils/DownloadFile";
import {
  ButtonSize,
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../pages/quickRound/CTAButtonComponents";
import { PopupHeader } from "../components/Headings";

type ExportSheetDetailsProps = {
  onPrimaryAction: () => void;
  onSecondaryAction: () => void;
  reportName: string;
  companyId?: string;
};

type ExportMultiDateSheetDetailsSubmit = {
  fromDate: string;
  toDate: string;
  tillDate: string;
};

const ExportMultidateSheetDetails = ({
  onPrimaryAction = () => {},
  onSecondaryAction = () => {},
  reportName,
  companyId,
}: ExportSheetDetailsProps) => {
  const companyName = useGetCompanyName();
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (values: ExportMultiDateSheetDetailsSubmit) => {
    setLoading(true);
    if (reportName === "All Transaction Summary Report") {
      await getAllTransactionBasedReport(values.fromDate, values.toDate).then(
        (res) => {
          const fileName = `Transaction Report_${companyName}`;
          downloadExcel(res, fileName);
        }
      );
    } else if (reportName === "Export Summary Report") {
      let fileName;
      await exportCaptableSummaryReport(values.tillDate).then((res) => {
        fileName = `Cap Table Summary Report_${companyName}`;
        downloadExcel(res, fileName);
      });
    } else if (reportName === "Allotment Window Report") {
      await getAllotmentWindowReport(
        companyId || "",
        values.fromDate,
        values.toDate
      ).then((res) => {
        const fileName = `Allotment Window Report_${companyName}`;
        downloadExcel(res, fileName);
      });
    }
    setLoading(false);
    onPrimaryAction();
  };

  const initialValues = {
    fromDate: "",
    toDate: "",
    tillDate: "",
  };

  const excelSheetDetailsValidation = Yup.object({
    fromDate:
      reportName === "Export Summary Report"
        ? Yup.string().label("From Date")
        : Yup.string().required().label("From Date"),
    toDate:
      reportName === "Export Summary Report"
        ? Yup.string().label("To Date")
        : Yup.string().required().label("To Date"),
    tillDate:
      reportName === "Export Summary Report"
        ? Yup.string().required().label("Till Date")
        : Yup.string().label("Till Date"),
  });

  return (
    <div>
      <PopupHeader
        text={reportName}
        subText={"Please select date till which the report has to be generated"}
        onClick={() => onSecondaryAction()}
      />
      <VStack className="w-full px-8 py-7 gap-9">
        <Formik
          initialValues={initialValues}
          key="exportSheetDetails"
          onSubmit={(values) => {
            handleSubmit(values);
          }}
          validationSchema={excelSheetDetailsValidation}
        >
          {(formik) => (
            <div>
              <Form key="exportSheetDetails">
                <VStack className="w-full gap-4">
                  {reportName === "Export Summary Report" ? (
                    <VStack>
                      <Label>Till Date</Label>
                      <Input
                        type="date"
                        placeholder="Enter Till Date"
                        className="w-full"
                        {...formik.getFieldProps("tillDate")}
                      />
                      {formik.touched.tillDate && formik.errors.tillDate && (
                        <Error text={formik.errors.tillDate} />
                      )}
                    </VStack>
                  ) : (
                    <HStack className=" text-xs justify-between md:flex-row flex-col gap-8">
                      <VStack>
                        <Label>From Date</Label>
                        <Input
                          type="date"
                          placeholder="Enter From Date"
                          className="w-64"
                          {...formik.getFieldProps("fromDate")}
                        />
                        {formik.touched.fromDate && formik.errors.fromDate && (
                          <Error text={formik.errors.fromDate} />
                        )}
                      </VStack>
                      <VStack>
                        <Label>To Date</Label>
                        <Input
                          type="date"
                          placeholder="Enter To Date"
                          className="w-64"
                          {...formik.getFieldProps("toDate")}
                        />
                        {formik.touched.toDate && formik.errors.toDate && (
                          <Error text={formik.errors.toDate} />
                        )}
                      </VStack>
                    </HStack>
                  )}
                </VStack>
                <HStack className="justify-between mt-10 h-8">
                  <SecondaryCTAButton
                    event-name={`Cancel Download ${reportName}`}
                    buttonSize={ButtonSize.SMALL}
                    onClick={() => onSecondaryAction()}
                  >
                    Cancel
                  </SecondaryCTAButton>
                  <PrimaryCTAButton
                    event-name={`Download ${reportName}`}
                    buttonSize={ButtonSize.SMALL}
                    type="submit"
                  >
                    {!loading && <span>Submit</span>}

                    {loading && (
                      <HStack>
                        <p
                          className="indicator-progress"
                          style={{ display: "block" }}
                        >
                          Please wait...
                        </p>
                        <Icon
                          className="animate-spin"
                          icon="lucide:loader-2"
                          width={18}
                        />
                      </HStack>
                    )}
                  </PrimaryCTAButton>
                </HStack>
              </Form>
            </div>
          )}
        </Formik>
      </VStack>
    </div>
  );
};

export default ExportMultidateSheetDetails;
