import { useQuery } from "react-query";
import { useNavigate } from "react-router";
import { useAuthStore } from "../store/useAuthStore";
import {
  getUserContext,
  getUserCompanies,
  getUserRoleGrants,
} from "../api/auth";
import { checkAccessToEsopCompany, getCompanyExists } from "../api/Esop";

export function useGetUserRoleGrants() {
  const { setAuth } = useAuthStore();
  return useQuery({
    queryKey: "userRoleGrants",
    queryFn: getUserRoleGrants,
    onSuccess: (data) => {
      const role = data[0].role || "";
      setAuth({ role });
    },
    staleTime: 60 * 1000,
  });
}

export function useGetAllUserCompanies() {
  return useQuery({
    queryKey: "userCompanies",
    queryFn: getUserCompanies,
    staleTime: 60 * 1000,
  });
}

export function useGetUserContext() {
  const { setAuth } = useAuthStore();
  const navigate = useNavigate();
  return useQuery({
    queryKey: "userContext",
    queryFn: getUserContext,
    staleTime: 60 * 1000,
    async onSuccess(data) {
      if (data.companyId === "") {
        navigate("/landing/redirect");
      } else {
        let canAccessEsop = false;
        if (data?.isNewEsop && data?.newEsopCompanyId) {
          const companyExists = await checkAccessToEsopCompany(
            data?.newEsopCompanyId?.toString()
          );
          canAccessEsop =
            companyExists?.toLowerCase() === "esop company exists";
        } else if (data?.legacyCompanyId) {
          const companyExists = await getCompanyExists(
            data.legacyCompanyId.toString()
          );
          canAccessEsop = companyExists === "Esop Company Exists";
        }
        localStorage.setItem("companyId", data.companyId || "");
        setAuth({ user: data, companyId: data.companyId, canAccessEsop });
        if (window.location.pathname === "/landing/redirect") {
          navigate("/landing/dashboard");
        }
      }
    },
  });
}
