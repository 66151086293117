import { Box } from "@mui/material";
import _ from "lodash";
import { HStack, VStack } from "../../components/utils";
import { PreviewQuickRoundResponseModel } from "../../types/previewQuickRound";
import { formatCurrency, getCurrencyType } from "../../utils/currencyFormatter";
import { formatDisplayDate } from "../../utils/date";
import { getSecurityColor } from "../../utils/historicUtilities";
import { InstrumentClass } from "../../types/DashboardModel";

export default function QuickBuybackPreviewDetails({
  eventDetails,
  instrumentClasses,
}: {
  eventDetails: PreviewQuickRoundResponseModel | undefined;
  instrumentClasses: InstrumentClass[];
}) {
  const currency = getCurrencyType();
  const buybackParticipants =
    eventDetails?.transactions?.quickBuybackParticipants || [];

  const quickBuybackInstruments = buybackParticipants.map(
    (transaction) => transaction.instrumentClassId
  );
  const instruments =
    instrumentClasses.filter((instrumentClass) =>
      quickBuybackInstruments?.includes(instrumentClass.id)
    ) || [];

  const buybackShares = _.sumBy(
    buybackParticipants,
    (participant) => participant.numberOfShares
  );
  const amountBoughtBack = _.sumBy(
    buybackParticipants,
    (participant) => participant.amount
  );

  return (
    <Box className="px-7 py-4 pb-10 border border-borderColor mx-4 shadow-box bg-white rounded text-[#464E5F]">
      <VStack>
        <p className="text-lg font-semibold">{eventDetails?.eventInfo?.name}</p>
        <HStack>
          {instruments.map((instrument) => (
            <span
              key={instrument.id}
              className={`inline-flex px-2 ${getSecurityColor(
                instrument.subType
              )} text-xs font-semibold leading-5`}
            >
              {instrument.subType}
            </span>
          ))}
        </HStack>
      </VStack>
      <HStack className="w-full items-center  justify-start gap-8 md:flex-row flex-col text-[#464E5F] flex-wrap">
        <BuybackCardDetails
          label="Buy Back Date"
          value={formatDisplayDate(eventDetails?.eventDate || "")}
        />
        <BuybackCardDetails
          label="No.of Shares"
          value={buybackShares.toLocaleString(currency)}
        />
        <BuybackCardDetails
          label="Amount Bought Back"
          value={formatCurrency(amountBoughtBack, currency)}
        />
        <BuybackCardDetails
          label="Shareholders"
          value={buybackParticipants.length.toLocaleString(currency)}
        />
      </HStack>
    </Box>
  );
}

function BuybackCardDetails({
  value,
  label,
}: {
  value: string;
  label: string;
}) {
  return (
    <Box className="px-5 py-3 shadow-box border border-borderColor bg-white rounded mt-5">
      <VStack className="items-center">
        <p className="font-semibold whitespace-nowrap text-[#464E5F]">
          {value}
        </p>
        <p className="font-medium text-xxs1 whitespace-nowrap">{label}</p>
      </VStack>
    </Box>
  );
}
