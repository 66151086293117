import { MouseEvent, useRef } from "react";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
} from "chart.js";
import { Chart, getElementAtEvent } from "react-chartjs-2";
import { Box } from "../../components/utils";
import { SecurityDetails } from "../../types/DashboardModel";
import { formatToReadableNumber } from "../../utils/currencyRoboto";
import { limitString } from "../../utils/string";
import {
  getCurrencySymbol,
  getCurrencyType,
} from "../../utils/currencyFormatter";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

export default function DashboardSecurityBarChart({
  securityDetails,
}: {
  securityDetails: SecurityDetails[];
}) {
  const currency = getCurrencySymbol();
  const securityDetail = securityDetails.filter((security) => {
    const securityName = security.name.split(" ");
    return !securityName.includes("conversion");
  });

  const currencyType = getCurrencyType();

  const dashboardSecurityoptions = {
    responsive: true,
    maintainAspectRatio: false,
    categoryPercentage: 0.5,
    barPercentage: 0.9,
    borderRadius: 10,

    legend: {
      display: true,
    },
    plugins: {
      zoom: {
        pan: {
          enabled: true,
          mode: "x",
        },
        zoom: {
          pinch: {
            enabled: true, // Enable pinch zooming
          },
          wheel: {
            enabled: true, // Enable wheel zooming
            speed: 0.05,
          },
          mode: "x",
        },
      },
      legend: {
        display: false,
        position: "top" as const,
      },
      title: {
        display: false,
        text: "Chart.js Bar Chart",
      },
      tooltip: {
        usePointStyle: true,
        bodyFont: {
          weight: "bold",
          size: 14,
        },
        titleColor: "#464E5F",
        bodyColor: "#464E5F",
        displayColors: true,
        backgroundColor: "#FFFFFF",

        callbacks: {
          title: (context: any) => context[0].label.replace(",", " "),
          label: (tooltipItem: any) => {
            const chartData = tooltipItem.chart.config._config
              .data as unknown as any;
            const label: string[] = [];
            for (let i = 0; i < chartData.datasets.length; i++) {
              const total = chartData.datasets[i].data[tooltipItem.dataIndex];
              const displayNumber = formatToReadableNumber({
                value: total,
                format: currencyType,
              });
              label.push(
                `${chartData.datasets[i].label}: ${currency} ${displayNumber}`
              );
            }

            return label.map((item: string) => item);
          },
        },
      },
    },
    elements: {
      bar: {
        borderWidth: 10,
        padding: 10,
      },
    },
    scales: {
      x: {
        display: true,

        grid: {
          display: false,
          offset: true,
        },
        ticks: {
          font: { size: 12 },
          padding: 10,
        },
      },
      y: {
        display: true,

        beginAtZero: true,
        grid: {
          borderDash: [1, 3],
          color: "#e8e8e8",
        },
        ticks: {
          beginAtZero: false,
          min: 0,
          font: { size: 12 },
          callback(value: any, index: any, ticks: any) {
            const data = currency;
            return `${data} ${formatToReadableNumber({
              value,
              format: currencyType,
            })}`;
          },
        },
      },
    },
  };

  const data = {
    labels: securityDetail.map((element) => {
      const roundName = element?.name.split(" ");
      if (roundName.length >= 2) return roundName.slice(0, 2);
      return roundName || "";
    }),
    datasets: [
      // {
      //   barThickness: 16,
      //   type: "bar" as const,
      //   borderColor: "white",
      //   borderWidth: 2,
      //   label: "Authorised",
      //   data: securityDetail.map((security) => security.authorizedCapital),
      //   backgroundColor: "#FF8863",
      // },
      {
        barThickness: 16,
        type: "bar" as const,
        borderColor: "white",
        borderWidth: 2,
        label: "Paid up",
        data: securityDetail.map((security) => security.paidUpCapital),
        backgroundColor: "#869EDD",
      },
      {
        barThickness: 16,
        type: "bar" as const,
        borderColor: "white",
        borderWidth: 2,
        label: "Issued",
        data: securityDetail.map((security) => security.issuedCapital),
        backgroundColor: "#E8C05C",
      },
    ],
  };

  const chartRef = useRef<ChartJS>(null);

  const onClick = (event: MouseEvent<HTMLCanvasElement>) => {
    const { current: chart } = chartRef;
    if (chart) {
      const selectedChart = getElementAtEvent(chart, event);
      if (selectedChart[0]) {
        chart.ctx.save();
        chart.update();
      }
    }
  };

  return (
    <Box className="w-full h-80 align-center">
      <Chart
        type="bar"
        id="chart"
        ref={chartRef}
        onClick={onClick}
        options={dashboardSecurityoptions as unknown as any}
        data={data}
      />
    </Box>
  );
}
