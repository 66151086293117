import { Icon } from "@iconify/react";
import { Formik, Form, useFormik } from "formik";
import * as Yup from "yup";
import { Dialog } from "@mui/material";
import { useNavigate } from "react-router";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { Input, Label } from "../../components/shared/InputField";
import { Box, Error, HStack, VStack } from "../../components/utils";
import {
  useDeleteOnGoingTransaction,
  usePostOnGoingTransaction,
  useOngoingTransactionRound,
  usePostTransactionBonusDetail,
  useDiscardQuickRound2,
  useRoundExcelUpload,
} from "../../queries/transactionRound";
import {
  LoaderCTAButton,
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../quickRound/CTAButtonComponents";
import {
  TransactionBonusInputModel,
  QuickBonusTransactionModel,
} from "./RoundTransactionModel";
import { useAuthStore } from "../../store";
import { useQuickBonusTransactionStore } from "../../store/useQuickTransactionStore";
import AlertDialog from "../../components/shared/AlertDialog";

import FileInput from "../../components/shared/FileInput";
import convertToBase64 from "../../utils/convertToBase64";

import { QuickBonusTransactionDetails } from "../../types/quickRound";
import { getCurrencySymbolRoboto } from "../../utils/currencyRoboto";
import { SwitchButton } from "../../components/shared/SwitchButton";
import QuickBonusSecurityDetails from "./QuickBonusSecurityDetails";
import AddTransactionBonus from "./AddTransactionBonus";
import { useQuickBonusSecurityStore } from "../../store/QuickBonusSecuritiesStore";
import FileUploadedUI from "../shareHolderRights/FileUploadedUI";
import { downloadS3File } from "../../utils/DownloadFile";
import { useGetInstrumentAndShareHolder } from "../../queries/quickRound";
import { useShareholderInstrumentStore } from "../../store/shareholderInstrumentStore";
import { useInvGetInstrumentAndShareHolder } from "../../queries/investorPortal";
import { currencyTypes } from "../../constants/CompanyCurrencyType";
import { getCurrencyType } from "../../utils/currencyFormatter";
import { useNewShareholderInstrumentStore } from "../../store/newShareholderAndInstrumentStore";

const transactionSchema = Yup.object().shape({
  bonusName: Yup.string().required().label("Bonus Name"),

  date: Yup.string().required().label("Date"),
});

function QuickAddBonus() {
  const companyId = useAuthStore.getState().companyId || "";
  const currencyType = getCurrencyType();
  const {
    state: template,
    setState: setTemplate,
    reset: resetTemplate,
  } = useQuickBonusTransactionStore();
  const [minimum, setMinimum] = useState(false);
  const symbol = getCurrencySymbolRoboto();

  const { mutate: deleteOnGoingTransaction } =
    useDeleteOnGoingTransaction(companyId);
  const { mutate: postOnGoingTransaction } = usePostOnGoingTransaction();

  const pathName = window.location.href.split("/");
  const roundType = pathName[4];

  const [dialog, setDialog] = useState<{
    open: boolean;
    message?: string;
  }>({
    open: false,
  });
  const { data: ongoingEvent } = useOngoingTransactionRound();
  const navigate = useNavigate();
  const { mutate: discardQuickRound } = useDiscardQuickRound2();
  const displayDiscardQuickRoundPopUp = () => {
    setDialog({
      open: true,
      message: `Do you want to discard the current ongoing quick ${roundType} ?`,
    });
  };
  const { mutate: postTransactionBonus } =
    usePostTransactionBonusDetail(companyId);
  const { mutate: bonusExcelUpload } = useRoundExcelUpload(companyId);

  const fileRef2 = useRef() as React.MutableRefObject<HTMLInputElement>;

  const onClickAoaFunction = () => {
    fileRef2.current.click();
  };

  const initialVal = {
    name: "",
    size: 0,
    type: "",
  };

  const onClickAoaDelete = () => {
    fileRef2.current.value = "";
    setTemplate({ ...template, fileType: initialVal });
  };

  const handleDiscard = () => {
    setDialog({ open: false });
    discardQuickRound();
    resetTemplate();
    deleteOnGoingTransaction(companyId);
    navigate("/transactions/allTransactions");
    bonusTransactionStore.setBonus([]);
  };

  const initialValues: TransactionBonusInputModel = {
    bonusName: template?.bonusName || "",
    applicableForAllSecurities: template?.applicableForAllSecurities || true,
    date: template?.date || "",
  };

  const bonusTransactionStore = useNewShareholderInstrumentStore();

  const shareholderAndInstrumentstore = useShareholderInstrumentStore();

  const {
    refetch: getInstrumentAndShareHolder,
    data: instrumentShareholderInfoData,
  } = useGetInstrumentAndShareHolder(companyId);

  const currentPps = instrumentShareholderInfoData?.dashboardCardDetails;

  useEffect(() => {
    if (
      instrumentShareholderInfoData?.shareholders.length !==
      shareholderAndInstrumentstore.shareholders.length
    )
      shareholderAndInstrumentstore.setShareholders(
        instrumentShareholderInfoData?.shareholders || []
      );
    if (
      instrumentShareholderInfoData?.instrumentClasses.length !==
      shareholderAndInstrumentstore.instrumentClasses.length
    )
      shareholderAndInstrumentstore.setInstrumentClass(
        instrumentShareholderInfoData?.instrumentClasses || []
      );
  }, [instrumentShareholderInfoData]);

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: transactionSchema,
    initialValues,
    onSubmit: (values) => {
      const quickBonusTransactions: QuickBonusTransactionDetails[] =
        bonusTransactionStore.bonus.map((element) => ({
          instrumentClassId: element.instrumentClassId || "",
          bonusRatio: element.bonusRatio,
          newNumberOfShares: element.newShares,
          finalConversionInstrumentClassId:
            element.finalConversionInstrumentClassId || "",
          investorId: element.investorId || "",
        })) || [];

      const finalData: QuickBonusTransactionModel = {
        ...template,
        stepNo: 2,
        transactionType: "bonus",
        companyId,
        bonusName: values.bonusName || "",

        applicableForAllSecurities: values.applicableForAllSecurities || false,
        date: values.date || "",
        quickBonusTransactions,
      };
      const finalData2: QuickBonusTransactionModel = {
        companyId,
        bonusName: values.bonusName || "",
        date: values.date || "",
        quickBonusTransactions,
      };

      postTransactionBonus(finalData2, {
        onSuccess: (data: any) => {
          postOnGoingTransaction({
            ...finalData,
            eventId: data.id,
          });
          setTemplate({
            ...finalData,
            eventId: data.id,
          });
          toast("Bonus Transaction Detail Upload Successfully!", {
            type: "success",
            autoClose: 2000,
          });
        },
        onError: (err: any) => {
          toast(err.response.data.errorMessage, {
            type: "error",
            autoClose: 2000,
          });
        },
      });
    },
  });
  //   async function handleFileUpload(e: React.ChangeEvent<HTMLInputElement>) {
  //     if (!e?.target?.files?.[0]) return;
  //     const file = e.target.files[0];
  //     const base64 = await convertToBase64(file);
  //     roundExcelUpload(
  //       { file: base64 },
  //       {
  //         onSuccess: (data) => {
  //           setTemplate({
  //             stepNo: 2,
  //             transactionType: "secondary",
  //             eventId: data.id,
  //           });
  //           toast("Excel Uploaded Successfully", {
  //             type: "success",
  //             autoClose: 2000,
  //           });
  //         },
  //         onError: (err: any) => {
  //           toast(err.response.data.errorMessage, {
  //             type: "error",
  //             autoClose: 2000,
  //           });
  //         },
  //       }
  //     );
  //   }

  function updateAoa(e: React.ChangeEvent<HTMLInputElement>) {
    if (!e?.target?.files?.[0]) return;
    const file = e.target.files[0];
    const allowedFileExtensions = [".xls", ".xlsx"];
    if (
      allowedFileExtensions.some((extension) => file.name.endsWith(extension))
    ) {
      setAoaFile(file);
      setTemplate({
        ...template,
        fileType: {
          name: file.name,
          size: file.size,
          type: file.type,
        },
      });
    } else {
      toast(
        `Invalid file type, allowed file types are ${allowedFileExtensions.join(
          ", "
        )}`,
        { type: "error" }
      );
    }
  }

  const [loading, setLoading] = useState(false);
  const [aoaFile, setAoaFile] = useState<File>();

  return (
    <VStack>
      <Box className="">
        {/* <Form> */}
        <VStack className="bg-white rounded-lg p-6">
          <HStack className="justify-between pb-8">
            <VStack>
              <p className="text-xl font-semibold text-gray-dark">
                Bonus Details
              </p>
              <Box className="text-xs font-medium text-gray-light">
                Fill the bonus details to create a quick round
              </Box>
            </VStack>
          </HStack>

          <Dialog open={dialog.open} maxWidth="md">
            <AlertDialog
              onClose={() => setDialog({ open: false })}
              open={dialog.open}
              message={dialog.message}
              onPrimaryAction={handleDiscard}
              onSecondaryAction={() => setDialog({ open: false })}
            />
          </Dialog>
          <VStack
            className={`mb-4 ${template.isManual ? "gap-6" : " "} hidden`}
          >
            <Label className="text-sm font-medium">
              Select method of bonus creation
            </Label>
            <HStack className="flex items-center gap-4">
              <Icon
                onClick={() => {
                  setTemplate({
                    ...template,
                    isManual: false,
                  });
                }}
                icon={
                  !template.isManual
                    ? "ic:round-radio-button-checked"
                    : "ic:round-radio-button-unchecked"
                }
                className=" text-primary"
                width="18"
                height="18"
              />

              <Label className="text-sm font-medium">
                Upload Excel with bonus details
              </Label>
              <input
                ref={fileRef2}
                onChange={updateAoa}
                multiple={false}
                type="file"
                accept=".xls, .xlsx"
                hidden
              />
              {/* {!template.isManual && ( */}
              <VStack className={`${template.isManual ? "hidden" : ""}`}>
                <FileUploadedUI
                  uploadText={"Upload Excel Sheet"}
                  fileDetail={
                    template.fileType ?? {
                      name: "",
                      size: 0,
                      type: "",
                    }
                  }
                  callBack={onClickAoaFunction}
                  onClickDelete={onClickAoaDelete}
                  // callBack={() => {}}
                  // onClickDelete={() => {}}
                />
                <p className="text-xs pt-1">
                  download the template{" "}
                  <span
                    onClick={() =>
                      downloadS3File(
                        "https://equion-dev.s3.us-west-2.amazonaws.com/RoundExcelTemplate.xlsx"
                      )
                    }
                    className="underline text-blue-600 font-medium cursor-pointer"
                  >
                    here
                  </span>
                </p>
              </VStack>
              {/* )} */}

              {/* https://hissa-prod.s3.ap-south-1.amazonaws.com/Round+template.xlsx */}

              {/* {!excelUpload && (
                <FileInput
                  accept=".xls, .xlsx"
                  file={"upload round excel"}
                  onChange={(e) => {
                    handleFileUpload(e);
                  } */}
              {/* } */}
              {/* /> */}
              {/* )} */}
            </HStack>
            <HStack className=" py-2 items-center mb-4 justify-between gap-8">
              <HStack className=" py-2 items-center mb-4">
                <Icon
                  onClick={() =>
                    setTemplate({
                      ...template,
                      isManual: true,
                      fileName: "",
                      stepNo: 1,
                    })
                  }
                  icon={
                    template.isManual
                      ? "ic:round-radio-button-checked"
                      : "ic:round-radio-button-unchecked"
                  }
                  className=" text-primary"
                  width="18"
                  height="18"
                />
                <Label className="px-4 text-sm font-medium">
                  Enter Manually
                </Label>
              </HStack>
            </HStack>
          </VStack>

          {!template.isManual && (
            <VStack className="gap-8 -mt-4">
              <HStack className="justify-start gap-8 flex-wrap">
                <VStack className="">
                  <Label className="py-2 w-32 text-sm font-medium whitespace-nowrap">
                    Date
                  </Label>
                  <VStack>
                    <Input
                      type="date"
                      className="w-60"
                      {...formik.getFieldProps("date")}
                    />
                    {formik.touched?.date && formik.errors?.date && (
                      <Error text={formik.errors?.date} />
                    )}
                  </VStack>
                </VStack>
                <VStack className="">
                  <Label className="py-2 w-32 text-sm font-medium whitespace-nowrap">
                    Bonus Name
                  </Label>
                  <VStack>
                    <Input
                      type="text"
                      placeholder="Enter Bonus Name"
                      className="w-60"
                      {...formik.getFieldProps("bonusName")}
                    />
                    {formik.touched?.bonusName && formik.errors?.bonusName && (
                      <Error text={formik.errors?.bonusName} />
                    )}
                  </VStack>
                </VStack>

                {/*reusable code <VStack className="">
                  <Label className="py-2 w-32 text-sm font-medium whitespace-nowrap">
                    Targeted PPS
                  </Label>
                  <VStack>
                    <Input
                      type="text"
                      placeholder="Enter Targeted PPS"
                      className="w-60"
                      {...formik.getFieldProps("targetPPS")}
                    />
                    {formik.touched?.targetPPS && formik.errors?.targetPPS && (
                      <Error text={formik.errors?.targetPPS} />
                    )}
                  </VStack>
                </VStack>
                <VStack className="">
                  <Label className="py-2 lg:w-fit
                  w-32 lg:mr-2 text-sm font-medium whitespace-nowrap ">
                    Bonus Ratio
                  </Label>
                  <VStack>
                    <HStack>
                      <p className="pt-5 text-md pr-2">1</p>
                      <p className="pt-5 text-md pr-2">:</p>

                      <Input
                        type="text"
                        className=" w-56"
                        placeholder="Enter Bonus Ratio"
                        {...formik.getFieldProps("bonusRatio")}
                      />
                    </HStack>
                    {formik.touched?.bonusRatio &&
                      formik.errors?.bonusRatio && (
                        <Error text={formik.errors?.bonusRatio} />
                      )}
                  </VStack>
                </VStack> */}
              </HStack>

              <HStack>
                {/* reusable code <div className="flex
                flex-row-reverse gap-4  items-center  text-sm text-gray-600 font-medium ">
                  <SwitchButton
                    className="text-sm font-medium items-center m-1"
                    value={formik.values.applicableForAllSecurities}
                    label="Applicable To All Securities"
                    onClick={() => {
                      formik.setFieldValue(
                        "applicableForAllSecurities",
                        !formik.values.applicableForAllSecurities
                      );
                    }}
                  />
                </div> */}
                <HStack className="items-center text-sm font-medium whitespace-nowrap gap-4">
                  <p className="lg:w-fit w-32">Current PPS</p>
                  <p>
                    <span className="text-xs1">{symbol}</span>
                    {currentPps?.currentSharePrice.toLocaleString(currencyType)}
                  </p>
                </HStack>
              </HStack>
            </VStack>
          )}
        </VStack>
        {!template.isManual && (
          <div className="mt-8">
            <AddTransactionBonus />
          </div>
        )}
        <HStack className="justify-between gap-4 mt-8">
          <HStack>
            <SecondaryCTAButton
              type="reset"
              event-name="Discard Bonus"
              className="text-red-500 shadow-box"
              onClick={displayDiscardQuickRoundPopUp}
            >
              Discard
            </SecondaryCTAButton>
          </HStack>
          {loading && (
            <LoaderCTAButton
              onClick={() => {
                setLoading(true);
              }}
            />
          )}
          {!loading && (
            <PrimaryCTAButton
              event-name="Create Bonus"
              onClick={async () => {
                if (!template.isManual && aoaFile) {
                  const base64 = await convertToBase64(aoaFile);
                  setLoading(true);
                  bonusExcelUpload(
                    { file: base64 },
                    {
                      onSuccess: async (data) => {
                        await getInstrumentAndShareHolder().then((data) => {
                          shareholderAndInstrumentstore.setInstrumentClass(
                            data?.data?.instrumentClasses || []
                          );
                          shareholderAndInstrumentstore.setShareholders(
                            data?.data?.shareholders || []
                          );
                          shareholderAndInstrumentstore.setInstrumentClassSubTypes(
                            data?.data?.instrumentClassSubTypes || {}
                          );
                        });
                        setLoading(false);

                        setTemplate({
                          ...template,
                          stepNo: 2,
                          isManual: false,
                          fileType: template.fileType,
                          transactionType: "bonus",
                        });
                        toast("Excel Uploaded Successfully", {
                          type: "success",
                          autoClose: 2000,
                        });
                      },
                      onError: (err: any) => {
                        setLoading(false);
                        toast(err.response.data.errorMessage, {
                          type: "error",
                          autoClose: 2000,
                        });
                      },
                    }
                  );
                } else if (
                  bonusTransactionStore.bonus.length > 0 &&
                  formik.errors
                ) {
                  setLoading(true);
                  setMinimum(false);
                  formik.handleSubmit();
                } else {
                  setMinimum(true);
                }
                setLoading(false);
              }}
              className="h-8 min-w-max"
              type="submit"
            >
              Create Bonus
            </PrimaryCTAButton>
          )}
        </HStack>

        {template.isManual && minimum && (
          <p className="text-red-500 text-xs text-right font-medium ">
            * Required minimum 1 bonus transaction
          </p>
        )}
      </Box>
    </VStack>
  );
}

export default QuickAddBonus;
