import { tableEntryStyle } from "../components/TableComponent";
import { HStack } from "../components/utils";
import { formatToReadableNumber } from "../utils/currencyRoboto";
import { formatDisplayDate } from "../utils/date";
import editLogo from "../utils/assets/edit.svg";

export function PaidUpSharePriceRender(data: any) {
  return (
    <HStack className="whitespace-nowrap pt-2">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.data?.paidUpSharePrice,
          format: data.data.currencyType,
        })}
      </p>
    </HStack>
  );
}

export function ToBePaidRender(data: any) {
  return (
    <HStack className="whitespace-nowrap pt-2">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.data?.toBePaid,
          format: data.data.currencyType,
        })}
      </p>
    </HStack>
  );
}

export function ScheduledDateRender(data: any) {
  return (
    <HStack className="whitespace-nowrap pt-2">
      <p className={tableEntryStyle}>
        {formatDisplayDate(data.data.scheduledDate)}
      </p>
    </HStack>
  );
}

export function PaidUpDateRender(data: any) {
  return (
    <HStack className="whitespace-nowrap pt-2">
      <p className={tableEntryStyle}>
        {formatDisplayDate(data.data.paidUpDate)}
      </p>
    </HStack>
  );
}
