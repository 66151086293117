import { useEffect, useMemo, useState } from "react";
import { Dialog } from "@mui/material";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { v4 as uuidv4 } from "uuid";
import { useFormik } from "formik";
import { useLocation, useNavigate, useParams } from "react-router";
import {
  Error,
  HStack,
  useGetCompanyName,
  VStack,
} from "../../components/utils";
import BCHeader from "../../shared/BCHeader";
import { useAuthStore } from "../../store";
import { useOnlineStatus } from "../modeling/isOnline";
import NoInternetConncetion from "../modeling/NoInternetBanner";
import { useRoundModelStore } from "../../store/roundModelingStore";

import {
  ButtonSize,
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../quickRound/CTAButtonComponents";
import DeleteModal from "../modeling/DeleteModal";
import { queryClient } from "../../queries";

import ConvertibleModelAgGridTable from "./ConvertibleModelAgGridTable";
import { ConvertibleModel } from "../../types/Modeling";
import NextPriceRoundComponent from "./NextPriceRoundComponent";
import {
  useGetIndividualNonPricedRoundModel,
  useGetNonPricedRoundModelDetails,
  useListOfNonPricedRoundModel,
} from "../../queries/modeling";
import GenericTableHeader from "../../shared/TableHeader";

import {
  getCurrencySymbol,
  getCurrencyType,
} from "../../utils/currencyFormatter";
import { Input, Label } from "../../components/shared/InputField";
import { useNewConvertibleModelStore } from "./ConvertibleModelStore";
import { formatToReadableNumber } from "../../utils/currencyRoboto";
import Tooltip from "../../components/shared/Tooltip";
import { ExportImport } from "../Utility/GrantsTable";
import {
  exportModelSummaryReport,
  exportRoundSummaryReport,
  exportSecondarySummaryReport,
} from "../../api/Report";
import { downloadExcel } from "../../utils/DownloadFile";
import { Action } from "./ModelingDashboard";
import ShareModal from "../share/ShareModal";
import { SharedResource } from "../../constants/SharedResource";
import { useSharedNonPricedRoundModel } from "../../queries/sharedResouces";
import { useShareAuthStore } from "../../store/useShareAuthStore";
import { useDeleteNonPricedRoundModels } from "../../queries/transactionRound";

function NonPricedRoundModelTemplate() {
  const [dialog, setDialog] = useState<{
    open: boolean;
    mode?: string;
    data?: any;
  }>({
    open: false,
  });

  const symbol = getCurrencySymbol();
  const uuid = uuidv4();

  const convertibleModelStore = useNewConvertibleModelStore();
  const companyName = useGetCompanyName();

  const [percentage, setPercentage] = useState<number>(0);

  const currencyType = getCurrencyType();
  const { id } = useParams() as {
    id: string;
  };

  const { mutate: deleteOnGoingTransaction } = useDeleteNonPricedRoundModels();

  const pathName = window.location.href.split("/");
  const sharedPage = useMemo(() => pathName[3] === "share", [pathName]);
  const { shareId, shareCompanyId, shareResourceId } = useShareAuthStore();

  const {
    refetch: fetchModelingDetails,
    data: nonPricedModelDetail,
    isPlaceholderData,
  } = sharedPage
    ? useSharedNonPricedRoundModel(shareResourceId!)
    : id
    ? useGetIndividualNonPricedRoundModel(id)
    : useGetNonPricedRoundModelDetails();

  useEffect(() => {
    fetchModelingDetails();
  }, [id]);

  useEffect(() => {
    const updatedConvertibles = (
      nonPricedModelDetail?.draftData?.convertibles ?? []
    ).map((item: any) => {
      if (!item.uuid) {
        return { ...item, uuid: uuidv4() };
      }
      return item;
    });

    convertibleModelStore.setConvertibleModel(updatedConvertibles);
  }, [nonPricedModelDetail]);

  const roundModelStore = useRoundModelStore();
  const isOnline = useOnlineStatus();
  const isAuthenticated = useAuthStore().isAuthenticated;
  const [showShare, setShowShare] = useState(false);

  useEffect(() => {
    if (isAuthenticated && !roundModelStore.roundCreation.modelId) {
      roundModelStore.connect();
      roundModelStore.recieveMessage("message");
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (roundModelStore.roundCreation.errorMessage && isAuthenticated) {
      toast(roundModelStore.roundCreation.errorMessage, {
        type: "error",
        autoClose: 1000,
      });
    }
  }, []);

  const initialValues = {
    modelName: nonPricedModelDetail?.draftData?.modelName || "",
    preFDBShares: nonPricedModelDetail?.drafData?.preFDBShares || 0,
    preEsop: nonPricedModelDetail?.drafData?.preEsop || 0,
  };
  const validationSchema = Yup.object().shape({
    modelName: Yup.string().required().label("Model Name"),
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: false,
    validationSchema,
    onSubmit: (values) => {},
  });

  useEffect(() => {
    if (initialValues.modelName) {
      formik.setFieldValue("modelName", initialValues.modelName);
    }
  }, [initialValues.modelName]);

  useEffect(() => {
    const preEsop = nonPricedModelDetail?.draftData?.preEsop;
    const preFDBShares = nonPricedModelDetail?.draftData?.preFdbShares || 0;
    const percentage = (preEsop / preFDBShares) * 100;
    setPercentage(percentage);
  }, [
    nonPricedModelDetail?.draftData?.preEsop,
    nonPricedModelDetail?.draftData?.preFdbShares,
  ]);

  const navigate = useNavigate();

  const [convertiblesDataChanged, setConvertiblesDataChanged] = useState(false);

  async function handleAction(action: Action) {
    if (action.disabled) return;

    if (action.name === "Model Summary Report") {
      exportModelSummaryReport(id).then((res) => {
        const fileName = `Model Summary Report_${companyName}`;
        downloadExcel(res, fileName);
      });
    }
  }

  function handleDisCard() {
    deleteOnGoingTransaction(id);
    convertibleModelStore.reset();
    setDialog({ open: false });
    queryClient.invalidateQueries("deleteNonPricedRoundModel");
    navigate("/modeling/overview");
  }

  return (
    <div className="min-h-full">
      {!isOnline && <NoInternetConncetion />}
      <Dialog open={dialog.open} maxWidth="lg">
        {dialog.mode === "Delete" && (
          <DeleteModal
            onPrimaryAction={handleDisCard}
            onSecondaryAction={() => setDialog({ open: false })}
            data={
              "Are you sure you want to delete the model? Data will be permanently removed. This action cannot be undone."
            }
          />
        )}
      </Dialog>
      <HStack className="justify-between ">
        <div>
          <BCHeader
            className="items-baseline"
            bcTitle="Round Modeling"
            bcSubTitle="Non Priced Model"
          />
        </div>
        <HStack className="px-4">
          <div>
            {id && !sharedPage && (
              <ExportImport
                actions={[
                  {
                    name: "Model Summary Report",
                  },
                ]}
                onAction={(action) => handleAction(action)}
              />
            )}
          </div>
          {id && !sharedPage && (
            <div className="px-2">
              <SecondaryCTAButton
                buttonSize={ButtonSize.SMALL}
                onClick={() => {
                  setShowShare(true);
                }}
              >
                Share
              </SecondaryCTAButton>
            </div>
          )}
          {id && !sharedPage && (
            <div>
              <SecondaryCTAButton
                buttonSize={ButtonSize.SMALL}
                className="h-8"
                event-name="Discard Model Button"
                onClick={() => {
                  setDialog({
                    open: true,
                    mode: "Delete",
                    data: convertibleModelStore.convertibleModel,
                  });
                  setTimeout(() => {
                    queryClient.refetchQueries("getListOfNonPricedRoundModel");
                  }, 2000);
                }}
              >
                Discard
              </SecondaryCTAButton>
            </div>
          )}
        </HStack>
      </HStack>

      <VStack className={`mt-4 gap-8 ${isPlaceholderData ? "loading" : ""}`}>
        <VStack>
          <div>
            <>
              <div className="justify-between w-full px-4 py-4 bg-white shadow-box">
                <HStack className="justify-between"></HStack>
                {showShare && (
                  <ShareModal
                    resourceId={id ?? ""}
                    resourceType={SharedResource.NON_PRICED_ROUND_MODEL}
                    close={() => setShowShare(false)}
                  />
                )}

                <HStack className="grid w-full gap-8 pl-2 mx-auto md:grid-cols-3 sm:grid-cols-1">
                  <VStack className="flex-1">
                    <Label className="w-48 text-sm font-medium whitespace-nowrap">
                      Model Name
                    </Label>
                    <VStack>
                      <Input
                        type="text"
                        placeholder="Enter Model Name"
                        className="w-72"
                        {...formik.getFieldProps("modelName")}
                      />
                      {formik.touched?.modelName &&
                        formik.errors?.modelName && (
                          <Error text={formik.errors?.modelName as string} />
                        )}
                    </VStack>
                  </VStack>

                  <VStack className="flex-1 ">
                    <Label className="w-48 text-sm font-medium whitespace-nowrap">
                      Pre FDB Shares
                    </Label>
                    <Tooltip
                      text={nonPricedModelDetail?.draftData?.preFdbShares?.toLocaleString(
                        currencyType
                      )}
                    >
                      <p className="pt-4">
                        {formatToReadableNumber({
                          value: nonPricedModelDetail?.draftData?.preFdbShares,
                          format: currencyType,
                        })}
                      </p>
                    </Tooltip>
                  </VStack>

                  <VStack className="flex-1 ">
                    <Label className="w-48 text-sm font-medium whitespace-nowrap">
                      Pre ESOP (%)
                    </Label>
                    <p className="pt-4"> {percentage.toFixed(2)}</p>
                  </VStack>
                </HStack>
              </div>
            </>
          </div>
        </VStack>

        <HStack className="flex-col w-full gap-4 lg:flex-row">
          <div className="w-full">
            <ConvertibleModelAgGridTable
              convertibleModelTableData={convertibleModelStore.convertibleModel}
              setConvertiblesDataChanged={setConvertiblesDataChanged}
              sharedPage={sharedPage}
            />
          </div>
        </HStack>
        <VStack>
          <div>
            {nonPricedModelDetail && (
              <NextPriceRoundComponent
                nonPricedModel={nonPricedModelDetail}
                modelId={id || ""}
                modelName={formik.values.modelName}
                convertibleTableDetails={
                  convertibleModelStore.convertibleModel || []
                }
                convertiblesDataChanged={convertiblesDataChanged}
                setConvertiblesDataChanged={setConvertiblesDataChanged}
                sharedPage={sharedPage}
              />
            )}
          </div>
        </VStack>
      </VStack>
    </div>
  );
}

export default NonPricedRoundModelTemplate;
