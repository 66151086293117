import { useMutation, useQuery } from "react-query";
import {
  changePassword,
  getUserProfile,
  saveUserProfile,
} from "../api/userProfile";
import { queryClient } from "./client";
import { handleEventForTracking } from "../amplitudeAnalytics";

export function useGetUserProfile() {
  return useQuery({
    queryKey: "getUserProfile",
    queryFn: getUserProfile,
  });
}

export function useSaveUserProfile() {
  return useMutation({
    mutationKey: "saveUserProfile",
    mutationFn: saveUserProfile,
    onSuccess: () => {
      queryClient.invalidateQueries("getUserProfile");
      queryClient.refetchQueries("getUserProfile");
      handleEventForTracking({
        eventName: "saveUserProfile",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "saveUserProfile",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function useChangePassword() {
  return useMutation({
    mutationKey: "changePassword",
    mutationFn: changePassword,
    onSuccess: () => {
      handleEventForTracking({
        eventName: "changePassword",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "changePassword",
        success: false,
        eventType: "API",
      });
    },
  });
}
