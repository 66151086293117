import { Icon } from "@iconify/react";
import { useMemo, useState } from "react";
import { HStack, VStack } from "../components/utils";
import { ShareholderModel } from "../types/ShareHolderRightsModel";
import { limitString } from "../utils/string";
import Tooltip from "../components/shared/Tooltip";

type InvestorRightsModalProps = {
  name: string;
  shareholders: ShareholderModel[];
  onPrimaryAction: () => void;
};

function InvestorRightsModal({
  name,
  shareholders,
  onPrimaryAction = () => {},
}: InvestorRightsModalProps) {
  const [globalFilterText, setGlobalFilterText] = useState("");
  const [sortField, setSortField] = useState(false);

  const investorList: string[] =
    shareholders.find((shareholder) => shareholder.shareholderType === name)
      ?.shareholderName || [];
  const currentTableData = useMemo(() => {
    if (!investorList) return [];
    const filterResult = investorList.filter((investor) =>
      investor.toLowerCase().includes(globalFilterText.toLowerCase())
    );
    const sortResult = sortField ? filterResult.sort() : filterResult.reverse();
    return sortResult || [];
  }, [investorList, sortField, globalFilterText]);
  return (
    <div className="w-full bg-white rounded-md px-4 shadow-box h-96 overflow-auto">
      <HStack className="items-center h-8 min-h-min px-1 justify-between  mt-2 rounded bg-slate-light text-slate-dark">
        <HStack>
          <Icon icon="fe:search" width="24" className="mr-2 " />
          <input
            type="text"
            className="w-full font-medium border-0 outline-none text-xs2 bg-inherit"
            placeholder="Search Shareholders"
            value={globalFilterText}
            onChange={(e) => {
              setGlobalFilterText(e.target.value);
            }}
          ></input>
        </HStack>
        <div
          className="h-6 mx-auto bg-orange-501 rounded-full items-center pt-0.5"
          onClick={() => {
            onPrimaryAction();
          }}
        >
          <span className="text-white p-2">x</span>
        </div>
      </HStack>
      <VStack className="w-full  px-2 py-4 ">
        <div className="-my-2 overflow-x-auto sm:-mx-6">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="overflow-hidden md:rounded-lg bg-white">
              <div>
                <table className="min-w-full bg-white p-5">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5  pr-3 text-left text-sm font-semibold text-[#B5B5C3] sm:pl-6 hover:cursor-pointer mx-auto"
                      >
                        <HStack className="items-center justify-start">
                          Shareholders Name
                          <VStack
                            className="ml-2"
                            onClick={() => setSortField(!sortField)}
                          >
                            <Icon
                              icon="bi:triangle-fill"
                              className={`${
                                sortField ? "text-gray-900" : "text-gray-300"
                              } rounded`}
                              height={8}
                              width={16}
                            />
                            <Icon
                              icon="bi:triangle-fill"
                              className={`${
                                !sortField ? "text-gray-900" : "text-gray-300"
                              } rounded`}
                              height={8}
                              rotate={90}
                              width={16}
                            />
                          </VStack>
                        </HStack>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="">
                    {currentTableData.map((investorName, index) => (
                      <tr
                        key={`${investorName}}${index}`}
                        className=" hover:bg-slate-50"
                      >
                        <td className="sm:pl-6 text-sm text-left border-t border-dashed cursor-default">
                          {investorName.length > 40 ? (
                            <Tooltip text={investorName}>
                              <span className="cursor-pointer">
                                {limitString(investorName, 40)}
                              </span>
                            </Tooltip>
                          ) : (
                            limitString(investorName, 40)
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </VStack>
    </div>
  );
}

export default InvestorRightsModal;
