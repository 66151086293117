import React from "react";
import { queryClient } from "../queries";

export function invalidateDashboardQueries() {
  queryClient.invalidateQueries("getInvInstrumentAndShareHolder");
  queryClient.refetchQueries("getInvInstrumentAndShareHolder");
  queryClient.invalidateQueries("getInstrumentAndShareHolder");
  queryClient.refetchQueries("getInstrumentAndShareHolder");
  queryClient.invalidateQueries("getInvEvents");
  queryClient.refetchQueries("getInvEvents");
  queryClient.invalidateQueries("getAllEvents");
  queryClient.refetchQueries("getAllEvents");
  queryClient.invalidateQueries("getSecurityOverviewDetails");
  queryClient.refetchQueries("getSecurityOverviewDetails");
  queryClient.invalidateQueries("getShareholderOverview");
  queryClient.refetchQueries("getShareholderOverview");
  queryClient.invalidateQueries("getAllTransactions");
  queryClient.refetchQueries("getAllTransactions");
  queryClient.invalidateQueries("getCompanyConfig");
  queryClient.refetchQueries("getCompanyConfig");
}
