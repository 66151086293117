import { useEffect } from "react";
import io from "socket.io-client";
import { toast } from "react-toastify";
import { Box, HStack, VStack } from "../../components/utils";
import Convertible from "./Convertible";
import ExistingInvestor from "./ExistingInvestor";
import NewInvestors from "./NewInvestors";
import SecurityAllotment from "./SecurityAllotment";
import RoundCreationSummary from "./RoundCreationSummary";
import RoundCreationStepper from "./RoundCreationStepper";
import RoundCreationComponent from "./RoundCreationComponent";
import SecondaryPage from "./SecondaryPage";
import AntiDilutionPage from "./AntiDilutionPage";
import RoundCreation from "./RoundCreation";
import { useOnlineStatus } from "./isOnline";
import NoInternetConncetion from "./NoInternetBanner";
import { useRMCreationStore } from "../../store/roundCreationStore";
import RoundCreationHeader from "./RoundCreationHeader";
import BCHeader from "../../shared/BCHeader";
import SecurityBuilder from "./SecurityBuilder";
import { useAuthStore } from "../../store";
import { PageHeading } from "../../components/Headings";

function RoundCreationTemplate() {
  const roundCreationState = useRMCreationStore();

  const isOnline = useOnlineStatus();
  const isAuthenticated = useAuthStore().isAuthenticated;

  useEffect(() => {
    if (
      roundCreationState.roundCreation.errors &&
      roundCreationState.roundCreation.errors.length > 0 &&
      isAuthenticated
    ) {
      for (const error of roundCreationState.roundCreation.errors) {
        toast(error, {
          type: "error",
          autoClose: 1000,
        });
      }
    }
  }, []);

  return (
    <div className="min-h-full">
      {!isOnline && <NoInternetConncetion />}
      <BCHeader className={`items-baseline`} bcTitle="Round Creation" />
      {!roundCreationState.roundCreation.id && <RoundCreation />}
      {roundCreationState.roundCreation.headers &&
        roundCreationState.roundCreation.currentTab !== 7 && (
          <RoundCreationHeader
            currentCompanyDetail={roundCreationState.roundCreation.headers}
          />
        )}
      {roundCreationState.roundCreation.id && (
        <HStack className="w-full gap-4 lg:flex-row flex-col">
          <RoundCreationStepper
            templateNumber={roundCreationState.roundCreation.currentTab}
          />
          <div className="w-full">
            {roundCreationState.roundCreation.currentTab === 1 ? (
              <RoundCreationComponent />
            ) : null}
            {roundCreationState.roundCreation.currentTab === 2 ? (
              <Convertible />
            ) : null}
            {roundCreationState.roundCreation.currentTab === 3 ? (
              <ExistingInvestor />
            ) : null}
            {roundCreationState.roundCreation.currentTab === 4 ? (
              <NewInvestors />
            ) : null}
            {roundCreationState.roundCreation.currentTab === 5 &&
            roundCreationState.roundCreation.screen !== "New_Security" ? (
              <SecurityAllotment />
            ) : null}
            {roundCreationState.roundCreation.currentTab === 5 &&
            roundCreationState.roundCreation.screen === "New_Security" ? (
              <SecurityBuilder />
            ) : null}
            {roundCreationState.roundCreation.currentTab === 6 ? (
              <SecondaryPage />
            ) : null}
            {roundCreationState.roundCreation.currentTab === 7 ? (
              <RoundCreationSummary />
            ) : null}
            {roundCreationState.roundCreation.currentTab === 8 ? (
              <AntiDilutionPage
                data={roundCreationState.roundCreation}
                onBack={() => {}}
              />
            ) : null}
          </div>
        </HStack>
      )}
    </div>
  );
}

export default RoundCreationTemplate;
