import axios, { AxiosError, AxiosRequestHeaders } from "axios";
import { useAuthStore } from "../store";
import {
  clearCredentials,
  redirectToHissa,
  revalidateAuth,
} from "../components/utils";
import { queryClient } from "../queries";

const newCapTableApi = axios.create({
  baseURL: process.env.REACT_APP_NEW_CAPTABLE_API || "http://localhost:4000",
});

newCapTableApi.defaults.headers.post["Content-Type"] = "application/json";

newCapTableApi.interceptors.request.use((config) => {
  const companyId = useAuthStore.getState().companyId || "";
  const accesstoken =
    localStorage.getItem("accesstoken") || useAuthStore.getState().accessToken;
  revalidateAuth();
  if (accesstoken && !config.url?.endsWith("/login")) {
    (config.headers as AxiosRequestHeaders).AccessToken = accesstoken;
    (config.headers as AxiosRequestHeaders).companyId = companyId;
  }
  if (config.url === "exit") {
    delete (config.headers as AxiosRequestHeaders).AccessToken;
  }
  return config;
});

newCapTableApi.interceptors.response.use(
  (res) => {
    if (res.status === 401) {
      redirectToHissa("", true);
      clearCredentials();
      queryClient.clear();
      useAuthStore.setState((store) => ({ ...store, isAuthenticated: false }));
      return Promise.reject(res);
    }
    return res;
  },
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      clearCredentials();
      useAuthStore.setState((store) => ({ ...store, isAuthenticated: false }));
    }
    return Promise.reject(error);
  }
);

export default newCapTableApi;
