export interface AllTermsheetModel {
  id: string;
  termsheetName: string;
  valuation: number;
  roundSize: number;
}

/////////////////////////////////////////////////////////////////
interface RoundDetailModel {
  roundId: string; //uuid
  roundType: string; //what type of round is it secondary, round ,bonus,buyback
  roundName: string; //name of the round
  roundIdentifier: string; //short id for round
}

export interface IndividualTermSheetModel {
  id: string;
  name: string;
  roundSize: number;
  preMoneyValuation: number;
  preMoneyDate: string;
  dilution: number;
  isPricedRound: boolean;
  postRoundEsop: number;
  termSheetLink: string;
  captable: IndividualTermSheetCapTable[];
}

export interface IndividualTermSheetCapTable {
  id: string;
  rounds: RoundDetailModel[];
  preHoldingShares: number;
  postHoldingShares: number;
  preHoldingFdbShares: number;
  postHoldingFdbShares: number;
  preHoldingFdbPercentage: number;
  postHoldingFdbPercentage: number;
  amountInvestedInRound: number;
  shareholderName: string;
  shareholderType: string;
}

export enum TermsheetStatus {
  active,
  withdrawn,
  rejected,
}

export type SectionType = {
  sectionName: string;
  values: { label: string; value: string }[];
};

export interface NewTermsheetDetails {
  investors: Investor[];
  roundDetails: RoundDetails;
  otherTerms?: SectionType[];
}

interface RoundDetails {
  roundSize: number | null;
  preMoneyValuation: number | null;
  postMoneyValuation: number | null;
  pricedRound: boolean;
  closeDate: string;
  postRoundEsopPercentage: number | null;

  roundName?: string;
  roundId?: string | null;
  roundCurrency?: string | null;
  exchangeRate?: number | null;
  autoDiluteEsop?: boolean | null;

  issuanceType?: string | null;
  paymentType?: string | null;
  allotmentType?: string | null;
  liquidationPreference?: number | null;
  liquidationPreferenceParticipation?: boolean | null;
  liquidationPreferenceDescription?: string | null;
  antidilution?: string | null;
  antidilutionDescription?: string | null;
}

export interface Investor {
  companyInvestorId?: string;
  shareholderName: string;
  shareholderType: string | null;
  securityType: string;
  investmentAmount: number | null;
  securityName?: string | null;
  noOfShares?: number | null;
  fdbShares?: number | null;
  percentageOfShares?: number | null;
  country?: string;
  dateOfInvestment?: string;
  pricePerShare?: number | null;
  address?: string | null;
  conversionRatio?: string | null;
  parValue?: number | null;
  votingRatio?: number | null;
  votingRight?: string | null;
  investmentCurrency?: string | null;
  isConvertible?: boolean;
  discount?: number | null;
  valuationCap?: number | null;
  instrumentClassId?: string | null;
}

export interface TermsheetData {
  status: TermsheetStatus;
  termsheetName: string;
  id: string;
  termsheetData: NewTermsheetDetails;
  proposalDate: string;
  expiryDate: string;
  documentUrl: string;
}

export interface PrePostCaptable {
  preEventHoldings: EventHolding[];
  postEventHoldings: postEventHolding[];
  postEsopAdjustedCaptable: EventHolding[];
  roundDetails: any;
}

export interface postEventHolding {
  name: string;
  postCaptable: EventHolding[];
}

export interface RoundIdentifiers {
  roundId: string;
  roundIdentifier: string;
  roundName: string;
  roundType: string;
}

export interface HoldingSecuritiesType {
  numberOfShares: string;
  securityType: string;
}

export interface EventHolding {
  id: string;
  shares: number;
  fdbShares: number;
  equityShares: number;
  prefShares: number;
  debentures: number;
  warrants: number;
  notes: number;
  percentage: number;
  fdbPercentage: number;
  holdingSecirities: HoldingSecuritiesType[];
  name: string;
  type: string;
  investedAmount: number;
  roundIdentifierDetails: RoundIdentifiers;
  residential: string;
  isEquityPartlyPaid: boolean;
  isPreferencePartlyPaid: boolean;
  isDebtPartlyPaid: boolean;
  isDebt: boolean;
}

export interface IndividualTermsheet {
  termsheetData: TermsheetData;
  prePostCaptable: PrePostCaptable;
}

export interface IndividualTermSheetPreCapTable {
  id: string;
  preHoldingFdbShares: number;
  postHoldingFdbShares: number;
  preHoldingFdbPercentage: number;
  postHoldingFdbPercentage: number;
  amountInvestedInRound: number;
  shareholderName: string;
  shareholderType?: string;
}
