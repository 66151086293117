import { useState } from "react";
import { Input, Label } from "../../components/shared/InputField";
import { Select } from "../../components/shared/Select";
import { HStack, VStack } from "../../components/utils";
import { ValuationTabDetails } from "./TransactionTabDetails";
import Avatar from "../Utility/Avatar";
import { formatDisplayDate } from "../../utils/date";
import { EventInfo } from "../newQuickTransaction/RoundTransactionModel";
import { ShowNumberInWords } from "../../components/shared/UiElement";
import {
  getCurrencySymbol,
  getCurrencyType,
} from "../../utils/currencyFormatter";
import {
  convertToNumber,
  filterOnlyNumbers,
  numberWithCommas,
} from "../../utils/numUtils";

const Valuation = ({
  date,
  transactionDetails,
  valuation,
  pps,
  preEventvaluation,
  preEventPps,
  onValuationUpdate,
  onPpsUpdate,
  currencySymbol,
  isShared = false,
  isPriced = true,
}: {
  date?: string;
  transactionDetails?: EventInfo;
  valuation: number;
  pps: number;
  preEventvaluation: number;
  preEventPps: number;
  onValuationUpdate: (data: number) => void;
  onPpsUpdate: (data: number) => void;
  currencySymbol: string;
  isShared: boolean;
  isPriced: boolean;
}) => {
  const [valuationInput, setValuationInput] = useState<string>("Current Value");
  const [ppsInput, setPpsInput] = useState<string>("Current Value");
  const [valuationInputValue, setValuationInputValue] = useState(valuation);
  const [ppsInputValue, setPpsInputValue] = useState(pps);
  const [onHoverUpdatedPps, setOnHoverUpdatedPps] = useState(false);
  const [onHoverUpdatedValuation, setOnHoverUpdatedValuation] = useState(false);
  const [formatUpdateValuation, setFormatUpdateValuation] = useState(
    `${valuation}`
  );
  const [formatUpdatePps, setFormatUpdatePps] = useState(`${pps}`);

  const currencyType = getCurrencyType();

  const options = ["Current Value", "Previous Value", "Custom"];

  return (
    <div className="p-4 mx-auto bg-white border rounded-lg max-w-7xl sm:px-6 lg:px-7 shadow-box border-borderColor ">
      {transactionDetails && date && (
        <div className="flex items-end">
          <div className="flex-shrink-0 w-10 h-10">
            <Avatar name={transactionDetails.name || "A"} />
          </div>
          <div className="ml-4">
            <div className="text-lg font-semibold text-gray-900">
              {transactionDetails.name || "A"}
            </div>
            <div className="font-semibold text-gray-500 text-xs2">
              {formatDisplayDate(date)}
            </div>
          </div>
        </div>
      )}
      <ValuationTabDetails
        valuation={valuationInputValue}
        pps={ppsInputValue}
        preValuation={preEventvaluation || 0}
        prePps={preEventPps || 0}
      />
      {isPriced && (
        <HStack className="flex-col justify-between md:flex-row">
          <VStack>
            <HStack className="items-center justify-between gap-2 mb-4">
              <Label className="items-center my-4 text-xs font-medium">
                Select method of valuation update
              </Label>
              <Select
                className="w-12 md:w-36 h-min"
                options={options}
                disabled={isShared}
                value={valuationInput}
                onChange={(e) => {
                  const editType = e.target.value;
                  setValuationInput(editType);
                  if (editType === "Current Value") {
                    setValuationInputValue(valuation);
                    setFormatUpdateValuation(`${valuation}`);
                    onValuationUpdate(valuation);
                  } else if (editType === "Previous Value") {
                    setFormatUpdateValuation(`${preEventvaluation}`);
                    setValuationInputValue(preEventvaluation);
                    onValuationUpdate(preEventvaluation);
                  }
                }}
              />
            </HStack>
            <HStack className="items-center justify-between">
              <Label className="items-center my-4 text-xs font-medium">
                Select method of PPS update
              </Label>
              <Select
                className="w-12 md:w-36 h-min"
                options={options}
                value={ppsInput}
                disabled={isShared}
                onChange={(e) => {
                  const editType = e.target.value;
                  setPpsInput(editType);
                  if (editType === "Current Value") {
                    setPpsInputValue(pps);
                    setFormatUpdatePps(`${pps}`);
                    onPpsUpdate(pps);
                  } else if (editType === "Previous Value") {
                    setPpsInputValue(preEventPps);
                    setFormatUpdatePps(`${preEventPps}`);
                    onPpsUpdate(preEventPps);
                  }
                }}
              />
            </HStack>
          </VStack>
          <VStack className="mt-2 md:m-0 w-72">
            <HStack className="items-center justify-between mb-4">
              <Label className="items-center my-4 text-xs font-medium">
                Updated Valuation ({currencySymbol})
              </Label>
              <VStack>
                <Input
                  placeholder="Enter valuation"
                  className="items-center w-28 h-min"
                  disabled={valuationInput !== "Custom"}
                  type="text"
                  onMouseEnter={() => {
                    setOnHoverUpdatedValuation(true);
                  }}
                  onMouseLeave={() => {
                    setOnHoverUpdatedValuation(false);
                  }}
                  onChange={(e: any) => {
                    const filteredValue = filterOnlyNumbers(e.target.value);
                    setFormatUpdateValuation(filteredValue);
                    const values = convertToNumber(filteredValue);

                    setValuationInputValue(values);
                    onValuationUpdate(values);
                  }}
                  value={numberWithCommas(formatUpdateValuation, currencyType)}
                />

                {onHoverUpdatedValuation && (
                  <ShowNumberInWords
                    value={valuationInputValue}
                    width={38}
                    currency={currencyType}
                  />
                )}
              </VStack>
            </HStack>
            <HStack className="items-center justify-between">
              <Label className="items-center my-4 text-xs font-medium">
                Updated PPS ({currencySymbol})
              </Label>
              <VStack>
                <Input
                  placeholder="Enter PPS"
                  className="items-center w-28 h-min"
                  disabled={ppsInput !== "Custom"}
                  type="text"
                  onMouseEnter={() => {
                    setOnHoverUpdatedPps(true);
                  }}
                  onMouseLeave={() => {
                    setOnHoverUpdatedPps(false);
                  }}
                  onChange={(e: any) => {
                    const filteredValue = filterOnlyNumbers(e.target.value);
                    setFormatUpdatePps(filteredValue);
                    const values = convertToNumber(filteredValue);

                    setPpsInputValue(values);
                    onPpsUpdate(values);
                  }}
                  value={numberWithCommas(formatUpdatePps, currencyType)}
                />
                {onHoverUpdatedPps && (
                  <ShowNumberInWords
                    value={ppsInputValue}
                    width={38}
                    currency={currencyType}
                  />
                )}
              </VStack>
            </HStack>
          </VStack>
        </HStack>
      )}
    </div>
  );
};

export default Valuation;
