import { Center, HStack } from "./utils";

function SecurityBarChartLegend() {
  const legends = [
    //Dirty commit {
    //   name: "Authorised",
    //   colorName: "#FF8863",
    // },
    {
      name: "Paid Up",
      colorName: "#869EDD",
    },
    {
      name: "Issued",
      colorName: "#E8C05C",
    },
  ];

  return (
    <HStack className="overflow-auto scrollbar-hide px-16 mx-auto justify-evenly">
      {legends.map((legend) => (
        <HStack
          key={legend.name}
          className="items-center font-medium text-gray-600 border-gray-600 text-xs1 cursor-pointer mx-2"
        >
          <Center className="w-5 h-5 loader-linear ">
            <Center
              style={{
                backgroundColor: legend.colorName,
              }}
              className="flex w-3 h-3 rounded-full "
            ></Center>
          </Center>
          <p className="my-auto text-xxs1">{legend.name}</p>
        </HStack>
      ))}
    </HStack>
  );
}

export default SecurityBarChartLegend;
