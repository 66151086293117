import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { AgGridReact } from "ag-grid-react";
import {
  CellClickedEvent,
  ColDef,
  SideBarDef,
  GridApi,
} from "ag-grid-community";
import { useNavigate } from "react-router";
import { Action, CTADropdown } from "../../components/shared/Dropdown";
import { Box, HStack, VStack, useGetCompanyName } from "../../components/utils";
import { AgGridTransactionsCaptable } from "../../types/AllTransactionModel";
import { agConfigDashboardSideBar } from "../dashboardPage/AgGridConfig";

import {
  AmountInvestedRender,
  NetworthRender,
  NumberOfSharesRender,
  PostHoldingCommonSharesRender,
  PostHoldingDebRender,
  PostHoldingFdbPercentage,
  PostHoldingFdbSharesRender,
  PostHoldingPrefRender,
  PreHoldingCommonSharesRender,
  PreHoldingDebRender,
  PreHoldingFdbPercentage,
  PreHoldingFdbSharesRender,
  PreHoldingPrefRender,
  ShareholderNameRender,
  TotalAmountInvestedRender,
  TransactionAmountRender,
} from "./RoundsAGComponent";
import {
  getCurrencySymbol,
  getCurrencyType,
} from "../../utils/currencyFormatter";
import QuickSearch from "./QuickSearch";
import GenericTableHeader from "../../shared/TableHeader";
import { IconCTAButton } from "../quickRound/CTAButtonComponents";
import { useAuthStore } from "../../store/useAuthStore";
import {
  useGetOnFilterState,
  usePostOnFilterState,
} from "../dashboardPage/AgGridCacheQuery";
import {
  exportIndividualRoundSummaryReport,
  exportPAS4Report,
} from "../../api/Report";
import { getIndividualRoundDetailReport } from "../../api/Reports";
import { downloadExcel } from "../../utils/DownloadFile";
import { downloadFile } from "../../utils/downloadBlob";
import { tableCellStyle } from "../../components/TableComponent";
import { useAgGridTableState } from "../../store/agGridTableStore";
import { ExportImport } from "../Utility/GrantsTable";
import SearchInput from "../../components/shared/SearchInput";
import { CumulativeHoldingQuickRound } from "../../types/CapTable";

function NonPricedAgCaptable({
  capTableData,
}: {
  capTableData: CumulativeHoldingQuickRound[];
}) {
  const currencySymbol = getCurrencySymbol();
  const companyName = useGetCompanyName();
  const gridApi = useRef<any>(null);

  const displayedRowCount = gridApi.current?.api.getDisplayedRowCount();
  const componentsRegistery = useMemo(
    () => ({
      shareholderName: memo(ShareholderNameRender),
      preHoldingFdbShares: memo(PreHoldingFdbSharesRender),
      postHoldingFdbShares: memo(PostHoldingFdbSharesRender),
      preHoldingFdbPercentage: memo(PreHoldingFdbPercentage),
      postHoldingFdbPercentage: memo(PostHoldingFdbPercentage),
      amountInvested: memo(AmountInvestedRender),
      totalAmountInvested: memo(TotalAmountInvestedRender),
      numberOfShares: memo(NumberOfSharesRender),
      networth: memo(NetworthRender),
      transactionAmount: memo(TransactionAmountRender),
      preHoldingCommonShares: memo(PreHoldingCommonSharesRender),
      postHoldingCommonShares: memo(PostHoldingCommonSharesRender),
      preHoldingPref: memo(PreHoldingPrefRender),
      postHoldingPref: memo(PostHoldingPrefRender),
      preHoldingDeb: memo(PreHoldingDebRender),
      postHoldingDeb: memo(PostHoldingDebRender),
    }),
    []
  );
  const isValidDate = (date: string) =>
    Number.isNaN(date) &&
    new Date(date).toString() !== "Invalid Date" &&
    !Number.isNaN(Date.parse(date));

  const normaliseValue = (value: string | number) => {
    if (typeof value === "number") return value;
    if (typeof value === "string") {
      // check if it can be converted to number first
      // assume if commas are there
      if (isValidDate(value)) return new Date(value).valueOf();
      try {
        value = value.replaceAll(",", "");
      } catch (TypeError) {
        value = value.replace(/,./g, "");
      }
      return parseFloat(value);
    }
    return value;
  };

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      autoHeight: true,
      wrapHeaderText: true,
      suppressColumnVirtualisation: true,
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      filterParams: {
        buttons: ["reset"],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
      flex: 1,
    }),
    []
  );

  const columnDefs: any[] = useMemo<any>(
    () => [
      {
        headerName: "Name",
        field: "shareholderName",
        getQuickFilterText: (params: any) => params.value.name,
        filter: "agMultiColumnFilter",
        comparator(valueA: any, valueB: any, ..._: any[]) {
          return valueA.name > valueB.name ? 1 : -1;
        },
        filterParams: {
          maxNumConditions: 5,
          buttons: ["reset"],
          filters: [
            {
              title: "Name",
              display: "subMenu",
              filter: "agTextColumnFilter",
              buttons: ["reset"],
              filterValueGetter: (params: any) => params.value.name,
              filterParams: {
                buttons: ["reset"],
                valueGetter: (params: any) =>
                  params.getValue("shareholderName").name,
              },
            },
            {
              title: "Type",
              filter: "agSetColumnFilter",
              display: "subMenu",
              buttons: ["reset"],
              filterParams: {
                buttons: ["reset"],
                keyCreator: (params: any) => {
                  const shareholderType = params.value.type;
                  return shareholderType;
                },
                valueFormatter: (params: any) => {
                  const shareholderType = params.value.type;
                  return shareholderType;
                },
              },
            },
          ],
        },
        cellRenderer: "shareholderName",
        initialWidth: 200,
        sortable: true,
        autoHeight: true,
        cellStyle: tableCellStyle,
        wrapText: true,
        suppressSizeToFit: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipShareholderName",
        pinned: "left",
      },
      {
        headerName: "Pre-Holding FDB %",
        field: "preHoldingFdbPercentage",
        filter: "agNumberColumnFilter",
        initialWidth: 150,
        cellRenderer: "preHoldingFdbPercentage",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipPreHoldingFdbPercentage",
      },
      {
        headerName: "Post-Holding FDB %",
        field: "postHoldingFdbPercentage",
        filter: "agNumberColumnFilter",
        initialWidth: 150,
        cellRenderer: "postHoldingFdbPercentage",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipPostHoldingFdbPercentage",
      },
      {
        headerName: "Pre-Holding FDB Shares",
        initialWidth: 150,
        field: "preHoldingFdbShares",
        filter: "agNumberColumnFilter",
        cellRenderer: "preHoldingFdbShares",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipPreHoldingFDBShares",
      },
      {
        headerName: "Post-Holding FDB Shares",
        field: "postHoldingFdbShares",
        initialWidth: 150,
        filter: "agNumberColumnFilter",
        cellRenderer: "postHoldingFdbShares",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipPostHoldingFDBShares",
      },
    ],
    []
  );

  const gridOptions = {
    suppressRowVirtualisation: true, // Add this line to disable row virtualization
    paginationAutoPageSize: false, // Optional: Set to false if using custom pagination
    suppressScrollOnNewData: true, // Optional: Set to true to prevent scrolling to the top on
  };
  const currencyType = getCurrencyType();
  const rowData = useMemo(
    () =>
      capTableData?.map((template) => ({
        id: template.id,
        preHoldingFdbShares: template.preHoldingFdbShares,
        tooltipPreHoldingFDBShares:
          template.preHoldingFdbShares?.toLocaleString(currencyType),
        preHoldingFdbPercentage: Number(
          (Math.abs(template.preHoldingFdbPercentage) * 100).toFixed(2)
        ),
        tooltipPreHoldingFdbPercentage:
          Math.abs(template.preHoldingFdbPercentage) * 100,

        postHoldingFdbShares: template.postHoldingFdbShares,
        tooltipPostHoldingFDBShares:
          template.postHoldingFdbShares?.toLocaleString(currencyType),
        postHoldingFdbPercentage: Number(
          (Math.abs(template.postHoldingFdbPercentage) * 100).toFixed(2)
        ),
        tooltipPostHoldingFdbPercentage: (
          Math.abs(template.postHoldingFdbPercentage) * 100
        ).toLocaleString(),
        shareholderName: {
          name: template.name,
          type: template.type,
        },
        preHoldingPref: template.preHoldingPrefShares,
        tooltipPreHoldingPref:
          template.preHoldingPrefShares?.toLocaleString(currencyType),
        postHoldingPref: template.postHoldingPrefShares,
        tooltipPostHoldingPref:
          template.postHoldingPrefShares?.toLocaleString(currencyType),
        preHoldingDeb: template.preHoldingDebentures,
        tooltipPreHoldingDeb:
          template.preHoldingDebentures?.toLocaleString(currencyType),
        postHoldingDeb: template.postHoldingDebentures,
        tooltipPostHoldingDeb:
          template.postHoldingDebentures?.toLocaleString(currencyType),
        currencySymbol,
        currencyType,
      })),
    [capTableData]
  );

  function setTableHeight() {
    if (displayedRowCount === 1) {
      return (displayedRowCount + 2.75) * 60;
    } else if (displayedRowCount === 2) {
      return (displayedRowCount + 2.68) * 60;
    } else if (displayedRowCount === 3) {
      return (displayedRowCount + 3) * 60;
    } else if (displayedRowCount === 4) {
      return (displayedRowCount + 2.55) * 60;
    } else if (displayedRowCount === 5) {
      return (displayedRowCount + 2.48) * 60;
    } else if (displayedRowCount === 6) {
      return (displayedRowCount + 2.42) * 60;
    } else if (displayedRowCount === 7) {
      return (displayedRowCount + 2.35) * 60;
    } else if (displayedRowCount === 8) {
      return (displayedRowCount + 2.28) * 60;
    } else if (displayedRowCount === 9) {
      return (displayedRowCount + 2.22) * 60;
    } else {
      return 10 * 60;
    }
  }

  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const openToolPanel = (key: any) => {
    if (key === "columns") {
      if (gridApi) {
        gridApi?.current?.api?.closeToolPanel();
        setIsFilterOpen(false);
      }
    } else if (key === "filters") {
      if (gridApi) {
        if (!isFilterOpen) gridApi?.current?.api?.openToolPanel(key);
        else gridApi?.current?.api?.closeToolPanel();
        setIsFilterOpen((state) => !state);
      }
    }
  };

  function setPinnedBottomRowData({ api }: { api: any }) {
    const data = api.rowModel.rootNode;
    const filteredData = data.childrenAfterAggFilter.map(
      (element: any) => element.data
    );
    const totalPreholdingFdbShares = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + data.preHoldingFdbShares,
      0
    );

    const totalPostholdingFdbShares = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + data.postHoldingFdbShares,
      0
    );

    const totalPreholdingFdbPercentage = filteredData?.reduce(
      (accumulator: any, data: any) =>
        accumulator + data.preHoldingFdbPercentage,
      0
    );

    const totalPostholdingFdbPercentage = filteredData?.reduce(
      (accumulator: any, data: any) =>
        accumulator + data.preHoldingFdbPercentage,
      0
    );

    api.setPinnedBottomRowData([
      {
        shareholderName: {
          name: "Total",
          type: null,
        },

        rounds: null,
        preHoldingFdbShares: totalPreholdingFdbShares,
        tooltipPreHoldingFDBShares:
          totalPreholdingFdbShares.toLocaleString(currencyType),
        preHoldingFdbPercentage:
          totalPreholdingFdbPercentage * 100 > 99.98
            ? 100
            : Number((totalPreholdingFdbPercentage * 100).toFixed(2)),
        tooltipPreHoldingFdbPercentage:
          totalPreholdingFdbPercentage * 100 > 99.98
            ? 100
            : Number((totalPreholdingFdbPercentage * 100).toFixed(2)),

        postHoldingFdbShares: totalPostholdingFdbShares,
        tooltipPostHoldingFDBShares:
          totalPostholdingFdbShares.toLocaleString(currencyType),
        postHoldingFdbPercentage: Number(
          totalPostholdingFdbPercentage * 100 > 99.98
            ? 100
            : Number((totalPostholdingFdbPercentage * 100).toFixed(2))
        ),
        tooltipPostHoldingFdbPercentage:
          totalPostholdingFdbPercentage * 100 > 99.98
            ? 100
            : Number((totalPostholdingFdbPercentage * 100).toFixed(2)),
        currencyType,
      },
    ]);
  }

  const [columnState, setColumnState] = useState(null);

  const [filteredRowData, setFilteredRowData] = useState<any>([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const onAgGridFilterChanged = (grid: any) => {
    const filtersApplied = grid.api.isAnyFilterPresent();
    setIsFilterApplied(filtersApplied);

    const filteredData = grid.api
      .getModel()
      .rowsToDisplay.map((node: any) => node.data);
    setFilteredRowData(filteredData);
    setPinnedBottomRowData(grid);
  };

  return (
    <>
      <HStack className="justify-between pt-4">
        <GenericTableHeader
          heading={"Cap Table"}
          subHeading={"Shareholders"}
          count={
            isFilterApplied === true ? filteredRowData.length : rowData.length
          }
        />
        <HStack className="justify-end items-center gap-4 pr-4">
          <SearchInput
            placeholder={`Search`}
            onChange={(e: any) => {
              gridApi.current.api.setQuickFilter(e.target.value);
            }}
          />

          <IconCTAButton
            value={"Filters"}
            onClick={() => openToolPanel("filters")}
            iconName={"material-symbols:filter-alt"}
            className={`px-4 font-medium items-center flex flex-row ${
              isFilterOpen ? "text-orange-501" : "text-gray-400"
            }`}
          />
        </HStack>
      </HStack>
      <HStack className="justify-between w-full">
        <Box
          style={{
            height: setTableHeight(),
          }}
          className="w-full max-h-full overflow-x-auto ag-theme-material h-full "
        >
          <AgGridReact
            sideBar={agConfigDashboardSideBar}
            onGridReady={(params) => {
              // Store the grid API referen
              gridApi.current = params;
            }}
            components={componentsRegistery}
            alwaysShowHorizontalScroll
            alwaysMultiSort
            animateRows={true}
            defaultColDef={defaultColDef}
            onRowDataUpdated={setPinnedBottomRowData}
            onFilterChanged={onAgGridFilterChanged}
            rowData={rowData}
            enableCharts={true}
            enableRangeSelection={true}
            columnDefs={columnDefs}
            pagination={false}
            suppressRowTransform={true}
            suppressCopyRowsToClipboard={true}
            suppressCopySingleCellRanges={true}
            suppressCellFocus={true}
            suppressMenuHide={false}
            tooltipShowDelay={1000}
            tooltipInteraction={true}
            rowClass={"border-t border-dashed "}
            gridOptions={gridOptions}
            overlayNoRowsTemplate={
              '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow; margin-top: 50px;">No Rows To Show</span>'
            }
            getRowStyle={(params) => {
              if (params.rowIndex % 2 === 0) {
                return { background: "#f8f8f8" };
              } else {
                return { background: "#ffffff" };
              }
            }}
          ></AgGridReact>
        </Box>
      </HStack>
    </>
  );
}

export default NonPricedAgCaptable;
