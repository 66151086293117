import React from "react";
import { VStack } from "../components/utils";

const EmptyTableIllustration = ({ text }: { text: string }) => (
  <VStack className="w-full mx-auto placeholderLoader">
    <img
      src="/emptyTable.png"
      className="h-44 my-5 w-56 mx-auto placeholderLoader"
      alt="no data"
    />
    <p className="mx-auto text-labelColor ">{text}</p>
  </VStack>
);

export default EmptyTableIllustration;
