import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { HStack, VStack } from "../../components/utils";
import BCHeader from "../../shared/BCHeader";
import { useAuthStore } from "../../store";
import { useRoundModelStore } from "../../store/roundModelingStore";
import ReportsAgTable, { ReportsTableModelProps } from "./ReportsTable";

function ReportsPage() {
  const [dialog, setDialog] = useState<{
    open: boolean;
    mode?: string;
    data?: string;
  }>({
    open: false,
  });

  const roundModelStore = useRoundModelStore();
  const isAuthenticated = useAuthStore().isAuthenticated;

  useEffect(() => {
    if (isAuthenticated && !roundModelStore.roundCreation.modelId) {
      roundModelStore.connect();
      roundModelStore.recieveMessage("message");
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (roundModelStore.roundCreation.errorMessage && isAuthenticated) {
      toast(roundModelStore.roundCreation.errorMessage, {
        type: "error",
        autoClose: 1000,
      });
    }
  }, []);

  return (
    <div className="min-h-full">
      <HStack className="justify-start mb-4">
        <VStack>
          <BCHeader className={`items-baseline`} bcTitle="Reports Overview" />
          <p className="ml-3 sm:mt-0 -mt-4 sm:text-xs1 text-xxs font-medium text-descriptionColor whitespace-nowrap md:mr-12">
            All your reports, bundled for your convenience.
          </p>
        </VStack>
      </HStack>
      <ReportsAgTable />
    </div>
  );
}

export default ReportsPage;
