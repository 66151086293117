import { Icon } from "@iconify/react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Dialog } from "@mui/material";
import { useNavigate } from "react-router";
import { useState } from "react";
import { toast } from "react-toastify";
import { Input, Label } from "../../components/shared/InputField";
import { Box, Error, HStack, VStack } from "../../components/utils";
import {
  useDeleteOnGoingTransaction,
  usePostOnGoingTransaction,
  usePostTransactionSplitDetail,
  useDiscardQuickRound2,
} from "../../queries/transactionRound";
import {
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../quickRound/CTAButtonComponents";
import {
  TransactionSplitInputModel,
  QuickSplitTransactionModel,
} from "./RoundTransactionModel";
import { useAuthStore } from "../../store";
import { useQuickSplitTransactionStore } from "../../store/useQuickTransactionStore";
import AlertDialog from "../../components/shared/AlertDialog";
import { Select } from "../../components/shared/Select";
import FileInput from "../../components/shared/FileInput";
import { SwitchButton } from "../../components/shared/SwitchButton";
import { useShareholderInstrumentStore } from "../../store/shareholderInstrumentStore";

const transactionSchema = Yup.object().shape({
  splitName: Yup.string().required().label("Split Name"),
  splitRatio: Yup.number().required().label("Split Number"),
  securityType: Yup.string().required().label("Security Type"),
  date: Yup.string().required().label("Round Size"),
});

function QuickAddSplit() {
  const companyId = useAuthStore.getState().companyId || "";
  const [excelUpload, setExcelUpload] = useState(false);
  const [minimum, setMinimum] = useState(false);

  const {
    state: template,
    setState: setTemplate,
    reset: resetTemplate,
  } = useQuickSplitTransactionStore();
  const pathName = window.location.href.split("/");
  const roundType = pathName[4];

  const shareholderInstruments = useShareholderInstrumentStore();

  const currentInstrumentTypes = Array.from(
    new Set(
      shareholderInstruments.instrumentClasses.map(
        (instrument) => instrument.subType
      )
    )
  );

  const { mutate: postTransactionSplit } =
    usePostTransactionSplitDetail(companyId);

  const { mutate: deleteOnGoingTransaction } =
    useDeleteOnGoingTransaction(companyId);
  const { mutate: postOnGoingTransaction } = usePostOnGoingTransaction();

  const initialValues: TransactionSplitInputModel = {
    splitName: template.quickSplitInput?.splitName || "",
    splitRatio: template.quickSplitInput?.splitRatio || 0,
    securityType: template.quickSplitInput?.securityType || "",
    date: template.quickSplitInput?.date || "",
    updateOtherClassesForEquity:
      template.quickSplitInput?.updateOtherClassesForEquity || false,
    allowUpdateRatioForOthers:
      template.quickSplitInput?.allowUpdateRatioForOthers || false,
  };
  const [dialog, setDialog] = useState<{
    open: boolean;
    message?: string;
  }>({
    open: false,
  });

  const navigate = useNavigate();
  const { mutate: discardQuickRound } = useDiscardQuickRound2();
  const displayDiscardQuickRoundPopUp = () => {
    setDialog({
      open: true,
      message: `Do you want to discard the current ongoing quick ${roundType} ?`,
    });
  };

  const handleDiscard = () => {
    setDialog({ open: false });
    discardQuickRound();
    resetTemplate();
    deleteOnGoingTransaction(companyId);
    navigate("/transactions/allTransactions");
  };

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: transactionSchema,
    initialValues,
    onSubmit: (values) => {
      const finalData: QuickSplitTransactionModel = {
        stepNo: 2,
        transactionType: "split",
        companyId,
        quickSplitInput: {
          splitName: values.splitName,
          splitRatio: values.splitRatio,
          date: values.date,
          securityType: values.securityType,
          updateOtherClassesForEquity: values.updateOtherClassesForEquity,
          allowUpdateRatioForOthers: values.allowUpdateRatioForOthers,
        },
      };
      const finalData2: QuickSplitTransactionModel = {
        companyId,
        quickSplitInput: {
          splitName: values.splitName,
          splitRatio: values.splitRatio,
          date: values.date,
          securityType: values.securityType,
          updateOtherClassesForEquity: values.updateOtherClassesForEquity,
          allowUpdateRatioForOthers: values.allowUpdateRatioForOthers,
        },
      };
      postTransactionSplit(finalData2, {
        onSuccess: (data) => {
          postOnGoingTransaction({
            ...finalData,
            eventId: data.id,
          });
          setTemplate({
            ...finalData,
            eventId: data.id,
          });
          toast("Split Detail Uploaded Successfully!", {
            type: "success",
            autoClose: 2000,
          });
        },
        onError: (err: any) => {
          toast(err.response.data.errorMessage, {
            type: "error",
            autoClose: 2000,
          });
        },
      });
    },
  });
  //   async function handleFileUpload(e: React.ChangeEvent<HTMLInputElement>) {
  //     if (!e?.target?.files?.[0]) return;
  //     const file = e.target.files[0];
  //     const base64 = await convertToBase64(file);
  //     roundExcelUpload(
  //       { file: base64 },
  //       {
  //         onSuccess: (data) => {
  //           setTemplate({
  //             stepNo: 2,
  //             transactionType: "secondary",
  //             eventId: data.id,
  //           });
  //           toast("Excel Uploaded Successfully", {
  //             type: "success",
  //             autoClose: 2000,
  //           });
  //         },
  //         onError: (err: any) => {
  //           toast(err.response.data.errorMessage, {
  //             type: "error",
  //             autoClose: 2000,
  //           });
  //         },
  //       }
  //     );
  //   }

  return (
    <VStack>
      <Box className="">
        <VStack className="bg-white rounded-lg p-6">
          <HStack className="justify-between pb-8">
            <VStack>
              <p className="text-xl font-semibold text-gray-dark">
                Split Details
              </p>
              <Box className="text-xs font-medium text-gray-light">
                Fill the split details to create a quick round
              </Box>
            </VStack>
          </HStack>

          <Dialog open={dialog.open} maxWidth="md">
            <AlertDialog
              onClose={() => setDialog({ open: false })}
              open={dialog.open}
              message={dialog.message}
              onPrimaryAction={handleDiscard}
              onSecondaryAction={() => setDialog({ open: false })}
            />
          </Dialog>
          <VStack className="mb-4 hidden">
            <Label className="text-sm font-medium">
              Select method of split creation
            </Label>
            <HStack className=" flex items-center hidden">
              <Icon
                onClick={() => setExcelUpload(false)}
                icon={
                  !excelUpload
                    ? "ic:round-radio-button-checked"
                    : "ic:round-radio-button-unchecked"
                }
                className=" text-primary"
                width="18"
                height="18"
              />

              <Label className="pl-2 pt-2 text-sm font-medium  mr-8">
                Upload Excel with split details
              </Label>
              {!excelUpload && (
                <FileInput
                  accept=".xls, .xlsx"
                  file={"upload split excel"}
                  // onChange={(e) => {
                  //   handleFileUpload(e);
                  // }}
                />
              )}
            </HStack>
            <HStack className=" py-2 items-center mb-4 hidden">
              <Icon
                // onClick={() => setExcelUpload(true)}
                // icon={
                //   excelUpload
                //     ? "ic:round-radio-button-checked"
                //     : "ic:round-radio-button-unchecked"
                // }

                icon="ic:round-radio-button-checked"
                className=" text-primary"
                width="18"
                height="18"
              />
              <Label className="px-2  text-sm font-medium">
                Enter Manually
              </Label>
            </HStack>
          </VStack>

          {!excelUpload && (
            <VStack className="gap-8 -mt-4">
              <HStack className="justify-start gap-8 flex-wrap">
                <VStack className="">
                  <Label className="py-2 w-32 mr-2 text-sm font-medium whitespace-nowrap">
                    Split Name
                  </Label>
                  <VStack>
                    <Input
                      type="text"
                      className="w-60"
                      placeholder="Enter Split Name"
                      {...formik.getFieldProps("splitName")}
                    />
                    {formik.touched?.splitName && formik.errors?.splitName && (
                      <Error text={formik.errors?.splitName} />
                    )}
                  </VStack>
                </VStack>
                <VStack className="">
                  <Label className="py-2 lg:w-fit w-32 mr-2 text-sm font-medium whitespace-nowrap">
                    Split Date
                  </Label>
                  <VStack>
                    <Input
                      type="date"
                      className="w-60"
                      {...formik.getFieldProps("date")}
                    />
                    {formik.touched?.date && formik.errors?.date && (
                      <Error text={formik.errors?.date} />
                    )}
                  </VStack>
                </VStack>

                <VStack className="">
                  <Label className="py-2 w-32 mr-2 text-sm font-medium whitespace-nowrap">
                    Split Number
                  </Label>
                  <VStack>
                    <HStack>
                      <p className="pt-5 text-md pr-2">1</p>
                      <p className="pt-5 text-md pr-2">:</p>

                      <Input
                        type="number"
                        className=" w-56"
                        placeholder="Enter split Ratio"
                        {...formik.getFieldProps("splitRatio")}
                      />
                    </HStack>
                    {formik.touched?.splitRatio &&
                      formik.errors?.splitRatio && (
                        <Error text={formik.errors?.splitRatio} />
                      )}
                  </VStack>
                </VStack>
                <VStack className="">
                  <Label className="py-2  mr-2 text-sm font-medium whitespace-nowrap">
                    Security
                  </Label>
                  <VStack className="w-60">
                    <Select
                      options={currentInstrumentTypes}
                      {...formik.getFieldProps("securityType")}
                      onChange={(e) => {
                        formik.handleChange(e);
                        if (e.target.value !== "Equity") {
                          formik.setFieldValue(
                            "updateOtherClassesForEquity",
                            false
                          );
                          formik.setFieldValue(
                            "allowUpdateRatioForOthers",
                            false
                          );
                        }
                      }}
                    />
                    {formik.touched?.securityType &&
                      formik.errors?.securityType && (
                        <Error text={formik.errors?.securityType} />
                      )}
                  </VStack>
                </VStack>
                {formik.values.securityType === "Equity" && (
                  <HStack className="justify-between gap-16">
                    <VStack className="">
                      <Label className="py-2 w-32 mr-2 text-sm font-medium whitespace-nowrap">
                        Split Other Classes
                      </Label>
                      <VStack>
                        <SwitchButton
                          className="text-sm font-medium items-center ml-2 mb-1"
                          value={formik.values.updateOtherClassesForEquity}
                          label=""
                          onClick={() => {
                            formik.setFieldValue(
                              "updateOtherClassesForEquity",
                              !formik.values.updateOtherClassesForEquity
                            );
                          }}
                        />
                        {formik.touched?.updateOtherClassesForEquity &&
                          formik.errors?.updateOtherClassesForEquity && (
                            <Error
                              text={formik.errors?.updateOtherClassesForEquity}
                            />
                          )}
                      </VStack>
                    </VStack>
                    <VStack className="">
                      <Label className="py-2 w-32 mr-2 text-sm font-medium whitespace-nowrap">
                        Allow Update Ratio
                      </Label>
                      <SwitchButton
                        className="text-sm font-medium items-center ml-2 mb-1"
                        value={formik.values.allowUpdateRatioForOthers}
                        label=""
                        onClick={() => {
                          formik.setFieldValue(
                            "allowUpdateRatioForOthers",
                            !formik.values.allowUpdateRatioForOthers
                          );
                        }}
                      />
                      {formik.touched?.allowUpdateRatioForOthers &&
                        formik.errors?.allowUpdateRatioForOthers && (
                          <Error
                            text={formik.errors?.allowUpdateRatioForOthers}
                          />
                        )}
                    </VStack>
                  </HStack>
                )}
              </HStack>
            </VStack>
          )}
        </VStack>

        <HStack className="justify-between gap-4 mt-8">
          <HStack>
            <SecondaryCTAButton
              type="reset"
              event-name="Discard Split"
              className="text-red-500 shadow-box"
              onClick={displayDiscardQuickRoundPopUp}
            >
              Discard
            </SecondaryCTAButton>
          </HStack>
          <PrimaryCTAButton
            event-name="Create Split"
            onClick={() => {
              formik.handleSubmit();
            }}
            className="min-w-max"
            type="submit"
          >
            Create Split
          </PrimaryCTAButton>
        </HStack>
      </Box>
    </VStack>
  );
}

export default QuickAddSplit;
