import { Form, Formik } from "formik";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Input, Label } from "../components/shared/InputField";
import { Select } from "../components/shared/Select";
import { SwitchButton } from "../components/shared/SwitchButton";
import { HStack, VStack, Error, Box } from "../components/utils";
import { useGetQuickRoundPreview } from "../queries/quickRound";
import {
  useGetSecurityConversion,
  useGetUpdatedConversionDetails,
  useUpdateSecurityConversionDetails,
} from "../queries/securities";
import { useAuthStore } from "../store";
import {
  SecurityConversionDetails,
  SecurityConversionUpdateDetails,
  SecurityTableDetail,
  UpdatedConversionDetails,
} from "../types/SecurityModel";
import { getCurrencyType } from "../utils/currencyFormatter";
import {
  ButtonSize,
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../pages/quickRound/CTAButtonComponents";
import MsgBanner from "../pages/modeling/MsgBanner";
import { PopupHeader } from "../components/Headings";
import { FormatNumberSpan } from "../utils/currencyRoboto";
import { IndividualRoundsModel } from "../types/AllTransactionModel";
import ConversionSlider from "./NonPricedRoundSlider";
import { useConversionStore } from "../store/conversionStore";

export function NonPricedRoundList({
  rounds,
  onPrimaryAction = () => {},
  onSecondaryAction = () => {},
  isProforma = false,
}: {
  rounds: IndividualRoundsModel[];
  onPrimaryAction: () => void;
  onSecondaryAction: () => void;
  isProforma?: boolean;
}) {
  const [securities, setSecurties] = useState<SecurityTableDetail[]>(
    rounds[0].nonPricedSecurityDetails ?? []
  );

  const conversionStore = useConversionStore();
  const currencyType = getCurrencyType();

  const companyId = useAuthStore.getState().companyId || "";

  const [eventId, setEventId] = useState(rounds[0].id ?? "");

  const { mutate: updateConversion, data: conversionDetails } =
    useUpdateSecurityConversionDetails();

  const { mutate: getUpdatedConversion } = useGetUpdatedConversionDetails();

  const { refetch: getQuickRoundPreview, data: previewData } =
    useGetQuickRoundPreview(eventId || "");

  const [updatedConversionDetails, setUpdatedConversionDetails] =
    useState<UpdatedConversionDetails>();

  const [securityDetails, setSecurityDetails] = useState(securities[0]);

  const handleSave = (values: SecurityConversionUpdateDetails) => {
    setLoadingTab(true);
    const conversionDetails: SecurityConversionUpdateDetails = {
      securityClassId: securityDetails?.id || "",
      conversionDate: values.conversionDate,
      conversionType: values.conversionType,
      newSharePrice: values.newSharePrice,
      updateConversionRatio: values.updateConversionRatio,
      conversionSecurityClass: values.conversionSecurityClass,
      convertibleSecurityClassId:
        securityClasses?.find(
          (securityClass) =>
            securityClass.securityClassName === values.conversionSecurityClass
        )?.securityClassId || "",
      useValuationAtConversion: values.useValuationAtConversion,
      valuation: values.valuation,
      isConvertingRound: true,
    };

    const conversionList = conversionStore.conversionDetails;
    setCalculated(false);
    if (rounds.length === conversionStore.index + 1) {
      if (conversionList.length === conversionStore.index) {
        conversionList.push(conversionDetails);
        conversionStore.setConversionDetails(conversionList);
      } else {
        conversionList[conversionStore.index] = conversionDetails;
        conversionStore.setConversionDetails(conversionList);
      }

      setLoadingTab(false);
      onPrimaryAction();
    } else if (
      conversionStore.conversionDetails.length >= conversionStore.index
    ) {
      conversionList[conversionStore.index] = conversionDetails;
      conversionStore.setConversionDetails(conversionList);
      onTabChanged();
      setLoadingTab(false);
    } else {
      conversionList.push(conversionDetails);
      conversionStore.setConversionDetails(conversionList);
      onTabChanged();
      setLoadingTab(false);
    }
  };

  const onTabChanged = () => {
    const ind = conversionStore.index + 1;
    conversionStore.setIndex(ind);
    const roundId = rounds[ind].id;
    conversionStore.setEventId(roundId);
    setEventId(roundId ?? "");
    setUpdatedConversionDetails(undefined);
  };

  const onPrevTabChanged = () => {
    setCalculated(false);
    const ind = conversionStore.index - 1;
    conversionStore.setIndex(ind);
    const roundId = rounds[ind].id;
    conversionStore.setEventId(roundId);
    setEventId(roundId ?? "");
    setUpdatedConversionDetails(undefined);
  };

  useEffect(() => {
    getQuickRoundPreview();
    const securityData = rounds.find(
      (r) => r.id === eventId
    )?.nonPricedSecurityDetails;

    if (securityData) {
      setSecurityDetails(securityData[0]);
      setSecurties(securityData || []);
      setSelectedSecurity(securityData[0].className);
    }
    securityConversionModelRefetch();
  }, [conversionDetails, eventId, conversionStore.index]);

  const {
    refetch: securityConversionModelRefetch,
    data: securityConversionModel,
  } = useGetSecurityConversion(securityDetails?.id || "");

  const securityTypes = securityConversionModel?.securityType.map(
    (security) => security.securityType
  );
  const [selectedSecurityType, setSelectedSecurityType] = useState<string>("");
  const [securityClasses, setSecurityClasses] = useState<
    {
      securityClassId: string;
      securityClassName: string;
    }[]
  >();

  useEffect(() => {
    const securityList =
      securityConversionModel?.securityType.find(
        (security) => security.securityType === selectedSecurityType
      )?.securityList || [];
    setSecurityClasses(securityList);
  }, [selectedSecurityType]);

  const initialValues = useMemo(() => {
    const conversionDetail =
      conversionStore.conversionDetails[conversionStore.index];

    return conversionDetail &&
      conversionStore.conversionDetails.length > conversionStore.index
      ? {
          companyId,
          conversionDate: conversionDetail.conversionDate ?? "",
          conversionType: conversionDetail.conversionType ?? "",
          updateConversionRatio: conversionDetail.updateConversionRatio ?? true,
          conversionSecurityClass:
            conversionDetail.conversionSecurityClass ?? "",
          convertibleSecurityClassId:
            conversionDetail.convertibleSecurityClassId ?? "",
          securityClassId: conversionDetail.securityClassId ?? "",
          shareholderId: "",
          useValuationAtConversion:
            conversionDetail.useValuationAtConversion ?? true,
          valuation: conversionDetail.valuation ?? 0,
          newSharePrice: conversionDetail.newSharePrice ?? 0,
          securityType: conversionDetail.securityType ?? "",
        }
      : {
          companyId: "",
          conversionDate: "",
          conversionType: "",
          updateConversionRatio: true,
          conversionSecurityClass: "",
          convertibleSecurityClassId: "",
          securityClassId: "",
          shareholderId: "",
          useValuationAtConversion: true,
          valuation: 0,
          newSharePrice: 0,
          securityType: "",
        };
  }, [conversionStore.index]);

  const [selectedSecurity, setSelectedSecurity] = useState(
    securityDetails.className
  );

  const securityConversionInputSchema = Yup.object({
    conversionDate: Yup.string().required().label("Conversion Date"),
    conversionType: Yup.string().required().label("Conversion Type"),

    useValuationAtConversion: Yup.boolean().label("useValuationAtConversion"),
    updateConversionRatio: Yup.boolean().label("Update ConversionRatio"),
    valuation: Yup.number().when("useValuationAtConversion", {
      is: (val: boolean) => val === true,
      then: Yup.number().required().label("Valuation"),
      otherwise: Yup.number().label("Valuation"),
    }),
    newSharePrice: Yup.number().when("useValuationAtConversion", {
      is: (val: boolean) => val === false,
      then: Yup.number().required().label("Share Price"),
      otherwise: Yup.number().label("Share Price"),
    }),
    conversionSecurityClass: Yup.string().when("updateConversionRatio", {
      is: (val: boolean) => val === false,
      then: Yup.string().required().label("Security Class"),
      otherwise: Yup.string().label("Security Class"),
    }),
    securityType: Yup.string().when("updateConversionRatio", {
      is: (val: boolean) => val === false,
      then: Yup.string().required().label("Security Type"),
      otherwise: Yup.string().label("Security Type"),
    }),
  });

  const [loading, setLoading] = useState(false);
  const [calculated, setCalculated] = useState(false);
  const [loadingTab, setLoadingTab] = useState(false);

  return (
    <div className="md:w-[720px] w-full bg-white rounded-md overflow-x-hidden">
      <MsgBanner message="This will convert all previous non priced round to priced round." />
      {rounds.length > 1 && (
        <HStack className="items-center justify-center w-full px-8 pt-2 mx-auto font-semibold bg-white border border-gray-100 rounded-md">
          <ConversionSlider
            rounds={rounds ?? []}
            onChangeRange={(countNumber: string) => {
              setEventId(countNumber);
            }}
          />
        </HStack>
      )}
      <PopupHeader
        text={" Non Priced Round Conversion"}
        subText={`Round Name : ${
          rounds[conversionStore.index].name
        }  |   Security Name : ${securityDetails.type}`}
        onClick={() => onSecondaryAction()}
      />
      <VStack className="p-4 font-medium text-left">
        {securityConversionModel && (
          <div>
            <div className="hidden md:block">
              <AddSecurityConversionDetailCards
                securityConversion={securityConversionModel}
                currencyType={currencyType}
              />
            </div>
            <div className="block p-2 border border-gray-400 rounded-md md:hidden shadow-box">
              <ConversionDetailsMobileCard
                value={`1:${securityConversionModel.originalConversionRatio.toLocaleString(
                  currencyType
                )}`}
                label="Original CR"
              />

              <ConversionDetailsMobileCard
                value={securityConversionModel.baseValuation}
                label="Base Valuation"
                currencyType={currencyType}
              />

              <ConversionDetailsMobileCard
                value={securityConversionModel.sharePrice}
                label="Original Share Price"
                currencyType={currencyType}
              />

              <ConversionDetailsMobileCard
                value={securityConversionModel.valuationCap}
                label="Valuation Cap"
                currencyType={currencyType}
              />
            </div>
          </div>
        )}

        <Formik
          key="addQuickBuybackParticipant"
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={securityConversionInputSchema}
          onSubmit={(values) => {
            handleSave(values);
          }}
        >
          {(formik) => (
            <Form key="shareholder">
              <VStack className="w-full gap-4 px-4 py-4">
                <HStack className="flex-col gap-8 md:flex-row">
                  <VStack className="flex-1">
                    <Label>Security Class</Label>
                    <Select
                      placeholder="Select Security"
                      value={selectedSecurity}
                      options={securities.map((security) => security.className)}
                      onChange={(e) => {
                        const security = securities.find(
                          (security) => security.className === selectedSecurity
                        );
                        if (security) setSecurityDetails(security);
                      }}
                    />
                  </VStack>

                  <VStack className="flex-1">
                    <Label>Conversion Date</Label>
                    <Input
                      type="date"
                      placeholder="Eg:DD/MM/YYYY"
                      {...formik.getFieldProps("conversionDate")}
                    />
                    {formik.touched?.conversionDate &&
                      formik.errors?.conversionDate && (
                        <Error text={formik.errors?.conversionDate} />
                      )}
                  </VStack>
                </HStack>
                <VStack>
                  <HStack>
                    <div className="flex flex-row-reverse items-center text-sm font-medium text-gray-600 ">
                      <SwitchButton
                        className="items-center mr-2 text-sm font-medium"
                        value={formik.values.useValuationAtConversion}
                        label="Use Valuation At Conversion"
                        onClick={() => {
                          formik.setFieldValue(
                            "useValuationAtConversion",
                            !formik.values.useValuationAtConversion
                          );
                        }}
                      />
                    </div>
                  </HStack>

                  <HStack className="gap-4">
                    {formik.values.useValuationAtConversion && (
                      <VStack className="flex-1">
                        <Label>Valuation</Label>

                        <Input
                          type="number"
                          placeholder="Enter Valuation"
                          className={`${
                            !formik.values.useValuationAtConversion
                              ? "cursor-not-allowed"
                              : ""
                          }`}
                          {...formik.getFieldProps("valuation")}
                          disabled={!formik.values.useValuationAtConversion}
                        />
                        {formik.touched?.valuation &&
                          formik.errors?.valuation && (
                            <Error text={formik.errors?.valuation} />
                          )}
                      </VStack>
                    )}

                    {!formik.values.useValuationAtConversion && (
                      <VStack className="flex-1">
                        <Label>Share Price</Label>
                        <Input
                          type="number"
                          className={`${
                            formik.values.useValuationAtConversion
                              ? "cursor-not-allowed"
                              : ""
                          }`}
                          placeholder="Enter Share Price"
                          {...formik.getFieldProps("newSharePrice")}
                          disabled={formik.values.useValuationAtConversion}
                        />
                        {formik.touched?.newSharePrice &&
                          formik.errors?.newSharePrice && (
                            <Error text={formik.errors?.newSharePrice} />
                          )}
                      </VStack>
                    )}
                    <VStack className="flex-1 hidden md:block"></VStack>
                  </HStack>
                </VStack>
                <VStack>
                  <HStack>
                    <div className="flex flex-row-reverse items-center text-sm font-medium text-gray-600 ">
                      <SwitchButton
                        className="items-center mr-2 text-sm font-medium"
                        value={formik.values.updateConversionRatio}
                        label="Update Conversion Ratio"
                        onClick={() => {
                          formik.setFieldValue(
                            "updateConversionRatio",
                            !formik.values.updateConversionRatio
                          );
                        }}
                      />
                    </div>
                  </HStack>

                  {!formik.values.updateConversionRatio && (
                    <HStack className="flex-col gap-4 md:flex-row">
                      <VStack className="flex-1">
                        <Label>Security Type</Label>

                        <Select
                          options={securityTypes || []}
                          placeholder="Select Security Type"
                          className=""
                          {...formik.getFieldProps("securityType")}
                          onChange={(e) => {
                            setSelectedSecurityType(e.target.value);
                            formik.handleChange(e);
                          }}
                          disabled={formik.values.updateConversionRatio}
                        />
                        {formik.touched?.securityType &&
                          formik.errors?.securityType && (
                            <Error text={formik.errors?.securityType} />
                          )}
                      </VStack>

                      <VStack className="flex-1">
                        <Label>Security Class</Label>

                        <Select
                          options={
                            securityClasses?.map(
                              (securityClass) => securityClass.securityClassName
                            ) || []
                          }
                          placeholder="Select Security Class"
                          className="w-45"
                          {...formik.getFieldProps("conversionSecurityClass")}
                          disabled={formik.values.updateConversionRatio}
                        />
                        {formik.touched?.conversionSecurityClass &&
                          formik.errors?.conversionSecurityClass && (
                            <Error
                              text={formik.errors?.conversionSecurityClass}
                            />
                          )}
                      </VStack>
                    </HStack>
                  )}
                </VStack>
                <VStack>
                  <div className="justify-center">
                    {updatedConversionDetails && !loading && (
                      <UpdatedConversionDetailsCards
                        currencyType={currencyType}
                        updatedConversionDetails={updatedConversionDetails}
                      />
                    )}
                  </div>
                </VStack>

                <HStack className="justify-between mt-6">
                  <HStack className="gap-4">
                    <SecondaryCTAButton
                      buttonSize={ButtonSize.SMALL}
                      onClick={() =>
                        conversionStore.index === 0
                          ? onSecondaryAction()
                          : onPrevTabChanged()
                      }
                    >
                      {conversionStore.index === 0 ? "Cancel" : "Back"}
                    </SecondaryCTAButton>{" "}
                    <SecondaryCTAButton
                      buttonSize={ButtonSize.SMALL}
                      onClick={() => {
                        conversionStore.reset();
                      }}
                    >
                      Clear
                    </SecondaryCTAButton>
                  </HStack>
                  <HStack className="gap-4">
                    <SecondaryCTAButton
                      buttonSize={ButtonSize.SMALL}
                      loading={loading}
                      onClick={() => {
                        setLoading(true);
                        const conversionDetails: SecurityConversionUpdateDetails =
                          {
                            securityClassId: securityDetails?.id || "",
                            conversionDate: formik.values.conversionDate,
                            conversionType: formik.values.conversionType || "",
                            newSharePrice: formik.values.newSharePrice,
                            updateConversionRatio:
                              formik.values.updateConversionRatio,
                            conversionSecurityClass:
                              formik.values.conversionSecurityClass,
                            convertibleSecurityClassId:
                              securityClasses?.find(
                                (securityClass) =>
                                  securityClass.securityClassName ===
                                  formik.values.conversionSecurityClass
                              )?.securityClassId || "",
                            useValuationAtConversion:
                              formik.values.useValuationAtConversion,
                            valuation: formik.values.valuation,
                          };
                        getUpdatedConversion(conversionDetails, {
                          onSuccess: async (data) => {
                            setTimeout(() => {
                              setLoading(false);
                              setCalculated(true);
                            }, 500);

                            setUpdatedConversionDetails(data);
                          },

                          onError: (err: any) => {
                            setTimeout(() => {
                              setLoading(false);
                            }, 500);
                            setLoading(false);
                            toast(err.response.data.errorMessage, {
                              type: "error",
                              autoClose: 2000,
                            });
                          },
                        });
                      }}
                    >
                      Calculate
                    </SecondaryCTAButton>

                    <PrimaryCTAButton
                      disabled={!calculated}
                      buttonSize={ButtonSize.SMALL}
                      type="submit"
                      loading={loadingTab}
                    >
                      {conversionStore.index === rounds.length - 1
                        ? "Convert Round"
                        : "Next"}
                    </PrimaryCTAButton>
                  </HStack>
                </HStack>
              </VStack>
            </Form>
          )}
        </Formik>
      </VStack>
    </div>
  );
}

function ConversionDetailsCard({
  value,
  label,
  currencyType,
}: {
  value: any;
  label: string;
  currencyType?: string;
}) {
  return (
    <Box className="flex-wrap w-full px-2 py-4 mt-3 bg-white border border-gray-300 rounded-md shadow-box">
      <VStack>
        {label === "Original CR" ? (
          <div className="text-sm font-semibold text-center">{value}</div>
        ) : (
          <div className="text-sm font-semibold text-center">
            <FormatNumberSpan value={value ?? 0} format={currencyType} />
          </div>
        )}
        <div className="font-medium text-center text-xxs1 text-descriptionColor">
          {label}
        </div>
      </VStack>
    </Box>
  );
}

function ConversionDetailsMobileCard({
  value,
  label,
  currencyType,
}: {
  value: any;
  label: string;
  currencyType?: string;
}) {
  return (
    <HStack className="items-center justify-between">
      <div className="text-xs font-medium text-center text-descriptionColor">
        {label}
      </div>
      {label === "Original CR" ? (
        <div className="text-sm font-semibold text-center">{value}</div>
      ) : (
        <div className="text-sm font-semibold text-center">
          <FormatNumberSpan value={value ?? 0} format={currencyType} />
        </div>
      )}
    </HStack>
  );
}

function AddSecurityConversionDetailCards({
  securityConversion,
  currencyType,
}: {
  securityConversion: SecurityConversionDetails;
  currencyType: string;
}) {
  return (
    <Box className="border-b">
      <HStack className="justify-between gap-12 pb-8">
        <ConversionDetailsCard
          value={`1:${securityConversion.originalConversionRatio.toLocaleString(
            currencyType
          )}`}
          label="Original CR"
        />

        <ConversionDetailsCard
          value={securityConversion.baseValuation}
          label="Base Valuation"
          currencyType={currencyType}
        />

        <ConversionDetailsCard
          value={securityConversion.sharePrice}
          label="Original Share Price"
          currencyType={currencyType}
        />

        <ConversionDetailsCard
          value={securityConversion.valuationCap}
          label="Valuation Cap"
          currencyType={currencyType}
        />
      </HStack>
    </Box>
  );
}

function UpdatedConversionDetailsCards({
  currencyType,
  updatedConversionDetails,
}: {
  currencyType: string;
  updatedConversionDetails: any;
}) {
  return (
    <Box className="">
      <HStack className="flex-col px-3 m-4 md:flex-row">
        <ConversionDetailsCard
          value={`1:${(
            updatedConversionDetails.updatedConversionRatio ?? 0
          ).toLocaleString(currencyType)}`}
          label="Updated CR"
        />
        <ConversionDetailsCard
          value={updatedConversionDetails.conversionPPS}
          label="Conversion Share Price"
          currencyType={currencyType}
        />
      </HStack>
    </Box>
  );
}
