import { Icon } from "@iconify/react";
import { Center, HStack, VStack } from "../../components/utils";
import { _sum } from "../../utils/arrays";
import { getSecurityColor } from "../../utils/historicUtilities";
import { getCurrencySymbol } from "../../utils/currencyFormatter";

export type ShareType = {
  instrument: string;
  instrumentClass?: string;
  conversation?: number;
  par?: number;
  id: string;
  ratio?: number;
};

const ShareTypes = ({ shareType }: { shareType: ShareType[] }) => (
  <>
    {shareType && (
      <VStack
        className={`p-4 bg-white border border-borderColor shadow-box rounded-lg max-h-96`}
      >
        <VStack className="mb-0">
          <p className="font-medium text-lg text-gray-600 ">Instrument used</p>
          <p className="text-xs1 font-semi-medium text-gray-light pb-4 ">
            {shareType.length} instruments
          </p>
        </VStack>

        {shareType.map((row) => (
          <VStack
            key={row.instrument}
            className="justify-between p-2 flex-col overflow-auto"
          >
            <HStack>
              <Center
                className="w-6 h-6 p-1 items-center"
                style={{ backgroundColor: "#F3F6F9", borderRadius: "50%" }}
              >
                <Icon icon="mdi:octahedron" style={{ color: "gray" }} />
              </Center>
              <p
                className={`inline-flex px-2 ${getSecurityColor(
                  row.instrument
                )} text-xs font-semibold capitalize leading-5`}
              >
                {row.instrument}
              </p>
              <p className="capitalize text-xs1 font-medium text-gray-500">
                {" : "}
                {row.instrumentClass}
              </p>
            </HStack>
            <HStack className="ml-6 px-2 leading-5">
              {row.par && row.conversation && (
                <p className="capitalize text-xs1 font-semi-medium text-gray-light">
                  PAR : {getCurrencySymbol()} {row.par} | Conversion Ratio: 1:{" "}
                  {row.conversation}
                </p>
              )}
              {row.ratio && (
                <p className="capitalize text-xs1 font-semi-medium text-gray-light">
                  split ratio: 1:{row.ratio}
                </p>
              )}
            </HStack>
          </VStack>
        ))}
      </VStack>
    )}
  </>
);

export default ShareTypes;
