import { Dialog } from "@mui/material";
import { useEffect, useState } from "react";
import AlertDialog from "../../components/shared/AlertDialog";
import { Action, CTADropdown } from "../../components/shared/Dropdown";
import { HStack } from "../../components/utils";
import {
  QuickBuybackParticipantDetails,
  Shareholder,
  ShareholderInstrumentModel,
} from "../../types/quickRound";
import { useQuickBuybackParticipantDetailsStore } from "../../store/QuickBuyBackParticipantsStore";
import AddBuybackParticipantDetails from "../../modals/AddBuybackParticipantDetails";
import { formatDate, formatDisplayDate } from "../../utils/date";
import {
  getCurrencySymbol,
  getCurrencyType,
} from "../../utils/currencyFormatter";
import { EmptyTable } from "../quickRound/EmptyTableComponents";
import { BuybackTransaction } from "./RoundTransactionModel";
import TextToolTip from "../shareHolderRights/TextToolTip";
import { InstrumentClass } from "../../types/DashboardModel";
import { useShareholderInstrumentStore } from "../../store/shareholderInstrumentStore";

export default function QuickBuybackParticipantsDetails({
  readOnly = false,
  buyBackParticipantDetails,
  buyBackDate = formatDate(new Date().toUTCString()),
  instrumentClasses = [],
  shareholders = [],
}: {
  readOnly?: boolean;
  buyBackParticipantDetails?: BuybackTransaction[];
  buyBackDate?: string;
  instrumentClasses?: InstrumentClass[];
  shareholders?: Shareholder[];
}) {
  const currency = getCurrencyType();
  const currencySymbol = getCurrencySymbol();
  const buybackParticipantsStore = useQuickBuybackParticipantDetailsStore();
  useEffect(() => {
    if (buyBackParticipantDetails && buyBackParticipantDetails.length > 0) {
      buybackParticipantsStore.setAllBuyBackParticipantDetails(
        buyBackParticipantDetails.map((buybackParticipant) => ({
          ...buybackParticipant,
          name:
            shareholders.find(
              (instrument) =>
                instrument.id === buybackParticipant.companyInvestorId
            )?.name || "",
          instrumentClassName:
            instrumentClasses.find(
              (instrument) =>
                instrument.id === buybackParticipant.instrumentClassId
            )?.name || "",
        }))
      );
    }
  }, [buyBackParticipantDetails]);

  const [dialog, setDialog] = useState<{
    open: boolean;
    message?: string;
    data?: QuickBuybackParticipantDetails;
    index?: number;
    mode?: "Edit" | "Delete" | "Add";
  }>({
    open: false,
  });

  const handleAction = (
    action: Action,
    buybackParticipant: QuickBuybackParticipantDetails,
    index: number
  ) => {
    if (action.name === "Edit" && !action.disabled) {
      buybackParticipantsStore.removeBuyBackParticipantDetails(
        buybackParticipant
      );
      setDialog({
        open: true,
        mode: "Edit",
        data: buybackParticipant,
        index: index || 0,
      });
    }

    if (action.name === "Delete" && !action.disabled) {
      setDialog({
        open: true,
        mode: "Delete",
        data: buybackParticipant,
        message: `Do you want to delete the buyback transaction?`,
        index: index || 0,
      });
    }
  };

  return (
    <div className="px-7  border border-borderColor  mx-4 shadow-box bg-white rounded-md">
      <HStack className="text-lg text-left font-medium border-b py-4 justify-between">
        <p className="mr-12 font-medium text-[#464E5F] text-lg">
          Buyback Transaction
        </p>
      </HStack>
      <Dialog open={dialog.open} maxWidth="md">
        {dialog.mode === "Delete" ? (
          <AlertDialog
            onClose={() => setDialog({ open: false })}
            open={dialog.open}
            message={dialog.message}
            onPrimaryAction={() => {
              if (dialog?.data) {
                buybackParticipantsStore.removeBuyBackParticipantDetails(
                  dialog?.data as QuickBuybackParticipantDetails
                );
              }
              setDialog({ open: false });
            }}
            onSecondaryAction={() => setDialog({ open: false })}
          />
        ) : dialog.mode === "Add" ? (
          <AddBuybackParticipantDetails
            mode="Add"
            onPrimaryAction={() => {
              setDialog({ open: false });
            }}
            onSecondaryAction={() => {
              setDialog({ open: false });
            }}
          />
        ) : dialog.mode === "Edit" ? (
          <AddBuybackParticipantDetails
            mode="Edit"
            initialValue={dialog.data}
            onPrimaryAction={() => {
              setDialog({ open: false });
            }}
            onSecondaryAction={() => {
              setDialog({ open: false });
            }}
          />
        ) : (
          <div></div>
        )}
      </Dialog>
      <div className="inline-block w-full py-2 align-middle mb-4">
        <div className="overflow-x-auto md:rounded-lg mb-4">
          <div>
            <table className="w-full bg-white">
              <thead>
                <tr className="text-[#464E5F]">
                  <th
                    scope="col"
                    className="py-3.5 pr-3 text-left text-sm font-semibold hover:cursor-pointer"
                  >
                    Shareholder
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold  hover:cursor-pointer"
                  >
                    No.of Shares
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold hover:cursor-pointer"
                  >
                    Amount {` (${currencySymbol})`}
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold hover:cursor-pointer"
                  >
                    Security Class
                  </th>

                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold  hover:cursor-pointer"
                  >
                    Date
                  </th>
                </tr>
              </thead>
              <tbody>
                {buybackParticipantsStore.buybackParticipantDetails.map(
                  (buybackParticipant, index) => (
                    <tr
                      key={`${buybackParticipant.name}${buybackParticipant.instrumentClassName}}`}
                      className="border-t border-dashed hover:bg-slate-50"
                    >
                      <td className="py-4 pr-3 text-sm">
                        <TextToolTip
                          text={buybackParticipant.name}
                          className={"text-gray-900"}
                        />
                      </td>
                      <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                        {buybackParticipant.numberOfShares.toLocaleString(
                          currency
                        )}
                      </td>
                      <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                        {buybackParticipant.amount.toLocaleString(currency)}
                      </td>
                      <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                        {buybackParticipant.instrumentClassName || ""}
                      </td>

                      <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                        {formatDisplayDate(buyBackDate)}
                      </td>
                      <td className="px-2 py-4 align-top">
                        {!readOnly && (
                          <CTADropdown
                            actions={[
                              {
                                name: "Edit",
                                disabled: false,
                              },
                              {
                                name: "Delete",
                                disabled: false,
                              },
                            ]}
                            onAction={(action) =>
                              handleAction(action, buybackParticipant, index)
                            }
                          />
                        )}
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
            {buybackParticipantsStore.buybackParticipantDetails.length ===
              0 && (
              <EmptyTable
                header="No Buyback Available"
                subHeader="Get started by adding a buyback"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
