import { useState } from "react";
import Box from "@mui/material/Box";
import { Icon } from "@iconify/react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { format } from "date-fns";
import { exportIndividualSecuritySummaryReport } from "../../api/Report";
import {
  HStack,
  VStack,
  useGetCompanyName,
  Error,
} from "../../components/utils";
import { downloadExcel } from "../../utils/DownloadFile";
import { PopupHeader } from "../../components/Headings";
import { Label } from "../../components/shared/InputField";
import {
  ButtonSize,
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../quickRound/CTAButtonComponents";
import { SecurityTypeList } from "../../constants/ShareholderSecurityTypes";
import { Select } from "../../components/shared/Select";

type ExportSheetDetailsProps = {
  onPrimaryAction: () => void;
  onSecondaryAction: () => void;
  reportName: string;
  companyId?: string;
};

type ExportSecurityTypeSubmit = {
  type: string;
};

const ExportSecurityTypeReport = ({
  onPrimaryAction = () => {},
  onSecondaryAction = () => {},
  reportName,
}: ExportSheetDetailsProps) => {
  const companyName = useGetCompanyName();
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (values: ExportSecurityTypeSubmit) => {
    setLoading(true);
    exportIndividualSecuritySummaryReport(values.type).then((res) => {
      const fileName = `Security Details Report_${companyName}`;
      downloadExcel(res, fileName);
    });
    setLoading(false);
    onPrimaryAction();
  };

  const initialValues = {
    type: "",
  };

  const excelSheetDetailsValidation = Yup.object({
    type: Yup.string().required().label("Security Type"),
  });

  return (
    <div>
      <PopupHeader
        text={reportName}
        subText={
          "Please select security for which the report has to be generated"
        }
        onClick={() => onSecondaryAction()}
      />
      <VStack className="w-full px-8 py-7 gap-9">
        <Formik
          initialValues={initialValues}
          key="exportSheetDetails"
          onSubmit={(values) => {
            handleSubmit(values);
          }}
          validationSchema={excelSheetDetailsValidation}
        >
          {(formik) => (
            <div>
              <Form key="exportSheetDetails">
                <VStack className="w-full gap-4">
                  <VStack>
                    <Label>Security</Label>
                    <Select
                      options={SecurityTypeList}
                      className="w-full"
                      {...formik.getFieldProps(`type`)}
                    />
                    {formik.touched.type && formik.errors.type && (
                      <Error text={formik.errors.type} />
                    )}
                  </VStack>
                </VStack>
                <HStack className="justify-between mt-10 h-8">
                  <SecondaryCTAButton
                    event-name="Cancel Security Type Report"
                    buttonSize={ButtonSize.SMALL}
                    onClick={() => onSecondaryAction()}
                  >
                    Cancel
                  </SecondaryCTAButton>
                  <PrimaryCTAButton
                    event-name="Submit Security Type Report"
                    buttonSize={ButtonSize.SMALL}
                    type="submit"
                  >
                    {!loading && <span>Submit</span>}

                    {loading && (
                      <HStack>
                        <p
                          className="indicator-progress"
                          style={{ display: "block" }}
                        >
                          Please wait...
                        </p>
                        <Icon
                          className="animate-spin"
                          icon="lucide:loader-2"
                          width={18}
                        />
                      </HStack>
                    )}
                  </PrimaryCTAButton>
                </HStack>
              </Form>
            </div>
          )}
        </Formik>
      </VStack>
    </div>
  );
};

export default ExportSecurityTypeReport;
