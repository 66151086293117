import { Form, Formik } from "formik";
import * as Yup from "yup";
import { v4 as uuidv4 } from "uuid";
import { Input, Label } from "../../components/shared/InputField";
import { Select } from "../../components/shared/Select";
import { HStack, VStack, Error } from "../../components/utils";
import { useShareholderInstrumentStore } from "../../store/shareholderInstrumentStore";
import {
  AddTransactionModalType,
  PrimaryTransactionDataModel,
} from "../../types/quickRound";
import { getCurrencySymbol } from "../../utils/currencyFormatter";
import {
  ButtonSize,
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "./CTAButtonComponents";

type AddTransactionModalProps = {
  initialValue?: PrimaryTransactionDataModel;
  onPrimaryAction: (primary: PrimaryTransactionDataModel) => void;
  onSecondaryAction: () => void;
  mode: "Add" | "Edit";
};

const AddTransactionModal = ({
  initialValue,
  onPrimaryAction = () => {},
  onSecondaryAction = () => {},
  mode,
}: AddTransactionModalProps) => {
  const currencySymbol = getCurrencySymbol();
  const shareholderInstruments = useShareholderInstrumentStore();
  const shareHolders: string[] = shareholderInstruments.shareholders.map(
    (shareHolderDetail) => shareHolderDetail.name
  );
  const instruments: string[] = shareholderInstruments.instrumentClasses.map(
    (instrument) => instrument.name
  );

  const initialShareHolderValues: AddTransactionModalType = {
    uuid: uuidv4(),
    name: initialValue?.name || "",
    security: initialValue?.security || "",
    sharePrice: initialValue?.sharePrice || 0,
    numberOfShares: initialValue?.numberOfShares || 0,
    amountInvested: initialValue?.amountInvested || 0,
  };

  const validationShareHolderSchema = Yup.object().shape({
    name: Yup.string().required().label("Name"),
    security: Yup.string().required().label("Security"),
    sharePrice: Yup.number().positive().required().label("Share Price"),
    numberOfShares: Yup.number()
      .integer()
      .min(1)
      .min(1)
      .required()
      .label("No.of Shares"),
    amountInvested: Yup.number().positive().required().label("Amount Invested"),
  });

  const handleSubmit = (values: AddTransactionModalType) => {
    const investorDetail = shareholderInstruments.shareholders.find(
      (shareHolderDetail) => shareHolderDetail.name === values.name
    );
    const instrumentDetail = shareholderInstruments.instrumentClasses.find(
      (instrumentDetail) => instrumentDetail.name === values.security
    );
    const transaction: PrimaryTransactionDataModel = {
      uuid: uuidv4(),
      name: values.name,
      investorType: investorDetail?.type,
      security: values.security,
      image: "",
      sharePrice: values.sharePrice,
      instrumentClassId: instrumentDetail?.id,
      par: instrumentDetail?.parValue,
      conversionRatio: `1: ${instrumentDetail?.conversionRatio}`,
      investorId: investorDetail?.id,
      numberOfShares: values.numberOfShares,
      amountInvested: values.amountInvested,
    };
    onPrimaryAction(transaction);
  };

  return (
    <Formik
      key="shareHolder"
      enableReinitialize={true}
      initialValues={initialShareHolderValues}
      onSubmit={(values) => {
        handleSubmit(values);
      }}
      validationSchema={validationShareHolderSchema}
    >
      {(formik) => (
        <Form key="shareholder">
          <div className="w-full bg-white rounded-md px-2 border">
            <HStack className="text-lg text-left font-medium border-b py-4">
              <h6 className="flex-1"> {mode} Shareholder</h6>
            </HStack>
            <VStack className="w-full  px-2 py-4 gap-6">
              <VStack className="gap-8">
                <HStack className="gap-4 md:flex-row flex-col">
                  <div className="flex-1">
                    <Label className="text-sm font-normal">
                      Shareholder Name
                    </Label>
                    <Select
                      options={shareHolders.filter(
                        (shareHolder) => shareHolder !== "ESOP pool"
                      )}
                      {...formik.getFieldProps("name")}
                    />
                    {formik.touched?.name && formik.errors?.name && (
                      <Error text={formik.errors?.name} />
                    )}
                  </div>
                  <div className="flex-1">
                    <Label className="text-sm font-normal">Security</Label>
                    <Select
                      options={instruments}
                      {...formik.getFieldProps("security")}
                    />
                    {formik.touched.security && formik.errors?.security && (
                      <Error text={formik.errors?.security} />
                    )}
                  </div>
                </HStack>
                <HStack className="gap-4  md:flex-row flex-col">
                  <div className="flex-1">
                    <Label className="text-sm font-normal">No.of Shares</Label>
                    <Input
                      type="number"
                      placeholder="Enter No.of Shares"
                      onKeyUpCapture={() => {
                        formik.setFieldValue(
                          "amountInvested",
                          (formik?.values?.sharePrice || 0) *
                            (formik?.values?.numberOfShares || 0)
                        );
                      }}
                      {...formik.getFieldProps("numberOfShares")}
                    />
                    {formik.touched?.numberOfShares &&
                      formik.errors?.numberOfShares && (
                        <Error text={formik.errors?.numberOfShares} />
                      )}
                  </div>
                  <div className="flex-1">
                    <Label className="text-sm font-normal">
                      Share Price ({currencySymbol})
                    </Label>
                    <Input
                      type="number"
                      placeholder="Enter Share Price"
                      onKeyUpCapture={() => {
                        formik.setFieldValue(
                          "amountInvested",
                          (formik?.values?.sharePrice || 0) *
                            (formik?.values?.numberOfShares || 0)
                        );
                      }}
                      {...formik.getFieldProps("sharePrice")}
                    />
                    {formik.touched?.sharePrice &&
                      formik.errors?.sharePrice && (
                        <Error text={formik.errors?.sharePrice} />
                      )}
                  </div>
                </HStack>
                <HStack className="gap-4  md:flex-row flex-col">
                  <div className="flex-1">
                    <Label className="text-sm font-normal">
                      Amount Invested ({currencySymbol})
                    </Label>
                    <Input
                      type="number"
                      {...formik.getFieldProps("amountInvested")}
                    />
                    {formik.touched?.amountInvested &&
                      formik.errors?.amountInvested && (
                        <Error text={formik.errors?.amountInvested} />
                      )}
                  </div>
                  <div className="flex-1"></div>
                </HStack>
              </VStack>

              <HStack className="justify-between h-8">
                <SecondaryCTAButton
                  event-name="Cancel Primary Transaction Modal"
                  buttonSize={ButtonSize.SMALL}
                  onClick={() => {
                    if (mode === "Edit") {
                      handleSubmit({
                        uuid: uuidv4(),
                        name: initialValue?.name || "",
                        security: initialValue?.security || "",
                        sharePrice: initialValue?.sharePrice || 0,
                        numberOfShares: initialValue?.numberOfShares || 0,
                        amountInvested: initialValue?.amountInvested || 0,
                      });
                    } else {
                      onSecondaryAction();
                    }
                  }}
                >
                  Cancel
                </SecondaryCTAButton>
                <PrimaryCTAButton
                  event-name="Save Primary Transaction Modal"
                  buttonSize={ButtonSize.SMALL}
                  type="submit"
                >
                  Save
                </PrimaryCTAButton>
              </HStack>
            </VStack>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddTransactionModal;
