import { useFormikContext } from "formik";
import { Disclosure } from "@headlessui/react";
import { useEffect, useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { SwitchButton } from "../../components/shared/SwitchButton";
import { HStack, VStack, Error } from "../../components/utils";
import { Input, Label } from "../../components/shared/InputField";
import { EditSecurityModel } from "../../types/SecurityModel";
import { SecurityTextDetail } from "../../constants/SecurityConstantContent";
import SecurityDescriptionText from "./SecurityDescriptionText";
import { classNames } from "../../utils/string";
import { ShowNumberInWords } from "../../components/shared/UiElement";
import { getCurrencyType } from "../../utils/currencyFormatter";
import {
  convertToNumber,
  filterOnlyNumbers,
  numberWithCommas,
} from "../../utils/numUtils";

const AddLiquidationDetails = ({
  keepOpen,
  viewOnly,
  currencySymbol,
}: {
  keepOpen: boolean;
  viewOnly: boolean;
  currencySymbol: string;
}) => {
  const formikk = useFormikContext<EditSecurityModel>();
  const [
    onHoverLiquidationParticipationCap,
    setOnHoverLiquidationParticipationCap,
  ] = useState(false);
  const [
    formatLiqidationParticipationCap,
    setFormatLiqidationParticipationCap,
  ] = useState("");

  useEffect(() => {
    if (formikk.values.liquidationParticipationCap) {
      setFormatLiqidationParticipationCap(
        `${formikk.values.liquidationParticipationCap}`
      );
    }
  }, [formikk.values.liquidationParticipationCap]);

  const currencyType = getCurrencyType();
  const { values, errors, touched, getFieldProps, setFieldValue } = formikk;
  return (
    <Disclosure
      as="div"
      key="Liquidation Preference"
      className=""
      defaultOpen={keepOpen}
    >
      {({ open }) => (
        <>
          <dt
            className={`text-lg border ${
              open ? "border-b-0 rounded-t-md" : "rounded-md"
            } p-4`}
          >
            <Disclosure.Button className="flex items-start justify-between w-full text-left text-gray-400">
              <span className="text-base font-medium text-slate-900">
                Liquidation Preference
              </span>
              <span className="flex items-center ml-6 h-7">
                <ChevronDownIcon
                  className={classNames(
                    open ? "-rotate-180" : "rotate-0",
                    "h-6 w-6 transform"
                  )}
                  aria-hidden="true"
                />
              </span>
            </Disclosure.Button>
          </dt>
          <Disclosure.Panel as="dd" className="p-4 border rounded-b-md">
            <VStack className="gap-8">
              <SecurityDescriptionText
                description={SecurityTextDetail.Liquidation}
              />
              <HStack className="flex-col gap-4 md:flex-row ">
                <HStack className="flex-row-reverse items-center justify-end flex-1 gap-4 text-sm font-medium text-slate-900">
                  <SwitchButton
                    value={values.liquidationIsParriPassu || false}
                    label="Pari Passu"
                    onClick={() => {
                      if (viewOnly) return;
                      setFieldValue(
                        `liquidationIsParriPassu`,
                        !values.liquidationIsParriPassu
                      );
                    }}
                  />
                </HStack>
                <HStack className="flex-row-reverse items-center justify-end flex-1 gap-4 text-sm font-medium text-slate-900">
                  <SwitchButton
                    value={values.liquidationIsParticipant || false}
                    label="Participation"
                    onClick={() => {
                      if (viewOnly) return;
                      setFieldValue(
                        `liquidationIsParticipant`,
                        !values.liquidationIsParticipant
                      );
                    }}
                  />
                </HStack>
              </HStack>
              <HStack className="flex-col justify-between gap-4 md:flex-row">
                <VStack className="items-start flex-1">
                  <Label className="mr-4 text-sm font-medium text-slate-900 whitespace-nowrap">
                    Rank
                  </Label>
                  <Input
                    type="number"
                    placeholder="Enter Rank"
                    {...getFieldProps("liquidationRank")}
                    disabled={viewOnly}
                  />
                  {touched?.liquidationRank && errors?.liquidationRank && (
                    <Error text={errors?.liquidationRank} />
                  )}
                </VStack>
                <VStack className="items-start flex-1">
                  <Label className="mr-4 text-sm font-medium text-slate-900 whitespace-nowrap">
                    Investment Multiple
                  </Label>
                  <Input
                    type="number"
                    placeholder="Enter Investment Multiple"
                    {...getFieldProps("liquidationInvestmentMultiple")}
                    disabled={viewOnly}
                  />
                  {touched?.liquidationInvestmentMultiple &&
                    errors?.liquidationInvestmentMultiple && (
                      <Error text={errors?.liquidationInvestmentMultiple} />
                    )}
                </VStack>
                <VStack className="items-start flex-1">
                  <Label className="mr-4 text-sm font-medium text-slate-900 whitespace-nowrap">
                    Liquidation Participation Cap ({currencySymbol})
                  </Label>
                  <Input
                    placeholder="Enter Participation Cap"
                    type="text"
                    onMouseEnter={() => {
                      setOnHoverLiquidationParticipationCap(true);
                    }}
                    onMouseLeave={() => {
                      setOnHoverLiquidationParticipationCap(false);
                    }}
                    onChange={(e: any) => {
                      const filteredValue = filterOnlyNumbers(e.target.value);
                      setFormatLiqidationParticipationCap(filteredValue);
                      const values = convertToNumber(filteredValue);

                      formikk.setFieldValue(
                        "liquidationParticipationCap",
                        values
                      );
                      formikk.handleChange("liquidationParticipationCap");
                    }}
                    value={numberWithCommas(
                      formatLiqidationParticipationCap ??
                        `${formikk.values.liquidationParticipationCap}`,
                      currencyType
                    )}
                    disabled={viewOnly}
                  />
                  {onHoverLiquidationParticipationCap && (
                    <ShowNumberInWords
                      value={formikk.values.liquidationParticipationCap!}
                      width={38}
                      currency={currencyType}
                    />
                  )}

                  {touched?.liquidationParticipationCap &&
                    errors?.liquidationParticipationCap && (
                      <Error text={errors?.liquidationParticipationCap} />
                    )}
                </VStack>
              </HStack>
            </VStack>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default AddLiquidationDetails;
