import _ from "lodash";
import { Form, Formik, useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useState } from "react";
import { HStack, Error, VStack } from "../components/utils";
import {
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../pages/quickRound/CTAButtonComponents";
import { Input, Label } from "../components/shared/InputField";
import {
  useUpdateCertificateFdb,
  useUpdateTransaction,
} from "../queries/shareholders";
import {
  getCompanyCurrency,
  getCurrency,
  getCurrencySymbol,
  getCurrencyType,
  getKeyByValue,
} from "../utils/currencyFormatter";
import { currencies, currencyTypes } from "../constants/CompanyCurrencyType";
import { Select } from "../components/shared/Select";
import { getRoundCurrencySymbol } from "../utils/currencyRoboto";
import { UpdateTransactionInput } from "../types/Shareholder";

type UpdateCurrencyType = {
  transactionCurrency: string;
  amountInCompanyCurrency: number;
  amountInTransactionCurrency: number;
  exchangeRate: number;
  exchangeRateForRound: number;
  roundCurrency: string;
  recordId: string;
  onPrimaryAction: () => void;
  onSecondaryAction: () => void;
};

export function UpdateInvestmentCurrency({
  transactionCurrency,
  amountInCompanyCurrency,
  amountInTransactionCurrency,
  exchangeRate,
  exchangeRateForRound,
  recordId,
  roundCurrency,
  onPrimaryAction = () => {},
  onSecondaryAction = () => {},
}: UpdateCurrencyType) {
  const { mutate: updateTransaction } = useUpdateTransaction();
  const [transactionAmount, setTransactionAmount] = useState<number>(
    amountInTransactionCurrency || 1
  );

  const updateTransactionSchema = Yup.object().shape({
    transactionCurrency: Yup.string().required().label("Investment Currency"),
    currenctExchangeRate: Yup.number()
      .required()
      .label("Exchange Rate")
      .moreThan(0),
  });

  const invCurrency = getCurrency(transactionCurrency);
  const roundCurrencySymbol = getRoundCurrencySymbol(
    roundCurrency || "INR - ₹"
  );
  const roundCurrencyFormat = getCurrency(roundCurrency);

  const updateTransactionInitialValues = {
    transactionCurrency: transactionCurrency || "INR - ₹",
    amountInCompanyCurrency: amountInCompanyCurrency || 0,
    amountInTransactionCurrency: amountInTransactionCurrency || 0,
    currenctExchangeRate: exchangeRate,
  };

  const formik = useFormik({
    initialValues: updateTransactionInitialValues,
    enableReinitialize: true,
    validationSchema: updateTransactionSchema,
    onSubmit: (values) => {
      const finalAmountInvestedInCurrency =
        values.currenctExchangeRate &&
        roundCurrency &&
        values?.transactionCurrency !== roundCurrency
          ? (amountInTransactionCurrency * exchangeRate || 1) /
            Number(values.currenctExchangeRate)
          : roundCurrency && values?.transactionCurrency === roundCurrency
          ? amountInTransactionCurrency * exchangeRate || 1
          : values?.transactionCurrency === companyCurrency && !roundCurrency
          ? amountInCompanyCurrency
          : values?.transactionCurrency !== companyCurrency &&
            !roundCurrency &&
            values.currenctExchangeRate
          ? amountInCompanyCurrency / Number(values.currenctExchangeRate)
          : amountInTransactionCurrency;

      const finalExchangeRate =
        (roundCurrency && values?.transactionCurrency !== roundCurrency) ||
        (!roundCurrency && values?.transactionCurrency !== companyCurrency)
          ? values.currenctExchangeRate
          : 1;

      const updateTransactionData: UpdateTransactionInput = {
        investmentRecordId: recordId,
        currency: values.transactionCurrency,
        exchangeRate: finalExchangeRate,
        amountInvestedInCurrency: finalAmountInvestedInCurrency,
      };
      updateTransaction(updateTransactionData, {
        onSuccess: (data) => {
          toast("Transaction Updated Successfully", {
            type: "success",
            autoClose: 2000,
          });
          onPrimaryAction();
        },
        onError: (err: any) => {
          toast(err.response.data.errorMessage, {
            type: "error",
            autoClose: 2000,
          });
        },
      });
    },
  });

  const currencyType = getCurrencyType();
  const currencySymbol = getCurrencySymbol();
  const companyCurrency = getCompanyCurrency();
  const invCurrencySymbol = getRoundCurrencySymbol(
    formik.values.transactionCurrency || transactionCurrency
  );

  return (
    <div className="w-150 bg-white rounded-md border-2 p-5">
      <VStack className="text-lg font-medium border-b py-2 px-2">
        <HStack className="justify-between">
          <p className="font-semibold text-lg mb-3">Update Transaction</p>
          <span onClick={() => onSecondaryAction()} className="cursor-pointer">
            X
          </span>
        </HStack>
      </VStack>

      <VStack className="text-lg font-medium border-b py-2 px-2">
        <HStack className="items-center m-2 grid grid-cols-2 gap-2">
          <Label className=" whitespace-nowrap">
            Amount in Company Currency ({currencySymbol})
          </Label>
          <VStack className="ml-5 w-44">
            {amountInCompanyCurrency?.toLocaleString(currencyType)}
          </VStack>
        </HStack>

        {roundCurrency && roundCurrency !== companyCurrency && (
          <HStack className="items-center m-2 grid grid-cols-2 gap-2">
            <Label className=" whitespace-nowrap">
              Amount in Round Currency ({roundCurrencySymbol})
            </Label>
            <HStack className="ml-5 w-44">
              {(
                amountInTransactionCurrency * exchangeRate || 1
              )?.toLocaleString(roundCurrencyFormat)}

              <div className=" ml-1 flex justify-end text-[9px] text-gray-500 my-0.5">
                (1{" "}
                {getKeyByValue(
                  currencyTypes,
                  getCurrency(roundCurrency) || currencyType
                )}{" "}
                = {exchangeRateForRound}{" "}
                {getKeyByValue(
                  currencyTypes,
                  getCurrency(companyCurrency) || currencyType
                )}
                )
              </div>
            </HStack>
          </HStack>
        )}

        <HStack className="items-center m-2 grid grid-cols-2 gap-2">
          <Label className=" whitespace-nowrap">
            Amount in Transaction Currency ({invCurrencySymbol})
          </Label>
          <VStack className="ml-5 w-44 whitespace-nowrap">
            {formik.values.currenctExchangeRate &&
            roundCurrency &&
            formik.values?.transactionCurrency !== roundCurrency
              ? (
                  (amountInTransactionCurrency * exchangeRate || 1) /
                  Number(formik.values.currenctExchangeRate)
                )?.toLocaleString(invCurrency)
              : roundCurrency &&
                formik.values?.transactionCurrency === roundCurrency
              ? (
                  amountInTransactionCurrency * exchangeRate || 1
                )?.toLocaleString(roundCurrencyFormat)
              : formik.values?.transactionCurrency === companyCurrency &&
                !roundCurrency
              ? amountInCompanyCurrency?.toLocaleString(invCurrency)
              : formik.values?.transactionCurrency !== companyCurrency &&
                formik.values.currenctExchangeRate &&
                !roundCurrency
              ? (
                  amountInCompanyCurrency /
                  Number(formik.values.currenctExchangeRate)
                )?.toLocaleString(invCurrency)
              : (formik.values?.transactionCurrency !== companyCurrency &&
                  !formik.values.currenctExchangeRate &&
                  !roundCurrency) ||
                (!formik.values.currenctExchangeRate &&
                  companyCurrency === roundCurrency)
              ? "-"
              : amountInTransactionCurrency?.toLocaleString(invCurrency)}
          </VStack>
        </HStack>

        <HStack className="items-center m-2 grid grid-cols-2 gap-2">
          <Label className=" whitespace-nowrap ">
            Select Transaction Currency
          </Label>
          <VStack className="ml-5 w-44">
            <Select
              className="w-70"
              options={currencies}
              {...formik.getFieldProps("transactionCurrency")}
            />
            {formik.touched?.transactionCurrency &&
              formik.errors?.transactionCurrency && (
                <Error text={formik.errors?.transactionCurrency} />
              )}
          </VStack>
        </HStack>

        {((roundCurrency &&
          formik.values?.transactionCurrency !== roundCurrency) ||
          (formik.values?.transactionCurrency !== companyCurrency &&
            !roundCurrency)) &&
          formik.values.transactionCurrency && (
            <HStack className="items-center m-2 grid grid-cols-2 gap-2">
              <Label className=" whitespace-nowrap w-72">Exchange Rate</Label>
              <VStack className="ml-5 w-44">
                <Input
                  type="number"
                  placeholder="Enter Exchange Rate"
                  className="w-44"
                  {...formik.getFieldProps("currenctExchangeRate")}
                />

                {formik.errors?.currenctExchangeRate && (
                  <Error text={formik.errors?.currenctExchangeRate} />
                )}

                {!formik.errors?.currenctExchangeRate && (
                  <div className=" flex justify-end text-[9px] text-gray-500 my-0.5">
                    1{" "}
                    {getKeyByValue(
                      currencyTypes,
                      getCurrency(formik.values.transactionCurrency) ||
                        currencyType
                    )}{" "}
                    = {formik.values.currenctExchangeRate}{" "}
                    {getKeyByValue(
                      currencyTypes,
                      getCurrency(roundCurrency) || currencyType
                    )}
                  </div>
                )}
              </VStack>
            </HStack>
          )}
        <HStack className="justify-between mt-10">
          <SecondaryCTAButton
            event-name="Back Update Investment Currency"
            onClick={() => {
              onSecondaryAction();
            }}
          >
            Back
          </SecondaryCTAButton>
          <PrimaryCTAButton
            event-name="Save Update Investment Currency"
            type="submit"
            onClick={() => {
              formik.handleSubmit();
            }}
          >
            Save
          </PrimaryCTAButton>
        </HStack>
      </VStack>
    </div>
  );
}
