import { HStack, VStack } from "../../components/utils";
import { ShareholderTypeModel } from "./DragAndDrop";
import TextToolTip from "./TextToolTip";

function DNDCard({
  shareholderDetail,
  isDragging,
  showDetail = false,
}: {
  shareholderDetail: ShareholderTypeModel;
  isDragging: boolean;
  showDetail?: boolean;
}) {
  return (
    <HStack
      className={`justify-between px-2 w-48 py-1 shadow-box ${
        isDragging ? "bg-[#263B4A]" : "bg-[#FFFFFF]"
      }`}
    >
      <VStack className="justify-start gap-0 w-full">
        <TextToolTip
          className={`${
            !isDragging ? "text-stone-600" : "text-white"
          } text-sm font-medium`}
          text={shareholderDetail.name}
        />
        {showDetail && (
          <HStack className="justify-between w-full">
            <p
              className={`${
                !isDragging ? "text-stone-400" : "text-white"
              } text-xs1 font-medium`}
            >
              {shareholderDetail.type}
            </p>
            <p
              className={`${
                !isDragging ? "text-stone-400" : "text-white"
              } text-xs1 font-medium`}
            >{`${(shareholderDetail.percentage * 100).toFixed(2)}%`}</p>
          </HStack>
        )}
      </VStack>
    </HStack>
  );
}

export default DNDCard;
