/* eslint-disable max-len */
import { useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import { Icon } from "@iconify/react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { toast } from "react-toastify";
import { HStack, VStack, Error, Notification } from "../../components/utils";
import {
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../quickRound/CTAButtonComponents";
import { Input, Label } from "../../components/shared/InputField";
import { SwitchDropDownModel } from "../rounds/RoundsAllTransactions";
import DropDownInput from "../../components/shared/DropDownInput";
import Tooltip from "../../components/shared/Tooltip";
import { useGetInstrumentAndShareHolder } from "../../queries/quickRound";
import { useAuthStore } from "../../store";
import {
  getCurrencySymbol,
  getCurrencyType,
} from "../../utils/currencyFormatter";
import { useRoundModelStore } from "../../store/roundModelingStore";
import {
  InvestorDetails,
  InvestorState,
  ModelBuilder,
  OngoingInvestorDetails,
} from "../../types/Modeling";
import { FormatNumberSpan } from "../../utils/currencyRoboto";
import {
  AddNewInvestorModel,
  DropDownValueModel,
  ShareholderDropDown,
} from "./RoundModelingModel";
import { ShowNumberInWords } from "../../components/shared/UiElement";
import AlertMessage, { AlertType } from "./Alert";
import AlertTextMessage from "./AlertTextMessage";
import SuggestionBox from "./InputSuggestion";

const AddNewInvestorsModal = ({
  data,
  actionMode = "New",
  onBack,
  onNext,
}: {
  data?: InvestorDetails;
  actionMode?: string;
  onBack: () => void;
  onNext: (data: ModelBuilder) => void;
}) => {
  const roundCreationState = useRoundModelStore();
  const [mode, setMode] = useState(actionMode);

  const [loading, setLoading] = useState(false);
  const companyId = useAuthStore().companyId || "";
  useGetInstrumentAndShareHolder(companyId);
  const currencySymbol = getCurrencySymbol();
  const [shareholders, setShareholder] = useState<ShareholderDropDown[]>(
    roundCreationState?.roundCreation?.existingInvestorDetails?.listOfInvestor.map(
      (ele) => ({
        name: ele.investorName,
        type: ele.investorType ?? "",
        id: ele.investorId,
      })
    ) ?? []
  );

  useEffect(() => {
    const dataList =
      roundCreationState?.roundCreation?.existingInvestorDetails?.listOfInvestor.map(
        (ele) => ({
          name: ele.investorName,
          type: ele.investorType ?? "",
          id: ele.investorId,
        })
      ) ?? [];
    setShareholder(dataList);
  }, [
    roundCreationState?.roundCreation?.existingInvestorDetails?.listOfInvestor,
  ]);

  const [selectedShareholder, setSelectedShareholder] =
    useState<ShareholderDropDown>({
      name: data?.investorName || "",
      type: data?.investorType || "",
      id: data?.investorId || "",
    });
  const [isEdit, setIsEdit] = useState(false);

  const [isAmtLocked, setIsAmtLocked] = useState(false);
  const [holding, setHolding] = useState(
    data?.postHoldingPercentage?.toString() ?? ""
  );
  const [amount, setAmount] = useState(data?.amountToInvest?.toString() ?? "");

  const shareholderDetail = useMemo(
    () =>
      roundCreationState.roundCreation.existingInvestorDetails?.investorDetails.find(
        (sh: InvestorDetails) => sh.investorId === selectedShareholder.id
      ),
    [
      roundCreationState.roundCreation?.existingInvestorDetails,
      selectedShareholder,
    ]
  );

  const handleSubmit = async (values: AddNewInvestorModel, action: string) => {
    const ongoingExistingInvestorDetails: OngoingInvestorDetails = {
      investorId: values.shareholderId,
      investorName: values.shareholderName,
      amountToInvest: Number(amount),
      postHoldingPercentage: Number(holding),
      unLockedInputFeild: {
        hasToCalculateAmt: isAmtLocked,
        value: isAmtLocked ? Number(holding) : Number(amount),
      },
      optedInvestorRightType: values.investorRights,
      state: InvestorState.ToBeSaved,
      hasBlendedSecondaries:
        roundCreationState.roundCreation.existingInvestorDetails
          ?.ongoingExistingInvestorDetails?.hasBlendedSecondaries ?? false,
      blendedSecondaries:
        roundCreationState.roundCreation.existingInvestorDetails
          ?.ongoingExistingInvestorDetails?.blendedSecondaries,
    };
    hitSocketApi(ongoingExistingInvestorDetails);
    setLoading(false);
    onBack();
  };

  const handleDelete = async () => {
    setLoading(true);
    const ongoingExistingInvestorDetails: OngoingInvestorDetails = {
      investorId: "",
      investorName: "",
      amountToInvest: 0,
      postHoldingPercentage: 0,
      unLockedInputFeild: {
        hasToCalculateAmt: isAmtLocked,
        value: 0,
      },
      optedInvestorRightType: "",
      state: InvestorState.ToBeDeleted,
      hasBlendedSecondaries: false,
      blendedSecondaries: undefined,
    };
    hitSocketApi(ongoingExistingInvestorDetails);
    setLoading(false);
    onBack();
  };

  const [investorRightType, setInvestorRightType] = useState(
    data?.optedInvestorRightType || "None"
  );

  const [changeType, setChangeType] = useState(false);

  const investorInitialValues = {
    shareholderId: data?.investorId || "",
    shareholderName: data?.investorName || "",
    postHolding: data?.postHoldingPercentage || 0,
    investmentAmount: data?.amountToInvest || 0,
    shareholderType: data?.investorType || "",
    investorRights: data?.optedInvestorRightType || investorRightType,

    state: actionMode || "",
    targetPercent:
      data?.blendedSecondaries?.targetHoldingPercentage ??
      data?.postHoldingPercentage ??
      0,
    secondaryAmount: data?.blendedSecondaries?.secondaryInvestmentAmount,
    secondaryPercent:
      data?.blendedSecondaries?.secondaryInvestmentHoldingPercentage,
  };

  const hitSocketApi = (
    ongoingExistingInvestorDetails?: OngoingInvestorDetails
  ) => {
    const data: ModelBuilder = {
      ...roundCreationState.roundCreation,
      existingInvestorDetails: {
        listOfInvestor:
          roundCreationState.roundCreation.existingInvestorDetails
            ?.listOfInvestor || [],
        investorDetails:
          roundCreationState.roundCreation.existingInvestorDetails
            ?.investorDetails || [],
        ongoingExistingInvestorDetails,
      },
    };
    onNext(data);
  };
  const [blended, setBlended] = useState(
    shareholderDetail?.hasBlendedSecondaries ?? false
  );

  const validator = Yup.number()
    .required()
    .moreThan(0)
    .max(100)
    .label("Holding");

  const investorValidationSchema = Yup.object({
    shareholderId: Yup.string().label("Shareholder Id"),
    shareholderName: Yup.string().required().label("Shareholder Name"),
    investorRights: Yup.string()
      .oneOf(["Maintain Capital", "None", "Pro Rata Investment"])
      .required()
      .label("Investor Rights"),
    postHolding:
      Number(
        roundCreationState.roundCreation.existingInvestorDetails
          ?.ongoingExistingInvestorDetails?.postHoldingPercentage ?? 0
      ) > 0
        ? Yup.number().label("Holding")
        : // eslint-disable-next-line array-callback-return
          validator,
    investmentAmount:
      (roundCreationState.roundCreation.existingInvestorDetails
        ?.ongoingExistingInvestorDetails?.amountToInvest ?? 0) > 0
        ? Yup.number().label("Investment Amount")
        : Yup.number().required().moreThan(0).label("Final Investment"),
    state: Yup.string().label("State"),
    targetPercent: blended
      ? Yup.number().required().moreThan(0).label("Share Price")
      : Yup.number().moreThan(0).label("Share Price"),
    secondaryAmount: blended
      ? Yup.number().required().moreThan(0).label("Secondary Amount")
      : Yup.number().moreThan(0).label("Secondary Amount"),
  });

  const presettingStateForEditBlendedSecondary = () => {
    setBlended(true);
    setIsEdit(true);
    if (data) {
      setAmount(`${data.blendedSecondaries?.finalInvestmentAmount}` ?? 0);
      setIsAmtLocked(data.unLockedInputFeild.hasToCalculateAmt ?? false);
    }
  };

  const presettingState = () => {
    setIsEdit(true);
    formik.setFieldValue("shareholderId", selectedShareholder.id);
    formik.setFieldValue("shareholderName", selectedShareholder.name);
    formik.setFieldValue("postHolding", Number(isAmtLocked));
    formik.setFieldValue("investmentAmount", Number(amount));
    formik.setFieldValue("investorRights", investorRightType);
    setInvestorRightType(investorRightType);
  };

  //used to prefill the ongoingInvestorDetails in socket
  function buildInvestorDetailsForEditBlendedSecondary() {
    if (data)
      return {
        investorId: data?.investorId ?? "",
        amountToInvest: data.blendedSecondaries?.finalInvestmentAmount ?? 0,
        postHoldingPercentage: data.postHoldingPercentage ?? 0,
        investorName: data.investorName ?? "",
        unLockedInputFeild: {
          hasToCalculateAmt: data.unLockedInputFeild.hasToCalculateAmt ?? false,
          value: data.unLockedInputFeild.value ?? 0,
        },
        investorType: data?.investorType ?? "",
        hasBlendedSecondaries: data.hasBlendedSecondaries ?? true,
        blendedSecondaries: data.blendedSecondaries,
        state: InvestorState.Update,
        optedInvestorRightType: data.optedInvestorRightType ?? "",
      };
    return undefined;
  }

  const buildInvestorDetails = () => ({
    investorId: selectedShareholder.id,
    investorName: selectedShareholder.name,
    amountToInvest: Number(amount),
    postHoldingPercentage: Number(holding),
    unLockedInputFeild: {
      hasToCalculateAmt: isAmtLocked,
      value: isAmtLocked ? Number(holding) : Number(amount),
    },
    optedInvestorRightType: investorRightType,
    state: InvestorState.Update,
    hasBlendedSecondaries: isEdit
      ? data?.hasBlendedSecondaries ?? false
      : roundCreationState.roundCreation.newInvestorDetails
          ?.ongoingExistingInvestorDetails?.hasBlendedSecondaries ?? false,
    blendedSecondaries: isEdit
      ? data?.blendedSecondaries
      : roundCreationState.roundCreation.newInvestorDetails
          ?.ongoingExistingInvestorDetails?.blendedSecondaries,
  });

  const showErrorMessage = () => {
    const data = roundCreationState.roundCreation;
    if (data.errorMessage) {
      toast(data.errorMessage, {
        type: "error",
        autoClose: 2000,
      });
      roundCreationState.setRoundCreation({
        ...data,
        errorMessage: "",
      });
    }
  };

  //used to update the ongoinginvestor information when editing and when amount holding or input detail changes
  useEffect(() => {
    if (!selectedShareholder.id) return;
    if (data?.hasBlendedSecondaries && !isEdit && actionMode === "Edit") {
      presettingStateForEditBlendedSecondary();
      const investorDetails = buildInvestorDetailsForEditBlendedSecondary();
      hitSocketApi(investorDetails);
    } else {
      const investorDetails: OngoingInvestorDetails = buildInvestorDetails();
      hitSocketApi(investorDetails);
      presettingState();
    }
  }, [amount, holding, selectedShareholder, changeType]);

  useEffect(() => {
    showErrorMessage();
  }, []);

  const [targetPercentage, setTargetPercentage] = useState(
    data?.blendedSecondaries?.targetHoldingPercentage ?? 0
  );
  useEffect(() => {
    if (
      data?.hasBlendedSecondaries ||
      roundCreationState.roundCreation.existingInvestorDetails
        ?.ongoingExistingInvestorDetails?.hasBlendedSecondaries
    ) {
      const ongoing =
        roundCreationState.roundCreation.existingInvestorDetails
          ?.ongoingExistingInvestorDetails;
      hitSocketApi({
        ...ongoing,
        investorId: ongoing?.investorId ?? "",
        amountToInvest: ongoing?.amountToInvest ?? 0,
        postHoldingPercentage: ongoing?.postHoldingPercentage ?? 0,
        investorName: ongoing?.investorName ?? "",
        unLockedInputFeild: {
          hasToCalculateAmt:
            ongoing?.unLockedInputFeild.hasToCalculateAmt ?? false,
          value: ongoing?.unLockedInputFeild.value ?? 0,
        },
        investorType: ongoing?.investorType ?? "",
        hasBlendedSecondaries: true,
        blendedSecondaries: {
          primaryInvestmentAmount:
            ongoing?.blendedSecondaries?.primaryInvestmentAmount ?? 0,
          discountPercentage:
            ongoing?.blendedSecondaries?.discountPercentage ?? 0,
          finalInvestmentAmount:
            ongoing?.blendedSecondaries?.finalInvestmentAmount ?? 0,
          finalInvestmentHoldingPercentage:
            ongoing?.blendedSecondaries?.finalInvestmentHoldingPercentage ?? 0,
          primaryInvestmentHoldingPercentage:
            ongoing?.blendedSecondaries?.primaryInvestmentHoldingPercentage ??
            0,
          secondaryInvestmentAmount:
            ongoing?.blendedSecondaries?.secondaryInvestmentAmount ?? 0,
          secondaryInvestmentHoldingPercentage:
            ongoing?.blendedSecondaries?.secondaryInvestmentHoldingPercentage ??
            0,
          sharePrice: ongoing?.blendedSecondaries?.sharePrice ?? 0,
          targetHoldingPercentage: Number(targetPercentage) ?? 0,
        },
        state:
          roundCreationState.roundCreation.existingInvestorDetails
            ?.ongoingExistingInvestorDetails?.state ?? InvestorState.Update,
        optedInvestorRightType:
          roundCreationState.roundCreation.existingInvestorDetails
            ?.ongoingExistingInvestorDetails?.optedInvestorRightType ?? "",
      });
    }
  }, [targetPercentage]);

  const settingValuesOnSocketEntry = () => {
    const socketData =
      roundCreationState.roundCreation.existingInvestorDetails
        ?.ongoingExistingInvestorDetails;
    formik.setFieldValue("shareholderName", socketData?.investorName);
    formik.setFieldValue(
      "targetPercent",
      socketData?.blendedSecondaries?.targetHoldingPercentage
    );
    formik.setFieldValue(
      "secondaryAmount",
      socketData?.blendedSecondaries?.secondaryInvestmentAmount
    );
    formik.setFieldValue(
      "postHolding",
      Number(socketData?.postHoldingPercentage)
    );
    formik.setFieldValue(
      "investmentAmount",
      Number(socketData?.amountToInvest)
    );
    formik.setFieldValue(
      "investorRights",
      socketData?.optedInvestorRightType || "None"
    );
    setInvestorRightType(socketData?.optedInvestorRightType || "None");
  };

  useEffect(() => {
    settingValuesOnSocketEntry();
  }, [
    roundCreationState.roundCreation.existingInvestorDetails
      ?.ongoingExistingInvestorDetails,
    roundCreationState.roundCreation.existingInvestorDetails
      ?.ongoingExistingInvestorDetails?.blendedSecondaries
      ?.targetHoldingPercentage,
    roundCreationState.roundCreation.existingInvestorDetails
      ?.ongoingExistingInvestorDetails?.blendedSecondaries
      ?.secondaryInvestmentAmount,
    blended,
  ]);

  const formik = useFormik({
    initialValues: investorInitialValues,
    validationSchema: investorValidationSchema,
    enableReinitialize: false,
    onSubmit: (values) => {
      handleSubmit(values, "Closed");
    },
  });

  const updateLocalStates = () => {
    setAmount(
      (
        roundCreationState.roundCreation.existingInvestorDetails
          ?.ongoingExistingInvestorDetails?.amountToInvest || 0
      ).toFixed(2)
    );
    setHolding(
      (
        roundCreationState.roundCreation.existingInvestorDetails
          ?.ongoingExistingInvestorDetails?.postHoldingPercentage || 0
      ).toFixed(2)
    );
  };

  const currencyType = getCurrencyType();
  const [onHoverAmount, setonHoverAmount] = useState(false);
  const [onHoverSecondary, setonHoverSecondary] = useState(false);

  return (
    <div className="max-w-2xl">
      <Box className="px-4 pt-4 pb-2 text-lg font-medium border-b">
        <h6 className="flex justify-between">
          <VStack>
            {actionMode === "New" ? (
              <p className="text-lg font-medium">Add Existing Shareholder</p>
            ) : (
              <p className="text-lg font-medium">Edit Existing Shareholder</p>
            )}
          </VStack>
          <XMarkIcon
            className="h-6 cursor-pointer"
            onClick={() => {
              handleDelete();
            }}
          />
        </h6>
      </Box>
      <VStack className="w-full py-7 gap-9">
        <VStack className="w-full gap-6 px-10">
          <HStack className="gap-4">
            <VStack className="flex-1">
              <Label className="mt-3 mr-4 text-sm font-medium whitespace-nowrap">
                Shareholder Name
              </Label>
              <Box className="mb-12 w-72">
                <DropDownInput
                  data={shareholders.filter((ele) => ele.name !== "Esop pool")}
                  className={" w-72 rounded-md"}
                  placeholder="Search Investor..."
                  width="w-72"
                  getSelectedValue={(data: any) => {
                    formik.setFieldValue("shareholderName", data.name);
                    formik.setFieldValue("shareholderId", data.id);
                    setSelectedShareholder(data);
                    updateLocalStates();
                  }}
                  selectedOption={
                    selectedShareholder as unknown as ShareholderDropDown
                  }
                  field={"name" as keyof SwitchDropDownModel}
                  disabled={mode === "Edit"}
                />
              </Box>

              {formik.touched.shareholderName &&
                formik.errors.shareholderName && (
                  <Error text={formik.errors.shareholderName} />
                )}
            </VStack>
            <VStack className="flex-1">
              <Label className="mt-3 mr-4 text-sm font-medium whitespace-nowrap">
                Investor Rights
              </Label>
              <Box className="mb-12 w-72">
                {investorRightType === "None" ? (
                  <DropDownInput
                    disabled={mode === "Edit" && data?.hasBlendedSecondaries}
                    data={
                      [
                        {
                          name: "Pro Rata Investment",
                          value: "Pro Rata Investment",
                        },
                        {
                          name: "Maintain Capital",
                          value: "Maintain Capital",
                        },
                        {
                          name: "None",
                          value: "None",
                        },
                      ] as DropDownValueModel[]
                    }
                    className={"w-72 rounded-md"}
                    placeholder="Search Rights..."
                    width="w-72"
                    getSelectedValue={(data: any) => {
                      if (data.value !== "") {
                        formik.setFieldValue("investorRights", data.value);
                        setInvestorRightType(data.value);
                        setChangeType(!changeType);
                        updateLocalStates();
                      }
                    }}
                    selectedOption={{
                      name: "None",
                      value: "None",
                    }}
                    field={"value" as keyof DropDownValueModel}
                  />
                ) : (
                  <DropDownInput
                    disabled={mode === "Edit" && data?.hasBlendedSecondaries}
                    data={
                      [
                        {
                          name: "Pro Rata Investment",
                          value: "Pro Rata Investment",
                        },
                        {
                          name: "Maintain Capital",
                          value: "Maintain Capital",
                        },
                        {
                          name: "None",
                          value: "None",
                        },
                      ] as DropDownValueModel[]
                    }
                    className={"w-72 rounded-md"}
                    placeholder="Search Rights..."
                    getSelectedValue={(data: any) => {
                      if (data.value !== "") {
                        formik.setFieldValue("investorRights", data.value);
                        setInvestorRightType(data.value);
                        setChangeType(!changeType);
                        updateLocalStates();
                      }
                    }}
                    selectedOption={{
                      name:
                        roundCreationState.roundCreation.existingInvestorDetails
                          ?.ongoingExistingInvestorDetails
                          ?.optedInvestorRightType ?? investorRightType,
                      value:
                        roundCreationState.roundCreation.existingInvestorDetails
                          ?.ongoingExistingInvestorDetails
                          ?.optedInvestorRightType ?? investorRightType,
                    }}
                    field={"value" as keyof DropDownValueModel}
                  />
                )}
              </Box>
              {formik.touched.investorRights &&
                formik.errors.investorRights && (
                  <Error text={formik.errors.investorRights} />
                )}
            </VStack>
          </HStack>
          <HStack className="gap-4">
            <VStack className="flex-1">
              <HStack>
                <Label className="mr-4 text-sm font-medium whitespace-nowrap">
                  Investment Amount {` (${currencySymbol})`}
                </Label>
                {formik.values.investorRights.toString().toLowerCase() ===
                "none" ? (
                  !isAmtLocked ? (
                    <Icon
                      onClick={() => {
                        setIsAmtLocked((prev) => !prev);
                        updateLocalStates();
                      }}
                      icon="material-symbols:lock-open-outline-rounded"
                      width={20}
                      className="text-[#E75935]"
                      height={20}
                    />
                  ) : (
                    <Icon
                      onClick={() => {
                        setIsAmtLocked((prev) => !prev);
                        updateLocalStates();
                      }}
                      icon="ic:outline-lock"
                      width={20}
                      className="text-gray-400"
                      height={20}
                    />
                  )
                ) : (
                  <Tooltip
                    _className={`w-32`}
                    text={
                      "Change Investor Rights to 'None' to edit the Post Holding % and unlock the input field"
                    }
                  >
                    <Icon
                      icon="ic:outline-lock"
                      width={20}
                      className="text-gray-400"
                      height={20}
                    />
                  </Tooltip>
                )}
              </HStack>
              <Input
                type="text"
                onMouseEnter={() => {
                  setonHoverAmount(true);
                }}
                onMouseLeave={() => {
                  setonHoverAmount(false);
                }}
                placeholder={isAmtLocked ? "" : "Enter Amount"}
                readOnly={
                  isAmtLocked || formik.values.investorRights !== "None"
                }
                className="w-72"
                value={Intl.NumberFormat(currencyType).format(
                  (isAmtLocked || formik.values.investorRights !== "None"
                    ? roundCreationState.roundCreation.existingInvestorDetails
                        ?.ongoingExistingInvestorDetails?.amountToInvest ?? 0
                    : Number(amount ?? "0") ?? 0) ?? 0
                )}
                onChange={(e) => {
                  if (e.target.value) {
                    setAmount(e.target.value ?? "0");
                    const values = parseInt(
                      (e.target.value ?? "0")?.replaceAll(",", "") || "",
                      10
                    );
                    setAmount(`${values}`);
                    const newValue = Number.isNaN(values) ? undefined : values;
                    formik.setFieldValue("investmentAmount", newValue ?? 0);
                    formik.handleChange("investmentAmount");
                  } else {
                    setAmount("0");
                    const values = parseInt("0"?.replaceAll(",", "") || "", 10);
                    setAmount(`${values}`);
                    const newValue = Number.isNaN(values) ? undefined : values;
                    formik.setFieldValue("investmentAmount", newValue ?? 0);
                    formik.handleChange("investmentAmount");
                  }
                }}
              />
              {onHoverAmount && (
                <ShowNumberInWords
                  value={
                    (isAmtLocked || formik.values.investorRights !== "None"
                      ? roundCreationState.roundCreation.existingInvestorDetails
                          ?.ongoingExistingInvestorDetails?.amountToInvest
                      : Number(amount)) ?? 0
                  }
                  currency={currencyType}
                />
              )}
              {!isAmtLocked &&
                formik.values.investorRights.toString().toLowerCase() ===
                  "none" && (
                  <div className="h-5">
                    <AlertTextMessage
                      type={AlertType.INSIGHT}
                      heading={`Final Investable Amount : ${currencySymbol} ${(
                        roundCreationState.roundCreation.existingInvestorDetails
                          ?.ongoingExistingInvestorDetails?.amountToInvest ?? 0
                      )?.toLocaleString(currencyType)}`}
                    />{" "}
                  </div>
                )}
              <p className="pt-1 font-medium text-white text-xxs">.</p>

              {formik.touched.investmentAmount &&
                formik.errors.investmentAmount && (
                  <Error text={formik.errors.investmentAmount} />
                )}
            </VStack>
            <VStack className="flex-1">
              <HStack>
                <Label className="mr-4 text-sm font-medium whitespace-nowrap">
                  Post Holding %
                </Label>
                {investorRightType.toString().toLowerCase() === "none" ? (
                  isAmtLocked ? (
                    <Icon
                      onClick={() => {
                        setIsAmtLocked((prev) => !prev);
                        updateLocalStates();
                      }}
                      icon="material-symbols:lock-open-outline-rounded"
                      width={20}
                      className="text-[#E75935]"
                      height={20}
                    />
                  ) : (
                    <Tooltip
                      _className={`w-32`}
                      text={
                        "Change Investor Rights to 'None' to edit the Post Holding % and unlock the input field"
                      }
                    >
                      <Icon
                        onClick={() => {
                          setIsAmtLocked((prev) => !prev);
                          updateLocalStates();
                        }}
                        icon="ic:outline-lock"
                        width={20}
                        className="text-gray-400"
                        height={20}
                      />
                    </Tooltip>
                  )
                ) : (
                  <Tooltip
                    _className={`w-32`}
                    text={
                      "Change Investor Rights to 'None' to edit the Post Holding % and unlock the input field"
                    }
                  >
                    <Icon
                      icon="ic:outline-lock"
                      width={20}
                      className="text-gray-400"
                      height={20}
                    />
                  </Tooltip>
                )}
              </HStack>
              <Input
                type="number"
                placeholder={!isAmtLocked ? "" : "Enter Holding %"}
                readOnly={!isAmtLocked}
                className="w-72"
                // disabled={mode === "Edit" && data?.hasBlendedSecondaries}
                value={Number(
                  (!isAmtLocked ||
                  formik.values.investorRights.toLowerCase() !== "none"
                    ? roundCreationState.roundCreation.existingInvestorDetails
                        ?.ongoingExistingInvestorDetails?.postHoldingPercentage
                    : Number(holding)
                  )?.toFixed(2)
                )}
                onChange={(e) => {
                  formik.setFieldValue("postHolding", Number(e.target.value));
                  setHolding(e.target.value);
                }}
              />
              {isAmtLocked &&
                formik.values?.investorRights?.toString().toLowerCase() ===
                  "none" && (
                  <div className="h-5">
                    <AlertTextMessage
                      type={AlertType.INSIGHT}
                      heading={`Final Holding :${roundCreationState.roundCreation.existingInvestorDetails?.ongoingExistingInvestorDetails?.postHoldingPercentage?.toFixed(
                        2
                      )}`}
                    />{" "}
                  </div>
                )}
              {!blended ? (
                <p className="text-xs text-[#A6A6A6] font-medium pt-1">
                  to enable blended secondary,
                  <span
                    role="button"
                    className="text-blue-600 underline"
                    onClick={() => {
                      setBlended(true);
                      const data =
                        roundCreationState.roundCreation.existingInvestorDetails
                          ?.ongoingExistingInvestorDetails
                          ?.postHoldingPercentage ?? 0;
                      const dataNum = data;
                      setTargetPercentage(dataNum);
                      hitSocketApi({
                        ...roundCreationState.roundCreation
                          .existingInvestorDetails
                          ?.ongoingExistingInvestorDetails,
                        investorId:
                          roundCreationState.roundCreation
                            .existingInvestorDetails
                            ?.ongoingExistingInvestorDetails?.investorId ?? "",
                        amountToInvest:
                          roundCreationState.roundCreation
                            .existingInvestorDetails
                            ?.ongoingExistingInvestorDetails?.amountToInvest ??
                          0,
                        postHoldingPercentage:
                          roundCreationState.roundCreation
                            .existingInvestorDetails
                            ?.ongoingExistingInvestorDetails
                            ?.postHoldingPercentage ?? 0,
                        investorName:
                          roundCreationState.roundCreation
                            .existingInvestorDetails
                            ?.ongoingExistingInvestorDetails?.investorName ??
                          "",
                        unLockedInputFeild: {
                          hasToCalculateAmt:
                            roundCreationState.roundCreation
                              .existingInvestorDetails
                              ?.ongoingExistingInvestorDetails
                              ?.unLockedInputFeild.hasToCalculateAmt ?? false,
                          value:
                            roundCreationState.roundCreation
                              .existingInvestorDetails
                              ?.ongoingExistingInvestorDetails
                              ?.unLockedInputFeild.value ?? 0,
                        },
                        investorType:
                          roundCreationState.roundCreation
                            .existingInvestorDetails
                            ?.ongoingExistingInvestorDetails?.investorType ??
                          "",
                        hasBlendedSecondaries: true,
                        blendedSecondaries:
                          roundCreationState.roundCreation
                            .existingInvestorDetails
                            ?.ongoingExistingInvestorDetails
                            ?.blendedSecondaries,
                        state:
                          roundCreationState.roundCreation
                            .existingInvestorDetails
                            ?.ongoingExistingInvestorDetails?.state ??
                          InvestorState.Update,
                        optedInvestorRightType:
                          roundCreationState.roundCreation
                            .existingInvestorDetails
                            ?.ongoingExistingInvestorDetails
                            ?.optedInvestorRightType ?? "",
                      });
                    }}
                  >
                    click here
                  </span>
                </p>
              ) : (
                <p className="text-xs text-[#A6A6A6] font-medium pt-1">
                  to discard blended secondary,
                  <span
                    role="button"
                    className="text-blue-600 underline"
                    onClick={() => {
                      setBlended(false);
                      hitSocketApi({
                        ...roundCreationState.roundCreation
                          .existingInvestorDetails
                          ?.ongoingExistingInvestorDetails,
                        investorId:
                          roundCreationState.roundCreation
                            .existingInvestorDetails
                            ?.ongoingExistingInvestorDetails?.investorId ?? "",
                        amountToInvest:
                          roundCreationState.roundCreation
                            .existingInvestorDetails
                            ?.ongoingExistingInvestorDetails?.amountToInvest ??
                          0,
                        postHoldingPercentage:
                          roundCreationState.roundCreation
                            .existingInvestorDetails
                            ?.ongoingExistingInvestorDetails
                            ?.postHoldingPercentage ?? 0,
                        investorName:
                          roundCreationState.roundCreation
                            .existingInvestorDetails
                            ?.ongoingExistingInvestorDetails?.investorName ??
                          "",
                        unLockedInputFeild: {
                          hasToCalculateAmt:
                            roundCreationState.roundCreation
                              .existingInvestorDetails
                              ?.ongoingExistingInvestorDetails
                              ?.unLockedInputFeild.hasToCalculateAmt ?? false,
                          value:
                            roundCreationState.roundCreation
                              .existingInvestorDetails
                              ?.ongoingExistingInvestorDetails
                              ?.unLockedInputFeild.value ?? 0,
                        },
                        investorType:
                          roundCreationState.roundCreation
                            .existingInvestorDetails
                            ?.ongoingExistingInvestorDetails?.investorType ??
                          "",
                        hasBlendedSecondaries: false,
                        blendedSecondaries:
                          roundCreationState.roundCreation
                            .existingInvestorDetails
                            ?.ongoingExistingInvestorDetails
                            ?.blendedSecondaries,
                        state:
                          roundCreationState.roundCreation
                            .existingInvestorDetails
                            ?.ongoingExistingInvestorDetails?.state ??
                          InvestorState.Update,
                        optedInvestorRightType:
                          roundCreationState.roundCreation
                            .existingInvestorDetails
                            ?.ongoingExistingInvestorDetails
                            ?.optedInvestorRightType ?? "",
                      });
                    }}
                  >
                    click here
                  </span>
                </p>
              )}

              {formik.touched.postHolding && formik.errors.postHolding && (
                <Error text={formik.errors.postHolding} />
              )}
            </VStack>
          </HStack>
        </VStack>
        {blended && (
          <div className="py-2 mx-4 border rounded-lg shadow-lg border-borderColor">
            <VStack>
              <HStack className="px-4">
                <p>Blended Secondary</p>
                <div></div>
              </HStack>
              <HStack className="gap-7 pl-4">
                <VStack>
                  <Label className="mt-3 mr-4 text-sm font-medium whitespace-nowrap">
                    Target Percentage
                  </Label>
                  <Input
                    type="number"
                    placeholder={"Enter Target Percent"}
                    className="w-72"
                    value={Number(targetPercentage.toFixed(4)) ?? 0}
                    onChange={(e) => {
                      formik.setFieldValue(
                        "targetPercent",
                        e.target.value ?? 0
                      );
                      setTargetPercentage(Number(e.target.value));
                    }}
                  />

                  {formik.touched.investmentAmount &&
                    formik.errors.investmentAmount && (
                      <Error text={formik.errors.investmentAmount} />
                    )}
                </VStack>
                <VStack>
                  <Label className="font-medium text-sm whitespace-nowrap mt-3 mr-4">
                    Secondary Share Price
                  </Label>
                  <HStack className="items-center mt-3 mr-4 text-base font-medium whitespace-nowrap">
                    <p className="pr-1">{currencySymbol}</p>
                    <FormatNumberSpan
                      value={
                        roundCreationState.roundCreation.existingInvestorDetails
                          ?.ongoingExistingInvestorDetails?.blendedSecondaries
                          ?.sharePrice ?? 0
                      }
                      format={currencyType}
                    />
                  </HStack>
                  <HStack className="items-center mt-3 mr-4 text-xs font-medium whitespace-nowrap">
                    <p className="mr-1 text-xs text-descriptionColor">{`Discount on the FDB Share price is `}</p>
                    <FormatNumberSpan
                      value={
                        roundCreationState.roundCreation.existingInvestorDetails
                          ?.ongoingExistingInvestorDetails?.blendedSecondaries
                          ?.discountPercentage ?? 0
                      }
                      format={currencyType}
                    />
                    <p className="ml-0.5">%</p>
                  </HStack>
                </VStack>
                <div></div>
              </HStack>
              <HStack className="justify-between w-full px-4">
                <VStack>
                  <VStack>
                    <Label className="mt-3 mr-4 text-sm font-medium whitespace-nowrap">
                      Secondary Investment ({currencySymbol})
                    </Label>
                    <Input
                      type="text"
                      onMouseEnter={() => {
                        setonHoverSecondary(true);
                      }}
                      onMouseLeave={() => {
                        setonHoverSecondary(false);
                      }}
                      placeholder={"Enter Secondary Investment"}
                      className="w-72"
                      value={formik.values.secondaryAmount}
                      onChange={(e) => {
                        formik.setFieldValue(
                          "secondaryAmount",
                          e.target.value ?? 0
                        );
                        const ongoing =
                          roundCreationState.roundCreation
                            .existingInvestorDetails
                            ?.ongoingExistingInvestorDetails;
                        hitSocketApi({
                          ...ongoing,
                          investorId: ongoing?.investorId ?? "",
                          amountToInvest: ongoing?.amountToInvest ?? 0,
                          postHoldingPercentage:
                            ongoing?.postHoldingPercentage ?? 0,
                          investorName: ongoing?.investorName ?? "",
                          unLockedInputFeild: {
                            hasToCalculateAmt:
                              ongoing?.unLockedInputFeild.hasToCalculateAmt ??
                              false,
                            value: ongoing?.unLockedInputFeild.value ?? 0,
                          },
                          investorType: ongoing?.investorType ?? "",
                          hasBlendedSecondaries: true,
                          blendedSecondaries: {
                            primaryInvestmentAmount:
                              ongoing?.blendedSecondaries
                                ?.primaryInvestmentAmount ?? 0,
                            discountPercentage:
                              ongoing?.blendedSecondaries?.discountPercentage ??
                              0,
                            finalInvestmentAmount:
                              ongoing?.blendedSecondaries
                                ?.finalInvestmentAmount ?? 0,
                            finalInvestmentHoldingPercentage:
                              ongoing?.blendedSecondaries
                                ?.finalInvestmentHoldingPercentage ?? 0,
                            primaryInvestmentHoldingPercentage:
                              ongoing?.blendedSecondaries
                                ?.primaryInvestmentHoldingPercentage ?? 0,
                            secondaryInvestmentAmount:
                              Number(e.target.value ?? 0) ?? 0,
                            secondaryInvestmentHoldingPercentage:
                              ongoing?.blendedSecondaries
                                ?.secondaryInvestmentHoldingPercentage ?? 0,
                            sharePrice:
                              ongoing?.blendedSecondaries?.sharePrice ?? 0,
                            targetHoldingPercentage:
                              ongoing?.blendedSecondaries
                                ?.targetHoldingPercentage ?? 0,
                          },
                          state:
                            roundCreationState.roundCreation
                              .existingInvestorDetails
                              ?.ongoingExistingInvestorDetails?.state ??
                            InvestorState.Update,
                          optedInvestorRightType:
                            roundCreationState.roundCreation
                              .existingInvestorDetails
                              ?.ongoingExistingInvestorDetails
                              ?.optedInvestorRightType ?? "",
                        });
                      }}
                    />
                    <SuggestionBox
                      heading={`Amount should not exceed final investment`}
                    />
                    {onHoverSecondary && (
                      <ShowNumberInWords
                        value={formik.values.secondaryAmount ?? 0}
                        currency={currencyType}
                      />
                    )}
                  </VStack>
                  <HStack className="items-center mt-3 mr-4 text-base font-medium whitespace-nowrap">
                    <FormatNumberSpan
                      value={
                        roundCreationState.roundCreation.existingInvestorDetails
                          ?.ongoingExistingInvestorDetails?.blendedSecondaries
                          ?.secondaryInvestmentHoldingPercentage ?? 0
                      }
                      format={currencyType}
                    />
                    <p className="ml-0.5">%</p>
                  </HStack>
                </VStack>

                <VStack>
                  <Label className="mt-3 mr-4 text-sm font-medium whitespace-nowrap">
                    Primary Investment
                  </Label>
                  <HStack className="items-center h-10 mt-4 mr-4 text-base font-medium whitespace-nowrap">
                    <div className="mr-0.5">{`${currencySymbol} `}</div>
                    <FormatNumberSpan
                      value={
                        roundCreationState.roundCreation.existingInvestorDetails
                          ?.ongoingExistingInvestorDetails?.blendedSecondaries
                          ?.primaryInvestmentAmount ?? 0
                      }
                      format={currencyType}
                    />
                  </HStack>

                  <HStack className="items-center mt-8 mr-4 text-base font-medium whitespace-nowrap">
                    <FormatNumberSpan
                      value={
                        roundCreationState.roundCreation.existingInvestorDetails
                          ?.ongoingExistingInvestorDetails?.blendedSecondaries
                          ?.primaryInvestmentHoldingPercentage ?? 0
                      }
                      format={currencyType}
                    />
                    <p className="ml-0.5">%</p>
                  </HStack>
                </VStack>
                <VStack>
                  <Label className="mt-3 mr-4 text-sm font-medium whitespace-nowrap">
                    Final Investment
                  </Label>
                  <HStack className="items-center h-10 mt-4 mr-4 text-base font-medium whitespace-nowrap">
                    <div className="mr-0.5">{`${currencySymbol} `}</div>
                    <FormatNumberSpan
                      value={
                        roundCreationState.roundCreation.existingInvestorDetails
                          ?.ongoingExistingInvestorDetails?.blendedSecondaries
                          ?.finalInvestmentAmount ?? 0
                      }
                      format={currencyType}
                    />
                  </HStack>
                  <HStack className="items-center mt-8 mr-4 text-base font-medium whitespace-nowrap">
                    <FormatNumberSpan
                      value={
                        roundCreationState.roundCreation.existingInvestorDetails
                          ?.ongoingExistingInvestorDetails?.blendedSecondaries
                          ?.finalInvestmentHoldingPercentage ?? 0
                      }
                      format={currencyType}
                    />
                    <p className="ml-0.5">%</p>
                  </HStack>
                </VStack>
              </HStack>
            </VStack>
          </div>
        )}
        {blended && (
          <div className="mx-4 -mt-8">
            <HStack className="px-4">
              <SuggestionBox
                heading={`~ The money raise will be updated with respect to the primary investment amount once saved`}
              />
            </HStack>
          </div>
        )}

        <HStack className="justify-between px-10 mt-4">
          <SecondaryCTAButton
            event-name="Cancel Add New Existing Investor Modal"
            onClick={() => {
              handleDelete();
            }}
          >
            Cancel
          </SecondaryCTAButton>
          <PrimaryCTAButton
            className="p-1"
            event-name="Save Add New Existing Investor Modal"
            //RMDATA type="submit"
            onClick={() => {
              formik.handleSubmit();
            }}
          >
            {!loading && <span>Save</span>}

            {loading && (
              <HStack>
                <p className="indicator-progress" style={{ display: "block" }}>
                  Please wait...
                </p>
                <Icon
                  className="animate-spin"
                  icon="lucide:loader-2"
                  width={18}
                />
              </HStack>
            )}
          </PrimaryCTAButton>
        </HStack>
      </VStack>
    </div>
  );
};

export default AddNewInvestorsModal;
