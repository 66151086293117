import { tableEntryStyle } from "../../../../components/TableComponent";
import { HStack } from "../../../../components/utils";
import {
  getCurrencySymbol,
  getCurrencyType,
  getCurrencyTypeforDecimals,
} from "../../../../utils/currencyFormatter";

export function TimeLineRenderer(data: any) {
  const currencyType = getCurrencyType();

  const keys = Object.keys(data.data);

  const filteredKeys = keys.filter(
    (key) => key !== "orgHierarchy" && key !== "tooltipText"
  );

  filteredKeys.sort((a, b) => parseInt(b, 10) - parseInt(a, 10));

  const differences: any = {};

  for (let index = 0; index < filteredKeys.length - 1; index++) {
    const key = filteredKeys[index];
    const nextKey = filteredKeys[index + 1];

    const currentValue = parseFloat(data.data[key]);
    const previousValue = parseFloat(data.data[nextKey]);

    if (
      !Number.isNaN(currentValue) &&
      !Number.isNaN(previousValue) &&
      previousValue !== 0
    ) {
      const difference =
        ((currentValue - previousValue) / Math.abs(previousValue)) * 100;
      differences[key] = difference;
    }
  }

  return (
    <div className="whitespace-normal py-1">
      <p>
        {data.rowIndex === 0
          ? data.value.toLocaleString(currencyType)
          : data.value[0].toLocaleString(currencyType)}
      </p>
      <p className={tableEntryStyle}>{data.value[1]}</p>

      {filteredKeys.map(
        (key, index) =>
          data.column.colId === key &&
          differences[key] !== undefined &&
          index + 1 !== undefined &&
          differences[key] !== 0 && (
            <div
              key={key}
              className={`${
                differences[key] > 0
                  ? "text-green-600 text-xxs"
                  : "text-red-600 text-xxs"
              }`}
            >
              {data.rowIndex === 0 && (
                <p>{getCurrencyTypeforDecimals(differences[key])} %</p>
              )}
            </div>
          )
      )}
    </div>
  );
}

export function UnsecuredBorrowingRenderer(data: any) {
  const keys = Object.keys(data.data);

  const filteredKeys = keys.filter(
    (key) => key !== "orgHierarchy" && key !== "tooltipText"
  );

  filteredKeys.sort((a, b) => parseInt(b, 10) - parseInt(a, 10));

  const differences: any = {};

  for (let index = 0; index < filteredKeys.length - 1; index++) {
    const key = filteredKeys[index];
    const nextKey = filteredKeys[index + 1];

    const currentValue = parseFloat(data.data[key]);
    const previousValue = parseFloat(data.data[nextKey]);

    if (previousValue !== 0) {
      const difference =
        ((currentValue - previousValue) / Math.abs(previousValue)) * 100;
      differences[key] = difference;
    }
  }

  return (
    <div className="whitespace-normal py-1">
      <p>{getCurrencyTypeforDecimals(data.value)}</p>

      {filteredKeys.map(
        (key, index) =>
          data.column.colId === key &&
          differences[key] !== undefined &&
          index + 1 !== undefined &&
          differences[key] !== 0 && (
            <div
              key={key}
              className={`${
                differences[key] > 0
                  ? "text-green-600 text-xxs"
                  : "text-red-600 text-xxs"
              }`}
            >
              <p>{getCurrencyTypeforDecimals(differences[key])} %</p>
            </div>
          )
      )}
    </div>
  );
}

export function BadDebtsRenderer(data: any) {
  const keys = Object.keys(data.data);

  const filteredKeys = keys.filter(
    (key) => key !== "orgHierarchy" && key !== "tooltipText"
  );

  filteredKeys.sort((a, b) => parseInt(b, 10) - parseInt(a, 10));

  const differences: any = {};

  for (let index = 0; index < filteredKeys.length - 1; index++) {
    const key = filteredKeys[index];
    const nextKey = filteredKeys[index + 1];

    const currentValue = parseFloat(data.data[key]);
    const previousValue = parseFloat(data.data[nextKey]);

    if (previousValue !== 0) {
      const difference =
        ((currentValue - previousValue) / Math.abs(previousValue)) * 100;
      differences[key] = difference;
    }
  }

  return (
    <div className="whitespace-normal py-1">
      <p>{getCurrencyTypeforDecimals(data.value)}</p>

      {filteredKeys.map(
        (key, index) =>
          data.column.colId === key &&
          differences[key] !== undefined &&
          index + 1 !== undefined &&
          differences[key] !== 0 && (
            <div
              key={key}
              className={`${
                differences[key] > 0
                  ? "text-green-600 text-xxs"
                  : "text-red-600 text-xxs"
              }`}
            >
              <p>{getCurrencyTypeforDecimals(differences[key])}%</p>
            </div>
          )
      )}
    </div>
  );
}

export function ProvisionsRenderer(data: any) {
  const currencySymbol = getCurrencySymbol();
  const currencyType = getCurrencyType();

  const keys = Object.keys(data.data);

  const filteredKeys = keys.filter(
    (key) => key !== "orgHierarchy" && key !== "tooltipText"
  );

  filteredKeys.sort((a, b) => parseInt(b, 10) - parseInt(a, 10));

  const differences: any = {};

  for (let index = 0; index < filteredKeys.length - 1; index++) {
    const key = filteredKeys[index];
    const nextKey = filteredKeys[index + 1];

    const currentValue = parseFloat(data.data[key]);
    const previousValue = parseFloat(data.data[nextKey]);

    if (previousValue !== 0) {
      const difference =
        ((currentValue - previousValue) / Math.abs(previousValue)) * 100;
      differences[key] = difference;
    }
  }

  return (
    <div className="whitespace-normal py-1">
      <p>{data.value.toLocaleString(currencyType)}</p>

      {filteredKeys.map(
        (key, index) =>
          data.column.colId === key &&
          differences[key] !== undefined &&
          index + 1 !== undefined &&
          differences[key] !== 0 && (
            <div
              key={key}
              className={`${
                differences[key] > 0
                  ? "text-green-600 text-xxs"
                  : "text-red-600 text-xxs"
              }`}
            >
              <p>
                {differences[key].toLocaleString(currencyType, {
                  maximumFractionDigits: 2,
                })}{" "}
                %
              </p>
            </div>
          )
      )}
    </div>
  );
}

export function LoansAndAdvancesRenderer(data: any) {
  const currencySymbol = getCurrencySymbol();
  const currencyType = getCurrencyType();

  const keys = Object.keys(data.data);

  const filteredKeys = keys.filter(
    (key) => key !== "orgHierarchy" && key !== "tooltipText"
  );

  filteredKeys.sort((a, b) => parseInt(b, 10) - parseInt(a, 10));

  const differences: any = {};

  for (let index = 0; index < filteredKeys.length - 1; index++) {
    const key = filteredKeys[index];
    const nextKey = filteredKeys[index + 1];

    const currentValue = parseFloat(data.data[key]);
    const previousValue = parseFloat(data.data[nextKey]);

    if (previousValue !== 0) {
      const difference =
        ((currentValue - previousValue) / Math.abs(previousValue)) * 100;
      differences[key] = difference;
    }
  }

  return (
    <div className="whitespace-normal py-1">
      <HStack className=" justify-between w-full gap-4">
        <p className="flex-1 w-28  whitespace-nowrap ">
          {data.rowIndex === 0
            ? `${data.value[0].toLocaleString(currencyType)} | `
            : `${data.value[0].toLocaleString(currencyType)}`}
        </p>

        <p
          className={`${
            data.rowIndex === 0
              ? "text-left flex-1 w-28 ml-2 whitespace-nowrap"
              : "text-left  flex-1 w-28 ml-2  whitespace-nowrap"
          }`}
        >
          {data.value[1].toLocaleString(currencyType)}
        </p>
      </HStack>
    </div>
  );
}
