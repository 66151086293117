export function getDougnutTagColor(type: string): any {
  switch (type.toUpperCase()) {
    case "FOUNDER":
      return {
        text: "#241DBA",
        border: "#241DBA",
        bgColor: "bg-[#241DBA]/[0.1]",
      };
    case "ANGEL":
      return {
        text: "#2DA900",
        border: "#2DA900",
        bgColor: "bg-[#2DA900]/[0.1]",
      };
    case "VC":
      return {
        text: "#00A3C7",
        border: "#00A3C7",
        bgColor: "bg-[#00A3C7]/[0.1]",
      };
    case "INCUBATOR":
      return {
        text: "#E79212",
        border: "#E79212",
        bgColor: "bg-[#E79212]/[0.1]",
      };
    case "ACCELERATOR":
      return {
        text: "#F86161",
        border: "#F86161",
        bgColor: "bg-[#F86161]/[0.1]",
      };
    case "ANGELNETWORK":
      return {
        text: "#6904DC",
        border: "#6904DC",
        bgColor: "bg-[#6904DC]/[0.1]",
      };
    case "OTHERS":
      return {
        text: "#B5A18A",
        border: "#B5A18A",
        bgColor: "bg-[#B5A18A]/[0.1]",
      };
    case "ANGELFUND":
      return {
        text: "#9A7CEE",
        border: "#9A7CEE",
        bgColor: "bg-[#9A7CEE]/[0.1]",
      };
    case "CORPORATE":
      return {
        text: "#BABC57",
        border: "#BABC57",
        bgColor: "bg-[#BABC57]/[0.1]",
      };
    case "FAMILYOFFICE":
      return {
        text: "#57BF99",
        border: "#57BF99",
        bgColor: "bg-[#57BF99]/[0.2]",
      };
    case "FAMILY OFFICE":
      return {
        text: "#57BF99",
        border: "#57BF99",
        bgColor: "bg-[#57BF99]/[0.1]",
      };
    case "PE":
      return {
        text: "#E1B774",
        border: "#E1B774",
        bgColor: "bg-[#E1B774]/[0.1]",
      };
    case "FINANCIALINSTITUTION":
      return {
        text: "#2DA900",
        border: "#2DA900",
        bgColor: "bg-[#2DA900]/[0.1]",
      };
    case "FINANCIAL INSTITUTION":
      return {
        text: "#2DA900",
        border: "#2DA900",
        bgColor: "bg-[#2DA900]/[0.1]",
      };
    case "OPTIONS":
      return {
        text: "#E49494",
        border: "#E49494",
        bgColor: "bg-[#E49494]/[0.1]",
      };
    case "EMPLOYEE":
      return {
        text: "#B5A18A",
        border: "#B5A18A",
        bgColor: "bg-[#B5A18A]/[0.1]",
      };
    case "ESOPTRUST":
      return {
        text: "#ad4d0c",
        border: "#8c3b04",
        bgColor: "bg-[#8c3b04]/[0.1]",
      };
    case "TRUST":
      return {
        text: "#ad4d0c",
        border: "#8c3b04",
        bgColor: "bg-[#8c3b04]/[0.1]",
      };
    case "FORFEITED":
      return {
        text: "#084f47",
        border: "#084f47",
        bgColor: "bg-[#084f47]/[0.1]",
      };
    case "EQUITY":
      return {
        bgColor: "bg-[#241DBA]/[0.1]",
        text: "#241DBA",
        border: "#241DBA",
      };

    case "PREFERENCE":
      return {
        bgColor: "bg-[#2DA900]/[0.1]",
        text: "#2DA900",
        border: "#2DA900",
      };

    case "CCD":
      return {
        bgColor: "bg-[#00A3C7]/[0.1]",
        text: "#00A3C7",
        border: "#00A3C7",
      };
    case "OCD":
      return {
        bgColor: "bg-[#E79212]/[0.1]",
        text: "#E79212",
        border: "#E79212",
      };
    case "NOTE":
      return {
        bgColor: "bg-[#F86161]/[0.1]",
        text: "#F86161",
        border: "#F86161",
      };
    case "WARRANT":
      return {
        bgColor: "bg-[#6904DC]/[0.1]",
        text: "#6904DC",
        border: "#6904DC",
      };

    case "RPS":
      return {
        bgColor: "bg-[#BAA8ED]/[0.1]",
        text: "#BAA8ED",
        border: "#BAA8ED",
      };

    case "NCD":
      return {
        bgColor: "bg-[#A6A900]/[0.1]",
        text: "#A6A900",
        border: "#A6A900",
      };

    case "OCPS":
      return {
        bgColor: "bg-[#00AD6F]/[0.1]",
        text: "#00AD6F",
        border: "#00AD6F",
      };

    case "OCRPS":
      return {
        bgColor: "bg-[#E79212]/[0.1]",
        text: "#E79212",
        border: "#E79212",
      };

    case "DEBENTURE":
      return {
        bgColor: "bg-[#FFD467]/[0.1]",
        text: "#FFD467",
        border: "#FFD467",
      };

    case "CCPS":
      return {
        bgColor: "bg-[#2DA900]/[0.1]",
        text: "#2DA900",
        border: "#2DA900",
      };
    case "HNI":
      return {
        bgColor: "bg-[#8B0000]/[0.1]",
        text: "#8B0000",
        border: "#8B0000",
      };

    default:
      return {
        text: "#E8FFF3",
        border: "#E8FFF3",
        bgColor: "bg-[#E8FFF3]/[0.1]",
      };
  }
}

export function getRoundIdentifierColor(roundIdentifier: string): string {
  switch (true) {
    case roundIdentifier.startsWith("FF"):
      return "bg-red-50 text-red-400 border-red-400";
    case roundIdentifier.startsWith("SS"):
      return "bg-orange-50 text-orange-400 border-orange-400";
    case roundIdentifier.startsWith("SA"):
      return "bg-yellow-50 text-yellow-400 border-yellow-400";
    case roundIdentifier.startsWith("SB"):
      return "bg-amber-50 text-amber-400 border-amber-400";
    case roundIdentifier.startsWith("SC"):
      return "bg-green-50 text-green-400 border-green-400";
    case roundIdentifier.startsWith("SD"):
      return "bg-teal-50 text-teal-400 border-teal-400";
    case roundIdentifier.startsWith("AA"):
      return "bg-blue-50 text-blue-400 border-blue-400";
    case roundIdentifier.startsWith("A"):
      return "bg-violet-50 text-violet-400 border-violet-400";
    default:
      return "bg-purple-50 text-purple-400 border-purple-400";
  }
}

export function hexToRGBA(hex: string, opacity: number) {
  hex = hex.replace(/^#/, "");

  if (hex.length === 3) {
    hex = hex
      .split("")
      .map((char) => char.repeat(2))
      .join("");
  }

  const bigint = parseInt(hex, 16);
  const r = Math.floor(bigint / 65536) % 256;
  const g = Math.floor(bigint / 256) % 256;
  const b = bigint % 256;

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}
