import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { useRoundModelStore } from "../../store/roundModelingStore";
import NewInvestorAGGridTable from "./NewInvestorAgTable";
import ModelSummaryAGGridTable from "./ModelSummaryAgTable";
import { HStack } from "../../components/utils";
import {
  ButtonSize,
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../quickRound/CTAButtonComponents";
import SecondaryAgTable from "./SecondaryAGTable";
import {
  OutstandingConvertible,
  SecondaryAllotmentDetails,
} from "../../types/Modeling";
import GenericTableHeader from "../../shared/TableHeader";
import AlertMessage from "./Alert";
import { queryClient } from "../../queries";
import { useAuthStore } from "../../store";
import { useGetInstrumentAndShareHolder } from "../../queries/quickRound";
import { useShareholderInstrumentStore } from "../../store/shareholderInstrumentStore";
import { useGetOnGoingTransaction } from "../../queries/transactionRound";
import ModelConvertibleAGGridTable from "./ModelConvertibleAGGridTable";

const ModelSummaryTab = () => {
  const {
    roundCreation: roundModel,
    sendMessage: onNext,
    connect,
    saveModel,
  } = useRoundModelStore();
  const [currentTab, setCurrentTab] = useState("Primary");
  const primaryInvestments = [
    ...(roundModel.existingInvestorDetails?.investorDetails ?? []),
    ...(roundModel.newInvestorDetails?.investorDetails ?? []),
  ];

  const [convertibleDetails, setConvertibleDetails] = useState<
    OutstandingConvertible[]
  >([]);

  const companyId = useAuthStore.getState().companyId || "";

  const { refetch, data: onGoingTransaction } =
    useGetOnGoingTransaction(companyId);
  useEffect(() => {
    refetch();
  }, []);
  const navigate = useNavigate();
  function scrollToTop() {
    const scroller = document.querySelector("#outlet");
    scroller?.scrollTo(0, 0);
  }

  const [secondaryDetails, setSecondaryDetails] = useState<
    SecondaryAllotmentDetails[]
  >([]);

  const {
    refetch: getInstrumentAndShareHolder,
    isPlaceholderData,
    data: instrumentShareholderInfoData,
  } = useGetInstrumentAndShareHolder(companyId);
  const shareholderAndInstrumentstore = useShareholderInstrumentStore();
  useEffect(() => {
    getInstrumentAndShareHolder().then((data1) => {
      shareholderAndInstrumentstore.setInstrumentClass(
        data1?.data?.instrumentClasses || []
      );
      shareholderAndInstrumentstore.setShareholders(
        data1?.data?.shareholders || []
      );
      shareholderAndInstrumentstore.setInstrumentClassSubTypes(
        data1?.data?.instrumentClassSubTypes || {}
      );
    });
  }, []);
  useEffect(() => {
    const secondaries: SecondaryAllotmentDetails[] = [];
    roundModel.secondaries?.secondaryAllotmentDetails.forEach(
      (secondary: SecondaryAllotmentDetails) => {
        if (secondary.isBlendedSecondary && secondary) {
          secondary?.sellerDetails?.forEach((element) => {
            const secondariesDetails = {
              id: secondary.id,
              sellerId: element.sellerId,
              buyerId: secondary.buyerId,
              sellerName: element.sellerName,
              buyerName: secondary.buyerName,
              fdbShares: element.fdbShares,
              amountInvested: element.amount,
              sharePrice: secondary.sharePrice,
              secondaryHoldingPercentage: element.holdingPercentage,
              postHoldingPercentage: 0,
              isBlendedSecondary: true,
            };
            secondaries.push(secondariesDetails);
          });
        } else {
          secondaries.push(secondary);
        }
      }
    );
    setSecondaryDetails(secondaries);
  }, [roundModel.secondaries?.secondaryAllotmentDetails]);

  useEffect(() => {
    const convertibleDetailsList: any[] = [];

    const convertedList = roundModel.convertibles?.convertibles.filter(
      (convertible) => convertible.isConverted === true
    );

    convertedList?.forEach((convertible) => {
      const investorList: any[] = [];

      convertible.investors.forEach((investorDetail) => {
        investorList.push(investorDetail);
      });

      investorList.forEach((investorDetail) => {
        const investor =
          roundModel.convertibles?.convertiblesCaptable?.investors.find(
            (inv) => inv.investorId === investorDetail.investorId
          );

        const shareholder = shareholderAndInstrumentstore?.shareholders.find(
          (shareholder) => shareholder.id === investor?.investorId
        );

        const instrumentClass =
          shareholderAndInstrumentstore?.instrumentClasses.find(
            (instrument) => instrument.id === convertible.instrumentClassId
          );

        convertibleDetailsList.push({
          id: investor?.investorId || "",
          investorName: investor?.investorName || "",
          securityName: convertible?.securityName || "",
          amountInvested: investor?.investmentAmount || 0,
          fdbShares: investor?.postHoldingFdbShares || 0,
          numberOfShares: investorDetail.postNumberOfShares || 0,
          investorType: shareholder?.type,
          securityType: instrumentClass?.subType,
        });
      });
    });
    setConvertibleDetails(convertibleDetailsList);
  }, [roundModel.convertibles?.convertibles, shareholderAndInstrumentstore]);

  return (
    <div className="gap-4">
      {(primaryInvestments.length > 0 || secondaryDetails.length > 0) && (
        <div className="bg-white border rounded-md border-borderColor shadow-box ">
          <HStack className="justify-between py-4">
            <GenericTableHeader
              heading={"Transaction"}
              subHeading={"summary of all the transaction in modelling"}
              count={undefined}
            />
            {secondaryDetails.find(
              (secondary) => !secondary.buyerName || !secondary.sellerName
            ) && (
              <div className="px-6">
                <AlertMessage
                  heading="Pending Secondary"
                  data="Generated Captable might be inaccurate due to unassigned secondary"
                />{" "}
              </div>
            )}
          </HStack>

          {
            <HStack className="items-center justify-between px-6 -mb-12 lg:justify-start">
              <HStack className="w-full gap-4">
                {primaryInvestments.length > 0 && (
                  <p
                    aria-current={currentTab === "Primary" ? "page" : undefined}
                    onClick={() => {
                      setCurrentTab("Primary");
                    }}
                    className={`${
                      currentTab === "Primary"
                        ? "text-primary font-medium border-b border-primary"
                        : "text-gray-500 font-medium"
                    } text-base cursor-pointer`}
                  >
                    Primary
                  </p>
                )}
                {secondaryDetails.length > 0 && (
                  <p
                    aria-current={
                      currentTab === "Secondary" ? "page" : undefined
                    }
                    onClick={() => {
                      setCurrentTab("Secondary");
                    }}
                    className={`${
                      currentTab === "Secondary"
                        ? "text-primary font-medium border-b border-primary"
                        : "text-gray-500 font-medium"
                    } text-base cursor-pointer`}
                  >
                    Secondary
                  </p>
                )}
                {convertibleDetails.length > 0 && (
                  <p
                    aria-current={
                      currentTab === "Convertible" ? "page" : undefined
                    }
                    onClick={() => {
                      setCurrentTab("Convertible");
                    }}
                    className={`${
                      currentTab === "Convertible"
                        ? "text-primary font-medium border-b border-primary"
                        : "text-gray-500 font-medium"
                    } text-base cursor-pointer`}
                  >
                    Convertible
                  </p>
                )}
              </HStack>
            </HStack>
          }
          <div className="pt-4">
            <div className="pt-4">
              {currentTab === "Primary" && (
                <NewInvestorAGGridTable data={primaryInvestments ?? []} />
              )}
              {currentTab === "Secondary" && (
                <SecondaryAgTable secondaryDetails={secondaryDetails} />
              )}
              {currentTab === "Convertible" && (
                <ModelConvertibleAGGridTable
                  outstandingConvertibles={convertibleDetails ?? []}
                />
              )}
            </div>
          </div>
        </div>
      )}
      <div className="my-8">
        <ModelSummaryAGGridTable
          data={roundModel.onGoingCaptable?.investors ?? []}
        />
      </div>
      <HStack className="justify-between gap-4">
        <SecondaryCTAButton
          type="reset"
          event-name="Back from summary tab"
          buttonSize={ButtonSize.SMALL}
          onClick={() => {
            onNext({ ...roundModel, currentTab: 5 });
            scrollToTop();
          }}
        >
          Back
        </SecondaryCTAButton>
        <HStack>
          <div className="justify-end pr-4 ">
            <PrimaryCTAButton
              className="h-8"
              event-name="Save Model Button"
              onClick={() => {
                connect();
                saveModel(roundModel);

                toast(
                  `Saved ${roundModel?.roundDetailsTab?.roundName} round model successfully`,
                  {
                    type: "success",
                    autoClose: 2000,
                  }
                );
                queryClient.invalidateQueries("listofmodeling");
              }}
              buttonSize={ButtonSize.SMALL}
            >
              Save Model
            </PrimaryCTAButton>
          </div>
          <PrimaryCTAButton
            event-name="Convert to proforma"
            disabled={onGoingTransaction}
            onClick={() => {
              saveModel(roundModel);
              navigate(`/transactions/round/create/${roundModel.modelId}`);
              toast(
                `Imported ${roundModel?.roundDetailsTab?.roundName} round model into Proforma successfully`,
                {
                  type: "success",
                  autoClose: 2000,
                }
              );
              queryClient.invalidateQueries("listofmodeling");
            }}
            buttonSize={ButtonSize.SMALL}
            type="submit"
          >
            Convert to Proforma
          </PrimaryCTAButton>
        </HStack>
      </HStack>
      {onGoingTransaction && (
        <p className="pt-2 text-gray-500 text-end">
          * Cannot convert model when performa Round is ongoing.
        </p>
      )}
    </div>
  );
};

export default ModelSummaryTab;
