import React from "react";
import { Box } from "@mui/material";
import { useFormikContext } from "formik";
import DeleteIcon from "@mui/icons-material/Delete";
import { Select } from "../components/shared/Select";
import { Input, Label, TextArea } from "../components/shared/InputField";
import { VStack, HStack, Error } from "../components/utils";
import {
  designationDropDownValues,
  typeOfValuation,
  valuationMethodology,
} from "../constants/DropDownValues";
import {
  CompanyDetailsPostModel,
  ValuationDataModel,
} from "../types/OnBoardDataModel";
import FileInput from "../components/shared/FileInput";
import convertToBase64 from "../utils/convertToBase64";
import {
  ButtonSize,
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../pages/quickRound/CTAButtonComponents";

type AddEditValuationProps = {
  valuation?: ValuationDataModel;
  valuationIndex: number;
  onPrimaryAction?: () => void;
  onSecondaryAction?: () => void;
  mode?: string;
};

const AddEditvaluation = ({
  valuation,
  valuationIndex,
  onPrimaryAction = () => {},
  onSecondaryAction = () => {},
  mode,
}: AddEditValuationProps) => {
  const formik = useFormikContext<CompanyDetailsPostModel>();

  const {
    values,
    touched,
    getFieldProps,
    setFieldValue,
    setFieldTouched,
    resetForm,
    errors,
    initialErrors,
    setFieldError,
  } = formik;

  const fieldTouched = touched?.valuationDetails?.[valuationIndex];
  const err = errors?.valuationDetails?.[
    valuationIndex
  ] as unknown as ValuationDataModel;
  const basicDetailErrors =
    err?.name ||
    err?.dateOfValuation ||
    err?.valuationPps ||
    err?.bookValue ||
    err?.typeOfValuation ||
    err?.valuationMethodology ||
    err?.valuationReportBase64 ||
    err?.udinNumber;

  const handleCancel = () => {
    if (mode === "Add") {
      if (!valuation) {
        setFieldValue(
          "valuationDetails",
          values?.valuationDetails?.filter(
            (_, index) => index !== valuationIndex
          )
        );
        resetForm();
      }
    }
    onSecondaryAction();
  };
  const handleClose = () => {
    onSecondaryAction();
  };

  const doPartialTouch = () => {
    setFieldTouched(`valuationDetails[${valuationIndex}].name`);
    setFieldTouched(`valuationDetails[${valuationIndex}].dateOfValuation`);
    setFieldTouched(`valuationDetails[${valuationIndex}].typeOfValuation`);
    setFieldTouched(`valuationDetails[${valuationIndex}].valuationPps`);
    setFieldTouched(`valuationDetails[${valuationIndex}].valuationMethodology`);
    setFieldTouched(`valuationDetails[${valuationIndex}].bookValue`);
    setFieldTouched(
      `valuationDetails[${valuationIndex}].valuationReportBase64`
    );
    setFieldTouched(`valuationDetails[${valuationIndex}].udinNumber`);
  };

  function handleDelete() {
    formik.values.valuationDetails[valuationIndex].valuationReportBase64 = "";
  }

  async function handleFileUpload(e: React.ChangeEvent<HTMLInputElement>) {
    if (!e?.target?.files?.[0]) return;
    const file = e.target.files[0];

    const base64 = await convertToBase64(file);
    formik.values.valuationDetails[valuationIndex].fileType = file.type;
    formik.values.valuationDetails[valuationIndex].valuationReportBase64 =
      base64 as string;
  }

  const handleSubmit = () => {
    if (valuation) {
      setFieldValue(`valuationDetails[${valuationIndex}].isEdit`, true);
    }
    onPrimaryAction();
  };
  return (
    <>
      <Box className="px-10 text-lg font-medium border-b py-4">
        <h6 className="flex justify-between">
          {!valuation ? "Add valuation Details" : "Edit valuation Details"}{" "}
          <span
            onClick={() => {
              handleClose();
            }}
            className="cursor-pointer"
          >
            X
          </span>{" "}
        </h6>
      </Box>
      <VStack className="w-full px-10 py-7 gap-9">
        <VStack className="w-full gap-9">
          <HStack className="gap-8 ">
            <div className="flex-1">
              <Label className="text-sm font-normal">
                Name<span className="text-rose-400">*</span>
              </Label>
              <Input
                type="text"
                placeholder="Enter the valuation name"
                className="w-96"
                {...getFieldProps(`valuationDetails[${valuationIndex}].name`)}
              />
              {fieldTouched?.name && err?.name && <Error text={err?.name} />}
            </div>
            <div>
              <Label className="text-sm font-normal">
                Date of Valuation<span className="text-rose-400">*</span>
              </Label>
              <Input
                type="date"
                placeholder="Eg:DD/MM/YYYY"
                className="w-96"
                {...getFieldProps(
                  `valuationDetails[${valuationIndex}].dateOfValuation`
                )}
              />
              {fieldTouched?.dateOfValuation && err?.dateOfValuation && (
                <Error text={err?.dateOfValuation} />
              )}
            </div>
          </HStack>
          <HStack className="gap-8">
            <div className="flex-1">
              <Label className="text-sm font-normal">
                Type of Valuation
                <span className="text-rose-400">*</span>
              </Label>
              <Select
                options={typeOfValuation}
                {...getFieldProps(
                  `valuationDetails[${valuationIndex}].typeOfValuation`
                )}
              />
              {fieldTouched?.typeOfValuation && err?.typeOfValuation && (
                <Error text={err?.typeOfValuation} />
              )}
            </div>

            <div className="flex-1">
              <Label className="text-sm font-normal">
                Valuation(PPS (₹))<span className="text-rose-400">*</span>
              </Label>
              <Input
                type="number"
                placeholder="Valuation Pps"
                className="w-96"
                {...getFieldProps(
                  `valuationDetails[${valuationIndex}].valuationPps`
                )}
              />
              {fieldTouched?.valuationPps && err?.valuationPps && (
                <Error text={err?.valuationPps.toString()} />
              )}
            </div>
          </HStack>
          <HStack className="gap-8">
            <div className="flex-1">
              <Label className="text-sm font-normal">
                Valuation Methodology <span className="text-rose-400">*</span>
              </Label>
              <Select
                options={valuationMethodology}
                {...getFieldProps(
                  `valuationDetails[${valuationIndex}].valuationMethodology`
                )}
              />
              {fieldTouched?.valuationMethodology &&
                err?.valuationMethodology && (
                  <Error text={err?.valuationMethodology} />
                )}
            </div>

            <div className="flex-1">
              <Label className="text-sm font-normal">
                Book Value <span className="text-rose-400">*</span>
              </Label>
              <Input
                type="number"
                placeholder="Book Value"
                className="w-96"
                {...getFieldProps(
                  `valuationDetails[${valuationIndex}].bookValue`
                )}
              />
              {fieldTouched?.bookValue && err?.bookValue && (
                <Error text={err?.bookValue.toString()} />
              )}
            </div>
          </HStack>

          <HStack className="gap-8">
            <div className="flex-1">
              <Label className="text-sm font-normal">Valuer</Label>
              <Input
                type="text"
                placeholder="Enter the valuer name"
                className="w-96"
                {...getFieldProps(`valuationDetails[${valuationIndex}].valuer`)}
              />
            </div>
            <div className="flex-1">
              <Label className="text-sm font-normal">Valuer Rg No.</Label>
              <Input
                type="text"
                placeholder="Valuer Rg No"
                className="w-96"
                {...getFieldProps(
                  `valuationDetails[${valuationIndex}].valuerRgNumber`
                )}
              />
            </div>
          </HStack>
          <HStack className="gap-8">
            <div className="flex-1">
              <Label className="text-sm font-normal">
                UDIN No.<span className="text-rose-400">*</span>
              </Label>
              <Input
                type="text"
                placeholder="Enter the udin Number"
                className="w-96"
                {...getFieldProps(
                  `valuationDetails[${valuationIndex}].udinNumber`
                )}
              />
              {fieldTouched?.udinNumber && err?.udinNumber && (
                <Error text={err?.udinNumber} />
              )}
            </div>
            <div className="flex-1">
              <Label>
                Valuation Report <span className="text-rose-400">*</span>
              </Label>
              <div className="py-1.5">
                {mode === "Edit" &&
                formik.values.valuationDetails[valuationIndex]
                  .valuationReportBase64 !== "" &&
                formik.values.valuationDetails !== null ? (
                  <div className="flex">
                    <FileInput
                      accept=".xls, .xlsx, application/pdf"
                      file={"Valuation-Report"}
                      onChange={(e) => {
                        handleFileUpload(e);
                      }}
                    />
                    <button
                      onClick={() => handleDelete()}
                      className="ml-6 text-zinc-300 hover:scale-105"
                    >
                      <DeleteIcon />
                    </button>
                  </div>
                ) : (
                  <FileInput
                    accept=".xls, .xlsx, application/pdf"
                    file={
                      formik.values.valuationDetails[valuationIndex]
                        .valuationReportBase64 !== ""
                        ? "Update File"
                        : "Attach File"
                    }
                    onChange={(e) => {
                      handleFileUpload(e);
                    }}
                  />
                )}
              </div>
            </div>
          </HStack>
          <HStack className="justify-between h-8">
            <SecondaryCTAButton
              event-name="Cancel Valuation Modal"
              buttonSize={ButtonSize.SMALL}
              onClick={() => {
                handleCancel();
              }}
              className="mr-8"
            >
              Cancel
            </SecondaryCTAButton>

            <PrimaryCTAButton
              event-name="Save Valuation Modal"
              buttonSize={ButtonSize.SMALL}
              onClick={() => {
                if (basicDetailErrors) {
                  doPartialTouch();
                } else {
                  handleSubmit();
                }
              }}
            >
              Save
            </PrimaryCTAButton>
          </HStack>
        </VStack>
      </VStack>
    </>
  );
};

export default AddEditvaluation;
