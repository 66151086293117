import { useEffect } from "react";
import { Box, HStack, VStack } from "../../components/utils";
import { useGetOnGoingTransaction } from "../../queries/transactionRound";
import BCHeader from "../../shared/BCHeader";
import { useAuthStore } from "../../store";
import AddTemplateBonus from "./AddTemplateBonus";
import StepperQuickBonusTransaction from "./StepperBonusTransaction";
import TransactionPreviewPage from "./TransactionPreviewPage";
import { useQuickBonusTransactionStore } from "../../store/useQuickTransactionStore";
import QuickAddBonus from "./QuickAddBonus";

function TransactionBonusTemplate() {
  const { state: template, setState: setTemplate } =
    useQuickBonusTransactionStore();
  const stepNo = template.stepNo;
  const companyId = useAuthStore.getState().companyId || "";
  const { refetch, data: onGoingTransaction } =
    useGetOnGoingTransaction(companyId);
  useEffect(() => {
    refetch();
  }, []);
  useEffect(() => {
    if (onGoingTransaction) setTemplate(onGoingTransaction);
    else {
      setTemplate({ stepNo: 1, transactionType: "bonus", companyId });
    }
  }, [onGoingTransaction]);

  return (
    <div className="min-h-full">
      <BCHeader
        className="items-baseline mb-2"
        bcTitle="Transaction Dashboard"
        bcSubTitle="Add Bonus"
      />
      {template.stepNo !== 2 ? <QuickAddBonus /> : null}
      {template.stepNo === 2 ? <TransactionPreviewPage /> : null}
    </div>
  );
}

export default TransactionBonusTemplate;
