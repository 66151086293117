import { memo, useMemo, useRef, useState } from "react";
import { CellClickedEvent, ColDef, GetDataPath } from "ag-grid-community";
import { useNavigate } from "react-router";
import { AgGridReact } from "ag-grid-react";
import { Box, Dialog } from "@mui/material";
import _ from "lodash";
import { setTableHeight } from "./utils";
import {
  getCurrencySymbol,
  getCurrencyType,
} from "../../utils/currencyFormatter";
import { tableCellStyle } from "../../components/TableComponent";
import { HStack } from "../../components/utils";
import { agConfigDashboardSideBar } from "../dashboardPage/AgGridConfig";
import GenericTableHeader from "../../shared/TableHeader";
import {
  AmountRender,
  FDBSharePercentRender,
  FDBShareRender,
  SellerNameRender,
} from "./AgComponent";
import SearchInput from "../../components/shared/SearchInput";
import {
  InvestorState,
  ModelBuilder,
  OngoingSecondaryAllotmentDetails,
  SecondaryAllotmentDetails,
} from "../../types/Modeling";
import { useRoundModelStore } from "../../store/roundModelingStore";
import DeleteModal from "../modeling/DeleteModal";
import AddNewSecondaryModal from "./SecondaryModal";
import {
  ButtonSize,
  SecondaryCTAButton,
} from "../quickRound/CTAButtonComponents";
import AddBlendedSecondaryModal from "./SecondaryBlendedModal";
import { CTADropdown } from "../../components/shared/Dropdown";
import { handleEventForTracking } from "../../amplitudeAnalytics";

function SecondaryAgTable({
  secondaryDetails,
  sharedPageData,
}: {
  secondaryDetails: any;
  sharedPageData?: ModelBuilder;
}) {
  const { sendMessage: onNext, roundCreation: roundCreationData } =
    useRoundModelStore();
  const gridApi = useRef<any>(null);
  const gridRef = useRef<AgGridReact>(null);
  const currencySymbol = getCurrencySymbol();

  const componentsRegistery = useMemo(
    () => ({
      sellerName: memo(SellerNameRender),
      fdbShares: memo(FDBShareRender),
      fdbSharePercent: memo(FDBSharePercentRender),
      amount: memo(AmountRender),
    }),
    []
  );

  const pathName = window.location.href.split("/");
  const sharedPage = useMemo(() => pathName[3] === "share", [pathName]);
  if (sharedPage && sharedPageData) {
    roundCreationData.currentTab = sharedPageData?.currentTab;
    roundCreationData.baseCaptableId = sharedPageData?.baseCaptableId;
    roundCreationData.companyId = sharedPageData?.companyId || "";
    roundCreationData.convertibles = sharedPageData?.convertibles;
    roundCreationData.errorMessage = sharedPageData?.errorMessage;
    roundCreationData.existingInvestorDetails =
      sharedPageData?.existingInvestorDetails;
    roundCreationData.highestVisitedTabLevel =
      sharedPageData.highestVisitedTabLevel;
    roundCreationData.isNavigating = sharedPageData?.isNavigating;
    roundCreationData.modelId = sharedPageData.modelId;
    roundCreationData.secondaries = sharedPageData.secondaries;
    roundCreationData.roundDetailsTab = sharedPageData.roundDetailsTab;
    roundCreationData.preCaptableHeaders = sharedPageData.preCaptableHeaders;
    roundCreationData.modelState = sharedPageData.modelState;
    roundCreationData.newInvestorDetails = sharedPageData.newInvestorDetails;
    roundCreationData.modelState = sharedPageData.modelState;
    roundCreationData.isTermsheet = sharedPageData.isTermsheet;
    roundCreationData.roundDetailsTab = sharedPageData.roundDetailsTab;
  }

  const columnDefs: ColDef[] = useMemo(
    () =>
      roundCreationData.currentTab === 6
        ? [
            {
              headerName: "FDB Shares",
              field: "fdbShares",
              filter: "agSetColumnFilter",
              cellRenderer: "fdbShares",
              cellStyle: tableCellStyle,
              sortable: true,
              menuTabs: ["filterMenuTab"],
              tooltipField: "fdbSharesTooltipField",
            },
            {
              headerName: "FDB Shares %",
              field: "fdbSharePercent",
              filter: "agNumberColumnFilter",
              cellRenderer: "fdbSharePercent",
              cellStyle: tableCellStyle,
              sortable: true,
              menuTabs: ["filterMenuTab"],
              tooltipField: "fdbSharePercentTooltipField",
            },
            {
              headerName: `Amount (${currencySymbol})`,
              field: "amount",
              filter: "agNumberColumnFilter",
              cellRenderer: "amount",
              cellStyle: tableCellStyle,
              sortable: true,
              menuTabs: ["filterMenuTab"],
              tooltipField: "amountTooltipField",
            },
          ]
        : [
            {
              headerName: "FDB Shares",
              field: "fdbShares",
              filter: "agSetColumnFilter",
              cellRenderer: "fdbShares",
              cellStyle: tableCellStyle,
              sortable: true,
              menuTabs: ["filterMenuTab"],
              tooltipField: "fdbSharesTooltipField",
            },
            {
              headerName: "FDB Shares %",
              field: "fdbSharePercent",
              filter: "agNumberColumnFilter",
              cellRenderer: "fdbSharePercent",
              cellStyle: tableCellStyle,
              sortable: true,
              menuTabs: ["filterMenuTab"],
              tooltipField: "fdbSharePercentTooltipField",
            },
            {
              headerName: `Amount (${currencySymbol})`,
              field: "amount",
              filter: "agNumberColumnFilter",
              cellRenderer: "amount",
              cellStyle: tableCellStyle,
              sortable: true,
              menuTabs: ["filterMenuTab"],
              tooltipField: "amountTooltipField",
            },
            {
              headerName: "",
              field: "action",
              pinned: "right",
              hide: false,
              width: 80,
              maxWidth: 80,
              filter: false,
              menuTabs: [],
              colId: "action-column",
              suppressNavigable: true,
              suppressColumnsToolPanel: true,
              cellStyle: { paddingTop: "10px", lineHeight: "20px" },
              resizable: false,
              sortable: false,
              cellRendererParams: (value: any) =>
                value.data.action ? value.data.action.props : <div></div>,
              cellRenderer: CTADropdown,
            },
          ],
    []
  );

  const navigate = useNavigate();

  const onClickShareholderName = (id: string) => {
    handleEventForTracking({ eventName: `Shareholder Name` });
    navigate(`/shareholders/individual/${id}`);
  };

  const isValidDate = (date: string) =>
    Number.isNaN(date) &&
    new Date(date).toString() !== "Invalid Date" &&
    !Number.isNaN(Date.parse(date));

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      autoHeight: true,
      wrapHeaderText: true,
      suppressColumnVirtualisation: true,
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      filterParams: {
        buttons: ["reset"],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
      flex: 1,
    }),
    [secondaryDetails]
  );

  const getRowStyle = (params: any) => {
    let rowStyle;
    if (params.rowIndex % 2 === 0) {
      rowStyle = { background: "#f8f8f8" };
    } else {
      rowStyle = { background: "#ffffff" };
    }

    if (params.node.level === 0) {
      rowStyle = {
        ...rowStyle,

        color: "black",
        fontWeight: 600,
      };
    }
    return rowStyle;
  };

  const autoGroupColumnDef = useMemo<ColDef>(
    () => ({
      headerName: "Buyer / Seller Name",
      minWidth: 250,
      menuTabs: [],
      getQuickFilterText: (params) => params.value,
      showRowGroup: false,
      cellRenderer: "agGroupCellRenderer",
      cellRendererParams: {
        suppressCount: true,
      },
      pinned: true,
    }),
    [secondaryDetails]
  );

  const getDataPath = useMemo<GetDataPath>(
    () => (data: any) => data.orgHierarchy,
    []
  );

  const currencyType = getCurrencyType();
  const gridOptions = {
    suppressRowVirtualisation: true,
    paginationAutoPageSize: false,
    suppressScrollOnNewData: true,
  };

  const rowData = useMemo(() => {
    if (!secondaryDetails) return [];
    return secondaryDetails.flatMap((secondary: SecondaryAllotmentDetails) => {
      const buyerName = secondary.buyerName
        ? secondary.buyerName
        : "NOT ASSIGNED";
      const fdbShares = secondary.fdbShares;
      const fdbSharePercent = secondary.secondaryHoldingPercentage;

      const amount = secondary.amountInvested;
      const fdbSharePercentTooltipField =
        secondary.secondaryHoldingPercentage.toLocaleString(currencyType);
      const fdbSharesTooltipField =
        secondary.fdbShares?.toLocaleString(currencyType);
      const amountTooltipField =
        secondary.amountInvested.toLocaleString(currencyType);
      const totalAmount = secondaryDetails.reduce(
        (acc: any, item: SecondaryAllotmentDetails) => {
          if (secondary.buyerName === item.buyerName)
            return acc + item.amountInvested;
          else {
            return acc;
          }
        },
        0
      );

      const totalFdbShares = secondaryDetails.reduce(
        (acc: any, item: SecondaryAllotmentDetails) => {
          if (secondary.buyerName === item.buyerName)
            return acc + item.fdbShares;
          else {
            return acc;
          }
        },
        0
      );

      const totalFdbSharePercent = secondaryDetails.reduce(
        (acc: any, item: SecondaryAllotmentDetails) => {
          if (secondary.buyerName === item.buyerName)
            return acc + item.secondaryHoldingPercentage;
          else {
            return acc;
          }
        },
        0
      );
      const sellerName = secondary.isBlendedSecondary
        ? `(B) ${secondary.sellerName ? secondary.sellerName : "NOT ASSIGNED"}`
        : secondary.sellerName ?? "NOT ASSIGNED";
      const action = secondary.isBlendedSecondary ? (
        <CTADropdown
          actions={[
            {
              name: "Edit",
              disabled: false,
            },
          ]}
          onAction={(action) => {
            handleModifyAction(action.name, secondary);
          }}
        />
      ) : (
        <CTADropdown
          actions={[
            {
              name: "Edit",
              disabled: false,
            },
            {
              name: "Delete",
              disabled: false,
            },
          ]}
          onAction={(action) => {
            handleModifyAction(action.name, secondary);
          }}
        />
      );
      return [
        {
          orgHierarchy: [buyerName],
          fdbShares: totalFdbShares,
          sellerName,
          amount: totalAmount,
          fdbSharePercent: totalFdbSharePercent,
          amountTooltipField: totalAmount.toLocaleString(currencyType),
          fdbSharePercentTooltipField:
            totalFdbSharePercent.toLocaleString(currencyType),
          action: secondary.isBlendedSecondary ? action : undefined,
          fdbSharesTooltipField: totalFdbShares.toLocaleString(currencyType),
          currencySymbol,
          currencyType,
        },
        {
          orgHierarchy: [buyerName, `${sellerName}`],
          sellerName,
          fdbShares,
          fdbSharePercent,
          amount,
          action: secondary.isBlendedSecondary ? undefined : action,
          amountTooltipField,
          fdbSharePercentTooltipField,
          fdbSharesTooltipField,
          currencySymbol,
          currencyType,
        },
      ];
    });
  }, [secondaryDetails]);

  const addedvalue = 0;

  const handleCellClick = (cellParams: CellClickedEvent<any, any>) => {
    if (cellParams.column.getColId() !== "action-column") {
      const template = cellParams.data;
      if (cellParams.column.getColId() === "shareholderName") {
        onClickShareholderName(template.id);
      }
    }
  };

  const openToolPanel = (key: any) => {
    if (key === "columns") {
      if (gridApi) {
        if (!isColumnOpen) gridApi?.current?.api?.openToolPanel(key);
        else gridApi?.current?.api?.closeToolPanel();
        setIsColumnOpen((state) => !state);
        setIsFilterOpen(false);
      }
    } else if (key === "filters") {
      if (gridApi) {
        if (!isFilterOpen) gridApi?.current?.api?.openToolPanel(key);
        else gridApi?.current?.api?.closeToolPanel();
        setIsFilterOpen((state) => !state);
        setIsColumnOpen(false);
      }
    }
  };

  const [filteredRowData, setFilteredRowData] = useState<any>([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const onAgGridFilterChanged = (grid: any) => {
    const filtersApplied = grid.api.isAnyFilterPresent();
    setIsFilterApplied(filtersApplied);

    const filteredData = grid.api
      .getModel()
      .rowsToDisplay.map((node: any) => node.data);
    setFilteredRowData(filteredData);
  };

  const [dialog, setDialog] = useState<{
    open: boolean;
    mode?: string;
    data?: any;
  }>({
    open: false,
  });

  function handleModifyAction(action: string, data: SecondaryAllotmentDetails) {
    if (action === "Delete") {
      setDialog({
        open: true,
        mode: "Delete",
        data,
      });
    } else if (action === "Edit" && data.isBlendedSecondary) {
      setDialog({
        open: true,
        mode: "Blended",
        data,
      });
    } else if (action === "Edit" && !data.isBlendedSecondary) {
      setDialog({
        open: true,
        mode: "Edit",
        data,
      });
    }
  }

  const [isColumnOpen, setIsColumnOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  function setPinnedBottomRowData({ api }: { api: any }) {
    const data = api.rowModel.rootNode;
    const filteredData = data.childrenAfterAggFilter.map(
      (element: any) => element.data
    );
    const totalAmountToInvest = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + data.amountToInvest,
      0
    );
    const totalShares = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + data.shares,
      0
    );

    const totalSecondaryHoldingPercentage = filteredData?.reduce(
      (accumulator: any, data: any) =>
        accumulator + data.secondaryHoldingPercentage,
      0
    );

    api.setPinnedBottomRowData([
      {
        id: "",
        buyerName: "Total",
        sellerName: "",
        amountToInvest: totalAmountToInvest,
        tooltipAmountToInvest: totalAmountToInvest.toLocaleString(currencyType),
        shares: totalShares,
        sharePrice: null,
        secondaryHolding: totalSecondaryHoldingPercentage,
        tooltipSecondaryHolding: totalSecondaryHoldingPercentage,
        postholding: null,
        hasBlended: false,
        actions: <div></div>,
        currencySymbol,
        currencyType,
      },
    ]);
  }

  const handleSubmit = async (id: string) => {
    const ongoingExistingInvestorDetails: OngoingSecondaryAllotmentDetails = {
      id,
      sellerId: "",
      buyerId: "",
      sellerName: "",
      buyerName: "",
      fdbShares: 0,
      amountInvested: 0,
      sharePrice: 0,
      state: InvestorState.ToBeDeleted,
    };
    const newList: ModelBuilder = {
      ...roundCreationData,
      secondaries: {
        listOfBuyers: roundCreationData.secondaries?.listOfBuyers ?? [],
        listOfSellers: roundCreationData.secondaries?.listOfSellers ?? [],
        secondaryAllotmentDetails:
          roundCreationData.secondaries?.secondaryAllotmentDetails ?? [],
        ongoingSecondaryAllotmentDetails: ongoingExistingInvestorDetails,
      },
    };
    onNext(newList);
    setDialog({ open: false });
  };
  return (
    <>
      <div className="bg-white rounded-md border-borderColor shadow-box">
        <Dialog open={dialog.open} maxWidth="lg">
          {dialog.mode === "Delete" && (
            <DeleteModal
              onPrimaryAction={() => {
                handleSubmit(dialog.data?.id || "");
              }}
              onSecondaryAction={() => setDialog({ open: false })}
              data={
                "Are you sure you want to delete the entry? Data will be permanently removed. This action cannot be undone."
              }
            />
          )}
          {dialog.mode === "Add" && (
            <AddNewSecondaryModal
              data={dialog?.data}
              listOfSecondaries={secondaryDetails}
              actionMode={"New"}
              onBack={() => setDialog({ open: false })}
              onNext={onNext}
            />
          )}
          {dialog.mode === "Edit" && (
            <AddNewSecondaryModal
              data={dialog?.data}
              listOfSecondaries={secondaryDetails}
              actionMode={"Edit"}
              onBack={() => setDialog({ open: false })}
              onNext={onNext}
            />
          )}
          {dialog.mode === "Blended" && (
            <AddBlendedSecondaryModal
              data={dialog?.data}
              actionMode={"New"}
              onBack={() => setDialog({ open: false })}
              onNext={onNext}
            />
          )}
        </Dialog>
        <HStack className="flex-col items-start justify-start py-2 lg:flex-row lg:justify-between lg:items-center ">
          {roundCreationData.currentTab === 5 ? (
            <GenericTableHeader
              heading="Secondary Transaction"
              subHeading="Transaction"
              count={secondaryDetails.length}
            />
          ) : (
            <div></div>
          )}

          <HStack className="items-center justify-between w-full gap-4 px-8 lg:w-auto lg:justify-end">
            <SearchInput
              placeholder={`Search`}
              onChange={(e: any) => {
                gridApi.current.api.setQuickFilter(e.target.value);
              }}
            />
            {roundCreationData.currentTab === 6 ? (
              <div></div>
            ) : (
              <SecondaryCTAButton
                className="h-8"
                buttonSize={ButtonSize.SMALL}
                onClick={() => {
                  setDialog({ open: true, mode: "Add" });
                }}
              >
                + Add
              </SecondaryCTAButton>
            )}
          </HStack>
        </HStack>

        <HStack className="justify-between w-full">
          <Box
            style={{
              height: setTableHeight(secondaryDetails.length),
            }}
            className="w-full h-full max-h-full overflow-x-auto ag-theme-material"
          >
            <AgGridReact
              sideBar={agConfigDashboardSideBar}
              ref={gridRef}
              onGridReady={(params) => {
                // Store the grid API referen
                gridApi.current = params;
              }}
              components={componentsRegistery}
              alwaysShowHorizontalScroll
              alwaysMultiSort
              animateRows={true}
              defaultColDef={defaultColDef}
              onFilterChanged={onAgGridFilterChanged}
              rowData={rowData}
              onCellClicked={handleCellClick}
              columnDefs={columnDefs}
              autoGroupColumnDef={autoGroupColumnDef}
              treeData={true}
              groupDefaultExpanded={-1}
              pagination={false}
              getDataPath={getDataPath}
              suppressRowTransform={true}
              suppressCopyRowsToClipboard={true}
              suppressCopySingleCellRanges={true}
              suppressCellFocus={true}
              suppressMenuHide={false}
              tooltipShowDelay={1000}
              tooltipInteraction={true}
              gridOptions={gridOptions}
              getRowStyle={getRowStyle}
            ></AgGridReact>
          </Box>
        </HStack>
      </div>
    </>
  );
}

export default SecondaryAgTable;
