/* eslint-disable array-callback-return */
import { useEffect, useState } from "react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import Box from "@mui/material/Box";
import { useFormik } from "formik";
import * as Yup from "yup";
import { HStack, VStack, Error } from "../../components/utils";
import {
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../quickRound/CTAButtonComponents";
import { Input, Label } from "../../components/shared/InputField";
import DropDownInput from "../../components/shared/DropDownInput";
import { SwitchDropDownModel } from "../rounds/RoundsAllTransactions";
import { getCurrencySymbol } from "../../utils/currencyFormatter";
import {
  InvestorState,
  ModelBuilder,
  OngoingSecondaryAllotmentDetails,
  SecondaryAllotmentDetails,
} from "../../types/Modeling";
import { useRoundModelStore } from "../../store/roundModelingStore";
import { AddNewSecondaryModel } from "./RoundModelingModel";

export interface ShareholderDropDown {
  name: string;
  type: string;
  id: string;
  fdbShares?: number;
}

type AddNewSecondaryProps = {
  onNext: (data: ModelBuilder) => void;
  onBack: () => void;
  data?: SecondaryAllotmentDetails;
  listOfSecondaries?: SecondaryAllotmentDetails[];
  actionMode: string;
};

const AddNewSecondaryModal = ({
  onNext = () => {},
  onBack = () => {},
  data,
  listOfSecondaries,
  actionMode,
}: AddNewSecondaryProps) => {
  const roundCreationState = useRoundModelStore();
  const [sellersShareholderlist, setSellersShareholder] = useState<
    ShareholderDropDown[]
  >([]);
  const [buyerShareholderlist, setBuyerShareholder] = useState<
    ShareholderDropDown[]
  >([]);

  const currencySymbol = getCurrencySymbol();
  const secondaryInitialValues = {
    id: data?.id || "",
    buyerId: data?.buyerId || "",
    sellerId: data?.sellerId || "",
    buyerName:
      roundCreationState.roundCreation.secondaries?.listOfBuyers.find(
        (e) => e.investorId === data?.buyerId
      )?.investorName ?? "",
    sellerName:
      roundCreationState.roundCreation.secondaries?.listOfSellers.find(
        (e) => e.investorId === data?.sellerId
      )?.investorName ?? "",
    shares: data?.fdbShares || 0,
    amount: data?.amountInvested || 0,
  };
  const [selectedBuyer, setSelectedBuyer] = useState<ShareholderDropDown>({
    name: data?.buyerName ?? "",
    type: "",
    id: data?.buyerId || "",
  });

  const [selectedSeller, setSelectedSeller] = useState<ShareholderDropDown>({
    name:
      roundCreationState.roundCreation.secondaries?.listOfSellers.find(
        (e) => e.investorId === data?.sellerId
      )?.investorName ?? "",
    type: "",
    id: data?.sellerId || "",
  });
  const [maximumShare, setMaximumShare] = useState(0);
  const secondaryValidationSchema = Yup.object({
    buyerName:
      selectedSeller.id === ""
        ? Yup.string().required().label("Buyer Name")
        : Yup.string().label("Buyer Name"),

    sellerName:
      selectedBuyer.id === ""
        ? Yup.string().required().label("Seller Name")
        : Yup.string().label("Seller Name"),

    // sellerName: Yup.string().required().label("Seller Name"),
    // eslint-disable-next-line newline-per-chained-call
    shares:
      selectedSeller.id === ""
        ? Yup.number().min(1).required().label("Shares")
        : Yup.number()
            .min(1)
            .max(maximumShare ?? 0)
            .required()
            .label("Shares"),
    amount: Yup.number().min(1).required().label("Amount"),
  });

  const formik = useFormik<AddNewSecondaryModel>({
    initialValues: secondaryInitialValues,
    validationSchema: secondaryValidationSchema,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  useEffect(() => {
    const maximum =
      sellersShareholderlist.find((ele) => ele.id === formik.values.sellerId)
        ?.fdbShares ?? 0;
    setMaximumShare(maximum + (data?.fdbShares ?? 0));
  }, [formik.values.sellerId, maximumShare]);
  useEffect(() => {
    const sellersShareholder =
      roundCreationState.roundCreation.secondaries?.listOfSellers.map(
        (seller) => ({
          name: seller.investorName,
          type: "",
          id: seller.investorId,
          fdbShares: seller.totalFdbShares,
        })
      ) ?? [];
    const buyerShareholderlist =
      roundCreationState.roundCreation.secondaries?.listOfBuyers.map(
        (buyer) => ({
          name: buyer.investorName,
          type: "",
          id: buyer.investorId,
          fdbShares: buyer.totalFdbShares,
        })
      ) ?? [];
    setSellersShareholder(sellersShareholder);
    setBuyerShareholder(buyerShareholderlist);
    if (actionMode === "Edit") {
      const maximum =
        roundCreationState.roundCreation.secondaries?.listOfSellers.find(
          (ele) => ele.investorId === data?.sellerId
        )?.totalFdbShares ?? 0;
      setMaximumShare(maximum ?? 0 + (data?.fdbShares ?? 0));
    }
  }, []);
  // const handleSubmit = async (values: AddNewSecondaryModel) => {
  // setLoading(true);
  // setLoading(false);
  // const shareholders =
  //   roundCreationState.roundCreation.secondaries?.secondaryAllotmentDetails ||
  //   ([] as SecondaryAllotmentDetails[]);
  // if (actionMode === "New") {
  //   shareholders.push({
  //     buyerName: values.buyerName,
  //     sellerName: values.sellerName,
  //     amountInvested: values.amount,
  //     buyerId: values.buyerId,
  //     noOfShares: values.shares,
  //     sellerId: values.sellerId,
  //     state: "New",
  //   });
  //   onNext({
  //     ...roundCreationState.roundCreation,
  //     companyId,
  //     tab6: {
  //       transaction: shareholders,
  //       id: isNewBuyer ? values.id : "",
  //       newBuyerName: isNewBuyer ? values.buyerName : "",
  //       isValuesChanged: isNewBuyer,
  //     },
  //   });
  // } else if (actionMode === "Edit") {
  //   const shareholderList = shareholders.map((element) => {
  //     if (element.id === values.id) {
  //       return {
  //         buyerName: values.buyerName,
  //         sellerName: values.sellerName,
  //         securityName: values.security,
  //         amountInvested: values.amount,
  //         buyerId: values.buyerId,
  //         currencyType: "INR",
  //         instrumentId:
  //           extractSecurityByName(
  //             values.security,
  //             shareholderInstrumentStore.instrumentClasses
  //           )?.id || "",
  //         noOfShares: values.shares,
  //         sellerId: values.sellerId,
  //         state: "Edit",
  //       };
  //     } else {
  //       return element;
  //     }
  //   });
  //   onNext({
  //     ...roundCreationState.roundCreation,
  //     companyId,
  //     tab6: {
  //       transaction: shareholderList,
  //       id: isNewBuyer ? values.id : "",
  //       newBuyerName: isNewBuyer ? values.buyerName : "",
  //       isValuesChanged: isNewBuyer,
  //     },
  //   });
  // }
  // onBack();
  // };
  const handleSubmit = async (values: AddNewSecondaryModel) => {
    const ongoingExistingSecondaryDetails: OngoingSecondaryAllotmentDetails = {
      id: data?.id ?? "",
      sellerId: values.sellerId,
      buyerId: values.buyerId,
      sellerName: values.sellerName,
      buyerName: values.buyerName,
      fdbShares: values.shares,
      amountInvested: values.amount,
      sharePrice: 0,
      state:
        actionMode === "Edit" ? InvestorState.Update : InvestorState.ToBeSaved,
    };
    hitSocketApi(ongoingExistingSecondaryDetails);
    onBack();
  };

  const hitSocketApi = (
    ongoingExistingSecondaryDetails?: OngoingSecondaryAllotmentDetails
  ) => {
    const secondaries = roundCreationState.roundCreation.secondaries;
    const data: ModelBuilder = {
      ...roundCreationState.roundCreation,
      secondaries: {
        listOfBuyers: secondaries?.listOfBuyers ?? [],
        listOfSellers: secondaries?.listOfSellers ?? [],
        secondaryAllotmentDetails: secondaries?.secondaryAllotmentDetails ?? [],
        ongoingSecondaryAllotmentDetails: ongoingExistingSecondaryDetails,
      },
    };
    onNext(data);
  };
  return (
    <div className="w-full">
      <Box className="px-4 text-lg font-medium border-b pt-4 pb-2">
        <h6 className="flex justify-between">
          <VStack>
            <p className="font-medium text-lg">
              {actionMode === "Edit" ? "Edit Secondary" : "Add Secondary"}
            </p>
          </VStack>
          <XMarkIcon
            onClick={() => onBack()}
            className="cursor-pointer h-6 w-6"
          />
        </h6>
      </Box>
      <VStack className="w-full py-7 gap-9">
        <form
          onSubmit={formik.handleSubmit}
          id="exportSheetDetails"
          className="form"
          noValidate
        >
          <VStack className="w-full gap-6 px-10">
            <HStack className="gap-4">
              <VStack className="flex-1">
                <Label className="font-medium text-sm whitespace-nowrap mt-3 mr-4">
                  Buyer Name
                </Label>
                <Box className="w-72 mb-12">
                  <DropDownInput
                    data={buyerShareholderlist.filter(
                      (shareHolder) =>
                        shareHolder.name.toLowerCase().trim() !== "esop pool" &&
                        shareHolder.name !== selectedSeller.name
                    )}
                    className={"bg-slate-light w-72 rounded-md border-none"}
                    placeholder="Search Buyer..."
                    getEnteredValue={(data: any) => {
                      formik.setFieldValue("buyerName", data);
                    }}
                    getSelectedValue={(data: any) => {
                      if (data.name !== "" && data.id !== "") {
                        formik.setFieldValue("buyerName", data.name);
                        formik.setFieldValue("buyerId", data.id);
                        setSelectedBuyer(data);
                      }
                    }}
                    selectedOption={
                      selectedBuyer as unknown as ShareholderDropDown
                    }
                    field={"name" as keyof SwitchDropDownModel}
                  />
                </Box>
                {formik.touched.buyerName && formik.errors.buyerName && (
                  <Error text={formik.errors.buyerName} />
                )}
              </VStack>
              <VStack className="flex-1">
                <Label className="font-medium text-sm whitespace-nowrap mt-3 mr-4">
                  Seller Name
                </Label>
                <Box className="w-72 mb-12">
                  <DropDownInput
                    data={sellersShareholderlist.filter(
                      (ele) =>
                        ele.name.toLowerCase().trim() !== "esop pool" &&
                        ele.id !== selectedBuyer.id &&
                        !listOfSecondaries
                          // eslint-disable-next-line array-callback-return
                          ?.filter((ele2) => {
                            // eslint-disable-next-line no-unused-expressions
                            selectedBuyer.id === ele2.buyerId;
                          })
                          .find((ele3) => {
                            // eslint-disable-next-line no-unused-expressions
                            ele3.sellerName === ele.name;
                          })
                    )}
                    className={"bg-slate-light w-72 rounded-md border-none"}
                    placeholder="Search Seller..."
                    getSelectedValue={(data: any) => {
                      if (data.name !== "" && data.id !== "") {
                        formik.setFieldValue("sellerName", data.name);
                        formik.setFieldValue("sellerId", data.id);
                        setSelectedSeller(data);
                      }
                    }}
                    selectedOption={
                      selectedSeller as unknown as ShareholderDropDown
                    }
                    field={"name" as keyof SwitchDropDownModel}
                  />
                </Box>
                {formik.touched.sellerName && formik.errors.sellerName && (
                  <Error text={formik.errors.sellerName} />
                )}
              </VStack>
            </HStack>
            <HStack className="gap-4">
              <VStack className="flex-1">
                <Label className="font-medium text-sm whitespace-nowrap mt-3 mr-4">
                  Amount{` (${currencySymbol})`}
                </Label>
                <Input
                  type="number"
                  placeholder="Amount"
                  className="w-72"
                  {...formik.getFieldProps("amount")}
                />

                {formik.touched.amount && formik.errors.amount && (
                  <Error text={formik.errors.amount} />
                )}
              </VStack>
              <VStack className="flex-1">
                <Label className="font-medium text-sm whitespace-nowrap mt-3 mr-4">
                  No of Shares
                </Label>
                <Input
                  type="number"
                  placeholder="Shares"
                  className="w-72"
                  {...formik.getFieldProps("shares")}
                  onInput={(e) => {
                    const inputElement = e.target as HTMLInputElement;
                    inputElement.value = Math.round(
                      Number(inputElement.value)
                    ).toString();
                  }}
                />
                {formik.touched.shares && formik.errors.shares && (
                  <Error text={formik.errors.shares} />
                )}
                {selectedSeller.id !== "" && (
                  <p className="text-xxs text-[#A6A6A6] font-medium pt-1">
                    ~Maximum shares you can transfer:
                    {maximumShare}
                  </p>
                )}
              </VStack>
            </HStack>
          </VStack>
          <HStack className="justify-between mt-10 px-10">
            <SecondaryCTAButton
              event-name="Cancel Secondary Modeling Modal"
              onClick={() => onBack()}
            >
              Cancel
            </SecondaryCTAButton>
            <PrimaryCTAButton
              event-name="Save Secondary Modeling Modal"
              loading={roundCreationState.roundCreation.isNavigating}
              type="submit"
            >
              Save
            </PrimaryCTAButton>
          </HStack>
        </form>
      </VStack>
    </div>
  );
};

export default AddNewSecondaryModal;
