import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  ChartType,
} from "chart.js";
import _ from "lodash";
import { format } from "date-fns";
import { Box } from "../../components/utils";
import { RoundsDetailModel } from "../../types/DashboardModel";
import { prefrenceColorList } from "../../constants/ShareholderSecurityTypes";
import { limitString } from "../../utils/string";
import {
  getCurrencySymbol,
  getCurrencyType,
} from "../../utils/currencyFormatter";
import { formatToReadableNumber } from "../../utils/currencyRoboto";
import { formatDisplayDate } from "../../utils/date";
import { getShareholderValue } from "../../constants/ShareholderConstants";

ChartJS.register(ArcElement, Tooltip, Legend);

interface Dataset {
  label: string;
  date?: any[];
  data: any[];
  backgroundColor: string;
  yAxisID?: string;
  type?: string;
  borderColor?: string;
  pointStyle?: string;
  pointRadius?: number;
  fill?: boolean;
  borderWidth?: number;
}

export default function DashboardRoundsBarChart({
  roundsDetail,
}: {
  roundsDetail: RoundsDetailModel[];
}) {
  const currency = getCurrencySymbol();
  const values = roundsDetail[0].holding;

  const dataset: Dataset[] = [];
  dataset.push({
    label: "Dilution",
    type: "line" as ChartType,
    date: roundsDetail.map((element) => element.date),
    yAxisID: "y",
    data: roundsDetail.map((element) => element.dilution),
    backgroundColor: "#F86161",
    borderColor: "#F86161",
    pointStyle: "circle",
    pointRadius: 3,
    fill: false,
    borderWidth: 2,
  });
  dataset.push({
    label: "Valuation",
    date: roundsDetail.map((element) => element.date),
    yAxisID: "y1",
    type: "line" as ChartType,
    data: roundsDetail.map((element) => element.valution),
    backgroundColor: "#2DA900",
    borderColor: "#2DA900",
    borderWidth: 2,
    pointStyle: "circle",
    pointRadius: 3,
    fill: false,
  });

  values.forEach((element, index) =>
    dataset.push({
      label: getShareholderValue(element.shareholderType ?? "") ?? "",
      data: element.percentage,
      date: roundsDetail.map((element) => element.date),
      backgroundColor: prefrenceColorList[index],
      yAxisID: "y",
    })
  );

  const getWidth = () =>
    0.2 * roundsDetail.length > 1.5 ? 1 : 0.1 * roundsDetail.length;

  const width = getWidth();
  const data: any = {
    labels: roundsDetail.map((element) => {
      const roundName = element?.roundName.split(" ");
      if (roundName.length >= 2)
        return [
          ...roundName.slice(0, 2),
          `(${format(new Date(element.date), "ddMMMyy")})`,
        ];
      return [...roundName, `(${format(new Date(element.date), "ddMMMyy")})`];
    }),
    datasets: dataset,
  };

  const currencyType = getCurrencyType();

  const options: any = {
    responsive: true,
    maintainAspectRatio: false,
    categoryPercentage: 0.5,
    barPercentage: width,
    borderRadius: 5,
    interaction: {
      mode: "point",
      intersect: false,
      //for discrete labels
      includeInvisible: false,
    },
    plugins: {
      mode: "label",
      tooltip: {
        usePointStyle: true,
        bodyFont: {
          weight: "bold",
          size: 14,
        },
        titleColor: "#464E5F",
        bodyColor: "#464E5F",
        footerColor: "#464E5F",
        footerFont: {
          weight: "bold",
          size: 10,
        },
        displayColors: true,
        backgroundColor: "#FFFFFF",
        callbacks: {
          footer: (context: any) =>
            `Event Date: ${formatDisplayDate(
              context[0].dataset.date[context[0].dataIndex]
            )}`,
          title: (context: any) => {
            const roundName = context[0].label.replaceAll(",", " ");
            const nameIndex = roundName.lastIndexOf("(");
            return roundName.slice(0, nameIndex);
          },
          label: (context: any) => {
            const labels: any[] = [];
            const formattedValue = context.raw.toLocaleString();

            const displayValue =
              context.dataset?.label === "Valuation"
                ? `${currency} ${formatToReadableNumber({
                    value: context.raw,
                    format: currencyType,
                  })}`
                : `${formattedValue}%`;

            labels.push(`${context.dataset?.label} : ${displayValue}`);

            return labels;
          },
        },
      },
      legend: {
        display: true,
        position: "bottom" as const,
        labels: {
          boxWidth: 10,
        },
      },
    },
    tooltips: {
      mode: "index",
      intersect: false,
    },
    hover: {
      mode: "index",
      intersect: false,
    },
    elements: {
      bar: {},
    },
    scales: {
      x: {
        display: true,
        stacked: true,
        grid: {
          display: false,
          offset: true,
        },
        ticks: {
          font: { size: 12 },
          padding: 10,
          maxRotation: 0,
          minRotation: 0,
        },
      },
      y: {
        display: true,
        stacked: true,
        min: 0,
        max: 100,
        grid: {
          borderDash: [1, 3],
          color: "#e8e8e8",
        },
        ticks: {
          beginAtZero: true,
          min: 0,
          max: 100,
          font: { size: 12 },
          callback(value: any, index: any, ticks: any) {
            return `${value}%`;
          },
        },
      },
      y1: {
        display: true,
        stacked: true,
        position: "right",
        grid: {
          borderDash: [1, 3],
          color: "#e8e8e8",
        },
        ticks: {
          beginAtZero: true,
          min: 0,
          max: 100,
          font: { size: 12 },
          callback(value: any, index: any, ticks: any) {
            const data = currency;
            return `${data} ${formatToReadableNumber({
              value,
              format: currencyType,
            })}
            `;
          },
        },
      },
    },
  };

  return (
    <Box className="w-full h-96 pt-10 px-4 align-center">
      {options ? <Bar options={options} data={data} /> : <p></p>}
    </Box>
  );
}
