import { useState } from "react";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import { Dialog } from "@mui/material";
import {
  CompanyDetailsPostModel,
  DropDownModel,
} from "../../types/OnBoardDataModel";
import { formatDate } from "../../utils/date";
import { initialCompanyDetail } from "./initValues";
import BCHeader from "../../shared/BCHeader";
import AlertDialog from "../../components/shared/AlertDialog";
import CompanyDetailForm from "../onboarding/CompanyDetailForm";
import BusinessDetailForm from "../onboarding/BusinessDetailForm";
import FounderTable from "../onboarding/FounderTable";
import DirectorTable from "../onboarding/DirectorTable";
import { useAuthStore } from "../../store";
import { HStack, VStack } from "../../components/utils";
import CompanyShareCapital from "./CompanyShareCapital";
import {
  useCreateCompanyDetail,
  useGetCompanyConfig,
  useGetCompanyDetails,
  useSetCompanyConfig,
} from "../../queries/termsheet";
import { useCanUserAccess } from "../../store/useRoleGrantStore";
import ValuationTable from "../onboarding/ValuationTable";
import { invalidateDashboardQueries } from "../../utils/invalidateDashboard";
import { PrimaryCTAButton } from "../quickRound/CTAButtonComponents";
import AlertMessage from "../../shared/AlertMessage";
import { queryClient } from "../../queries";

interface FileTypeModel {
  fileBlob: string;
  fileName?: string;
  fileType?: string;
}
function CompanyProfile() {
  const companyId = useAuthStore.getState().companyId || "";

  const { data: nodeCompanyDetail } = useGetCompanyDetails();
  const { mutate: nodeCreateCompanyDetails } = useCreateCompanyDetail();
  const { mutate: companyConfig } = useSetCompanyConfig();
  const { data: configData } = useGetCompanyConfig(companyId);
  const [companyLogoImage, setCompanyLogoImage] = useState<FileTypeModel>({
    fileBlob: "",
  });
  const [dialog, setDialog] = useState<{
    open: boolean;
    message?: string;
  }>({
    open: false,
  });

  const canEditCompany = useCanUserAccess("update", "companydetail");

  const getInitialValue = () =>
    nodeCompanyDetail
      ? {
          id: nodeCompanyDetail.companyDetails.id || "",
          nameOfTheCompany: nodeCompanyDetail?.companyDetails.name || "",
          dippCertificateNumber:
            nodeCompanyDetail?.companyDetails?.dippCertificateNumber || "",
          cinNumber: nodeCompanyDetail?.companyDetails?.cinNumber || "",
          typeOfTheCompany:
            nodeCompanyDetail?.companyDetails?.typeOfCompany || "",
          companyCurrency:
            nodeCompanyDetail?.companyDetails?.companyCurrency || "",
          dateOfIncorporation: formatDate(
            nodeCompanyDetail?.companyDetails?.dateOfIncorporation
          ),
          corporateOfficeAddress:
            nodeCompanyDetail?.companyDetails?.corporateOfficeAddress || "",
          companyEmailId:
            nodeCompanyDetail?.companyDetails?.companyEmailId || "",
          companyWebsite:
            nodeCompanyDetail?.companyDetails?.companyWebsite || "",
          companyLogoPath: nodeCompanyDetail?.companyDetails?.companyLogo || "",
          doingBusinessAs:
            nodeCompanyDetail?.companyDetails?.doingBusinessAs || "",
          nic: nodeCompanyDetail?.companyDetails?.nicCode || "",

          industry: nodeCompanyDetail?.companyDetails?.industry || [],
          category: nodeCompanyDetail?.companyDetails?.category || [],

          founders: nodeCompanyDetail?.founders || [],
          directors: nodeCompanyDetail?.directors || [],
          valuationDetails: nodeCompanyDetail?.valuationDetails || [],

          equityAuthorizedShareCapital:
            nodeCompanyDetail?.capitalDetails.equityAuthorizedCaptial || 0,
          preferenceAuthorizedShareCapital:
            nodeCompanyDetail?.capitalDetails.preferenceAuthorizedCaptial || 0,
          equityPaidUpShareCapital:
            nodeCompanyDetail?.capitalDetails.equityPaidUpCaptial || 0,
          preferencePaidUpShareCapital:
            nodeCompanyDetail?.capitalDetails.preferencePaidUpCaptial || 0,
          splitFilter: configData?.splitFilter || false,
        }
      : initialCompanyDetail;
  const handleCompanyDetailsSubmit = (values: CompanyDetailsPostModel) => {
    if (!canEditCompany) return;

    const incorpDate = new Date(values.dateOfIncorporation).toUTCString();
    const data = {
      id: values.id,
      nameOfTheCompany: values.nameOfTheCompany,
      dippCertificateNumber: values.dippCertificateNumber,
      doingBusinessAs: values.doingBusinessAs,
      cinNumber: values.cinNumber,
      typeOfTheCompany: values.typeOfTheCompany,
      dateOfIncorporation: incorpDate,
      corporateOfficeAddress: values.corporateOfficeAddress,
      companyEmailId: values.companyEmailId,
      companyWebsite: values.companyWebsite,
      companyCurrency: values.companyCurrency,
      nic: values.nic,
      companyLogoPath: values.companyLogoPath,
    };

    const data1 = {
      nameOfTheCompany: values.nameOfTheCompany,
      cinNumber: values.cinNumber,
      typeOfTheCompany: values.typeOfTheCompany,
      dateOfIncorporation: values.dateOfIncorporation,
      corporateOfficeAddress: values.corporateOfficeAddress,
      companyEmailId: values.companyEmailId,
      dippCertificateNumber: values.dippCertificateNumber,
      companyWebsite: values.companyWebsite,
      companyLogoPath: values.companyLogoPath,
      companyCurrency: values.companyCurrency,
      doingBusinessAs: values.doingBusinessAs,
      category: values.category,
      industry: values.industry,
      nic: values.nic,
      founders: values.founders,
      directors: values.directors,
      valuationDetails: values.valuationDetails,
      capitalDetails: {
        valuation: nodeCompanyDetail?.capitalDetails.valuation,
        pps: nodeCompanyDetail?.capitalDetails.pps,
        companyId: nodeCompanyDetail?.capitalDetails?.companyId || companyId,
        equityAuthorizedCaptial: values.equityAuthorizedShareCapital,
        preferenceAuthorizedCaptial: values.preferenceAuthorizedShareCapital,
        equityPaidUpCaptial: values.equityPaidUpShareCapital,
        preferencePaidUpCaptial: values.preferenceAuthorizedShareCapital,
      },
    };
    nodeCreateCompanyDetails(data1, {
      onSuccess: () => {
        toast("Company Profile updated Successfully!", {
          type: "success",
          autoClose: 2000,
        });
        queryClient.invalidateQueries("userCompanies");
        queryClient.refetchQueries("userCompanies");
      },
      onError: (err: any) => {
        toast(err.response.data.errorMessage, {
          type: "error",
          autoClose: 2000,
        });
      },
    });
    const dataConfig = {
      splitFilter: values.splitFilter,
      companyId,
    };
    companyConfig(dataConfig, {
      onSuccess: () => {
        invalidateDashboardQueries();
        toast("Split Filter applied", {
          type: "success",
          autoClose: 2000,
        });
      },
      onError: (err: any) => {
        toast(err, {
          type: "error",
          autoClose: 2000,
        });
      },
    });
  };
  const validationCompanyDetailsSchema = Yup.object().shape({
    nameOfTheCompany: Yup.string().required("Company Name is required"),
    cinNumber: Yup.string().required("CIN Number is required"),
    typeOfTheCompany: Yup.string().required("Company Type is required"),
    dateOfIncorporation: Yup.string().required(
      "Date of Incorporation is required"
    ),
    companyEmailId: Yup.string()
      .email("Enter Valid Company Email is required")
      .required("Email is required"),
    companyCurrency: Yup.string().required("Company Currency is required"),
    nic: Yup.string().required("NIC is required"),
    industry: Yup.array().min(1, "Industry is Required"),
    category: Yup.array().min(1, "Category is Required"),
    valuationDetails: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string().required("Valuation Name is required"),
          valuationPps: Yup.number().required("Valuation PPS is required"),
          dateOfValuation: Yup.string().required(
            "Date Of Valuation is required"
          ),
          typeOfValuation: Yup.string().required(
            "Type Of Valuation is required"
          ),
          valuationMethodology: Yup.string().required(
            "Valuation Methodology is required"
          ),
          bookValue: Yup.number().required("Book Value is required"),
          valuationReportBase64: Yup.string().required(
            "Valuation Report is required"
          ),
          udinNumber: Yup.string().required("UDIN Number is required"),
        })
      )
      .required("Required"),
    founders: Yup.array()
      .of(
        Yup.object().shape({
          fullName: Yup.string().required("Founder Name is required"),
          emailId: Yup.string()
            .email("Valid Founder Email Id is required")
            .required("Valid Email Id is required"),
          nationality: Yup.string().required("Nationality is required"),
        })
      )
      .required("Required"),
    directors: Yup.array()
      .of(
        Yup.object().shape({
          fullName: Yup.string().required("Director Name is required"),
          emailId: Yup.string()
            .email("Valid Director Email Id is required")
            .required("Valid Email Id is required"),
          typeOfDirector: Yup.string().required("Type of Director is required"),
          din: Yup.string().required("DIN Number is required"),
          nationality: Yup.string().required("Nationality is required"),
        })
      )
      .required("Required"),
    splitFilter: Yup.boolean().optional(),
  });

  const getDropDownValue = () => {
    const dropDownOptions: DropDownModel = {
      selectedCategoryValues: nodeCompanyDetail?.companyDetails.category,
      selectedIndustryValues: nodeCompanyDetail?.companyDetails.industry,
      industryDropdownValues: nodeCompanyDetail?.industryDropdownValues || [],
      categoryDropdownValues: nodeCompanyDetail?.categoryDropdownValues || [],
    };
    return dropDownOptions;
  };

  return (
    <div className="gap-4 mx-auto">
      <BCHeader className="items-baseline mb-4" bcTitle="Company Details" />
      <Formik
        enableReinitialize={true}
        initialValues={getInitialValue()}
        onSubmit={(values: CompanyDetailsPostModel, error: any) => {
          handleCompanyDetailsSubmit(values);
        }}
        validationSchema={validationCompanyDetailsSchema}
      >
        {(formik) => (
          <>
            <Form>
              <VStack className="gap-8">
                <Dialog open={dialog.open} maxWidth="md">
                  <AlertDialog
                    onClose={() => setDialog({ open: false })}
                    open={dialog.open}
                    message={dialog.message}
                    onSecondaryAction={() => setDialog({ open: false })}
                  />
                </Dialog>
                <CompanyDetailForm
                  companyLogoImage={companyLogoImage?.fileBlob}
                  setCompanyLogoImage={setCompanyLogoImage}
                />
                <CompanyShareCapital />
                <BusinessDetailForm dropDownValue={getDropDownValue()} />
                <ValuationTable />
                <FounderTable />
                <DirectorTable />
                <HStack className="justify-end items-baseline gap-8">
                  {!formik.isValid && (
                    <AlertMessage
                      heading="Missing Required Field"
                      data="some of the required feilds are not filled please check and "
                    />
                  )}
                  <PrimaryCTAButton
                    className="h-10"
                    event-name="Save Company Detail Changes"
                    type="submit"
                    disabled={!canEditCompany}
                  >
                    Save Changes
                  </PrimaryCTAButton>
                </HStack>
              </VStack>
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
}

export default CompanyProfile;
