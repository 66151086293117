import { useRef, useState } from "react";
import { useNavigate } from "react-router";

import {
  tableEntryStyle,
  tableFirstColStyle,
} from "../../components/TableComponent";

import {
  formatToReadableNumber,
  getCurrencySymbolRoboto,
} from "../../utils/currencyRoboto";
import Avatar from "../Utility/Avatar";
import RoundTag from "../../shared/RoundTag";
import { getSecurityColor } from "../../utils/historicUtilities";
import { VStack } from "../../components/utils";

export function SecurityClassRender(data: any) {
  return (
    <VStack className="whitespace-nowrap py-2 justify-center">
      <p className={tableFirstColStyle}>{data.data.securityClass}</p>
      {data.data.demat && (
        <p className="px-2 w-fit text-xxs border rounded-sm border-1 border-[#2DA900] text-[#2DA900]">
          Demat
        </p>
      )}
    </VStack>
  );
}

function SecurityTag({ security }: { security: string }) {
  return (
    <p
      className={`${getSecurityColor(
        security
      )} text-xs text-center align-middle font-semibold leading-5 rounded-sm`}
    >
      {security}
    </p>
  );
}

export function SecurityTypeRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-2">
      <SecurityTag security={data.data.securityType} />
    </div>
  );
}

export function NumberOfSharesRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-2">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.data?.numberOfShares,
          format: data.data?.currencyType,
        })}
      </p>
    </div>
  );
}

export function RoundsRender(data: any) {
  const navigate = useNavigate();
  const onClickNavigate = (url: string) => navigate(url);
  return (
    <div className="whitespace-nowrap pt-2">
      <RoundTag
        roundNames={data.data.rounds || []}
        navigate={data.data.cin ? () => {} : onClickNavigate}
      />
    </div>
  );
}

export function PPSRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-2">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: Math.abs(data.data.pps),
          format: data.data.currencyType,
        })}
      </p>
    </div>
  );
}

export function AmountInvestedRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-2">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.data?.amountInvested,
          format: data.data.currencyType,
        })}
      </p>
    </div>
  );
}

export function CurrentValueRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-2">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.data?.currentValue,
          format: data.data.currencyType,
        })}
      </p>
    </div>
  );
}
