import create from "zustand";

type AllTransactionTableFilterState = {
  transactionType: string;
  setTransactionType: (text: string) => void;
  reset: () => void;
};

const initialState = {
  transactionType: "All",
};
const useStore = create<AllTransactionTableFilterState>((set) => ({
  ...initialState,
  setTransactionType: (transactionType) => {
    set((state) => ({ ...state, transactionType }));
  },
  reset: () => {
    set(() => initialState);
  },
}));

export const useAllTransactionTableFilterState = useStore;
