import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import BCHeader from "../../shared/BCHeader";
import CompanyoverviewCard from "./Type2CompanyOverviewCard";
import { Type2CompanyPageModel } from "../../types/T2CompanyModel";
import { useType2CompanyOverviewDetails } from "../../queries/t2Company";
import { handleEventForTracking } from "../../amplitudeAnalytics";

export const dataDict: Record<string, string> = {
  id: "ID",
  name: "Name",
  cin: "Corporate Identification Number (CIN)",
  dba: "Doing Business As (DBA)",
  valuation: "Valuation",
  lastRoundName: "Last Round Name",
  lastRoundSize: "Last Round Size",
  lastRoundDate: "Last Round Date",
  sharePrice: "Share Price",
  totalFundsRaised: "Total Funds Raised",
  totalNumberOfRounds: "Total Number of Rounds",
  totalNumberOfShares: "Total Number of Shares",
  jurisdiction: "Jurisdiction",
  companyCurrency: "Company Currency",
  isPrivate: "Is Private",
  createdAt: "Created At",
  updatedAt: "Updated At",
  isDeleted: "Is Deleted",
};

export default function Type2CompanyPage() {
  const { cin, token } = useParams();
  const { data: type2CompanyDetails } = useType2CompanyOverviewDetails(
    cin ?? "",
    token
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (!cin) {
      handleEventForTracking({ eventName: "Type 2 Company" });
      navigate("/404");
    }
  }, []);
  const checkKeyDate = (key: string) =>
    ["Created At", "Updated At", "Last Round Date"].includes(key);
  return (
    <div className={"mx-auto max-w-7xl sm:px-6 lg:px-8"}>
      <BCHeader className="items-baseline mb-2" bcTitle="Company Overview" />
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {type2CompanyDetails &&
          Object.keys(type2CompanyDetails).map((key) => {
            const typedKey = key as keyof Type2CompanyPageModel;
            const value = type2CompanyDetails[typedKey]?.toString() || "";
            return (
              <div key={key} className="bg-white p-4  rounded-md">
                <CompanyoverviewCard
                  key={key}
                  name={dataDict[typedKey]}
                  value={value}
                  isDate={checkKeyDate(dataDict[typedKey])}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
}
