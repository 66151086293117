import { QueryFunctionContext } from "react-query";
import api from "./capTableApi";
import {
  ShareHolderRightsModel,
  ShareHolderRightsTemplate,
} from "../types/ShareHolderRightsModel";
import {
  GroupTypeModel,
  RightsInputGroupName,
} from "../pages/shareHolderRights/DragAndDrop";

export async function allGetShareHolderRight(
  context: QueryFunctionContext
): Promise<ShareHolderRightsModel[]> {
  const companyId = context.queryKey[1];
  const cinNumber = context.queryKey[2];
  return api
    .get(
      `/v1/company/investorRights/overview?companyId=${companyId}&cinNumber=${cinNumber}`
    )
    .then((res) => res.data.data);
}

export async function getAllIndividualShareHolderRight(
  context: QueryFunctionContext
): Promise<ShareHolderRightsModel[]> {
  const companyId = context.queryKey[1];
  const shareholderId = context.queryKey[2];
  const cin = context.queryKey[3];
  return api
    .get(
      `/v1/company/investorRights/shareholderRights?companyId=${companyId}&shareholderId=${shareholderId}&cin=${cin}`
    )
    .then((res) => res.data.data);
}

export async function downloadShareHolderRightsTemplate(): Promise<ShareHolderRightsTemplate> {
  return api
    .get(`/v1/company/investorRights/downloadSHATemplate`)
    .then((res) => res.data);
}
export async function downloadShareHolderRightsExcelSheet(
  context: QueryFunctionContext
): Promise<ShareHolderRightsTemplate> {
  const companyId = context.queryKey[1];
  return api
    .get(`/v1/company/investorRights/downloadSHAMatrix?companyId=${companyId}`)
    .then((res) => res.data);
}

export async function uploadImportedShareHolderRightsTemplate({
  companyId,
  formData,
  cinNumber,
}: {
  companyId: string;
  formData: FormData;
  cinNumber: string;
}): Promise<void> {
  return api
    .post(
      `/v1/company/investorRights/importExcel/?companyId=${companyId}&cinNumber=${cinNumber}`,
      formData
    )
    .then((res) => res.data);
}

export async function uploadImportedShareHolderRightsFile({
  companyId,
  formData,
  cinNumber,
  rightsDetailId,
}: {
  companyId: string;
  formData: FormData;
  cinNumber: string;
  rightsDetailId: string;
}): Promise<string> {
  return api
    .post(
      `/v1/company/investorRights/importCompanyFile?companyId=${companyId}&cinNumber=${cinNumber}&rightsDetailId=${rightsDetailId}`,
      formData
    )
    .then((res) => res.data.data.id);
}

export async function getCaptableShareholderMapingTable(
  context: QueryFunctionContext
): Promise<RightsInputGroupName> {
  const companyId = context.queryKey[1];
  const rightsId = context.queryKey[2];
  const cin = context.queryKey[3];
  return api
    .get(
      `/v1/company/investorRights/shareholderGroup?companyId=${companyId}&cinNumber=${cin}&rightsId=${rightsId}`
    )
    .then((res) => res.data.data);
}

export async function discardRightsTable(
  context: QueryFunctionContext
): Promise<RightsInputGroupName> {
  const rightsId = context.queryKey[1];
  const companyId = context.queryKey[2];
  const cin = context.queryKey[3];
  return api
    .delete(
      `/v1/company/investorRights/rightstable?rightsId=${rightsId}&companyId=${companyId}&cin=${cin}`
    )
    .then((res) => res.data.data);
}

export async function updateCaptableShareholderMapingTable({
  rightsId,
  companyId,
  cinNumber,
  columns,
}: {
  rightsId: string;
  companyId: string;
  cinNumber: string;
  columns: GroupTypeModel[];
}): Promise<void> {
  return api
    .put(
      `/v1/company/investorRights/shareholderGroup?companyId=${companyId}&cinNumber=${cinNumber}&rightsId=${rightsId}`,
      columns
    )
    .then((res) => res.data.data);
}
