import { useState } from "react";
import { toast } from "react-toastify";
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
} from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { SharedUserModel } from "./ShareModal";
import { useSubmitSharedEmail, useUpdatePermission } from "../../queries/share";

const people = [
  { id: 2, name: "VIEW" },
  { id: 3, name: "REMOVE" },
];

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export default function SelectInputUI({
  value,
  shareId,
  resourceType,
}: {
  value: SharedUserModel;
  shareId: string;
  resourceType: string;
}) {
  const { mutate: updatePermission } = useUpdatePermission();
  return (
    <Listbox
      value={value}
      onChange={(data: any) => {
        updatePermission(
          {
            users: {
              accessExpiry: value.accessExpiry,
              email: value.email,
              id: value.id,
              permission: data.name,
              sharedBy: value.sharedBy,
            },
            shareId,
            resourceType,
          },
          {
            onSuccess: () => {
              if (data.name === "REMOVE")
                toast("User Removed successfully!", {
                  type: "success",
                  autoClose: 2000,
                });
              else
                toast("Permission Changed successfully!", {
                  type: "success",
                  autoClose: 2000,
                });
            },
            onError: (err: any) => {
              toast(err.response.data.errorMessage, {
                type: "error",
                autoClose: 2000,
              });
            },
          }
        );
      }}
    >
      {({ open }) => (
        <>
          <div className="relative">
            <ListboxButton className="relative w-full cursor-default rounded-md bg-white pl-3 pr-8 text-left text-gray-900 focus:outline-none sm:text-xs sm:leading-6">
              <span className="block truncate">{value.permission}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronDownIcon
                  className="h-4 w-4 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </ListboxButton>
            <Transition
              show={open}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <ListboxOptions className="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-xs shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-xs">
                {people.map((person) => (
                  <ListboxOption
                    key={person.id}
                    className={({ focus }) =>
                      classNames(
                        focus ? "bg-secondary/5 text-secondary" : "",
                        !focus ? "text-secondary" : "",
                        "relative cursor-default select-none py-2 pl-3 pr-9"
                      )
                    }
                    value={person}
                  >
                    {({ selected, focus }) => (
                      <>
                        <span
                          className={classNames(
                            selected
                              ? "font-semibold text-primary"
                              : "font-normal",
                            "block"
                          )}
                        >
                          {person.name}
                        </span>
                      </>
                    )}
                  </ListboxOption>
                ))}
              </ListboxOptions>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
