import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  CellClickedEvent,
  ColDef,
  GridApi,
  RowDataUpdatedEvent,
  SideBarDef,
} from "ag-grid-community";
import { useNavigate } from "react-router";

import { AgGridReact } from "ag-grid-react";
import _ from "lodash";
import { Action, CTADropdown } from "../../components/shared/Dropdown";
import { agConfigDashboardSideBar } from "../dashboardPage/AgGridConfig";

import {
  getCurrencySymbol,
  getCurrencyType,
} from "../../utils/currencyFormatter";

import { Box, HStack, useGetCompanyName } from "../../components/utils";
import GenericTableHeader from "../../shared/TableHeader";
import {
  ButtonSize,
  IconCTAButton,
  PrimaryCTAButton,
} from "../quickRound/CTAButtonComponents";
import QuickSearch from "../rounds/QuickSearch";
import { useAuthStore } from "../../store";
import {
  useGetOnFilterState,
  usePostOnFilterState,
} from "../dashboardPage/AgGridCacheQuery";
import { tableCellStyle } from "../../components/TableComponent";
import { ExportImport } from "../Utility/GrantsTable";
import {
  exportSharecertificateReport,
  exportShareholderReport,
} from "../../api/Report";
import { downloadExcel } from "../../utils/DownloadFile";
import { useAgGridTableState } from "../../store/agGridTableStore";
import SearchInput from "../../components/shared/SearchInput";
import { RoundsToolTip } from "../dashboardPage/customTooltip";
import { getShareholderValue } from "../../constants/ShareholderConstants";
import { Label } from "../../components/shared/InputField";
import { FromRender, ToRender } from "./SplitShareRangesAgComponent";

export type SplitShareRangeRecord = {
  index: number;
  from: number;
  to: number;
  actions: any;
};

function SplitShareRangesAgTable({
  ranges,
  gridApi,
}: {
  ranges: number[][];
  gridApi: any;
}) {
  const currencySymbol = getCurrencySymbol();
  const companyName = useGetCompanyName();
  const companyId = useAuthStore().companyId ?? "";
  const userId = useAuthStore().user?.userId ?? 0;

  const componentsRegistery = useMemo(
    () => ({
      from: memo(FromRender),
      to: memo(ToRender),
    }),
    []
  );

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      autoHeight: true,
      wrapHeaderText: true,
      suppressColumnVirtualisation: true,
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      filterParams: {
        buttons: ["reset"],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
      flex: 1,
    }),
    []
  );
  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        headerName: "From",
        field: "from",
        filter: "agMultiColumnFilter",
        comparator(valueA: any, valueB: any, ..._: any[]) {
          return valueA.name > valueB.name ? 1 : -1;
        },
        pinned: "left",
        cellRenderer: "from",
        initialWidth: 250,
        sortable: false,
        autoHeight: true,
        cellStyle: tableCellStyle,
        suppressSizeToFit: true,
        editable: true,
      },
      {
        headerName: "To",
        field: "to",
        initialWidth: 250,
        cellRenderer: "to",
        cellStyle: tableCellStyle,
        sortable: false,
        autoHeight: true,
        editable: true,
      },
      {
        headerName: "",
        field: "actions",
        pinned: "right",
        hide: false,
        width: 80,
        maxWidth: 80,
        filter: false,
        menuTabs: [],
        colId: "action-column",
        suppressNavigable: true,
        suppressColumnsToolPanel: true,
        cellStyle: { "padding-top": "10px", "line-height": "20px" },
        resizable: false,
        sortable: false,
        cellRendererParams: ({ value }: { value: any }) => value?.props,
        cellRenderer: CTADropdown,
      },
    ],
    []
  );

  const deleteRowData = (index: number) => {
    const currentData = getRowData();
    gridApi.current.api.applyTransaction({ remove: [currentData[index]] });
  };

  function handleModifyAction(action: string, index: number) {
    if (action === "Delete") {
      deleteRowData(index);
    }
  }

  const getRowData = () => {
    const currentData: SplitShareRangeRecord[] = [];
    gridApi.current.api.forEachNode((node: any) => {
      currentData.push(node.data);
    });
    return currentData;
  };

  const rowData = useMemo(
    () =>
      ranges.map((range, index) => ({
        index,
        from: range[0],
        to: range[1],
        actions: (
          <CTADropdown
            actions={[
              {
                name: "Delete",
                disabled: false,
              },
            ]}
            onAction={(action) => {
              handleModifyAction(action.name, index);
            }}
          />
        ),
      })),
    [ranges]
  );

  return (
    <div>
      <HStack className="flex-col lg:flex-row justify-start lg:justify-between  py-2 lg:items-center items-start  bg-white rounded-md">
        <Label className="text-sm font-normal items-center pr-5 m-2">
          Enter New Share Numbers:
        </Label>
      </HStack>
      <p className="text-xs text-gray-400">
        Edit the table values by clicking on the cell
      </p>
      <HStack className="justify-between w-full">
        <Box
          style={{
            height: 300,
          }}
          className="w-[580px] max-h-full overflow-x-auto ag-theme-material h-full border"
        >
          <AgGridReact
            sideBar={agConfigDashboardSideBar}
            onGridReady={(params) => {
              // Store the grid API referen
              gridApi.current = params;
            }}
            components={componentsRegistery}
            alwaysShowHorizontalScroll
            alwaysMultiSort
            animateRows={true}
            defaultColDef={defaultColDef}
            columnDefs={columnDefs}
            rowData={rowData}
            suppressRowTransform={true}
            suppressCopyRowsToClipboard={true}
            suppressCopySingleCellRanges={true}
            suppressCellFocus={true}
            suppressMenuHide={false}
            stopEditingWhenCellsLoseFocus={true}
            singleClickEdit={true}
            rowClass={"border-t border-dashed "}
            overlayNoRowsTemplate={
              '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow; margin-top: 50px;">No Rows To Show</span>'
            }
            getRowStyle={(params) => {
              if (params.rowIndex % 2 === 0) {
                return { background: "#f8f8f8" };
              } else {
                return { background: "#ffffff" };
              }
            }}
          ></AgGridReact>
        </Box>
      </HStack>
      <HStack className="justify-start mt-3">
        <PrimaryCTAButton
          buttonSize={ButtonSize.SMALL}
          event-name="Split Share Add Row"
          onClick={() => {
            const data = getRowData();
            gridApi.current.api.applyTransaction({
              add: [
                {
                  index: data.length,
                  from: 0,
                  to: 0,
                  actions: (
                    <CTADropdown
                      actions={[
                        {
                          name: "Delete",
                          disabled: false,
                        },
                      ]}
                      onAction={(action) => {
                        handleModifyAction(action.name, data.length);
                      }}
                    />
                  ),
                },
              ],
            });
          }}
        >
          Add Row
        </PrimaryCTAButton>
      </HStack>
    </div>
  );
}

export default SplitShareRangesAgTable;
