import Tooltip from "../../components/shared/Tooltip";
import { HStack, VStack } from "../../components/utils";
import { RoundHeaderType } from "../../constants/RoundsConstant";
import { getCurrencyType, getCurrency } from "../../utils/currencyFormatter";
import {
  FormatNumberSpan,
  getCurrencySymbolRoboto,
  getRoundCurrencySymbol,
} from "../../utils/currencyRoboto";
import { formatDisplayDate } from "../../utils/date";

export const RoundHeaderCards = ({
  roundDetail,
  headerType,
}: {
  roundDetail: any;
  headerType: RoundHeaderType;
}) => {
  const symbol = getCurrencySymbolRoboto();
  const currency = getCurrencyType();
  const roundSymbol = getRoundCurrencySymbol(roundDetail.currency);
  const roundCurrency = getCurrency(roundDetail.currency);
  switch (headerType) {
    case RoundHeaderType.RedemptionHeader:
      return (
        <div
          key={roundDetail?.name || ""}
          className="relative rounded-lg bg-white border border-borderColor shadow-box p-4 px-6 "
        >
          <dt className="flex flex-row justify-between items-center">
            <p className=" truncate text-lg font-medium text-[#464E5F] mr-5">
              Redemption
            </p>
          </dt>
          <dd className=" flex items-baseline flex-col">
            <HStack className="text-3xl items-baseline font-medium text-[#078b04b3] pt-2 pb-1 whitespace-nowrap">
              <span className="text-xl">{symbol}</span>
              <FormatNumberSpan
                value={Math.abs(roundDetail?.amountRedeemed)}
                format={currency}
              />
            </HStack>
          </dd>
          <HStack className="justify-between whitespace-nowrap">
            <p className={`text-xs text-[#464E5F] font-medium`}>
              {formatDisplayDate(roundDetail.dateOfTransaction)}
            </p>
            <p className={`text-xs text-[rgb(70,78,95)] font-medium`}>
              {roundDetail.securityName}
            </p>
          </HStack>
        </div>
      );

    case RoundHeaderType.RedemptionnShares:
      return (
        <div
          key={roundDetail.name}
          className="relative rounded-lg bg-white px-4 py-2 border border-borderColor shadow-box"
        >
          <dt className="flex flex-row justify-between items-center">
            <p className=" truncate text-lg font-medium text-[#464E5F]">
              Redeemed Shares:
            </p>
          </dt>
          <dd className=" flex items-baseline flex-col whitespace-nowrap">
            <p className="text-3xl font-medium text-[#464E5F] pt-2 pb-1 whitespace-nowrap">
              <FormatNumberSpan
                value={roundDetail?.numberOfSharesRedeemed}
                format={currency}
              />
            </p>
            <HStack className="">
              <HStack
                className={`text-base text-[#078b04b3] items-baseline font-semibold whitespace-nowrap`}
              >
                <span className="text-[#464E5F] font-medium">Par Value :</span>
                <span className="text-xs font-medium">{symbol}</span>
                <FormatNumberSpan value={roundDetail?.par} format={currency} />
              </HStack>
            </HStack>
          </dd>
        </div>
      );

    case RoundHeaderType.ConversionHeader:
      return (
        <div
          key={roundDetail?.name || ""}
          className="relative rounded-lg bg-white p-4 px-6 border border-borderColor shadow-box"
        >
          <dt className="flex flex-row justify-between items-center">
            <p className=" truncate text-lg font-medium text-[#464E5F] mr-5">
              Conversion
            </p>
          </dt>
          <dd className=" flex items-baseline flex-col">
            <HStack className="text-3xl items-baseline font-medium text-[#078b04b3] pt-2 pb-1 whitespace-nowrap">
              <span className="text-xl">{symbol}</span>
              <FormatNumberSpan
                value={Math.abs(roundDetail?.amount)}
                format={currency}
              />
            </HStack>
          </dd>
          <HStack className="justify-between whitespace-nowrap">
            <p className={`text-xs text-[#464E5F] font-medium`}>
              {formatDisplayDate(roundDetail.dateOfTransaction)}
            </p>
          </HStack>
        </div>
      );

    case RoundHeaderType.ConversionSrcSecurity:
      return (
        <div
          key={roundDetail.name}
          className="flex w-full flex-col rounded-lg bg-white px-4 py-2 border border-borderColor shadow-box justify-between"
        >
          <dd className="flex items-baseline flex-col text-lg font-medium text-[#464E5F]">
            <VStack className="w-full">
              <p className="text-sm pt-2 pb-1 whitespace-nowrap">
                Source Security:
              </p>
              <p className="text-sm text-center font-semibold text-[#078b04b3] rounded bg-[#E8FFF3] py-2 px-2 whitespace-nowrap">
                {roundDetail.sourceSecurityName}
              </p>
            </VStack>
            <HStack className="justify-between space-x-8 mt-1">
              <HStack className="items-center">
                <p className="text-sm pt-2 pb-1 whitespace-nowrap">PAR:</p>
                <p className="text-sm text-center font-semibold pt-2 pb-1 whitespace-nowrap">
                  {roundDetail.sourceSecurityPar}
                </p>
              </HStack>
              <HStack className="items-center">
                <p className="text-sm pt-2 pb-1 whitespace-nowrap">
                  No of Shares:
                </p>
                <p className="text-sm text-center font-semibold pt-2 pb-1 whitespace-nowrap">
                  {roundDetail.numberOfSharesConverted}
                </p>
              </HStack>
            </HStack>
          </dd>
        </div>
      );

    case RoundHeaderType.ConversionDestSecurity:
      return (
        <div
          key={roundDetail.name}
          className="flex w-full flex-col rounded-lg bg-white px-4 py-2 border border-borderColor shadow-box justify-between"
        >
          <dd className="flex items-baseline flex-col text-lg font-medium text-[#464E5F]">
            <VStack className="w-full">
              <p className="text-sm pt-2 pb-1 whitespace-nowrap">
                Source Security:
              </p>
              <p className="text-sm text-center font-semibold text-[#078b04b3] rounded bg-[#E8FFF3] py-2 px-2 whitespace-nowrap">
                {roundDetail.destinationSecurityName}
              </p>
            </VStack>
            <HStack className="justify-between space-x-8 mt-1">
              <HStack className="items-center">
                <p className="text-sm pt-2 pb-1 whitespace-nowrap">PAR:</p>
                <p className="text-sm text-center font-semibold pt-2 pb-1 whitespace-nowrap">
                  {roundDetail.destinationSecurityPar}
                </p>
              </HStack>
              <HStack className="items-center">
                <p className="text-sm pt-2 pb-1 whitespace-nowrap">
                  No of Shares:
                </p>
                <p className="text-sm text-center font-semibold pt-2 pb-1 whitespace-nowrap">
                  {roundDetail.finalNoOfShares}
                </p>
              </HStack>
            </HStack>
          </dd>
        </div>
      );

    case RoundHeaderType.RoundDetailHeader:
      return (
        <div
          key={roundDetail?.name || ""}
          className="relative rounded-lg bg-white p-4 px-6 border border-borderColor shadow-box"
        >
          <dt className="flex flex-row justify-between items-center">
            <p className=" truncate text-lg font-medium text-[#464E5F] mr-5">
              {roundDetail?.type || ""}
            </p>
            {roundDetail.identifier &&
              roundDetail.type?.toLocaleLowerCase() === "Round" && (
                <p className="truncate text-sm font-semibold text-[#3699FF] bg-[#E5F2FF] rounded-sm p-1">
                  {roundDetail.identifier}
                </p>
              )}
          </dt>
          <dd className=" flex items-baseline flex-col">
            <HStack className="text-3xl items-baseline font-medium text-[#078b04b3] pt-2 pb-1 whitespace-nowrap">
              <span className="text-xl">{symbol}</span>
              <FormatNumberSpan
                value={Math.abs(roundDetail?.amountRaised)}
                format={currency}
              />
            </HStack>
          </dd>
          <HStack className="justify-between whitespace-nowrap">
            <Tooltip text={roundDetail.name}>
              <p className={`text-xs text-[#464E5F] font-medium mr-5`}>
                {roundDetail.name}
              </p>
            </Tooltip>
            <p className={`text-xs text-[#464E5F] font-medium`}>
              {formatDisplayDate(roundDetail.dateOfTransaction)}
            </p>
          </HStack>
        </div>
      );

    case RoundHeaderType.SecondaryDetailHeader:
      return (
        <div
          key={roundDetail?.name || ""}
          className="relative rounded-lg bg-white p-2 px-6 border border-borderColor shadow-box"
        >
          <dt className="flex flex-row justify-between items-center">
            <Tooltip text={roundDetail.type} _position="bottom-0">
              <p className=" truncate text-lg font-medium text-[#464E5F] mr-5">
                {(roundDetail?.type[0].toUpperCase() || "") +
                  (roundDetail.type.slice(1) || "")}
              </p>
            </Tooltip>
            {roundDetail.identifier &&
              roundDetail.type?.toLocaleLowerCase() === "Round" && (
                <Tooltip text={roundDetail.identifier} _position="bottom-0">
                  <p className=" truncate text-sm font-semibold text-[#3699FF] bg-[#E5F2FF] rounded-sm p-1">
                    {roundDetail.identifier}
                  </p>
                </Tooltip>
              )}
          </dt>
          <dd className=" flex items-baseline flex-col">
            <HStack className="text-3xl items-baseline font-medium text-[#078b04b3] pt-2 pb-1 whitespace-nowrap">
              <span className="text-xl">{symbol}</span>
              <FormatNumberSpan
                value={Math.abs(roundDetail?.transactionAmount)}
                format={currency}
              />
            </HStack>
          </dd>
          <HStack className="justify-between whitespace-nowrap mt-1">
            <Tooltip text={roundDetail.name} _position="bottom-0">
              <p className={`text-xs text-[#464E5F] font-medium mr-5`}>
                {roundDetail.name.split(" ")[0]}
              </p>
            </Tooltip>
            <p className={`text-xs text-[#464E5F] font-medium`}>
              {formatDisplayDate(roundDetail.dateOfTransaction)}
            </p>
          </HStack>
        </div>
      );

    case RoundHeaderType.RoundBonusHeader:
      return (
        <div
          key={roundDetail.name}
          className="relative rounded-lg bg-white p-4 px-6 border border-borderColor shadow-box"
        >
          <dt className="flex flex-row justify-between items-center">
            <p className=" truncate text-lg font-medium text-[#464E5F] mr-5">
              {roundDetail.type}
            </p>
            {roundDetail.identifier && roundDetail.type === "Round" && (
              <p className=" truncate text-sm font-semibold text-[#3699FF] bg-[#E5F2FF] rounded-sm p-1">
                {roundDetail.identifier}
              </p>
            )}
          </dt>
          <dd className=" flex items-baseline flex-col">
            <HStack className="text-3xl items-baseline font-medium text-[#078b04b3] pt-2 pb-1 whitespace-nowrap">
              <span className="text-xl">{symbol}</span>
              <FormatNumberSpan
                value={Math.abs(roundDetail?.amountRaised)}
                format={currency}
              />
            </HStack>
          </dd>
          <HStack className="justify-between whitespace-nowrap">
            <p className={`text-xs text-[#464E5F] font-medium mr-5`}>
              {roundDetail.name}
            </p>
            <p className={`text-xs text-[#464E5F] font-medium`}>
              {formatDisplayDate(roundDetail.dateOfTransaction)}
            </p>
          </HStack>
        </div>
      );
    case RoundHeaderType.RoundBuyBackHeader:
      return (
        <div
          key={roundDetail.name}
          className="relative rounded-lg bg-white p-4 px-6 border border-borderColor shadow-box"
        >
          <dt className="flex flex-row justify-between items-center">
            <p className=" truncate text-lg font-medium text-[#464E5F] mr-5">
              {roundDetail.type}
            </p>
            {roundDetail.identifier && roundDetail.type === "Round" && (
              <p className=" truncate text-sm font-semibold text-[#3699FF] bg-[#E5F2FF] rounded-sm p-1">
                {roundDetail.identifier}
              </p>
            )}
          </dt>
          <dd className="flex items-baseline flex-col">
            <HStack className="text-3xl  items-baseline font-medium text-[#078b04b3] pt-2 pb-1 whitespace-nowrap">
              <span className="text-xl">{symbol}</span>
              <FormatNumberSpan
                value={Math.abs(roundDetail?.amountRaised)}
                format={currency}
              />
            </HStack>
          </dd>
          <HStack className="justify-between whitespace-nowrap">
            <p className={`text-xs text-[#464E5F] font-medium mr-5`}>
              {roundDetail.name}
            </p>
            <p className={`text-xs text-[#464E5F] font-medium`}>
              {formatDisplayDate(roundDetail.dateOfTransaction)}
            </p>
          </HStack>
        </div>
      );
    case RoundHeaderType.RoundNameHeader:
      return (
        <div
          key={roundDetail.name}
          className="relative rounded-lg justify-between bg-white px-4 py-2 border border-borderColor shadow-box"
        >
          <dt className="flex flex-row justify-between items-center overflow-x-clip text-ellipsis">
            <Tooltip text={roundDetail.name}>
              <p className=" truncate text-lg font-medium text-[#464E5F]  overflow-clip text-ellipsis w-fit">
                {roundDetail.name} {roundDetail.name === "Round"}
              </p>
            </Tooltip>
            {roundDetail.identifier && (
              <p className=" truncate text-sm font-semibold text-[#3699FF] bg-[#E5F2FF] rounded-sm p-1">
                {roundDetail.identifier}
              </p>
            )}
          </dt>
          <dd className="flex items-baseline flex-col whitespace-nowrap">
            <HStack className="text-3xl items-baseline font-medium text-[#078b04b3] pt-2 pb-1 whitespace-nowrap">
              <span className="text-xl">{symbol}</span>
              <FormatNumberSpan
                value={Math.abs(roundDetail?.amountRaised)}
                format={currency}
              />
            </HStack>
            <HStack className="justify-between w-full items-baseline ">
              {roundCurrency !== currency && (
                <HStack className=" `text-xs text-[#464E5F] font-medium">
                  <span>{roundSymbol}</span>
                  <FormatNumberSpan
                    value={Math.abs(roundDetail?.amountInvestedInCurrency)}
                    format={roundCurrency}
                  />
                  <span className="text-[#464E5F] font-medium">(Round)</span>
                </HStack>
              )}
              <p className={`text-xs text-[#464E5F] font-medium`}>
                {formatDisplayDate(roundDetail.dateOfTransaction || new Date())}
              </p>
              {roundDetail.paymentType && (
                <p className={`text-xs text-[#464E5F] font-medium`}>
                  {roundDetail.paymentType}
                </p>
              )}
            </HStack>
          </dd>
        </div>
      );
    case RoundHeaderType.RoundPostMoneyHeader:
      return (
        <div
          key={roundDetail.name}
          className="relative rounded-lg bg-white px-4 py-2 border border-borderColor shadow-box"
        >
          <dt className="flex flex-row justify-between items-center">
            <p className=" truncate text-lg font-medium text-[#464E5F]">
              Post Money :
            </p>
          </dt>
          <dd className=" flex items-baseline flex-col whitespace-nowrap">
            <HStack className="text-3xl items-baseline font-medium text-[#464E5F] pt-2 pb-1 whitespace-nowrap">
              <span className="text-xl">{symbol}</span>
              <FormatNumberSpan
                value={Math.abs(roundDetail?.postMoney)}
                format={currency}
              />
            </HStack>

            <HStack className="">
              <HStack
                className={`text-base text-[#078b04b3] items-baseline font-semibold whitespace-nowrap`}
              >
                <span className="text-xs font-medium">{symbol}</span>
                <FormatNumberSpan
                  value={Math.abs(roundDetail?.preMoney)}
                  format={currency}
                />

                <span className="text-[#464E5F] font-medium">(Pre Money)</span>
              </HStack>
            </HStack>
          </dd>
        </div>
      );
    case RoundHeaderType.RoundSharePriceHeader:
      return (
        <div
          key={roundDetail.name}
          className="relative rounded-lg bg-white px-4 py-2 border border-borderColor shadow-box"
        >
          <dt className="flex flex-row justify-between items-center">
            <p className=" truncate text-lg font-medium text-[#464E5F]">
              New Share Price :
            </p>
          </dt>
          <dd className=" flex items-baseline flex-col whitespace-nowrap">
            <HStack className="text-3xl items-baseline font-medium text-[#464E5F] pt-2 pb-1 whitespace-nowrap">
              <span className="text-xl">{symbol}</span>
              <FormatNumberSpan
                value={Math.abs(roundDetail?.pps)}
                format={currency}
              />
            </HStack>
            <HStack className="">
              <HStack
                className={`text-base text-[#078b04b3] items-baseline font-semibold whitespace-nowrap`}
              >
                <span className="text-xs font-medium">{symbol}</span>
                <FormatNumberSpan
                  value={Math.abs(roundDetail?.oldPps)}
                  format={currency}
                />
                <span className="text-[#464E5F] font-medium">
                  (Old Share Price)
                </span>
              </HStack>
            </HStack>
          </dd>
        </div>
      );
    case RoundHeaderType.RoundPriceShareHeader:
      return (
        <div
          key={roundDetail.name}
          className="relative rounded-lg bg-white px-4 py-2 border border-borderColor shadow-box"
        >
          <dt className="flex flex-row justify-between items-center">
            <p className=" truncate text-lg font-medium text-[#464E5F] mr-5">
              No. Of Shares :
            </p>
          </dt>
          <dd className=" flex items-baseline flex-col whitespace-nowrap">
            <p className="text-3xl font-medium text-[#464E5F] pt-2 pb-1 whitespace-nowrap">
              <FormatNumberSpan
                value={Math.abs(roundDetail?.numberOfShares)}
                format={currency}
              />
            </p>
            <HStack className="">
              <HStack
                className={`text-base text-[#464E5F] items-baseline font-medium whitespace-nowrap`}
              >
                <span className="mr-1">PPS </span>
                <span className="text-sm">{symbol}</span>
                <FormatNumberSpan
                  value={Math.abs(roundDetail?.pps)}
                  format={currency}
                />
              </HStack>
            </HStack>
          </dd>
        </div>
      );
    case RoundHeaderType.RoundDilutionHeader:
      return (
        <div
          key={roundDetail.name}
          className="flex flex-col rounded-lg bg-white px-4 py-2 border border-borderColor shadow-box justify-between"
        >
          <dd
            className={`flex items-baseline flex-row ${
              roundDetail.issuanceType ? "text-sm" : "text-base"
            } font-medium text-[#464E5F] justify-between`}
          >
            <p className=" pt-2 pb-1">Dilution:</p>
            <Tooltip text={`${(roundDetail.dilution || 0) * 100}`}>
              <p className="border rounded text-red-700 px-2 border-red-700">
                {((roundDetail.dilution || 0) * 100).toLocaleString(currency)}%
              </p>
            </Tooltip>
          </dd>
          <dd
            className={`flex items-baseline flex-row ${
              roundDetail.issuanceType ? "text-sm" : "text-base"
            } font-medium text-[#464E5F] justify-between whitespace-nowrap`}
          >
            <VStack>
              <p className=" pt-2 pb-1">Price Round:</p>
              {!roundDetail.isPricedRound &&
                (roundDetail?.nonPricedSecurityDetails?.length || 0) === 0 && (
                  <p className="ml-2 mb-2 text-base">(Converted)</p>
                )}
            </VStack>
            <p>{roundDetail.isPricedRound ? "Yes" : "No"}</p>
          </dd>
          <dd
            className={`flex items-baseline flex-row ${
              roundDetail.issuanceType ? "text-sm" : "text-base"
            } font-medium text-[#464E5F] justify-between whitespace-nowrap`}
          >
            {roundDetail.issuanceType ? (
              <HStack className="w-full items-center justify-between">
                <p>Issuance Type:</p>
                <p>{roundDetail.issuanceType}</p>
              </HStack>
            ) : (
              <div></div>
            )}
          </dd>
        </div>
      );
    case RoundHeaderType.RoundDilutionHeaderExercise:
      return (
        <div
          key={roundDetail.name}
          className="flex flex-col rounded-lg bg-white px-4 py-2 border border-borderColor shadow-box justify-between"
        >
          <dd
            className={`flex items-baseline flex-row ${
              roundDetail.issuanceType ? "text-sm" : "text-base"
            } font-medium text-[#464E5F] justify-between`}
          >
            <p className=" pt-2 pb-1">Dilution:</p>
            <Tooltip text={`${(roundDetail.dilution || 0) * 100}`}>
              <p className="border rounded text-red-700 px-2 border-red-700">
                {((roundDetail.dilution || 0) * 100).toLocaleString(currency)}%
              </p>
            </Tooltip>
          </dd>
        </div>
      );
    case RoundHeaderType.RoundSecurityNameHeader:
      return roundDetail.securityTypes.length > 0 ? (
        <div
          key={roundDetail.name}
          className="relative whitespace-nowrap rounded-lg bg-white px-4 py-2 border border-borderColor shadow-box"
        >
          <dt className="flex flex-row justify-between items-center">
            <p className=" truncate text-lg font-medium text-[#464E5F]">
              Security:
            </p>
          </dt>

          <dt className="flex flex-row justify-center pt-2 pb-1 gap-2">
            {roundDetail.securityTypes.map((security: string) => (
              <p
                key={security}
                className="text-sm text-center font-semibold text-[#078b04b3] rounded bg-[#E8FFF3] pt-2 pb-1 px-2"
              >
                {security}
              </p>
            ))}
          </dt>
        </div>
      ) : (
        <></>
      );
    case RoundHeaderType.RoundOnlyDilutionNameHeader:
      return (
        <div
          key={roundDetail.name}
          className="relative rounded-lg bg-white px-4 py-2 border border-borderColor shadow-box"
        >
          <dt className="flex flex-row justify-between items-center">
            <p className=" truncate text-lg font-medium text-[#464E5F]">
              Dilution:
            </p>
          </dt>
          <dt className="flex flex-row justify-center pt-2 pb-1">
            <p className="border rounded font-semibold text-[#D10606] text-base px-2 border-[#D10606]">
              <Tooltip text={`${(roundDetail.dilution || 0) * 100}`}>
                <p className="text-red-700 px-2">
                  {((roundDetail.dilution || 0) * 100).toLocaleString(currency)}
                  %
                </p>
              </Tooltip>
            </p>
          </dt>
        </div>
      );
    case RoundHeaderType.RoundBuyerSellerHeader:
      return (
        <div
          key={roundDetail.name}
          className="whitespace-nowrap flex flex-col relative rounded-lg bg-white px-4 py-2 border border-borderColor shadow-box"
        >
          <dt className="flex flex-row justify-between items-center py-2">
            <dt className="flex flex-col justify-center">
              <p className=" truncate text-xs font-medium text-[#464E5F]">
                No. of Buyers:
              </p>
              <p className="text-3xl px-2 text-center pt-4">
                {roundDetail.numberOfBuyer}
              </p>
            </dt>
            <div className="absolute left-1/2  -ml-0.5 w-0.5 h-20 mt-4 bg-[#464E5F]"></div>
            <dt className="flex flex-col justify-center">
              <p className=" truncate text-xs font-medium text-[#464E5F]">
                No. of Sellers:
              </p>
              <p className="text-3xl px-2 text-center pt-4">
                {roundDetail.numberOfSeller}
              </p>
            </dt>
          </dt>
        </div>
      );
    case RoundHeaderType.RoundNewBonusShareHeader:
      return (
        <div
          key={roundDetail.name}
          className="whitespace-nowrap relative rounded-lg bg-white px-4 py-2 border border-borderColor shadow-box"
        >
          <dt className="flex flex-row justify-between items-center">
            <p className=" truncate text-lg font-medium text-[#464E5F]">
              New Bonus Share :
            </p>
          </dt>
          <dd className=" flex items-baseline flex-col">
            <p className="text-3xl font-medium text-[#464E5F] pt-2 pb-1 whitespace-nowrap">
              <FormatNumberSpan
                value={roundDetail.newFdbShares - roundDetail.oldFdbShares}
                format={currency}
              />
            </p>
          </dd>
        </div>
      );
    case RoundHeaderType.RoundNewFDBShareHeader:
      return (
        <div
          key={roundDetail.name}
          className="whitespace-nowrap relative rounded-lg bg-white px-4 py-2 border border-borderColor shadow-box"
        >
          <dt className="flex flex-row justify-between items-center">
            <p className=" truncate text-lg font-medium text-[#464E5F]">
              New FDB Share :
            </p>
          </dt>
          <dd className=" flex items-baseline flex-col">
            <p className="text-3xl font-medium text-[#464E5F] pt-2 pb-1 whitespace-nowrap">
              <FormatNumberSpan
                value={roundDetail.newFdbShares}
                format={currency}
              />
            </p>
          </dd>
        </div>
      );
    case RoundHeaderType.RoundEsopShareHeader:
      return (
        <div
          key={roundDetail.name}
          className="whitespace-nowrap relative rounded-lg bg-white px-4 py-2 border border-borderColor shadow-box"
        >
          <dt className="flex flex-row justify-between items-center">
            <p className=" truncate text-lg font-medium text-[#464E5F]">
              {roundDetail.name} {roundDetail.name === "Round"}
            </p>
          </dt>
          <dd className=" flex items-baseline flex-col">
            <HStack className="text-3xl items-baseline font-medium text-[#078b04b3] pt-2 pb-1 whitespace-nowrap">
              <span className="text-xl">{symbol}</span>
              <FormatNumberSpan
                value={roundDetail.postMoney}
                format={currency}
              />
            </HStack>
            <HStack className="">
              <p className={`text-xs text-[#464E5F] font-medium`}>
                {formatDisplayDate(roundDetail.dateOfTransaction || new Date())}
              </p>
            </HStack>
          </dd>
        </div>
      );
    case RoundHeaderType.RoundSplitShareHeader:
      return (
        <div
          key={roundDetail?.name || ""}
          className="relative rounded-lg bg-white p-4 px-6 border border-borderColor shadow-box"
        >
          <dt className="flex flex-row justify-between items-center">
            <p className=" truncate text-lg font-medium text-[#464E5F] mr-5">
              {roundDetail?.type || ""}
            </p>
            {roundDetail.identifier &&
              roundDetail.type?.toLocaleLowerCase() === "Round" && (
                <p className=" truncate text-sm font-semibold text-[#3699FF] bg-[#E5F2FF] rounded-sm p-1">
                  {roundDetail.identifier}
                </p>
              )}
          </dt>
          <dd className=" flex items-baseline flex-col">
            <p className="text-3xl font-medium text-[#078b04b3] pt-2 pb-1 whitespace-nowrap">
              {roundDetail?.splitRatio}
            </p>
          </dd>
          <HStack className="justify-between whitespace-nowrap">
            <Tooltip text={roundDetail.name}>
              <p className={`text-xs text-[#464E5F] font-medium mr-5`}>
                {roundDetail.name}
              </p>
            </Tooltip>
            <p className={`text-xs text-[#464E5F] font-medium`}>
              {formatDisplayDate(roundDetail.dateOfTransaction)}
            </p>
          </HStack>
        </div>
      );

    default:
      return <></>;
  }
};
