import { memo, useEffect, useMemo, useRef, useState } from "react";
import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { agConfigDashboardSideBar } from "../dashboardPage/AgGridConfig";
import {
  getCurrencySymbol,
  getCurrencyType,
} from "../../utils/currencyFormatter";
import { Box, HStack } from "../../components/utils";
import { tableCellStyle } from "../../components/TableComponent";
import SearchInput from "../../components/shared/SearchInput";
import { setTableHeight } from "./utils";
import { OngoingCaptableInvestor } from "../../types/Modeling";
import { getShareholderValue } from "../../constants/ShareholderConstants";
import {
  NumberComponent,
  ShareholderNameModelRender,
  StringComponent,
} from "./AgComponent";
import GenericTableHeader from "../../shared/TableHeader";
import { ExportImport } from "../Utility/GrantsTable";
import { Action } from "../../components/shared/Dropdown";
import { exportPricedRoundModelReport } from "../../api/Report";
import { useRoundModelStore } from "../../store/roundModelingStore";
import { downloadExcel } from "../../utils/DownloadFile";

function ModelSummaryAGGridTable({
  data,
}: {
  data: OngoingCaptableInvestor[];
}) {
  const gridApi = useRef<any>(null);
  const [displayedRowCount, setDisplayedRowCount] = useState(0);

  const pathName = window.location.href.split("/");
  const sharedPage = useMemo(() => pathName[3] === "share", [pathName]);

  useEffect(() => {
    const displayedRow = gridApi.current?.api.getDisplayedRowCount();
    setDisplayedRowCount(displayedRow);
  }, [gridApi.current?.api.getDisplayedRowCount()]);

  const componentsRegistery = useMemo(
    () => ({
      investorName: memo(ShareholderNameModelRender),
      numberComponent: memo(NumberComponent),
      stringComponent: memo(StringComponent),
    }),
    []
  );

  const { roundCreation: roundModel } = useRoundModelStore();

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      autoHeight: true,
      wrapHeaderText: true,
      suppressColumnVirtualisation: true,
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      filterParams: {
        buttons: ["reset"],
        maxNumConditions: 5,
      },
      minWidth: 100,
      filter: true,
      resizable: true,
      flex: 1,
    }),
    []
  );

  const [isColumnOpen, setIsColumnOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const openToolPanel = (key: any) => {
    if (key === "columns") {
      if (gridApi) {
        if (!isColumnOpen) gridApi?.current?.api?.openToolPanel(key);
        else gridApi?.current?.api?.closeToolPanel();
        setIsColumnOpen((state) => !state);
        setIsFilterOpen(false);
      }
    } else if (key === "filters") {
      if (gridApi) {
        if (!isFilterOpen) gridApi?.current?.api?.openToolPanel(key);
        else gridApi?.current?.api?.closeToolPanel();
        setIsFilterOpen((state) => !state);
        setIsColumnOpen(false);
      }
    }
  };
  const currencySymbol = getCurrencySymbol();

  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        headerName: "Shareholder Name",
        field: "investorName",
        filter: "agMultiColumnFilter",
        getQuickFilterText: (params) => params.value.name,
        comparator(valueA: any, valueB: any, ..._: any[]) {
          return valueA.name > valueB.name ? 1 : -1;
        },
        filterParams: {
          maxNumConditions: 5,
          buttons: ["reset"],
          filters: [
            {
              title: "Name",
              display: "subMenu",
              filter: "agTextColumnFilter",
              buttons: ["reset"],
              filterValueGetter: (params: any) => params.value.name,
              filterParams: {
                buttons: ["reset"],
                valueGetter: (params: any) =>
                  params.getValue("investorName").name,
              },
            },
            {
              title: "Type",
              filter: "agSetColumnFilter",
              display: "subMenu",
              buttons: ["reset"],
              filterParams: {
                buttons: ["reset"],
                keyCreator: (params: any) => {
                  const shareholderType = params.value.type;
                  return shareholderType;
                },
                valueFormatter: (params: any) => {
                  const shareholderType = getShareholderValue(
                    params.value.type
                  );
                  return shareholderType;
                },
              },
            },
          ],
        },
        pinned: "left",
        cellRenderer: "investorName",
        initialWidth: 300,
        sortable: true,
        autoHeight: true,
        cellStyle: tableCellStyle,
        wrapText: true,
        suppressSizeToFit: true,
        menuTabs: ["filterMenuTab"],
      },

      {
        headerName: "Pre Holding %",
        field: "preHoldingPercentage",
        filter: "agNumberColumnFilter",
        cellRenderer: "numberComponent",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "toolTipPreHoldingPercentage",
      },
      {
        headerName: "Post Holding %",
        field: "postHoldingPercentage",
        filter: "agNumberColumnFilter",
        cellRenderer: "numberComponent",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "toolTipPostHoldingPercentage",
      },
      {
        headerName: "Pre FDB Shares",
        field: "preHoldingFdbShares",
        filter: "agNumberColumnFilter",
        cellRenderer: "numberComponent",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "toolTipPreHoldingFdbShares",
      },
      {
        headerName: "Post FDB Shares",
        field: "postHoldingFdbShares",
        filter: "agNumberColumnFilter",
        cellRenderer: "numberComponent",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "toolTipPostHoldingFdbShares",
      },
      {
        headerName: `Total Amount Invested (${currencySymbol})`,
        field: "totalAmountInvested",
        filter: "agNumberColumnFilter",
        cellRenderer: "numberComponent",
        cellStyle: tableCellStyle,
        sortable: true,
        hide: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "toolTipTotalAmountInvested",
      },
      {
        headerName: `Amount Invested (${currencySymbol})`,
        field: "investmentAmount",
        filter: "agNumberColumnFilter",
        cellRenderer: "numberComponent",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "toolTipInvestmentAmount",
      },
    ],
    []
  );

  const currencyType = getCurrencyType();

  const rowData = useMemo(
    () =>
      data?.map((template) => ({
        id: template.id,
        investorId: template.investorId,
        investorName: {
          name:
            template.investorName !== ""
              ? template.investorName
              : "Not Assigned",
          type: template.investorType,
        },
        investorType: template.investorType,
        preHoldingFdbShares: template.preHoldingFdbShares,
        preHoldingPercentage: template.preHoldingPercentage,
        postHoldingFdbShares: template.postHoldingFdbShares,
        postHoldingPercentage: template.postHoldingPercentage,
        totalAmountInvested: template.totalAmountInvested,
        investmentAmount: template.investmentAmount,
        toolTipPreHoldingFdbShares:
          template.preHoldingFdbShares?.toLocaleString(currencyType),
        toolTipPreHoldingPercentage:
          template.preHoldingPercentage?.toLocaleString(currencyType),
        toolTipPostHoldingFdbShares:
          template.postHoldingFdbShares?.toLocaleString(currencyType),
        toolTipPostHoldingPercentage:
          template.postHoldingPercentage?.toLocaleString(currencyType),
        toolTipTotalAmountInvested:
          template.totalAmountInvested?.toLocaleString(currencyType),
        toolTipInvestmentAmount:
          template.investmentAmount?.toLocaleString(currencyType),
        currencySymbol,
        currencyType,
      })),
    [data]
  );

  function setPinnedBottomRowData({ api }: { api: any }) {
    const data = api.rowModel.rootNode;
    const filteredData = data.childrenAfterAggFilter.map(
      (element: any) => element.data
    );
    const totalPreHoldingFdbShares = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + data.preHoldingFdbShares,
      0
    );
    const totalPreHoldingPercentage = Math.round(
      filteredData?.reduce(
        (accumulator: any, data: any) =>
          accumulator + data.preHoldingPercentage,
        0
      )
    );
    const totalPostHoldingFdbShares = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + data.postHoldingFdbShares,
      0
    );
    const totalPostHoldingPercentage = Math.round(
      filteredData?.reduce(
        (accumulator: any, data: any) =>
          accumulator + data.postHoldingPercentage,
        0
      )
    );
    const totalTotalAmountInvested = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + data.totalAmountInvested,
      0
    );
    const totalInvestmentAmount = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + data.investmentAmount,
      0
    );

    api.setPinnedBottomRowData([
      {
        id: "total",
        investorId: "total",
        investorName: {
          name: "Total",
          type: "",
        },
        investorType: "",
        preHoldingFdbShares: totalPreHoldingFdbShares,
        preHoldingPercentage: totalPreHoldingPercentage,
        postHoldingFdbShares: totalPostHoldingFdbShares,
        postHoldingPercentage: totalPostHoldingPercentage,
        totalAmountInvested: totalTotalAmountInvested,
        investmentAmount: totalInvestmentAmount,
        currencySymbol,
        currencyType,
        toolTipPreHoldingFdbShares:
          totalPreHoldingFdbShares.toLocaleString(currencyType),
        toolTipPreHoldingPercentage:
          totalPreHoldingPercentage.toLocaleString(currencyType),
        toolTipPostHoldingFdbShares:
          totalPostHoldingFdbShares.toLocaleString(currencyType),
        toolTipPostHoldingPercentage:
          totalPostHoldingPercentage.toLocaleString(currencyType),
        toolTipTotalAmountInvested:
          totalTotalAmountInvested.toLocaleString(currencyType),
        toolTipInvestmentAmount:
          totalInvestmentAmount.toLocaleString(currencyType),
      },
    ]);
  }
  async function handleExportAction(action: Action) {
    if (action.name === "Model Summary Report") {
      exportPricedRoundModelReport(roundModel).then((res) => {
        const fileName = `Model Summary Report_${
          roundModel.roundDetailsTab?.roundName || ""
        }`;
        downloadExcel(res, fileName);
      });
    }
  }

  return (
    <div className="bg-white border rounded-md border-borderColor shadow-box">
      <HStack className="flex-col items-start justify-start py-4 lg:flex-row lg:justify-between lg:items-center ">
        <GenericTableHeader
          heading={"Model Cap Table"}
          subHeading={
            "Post Cap Table of the company after considering the transaction in modelling"
          }
          // count={rowData.length}
        />
        <HStack className="items-center justify-between w-full gap-4 px-8 lg:w-auto lg:justify-end">
          <SearchInput
            placeholder={`Search`}
            onChange={(e: any) => {
              gridApi.current.api.setQuickFilter(e.target.value);
            }}
          />
          {!sharedPage && (
            <ExportImport
              actions={[
                {
                  name: "Model Summary Report",
                },
              ]}
              onAction={(action) => handleExportAction(action)}
            />
          )}
        </HStack>
      </HStack>
      <HStack className="justify-between w-full">
        <Box
          style={{
            height: setTableHeight(displayedRowCount),
          }}
          className="w-full h-full max-h-full overflow-x-auto ag-theme-material"
        >
          <AgGridReact
            sideBar={agConfigDashboardSideBar}
            onGridReady={(params) => {
              // Store the grid API referen
              gridApi.current = params;
            }}
            suppressScrollOnNewData={true}
            alwaysShowHorizontalScroll={false}
            alwaysShowVerticalScroll={false}
            components={componentsRegistery}
            alwaysMultiSort
            animateRows={true}
            enableCharts={true}
            enableRangeSelection={true}
            defaultColDef={defaultColDef}
            onRowDataUpdated={setPinnedBottomRowData}
            rowData={rowData}
            onFilterChanged={setPinnedBottomRowData}
            columnDefs={columnDefs}
            pagination={false}
            suppressRowTransform={true}
            suppressCopyRowsToClipboard={true}
            suppressCopySingleCellRanges={true}
            suppressCellFocus={true}
            suppressMenuHide={false}
            tooltipShowDelay={1000}
            tooltipInteraction={true}
            rowClass={"border-t border-dashed "}
            overlayNoRowsTemplate={
              '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow; margin-top: 50px;">No Rows To Show</span>'
            }
            getRowStyle={(params) => {
              if (params.rowIndex % 2 === 0) {
                return { background: "#f8f8f8" };
              } else {
                return { background: "#ffffff" };
              }
            }}
          ></AgGridReact>
        </Box>
      </HStack>
    </div>
  );
}

export default ModelSummaryAGGridTable;
