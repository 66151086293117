import { AnyAaaaRecord } from "dns";
import { currencyTypes } from "../constants/CompanyCurrencyType";
import { useAuthStore } from "../store";

export function formatCurrency(num: number, format: string) {
  let returnFormat = "INR";
  for (const [key, value] of Object.entries(currencyTypes)) {
    if (value.toString() === format) {
      returnFormat = key.toString();
    }
  }
  if (returnFormat === "SGD") {
    return `${"S$"} ${num?.toLocaleString(format)}`;
  } else {
    return num?.toLocaleString(format, {
      style: "currency",
      currency: returnFormat,
    });
  }
}

export function getCurrencyType() {
  const { user } = useAuthStore();
  const res = user?.companyCurrency?.split("-")[0]?.trim() || "INR";
  const key = res as keyof typeof currencyTypes;
  const currencyType =
    key != null ? currencyTypes[key] : window.navigator.language;
  return currencyType;
}

export function getCurrencyTypeforDecimals(value: any) {
  const { user } = useAuthStore();
  const res = user?.companyCurrency?.split("-")[0]?.trim() || "INR";
  const key = res as keyof typeof currencyTypes;
  const currencyType =
    key != null ? currencyTypes[key] : window.navigator.language;
  const result = Number(value).toLocaleString(currencyType, {
    maximumFractionDigits: 2,
  });

  return result;
}

export function getCurrency(roundCurrency: string) {
  const res = roundCurrency?.split("-")[0]?.trim() || "INR";
  const key = res as keyof typeof currencyTypes;
  const currencyType =
    key != null ? currencyTypes[key] : window.navigator.language;
  return currencyType;
}

export function getCurrencySymbol() {
  const { user } = useAuthStore();
  const res = user?.companyCurrency?.split("-")[1]?.trim() || "";
  return res;
}

export function getCompanyCurrency() {
  const { user } = useAuthStore();
  const res = user?.companyCurrency || "INR - ₹";
  return res;
}

export function getKeyByValue(object: any, value: string) {
  return Object.keys(object).find((key) => object[key] === value);
}
