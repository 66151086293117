import { QueryFunctionContext } from "react-query";
import { LiquidationStack, SecurityDataModel } from "../types/SecurityModel";
import api from "./capTableApi";
import {
  AllTransactionModel,
  IndividualBonusModel,
  IndividualBuybackModel,
  IndividualRoundsModel,
  IndividualSecondaryModel,
  IndividualSplitModel,
} from "../types/AllTransactionModel";
import { ShareholderInstrumentModel } from "../types/quickRound";
import { DashBoardModel } from "../types/DashboardModel";
import { ShareHolderRightsModel } from "../types/ShareHolderRightsModel";
import { ArticleModel } from "../pages/shareHolderRights/RightsArticle";
import {
  FinancialDataModel,
  FinancialResponseModel,
} from "../pages/finance/FinanceWrapper";
import { SecurityClass } from "../pages/securities/NewModel";

export async function getInvInstrumentAndShareHolder(
  context: QueryFunctionContext
): Promise<ShareholderInstrumentModel> {
  const companyId = context.queryKey[1];
  const token = context.queryKey[2];
  return api
    .get("v1/company/inv/shareholdersAndInstruments", {
      params: { companyId },
      headers: {
        IframeToken: token as string,
      },
    })
    .then((res) => res.data.data);
}

export async function getInvEvents(
  context: QueryFunctionContext
): Promise<DashBoardModel[]> {
  const companyId = context.queryKey[1];
  const token = context.queryKey[2];
  return api
    .get("/v1/company/inv/dashboard/events", {
      params: { companyId },
      headers: {
        IframeToken: token as string,
      },
    })
    .then((res) => res.data.data);
}

export async function allGetInvShareHolderRight(
  context: QueryFunctionContext
): Promise<ShareHolderRightsModel[]> {
  const companyId = context.queryKey[1];
  const token = context.queryKey[2];
  return api
    .get(`/v1/company/inv/investorRights/overview?companyId=${companyId}`, {
      headers: {
        IframeToken: token as string,
      },
    })
    .then((res) => res.data.data);
}

export async function getInvSecurityOverviewDetails(
  context: QueryFunctionContext
): Promise<SecurityDataModel> {
  const companyId = context.queryKey[1];
  const token = context.queryKey[2];
  return api
    .get(`v1/company/inv/instrumentClass/overview?companyId=${companyId}`, {
      headers: {
        IframeToken: token as string,
      },
    })
    .then((res) => res.data.data);
}

export async function getInvLiquidationPreferenceDetails(
  context: QueryFunctionContext
): Promise<LiquidationStack[]> {
  const companyId = context.queryKey[1];
  const token = context.queryKey[2];
  return api
    .get(`v1/company/inv/instrumentClass/liquidation?companyId=${companyId}`, {
      headers: {
        IframeToken: token as string,
      },
    })
    .then((res) => res.data.data);
}

export async function getInvSecuritySummary(
  context: QueryFunctionContext
): Promise<SecurityClass> {
  const companyId = context.queryKey[1];
  const token = context.queryKey[2];

  const securityId = context.queryKey[3];

  return api
    .get(
      `v1/company/inv/instrumentClass?securityId=${securityId}&companyId=${companyId}`,
      {
        headers: {
          IframeToken: token as string,
        },
      }
    )
    .then((res) => res.data.data);
}

export async function getInvAllTransactions(
  context: QueryFunctionContext
): Promise<AllTransactionModel[]> {
  const companyId = context.queryKey[1];
  const token = context.queryKey[2];
  return api
    .get(`v1/company/inv/transaction/allTransactions?companyId=${companyId}`, {
      headers: {
        IframeToken: token as string,
      },
    })
    .then((res) => res.data.data);
}

export async function getInvRoundsTransactions(
  context: QueryFunctionContext
): Promise<IndividualRoundsModel> {
  const eventId = context.queryKey[1];
  const companyId = context.queryKey[2];
  const token = context.queryKey[3];
  return api
    .get("v1/company/inv/transaction/round", {
      params: { eventId, companyId },
      headers: {
        IframeToken: token as string,
      },
    })
    .then((res) => res.data.data);
}

export async function getInvEsopExerciseTransactions(
  context: QueryFunctionContext
): Promise<IndividualRoundsModel> {
  const eventId = context.queryKey[1];
  const companyId = context.queryKey[2];
  const token = context.queryKey[3];
  return api
    .get("v1/company/inv/transaction/esopExcerise", {
      params: { eventId, companyId },
      headers: {
        IframeToken: token as string,
      },
    })
    .then((res) => res.data.data);
}

export async function getInvSecondaryTransactions(
  context: QueryFunctionContext
): Promise<IndividualSecondaryModel> {
  const eventId = context.queryKey[1];
  const companyId = context.queryKey[2];
  const token = context.queryKey[3];
  return api
    .get(`v1/company/inv/transaction/secondary?companyId=${companyId}`, {
      params: { eventId },
      headers: {
        IframeToken: token as string,
      },
    })
    .then((res) => res.data.data);
}

export async function getInvSplitTransactions(
  context: QueryFunctionContext
): Promise<IndividualSplitModel> {
  const eventId = context.queryKey[1];
  const companyId = context.queryKey[2];
  const token = context.queryKey[3];
  return api
    .get("v1/company/inv/transaction/split", {
      params: { eventId, companyId },
      headers: {
        IframeToken: token as string,
      },
    })
    .then((res) => res.data.data);
}

export async function getInvBuybackTransactions(
  context: QueryFunctionContext
): Promise<IndividualBuybackModel> {
  const eventId = context.queryKey[1];
  const companyId = context.queryKey[2];
  const token = context.queryKey[3];
  return api
    .get("v1/company/inv/transaction/buyback", {
      params: { eventId, companyId },
      headers: {
        IframeToken: token as string,
      },
    })
    .then((res) => res.data.data);
}

export async function getInvBonusTransactions(
  context: QueryFunctionContext
): Promise<IndividualBonusModel> {
  const eventId = context.queryKey[1];
  const companyId = context.queryKey[2];
  const token = context.queryKey[3];
  return api
    .get("v1/company/inv/transaction/bonus", {
      params: { eventId, companyId },
      headers: {
        IframeToken: token as string,
      },
    })
    .then((res) => res.data.data);
}

export async function getInvArticleDescription(
  context: QueryFunctionContext
): Promise<ArticleModel> {
  const rightsId = context.queryKey[1];
  const cin = context.queryKey[2];
  const companyId = context.queryKey[3];
  const token = context.queryKey[4];
  return api
    .get("v1/company/inv/investorRights/article", {
      params: { cin, rightsId, companyId },
      headers: {
        IframeToken: token as string,
      },
    })
    .then((res) => res.data.data);
}

export async function putInvArticleDescription({
  article,
  token,
}: {
  article: ArticleModel;
  token: string | undefined;
}): Promise<any> {
  return api
    .put(`v1/company/inv/investorRights/article`, article, {
      headers: {
        IframeToken: token as string,
      },
    })
    .then((res) => res.data.data);
}

export async function getArticleDescription(
  context: QueryFunctionContext
): Promise<ArticleModel> {
  const rightsId = context.queryKey[1];
  const cin = context.queryKey[2];
  const companyId = context.queryKey[3];
  const token = context.queryKey[4];
  return api
    .get("v1/company/investorRights/article", {
      params: { cin, rightsId, companyId },
      headers: {
        IframeToken: token as string,
      },
    })
    .then((res) => res.data.data);
}

export async function putArticleDescription({
  article,
  token,
}: {
  article: ArticleModel;
  token: string | undefined;
}): Promise<any> {
  return api
    .put(`v1/company/investorRights/article`, article, {
      headers: {
        IframeToken: token as string,
      },
    })
    .then((res) => res.data.data);
}

export async function getInvPortalIframeToken(
  context: QueryFunctionContext
): Promise<String> {
  const token = context.queryKey[1];
  return api.get("v1/company/inv/iframeToken").then((res) => res.data.data);
}

export async function getInvFinancialData(
  context: QueryFunctionContext
): Promise<FinancialResponseModel> {
  const companyId = context.queryKey[1];
  const token = context.queryKey[2];
  return api
    .get(`v1/company/inv/finance?companyId=${companyId}`, {
      headers: {
        IframeToken: token as string,
      },
    })
    .then((res) => res.data.data);
}
