export const shareholderTypeLabelValue = [
  {
    label: "Founder",
    value: "Founder",
  },
  {
    label: "Angel",
    value: "Angel",
  },
  {
    label: "Incubator",
    value: "Incubator",
  },
  {
    label: "Accelerator",
    value: "Accelerator",
  },
  {
    label: "AngelNetwork",
    value: "Angel Network",
  },
  {
    label: "AngelFund",
    value: "Angel Fund",
  },
  {
    label: "VC",
    value: "VC",
  },
  {
    label: "Corporate",
    value: "Corporate",
  },
  {
    label: "FamilyOffice",
    value: "Family Office",
  },
  {
    label: "PE",
    value: "PE",
  },
  {
    label: "FinancialInstitution",
    value: "Financial Institution",
  },
  {
    label: "Options",
    value: "Options",
  },
  {
    label: "Others",
    value: "Others",
  },
  {
    label: "Employee",
    value: "Employee",
  },

  {
    label: "Trust",
    value: "Trust",
  },
  {
    label: "EsopTrust",
    value: "ESOP Trust",
  },
  {
    label: "Forfeited",
    value: "Forfeited",
  },
  {
    label: "HNI",
    value: "HNI",
  },
];

export const getShareholderLabelAndValue = (value: string) =>
  shareholderTypeLabelValue.find(
    (shareholderType) => shareholderType.label === value
  );

export const getShareholderValue = (value: string) =>
  shareholderTypeLabelValue.find(
    (shareholderType) => shareholderType.label === value
  )?.value;

export function getShareholderTagCustomColor(shareholderType: string): string {
  const type = shareholderType.toUpperCase();
  switch (type) {
    case "FOUNDER":
      return "#241DBA";
    case "ANGEL":
      return "#2DA900";
    case "VC":
      return "#00A3C7";
    case "VENTURE CAPITAL":
      return "#00A3C7";
    case "INCUBATOR":
      return "#E79212";
    case "ACCELERATOR":
      return "#F86161";
    case "ANGELNETWORK":
      return "#6904DC";
    case "OTHERS":
      return "#B5A18A";
    case "ANGELFUND":
      return "#9A7CEE";
    case "CORPORATE":
      return "#BABC57";
    case "FAMILY OFFICE" || "FAMILYOFFICE":
      return "#57BF99";
    case "PE":
      return "#E1B774";
    case "FINANCIAL INSTITUTION":
      return "#2DA900";
    case "FINANCIALINSTITUTION":
      return "#2DA900";
    case "OPTIONS":
      return "#E49494";
    case "EMPLOYEE":
      return "#B5A18A";
    case "ESOPTRUST":
      return "#ad4d0c";
    case "TRUST":
      return "#ad4d0c";
    case "FORFEITED":
      return "#068077";
    default:
      return "#04b0a7";
  }
}

export function getShareholderCardColor(shareholderType: string): string {
  const type = shareholderType.toUpperCase();
  switch (type) {
    case "FOUNDER":
      return "from-[#241DBA]/[0.6]";
    case "ANGEL":
      return "from-[#2DA900]/[0.6]";
    case "VC":
      return "from-[#00A3C7]/[0.6]";
    case "INCUBATOR":
      return "from-[#E79212]/[0.6]";
    case "ACCELERATOR":
      return "from-[#F86161]/[0.6]";
    case "ANGELNETWORK":
      return "from-[6904DC]/[0.6]";
    case "OTHERS":
      return "from-[#B5A18A]/[0.6]";
    case "ANGELFUND":
      return "from-[#BAA8ED]/[0.6]";
    case "CORPORATE":
      return "from-[#A6A900]/[0.6]";
    case "FAMILY OFFICE" || "FAMILY OFFICE":
      return "from-[#00AD6F]/[0.6]";
    case "PE":
      return "from-[#E79212]/[0.6]";
    case "FINANCIAL INSTITUTION":
      return "from-[#2DA900]/[0.6]";
    case "FINANCIALINSTITUTION":
      return "from-[#2DA900]/[0.6]";
    case "OPTIONS":
      return "from-[#F86161]/[0.6]";
    case "ESOPTRUST":
      return "from-[#ad4d0c]/[0.6]";
    case "TRUST":
      return "from-[#ad4d0c]/[0.6]";
    case "FORFEITED":
      return "from-[#068077]/[0.6]";
    case "HNI":
      return "from-[#8B0000]/[0.6]";
    default:
      return "from-[#E8FFF3]";
  }
}

export function getDougnutTagColor(type: string): any {
  switch (type.toUpperCase()) {
    case "FOUNDER":
      return {
        text: "#241DBA",
        border: "#241DBA",
        bgColor: "bg-[#241DBA]/[0.1]",
      };
    case "ANGEL":
      return {
        text: "#2DA900",
        border: "#2DA900",
        bgColor: "bg-[#2DA900]/[0.1]",
      };
    case "VC":
      return {
        text: "#00A3C7",
        border: "#00A3C7",
        bgColor: "bg-[#00A3C7]/[0.1]",
      };
    case "INCUBATOR":
      return {
        text: "#E79212",
        border: "#E79212",
        bgColor: "bg-[#E79212]/[0.1]",
      };
    case "ACCELERATOR":
      return {
        text: "#F86161",
        border: "#F86161",
        bgColor: "bg-[#F86161]/[0.1]",
      };
    case "ANGELNETWORK":
      return {
        text: "#6904DC",
        border: "#6904DC",
        bgColor: "bg-[#6904DC]/[0.1]",
      };
    case "OTHERS":
      return {
        text: "#B5A18A",
        border: "#B5A18A",
        bgColor: "bg-[#B5A18A]/[0.1]",
      };
    case "ANGELFUND":
      return {
        text: "#9A7CEE",
        border: "#9A7CEE",
        bgColor: "bg-[#9A7CEE]/[0.1]",
      };
    case "CORPORATE":
      return {
        text: "#BABC57",
        border: "#BABC57",
        bgColor: "bg-[#BABC57]/[0.1]",
      };
    case "FAMILYOFFICE":
      return {
        text: "#57BF99",
        border: "#57BF99",
        bgColor: "bg-[#57BF99]/[0.1]",
      };
    case "PE":
      return {
        text: "#E1B774",
        border: "#E1B774",
        bgColor: "bg-[#E1B774]/[0.1]",
      };
    case "FINANCIAL INSTITUTION":
      return {
        text: "#2DA900",
        border: "#2DA900",
        bgColor: "bg-[#2DA900]/[0.1]",
      };
    case "FINANCIALINSTITUTION":
      return {
        text: "#2DA900",
        border: "#2DA900",
        bgColor: "bg-[#2DA900]/[0.1]",
      };
    case "OPTIONS":
      return {
        text: "#E49494",
        border: "#E49494",
        bgColor: "bg-[#E49494]/[0.1]",
      };
    case "EMPLOYEE":
      return {
        text: "#B5A18A",
        border: "#B5A18A",
        bgColor: "bg-[#B5A18A]/[0.1]",
      };
    case "ESOPTRUST":
      return {
        text: "#ad4d0c",
        border: "#8c3b04",
        bgColor: "bg-[#8c3b04]/[0.1]",
      };
    case "TRUST":
      return {
        text: "#ad4d0c",
        border: "#8c3b04",
        bgColor: "bg-[#8c3b04]/[0.1]",
      };
    case "FORFEITED":
      return {
        text: "#084f47",
        border: "#084f47",
        bgColor: "bg-[#084f47]/[0.1]",
      };
    case "EQUITY":
      return {
        bgColor: "bg-[#241DBA]/[0.1]",
        text: "#241DBA",
        border: "#241DBA",
      };

    case "PREFERENCE":
      return {
        bgColor: "bg-[#2DA900]/[0.1]",
        text: "#2DA900",
        border: "#2DA900",
      };

    case "CCD":
      return {
        bgColor: "bg-[#00A3C7]/[0.1]",
        text: "#00A3C7",
        border: "#00A3C7",
      };
    case "OCD":
      return {
        bgColor: "bg-[#E79212]/[0.1]",
        text: "#E79212",
        border: "#E79212",
      };
    case "NOTE":
      return {
        bgColor: "bg-[#F86161]/[0.1]",
        text: "#F86161",
        border: "#F86161",
      };
    case "WARRANT":
      return {
        bgColor: "bg-[#6904DC]/[0.1]",
        text: "#6904DC",
        border: "#6904DC",
      };

    case "RPS":
      return {
        bgColor: "bg-[#BAA8ED]/[0.1]",
        text: "#BAA8ED",
        border: "#BAA8ED",
      };

    case "NCD":
      return {
        bgColor: "bg-[#A6A900]/[0.1]",
        text: "#A6A900",
        border: "#A6A900",
      };

    case "OCPS":
      return {
        bgColor: "bg-[#00AD6F]/[0.1]",
        text: "#00AD6F",
        border: "#00AD6F",
      };

    case "OCRPS":
      return {
        bgColor: "bg-[#E79212]/[0.1]",
        text: "#E79212",
        border: "#E79212",
      };

    case "DEBENTURE":
      return {
        bgColor: "bg-[#FFD467]/[0.1]",
        text: "#FFD467",
        border: "#FFD467",
      };

    case "CCPS":
      return {
        bgColor: "bg-[#2DA900]/[0.1]",
        text: "#2DA900",
        border: "#2DA900",
      };
    case "HNI":
      return {
        bgColor: "bg-[#8B0000]/[0.1]",
        text: "#8B0000",
        border: "#8B0000",
      };

    default:
      return {
        text: "#E8FFF3",
        border: "#E8FFF3",
        bgColor: "bg-[#E8FFF3]/[0.1]",
      };
  }
}
export function getShareholderTagColor(shareholderType: string): string {
  const type = shareholderType.toUpperCase();
  switch (type) {
    case "FOUNDER":
      return "text-[#241DBA] border-[#241DBA]";
    case "ANGEL":
      return "text-[#2DA900] border-[#2DA900]";
    case "VC":
      return "text-[#00A3C7] border-[#00A3C7]";
    case "VENTURE CAPITAL":
      return "text-[#00A3C7] border-[#00A3C7]";
    case "INCUBATOR":
      return "text-[#E79212] border-[#E79212]";
    case "ACCELERATOR":
      return "text-[#F86161] border-[#F86161]";
    case "ANGELNETWORK":
      return "text-[6904DC] border-[6904DC]";
    case "ANGEL NETWORK":
      return "text-[6904DC] border-[6904DC]";
    case "OTHERS":
      return "text-[#B5A18A] border-[#B5A18A]";
    case "ANGELFUND":
      return "text-[#9A7CEE] border-[#9A7CEE]";
    case "CORPORATE":
      return "text-[#BABC57] border-[#BABC57]";
    case "FAMILY OFFICE" || "FAMILYOFFICE":
      return "text-[#57BF99] border-[#57BF99]";
    case "PE":
      return "text-[#E1B774] border-[#E1B774]";
    case "FINANCIAL INSTITUTION":
      return "text-[#2DA900] border-[#2DA900]";
    case "FINANCIALINSTITUTION":
      return "text-[#2DA900] border-[#2DA900]";
    case "OPTIONS":
      return "text-[#E49494] border-[#E49494]";
    case "EMPLOYEE":
      return "text-[#B5A18A]/[0.6] border-[#B5A18A]/[0.6]";
    case "ESOPTRUST":
      return "text-[#ad4d0c] border-[#8c3b04]";
    case "TRUST":
      return "text-[#ad4d0c] border-[#8c3b04]";
    case "FORFEITED":
      return "text-[#084f47] border-[#084f47]";
    case "HNI":
      return "text-[#8B0000] border-[#8B0000]";
    default:
      return "text-[#E8FFF3] border-[#E8FFF3]";
  }
}

export const shareholderOrder = {
  founder: 1,
  angel: 2,
  angelnetwork: 3,
  angelfund: 4,
  incubator: 5,
  accelerator: 6,
  vc: 7,
  corporate: 8,
  familyoffice: 9,
  pe: 10,
  financialinstitution: 11,
  hni: 12,
  options: 13,
  employee: 14,
  others: 15,
};

export const ShareholderSubTypes = [
  { text: "Endowment Funds", value: "EndowmentFunds" },
  { text: "Commercial Banks", value: "CommercialBanks" },
  { text: "Mutual Funds", value: "MutualFunds" },
  { text: "Hedge Funds", value: "HedgeFunds" },
  { text: "Pension Funds", value: "PensionFunds" },
  { text: "Insurance Companies", value: "InsuranceCompanies" },
  { text: "Others", value: "Others" },
];
