import { useEffect, useMemo, useState } from "react";
import { Icon } from "@iconify/react";
import { Box, Center, HStack, VStack } from "../../components/utils";
import { globalFilter, sort } from "../../utils/arrays";
import BasicMenu from "../../components/shared/Menu";
import Pagination from "../../components/shared/Pagination";
import Avatar from "../Utility/Avatar";
import { limitString, _trimAll } from "../../utils/string";
import { Action, CTADropdown } from "../../components/shared/Dropdown";
import { CumulativeHoldingQuickRound } from "../../types/CapTable";
import { useCapTableFilterStore } from "../../store/capTableFilterStore";
import { EmptyTable } from "../quickRound/EmptyTableComponents";
import {
  TableHeader,
  THead,
  tableEntryStyle,
} from "../../components/TableComponent";
import Tooltip from "../../components/shared/Tooltip";
import { FormatNumberSpan } from "../../utils/currencyRoboto";
import { getCurrency, getCurrencyType } from "../../utils/currencyFormatter";
import { downloadExcel } from "../../utils/DownloadFile";

export function ShareHolderCapTableQuickRound({
  data,
  toggle,
  isModeling,
}: {
  data: CumulativeHoldingQuickRound[];
  toggle: () => void;
  isModeling?: boolean;
}) {
  const [globalFilterText, setGlobalFilterText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [sortField, setSortField] = useState<{
    field: keyof CumulativeHoldingQuickRound | "" | undefined;
    ascending: boolean;
  }>({ field: "name", ascending: true });
  const filter = useCapTableFilterStore();
  const [totalData, setTotalData] = useState<CumulativeHoldingQuickRound>({
    postHoldingDebentures: 0,
    postHoldingEquityShares: 0,
    postHoldingFdbPercentage: 0,
    postHoldingFdbShares: 0,
    postHoldingPercentage: 0,
    postHoldingPrefShares: 0,
    postHoldingshares: 0,
    preHoldingDebentures: 0,
    preHoldingEquityShares: 0,
    preHoldingFdbPercentage: 0,
    preHoldingFdbShares: 0,
    preHoldingPercentage: 0,
    preHoldingPrefShares: 0,
    preHoldingshares: 0,
    id: "0",
    name: "",
    type: "",
  });
  data = useMemo(() => {
    if (!data) return [];
    try {
      const filterResult = globalFilter(data, globalFilterText, [
        "preHoldingFdbShares",
        "preHoldingFdbPercentage",
        "postHoldingFdbShares",
        "postHoldingFdbPercentage",
        "type",
        "name",
      ]);
      const sortResult = sort(
        filterResult,
        sortField?.field,
        sortField?.ascending
      );
      return sortResult;
    } catch (e) {
      return [];
    }
  }, [data, globalFilterText, filter, sortField]);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return data.slice(firstPageIndex, lastPageIndex);
  }, [data, currentPage, pageSize]);
  useEffect(() => {
    setCurrentPage(1);
  }, [globalFilterText, filter]);
  useEffect(() => {
    getTotalData();
  }, [currentTableData]);
  const getTotalData = () => {
    const totalDataLocal: CumulativeHoldingQuickRound = {
      id: "",
      name: "Total",
      postHoldingFdbPercentage: 0,
      postHoldingFdbShares: 0,
      preHoldingFdbPercentage: 0,
      preHoldingFdbShares: 0,
      type: "",
    };
    data.forEach((transaction: CumulativeHoldingQuickRound) => {
      totalDataLocal.postHoldingFdbPercentage +=
        transaction?.postHoldingFdbPercentage || 0;
      totalDataLocal.postHoldingFdbShares +=
        transaction?.postHoldingFdbShares || 0;
      totalDataLocal.preHoldingFdbPercentage +=
        transaction?.preHoldingFdbPercentage || 0;
      totalDataLocal.preHoldingFdbShares +=
        transaction?.preHoldingFdbShares || 0;
    });
    setTotalData(totalDataLocal);
  };
  const currencyType = getCurrencyType();

  return (
    <Box className="py-4 bg-white shadow-box rounded-lg border border-borderColor  mx-8">
      <HStack
        aria-label="toolbar"
        className="justify-between mb-8 flex-col lg:flex-row"
      >
        <HStack className="justify-between px-8">
          <VStack className="min-w-max">
            <p className="text-lg font-medium text-gray-dark">Cap Table</p>
            <p className="text-xs font-medium text-gray-light">
              {data.length} Shareholders
            </p>
          </VStack>
        </HStack>

        <HStack className="h-11 min-w-min pr-8">
          <HStack className="items-center h-12 min-h-min px-2 rounded-lg w-72 bg-slate-50 text-slate-dark">
            <Icon icon="fe:search" width="24" className="mr-2 " />
            <input
              type="text"
              className="md:w-full w-48 font-medium border-0 outline-none text-xs2 bg-inherit"
              placeholder="Search..."
              value={globalFilterText}
              onChange={(e) => {
                setGlobalFilterText(e.target.value);
              }}
            ></input>
          </HStack>
        </HStack>
      </HStack>
      <HStack className="justify-between">
        {/* <Box
          className={`${
            currentTableData.length > 3 ? "" : "h-96"
          } max-h-full overflow-x-auto`}
        > */}
        <table className="table-space w-full">
          <thead>
            <TableHeader>
              <th className="py-3"></th>

              <THead
                fieldName="name"
                isFirstFeild={true}
                fieldDisplayName="Shareholder Name"
                selectedFieldName={sortField?.field || ""}
                isAscending={sortField?.ascending || false}
                onChangeSort={() => {
                  setSortField({
                    field: "name",
                    ascending: !sortField?.ascending,
                  });
                }}
              />

              <THead
                fieldName="preHoldingFdbPercentage"
                fieldDisplayName="Pre Holding (%)"
                selectedFieldName={sortField?.field || ""}
                isAscending={sortField?.ascending || false}
                onChangeSort={() => {
                  setSortField({
                    field: "preHoldingFdbPercentage",
                    ascending: !sortField?.ascending,
                  });
                }}
              />

              <THead
                fieldName="postHoldingFdbPercentage"
                fieldDisplayName="Post Holding (%)"
                selectedFieldName={sortField?.field || ""}
                isAscending={sortField?.ascending || false}
                onChangeSort={() => {
                  setSortField({
                    field: "postHoldingFdbPercentage",
                    ascending: !sortField?.ascending,
                  });
                }}
              />

              <THead
                fieldName="preHoldingFdbShares"
                fieldDisplayName="Pre-Holding FDB Shares"
                selectedFieldName={sortField?.field || ""}
                isAscending={sortField?.ascending || false}
                onChangeSort={() => {
                  setSortField({
                    field: "preHoldingFdbShares",
                    ascending: !sortField?.ascending,
                  });
                }}
              />

              <THead
                fieldName="postHoldingFdbShares"
                fieldDisplayName="Post Holding FDB Shares"
                selectedFieldName={sortField?.field || ""}
                isAscending={sortField?.ascending || false}
                onChangeSort={() => {
                  setSortField({
                    field: "postHoldingFdbShares",
                    ascending: !sortField?.ascending,
                  });
                }}
              />
            </TableHeader>
          </thead>
          <tbody id="employee-table" className={!data ? "loading" : ""}>
            {currentTableData &&
              currentTableData?.map((captableEntry, index) => (
                <tr
                  key={index}
                  className="border-t border-dashed hover:bg-slate-50 w-full"
                >
                  <td className="px-2 py-4 align-top">
                    {captableEntry?.name ? (
                      <Avatar
                        status="Employed"
                        name={captableEntry.name || ""}
                      />
                    ) : (
                      <div className="w-8 h-8 loading-opacity"></div>
                    )}
                  </td>
                  <td className="py-4 align-top ">
                    <HStack>
                      <Box>
                        <Tooltip text={captableEntry.name}>
                          <p className="text-xs font-medium text-gray-dark">
                            {limitString(captableEntry.name || "", 40)}
                          </p>
                        </Tooltip>
                        <p className="leading-5 capitalize text-xxs text-gray-light">
                          {captableEntry.type}
                        </p>
                      </Box>
                    </HStack>
                  </td>
                  <td
                    className={`px-3 py-4 whitespace-nowrap ${tableEntryStyle}`}
                  >
                    <FormatNumberSpan
                      value={100 * captableEntry.preHoldingFdbPercentage}
                      format={currencyType}
                    />
                  </td>
                  <td
                    className={`px-3 py-4 whitespace-nowrap ${tableEntryStyle}`}
                  >
                    <FormatNumberSpan
                      value={100 * captableEntry.postHoldingFdbPercentage}
                      format={currencyType}
                    />
                  </td>
                  <td
                    className={`px-3 py-4 whitespace-nowrap ${tableEntryStyle}`}
                  >
                    <FormatNumberSpan
                      value={captableEntry.preHoldingFdbShares}
                      format={currencyType}
                    />
                  </td>
                  <td
                    className={`px-3 py-4 whitespace-nowrap ${tableEntryStyle}`}
                  >
                    <FormatNumberSpan
                      value={captableEntry.postHoldingFdbShares}
                      format={currencyType}
                    />
                  </td>
                </tr>
              ))}
            {currentTableData.length > 0 && (
              <tr
                key="total"
                className="border-t border-dashed hover:bg-slate-50"
              >
                <td className="px-2 py-4 align-top"></td>
                <td className="py-4 align-top ">
                  <HStack>
                    <Box>
                      <p className="text-xs font-semibold text-gray-dark">
                        Total
                      </p>
                    </Box>
                  </HStack>
                </td>
                <td
                  className={`px-3 py-4 whitespace-nowrap ${tableEntryStyle}`}
                >
                  <FormatNumberSpan
                    value={100 * totalData.preHoldingFdbPercentage}
                    format={currencyType}
                  />
                </td>
                <td
                  className={`px-3 py-4 whitespace-nowrap ${tableEntryStyle}`}
                >
                  <FormatNumberSpan
                    value={100 * totalData.postHoldingFdbPercentage}
                    format={currencyType}
                  />
                </td>
                <td
                  className={`px-3 py-4 whitespace-nowrap ${tableEntryStyle}`}
                >
                  <FormatNumberSpan
                    value={totalData.preHoldingFdbShares}
                    format={currencyType}
                  />
                </td>
                <td
                  className={`px-3 py-4 whitespace-nowrap ${tableEntryStyle}`}
                >
                  <FormatNumberSpan
                    value={totalData.postHoldingFdbShares}
                    format={currencyType}
                  />
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {/* </Box> */}
      </HStack>
      {currentTableData.length <= 0 && (
        <EmptyTable header="No Transaction Found" />
      )}
      <Box className="flex justify-between">
        <BasicMenu
          defaultValue={pageSize}
          options={[5, 10, 20, 50, 100]}
          onOptionChange={(value) => {
            setPageSize(value);
            setCurrentPage(1);
          }}
        />
        <Pagination
          currentPage={currentPage}
          totalCount={data.length}
          pageSize={pageSize}
          onPageChange={(page: number) => setCurrentPage(page)}
        />
      </Box>
    </Box>
  );
}
