import React, { useMemo } from "react";
import { CaptableTag } from "../../components/AllotmentTag";
import {
  tableEntryStyle,
  tableFirstColStyle,
} from "../../components/TableComponent";
import { VStack, HStack } from "../../components/utils";
import {
  getCurrencyType,
  getCurrencySymbol,
} from "../../utils/currencyFormatter";
import { getCurrencySymbolRoboto } from "../../utils/currencyRoboto";
import { limitString } from "../../utils/string";
import { ShareholderHoverChart } from "./ShareholderHoverChart";
import { currencyTypes } from "../../constants/CompanyCurrencyType";

export function CustomToolTip(props: any) {
  const data = useMemo(
    () => props.api.getDisplayedRowAtIndex(props.rowIndex).data,
    []
  );

  const currency = getCurrencyType();
  const symbol = getCurrencySymbolRoboto();
  return (
    <div className="relative group ">
      <div className="custom-tooltip">
        <div className="flex-col p-5 bg-white ">
          {/* <span className="relative z-10 p-5 text-xs leading-none bg-white "> */}
          <VStack className="gap-4">
            <HStack className="justify-between my-auto item-center w-80">
              <p
                data-event-name="captableChartShareholderDrilling"
                className="my-auto text-lg "
              >
                {data.shareholderName.name?.toLowerCase() === "esop pool"
                  ? "ESOP Pool"
                  : data.shareholderName.name || "Others"}
              </p>
              <div className="my-auto mt-1 item-center">
                <CaptableTag
                  tagName={data.shareholderName.type ?? "Others"}
                  dataEventName={"shareholderHoverChart"}
                />
              </div>
            </HStack>
            <VStack className="gap-2">
              {data?.fdbPercentage ? (
                <HStack>
                  <p className="font-medium text-blue-800">
                    Holding Percentage :
                  </p>
                  <p className="font-medium text-red-800">
                    {data.fdbPercentage.toFixed(2)}%
                  </p>
                </HStack>
              ) : (
                <div></div>
              )}
              {data?.amountInvested ? (
                <HStack>
                  <p className="font-medium text-blue-800">Investment : </p>
                  <p className="font-medium text-red-800">
                    {symbol} {data.amountInvested.toLocaleString(currency)}
                  </p>
                </HStack>
              ) : (
                <div></div>
              )}
              {data?.fdbShares ? (
                <HStack>
                  <p className="font-medium text-blue-800">Shares : </p>
                  <p className="font-medium text-red-800">
                    {data.fdbShares.toLocaleString(currency)}
                  </p>
                </HStack>
              ) : (
                <div></div>
              )}
            </VStack>
          </VStack>
          {data.fdbPercentage ? (
            <ShareholderHoverChart
              chartModel={[
                {
                  name: data.shareholderName.name || "",
                  value: data.fdbPercentage / 100,
                },
                {
                  name: "Others",
                  value: 1 - data.fdbPercentage / 100,
                },
              ]}
            />
          ) : (
            <div></div>
          )}
          {/* </span> */}
        </div>
      </div>
    </div>
  );
}

export const CustomCaptableTooltipComponent = (props: any) => {
  const currencyType = getCurrencyType();

  return (
    <div className="relative p-2 bg-white shadow-xl group">
      <div className={`${"custom-tooltip"}`}>
        <p className="p-1">
          <span>
            {Number(props.valueFormatted).toLocaleString(currencyType)}
          </span>
        </p>
      </div>
    </div>
  );
};

export function RoundsToolTip(props: any) {
  const data = useMemo(
    () => props.api.getDisplayedRowAtIndex(props.rowIndex).data,
    []
  );

  return (
    <div className="relative p-2 bg-white shadow-xl group">
      {data.roundIdentifiers && (
        <div
          className={`${
            data.roundIdentifiers[
              props.node?.key.toLowerCase().split(" ").join("")
            ].length <= 4
              ? "custom-tooltip scrollbar-hide"
              : "custom-tooltip max-h-24 overflow-y-scroll"
          }`}
        >
          {data.roundIdentifiers[
            props.node?.key.toLowerCase().split(" ").join("")
          ]?.map(
            (round: any) =>
              round.roundId && (
                <p className="p-1" key={round.roundId}>
                  <span>
                    {round.roundIdentifier} - {round.roundName}
                  </span>
                </p>
              )
          )}
        </div>
      )}
      {data.rounds && (
        <div
          className={`${
            data.rounds.length <= 4
              ? "custom-tooltip scrollbar-hide"
              : "custom-tooltip max-h-24 overflow-y-scroll"
          }`}
        >
          {data.rounds?.map(
            (round: any) =>
              round.roundId && (
                <p className="p-1" key={round.roundId}>
                  <span>
                    {round.roundIdentifier} - {round.roundName}
                  </span>
                </p>
              )
          )}
        </div>
      )}
    </div>
  );
}

export function financeCustomToolTip(props: any) {
  const currencySymbol = getCurrencySymbol();
  const currencyType = getCurrencyType();
  // Customize tooltip content based on the cell value or any other logic
  return (
    <div className="relative p-2 bg-white shadow-xl group">
      {props.column.colId && (
        <span>
          {Number(props.data[props.column.colId])?.toLocaleString(currencyType)}
        </span>
      )}
    </div>
  );
}

export function financeCustomRatiosToolTip(props: any) {
  const currencyType = getCurrencyType();
  // Customize tooltip content based on the cell value or any other logic
  return (
    <div className="relative p-2 bg-white shadow-xl group">
      {props.column.colId && (
        <span>{Number(props.data[props.column.colId])}</span>
      )}
    </div>
  );
}

export function tooltipLiquidationPreferences(props: any) {
  const currencyType = getCurrencyType();

  // Customize tooltip content based on the cell value or any other logic
  return (
    <div className="relative p-2 bg-white shadow-xl group">
      {props.column.colId && (
        <VStack>
          <p className="whitespace-nowrap">
            LP Value :{" "}
            {Number(props.data.liquidationPreferences).toLocaleString(
              currencyType
            )}
          </p>
          <p className="whitespace-nowrap">
            Participation Cap :{" "}
            {Number(props.data.participationCap).toLocaleString(currencyType)}
          </p>
        </VStack>
      )}
    </div>
  );
}

export function ShareholderToolTip(props: any) {
  const data = useMemo(
    () => props.api.getDisplayedRowAtIndex(props.rowIndex).data,
    []
  );

  return (
    data.shareholder.length > 0 && (
      <div className="relative p-2 bg-white shadow-xl group">
        <div className={`${"custom-tooltip max-h-24 overflow-y-scroll"}`}>
          {data.shareholder.map((shareholder: any) => (
            <p className="p-1" key={shareholder.shareholderId}>
              <span>
                {shareholder.shareholderName[0]} - {shareholder.shareholderName}
              </span>
            </p>
          ))}
        </div>
      </div>
    )
  );
}

export function ShareholderPPSTooltip(props: any) {
  const data = useMemo(
    () => props.api.getDisplayedRowAtIndex(props.rowIndex).data,
    []
  );

  return (
    <div className="relative p-2 bg-white shadow-xl group">
      <div className={`${"custom-tooltip max-h-24"}`}>
        {data.isPartlyPaid ? (
          <VStack>
            <HStack className="gap-2">
              <p className="font-medium text-blue-800">PPS Paid :</p>
              <p className="font-medium text-red-800">
                {data.currencySymbol}
                {data.pps.toLocaleString(data.currencyType)}
              </p>
            </HStack>
            {data.isPartlyPaid && (
              <HStack className="gap-2">
                <p className="font-medium text-blue-800">PPS Value :</p>
                <p className="font-medium text-red-800">
                  {data.currencySymbol}
                  {data.actualSharePrice.toLocaleString(data.currencyType)}
                </p>
              </HStack>
            )}
          </VStack>
        ) : (
          <p className={tableEntryStyle}>
            {data.pps.toLocaleString(data.currencyType)}
          </p>
        )}
      </div>
    </div>
  );
}

export function ValuationPPSTooltip(props: any) {
  const data = useMemo(
    () => props.api.getDisplayedRowAtIndex(props.rowIndex).data,
    []
  );

  return (
    <div className="relative p-2 bg-white shadow-xl group">
      <div className={`${"custom-tooltip max-h-24"}`}>
        <VStack className="gap-2">
          <HStack className="gap-2">
            <p className="font-medium text-blue-800">Valuation :</p>
            <p className="font-medium text-red-800">
              {data.currencySymbol}
              {data.valuation.toLocaleString(data.currencyType)}
            </p>
          </HStack>
          {data.pps && (
            <HStack className="gap-2">
              <p className="font-medium text-blue-800">PPS :</p>
              <p className="font-medium text-red-800">
                {data.currencySymbol}
                {data.pps.toLocaleString(data.currencyType)}
              </p>
            </HStack>
          )}
        </VStack>
      </div>
    </div>
  );
}
