import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import BCHeader from "../../shared/BCHeader";
import { Type3CompanyPageModel } from "../../types/T3CompanyModel";
import { useType3CompanyOverviewDetails } from "../../queries/t3Company";
import { dataDict } from "../t2Company/Type2Company";
import CompanyOverviewCard from "./Type3CompanyOverviewCard";
import {
  tableFirstColStyle,
  TableHeader,
  THead,
} from "../../components/TableComponent";
import { ColumnOptions } from "../../types/DashboardModel";
import Tooltip from "../../components/shared/Tooltip";
import { limitString } from "../../utils/string";
import { formatDisplayDate } from "../../utils/date";
import { VStack } from "../../components/utils";
import { handleEventForTracking } from "../../amplitudeAnalytics";

const initialColumnState = [
  {
    prop: "shareholderName",
    displayName: "Shareholder Name",
    isVisible: true,
    isDisabled: false,
  },
  {
    prop: "securityTypeClass",
    displayName: "Security Type",
    isVisible: true,
    isDisabled: false,
  },
  {
    prop: "numberOfShares",
    displayName: "No. of Shares",
    isVisible: true,
    isDisabled: false,
  },
  {
    prop: "shareholderFDBPercentage",
    displayName: "Shareholder FDB%",
    isVisible: true,
    isDisabled: false,
  },
  {
    prop: "shareholderType",
    displayName: "Shareholder Type",
    isVisible: true,
    isDisabled: false,
  },
  {
    prop: "shareholderCountry",
    displayName: "Country",
    isVisible: true,
    isDisabled: false,
  },
  {
    prop: "dateOfInvestment",
    displayName: "Date of Investment",
    isVisible: true,
    isDisabled: false,
  },
  {
    prop: "pricePerShare",
    displayName: "Price Per Share",
    isVisible: true,
    isDisabled: false,
  },
  {
    prop: "investmentCurrency",
    displayName: "Investment Currency",
    isVisible: true,
    isDisabled: false,
  },
];

export default function Type3CompanyPage() {
  const { cin, token } = useParams();
  const { data: type3CompanyDetails } = useType3CompanyOverviewDetails(
    cin ?? "",
    token
  );

  const navigate = useNavigate();
  const [columnOptions, setColumnOptions] =
    useState<ColumnOptions[]>(initialColumnState);

  useEffect(() => {
    if (!cin) {
      handleEventForTracking({ eventName: "Type 3 Company" });
      navigate("/404");
    }
  }, []);

  const checkKeyDate = (key: string) =>
    ["Created At", "Updated At", "Last Round Date"].includes(key);
  return (
    <div className={"mx-auto max-w-7xl sm:px-6 lg:px-8"}>
      <BCHeader className="items-baseline mb-2" bcTitle="Company Overview" />
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {type3CompanyDetails &&
          Object.keys(type3CompanyDetails).map((key) => {
            const typedKey = key as keyof Type3CompanyPageModel;
            const value = type3CompanyDetails[typedKey]?.toString() || "";
            if (key !== "allotments") {
              return (
                <div key={key} className="bg-white p-4  rounded-md">
                  <CompanyOverviewCard
                    key={key}
                    name={dataDict[typedKey]}
                    value={value}
                    isDate={checkKeyDate(dataDict[typedKey])}
                    companyCurrency={dataDict.companyCurrency}
                  />
                </div>
              );
            }
            return <></>;
          })}
      </div>
      <div className="relative flex flex-col px-0 mt-8">
        <VStack>
          <p className="text-sm font-medium text-gray-dark">
            Shareholders Table
          </p>
        </VStack>
        <div className="overflow-x-auto pt-4">
          <div className="inline-block min-w-full align-middle">
            <div className="md:rounded-lg">
              <table className="min-w-full bg-white">
                <thead>
                  <TableHeader>
                    {columnOptions.map(
                      (element) =>
                        element.isVisible && (
                          <THead
                            key={element.name}
                            fieldDisplayName={element.displayName || ""}
                            fieldName={element.name || ""}
                            isColumnFilter={false}
                          />
                        )
                    )}
                  </TableHeader>
                </thead>
                <tbody className="">
                  {type3CompanyDetails &&
                    (
                      type3CompanyDetails as unknown as Type3CompanyPageModel["data"]
                    ).allotments.map((allotment) => (
                      <tr
                        key={`${allotment.shareholderName}`}
                        className="border-t whitespace-nowrap border-dashed hover:bg-slate-50 text-[#030303] text-xs2 font-medium "
                      >
                        <td className="py-4 pr-3 text-sm whitespace-nowrap ">
                          <div className="flex items-center">
                            <div>
                              {allotment.shareholderName.length > 24 ? (
                                <Tooltip text={allotment.shareholderName}>
                                  <p className={tableFirstColStyle}>
                                    {limitString(
                                      allotment.shareholderName || "",
                                      24
                                    )}
                                  </p>
                                </Tooltip>
                              ) : (
                                <p className={tableFirstColStyle}>
                                  {allotment.shareholderName}
                                </p>
                              )}
                            </div>
                          </div>
                        </td>
                        <td className="py-4 pr-3 text-sm whitespace-nowrap ">
                          <div className="flex items-center">
                            <div>
                              <p className={tableFirstColStyle}>
                                {allotment.securityTypeClass}
                              </p>
                            </div>
                          </div>
                        </td>
                        <td className="py-4 pr-3 text-sm whitespace-nowrap ">
                          <div className="flex items-center">
                            <div>
                              <p className={tableFirstColStyle}>
                                {allotment.numberOfShares}
                              </p>
                            </div>
                          </div>
                        </td>
                        <td className="py-4 pr-3 text-sm whitespace-nowrap ">
                          <div className="flex items-center">
                            <div>
                              <p className={tableFirstColStyle}>
                                {allotment.shareholderFDBPercentage * 100.0}
                              </p>
                            </div>
                          </div>
                        </td>
                        <td className="py-4 pr-3 text-sm whitespace-nowrap ">
                          <div className="flex items-center">
                            <div>
                              <p className={tableFirstColStyle}>
                                {allotment.shareholderType}
                              </p>
                            </div>
                          </div>
                        </td>
                        <td className="py-4 pr-3 text-sm whitespace-nowrap ">
                          <div className="flex items-center">
                            <div>
                              <p className={tableFirstColStyle}>
                                {allotment.shareholderCountry}
                              </p>
                            </div>
                          </div>
                        </td>
                        <td className="py-4 pr-3 text-sm whitespace-nowrap ">
                          <div className="flex items-center">
                            <div>
                              <p className={tableFirstColStyle}>
                                {formatDisplayDate(
                                  allotment.dateOfInvestment as unknown as string
                                )}
                              </p>
                            </div>
                          </div>
                        </td>
                        <td className="py-4 pr-3 text-sm whitespace-nowrap ">
                          <div className="flex items-center">
                            <div>
                              <p className={tableFirstColStyle}>
                                {allotment.pricePerShare}
                              </p>
                            </div>
                          </div>
                        </td>
                        <td className="py-4 pr-3 text-sm whitespace-nowrap ">
                          <div className="flex items-center">
                            <div>
                              <p className={tableFirstColStyle}>
                                {allotment.investmentCurrency}
                              </p>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
