import { useShareAuthStore } from "../../store/useShareAuthStore";
import { SharedResource } from "../../constants/SharedResource";
import RoundsOverview from "../rounds/RoundsOverview";
import TransactionPreviewPage from "../newQuickTransaction/TransactionPreviewPage";
import DashboardMainPage from "../dashboardPage/DashboardMainPage";
import NonPricedRoundModelTemplate from "../newModeling/NonPricedRoundModelTemplate";
import RoundModelTemplate from "../newModeling/ModelingPage";
import PricedRoundSharedSummaryPage from "../modeling/PricedRoundSharedSummaryPage";

const SharedPage = () => {
  const { shareResourceType } = useShareAuthStore();
  return (
    <div>
      {shareResourceType === SharedResource.ROUND_TRANSACTION && (
        <RoundsOverview />
      )}
      {shareResourceType === SharedResource.ROUND_CREATION_SUMMARY && (
        <TransactionPreviewPage />
      )}
      {shareResourceType === SharedResource.DASHBOARD && <DashboardMainPage />}
      {shareResourceType === SharedResource.NON_PRICED_ROUND_MODEL && (
        <NonPricedRoundModelTemplate />
      )}
      {shareResourceType === SharedResource.PRICED_ROUND_MODEL && (
        <PricedRoundSharedSummaryPage />
      )}
    </div>
  );
};

export default SharedPage;
