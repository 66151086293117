import { Form, Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import _ from "lodash";
import { Input, Label } from "../components/shared/InputField";
import { Select } from "../components/shared/Select";
import { HStack, VStack, Error } from "../components/utils";
import {
  AddSecondaryTransactionModalType,
  QuickBonusSecurityModel,
  SecondaryTransactionDataModel,
  Shareholder,
} from "../types/quickRound";
import { useQuickBonusSecurityStore } from "../store/QuickBonusSecuritiesStore";
import { useShareholderInstrumentStore } from "../store/shareholderInstrumentStore";
import { useAuthStore } from "../store";
import { useGetInstrumentAndShareHolder } from "../queries/quickRound";
import {
  ButtonSize,
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../pages/quickRound/CTAButtonComponents";

type AddBonusSecurityModalProps = {
  initialValue?: QuickBonusSecurityModel;

  onPrimaryAction: () => void;
  onSecondaryAction: () => void;
  mode: "Add" | "Edit";
};

const AddBonusSecurityDetails = ({
  initialValue,
  mode,
  onPrimaryAction = () => {},
  onSecondaryAction = () => {},
}: AddBonusSecurityModalProps) => {
  const shareholderInstruments = useShareholderInstrumentStore();

  const shareHolders: string[] =
    shareholderInstruments?.shareholders
      .filter((shareholder) => shareholder.name.toLowerCase() !== "esop pool")
      .map((shareHolderDetail) => shareHolderDetail.name) || [];
  const instruments: string[] =
    shareholderInstruments?.instrumentClasses.map(
      (instrument) => instrument.name
    ) || [];

  const initialShareHolderValues: QuickBonusSecurityModel = {
    type: initialValue?.type || "",
    sourceSecurity: initialValue?.sourceSecurity || "",
    bonusRatio: initialValue?.bonusRatio || "1:1",
    newShares: initialValue?.newShares || 0,
    conversionRatio: initialValue?.conversionRatio || "1:1",
    finalSecurity: initialValue?.finalSecurity || "",
    investorName: initialValue?.investorName || "",
  };

  const quickBonusTransactionInputSchema = Yup.object({
    sourceSecurity: Yup.string().required().label("Source Security"),
    investorName: Yup.string().required().label("Investor Name"),
    newShares: Yup.number()
      .integer()
      .min(1)
      .required()
      .label("New No.of Shares"),
    finalSecurity: Yup.string().required().label("Bonus Security"),
  });

  const bonusSecurityStore = useQuickBonusSecurityStore();

  const handleSubmit = (values: QuickBonusSecurityModel) => {
    const instrumentClass = shareholderInstruments?.instrumentClasses.find(
      (instrument) => instrument.name === values.sourceSecurity
    );

    const investorId =
      shareholderInstruments?.shareholders.find(
        (instrument) => instrument.name === values.investorName
      )?.id || "";
    const instrumentClassId =
      shareholderInstruments?.instrumentClasses.find(
        (instrument) => instrument.name === values.sourceSecurity
      )?.id || "";
    const finalConversionInstrumentClassId =
      shareholderInstruments?.instrumentClasses.find(
        (instrument) => instrument.name === values.finalSecurity
      )?.id || "";
    const bonusSecurity: QuickBonusSecurityModel = {
      instrumentClassId,
      finalConversionInstrumentClassId,
      investorId,
      type: instrumentClass?.subType || "",
      sourceSecurity: values.sourceSecurity || "",
      bonusRatio: values.bonusRatio || "1:1",
      newShares: values.newShares || 0,
      conversionRatio: `1:${instrumentClass?.conversionRatio || 1}`,
      finalSecurity: values.finalSecurity || "",
      investorName: values.investorName || "",
    };
    if (_.some(bonusSecurityStore.bonusSecurities, bonusSecurity)) {
      toast("transaction already exists", { type: "error", autoClose: 2000 });
    } else {
      bonusSecurityStore.setBonusSecurities(bonusSecurity);
      onPrimaryAction();
    }
  };

  return (
    <Formik
      key="addBonusSecurity"
      enableReinitialize={true}
      initialValues={initialShareHolderValues}
      validationSchema={quickBonusTransactionInputSchema}
      onSubmit={(values) => {
        handleSubmit(values);
      }}
    >
      {(formik) => (
        <Form key="shareholder">
          <div className="w-full bg-white rounded-md px-2 border-dashed border-2">
            <HStack className="text-lg text-left font-medium border-b py-4">
              <h6 className="flex-1">Add Bonus Security Details</h6>
            </HStack>
            <VStack className="w-full  px-2 py-4 gap-6">
              <VStack className="gap-8">
                <HStack className="gap-4 md:flex-row flex-col">
                  <div className="flex-1">
                    <Label className="text-sm font-normal">Investor Name</Label>
                    <Select
                      options={shareHolders}
                      {...formik.getFieldProps("investorName")}
                    />
                    {formik.touched?.investorName &&
                      formik.errors?.investorName && (
                        <Error text={formik.errors?.investorName} />
                      )}
                  </div>
                  <div className="flex-1">
                    <Label className="text-sm font-normal">
                      Source Security
                    </Label>
                    <Select
                      options={instruments}
                      {...formik.getFieldProps("sourceSecurity")}
                    />
                    {formik.touched?.sourceSecurity &&
                      formik.errors?.sourceSecurity && (
                        <Error text={formik.errors?.sourceSecurity} />
                      )}
                  </div>
                </HStack>
                <HStack className="gap-4  md:flex-row flex-col">
                  <div className="flex-1">
                    <Label className="text-sm font-normal">New Shares</Label>
                    <Input
                      type="number"
                      {...formik.getFieldProps("newShares")}
                    />
                    {formik.touched.newShares && formik.errors?.newShares && (
                      <Error text={formik.errors?.newShares} />
                    )}
                  </div>
                  <div className="flex-1">
                    <Label className="text-sm font-normal">
                      Bonus Security
                    </Label>
                    <Select
                      options={instruments}
                      {...formik.getFieldProps("finalSecurity")}
                    />
                    {formik.touched.finalSecurity &&
                      formik.errors?.finalSecurity && (
                        <Error text={formik.errors?.finalSecurity} />
                      )}
                  </div>
                </HStack>
              </VStack>

              <HStack className="justify-between h-8">
                <SecondaryCTAButton
                  event-name="Cancel Bonus Modal"
                  buttonSize={ButtonSize.SMALL}
                  onClick={() => {
                    if (mode === "Edit") {
                      handleSubmit({
                        type: initialValue?.type || "",
                        sourceSecurity: initialValue?.sourceSecurity || "",
                        bonusRatio: initialValue?.bonusRatio || "",
                        newShares: initialValue?.newShares || 0,
                        conversionRatio: initialValue?.conversionRatio || "",
                        finalSecurity: initialValue?.finalSecurity || "",
                        investorName: initialValue?.investorName || "",
                      });
                    } else {
                      onSecondaryAction();
                    }
                  }}
                >
                  Cancel
                </SecondaryCTAButton>
                <PrimaryCTAButton
                  event-name="Save Bonus Modal"
                  buttonSize={ButtonSize.SMALL}
                  type="submit"
                >
                  Save
                </PrimaryCTAButton>
              </HStack>
            </VStack>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddBonusSecurityDetails;
