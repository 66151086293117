import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  CellClickedEvent,
  ColDef,
  GetDataPath,
  GridApi,
  SideBarDef,
} from "ag-grid-community";

import { useNavigate } from "react-router";

import { AgGridReact } from "ag-grid-react";

import { Box } from "@mui/material";
import {
  getCurrencySymbol,
  getCurrencyType,
} from "../../../../utils/currencyFormatter";
import { tableCellStyle } from "../../../../components/TableComponent";
import SearchInput from "../../../../components/shared/SearchInput";
import { useGetCompanyName, HStack } from "../../../../components/utils";
import GenericTableHeader from "../../../../shared/TableHeader";
import { useAuthStore } from "../../../../store";
import { useAgGridTableState } from "../../../../store/agGridTableStore";
import { downloadExcel } from "../../../../utils/DownloadFile";
import { ExportImport } from "../../../Utility/GrantsTable";
import {
  useGetOnFilterState,
  usePostOnFilterState,
} from "../../../dashboardPage/AgGridCacheQuery";
import { agConfigDashboardSideBar } from "../../../dashboardPage/AgGridConfig";
import {
  RoundsToolTip,
  financeCustomRatiosToolTip,
  financeCustomToolTip,
} from "../../../dashboardPage/customTooltip";
import { IconCTAButton } from "../../../quickRound/CTAButtonComponents";
import { TimeLineRenderer } from "./RatiosAGComponent";
import { handleEventForTracking } from "../../../../amplitudeAnalytics";

function AGGridProfitabilityRatiosTable({
  data,
  timelineType,
}: {
  data: any;
  timelineType: string;
}) {
  const companyName = useGetCompanyName();
  const companyId = useAuthStore().companyId ?? "";
  const userId = useAuthStore().user?.userId ?? 0;
  const gridApi = useRef<any>(null);
  const gridRef = useRef<AgGridReact>(null);
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);
  const currencySymbol = getCurrencySymbol();

  if (timelineType === "Year") {
    data?.sort((a: any, b: any) => parseInt(b.year, 10) - parseInt(a.year, 10));
  } else if (timelineType === "Quarterly") {
    data?.sort((a: any, b: any) => {
      if (a.year !== b.year) {
        return b.year - a.year;
      } else {
        const quartersOrder = ["Q1", "Q2", "Q3", "Q4"];
        return (
          quartersOrder.indexOf(b.timeline) - quartersOrder.indexOf(a.timeline)
        );
      }
    });
  } else if (timelineType === "Monthly") {
    data?.sort((a: any, b: any) => {
      if (a.year === b.year) {
        // If years are equal, sort based on month in descending order
        const months = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        return months.indexOf(b.timeline) - months.indexOf(a.timeline);
      } else {
        return b.year - a.year;
      }
    });
  }

  useEffect(() => {
    if (data && timelineType === "Year") {
      const newColumnDefs = data.map((template: any) => ({
        headerName: `${template.timeline}`,
        field: template.timeline,
        filter: "agNumberColumnFilter",
        cellRenderer: "timeline",
        initialWidth: 300,
        sortable: true,
        autoHeight: true,
        cellStyle: tableCellStyle,
        wrapText: true,
        suppressSizeToFit: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipText",
        tooltipComponent: financeCustomRatiosToolTip,
      }));
      setColumnDefs(newColumnDefs);
    } else if (data && timelineType === "Monthly") {
      const newColumnDefs = data.map((template: any) => ({
        headerName: `${template.year}-${template.timeline} (${currencySymbol})`,
        field: template.timeline,
        filter: "agNumberColumnFilter",
        cellRenderer: "timeline",
        initialWidth: 300,
        sortable: true,
        autoHeight: true,
        cellStyle: tableCellStyle,
        wrapText: true,
        suppressSizeToFit: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipText",
        tooltipComponent: financeCustomToolTip,
      }));
      setColumnDefs(newColumnDefs);
    } else if (data && timelineType === "Quarterly") {
      const newColumnDefs = data.map((template: any) => ({
        headerName: `${template.year}-${template.timeline} (${currencySymbol})`,
        field: template.timeline,
        filter: "agNumberColumnFilter",
        cellRenderer: "timeline",
        initialWidth: 300,
        sortable: true,
        autoHeight: true,
        cellStyle: tableCellStyle,
        wrapText: true,
        suppressSizeToFit: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipText",
        tooltipComponent: financeCustomToolTip,
      }));
      setColumnDefs(newColumnDefs);
    } else {
      setColumnDefs([]);
    }
  }, [data]);

  //Dirty commit const { refetch, data: filterState } = useGetOnFilterState(
  //   `${userId}`,
  //   companyId
  // );
  // const agTableStore = useAgGridTableState();
  // useEffect(() => {
  //   refetch().then((data) => {
  //     if (data.data) agTableStore.setState(data.data);
  //     setColumnSetting(data.data?.profitabilityColumnModel);
  //     setFilterSetting(data.data?.profitabilityFilterModel);
  //   });
  // }, []);

  //Dirty commit const { mutate: postOnFilter } = usePostOnFilterState();

  //Dirty commit function getColumnSetting() {
  //   if (gridApi.current) return gridApi.current.columnApi?.getColumnState();
  //   return {};
  // }

  //Dirty commit function setColumnSetting(model: any) {
  //   if (gridApi.current)
  //     gridApi.current.columnApi.applyColumnState({ state: model });
  //   return {};
  // }

  //Dirty commit // Gets filter model via the grid API
  // const getFilterSetting = () => {
  //   if (gridApi.current) return gridApi.current.api?.getFilterModel();
  //   return {};
  // };

  //Dirty commit const setFilterSetting = (model: any) => {
  //   if (gridApi.current) return gridApi.current.api?.setFilterModel(model);
  //   return {};
  // };

  //Dirty commit const uploadFilterAndColumn = async () => {
  //   const columnState = await getColumnSetting();
  //   const filterState = await getFilterSetting();
  //   postOnFilter({
  //     userId: `${userId}`,
  //     companyId,
  //     filterData: {
  //       ...agTableStore.state,
  //       profitabilityColumnModel: columnState,
  //       profitabilityFilterModel: filterState,
  //     },
  //   });
  // };

  const cin = "";

  const componentsRegistery = useMemo(
    () => ({
      timeline: memo(TimeLineRenderer),
    }),
    []
  );

  const navigate = useNavigate();

  const onClickShareholderName = (id: string) => {
    handleEventForTracking({ eventName: `Shareholder Name` });
    navigate(`/shareholders/individual/${id}`);
  };

  const isValidDate = (date: string) =>
    Number.isNaN(date) &&
    new Date(date).toString() !== "Invalid Date" &&
    !Number.isNaN(Date.parse(date));

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      autoHeight: true,
      wrapHeaderText: true,
      suppressColumnVirtualisation: true,
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      filterParams: {
        buttons: ["reset"],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
      flex: 1,
    }),
    [data]
  );

  const getRowStyle = (params: any) => {
    let rowStyle;
    if (params.rowIndex % 2 === 0) {
      rowStyle = { background: "#f8f8f8" };
    } else {
      rowStyle = { background: "#ffffff" };
    }
    if (
      (params.data.orgHierarchy.length === 1 &&
        params.data.orgHierarchy[0] === "Return On Capital Employed (in %)") ||
      (params.data.orgHierarchy.length === 1 &&
        params.data.orgHierarchy[0] === "Return On Equity Ratio (in %)") ||
      (params.data.orgHierarchy.length === 1 &&
        params.data.orgHierarchy[0] === "Net Profit Margin Ratio (in %)")
    ) {
      rowStyle = {
        ...rowStyle,

        color: "black",
        fontWeight: 600,
      };
    }

    return rowStyle;
  };

  const autoGroupColumnDef = useMemo<ColDef>(
    () => ({
      headerName: "Ratio Name",
      minWidth: 300,
      menuTabs: [],
      cellRenderer: "agGroupCellRenderer",
      cellRendererParams: {
        suppressCount: true,
      },
      pinned: true,
    }),
    [data]
  );

  const getDataPath = useMemo<GetDataPath>(
    () => (data: any) => data.orgHierarchy,
    []
  );

  const rowData = useMemo(() => {
    if (!data) return [];

    return data
      .map((template: any) => {
        const timeline = template.timeline;
        const netProfitMarginRatio = Number(template.data.netProfitMarginRatio);
        const returnOnEquity = Number(template.data.returnOnEquity);
        const returnOnCapitalEmployed = Number(
          template.data.returnOnCapitalEmployed
        );

        const ratiosTooltip = template.data.netProfitMarginRatio;
        return [
          {
            orgHierarchy: ["Net Profit Margin Ratio (in %)"],
            [timeline]: netProfitMarginRatio,
            tooltipText: ratiosTooltip,
          },
          {
            orgHierarchy: ["Return On Equity Ratio (in %)"],
            [timeline]: returnOnEquity,
            tooltipText: ratiosTooltip,
          },
          {
            orgHierarchy: ["Return On Capital Employed (in %)"],
            [timeline]: returnOnCapitalEmployed,
            tooltipText: ratiosTooltip,
          },
        ];
      })
      .flat();
  }, [data]);

  const groupedData = rowData.reduce((groups: any, item: any) => {
    const key = JSON.stringify(item.orgHierarchy);
    (groups[key] = groups[key] || []).push(item);
    return groups;
  }, {});

  const groupedArray = Object.values(groupedData);
  const combinedData = groupedArray.flatMap((group: any) => {
    const combinedGroup = Object.assign({}, ...group);

    return [combinedGroup];
  });

  const handleCellClick = (cellParams: CellClickedEvent<any, any>) => {
    if (cellParams.column.getColId() !== "action-column") {
      const template = cellParams.data;
      if (cellParams.column.getColId() === "shareholderName") {
        onClickShareholderName(template.id);
      }
    }
  };
  const [isColumnOpen, setIsColumnOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const openToolPanel = (key: any) => {
    if (key === "columns") {
      if (gridApi) {
        if (!isColumnOpen) gridApi?.current?.api?.openToolPanel(key);
        else gridApi?.current?.api?.closeToolPanel();
        setIsColumnOpen((state) => !state);
        setIsFilterOpen(false);
      }
    } else if (key === "filters") {
      if (gridApi) {
        if (!isFilterOpen) gridApi?.current?.api?.openToolPanel(key);
        else gridApi?.current?.api?.closeToolPanel();
        setIsFilterOpen((state) => !state);
        setIsColumnOpen(false);
      }
    }
  };

  const [filteredRowData, setFilteredRowData] = useState<any>([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const onAgGridFilterChanged = (grid: any) => {
    const filtersApplied = grid.api.isAnyFilterPresent();
    setIsFilterApplied(filtersApplied);

    const filteredData = grid.api
      .getModel()
      .rowsToDisplay.map((node: any) => node.data);
    setFilteredRowData(filteredData);
  };
  return (
    <div>
      <HStack className="justify-between w-full">
        <Box
          style={{
            height: (1 + 2.75) * 60,
          }}
          className="w-full max-h-full overflow-x-auto ag-theme-material h-full "
        >
          <AgGridReact
            sideBar={agConfigDashboardSideBar}
            ref={gridRef}
            onGridReady={(params) => {
              // Store the grid API referen
              gridApi.current = params;
            }}
            components={componentsRegistery}
            alwaysMultiSort
            animateRows={true}
            defaultColDef={defaultColDef}
            onFilterChanged={onAgGridFilterChanged}
            rowData={combinedData}
            onCellClicked={handleCellClick}
            columnDefs={columnDefs}
            autoGroupColumnDef={autoGroupColumnDef}
            treeData={true}
            groupDefaultExpanded={-1}
            pagination={false}
            getDataPath={getDataPath}
            suppressRowTransform={true}
            suppressCopyRowsToClipboard={true}
            suppressCopySingleCellRanges={true}
            suppressCellFocus={true}
            suppressMenuHide={false}
            tooltipShowDelay={1000}
            tooltipInteraction={true}
            rowClass={"border-t border-dashed "}
            overlayNoRowsTemplate={
              '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow; margin-top: 50px;">No Rows To Show</span>'
            }
            getRowStyle={getRowStyle}
          ></AgGridReact>
        </Box>
      </HStack>
    </div>
  );
}

export default AGGridProfitabilityRatiosTable;
