import {
  tableEntryStyle,
  tableFirstColStyle,
} from "../../components/TableComponent";
import { formatToReadableNumber } from "../../utils/currencyRoboto";

export function PlanNameRender(data: any) {
  return (
    <div className="pt-2 whitespace-nowrap">
      <p className={tableFirstColStyle}>{data.data.planName}</p>
    </div>
  );
}

export function PlanTypeRender(data: any) {
  return (
    <div className="pt-2 whitespace-nowrap">
      <p className={tableFirstColStyle}>{data.data.planType}</p>
    </div>
  );
}

export function ReservedPoolRender(data: any) {
  return (
    <div className="pt-2 whitespace-nowrap">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.data?.reservedPool,
          format: data.data?.currencyType,
        })}
      </p>
    </div>
  );
}

export function AllocatedPoolRender(data: any) {
  return (
    <div className="pt-2 whitespace-nowrap">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.data?.allocatedPool,
          format: data.data?.currencyType,
        })}
      </p>
    </div>
  );
}

export function UnallocatedPoolRender(data: any) {
  return (
    <div className="pt-2 whitespace-nowrap">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.data?.unallocatedPool,
          format: data.data?.currencyType,
        })}
      </p>
    </div>
  );
}
