import { CaptableTag } from "../../components/AllotmentTag";
import {
  tableEntryStyle,
  tableFirstColStyle,
} from "../../components/TableComponent";
import { formatToReadableNumber } from "../../utils/currencyRoboto";
import { limitString } from "../../utils/string";
import Avatar from "../Utility/Avatar";

export function ShareholderRender(data: any) {
  return (
    <td className="flex flex-col pb-2 text-sm text-left">
      <div className="flex">
        <div className="pr-2 text-left">
          {data.value.name !== "Total" ? (
            <Avatar name={data.value.name || ""} />
          ) : (
            ""
          )}
        </div>
        <div>
          {data.value.name?.length > 24 ? (
            <p
              data-event-name="captableShareholderDrilling"
              className={`${tableFirstColStyle}`}
            >
              {limitString(data.value.name || "", 24)}
            </p>
          ) : (
            <p
              data-event-name="captableShareholderDrilling"
              className={`${tableFirstColStyle}`}
            >
              {limitString(data.value.name || "", 24)}
            </p>
          )}

          {data.value.name !== "Total" ? (
            <CaptableTag tagName={data.value.type ?? "Others"} />
          ) : (
            ""
          )}
        </div>
      </div>
    </td>
  );
}

export function PrenumberOfSharesRender(data: any) {
  return (
    <div className="pt-3 whitespace-nowrap">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.data?.preNumberOfShares || 0,
          format: data.data.currencyType,
        })}
      </p>
    </div>
  );
}

export function PostNumberOfSharesRender(data: any) {
  return (
    <div className="pt-3 whitespace-nowrap">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.data?.postNumberOfShares || 0,
          format: data.data.currencyType,
        })}
      </p>
    </div>
  );
}

export function PreFdbSharesRender(data: any) {
  return (
    <div className="pt-3 whitespace-nowrap">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.data?.preFdbShares || 0,
          format: data.data.currencyType,
        })}
      </p>
    </div>
  );
}

export function PostFdbSharesRender(data: any) {
  return (
    <div className="pt-3 whitespace-nowrap">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.data?.postFdbShares || 0,
          format: data.data.currencyType,
        })}
      </p>
    </div>
  );
}
