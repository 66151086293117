import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  CellClickedEvent,
  ColDef,
  GridApi,
  SideBarDef,
} from "ag-grid-community";
import { Dialog } from "@mui/material";
import { Icon } from "@iconify/react";
import { useNavigate, useParams } from "react-router";
import ReactDOMServer from "react-dom/server";
import { AgGridReact } from "ag-grid-react";
import { Action, CTADropdown } from "../../components/shared/Dropdown";
import { agConfigDashboardSideBar } from "../dashboardPage/AgGridConfig";
import {
  getCurrencySymbol,
  getCurrencyType,
} from "../../utils/currencyFormatter";

import { Box, HStack, useGetCompanyName } from "../../components/utils";

import { useAuthStore } from "../../store";

import { tableCellStyle } from "../../components/TableComponent";

import { useNewShareholderInstrumentStore } from "../../store/newShareholderAndInstrumentStore";
import { SecondaryTransactionDataModel } from "../../types/quickRound";
import {
  DocumentNameRender,
  FilterTypeRender,
  TimelineRender,
  UpdatedDateRender,
  YearRender,
} from "./FinancialSettingsAgComponent";
import { EmptyTable } from "../../components/shared/EmptyTable";
import { useGetFinancialData } from "../../queries/finance";
import { queryClient } from "../../queries/client";
import DeleteModal from "../modeling/DeleteModal";
import { deleteFinancialData } from "../../api/transactionRound";
import BCHeader from "../../shared/BCHeader";
import { downloadS3File } from "../../utils/DownloadFile";
import UploadFile from "./components/UploadFile";
import { FinancialDataModel } from "./FinanceWrapper";
import { handleEventForTracking } from "../../amplitudeAnalytics";
import { PrimaryCTAButton } from "../quickRound/CTAButtonComponents";

function AgGridFinancialSettingsTable() {
  const { cId } = useParams();

  const currencySymbol = getCurrencySymbol();
  const companyName = useGetCompanyName();
  const companyId = useAuthStore().companyId ?? "";
  const userId = useAuthStore().user?.userId ?? 0;
  const gridApi = useRef<any>(null);

  const rowCount = gridApi.current?.api.getDisplayedRowCount();

  const [displayedRowCount, setDisplayedRowCount] = useState<number>(0);

  const [dialog, setDialog] = useState<{
    open: boolean;
    data?: any;
    mode?: "Update" | "Delete" | "Download";
  }>({
    open: false,
  });

  const { isPlaceholderData, data: response } = cId
    ? useGetFinancialData(cId || "")
    : useGetFinancialData(companyId || "");

  const [data, setdata] = useState<FinancialDataModel[]>();

  useEffect(() => {
    response?.data.sort(
      (a, b) =>
        new Date(a.updatedDate).getTime() - new Date(b.updatedDate).getTime()
    );

    if (response) {
      setdata(response.data.sort((d1, d2) => d1.id.localeCompare(d2.id)));
    }
  }, [response]);

  useEffect(() => {
    if (rowCount !== undefined) {
      setDisplayedRowCount(rowCount);
    }
  }, [rowCount]);

  const secondaryTransactionStore = useNewShareholderInstrumentStore();
  const handleDelete = (securityName: SecondaryTransactionDataModel) => {
    const index = secondaryTransactionStore.secondary.findIndex(
      (transaction) => transaction === securityName
    );
    const secondaryTransactions = secondaryTransactionStore.secondary.slice();
    secondaryTransactions.splice(index, 1);
    secondaryTransactionStore.setSecondary(secondaryTransactions);
  };

  function handleModifyAction(action: string, data: any) {
    if (action === "Delete") {
      setDialog({
        open: true,
        mode: "Delete",
        data,
      });
    } else if (action === "Download") {
      downloadS3File(data.s3Url);
    } else if (action === "Update Document") {
      setDialog({
        open: true,
        mode: "Update",
        data,
      });
    }
  }

  function getColumnSetting() {
    if (gridApi.current) return gridApi.current.columnApi?.getColumnState();
    return {};
  }

  function setColumnSetting(model: any) {
    if (gridApi.current)
      gridApi.current.columnApi.applyColumnState({ state: model });
    return {};
  }

  const getFilterSetting = () => {
    if (gridApi.current) return gridApi.current.api?.getFilterModel();
    return {};
  };

  const setFilterSetting = (model: any) => {
    if (gridApi.current) return gridApi.current.api?.setFilterModel(model);
    return {};
  };

  const cin = "";

  const componentsRegistery = useMemo(
    () => ({
      documentName: memo(DocumentNameRender),
      financialType: memo(FilterTypeRender),
      year: memo(YearRender),
      timeline: memo(TimelineRender),
      date: memo(UpdatedDateRender),
    }),
    []
  );

  const navigate = useNavigate();

  const onClickShareholderName = (id: string) => {
    handleEventForTracking({ eventName: `Shareholder Name` });
    navigate(`/shareholders/individual/${id}`);
  };

  const isValidDate = (date: string) =>
    Number.isNaN(date) &&
    new Date(date).toString() !== "Invalid Date" &&
    !Number.isNaN(Date.parse(date));

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      autoHeight: true,
      wrapHeaderText: true,
      suppressColumnVirtualisation: true,
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      filterParams: {
        buttons: ["reset"],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
      flex: 1,
    }),
    []
  );

  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        headerName: "Document Name",
        field: "documentName",
        cellRenderer: "documentName",
        initialWidth: 250,
        cellStyle: tableCellStyle,
        sortable: true,
        filter: "agSetColumnFilter",
        menuTabs: ["filterMenuTab"],
      },
      {
        headerName: "Year",
        field: "year",
        filter: "agSetColumnFilter",
        cellRenderer: "year",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
      },
      {
        headerName: "Timeline",
        field: "timeline",
        filter: "agNumberColumnFilter",
        cellRenderer: "timeline",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
      },
      {
        headerName: "Financial Type",
        field: "financialType",
        filter: "agSetColumnFilter",
        cellRenderer: "financialType",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
      },

      {
        headerName: "Updated Date",
        field: "date",
        filter: "agDateColumnFilter",
        filterParams: {
          comparator: (dateFromFilter: Date, cellValue: any) => {
            if (cellValue == null) {
              return 0;
            }
            const dateParts = cellValue.split("-");
            const day = Number(dateParts[2]);
            const month = Number(dateParts[1]) - 1;
            const year = Number(dateParts[0]);
            const cellDate = new Date(year, month, day);
            if (cellDate < dateFromFilter) {
              return -1;
            } else if (cellDate > dateFromFilter) {
              return 1;
            }
            return 0;
          },
        },
        cellRenderer: "date",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
      },

      {
        headerName: "",
        field: "actions",
        pinned: "right",
        hide: false,
        width: 80,
        maxWidth: 80,
        filter: false,
        menuTabs: [],
        colId: "action-column",
        suppressNavigable: true,
        suppressColumnsToolPanel: true,
        cellStyle: { "padding-top": "10px", "line-height": "20px" },
        resizable: false,
        sortable: false,
        cellRendererParams: ({ value }: { value: any }) => value.props,
        cellRenderer: CTADropdown,
      },
    ],
    []
  );

  function setTableHeight() {
    if (displayedRowCount === 1) {
      return (displayedRowCount + 1) * 60;
    } else if (displayedRowCount === 2) {
      return (displayedRowCount + 0.7) * 60;
    } else if (displayedRowCount === 3) {
      return (displayedRowCount + 0.6) * 60;
    } else if (displayedRowCount === 4) {
      return (displayedRowCount + 0.4) * 60;
    } else if (displayedRowCount === 5) {
      return (displayedRowCount + 0.3) * 60;
    } else if (displayedRowCount === 6) {
      return (displayedRowCount + 0.2) * 60;
    } else if (displayedRowCount === 7) {
      return (displayedRowCount + 0.2) * 60;
    } else if (displayedRowCount === 8) {
      return (displayedRowCount + 0.2) * 60;
    } else if (displayedRowCount === 9) {
      return (displayedRowCount + 0.2) * 60;
    } else {
      return 10 * 60;
    }
  }

  const gridOptions = {
    suppressRowVirtualisation: true,
    paginationAutoPageSize: false,
    suppressScrollOnNewData: true,
  };

  const currencyType = getCurrencyType();

  const rowData = useMemo(
    () =>
      data &&
      data?.map((template: any) => ({
        documentName: template.name,
        financialType: template.financialType,
        year: template.year,
        date: template.updatedDate,
        timeline: template.timelineType !== "Year" ? template.timeline : "-",
        currencySymbol,
        currencyType,

        actions: (
          <CTADropdown
            actions={[
              {
                name: "Download",
                disabled: false,
              },
              {
                name: "Update Document",
                disabled: false,
              },
              {
                name: "Delete",
                disabled: false,
              },
            ]}
            // onAction={(action) => handleDelete(template)}
            onAction={(action) => {
              handleModifyAction(action.name, template);
            }}
          />
        ),
      })),
    [data]
  );

  const handleCellClick = (cellParams: CellClickedEvent<any, any>) => {
    if (cellParams.column.getColId() !== "action-column") {
      const template = cellParams.data;
      if (cellParams.column.getColId() === "shareholderName") {
        onClickShareholderName(template.id);
      }
    }
  };

  const handleDeleteEntry = async () => {
    if (dialog.data.companyIsPrivate && dialog.data.isPrivate)
      await deleteFinancialData(dialog.data.id, dialog.data.isPrivate);
    else if (!dialog.data.companyIsPrivate && !dialog.data.isPrivate) {
      await deleteFinancialData(dialog.data.id, dialog.data.isPrivate);
    }
    setDialog({ open: false });
    queryClient.refetchQueries("getFinancialData");

    setTimeout(() => {
      const count = gridApi.current?.api.getDisplayedRowCount();

      setDisplayedRowCount(count);
    }, 300);
  };

  const [filteredRowData, setFilteredRowData] = useState<any>([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");

  const onAgGridFilterChanged = (grid: any) => {
    const filtersApplied = grid.api.isAnyFilterPresent();
    setIsFilterApplied(filtersApplied);

    const filteredData = grid.api
      .getModel()
      .rowsToDisplay.map((node: any) => node.data);
    setFilteredRowData(filteredData);
  };
  function handleFileUpload() {
    setShowModal(true);
  }

  function handleFileUploadClose() {
    setShowModal(false);
    setSelectedOption("");
  }

  return (
    <div>
      <HStack className="justify-between">
        <BCHeader
          className="items-baseline"
          bcTitle="Financial Overview"
          bcSubTitle="Settings"
        />
        <PrimaryCTAButton
          event-name="Upload Reports Button"
          className="text-xs2 h-8 whitespace-nowrap"
          onClick={() => handleFileUpload()}
        >
          Upload Reports
        </PrimaryCTAButton>
        <Dialog open={showModal} maxWidth="md">
          <UploadFile
            companyId={cId ? cId || "" : companyId || ""}
            show={showModal}
            handleClose={handleFileUploadClose}
          />
        </Dialog>
      </HStack>
      <Dialog open={dialog.open} maxWidth="md">
        {dialog.mode === "Update" && dialog.data ? (
          <UploadFile
            companyId={cId ? cId || "" : companyId || ""}
            handleClose={() => setDialog({ open: false })}
            timelineType={dialog.data.timelineType}
            yearValue={dialog.data.year}
            timelineValue={dialog.data.timeline}
            isEdit={true}
          />
        ) : (
          <></>
        )}
        {dialog.mode === "Delete" && dialog.data ? (
          <DeleteModal
            onPrimaryAction={handleDeleteEntry}
            onSecondaryAction={() => setDialog({ open: false })}
            data={
              "Are you sure you want to delete the entry? Data will be permanently removed. This action cannot be undone."
            }
          />
        ) : (
          <div></div>
        )}
      </Dialog>
      <HStack className="justify-between w-full mt-4">
        <Box
          style={{
            height: setTableHeight() + 120,
          }}
          className="w-full max-h-full overflow-x-auto ag-theme-material h-full rounded-md "
        >
          <AgGridReact
            sideBar={agConfigDashboardSideBar}
            onGridReady={(params) => {
              gridApi.current = params;
            }}
            components={componentsRegistery}
            alwaysShowHorizontalScroll
            alwaysMultiSort
            animateRows={true}
            enableCharts={true}
            enableRangeSelection={true}
            defaultColDef={defaultColDef}
            onFilterChanged={onAgGridFilterChanged}
            rowData={rowData}
            onCellClicked={handleCellClick}
            columnDefs={columnDefs}
            pagination={false}
            suppressRowTransform={true}
            suppressCopyRowsToClipboard={true}
            suppressCopySingleCellRanges={true}
            suppressCellFocus={true}
            suppressMenuHide={false}
            tooltipShowDelay={1000}
            tooltipInteraction={true}
            rowClass={"border-t border-dashed "}
            gridOptions={gridOptions}
            overlayNoRowsTemplate={ReactDOMServer.renderToString(
              <EmptyTable header="No Financial Data To Delete" subHeader="" />
            )}
            getRowStyle={(params) => {
              if (params.rowIndex % 2 === 0) {
                return { background: "#f8f8f8" };
              } else {
                return { background: "#ffffff" };
              }
            }}
          ></AgGridReact>
        </Box>
      </HStack>
    </div>
  );
}

export default AgGridFinancialSettingsTable;
