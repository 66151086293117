import { CaptableTag } from "../../components/AllotmentTag";
import {
  tableEntryStyle,
  tableFirstColStyle,
} from "../../components/TableComponent";
import {
  formatToReadableNumber,
  FormatNumberSpan,
} from "../../utils/currencyRoboto";
import { limitString } from "../../utils/string";
import Avatar from "../Utility/Avatar";

export function ShareholderNameModelRender(data: any) {
  return (
    <td className="flex flex-col pb-2 text-sm text-left">
      <div className="flex">
        <div className="pr-2 text-left">
          {data.value.name !== "Total" ? (
            <Avatar name={data.value.name || "-"} />
          ) : (
            ""
          )}
        </div>
        <div
          className={` ${
            data.value.name === "Total"
              ? "pt-2 cursor-pointer"
              : "ml-2 cursor-pointer"
          } `}
        >
          {data.value.name?.length > 24 ? (
            <p
              data-event-name="captableShareholderDrilling"
              className={`${tableFirstColStyle}`}
            >
              {limitString(data.value.name || "", 24)}
            </p>
          ) : (
            <p
              data-event-name="captableShareholderDrilling"
              className={`${tableFirstColStyle}`}
            >
              {limitString(data.value.name || "", 24)}
            </p>
          )}

          {data.value.name !== "Total" ? (
            <CaptableTag tagName={data.value.type ?? "Others"} />
          ) : (
            ""
          )}
        </div>
      </div>
    </td>
  );
}

export function StringComponent(data: any) {
  return (
    <div className="pt-3 whitespace-nowrap">
      <p className={tableEntryStyle}>{data.value}</p>
    </div>
  );
}

export function SellerComponent(data: any) {
  return (
    <div className="pt-3 whitespace-nowrap">
      <p className={tableEntryStyle}>{"data.value"}</p>
    </div>
  );
}

export function InvestorRightsComponent(data: any) {
  return (
    <div className="pt-3 whitespace-nowrap">
      <p className={tableEntryStyle}>{data.value}</p>
      {data.data.hasBlended ? (
        <p className="text-xxs w-fit text-descriptionColor">Blended</p>
      ) : (
        <div></div>
      )}
    </div>
  );
}

export function NumberComponent(data: any) {
  return (
    <div className="pt-3 whitespace-nowrap">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.value,
          format: data.data.currencyType,
        })}
      </p>
    </div>
  );
}

export function PostHoldingComponent(data: any) {
  return (
    <div className="pt-3 whitespace-nowrap">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.value,
          format: data.data.currencyType,
        })}
      </p>
      {data.data.hasBlended ? (
        <p className="font-medium text-left text-xxs text-descriptionColor">
          {`secondary: ${formatToReadableNumber({
            value: data.data.blendedSecondaryPercentage,
            format: data.data.currencyType,
          })} %`}
        </p>
      ) : (
        <div></div>
      )}
    </div>
  );
}

export function PreHoldingComponent(data: any) {
  return (
    <div className="pt-3 whitespace-nowrap">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.value,
          format: data.data.currencyType,
        })}
      </p>
    </div>
  );
}

export function PostAmountComponent(data: any) {
  return (
    <div className="pt-3 whitespace-nowrap">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.value,
          format: data.data.currencyType,
        })}
      </p>
      {data.data.hasBlended ? (
        <p className="font-medium text-left text-xxs text-descriptionColor">
          {`secondary: ${formatToReadableNumber({
            value: data.data.blendedSecondaryAmount,
            format: data.data.currencyType,
          })}`}
        </p>
      ) : (
        <div></div>
      )}
    </div>
  );
}

export function FDBShareRender(data: any) {
  return (
    <div className="pt-2 whitespace-nowrap">
      <p className={tableEntryStyle}>
        <FormatNumberSpan value={data.data.fdbShares} />
      </p>
    </div>
  );
}

export function SellerNameRender(data: any) {
  return (
    <div className="pt-2 whitespace-nowrap">
      <p className={tableEntryStyle}>
        {data.rowIndex > 0 ? `${data.data.sellerName}` : "-"}
      </p>
    </div>
  );
}

export function FDBSharePercentRender(data: any) {
  return (
    <div className="pt-2 whitespace-nowrap">
      <p className={tableEntryStyle}>
        <FormatNumberSpan value={data.data.fdbSharePercent} />
      </p>
    </div>
  );
}

export function AmountRender(data: any) {
  return (
    <div className="pt-2 whitespace-nowrap">
      <p className={tableEntryStyle}>
        <FormatNumberSpan value={data.data.amount} />
      </p>
    </div>
  );
}
