import { useQuery } from "react-query";
import {
  randomIndividualTransactions,
  shareholderInstrumentDummyData,
} from "./randomPlaceholderData";
import {
  getSharedCompanyDetail,
  getSharedEvents,
  getSharedInstrumentAndShareHolder,
  getSharedInstrumentAndShareHolders,
  getSharedNonPricedRoundModel,
  getSharedPricedRoundModel,
  getSharedQuickRoundPreview,
  getSharedRoundsTransactions,
} from "../api/sharedResources";

export function useSharedGetIndividualRoundDetail(
  eventId: string,
  cId: string
) {
  return useQuery({
    queryKey: ["getSharedRoundsTransactions", eventId, cId],
    queryFn: getSharedRoundsTransactions,
    placeholderData: randomIndividualTransactions(),
  });
}

export function useSharedGetInstrumentAndShareHolder(
  id: string,
  resourceId: string,
  cId: string
) {
  return useQuery({
    queryKey: ["getSharedInstrumentAndShareHolder", cId, resourceId, id],
    queryFn: getSharedInstrumentAndShareHolder,
    placeholderData: shareholderInstrumentDummyData(),
  });
}

export function useGetSharedInstrumentAndShareHolders(
  id: string,
  resourceId: string,
  cId: string
) {
  return useQuery({
    queryKey: ["getSharedInstrumentAndShareHolders", cId, resourceId, id],
    queryFn: getSharedInstrumentAndShareHolders,
    placeholderData: shareholderInstrumentDummyData(),
  });
}

export function useSharedGetQuickRoundPreview(eventId: string) {
  return useQuery({
    queryKey: ["proformaEventSummary", eventId],
    queryFn: getSharedQuickRoundPreview,
    select: (data) => data.data,
    enabled: false,
  });
}

export function useSharedGetSharedEvents() {
  return useQuery({
    queryKey: "getSharedEvents",
    queryFn: getSharedEvents,
  });
}

export function useGetSharedCompanyDetail() {
  return useQuery({
    queryKey: "getSharedCompanyDetail",
    queryFn: getSharedCompanyDetail,
    select: (data) => data.data,
  });
}

export function useSharedNonPricedRoundModel(modelId: string) {
  return useQuery({
    queryKey: ["getSharedNonPricedRoundModel", modelId],
    queryFn: getSharedNonPricedRoundModel,
    enabled: true,
  });
}

export function useSharedPricedRoundModel(modelId: string) {
  return useQuery({
    queryKey: ["getSharedNonPricedRoundModel", modelId],
    queryFn: getSharedPricedRoundModel,
    enabled: true,
  });
}
