import { QueryFunctionContext } from "react-query";
import api from "../../api/capTableApi";
import { AgGridStateModel } from "./AgGridCaptable";

enum GLOBALFILTERTYPE {
  CAPTABLE = "CAPTABLE",
  TRANSACTION = "TRANSACTION",
  SHAREHOLDER = "SHAREHOLDER",
  SECURITY = "SECURITY",
  TERMSHEET = "TERMSHEET",
}

export async function getOnFilterState(
  context: QueryFunctionContext
): Promise<any> {
  const userId = context.queryKey[1];
  const companyId = context.queryKey[2];
  return {};
  // filter change
  // api
  //   .get(`v1/utils/cace?key=filter&userId=${userId}&companyId=${companyId}`)
  //   .then((res) => res.data.data);
}

export async function postOnFilterState({
  userId,
  companyId,
  filterData,
}: {
  userId: string;
  companyId: string;
  filterData: AgGridStateModel;
}): Promise<any> {
  // filter change
  // { message: string; data: AgGridStateModel }
  return {};
  // filter change
  // return api
  //   .put(
  //     `v1/utils/cace?key=filter&userId=${userId}&companyId=${companyId}`,
  //     filterData
  //   )
  //   .then((res) => res.data);
}
