import { useEffect, useState } from "react";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { resetPassword } from "../../queries/captableLogin";
import { ResetPasswordDetailModel } from "../../types/CaptableLoginModel";
import { Input, Label } from "../../components/shared/InputField";
import {
  VStack,
  Error,
  HStack,
  decodeCaptableAuthToken,
} from "../../components/utils";
import { useAuthStore } from "../../store";
import { loginPageImageUrl } from "../../constants/LoginPageImage";
import useIsMobile from "../../utils/detectDevice";
import {
  ButtonSize,
  PrimaryCTAButton,
} from "../quickRound/CTAButtonComponents";

const initialValues: ResetPasswordDetailModel = {
  emailId: "",
  companyId: "",
  newPassword: "",
  confirmPassword: "",
};

const resetPasswordSchema = Yup.object().shape({
  newPassword: Yup.string()
    .min(6, "Password length should be atleast 6")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,})/,
      "Must Contain 6 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    )
    .max(50, "Maximum 50 symbols")
    .required("Password is required"),
  confirmPassword: Yup.string()
    .required("Password confirmation is required")
    .when("newPassword", {
      is: (val: string) => val && val.length > 0,
      then: Yup.string().oneOf(
        [Yup.ref("newPassword")],
        "Password and Confirm Password didn't match"
      ),
    }),
});

export default function ResetPasswordPage() {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  const token = params.get("token");
  const jwt = token || "";
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmedPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  let imageCount = 0;

  const companyId = useAuthStore.getState().companyId || "";
  const navigate = useNavigate();
  const imageUrl = loginPageImageUrl;
  const imageLength = imageUrl.length;

  useEffect(() => {
    //timer to change the login image on right side
    const intervalId = setInterval(() => {
      imageCount++;
      setActiveIndex(imageCount % imageLength);
    }, 5000);
    return () => clearInterval(intervalId);
  }, [jwt]);

  const { isMobile } = useIsMobile(1025);

  return (
    <>
      {!isMobile ? (
        <div className="flex h-screen bg-white w-full">
          <div className="flex flex-col justify-between flex-1 px-4 pb-12 mx-auto sm:px-6 lg:flex-none  md:w-5/12 w-full">
            <div className="pt-[32px] ">
              <img
                className="h-12 mx-auto w-28"
                src={
                  "https://equion-dev.s3.us-west-2.amazonaws.com/logonew.svg"
                }
                alt="hissa_logo"
              />
            </div>
            <div className="w-full max-w-sm mx-auto lg:w-96">
              <div>
                <h2 className="mt-6 text-2xl text-center font-semibold tracking-tight text-[#181C32]">
                  Setup new password
                </h2>
                <HStack>
                  <p className="mt-2 px-12 text-sm text-center font-medium text-[#7E8299]">
                    Have you already reset the password ?
                    <span
                      className="text-orange-501 cursor-pointer"
                      event-name="Sign in from reset password"
                      onClick={() => {
                        navigate("/login");
                      }}
                    >
                      Sign in
                    </span>
                  </p>
                </HStack>
              </div>

              <div className="mt-8">
                <div className="mt-6">
                  <Formik
                    enableReinitialize={true}
                    validationSchema={resetPasswordSchema}
                    initialValues={initialValues}
                    onSubmit={(values) => {
                      if (jwt) {
                        const { emailId, companyId } =
                          decodeCaptableAuthToken(jwt);

                        setLoading(true);

                        resetPassword({
                          jwt,
                          emailId,
                          passwordNew: values.newPassword,
                        })
                          .then(({ data: { result } }) => {
                            toast("Password Reset Successfully", {
                              type: "success",
                              autoClose: 2000,
                            });
                            setLoading(false);
                            navigate("/login");
                          })
                          .catch((error: any) => {
                            setLoading(false);
                            toast(error.response.data.errorMessage, {
                              type: "error",
                              autoClose: 2000,
                            });
                          });
                      } else
                        toast("Invalid link please try reseting again", {
                          type: "error",
                          autoClose: 2000,
                        });
                    }}
                  >
                    {(formik) => (
                      <Form>
                        <VStack className="gap-4">
                          <div>
                            <Label htmlFor="password">Password</Label>

                            <Input
                              id="password"
                              type={`${showPassword ? "text" : "password"}`}
                              autoComplete="current-password"
                              {...formik.getFieldProps(`newPassword`)}
                            />

                            {formik.touched?.newPassword &&
                              formik.errors?.newPassword && (
                                <Error text={formik.errors?.newPassword} />
                              )}
                          </div>
                          <div>
                            <Label htmlFor="password">Confirm Password</Label>

                            <Input
                              id="password"
                              type={`${showPassword ? "text" : "password"}`}
                              autoComplete="current-password"
                              {...formik.getFieldProps(`confirmPassword`)}
                            />

                            {formik.touched?.confirmPassword &&
                              formik.errors?.confirmPassword && (
                                <Error text={formik.errors?.confirmPassword} />
                              )}
                          </div>
                          <div className="relative flex items-start">
                            <div className="flex h-6 items-center">
                              <input
                                id="comments"
                                aria-describedby="comments-description"
                                name="comments"
                                type="checkbox"
                                onClick={() => {
                                  setShowPassword(!showPassword);
                                }}
                              />
                            </div>
                            <div className="ml-3 text-sm leading-6">
                              <label
                                htmlFor="comments"
                                className="font-medium text-gray-900"
                              >
                                Show Password
                              </label>
                            </div>
                          </div>
                          <div className="mx-auto mt-16">
                            <PrimaryCTAButton
                              type="submit"
                              event-name="Reset Password"
                              loading={loading}
                              buttonSize={ButtonSize.MEDIUM}
                            >
                              Reset Password
                            </PrimaryCTAButton>
                          </div>
                        </VStack>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
            <div></div>
          </div>
          <VStack className="md:w-7/12 md:visible invisible bg-gradient-to-tl from-[#030093] to-[#2E4D9B]/[.54] justify-evenly">
            <VStack className="gap-8">
              <img
                className="inset-0 md:visible invisible object-cover mx-auto w-fit px-32"
                src={imageUrl[activeIndex]}
                alt=""
              />

              {activeIndex === 0 ? (
                <div className="px-10 pb-10 text-2xl font-semibold text-center text-white">
                  Elevate your equity management with{" "}
                  <span className="text-orange-501">Hissa™</span> !
                </div>
              ) : activeIndex === 1 ? (
                <div className="px-10 pb-10 text-2xl font-semibold text-center text-white">
                  <span className="text-orange-501">Hissa™</span> takes the
                  chaos out of the Cap Table!
                </div>
              ) : (
                <div className="px-10 pb-10 text-2xl font-semibold text-center text-white">
                  Value creation through Stock Options just got easier with{" "}
                  <span className="text-orange-501">Hissa™</span> !
                </div>
              )}
            </VStack>
          </VStack>
        </div>
      ) : (
        <div className="flex h-screen bg-white w-full">
          <div className="flex flex-col justify-between flex-1 px-4 pb-12 mx-auto sm:px-6 lg:flex-none w-full">
            <div className="pt-[32px] ">
              <img
                className="h-12 mx-auto w-28"
                src={
                  "https://equion-dev.s3.us-west-2.amazonaws.com/logonew.svg"
                }
                alt="hissa_logo"
              />
            </div>
            <div className="w-full max-w-sm lg:w-96 border border-gray-200 px-4 py-8 rounded-md shadow-box mx-auto">
              <div>
                <h2 className="text-center text-[28px] font-semibold tracking-tight text-[#181C32]">
                  Setup new password
                </h2>
                <HStack>
                  <p className="mt-2 px-12 text-sm text-center font-medium text-[#7E8299]">
                    Have you already reset the password ?
                    <span
                      className="text-orange-501 cursor-pointer"
                      onClick={() => {
                        navigate("/login");
                      }}
                    >
                      Sign in
                    </span>
                  </p>
                </HStack>
              </div>

              <div className="mt-8">
                <div className="mt-6">
                  <Formik
                    enableReinitialize={true}
                    validationSchema={resetPasswordSchema}
                    initialValues={initialValues}
                    onSubmit={(values) => {
                      if (jwt) {
                        const { emailId, companyId } =
                          decodeCaptableAuthToken(jwt);

                        setLoading(true);

                        resetPassword({
                          jwt,
                          emailId,
                          passwordNew: values.newPassword,
                        })
                          .then(({ data: { result } }) => {
                            toast("Password Reset Successfully", {
                              type: "success",
                              autoClose: 2000,
                            });
                            setLoading(false);
                            navigate("/login");
                          })
                          .catch((error: any) => {
                            setLoading(false);
                            toast(error.response.data.errorMessage, {
                              type: "error",
                              autoClose: 2000,
                            });
                          });
                      } else
                        toast("Invalid link please try reseting again", {
                          type: "error",
                          autoClose: 2000,
                        });
                    }}
                  >
                    {(formik) => (
                      <Form>
                        <VStack className="gap-4">
                          <div>
                            <Label htmlFor="password">Password</Label>

                            <Input
                              id="password"
                              type={`${showPassword ? "text" : "password"}`}
                              autoComplete="current-password"
                              {...formik.getFieldProps(`newPassword`)}
                            />

                            {formik.touched?.newPassword &&
                              formik.errors?.newPassword && (
                                <Error text={formik.errors?.newPassword} />
                              )}
                          </div>
                          <div>
                            <Label
                              htmlFor="password"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Confirm Password
                            </Label>

                            <Input
                              id="password"
                              type={`${showPassword ? "text" : "password"}`}
                              autoComplete="current-password"
                              {...formik.getFieldProps(`confirmPassword`)}
                            />

                            {formik.touched?.confirmPassword &&
                              formik.errors?.confirmPassword && (
                                <Error text={formik.errors?.confirmPassword} />
                              )}
                          </div>
                          <div className="relative flex items-start">
                            <div className="flex h-6 items-center">
                              <input
                                id="comments"
                                aria-describedby="comments-description"
                                name="comments"
                                type="checkbox"
                                onClick={() => {
                                  setShowPassword(!showPassword);
                                }}
                              />
                            </div>
                            <div className="ml-3 text-sm leading-6">
                              <label
                                htmlFor="comments"
                                className="font-medium text-gray-900"
                              >
                                Show Password
                              </label>
                            </div>
                          </div>
                          <div className="mx-auto mt-16">
                            <PrimaryCTAButton
                              type="submit"
                              event-name="Reset Password"
                              loading={loading}
                              buttonSize={ButtonSize.MEDIUM}
                            >
                              Reset Password
                            </PrimaryCTAButton>
                          </div>
                        </VStack>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      )}
    </>
  );
}
