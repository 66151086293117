export const downloadFile = (
  fileObj: any,
  fileName: string,
  contentType: any
) => {
  const downloadLink: HTMLAnchorElement = document.createElementNS(
    "http://www.w3.org/1999/xhtml",
    "a"
  ) as HTMLAnchorElement;
  downloadLink.download = fileName;
  const blob = new Blob([fileObj], { type: contentType });
  let dataUrl: string = window.URL.createObjectURL(blob);
  downloadLink.href = dataUrl;
  const event: MouseEvent = document.createEvent("MouseEvent");
  event.initEvent("click", true, true);
  downloadLink.dispatchEvent(event);
  setTimeout((): void => {
    window.URL.revokeObjectURL(dataUrl);
    dataUrl = "";
  });
};
