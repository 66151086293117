import { useState } from "react";
import { Dialog } from "@mui/material";
import _ from "lodash";

import { HStack, VStack } from "./utils";
import { limitString } from "../utils/string";
import Tooltip from "./shared/Tooltip";
import { FormatNumberSpan } from "../utils/currencyRoboto";
import { getShareholderValue } from "../constants/ShareholderConstants";
import { NonPricedRoundCardModel } from "../types/Modeling";

import DeleteModal from "../pages/modeling/DeleteModal";

export default function NonPricedModelCardDesign({
  data,
  currencySymbol,
  currencyType,
  onClick,
}: Readonly<{
  data: NonPricedRoundCardModel;
  onClick: () => void;
  currencySymbol: string;
  currencyType: string;
}>) {
  const [dialog, setDialog] = useState<{
    open: boolean;
    mode?: string;
    data?: string;
  }>({
    open: false,
  });

  const onDeleteFunction = (id: string) => {
    setDialog({
      open: true,
      mode: "Delete",
      data: id,
    });
  };

  function getConvertibleHoldingPercentage() {
    const percentage = _.sumBy(
      data.draftData.investorHolding.convertibleInvestorHolding,
      "holdingPercentage"
    );

    return percentage < 0 || percentage > 100 ? "-" : percentage;
  }

  function getTotalConvertibleAmount() {
    const totalInvestMent = _.sumBy(
      data?.draftData?.convertibles,
      "investment"
    );
    return totalInvestMent;
  }

  return (
    <div className="sm:w-[46%] md:w-[31%] w-full pb-10">
      <Dialog open={dialog.open} maxWidth="lg">
        {dialog.mode === "Delete" && (
          <DeleteModal
            onPrimaryAction={() => {
              setDialog({ open: false });
            }}
            onSecondaryAction={() => setDialog({ open: false })}
            data={
              "Are you sure you want to delete the model? Data will be permanently removed. This action cannot be undone."
            }
          />
        )}
      </Dialog>

      <div
        className={`h-56  bg-gradient-to-b  border border-borderColor shadow-box rounded-lg hover:cursor-pointer flex flex-col bg-white`}
      >
        <VStack className="bg-gradient-to-b rounded-lg shadow-sm justify-between h-auto  bg-[#F2F2F2]/20">
          <HStack className="justify-between ">
            <div onClick={onClick} className="w-full">
              <Tooltip text={data?.draftData?.modelName}>
                <p className="px-4 pt-4 text-base font-semibold">
                  {limitString(data?.draftData?.modelName, 17)}
                </p>
              </Tooltip>
            </div>
            <div className="px-4">
              {/* <CTADropdown
                actions={[
                  {
                    name: "Delete",
                    disabled: false,
                  },
                ]}
                onAction={(action) => {
                  if (action.name === "Delete") {
                    onDeleteFunction(data.modelId);
                  }
                }}
              /> */}
            </div>
            <div className="w-1/2 px-2 py-1 mx-4 mt-3 text-xs text-center text-red-500 border border-red-500 rounded-sm">
              Non Priced
            </div>
          </HStack>
          <HStack className="justify-between px-4 py-4" onClick={onClick}>
            {[
              {
                name: "Convertible",
                value: getConvertibleHoldingPercentage() ?? "-",
                isMoney: false,
                isPercent: true,
              },
              {
                name: "Border",
                value: data.draftData.roundSize,
                isMoney: true,
                isPercent: false,
              },
              {
                name: "New Investor",
                value:
                  data.draftData.investorHolding.newInvestorHolding < 0 ||
                  data.draftData.investorHolding.newInvestorHolding > 100
                    ? "-"
                    : data.draftData.investorHolding.newInvestorHolding ?? "-",
                isMoney: false,
                isPercent: true,
              },
              {
                name: "Border",
                value: data.draftData.roundSize,
                isMoney: false,
                isPercent: false,
              },
              {
                name: "Existing",
                value:
                  data.draftData.investorHolding.preRoundInvestorHolding < 0 ||
                  data.draftData.investorHolding.preRoundInvestorHolding > 100
                    ? "-"
                    : data.draftData.investorHolding.preRoundInvestorHolding ??
                      "-",
                isMoney: false,
                isPercent: true,
              },
            ].map((element) =>
              element.name === "Border" ? (
                <div
                  key={element.name}
                  className="bg-gray-500  w-[0.2px] h-10 "
                ></div>
              ) : (
                <VStack key={element.name} className="justify-start ">
                  <p className="text-xs font-medium text-gray-500">
                    {element.name}

                    {element.isMoney && (
                      <span
                        className="pl-1"
                        style={{ fontFamily: "'Roboto', sans-serif" }}
                      >
                        {`(${currencySymbol})`}
                      </span>
                    )}

                    {element.isPercent && (
                      <span
                        className="pl-1"
                        style={{ fontFamily: "'Roboto', sans-serif" }}
                      >
                        {"%"}
                      </span>
                    )}
                  </p>
                  <p className="justify-end gap-2 text-base font-semibold text-left">
                    {element.value !== "-" ? (
                      <FormatNumberSpan
                        value={Number(element.value) ?? 0}
                        format={currencyType}
                      />
                    ) : (
                      <>-</>
                    )}
                  </p>
                </VStack>
              )
            )}
          </HStack>
        </VStack>
        <VStack className="gap-2 py-3" onClick={onClick}>
          {[
            {
              key: "Convertible Amount",
              isAmount: true,
              isPercent: false,
              value: getTotalConvertibleAmount() ?? 0,
            },
            {
              key: "Pre Money Valuation",
              isAmount: true,
              isPercent: false,
              value: data?.draftData?.preMoneyValuation ?? 0,
            },
            {
              key: "Round Size",
              isAmount: true,
              isPercent: false,
              value: data?.draftData?.roundSize ?? 0,
            },
          ].map((element) => (
            <HStack key={element.key} className="justify-between px-4 text-xs">
              <p className="text-gray-500">
                {element.key}
                {element.isAmount && (
                  <span
                    className="pl-1"
                    style={{ fontFamily: "'Roboto', sans-serif" }}
                  >
                    ({currencySymbol})
                  </span>
                )}
                {element.isPercent && (
                  <span
                    className="pl-1"
                    style={{ fontFamily: "'Roboto', sans-serif" }}
                  >
                    ({"%"})
                  </span>
                )}
              </p>

              <p className="justify-end gap-2 font-semibold text-right">
                <FormatNumberSpan
                  value={element.value ?? 0}
                  format={currencyType}
                />
              </p>
            </HStack>
          ))}
        </VStack>
      </div>
    </div>
  );
}
