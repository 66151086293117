import _ from "lodash";
import {
  tableEntryStyle,
  tableFirstColStyle,
} from "../../components/TableComponent";
import {
  formatToReadableNumber,
  getRoundCurrencySymbol,
} from "../../utils/currencyRoboto";
import { getSecurityColor } from "../../utils/historicUtilities";
import { limitString } from "../../utils/string";
import { VStack } from "../../components/utils";
import { CaptableTag } from "../../components/AllotmentTag";
import {
  formatCurrency,
  getCurrency,
  getCurrencyType,
  getKeyByValue,
} from "../../utils/currencyFormatter";
import { currencyTypes } from "../../constants/CompanyCurrencyType";
import Avatar from "../Utility/Avatar";
import { checkVoid } from "../../utils/transactionUtils";

export function InvestorNameRender(data: any) {
  return (
    <td className="flex flex-col pb-2 text-sm text-left">
      <div className="flex">
        <div className="pr-2 text-left">
          {data.value.name !== "Total" ? (
            <Avatar name={data.value.name || ""} />
          ) : (
            ""
          )}
        </div>
        <div
          className={` ${
            data.value.name === "Total"
              ? "pt-2 cursor-pointer"
              : "ml-2 cursor-pointer"
          } `}
        >
          {data.value.name?.length > 24 ? (
            <p
              data-event-name="captableShareholderDrilling"
              className={`${tableFirstColStyle}`}
            >
              {limitString(data.value.name || "", 24)}
            </p>
          ) : (
            <p
              data-event-name="captableShareholderDrilling"
              className={`${tableFirstColStyle}`}
            >
              {limitString(data.value.name || "", 24)}
            </p>
          )}

          {data.value.name !== "Total" ? (
            <CaptableTag tagName={data.value.type ?? "Others"} />
          ) : (
            ""
          )}
        </div>
      </div>
    </td>
  );
}

export function AmountRender(data: any) {
  return (
    <div className="pt-2 whitespace-nowrap">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.data?.amountInvested,
          format: data.data.currencyType,
        })}
      </p>
    </div>
  );
}

export function FDBSharesRender(data: any) {
  return (
    <div className="pt-2 whitespace-nowrap">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.data?.fdbShares,
          format: data.data.currencyType,
        })}
      </p>
    </div>
  );
}

export function SecurityNameRender(data: any) {
  return (
    <td className="pt-2 whitespace-nowrap">
      {data.data.securityClass?.length > 24 ? (
        <p className={`${tableEntryStyle}`}>
          {limitString(data.data?.securityName || "", 24)}
        </p>
      ) : (
        <p className={`${tableEntryStyle}`}>
          {limitString(data.data?.securityName || "", 24)}
        </p>
      )}

      <p className="text-left font-semibold text-xxs1 text-[#464E5F]/[0.5]">
        {data.data.icin}
      </p>
    </td>
  );
}

export function SecurityTypeRender(data: any) {
  return (
    <div className="pt-2 whitespace-nowrap">
      {!checkVoid(data.data.securityType) ? (
        data.data.securityType !== "Total" ? (
          <p
            className={`${getSecurityColor(
              data.data.securityType
            )} text-xs text-center w-24 align-middle font-semibold leading-5 rounded-sm mb-1`}
          >
            {data.data.securityType}
          </p>
        ) : (
          <p className={tableEntryStyle}>{data.data.securityType}</p>
        )
      ) : (
        <p className={`${tableEntryStyle}`}>-</p>
      )}
    </div>
  );
}

export function NumberOfSharesRender(data: any) {
  return (
    <div className="pt-2 whitespace-nowrap">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.data?.numberOfShares,
          format: data.data.currencyType,
        })}
      </p>
    </div>
  );
}
