import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import _ from "lodash";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import SearchAutoCompleteOnInput from "./SearchAutoCompleteOnInput";
import { Box, HStack, VStack } from "../../components/utils";
import DNDCard from "./DNDCard";
import { CheckBoxWithLabel } from "../../components/shared/InputField";
import { useRightsStore } from "../../store/rightsStore";
import {
  useDiscardRightsTable,
  useGetCaptableShareholderMapingTable,
  useUpdateCaptableShareholderMapingTable,
} from "../../queries/shareHolderRights";
import { useAuthStore } from "../../store";
import BCHeader from "../../shared/BCHeader";
import {
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../quickRound/CTAButtonComponents";
import { downloadS3File } from "../../utils/DownloadFile";
import Loader from "../../shared/Loader";
import { useGetCompanyDetails } from "../../queries/termsheet";

export interface ShareholderTypeModel {
  type: string;
  name: string;
  id: string;
  investorId: string;
  percentage: number;
}

export interface GroupTypeModel {
  groupName: string;
  groupId: string;
  shareholders: ShareholderTypeModel[];
}

interface RightsDetail {
  id: string;
  excelUrl: string;
  aoaUrl: string;
  date: string;
  updatedAt: string;
}

export interface RightsInputGroupName {
  rightsId: string;
  rightsDetail: RightsDetail;
  groupList: GroupTypeModel[];
}

const onDragEnd = (result: any, columns: GroupTypeModel[], setColumns: any) => {
  if (!result.destination) return;
  const { source, destination } = result;
  if (source.droppableId !== destination.droppableId) {
    const sourceColumn = columns.find(
      (element) => element.groupId === source.droppableId
    );
    const destColumn = columns.find(
      (element) => element.groupId === destination.droppableId
    );
    const sourceItems: ShareholderTypeModel[] =
      sourceColumn?.shareholders ?? [];
    const destItems: ShareholderTypeModel[] = destColumn?.shareholders ?? [];
    const [removed] = sourceItems.splice(source.index, 1);
    destItems.splice(destination.index, 0, removed);
    const newColumn = columns.map((column) => {
      if (column.groupId === source.droppableId)
        return { ...column, shareholders: sourceItems };
      else if (column.groupId === destination.droppableId)
        return { ...column, shareholders: destItems };
      else return column;
    });
    setColumns(newColumn);
  } else {
    const column = columns.find(
      (element) => element.groupId === source.droppableId
    );
    const copiedItems = column?.shareholders ?? [];
    const [removed] = copiedItems.splice(source.index, 1);
    copiedItems.splice(destination.index, 0, removed);
    const newColumn = columns.map((column) => {
      if (column.groupId === source.droppableId)
        return { ...column, shareholders: copiedItems };
      else return column;
    });
    setColumns(newColumn);
  }
};

function DragAndDrop() {
  const rightsId = useRightsStore.getState().rightsId || "";

  const navigate = useNavigate();
  const companyId = useAuthStore.getState().companyId || "";
  const [loading, setLoading] = useState(false);
  const { data: nodeCompanyDetail } = useGetCompanyDetails();
  const cin = nodeCompanyDetail?.companyDetails.cinNumber || "";
  const { data: groupData } = useGetCaptableShareholderMapingTable({
    rightsId,
    companyId,
    cin,
  });
  const { refetch: deleteRights } = useDiscardRightsTable({
    rightsId,
    companyId,
    cin,
  });
  const { mutate: updateShareholder } =
    useUpdateCaptableShareholderMapingTable();
  const [showSearch, setShowSearch] = useState("random");
  const [showDetail, setShowDetail] = useState(true);
  const [showFull, setShowFull] = useState(false);
  const [columns, setColumns] = useState<GroupTypeModel[]>([]);

  useEffect(() => {
    if (groupData?.groupList) setColumns(groupData?.groupList);
  }, [groupData]);

  function getSelectedValue(
    shareholder: ShareholderTypeModel,
    groupId: string
  ) {
    changeShowSearch();
    addNewInvestors(shareholder, groupId);
  }
  function changeShowSearch() {
    const randomKey = `${_.random(100, 10000)}`;
    setShowSearch(randomKey);
  }

  const addNewInvestors = (
    shareholder: ShareholderTypeModel,
    groupId: string
  ) => {
    if (!groupId) return;
    let sourceId = "";
    let sourceIndex = 0;
    columns.forEach((element) => {
      element.shareholders.forEach((shareholders, index) => {
        if (shareholders.id === shareholder.id) {
          sourceId = element.groupId;
          sourceIndex = index;
        }
      });
    });
    if (sourceId !== groupId) {
      const sourceColumn = columns.find(
        (element) => element.groupId === sourceId
      );
      const destColumn = columns.find((element) => element.groupId === groupId);
      const sourceItems: ShareholderTypeModel[] =
        sourceColumn?.shareholders ?? [];
      const destItems: ShareholderTypeModel[] = destColumn?.shareholders ?? [];
      const [removed] = sourceItems.splice(sourceIndex, 1);
      destItems.splice(0, 0, removed);
      const newColumn = columns.map((column) => {
        if (column.groupId === sourceId)
          return { ...column, shareholders: sourceItems };
        else if (column.groupId === groupId)
          return { ...column, shareholders: destItems };
        else return column;
      });
      setColumns(newColumn);
    }
  };
  const handleChange = () => {
    setLoading(true);
    updateShareholder(
      { rightsId, companyId, cinNumber: cin, columns },
      {
        onSuccess: (data) => {
          setLoading(false);
          toast("Shareholder Mapping Updated Successfully!", {
            type: "success",
            autoClose: 2000,
          });
          navigate("/rights/overview");
        },
        onError: (data) => {
          setLoading(false);
          toast("Something went wrong!", {
            type: "error",
            autoClose: 2000,
          });
        },
      }
    );
  };

  return (
    <div className="min-h-full">
      <BCHeader
        className="items-baseline mb-2"
        bcTitle="Rights And Transfer Restrictions"
        bcSubTitle="Rights Setting"
      />
      {columns.length !== 0 ? (
        <Box className="my-auto bg-white rounded-lg h-full w-full shadow-box">
          <HStack className="justify-between p-6">
            <h1 className="text-center text-base font-medium">
              Shareholder Mapping
            </h1>
            <HStack>
              <SecondaryCTAButton
                navigation-name="Back to Rights"
                className="mr-2"
                onClick={() => {
                  navigate("/rights/overview");
                }}
              >
                Back To Rights
              </SecondaryCTAButton>
              <PrimaryCTAButton
                event-name="Edit Articles"
                onClick={() => {
                  navigate("/rights/article");
                }}
              >
                Edit Articles
              </PrimaryCTAButton>
            </HStack>
          </HStack>
          <div className="flex justify-start w-full h-[100%] overflow-x-auto px-2">
            <DragDropContext
              onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
            >
              {columns.map((column, index) => (
                <div
                  key={column.groupId}
                  className={`flex flex-col ${
                    index === 0 ? "bg-[#F4FBFF]" : "bg-stone-100"
                  } p-2 mx-1 rounded-lg`}
                >
                  <VStack>
                    <HStack className="justify-between pb-2">
                      <VStack className="pl-2 w-full">
                        <h2 className="text-left text-stone-800 font-medium">
                          {column.groupName}
                        </h2>
                        <HStack className="justify-between w-full">
                          <h2 className="text-left text-xs1">
                            {`${column.shareholders.length} shareholders :`}
                          </h2>
                          {showDetail && (
                            <h2 className="text-right text-xs1">{`${(
                              _.sumBy(
                                column.shareholders.map(
                                  (event) => event.percentage
                                )
                              ) * 100
                            ).toFixed(2)} %`}</h2>
                          )}
                        </HStack>
                      </VStack>
                      <VStack>
                        <Icon
                          onClick={() => {
                            if (showSearch === column.groupId)
                              changeShowSearch();
                            else {
                              setShowSearch(column.groupId);
                            }
                          }}
                          icon="subway:add"
                          className={`hover:cursor-pointer ${
                            showSearch === column.groupId
                              ? "text-orange-501"
                              : "text-gray-400"
                          } scale-125 rounded items-center`}
                          height={14}
                          rotate={90}
                          width={16}
                        />
                      </VStack>
                    </HStack>
                    {showSearch === column.groupId && (
                      <SearchAutoCompleteOnInput
                        data={columns[0].shareholders}
                        groupId={column.groupId || "notassigned"}
                        getSelectedValue={getSelectedValue}
                        changeShowSearch={changeShowSearch}
                        field={"name"}
                      />
                    )}
                  </VStack>

                  <div className="items-center">
                    <Droppable
                      droppableId={column.groupId}
                      key={column.groupId}
                      renderClone={(provided, snapshot, rubric) => (
                        <div
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                        >
                          <DNDCard
                            shareholderDetail={
                              column.shareholders[rubric.source.index]
                            }
                            isDragging={snapshot.isDragging}
                            showDetail={showDetail}
                          />
                        </div>
                      )}
                    >
                      {(provided, snapshot) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          className={`justify-between rounded-md ${
                            showFull ? "" : "max-h-[500px]"
                          } overflow-y-auto min-h-[500px] min-w-[200px] ${
                            snapshot.isDraggingOver
                              ? "bg-gray-200"
                              : "bg-inherit"
                          }`}
                        >
                          {column.shareholders.map((item, index) => (
                            <Draggable
                              key={item.id || "random"}
                              draggableId={item.id || "random"}
                              index={index}
                            >
                              {(provided, snapshot) => {
                                const faf = "";
                                return (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    className="draggable"
                                    style={{
                                      display: "block",
                                      userSelect: "none",
                                      margin: "0 0 8px 0",
                                      minHeight: "35px",
                                      ...provided.draggableProps.style,
                                    }}
                                  >
                                    <DNDCard
                                      shareholderDetail={item}
                                      isDragging={snapshot.isDragging}
                                      showDetail={showDetail}
                                    />
                                  </div>
                                );
                              }}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </div>
                </div>
              ))}
            </DragDropContext>
          </div>
          <HStack className="justify-end text-center pr-6 gap-4">
            <CheckBoxWithLabel
              label={"Show Details"}
              labelclassname="text-xs1 text-gray-500 p-1 mr-1"
              checked={showDetail}
              onChange={(e) => setShowDetail(!showDetail)}
            />
            <CheckBoxWithLabel
              label={"Show Full List"}
              labelclassname="text-xs1 text-gray-500 p-1"
              checked={showFull}
              onChange={(e) => setShowFull(!showFull)}
            />
          </HStack>
          <HStack className="justify-between  text-center p-6 gap-4">
            <SecondaryCTAButton
              event-name="Delete Rights & Restriction"
              onClick={async () => {
                await deleteRights();
                navigate("/rights/overview");
              }}
            >
              Delete Rights & Restriction
            </SecondaryCTAButton>

            <HStack>
              <PrimaryCTAButton
                event-name="Shareholder Type Matching"
                className={loading ? "animate-pulse" : ""}
                onClick={() => {
                  handleChange();
                }}
              >
                {!loading && <span>Submit</span>}
                {loading && (
                  <HStack>
                    <p
                      className="indicator-progress"
                      style={{ display: "block" }}
                    >
                      Uploading...
                    </p>
                  </HStack>
                )}
              </PrimaryCTAButton>
            </HStack>
          </HStack>
        </Box>
      ) : (
        <Loader />
      )}
    </div>
  );
}

export default DragAndDrop;
