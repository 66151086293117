import { ColumnOptions } from "../types/DashboardModel";
import {
  AllTermsheetModel,
  IndividualTermSheetCapTable,
} from "../types/TermsheetModel";

export const initialTermSheetColumnState: ColumnOptions[] = [
  {
    name: "name",
    displayName: "Name",
    isVisible: true,
    isDisabled: false,
  },
  {
    name: "valuation",
    displayName: "Valuation",
    isVisible: true,
    isDisabled: false,
  },
  {
    name: "roundSize",
    displayName: "Round Size",
    isVisible: true,
    isDisabled: false,
  },
];

export const termsheetConstantList = [
  "termsheetName" as keyof AllTermsheetModel,
  "valuation" as keyof AllTermsheetModel,
  "roundSize" as keyof AllTermsheetModel,
];

export enum TermSheetType {
  roundSize,
  valuation,
  dilution,
  leadInvestor,
}

export const initialTermSheetIndividualColumnState: ColumnOptions[] = [
  {
    name: "preHoldingFdbPercentage",
    displayName: "Pre-Holding Fdb %",
    isVisible: true,
    isDisabled: false,
  },
  {
    name: "postHoldingFdbPercentage",
    displayName: "Post-Holding Fdb %",
    isVisible: true,
    isDisabled: false,
  },
  {
    name: "preHoldingFdbShares",
    displayName: "Pre-Holding FDB Shares",
    isVisible: true,
    isDisabled: false,
  },

  {
    name: "postHoldingFdbShares",
    displayName: "Post-Holding FDB Shares",
    isVisible: true,
    isDisabled: false,
  },

  {
    name: "amountInvestedInRound",
    displayName: "Amount To Invest",
    isVisible: true,
    isDisabled: false,
  },
];

export const termsheetIndividualConstantList = [
  "preHoldingFdbShares" as keyof IndividualTermSheetCapTable,
  "postHoldingFdbShares" as keyof IndividualTermSheetCapTable,
  "preHoldingFdbPercentage" as keyof IndividualTermSheetCapTable,
  "postHoldingFdbPercentage" as keyof IndividualTermSheetCapTable,
  "amountInvestedInRound" as keyof IndividualTermSheetCapTable,
  "shareholderName" as keyof IndividualTermSheetCapTable,
  "shareholderType" as keyof IndividualTermSheetCapTable,
];
