import React from "react";
import AGGridLiquidityRatiosTable from "./LiquidityRatiosAGTable";
import AGGridLeverageRatiosTable from "./LeverageRatiosAGTable";
import AGGridProfitabilityRatiosTable from "./ProfitabilityRatiosAGTable";
import { VStack } from "../../../../components/utils";
import AGGridGrossTransactionTable from "./GrossTransactionAgTable";
import AGGridUnsecuredBorrowingTable from "./UnsecuredBorrowingAGTable";
import AGGridBadDebtsTable from "./BadDebtsAGGridTable";
import AGGridLoansAndAdvancesTable from "./LoansAndAdvancesAGGridTable";
import AGGridProvisionsTable from "./ProvisionsAGGridTable";
import { getCurrencySymbol } from "../../../../utils/currencyFormatter";

export default function RelatedPartyTransactionAGTable({
  data,
  timelineType,
}: {
  data: any;
  timelineType: string;
}) {
  return (
    <VStack>
      <div>
        <p className="font-semibold text-sm pt-4 px-6">
          Gross Transactions Details
        </p>
        <AGGridGrossTransactionTable data={data} timelineType={timelineType} />
      </div>
      <div>
        <p className="font-semibold text-sm pt-4 px-6">Unsecured Borrowing</p>
        <AGGridUnsecuredBorrowingTable
          data={data}
          timelineType={timelineType}
        />
      </div>
      <div>
        <p className="font-semibold text-sm pt-4 px-6">Bad Debts</p>
        <AGGridBadDebtsTable data={data} timelineType={timelineType} />
      </div>
      <div>
        <p className="font-semibold text-sm pt-4 px-6">Loans And Advances</p>
        <AGGridLoansAndAdvancesTable data={data} timelineType={timelineType} />
      </div>
      <div>
        <p className="font-semibold text-sm pt-4 px-6">
          Provisions Made For Bad Loans
        </p>
        <AGGridProvisionsTable data={data} timelineType={timelineType} />
      </div>
    </VStack>
  );
}
