export function setTableHeight(displayedRowCount: number) {
  if (displayedRowCount === 1) {
    return (displayedRowCount + 4) * 60;
  } else if (displayedRowCount === 2) {
    return (displayedRowCount + 3) * 60;
  } else if (displayedRowCount === 3) {
    return (displayedRowCount + 3) * 60;
  } else if (displayedRowCount === 4) {
    return (displayedRowCount + 2.55) * 60;
  } else if (displayedRowCount === 5) {
    return (displayedRowCount + 2.48) * 60;
  } else if (displayedRowCount === 6) {
    return (displayedRowCount + 2.42) * 60;
  } else if (displayedRowCount === 7) {
    return (displayedRowCount + 2.35) * 60;
  } else if (displayedRowCount === 8) {
    return (displayedRowCount + 2.28) * 60;
  } else if (displayedRowCount === 9) {
    return (displayedRowCount + 2.22) * 60;
  } else {
    return 5 * 60;
  }
}
