import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { useAuthStore } from "../../store";
import { useGetInstrumentAndShareHolder } from "../../queries/quickRound";
import { commonExtractor } from "../../utils/dashboardUtils";
import {
  EventComparisonModel,
  InvestmentShareHolderModel,
  RoundsDetailModel,
} from "../../types/DashboardModel";
import DashboardCard from "./DashboardCards";
import { useGetAllEvents } from "../../queries/captable";
import { useSecurityOverviewDetails } from "../../queries/securities";
import { useGetShareholderOverview } from "../../queries/shareholders";
import { useGetAllTransactions } from "../../queries/transactionRound";
import { useShareholderInstrumentStore } from "../../store/shareholderInstrumentStore";
import IndividualNotes from "../../components/IndividualNotes";
import DashboardChart from "./DashBoardChart";
import {
  useInvGetAllEvents,
  useInvGetInstrumentAndShareHolder,
} from "../../queries/investorPortal";
import { MarkLabel } from "./DashboardCapTableTable";
import AgGridDashboardCapTable from "./DashboardAgGridCaptable";
import { useShareAuthStore } from "../../store/useShareAuthStore";
import { HStack } from "../../components/utils";
import ShareModal from "../share/ShareModal";
import BCHeader from "../../shared/BCHeader";
import {
  ButtonSize,
  SecondaryCTAButton,
} from "../quickRound/CTAButtonComponents";
import {
  useGetSharedInstrumentAndShareHolders,
  useSharedGetInstrumentAndShareHolder,
  useSharedGetSharedEvents,
} from "../../queries/sharedResouces";

export default function DashboardMainPage() {
  const pathName = window.location.href.split("/");
  const sharedPage = useMemo(() => pathName[3] === "share", [pathName]);
  const { shareId, shareCompanyId, shareResourceId, shareResourceType } =
    useShareAuthStore();
  const [showShare, setShowShare] = useState(false);
  const roundType: string = sharedPage
    ? (shareResourceType ?? "")?.split(" ")[0].toLowerCase()
    : pathName[4] || "round";
  const { cId, token } = useParams() as {
    cId: string;
    token: string;
  };
  const { companyId: _companyId, user } = useAuthStore();
  const companyId = _companyId || "";
  const [shareHoldersDetail, setShareHoldersDetail] = useState<
    EventComparisonModel[]
  >([]);
  const [roundChartData, setRoundChartData] = useState<RoundsDetailModel[]>([]);
  const [investmentData, setInvestmentData] =
    useState<InvestmentShareHolderModel[]>();
  const [capTableMarks, setCapTableMarks] = useState<MarkLabel[]>([]);

  const { isPlaceholderData, data: instrumentShareholderInfoData } = sharedPage
    ? useGetSharedInstrumentAndShareHolders(
        shareId ?? "",
        shareResourceId ?? "",
        shareCompanyId ?? ""
      )
    : cId
    ? useInvGetInstrumentAndShareHolder(cId, token)
    : useGetInstrumentAndShareHolder(companyId, cId);
  const { isPlaceholderData: eventsIsPlaceholderData, data: allEventsDetail } =
    sharedPage
      ? useSharedGetSharedEvents()
      : cId
      ? useInvGetAllEvents(cId, token)
      : useGetAllEvents(companyId, cId);

  const { data: overviewData } = useSecurityOverviewDetails();
  const { data: shareholder } = useGetShareholderOverview();
  const { data: alltransactions } = useGetAllTransactions();

  useEffect(() => {
    if (allEventsDetail && instrumentShareholderInfoData) {
      const {
        allRoundDetails,
        tempInvestmentData,
        allShareholder,
        markLabels,
      } = commonExtractor(allEventsDetail);
      setRoundChartData(allRoundDetails);
      setInvestmentData(tempInvestmentData);
      setShareHoldersDetail(allShareholder);
      setCapTableMarks(markLabels);
    }
  }, [allEventsDetail, instrumentShareholderInfoData]);
  const shareholderAndInstrumentstore = useShareholderInstrumentStore();

  useEffect(() => {
    if (!isPlaceholderData) {
      shareholderAndInstrumentstore.setInstrumentClass(
        instrumentShareholderInfoData?.instrumentClasses || []
      );
      shareholderAndInstrumentstore.setShareholders(
        instrumentShareholderInfoData?.shareholders || []
      );
      shareholderAndInstrumentstore.setInstrumentClassSubTypes(
        instrumentShareholderInfoData?.instrumentClassSubTypes || {}
      );
    }
  }, [instrumentShareholderInfoData, isPlaceholderData]);

  return (
    <>
      <div
        className={`${cId ? "bg-white px-4" : ""} ${
          isPlaceholderData ? "loading" : "min-h-full"
        }`}
      >
        {!sharedPage && (
          <HStack className="justify-between w-full gap-4">
            {showShare && (
              <ShareModal
                resourceId={companyId}
                resourceType={"DASHBOARD"}
                close={() => setShowShare(false)}
              />
            )}
            <BCHeader className="items-baseline mb-2" bcTitle="Dashboard" />
            <HStack className="gap-8">
              {
                <SecondaryCTAButton
                  buttonSize={ButtonSize.SMALL}
                  onClick={() => {
                    setShowShare(true);
                  }}
                >
                  Share
                </SecondaryCTAButton>
              }
            </HStack>
          </HStack>
        )}
        <main>
          {instrumentShareholderInfoData?.dashboardCardDetails && !cId && (
            <DashboardCard
              dashboardCardDetails={
                instrumentShareholderInfoData?.dashboardCardDetails
              }
            />
          )}
          {instrumentShareholderInfoData && user?.stage !== "Three" && (
            <div className={eventsIsPlaceholderData ? "loading" : ""}>
              <DashboardChart
                investorPortalView={!cId || sharedPage}
                roundChartData={roundChartData || []}
                shareHoldersList={shareHoldersDetail || []}
                investmentData={investmentData || []}
                securityDetails={
                  instrumentShareholderInfoData?.securityDetails || []
                }
              />
            </div>
          )}

          {instrumentShareholderInfoData && (
            <>
              <div className={eventsIsPlaceholderData ? "loading" : ""}>
                <AgGridDashboardCapTable
                  cinNumber={sharedPage ? "undefined" : cId}
                  allEventsDetail={allEventsDetail}
                  companyId={companyId}
                  markLabels={capTableMarks}
                  latestCaptable={instrumentShareholderInfoData.capTable || []}
                />
              </div>
            </>
          )}

          {/*Dirty commit {!cId && <ScenarioBasedCaptable companyId={companyId} />} */}
          {!cId && instrumentShareholderInfoData?.notes && (
            <IndividualNotes
              data={instrumentShareholderInfoData?.notes || ""}
            />
          )}
        </main>
      </div>
    </>
  );
}

// mx-2 sm:mx-4 lg:mx-6
