import { useState, useEffect, useCallback } from "react";
import debounce from "lodash/debounce";
import { useFormik } from "formik";
import * as Yup from "yup";
import Box from "@mui/material/Box";
import { Icon } from "@iconify/react";
import _ from "lodash";
import { Slider } from "@mui/material";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { HStack, VStack, Error } from "../../components/utils";
import {
  ButtonSize,
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../quickRound/CTAButtonComponents";
import {
  OutstandingConvertibleModelTransaction,
  RoundCreationModel,
} from "./RoundCreationModel";

import { getCurrencyType } from "../../utils/currencyFormatter";
import {
  FormatNumberSpan,
  getCurrencySymbolRoboto,
} from "../../utils/currencyRoboto";
import { sliderConvertibleStyle } from "../../constants/DashboardConstants";
import ConversionCaptable from "./ConversionCaptable";
import { useRMCreationStore } from "../../store/roundCreationStore";
import { Label } from "../../components/shared/InputField";
import {
  ConvertibleDetails,
  IndividualConvertiblesState,
  ModelBuilder,
} from "../../types/Modeling";
import AGGridOutStandingConvertibleHolders from "./OutstandingConvertibleHoldersAGtable";
import { useRoundModelStore } from "../../store/roundModelingStore";

type OutstandingConvertibleProps = {
  onPrimaryAction: () => void;
  onSecondaryAction: () => void;
  data: ConvertibleDetails;
  onClose: () => void;
  roundModel: ModelBuilder;
  sendMessage: (message: ModelBuilder) => void;
};

const OutstandingConvertibleThirdPopup = ({
  onPrimaryAction,
  onSecondaryAction,
  data,
  onClose,
  roundModel,
}: OutstandingConvertibleProps) => {
  const [postDiscountVal, setPostDiscountVal] = useState(
    data?.valuationApplied
  );
  const [loading, setLoading] = useState(false);
  const [postDiscountValuation, setPostDiscountValuation] = useState(
    data?.valuationApplied
  );

  const symbol = getCurrencySymbolRoboto();
  const currency = getCurrencyType();

  const [isValuationLocked, setIsValuationLocked] = useState(true);

  const {
    roundCreation,
    setRoundCreation,
    sendMessage,
    reset: discardRoundCreation,
  } = useRoundModelStore();

  const handleSubmit = async () => {
    setLoading(true);
    roundModel.convertibles!.convertibles.find(
      (convertible) => convertible.instrumentClassId === data.instrumentClassId
    )!.state! = IndividualConvertiblesState.UpdateCaptable;
    sendMessage(roundModel);

    onPrimaryAction();
  };

  const initialValues = {
    postDiscountValuation: data.valuationApplied || 0,
  };

  const validationSchema = Yup.object().shape({
    postDiscountValuation: Yup.number()
      .required()
      .moreThan(data.baseValuation)
      .lessThan(data.valuationCap)
      .label("Post Discount Valuation"),
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: false,
    validationSchema,
    onSubmit: (values) => {},
  });

  const updateDiscountValuation = () => {
    roundModel.convertibles!.convertibles.find(
      (convertible) => convertible.instrumentClassId === data.instrumentClassId
    )!.valuationApplied = Number(formik.values.postDiscountValuation);
    roundModel.convertibles!.convertibles.find(
      (convertible) => convertible.instrumentClassId === data.instrumentClassId
    )!.state = IndividualConvertiblesState.UpdateValuation;
    sendMessage(roundModel);
  };

  return (
    <div className="w-full p-2">
      <Box className="px-4 pt-4 pb-2 text-lg font-medium">
        <h6 className="flex justify-between">
          <VStack>
            <p className="font-medium text-lg text-[#464E5F]">
              Outstanding Convertible - {data?.securityName}
            </p>
            <p className="text-xs1 text-[#464E5F]">
              The conversion ratio has been updated, with no conversion of
              security type.
            </p>
          </VStack>

          <XMarkIcon
            className="h-5 font-medium cursor-pointer"
            onClick={() => onClose()}
          />
        </h6>
      </Box>
      <div className="p-5">
        <div className="items-center mb-5 w-fit">
          <HStack className="flex flex-wrap items-center px-2 border-2 ">
            <HStack className="py-2">
              <div>Pre Money Valuation: </div>
              <HStack className="ml-2 text-orange-p">
                <div>{symbol}</div>
                <FormatNumberSpan
                  value={data?.currentValuation}
                  format={currency}
                />
              </HStack>
            </HStack>
            <div className="mx-4 text-xl">|</div>
            <HStack className="py-2">
              <div>New Conversion Ratio :</div>
              <div className="ml-2 text-orange-p">
                {`1 : ${Number(data?.newConversionRatio.split(":")[1]).toFixed(
                  2
                )}`}
              </div>
            </HStack>
          </HStack>
        </div>
        <VStack className="items-end m-5">
          <HStack className="justify-start w-48">
            <Slider
              min={data?.baseValuation}
              value={postDiscountValuation}
              defaultValue={data?.valuationApplied}
              max={data?.valuationCap}
              onChange={(e: any, countNumber: any) => {
                setPostDiscountValuation(countNumber);
                setPostDiscountVal(countNumber);
                formik.setFieldValue("postDiscountValuation", countNumber);
                updateDiscountValuation();
              }}
              onChangeCommitted={(e: any, countNumber: any) => {}}
              valueLabelDisplay="auto"
              valueLabelFormat={(value: any) => (
                <div className="bg-transparent">
                  <p className="text-center">{value}</p>
                </div>
              )}
              sx={sliderConvertibleStyle}
            />
          </HStack>
          <HStack className="justify-between w-48 mb-2 -mt-2 text-blue-600">
            <p>Base</p>
            <p>Cap</p>
          </HStack>
          <HStack className="items-center px-2">
            <HStack>
              <p className="py-2 mr-4 text-sm font-medium whitespace-nowrap">
                Post Discount Valuation ({symbol})
              </p>
              <VStack>
                <HStack>
                  <input
                    type="number"
                    className="p-2 font-medium border-0 border-gray-400 rounded-md outline-none w-50 text-xs2 bg-inherit bg-slate-light"
                    {...formik.getFieldProps("postDiscountValuation")}
                    disabled={isValuationLocked}
                    onChange={(e) => {
                      formik.handleChange(e);
                      if (isValuationLocked) return;
                      if (
                        e.target.value &&
                        !Number.isNaN(Number(e.target.value)) &&
                        !formik.errors.postDiscountValuation
                      ) {
                        setPostDiscountValuation(
                          formik.values.postDiscountValuation
                        );
                        updateDiscountValuation();
                      }
                    }}
                  />
                  {isValuationLocked ? (
                    <Icon
                      onClick={() => {
                        setIsValuationLocked(false);
                      }}
                      icon="ic:outline-lock"
                      width={20}
                      className="text-[#E75935]"
                      height={20}
                    />
                  ) : (
                    <Icon
                      onClick={() => {
                        setIsValuationLocked(true);
                      }}
                      icon="material-symbols:lock-open-outline-rounded"
                      width={20}
                      className="text-gray-400"
                      height={20}
                    />
                  )}
                </HStack>
              </VStack>
            </HStack>
          </HStack>
          <HStack className="items-center">
            <Label className="mt-3 mr-4 text-sm font-medium whitespace-nowrap">
              Share Price
            </Label>
            <HStack className="mt-3 ml-2">
              {symbol}
              <FormatNumberSpan
                value={data?.finalConversionPPS}
                format={currency}
              />
            </HStack>
          </HStack>
        </VStack>

        <AGGridOutStandingConvertibleHolders
          OutStandingConvertibleHoldersDetails={data?.investors ?? []}
        />

        <HStack className="justify-between mt-10">
          <SecondaryCTAButton
            event-name="revert changes"
            buttonSize={ButtonSize.SMALL}
            onClick={() => {
              sendMessage(roundModel);
              onSecondaryAction();
            }}
          >
            Back
          </SecondaryCTAButton>
          <PrimaryCTAButton
            event-name="Individual convertible Preview Captable"
            className="p-1"
            buttonSize={ButtonSize.SMALL}
            onClick={() => handleSubmit()}
          >
            {!loading && <span>Save</span>}

            {loading && (
              <HStack>
                <p className="indicator-progress" style={{ display: "block" }}>
                  Please wait...
                </p>
                <Icon
                  className="animate-spin"
                  icon="lucide:loader-2"
                  width={18}
                />
              </HStack>
            )}
          </PrimaryCTAButton>
        </HStack>
      </div>
    </div>
  );
};

export default OutstandingConvertibleThirdPopup;
