import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  CellClickedEvent,
  ColDef,
  GridApi,
  SideBarDef,
} from "ag-grid-community";
import { Icon } from "@iconify/react";

import { Dialog } from "@mui/material";
import { toast } from "react-toastify";
import { AgGridReact } from "ag-grid-react";
import { Action, CTADropdown } from "../../components/shared/Dropdown";
import { agConfigDashboardSideBar } from "../dashboardPage/AgGridConfig";

import { ShareholderSecurityModel } from "../../types/Shareholder";

import {
  getCurrencySymbol,
  getCurrencyType,
} from "../../utils/currencyFormatter";

import { useDeleteEsopDetails } from "../../queries/shareholders";

import { globalFilter, sort } from "../../utils/arrays";

import { Box, HStack, VStack } from "../../components/utils";
import { useAuthStore } from "../../store";
import {
  useGetOnFilterState,
  usePostOnFilterState,
} from "../dashboardPage/AgGridCacheQuery";
import GenericTableHeader from "../../shared/TableHeader";
import {
  IconCTAButton,
  PrimaryCTAButton,
} from "../quickRound/CTAButtonComponents";
import { useAgGridTableState } from "../../store/agGridTableStore";
import { tableCellStyle } from "../../components/TableComponent";
import SearchInput from "../../components/shared/SearchInput";

import {
  AllocatedPoolRender,
  PlanNameRender,
  PlanTypeRender,
  ReservedPoolRender,
  UnallocatedPoolRender,
} from "./EsopPlanDetailsAGComponent";
import EsopPlanDetailsPopUp from "./EsopPlanDetailsPopUp";
import { useGetUserRoleGrants } from "../../queries/auth";

export interface EsopPlanDetailsModel {
  id?: string;
  planName: string;
  planType: string;
  reservedPool: number;
  allocatedPool: number;
  unallocatedPool: number;
}

export interface EsopPlanDetail {
  cInvestorId: string;
  planDetails: EsopPlanDetailsModel;
}

function AgGridEsopPlanDetailsTable({
  esopPLanDetailsList,
  shareholderId,
}: {
  esopPLanDetailsList: EsopPlanDetailsModel[];
  currencyType: string;
  shareholderId: string;
}) {
  const [dialog, setDialog] = useState<{
    open: boolean;
    name?: "Add" | "Update" | "Delete" | "";
    data?: string;
    mode?: string;
  }>({
    name: "",
    open: false,
  });

  const { data: userRoleData } = useGetUserRoleGrants();

  const [certificateId, setCertificateId] = useState("");
  const [isColumnOpen, setIsColumnOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const [globalFilterText, setGlobalFilterText] = useState("");

  const gridApi = useRef<any>(null);

  const fileRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  const currencySymbol = getCurrencySymbol();
  const companyId = useAuthStore().companyId ?? "";
  const userId = useAuthStore().user?.userId ?? 0;
  const { refetch, data: filterState } = useGetOnFilterState(
    `${userId}`,
    companyId
  );
  const agTableStore = useAgGridTableState();
  const [sortField, setSortField] = useState<{
    field: keyof EsopPlanDetailsModel | "" | undefined;
    ascending: boolean;
  }>({ field: "planName", ascending: true });

  const displayedRowCount = gridApi.current?.api.getDisplayedRowCount();

  useEffect(() => {
    refetch().then((data) => {
      if (data.data) agTableStore.setState(data.data);
      setColumnSetting(data.data?.shareholderSecurityColumnModel);
      setFilterSetting(data.data?.shareholderSecurityFilterModel);
    });
  }, []);

  const { mutate: postOnFilter } = usePostOnFilterState();

  function getColumnSetting() {
    if (gridApi.current) return gridApi.current.columnApi?.getColumnState();
    return {};
  }

  function setColumnSetting(model: any) {
    if (gridApi.current)
      gridApi.current.columnApi.applyColumnState({ state: model });
    return {};
  }

  // Gets filter model via the grid API
  const getFilterSetting = () => {
    if (gridApi.current) return gridApi.current.api?.getFilterModel();
    return {};
  };

  const setFilterSetting = (model: any) => {
    if (gridApi.current) return gridApi.current.api?.setFilterModel(model);
    return {};
  };

  const uploadFilterAndColumn = async () => {
    const columnState = await getColumnSetting();
    const filterState = await getFilterSetting();
    postOnFilter({
      userId: `${userId}`,
      companyId,
      filterData: {
        ...agTableStore.state,
        shareholderSecurityColumnModel: columnState,
        shareholderSecurityFilterModel: filterState,
      },
    });
  };

  const cin = "";

  const componentsRegistery = useMemo(
    () => ({
      planName: memo(PlanNameRender),
      planType: memo(PlanTypeRender),
      reservedPool: memo(ReservedPoolRender),
      allocatedPool: memo(AllocatedPoolRender),
      unallocatedPool: memo(UnallocatedPoolRender),
    }),
    []
  );

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      autoHeight: true,
      wrapHeaderText: true,
      suppressColumnVirtualisation: true,
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      filterParams: {
        buttons: ["reset"],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
      flex: 1,
    }),
    []
  );

  const columnDefs: ColDef[] = useMemo(() => {
    const columnDef: ColDef[] = [
      {
        headerName: "Plan Name",
        filter: "agSetColumnFilter",
        field: "planName",
        pinned: "left",
        cellRenderer: "planName",
        initialWidth: 300,
        sortable: true,
        autoHeight: true,
        cellStyle: tableCellStyle,
        wrapText: true,
        suppressSizeToFit: true,
        menuTabs: ["filterMenuTab"],
      },
      {
        headerName: "Plan Type",
        field: "planType",
        filter: "agSetColumnFilter",
        cellRenderer: "planType",
        cellStyle: tableCellStyle,
        sortable: false,
        menuTabs: ["filterMenuTab"],
      },
      {
        headerName: "Reserved Pool",
        field: "reservedPool",
        filter: "agNumberColumnFilter",
        cellRenderer: "reservedPool",
        cellStyle: tableCellStyle,
        sortable: false,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipReservedPool",
      },
      {
        headerName: "Allocated Pool",
        field: "allocatedPool",
        filter: "agNumberColumnFilter",
        cellRenderer: "allocatedPool",
        cellStyle: tableCellStyle,
        sortable: false,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipAllocatedPool",
      },
      {
        headerName: "Unallocated Pool",
        field: "unallocatedPool",
        filter: "agNumberColumnFilter",
        cellRenderer: "unallocatedPool",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipUnallocatedPool",
      },
    ];

    if (userRoleData[0].role !== "Admin Viewer") {
      columnDef.push({
        headerName: "",
        field: "actions",
        pinned: "right",
        hide: false,
        width: 80,
        maxWidth: 80,
        filter: false,
        menuTabs: [],
        colId: "action-column",
        suppressNavigable: true,
        suppressColumnsToolPanel: true,
        cellStyle: { "padding-top": "16px", "line-height": "20px" },
        resizable: false,
        sortable: false,
        editable: false,
        cellRendererParams: ({ value }: { value: any }) => value?.props,
        cellRenderer: CTADropdown,
      });
    }
    return columnDef;
  }, []);
  const gridOptions = {
    suppressRowVirtualisation: true, // Add this line to disable row virtualization
    paginationAutoPageSize: false, // Optional: Set to false if using custom pagination
    suppressScrollOnNewData: true, // Optional: Set to true to prevent scrolling to the top on
  };
  const currencyType = getCurrencyType();
  const { mutate: deleteEsopDetail } = useDeleteEsopDetails();

  function handleModifyAction(action: string, data: any) {
    if (action === "Delete") {
      deleteEsopDetail(data.id, {
        onSuccess: (data) => {
          toast("Esop Detail Deleted Successfully", {
            type: "success",
            autoClose: 2000,
          });
        },
        onError: (err: any) => {
          toast(err.response.data.errorMessage, {
            type: "error",
            autoClose: 2000,
          });
        },
      });
    } else if (action === "Add") {
      setDialog({
        open: true,
        mode: "Add",
        data,
      });
    } else if (action === "Update") {
      setDialog({
        open: true,
        mode: "Update",
        data,
      });
    }
  }

  function setPinnedBottomRowData({ api }: { api: any }) {
    const data = api.rowModel.rootNode;
    const filteredData = data.childrenAfterAggFilter.map(
      (element: any) => element.data
    );
    if (filteredData.length === 0) {
      api.setPinnedBottomRowData([]); // Clear the pinned bottom row if no data is present
      return;
    }

    const totalReservedPool = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + data.reservedPool,
      0
    );
    const totalAllocatedPool = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + data.allocatedPool,
      0
    );
    const totalUnAllocatedPool = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + data.unallocatedPool,
      0
    );
    api.setPinnedBottomRowData([
      {
        planName: "Total",
        planType: null,
        reservedPool: totalReservedPool,
        tooltipReservedPool: totalReservedPool.toLocaleString(currencyType),
        allocatedPool: totalAllocatedPool,
        tooltipAllocatedPool: totalAllocatedPool.toLocaleString(currencyType),

        unallocatedPool: totalUnAllocatedPool,
        tooltipUnallocatedPool:
          totalAllocatedPool?.toLocaleString(currencyType),
        currencyType,
        currencySymbol,
      },
    ]);
  }

  esopPLanDetailsList = useMemo(() => {
    if (!esopPLanDetailsList) return [];
    try {
      const filterResult = globalFilter(esopPLanDetailsList, globalFilterText, [
        "planName",
        "reservedPool",
        "allocatedPool",
        "unallocatedPool",
      ]);
      const sortResult = sort(
        filterResult,
        sortField?.field,
        sortField?.ascending
      );
      return sortResult;
    } catch (e) {
      return esopPLanDetailsList;
    }
  }, [esopPLanDetailsList, globalFilterText, sortField]);

  const rowData = useMemo(
    () =>
      esopPLanDetailsList?.map((template) => ({
        planName: template.planName,
        planType: template.planType,
        reservedPool: template.reservedPool,
        tooltipReservedPool:
          template.reservedPool?.toLocaleString(currencyType),
        allocatedPool: template.allocatedPool,
        tooltipAllocatedPool:
          template.allocatedPool?.toLocaleString(currencyType),

        unallocatedPool: template.unallocatedPool,
        tooltipUnallocatedPool:
          template.unallocatedPool?.toLocaleString(currencyType),

        actions: (
          <CTADropdown
            actions={[
              {
                name: "Update",
                disabled: false,
              },
              {
                name: "Delete",
                disabled: false,
              },
            ]}
            // onAction={(action) => handleDelete(template)}
            onAction={(action) => {
              handleModifyAction(action.name, template);
            }}
          />
        ),
      })),
    [esopPLanDetailsList]
  );

  const handleCellClick = (cellParams: CellClickedEvent<any, any>) => {
    if (cellParams.column.getColId() !== "action-column") {
      const selectedRowIndex = cellParams.node.rowIndex || 0;
      const template = rowData[selectedRowIndex];
      if (cellParams.column.getColId() === "transactionName") {
        //dirty comment
        // onClickTransactionName(template.transactionType, template.id);
      }
    }
  };

  const openToolPanel = (key: any) => {
    if (key === "columns") {
      if (gridApi) {
        if (!isColumnOpen) gridApi?.current?.api?.openToolPanel(key);
        else gridApi?.current?.api?.closeToolPanel();
        setIsColumnOpen((state) => !state);
        setIsFilterOpen(false);
      }
    } else if (key === "filters") {
      if (gridApi) {
        if (!isFilterOpen) gridApi?.current?.api?.openToolPanel(key);
        else gridApi?.current?.api?.closeToolPanel();
        setIsFilterOpen((state) => !state);
        setIsColumnOpen(false);
      }
    }
  };
  const [filteredRowData, setFilteredRowData] = useState<any>([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const onAgGridFilterChanged = (grid: any) => {
    const filtersApplied = grid.api.isAnyFilterPresent();
    setIsFilterApplied(filtersApplied);

    const filteredData = grid.api
      .getModel()
      .rowsToDisplay.map((node: any) => node.data);
    setFilteredRowData(filteredData);
    setPinnedBottomRowData(grid);

    uploadFilterAndColumn();
  };

  return (
    <>
      <HStack className="items-center justify-between py-2 bg-white rounded-md shadow-box">
        <GenericTableHeader
          heading={"ESOP Plan Details"}
          subHeading={"plans"}
          count={
            isFilterApplied === true ? filteredRowData.length : rowData.length
          }
        />
        <Dialog open={dialog.open} maxWidth="md">
          {(dialog.mode === "Add" || dialog.mode === "Update") && (
            <EsopPlanDetailsPopUp
              mode={dialog.mode}
              onPrimaryAction={() => {
                setDialog({ open: false });
              }}
              onSecondaryAction={() => {
                setDialog({ open: false });
              }}
              data={dialog.mode === "Add" ? "" : dialog.data}
            />
          )}
        </Dialog>
        <HStack className="items-center justify-end gap-4 py-2 mr-4">
          {/* <QuickSearch
            placeholder={`Search`}
            onChange={(e) => {
              gridApi.current.api.setQuickFilter(e.target.value);
            }}
          /> */}
          <SearchInput
            placeholder={`Search`}
            onChange={(e: any) => {
              gridApi.current.api.setQuickFilter(e.target.value);
            }}
          />
          <IconCTAButton
            value={"Columns"}
            onClick={() => openToolPanel("columns")}
            iconName={"fluent:column-triple-edit-20-regular"}
            className={`px-4 font-medium items-center flex flex-row ${
              isColumnOpen ? "text-orange-501" : "text-gray-400"
            }`}
          />
          <IconCTAButton
            value={"Filters"}
            onClick={() => openToolPanel("filters")}
            iconName={"material-symbols:filter-alt"}
            className={`px-4 font-medium items-center flex flex-row ${
              isFilterOpen ? "text-orange-501" : "text-gray-400"
            }`}
          />
          {userRoleData[0].role !== "Admin Viewer" && (
            <PrimaryCTAButton
              className="h-8 text-xs2 whitespace-nowrap"
              onClick={() => {
                setDialog({
                  open: true,
                  mode: "Add",
                });
              }}
            >
              Add ESOP Plan Details
            </PrimaryCTAButton>
          )}
        </HStack>
      </HStack>

      <HStack className="justify-between w-full">
        <Box
          style={{
            height: (displayedRowCount + 7.5) * 60,
          }}
          className="w-full h-full max-h-full overflow-x-auto ag-theme-material "
        >
          <AgGridReact
            sideBar={agConfigDashboardSideBar}
            onGridReady={(params) => {
              // Store the grid API referen
              gridApi.current = params;
            }}
            components={componentsRegistery}
            alwaysShowHorizontalScroll
            alwaysMultiSort
            enableCharts={true}
            enableRangeSelection={true}
            animateRows={true}
            defaultColDef={defaultColDef}
            onFilterChanged={onAgGridFilterChanged}
            onRowDataUpdated={setPinnedBottomRowData}
            onColumnEverythingChanged={uploadFilterAndColumn}
            rowData={rowData}
            onCellClicked={handleCellClick}
            columnDefs={columnDefs}
            pagination={false}
            suppressRowTransform={true}
            suppressCopyRowsToClipboard={true}
            suppressCopySingleCellRanges={true}
            suppressCellFocus={true}
            onColumnResized={uploadFilterAndColumn}
            suppressMenuHide={false}
            tooltipShowDelay={1000}
            tooltipInteraction={true}
            rowClass={"border-t border-dashed"}
            overlayNoRowsTemplate={
              '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow; margin-top: 50px;">No Rows To Show</span>'
            }
            gridOptions={gridOptions}
            getRowStyle={(params) => {
              if (params.rowIndex % 2 === 0) {
                return { background: "#f8f8f8" };
              } else {
                return { background: "#ffffff" };
              }
            }}
          ></AgGridReact>
        </Box>
      </HStack>
    </>
  );
}

export default AgGridEsopPlanDetailsTable;
