export const designationDropDownValues = ["Founder", "CFO", "Director"];
export const valuationMethodology = ["DC", "Book Value"];
export const typeOfValuation = ["Equity", "CCPS", "Option"];
export const typeOfDirectorDropDownValues = [
  "Executive director",
  "Non-Executive Director",
  "Managing director",
  "Independent director",
  "Residential director",
];
export const currencyDropDownValues = [
  "AED - د.إ",
  "AUD - A$",
  "CNY - ¥",
  "EUR - €",
  "GBP - £",
  "INR - ₹",
  "JPY - ¥",
  "SGD - S$",
  "USD - $",
];
export const companyTypeDropDownValues = [
  "Private Limited Company",
  "Public Limited Company",
  "Unlimited Company",
  "Partnership",
  "Limited Liability Partnership",
];

export const designationForUserDropDownValues = [
  "Founder",
  "CFO",
  "Director",
  "Attorney",
  "Company Secretary",
  "Chartered Accountant",
  "Compliance Officer",
  "Consultant",
  "Others",
];
