import Box from "@mui/material/Box";
import { Label } from "../components/shared/InputField";
import { HStack, VStack } from "../components/utils";

type ValidationErrorsDialogProps = {
  errors: string[];
  onPrimaryAction: () => void;
  onSecondaryAction: () => void;
};

const ValidationErrorsDialog = ({
  errors,
  onPrimaryAction,
  onSecondaryAction,
}: ValidationErrorsDialogProps) => (
  <div className="p-5 h-96">
    <Box className="p-2 text-lg font-medium border-b h-1/5">
      <HStack className="justify-between">
        <h6 className="text-black-600">Excel Sheet Validation Errors</h6>
        <span onClick={() => onSecondaryAction()} className="cursor-pointer">
          X
        </span>
      </HStack>
    </Box>
    <VStack className="h-4/5 w-30 overflow-y-scroll gap-3">
      {errors.map((error) => (
        <Box key={error} className="shadow-box px-2 mx-2 mt-2">
          <Label className="text-red-600">{error}</Label>
        </Box>
      ))}
    </VStack>
  </div>
);

export default ValidationErrorsDialog;
