import { useMutation, useQuery } from "react-query";
import {
  getIndividualNonPricedRoundModel,
  getListOfModelings,
  getListOfNonPricedRoundModel,
  getModelingDetails,
  getNonPricedRoundModelDetails,
  saveNonPricedRoundModel,
} from "../api/modeling";
import {
  randomModelingData,
  randomNonPricedRoundModel,
} from "./randomPlaceholderData";
import { postOnFilterState } from "../pages/dashboardPage/AgGridCache";

export function useGetListOfModeling() {
  return useQuery({
    queryKey: "listofmodeling",
    queryFn: getListOfModelings,
    placeholderData: randomModelingData(),
    enabled: true,
  });
}

export function useListOfNonPricedRoundModel() {
  return useQuery({
    queryKey: "getListOfNonPricedRoundModel",
    queryFn: getListOfNonPricedRoundModel,
    enabled: true,
  });
}

export function useGetIndividualNonPricedRoundModel(modelId: string) {
  return useQuery({
    queryKey: ["getIndividualNonPricedRoundModel", modelId],
    queryFn: getIndividualNonPricedRoundModel,
    enabled: true,
  });
}

export function useGetNonPricedRoundModelDetails() {
  return useQuery({
    queryKey: "getNonPricedRoundModelDetails",
    queryFn: getNonPricedRoundModelDetails,
    enabled: true,
  });
}

export function useSaveNonPricedRoundModel() {
  return useMutation({
    mutationKey: "saveNonPricedRoundModel",
    mutationFn: saveNonPricedRoundModel,
    onSuccess: () => {},
    onError: () => {},
  });
}

export function useGetModelingDetails(modelId: string) {
  return useQuery({
    queryKey: ["modelingDetails", modelId],
    queryFn: getModelingDetails,
    enabled: false,
  });
}
