import { toast } from "react-toastify";
import { useFormikContext, Formik, Form } from "formik";
import { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { Icon } from "@iconify/react";
import debounce from "lodash/debounce";
import { isEqual } from "lodash";
import { HStack, VStack, Error } from "../../components/utils";
import { Input, Label } from "../../components/shared/InputField";
import { RoundCreationModel, RoundInputDetail } from "./RoundCreationModel";
import {
  ButtonSize,
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../quickRound/CTAButtonComponents";
import { getCurrencyType } from "../../utils/currencyFormatter";
import {
  FormatNumberSpan,
  getCurrencySymbolRoboto,
} from "../../utils/currencyRoboto";
import { SwitchButton } from "../../components/shared/SwitchButton";
import RoundHeaderDetailCard from "./RoundHeader";
import { useRMCreationStore } from "../../store/roundCreationStore";

export default function RoundCreationComponent() {
  const currency = getCurrencyType();
  const symbol = getCurrencySymbolRoboto();

  const {
    roundCreation: data,
    sendMessage: onNext,
    setRoundCreation: setRoundState,
    reset: disCardRoundCreation,
  } = useRMCreationStore();

  const [isPremoneyLocked, setIsPremoneyLocked] = useState(false);
  const [preMoneyValuation, setPreMoneyValuation] = useState(
    data.tab1?.inputDetails?.preMoneyValuation?.toString() || ""
  );

  const [pps, setPps] = useState(
    data.tab1?.inputDetails?.pps?.toString() || ""
  );

  const debouncedSubmit = debounce((values) => {
    onNext({
      ...data,
      tab1: {
        inputDetails: {
          ...data.tab1?.inputDetails,
          roundIdentifier: values.roundIdentifier || "",
          roundName: values.roundName || "",
          pricedRound: values.pricedRound,
          autoDiluteEsop: values.autoDiluteEsop ?? false,
          postRoundEsop: values.postRoundEsop || 0,
          roundSize: values.roundSize || 0,
          closeDate: values.closingDate || "",
          hasToCalcuatePps: !isPremoneyLocked,
        },
      },
    });
  }, 10);

  const AutoSubmitToken = () => {
    // Grab values and submitForm from context
    const { values, submitForm } = useFormikContext<RoundInputDetail>();
    const prevValuesRef = useRef(values);
    useEffect(() => {
      // Call the debounced submit function
      if (!isEqual(prevValuesRef.current, values)) {
        debouncedSubmit(values);
      }

      // Cleanup function to cancel the debounced call if the component unmounts
      return () => debouncedSubmit.cancel();
    }, [values]);
    return null;
  };

  const initialRoundInputDetail = {
    roundName: data.tab1?.inputDetails?.roundName || "",
    roundIdentifier: data.tab1?.inputDetails?.roundIdentifier || "",
    pricedRound: data.tab1?.inputDetails?.pricedRound ?? true,
    autoDiluteEsop: data.tab1?.inputDetails?.autoDiluteEsop ?? true,
    postRoundEsop: data.tab1?.inputDetails?.postRoundEsop || 0,
    roundSize: data.tab1?.inputDetails?.roundSize || 0,
    preMoneyValuation: data.tab1?.inputDetails?.preMoneyValuation || 0,
    pps: data.tab1?.inputDetails?.pps || 0,
    hasToCalcuatePps: data.tab1?.inputDetails?.hasToCalcuatePps || true,
    postMoneyValuation:
      (data.tab1?.inputDetails?.preMoneyValuation || 0) +
      (data.tab1?.inputDetails?.roundSize || 0),
    dilution:
      ((data.tab1?.inputDetails?.roundSize || 0) /
        ((data.tab1?.inputDetails?.preMoneyValuation || 0) +
          (data.tab1?.inputDetails?.roundSize || 0))) *
      100,
    currency: data.tab1?.inputDetails?.currency || "INR",
    exchangeRate: data.tab1?.inputDetails?.exchangeRate || 0,
    closingDate: data.tab1?.inputDetails?.closeDate || "",
  };
  const [isPricedRound, setIsPricedRound] = useState(
    data.tab1?.inputDetails?.pricedRound ?? true
  );

  const roundDetailsValidationSchema = Yup.object({
    roundName: Yup.string().required().label("Round Name"),
    roundIdentifier: Yup.string().required().label("Round Identifier"),
    pricedRound: Yup.boolean().required().label("pricedRound Type"),
    autoDiluteEsop: Yup.string().required().label("AutoDiluteEsop"),
    roundSize: Yup.number().min(1).required().label("Round Size"),
    postRoundEsop: Yup.number().max(95).label("Post Round ESOP"),
    preMoneyValuation: Yup.number().min(1).label("Valuation"),
    closingDate: Yup.string().required().label("Close Date"),
    hasToCalcuatePps: Yup.boolean().required().label("hasToCalcuatePps"),
    pps: Yup.number().label("PPS"),
  });

  useEffect(() => {
    if (data.errors && data.errors.length > 0) {
      for (const error of data.errors) {
        toast(error, {
          type: "error",
          autoClose: 2000,
        });
      }
      setRoundState({
        ...data,
        errors: [],
      });
    }
  }, []);

  useEffect(() => {
    onNext({
      ...data,
      tab1: {
        inputDetails: {
          ...data.tab1?.inputDetails,
          preMoneyValuation: Number(preMoneyValuation),
          pps: Number(pps),
          hasToCalcuatePps: !isPremoneyLocked,
        },
      },
    });
  }, [preMoneyValuation, pps]);

  function scrollToTop() {
    const scroller = document.querySelector("#outlet");
    scroller?.scrollTo(0, 0);
  }

  const updateLocalStates = (currentLockStatus: boolean) => {
    if (currentLockStatus) {
      setPreMoneyValuation(
        data.tab1?.inputDetails?.preMoneyValuation?.toString() || ""
      );
    } else {
      setPps(data.tab1?.inputDetails?.pps?.toString() || "");
    }
  };

  return (
    <VStack>
      <div className="px-4 py-4 bg-white rounded-lg ">
        <Label className="text-xl font-semibold text-gray-dark">
          Round Details
        </Label>

        <p className="mb-4 font-medium text-gray-light">
          Fill in the Round Details
        </p>
        <div className="border-b border-solid border-[#EBEBEB] mb-8"></div>
        {data.tab1?.tab1RoundHeader && (
          <RoundHeaderDetailCard
            currentCompanyDetail={data.tab1.tab1RoundHeader}
          />
        )}

        <VStack className="justify-between">
          <Formik
            key="addEditRoundCreation"
            initialValues={initialRoundInputDetail}
            validationSchema={roundDetailsValidationSchema}
            onSubmit={(values) => {
              setRoundState({
                ...data,
                isLoader: true,
              });
              const inputValues = {
                roundIdentifier: values.roundIdentifier || "",
                roundName: values.roundName || "",
                pricedRound: values.pricedRound,
                autoDiluteEsop: values.autoDiluteEsop ?? false,
                postRoundEsop: values.postRoundEsop || 0,
                roundSize: values.roundSize || 0,
                preMoneyValuation: values.preMoneyValuation || 0,
                postMoneyValuation:
                  values.preMoneyValuation + values.roundSize || 0,
                dilution:
                  (values.roundSize /
                    (values.preMoneyValuation + values.roundSize)) *
                    100 || 0,
                currency: values.currency || "INR",
                exchangeRate: values.exchangeRate || 0,
                closeDate: values.closingDate || "",
              };
              onNext({
                ...data,
                currentTab: 3,
                tab1: {
                  tab1RoundHeader: data.tab1?.tab1RoundHeader,
                  inputDetails: inputValues,
                },
              } as RoundCreationModel);
              scrollToTop();
            }}
          >
            {(formik) => (
              <Form key="addEditRoundCreation">
                <HStack className="items-center flex-1 mb-8">
                  <Label className="w-40 mr-16 text-sm font-medium whitespace-nowrap">
                    Round Name
                  </Label>
                  <VStack>
                    <Input
                      className="w-64 mr-16 text-sm font-medium whitespace-nowrap"
                      type="text"
                      placeholder="Enter the round name"
                      {...formik.getFieldProps("roundName")}
                    />

                    {formik.touched?.roundName && formik.errors?.roundName && (
                      <Error text={formik.errors?.roundName} />
                    )}
                  </VStack>
                </HStack>
                <HStack className="items-center flex-1 mb-8">
                  <Label className="w-40 mr-16 text-sm font-medium whitespace-nowrap">
                    Round Identifier
                  </Label>
                  <VStack>
                    <Input
                      className="w-64 mr-16 text-sm font-medium whitespace-nowrap"
                      type="text"
                      placeholder="Enter the round identifier"
                      {...formik.getFieldProps("roundIdentifier")}
                    />

                    {formik.touched?.roundIdentifier &&
                      formik.errors?.roundIdentifier && (
                        <Error text={formik.errors?.roundIdentifier} />
                      )}
                  </VStack>
                </HStack>

                <HStack className="items-center mb-8">
                  <HStack className="items-center flex-1">
                    <Label className="w-40 mr-16 text-sm font-medium whitespace-nowrap">
                      Priced Round
                    </Label>
                    <SwitchButton
                      className="mr-16 text-sm font-medium"
                      label=""
                      value={formik.values.pricedRound || false}
                      onClick={() => {
                        formik.setFieldValue(
                          "pricedRound",
                          !formik.values.pricedRound
                        );
                        setIsPricedRound(!isPricedRound);
                      }}
                    />

                    {formik.touched?.pricedRound &&
                      formik.errors?.pricedRound && (
                        <Error text={formik.errors?.pricedRound} />
                      )}
                  </HStack>
                  <HStack className="items-center flex-1">
                    <Label className="w-40 mr-16 text-sm font-medium whitespace-nowrap">
                      Auto Dilute ESOP (%)
                    </Label>
                    <SwitchButton
                      className="ml-8 text-sm font-medium"
                      label=""
                      value={formik.values.autoDiluteEsop ?? false}
                      onClick={() => {
                        formik.setFieldValue(
                          "autoDiluteEsop",
                          !formik.getFieldProps("autoDiluteEsop").value
                        );
                      }}
                    />

                    {formik.touched?.autoDiluteEsop &&
                      formik.errors?.autoDiluteEsop && (
                        <Error text={formik.errors?.autoDiluteEsop} />
                      )}
                  </HStack>
                </HStack>
                {!formik.values.autoDiluteEsop && (
                  <HStack className="items-center flex-1 mb-8">
                    <Label className="w-40 mr-16 text-sm font-medium whitespace-nowrap">
                      Post Round ESOP (&#37;)
                    </Label>

                    <VStack>
                      <Input
                        className="w-64 mr-16 text-sm font-medium whitespace-nowrap"
                        type="number"
                        readOnly={formik.values.autoDiluteEsop}
                        placeholder="Enter the post round ESOP &#37;"
                        {...formik.getFieldProps("postRoundEsop")}
                      />
                      {formik.touched?.postRoundEsop &&
                        formik.errors?.postRoundEsop && (
                          <Error text={formik.errors?.postRoundEsop} />
                        )}
                    </VStack>
                  </HStack>
                )}
                <HStack className="items-center flex-1 mb-8">
                  <Label className="w-40 mr-16 text-sm font-medium whitespace-nowrap">
                    Round Size ({symbol})
                  </Label>
                  <VStack>
                    <Input
                      className="w-64 mr-16 text-sm font-medium whitespace-nowrap"
                      type="number"
                      placeholder="Enter the round size"
                      {...formik.getFieldProps("roundSize")}
                    />
                    <p className="hidden text-xxs1">
                      The current round is in (INR), click{" "}
                      <span className="text-[#E85936] text-xxs1 cursor-pointer">
                        here
                      </span>{" "}
                      to change the currency
                    </p>
                    {formik.touched?.roundSize && formik.errors?.roundSize && (
                      <Error text={formik.errors?.roundSize} />
                    )}
                  </VStack>
                </HStack>

                <HStack className="items-center flex-1 mb-8 ">
                  <Label className="mr-16 text-sm font-medium whitespace-nowrap w-44">
                    {isPricedRound ? "Pre-money Valuation" : "Base Valuation"} (
                    {symbol})
                  </Label>
                  <VStack>
                    <Input
                      className="w-64 mr-2 text-sm font-medium whitespace-nowrap"
                      type="number"
                      disabled={isPremoneyLocked}
                      placeholder={
                        isPricedRound
                          ? "Enter Pre-money Valuation"
                          : "Enter Base Valuation"
                      }
                      value={
                        isPremoneyLocked
                          ? data.tab1?.inputDetails?.preMoneyValuation
                          : preMoneyValuation
                      }
                      onChange={(e) => {
                        formik.setFieldValue(
                          "preMoneyValuation",
                          Number(e.target.value)
                        );
                        setPreMoneyValuation(e.target.value);
                      }}
                    />

                    {formik.touched?.preMoneyValuation &&
                      formik.errors?.preMoneyValuation && (
                        <Error text={formik.errors?.preMoneyValuation} />
                      )}
                  </VStack>
                  {!isPremoneyLocked && (
                    <Icon
                      onClick={() => {
                        setIsPremoneyLocked((prev) => {
                          updateLocalStates(prev);
                          return !prev;
                        });
                      }}
                      icon="material-symbols:lock-open-outline-rounded"
                      width={20}
                      className="text-[#E75935]"
                      height={20}
                    />
                  )}
                  {isPremoneyLocked && (
                    <Icon
                      onClick={() => {
                        setIsPremoneyLocked((prev) => {
                          updateLocalStates(prev);
                          return !prev;
                        });
                      }}
                      icon="ic:outline-lock"
                      width={20}
                      className="text-gray-400"
                      height={20}
                    />
                  )}
                </HStack>
                <HStack className="items-center flex-1 mb-8 ">
                  <Label className="mr-16 text-sm font-medium whitespace-nowrap w-44">
                    {"FDB Share Price"} ({symbol})
                  </Label>
                  <VStack>
                    <Input
                      className="w-64 mr-2 text-sm font-medium whitespace-nowrap"
                      type="number"
                      readOnly={!isPremoneyLocked}
                      placeholder={"Enter FDB Share Price"}
                      disabled={!isPremoneyLocked}
                      value={
                        !isPremoneyLocked ? data.tab1?.inputDetails?.pps : pps
                      }
                      onChange={(e) => {
                        formik.setFieldValue("pps", Number(e.target.value));
                        setPps(e.target.value);
                      }}
                    />

                    {formik.touched?.preMoneyValuation &&
                      formik.errors?.preMoneyValuation && (
                        <Error text={formik.errors?.preMoneyValuation} />
                      )}
                  </VStack>
                  {isPremoneyLocked && (
                    <Icon
                      onClick={() => {
                        setIsPremoneyLocked((prev) => {
                          updateLocalStates(prev);
                          return !prev;
                        });
                      }}
                      icon="material-symbols:lock-open-outline-rounded"
                      width={20}
                      className="text-[#E75935]"
                      height={20}
                    />
                  )}
                  {!isPremoneyLocked && (
                    <Icon
                      onClick={() => {
                        setIsPremoneyLocked((prev) => {
                          updateLocalStates(prev);
                          return !prev;
                        });
                      }}
                      icon="ic:outline-lock"
                      width={20}
                      className="text-gray-400"
                      height={20}
                    />
                  )}
                </HStack>
                <HStack className="items-center flex-1 mb-8">
                  <Label className="w-40 mr-16 text-sm font-medium whitespace-nowrap">
                    Close Date
                  </Label>
                  <VStack>
                    <Input
                      type="date"
                      className="w-60"
                      placeholder="12-12-2022"
                      {...formik.getFieldProps("closingDate")}
                    />
                    {formik.touched?.closingDate &&
                      formik.errors?.closingDate && (
                        <Error text={formik.errors?.closingDate} />
                      )}
                  </VStack>
                </HStack>
                <HStack className="justify-start mb-4">
                  <HStack className="items-center flex-1 mb-8">
                    <Label className="text-sm font-medium whitespace-nowrap">
                      Post-Money Valuation ({symbol}):
                    </Label>

                    <Label className="ml-2 mr-16 text-sm font-medium text-orange-500 whitespace-nowrap">
                      <FormatNumberSpan
                        value={Number(
                          (data.headers?.roundSize || 0) +
                            (data.headers?.valuation || 0)
                        )}
                        format={currency}
                      />
                    </Label>
                  </HStack>
                  <HStack className="items-center flex-1 gap-2 mb-8">
                    <Label className="text-sm font-medium whitespace-nowrap">
                      Dilution (&#37;):
                    </Label>
                    <Label className="ml-2 text-sm font-medium text-orange-500 whitespace-nowrap">
                      {
                        <FormatNumberSpan
                          value={Number(
                            ((data.headers?.roundSize || 0) /
                              ((data.headers?.valuation || 0) +
                                (data.headers?.roundSize || 0))) *
                              100 || 0.0
                          )}
                          format={currency}
                        />
                      }
                    </Label>
                    &#37;
                  </HStack>
                </HStack>
                <HStack className="justify-between">
                  <SecondaryCTAButton
                    event-name="Round Details Discard"
                    buttonSize={ButtonSize.SMALL}
                    onClick={() => {
                      disCardRoundCreation(data);
                    }}
                  >
                    Discard
                  </SecondaryCTAButton>
                  <PrimaryCTAButton
                    event-name="Round Details Next"
                    loading={data.isLoader}
                    buttonSize={ButtonSize.SMALL}
                    type="submit"
                  >
                    Next
                  </PrimaryCTAButton>
                </HStack>
                <AutoSubmitToken />
              </Form>
            )}
          </Formik>
        </VStack>
      </div>
    </VStack>
  );
}
