import { Icon } from "@iconify/react";
import { useState } from "react";
import { HStack, VStack } from "../../components/utils";
import Tooltip from "../../components/shared/Tooltip";
import { useRoundModelStore } from "../../store/roundModelingStore";

interface StepperModel {
  heading: string;
  description: string;
  subHeading: string[];
}

function RoundModelingStepper({ templateNumber }: { templateNumber: number }) {
  const [expanded, setExpanded] = useState(false);
  const { roundCreation: data, sendMessage: onNext } = useRoundModelStore();

  const stepper: StepperModel[] = [
    {
      heading: "Round Details",
      description: "",
      subHeading: [],
    },
    {
      heading: "Outstanding Convertible",
      description: "",
      subHeading: [],
    },
    {
      heading: "Existing",
      description: "",
      subHeading: [],
    },
    {
      heading: "New",
      description: "",
      subHeading: [],
    },
    {
      heading: "Secondary",
      description: "",
      subHeading: [],
    },
    {
      heading: "Summary",
      description: "",
      subHeading: [],
    },
  ];

  const updateCurrentTab = (num: number) => {
    onNext({
      ...data,
      currentTab: num,
      isNavigating: true,
    });
  };

  const [isNavigation, setIsNavigation] = useState(false);

  return (
    <>
      <div
        className={`ease-in placeholderLoader shadow-box h-[450px] border border-borderColor bg-green rounded-md duration-300 block ${
          expanded || isNavigation ? "lg:w-64 min-w-64" : "lg:w-12"
        } lg:overflow-y-auto overflow-x-hidden lg:bg-white px-3`}
      >
        <div className="flex items-center justify-start h-8 gap-2 cursor-pointer shrink-0">
          {!isNavigation && (
            <Icon
              onClick={() => {
                setIsNavigation(true);
              }}
              icon="material-symbols:lock-open-outline-rounded"
              width={20}
              className="text-[#E75935]"
              height={20}
            />
          )}
          {isNavigation && (
            <Icon
              onClick={() => {
                setIsNavigation(false);
              }}
              icon="ic:outline-lock"
              width={20}
              className="text-gray-400"
              height={20}
            />
          )}
        </div>
        <nav
          onMouseEnter={() => {
            setExpanded(true);
          }}
          onMouseLeave={() => {
            setExpanded(false);
          }}
        >
          <ul className="pt-6">
            <div className="h-16">
              <StepperUi
                expanded={expanded || isNavigation}
                onNext={updateCurrentTab}
                templateNumber={templateNumber}
                index={1}
                highestTab={data.highestVisitedTabLevel ?? 4}
                stepperData={stepper[0]}
              />
            </div>
            {data?.convertibles?.convertibles.length! > 0 && (
              <div className="h-16">
                <StepperUi
                  expanded={expanded || isNavigation}
                  highestTab={data.highestVisitedTabLevel ?? 1}
                  onNext={updateCurrentTab}
                  templateNumber={templateNumber}
                  index={2}
                  stepperData={stepper[1]}
                />
              </div>
            )}
            <div className="h-16">
              <StepperUi
                expanded={expanded || isNavigation}
                highestTab={data.highestVisitedTabLevel ?? 1}
                onNext={updateCurrentTab}
                templateNumber={templateNumber}
                index={3}
                stepperData={stepper[2]}
              />
            </div>
            <div className="h-16">
              <StepperUi
                expanded={expanded || isNavigation}
                highestTab={data.highestVisitedTabLevel ?? 1}
                onNext={updateCurrentTab}
                templateNumber={templateNumber}
                index={4}
                stepperData={stepper[3]}
              />
            </div>
            <div className="h-16">
              <StepperUi
                expanded={expanded || isNavigation}
                highestTab={data.highestVisitedTabLevel ?? 1}
                onNext={updateCurrentTab}
                templateNumber={templateNumber}
                index={5}
                stepperData={stepper[4]}
              />
            </div>
            <div className="h-16">
              <StepperUi
                expanded={expanded || isNavigation}
                highestTab={data.highestVisitedTabLevel ?? 1}
                onNext={updateCurrentTab}
                templateNumber={templateNumber}
                index={6}
                stepperData={stepper[5]}
              />{" "}
            </div>
          </ul>
        </nav>
      </div>
    </>
  );
}

export default RoundModelingStepper;

function StepperUi({
  templateNumber,
  expanded,
  highestTab,
  index,
  stepperData,
  onNext,
}: {
  templateNumber: number;
  expanded: boolean;
  highestTab: number;
  index: number;
  stepperData: StepperModel;
  onNext: (num: number) => void;
}) {
  return (
    <HStack className="items-center py-4 cursor-pointer">
      <div className="w-4 h-8">
        {templateNumber === index ? (
          <>
            <Icon
              icon="material-symbols:circle-outline"
              className="w-4 h-4 cursor-pointer text-orange-501 animate-pulse"
            />
          </>
        ) : templateNumber < index && highestTab < index ? (
          <>
            <Icon
              icon="material-symbols:circle-outline"
              className="w-4 h-4 text-gray-300 cursor-not-allowed"
            />
          </>
        ) : templateNumber >= index || highestTab >= index ? (
          <>
            <Icon
              icon="charm:tick"
              className="w-4 h-4 cursor-pointer text-orange-501"
            />
          </>
        ) : (
          <>
            <Icon
              icon="material-symbols:circle-outline"
              className="w-4 h-4 cursor-pointer text-orange-501"
            />
          </>
        )}
      </div>
      {expanded && (
        <Tooltip
          _className="w-32"
          text={
            templateNumber <= index && highestTab < index
              ? "Please follow the modeling steps to unlock"
              : null
          }
        >
          <VStack
            onClick={() =>
              templateNumber <= index && highestTab < index
                ? () => {}
                : onNext(index)
            }
            className={`align-top h-8 min-w-fit ${
              templateNumber <= index && highestTab < index
                ? "cursor-not-allowed"
                : "cursor-pointer"
            }`}
          >
            <p
              className={`${
                templateNumber === index
                  ? "font-semibold text-sm  text-orange-501 pl-2 whitespace-nowrap"
                  : templateNumber <= index && highestTab < index
                  ? "font-semibold text-sm  text-gray-300 pl-2 whitespace-nowrap"
                  : "font-semibold text-sm  text-gray-dark pl-2 whitespace-nowrap"
              }`}
            >
              {stepperData.heading}
            </p>
          </VStack>
        </Tooltip>
      )}
    </HStack>
  );
}
