import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CellClickedEvent, ColDef } from "ag-grid-community";
import { useNavigate } from "react-router";
import { Dialog } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import { Action, CTADropdown } from "../../components/shared/Dropdown";
import { agConfigDashboardSideBar } from "../dashboardPage/AgGridConfig";
import {
  getCurrencySymbol,
  getCurrencyType,
} from "../../utils/currencyFormatter";
import { Box, HStack, useGetCompanyName } from "../../components/utils";
import GenericTableHeader from "../../shared/TableHeader";
import { IconCTAButton } from "../quickRound/CTAButtonComponents";
import QuickSearch from "../rounds/QuickSearch";
import { useAuthStore } from "../../store";
import {
  useGetOnFilterState,
  usePostOnFilterState,
} from "../dashboardPage/AgGridCacheQuery";
import { tableCellStyle } from "../../components/TableComponent";
import { ExportImport } from "../Utility/GrantsTable";
import {
  exportIndividualSecuritySummaryReport,
  exportSecurityBasedReport,
  exportSecuritySummaryReport,
} from "../../api/Report";
import { downloadExcel } from "../../utils/DownloadFile";
import { SecurityDetail, SecurityTableDetail } from "../../types/SecurityModel";
import {
  AmountInvestedRender,
  NumberOfSharesRender,
  PARValueRender,
  RoundsRender,
  SecurityClassRender,
  SecurityTypeRender,
  ShareHolderRender,
} from "./SecurityClassAgComponent";
import {
  convertibleSecurities,
  redeemableSecurities,
} from "../../constants/SecurityConstantContent";
import { useCanUserAccess } from "../../store/useRoleGrantStore";
import { AddSecurityConversion } from "../../modals/AddSecurityConversion";
import { AddSecurityRedemption } from "../../modals/AddSecurityRedemption";
import AddNewSecurity from "./AddNewSecurity";
import { useAgGridTableState } from "../../store/agGridTableStore";
import SearchInput from "../../components/shared/SearchInput";
import {
  RoundsToolTip,
  ShareholderToolTip,
} from "../dashboardPage/customTooltip";
import { handleEventForTracking } from "../../amplitudeAnalytics";

function AGGridSecurityTypeTable({
  isInvestorPortal,
  securityType,
  cid,
  token,
  securityList,
}: {
  cid: string;
  token: string;
  isInvestorPortal?: boolean;
  securityType: string;
  securityList: SecurityDetail[];
}) {
  const currencySymbol = getCurrencySymbol();
  const companyName = useGetCompanyName();
  const companyId = useAuthStore().companyId ?? "";
  const userId = useAuthStore().user?.userId ?? 0;
  const gridApi = useRef<any>(null);
  const { refetch, data: filterState } = useGetOnFilterState(
    `${userId}`,
    companyId
  );

  const displayedRowCount = gridApi.current?.api.getDisplayedRowCount();

  const agTableStore = useAgGridTableState();
  useEffect(() => {
    refetch().then((data) => {
      if (data.data) agTableStore.setState(data.data);
      setColumnSetting(data.data?.securitiesTypeColumnModel);
      setFilterSetting(data.data?.securitiesTypeFilterModel);
    });
  }, []);

  const { mutate: postOnFilter } = usePostOnFilterState();

  function getColumnSetting() {
    if (gridApi.current) return gridApi.current.columnApi?.getColumnState();
    return {};
  }

  function setColumnSetting(model: any) {
    if (gridApi.current)
      gridApi.current.columnApi.applyColumnState({ state: model });
    return {};
  }

  // Gets filter model via the grid API
  const getFilterSetting = () => {
    if (gridApi.current) return gridApi.current.api?.getFilterModel();
    return {};
  };

  const setFilterSetting = (model: any) => {
    if (gridApi.current) return gridApi.current.api?.setFilterModel(model);
    return {};
  };

  const uploadFilterAndColumn = async () => {
    const columnState = await getColumnSetting();
    const filterState = await getFilterSetting();
    postOnFilter({
      userId: `${userId}`,
      companyId,
      filterData: {
        ...agTableStore.state,
        securitiesTypeColumnModel: columnState,
        securitiesTypeFilterModel: filterState,
      },
    });
  };

  const cin = "";

  const componentsRegistery = useMemo(
    () => ({
      securityClass: memo(SecurityClassRender),
      securityType: memo(SecurityTypeRender),
      rounds: memo(RoundsRender),
      shareholder: memo(ShareHolderRender),
      numberOfShares: memo(NumberOfSharesRender),
      parValue: memo(PARValueRender),
      amountInvested: memo(AmountInvestedRender),
    }),
    []
  );

  async function handleExportAction(action: Action) {
    if (action.name === "Export Summary Report") {
      exportSecuritySummaryReport().then((res) => {
        const fileName = `Security Summary Report_${companyName}`;
        downloadExcel(res, fileName);
      });
    } else if (action.name === "Export Details Report") {
      exportSecurityBasedReport("").then((res) => {
        const fileName = `Security Details Report_${companyName}`;
        downloadExcel(res, fileName);
      });
    } else if (action.name === "Export Security Report") {
      exportIndividualSecuritySummaryReport(securityType).then((res) => {
        const fileName = `Security Details Report_${companyName}`;
        downloadExcel(res, fileName);
      });
    }
  }

  const navigate = useNavigate();

  const onClickSecurityType = (type: string) => {
    if (cid) {
      handleEventForTracking({ eventName: "Inv Security Name" });
      navigate(`/inv/security/individual/${type}/${cid}/${token}`);
    } else {
      handleEventForTracking({ eventName: "Security Name" });
      navigate(`/security/individual/${type}`);
    }
  };

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      autoHeight: true,
      wrapHeaderText: true,
      suppressColumnVirtualisation: true,
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      filterParams: {
        buttons: ["reset"],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
      flex: 1,
    }),
    []
  );
  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        headerName: "Security Type",
        field: "securityType",
        filter: "agSetColumnFilter",
        cellRenderer: "securityType",
        cellStyle: tableCellStyle,
        sortable: false,
        pinned: true,
        menuTabs: ["filterMenuTab"],
      },
      {
        headerName: "Round",
        field: "rounds",
        cellRenderer: "rounds",
        initialWidth: 250,
        cellStyle: tableCellStyle,
        sortable: false,
        tooltipField: "numberOfShares",
        tooltipComponent: RoundsToolTip,
        filter: "agSetColumnFilter",
        menuTabs: ["filterMenuTab"],
        valueGetter: (params) =>
          params.data.rounds?.map((value: any) => value.roundName),
        valueFormatter: (params) =>
          params.data.rounds?.map((value: any) => value.roundName),
      },

      {
        headerName: "Shareholder",
        field: "shareholder",
        cellRenderer: "shareholder",
        tooltipField: "shareholder",
        tooltipComponent: ShareholderToolTip,
        cellStyle: tableCellStyle,
        sortable: false,
        filter: false,
        menuTabs: [],
        hide: true,
      },

      {
        headerName: "No. of Securities",
        field: "numberOfShares",
        filter: "agNumberColumnFilter",
        cellRenderer: "numberOfShares",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipNumberOfShares",
      },
      {
        headerName: `Capital Value (${currencySymbol})`,
        field: "amountInvested",
        filter: "agNumberColumnFilter",
        cellRenderer: "amountInvested",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipAmountInvested",
      },
    ],
    []
  );
  const gridOptions = {
    suppressRowVirtualisation: true, // Add this line to disable row virtualization
    paginationAutoPageSize: false, // Optional: Set to false if using custom pagination
    suppressScrollOnNewData: true, // Optional: Set to true to prevent scrolling to the top on
  };
  const currencyType = getCurrencyType();

  const rowData = useMemo(
    () =>
      securityList?.map((template) => ({
        securityType: template.securityType,
        rounds: template.roundIdentifierDetails,
        shareholder: template?.shareholder,
        numberOfShares: template.noOfShares,
        tooltipNumberOfShares: template.noOfShares.toLocaleString(currencyType),
        amountInvested: template.capitalValue,
        cin: cid,
        tooltipAmountInvested:
          template.capitalValue.toLocaleString(currencyType),

        isInvestorPortal,
        currencySymbol,
        currencyType,
      })),
    [securityList]
  );

  function setTableHeight() {
    if (displayedRowCount === 1) {
      return (displayedRowCount + 1.8) * 60;
    } else if (displayedRowCount === 2) {
      return (displayedRowCount + 2.4) * 60;
    } else if (displayedRowCount === 3) {
      return (displayedRowCount + 3) * 60;
    } else if (displayedRowCount === 4) {
      return (displayedRowCount + 2) * 60;
    } else if (displayedRowCount === 5) {
      return (displayedRowCount + 1.8) * 60;
    } else if (displayedRowCount === 6) {
      return (displayedRowCount + 1.6) * 60;
    } else if (displayedRowCount === 7) {
      return (displayedRowCount + 1.4) * 60;
    } else if (displayedRowCount === 8) {
      return (displayedRowCount + 1.2) * 60;
    } else if (displayedRowCount === 9) {
      return (displayedRowCount + 1) * 60;
    } else {
      return 10 * 60;
    }
  }

  const handleCellClick = (cellParams: CellClickedEvent<any, any>) => {
    if (cellParams.column.getColId() !== "action-column") {
      const template = cellParams.data;
      if (
        cellParams.value.name !== "Total" &&
        cellParams.column.getColId() === "securityType"
      ) {
        onClickSecurityType(template.securityType);
      }
    }
  };
  const [isColumnOpen, setIsColumnOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const openToolPanel = (key: any) => {
    if (key === "columns") {
      if (gridApi) {
        if (!isColumnOpen) gridApi?.current?.api?.openToolPanel(key);
        else gridApi?.current?.api?.closeToolPanel();
        setIsColumnOpen((state) => !state);
        setIsFilterOpen(false);
      }
    } else if (key === "filters") {
      if (gridApi) {
        if (!isFilterOpen) gridApi?.current?.api?.openToolPanel(key);
        else gridApi?.current?.api?.closeToolPanel();
        setIsFilterOpen((state) => !state);
        setIsColumnOpen(false);
      }
    }
  };

  function setPinnedBottomRowData({ api }: { api: any }) {
    const data = api.rowModel.rootNode;
    const filteredData = data.childrenAfterAggFilter.map(
      (element: any) => element.data
    );
    const totalNumberOfShares = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + data.numberOfShares,
      0
    );

    const totalAmountInvested = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + data.amountInvested,
      0
    );

    api.setPinnedBottomRowData([
      {
        securityType: "Total",
        rounds: null,
        shareholder: null,
        numberOfShares: totalNumberOfShares,
        tooltipNumberOfShares: totalNumberOfShares.toLocaleString(currencyType),
        parValue: null,
        amountInvested: totalAmountInvested,
        tooltipAmountInvested: totalAmountInvested.toLocaleString(currencyType),
        actions: null,
        currencySymbol,
        currencyType,
      },
    ]);
  }

  const [filteredRowData, setFilteredRowData] = useState<any>([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const onAgGridFilterChanged = (grid: any) => {
    const filtersApplied = grid.api.isAnyFilterPresent();
    setIsFilterApplied(filtersApplied);

    const filteredData = grid.api
      .getModel()
      .rowsToDisplay.map((node: any) => node.data);
    setFilteredRowData(filteredData);
    setPinnedBottomRowData(grid);
    uploadFilterAndColumn();
  };

  return (
    <div className="bg-white border rounded-md border-borderColor shadow-box">
      <HStack className="flex-col items-start justify-start py-2 lg:flex-row lg:justify-between lg:items-center ">
        <GenericTableHeader
          heading={"Security Type Table"}
          subHeading={"Securities"}
          count={
            isFilterApplied === true ? filteredRowData.length : rowData.length
          }
        />
        <HStack className="items-center justify-between w-full gap-4 px-8 py-2 lg:w-auto lg:justify-end">
          <SearchInput
            placeholder={`Search`}
            onChange={(e: any) => {
              gridApi.current.api.setQuickFilter(e.target.value);
            }}
          />
          <HStack className="gap-4">
            {!isInvestorPortal && (
              <div>
                {securityType === "All" ? (
                  <ExportImport
                    actions={[
                      {
                        name: "Export Summary Report",
                      },
                      {
                        name: "Export Details Report",
                      },
                    ]}
                    onAction={(action) => handleExportAction(action)}
                  />
                ) : (
                  <ExportImport
                    actions={[
                      {
                        name: "Export Security Report",
                      },
                    ]}
                    onAction={(action) => handleExportAction(action)}
                  />
                )}
              </div>
            )}

            <IconCTAButton
              value={"Columns"}
              onClick={() => openToolPanel("columns")}
              iconName={"fluent:column-triple-edit-20-regular"}
              className={`px-4 font-medium items-center flex flex-row ${
                isColumnOpen ? "text-orange-501" : "text-gray-400"
              }`}
            />
            <IconCTAButton
              value={"Filters"}
              onClick={() => openToolPanel("filters")}
              iconName={"material-symbols:filter-alt"}
              className={`px-4 font-medium items-center flex flex-row ${
                isFilterOpen ? "text-orange-501" : "text-gray-400"
              }`}
            />
          </HStack>
        </HStack>
      </HStack>

      <HStack className="justify-between w-full">
        <Box
          style={{
            height: setTableHeight(),
          }}
          className="w-full h-full max-h-full overflow-x-auto ag-theme-material "
        >
          <AgGridReact
            sideBar={agConfigDashboardSideBar}
            onGridReady={(params) => {
              gridApi.current = params;
            }}
            components={componentsRegistery}
            alwaysShowHorizontalScroll
            alwaysMultiSort
            animateRows={true}
            enableCharts={true}
            enableRangeSelection={true}
            defaultColDef={defaultColDef}
            onRowDataUpdated={setPinnedBottomRowData}
            onFilterChanged={onAgGridFilterChanged}
            onColumnEverythingChanged={uploadFilterAndColumn}
            rowData={rowData}
            onCellClicked={handleCellClick}
            columnDefs={columnDefs}
            pagination={false}
            suppressRowTransform={true}
            suppressCopyRowsToClipboard={true}
            suppressCopySingleCellRanges={true}
            suppressCellFocus={true}
            suppressMenuHide={false}
            onColumnResized={uploadFilterAndColumn}
            tooltipShowDelay={1000}
            tooltipInteraction={true}
            rowClass={
              "border-t border-dashed cursor-pointer hover:bg-slate-50 "
            }
            overlayNoRowsTemplate={
              '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow; margin-top: 50px;">No Rows To Show</span>'
            }
            gridOptions={gridOptions}
            getRowStyle={(params) => {
              if (params.rowIndex % 2 === 0) {
                return { background: "#f8f8f8" };
              } else {
                return { background: "#ffffff" };
              }
            }}
          ></AgGridReact>
        </Box>
      </HStack>
    </div>
  );
}

export default AGGridSecurityTypeTable;
