import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router";
import { useState } from "react";
import { toast } from "react-toastify";
import _ from "lodash";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { HStack, VStack, Error } from "../../components/utils";
import { Input, Label } from "../../components/shared/InputField";
import {
  SecondaryCTAButton,
  PrimaryCTAButton,
} from "../quickRound/CTAButtonComponents";
import { useApproveTransactionPreview } from "../../queries/transactionRound";
import { queryClient } from "../../queries/client";

type AddCloseDateModalProps = {
  onPrimaryAction: () => void;
  onSecondaryAction: () => void;
  data: any;
  mode: string;
  onClose: () => void;
  handleOnSuccessDiscard: Function;
  resetTemplate: Function;
  closeDate: string;
};

type TypeSharesPerSecurity = {
  instrumentId: string;
  instrumentName: string;
  noOfShares: number;
  investorId: string;
};

type closeDateModel = {
  closeDate: string;
};

const OverrideCloseDatePopup = ({
  onPrimaryAction = () => {},
  onSecondaryAction = () => {},
  data,
  handleOnSuccessDiscard,
  resetTemplate,
  closeDate,
}: AddCloseDateModalProps) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const { mutate: approveTransaction } = useApproveTransactionPreview();

  const handleSubmit = (values: closeDateModel) => {
    setLoading(true);
    approveTransaction(
      { ...data, closeDate: values.closeDate },
      {
        onSuccess: (data) => {
          handleOnSuccessDiscard();
          setLoading(false);
          queryClient.invalidateQueries("getAllEvents");
          queryClient.invalidateQueries("getAllTransactions");
          queryClient.invalidateQueries("getInstrumentAndShareHolder");
          queryClient.invalidateQueries("getSecurityOverviewDetails");
          queryClient.invalidateQueries("getShareholderOverview");

          resetTemplate();
          navigate("/landing/dashboard");
          toast("Transaction Added Successfully!", {
            type: "success",
            autoClose: 2000,
          });
        },

        onError: (err: any) => {
          setLoading(false);
          toast(err.response.data.reason, {
            type: "error",
            autoClose: 2000,
          });
        },
      }
    );

    onPrimaryAction();
  };

  const validationSchema = Yup.object().shape({
    closeDate: Yup.string().required().label("Close Date"),
  });

  const initialValues: closeDateModel = {
    closeDate: closeDate || "",
  };

  return (
    <VStack>
      <HStack className="justify-between px-6 py-4 border-b">
        <p className="text-base font-medium">Confirm Close Date</p>
        <XMarkIcon className="w-6 h-6" onClick={() => onSecondaryAction()} />
      </HStack>

      <div className="px-4 py-4 bg-white rounded-lg">
        <VStack className="justify-between">
          <Formik
            key="closeDate"
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            {(formik) => (
              <Form key="closeDate">
                <VStack className="">
                  <Label className="w-48 text-sm font-medium whitespace-nowrap">
                    Close Date
                  </Label>
                  <VStack>
                    <Input
                      type="date"
                      className="w-60"
                      placeholder="12-12-2022"
                      {...formik.getFieldProps("closeDate")}
                    />
                    {formik.touched?.closeDate && formik.errors?.closeDate && (
                      <Error text={formik.errors?.closeDate} />
                    )}
                  </VStack>
                </VStack>
                <HStack className="justify-between mt-10">
                  <SecondaryCTAButton
                    event-name="Cancel Close date Modal"
                    onClick={() => onSecondaryAction()}
                    type="reset"
                    className="text-red-500"
                  >
                    Cancel
                  </SecondaryCTAButton>
                  <PrimaryCTAButton
                    loading={loading}
                    event-name="Save Close date Modal"
                    type="submit"
                  >
                    Approve
                  </PrimaryCTAButton>
                </HStack>
              </Form>
            )}
          </Formik>
        </VStack>
      </div>
    </VStack>
  );
};

export default OverrideCloseDatePopup;
