import {
  Combobox,
  ComboboxOption,
  ComboboxOptions,
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import { toast } from "react-toastify";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { useMemo, useState } from "react";
import { Box, HStack, VStack } from "../../components/utils";
import {
  ButtonSize,
  PrimaryCTAButton,
} from "../quickRound/CTAButtonComponents";
import EmailInputBox from "./multiEmail";
import Avatar from "../Utility/Avatar";
import EmptyTableIllustration from "../../shared/EmptyTableIllustration";
import SelectInputUI from "./SelectPop";
import {
  useGetSharedUserAndInitializeShare,
  useSubmitSharedEmail,
  useUpdateResourceLive,
} from "../../queries/share";
import { useAuthStore } from "../../store";
import { SwitchButton } from "../../components/shared/SwitchButton";

export type SharedUserModel = {
  accessExpiry: string;
  email: string;
  id: string;
  permission: string;
  sharedBy: string;
};

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export default function ShareModal({
  resourceId,
  resourceType,
  close,
}: {
  resourceId: string;
  resourceType: string;
  close: () => void;
}) {
  const [query, setQuery] = useState("");
  const [copied, setCopied] = useState(false);
  const [input, setInput] = useState<string[]>([]);
  const [open, setOpen] = useState(true);
  const [sharedUser, setSharedUser] = useState<
    {
      id: string;
      name: string;
    }[]
  >([]);
  const email = useAuthStore().user?.emailId ?? "";
  const companyId = useAuthStore().companyId ?? "";
  const { data } = useGetSharedUserAndInitializeShare(resourceId, resourceType);
  const { mutate: submitSharedEmail } = useSubmitSharedEmail();
  const sharedUserData: SharedUserModel[] = useMemo(
    () => (data ? data.users ?? [] : []),
    [data]
  );

  const { mutate: updateResourceLive } = useUpdateResourceLive();
  const [invited, setInvited] = useState(false);
  function validateEmail(email: any) {
    // Regular expression pattern for email validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Test the email against the pattern
    return emailPattern.test(email);
  }

  return (
    <Transition show={open} afterLeave={() => setQuery("")} appear>
      <Dialog
        className="relative z-10"
        onClose={() => {
          setOpen(false);
          close();
        }}
      >
        <TransitionChild
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-25" />
        </TransitionChild>

        <div className="fixed inset-0 z-10 w-screen p-4 overflow-y-auto sm:p-6 md:p-20">
          <TransitionChild
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <DialogPanel className="max-w-3xl mx-auto overflow-hidden transition-all transform bg-white divide-gray-100 shadow-2xl rounded-xl ring-1 ring-black ring-opacity-5">
              <Box className="px-6 py-4 text-lg font-medium border-b">
                <div className="flex justify-between">
                  <h2 className="text-sm font-semibold text-gray-500">
                    Share this page
                  </h2>

                  <XMarkIcon
                    className="h-5 font-medium cursor-pointer"
                    onClick={() => {
                      setOpen(false);
                      close();
                    }}
                  />
                </div>
              </Box>

              <Combobox>
                <HStack className="relative items-center gap-4 px-4 mt-2 mb-1">
                  <HStack className="flex-1">
                    <EmailInputBox
                      input={input ?? []}
                      onChange={(data: any) => {
                        setInput(data);
                      }}
                    />
                  </HStack>
                  <PrimaryCTAButton
                    loading={invited}
                    loadingText="Inviting"
                    disabled={input.length <= 0}
                    buttonSize={ButtonSize.MEDIUM}
                    className="items-center px-4"
                    onClick={() => {
                      setInvited(true);
                      const isValidEmail = true;

                      // input.map((ele: any) => {
                      //   if (!validateEmail(ele)) isValidEmail = false;
                      // });
                      if (isValidEmail) {
                        submitSharedEmail(
                          {
                            users: input,
                            shareId: data.id,
                            resourceType,
                          },
                          {
                            onSuccess: () => {
                              setInvited(false);
                              toast("Invite Sent successfully!", {
                                type: "success",
                                autoClose: 2000,
                              });
                              setInput([]);
                            },
                            onError: () => {
                              setInvited(false);
                              toast("Something went wrong", {
                                type: "error",
                                autoClose: 2000,
                              });
                            },
                          }
                        );
                      } else {
                        setInvited(false);
                        toast("Something went wrong! please check mail", {
                          type: "error",
                          autoClose: 2000,
                        });
                      }
                    }}
                  >
                    Invite
                  </PrimaryCTAButton>
                </HStack>
                <p className="px-4 text-gray-400 text-xxs leading-2">
                  Please press enter or space after entering email
                </p>
                {(query === "" || sharedUserData.length > 0) && (
                  <ComboboxOptions
                    static
                    as="ul"
                    className="overflow-y-auto divide-y divide-gray-100 max-h-80 scroll-py-2"
                  >
                    <li className="p-2">
                      {query === "" && (
                        <h2 className="px-3 mt-4 mb-2 text-xs font-semibold text-gray-500">
                          User&apos;s having access
                        </h2>
                      )}
                      <ul className="text-sm text-gray-700">
                        {sharedUserData.length > 0 ? (
                          sharedUserData.map((user) => (
                            <ComboboxOption
                              as="li"
                              key={user.id}
                              value={user}
                              className={({ focus }) =>
                                classNames(
                                  "flex cursor-default select-none items-center rounded-md px-3 py-2",
                                  focus && "bg-secondary/5 text-secondary"
                                )
                              }
                            >
                              {({ focus }) => (
                                <>
                                  <Avatar name={user.email} />
                                  <span className="flex-auto ml-3 truncate">
                                    {user.email}
                                  </span>

                                  <SelectInputUI
                                    value={user}
                                    shareId={data.id}
                                    resourceType={resourceType}
                                  />
                                </>
                              )}
                            </ComboboxOption>
                          ))
                        ) : (
                          <VStack>
                            <div className="">
                              <EmptyTableIllustration
                                text={"Not Shared with anyone yet"}
                              />
                            </div>
                          </VStack>
                        )}
                        {sharedUserData.length !== 0 && (
                          <HStack className="items-center justify-between px-4 py-4 mt-8">
                            <h2 className="mr-2 text-xs font-semibold text-gray-500 ">
                              Link:{" "}
                              <a
                                href={`${process.env.REACT_APP_URL}/share/${data.id}/`}
                                target="_blank"
                                rel="noreferrer"
                                className="underline"
                              >{`${process.env.REACT_APP_URL}/share/${data.id}/`}</a>
                            </h2>
                            {!copied ? (
                              <span
                                onClick={async () => {
                                  setCopied(true);
                                  await navigator.clipboard.writeText(
                                    `${process.env.REACT_APP_URL}/share/${data.id}/`
                                  );
                                  setTimeout(() => {
                                    setCopied(false);
                                  }, 2000);
                                }}
                                className="inline-flex cursor-pointer items-center gap-x-0.5 rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-500"
                              >
                                Copy
                              </span>
                            ) : (
                              <span className="inline-flex cursor-pointer  items-center gap-x-0.5 rounded-md text-blue-100 px-2 py-1 text-xs font-medium bg-blue-500">
                                Copied
                              </span>
                            )}
                            <HStack className="flex-row-reverse items-center flex-1 gap-4 pb-1">
                              <SwitchButton
                                className={`font-medium whitespace-nowrap text-xs h-4 pt-1`}
                                value={data.isLive}
                                toggleClassName={"h-2 w-2"}
                                label={"Link Active"}
                                onClick={() => {
                                  updateResourceLive({
                                    resource: { isLive: !data.isLive },
                                    shareId: data.id,
                                  });
                                }}
                              />
                            </HStack>
                          </HStack>
                        )}
                      </ul>
                    </li>
                  </ComboboxOptions>
                )}
              </Combobox>
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  );
}
