import React from "react";
import { Icon } from "@iconify/react";
import { HStack, VStack } from "./utils";
import { getCurrencySymbolRoboto } from "../utils/currencyRoboto";

export function TableHeader(
  props: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLTableRowElement>,
    HTMLTableRowElement
  >
) {
  const { className: _className, ...rest } = props;
  const className = `${_className} text-center font-medium text-xs2 text-[#002685] whitespace-nowrap`;
  return <tr {...rest} className={className}></tr>;
}

export const tableEntryStyle = "text-left font-medium text-xs2 text-[#464E5F]";
export const tableCellStyle = { "padding-top": "8px", "line-height": "20px" };
export const tableFirstColStyle =
  "text-left font-semibold text-xs2 text-[#464E5F]";

export function THead({
  fieldName,
  fieldDisplayName,
  isAscending,
  selectedFieldName,
  onChangeSort,
  className,
  isFirstFeild = false,
  showCurrency = false,
  showSortingIcon = true,
  isColumnFilter = true,
  data,
}: {
  fieldName?: any;
  isFirstFeild?: boolean;
  className?: string;
  fieldDisplayName: string;
  isAscending?: boolean;
  showCurrency?: boolean;
  showSortingIcon?: boolean;
  selectedFieldName?: string;
  data?: any;
  isColumnFilter?: boolean;
  onChangeSort?: () => void;
}) {
  const _className = `font-medium ${
    isFirstFeild ? "" : "px-3"
  } py-3.5 ${className} `;
  const symbol = getCurrencySymbolRoboto();
  return (
    <th className={_className}>
      <HStack
        className={`items-center ${
          isFirstFeild ? "justify-start" : "justify-center"
        }`}
      >
        {showCurrency ? (
          <p className="whitespace-no-wrap">
            {fieldDisplayName} ({symbol})
          </p>
        ) : (
          <p className="whitespace-no-wrap">{fieldDisplayName}</p>
        )}

        {showSortingIcon && (
          <VStack className="ml-2 hover:cursor-pointer" onClick={onChangeSort}>
            <Icon
              icon="humbleicons:triangle"
              className={`hover:cursor-pointer ${
                fieldName === selectedFieldName && isAscending
                  ? "text-orange-501 scale-125"
                  : "text-gray-400"
              } rounded `}
              height={8}
              width={10}
            />
            <Icon
              icon="humbleicons:triangle"
              className={`hover:cursor-pointer ${
                fieldName === selectedFieldName && !isAscending
                  ? "text-orange-501 scale-125"
                  : "text-gray-400"
              } rounded`}
              height={8}
              rotate={90}
              width={10}
            />
          </VStack>
        )}
        {/*Dirty Commit {isColumnFilter && (
          <InColumnRangeFilters
            primaryAction={() => {}}
            secondaryAction={() => {}}
            name={fieldName}
            type={""}
            data={data}
          />
        )} */}
      </HStack>
    </th>
  );
}
