import create from "zustand";
import { configurePersist } from "zustand-persist";

const { persist: _persist } = configurePersist({
  storage: localStorage,
  rootKey: "root",
});

const persist = _persist as any;

type RightsState = {
  rightsId: string;
  setRightsId: (text: string) => void;
  reset: () => void;
};

const initialState = {
  rightsId: "",
};

const rightsStore = create<RightsState>(
  persist(
    {
      key: "authorization",
    },
    (set: any) => ({
      ...initialState,
      setRightsId: (text: string) => {
        set((state: any) => ({ rightsId: text }));
      },
      reset: () => {
        set(() => initialState);
      },
    })
  )
);

export const useRightsStore = rightsStore;
