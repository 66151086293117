import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { clearCredentials } from "../../components/utils";
import { useAuthorizationStore, useAuthStore } from "../../store";
import { useRMCreationStore } from "../../store/roundCreationStore";

const CaptableLandingPage = () => {
  const { id, token } = useParams() as { id: string; token: string };
  const { setAuth, clear } = useAuthStore();
  const navigate = useNavigate();
  const { clearAuthorization } = useAuthorizationStore();
  const { roundCreation: data, reset: discardRoundCreation } =
    useRMCreationStore();

  useEffect(() => {
    if (id && token) {
      discardRoundCreation(data);
      clearAuthorization();
      clearCredentials();

      toast("Logged In Successfully!", {
        type: "success",
        autoClose: 2000,
      });

      setAuth({
        isAuthenticated: true,
        accessToken: `Bearer ${token}`,
        companyId: id,
        user: undefined,
      });
      navigate("/landing/dashboard");
    } else {
      navigate("/login");
    }
  }, [id]);

  return <div></div>;
};

export default CaptableLandingPage;
