import create from "zustand";
import { configurePersist } from "zustand-persist";
import { IndividualRoundsModel } from "../types/AllTransactionModel";
import { SecurityConversionUpdateDetails } from "../types/SecurityModel";

const { persist: _persist } = configurePersist({
  storage: localStorage,
  rootKey: "root",
});

const persist = _persist as any;

type ConversionState = {
  rounds: IndividualRoundsModel[];
  eventId: string;
  index: number;
  conversionDetails: SecurityConversionUpdateDetails[];
  setRounds: (rounds: IndividualRoundsModel[]) => void;
  setEventId: (eventId: string) => void;
  setIndex: (index: number) => void;
  setConversionDetails: (
    conversionDetails: SecurityConversionUpdateDetails[]
  ) => void;
  reset: () => void;
};

const initialConversionState = {
  rounds: [],
  eventId: "",
  index: 0,
  conversionDetails: [],
};

const conversionStore = create<ConversionState>(
  persist(
    {
      key: "conversion",
    },
    (set: any) => ({
      ...initialConversionState,
      setRounds: (rounds: IndividualRoundsModel[]) => {
        set((state: any) => ({
          ...state,
          rounds,
        }));
      },

      setEventId: (eventId: string) => {
        set((state: any) => ({ ...state, eventId }));
      },
      setIndex: (index: number) => {
        set((state: any) => ({ ...state, index }));
      },
      setConversionDetails: (
        conversionDetails: SecurityConversionUpdateDetails[]
      ) => {
        set((state: any) => ({ ...state, conversionDetails }));
      },
      reset: () => {
        set(() => initialConversionState);
      },
    })
  )
);

export const useConversionStore = conversionStore;
