import { useFormikContext } from "formik";
import { Disclosure } from "@headlessui/react";
import { useEffect, useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { SwitchButton } from "../../components/shared/SwitchButton";
import { HStack, VStack, Error } from "../../components/utils";
import { Input, Label } from "../../components/shared/InputField";
import { EditSecurityModel } from "../../types/SecurityModel";
import {
  Security,
  SecurityTextDetail,
} from "../../constants/SecurityConstantContent";
import SecurityDescriptionText from "./SecurityDescriptionText";
import { Select } from "../../components/shared/Select";
import { classNames } from "../../utils/string";
import { ShowNumberInWords } from "../../components/shared/UiElement";
import { getCurrencyType } from "../../utils/currencyFormatter";
import {
  convertToNumber,
  filterOnlyNumbers,
  numberWithCommas,
} from "../../utils/numUtils";

const AddFeaturesDetail = ({
  keepOpen,
  viewOnly,
  currencySymbol,
}: {
  keepOpen: boolean;
  viewOnly: boolean;
  currencySymbol: string;
}) => {
  const formikk = useFormikContext<EditSecurityModel>();
  const { values, errors, touched, getFieldProps, setFieldValue } = formikk;
  const [onHoverPar, setOnHoverPar] = useState(false);
  const currencyType = getCurrencyType();
  const [parValue, setParValue] = useState(``);
  useEffect(() => {
    if (formikk.values.featureParValue)
      setParValue(`${formikk.values.featureParValue}`);
  }, [formikk.values.featureParValue]);
  return (
    <Disclosure as="div" key="Features" className="" defaultOpen={keepOpen}>
      {({ open }: any) => (
        <>
          <dt
            className={`text-lg border ${
              open ? "border-b-0 rounded-t-md" : "rounded-md"
            } p-4`}
          >
            <Disclosure.Button className="flex items-start justify-between w-full text-left text-gray-400">
              <span className="text-base font-medium text-slate-900">
                Features
              </span>
              <span className="flex items-center ml-6 h-7">
                <ChevronDownIcon
                  className={classNames(
                    open ? "-rotate-180" : "rotate-0",
                    "h-6 w-6 transform text-slate-500"
                  )}
                  aria-hidden="true"
                />
              </span>
            </Disclosure.Button>
          </dt>
          <Disclosure.Panel as="dd" className="p-4 border rounded-b-md ">
            <VStack className="gap-8">
              <SecurityDescriptionText
                description={SecurityTextDetail.Features}
              />
              {values.securityType !== Security.Note && (
                <HStack className="flex-col justify-between gap-4 md:flex-row">
                  {values.securityType !== Security.Note ? (
                    <VStack className="items-start flex-1">
                      <Label className="mr-4 text-sm font-medium text-slate-900 whitespace-nowrap">
                        PAR Value ({currencySymbol})
                      </Label>
                      <Input
                        placeholder="Enter PAR Value"
                        {...getFieldProps("featureParValue")}
                        type="text"
                        onMouseEnter={() => {
                          setOnHoverPar(true);
                        }}
                        onMouseLeave={() => {
                          setOnHoverPar(false);
                        }}
                        onChange={(e: any) => {
                          const filteredValue = filterOnlyNumbers(
                            e.target.value
                          );
                          setParValue(filteredValue);
                          const values = convertToNumber(filteredValue);
                          formikk.setFieldValue("featureParValue", values);
                          formikk.handleChange("featureParValue");
                        }}
                        value={numberWithCommas(parValue, currencyType)}
                        disabled={viewOnly}
                      />
                      {onHoverPar && (
                        <ShowNumberInWords
                          value={formikk.values.featureParValue!}
                          width={38}
                          currency={currencyType}
                        />
                      )}

                      {touched?.featureParValue && errors?.featureParValue && (
                        <Error text={errors?.featureParValue} />
                      )}
                    </VStack>
                  ) : (
                    <div className="flex-1"></div>
                  )}
                  {values.securityType === Security.Equity ||
                  values.securityType === Security.CCPS ||
                  values.securityType === Security.OCPS ||
                  values.securityType === Security.NCD ||
                  values.securityType === Security.CCD ||
                  values.securityType === Security.OCD ||
                  values.securityType === Security.RPS ? (
                    <VStack className="items-start flex-1">
                      <Label className="mr-4 text-sm font-medium text-slate-900 whitespace-nowrap">
                        Voting Ratio
                      </Label>
                      {
                        <HStack className="items-center">
                          <HStack className="px-1 pt-1.5">
                            <p>1</p>
                            <p className="pl-1">:</p>
                          </HStack>
                          <Input
                            type="number"
                            placeholder="Enter Voting Ratio"
                            {...getFieldProps("featureVotingRatio")}
                            disabled={viewOnly}
                          />
                        </HStack>
                      }
                      {touched?.featureVotingRatio &&
                        errors?.featureVotingRatio && (
                          <Error text={errors?.featureVotingRatio} />
                        )}
                    </VStack>
                  ) : (
                    <div className="flex-1"></div>
                  )}
                </HStack>
              )}

              <HStack className="flex-col justify-between gap-4 md:flex-row">
                {values.securityType === Security.Note ? (
                  <VStack className="items-start flex-1">
                    <Label className="mr-4 text-sm font-medium text-slate-900 whitespace-nowrap">
                      Note Period (Years)
                    </Label>

                    <Input
                      type="number"
                      placeholder="Enter Note Period"
                      {...getFieldProps("featureNotePeriod")}
                      disabled={viewOnly}
                    />
                    {touched?.featureNotePeriod &&
                      errors?.featureNotePeriod && (
                        <Error text={errors?.featureNotePeriod} />
                      )}
                  </VStack>
                ) : (
                  <div className="flex-1"></div>
                )}
                <HStack className="flex-row-reverse items-center justify-end flex-1 gap-4 text-sm font-medium text-slate-900">
                  {values.securityType === Security.Note && (
                    <SwitchButton
                      value={values?.featureRepaymentPrePayment || false}
                      label="Repayment/Pre-Payment"
                      onClick={() => {
                        if (viewOnly) return;
                        setFieldValue(
                          `featureRepaymentPrePayment`,
                          !values?.featureRepaymentPrePayment
                        );
                      }}
                    />
                  )}
                </HStack>
              </HStack>
            </VStack>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default AddFeaturesDetail;
