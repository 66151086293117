import { tableEntryStyle } from "../../components/TableComponent";
import { getDougnutTagColor } from "../../constants/ShareholderConstants";
import { formatDisplayDate } from "../../utils/date";

export function DocumentNameRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-2">
      <p className={tableEntryStyle}>{data.data.documentName}</p>
    </div>
  );
}
export function FilterTypeRender(data: any) {
  return (
    <p
      className={`ml-4 px-2 py-1 text-xxs font-medium mb-2 w-fit h-fit rounded-sm ${
        data.data.financialType === "Consolidated"
          ? "bg-[#6904DC]/[0.1]"
          : "bg-[#2DA900]/[0.1]"
      } text-[${
        data.data.financialType === "Consolidated" ? "#6904DC" : "#2DA900"
      }]`}
    >
      {data.data.financialType}
    </p>
  );
}

export function YearRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-2">
      <p className={tableEntryStyle}>{data.data.year}</p>
    </div>
  );
}

export function TimelineRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-2">
      <p className={tableEntryStyle}>{data.data.timeline}</p>
    </div>
  );
}

export function UpdatedDateRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-2">
      <p className={tableEntryStyle}>{formatDisplayDate(data.data.date)}</p>
    </div>
  );
}
