import { QueryFunctionContext } from "react-query";
import api from "./capTableApi";
import { Type2CompanyPageModel } from "../types/T2CompanyModel";

export async function getType2CompanyOverviewDetails(
  context: QueryFunctionContext
): Promise<Type2CompanyPageModel> {
  const cin = context.queryKey[1];
  const token: string = (context.queryKey[2] as string) || "";
  const isPrivate = token !== "";
  return api
    .get(`v1/company/t2Company`, {
      params: {
        isPrivate,
        token,
        cin,
      },
    })
    .then((res) => res.data.data);
}
