import { useEffect } from "react";
import { toast } from "react-toastify";
import { HStack, VStack } from "../../components/utils";
import { Label } from "../../components/shared/InputField";
import { NewInvestorState, RoundCreationModel } from "./RoundCreationModel";
import { getCurrencyType } from "../../utils/currencyFormatter";
import ConversionCaptable from "./ConversionCaptable";
import CardDetailHeader from "./CardDetailHeader";
import { useRMCreationStore } from "../../store/roundCreationStore";
import {
  ButtonSize,
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../quickRound/CTAButtonComponents";
import { useRoundModelStore } from "../../store/roundModelingStore";
import {
  ConvertibleType,
  IndividualConvertiblesState,
  ModelBuilder,
  OutStandingConvertibleDetails,
} from "../../types/Modeling";
import AGGridConvertibleTable from "./ConvertibleAGgridTable";
import AGGridConversionCapTable from "./ConversionAgGridCaptable";
import GenericTableHeader from "../../shared/TableHeader";
import { queryClient } from "../../queries";

export default function Convertible() {
  const {
    roundCreation: data,
    setRoundCreation: setRoundState,
    sendMessage,
    reset: discardRoundCreation,
    connect,
    saveModel,
  } = useRoundModelStore();
  const currency = getCurrencyType();
  function scrollToTop() {
    const scroller = document.querySelector("#outlet");
    scroller?.scrollTo(0, 0);
  }

  const updateCurrentTab = (num: number) => {
    sendMessage({
      ...data,
      currentTab: num,
      isNavigating: true,
    });
  };

  function handleSubmit() {
    setRoundState({
      ...data,
      isNavigating: true,
    });

    sendMessage({
      ...data,
      currentTab: 3,
    } as ModelBuilder);

    scrollToTop();
  }

  return (
    <>
      <div className="py-4 bg-white rounded-lg ">
        <GenericTableHeader
          heading={"Outstanding Convertibles"}
          subHeading={"Settle the outstanding convertibles"}
        />
      </div>
      <VStack className="px-6 py-2 bg-white ">
        <AGGridConvertibleTable
          convertibleDetails={data?.convertibles?.convertibles ?? []}
          roundModel={data}
        />
        <div>
          <HStack className="gap-8 my-8 justify-evenly">
            {[
              {
                name: "New Post Money",
                value: data.convertibles?.newPostMoneyValuation || 0,
                currency,
              },
              {
                name: "New Pre Money",
                value: data.convertibles?.newPreMoneyValuation || 0,
                currency,
              },
              {
                name: "% Remaining for new investor",
                value:
                  (data.convertibles?.remainingHoldingForNewInvestor || 0) *
                  100,
              },
              {
                name: "Remaining Convertibles",
                value: data.convertibles?.remainingConvertibles || 0,
              },
            ].map((element) => (
              <CardDetailHeader
                key={element.name}
                name={element.name}
                value={element.value}
                currency={element.currency}
              />
            ))}
          </HStack>
        </div>

        {(data.convertibles?.convertiblesCaptable.investors ?? []).length >
          0 && (
          <AGGridConversionCapTable
            conversionCaptableDetails={
              data.convertibles?.convertiblesCaptable.investors ?? []
            }
          />
        )}

        <HStack className="justify-between gap-4 mt-8">
          <HStack className="gap-4">
            <SecondaryCTAButton
              buttonSize={ButtonSize.SMALL}
              onClick={() => {
                sendMessage({
                  ...data,
                  currentTab: 1,
                  isNavigating: true,
                });
                scrollToTop();
              }}
            >
              Back
            </SecondaryCTAButton>

            <SecondaryCTAButton
              buttonSize={ButtonSize.SMALL}
              onClick={() => updateCurrentTab(3)}
            >
              Skip
            </SecondaryCTAButton>
          </HStack>
          <HStack>
            <div className="justify-end pr-4 ">
              <PrimaryCTAButton
                className="h-8"
                event-name="Save Model Button"
                onClick={() => {
                  connect();
                  saveModel(data);

                  toast(
                    `Saved ${data?.roundDetailsTab?.roundName} round model successfully`,
                    {
                      type: "success",
                      autoClose: 2000,
                    }
                  );
                  queryClient.invalidateQueries("listofmodeling");
                }}
                buttonSize={ButtonSize.SMALL}
              >
                Save Model
              </PrimaryCTAButton>
            </div>

            <PrimaryCTAButton
              buttonSize={ButtonSize.SMALL}
              className="min-w-max"
              onClick={handleSubmit}
            >
              Next
            </PrimaryCTAButton>
          </HStack>
        </HStack>
      </VStack>
    </>
  );
}
