import { Badge } from "@mui/material";
import { Icon } from "@iconify/react";
import { HStack, VStack } from "../../components/utils";
import TextToolTip from "./TextToolTip";
import { FileType } from "./AddNewCompanyRights";

function FileUploadedUI({
  fileDetail,
  uploadText,
  callBack,
  onClickDelete,
}: {
  fileDetail: FileType;
  uploadText: string;
  callBack: () => void;
  onClickDelete: () => void;
}) {
  return (
    <div>
      {fileDetail.name !== "" ? (
        <Badge
          key={fileDetail.name}
          className="cursor-pointer"
          badgeContent={<span>x</span>}
          invisible={false}
          variant="standard"
          onClick={() => {
            onClickDelete();
          }}
          color="primary"
          sx={{
            "& .MuiBadge-badge": {
              fontSize: 10,
              height: 16,
              minWidth: 12,
            },
          }}
        >
          <HStack className="items-center border border-gray-201 px-4 py-2 w-80 rounded-md">
            {fileDetail.type !==
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ? (
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/8/87/PDF_file_icon.svg"
                alt="pdf_icon"
                width={24}
                height={24}
              />
            ) : (
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/3/34/Microsoft_Office_Excel_%282019%E2%80%93present%29.svg"
                alt="excel_icon"
                width={24}
                height={24}
              />
            )}
            <VStack className="pl-2">
              <TextToolTip text={fileDetail.name} className={""} limit={32} />
              <p>{`${fileDetail.size.toLocaleString()} KB`}</p>
            </VStack>
          </HStack>
        </Badge>
      ) : (
        <div>
          {uploadText === "Upload Excel" ? (
            <HStack
              onClick={callBack}
              className="items-center border cursor-pointer border-gray-201 w-80 rounded-md"
            >
              <div className="w-[49px] h-[54px] bg-gray-200 rounded-md place-content-center">
                <Icon
                  icon="lucide:upload"
                  className="items-center m-auto bottom-2 mt-3"
                  height={24}
                  width={24}
                />
              </div>
              <div className="text-center pl-4">
                <span className="text-black text-[13px] font-medium">
                  Upload{" "}
                </span>
                Excel Sheet
              </div>
            </HStack>
          ) : uploadText === "Upload Excel Sheet" ? (
            <HStack
              onClick={callBack}
              className="items-center border cursor-pointer border-gray-201 w-80 rounded-md"
            >
              <div className="w-[49px] h-[54px] bg-gray-200 rounded-md place-content-center">
                <Icon
                  icon="lucide:upload"
                  className="items-center m-auto bottom-2 mt-3"
                  height={24}
                  width={24}
                />
              </div>
              <div className="text-center pl-4">
                <span className="text-black text-[13px] font-medium">
                  Upload{" "}
                </span>
                Excel Sheet
              </div>
            </HStack>
          ) : (
            <HStack
              onClick={callBack}
              className="items-center border cursor-pointer border-gray-201 w-80 rounded-md"
            >
              <div className="w-[49px] h-[54px] bg-gray-200 rounded-md place-content-center">
                <Icon
                  icon="lucide:upload"
                  className="items-center m-auto bottom-2 mt-3"
                  height={24}
                  width={24}
                />
              </div>
              <div className="text-center pl-4">
                <span className="text-black text-[13px] font-medium">
                  Upload{" "}
                </span>
                AoA Document
              </div>
            </HStack>
          )}
        </div>
      )}
    </div>
  );
}

export default FileUploadedUI;
