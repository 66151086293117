import create from "zustand";

type ShareHolderChartState = {
  name: string;
  setName: (name: string) => void;
  reset: () => void;
};

const initialState = {
  name: "",
};

const shareHolderChartState = create<ShareHolderChartState>((set) => ({
  ...initialState,
  setName: (name) => {
    set((state) => ({ name }));
  },
  reset: () => {
    set(() => initialState);
  },
}));

export const useShareHolderChartStore = shareHolderChartState;
