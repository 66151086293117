import { XMarkIcon } from "@heroicons/react/24/outline";
import { Box, VStack } from "./utils";

export function Headings({
  text,
  subText,
}: {
  text: string;
  subText?: string;
}) {
  return (
    <VStack className="pb-8">
      <div className="text-[20px] font-semibold leading-6 text-[#071437]">
        {text}
      </div>
      <div className="text-xs1 font-medium leading-6 text-[#99A1B7]">
        {subText}
      </div>
    </VStack>
  );
}

export function PopupHeadings({
  text,
  subText,
}: {
  text: string;
  subText?: string;
}) {
  return (
    <VStack>
      <div className="md:text-lg text-base font-semibold md:leading-6 leading-5 text-labelColor">
        {text}
      </div>
      {subText && (
        <div className="md:text-xs text-xxs  font-medium md:leading-4 leading-3 text-descriptionColor">
          {subText}
        </div>
      )}
    </VStack>
  );
}

export function PageHeading({ text }: { text: string }) {
  return (
    <div className="text-lg font-semibold leading-6 text-headerColor">
      {text}
    </div>
  );
}

export function PopupHeader({
  text,
  subText,
  onClick,
}: {
  text: string;
  subText?: string;
  onClick: () => void;
}) {
  return (
    <Box className="px-6 text-lg font-medium border-b py-4">
      <div className="flex justify-between">
        <PopupHeadings text={text} subText={subText} />
        <XMarkIcon
          className="cursor-pointer h-5 font-medium"
          onClick={onClick}
        />
      </div>
    </Box>
  );
}
