import { useRef } from "react";
import _ from "lodash";
import { useNavigate } from "react-router";
import {
  tableEntryStyle,
  tableFirstColStyle,
} from "../../components/TableComponent";

import { formatToReadableNumber } from "../../utils/currencyRoboto";

export function BeneficiaryNameRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-2">
      <p className={tableEntryStyle}>{data.data.beneficiaryName}</p>
    </div>
  );
}

export function SecurityClassRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-2">
      <p className={tableEntryStyle}>{data.data.securityClass}</p>
    </div>
  );
}

export function NumberOfSharesRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-2">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.data?.numberOfShares,
          format: data.data.currencyType,
        })}
      </p>
    </div>
  );
}

export function HoldingPercentageRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-1">
      {formatToReadableNumber({
        value: data.data?.holdingPercentage,
        format: data.data.currencyType,
      })}
    </div>
  );
}
