import _ from "lodash";
import { tableEntryStyle } from "../../components/TableComponent";

import { formatToReadableNumber } from "../../utils/currencyRoboto";

import { HStack } from "../../components/utils";

export function ShareHolderNameRender(data: any) {
  return (
    <HStack className="pt-2 pb-1 ">
      <p
        className={`${
          data.data.shareholderName !== "Total"
            ? tableEntryStyle
            : "font-semibold"
        }`}
      >
        {data.data.shareholderName}
      </p>
      {((data.data.preHoldingPercentage === 0 &&
        data.data.postHoldingPercentage > 0) ||
        data.data.amountInvested !== 0) &&
        data.data.shareholderName !== "Total" && (
          <div
            className={`w-fit h-fit px-1 mb-1 ml-2 text-xxs rounded-sm font-semibold bg-white border text-[#2DA900] border-[#2DA900]`}
          >
            <p>{"New Investor"}</p>
          </div>
        )}
    </HStack>
  );
}

export function PreHoldingPercentageRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-2">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.data?.preHoldingPercentage,
          format: data.data.currencyType,
        })}
      </p>
    </div>
  );
}
export function PostHoldingPercentageRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-2">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.data?.postHoldingPercentage,
          format: data.data.currencyType,
        })}
      </p>
    </div>
  );
}
export function PreHoldingSharesRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-2">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.data?.preHoldingShares,
          format: data.data.currencyType,
        })}
      </p>
    </div>
  );
}
export function PostHoldingSharesRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-2">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.data?.postHoldingShares,
          format: data.data.currencyType,
        })}
      </p>
    </div>
  );
}

export function AmountRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-2">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.data?.amountInvested,
          format: data.data.currencyType,
        })}
      </p>
    </div>
  );
}
