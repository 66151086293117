import { QueryFunctionContext } from "react-query";
import { ModelingListModel, NonPricedRoundModel } from "../types/Modeling";
import api from "./capTableApi";

export async function getListOfModelings(): Promise<ModelingListModel[]> {
  return api.get(`v1/company/model/overview`).then((res) => res.data.data);
}

export async function getModelingDetails(
  context: QueryFunctionContext
): Promise<any> {
  const modelId = context.queryKey[1];
  return api
    .get(`v1/company/transaction/roundInputFromModel?modelId=${modelId}`)
    .then((res) => res.data.data);
}

export async function getNonPricedRoundModelDetails(
  context: QueryFunctionContext
): Promise<any> {
  return api
    .get(`v1/company/model/convertibleDetails`)
    .then((res) => res.data.data);
}

export async function getListOfNonPricedRoundModel(
  context: QueryFunctionContext
): Promise<any> {
  return api
    .get(`v1/company/model/nonPricedRounds`)
    .then((res) => res.data.data);
}

export async function getIndividualNonPricedRoundModel(
  context: QueryFunctionContext
): Promise<any> {
  const id = context.queryKey[1];

  return api
    .get(`v1/company/model/nonPricedRound`, {
      params: { modelId: id },
    })
    .then((res) => res.data.data);
}

export async function saveNonPricedRoundModel({
  nonPricedRoundModel,
  modelId,
}: {
  nonPricedRoundModel: NonPricedRoundModel;
  modelId: string;
}): Promise<any> {
  return api
    .put("v1/company/model/nonPricedRound", {
      modelData: nonPricedRoundModel,
      modelId,
    })
    .then((res) => res.data.data);
}
