import * as Yup from "yup";
import { Formik, Form } from "formik";
import { useMemo, useState } from "react";
import { Input, Label } from "../../components/shared/InputField";
import { Box, Error, HStack, VStack } from "../../components/utils";
import {
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../quickRound/CTAButtonComponents";
import { NewNonPricedSecurity } from "./RoundCreationModel";
import { useRMCreationStore } from "../../store/roundCreationStore";
import { SecurityTypeList } from "../../constants/ShareholderSecurityTypes";
import { Select } from "../../components/shared/Select";
import { getCurrencySymbolRoboto } from "../../utils/currencyRoboto";
import SecurityChart from "./SecurityChart";

const SecurityBuilder = () => {
  const roundCreationState = useRMCreationStore();
  const securityId = "";
  const securityBuilderSchema = Yup.object().shape({
    name: Yup.string().required().label("Security Name"),
    conversionRatio: Yup.string().required().label("Conversion Ratio"),
    type: Yup.string().required().label("Security Type"),
    parValue: Yup.number().required().label("PAR Value"),
    valuationCap: Yup.number().required().label("Valuation Cap"),
    discount: Yup.number().required().label("Discount %"),
    discountType: Yup.string().required().label("Discount Type"),
  });

  const nonPricedSecurity = useMemo(
    () =>
      roundCreationState.roundCreation.tab5?.nonPricedInstruments?.find(
        (sh) => sh.id === securityId
      ),
    [roundCreationState.roundCreation.tab4?.ongoingNewInvestorDetails]
  );

  const initialValues: NewNonPricedSecurity = {
    id: nonPricedSecurity?.id || "",
    name: nonPricedSecurity?.name || "",
    type: nonPricedSecurity?.type || "",
    convertibleAmount: nonPricedSecurity?.roundSize,
    parValue: nonPricedSecurity?.parValue || 0,
    conversionRatio: nonPricedSecurity?.conversionRatio || "",
    valuationCap: nonPricedSecurity?.valuationCap || 0,
    discount: nonPricedSecurity?.discount || 0,
    discountType: nonPricedSecurity?.discountType || "",
    roundSize: nonPricedSecurity?.roundSize || 0,
    postEsop: nonPricedSecurity?.postEsop || 0,
    valuation: nonPricedSecurity?.valuation || 0,
    amount: nonPricedSecurity?.amount || 0,
  };
  const symbol = getCurrencySymbolRoboto();

  return (
    <VStack>
      <Box className="p-6 bg-white rounded-lg">
        <VStack className="pb-2 border-b">
          <p className="text-base font-semibold text-gray-dark">
            Security Builder
          </p>
          <Box className="text-xs font-medium text-gray-light">
            Enter the details and paramaters of the security
          </Box>
        </VStack>
        <Formik
          key="roundTransaction"
          enableReinitialize={true}
          validationSchema={securityBuilderSchema}
          initialValues={initialValues}
          onSubmit={(values) => {
            roundCreationState.setRoundCreation({
              ...roundCreationState.roundCreation,
              isLoader: true,
            });
            const newNonPricedSecurity =
              roundCreationState.roundCreation.tab5?.nonPricedInstruments || [];
            newNonPricedSecurity.push({
              id: values.id,
              name: values.name,
              type: values.type,
              conversionRatio: values.conversionRatio,
              parValue: values.parValue,
              convertibleAmount: values.convertibleAmount,
              valuationCap: values.valuationCap,
              discount: values.discount,
              discountType: values.discountType,
              roundSize: values.roundSize,
              postEsop: values.postEsop,
              valuation: values.valuation,
              amount: values.amount,
              state: "New",
            });
            roundCreationState.sendMessage({
              ...roundCreationState.roundCreation,
              screen: "",
              tab5: {
                listOfShareHolders:
                  roundCreationState.roundCreation.tab5?.listOfShareHolders ||
                  [],
                investorDetails:
                  roundCreationState.roundCreation.tab5?.investorDetails || [],
                newInstruments:
                  roundCreationState.roundCreation.tab5?.newInstruments || [],
                listOfInstruments:
                  roundCreationState.roundCreation.tab5?.listOfInstruments,
                nonPricedInstruments: newNonPricedSecurity,
              },
            });
          }}
        >
          {(formik) => (
            <Form>
              <VStack className="">
                <HStack className="flex-col justify-between lg:flex-row">
                  <VStack className="py-4">
                    <Label className="w-48 py-2 text-sm font-medium whitespace-nowrap">
                      Security Type
                    </Label>
                    <Select
                      options={SecurityTypeList.filter(
                        (ele) => ele !== "Equity"
                      )}
                      className="w-60"
                      {...formik.getFieldProps(`type`)}
                    />
                    {formik.touched?.type && formik.errors?.type && (
                      <Error text={formik.errors?.type} />
                    )}
                  </VStack>
                  <VStack className="py-4">
                    <Label className="w-48 py-2 text-sm font-medium whitespace-nowrap">
                      Security Name
                    </Label>
                    <Input
                      type="text"
                      placeholder="Enter Round Name"
                      className="w-60"
                      {...formik.getFieldProps("name")}
                    />
                    {formik.touched?.name && formik.errors?.name && (
                      <Error text={formik.errors?.name} />
                    )}
                  </VStack>
                  <VStack className="py-4">
                    <Label className="w-48 py-2 text-sm font-medium whitespace-nowrap">
                      Convertible Investment
                    </Label>
                    <Input
                      type="text"
                      placeholder=""
                      className="w-60"
                      {...formik.getFieldProps("convertibleAmount")}
                    />
                    {formik.touched?.convertibleAmount &&
                      formik.errors?.convertibleAmount && (
                        <Error text={formik.errors?.convertibleAmount} />
                      )}
                  </VStack>
                </HStack>
                <HStack className="flex-col justify-between lg:flex-row">
                  <VStack className="py-4">
                    <Label className="w-48 py-2 text-sm font-medium whitespace-nowrap">
                      Conversion Ratio
                    </Label>
                    <Input
                      type="text"
                      placeholder="Conversion Ratio"
                      className="w-60"
                      {...formik.getFieldProps("conversionRatio")}
                    />
                    {formik.touched?.conversionRatio &&
                      formik.errors?.conversionRatio && (
                        <Error text={formik.errors?.conversionRatio} />
                      )}
                  </VStack>
                  <VStack className="py-4">
                    <Label className="w-48 py-2 text-sm font-medium whitespace-nowrap">
                      PAR Value
                    </Label>
                    <Input
                      type="number"
                      placeholder="Enter Par value"
                      className="w-60"
                      {...formik.getFieldProps("parValue")}
                    />
                    {formik.touched?.parValue && formik.errors?.parValue && (
                      <Error text={formik.errors?.parValue} />
                    )}
                  </VStack>
                  <VStack className="py-4 w-60"></VStack>
                </HStack>

                <VStack className="pt-12 pb-2 border-b">
                  <p className="text-base font-semibold text-gray-dark">
                    Details of Security
                  </p>
                  <Box className="text-xs font-medium text-gray-light">
                    Enter the details of the security which will be issued
                  </Box>
                </VStack>
                <HStack className="flex-col justify-between lg:flex-row">
                  <VStack className="py-4">
                    <Label className="w-48 py-2 text-sm font-medium whitespace-nowrap">
                      Valuation Cap {symbol}
                    </Label>
                    <Input
                      type="number"
                      className="w-60"
                      placeholder=""
                      {...formik.getFieldProps("valuationCap")}
                    />
                    {formik.touched?.valuationCap &&
                      formik.errors?.valuationCap && (
                        <Error text={formik.errors?.valuationCap} />
                      )}
                  </VStack>
                  <VStack className="py-4">
                    <Label className="w-48 py-2 text-sm font-medium whitespace-nowrap">
                      Discount %
                    </Label>
                    <Input
                      type="number"
                      className="w-60"
                      placeholder=""
                      {...formik.getFieldProps("discount")}
                    />
                    {formik.touched?.discount && formik.errors?.discount && (
                      <Error text={formik.errors?.discount} />
                    )}
                  </VStack>
                  <VStack className="py-4">
                    <Label className="w-48 py-2 text-sm font-medium whitespace-nowrap">
                      Discount Type
                    </Label>
                    <Select
                      options={[
                        "Pre - Money",
                        "Post - Money",
                        "Investment Method",
                      ]}
                      className="w-60"
                      {...formik.getFieldProps(`discountType`)}
                    />

                    {formik.touched?.discountType &&
                      formik.errors?.discountType && (
                        <Error text={formik.errors?.discountType} />
                      )}
                  </VStack>
                </HStack>
                <VStack className="pt-12 pb-2 border-b">
                  <p className="text-base font-semibold text-gray-dark">
                    Next Priced Round Parameters
                  </p>
                  <Box className="text-xs font-medium text-gray-light">
                    Edit the values of the parameters to change the graph
                  </Box>
                </VStack>
                <HStack className="flex-col justify-between lg:flex-row">
                  <VStack className="py-4">
                    <Label className="w-48 py-2 text-sm font-medium whitespace-nowrap">
                      Round Size {symbol}
                    </Label>
                    <Input
                      type="number"
                      className="w-60"
                      placeholder=""
                      {...formik.getFieldProps("roundSize")}
                    />
                    {formik.touched?.roundSize && formik.errors?.roundSize && (
                      <Error text={formik.errors?.roundSize} />
                    )}
                  </VStack>
                  <VStack className="py-4">
                    <Label className="w-48 py-2 text-sm font-medium whitespace-nowrap">
                      Post ESOP (%)
                    </Label>
                    <Input
                      type="number"
                      className="w-60"
                      placeholder=""
                      {...formik.getFieldProps("postEsop")}
                    />
                    {formik.touched?.postEsop && formik.errors?.postEsop && (
                      <Error text={formik.errors?.postEsop} />
                    )}
                  </VStack>
                  <VStack className="py-4">
                    <Label className="w-48 py-2 text-sm font-medium whitespace-nowrap">
                      Pre-Money Valuation {symbol}
                    </Label>
                    <Input
                      type="number"
                      className="w-60"
                      placeholder=""
                      {...formik.getFieldProps("valuation")}
                    />
                    {formik.touched?.valuation && formik.errors?.valuation && (
                      <Error text={formik.errors?.valuation} />
                    )}
                  </VStack>
                </HStack>

                <SecurityChart securityData={formik.values} />

                <HStack className="justify-between gap-4">
                  <HStack>
                    <SecondaryCTAButton
                      type="reset"
                      event-name="Security Builder Discard"
                      className="text-red-500 shadow-box"
                      onClick={() => {
                        roundCreationState.reset(
                          roundCreationState.roundCreation
                        );
                      }}
                    >
                      Discard
                    </SecondaryCTAButton>
                  </HStack>
                  <PrimaryCTAButton
                    loading={roundCreationState.roundCreation.isLoader}
                    className="min-w-max"
                    type="submit"
                    event-name="Security Builder Next"
                  >
                    Next
                  </PrimaryCTAButton>
                </HStack>
              </VStack>
            </Form>
          )}
        </Formik>
      </Box>
    </VStack>
  );
};

export default SecurityBuilder;
