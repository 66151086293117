import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { HStack, VStack } from "../../components/utils";
import BCHeader from "../../shared/BCHeader";
import { useAuthStore } from "../../store";
import { useGetAllTermsheet } from "../../queries/termsheet";
import TermsheetEmptyPage from "../../components/placeholder/TermsheetEmptyPage";
import EmptyPageIllustration from "../../shared/EmptyPageIllustration";
import { TermSheetMainHeaderCard } from "./TermsheetHeaderCards";
import AGGridTermsheeCapTable from "./AllTermsheetAgTable";

export default function AllTermsheetPage() {
  const navigate = useNavigate();
  const companyId = useAuthStore.getState().companyId || "";

  const { isPlaceholderData, data: allTermsheet } =
    useGetAllTermsheet(companyId);

  const termsheet = useMemo(() => allTermsheet ?? [], [allTermsheet]);

  return (
    <div className={isPlaceholderData ? "loading min-h-full" : "min-h-full"}>
      <HStack className="justify-between items-center">
        <VStack>
          <BCHeader className="items-baseline" bcTitle="Term Sheet" />
          <p className="ml-3 sm:mt-0 -mt-4 sm:text-xs1 text-xxs font-medium text-descriptionColor whitespace-nowrap md:mr-12">
            View term sheets with ease. Get clear insights into investor
            proposals.
          </p>
        </VStack>

        <div></div>
      </HStack>
      {allTermsheet?.length === 0 ? (
        <EmptyPageIllustration text={"No Term Sheet Available"} />
      ) : (
        <div>
          {" "}
          <HStack className="gap-8 mt-5 overflow-x-scroll scrollbar-hide pb-8">
            {termsheet?.map((header, index) => (
              <div
                key={index}
                className="hover:cursor-pointer"
                navigation-name="Termsheet Card"
                onClick={() => navigate(`/termsheet/overview/${header.id}`)}
              >
                <TermSheetMainHeaderCard termSheetDetail={header} />
              </div>
            )) || <></>}
          </HStack>
          <div className="bg-white shadow rounded-md">
            {allTermsheet && (
              <AGGridTermsheeCapTable captableData={allTermsheet} />
            )}
          </div>
        </div>
      )}
    </div>
  );
}
