import { useState } from "react";
import { toast } from "react-toastify";
import {
  ButtonSize,
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../pages/quickRound/CTAButtonComponents";
import { HStack } from "../components/utils";
import { useGenerateInvestorCredentials } from "../queries/globalInvestors";
import { InvestorUserAccess } from "../types/Investors";
import { queryClient } from "../queries";
import { SwitchButton } from "../components/shared/SwitchButton";

type generateCredentialsProps = {
  onPrimaryAction: () => void;
  onSecondaryAction: () => void;
  mode: "Add" | "Edit" | "View";
  shareHolderName: string;
  shareHolderId: string;
  adminEmail: string;
  globalAccountId: string;
  globalInvestorId: string;
};

const OnboardInvestor = ({
  onPrimaryAction = () => {},
  onSecondaryAction = () => {},
  mode,
  shareHolderName,
  shareHolderId,
  adminEmail,
  globalAccountId,
  globalInvestorId,
}: generateCredentialsProps) => {
  const viewOnly = mode === "View";
  const [isEmail, setIsEmail] = useState<boolean>(false);
  const [isClicked, setIsClicked] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  const { mutate: generateInvestorCredentials } =
    useGenerateInvestorCredentials();

  function HandleSubmit() {
    const inputValues: InvestorUserAccess = {
      sendEmails: isEmail,
      investors: [
        {
          shareholderId: shareHolderId,
          name: shareHolderName,
          emailId: adminEmail,
          userId: 0,
          accessGiven: true,
          isDeleted: false,
          globalAccountId,
          globalInvestorId,
        },
      ],
    };

    generateInvestorCredentials(inputValues, {
      onSuccess: () => {
        queryClient.invalidateQueries("getGlobalInvestors");
        queryClient.invalidateQueries("getIndividualShareholderOverview");
        toast("Credentials Generated", {
          type: "success",
          autoClose: 2000,
        });
        onPrimaryAction();
        setIsClicked(false);
        setLoading(false);
      },
      onError: (e: any) => {
        toast(`${e?.response?.data?.errorMessage || "Something went wrong"}`, {
          type: "error",
          autoClose: 2000,
        });
        onPrimaryAction();
        setIsClicked(false);
        setLoading(false);
      },
    });
  }
  return (
    <div>
      <div className="m-9">
        <span className="font-bold text-md">Note : </span>
        <span>{`Create investor portal account with admin email `}</span>
        <span className="font-semibold">{adminEmail}</span>
        <div>
          <HStack className="flex-1 mt-4 ">
            <div className="flex flex-row-reverse items-center  text-md text-gray-600">
              <SwitchButton
                className="text-sm text-[#464E5F] font-medium items-center mr-3"
                value={isEmail}
                label="Send Email"
                onClick={() => {
                  setIsEmail(!isEmail);
                }}
              />
            </div>
          </HStack>
        </div>
        <HStack className="justify-between mt-10">
          <SecondaryCTAButton
            event-name="Back Shareholder Profile"
            buttonSize={ButtonSize.SMALL}
            type="reset"
            onClick={onSecondaryAction}
          >
            Cancel
          </SecondaryCTAButton>
          {!viewOnly && (
            <PrimaryCTAButton
              event-name="Save Shareholder Profile"
              buttonSize={ButtonSize.SMALL}
              type="submit"
              disabled={isClicked}
              loading={loading}
              onClick={() => {
                setLoading(true);
                HandleSubmit();
                setIsClicked(true);
              }}
            >
              Confirm
            </PrimaryCTAButton>
          )}
        </HStack>
      </div>
    </div>
  );
};

export default OnboardInvestor;
