import { useEffect, useMemo, useState } from "react";
import { TrashIcon, XMarkIcon } from "@heroicons/react/24/solid";
import Box from "@mui/material/Box";
import { Icon } from "@iconify/react";
import { useFormik } from "formik";
import * as Yup from "yup";
import _, { template } from "lodash";
import { HStack, VStack, Error } from "../../components/utils";
import {
  ButtonSize,
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../quickRound/CTAButtonComponents";
import { Input, Label } from "../../components/shared/InputField";
import { useAuthStore } from "../../store";
import DropDownInput from "../../components/shared/DropDownInput";
import { useShareholderInstrumentStore } from "../../store/shareholderInstrumentStore";
import { SwitchDropDownModel } from "../rounds/RoundsAllTransactions";
import {
  getCurrencySymbol,
  getCurrencyType,
} from "../../utils/currencyFormatter";
import {
  InvestorState,
  ModelBuilder,
  OngoingBlendedSecondaryAllotmentDetails,
  OngoingSecondaryAllotmentDetails,
  SecondaryAllotmentDetails,
} from "../../types/Modeling";
import { useRoundModelStore } from "../../store/roundModelingStore";
import { FormatNumberSpan } from "../../utils/currencyRoboto";
import Tooltip from "../../components/shared/Tooltip";
import { limitString } from "../../utils/string";
import { SellerDetails } from "./RoundModelingModel";
import AlertTextMessage, { AlertType } from "./AlertTextMessage";
import { Select } from "../../components/shared/Select";

export interface ShareholderDropDown {
  name: string;
  type: string;
  id: string;
  fdbShares?: number;
}

type AddNewSecondaryProps = {
  onNext: (data: ModelBuilder) => void;
  onBack: () => void;
  data?: SecondaryAllotmentDetails;
  actionMode: string;
};

const AddBlendedSecondaryModal = ({
  onNext = () => {},
  onBack = () => {},
  data,
  actionMode,
}: AddNewSecondaryProps) => {
  const roundCreationState = useRoundModelStore();

  const [sellersShareholderlist, setSellersShareholder] = useState<
    ShareholderDropDown[]
  >([]);
  const currencySymbol = getCurrencySymbol();
  const secondaryInitialValues: SellerDetails[] =
    roundCreationState.roundCreation.secondaries?.secondaryAllotmentDetails
      .find(
        (secondary) =>
          secondary.buyerId === data?.buyerId && secondary.isBlendedSecondary
      )
      ?.sellerDetails?.map((ele) => ({
        id: ele.id ?? "",
        sellerId: ele.sellerId ?? "",
        sellerName: ele.sellerName ?? "",
        fdbShares: ele.fdbShares ?? 0,
        amount: ele.amount ?? 0,
        holdingPercentage: ele.holdingPercentage ?? 0,
      })) ?? [];

  const secondaryValidationSchema = Yup.object({
    sellers: Yup.array().of(
      // Array of seller objects
      Yup.object({
        sellerName: Yup.string().required("Seller name is required"),
        shares: Yup.number()
          .min(1, "Must have at least one share")
          .required("Number of shares is required"),
      })
    ),
  });

  const formik = useFormik<SellerDetails[]>({
    initialValues: secondaryInitialValues,
    validationSchema: secondaryValidationSchema,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const [selectedSeller, setSelectedSeller] = useState<ShareholderDropDown[]>(
    roundCreationState.roundCreation.secondaries?.secondaryAllotmentDetails
      .find(
        (secondary) =>
          secondary.buyerId === data?.buyerId && secondary.isBlendedSecondary
      )
      ?.sellerDetails?.map((ele) => ({
        name: ele.sellerName ?? "",
        type: "",
        id: ele.sellerId ?? "",
        fdbShares: ele.fdbShares ?? 0,
      })) ?? [
      {
        name: "",
        type: "",
        id: "",
        fdbShares: 0,
      },
    ]
  );

  useEffect(() => {
    const sellersShareholder =
      roundCreationState.roundCreation.secondaries?.listOfSellers.map(
        (seller) => ({
          name: seller.investorName,
          type: "",
          id: seller.investorId,
          fdbShares: seller.totalFdbShares,
        })
      ) ?? [];
    setSellersShareholder(sellersShareholder);
  }, []);

  const { roundCreation } = useRoundModelStore();

  const secondaryData = useMemo(
    () =>
      roundCreation.secondaries?.secondaryAllotmentDetails.find(
        (secondary) => secondary.id === data?.id
      ),
    [roundCreation]
  );

  const handleSubmit = async (values: SellerDetails[]) => {
    const ongoingExistingSecondaryDetails: OngoingBlendedSecondaryAllotmentDetails =
      {
        id: secondaryData?.id ?? "",
        buyerId: secondaryData?.buyerId ?? "",
        buyerName: secondaryData?.buyerName ?? "",
        sharePrice: secondaryData?.sharePrice ?? 0,
        postHoldingPercentage: secondaryData?.postHoldingPercentage ?? 0,
        totalAmountToBeInvested: secondaryData?.amountInvested ?? 0,
        secondaryHoldingPercentage:
          secondaryData?.secondaryHoldingPercentage ?? 0,
        totalFdbSharesToBeBought: secondaryData?.fdbShares ?? 0,
        state: InvestorState.ToBeSaved,
        sellerDetails: values.map((ele: SellerDetails) => ({
          id: ele.id,
          sellerId: ele.sellerId,
          sellerName: ele.sellerName,
          fdbShares: ele.fdbShares,
          amount: ele.fdbShares * (data?.sharePrice ?? 0),
          holdingPercentage:
            (ele.fdbShares /
              ((roundCreationState.roundCreation.onGoingRoundHeaders
                ?.postMoneyValuation ?? 0) /
                (roundCreationState.roundCreation.onGoingRoundHeaders
                  ?.fdbSharePrice ?? 0))) *
            100,
        })),
        remainingInvestmentAmount: 0,
      };
    hitSocketApi(ongoingExistingSecondaryDetails);
    onBack();
  };
  const addSeller = () => {
    setSelectedSeller([
      ...selectedSeller,
      {
        name: "",
        type: "",
        id: `${_.random(0, 999999)}`,
        fdbShares: 0,
      },
    ]);
    formik.setValues([
      ...formik.values,
      {
        sellerId: "",
        sellerName: "",
        amount: 0,
        fdbShares: 0,
        holdingPercentage: 0,
        id: `${_.random(0, 999999)}`,
      },
    ]);
  };
  const currencyType = getCurrencyType();

  const hitSocketApi = (
    ongoingBlendedInvestorDetails?: OngoingBlendedSecondaryAllotmentDetails
  ) => {
    const data: ModelBuilder = {
      ...roundCreationState.roundCreation,
      secondaries: {
        listOfBuyers:
          roundCreationState.roundCreation.secondaries?.listOfBuyers ?? [],
        listOfSellers:
          roundCreationState.roundCreation.secondaries?.listOfSellers ?? [],
        secondaryAllotmentDetails:
          roundCreationState.roundCreation.secondaries
            ?.secondaryAllotmentDetails ?? [],
        ongoingBlendedSecondaryAllotmentDetails: ongoingBlendedInvestorDetails,
        ongoingSecondaryAllotmentDetails:
          roundCreationState.roundCreation.secondaries
            ?.ongoingSecondaryAllotmentDetails,
      },
    };
    onNext(data);
  };

  return (
    <div className="w-200">
      <Box className="px-4 pt-4 pb-2 text-lg font-medium border-b">
        <h6 className="flex justify-between">
          <VStack>
            <p className="text-lg font-medium">Edit Blended Secondary</p>
          </VStack>
          <XMarkIcon
            onClick={() => onBack()}
            className="w-6 h-6 cursor-pointer"
          />
        </h6>
      </Box>
      <VStack className="w-full py-7 gap-9">
        <form
          onSubmit={formik.handleSubmit}
          id="exportSheetDetails"
          className="form"
          noValidate
        >
          <VStack className="w-full gap-6 px-10">
            <HStack className="gap-4 px-4 py-4 border shadow-box border-borderColor">
              <VStack className="flex-1">
                <Label className="mr-4 text-sm font-medium whitespace-nowrap">
                  Buyer Name
                </Label>
                <div className="mt-3 mr-4 text-base font-medium whitespace-nowrap">
                  <Tooltip text={secondaryData?.buyerName ?? ""}>
                    <p>{limitString(secondaryData?.buyerName || "", 24)}</p>
                  </Tooltip>
                </div>
              </VStack>
              <VStack className="flex-1">
                <Label className="mr-4 text-sm font-medium whitespace-nowrap">
                  Alloted Share %
                </Label>
                <div className="mt-3 mr-4 text-base font-medium whitespace-nowrap">
                  <FormatNumberSpan
                    value={secondaryData?.secondaryHoldingPercentage}
                    format={currencyType}
                  />
                </div>
              </VStack>
              <VStack className="flex-1">
                <Label className="mr-4 text-sm font-medium whitespace-nowrap">
                  Share Price ({currencySymbol})
                </Label>
                <div className="mt-3 mr-4 text-base font-medium whitespace-nowrap">
                  <FormatNumberSpan
                    value={secondaryData?.sharePrice}
                    format={currencyType}
                  />
                </div>
              </VStack>
              <VStack className="flex-1">
                <Label className="mr-4 text-sm font-medium whitespace-nowrap">
                  FDB Shares
                </Label>
                <div className="mt-3 mr-4 text-base font-medium whitespace-nowrap">
                  <FormatNumberSpan
                    value={secondaryData?.fdbShares}
                    format={currencyType}
                  />
                </div>
              </VStack>
              <VStack className="flex-1">
                <Label className="mr-4 text-sm font-medium whitespace-nowrap">
                  Total Secondary Amount({currencySymbol})
                </Label>
                <div className="mt-3 mr-4 text-base font-medium whitespace-nowrap">
                  <FormatNumberSpan
                    value={secondaryData?.amountInvested}
                    format={currencyType}
                  />
                </div>
              </VStack>
            </HStack>
            <HStack className="items-center justify-between">
              <VStack>
                <Label className="mt-3 mr-4 text-sm font-medium whitespace-nowrap">
                  Seller Details
                </Label>
                <p className="mr-4 text-xs font-medium whitespace-nowrap text-descriptionColor">
                  Assign sellers interested in secondary sales & Match buyer
                  investment amounts and share totals.
                </p>
              </VStack>

              <PrimaryCTAButton
                className="h-8"
                event-name="Add Blended Seller Modal"
                onClick={addSeller}
                buttonSize={ButtonSize.SMALL}
              >
                ADD
              </PrimaryCTAButton>
            </HStack>
            {formik.values.map((ele, index) => (
              <HStack key={ele.id + index} className="gap-4">
                <VStack className="w-72">
                  <Label className="mt-3 mr-4 text-sm font-medium whitespace-nowrap">
                    Seller Name
                  </Label>

                  <Select
                    options={sellersShareholderlist
                      .filter(
                        (ele) =>
                          ele.name.toLowerCase().trim() !== "esop pool" &&
                          ele.id !== data?.buyerId &&
                          !formik.values.find(
                            (value, formikInd) =>
                              ele.name === value.sellerName &&
                              formikInd !== index
                          )
                      )
                      .sort()}
                    {...formik.getFieldProps(`${[index]}.sellerName`)}
                    textGetter={(o) => o.name}
                    valueGetter={(o) => o.name}
                    onChange={(e) => {
                      formik.setFieldValue(
                        `${index}.sellerName`,
                        e.target.value
                      );
                      formik.setFieldValue(`${index}.fdbShares`, 0);
                    }}
                  />

                  {sellersShareholderlist.find(
                    (ele) =>
                      ele.name.toLowerCase().trim() ===
                      formik.values[index].sellerName?.toLowerCase().trim()
                  )?.fdbShares !== undefined && (
                    <div>
                      {`available shares: ${
                        (sellersShareholderlist.find(
                          (ele) =>
                            ele.name.toLowerCase().trim() ===
                            formik.values[index].sellerName
                              ?.toLowerCase()
                              .trim()
                        )?.fdbShares ?? 0) - formik.values[index].fdbShares ?? 0
                      }`}
                    </div>
                  )}
                  {formik.touched[index]?.sellerName &&
                    formik.errors[index]?.sellerName && (
                      <Error text={formik.errors[index]?.sellerName} />
                    )}
                </VStack>
                <VStack className="flex-1">
                  <Label className="mt-3 mr-4 text-sm font-medium whitespace-nowrap">
                    No. of Shares
                  </Label>
                  <Input
                    type="number"
                    placeholder="Shares"
                    className="w-52"
                    value={Math.round(formik.values[index].fdbShares)}
                    onInput={(e: any) => {
                      const maxValue =
                        sellersShareholderlist.find(
                          (ele) =>
                            ele.name.toLowerCase().trim() ===
                            formik.values[index].sellerName
                              ?.toLowerCase()
                              .trim()
                        )?.fdbShares ?? 1000000000000000;
                      const enteredValue = Number(e.target.value ?? 0);
                      if (maxValue >= enteredValue) {
                        formik.setFieldValue(
                          `${[index]}.fdbShares`,
                          Number(e.target.value ?? 0)
                        );
                        const inputElement = e.target as HTMLInputElement;
                        inputElement.value = Math.round(
                          Number(inputElement.value)
                        ).toString();
                      }
                    }}
                  />
                  {formik.touched[index]?.fdbShares &&
                    formik.errors[index]?.fdbShares && (
                      <Error text={formik.errors[index]?.fdbShares} />
                    )}
                </VStack>
                <VStack className="items-center flex-1 w-72">
                  <Label className="mt-3 mr-4 text-sm font-medium whitespace-nowrap w-72">
                    Amount{` (${currencySymbol})`}
                  </Label>
                  <Label className="mt-4 mr-4 text-sm font-medium whitespace-nowrap w-72">
                    <FormatNumberSpan
                      value={
                        formik.values[index].fdbShares * (data?.sharePrice ?? 0)
                      }
                      format={currencyType}
                    />
                  </Label>
                </VStack>
                <VStack className="items-center flex-1 w-72">
                  <Label className="mt-3 mr-4 text-sm font-medium whitespace-nowrap">
                    Shares (%)
                  </Label>
                  <Label className="mt-4 mr-4 text-sm font-medium whitespace-nowrap">
                    <FormatNumberSpan
                      value={
                        (formik.values[index].fdbShares /
                          ((roundCreationState.roundCreation.onGoingRoundHeaders
                            ?.postMoneyValuation ?? 0) /
                            (roundCreationState.roundCreation
                              .onGoingRoundHeaders?.fdbSharePrice ?? 0))) *
                        100
                      }
                      format={currencyType}
                    />
                  </Label>
                </VStack>
                {formik.values.length > 1 && (
                  <TrashIcon
                    className="h-6 mt-10 cursor-pointer text-secondary"
                    onClick={() => {
                      const values = formik.values;
                      if (values.length > 1) {
                        const filteredValues = values.filter(
                          (ele: any, ind: any) =>
                            ele.id !== formik.values[index].id
                        );
                        formik.setValues(filteredValues);
                        setSelectedSeller(
                          selectedSeller.filter(
                            (ele: any, ind: any) =>
                              ele.id !== formik.values[index].id
                          )
                        );
                      }
                    }}
                  />
                )}
              </HStack>
            ))}
          </VStack>

          <HStack className="justify-between px-10">
            <SecondaryCTAButton
              event-name="Cancel Blended Modal"
              onClick={() => onBack()}
            >
              Cancel
            </SecondaryCTAButton>
            <VStack>
              <PrimaryCTAButton
                event-name="Save Blended Modal"
                loading={roundCreationState.roundCreation.isNavigating}
                type="submit"
                onClick={() => {
                  formik.submitForm();
                }}
              >
                Save
              </PrimaryCTAButton>
            </VStack>
          </HStack>
        </form>
      </VStack>
    </div>
  );
};

export default AddBlendedSecondaryModal;
