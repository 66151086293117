import _ from "lodash";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import {
  LightBulbIcon,
  XCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import { useState } from "react";

type AlertModalModel = {
  data?: string;
  heading?: string;
  type?: AlertType;
};

export enum AlertType {
  INSIGHT,
  ALERT,
  ERROR,
}

export default function AlertMessage({
  data,
  heading,
  type = AlertType.ALERT,
}: AlertModalModel) {
  const [hide, setHide] = useState(false);

  const textColor =
    type === AlertType.ALERT
      ? "text-red-700"
      : type === AlertType.INSIGHT
      ? "text-yellow-700"
      : type === AlertType.ERROR
      ? "text-red-500"
      : "";
  const iconColor =
    type === AlertType.ALERT
      ? "text-red-500"
      : type === AlertType.INSIGHT
      ? "text-yellow-500"
      : type === AlertType.ERROR
      ? "text-red-500"
      : "";
  return (
    <div
      className={`w-fit flex flex-col min-h-full items-end justify-center text-center sm:items-center ${
        hide ? "hidden" : "block"
      }`}
    >
      <div className="sm:flex sm:items-center">
        {type === AlertType.ALERT && (
          <ExclamationTriangleIcon
            className={`h-4 w-4 placeholderLoader animate-pulse ${iconColor}`}
            aria-hidden="true"
          />
        )}
        {type === AlertType.INSIGHT && (
          <LightBulbIcon
            className={`h-4 w-4 placeholderLoader animate-pulse ${iconColor}`}
            aria-hidden="true"
          />
        )}
        {type === AlertType.ERROR && (
          <XCircleIcon
            className={`h-4 w-4 placeholderLoader animate-pulse ${textColor}`}
            aria-hidden="true"
          />
        )}
        <div className="text-center sm:ml-1 sm:text-left">
          <h3
            className={`text-xxs1 placeholderLoader font-medium ${textColor}`}
          >
            {heading}
          </h3>
        </div>
      </div>
    </div>
  );
}
