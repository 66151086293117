import { Icon } from "@iconify/react";
import { useEffect, useRef, useState } from "react";
import { HStack } from "../../components/utils";
import { RightsMultiSelectModel } from "../../store/rightsfilterStore";

export function RightsDropDownChooser({
  label,
  className = "items-end",
  columnOptions,
  onAction,
}: {
  label?: string;
  className?: string;
  columnOptions: RightsMultiSelectModel[];
  onAction: (action: RightsMultiSelectModel[]) => void;
}) {
  const [dropdownOpen, setDropDownOpen] = useState(false);
  const contextRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const checkIfClickOutside = (e: any) => {
      if (!(dropdownOpen && contextRef.current?.contains(e.target))) {
        setDropDownOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickOutside);
    };
  }, [dropdownOpen]);

  return (
    <div
      className={`py-1 flex flex-col overflow-auto ${className} `}
      ref={contextRef}
    >
      <button
        className="w-full text-xs peer"
        type="button"
        onClick={() => setDropDownOpen((state) => !state)}
      >
        {label ? (
          <HStack className="bg-inherit px-2 py-1 text-sm text-gray-600 font-medium justify-between">
            <p>{label}</p>
            <Icon
              icon="ic:baseline-keyboard-arrow-right"
              className="text-gray-400 rounded"
              height={20}
            />
          </HStack>
        ) : (
          <Icon
            icon="carbon:overflow-menu-horizontal"
            className="text-gray-400 rounded hover:bg-gray-300"
            height={20}
          />
        )}
      </button>
      {dropdownOpen && (
        <div
          id="dropdown"
          className={`absolute z-10  mt-8 bg-white border divide-y divide-gray-100 rounded shadow-box`}
        >
          <p className="px-4 py-1 text-stone-300">More actions</p>
          <ul
            className="py-1 text-xs font-normal divide-y text-dark min-h-min overflow-auto whitespace-nowrap"
            aria-labelledby="dropdownDefault"
          >
            {columnOptions.map((action, index) => (
              <li key={index}>
                <HStack
                  className="justify-between px-4 py-2"
                  onClick={
                    !action.isDisabled
                      ? (e) => {
                          onAction(
                            columnOptions.map((options) => {
                              if (action.name === options.name) {
                                options.isVisible = !options.isVisible;
                              }
                              return options;
                            })
                          );
                        }
                      : () => {}
                  }
                >
                  <a
                    href=""
                    className={` block hover:bg-slate-50 mr-2  ${
                      action.isDisabled
                        ? "text-gray-300 cursor-not-allowed "
                        : action.isVisible
                        ? "text-gray-800"
                        : "text-gray-800 hover:text-red-500"
                    } `}
                    onMouseDown={(e) => e.preventDefault()}
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    {action.name.toLocaleUpperCase()}
                  </a>
                  <input
                    type="checkbox"
                    className={`accent-orange-501 outline-hidden mr-2 ${
                      action.isDisabled ? "cursor-not-allowed " : ""
                    }`}
                    checked={action.isVisible}
                  ></input>
                </HStack>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}
