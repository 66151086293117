import { useRef } from "react";
import _ from "lodash";
import { useNavigate } from "react-router";
import { Box, HStack, VStack } from "../../components/utils";
import { tableEntryStyle } from "../../components/TableComponent";

import { limitString } from "../../utils/string";
import { getSecurityColor } from "../../utils/historicUtilities";
import {
  formatToReadableNumber,
  getRoundCurrencySymbol,
} from "../../utils/currencyRoboto";

import { CaptableTag } from "../../components/AllotmentTag";
import {
  formatCurrency,
  getCompanyCurrency,
  getCurrency,
  getKeyByValue,
} from "../../utils/currencyFormatter";
import { currencyTypes } from "../../constants/CompanyCurrencyType";
import { formatDate, formatDisplayDate } from "../../utils/date";

export function BuyerNameRender(data: any) {
  return (
    <td className="pb-2 text-sm">
      <VStack>
        {data.data.buyerName.length > 24 ? (
          <div className="whitespace-normal w-fit">
            {limitString(data.data.buyerName, 24)}
          </div>
        ) : (
          <div className="whitespace-normal w-fit ">
            {limitString(data.data.buyerName, 24)}
          </div>
        )}
        <CaptableTag tagName={data.data.investorType ?? "Others"} />
      </VStack>
    </td>
  );
}

export function SellerNameRender(data: any) {
  return (
    <td className="pt-2 text-sm">
      <div className="flex">
        <div className={tableEntryStyle}>
          {data.data.sellerName.length > 24 ? (
            <div className="whitespace-normal w-fit ">
              {limitString(data.data.sellerName, 24)}
            </div>
          ) : (
            <div className="whitespace-normal w-fit">
              {limitString(data.data.sellerName, 24)}
            </div>
          )}
        </div>
      </div>
    </td>
  );
}

export function SecurityRender(data: any) {
  return (
    <td className="whitespace-nowrap   text-sm text-gray-500 ">
      {data.data.security ? (
        <div>
          <div className={tableEntryStyle}>
            <p
              className={`inline-flex px-2 ${getSecurityColor(
                data.data.security
              )} text-xs  font-semibold leading-5`}
            >
              {data.data.security}
            </p>
          </div>

          <div className="text-gray-500 justify-center  text-xs1">
            <span>
              PAR :{formatCurrency(data.data.par || 0, data.data.currencyType)}
            </span>
            {data.data.conversionRatio !== "1: 1" && (
              <span className="text-black"> | </span>
            )}
            {data.data.conversionRatio !== "1: 1" && (
              <span>Conversion Ratio : {data.data.conversionRatio}</span>
            )}
          </div>
        </div>
      ) : (
        <div className={tableEntryStyle}>-</div>
      )}
    </td>
  );
}

export function NumberOfSharesRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-3">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.data?.numberOfShares,
          format: data.data.currencyType,
        })}
      </p>
    </div>
  );
}

export function TransactionAmountRender(data: any) {
  const companyCurrency = getCompanyCurrency();

  return (
    <td className="whitespace-nowrap pt-3 ">
      <p className="text-xs2 font-medium ">
        <span className="pr-1">{data.data.currencySymbol}</span>
        {(
          data.data.transactionAmount.amountInvested *
          (data.data.exchangeRate || 1)
        ).toLocaleString(data.data.currency)}
      </p>
      {data.data?.transactionCurrency !== companyCurrency && (
        <div className="text-gray-500  text-center text-xs1">
          <span className="pr-1">
            {getRoundCurrencySymbol(
              data.data?.transactionCurrency || data.data.currency
            ) || data.data.currencySymbol}
          </span>
          {data.data.transactionAmount.amountInvested?.toLocaleString(
            getCurrency(data.data?.transactionCurrency || "") ||
              data.data.currency
          )}
          <span>
            (1{" "}
            {getKeyByValue(
              currencyTypes,
              getCurrency(data.data.transactionCurrency || companyCurrency) ||
                data.data.currency
            )}{" "}
            = {data.data.exchangeRate}{" "}
            {getKeyByValue(currencyTypes, getCurrency(companyCurrency))})
          </span>
        </div>
      )}
    </td>
  );
}

export function DateRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-3">
      {data.data.date ? (
        <p className={tableEntryStyle}>{formatDisplayDate(data.data.date)}</p>
      ) : (
        <p className={tableEntryStyle}>-</p>
      )}
    </div>
  );
}
