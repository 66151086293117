import { Icon } from "@iconify/react";
import { HStack } from "../../components/utils";
import {
  getCurrencySymbol,
  getCurrencyType,
} from "../../utils/currencyFormatter";
import { classNames } from "../../utils/string";
import { FormatNumberSpan } from "../../utils/currencyRoboto";

const stats = [
  {
    name: "Valuation",
    stat: "71,897",
    previousStat: "70,946",
    change: "12%",
    changeType: "increase",
  },
  {
    name: "Price Per Share",
    stat: "58.16",
    previousStat: "8",
    change: "2.02%",
    changeType: "increase",
  },
];

export function ValuationTabDetails({
  valuation,
  pps,
  preValuation,
  prePps,
}: {
  valuation: number;
  pps: number;
  preValuation: number;
  prePps: number;
}) {
  const currencySymbol = getCurrencySymbol();
  const currency = getCurrencyType();
  const previousValution = preValuation;
  const diff: number = valuation - previousValution;
  const valuationChange = Math.round(
    ((valuation - previousValution) / previousValution) * 100
  );
  const previousPps = prePps;
  const diffPps: number = pps - previousPps;
  const ppsChange = Math.round(((pps - previousPps) / previousPps) * 100);
  return (
    <div className="mt-5 flex md:flex-row flex-col gap-3">
      <div className="flex-1 px-4 mb-2 py-5 sm:p-6 shadow-box rounded-lg bg-white border border-borderColor">
        <HStack className="justify-between">
          <dt className="text-xs font-semibold text-gray-400">
            Valuation ({currencySymbol})
          </dt>
          <div
            className={classNames(
              diff >= 0
                ? "bg-green-100 text-green-800"
                : "bg-red-100 text-red-800",
              "inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0 whitespace-nowrap"
            )}
          >
            {diff >= 0 ? (
              <Icon icon="akar-icons:chevron-up" className="text-green-800" />
            ) : (
              <Icon icon="akar-icons:chevron-down" className="text-red-800" />
            )}

            <span className="sr-only">
              {" "}
              {diff >= 0 ? "Increased" : "Decreased"}
              by{" "}
            </span>
            {`${valuationChange.toLocaleString(currency)} %`}
          </div>
        </HStack>

        <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
          <div className="flex items-baseline text-lg font-semibold text-black flex-row">
            <FormatNumberSpan value={valuation} format={currency} />
            <div className="ml-2 text-sm font-medium text-gray-500">from</div>
            <div className="ml-2 text-sm font-medium text-gray-500">
              <FormatNumberSpan value={previousValution} format={currency} />
            </div>
          </div>
        </dd>
      </div>
      <div className="flex-1 px-4 mb-2 py-5 sm:p-6 shadow-box rounded-lg bg-white border border-borderColor">
        <HStack className="justify-between">
          <dt className="text-xs font-semibold text-gray-400">
            Price Per Share ({currencySymbol})
          </dt>
          <div
            className={classNames(
              diffPps >= 0
                ? "bg-green-100 text-green-800"
                : "bg-red-100 text-red-800",
              "inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0 whitespace-nowrap"
            )}
          >
            {diffPps >= 0 ? (
              <Icon icon="akar-icons:chevron-up" className="text-green-800" />
            ) : (
              <Icon icon="akar-icons:chevron-down" className="text-red-800" />
            )}

            <span className="sr-only">
              {diffPps >= 0 ? "Increased" : "Decreased"}
              by
            </span>
            {`${ppsChange.toLocaleString(currency)} %`}
          </div>
        </HStack>

        <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
          <div className="flex items-baseline text-lg font-semibold text-black flex-row">
            <FormatNumberSpan value={pps} format={currency} />
            <div className="ml-2 text-sm font-medium text-gray-500">from</div>
            <div className="ml-2 text-sm font-medium text-gray-500">
              <FormatNumberSpan value={prePps} format={currency} />
            </div>
          </div>
        </dd>
      </div>
    </div>
  );
}
