import { useEffect, useState, useMemo } from "react";
import Box from "@mui/material/Box";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { Icon } from "@iconify/react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { HStack, VStack, Error } from "../../components/utils";
import {
  ButtonSize,
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../quickRound/CTAButtonComponents";
import { Input, Label } from "../../components/shared/InputField";
import { Select } from "../../components/shared/Select";
import { useRMCreationStore } from "../../store/roundCreationStore";
import { useShareholderInstrumentStore } from "../../store/shareholderInstrumentStore";
import { ShareholderDropDown } from "./AddNewInvestors";
import { extractSecurityByName, extractSecurityDetails } from "./utility";
import {
  NewInstrumentState,
  NewInvestorState,
  NewSecurity,
} from "./RoundCreationModel";
import { SecurityTypeList } from "../../constants/ShareholderSecurityTypes";
import { getCurrencySymbol } from "../../utils/currencyFormatter";

type AddNewSecurityProps = {
  onBack: () => void;
  data?: NewSecurity;
  actionMode: string;
};

interface AddSecurityModel {
  securityName: string;
  securityType: string;
  // importDetails: boolean;
  securityId: string;
  importSecurityId: string;
  importSecurityName: string;
  conversionRatio: number;
  parValue: number;
}

const AddNewSecurity = ({
  onBack = () => {},
  data,
  actionMode,
}: AddNewSecurityProps) => {
  const roundCreationState = useRMCreationStore();
  const shareholderInstrumentStore = useShareholderInstrumentStore();
  const [loading, setLoading] = useState(false);
  const [shareholders, setShareholder] = useState<ShareholderDropDown[]>([]);
  const [instruments, setIntruments] = useState<string[]>([]);
  const [selectedSecurity, setSelectedSecurity] = useState("");
  useEffect(() => {
    const shareholdersList = shareholderInstrumentStore.shareholders.map(
      (sh) => ({
        id: sh.id,
        name: sh.name,
        type: sh.type,
      })
    );
    const instrumentList = shareholderInstrumentStore.instrumentClasses.map(
      (instrument) => instrument.name
    );
    setIntruments(instrumentList);
    setShareholder(shareholdersList);
  }, [shareholderInstrumentStore.shareholders]);

  const handleSubmit = async (values: AddSecurityModel) => {
    setLoading(true);
    setLoading(false);
    const securityId =
      extractSecurityByName(
        values.securityName,
        shareholderInstrumentStore.instrumentClasses
      )?.id || "";
    const importedSecurityId =
      extractSecurityByName(
        values.importSecurityName,
        shareholderInstrumentStore.instrumentClasses
      )?.id || "";
    const instruments =
      roundCreationState.roundCreation.tab5?.newInstruments ||
      ([] as NewSecurity[]);
    if (actionMode === "New") {
      instruments.push({
        conversionRatio: `1:${values.conversionRatio}` || "",
        id: securityId || "",
        importedInstrumentId: importedSecurityId || "",
        name: values.securityName || "",
        parValue: values.parValue || 0,
        type: values.securityType || "",
        state: NewInstrumentState.ToBeSaved,
      });
      roundCreationState.sendMessage({
        ...roundCreationState.roundCreation,
        tab5: {
          investorDetails:
            roundCreationState.roundCreation.tab5?.investorDetails ?? [],
          listOfInstruments:
            roundCreationState.roundCreation.tab5?.listOfInstruments ?? [],
          nonPricedInstruments:
            roundCreationState.roundCreation.tab5?.nonPricedInstruments ?? [],
          listOfShareHolders:
            roundCreationState.roundCreation.tab5?.listOfShareHolders ?? [],
          ongoingSecurityAllotmentDetails: undefined,
          newInstruments: instruments,
        },
      });
    } else {
      const shareholderList = instruments.map((element) => {
        if (element.id === values.securityId) {
          return {
            conversionRatio: `1: ${values.conversionRatio}` || "",
            id: values.securityId || "",
            importedInstrumentId: values.importSecurityId || "",
            name: values.securityName || "",
            parValue: values.parValue || 0,
            type: values.securityType || "",
            state: NewInstrumentState.ToBeSaved,
          };
        } else {
          return element;
        }
      });
      roundCreationState.sendMessage({
        ...roundCreationState.roundCreation,
        tab5: {
          listOfInstruments:
            roundCreationState.roundCreation.tab5?.listOfInstruments ?? [],
          nonPricedInstruments:
            roundCreationState.roundCreation.tab5?.nonPricedInstruments ?? [],
          listOfShareHolders:
            roundCreationState.roundCreation.tab5?.listOfShareHolders ?? [],
          ongoingSecurityAllotmentDetails: undefined,
          newInstruments: shareholderList,
          investorDetails:
            roundCreationState.roundCreation.tab5?.investorDetails || [],
        },
      });
    }
    onBack();
  };

  const currencySymbol = getCurrencySymbol();

  const [isImport, setIsImport] = useState(false);

  const securityInitialValues = {
    securityName: data?.name || "",
    securityType: data?.type || "",
    securityId: data?.id || "",
    importSecurityName:
      extractSecurityDetails(
        data?.importedInstrumentId || "",
        shareholderInstrumentStore.instrumentClasses
      )?.name || "",
    importSecurityId: data?.importedInstrumentId || "",
    conversionRatio: Number(data?.conversionRatio.split(":")[1]) || 1,
    parValue: data?.parValue || 0,
  };

  const securityValidationSchema = Yup.object({
    securityName: Yup.string().required().label("Security Name"),
    securityType: Yup.string().required().label("Security Type"),
    importSecurityName: isImport
      ? Yup.string().required().label("Import Security Name")
      : Yup.string().label("Import Security Name"),
    conversionRatio: Yup.number().min(1).required().label("Conversion Ratio"),
    parValue: Yup.number().min(1).required().label("Par Value"),
  });

  return (
    <div className="min-w-2xl max-w-2xl">
      <Box className="px-4 text-lg font-medium border-b pt-4 pb-2">
        <div className="flex justify-between">
          <VStack>
            <p className="font-medium text-lg">Add New Security</p>
          </VStack>

          <XMarkIcon
            onClick={() => onBack()}
            className="cursor-pointer h-6 w-6"
          />
        </div>
      </Box>
      <VStack className="w-full py-7 gap-9">
        <Formik
          initialValues={securityInitialValues}
          key="securityForm"
          onSubmit={(values) => {
            handleSubmit(values);
          }}
          validationSchema={securityValidationSchema}
        >
          {(formik) => (
            <div>
              <Form key="exportSheetDetails">
                <VStack className="w-full gap-4 px-10">
                  <HStack className="gap-4">
                    <VStack className="flex-1">
                      <Label className="font-medium text-sm whitespace-nowrap mt-3 mr-4">
                        Security Name
                      </Label>
                      <Input
                        type="text"
                        placeholder="Enter Security Name"
                        className="w-72"
                        {...formik.getFieldProps("securityName")}
                      />
                      {formik.touched.securityName &&
                        formik.errors.securityName && (
                          <Error text={formik.errors.securityName} />
                        )}
                    </VStack>
                    <VStack className="flex-1">
                      <Label className="font-medium text-sm whitespace-nowrap mt-3 mr-4">
                        Security Type
                      </Label>
                      <Select
                        className="w-72"
                        options={SecurityTypeList}
                        {...formik.getFieldProps("securityType")}
                        onChange={(event) => {
                          formik.setFieldValue("importSecurityName", "");
                          formik.setFieldValue("parValue", 1);
                          formik.setFieldValue("conversionRatio", 1);

                          formik.handleChange(event);
                          setSelectedSecurity(event.target.value);
                          const instrumentList =
                            event.target.value !== ""
                              ? shareholderInstrumentStore.instrumentClasses
                                  .filter(
                                    (instrument) =>
                                      instrument.subType === event.target.value
                                  )
                                  .map((instrument) => instrument.name)
                              : shareholderInstrumentStore.instrumentClasses.map(
                                  (instrument) => instrument.name
                                );
                          setIntruments(instrumentList);
                        }}
                      />
                      {formik.touched.securityType &&
                        formik.errors.securityType && (
                          <Error text={formik.errors.securityType} />
                        )}
                    </VStack>
                  </HStack>
                  {instruments.length !== 0 && formik.values.securityType && (
                    <VStack className={`border-t border-b py-6`}>
                      <Label className="mb-4 mr-8">
                        Select Method of Security addition
                      </Label>
                      <HStack className="items-center justify-start w-full">
                        <HStack className="items-center mr-12 gap-4">
                          <input
                            type="radio"
                            className="mr-1 accent-orange-501 outline-hidden"
                            checked={isImport}
                            onChange={() => {
                              setIsImport(!isImport);
                            }}
                          />
                          <Label className="mb-0 whitespace-nowrap">
                            Import Details from
                          </Label>
                          <VStack className="w-44">
                            <Select
                              className="w-44"
                              disabled={!isImport}
                              options={instruments}
                              onChange={(event) => {
                                const eventData = event.target.value;
                                const parData =
                                  extractSecurityByName(
                                    event.target.value,
                                    shareholderInstrumentStore.instrumentClasses
                                  )?.parValue ?? undefined;
                                const crData =
                                  extractSecurityByName(
                                    event.target.value,
                                    shareholderInstrumentStore.instrumentClasses
                                  )?.conversionRatio ?? undefined;

                                formik.setFieldValue("conversionRatio", crData);
                                formik.setFieldValue("parValue", parData);

                                formik.setFieldValue(
                                  "importSecurityName",
                                  eventData
                                );
                              }}
                            />
                            {formik.touched.importSecurityName &&
                              formik.errors.importSecurityName && (
                                <Error
                                  text={formik.errors.importSecurityName}
                                />
                              )}
                          </VStack>
                        </HStack>
                        <HStack>
                          <input
                            type="radio"
                            className="mr-1 accent-orange-501 outline-hidden"
                            checked={!isImport}
                            onChange={() => {
                              setIsImport(!isImport);
                              formik.setFieldValue("importSecurityName", "");
                              formik.setErrors({
                                ...formik.errors,
                                importSecurityName: "",
                              });
                              formik.setFieldTouched(
                                "importSecurityName",
                                false
                              );
                            }}
                          />
                          <Label className="mb-0 mr-8 whitespace-nowrap">
                            Enter Manually
                          </Label>
                        </HStack>
                      </HStack>
                    </VStack>
                  )}
                  {
                    <HStack className="gap-4">
                      {formik.values.securityType !== "Equity" && (
                        <VStack className="flex-1">
                          <Label className="font-medium text-sm whitespace-nowrap mt-3 mr-4">
                            Conversion Ratio
                          </Label>
                          <HStack className="gap-2 items-center">
                            <p className="py-2">1</p> <p className="py-2">:</p>
                            <Input
                              type="number"
                              placeholder="Enter Conversion Ratio"
                              className="w-72"
                              {...formik.getFieldProps("conversionRatio")}
                            />
                          </HStack>

                          {formik.touched.conversionRatio &&
                            formik.errors.conversionRatio && (
                              <Error text={formik.errors.conversionRatio} />
                            )}
                        </VStack>
                      )}
                      <VStack className="flex-1">
                        <Label className="font-medium text-sm whitespace-nowrap mt-3 mr-4">
                          PAR {` (${currencySymbol})`}
                        </Label>
                        <Input
                          type="number"
                          placeholder="Enter the par"
                          className="w-72"
                          {...formik.getFieldProps("parValue")}
                        />

                        {formik.touched.parValue && formik.errors.parValue && (
                          <Error text={formik.errors.parValue} />
                        )}
                      </VStack>
                    </HStack>
                  }
                </VStack>
                <HStack className="justify-between mt-10 px-10">
                  <SecondaryCTAButton
                    event-name="Cancel New Security"
                    buttonSize={ButtonSize.SMALL}
                    onClick={() => onBack()}
                  >
                    Cancel
                  </SecondaryCTAButton>
                  <PrimaryCTAButton
                    event-name="Save New Security"
                    buttonSize={ButtonSize.SMALL}
                    className="p-1"
                    type="submit"
                  >
                    {!loading && <span>Save</span>}

                    {loading && (
                      <HStack>
                        <p
                          className="indicator-progress"
                          style={{ display: "block" }}
                        >
                          Please wait...
                        </p>
                        <Icon
                          className="animate-spin"
                          icon="lucide:loader-2"
                          width={18}
                        />
                      </HStack>
                    )}
                  </PrimaryCTAButton>
                </HStack>
              </Form>
            </div>
          )}
        </Formik>
      </VStack>
    </div>
  );
};

export default AddNewSecurity;
