import _ from "lodash";
import { tableEntryStyle } from "../../components/TableComponent";

import { formatToReadableNumber } from "../../utils/currencyRoboto";

import { formatDisplayDate } from "../../utils/date";

export function TermsheetNameRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-2 cursor-pointer">
      <p className={tableEntryStyle}>{data.data.termsheetName}</p>
    </div>
  );
}

export function ExpiryDateRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-2">
      <p className={tableEntryStyle}>{formatDisplayDate(data.data.expDate)}</p>
    </div>
  );
}

export function ValuationRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-2">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.data?.valuation,
          format: data.data.currencyType,
        })}
      </p>
    </div>
  );
}

export function RoundSizeRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-1">
      {formatToReadableNumber({
        value: data.data?.roundSize,
        format: data.data.currencyType,
      })}
    </div>
  );
}

export function ActionMenuRender(data: any) {
  const options = ["Delete Report"];
  const actionMenu = () =>
    options.map((option) => (
      <label
        key={option}
        className="form-check-custom py-3 bg-hover-primary text-hover-white"
        onClick={() => {}}
      >
        <span className="form-check-label fs-base ms-6 fw-normal cursor-pointer">
          {option}
        </span>
      </label>
    ));

  return (
    <div className="px-0">
      <div
        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px py-3"
        data-kt-menu="true"
      >
        {actionMenu()}
      </div>
    </div>
  );
}
