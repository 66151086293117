import { Icon } from "@iconify/react";
import { Action, CTADropdown } from "../../components/shared/Dropdown";
import {
  ButtonSize,
  IconCTAButton,
  IconPlacement,
  IconSize,
} from "../quickRound/CTAButtonComponents";

export function ExportImport({
  actions,
  onAction,
  text,
}: {
  actions: Action[];
  onAction: (action: Action) => void;
  text?: string;
}) {
  return (
    <CTADropdown
      className="items-center"
      dropdownClassName="bottom-auto"
      actions={actions}
      onAction={onAction}
      render={() => (
        <IconCTAButton
          buttonSize={ButtonSize.SMALL}
          value={text ?? "Export"}
          iconName={"ri:arrow-drop-down-line"}
          iconPlacement={IconPlacement.RIGHT}
          iconSize={IconSize.L}
        />
      )}
    />
  );
}
