import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  CellClickedEvent,
  ColDef,
  GridApi,
  SideBarDef,
} from "ag-grid-community";
import { toast } from "react-toastify";

import { useNavigate } from "react-router";
import { Dialog } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import { Action, CTADropdown } from "../../components/shared/Dropdown";
import { agConfigDashboardSideBar } from "../dashboardPage/AgGridConfig";

import {
  getCurrencySymbol,
  getCurrencyType,
} from "../../utils/currencyFormatter";

import { Box, HStack, useGetCompanyName } from "../../components/utils";
import GenericTableHeader from "../../shared/TableHeader";
import {
  IconCTAButton,
  PrimaryCTAButton,
} from "../quickRound/CTAButtonComponents";
import QuickSearch from "../rounds/QuickSearch";
import { useAuthStore } from "../../store";
import {
  useGetOnFilterState,
  usePostOnFilterState,
} from "../dashboardPage/AgGridCacheQuery";

import { tableCellStyle } from "../../components/TableComponent";

import { useAgGridTableState } from "../../store/agGridTableStore";
import SearchInput from "../../components/shared/SearchInput";

import {
  ConversionMethodRender,
  ConversionRatioRender,
  DiscountRender,
  InvestmentRender,
  MethodRender,
  PARValueRender,
  SecurityNameRender,
  SecurityTypeRender,
  valuationCapRender,
} from "./ConvertibleModelAgComponent";
import { ConvertibleModel, NonPricedRoundModel } from "../../types/Modeling";
import AddConvertibleDetailsPopUp from "./AddConvertibleDetailsPopUp";
import { useNewConvertibleModelStore } from "./ConvertibleModelStore";
import NextPriceRoundComponent, {
  calculateConvertibleHoldings,
  NonPricedDatamodel,
} from "./NextPriceRoundComponent";

function ConvertibleModelAgGridTable({
  convertibleModelTableData,
  setConvertiblesDataChanged,
  sharedPage,
}: {
  convertibleModelTableData: ConvertibleModel[];
  setConvertiblesDataChanged: Function;
  sharedPage: boolean;
}) {
  const currencySymbol = getCurrencySymbol();
  const companyName = useGetCompanyName();
  const companyId = useAuthStore().companyId ?? "";
  const userId = useAuthStore().user?.userId ?? 0;
  const gridApi = useRef<any>(null);

  const displayedRowCount = gridApi.current?.api.getDisplayedRowCount();
  const convertibleModelStore = useNewConvertibleModelStore();

  const { refetch, data: filterState } = useGetOnFilterState(
    `${userId}`,
    companyId
  );

  const [dialog, setDialog] = useState<{
    open: boolean;
    name?: string;
    data?: ConvertibleModel;
    mode: string;
  }>({
    open: false,
    mode: "",
  });

  const agTableStore = useAgGridTableState();
  useEffect(() => {
    refetch().then((data) => {
      if (data.data) agTableStore.setState(data.data);
      setColumnSetting(data.data?.convertibleModelColumnModel);
      setFilterSetting(data.data?.convertibleModelFilterModel);
    });
  }, []);

  const { mutate: postOnFilter } = usePostOnFilterState();

  function getColumnSetting() {
    if (gridApi.current) return gridApi.current.columnApi?.getColumnState();
    return {};
  }

  function setColumnSetting(model: any) {
    if (gridApi.current)
      gridApi.current.columnApi.applyColumnState({ state: model });
    return {};
  }

  // Gets filter model via the grid API
  const getFilterSetting = () => {
    if (gridApi.current) return gridApi.current.api?.getFilterModel();
    return {};
  };

  const setFilterSetting = (model: any) => {
    if (gridApi.current) return gridApi.current.api?.setFilterModel(model);
    return {};
  };

  const uploadFilterAndColumn = async () => {
    const columnState = await getColumnSetting();
    const filterState = await getFilterSetting();
    postOnFilter({
      userId: `${userId}`,
      companyId,
      filterData: {
        ...agTableStore.state,
        convertibleModelColumnModel: columnState,
        convertibleModelFilterModel: filterState,
      },
    });
  };

  const componentsRegistery = useMemo(
    () => ({
      securityType: memo(SecurityTypeRender),
      method: memo(MethodRender),
      securityName: memo(SecurityNameRender),
      investment: memo(InvestmentRender),
      conversionRatio: memo(ConversionRatioRender),
      parValue: memo(PARValueRender),
      valuationCap: memo(valuationCapRender),
      discount: memo(DiscountRender),
      conversionMethod: memo(ConversionMethodRender),
    }),
    []
  );

  const navigate = useNavigate();

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      autoHeight: true,
      wrapHeaderText: true,
      suppressColumnVirtualisation: true,
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      filterParams: {
        buttons: ["reset"],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
      flex: 1,
    }),
    []
  );
  const columnDefs: ColDef[] = useMemo(() => {
    const columnDef: ColDef[] = [
      {
        headerName: "Security Name",
        field: "securityName",
        filter: "agSetColumnFilter",
        cellRenderer: "securityName",
        cellStyle: tableCellStyle,
        sortable: false,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipSecurityName",
        minWidth: 300,
      },
      {
        headerName: "Security Type",
        field: "securityType",
        filter: "agSetColumnFilter",
        cellRenderer: "securityType",
        cellStyle: tableCellStyle,
        sortable: false,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipSecurityType",
      },
      {
        headerName: `Investment (${currencySymbol})`,
        field: "investment",
        filter: "agNumberColumnFilter",
        cellRenderer: "investment",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipInvestment",
      },

      {
        headerName: `Valuation Cap (${currencySymbol})`,
        field: "valuationCap",
        filter: "agNumberColumnFilter",
        cellRenderer: "valuationCap",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipValuationCap",
      },
      {
        headerName: `Discount (%)`,
        field: "discount",
        filter: "agNumberColumnFilter",
        cellRenderer: "discount",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipDiscount",
      },
    ];

    if (!sharedPage) {
      columnDef.push({
        headerName: "",
        field: "action",
        pinned: "right",
        hide: false,
        width: 80,
        maxWidth: 80,
        filter: false,
        menuTabs: [],
        colId: "action-column",
        suppressNavigable: true,
        suppressColumnsToolPanel: true,
        cellStyle: { paddingTop: "14px", lineHeight: "20px" },
        resizable: false,
        sortable: false,
        cellRendererParams: (value: any) =>
          value.data.actions ? value.data.actions?.props : <div></div>,
        cellRenderer: CTADropdown,
      });
    }

    return columnDef;
  }, []);

  function handleModifyAction(action: string, rowData: ConvertibleModel) {
    if (action === "Edit") {
      setDialog({
        open: true,
        name: "Convertible",
        mode: "Edit",
        data: rowData,
      });
    }
    if (action === "Delete") {
      const convertibleModelDetails =
        convertibleModelStore.convertibleModel.slice();
      const index = convertibleModelDetails.findIndex(
        (model) => model.uuid === rowData.uuid
      );

      convertibleModelDetails.splice(index, 1);
      convertibleModelStore.setConvertibleModel(convertibleModelDetails);
      toast("Convertible Model deleted Successfully!", {
        type: "success",
        autoClose: 2000,
      });
      setConvertiblesDataChanged(true);
    } else if (action === "Exclude For Conversion") {
      const convertibleModelDetails =
        convertibleModelStore.convertibleModel.slice();
      const index = convertibleModelDetails.findIndex(
        (model) => model.uuid === rowData.uuid
      );
      convertibleModelStore.convertibleModel[index].excluded = true;
      convertibleModelStore.setConvertibleModel(convertibleModelDetails);
    } else if (action === "Include For Conversion") {
      const convertibleModelDetails =
        convertibleModelStore.convertibleModel.slice();
      const index = convertibleModelDetails.findIndex(
        (model) => model.uuid === rowData.uuid
      );
      convertibleModelStore.convertibleModel[index].excluded = false;
      convertibleModelStore.setConvertibleModel(convertibleModelDetails);
    }
  }

  const gridOptions = {
    suppressRowVirtualisation: true, // Add this line to disable row virtualization
    paginationAutoPageSize: false, // Optional: Set to false if using custom pagination
    suppressScrollOnNewData: true, // Optional: Set to true to prevent scrolling to the top on
  };

  const currencyType = getCurrencyType();

  const rowData = useMemo(
    () =>
      convertibleModelStore.convertibleModel.map((template, index) => ({
        uuid: template.uuid,
        securityType: template.securityType,
        method: template.method,
        model: template.isModel,
        tooltipSecurityType: template.securityType,
        securityName: template.securityName,
        tooltipSecurityName: template.securityName,
        investment: template.investment,
        tooltipInvestment: template.investment?.toLocaleString(currencyType),
        parValue: template.parValue,
        valuationCap: template.valuationCap,
        tooltipValuationCap:
          template.valuationCap?.toLocaleString(currencyType),
        discount: template.discount,
        tooltipDiscount: template.discount?.toLocaleString(currencyType),
        excluded: template.excluded,

        currencySymbol,
        currencyType,
        actions:
          template.isModel === true ? (
            <CTADropdown
              actions={[
                {
                  name: "Edit",
                  disabled: false,
                },
                {
                  name: "Delete",
                  disabled: false,
                },
                {
                  name: template.excluded
                    ? "Include For Conversion"
                    : "Exclude For Conversion",
                  disabled: false,
                },
              ]}
              onAction={(action) => {
                handleModifyAction(action.name, template);
              }}
            />
          ) : (
            <CTADropdown
              actions={[
                {
                  name: template.excluded
                    ? "Include For Conversion"
                    : "Exclude For Conversion",
                  disabled: false,
                },
              ]}
              onAction={(action) => {
                handleModifyAction(action.name, template);
              }}
            />
          ),
      })),
    [convertibleModelTableData, convertibleModelStore.convertibleModel]
  );

  function setTableHeight() {
    if (displayedRowCount === 1) {
      return (displayedRowCount + 2.75) * 60;
    } else if (displayedRowCount === 2) {
      return (displayedRowCount + 2.68) * 60;
    } else if (displayedRowCount === 3) {
      return (displayedRowCount + 3) * 60;
    } else if (displayedRowCount === 4) {
      return (displayedRowCount + 2.55) * 60;
    } else if (displayedRowCount === 5) {
      return (displayedRowCount + 2.48) * 60;
    } else if (displayedRowCount === 6) {
      return (displayedRowCount + 2.42) * 60;
    } else if (displayedRowCount === 7) {
      return (displayedRowCount + 2.35) * 60;
    } else if (displayedRowCount === 8) {
      return (displayedRowCount + 2.28) * 60;
    } else if (displayedRowCount === 9) {
      return (displayedRowCount + 2.22) * 60;
    } else {
      return 10 * 60;
    }
  }

  const [isColumnOpen, setIsColumnOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const openToolPanel = (key: any) => {
    if (key === "columns") {
      if (gridApi) {
        if (!isColumnOpen) gridApi?.current?.api?.openToolPanel(key);
        else gridApi?.current?.api?.closeToolPanel();
        setIsColumnOpen((state) => !state);
        setIsFilterOpen(false);
      }
    } else if (key === "filters") {
      if (gridApi) {
        if (!isFilterOpen) gridApi?.current?.api?.openToolPanel(key);
        else gridApi?.current?.api?.closeToolPanel();
        setIsFilterOpen((state) => !state);
        setIsColumnOpen(false);
      }
    }
  };

  const [filteredRowData, setFilteredRowData] = useState<any>([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const onAgGridFilterChanged = (grid: any) => {
    const filtersApplied = grid.api.isAnyFilterPresent();
    setIsFilterApplied(filtersApplied);

    const filteredData = grid.api
      .getModel()
      .rowsToDisplay.map((node: any) => node.data);
    setFilteredRowData(filteredData);
    uploadFilterAndColumn();
  };
  return (
    <div className="bg-white border rounded-md shadow-box border-borderColor">
      <HStack className="flex-col items-start justify-start py-2 lg:flex-row lg:justify-between lg:items-center">
        <GenericTableHeader heading={"Convertible"} subHeading={""} />
        <HStack className="items-center justify-between w-full gap-4 px-8 py-2 lg:w-auto lg:justify-end">
          <SearchInput
            placeholder={`Search`}
            onChange={(e: any) => {
              gridApi.current.api.setQuickFilter(e.target.value);
            }}
          />
          <HStack className="gap-4">
            <IconCTAButton
              value={"Columns"}
              onClick={() => openToolPanel("columns")}
              iconName={"fluent:column-triple-edit-20-regular"}
              className={`px-4 font-medium items-center flex flex-row ${
                isColumnOpen ? "text-orange-501" : "text-gray-400"
              }`}
            />
            <IconCTAButton
              value={"Filters"}
              onClick={() => openToolPanel("filters")}
              iconName={"material-symbols:filter-alt"}
              className={`px-4 font-medium items-center flex flex-row ${
                isFilterOpen ? "text-orange-501" : "text-gray-400"
              }`}
            />
          </HStack>
          {!sharedPage && (
            <PrimaryCTAButton
              event-name="Add Convertible"
              className="h-8 text-xs2 whitespace-nowrap"
              onClick={() => {
                setDialog({
                  open: true,
                  name: "Convertible",
                  mode: "Add",
                });
              }}
            >
              Add New
            </PrimaryCTAButton>
          )}
        </HStack>
      </HStack>
      <HStack className="justify-between w-full">
        <Box
          style={{
            height: setTableHeight(),
          }}
          className="w-full h-full max-h-full overflow-x-auto ag-theme-material "
        >
          <AgGridReact
            sideBar={agConfigDashboardSideBar}
            onGridReady={(params) => {
              // Store the grid API referen
              gridApi.current = params;
            }}
            components={componentsRegistery}
            alwaysShowHorizontalScroll={false}
            alwaysMultiSort
            animateRows={true}
            enableCharts={true}
            enableRangeSelection={true}
            defaultColDef={defaultColDef}
            onFilterChanged={onAgGridFilterChanged}
            onColumnEverythingChanged={uploadFilterAndColumn}
            rowData={rowData}
            columnDefs={columnDefs}
            pagination={false}
            suppressRowTransform={true}
            suppressCopyRowsToClipboard={true}
            suppressCopySingleCellRanges={true}
            suppressCellFocus={true}
            suppressMenuHide={false}
            onColumnResized={uploadFilterAndColumn}
            tooltipShowDelay={1000}
            tooltipInteraction={true}
            rowClass={"border-t border-dashed "}
            overlayNoRowsTemplate={
              '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow; margin-top: 50px;">No Rows To Show</span>'
            }
            gridOptions={gridOptions}
            getRowStyle={(params) => {
              if (params.rowIndex % 2 === 0) {
                return { background: "#f8f8f8" };
              } else {
                return { background: "#ffffff" };
              }
            }}
          ></AgGridReact>
        </Box>

        <Dialog open={dialog.open} maxWidth="md">
          {dialog.open && dialog.name === "Convertible" && (
            <AddConvertibleDetailsPopUp
              onPrimaryAction={() =>
                setDialog({
                  open: false,
                  name: "Convertible",
                  mode: dialog.mode,
                  data: dialog.data,
                })
              }
              onSecondaryAction={() =>
                setDialog({
                  open: false,
                  name: "Convertible",
                  mode: dialog.mode,
                })
              }
              data={dialog.data}
              mode={dialog.mode}
              setConvertiblesDataChanged={setConvertiblesDataChanged}
            />
          )}
        </Dialog>
      </HStack>
    </div>
  );
}

export default ConvertibleModelAgGridTable;
