/* eslint-disable quote-props */
export const currencyTypes: CurrencyTypeModel = {
  INR: "en-IN",
  USD: "en-US",
  EUR: "en-EU",
  GBP: "en-GB",
  AED: "ar-AE",
  AUD: "en-AU",
  CNY: "zh-CN",
  JPY: "ja-JP",
  SGD: "en-SG",
};

interface CurrencyTypeModel {
  [key: string]: string;
}

//Function to extract locale using company short name.
export function getLocaleByCompanyShortName(
  shortName: string
): string | undefined {
  return currencyTypes[shortName.toUpperCase()];
}

export const currencies = [
  "AED - د.إ",
  "AUD - A$",
  "CNY - ¥",
  "EUR - €",
  "GBP - £",
  "INR - ₹",
  "JPY - ¥",
  "SGD - S$",
  "USD - $",
];
