import { useRef } from "react";
import _ from "lodash";
import { useNavigate } from "react-router";
import { Box, HStack, VStack } from "../../components/utils";
import {
  tableEntryStyle,
  tableFirstColStyle,
} from "../../components/TableComponent";

import { limitString } from "../../utils/string";
import { getSecurityColor } from "../../utils/historicUtilities";
import { formatToReadableNumber } from "../../utils/currencyRoboto";
import RoundTag from "../../shared/RoundTag";
import { checkVoid } from "../../utils/transactionUtils";
import ShareholderIdentifier from "../../shared/ShareholderIdentifier";
import { formatDisplayDate } from "../../utils/date";

export function SecurityClassRender(data: any) {
  return (
    <td className="pt-2 whitespace-nowrap">
      {data.data.securityClass?.length > 24 ? (
        <p className={`${tableFirstColStyle}`}>
          {limitString(data.data?.securityClass || "", 24)}
        </p>
      ) : (
        <p className={`${tableFirstColStyle}`}>
          {limitString(data.data?.securityClass || "", 24)}
        </p>
      )}

      <p className="text-left font-semibold text-xxs1 text-[#464E5F]/[0.5]">
        {data.data.icin}
      </p>
    </td>
  );
}

export function SecurityTypeRender(data: any) {
  return (
    <div className="pt-2 whitespace-nowrap">
      {!checkVoid(data.data.securityType) ? (
        data.data.securityType !== "Total" ? (
          <p
            className={`${getSecurityColor(
              data.data.securityType
            )} text-xs text-center w-24 align-middle font-semibold leading-5 rounded-sm mb-1`}
          >
            {data.data.securityType}
          </p>
        ) : (
          <p className={tableEntryStyle}>{data.data.securityType}</p>
        )
      ) : (
        <p className={`${tableEntryStyle}`}>-</p>
      )}
    </div>
  );
}

export function PARValueRender(data: any) {
  return (
    <div className="pt-2 whitespace-nowrap">
      {!checkVoid(data.data.parValue) ? (
        <p className={tableEntryStyle}>
          {formatToReadableNumber({
            value: data.data?.parValue || 0,
            format: data.data.currencyType,
          })}
        </p>
      ) : (
        <p className={tableEntryStyle}>-</p>
      )}
    </div>
  );
}

export function NumberOfSharesRender(data: any) {
  return (
    <div className="pt-2 whitespace-nowrap">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.data?.numberOfShares,
          format: data.data.currencyType,
        })}
      </p>
    </div>
  );
}

export function ShareHolderRender(data: any) {
  const navigate = useNavigate();
  const onClickNavigate = (url: string) => navigate(url);
  return (
    <div className="pt-1 pl-1 whitespace-nowrap">
      {!checkVoid(data.data.shareholder) ? (
        <ShareholderIdentifier
          shareholderName={data.data.shareholder || []}
          type={data.data.securityType}
          itemsToDisplay={3}
          navigate={data.data.cin ? () => {} : onClickNavigate}
        />
      ) : (
        <p className={`${tableEntryStyle} pt-1`}>-</p>
      )}
    </div>
  );
}

export function AmountInvestedRender(data: any) {
  return (
    <div className="pt-2 whitespace-nowrap">
      <p className={tableEntryStyle}>
        {data.data.type?.toLowerCase() === "forfeited" &&
        data.data.amountInvested === 0 ? (
          <span>-</span>
        ) : (
          formatToReadableNumber({
            value: data.data?.amountInvested,
            format: data.data.currencyType,
          })
        )}
      </p>
    </div>
  );
}

export function DateOfSecurityRender(data: any) {
  return (
    <div className="pt-2 whitespace-nowrap">
      <p className={tableEntryStyle}>
        {data.data.date ? formatDisplayDate(data.data.date) : "-"}
      </p>
    </div>
  );
}

export function LiquidationPreferencesRender(data: any) {
  return (
    <div className="pt-2 whitespace-nowrap">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.data?.liquidationPreferences,
          format: data.data.currencyType,
        })}
      </p>
      <HStack className="py-2 text-xxs">
        {data.data.securityClass !== "Total" &&
          data.data?.investmentMultiple && (
            <p className="pr-2 ">{`${data.data?.investmentMultiple} X`}</p>
          )}
        {data.data?.isParticipation && data.data.securityClass !== "Total" && (
          <>
            <p>|</p>
            <p>
              <p className="pl-2">
                {formatToReadableNumber({
                  value: data.data?.participationCap,
                  format: data.data.currencyType,
                })}
              </p>
            </p>
          </>
        )}
      </HStack>
    </div>
  );
}

export function RoundsRender(data: any) {
  const navigate = useNavigate();
  const onClickNavigate = (url: string) => navigate(url);
  return (
    <div className="pt-1 whitespace-nowrap">
      {!checkVoid(data.data.rounds) ? (
        <RoundTag
          roundNames={data.data.rounds || []}
          navigate={data.data.cin ? () => {} : onClickNavigate}
        />
      ) : (
        <p className={`${tableEntryStyle} pt-1`}>-</p>
      )}
    </div>
  );
}
