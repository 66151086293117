import { ShieldExclamationIcon } from "@heroicons/react/24/solid";
import { motion } from "framer-motion";
import { useNavigate, useParams } from "react-router";

export default function DeniedPage() {
  const navigate = useNavigate();

  const { id } = useParams() as {
    id: string;
  };

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-10 w-auto"
            src={"https://equion-dev.s3.us-west-2.amazonaws.com/logonew.svg"}
            alt="hissa_logo"
          />
        </div>
        (
        <motion.div
          initial={{ opacity: 0, scale: 1 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
        >
          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
            <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
              <div className="flex flex-col items-center justify-center text-center space-y-2">
                <ShieldExclamationIcon className="h-12 w-12 text-secondary" />
                <h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                  Access Denied
                </h2>
              </div>
              <form className="space-y-6" id="otpForm">
                <div>
                  <label
                    htmlFor="otp"
                    className="block text-sm font-medium leading-6  text-gray-600 text-center"
                  >
                    oops sorry, looks like you dont have access for the
                    requested page.
                  </label>
                </div>

                <div>
                  <button
                    type="submit"
                    className="flex w-full justify-center rounded-md bg-primary px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-primary/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Go to Login
                  </button>
                </div>
              </form>
            </div>
          </div>
        </motion.div>
        )
      </div>
    </>
  );
}
