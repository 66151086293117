import { Icon } from "@iconify/react";
import { v4 as uuidv4 } from "uuid";
import { Form, Formik } from "formik";
import { getNames } from "country-list";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { HStack, VStack, Error } from "../../components/utils";
import { getCurrencyType } from "../../utils/currencyFormatter";
import { Input, Label } from "../../components/shared/InputField";
import {
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../quickRound/CTAButtonComponents";
import { Select } from "../../components/shared/Select";
import { SwitchButton } from "../../components/shared/SwitchButton";

import {
  newShareholderValidationSchema,
  validationBuybackShareHolderSchema,
} from "./newTransactionValidationSchema";
import { useNewShareholderInstrumentStore } from "../../store/newShareholderAndInstrumentStore";

import { useShareholderInstrumentStore } from "../../store/shareholderInstrumentStore";
import { ShowNumberInWords } from "../../components/shared/UiElement";
import { currencyTypes } from "../../constants/CompanyCurrencyType";
import {
  AddBuyBackTransactionModel,
  BuybackTransactionDataModel,
} from "./AddBuybackAgTable";
import {
  convertToNumber,
  filterOnlyNumbers,
  numberWithCommas,
} from "../../utils/numUtils";

type AddBuybackPopupProps = {
  onPrimaryAction: () => void;
  onSecondaryAction: () => void;
  data: any;
  mode: string;
};

export default function AddBuybackPopup({
  onPrimaryAction,
  onSecondaryAction,
  data,
  mode,
}: AddBuybackPopupProps) {
  const buybackTransactionStore = useNewShareholderInstrumentStore();
  const shareholderInstruments = useShareholderInstrumentStore();
  const currencyType = getCurrencyType();
  const shareHolders: string[] = shareholderInstruments.shareholders.map(
    (shareHolderDetail) => shareHolderDetail.name
  );

  const instruments: string[] = shareholderInstruments.instrumentClasses
    .filter(
      (instrument) =>
        instrument.isCurrent === true || instrument.isCurrent === undefined
    )
    .map((instrument) => instrument.name);

  const [onHoverNumberOfShares, setOnHoverNumberOfShares] = useState(false);
  const [onHoverAmount, setOnHoverAmount] = useState(false);
  const [formatNumberOfShares, setFormatNumberOfShares] = useState("");
  const [formatAmount, setFormatAmount] = useState("");

  const initialBuybackShareHolderValues: AddBuyBackTransactionModel = {
    uuid: data?.uuid || "",

    name: data?.name || "",
    noOfShares: data?.numberOfShares || 0,
    securityClass: data?.securityClass || "",
    amount: data?.amountInvested || 0,
  };

  useEffect(() => {
    if (initialBuybackShareHolderValues.noOfShares) {
      setFormatNumberOfShares(`${initialBuybackShareHolderValues.noOfShares}`);
    }
    if (initialBuybackShareHolderValues.amount) {
      setFormatAmount(`${initialBuybackShareHolderValues.amount}`);
    }
  }, [initialBuybackShareHolderValues.noOfShares]);

  const handleSubmit = (values: AddBuyBackTransactionModel) => {
    const exists = buybackTransactionStore.buyback.some(
      (transaction) =>
        transaction.name === values.name &&
        transaction.securityClass === values.securityClass &&
        transaction.numberOfShares === values.noOfShares &&
        transaction.amountInvested === values.amount
    );

    if (exists) {
      toast("Transaction already exists!", { type: "error", autoClose: 2000 });
      return;
    }
    const existingIndex = buybackTransactionStore.buyback.findIndex(
      (transaction: any) => transaction.uuid === values.uuid
    );
    const transaction: BuybackTransactionDataModel = {
      uuid: uuidv4(),

      name: values.name,
      investorType:
        shareholderInstruments.shareholders.find(
          (shareHolderDetail) => shareHolderDetail.name === values.name
        )?.type || "",
      securityClass: values.securityClass,
      security:
        shareholderInstruments.instrumentClasses.find(
          (instrumentDetail) => instrumentDetail.name === values.securityClass
        )?.type || "",

      instrumentClassId: shareholderInstruments.instrumentClasses.find(
        (instrumentDetail) => instrumentDetail.name === values.securityClass
      )?.id,
      investorId: shareholderInstruments.shareholders.find(
        (shareHolderDetail) => shareHolderDetail.name === values.name
      )?.id,

      numberOfShares: values.noOfShares,
      amountInvested: values.amount,

      par: shareholderInstruments.instrumentClasses.find(
        (instrumentDetail) => instrumentDetail.name === values.securityClass
      )?.parValue,
      conversionRatio: `1: ${
        shareholderInstruments.instrumentClasses.find(
          (instrumentDetail) => instrumentDetail.name === values.securityClass
        )?.conversionRatio
      }`,
    };

    if (existingIndex !== -1) {
      buybackTransactionStore.buyback[existingIndex] = transaction;
      buybackTransactionStore.setBuyback([...buybackTransactionStore.buyback]);
      toast("Transaction updated successfully", {
        type: "success",
        autoClose: 2000,
      });
    } else {
      buybackTransactionStore.setBuyback([
        ...buybackTransactionStore.buyback,
        transaction,
      ]);
      toast("Transaction added successfully", {
        type: "success",
        autoClose: 2000,
      });
    }
    onPrimaryAction();
  };

  return (
    <VStack>
      <HStack className="justify-between px-6 py-4 border-b">
        <p className="text-base font-medium">{mode} Buyback</p>
        <XMarkIcon className="w-6 h-6" onClick={() => onSecondaryAction()} />
      </HStack>

      <div className="px-4 py-4 bg-white rounded-lg">
        <VStack className="justify-between">
          <Formik
            key="buybackShareHolder"
            enableReinitialize={true}
            initialValues={initialBuybackShareHolderValues}
            onSubmit={(values, { resetForm }) => {
              handleSubmit(values);
              resetForm();
            }}
            validationSchema={validationBuybackShareHolderSchema}
          >
            {(formik) => (
              <>
                <Form key="shareholder">
                  <VStack className="w-full gap-6 px-2 py-4">
                    <VStack className="gap-8">
                      <HStack className="flex-col gap-8 md:flex-row">
                        <div className="flex-1 w-72">
                          <Label className="text-sm font-normal">Name</Label>
                          <Select
                            options={shareHolders.filter(
                              (shareHolder) =>
                                shareHolder.toLowerCase().trim() !== "esop pool"
                            )}
                            {...formik.getFieldProps("name")}
                          />
                          {formik.touched?.name && formik.errors?.name && (
                            <Error text={formik.errors?.name} />
                          )}
                        </div>
                        <div className="flex-1 w-72">
                          <Label className="text-sm font-normal">
                            Security Class
                          </Label>
                          <Select
                            options={instruments}
                            {...formik.getFieldProps("securityClass")}
                          />
                          {formik.touched?.securityClass &&
                            formik.errors?.securityClass && (
                              <Error text={formik.errors?.securityClass} />
                            )}
                        </div>
                      </HStack>
                      <HStack className="grid w-full grid-cols-1 gap-8 mx-auto md:grid-cols-2">
                        <div className="flex-1 w-72">
                          <Label className="text-sm font-normal">
                            Number of Shares
                          </Label>
                          <Input
                            type="text"
                            onMouseEnter={() => {
                              setOnHoverNumberOfShares(true);
                            }}
                            onMouseLeave={() => {
                              setOnHoverNumberOfShares(false);
                            }}
                            onChange={(e: any) => {
                              const values = parseInt(
                                e.target.value?.replaceAll(",", "") || 0,
                                10
                              );
                              formik.setFieldValue("noOfShares", values);
                              formik.handleChange("noOfShares");
                            }}
                            value={Intl.NumberFormat(currencyType).format(
                              formik.values.noOfShares
                            )}
                          />
                          {onHoverNumberOfShares && (
                            <ShowNumberInWords
                              value={formik.values.noOfShares}
                              currency={currencyType}
                            />
                          )}

                          {formik.touched.noOfShares &&
                            formik.errors?.noOfShares && (
                              <Error text={formik.errors?.noOfShares} />
                            )}
                        </div>
                        <div className="flex-1 w-72">
                          <Label className="text-sm font-normal">Amount</Label>
                          <Input
                            type="text"
                            onMouseEnter={() => {
                              setOnHoverAmount(true);
                            }}
                            onMouseLeave={() => {
                              setOnHoverAmount(false);
                            }}
                            onChange={(e: any) => {
                              const filteredValue = filterOnlyNumbers(
                                e.target.value
                              );
                              setFormatAmount(filteredValue);
                              const values = convertToNumber(filteredValue);

                              formik.setFieldValue("amount", values);
                              formik.handleChange("amount");
                            }}
                            value={numberWithCommas(
                              formatAmount ?? `${formik.values.amount!}`,
                              currencyType
                            )}
                          />
                          {onHoverAmount && (
                            <ShowNumberInWords
                              value={formik.values.amount}
                              currency={currencyType}
                            />
                          )}

                          {formik.touched?.amount && formik.errors?.amount && (
                            <Error text={formik.errors?.amount} />
                          )}
                        </div>
                      </HStack>
                    </VStack>

                    <HStack className="justify-between mt-10">
                      <SecondaryCTAButton
                        event-name="Cancel Buyback Modal"
                        onClick={() => onSecondaryAction()}
                        type="reset"
                        className="text-red-500"
                      >
                        Cancel
                      </SecondaryCTAButton>
                      <PrimaryCTAButton
                        event-name="Save Buyback Modal"
                        type="submit"
                      >
                        {mode}
                      </PrimaryCTAButton>
                    </HStack>
                  </VStack>
                </Form>
              </>
            )}
          </Formik>
        </VStack>
      </div>
    </VStack>
  );
}
