import { Dialog } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import AlertDialog from "../../components/shared/AlertDialog";
import { HStack, VStack, Box } from "../../components/utils";
import { useGetInstrumentAndShareHolder } from "../../queries/quickRound";
import {
  useDeleteOnGoingTransaction,
  usePostOnGoingTransaction,
  useDiscardQuickRound,
  usePostTransactionRoundDetail,
} from "../../queries/transactionRound";
import { useAuthStore } from "../../store";
import { useShareholderInstrumentStore } from "../../store/shareholderInstrumentStore";
import {
  extractSecondaryTransaction,
  extractTransaction,
} from "./ExtractionUtility";
import AddPrimary from "./AddPrimary";
import { useNewShareholderInstrumentStore } from "../../store/newShareholderAndInstrumentStore";
import {
  PrimaryTransaction,
  SecondaryTransaction,
  TransactionDetail,
} from "./RoundTransactionModel";
import { useQuickRoundTransactionStore } from "../../store/useQuickTransactionStore";
import AddSecondary from "./AddSecondary";
import {
  ButtonSize,
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../quickRound/CTAButtonComponents";
import AddPrimaryPopup from "./AddPrimaryPopup";
import AddSecondaryPopup from "./AddSecondaryPopup";
import AddShareholderPopup from "./AddShareholderPopup";
import AddInstrumentPopup from "./AddInstrumentPopup";
import AgGridQuickSecondaryTable from "./AddSecondaryAgTable";
import { getCompanyCurrency } from "../../utils/currencyFormatter";

export default function AddTransactionSecondary() {
  const [selected, setSelected] = useState(false);
  const companyId = useAuthStore.getState().companyId || "";
  const {
    state: template,
    setState: setTemplate,
    reset: resetTemplate,
  } = useQuickRoundTransactionStore();

  const secondaryTransactionStore = useNewShareholderInstrumentStore();
  const { mutate: deleteOnGoingTransaction } =
    useDeleteOnGoingTransaction(companyId);

  const pathName = window.location.href.split("/");
  const roundType = pathName[4];

  const [dialog, setDialog] = useState<{
    open: boolean;
    message?: string;
  }>({
    open: false,
  });

  const navigate = useNavigate();
  const { refetch: discardQuickRound } = useDiscardQuickRound();

  const shareholderInstruments = useShareholderInstrumentStore();

  const handleDiscard = () => {
    setDialog({ open: false });
    discardQuickRound();
    resetTemplate();
    deleteOnGoingTransaction(companyId);
    navigate("/transactions/allTransactions");
  };
  const companyCurrency = getCompanyCurrency();

  useEffect(() => {
    const primary = extractTransaction(
      template.newRoundInput?.primaryTransactions || [],
      shareholderInstruments.shareholders,
      shareholderInstruments.instrumentClasses,
      companyCurrency
    );
    const secondary = extractSecondaryTransaction(
      template.newRoundInput?.secondaryTransactions || [],
      shareholderInstruments.shareholders,
      shareholderInstruments.instrumentClasses
    );
    secondaryTransactionStore.setPrimary(primary);
    secondaryTransactionStore.setSecondary(secondary);
  }, []);

  const [addDialog, setAddDialog] = useState<{
    open: boolean;
    name?: string;
    data?: any;
  }>({
    open: false,
  });

  return (
    <VStack>
      <Box className="px-8 bg-white rounded-lg ">
        <HStack className="pt-10 pb-4">
          <Dialog open={addDialog.open} maxWidth="md">
            {addDialog.open && addDialog.name === "Secondary" && (
              <AddSecondaryPopup
                pricedRound={null}
                onPrimaryAction={() =>
                  setAddDialog({ open: false, name: "Secondary" })
                }
                onSecondaryAction={() =>
                  setAddDialog({ open: false, name: "Secondary" })
                }
                data={addDialog.data}
                mode={"Add"}
              />
            )}
            {addDialog.open && addDialog.name === "Shareholder" && (
              <AddShareholderPopup
                onPrimaryAction={() =>
                  setAddDialog({ open: false, name: "Shareholder" })
                }
                onSecondaryAction={() =>
                  setAddDialog({ open: false, name: "Shareholder" })
                }
                data={addDialog.data}
              />
            )}
          </Dialog>
          <HStack className="w-full gap-4">
            <p
              //   onClick={() => {
              //     setSelected(true);
              //   }}
              className={"text-lg"}
            >
              Secondary
            </p>
          </HStack>

          <HStack className="gap-4">
            <SecondaryCTAButton
              event-name="Add Buyer Button"
              onClick={() => {
                setAddDialog({ open: true, name: "Shareholder" });
              }}
              buttonSize={ButtonSize.SMALL}
            >
              Add Buyer
            </SecondaryCTAButton>
            <PrimaryCTAButton
              event-name="Add Secondary Button"
              onClick={() => {
                setAddDialog({ open: true, name: "Secondary" });
              }}
              buttonSize={ButtonSize.SMALL}
            >
              Add Secondary
            </PrimaryCTAButton>
          </HStack>
        </HStack>
        <Dialog open={dialog.open} maxWidth="md">
          <AlertDialog
            onClose={() => setDialog({ open: false })}
            open={dialog.open}
            message={dialog.message}
            onPrimaryAction={handleDiscard}
            onSecondaryAction={() => setDialog({ open: false })}
          />
        </Dialog>
        <AgGridQuickSecondaryTable />
      </Box>
    </VStack>
  );
}
