import { useState } from "react";
import { Dialog } from "@mui/material";
import { HStack, VStack } from "../../components/utils";
import { Label } from "../../components/shared/InputField";
import { SecondaryTransaction } from "./RoundCreationModel";
import {
  ButtonSize,
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../quickRound/CTAButtonComponents";
import NewSecondaryTable from "./NewSecondaryTable";
import AddNewSecondary from "./AddNewSecondary";
import { useRMCreationStore } from "../../store/roundCreationStore";

export default function SecondaryPage() {
  const {
    roundCreation: data,
    sendMessage: onNext,
    reset: disCardRoundCreation,
    setRoundCreation: setRoundState,
  } = useRMCreationStore();

  const [dialog, setDialog] = useState<{
    open: boolean;
    mode?: "Add";
    data?: SecondaryTransaction;
  }>({
    open: false,
  });
  function scrollToTop() {
    const scroller = document.querySelector("#outlet");
    scroller?.scrollTo(0, 0);
  }

  return (
    <VStack className=" bg-white justify-between rounded-lg px-4 py-4 min-h-full">
      <Dialog open={dialog.open} maxWidth="lg">
        {dialog.mode === "Add" && (
          <AddNewSecondary
            data={dialog?.data}
            actionMode={"New"}
            onBack={() => setDialog({ open: false })}
            onNext={onNext}
          />
        )}
      </Dialog>

      <div>
        <HStack className="justify-between ">
          <VStack>
            <Label className="text-xl font-semibold text-gray-dark">
              Secondary Transaction
            </Label>

            <p className="font-medium text-gray-light mb-4">
              Fill in the Secondary Details
            </p>
          </VStack>
          <VStack>
            <SecondaryCTAButton
              className="w-fit"
              onClick={() => {
                setDialog({ open: true, mode: "Add" });
              }}
            >
              + Add
            </SecondaryCTAButton>
            <div></div>
          </VStack>
        </HStack>

        <NewSecondaryTable
          secondary={data.tab6?.transaction || []}
          onNext={onNext}
        />
      </div>
      <HStack className="justify-between gap-4">
        <HStack className="gap-4">
          <SecondaryCTAButton
            type="reset"
            buttonSize={ButtonSize.SMALL}
            onClick={() => {
              onNext({ ...data, currentTab: 5 });
              scrollToTop();
            }}
          >
            Back
          </SecondaryCTAButton>
          <SecondaryCTAButton
            event-name="Discard from Modeling Summary"
            buttonSize={ButtonSize.SMALL}
            onClick={() => {
              disCardRoundCreation(data);
            }}
          >
            Discard
          </SecondaryCTAButton>
        </HStack>
        <PrimaryCTAButton
          event-name="Secondary Page Next"
          onClick={() => {
            setRoundState({
              ...data,
              isLoader: true,
            });
            onNext({ ...data, currentTab: 7 });
            scrollToTop();
          }}
          loading={data.isLoader}
          buttonSize={ButtonSize.SMALL}
          type="submit"
        >
          Next
        </PrimaryCTAButton>
      </HStack>
    </VStack>
  );
}
