import "./App.css";
import "./styles/loader.css";
import "react-toastify/dist/ReactToastify.css";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-community/styles/ag-theme-material.css";
import { QueryClientProvider } from "react-query";
import { ThemeProvider } from "@mui/material";
import { ToastContainer } from "react-toastify";
import { useEffect } from "react";
import * as AgGrid from "ag-grid-enterprise";
import { useLocation } from "react-router-dom";
import Router from "./routes";
import ScrollToTop from "./components/shared/ScrollToTop";
import { muiTheme } from "./theme/mui";
import { queryClient } from "./queries";
import { useAuthStore, useAuthorizationStore } from "./store";
import HandleHissaRedirect from "./components/shared/HandleHissaRedirect";
import {
  capTableAmplitudeInit,
  handleClickForTracking,
  handlePageLoadForTracking,
} from "./amplitudeAnalytics";

const agGridLicenseKey = process.env.REACT_APP_AG_LICENSE_KEY || "";
AgGrid.LicenseManager.setLicenseKey(agGridLicenseKey);

function App() {
  capTableAmplitudeInit();
  useAuthStore();
  useAuthorizationStore();
  const location = useLocation();

  useEffect(() => {
    handlePageLoadForTracking(location);
  }, [location]);
  return (
    <QueryClientProvider client={queryClient}>
      <ScrollToTop />
      <HandleHissaRedirect />
      <ThemeProvider theme={muiTheme}>
        <div
          onClick={(e) => {
            handleClickForTracking(e);
          }}
        >
          <Router />
        </div>
        <ToastContainer position="bottom-right" autoClose={2000} />
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
