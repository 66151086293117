import { useQuery } from "react-query";
import { getType2CompanyOverviewDetails } from "../api/t2Company";
import { getType3CompanyOverviewDetails } from "../api/t3Company";

export function useType3CompanyOverviewDetails(cin: string, token?: string) {
  return useQuery({
    queryKey: ["getType3CompanyOverviewDetails", cin, token],
    queryFn: getType3CompanyOverviewDetails,
  });
}
