import { useEffect, useMemo, useRef, useState } from "react";
import { Badge, Dialog, Tooltip } from "@mui/material";
import { globalFilter, sort } from "../../utils/arrays";
import ArticleModal from "../../modals/ArticleModal";
import {
  InvestorModel,
  ShareholderModel,
  ShareRightModel,
} from "../../types/ShareHolderRightsModel";
import { HStack, VStack } from "../../components/utils";
import {
  EmptyShareHolderRightTable,
  ExtraConditions,
  RightsTextDisplay,
  RightsTypeDisplay,
} from "./RightsAtomicWidgets";
import { useShareHolderFilterTypeStore } from "../../store/rightsfilterStore";
import { useShareHolderTransferFilterTypeStore } from "../../store/transferRightStore";
import InvestorRightsModal from "../../modals/InvestorRightsModal";

function RightsTable({
  shareholders = [],
  rightsTableDataList = [],
  currentTab,
  globalFilterText,
  type,
}: {
  shareholders: ShareholderModel[];
  rightsTableDataList?: ShareRightModel[];
  currentTab: string;
  globalFilterText: string;
  type: string;
}) {
  const shareholderRightsStore =
    currentTab === "Rights"
      ? useShareHolderFilterTypeStore()
      : useShareHolderTransferFilterTypeStore();

  const [rightsTableData, setRightsTableData] = useState<ShareRightModel[]>([]);

  useEffect(() => {
    setRightsTableData(rightsTableDataList);
  }, [rightsTableDataList]);
  const [dialog, setDialog] = useState<{
    open: boolean;
    data?: {
      articlenumber: string;
      description: string;
    };
  }>({
    open: false,
  });

  const currentTableData = useMemo(() => {
    if (!rightsTableData) return [];
    let sortResult: ShareRightModel[] = [];

    sortResult = sort(rightsTableData, "orderNumber", true);

    const filterResult = globalFilter(sortResult, "", [
      "rightName",
      "rightType",
    ])
      .filter(
        (right) =>
          shareholderRightsStore.rightsType.find(
            (rightsName) => rightsName.name === right.rightName
          )?.isVisible
      )
      .filter(
        (right) =>
          shareholderRightsStore.rightsTypeOptions.find(
            (rightsName) => rightsName.name === right.rightType
          )?.isVisible
      );

    return filterResult || [];
  }, [
    globalFilterText,
    shareholderRightsStore.rightsType,
    shareholderRightsStore.rightsTypeOptions,
    rightsTableData,
  ]);

  const getCondition = (condition: string): string => {
    const conditionText =
      condition === "No Restriction" ||
      condition === "Lockin" ||
      condition === "Restricted" ||
      condition === "Reserved Shares" ||
      condition === "Drag Rights" ||
      condition === "Exit" ||
      condition === "Yes" ||
      condition === "No"
        ? condition
        : "Conditional";

    return conditionText;
  };
  const length = currentTableData[0]?.investors?.length || 0;
  const array = new Array(length).fill(false);
  const [toggle, setToggle] = useState(array);

  const [dropdownOpen, setDropDownOpen] = useState(false);
  const contextRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const checkIfClickOutside = (e: any) => {
      if (!(dropdownOpen && contextRef.current?.contains(e.target))) {
        setDropDownOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickOutside);
    };
  }, [dropdownOpen]);

  const getTableShareholder = (shareholderType: string) => {
    const exist =
      globalFilterText !== "" &&
      shareholders
        .filter((shareholder) =>
          shareholder?.shareholderName?.find((sh) =>
            sh.toLowerCase().includes(globalFilterText.toLowerCase())
          )
        )
        .filter((ele) => ele.shareholderType === shareholderType).length > 0;
    return exist;
  };

  return (
    <div className="bg-white mt-4">
      <div className="px-10">
        <Dialog open={dialog.open} maxWidth="md">
          <ArticleModal
            name={dialog.data?.articlenumber || "N/A"}
            description={dialog.data?.description || "Article not available!!"}
            onPrimaryAction={() => {
              setDialog({ open: false });
            }}
          />
        </Dialog>

        {currentTableData.length > 0 && (
          <div className="-my-2 overflow-x-auto sm:-mx-6">
            <div className="inline-block min-w-full py-2 align-middle">
              <div className="overflow-hidden md:rounded-lg border-2 border-stone-200 bg-white">
                <div>
                  <table className="min-w-full bg-white p-5">
                    <thead>
                      <tr className="border-b-2 border-stone-200 bg-gray-50">
                        <th
                          scope="col"
                          className={`py-3.5 border-l-2 border-stone-200  pr-3 text-left text-sm font-semibold text-stone-500
                           sm:pl-6 hover:cursor-pointer ${
                             type === "shareholder" ? "w-96" : ""
                           }`}
                        >
                          Rights
                        </th>
                        {sort(
                          currentTableData[0].investors,
                          currentTab === "Rights"
                            ? "rightsOrderNumber"
                            : "restrictionOrderNumber",
                          true
                        ).map((investorsName, index) => (
                          <>
                            {shareholderRightsStore.investorType.find(
                              (investor) =>
                                investor.name === investorsName.investorType
                            )?.isVisible && (
                              <th
                                scope="col"
                                className={`${
                                  getTableShareholder(
                                    investorsName.investorType
                                  )
                                    ? "bg-[#F4FBFF]"
                                    : ""
                                } py-3.5 border-l border-stone-200 pr-3 text-center text-sm font-semibold text-stone-500 sm:pl-6 hover:cursor-pointer`}
                              >
                                <div ref={contextRef}>
                                  <p
                                    onClick={() => {
                                      const array = new Array(length).fill(
                                        false
                                      );
                                      array[index] = !toggle[index];
                                      setToggle(array);
                                    }}
                                  >
                                    {investorsName.investorType}
                                  </p>
                                  {toggle[index] && (
                                    <div
                                      id="dropdown"
                                      className={`absolute z-10  mt-1 bg-white border divide-y divide-gray-100 rounded shadow-box`}
                                    >
                                      <InvestorRightsModal
                                        name={investorsName.investorType}
                                        shareholders={shareholders}
                                        onPrimaryAction={() => {
                                          const array = new Array(length).fill(
                                            false
                                          );
                                          setToggle(array);
                                        }}
                                      />
                                    </div>
                                  )}
                                </div>
                              </th>
                            )}
                          </>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {currentTableData.map((rightsDetails, index) => (
                        <tr
                          key={`${rightsDetails.rightName}}${index}`}
                          className={`cursor-pointer hover:bg-slate-100 ${
                            !(index % 2) ? "bg-white" : "bg-gray-50"
                          } ${
                            rightsDetails.rightType !==
                            (index === 0
                              ? ""
                              : currentTableData[index - 1].rightType || "")
                              ? "border-t-2 border-stone-200"
                              : ""
                          }`}
                        >
                          <td
                            className=" text-sm  border border-dashed align-top"
                            onClick={() => {
                              const temptable: ShareRightModel = {
                                ...rightsDetails,
                                showDetail: !rightsDetails.showDetail,
                              };
                              currentTableData[index] = temptable;
                              setRightsTableData(currentTableData);
                            }}
                          >
                            <VStack className="flex items-start  text-gray-500">
                              {rightsDetails.rightType !==
                                (index === 0
                                  ? ""
                                  : currentTableData[index - 1].rightType ||
                                    "") && (
                                <RightsTypeDisplay
                                  rightType={rightsDetails.rightType || ""}
                                />
                              )}
                              <div className="py-4 pl-4 pr-3 sm:pl-6">
                                <RightsTextDisplay
                                  rightName={rightsDetails.rightName || ""}
                                  type="Rights"
                                  expanded={rightsDetails.showDetail}
                                />
                              </div>
                            </VStack>
                          </td>
                          {sort(
                            rightsDetails?.investors,
                            currentTab === "Rights"
                              ? "rightsOrderNumber"
                              : "restrictionOrderNumber",
                            true
                          )?.map((investors, i) => (
                            <>
                              {shareholderRightsStore.investorType.find(
                                (investor) =>
                                  investor.name === investors.investorType
                              )?.isVisible && (
                                <td
                                  className={`${
                                    getTableShareholder(investors.investorType)
                                      ? "bg-[#F4FBFF]"
                                      : ""
                                  } py-4 pr-3 text-sm sm:pl-6 items-start ${
                                    investors.condition === "No"
                                      ? "cursor-default "
                                      : ""
                                  }`}
                                >
                                  <div
                                    className="flex items-center text-gray-500"
                                    onClick={
                                      investors.condition === "No"
                                        ? () => {}
                                        : () => {
                                            const temptInvestorValue: InvestorModel =
                                              {
                                                ...investors,
                                                showInvestorDetails:
                                                  !investors.showInvestorDetails,
                                              };
                                            const investorDetails =
                                              rightsDetails?.investors || [];
                                            investorDetails[i] =
                                              temptInvestorValue;
                                            const temptable: ShareRightModel = {
                                              ...rightsDetails,
                                              investors: investorDetails,
                                            };
                                            currentTableData[index] = temptable;
                                            setRightsTableData(
                                              currentTableData
                                            );
                                          }
                                    }
                                  >
                                    <VStack className="mx-auto min-w-32 ">
                                      <HStack className="justify-between gap-4">
                                        {shareholderRightsStore.conditionalType.find(
                                          (condition) =>
                                            condition.name ===
                                            getCondition(
                                              investors.condition || ""
                                            )
                                        )?.isVisible && (
                                          <HStack>
                                            <ExtraConditions
                                              condition={
                                                investors.condition || ""
                                              }
                                              expanded={
                                                investors.showInvestorDetails ||
                                                rightsDetails.showDetail
                                              }
                                              currentTab={currentTab}
                                              isFallAwayRight={
                                                investors.isFallAwayRight ||
                                                false
                                              }
                                              fallAwayRightText={
                                                investors.fallAwayText || ""
                                              }
                                            />
                                          </HStack>
                                        )}
                                        {(investors.showInvestorDetails ||
                                          rightsDetails.showDetail) &&
                                          investors.condition !== "No" &&
                                          (investors.articleNumber || "")
                                            ?.length > 0 && (
                                            <p
                                              className="text-[#000000] bg-[#FFFFFFF] bg-opacity-5 border-[#000000] border px-2  min-w-min cursor-pointer"
                                              onClick={() => {
                                                setDialog({
                                                  open: true,
                                                  data: {
                                                    articlenumber:
                                                      investors?.articleNumber ||
                                                      "N/A",
                                                    description:
                                                      investors?.articleText ||
                                                      "Article not available",
                                                  },
                                                });
                                              }}
                                            >
                                              {investors.articleNumber}
                                            </p>
                                          )}
                                      </HStack>

                                      {(investors.showInvestorDetails ||
                                        rightsDetails.showDetail) &&
                                        investors.condition !== "Yes" &&
                                        investors.condition !== "No" &&
                                        (investors.condition || "")?.length >
                                          0 && (
                                          <p className="mt-2">
                                            {investors.condition
                                              ?.split(/\n/)
                                              .map((line) => (
                                                <div key={line}>{line}</div>
                                              ))}
                                          </p>
                                        )}
                                    </VStack>
                                  </div>
                                </td>
                              )}
                            </>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
        {currentTableData.length === 0 && (
          <EmptyShareHolderRightTable
            message={
              shareholderRightsStore.rightsType.length > 0
                ? "There are no rights available for the selected filter, change filter to view Rights"
                : "Please upload the Shareholderrights template to view the rights"
            }
          />
        )}
      </div>
    </div>
  );
}

export default RightsTable;
