import _ from "lodash";
import { useState } from "react";
import { useNavigate, useParams } from "react-router";
import { HStack, VStack } from "../../components/utils";
import BCHeader from "../../shared/BCHeader";
import { useGetIndividualTermsheet } from "../../queries/termsheet";
import { TermSheetType } from "../../constants/TermsheetConstants";
import { TermSheetHeaderCard } from "./TermsheetHeaderCards";
import {
  IndividualTermsheet,
  NewTermsheetDetails,
} from "../../types/TermsheetModel";
import TermsheetPreCaptableAgTable, {
  GetPrePostCaptableData,
} from "./TermsheetPreCaptableAgTable";
import PrePostPieChart from "./PrePostPieChart";
import { OtherTerms } from "./OtherTerms";
import {
  ButtonSize,
  PrimaryCTAButton,
} from "../quickRound/CTAButtonComponents";
import { useRoundModelStore } from "../../store/roundModelingStore";
import { getIndividualTermsheet } from "../../api/termsheet";
import {
  ExistingInvestorDetails,
  InvestorRightsType,
  InvestorState,
  ModelBuilder,
  NewInvestorDetails,
  OngoingInvestorDetails,
  RoundInputDetails,
} from "../../types/Modeling";
import { useAuthStore } from "../../store";
import { useGetUserRoleGrants } from "../../queries/auth";

export default function IndividualTermSheetPage() {
  const { id } = useParams();
  const _id = id || "";
  const { isPlaceholderData, data: termsheet } = useGetIndividualTermsheet(_id);
  const roundCreationStore = useRoundModelStore();
  const navigate = useNavigate();
  const companyId = useAuthStore().companyId ?? "";
  const [buttonType, setButtonType] = useState("Captable");
  const { refetch, data: userRoleData } = useGetUserRoleGrants();
  const finalCaptableData = GetPrePostCaptableData(
    termsheet as IndividualTermsheet
  );
  let newInvestorIndex: number[] = [];
  if (finalCaptableData) {
    newInvestorIndex = finalCaptableData
      .filter((element) => element?.postHoldingFdbPercentage !== 0)
      .map((element, index) => {
        if (
          element.amountInvestedInRound !== 0 ||
          (element?.preHoldingFdbPercentage === 0 &&
            element?.postHoldingFdbPercentage > 0)
        )
          return index;
        return null;
      })
      .filter((element) => element !== null) as number[];
  }

  async function createRoundModel(data: NewTermsheetDetails, id: string) {
    const termsheet = await getIndividualTermsheet({
      queryKey: ["getIndividualTermsheet", id],
      meta: undefined,
    });
    const { investors, roundDetails, otherTerms } = data;
    const finalCaptableData = await GetPrePostCaptableData(
      termsheet as IndividualTermsheet
    );
    const roundIdentifier: string[] = (
      roundDetails.roundName || "New Round Model"
    ).split(" ");
    const roundData: RoundInputDetails = {
      roundName: roundDetails.roundName || "New Round Model",
      hasToCalculatePps: true,
      roundIdentifier:
        roundIdentifier[0][0] +
        (roundIdentifier.length > 1 ? roundIdentifier[1][0] : ""),
      roundSize: Number(roundDetails.roundSize) || 0,
      preMoneyValuation: Number(roundDetails.preMoneyValuation) || 0,
      postMoneyValuation: Number(roundDetails.postMoneyValuation) || 0,
      postRoundEsopPercentage:
        Number(roundDetails.postRoundEsopPercentage) || 0,
      autoDiluteEsop: Number(roundDetails.postRoundEsopPercentage) === 0,
      pricedRound: roundDetails.pricedRound.toString() === "Yes",
      fdbSharePrice: 0,
    };
    const newInvestorData: NewInvestorDetails = { investorDetails: [] };
    const existingInvestorData: ExistingInvestorDetails = {
      investorDetails: [],
      listOfInvestor: [],
    };
    investors.forEach((investor) => {
      if (investor.companyInvestorId !== "") {
        const shareholderData = finalCaptableData?.filter(
          (shareHolder) =>
            shareHolder.shareholderName === investor.shareholderName
        );

        const existingInvestor =
          termsheet.prePostCaptable.postEsopAdjustedCaptable &&
          termsheet.prePostCaptable.postEsopAdjustedCaptable.filter(
            (item) => item.id === investor.companyInvestorId
          );
        existingInvestorData.investorDetails.push({
          amountToInvest: Number(investor.investmentAmount),
          id: "",
          investorId: "",
          investorName: investor.shareholderName,
          postHoldingPercentage:
            shareholderData.length > 0
              ? shareholderData[0].postHoldingFdbPercentage * 100
              : 0,
          preHoldingPercentage:
            shareholderData.length > 0
              ? shareholderData[0].preHoldingFdbPercentage * 100
              : 0,
          unLockedInputFeild: {
            hasToCalculateAmt: false,
            value: investor.investmentAmount || 0,
          },
          hasBlendedSecondaries: false,
          fdbShares: shareholderData[0].postHoldingFdbShares || 0,
          investorType:
            existingInvestor.length > 0 ? existingInvestor[0].type : "VC",
        });
        existingInvestorData.ongoingExistingInvestorDetails = {
          amountToInvest: Number(investor.investmentAmount),
          id: "",
          investorId: "",
          investorName: investor.shareholderName,
          postHoldingPercentage:
            shareholderData.length > 0
              ? shareholderData[0].postHoldingFdbPercentage * 100
              : 0,
          preHoldingPercentage:
            shareholderData.length > 0
              ? shareholderData[0].preHoldingFdbPercentage * 100
              : 0,
          unLockedInputFeild: {
            hasToCalculateAmt: false,
            value: investor.investmentAmount || 0,
          },
          hasBlendedSecondaries: false,
          fdbShares: shareholderData[0].postHoldingFdbShares || 0,
          state: InvestorState.ToBeSaved,
          investorType:
            existingInvestor.length > 0 ? existingInvestor[0].type : "VC",
        } as unknown as OngoingInvestorDetails;
      } else {
        const shareholderData = finalCaptableData?.filter(
          (shareHolder) =>
            shareHolder.shareholderName === investor.shareholderName
        );
        newInvestorData.investorDetails.push({
          investorName: investor.shareholderName,
          amountToInvest: Number(investor.investmentAmount),
          investorType: investor.shareholderType || "VC",
          optedInvestorRightType: InvestorRightsType.None,
          id: "",
          investorId: "",
          postHoldingPercentage:
            shareholderData.length > 0
              ? shareholderData[0].postHoldingFdbPercentage * 100
              : 0,
          preHoldingPercentage:
            shareholderData.length > 0
              ? shareholderData[0].preHoldingFdbPercentage * 100
              : 0,
          unLockedInputFeild: {
            hasToCalculateAmt: false,
            value: investor.investmentAmount || 0,
          },
          hasBlendedSecondaries: false,
          fdbShares: shareholderData[0].postHoldingFdbShares || 0,
        });
        newInvestorData.ongoingExistingInvestorDetails = {
          investorName: investor.shareholderName,
          amountToInvest: Number(investor.investmentAmount),
          investorType: investor.shareholderType || "VC",
          optedInvestorRightType: InvestorRightsType.None,
          id: "",
          investorId: "",
          postHoldingPercentage:
            shareholderData.length > 0
              ? shareholderData[0].postHoldingFdbPercentage * 100
              : 0,
          preHoldingPercentage:
            shareholderData.length > 0
              ? shareholderData[0].preHoldingFdbPercentage * 100
              : 0,
          unLockedInputFeild: {
            hasToCalculateAmt: false,
            value: investor.investmentAmount || 0,
          },
          hasBlendedSecondaries: false,
          fdbShares: shareholderData[0].postHoldingFdbShares || 0,
          state: InvestorState.ToBeSaved,
        } as unknown as OngoingInvestorDetails;
      }
    });
    const roundModel: ModelBuilder = {
      companyId,
      isNavigating: true,
      currentTab: 1,
      highestVisitedTabLevel: 1,
      roundDetailsTab: roundData,
      newInvestorDetails: newInvestorData,
      existingInvestorDetails: existingInvestorData,
      isTermsheet: true,
    };

    return roundModel;
  }

  async function handleModifyAction(action: string, data: any) {
    if (action === "Create Round Model") {
      const termsheetData = data.termsheetData as NewTermsheetDetails;
      const roundModel = await createRoundModel(termsheetData, data.id);
      if (roundModel) {
        roundCreationStore.connect();
        roundCreationStore.sendMessage(roundModel);
        navigate("/modeling/roundmodel");
      }
    }
  }

  const filteredPreholdingData =
    finalCaptableData &&
    finalCaptableData?.filter(
      (element) =>
        element?.preHoldingFdbPercentage &&
        element?.preHoldingFdbPercentage !== 0
    );

  const filteredPostholdingData =
    finalCaptableData &&
    finalCaptableData?.filter(
      (element) =>
        element?.postHoldingFdbPercentage &&
        element?.postHoldingFdbPercentage !== 0
    );

  return (
    <div className={!isPlaceholderData ? "min-h-full" : "loading min-h-full"}>
      <BCHeader
        className="items-baseline"
        bcTitle="Term Sheet"
        bcSubTitle="Individual Termsheet"
      />

      {termsheet && (
        <dl className="mt-6 grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-4 pb-8">
          {[
            TermSheetType.roundSize,
            TermSheetType.valuation,
            TermSheetType.dilution,
            TermSheetType.leadInvestor,
          ]?.map((header) => (
            <div key={header} className="">
              <TermSheetHeaderCard
                termSheetDetail={termsheet.termsheetData}
                headerType={header}
              />
            </div>
          ))}
        </dl>
      )}
      {finalCaptableData && termsheet && (
        <HStack className="grid grid-cols-2 gap-8 justify-between whitespace-nowrap mb-8">
          <div className="whitespace-nowrap relative rounded-lg h-96 bg-white px-4 py-2 border border-borderColor shadow-box ">
            <VStack className="flex-none items-center justify-center">
              <p
                className="md:text-lg text-base font-medium
              text-headerColor whitespace-nowrap"
              >
                Pre Holding %
              </p>
              <PrePostPieChart
                labels={filteredPreholdingData.map(
                  (element) => element?.shareholderName as string
                )}
                values={filteredPreholdingData.map(
                  (element) => Number(element?.preHoldingFdbPercentage) * 100
                )}
                header={"Pre Holding %"}
              />
            </VStack>
          </div>
          <div className="whitespace-nowrap relative rounded-lg bg-white px-4 py-2 h-full border border-borderColor shadow-box ">
            <VStack className="flex-none items-center justify-center">
              <p className="md:text-lg text-base font-medium text-headerColor whitespace-nowrap">
                Post Holding %
              </p>
              <PrePostPieChart
                labels={filteredPostholdingData.map(
                  (element) => element?.shareholderName as string
                )}
                values={filteredPostholdingData.map(
                  (element) => Number(element?.postHoldingFdbPercentage) * 100
                )}
                grayedOutIndex={newInvestorIndex}
                header={"Post Holding %"}
              />
            </VStack>
          </div>
        </HStack>
      )}

      {termsheet && (
        <div className="bg-white shadow rounded-md">
          <HStack className="justify-between px-1">
            {buttonType === "Other Data" && termsheet && (
              <OtherTerms data={termsheet} />
            )}
            {buttonType !== "Other Data" && <div></div>}
            <div
              className="inline-flex m-4 h-10 bg-gray-100 rounded-md shadow-sm"
              role="group"
            >
              <button
                type="button"
                className={`px-4 py-2 text-sm font-normal ${
                  buttonType === "Captable"
                    ? " bg-orange-600  text-white rounded-md"
                    : "bg-gray-100"
                }`}
                onClick={() => {
                  setButtonType("Captable");
                }}
              >
                Captable
              </button>
              <button
                type="button"
                className={`px-4 py-2 text-sm font-normal ${
                  buttonType === "Other Data"
                    ? " bg-orange-600  text-white rounded-md"
                    : "bg-gray-100"
                }`}
                onClick={() => {
                  setButtonType("Other Data");
                }}
              >
                Other Details
              </button>
            </div>
          </HStack>
          {buttonType === "Captable" && termsheet && (
            <TermsheetPreCaptableAgTable captableData={termsheet} />
          )}
        </div>
      )}
      <HStack className="justify-between mt-7">
        <div></div>
        <div>
          <PrimaryCTAButton
            event-name="Create Round Model"
            disabled={
              userRoleData?.length > 0 &&
              userRoleData[0].role === "Admin Viewer"
            }
            onClick={async () => {
              await handleModifyAction(
                "Create Round Model",
                termsheet?.termsheetData
              );
            }}
            buttonSize={ButtonSize.SMALL}
            type="submit"
          >
            Create Round Model
          </PrimaryCTAButton>
        </div>
      </HStack>
    </div>
  );
}
