export interface Headers {
  roundSize: number;
  valuation: number;
  pps: number;
  moneyRaised: number;
  leftToRaise: number;
  leadInvestor: string;
  convertiblePps: number;
  isPriceRound: boolean;
  autoDiluteEsop: boolean;
  postRoundEsop?: number;
}

export interface RoundCreationModel {
  maxTab?: number;
  isNavigate?: boolean;
  isLoader?: boolean;
  screen?: string;
  isSummaryPageViewed?: boolean;
  isConvertRound?: boolean;
  hasToDiscard: boolean;
  currentTab: number;
  errors?: string[];
  notifications?: string[];
  listOfShareHolders: ListOfShareHolders[];
  buyersList: ListOfShareHolders[];
  sellersList: ListOfShareHolders[];
  isAntidultion: boolean;
  baseCapTableId: string;
  companyId: string;
  id: string;
  name: string;
  headers?: Headers;
  tab1?: Tab1;
  tab2?: Tab2;
  tab3?: Tab3;
  tab4?: Tab4;
  tab5?: Tab5;
  tab6?: Tab6;
  tab7?: Tab7;
  tab8?: Tab8;
}

interface ListOfShareHolders {
  id: string;
  name: string;
  type: string;
  sharesPerSecurity?: SharesPerSecurity[];
}

interface SharesPerSecurity {
  investorId: string;
  instrumentId: string;
  instrumentName: string;
  noOfShares: number;
}

//SCREEN-1 : round detail input entry
interface Tab1 {
  tab1RoundHeader?: Tab1RoundHeader;
  inputDetails?: RoundInputDetail;
}

//SCREEN-2 : convertible screen followed by 3 popup inside convertibleTransaction
interface Tab2 {
  additionalAntiDilution?: number; //if antidultion
  noOfShares?: number; //if antidultion
  antiDilutionMethod?: string; //if antidultion
  isConverted?: boolean;
  convertibleTransaction: OutstandingConvertibleModelTransaction[];
  postConversationCaptable?: PostConversationCaptable[]; // input for antidultion.
  newPostmoney?: number;
  newPremoney?: number;
  remainingConvertible?: number;
}

//SCREEN-3 : adding exisiting investors screen
interface Tab3 {
  ongoingExistingInvestorDetails?: OngoingExistingInvestorDetails;
  existingInvestors: ExistingInvestorDetail[];
}

//SCREEN-4 : adding new investors screen
interface Tab4 {
  ongoingNewInvestorDetails?: OngoingExistingInvestorDetails;
  newInvestors: ExistingInvestorDetail[];
}

interface CalculateAmtToInvest {
  id?: string;
  hasToCalculateAmt?: boolean;
  holdingPercentage?: number;
  amt?: number;
  adjustedAmount?: number;
  adjustedHoldingPercentage?: number;
}

//SCREEN-5 : addition of instrument and adding the
// relevant instrument to the investor in the table below .
interface Tab5 {
  newInstruments: NewSecurity[]; //formulate the table with it
  investorDetails: InvestorDetails[];
  nonPricedInstruments?: NewNonPricedSecurity[];
  listOfInstruments?: ListOfInstruments[];
  listOfShareHolders: ListOfShareHolders[];
  ongoingSecurityAllotmentDetails?: OngoingSecurityAllotmentDetails;
}

export interface ListOfInstruments {
  id: string;
  name: string;
  type: string;
}

export interface NewSecurity {
  id: string;
  name: string;
  type: string;
  importedInstrumentId: string;
  conversionRatio: string;
  parValue: number;
  state?: string;
}

//SCREEN-6 : addition of secondary transaction
interface Tab6 {
  transaction: SecondaryTransaction[];
  id?: string;
  newBuyerName?: string;
  isValuesChanged?: boolean;
}

interface Tab7 {
  roundDetailSummary: RoundDetailSummary;
  primaryTransaction: IRoundPrimaryTransaction[];
  secondaryTransaction: IRoundSecondaryTransaction[];
  outstandingConvertiblesTransaction: PostConversationCaptable[];
  summaryCaptable: any[];
}

export interface Tab8 {
  additionalDilution: number;
  shares: number;
  method: string;
  summaryCaptable: PostConversationCaptable[];
}

//ROUND DETAIL SUMMARY YET TO DISCUSSED WHAT DETAIL NEEDS TO BE ADDED
interface RoundDetailSummary {
  roundName: string;
  roundDate: string;
  isPriceRound: boolean;
  amountRaised: number;
  preValuation: number;
  postValuation: number;
  pps: number;
  dilution: number;
  roundSecurity: string[]; //send name only directly
  nominalEquityShare: number;
}

interface IRoundPrimaryTransaction {
  name: string;
  investorType?: string;
  security: string;
  image?: string;
  sharePrice: number;
  instrumentClassId?: string;
  investorId?: string;
  numberOfShares: number;
  amountInvested: number;
  par?: number;
  conversionRatio: string;
}

interface IRoundSecondaryTransaction {
  buyerName: string;
  sellerName: string;
  investorType?: string;
  security: string;
  image?: string;
  sharePrice?: number;
  instrumentClassId?: string;
  buyerInvestorId?: string;
  sellerInvestorId?: string;
  numberOfShares: number;
  amountInvested: number;
  par?: number;
  conversionRatio?: string;
  date: string;
}

//table model of investor for adding security in the screen 5
export interface InvestorDetails {
  id: string;
  name: string;
  shareholderId: string;
  holdingPercentage: number;
  fdbShares: number;
  totalAmount: number;
  instrumentId: string;
  instrumentName: string;
  noOfShares: number;
  amount: number;
}

//transaction of table of the investor
interface TransactionModel {
  instrumentId: string;
  instrumentName: string;
  noOfShares: number;
  amount: number;
}

//deep copy of instrument needs to done if the instrument import is been done

//transaction model for secondary in Screen-6
export interface SecondaryTransaction {
  id?: string;
  buyerId: string;
  buyerName?: string;
  sellerName?: string;
  securityName?: string;
  sellerId: string;
  instrumentId: string;
  noOfShares: number;
  // fdbShares: number;
  amountInvested: number;
  currencyType: string;
  state: string;
}

export interface ExistingInvestorDetail {
  id: string;
  name: string;
  type?: string;
  totalAmountToInvest: number;
  postholdingPercentage?: number;
  currencyType: string;
  investorRightsType?: string;
  noOfShares: number;
  fdbShares: number;
}

export interface OngoingExistingInvestorDetails {
  id: string;
  name?: string;
  state?: NewInvestorState;
  type?: string;
  totalAmountToInvest: number;
  postholdingPercentage: number;
  unLockedInputFeild: UnLockedInputFeild;
  currencyType: string;
  investorRightsType: string;
}

export interface OngoingSecurityAllotmentDetails {
  id: string;
  shareholderId: string;
  name?: string;
  state?: NewInvestorState;
  totalAmountToInvest: number;
  postholdingPercentage: number;
  shares: number;
  fdbShares?: number;
  unLockedInputFeild: UnLockedInputFeild;
  currencyType: string;
  instrumentId: string;
  instrumentName: string;
}

export enum NewInvestorState {
  Update = "Update",
  ToBeSaved = "ToBeSaved",
  Delete = "Delete",
}

export enum NewInstrumentState {
  Saved = "Saved",
  ToBeSaved = "ToBeSaved",
  ToBeDeleted = "ToBeDeleted",
}

export enum InvestorRightsType {
  MaintainCapital = "Maintain Capital",
  ProRataInvestment = "Pro Rata Investment",
  None = "None",
}

export interface UnLockedInputFeild {
  hasToCalculateAmt: boolean;
  value: number;
}

interface ProRataModel {
  name: string;
  totalPercentage: number;
  utilizedPercentage?: number;
}

export interface OutstandingConvertibleModelTransaction {
  isConverted: boolean;
  instrumentId: string;
  securityName: string;
  roundIdentifier: RoundIdentifier[];
  method: string; //have to check with satish
  valutionApplied: ValuationApplied; //will not be ready before converting.
  amountInvested: number;
  holdingPercentage: number;
  noOfShares: number;
  conversionRatio: string;
  convertibleValuation: ConvertibleParameters;
  valuationCap: number;
  discountPercentage: number;
  //for pop1
  type: string; //ucr  pucr convertInstrumeId
  newConversionRatio: string; //update if derived
  updateConversionRatio: boolean;
  // instrumentid exist or else create a new security if there is partial update.
  targetInstrumentId: string;
  availableNumberOfShares: number;
  conversionDate: string;
  derivedInstrumentId: string; //store the newly created instument.
  //for pop2
  capPrice: number;
  discountPrice: number;
  currentPrice: number;
  minPrice: number;
  finalSharePrice: number;
  //for pop3
  postDiscountValution: number; //calculate it in backend : final pps x fdb // editable
  postConversationCaptable: PostConversationCaptable[];
  //
  adjustRatio: string;
  noteAmount: number;
  noteShares: number;
  noteFDB: number;

  state: string;
  // dirty commit
  // | "New"
  // | "Update Ratio"
  // | "Updated"
  // | "Update Captable"
  // | "Adjust Ratio";
}

export interface PostConversationCaptable {
  shareholderId: string;
  shareholderName: string;
  shareholderType: string;
  shareholderGroup: string;
  amountInvested: number;
  preHoldingPercentage: number;
  postHoldingPercentage: number;
}

interface ConvertibleParameters {
  baseValution: number;
  capValution: number;
  discountPercentage: number;
}

interface ValuationApplied {
  valuation: number;
  pps: number;
}

//model should be existing
interface RoundIdentifier {
  roundId: string; //uuid
  roundType: string; //what type of round is it secondary, round ,bonus,buyback
  roundName: string; //name of the round
  roundIdentifier: string;
}

interface CurrentEsop {
  percentage: number;
  freeOptions: number;
}

interface Valution {
  lastValuation: number;
  date: string;
}

interface LastRaise {
  lastRaise: number;
  date: string;
}

export interface Tab1RoundHeader {
  moneyRaised: number;
  numberOfRounds: number;
  equityShares: number;
  prefShares: number;
  fdbShares: number;
  valuation: Valution;
  lastRaise: LastRaise;
  date: string;
  companyCurrency: string;
  currentEsop: CurrentEsop;
}

export interface RoundInputDetail {
  roundName?: string;
  roundIdentifier?: string;
  pricedRound?: boolean;
  autoDiluteEsop?: boolean;
  postRoundEsop?: number;
  roundSize?: number;
  preMoneyValuation?: number;
  postMoneyValuation?: number;
  dilution?: number;
  currency?: string;
  exchangeRate?: number;
  issuanceType?: string;
  paymentType?: string;
  allotmentType?: string;
  closeDate?: string;
  pps?: number;
  hasToCalcuatePps: boolean;
}

export interface NewNonPricedSecurity {
  id?: string;
  name: string;
  type: string;
  conversionRatio?: string;
  parValue?: number;
  convertibleAmount?: number;
  valuationCap: number;
  discount: number;
  discountType: string;
  roundSize?: number;
  postEsop?: number;
  valuation?: number;
  amount?: number;
  state?: string;
}
