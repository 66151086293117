import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { motion } from "framer-motion";
import {
  CellClickedEvent,
  ColDef,
  GetDataPath,
  GridApi,
  SideBarDef,
} from "ag-grid-community";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router";
import { AgGridReact } from "ag-grid-react";
import { Box } from "@mui/material";
import _ from "lodash";
import {
  getCurrencySymbol,
  getCurrencyType,
} from "../../utils/currencyFormatter";
import { tableCellStyle } from "../../components/TableComponent";
import { useGetCompanyName, HStack, VStack } from "../../components/utils";
import { useAuthStore } from "../../store";
import { agConfigDashboardSideBar } from "../dashboardPage/AgGridConfig";

import {
  LpValueRender,
  MultipleRender,
  ParticipationRender,
  RankRender,
  SecurityNameRender,
} from "./LiquidationPreferenceAgComponent";
import GenericTableHeader from "../../shared/TableHeader";
import { formatToReadableNumber } from "../../utils/currencyRoboto";
import { classNames, limitString } from "../../utils/string";
import Tooltip from "../../components/shared/Tooltip";
import { Description } from "../../components/shared/InputField";

export type LiquidationPreferenceData = {
  securityClassId: string;
  securityType: string;
  securityClassName: string;
  rank: number;
  investmentMultiple: number;
  investment: number;
  lpValue: number;
  isParriPassu: boolean;
  isParticipation: boolean;
  participationCap: number;
};

function AgGridLpTable({ data }: { data: LiquidationPreferenceData[] }) {
  const gridApi = useRef<any>(null);
  const gridRef = useRef<AgGridReact>(null);
  const [open, setOpen] = useState(false);
  const [groupDefaultExpanded, setGroupDefaultExpanded] = useState(0);

  let totalLpStackValue = 0;
  const [totalLpStackAmtValue, setTotalLpStackAmtValue] = useState(0);

  const componentsRegistery = useMemo(
    () => ({
      multiple: memo(MultipleRender),
      participation: memo(ParticipationRender),
      lpValue: memo(LpValueRender),
    }),
    []
  );
  const currencyType = getCurrencyType();
  const currencySymbol = getCurrencySymbol();

  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        headerName: "Multiple",
        field: "multiple",
        filter: "agSetColumnFilter",
        cellRenderer: "multiple",
        cellStyle: tableCellStyle,
        sortable: false,
        menuTabs: ["filterMenuTab"],
      },
      {
        headerName: "Participation",
        field: "participation",
        filter: "agNumberColumnFilter",
        cellRenderer: "participation",
        cellStyle: tableCellStyle,
        sortable: false,
        menuTabs: ["filterMenuTab"],
        tooltipField: "participationTooltipField",
      },
      {
        headerName: `LP value (${currencySymbol})`,
        field: "lpValue",
        filter: "agNumberColumnFilter",
        cellRenderer: "lpValue",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "lpValueTooltipField",
      },
    ],
    []
  );

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      autoHeight: true,
      wrapHeaderText: true,
      suppressColumnVirtualisation: true,
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      filterParams: {
        buttons: ["reset"],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
      flex: 1,
    }),
    [data, groupDefaultExpanded, open]
  );

  const getRowStyle = (params: any) => {
    let rowStyle;
    if (params.rowIndex % 2 === 0) {
      rowStyle = { background: "#f8f8f8" };
    } else {
      rowStyle = { background: "#ffffff" };
    }

    if (params.node.level === 0) {
      rowStyle = {
        ...rowStyle,

        color: "black",
        fontWeight: 600,
      };
    }
    return rowStyle;
  };

  const CustomHeaderComponent = () => (
    <div>
      <HStack className="cursor-pointer" onClick={() => handleGroupToggle()}>
        <div className="pt-0.5">
          <ChevronDownIcon
            className={classNames(
              open ? "rotate-0" : "-rotate-90",
              "h-3 w-5 transform text-slate-500"
            )}
            strokeWidth={2.5}
          />
        </div>

        <span className="px-6">Ranks</span>
      </HStack>
    </div>
  );

  const handleGroupToggle = () => {
    if (open) {
      setOpen(!open);
      setGroupDefaultExpanded(0);
    } else {
      setOpen(!open);
      setGroupDefaultExpanded(-1);
    }
  };

  const autoGroupColumnDef = useMemo<ColDef>(
    () => ({
      headerName: "Ranks",
      minWidth: 280,
      menuTabs: [],
      cellRenderer: "agGroupCellRenderer",
      headerComponent: CustomHeaderComponent,
      cellRendererParams: {
        suppressCount: true,
      },
      pinned: true,
    }),
    [data, groupDefaultExpanded, open]
  );

  const getDataPath = useMemo<GetDataPath>(
    () => (data: any) => data.orgHierarchy,
    []
  );

  const gridOptions = {
    suppressRowVirtualisation: true,
    paginationAutoPageSize: false,
    suppressScrollOnNewData: true,
  };

  const filteredData = data?.filter(
    (template: any) => template.rank > 0 && template.lpValue > 0
  );

  const rowData = useMemo(() => {
    if (!data) return [];
    totalLpStackValue = 0;

    return filteredData.flatMap((template: any) => {
      const securityName = template.securityClassName;
      const securityNameTooltipField = template.securityClassName;
      const rank = template.rank;
      const multiple = template.investmentMultiple;
      const participation = template.participationCap;
      const participationTooltipField =
        template.isParticipation && template.participationCap
          ? template.participationCap.toLocaleString(currencyType)
          : "";
      const isParticipation = template.isParticipation;
      const lpValue = template.lpValue;
      const lpValueTooltipField = template.lpValue.toLocaleString(currencyType);

      const totalLpValue = data.reduce((acc: any, item: any) => {
        if (template.rank === item.rank) return acc + item.lpValue;
        else {
          return acc;
        }
      }, 0);
      totalLpStackValue += lpValue;
      setTotalLpStackAmtValue(totalLpStackValue);
      return [
        {
          orgHierarchy: [rank],
          lpValue: totalLpValue,
          lpValueTooltipField,
          isParticipation,
          currencyType,
          currencySymbol,
        },
        {
          orgHierarchy: [rank, securityName],
          multiple,
          participation,
          lpValue,
          totalLpValue,
          lpValueTooltipField,
          isParticipation,
          participationTooltipField,
          currencyType,
          currencySymbol,
        },
      ];
    });
  }, [data, groupDefaultExpanded, open]);

  const groupedData = _.groupBy(filteredData, "rank");
  const rankKeys = Object.keys(groupedData);

  const calculateTotalLpValue = (entries: any[]) => {
    let addedvalue = 0;
    const total = entries.reduce(
      (accumulator, entry) => accumulator + entry.lpValue,
      0
    );

    addedvalue += total;

    return addedvalue;
  };

  const [filteredRowData, setFilteredRowData] = useState<any>([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const onAgGridFilterChanged = (grid: any) => {
    const filtersApplied = grid.api.isAnyFilterPresent();
    setIsFilterApplied(filtersApplied);

    const filteredData = grid.api
      .getModel()
      .rowsToDisplay.map((node: any) => node.data);
    setFilteredRowData(filteredData);
  };

  const getBackgroundColor = (index: number) => {
    const maxOpacity = 1; // Maximum opacity
    const step = maxOpacity / (rankKeys.length - 1);
    if (index === 0) {
      return "rgba(0, 151, 19,1)";
    } else if (index === 1) {
      return "rgba(0, 151, 19, 0.6)";
    } else if (index === 2) {
      return "rgba(0, 151, 19, 0.4)";
    } else if (index === 3) {
      return "rgba(0, 151, 19, 0.3)";
    } else if (index === 4) {
      return "rgba(0, 151, 19, 0.2)";
    }

    const opacity = maxOpacity - index * step;

    return `rgba(12, 34, 74, ${opacity})`;
  };
  const currencytype = getCurrencyType();

  return (
    <>
      <div
        className="justify-between rounded-lg md:grid md:grid-cols-2 bg-inherit"
        style={{ gridTemplateColumns: "60% 37%" }}
      >
        <VStack className="">
          <HStack className="items-center justify-between py-4 bg-white rounded-md shadow-box">
            <p className="pl-6 text-lg font-medium text-headerColor whitespace-nowrap">
              Liquidation Preference Stack
            </p>
          </HStack>
          <HStack className="justify-between w-full">
            <Box
              style={{
                height: `${
                  (rowData?.length ?? 0 >= 3 ? 5 : rowData?.length ?? 0 + 2) *
                  58
                }px`,
              }}
              className="w-full h-full max-h-full overflow-x-auto ag-theme-material "
            >
              <AgGridReact
                sideBar={agConfigDashboardSideBar}
                ref={gridRef}
                onGridReady={(params) => {
                  gridApi.current = params;
                }}
                components={componentsRegistery}
                alwaysShowHorizontalScroll
                alwaysMultiSort
                animateRows={true}
                enableCharts={true}
                enableRangeSelection={true}
                defaultColDef={defaultColDef}
                onFilterChanged={onAgGridFilterChanged}
                rowData={rowData}
                columnDefs={columnDefs}
                autoGroupColumnDef={autoGroupColumnDef}
                treeData={true}
                groupDefaultExpanded={groupDefaultExpanded}
                pagination={false}
                getDataPath={getDataPath}
                suppressRowTransform={true}
                suppressCopyRowsToClipboard={true}
                suppressCopySingleCellRanges={true}
                suppressCellFocus={true}
                suppressMenuHide={false}
                tooltipShowDelay={1000}
                tooltipInteraction={true}
                gridOptions={gridOptions}
                getRowStyle={getRowStyle}
              ></AgGridReact>
            </Box>
          </HStack>
        </VStack>
        <VStack className="justify-between bg-white rounded-md">
          <GenericTableHeader
            className="mt-2"
            heading={"LP Stack"}
            subHeading={`Total LP Value: ${currencySymbol} ${totalLpStackAmtValue.toLocaleString(
              currencyType
            )}`}
          />
          <div className="h-full mx-8 mt-10 mb-2">
            {rankKeys.map((key, index) => (
              <>
                <div
                  key={index}
                  style={{ backgroundColor: getBackgroundColor(index) }}
                  className={`flex items-center justify-center ${
                    rankKeys.length.toString() === "1"
                      ? "py-12"
                      : rankKeys.length.toString() === "2"
                      ? "py-8"
                      : rankKeys.length.toString() === "3"
                      ? "py-4"
                      : "py-2"
                  }  w-full hover:shadow-2xl`}
                >
                  <div
                    className={`flex flex-wrap gap-2 px-0 mx-6 my-2 font-medium text-center ${
                      index < 3 ? "text-white" : ""
                    }`}
                  >
                    <>
                      <Tooltip
                        text={`
                          ${groupedData[key]?.map(
                            (data: any, dataIndex: any) =>
                              `${limitString(data?.securityClassName, 32)} \n`
                          )}`}
                        _className={`${
                          groupedData[key].length <= 4
                            ? "w-fit justify-start"
                            : "w-44"
                        }`}
                      >
                        <div>
                          <p>
                            {`Rank ${key} : ${currencySymbol} `}
                            {formatToReadableNumber({
                              value: calculateTotalLpValue(groupedData[key]),
                              format: currencyType,
                            })}
                          </p>
                          <p className={` ${index < 3 ? "text-white" : ""}`}>
                            {`(${groupedData[key].length} Security classes)`}
                          </p>
                        </div>
                      </Tooltip>
                    </>
                  </div>
                </div>
              </>
            ))}
          </div>
        </VStack>
      </div>
    </>
  );
}

export default AgGridLpTable;
