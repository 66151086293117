/* eslint-disable max-len */
import { useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import { Icon } from "@iconify/react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { HStack, VStack, Error } from "../../components/utils";
import {
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../quickRound/CTAButtonComponents";
import { Input, Label } from "../../components/shared/InputField";
import {
  ExistingInvestorDetail,
  NewInvestorState,
  OngoingExistingInvestorDetails,
  RoundCreationModel,
} from "./RoundCreationModel";
import { useAuthStore } from "../../store";
import { useRMCreationStore } from "../../store/roundCreationStore";
import { Select } from "../../components/shared/Select";
import { shareholderTypeLabelValue } from "../../constants/ShareholderConstants";
import { getCurrencySymbol } from "../../utils/currencyFormatter";

type AddNewInvestorModel = {
  shareholderName: string;
  shareholderId: string;
  shareholderType: string;
  postHolding?: number;
  investmentAmount?: number;
  state: string;
};

const CreateNewnvestor = ({
  data,
  actionMode = "New",
  onBack,
  onNext,
}: {
  data?: ExistingInvestorDetail;
  actionMode?: string;
  onBack: () => void;
  onNext: (data: RoundCreationModel) => void;
}) => {
  const [mode, setMode] = useState(actionMode);
  const roundCreationState = useRMCreationStore();
  const companyId = useAuthStore().companyId || "";
  const [loading, setLoading] = useState(false);
  const [shareholderName, setShareholderName] = useState("");
  const [shareholderType, setShareholderType] = useState("");
  const [holding, setHolding] = useState<string>(
    data?.postholdingPercentage?.toString() || ""
  );
  const [amount, setAmount] = useState<string>(
    data?.totalAmountToInvest.toString() || ""
  );
  const [isAmtLocked, setIsAmtLocked] = useState(false);
  const [isLoaded, setLoaded] = useState(false);
  const currencySymbol = getCurrencySymbol();
  const handleSubmit = async (values: AddNewInvestorModel) => {
    setLoading(true);
    setLoading(false);
    const ongoingNewInvestorDetails: OngoingExistingInvestorDetails = {
      id: values.shareholderId,
      name: values.shareholderName,
      totalAmountToInvest: values.investmentAmount || 0,
      postholdingPercentage: values.postHolding || 0,
      type: values.shareholderType,
      unLockedInputFeild: {
        hasToCalculateAmt: isAmtLocked,
        value: Number(amount) || 0,
      },
      currencyType: "",
      investorRightsType: "",
      state: NewInvestorState.ToBeSaved,
    };
    onNext({
      ...roundCreationState.roundCreation,
      companyId,
      tab4: {
        newInvestors: roundCreationState.roundCreation.tab4?.newInvestors || [],
        ongoingNewInvestorDetails,
      },
    });
    onBack();
  };

  useEffect(() => {
    formik.setFieldValue(
      "postHolding",
      roundCreationState.roundCreation.tab4?.ongoingNewInvestorDetails
        ?.postholdingPercentage
    );
    formik.setFieldValue(
      "investmentAmount",
      roundCreationState.roundCreation.tab4?.ongoingNewInvestorDetails
        ?.totalAmountToInvest
    );
  }, [
    roundCreationState.roundCreation.tab4?.ongoingNewInvestorDetails
      ?.totalAmountToInvest,
    roundCreationState.roundCreation.tab4?.ongoingNewInvestorDetails
      ?.totalAmountToInvest,
  ]);
  const investorInitialValues = {
    shareholderId: data?.id || "",
    shareholderName: data?.name || "",
    shareholderType: data?.type || "",
    postHolding: data?.postholdingPercentage,
    investmentAmount: data?.totalAmountToInvest,
    state: NewInvestorState.Update,
  };

  const investorValidationSchema = Yup.object({
    shareholderId: Yup.string().label("Shareholder Id"),
    shareholderName: Yup.string().required().label("Shareholder Name"),
    postHolding: Yup.number()
      .required()
      .moreThan(0)
      .max(100)
      .label("Post Holding"),
    investmentAmount: Yup.number()
      .required()
      .moreThan(0)
      .label("Investment Amount"),
    shareholderType: Yup.string().required().label("Shareholder Type"),
    state: Yup.string().label("State"),
  });

  const formik = useFormik({
    initialValues: investorInitialValues,
    enableReinitialize: true,
    onSubmit: (values: AddNewInvestorModel) => {
      handleSubmit(values);
    },
    validationSchema: investorValidationSchema,
  });

  useEffect(() => {
    const values = formik.values;
    const ongoingNewInvestorDetails: OngoingExistingInvestorDetails = {
      id: values.shareholderId,
      totalAmountToInvest: values.investmentAmount || 0,
      type: values.shareholderType,
      postholdingPercentage: values.postHolding || 0,
      unLockedInputFeild: {
        hasToCalculateAmt: isAmtLocked,
        value: isAmtLocked ? Number(holding) || 0 : Number(amount) || 0,
      },
      currencyType: "",
      investorRightsType: "None",
      state: NewInvestorState.Update,
    };
    onNext({
      ...roundCreationState.roundCreation,
      companyId,
      tab4: {
        newInvestors: roundCreationState.roundCreation.tab4?.newInvestors || [],
        ongoingNewInvestorDetails,
      },
    });
  }, [formik.values.postHolding, formik.values.investmentAmount]);

  useEffect(() => {
    const data = roundCreationState.roundCreation;
    if (data.errors && data.errors.length > 0) {
      for (const error of data.errors) {
        toast(error, {
          type: "error",
          autoClose: 2000,
        });
      }
      roundCreationState.setRoundCreation({
        ...data,
        errors: [],
      });
    }
  }, []);

  const resetNewInverstor = () => {
    const data = roundCreationState.roundCreation;
    const postData = {
      ...data,
      tab4: {
        newInvestors: data.tab4?.newInvestors || [],
      },
    };
    onNext(postData);
  };

  const updateLocalStates = () => {
    setIsAmtLocked((prev) => !prev);
    setAmount(
      (
        roundCreationState.roundCreation.tab4?.ongoingNewInvestorDetails
          ?.totalAmountToInvest || 0
      ).toFixed(2)
    );
    setHolding(
      (
        roundCreationState.roundCreation.tab4?.ongoingNewInvestorDetails
          ?.postholdingPercentage || 0
      ).toFixed(2)
    );
  };

  return (
    <div className="w-full">
      <Box className="px-4 text-lg font-medium border-b pt-4 pb-2">
        <h6 className="flex justify-between">
          <VStack>
            <p className="font-medium text-lg">
              {actionMode.toLowerCase() === "edit"
                ? "Edit New Shareholders"
                : "Add New Shareholders"}
            </p>
          </VStack>
          <XMarkIcon
            className="h-6 w-6 cursor-pointer"
            onClick={() => {
              const data = roundCreationState.roundCreation;
              onNext({
                ...data,
                tab4: {
                  newInvestors: data.tab4?.newInvestors || [],
                },
              });
              onBack();
            }}
          />
        </h6>
      </Box>
      <VStack className="w-full py-7 gap-9">
        <VStack className="w-full px-10">
          <HStack className="gap-4">
            <VStack className="flex-1 mb-12">
              <Label className="font-medium text-sm whitespace-nowrap mr-4">
                Shareholder Name
              </Label>
              <Input
                type="text"
                placeholder="Enter Name"
                className="w-72"
                value={formik.values.shareholderName}
                disabled={mode.toLowerCase() === "edit"}
                onChange={(e) => {
                  formik.setFieldValue("shareholderName", e.target.value);
                  setShareholderName(e.target.value);
                }}
              />
              {formik.touched.shareholderName &&
                formik.errors.shareholderName && (
                  <Error text={formik.errors.shareholderName} />
                )}
            </VStack>
            <VStack className="flex-1 mb-12">
              <Label className="font-medium text-sm whitespace-nowrap mr-4">
                Shareholder Type
              </Label>
              <Select
                options={shareholderTypeLabelValue}
                valueGetter={(o) => o.label}
                textGetter={(o) => o.value}
                placeholder="Select Shareholder Type"
                className="w-72"
                disabled={mode.toLowerCase() === "edit"}
                value={formik.values.shareholderType}
                onChange={(e) => {
                  formik.setFieldValue("shareholderType", e.target.value);
                  setShareholderType(e.target.value);
                }}
              />
              {formik.touched.shareholderType &&
                formik.errors.shareholderType && (
                  <Error text={formik.errors.shareholderType} />
                )}
            </VStack>
          </HStack>
          <HStack className="gap-4">
            <VStack className="flex-1">
              <HStack>
                <Label className="font-medium text-sm whitespace-nowrap mr-4">
                  Holding %
                </Label>
                {isAmtLocked ? (
                  <Icon
                    onClick={updateLocalStates}
                    icon="material-symbols:lock-open-outline-rounded"
                    width={20}
                    className="text-[#E75935]"
                    height={20}
                  />
                ) : (
                  <Icon
                    onClick={updateLocalStates}
                    icon="ic:outline-lock"
                    width={20}
                    className="text-gray-400"
                    height={20}
                  />
                )}
              </HStack>

              <Input
                type="number"
                placeholder={!isAmtLocked ? "" : "Enter Holding %"}
                readOnly={!isAmtLocked}
                className="w-72"
                value={
                  !isAmtLocked
                    ? roundCreationState.roundCreation.tab4
                        ?.ongoingNewInvestorDetails?.postholdingPercentage
                    : holding
                }
                onChange={(e) => {
                  formik.setFieldValue("postHolding", Number(e.target.value));
                  setHolding(e.target.value);
                }}
              />
              {isAmtLocked && (
                <p className="text-xxs text-[#A6A6A6] font-medium pt-1">
                  ~final holding considering existing holding and round size :
                  {roundCreationState.roundCreation.tab4?.ongoingNewInvestorDetails?.postholdingPercentage?.toFixed(
                    2
                  )}
                </p>
              )}
              {formik.touched.postHolding && formik.errors.postHolding && (
                <Error text={formik.errors.postHolding} />
              )}
            </VStack>
            <VStack className="flex-1">
              <HStack>
                <Label className="font-medium text-sm whitespace-nowrap mr-4">
                  Investment Amount {` (${currencySymbol})`}
                </Label>
                {!isAmtLocked ? (
                  <Icon
                    onClick={updateLocalStates}
                    icon="material-symbols:lock-open-outline-rounded"
                    width={20}
                    className="text-[#E75935]"
                    height={20}
                  />
                ) : (
                  <Icon
                    onClick={updateLocalStates}
                    icon="ic:outline-lock"
                    width={20}
                    className="text-gray-400"
                    height={20}
                  />
                )}
              </HStack>

              <Input
                type="number"
                placeholder={isAmtLocked ? "" : "Enter Amount"}
                readOnly={isAmtLocked}
                className="w-72"
                value={
                  isAmtLocked
                    ? roundCreationState.roundCreation.tab4
                        ?.ongoingNewInvestorDetails?.totalAmountToInvest
                    : amount
                }
                onChange={(e) => {
                  formik.setFieldValue("investmentAmount", e.target.value);
                  setAmount(e.target.value);
                }}
              />
              {formik.touched.investmentAmount &&
                formik.errors.investmentAmount && (
                  <Error text={formik.errors.investmentAmount} />
                )}
              {!isAmtLocked && (
                <p className="text-xxs text-[#A6A6A6] font-medium pt-1">
                  ~calculated based on entered amount and share price:
                  {roundCreationState.roundCreation.tab4?.ongoingNewInvestorDetails?.totalAmountToInvest?.toFixed(
                    2
                  )}
                </p>
              )}
            </VStack>
          </HStack>
        </VStack>
        <HStack className="justify-between mt-10 px-10">
          <SecondaryCTAButton
            event-name="Cancel Create New Investor"
            onClick={() => {
              resetNewInverstor();
              onBack();
            }}
          >
            Cancel
          </SecondaryCTAButton>
          <PrimaryCTAButton
            className="p-1"
            type="submit"
            event-name="Save Create New Investor"
            onClick={() => {
              formik.handleSubmit();
            }}
          >
            {!loading && <span>Save</span>}

            {loading && (
              <HStack>
                <p className="indicator-progress" style={{ display: "block" }}>
                  Please wait...
                </p>
                <Icon
                  className="animate-spin"
                  icon="lucide:loader-2"
                  width={18}
                />
              </HStack>
            )}
          </PrimaryCTAButton>
        </HStack>
      </VStack>
    </div>
  );
};

export default CreateNewnvestor;
