import { useRef } from "react";
import _ from "lodash";
import { useNavigate } from "react-router";
import { Box, HStack, VStack } from "../../components/utils";
import {
  tableEntryStyle,
  tableFirstColStyle,
} from "../../components/TableComponent";

import { limitString } from "../../utils/string";
import { getSecurityColor } from "../../utils/historicUtilities";
import { formatToReadableNumber } from "../../utils/currencyRoboto";
import RoundTag from "../../shared/RoundTag";
import { checkVoid } from "../../utils/transactionUtils";
import ShareholderIdentifier from "../../shared/ShareholderIdentifier";
import { formatDisplayDate } from "../../utils/date";
import Avatar from "../Utility/Avatar";

export function ShareholderNameRender(data: any) {
  return (
    <td className="flex flex-col pb-2 text-sm text-left">
      <div className="flex">
        <div className="pr-2 text-left">
          {data.value.name !== "Total" ? (
            <Avatar name={data.value.name || ""} />
          ) : (
            ""
          )}
        </div>
        <div
          className={` ${
            data.value.name === "Total"
              ? "pt-2 cursor-pointer"
              : "ml-2 cursor-pointer"
          } `}
        >
          {data.value.name?.length > 24 ? (
            <p
              data-event-name="captableShareholderDrilling"
              className={`${tableFirstColStyle}`}
            >
              {limitString(data.value.name || "", 24)}
            </p>
          ) : (
            <p
              data-event-name="captableShareholderDrilling"
              className={`${tableFirstColStyle}`}
            >
              {limitString(data.value.name || "", 24)}
            </p>
          )}
        </div>
      </div>
    </td>
  );
}

export function SecurityClassRender(data: any) {
  return (
    <td className="pt-2 whitespace-nowrap">
      {data.data.securityClass?.length > 24 ? (
        <p className={`${tableFirstColStyle}`}>
          {limitString(data.data?.securityClass || "", 24)}
        </p>
      ) : (
        <p className={`${tableFirstColStyle}`}>
          {limitString(data.data?.securityClass || "", 24)}
        </p>
      )}

      <p className="text-left font-semibold text-xxs1 text-[#464E5F]/[0.5]">
        {data.data.icin}
      </p>
      {data.data.isConverted && (
        <p className="w-16 px-1 mt-2 text-red-500 border border-red-500 rounded-sm font-xxs">
          Converted
        </p>
      )}
    </td>
  );
}

export function SecurityTypeRender(data: any) {
  return (
    <div className="pt-2 whitespace-nowrap">
      <p className={tableEntryStyle}>
        {" "}
        {data.data.conversionRatio > 0
          ? `1 : ${data.data.conversionRatio}`
          : "-"}
      </p>
    </div>
  );
}

export function NewConversionRatioRender(data: any) {
  return (
    <div className="pt-2 whitespace-nowrap">
      <p className={tableEntryStyle}>{data.data.newConversionRatio}</p>
    </div>
  );
}

export function MethodRender(data: any) {
  const navigate = useNavigate();
  return (
    <div className="pt-2 whitespace-nowrap">
      <p className={tableEntryStyle}>{data.data?.method}</p>
    </div>
  );
}

export function ValuationAppliedRender(data: any) {
  const navigate = useNavigate();
  return (
    <div className="pt-2 whitespace-nowrap">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.data?.valuationApplied,
          format: data.data.currencyType,
        })}
      </p>
    </div>
  );
}

export function ConversionPriceRender(data: any) {
  const navigate = useNavigate();
  return (
    <div className="pt-2 whitespace-nowrap">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.data?.conversionPrice,
          format: data.data.currencyType,
        })}
      </p>
    </div>
  );
}
