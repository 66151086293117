import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  CellClickedEvent,
  ColDef,
  GridApi,
  SideBarDef,
} from "ag-grid-community";
import { useNavigate } from "react-router";

import { AgGridReact } from "ag-grid-react";
import { Action, CTADropdown } from "../../components/shared/Dropdown";
import { agConfigDashboardSideBar } from "../dashboardPage/AgGridConfig";

import { ShareholderDataModel } from "../../types/Shareholder";

import {
  getCurrencySymbol,
  getCurrencyType,
} from "../../utils/currencyFormatter";

import { Box, HStack, useGetCompanyName } from "../../components/utils";
import GenericTableHeader from "../../shared/TableHeader";
import { IconCTAButton } from "../quickRound/CTAButtonComponents";
import QuickSearch from "../rounds/QuickSearch";
import { useAuthStore } from "../../store";
import {
  useGetOnFilterState,
  usePostOnFilterState,
} from "../dashboardPage/AgGridCacheQuery";
import { tableCellStyle } from "../../components/TableComponent";
import { ExportImport } from "../Utility/GrantsTable";
import {
  exportSharecertificateReport,
  exportShareholderReport,
} from "../../api/Report";
import { downloadExcel, downloadS3File } from "../../utils/DownloadFile";
import { useAgGridTableState } from "../../store/agGridTableStore";
import SearchInput from "../../components/shared/SearchInput";
import { RoundsToolTip } from "../dashboardPage/customTooltip";
import { getShareholderValue } from "../../constants/ShareholderConstants";
import { handleEventForTracking } from "../../amplitudeAnalytics";
import { CumulativeHoldingQuickRound } from "../../types/CapTable";
import { RoundsRender } from "../dashboardPage/AgGridCaptableComponent";
import { HoldingPercentageRender } from "../modeling/ConvertibleAGgridComponent";
import {
  AvgPPSRender,
  CurrentValueRender,
} from "../shareholders/ShareholderAGComponent";
import { NumberOfSharesRender } from "./AddBuybackAgComponent";
import { SecurityTypeRender } from "./OutstandingConvertibleAgComponent";
import { PostHoldingFdbPercentage } from "../rounds/RoundsAGComponent";
import {
  PostHoldingFDBSharesRender,
  PostHoldingPercentageRender,
  PreHoldingFDBSharesRender,
  PreHoldingPercentageRender,
  ShareholderNameRender,
} from "./ShareHolderAgGridCapTableQuickRoundComponent";

function AGGridShareholderQuickRoundTable({
  data,
}: {
  data: CumulativeHoldingQuickRound[];
}) {
  const currencySymbol = getCurrencySymbol();
  const companyName = useGetCompanyName();
  const companyId = useAuthStore().companyId ?? "";
  const userId = useAuthStore().user?.userId ?? 0;
  const gridApi = useRef<any>(null);

  const displayedRowCount = gridApi.current?.api.getDisplayedRowCount();

  const { refetch, data: filterState } = useGetOnFilterState(
    `${userId}`,
    companyId
  );
  const agTableStore = useAgGridTableState();
  useEffect(() => {
    refetch().then((data) => {
      if (data.data) agTableStore.setState(data.data);
      setColumnSetting(
        data.data?.shareHolderAgGridCaptableQuickRoundColumnModel
      );
      setFilterSetting(
        data.data?.shareHolderAgGridCaptableQuickRoundFilterModel
      );
    });
  }, []);

  const { mutate: postOnFilter } = usePostOnFilterState();

  function getColumnSetting() {
    if (gridApi.current) return gridApi.current.columnApi?.getColumnState();
    return {};
  }

  function setColumnSetting(model: any) {
    if (gridApi.current)
      gridApi.current.columnApi.applyColumnState({ state: model });
    return {};
  }

  // Gets filter model via the grid API
  const getFilterSetting = () => {
    if (gridApi.current) return gridApi.current.api?.getFilterModel();
    return {};
  };

  const setFilterSetting = (model: any) => {
    if (gridApi.current) return gridApi.current.api?.setFilterModel(model);
    return {};
  };

  const uploadFilterAndColumn = async () => {
    const columnState = await getColumnSetting();
    const filterState = await getFilterSetting();
    postOnFilter({
      userId: `${userId}`,
      companyId,
      filterData: {
        ...agTableStore.state,
        shareHolderAgGridCaptableQuickRoundColumnModel: columnState,
        shareHolderAgGridCaptableQuickRoundFilterModel: filterState,
      },
    });
  };

  const cin = "";

  const componentsRegistery = useMemo(
    () => ({
      shareholderName: memo(ShareholderNameRender),
      preHoldingPercentage: memo(PreHoldingPercentageRender),
      postHoldingPercentage: memo(PostHoldingPercentageRender),
      preHoldingFDBShares: memo(PreHoldingFDBSharesRender),
      postHoldingFDBShares: memo(PostHoldingFDBSharesRender),
    }),
    []
  );

  const navigate = useNavigate();

  const onClickShareholderName = (id: string) => {
    handleEventForTracking({ eventName: "Shareholder Name" });
    navigate(`/shareholders/individual/${id}`);
  };

  const isValidDate = (date: string) =>
    Number.isNaN(date) &&
    new Date(date).toString() !== "Invalid Date" &&
    !Number.isNaN(Date.parse(date));

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      autoHeight: true,
      wrapHeaderText: true,
      suppressColumnVirtualisation: true,
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      filterParams: {
        buttons: ["reset"],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
      flex: 1,
    }),
    []
  );
  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        headerName: "Name",
        field: "shareholderName",
        filter: "agMultiColumnFilter",
        getQuickFilterText: (params) => params.value.name,
        comparator(valueA: any, valueB: any, ..._: any[]) {
          return valueA.name > valueB.name ? 1 : -1;
        },
        filterParams: {
          maxNumConditions: 5,
          buttons: ["reset"],
          filters: [
            {
              title: "Name",
              display: "subMenu",
              filter: "agTextColumnFilter",
              buttons: ["reset"],
              filterValueGetter: (params: any) => params.value.name,
              filterParams: {
                buttons: ["reset"],
                valueGetter: (params: any) =>
                  params.getValue("shareholderName").name,
              },
            },
            {
              title: "Type",
              filter: "agSetColumnFilter",
              display: "subMenu",
              buttons: ["reset"],
              filterParams: {
                buttons: ["reset"],
                keyCreator: (params: any) => {
                  const shareholderType = params.value.type;
                  return shareholderType;
                },
                valueFormatter: (params: any) => {
                  const shareholderType = getShareholderValue(
                    params.value.type
                  );
                  return shareholderType;
                },
              },
            },
          ],
        },
        pinned: "left",
        cellRenderer: "shareholderName",
        initialWidth: 300,
        sortable: true,
        autoHeight: true,
        cellStyle: tableCellStyle,
        wrapText: true,
        suppressSizeToFit: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipShareholderName",
      },

      {
        headerName: "Pre Holding (%)",
        field: "preHoldingPercentage",
        filter: "agNumberColumnFilter",
        cellRenderer: "preHoldingPercentage",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipPreHoldingPercentage",
      },
      {
        headerName: "Post Holding (%)",
        field: "postHoldingPercentage",
        filter: "agNumberColumnFilter",
        cellRenderer: "postHoldingPercentage",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipPostHoldingPercentage",
      },
      {
        headerName: "Pre Holding FDB Shares",
        field: "preHoldingFDBShares",
        filter: "agNumberColumnFilter",
        cellRenderer: "preHoldingFDBShares",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipPreHoldingFDBShares",
      },
      {
        headerName: "Post Holding FDB Shares",
        field: "postHoldingFDBShares",
        filter: "agNumberColumnFilter",
        cellRenderer: "postHoldingFDBShares",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipPostHoldingFDBShares",
      },
    ],
    []
  );

  function setPinnedBottomRowData({ api }: { api: any }) {
    const data = api.rowModel.rootNode;
    const filteredData = data.childrenAfterAggFilter.map(
      (element: any) => element.data
    );
    const totalPreHoldingFDBShares = filteredData?.reduce(
      (accumulator: any, data: any) =>
        accumulator + Number(data.preHoldingFDBShares),
      0
    );
    const totalPostHoldingFDBShares = filteredData?.reduce(
      (accumulator: any, data: any) =>
        accumulator + Number(data.postHoldingFDBShares),
      0
    );
    const totalPreHoldingPercentage = filteredData?.reduce(
      (accumulator: any, data: any) =>
        accumulator + Number(data.preHoldingPercentage),
      0
    );

    const totalPostHoldingPercentage = filteredData?.reduce(
      (accumulator: any, data: any) =>
        accumulator + Number(data.postHoldingPercentage),
      0
    );

    api.setPinnedBottomRowData([
      {
        shareholderName: {
          name: "Total",
          type: null,
        },

        tooltipShareholderName: null,

        preHoldingFDBShares: totalPreHoldingFDBShares,
        tooltipPreHoldingFDBShares:
          totalPreHoldingFDBShares.toLocaleString(currencyType),
        postHoldingFDBShares: totalPostHoldingFDBShares,
        tooltipPostHoldingFDBShares:
          totalPostHoldingFDBShares.toLocaleString(currencyType),
        preHoldingPercentage:
          totalPreHoldingPercentage.toFixed(2) > 99.98
            ? 100
            : totalPreHoldingPercentage.toFixed(2),
        tooltipPreHoldingPercentage:
          totalPreHoldingPercentage.toFixed(2) > 99.98
            ? 100
            : totalPreHoldingPercentage.toFixed(2),
        postHoldingPercentage:
          totalPostHoldingPercentage.toFixed(2) > 99.98
            ? 100
            : totalPostHoldingPercentage.toFixed(2),
        tooltipPostHoldingPercentage:
          totalPostHoldingPercentage.toFixed(2) > 99.98
            ? 100
            : totalPostHoldingPercentage.toFixed(2),
        currencySymbol,
        currencyType,
      },
    ]);
  }

  const gridOptions = {
    suppressRowVirtualisation: true, // Add this line to disable row virtualization
    paginationAutoPageSize: false, // Optional: Set to false if using custom pagination
    suppressScrollOnNewData: true, // Optional: Set to true to prevent scrolling to the top on
  };

  const currencyType = getCurrencyType();

  const rowData = useMemo(
    () =>
      data?.map((template) => ({
        id: template.id,
        shareholderName: {
          name: template.name,
          type: template.type,
        },
        tooltipShareholderName: template.name,

        preHoldingFDBShares: template.preHoldingFdbShares,
        tooltipPreHoldingFDBShares:
          template.preHoldingFdbShares.toLocaleString(currencyType),
        postHoldingFDBShares: template.postHoldingFdbShares,
        tooltipPostHoldingFDBShares:
          template.postHoldingFdbShares.toLocaleString(currencyType),
        preHoldingPercentage: (
          Math.abs(template?.preHoldingFdbPercentage!) * 100
        ).toFixed(2),
        tooltipPreHoldingPercentage:
          Math.abs(template?.preHoldingFdbPercentage!) * 100,
        postHoldingPercentage: (
          Math.abs(template?.postHoldingFdbPercentage!) * 100
        ).toFixed(2),
        tooltipPostHoldingPercentage:
          Math.abs(template?.postHoldingFdbPercentage!) * 100,
        currencySymbol,
        currencyType,
      })),
    [data]
  );

  function setTableHeight() {
    if (displayedRowCount === 1) {
      return (displayedRowCount + 2.75) * 60;
    } else if (displayedRowCount === 2) {
      return (displayedRowCount + 2.68) * 60;
    } else if (displayedRowCount === 3) {
      return (displayedRowCount + 3) * 60;
    } else if (displayedRowCount === 4) {
      return (displayedRowCount + 2.55) * 60;
    } else if (displayedRowCount === 5) {
      return (displayedRowCount + 2.48) * 60;
    } else if (displayedRowCount === 6) {
      return (displayedRowCount + 2.42) * 60;
    } else if (displayedRowCount === 7) {
      return (displayedRowCount + 2.35) * 60;
    } else if (displayedRowCount === 8) {
      return (displayedRowCount + 2.28) * 60;
    } else if (displayedRowCount === 9) {
      return (displayedRowCount + 2.22) * 60;
    } else {
      return 10 * 60;
    }
  }

  const [isColumnOpen, setIsColumnOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const openToolPanel = (key: any) => {
    if (key === "columns") {
      if (gridApi) {
        if (!isColumnOpen) gridApi?.current?.api?.openToolPanel(key);
        else gridApi?.current?.api?.closeToolPanel();
        setIsColumnOpen((state) => !state);
        setIsFilterOpen(false);
      }
    } else if (key === "filters") {
      if (gridApi) {
        if (!isFilterOpen) gridApi?.current?.api?.openToolPanel(key);
        else gridApi?.current?.api?.closeToolPanel();
        setIsFilterOpen((state) => !state);
        setIsColumnOpen(false);
      }
    }
  };

  const [filteredRowData, setFilteredRowData] = useState<any>([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const onAgGridFilterChanged = (grid: any) => {
    const filtersApplied = grid.api.isAnyFilterPresent();
    setIsFilterApplied(filtersApplied);

    const filteredData = grid.api
      .getModel()
      .rowsToDisplay.map((node: any) => node.data);
    setFilteredRowData(filteredData);
    setPinnedBottomRowData(grid);
    uploadFilterAndColumn();
  };
  return (
    <div className="bg-white border rounded-md shadow-box border-borderColor">
      <HStack className="flex-col items-start justify-start py-2 lg:flex-row lg:justify-between lg:items-center">
        <GenericTableHeader
          heading={"Cap Table"}
          subHeading={"Shareholders"}
          count={
            isFilterApplied === true ? filteredRowData.length : rowData.length
          }
        />
        <HStack className="items-center justify-between w-full gap-4 px-8 py-2 lg:w-auto lg:justify-end">
          <SearchInput
            placeholder={`Search`}
            onChange={(e: any) => {
              gridApi.current.api.setQuickFilter(e.target.value);
            }}
          />
          <HStack className="gap-4">
            <IconCTAButton
              value={"Columns"}
              onClick={() => openToolPanel("columns")}
              iconName={"fluent:column-triple-edit-20-regular"}
              className={`px-4 font-medium items-center flex flex-row ${
                isColumnOpen ? "text-orange-501" : "text-gray-400"
              }`}
            />
            <IconCTAButton
              value={"Filters"}
              onClick={() => openToolPanel("filters")}
              iconName={"material-symbols:filter-alt"}
              className={`px-4 font-medium items-center flex flex-row ${
                isFilterOpen ? "text-orange-501" : "text-gray-400"
              }`}
            />
          </HStack>
        </HStack>
      </HStack>
      <HStack className="justify-between w-full">
        <Box
          style={{
            height: setTableHeight(),
          }}
          className="w-full h-full max-h-full overflow-x-auto ag-theme-material "
        >
          <AgGridReact
            sideBar={agConfigDashboardSideBar}
            onGridReady={(params) => {
              // Store the grid API referen
              gridApi.current = params;
            }}
            components={componentsRegistery}
            alwaysShowHorizontalScroll={false}
            alwaysMultiSort
            animateRows={true}
            enableCharts={true}
            enableRangeSelection={true}
            defaultColDef={defaultColDef}
            onFilterChanged={onAgGridFilterChanged}
            onColumnEverythingChanged={uploadFilterAndColumn}
            rowData={rowData}
            onRowDataUpdated={setPinnedBottomRowData}
            columnDefs={columnDefs}
            pagination={false}
            suppressRowTransform={true}
            suppressCopyRowsToClipboard={true}
            suppressCopySingleCellRanges={true}
            suppressCellFocus={true}
            suppressMenuHide={false}
            onColumnResized={uploadFilterAndColumn}
            tooltipShowDelay={1000}
            tooltipInteraction={true}
            rowClass={"border-t border-dashed "}
            overlayNoRowsTemplate={
              '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow; margin-top: 50px;">No Rows To Show</span>'
            }
            gridOptions={gridOptions}
            getRowStyle={(params) => {
              if (params.rowIndex % 2 === 0) {
                return { background: "#f8f8f8" };
              } else {
                return { background: "#ffffff" };
              }
            }}
          ></AgGridReact>
        </Box>
      </HStack>
    </div>
  );
}

export default AGGridShareholderQuickRoundTable;
