import { useMutation, useQuery } from "react-query";
import {
  createCompanyDetails,
  getAllIndividualTermsheet,
  getAllTermsheet,
  getCompanyConfig,
  getCompanyDetails,
  getIndividualTermsheet,
  setCompanyConfig,
} from "../api/termsheet";
import { queryClient } from "./client";
import {
  randomAllTermsheetModel,
  randomIndividualTermSheetModel,
} from "./randomPlaceholderData";
import { handleEventForTracking } from "../amplitudeAnalytics";

export function useGetAllTermsheet(companyId: string) {
  return useQuery({
    queryKey: ["getAllTermsheet", companyId],
    queryFn: getAllTermsheet,
    placeholderData: randomAllTermsheetModel(),
  });
}

export function useGetIndividualTermsheet(termSheetId: string) {
  return useQuery({
    queryKey: ["getIndividualTermsheet", termSheetId],
    queryFn: getIndividualTermsheet,
  });
}

export function useGetAllIndividualTermsheet(termSheetId: string) {
  return useQuery({
    queryKey: ["getAllIndividualTermsheet", termSheetId],
    queryFn: getAllIndividualTermsheet,
    placeholderData: randomIndividualTermSheetModel(),
  });
}

export function useGetCompanyDetails() {
  return useQuery({
    queryKey: "getCompanyDetails",
    queryFn: getCompanyDetails,
  });
}

export function useCreateCompanyDetail() {
  return useMutation({
    mutationKey: "createCompanyDetails",
    mutationFn: createCompanyDetails,
    onSuccess: () => {
      queryClient.invalidateQueries("getCompanyDetails");
      queryClient.refetchQueries("getCompanyDetails");
      handleEventForTracking({
        eventName: "createCompanyDetails",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "createCompanyDetails",
        success: false,
        eventType: "API",
      });
    },
  });
}
export function useSetCompanyConfig() {
  return useMutation({
    mutationKey: "setCompanyConfig",
    mutationFn: setCompanyConfig,
    onSuccess: () => {
      handleEventForTracking({
        eventName: "setCompanyConfig",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "setCompanyConfig",
        success: false,
        eventType: "API",
      });
    },
  });
}
export function useGetCompanyConfig(company: string) {
  return useQuery({
    queryKey: ["getCompanyConfig", company],
    queryFn: () => getCompanyConfig(company),
  });
}
