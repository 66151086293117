import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, HStack } from "../../components/utils";
import { useGetInstrumentAndShareHolder } from "../../queries/quickRound";
import { RoundHeaderCards } from "./RoundHeaderCard";
import BCHeader from "../../shared/BCHeader";
import { useGetIndividualSecondaryDetail } from "../../queries/transactionRound";
import { IndividualRoundDetailModel } from "../../types/RoundsModel";
import { useAuthStore } from "../../store";
import { extractSecondaryTransaction } from "../newQuickTransaction/ExtractionUtility";
import {
  IndividualSecondaryCapTable,
  SecondaryTransactionDataModel,
} from "../../types/AllTransactionModel";
import { secondaryHeaderCardList } from "../../constants/TransactionConstants";
import { useShareholderInstrumentStore } from "../../store/shareholderInstrumentStore";
import SearchAutoCompleteDropDown from "../../components/shared/SearchAutoCompleteDropDown";
import { useInternalSwitchStateStore } from "../../store/searchDropDown";
import { SwitchDropDownModel } from "./RoundsAllTransactions";
import {
  useInvGetIndividualSecondaryDetail,
  useInvGetInstrumentAndShareHolder,
} from "../../queries/investorPortal";
import AGGridRoundsTable from "./RoundsAGTable";
import AGGridRoundTransactionTable from "./RoundsTransactionAgTable";
import { handleEventForTracking } from "../../amplitudeAnalytics";

export default function RoundsSecondary() {
  const { id, cId, token } = useParams();
  const eventId = id || "";
  const [secondaryTransactions, setSecondaryTransaction] = useState<
    SecondaryTransactionDataModel[]
  >([]);
  const navigate = useNavigate();

  const companyId = useAuthStore.getState().companyId || "";

  const { isPlaceholderData, data: roundDetail } = cId
    ? useInvGetIndividualSecondaryDetail(eventId, cId, token || "")
    : useGetIndividualSecondaryDetail(eventId);

  const transactionSwitchStore = useInternalSwitchStateStore();
  const [selectedTransaction, setSelectedTransaction] =
    useState<SwitchDropDownModel>({ name: "", id: "", type: "" });

  function getSelectedValue(transaction?: SwitchDropDownModel) {
    if (transaction) {
      if (cId) {
        handleEventForTracking({ eventName: "Inv Transactions Search" });
        navigate(
          `/inv/transactions/${transaction.type?.toLowerCase()}/${
            transaction.id
          }/${cId}/${token}`
        );
      } else {
        handleEventForTracking({ eventName: "Transactions Search" });
        navigate(
          `/transactions/${transaction.type?.toLowerCase()}/${transaction.id}`
        );
      }
    }
  }

  const {
    refetch: getInstrumentAndShareHolder,
    data: instrumentShareholderInfoData,
  } = cId
    ? useInvGetInstrumentAndShareHolder(cId, token || "")
    : useGetInstrumentAndShareHolder(companyId, cId);

  const shareholderAndInstrumentstore = useShareholderInstrumentStore();

  useEffect(() => {
    if (
      cId ||
      (shareholderAndInstrumentstore.shareholders.length === 0 &&
        shareholderAndInstrumentstore.instrumentClasses.length === 0)
    )
      getInstrumentAndShareHolder().then((data) => {
        shareholderAndInstrumentstore.setInstrumentClass(
          data?.data?.instrumentClasses || []
        );
        shareholderAndInstrumentstore.setShareholders(
          data?.data?.shareholders || []
        );
        shareholderAndInstrumentstore.setInstrumentClassSubTypes(
          data?.data?.instrumentClassSubTypes || {}
        );
      });
  }, []);

  useEffect(() => {
    if (
      roundDetail?.transactions &&
      shareholderAndInstrumentstore.shareholders.length > 0 &&
      shareholderAndInstrumentstore.instrumentClasses.length > 0
    ) {
      const secondary = extractSecondaryTransaction(
        roundDetail?.transactions || [],
        shareholderAndInstrumentstore?.shareholders || [],
        shareholderAndInstrumentstore?.instrumentClasses || []
      );
      if (!isPlaceholderData)
        setSelectedTransaction({
          name: roundDetail?.name,
          id: roundDetail.id,
          type: roundDetail?.type,
        });
      setSecondaryTransaction(secondary);
    }
  }, [shareholderAndInstrumentstore, roundDetail]);

  const _captableData: IndividualSecondaryCapTable[] =
    roundDetail?.capTable || [];
  return (
    <div
      className={`${cId ? "bg-white px-4" : ""} ${
        isPlaceholderData ? "loading" : "min-h-full"
      }`}
    >
      <HStack className="justify-between min-h-[45px] items-center">
        <BCHeader
          className="items-baseline "
          bcTitle="Transaction"
          bcSubTitle={roundDetail?.name ?? "Secondary"}
          cin={cId}
          token={token}
        />
        <HStack>
          {selectedTransaction ? (
            <Box className="bg-white rounded-md w-48 ml-4 h-8">
              <SearchAutoCompleteDropDown
                placeholder="Switch Transaction"
                data={
                  transactionSwitchStore.transaction as SwitchDropDownModel[]
                }
                getSelectedValue={getSelectedValue}
                selectedOption={selectedTransaction as SwitchDropDownModel}
                field={"name" as keyof SwitchDropDownModel}
              />
            </Box>
          ) : (
            ""
          )}
        </HStack>
      </HStack>

      <div className="mx-auto max-w-full pb-8">
        {roundDetail && (
          <dl className="mt-5 grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-4">
            {secondaryHeaderCardList.map((headertype) => (
              <RoundHeaderCards
                key={headertype}
                roundDetail={
                  roundDetail as unknown as IndividualRoundDetailModel
                }
                headerType={headertype}
              />
            ))}
          </dl>
        )}
      </div>

      {secondaryTransactions.length > 0 && (
        <AGGridRoundTransactionTable
          isInvestorPortal={!!cId}
          isPreviewOnly={true}
          secondaryTransactionProps={secondaryTransactions}
          primaryTransactionProps={[]}
        />
      )}

      {/*Dirty Commit <div className=" bg-white shadow-box rounded-lg">
        <DashBoardGenericSecondaryCaptable
          isInvestorPortal={cId !== ""}
          captableData={_captableData || []}
        />
      </div> */}

      <AGGridRoundsTable
        capTableData={_captableData}
        isInvestorPortal={!!cId}
        roundName={roundDetail?.name || ""}
        eventId={eventId}
        mode={"secondary"}
      />
      {/*Dirty Commit <IndividualNotes data={"dataText"} /> */}
    </div>
  );
}
