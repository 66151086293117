import React from "react";
import { formatDisplayDate } from "../../utils/date";

const CompanyoverviewCard = ({
  name,
  value,
  currency,
  isDate = false,
}: {
  name: string;
  value: number | string;
  currency?: string;
  isDate?: boolean;
  className?: string;
}) => (
  <div>
    <p className="text-gray-500 font-medium">{name}</p>
    <p className="text-lg pt-2 font-medium">
      {isDate ? formatDisplayDate(value as string) : value}
    </p>
    {currency && <span className="text-gray-500">{currency}</span>}
  </div>
);

export default CompanyoverviewCard;
