import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  CellClickedEvent,
  ColDef,
  GridApi,
  SideBarDef,
} from "ag-grid-community";
import { useNavigate } from "react-router";
import { AgGridReact } from "ag-grid-react";
import { Action, CTADropdown } from "../../components/shared/Dropdown";
import { agConfigDashboardSideBar } from "../dashboardPage/AgGridConfig";
import {
  getCurrency,
  getCurrencySymbol,
  getCurrencyType,
} from "../../utils/currencyFormatter";

import { Box, HStack, useGetCompanyName } from "../../components/utils";
import GenericTableHeader from "../../shared/TableHeader";
import { IconCTAButton } from "../quickRound/CTAButtonComponents";
import QuickSearch from "../rounds/QuickSearch";
import { useAuthStore } from "../../store";
import {
  useGetOnFilterState,
  usePostOnFilterState,
} from "../dashboardPage/AgGridCacheQuery";
import { tableCellStyle } from "../../components/TableComponent";

import {
  IndividualTermsheet,
  NewTermsheetDetails,
  TermsheetData,
} from "../../types/TermsheetModel";
import {
  ActionMenuRender,
  ExpiryDateRender,
  RoundSizeRender,
  TermsheetNameRender,
  ValuationRender,
} from "./AllTermsheetAgComponent";
import { useAgGridTableState } from "../../store/agGridTableStore";
import SearchInput from "../../components/shared/SearchInput";
import { handleEventForTracking } from "../../amplitudeAnalytics";
import { getRoundCurrencySymbol } from "../../utils/currencyRoboto";
import { useRoundModelStore } from "../../store/roundModelingStore";
import {
  ExistingInvestorDetails,
  InvestorState,
  ModelBuilder,
  ModelState,
  NewInvestorDetails,
  OngoingInvestorDetails,
  RoundInputDetails,
} from "../../types/Modeling";
import { InvestorRightsType } from "../modeling/RoundCreationModel";
import { GetPrePostCaptableData } from "./TermsheetPreCaptableAgTable";
import { useGetIndividualTermsheet } from "../../queries/termsheet";
import { getIndividualTermsheet } from "../../api/termsheet";
import { useGetUserRoleGrants } from "../../queries/auth";

function AGGridTermsheeCapTable({
  captableData,
}: {
  captableData: TermsheetData[];
}) {
  const _captableData: TermsheetData[] = captableData;
  const currencySymbol = getCurrencySymbol();
  const currencyType = getCurrencyType();
  const companyName = useGetCompanyName();
  const companyId = useAuthStore().companyId ?? "";
  const userId = useAuthStore().user?.userId ?? 0;
  const gridApi = useRef<any>(null);
  const { refetch, data: filterState } = useGetOnFilterState(
    `${userId}`,
    companyId
  );
  const { data: userRoleData } = useGetUserRoleGrants();
  const agTableStore = useAgGridTableState();
  const roundCreationStore = useRoundModelStore();

  useEffect(() => {
    refetch().then((data) => {
      if (data.data) agTableStore.setState(data.data);
      setColumnSetting(data.data?.termsheetColumnModel);
      setFilterSetting(data.data?.termsheetFilterModel);
    });
  }, []);

  const { mutate: postOnFilter } = usePostOnFilterState();

  function getColumnSetting() {
    if (gridApi.current) return gridApi.current.columnApi?.getColumnState();
    return {};
  }

  function setColumnSetting(model: any) {
    if (gridApi.current)
      gridApi.current.columnApi.applyColumnState({ state: model });
    return {};
  }

  // Gets filter model via the grid API
  const getFilterSetting = () => {
    if (gridApi.current) return gridApi.current.api?.getFilterModel();
    return {};
  };

  const setFilterSetting = (model: any) => {
    if (gridApi.current) return gridApi.current.api?.setFilterModel(model);
    return {};
  };

  const uploadFilterAndColumn = async () => {
    const columnState = await getColumnSetting();
    const filterState = await getFilterSetting();
    postOnFilter({
      userId: `${userId}`,
      companyId,
      filterData: {
        ...agTableStore.state,
        termsheetColumnModel: columnState,
        termsheetFilterModel: filterState,
      },
    });
  };

  const cin = "";

  const componentsRegistery = useMemo(
    () => ({
      termsheetName: memo(TermsheetNameRender),
      expDate: memo(ExpiryDateRender),
      valuation: memo(ValuationRender),
      roundSize: memo(RoundSizeRender),
    }),
    []
  );

  async function createRoundModel(data: NewTermsheetDetails, id: string) {
    const termsheet = await getIndividualTermsheet({
      queryKey: ["getIndividualTermsheet", id],
      meta: undefined,
    });
    const { investors, roundDetails, otherTerms } = data;
    const finalCaptableData = await GetPrePostCaptableData(
      termsheet as IndividualTermsheet
    );
    const roundIdentifier: string[] = (
      roundDetails.roundName || "New Round Model"
    ).split(" ");
    const roundData: RoundInputDetails = {
      roundName: roundDetails.roundName || "New Round Model",
      hasToCalculatePps: true,
      roundIdentifier:
        roundIdentifier[0][0] +
        (roundIdentifier.length > 1 ? roundIdentifier[1][0] : ""),
      roundSize: Number(roundDetails.roundSize) || 0,
      preMoneyValuation: Number(roundDetails.preMoneyValuation) || 0,
      postMoneyValuation: Number(roundDetails.postMoneyValuation) || 0,
      postRoundEsopPercentage:
        Number(roundDetails.postRoundEsopPercentage) || 0,
      autoDiluteEsop: Number(roundDetails.postRoundEsopPercentage) === 0,
      pricedRound: roundDetails.pricedRound.toString() === "Yes",
      fdbSharePrice: 0,
    };
    const newInvestorData: NewInvestorDetails = { investorDetails: [] };
    const existingInvestorData: ExistingInvestorDetails = {
      investorDetails: [],
      listOfInvestor: [],
    };
    investors.forEach((investor) => {
      if (investor.companyInvestorId !== "") {
        const shareholderData = finalCaptableData?.filter(
          (shareHolder) =>
            shareHolder.shareholderName === investor.shareholderName
        );

        const existingInvestor =
          termsheet.prePostCaptable.postEsopAdjustedCaptable &&
          termsheet.prePostCaptable.postEsopAdjustedCaptable.filter(
            (item) => item.id === investor.companyInvestorId
          );
        existingInvestorData.investorDetails.push({
          amountToInvest: Number(investor.investmentAmount),
          id: "",
          investorId: investor.companyInvestorId || "",
          investorName: investor.shareholderName,
          postHoldingPercentage:
            shareholderData.length > 0
              ? shareholderData[0].postHoldingFdbPercentage * 100
              : 0,
          preHoldingPercentage:
            shareholderData.length > 0
              ? shareholderData[0].preHoldingFdbPercentage * 100
              : 0,
          unLockedInputFeild: {
            hasToCalculateAmt: false,
            value: investor.investmentAmount || 0,
          },
          hasBlendedSecondaries: false,
          fdbShares: shareholderData[0].postHoldingFdbShares || 0,
          investorType:
            existingInvestor.length > 0 ? existingInvestor[0].type : "VC",
        });
        existingInvestorData.ongoingExistingInvestorDetails = {
          amountToInvest: Number(investor.investmentAmount),
          id: "",
          investorId: investor.companyInvestorId || "",
          investorName: investor.shareholderName,
          postHoldingPercentage:
            shareholderData.length > 0
              ? shareholderData[0].postHoldingFdbPercentage * 100
              : 0,
          preHoldingPercentage:
            shareholderData.length > 0
              ? shareholderData[0].preHoldingFdbPercentage * 100
              : 0,
          unLockedInputFeild: {
            hasToCalculateAmt: false,
            value: investor.investmentAmount || 0,
          },
          hasBlendedSecondaries: false,
          fdbShares: shareholderData[0].postHoldingFdbShares || 0,
          state: InvestorState.ToBeSaved,
          investorType:
            existingInvestor.length > 0 ? existingInvestor[0].type : "VC",
        } as unknown as OngoingInvestorDetails;
      } else {
        const shareholderData = finalCaptableData?.filter(
          (shareHolder) =>
            shareHolder.shareholderName === investor.shareholderName
        );
        newInvestorData.investorDetails.push({
          investorName: investor.shareholderName,
          amountToInvest: Number(investor.investmentAmount),
          investorType: investor.shareholderType || "VC",
          optedInvestorRightType: InvestorRightsType.None,
          id: "",
          investorId: "",
          postHoldingPercentage:
            shareholderData.length > 0
              ? shareholderData[0].postHoldingFdbPercentage * 100
              : 0,
          preHoldingPercentage:
            shareholderData.length > 0
              ? shareholderData[0].preHoldingFdbPercentage * 100
              : 0,
          unLockedInputFeild: {
            hasToCalculateAmt: false,
            value: investor.investmentAmount || 0,
          },
          hasBlendedSecondaries: false,
          fdbShares: shareholderData[0].postHoldingFdbShares || 0,
        });
        newInvestorData.ongoingExistingInvestorDetails = {
          investorName: investor.shareholderName,
          amountToInvest: Number(investor.investmentAmount),
          investorType: investor.shareholderType || "VC",
          optedInvestorRightType: InvestorRightsType.None,
          id: "",
          investorId: "",
          postHoldingPercentage:
            shareholderData.length > 0
              ? shareholderData[0].postHoldingFdbPercentage * 100
              : 0,
          preHoldingPercentage:
            shareholderData.length > 0
              ? shareholderData[0].preHoldingFdbPercentage * 100
              : 0,
          unLockedInputFeild: {
            hasToCalculateAmt: false,
            value: investor.investmentAmount || 0,
          },
          hasBlendedSecondaries: false,
          fdbShares: shareholderData[0].postHoldingFdbShares || 0,
          state: InvestorState.ToBeSaved,
        } as unknown as OngoingInvestorDetails;
      }
    });
    const roundModel: ModelBuilder = {
      companyId,
      isNavigating: true,
      currentTab: 1,
      highestVisitedTabLevel: 1,
      roundDetailsTab: roundData,
      newInvestorDetails: newInvestorData,
      existingInvestorDetails: existingInvestorData,
      isTermsheet: true,
    };

    return roundModel;
  }
  async function handleModifyAction(action: string, data: any) {
    if (action === "Create Round Model") {
      const termsheetData = data.termsheetData as NewTermsheetDetails;
      const roundModel = await createRoundModel(termsheetData, data.id);
      if (roundModel) {
        roundCreationStore.connect();
        roundCreationStore.sendMessage(roundModel);
        navigate("/modeling/roundmodel");
      }
    }
  }

  const navigate = useNavigate();

  const onClickTermsheetName = (id: string) => {
    handleEventForTracking({ eventName: "Term Sheet Name" });
    navigate(`/termsheet/overview/${id}`);
  };

  const isValidDate = (date: string) =>
    Number.isNaN(date) &&
    new Date(date).toString() !== "Invalid Date" &&
    !Number.isNaN(Date.parse(date));

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      autoHeight: true,
      wrapHeaderText: true,
      suppressColumnVirtualisation: true,
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      filterParams: {
        buttons: ["reset"],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
      flex: 1,
    }),
    []
  );
  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        headerName: "Termsheet Name",
        field: "termsheetName",
        cellRenderer: "termsheetName",
        initialWidth: 250,
        filter: "agSetColumnFilter",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipTermsheetName",
      },
      {
        headerName: "Exp Date",
        field: "expDate",
        cellRenderer: "expDate",
        initialWidth: 250,
        filter: "agDateColumnFilter",
        filterParams: {
          comparator: (dateFromFilter: Date, cellValue: any) => {
            if (cellValue == null) {
              return 0;
            }
            const dateParts = cellValue.split("-");
            const day = Number(dateParts[2]);
            const month = Number(dateParts[1]) - 1;
            const year = Number(dateParts[0]);
            const cellDate = new Date(year, month, day);
            if (cellDate < dateFromFilter) {
              return -1;
            } else if (cellDate > dateFromFilter) {
              return 1;
            }
            return 0;
          },
        },
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
      },
      {
        headerName: "Pre Money Valuation",
        field: "valuation",
        cellRenderer: "valuation",
        initialWidth: 250,
        filter: "agNumberColumnFilter",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipValuation",
      },
      {
        headerName: "Round Size",
        field: "roundSize",
        filter: "agNumberColumnFilter",
        cellRenderer: "roundSize",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipRoundSize",
      },
      {
        headerName: "",
        field: "actions",
        pinned: "right",
        hide: false,
        width: 80,
        maxWidth: 80,
        filter: false,
        menuTabs: [],
        colId: "action-column",
        suppressNavigable: true,
        suppressColumnsToolPanel: true,
        cellStyle: { "padding-top": "10px", "line-height": "20px" },
        resizable: false,
        sortable: false,
        cellRendererParams: ({ value }: { value: any }) => value.props,
        cellRenderer: CTADropdown,
      },
    ],
    []
  );
  const gridOptions = {
    suppressRowVirtualisation: true, // Add this line to disable row virtualization
    paginationAutoPageSize: false, // Optional: Set to false if using custom pagination
    suppressScrollOnNewData: true, // Optional: Set to true to prevent scrolling to the top on
  };

  const rowData = useMemo(
    () =>
      _captableData?.map((template) => ({
        id: template.id,
        expDate: template.expiryDate,
        valuation: template.termsheetData.roundDetails.preMoneyValuation,
        tooltipValuation:
          template.termsheetData.roundDetails.preMoneyValuation &&
          template.termsheetData.roundDetails.preMoneyValuation.toLocaleString(
            currencyType
          ),
        roundSize: template.termsheetData.roundDetails.roundSize,
        tooltipRoundSize:
          template.termsheetData.roundDetails.roundSize &&
          template.termsheetData.roundDetails.roundSize.toLocaleString(
            currencyType
          ),
        termsheetName: template.termsheetName,
        currencySymbol,
        currencyType,
        actions: (
          <CTADropdown
            actions={[
              {
                name: "Create Round Model",
                disabled:
                  userRoleData?.length > 0 &&
                  userRoleData[0].role === "Admin Viewer",
              },
            ]}
            onAction={async (action) => {
              await handleModifyAction(action.name, template);
            }}
          />
        ),
      })),
    [_captableData]
  );

  const onPageSizeChanged = useCallback(() => {
    const value = (document.getElementById("page-size") as HTMLInputElement)
      .value;
    gridApi.current?.paginationSetPageSize(Number(value));
  }, []);

  const handleCellClick = (cellParams: CellClickedEvent<any, any>) => {
    if (cellParams.column.getColId() !== "action-column") {
      const template = cellParams.data;
      if (cellParams.column.getColId() === "termsheetName") {
        onClickTermsheetName(template.id);
      }
    }
  };
  const [isColumnOpen, setIsColumnOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const openToolPanel = (key: any) => {
    if (key === "columns") {
      if (gridApi) {
        if (!isColumnOpen) gridApi?.current?.api?.openToolPanel(key);
        else gridApi?.current?.api?.closeToolPanel();
        setIsColumnOpen((state) => !state);
        setIsFilterOpen(false);
      }
    } else if (key === "filters") {
      if (gridApi) {
        if (!isFilterOpen) gridApi?.current?.api?.openToolPanel(key);
        else gridApi?.current?.api?.closeToolPanel();
        setIsFilterOpen((state) => !state);
        setIsColumnOpen(false);
      }
    }
  };

  const [filteredRowData, setFilteredRowData] = useState<any>([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const onAgGridFilterChanged = (grid: any) => {
    const filtersApplied = grid.api.isAnyFilterPresent();
    setIsFilterApplied(filtersApplied);

    const filteredData = grid.api
      .getModel()
      .rowsToDisplay.map((node: any) => node.data);
    setFilteredRowData(filteredData);
    uploadFilterAndColumn();
  };
  return (
    <>
      <HStack className="justify-between py-2 items-center  bg-white rounded-md">
        <GenericTableHeader
          heading={"Term Sheets Summary"}
          subHeading={"Term Sheets"}
          count={
            isFilterApplied === true ? filteredRowData.length : rowData.length
          }
        />
        <HStack className="justify-between w-full lg:w-auto lg:justify-end py-2 items-center gap-4 px-8">
          <SearchInput
            placeholder={`Search`}
            onChange={(e: any) => {
              gridApi.current.api.setQuickFilter(e.target.value);
            }}
          />

          <IconCTAButton
            value={"Columns"}
            onClick={() => openToolPanel("columns")}
            iconName={"fluent:column-triple-edit-20-regular"}
            className={`px-4 font-medium items-center flex flex-row ${
              isColumnOpen ? "text-orange-501" : "text-gray-400"
            }`}
          />
          <IconCTAButton
            value={"Filters"}
            onClick={() => openToolPanel("filters")}
            iconName={"material-symbols:filter-alt"}
            className={`px-4 font-medium items-center flex flex-row ${
              isFilterOpen ? "text-orange-501" : "text-gray-400"
            }`}
          />
        </HStack>
      </HStack>
      <HStack className="justify-between w-full">
        <Box
          style={{
            height: `${
              (rowData.length >= 10 ? 10 : rowData.length + 3) * 58
            }px`,
          }}
          className="w-full max-h-full overflow-x-auto ag-theme-material h-full "
        >
          <AgGridReact
            sideBar={agConfigDashboardSideBar}
            onGridReady={(params) => {
              // Store the grid API referen
              gridApi.current = params;
            }}
            components={componentsRegistery}
            alwaysShowHorizontalScroll
            alwaysMultiSort
            animateRows={true}
            enableCharts={true}
            enableRangeSelection={true}
            defaultColDef={defaultColDef}
            onFilterChanged={onAgGridFilterChanged}
            onColumnEverythingChanged={uploadFilterAndColumn}
            rowData={rowData}
            onCellClicked={handleCellClick}
            columnDefs={columnDefs}
            pagination={false}
            suppressRowTransform={true}
            suppressCopyRowsToClipboard={true}
            suppressCopySingleCellRanges={true}
            suppressCellFocus={true}
            suppressMenuHide={false}
            onColumnResized={uploadFilterAndColumn}
            tooltipShowDelay={1000}
            rowClass={"border-t border-dashed"}
            gridOptions={gridOptions}
            overlayNoRowsTemplate={
              '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow; margin-top: 50px;">No Rows To Show</span>'
            }
            getRowStyle={(params) => {
              if (params.rowIndex % 2 === 0) {
                return { background: "#f8f8f8" };
              } else {
                return { background: "#ffffff" };
              }
            }}
          ></AgGridReact>
        </Box>
      </HStack>
    </>
  );
}

export default AGGridTermsheeCapTable;
