import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { classNames } from "../utils/string";

const IndividualNotes = ({ data }: { data: string }) => (
  <Disclosure
    as="div"
    key="notes"
    className="mt-4 shadow-box bg-white rounded-md border-t-4 border-[#E85936] border-opacity-10"
    defaultOpen={true}
  >
    {({ open }) => (
      <>
        <dt
          className={`text-lg border ${
            open ? "border-b-0 rounded-t-md" : "rounded-md"
          } border-gray-100 p-4`}
        >
          <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-400">
            <span className="font-medium text-slate-900 text-base">Notes</span>
            <span className="ml-6 flex items-center">
              <ChevronDownIcon
                className={classNames(
                  open ? "-rotate-180" : "rotate-0",
                  "h-6 w-6 transform text-slate-500"
                )}
                aria-hidden="true"
              />
            </span>
          </Disclosure.Button>
        </dt>
        <Disclosure.Panel
          as="dd"
          className="border border-gray-100 p-4 rounded-b-md"
        >
          <div className="mt-1">
            <textarea
              rows={4}
              name="comment"
              id="comment"
              readOnly
              className="bg-slate-50 block w-full placeholderLoader rounded-md border-gray-300 shadow-box focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              defaultValue={data}
            />
          </div>
        </Disclosure.Panel>
      </>
    )}
  </Disclosure>
);

export default IndividualNotes;
