import { ColumnOptions } from "../types/DashboardModel";
import { GenericCumulativeHolding } from "../types/GenericCaptableModel";
import { getCurrencySymbol } from "../utils/currencyFormatter";

export const initialColumnState: ColumnOptions[] = [
  {
    name: "roundIdentifierDetails",
    displayName: "Rounds",
    isVisible: true,
    isDisabled: false,
  },
  {
    name: "equityShares",
    displayName: "Equity",
    isVisible: true,
    isDisabled: false,
  },
  {
    name: "prefShares",
    displayName: "Preference",
    isVisible: true,
    isDisabled: false,
  },
  {
    name: "debentures",
    displayName: "Debentures",
    isVisible: true,
    isDisabled: false,
  },
  {
    name: "warrants",
    displayName: "Warrants",
    isVisible: false,
    isDisabled: false,
  },
  {
    name: "notes",
    displayName: "Notes",
    isVisible: false,
    isDisabled: false,
  },
  {
    name: "investedAmount",
    displayName: "Amount Invested",
    isVisible: false,
    isDisabled: false,
  },
  {
    name: "fdbShares",
    displayName: "FDB Shares",
    isVisible: true,
    isDisabled: false,
  },
  {
    name: "fdbPercentage",
    displayName: "FDB %",
    isVisible: true,
    isDisabled: false,
  },
];

export const initialConvertibleColumnState: ColumnOptions[] = [
  {
    name: "roundIdentifier",
    displayName: "Rounds",
    isVisible: true,
    isDisabled: false,
  },
  {
    name: "method",
    displayName: "Method",
    isVisible: true,
    isDisabled: false,
  },
  {
    name: "valutionApplied",
    displayName: "Val. Applied",
    isVisible: true,
    isDisabled: false,
  },
  {
    name: "conversionRatio",
    displayName: "Conv. Ratio",
    isVisible: true,
    isDisabled: false,
  },
  {
    name: "amountInvested",
    displayName: `Amt. Invested`,
    isVisible: true,
    isDisabled: false,
  },
  {
    name: "holdingPercentage",
    displayName: "Holding %",
    isVisible: true,
    isDisabled: false,
  },
];

export const filterString = [
  "fdbShares" as keyof GenericCumulativeHolding,
  "roundIdentifierDetails" as keyof GenericCumulativeHolding,
  "name" as keyof GenericCumulativeHolding,
  "equityShares" as keyof GenericCumulativeHolding,
  "debentures" as keyof GenericCumulativeHolding,
  "type" as keyof GenericCumulativeHolding,
  "fdbPercentage" as keyof GenericCumulativeHolding,
  "prefShares" as keyof GenericCumulativeHolding,
  "investedAmount" as keyof GenericCumulativeHolding,
];

export const sliderStyle = {
  color: "#e8593633",
  height: 8,
  "& .MuiSlider-track": {
    border: "none",
    color: "#00A624",
  },
  "& .MuiSlider-thumb": {
    height: 16,
    width: 24,
    borderRadius: "10px",
    backgroundColor: "#00A624",
    border: "2px solid currentColor",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "#000000",
    padding: 0,
    backgroundColor: "#FFFFFF",
    borderRadius: "30px",
    color: "#000000",
    transformOrigin: "bottom left",
  },
  ".MuiSlider-mark": {
    width: 2,
    height: 8,
    backgroundColor: "#464E5F",
  },
  ".MuiSlider-markActive ": {
    width: 2,
    height: 8,
    backgroundColor: "#FFFFFF",
  },
  ".MuiSlider-markLabel": {
    transform: "rotate(0deg)",
    width: 4,
  },
};

export const sliderConvertibleStyle = {
  color: "#e8593633",
  height: 8,
  "& .MuiSlider-track": {
    border: "none",
    color: "#00A624",
  },
  "& .MuiSlider-thumb": {
    height: 16,
    width: 24,
    borderRadius: "10px",
    backgroundColor: "#00A624",
    border: "2px solid currentColor",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "#000000",
    padding: 0,
    backgroundColor: "#FFFFFF",
    borderRadius: "30px",
    color: "#000000",
    transformOrigin: "bottom left",
  },
  ".MuiSlider-mark": {
    width: 2,
    height: 8,
    backgroundColor: "#464E5F",
  },
  ".MuiSlider-markActive ": {
    width: 2,
    height: 8,
    backgroundColor: "#FFFFFF",
  },
  ".MuiSlider-markLabel": {
    transform: "rotate(0deg)",
    width: 4,
  },
};

export const sliderRightsStyle = {
  color: "#e8593633",
  height: 4,
  "& .MuiSlider-track": {
    border: "none",
    color: "#ebb8ab",
  },
  "& .MuiSlider-thumb": {
    height: 18,
    width: 48,
    borderRadius: "2px",
    backgroundColor: "#E85936",
    border: "2px solid currentColor",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "#000000",
    padding: 0,
    backgroundColor: "#FFFFFF",
    borderRadius: "30px",
    color: "#000000",
    transformOrigin: "bottom left",
  },
  ".MuiSlider-mark": {
    width: 2,
    height: 8,
    backgroundColor: "#464E5F",
  },
  ".MuiSlider-markActive ": {
    width: 2,
    height: 8,
    backgroundColor: "#FFFFFF",
  },
  ".MuiSlider-markLabel": {
    transform: "rotate(0deg)",
    fontSize: 11,
    marginLeft: "-30px",
    width: 4,
  },
};
