import { Form, Formik } from "formik";
import { useParams } from "react-router";
import _, { initial } from "lodash";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { useEffect, useState } from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { HStack, VStack, Error } from "../../components/utils";
import {
  formatCurrency,
  getCompanyCurrency,
  getCurrency,
  getCurrencySymbol,
  getCurrencyType,
  getKeyByValue,
} from "../../utils/currencyFormatter";
import { Input, Label } from "../../components/shared/InputField";
import {
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../quickRound/CTAButtonComponents";
import { Select } from "../../components/shared/Select";
import { useNewShareholderInstrumentStore } from "../../store/newShareholderAndInstrumentStore";
import {
  AddSecondaryDistinctShareTransactionType,
  SecondaryTransactionDataModel,
} from "../../types/quickRound";
import { useShareholderInstrumentStore } from "../../store/shareholderInstrumentStore";
import { validationSecondaryShareHolderSchema } from "./newTransactionValidationSchema";
import { useGetSecondaryInvestors } from "../../queries/transactionRound";
import { currencies, currencyTypes } from "../../constants/CompanyCurrencyType";
import { getRoundCurrencySymbol } from "../../utils/currencyRoboto";
import { ShowNumberInWords } from "../../components/shared/UiElement";
import { queryClient } from "../../queries/client";
import {
  convertToNumber,
  filterOnlyNumbers,
  numberWithCommas,
} from "../../utils/numUtils";

interface BeneficiariesModel {
  name: string;
  value: string;
}

type AddSecondaryPopUpProps = {
  pricedRound: boolean | null;
  initialValue?: any;
  onPrimaryAction: () => void;
  onSecondaryAction: () => void;
  data: any;
  mode: string;
};

type TypeSharesPerSecurity = {
  instrumentId: string;
  instrumentName: string;
  noOfShares: number;
  investorId: string;
};

export default function AddSecondaryPopUp({
  pricedRound,
  onPrimaryAction,
  onSecondaryAction,
  data,
  mode,
}: AddSecondaryPopUpProps) {
  const { id, cId, token } = useParams();
  const eventId = id || "";
  const beneficiariesList: BeneficiariesModel[] = [];
  const [selectedSellerSharesPerSecurity, setSelectedSellerSharesPerSecurity] =
    useState<string[]>([]);
  const [sharesPerSecurity, setSharesPerSecurity] = useState<
    TypeSharesPerSecurity[]
  >([]);
  const [availableShares, setAvailableShares] = useState<number | undefined>(0);
  const [totalShares, setTotalShares] = useState<number | undefined>(0);

  const [onHoverTransactionAmount, setOnHoverTransactionAmount] =
    useState(false);
  const [onHoverNumberOfshares, setOnHoverNumberOfShares] = useState(false);
  const [onHoverExchangeRate, setOnHoverExchangeRate] = useState(false);

  const [formatExchangeRate, setFormatExchangeRate] = useState("");
  const [formatNumberOfShares, setFormatNumberOfShares] = useState("");
  const [formatTransactionAmount, setFormatTransctionAmount] = useState("");

  const currencySymbol = getCurrencySymbol();
  const currency = getCurrencyType();
  const companyCurrency = getCompanyCurrency();

  const secondaryTransactionStore = useNewShareholderInstrumentStore();
  const secondaryDetails = useGetSecondaryInvestors();
  const shareholderInstruments = useShareholderInstrumentStore();

  const shareHolders: string[] = shareholderInstruments.shareholders.map(
    (shareHolderDetail) => shareHolderDetail.name
  );
  const instruments: string[] = shareholderInstruments.instrumentClasses.map(
    (instrument) => instrument.name
  );

  const pricedRoundInstrumentClasses = shareholderInstruments.instrumentClasses;
  const initialSecondaryShareHolderValues: AddSecondaryDistinctShareTransactionType =
    {
      uuid: data?.uuid || "",
      numberOfShares: data?.numberOfShares || 0,
      buyerName: data?.buyerName || "",
      sellerName: data?.sellerName || "",
      date: data?.date || "",
      security: data?.security || "",
      //dirty commit distinctiveShareNumber: "",
      amountInvested: data?.amountInvested || 0,
      transactionCurrency: data?.transactionCurrency || companyCurrency || "",
      exchangeRate: data?.exchangeRate || 1,
    };

  useEffect(() => {
    if (initialSecondaryShareHolderValues.exchangeRate) {
      setFormatExchangeRate(
        `${initialSecondaryShareHolderValues.exchangeRate}`
      );
    }
    if (initialSecondaryShareHolderValues.amountInvested) {
      setFormatTransctionAmount(
        `${initialSecondaryShareHolderValues.amountInvested}`
      );
    }
    if (initialSecondaryShareHolderValues.numberOfShares) {
      setFormatNumberOfShares(
        `${initialSecondaryShareHolderValues.numberOfShares}`
      );
    }
  }, [
    initialSecondaryShareHolderValues.exchangeRate,
    initialSecondaryShareHolderValues.numberOfShares,
    initialSecondaryShareHolderValues.amountInvested,
  ]);

  const handleSubmit = (values: AddSecondaryDistinctShareTransactionType) => {
    const exists = secondaryTransactionStore.secondary.some(
      (transaction) =>
        transaction.buyerName === values.buyerName &&
        transaction.sellerName === values.sellerName &&
        transaction.numberOfShares === values.numberOfShares &&
        transaction.security === values.security &&
        transaction.amountInvested === values.amountInvested &&
        transaction.date === values.date &&
        transaction.transactionCurrency === values.transactionCurrency &&
        transaction.exchangeRate === values.exchangeRate
    );

    if (exists) {
      toast("Transaction already exists!", { type: "error", autoClose: 2000 });
      return;
    }
    const existingIndex = secondaryTransactionStore.secondary.findIndex(
      (transaction: any) => transaction.uuid === values.uuid
    );

    const transaction: SecondaryTransactionDataModel = {
      uuid: uuidv4(),
      buyerName: values.buyerName,
      sellerName: values.sellerName,
      shareNumber: values.distinctiveShareNumber,
      investorType:
        shareholderInstruments.shareholders.find(
          (shareHolderDetail) => shareHolderDetail.name === values.buyerName
        )?.type || "",
      security: values.security,
      image: "",
      instrumentClassId: shareholderInstruments.instrumentClasses.find(
        (instrumentDetail) => instrumentDetail.name === values.security
      )?.id,
      par: shareholderInstruments.instrumentClasses.find(
        (instrumentDetail) => instrumentDetail.name === values.security
      )?.parValue,
      conversionRatio: `1: ${
        shareholderInstruments.instrumentClasses.find(
          (instrumentDetail) => instrumentDetail.name === values.security
        )?.conversionRatio
      }`,
      buyerInvestorId: shareholderInstruments.shareholders.find(
        (shareHolderDetail) => shareHolderDetail.name === values.buyerName
      )?.id,
      sellerInvestorId: shareholderInstruments.shareholders.find(
        (shareHolderDetail) => shareHolderDetail.name === values.sellerName
      )?.id,
      numberOfShares: values.numberOfShares || 0,
      amountInvested: values.amountInvested,
      date: values.date,
      transactionCurrency: values.transactionCurrency,
      exchangeRate: values.exchangeRate,
    };

    if (existingIndex !== -1) {
      secondaryTransactionStore.secondary[existingIndex] = transaction;
      secondaryTransactionStore.setSecondary([
        ...secondaryTransactionStore.secondary,
      ]);
      toast("Transaction updated successfully", {
        type: "success",
        autoClose: 2000,
      });
    } else {
      secondaryTransactionStore.setSecondary([
        ...secondaryTransactionStore.secondary,
        transaction,
      ]);
      toast("Transaction added successfully", {
        type: "success",
        autoClose: 2000,
      });
    }
    onPrimaryAction();
  };

  useEffect(() => {
    const newData = secondaryDetails.data;

    const selectedSeller = newData?.find(
      (seller: any) => seller.name === data?.sellerName
    );
    if (selectedSeller) {
      const sharesPerSecurityArray = selectedSeller.sharesPerSecurity;
      setSharesPerSecurity(sharesPerSecurityArray);

      const instrumentNamesArray = sharesPerSecurityArray.map(
        (share: TypeSharesPerSecurity) => share.instrumentName
      );
      const uniqueInstrumentNames = Array.from(new Set(instrumentNamesArray));

      const foundShare = selectedSeller.sharesPerSecurity.find(
        (share: TypeSharesPerSecurity) => share.instrumentName === data.security
      );

      const numberOfShares = foundShare?.noOfShares;
      setTotalShares(numberOfShares);
      setAvailableShares(numberOfShares! - data.numberOfShares);
      setSelectedSellerSharesPerSecurity(uniqueInstrumentNames);
    } else {
      setSelectedSellerSharesPerSecurity([]);
      setAvailableShares(0);
    }
  }, [secondaryDetails.data]);

  const currencyType = getCompanyCurrency();

  return (
    <VStack>
      <HStack className="justify-between px-6 py-4 border-b">
        <p className="text-base font-medium">{mode} Secondary Transaction</p>
        <XMarkIcon className="w-6 h-6" onClick={() => onSecondaryAction()} />
      </HStack>
      <div className="px-4 py-4 bg-white rounded-lg">
        <VStack className="justify-between">
          <Formik
            key="secondaryShareHolder"
            enableReinitialize={true}
            initialValues={initialSecondaryShareHolderValues}
            onSubmit={(values, { resetForm }) => {
              handleSubmit(values);
              resetForm();
            }}
            validationSchema={validationSecondaryShareHolderSchema}
          >
            {(formik) => (
              <Form key="shareholder">
                <VStack className="w-full gap-6 px-2 py-4">
                  <VStack className="gap-8">
                    <HStack className="grid w-full grid-cols-1 gap-8 mx-auto md:grid-cols-2">
                      <div className="flex-1 w-72">
                        <Label className="text-sm font-normal">
                          Buyer Name
                        </Label>
                        <Select
                          options={shareHolders.filter(
                            (shareHolder) =>
                              shareHolder.toLowerCase().trim() !==
                                "esop pool" &&
                              shareHolder !== formik.values.sellerName
                          )}
                          {...formik.getFieldProps("buyerName")}
                        />
                        {formik.touched?.buyerName &&
                          formik.errors?.buyerName && (
                            <Error text={formik.errors?.buyerName} />
                          )}
                      </div>
                      <div className="flex-1 w-72">
                        <Label className="text-sm font-normal">
                          Seller Name
                        </Label>
                        <Select
                          options={shareHolders.filter(
                            (shareHolder) =>
                              shareHolder !== "ESOP pool" &&
                              !secondaryTransactionStore.shareholder
                                .map((element) => element.name)
                                .includes(shareHolder) &&
                              shareHolder !== formik.values.buyerName
                          )}
                          {...formik.getFieldProps("sellerName")}
                          onChange={(selectedOption) => {
                            data = secondaryDetails.data;

                            formik.setFieldValue(
                              "sellerName",
                              selectedOption.target.value
                            );
                            formik.setFieldValue("security", "");

                            const selectedSeller = data.find(
                              (seller: any) =>
                                seller.name === selectedOption.target.value
                            );
                            if (selectedSeller) {
                              let sharesPerSecurityArray =
                                selectedSeller.sharesPerSecurity;
                              setSharesPerSecurity(sharesPerSecurityArray);

                              if (pricedRound === true) {
                                sharesPerSecurityArray =
                                  sharesPerSecurityArray.filter(
                                    (itemA: any) => {
                                      const matchingInstrumentB =
                                        pricedRoundInstrumentClasses.find(
                                          (itemB) =>
                                            itemB.id === itemA.instrumentId &&
                                            itemB.pricedRound === true
                                        );
                                      return matchingInstrumentB;
                                    }
                                  );
                              } else if (pricedRound === false) {
                                sharesPerSecurityArray =
                                  sharesPerSecurityArray.filter(
                                    (itemA: any) => {
                                      const matchingInstrumentB =
                                        pricedRoundInstrumentClasses.find(
                                          (itemB) =>
                                            itemB.id === itemA.instrumentId &&
                                            itemB.pricedRound === false
                                        );
                                      return matchingInstrumentB;
                                    }
                                  );
                              }
                              const instrumentNamesArray =
                                sharesPerSecurityArray.map(
                                  (share: TypeSharesPerSecurity) =>
                                    share.instrumentName
                                );

                              const uniqueInstrumentNames: string[] =
                                Array.from(new Set(instrumentNamesArray));

                              const foundShare = sharesPerSecurity.find(
                                (share: TypeSharesPerSecurity) =>
                                  share.instrumentName ===
                                  selectedOption.target.value
                              );

                              const numberOfShares = foundShare?.noOfShares;

                              setAvailableShares(numberOfShares);
                              setSelectedSellerSharesPerSecurity(
                                uniqueInstrumentNames
                              );
                              setAvailableShares(0);
                            } else {
                              setSelectedSellerSharesPerSecurity([]);
                              formik.setFieldValue("security", "");
                              setAvailableShares(0);
                            }
                          }}
                        />
                        {formik.touched?.sellerName &&
                          formik.errors?.sellerName && (
                            <Error text={formik.errors?.sellerName} />
                          )}
                      </div>
                    </HStack>
                    <HStack className="grid w-full grid-cols-1 gap-8 mx-auto md:grid-cols-2">
                      <div className="flex-1 w-72">
                        <Label className="text-sm font-normal">Security</Label>
                        <Select
                          options={selectedSellerSharesPerSecurity}
                          {...formik.getFieldProps("security")}
                          onChange={(selectedOption) => {
                            formik.setFieldValue(
                              "security",
                              selectedOption.target.value
                            );

                            formik.setFieldValue("numberOfShares", 0);

                            const foundShare = sharesPerSecurity.find(
                              (share: TypeSharesPerSecurity) =>
                                share.instrumentName ===
                                selectedOption.target.value
                            );
                            const numberOfShares = foundShare?.noOfShares;
                            setTotalShares(numberOfShares);
                            setAvailableShares(numberOfShares);

                            let selledShares = 0;
                            secondaryTransactionStore.secondary.forEach(
                              (secondarydata) => {
                                if (
                                  secondarydata.security ===
                                    selectedOption.target.value &&
                                  secondarydata.sellerName ===
                                    formik.values.sellerName
                                ) {
                                  selledShares += Number(
                                    secondarydata.numberOfShares
                                  );
                                  setAvailableShares(
                                    numberOfShares! - Number(selledShares)
                                  );
                                  setTotalShares(
                                    numberOfShares! - Number(selledShares)
                                  );
                                } else {
                                  setTotalShares(numberOfShares);
                                  setAvailableShares(numberOfShares);
                                }
                              }
                            );
                          }}
                        />
                        {formik.touched.security && formik.errors?.security && (
                          <Error text={formik.errors?.security} />
                        )}
                      </div>
                      <div className="flex-1 w-72">
                        <Label className="text-sm font-normal">Date</Label>
                        <Input
                          type="date"
                          placeholder="Eg:DD/MM/YYYY"
                          {...formik.getFieldProps("date")}
                        />
                        {formik.touched?.date && formik.errors?.date && (
                          <Error text={formik.errors?.date} />
                        )}
                      </div>
                    </HStack>
                    <HStack className="grid w-full grid-cols-1 gap-8 mx-auto md:grid-cols-2">
                      <div className="flex-1 w-72">
                        <Label className="text-sm font-normal">
                          Transaction Currency
                        </Label>
                        <Select
                          options={currencies}
                          {...formik.getFieldProps("transactionCurrency")}
                        />

                        {formik.touched?.transactionCurrency &&
                          formik.errors?.transactionCurrency && (
                            <Error text={formik.errors?.transactionCurrency} />
                          )}
                      </div>
                      {formik.values.transactionCurrency &&
                        companyCurrency !==
                          formik.values.transactionCurrency && (
                          <div className="flex-1 w-72">
                            <Label className="text-sm font-normal">
                              Exchange Rate
                            </Label>
                            <Input
                              placeholder="Enter Exchange Rate"
                              type="text"
                              onMouseEnter={() => {
                                setOnHoverExchangeRate(true);
                              }}
                              onMouseLeave={() => {
                                setOnHoverExchangeRate(false);
                              }}
                              onChange={(e: any) => {
                                setFormatExchangeRate(e.target.value);
                                const values = convertToNumber(e.target.value);

                                formik.setFieldValue("exchangeRate", values);
                                formik.handleChange("exchangeRate");
                              }}
                              value={numberWithCommas(
                                formatExchangeRate ??
                                  `${formik.values.exchangeRate}`,
                                (currencyTypes as any)[
                                  (formik.values.exchangeRate ?? currencyType)
                                    .toString()
                                    .split(" - ")[0] as string
                                ]
                              )}
                            />
                            {onHoverExchangeRate && (
                              <ShowNumberInWords
                                value={formik.values.exchangeRate}
                                currency={currencyType}
                              />
                            )}

                            {formik.touched.exchangeRate &&
                              formik.errors?.exchangeRate && (
                                <Error text={formik.errors?.exchangeRate} />
                              )}

                            {!formik.errors?.exchangeRate && (
                              <div className="flex justify-end text-[9px] text-gray-500 my-0.5">
                                1{" "}
                                {getKeyByValue(
                                  currencyTypes,
                                  getCurrency(
                                    formik.values.transactionCurrency ||
                                      currency
                                  )
                                )}{" "}
                                = {formik.values.exchangeRate}{" "}
                                {getKeyByValue(
                                  currencyTypes,
                                  getCurrency(data?.roundCurrency) || currency
                                )}
                              </div>
                            )}
                          </div>
                        )}
                    </HStack>
                    <HStack className="grid w-full grid-cols-1 gap-8 mx-auto md:grid-cols-2">
                      <div className="flex-1 w-72">
                        <Label className="text-sm font-normal">
                          Transaction Amount (
                          {getRoundCurrencySymbol(
                            formik.values.transactionCurrency ||
                              data?.roundCurrency
                          ) || currencySymbol}
                          )
                        </Label>
                        <Input
                          placeholder="Enter Transaction Amount"
                          type="text"
                          onMouseEnter={() => {
                            setOnHoverTransactionAmount(true);
                          }}
                          onMouseLeave={() => {
                            setOnHoverTransactionAmount(false);
                          }}
                          onChange={(e: any) => {
                            const filteredValue = filterOnlyNumbers(
                              e.target.value
                            );
                            setFormatTransctionAmount(filteredValue);
                            const values = convertToNumber(filteredValue);

                            formik.setFieldValue("amountInvested", values);
                            formik.handleChange("amountInvested");
                          }}
                          value={numberWithCommas(
                            formatTransactionAmount,
                            (currencyTypes as any)[
                              (
                                formik.values.transactionCurrency ??
                                currencyType
                              ).split(" - ")[0] as string
                            ]
                          )}
                        />
                        {onHoverTransactionAmount && (
                          <ShowNumberInWords
                            value={formik.values.amountInvested}
                            currency={currencyType}
                          />
                        )}

                        {formik.touched?.amountInvested &&
                          formik.errors?.amountInvested && (
                            <Error text={formik.errors?.amountInvested} />
                          )}
                        {formik.values.exchangeRate > 1 &&
                          formik.values.amountInvested > 0 &&
                          formik.values.transactionCurrency &&
                          companyCurrency !==
                            formik.values.transactionCurrency && (
                            <p
                              className={`${
                                availableShares! >= 0
                                  ? "flex justify-end text-gray-400 text-xs pt-2"
                                  : "flex justify-end text-red-600 text-xs pt-2"
                              }`}
                            >
                              Final Amount : {currencySymbol}
                              {(
                                formik.values.exchangeRate *
                                formik.values.amountInvested
                              ).toLocaleString(getCurrency(currency))}
                            </p>
                          )}
                      </div>
                      {/*dirty commit <div className="flex-1">
                        <Label className="text-sm font-normal">
                          Distinctive Share Number
                        </Label>
                        <Input
                          type="text"
                          className="w-72"
                          placeholder="Enter Share number"
                          {...formik.getFieldProps("distinctiveShareNumber")}
                        />
                        {formik.touched?.distinctiveShareNumber &&
                          formik.errors?.distinctiveShareNumber && (
                            <Error
                              text={formik.errors?.distinctiveShareNumber}
                            />
                          )}
                      </div> */}
                      <div className="flex-1 w-72">
                        <Label className="text-sm font-normal">
                          Number of Shares
                        </Label>
                        <Input
                          placeholder="Enter number of shares"
                          {...formik.getFieldProps("numberOfShares")}
                          type="text"
                          onMouseEnter={() => {
                            setOnHoverNumberOfShares(true);
                          }}
                          onMouseLeave={() => {
                            setOnHoverNumberOfShares(false);
                          }}
                          onChange={(e: any) => {
                            const values = parseInt(
                              e.target.value?.replaceAll(",", "") || 0,
                              10
                            );
                            formik.setFieldValue("numberOfShares", values);
                            const noOfShares =
                              parseInt(String(values), 10) || 0;
                            setAvailableShares(totalShares! - noOfShares);
                            formik.handleChange("numberOfShares");
                          }}
                          value={Intl.NumberFormat(
                            (currencyTypes as any)[
                              (
                                formik.values.transactionCurrency ?? currency
                              ).split(" - ")[0] as string
                            ]
                          ).format(formik.values.numberOfShares!)}
                        />
                        {onHoverNumberOfshares && (
                          <ShowNumberInWords
                            value={formik.values.numberOfShares}
                            width={38}
                            currency={currencyType}
                          />
                        )}

                        <p
                          className={`${
                            availableShares! >= 0
                              ? "text-gray-400 text-xs pt-2"
                              : "text-red-600 text-xs pt-2"
                          }`}
                        >
                          Available Shares : {Number(availableShares)}{" "}
                        </p>
                        {formik.touched?.numberOfShares &&
                          formik.errors?.numberOfShares && (
                            <Error text={formik.errors?.numberOfShares} />
                          )}
                      </div>
                    </HStack>
                  </VStack>

                  <HStack className="justify-between mt-10">
                    <SecondaryCTAButton
                      event-name="Cancel Secondary Modal"
                      onClick={() => onSecondaryAction()}
                      type="reset"
                      className="text-red-500"
                    >
                      Cancel
                    </SecondaryCTAButton>
                    <PrimaryCTAButton
                      event-name="Save Secondary Modal"
                      type="submit"
                      disabled={availableShares! < 0}
                    >
                      {mode}
                    </PrimaryCTAButton>
                  </HStack>
                </VStack>
              </Form>
            )}
          </Formik>
        </VStack>
        {beneficiariesList.length !== 0 && (
          <VStack className="justify-center pt-4">
            <HStack>
              <p>Share Distinctive Numbers</p>
            </HStack>
            <div className="flex flex-col">
              <div className="-mx-4 overflow-x-auto">
                <div className="inline-block min-w-full py-2 mb-4 align-middle">
                  <div className="mb-4 overflow-hidden md:rounded-lg">
                    <table className="ml-12 w-fit">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            className="py-3.5 text-center text-xs2 font-semibold text-[#B5B5C3] sm:pl-6 hover:cursor-pointer"
                          >
                            Share Numbers
                          </th>
                          <th
                            scope="col"
                            className="py-3.5 text-center text-xs2 font-semibold text-[#B5B5C3] sm:pl-6 hover:cursor-pointer"
                          >
                            Beneficiaries
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {beneficiariesList.map((beneficiary) => (
                          <tr
                            key={`${beneficiary.name}${beneficiary.value}`}
                            className="border-t border-dashed cursor-pointer hover:bg-slate-50 text-[#030303] text-xs2 font-medium"
                          >
                            <td className="whitespace-nowrap py-3.5 text-xs2 text-center">
                              <p>{beneficiary.value}</p>
                            </td>
                            <td className="whitespace-nowrap py-3.5 text-xs2 text-center">
                              <p>{beneficiary.name}</p>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </VStack>
        )}
      </div>
    </VStack>
  );
}
