import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import {
  useGetOnGoingTransaction,
  useGetOngoingNonPricedRound,
} from "../../queries/transactionRound";
import TransactionPreviewPage from "./TransactionPreviewPage";
import { useQuickRoundTransactionStore } from "../../store/useQuickTransactionStore";
import { useAuthStore } from "../../store";
import QuickAddRound from "./QuickAddRound";
import BCHeader from "../../shared/BCHeader";
import { HStack } from "../../components/utils";
import ShareModal from "../share/ShareModal";
import { useShareAuthStore } from "../../store/useShareAuthStore";
import {
  ButtonSize,
  SecondaryCTAButton,
} from "../quickRound/CTAButtonComponents";

function TransactionRoundTemplate() {
  const pathName = window.location.href.split("/");

  const { id } = useParams() as {
    id: string;
  };
  const sharedPage = useMemo(() => pathName[3] === "share", [pathName]);
  const { shareId, shareCompanyId, shareResourceId, shareResourceType } =
    useShareAuthStore();
  const [showShare, setShowShare] = useState(false);
  const roundType: string = sharedPage
    ? (shareResourceType ?? "")?.split(" ")[0].toLowerCase()
    : pathName[4] || "round";
  const { state: template, setState: setTemplate } =
    useQuickRoundTransactionStore();
  const companyId = useAuthStore.getState().companyId || "";
  const { refetch, data: onGoingTransaction } =
    useGetOnGoingTransaction(companyId);

  useEffect(() => {
    refetch();
  }, []);
  useEffect(() => {
    if (id) {
      setTemplate({
        ...template,
        stepNo: 1,
        transactionType: "round",
        companyId,
        fileType: {
          name: "",
          size: 0,
          type: "",
        },
        roundType: "ROUNDMODELING",
      });
    } else if (onGoingTransaction)
      setTemplate({
        ...onGoingTransaction,
        fileType: {
          name: "",
          size: 0,
          type: "",
        },
        roundType: "MANUAL",
      });
    else {
      setTemplate({
        ...template,
        stepNo: 1,
        transactionType: "round",
        companyId,
        fileType: {
          name: "",
          size: 0,
          type: "",
        },
        roundType: "EXCEL",
      });
    }
  }, [onGoingTransaction, id]);

  const { data: nonPricedRoundDetail } = useGetOngoingNonPricedRound();

  return (
    <div className="min-h-full">
      {
        <div>
          {!sharedPage && (
            <HStack className="justify-between w-full gap-4 pb-4 mx-4">
              {showShare && (
                <ShareModal
                  resourceId={template.eventId ?? ""}
                  resourceType={"ROUND CREATION SUMMARY"}
                  close={() => setShowShare(false)}
                />
              )}
              <BCHeader
                className="items-baseline mb-2"
                bcTitle="Transaction Dashboard"
                bcSubTitle="Add Round"
              />
              <HStack className="gap-8 pr-4">
                {roundType === "round" && template.stepNo === 2 && (
                  <SecondaryCTAButton
                    buttonSize={ButtonSize.SMALL}
                    onClick={() => {
                      setShowShare(true);
                    }}
                  >
                    Share
                  </SecondaryCTAButton>
                )}
              </HStack>
            </HStack>
          )}
          {(template?.stepNo ?? 1) !== 2 ? <QuickAddRound /> : null}
          {(template?.stepNo ?? 1) === 2 ? <TransactionPreviewPage /> : null}
        </div>
      }
    </div>
  );
}

export default TransactionRoundTemplate;
