import create from "zustand";

type SelectedSecurityTypeStore = {
  securityType: string;
  setSecurityType: (index: string) => void;
  reset: () => void;
};

const initialState = {
  securityType: "All",
};

const selectedSecurityTypeStoreStore = create<SelectedSecurityTypeStore>(
  (set) => ({
    ...initialState,
    setSecurityType: (value) => {
      set((state) => ({ securityType: value }));
    },
    reset: () => {
      set(() => initialState);
    },
  })
);

export const useSelectedSecurityTypeStoreStore = selectedSecurityTypeStoreStore;
