export enum RoundHeaderType {
  RoundDetailHeader,
  SecondaryDetailHeader,
  RoundBonusHeader,
  RoundBuyBackHeader,
  RoundNameHeader,
  RoundPostMoneyHeader,
  RoundSharePriceHeader,
  RoundPriceShareHeader,
  RoundDilutionHeader,
  RoundDilutionHeaderExercise,
  RoundSecurityNameHeader,
  RoundOnlyDilutionNameHeader,
  RoundBuyerSellerHeader,
  RoundNewBonusShareHeader,
  RoundNewFDBShareHeader,
  RoundEsopShareHeader,
  RoundSplitShareHeader,
  ConversionHeader,
  ConversionSecurity,
  ConversionShares,
  ConversionRatio,
  ConversionDestSecurity,
  RedemptionHeader,
  RedemptionnShares,
  ConversionSrcSecurity,
}

export const FDBSecurityType = [
  "EQUITY",
  "CCPS",
  "CCD",
  "NOTE",
  "WARRANT",
  "OCPS",
  "OPTIONS",
];

export const NonFDBSecurity = ["OCD", "RPS", "NCD", "OCRPS", "Forfeited"];
