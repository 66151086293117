import _ from "lodash";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useState } from "react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import {
  HStack,
  Error,
  VStack,
  decodeCaptableAuthToken,
} from "../components/utils";
import {
  ButtonSize,
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../pages/quickRound/CTAButtonComponents";
import { Input, Label } from "../components/shared/InputField";
import { useAuthStore } from "../store";
import { useChangePassword } from "../queries/userProfile";

export function ChangePassword({
  onSecondaryAction,
}: {
  onSecondaryAction: Function;
}) {
  const { accessToken } = useAuthStore.getState();
  const decodedToken = decodeCaptableAuthToken(accessToken || "");
  const { mutate: changePassword } = useChangePassword();
  const ppsValuationSchema = Yup.object().shape({
    currentPassword: Yup.string().required().label("Current Password"),
    newPassword: Yup.string().required().label("New Password"),
    confirmPassword: Yup.string().required().label("Confirm Password"),
  });
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const initialValues = {
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const handleSubmit = (values: any) => {
    changePassword(
      {
        emailId: decodedToken.emailId,
        passwordNew: values.newPassword,
        currentPassword: values.currentPassword,
        passwordConfirm: values.confirmPassword,
      },
      {
        onSuccess: () => {
          toast("Password Changed Successfully", {
            type: "success",
            autoClose: 2000,
          });
          onSecondaryAction();
        },
        onError: (err: any) => {
          toast(err.response.data.errorMessage, {
            type: "error",
            autoClose: 2000,
          });
        },
      }
    );
  };

  return (
    <div className="w-full p-4">
      <HStack className="justify-between border-b">
        <p className="font-semibold text-lg mb-3">Change Password</p>
        <XMarkIcon
          onClick={() => onSecondaryAction()}
          className="h-6 w-6 cursor-pointer"
        />
      </HStack>
      <Formik
        enableReinitialize={true}
        validationSchema={ppsValuationSchema}
        initialValues={initialValues}
        onSubmit={(values) => handleSubmit(values)}
      >
        {(formik) => (
          <Form>
            <VStack className="gap-8 px-4 pt-4">
              <HStack className="sm:justify-between justify-start items-start sm:gap-4 gap-1 sm:items-center sm:flex-row flex-col">
                <Label htmlFor="password">Current Password</Label>
                <VStack>
                  <Input
                    id="currentPassword"
                    placeholder="Enter current password"
                    type={`${showConfirmPassword ? "text" : "password"}`}
                    autoComplete="current-password"
                    {...formik.getFieldProps(`currentPassword`)}
                  />

                  {formik.touched?.currentPassword &&
                    formik.errors?.currentPassword && (
                      <Error text={formik.errors?.currentPassword} />
                    )}
                </VStack>
              </HStack>
              <HStack className="sm:justify-between justify-start items-start sm:gap-4 gap-1 sm:items-center sm:flex-row flex-col">
                <Label htmlFor="password">New Password</Label>
                <VStack>
                  <Input
                    id="newPassword"
                    placeholder="Enter new password"
                    type={`${showConfirmPassword ? "text" : "password"}`}
                    autoComplete="current-password"
                    {...formik.getFieldProps(`newPassword`)}
                  />

                  {formik.touched?.newPassword &&
                    formik.errors?.newPassword && (
                      <Error text={formik.errors?.newPassword} />
                    )}
                </VStack>
              </HStack>
              <HStack className="sm:justify-between justify-start items-start sm:gap-4 gap-1 sm:items-center sm:flex-row flex-col">
                <Label htmlFor="password">Confirm Password</Label>
                <VStack>
                  <Input
                    id="confirmPassword"
                    placeholder="Re-enter new password"
                    type={`${showConfirmPassword ? "text" : "password"}`}
                    autoComplete="current-password"
                    {...formik.getFieldProps(`confirmPassword`)}
                  />

                  {formik.touched?.confirmPassword &&
                    formik.errors?.confirmPassword && (
                      <Error text={formik.errors?.confirmPassword} />
                    )}
                </VStack>
              </HStack>
              <div className="relative flex items-start">
                <div className="flex h-6 items-center">
                  <input
                    id="comments"
                    aria-describedby="comments-description"
                    name="comments"
                    type="checkbox"
                    onClick={() => {
                      setShowConfirmPassword(!showConfirmPassword);
                    }}
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  />
                </div>
                <div className="ml-3 text-sm leading-6">
                  <label
                    htmlFor="comments"
                    className="font-medium text-gray-900"
                  >
                    Show Password
                  </label>
                </div>
              </div>
              <HStack className="justify-between mt-10">
                <SecondaryCTAButton
                  event-name="Back Change Password"
                  buttonSize={ButtonSize.SMALL}
                  onClick={() => {
                    onSecondaryAction();
                  }}
                >
                  Back
                </SecondaryCTAButton>
                <PrimaryCTAButton
                  event-name="Save Change Password"
                  buttonSize={ButtonSize.SMALL}
                  type="submit"
                >
                  Save
                </PrimaryCTAButton>
              </HStack>
            </VStack>
          </Form>
        )}
      </Formik>
    </div>
  );
}
