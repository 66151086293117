import { useState } from "react";
import { Icon } from "@iconify/react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  exportIndividualRoundSummaryReport,
  exportIndividualSecuritySummaryReport,
  exportPAS4Report,
} from "../../api/Report";
import {
  HStack,
  VStack,
  useGetCompanyName,
  Error,
} from "../../components/utils";
import { downloadExcel } from "../../utils/DownloadFile";
import { PopupHeader } from "../../components/Headings";
import { Label } from "../../components/shared/InputField";
import {
  ButtonSize,
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../quickRound/CTAButtonComponents";
import SearchAutoCompleteDropDown from "../../components/shared/SearchAutoCompleteDropDown";
import { SwitchDropDownModel } from "../rounds/RoundsAllTransactions";
import { useInternalSwitchStateStore } from "../../store/searchDropDown";
import { Select } from "../../components/shared/Select";

type ExportSheetDetailsProps = {
  onPrimaryAction: () => void;
  onSecondaryAction: () => void;
  reportName: string;
  companyId?: string;
};

type ExportTransactionTypeSubmit = {
  id: string;
};

const ExportTransactionTypeReport = ({
  onPrimaryAction = () => {},
  onSecondaryAction = () => {},
  reportName,
}: ExportSheetDetailsProps) => {
  const transactionSwitchStore = useInternalSwitchStateStore();
  const [selectedTransaction, setSelectedTransaction] =
    useState<SwitchDropDownModel>({ name: "", id: "", type: "" });
  const companyName = useGetCompanyName();
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (values: ExportTransactionTypeSubmit) => {
    setLoading(true);
    if (reportName === "Export Specific Round Summary Report") {
      const name =
        transactionSwitchStore.transaction.find(
          (transaction) => transaction.id === values.id
        )?.name ?? "";
      exportIndividualRoundSummaryReport(values.id).then((res: any) => {
        const fileName = `${name} Round Summary Report_${companyName}`;
        downloadExcel(res, fileName);
      });
    } else if (reportName === "Export PAS4 Report") {
      const name =
        transactionSwitchStore.transaction.find(
          (transaction) => transaction.id === values.id
        )?.name ?? "";
      exportPAS4Report(values.id, name).then((res: any) => {
        const fileName = `Pas4_Report_${name}`;
        downloadExcel(res, fileName);
      });
    }

    setLoading(false);
    onPrimaryAction();
  };

  const initialValues = {
    id: "",
  };

  const excelSheetDetailsValidation = Yup.object({
    id: Yup.string().required().label("Transaction Name"),
  });

  return (
    <div>
      <PopupHeader
        text={reportName}
        subText={"Please select round for which the report has to be generated"}
        onClick={() => onSecondaryAction()}
      />
      <VStack className="w-full px-8 py-7 gap-9">
        <Formik
          initialValues={initialValues}
          key="exportSheetDetails"
          onSubmit={(values) => {
            handleSubmit(values);
          }}
          validationSchema={excelSheetDetailsValidation}
        >
          {(formik) => (
            <div>
              <Form key="exportSheetDetails">
                <VStack className="w-full gap-4">
                  <VStack>
                    <Label>Round Name</Label>
                    <Select
                      placeholder="Select the transaction name"
                      className="w-full"
                      options={transactionSwitchStore.transaction}
                      valueGetter={(o) => o.id}
                      textGetter={(o) => o.name}
                      {...formik.getFieldProps("id")}
                    />

                    {formik.touched.id && formik.errors.id && (
                      <Error text={formik.errors.id} />
                    )}
                  </VStack>
                </VStack>
                <HStack className="justify-between mt-10 h-8">
                  <SecondaryCTAButton
                    event-name="Cancel Transaction Type Report"
                    buttonSize={ButtonSize.SMALL}
                    onClick={() => onSecondaryAction()}
                  >
                    Cancel
                  </SecondaryCTAButton>
                  <PrimaryCTAButton
                    event-name="Submit Transaction Type Report"
                    buttonSize={ButtonSize.SMALL}
                    type="submit"
                  >
                    {!loading && <span>Submit</span>}

                    {loading && (
                      <HStack>
                        <p
                          className="indicator-progress"
                          style={{ display: "block" }}
                        >
                          Please wait...
                        </p>
                        <Icon
                          className="animate-spin"
                          icon="lucide:loader-2"
                          width={18}
                        />
                      </HStack>
                    )}
                  </PrimaryCTAButton>
                </HStack>
              </Form>
            </div>
          )}
        </Formik>
      </VStack>
    </div>
  );
};

export default ExportTransactionTypeReport;
