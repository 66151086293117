/* eslint-disable max-len */
import { useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import { Icon } from "@iconify/react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { HStack, VStack, Error } from "../../components/utils";
import {
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../quickRound/CTAButtonComponents";
import { Input, Label } from "../../components/shared/InputField";
import { useAuthStore } from "../../store";
import { Select } from "../../components/shared/Select";
import { shareholderTypeLabelValue } from "../../constants/ShareholderConstants";
import {
  getCurrencySymbol,
  getCurrencyType,
} from "../../utils/currencyFormatter";
import {
  InvestorDetails,
  InvestorState,
  ModelBuilder,
  OngoingInvestorDetails,
} from "../../types/Modeling";
import { useRoundModelStore } from "../../store/roundModelingStore";
import { FormatNumberSpan } from "../../utils/currencyRoboto";
import {
  CreateNewInvestorModel,
  ShareholderDropDown,
} from "./RoundModelingModel";
import SuggestionBox from "./InputSuggestion";

const CreateNewnvestorModal = ({
  data,
  actionMode = "New",
  onBack,
  onNext,
}: {
  data?: InvestorDetails;
  actionMode?: string;
  onBack: () => void;
  onNext: (data: ModelBuilder) => void;
}) => {
  const [mode, setMode] = useState(actionMode);
  const roundCreationState = useRoundModelStore();
  const companyId = useAuthStore().companyId || "";
  const [loading, setLoading] = useState(false);
  const [holding, setHolding] = useState<string>(
    data?.postHoldingPercentage?.toString() || ""
  );
  const [isEdit, setIsEdit] = useState(false);
  const [amount, setAmount] = useState<string>(
    data?.amountToInvest.toString() || ""
  );
  const [isAmtLocked, setIsAmtLocked] = useState(false);
  const currencySymbol = getCurrencySymbol();
  const handleSubmit = async (values: CreateNewInvestorModel) => {
    setLoading(true);
    setLoading(false);
    const ongoingNewInvestorDetails: OngoingInvestorDetails = {
      investorId: values.shareholderId,
      investorName: values.shareholderName,
      amountToInvest: values.investmentAmount || 0,
      postHoldingPercentage: values.postHolding || 0,
      investorType: values.shareholderType,
      unLockedInputFeild: {
        hasToCalculateAmt: isAmtLocked,
        value: isAmtLocked ? Number(holding) : Number(amount),
      },
      hasBlendedSecondaries:
        roundCreationState.roundCreation.newInvestorDetails
          ?.ongoingExistingInvestorDetails?.hasBlendedSecondaries ?? false,
      blendedSecondaries:
        roundCreationState.roundCreation.newInvestorDetails
          ?.ongoingExistingInvestorDetails?.blendedSecondaries,
      optedInvestorRightType: "",
      state: InvestorState.ToBeSaved,
    };
    onNext({
      ...roundCreationState.roundCreation,
      companyId,
      newInvestorDetails: {
        investorDetails:
          roundCreationState.roundCreation.newInvestorDetails
            ?.investorDetails || [],
        ongoingExistingInvestorDetails: ongoingNewInvestorDetails,
      },
    });
    onBack();
  };

  const updatingFormikValues = () => {
    const socketData =
      roundCreationState.roundCreation.newInvestorDetails
        ?.ongoingExistingInvestorDetails;
    formik.setFieldValue(
      "postHolding",
      roundCreationState.roundCreation.newInvestorDetails
        ?.ongoingExistingInvestorDetails?.postHoldingPercentage
    );
    formik.setFieldValue(
      "investmentAmount",
      roundCreationState.roundCreation.newInvestorDetails
        ?.ongoingExistingInvestorDetails?.amountToInvest
    );
    formik.setFieldValue(
      "shareholderType",
      roundCreationState.roundCreation.newInvestorDetails
        ?.ongoingExistingInvestorDetails?.investorType
    );
    formik.setFieldValue(
      "targetPercent",
      socketData?.blendedSecondaries?.targetHoldingPercentage
    );
    formik.setFieldValue(
      "secondaryAmount",
      socketData?.blendedSecondaries?.secondaryInvestmentAmount
    );
  };
  useEffect(() => {
    updatingFormikValues();
  }, [
    roundCreationState.roundCreation.newInvestorDetails
      ?.ongoingExistingInvestorDetails?.amountToInvest,
  ]);

  const investorInitialValues = {
    shareholderId: data?.investorId || "",
    shareholderName: data?.investorName || "",
    shareholderType: data?.investorType || "",
    postHolding: data?.postHoldingPercentage,
    investmentAmount: data?.amountToInvest,
    targetPercent:
      data?.blendedSecondaries?.targetHoldingPercentage ??
      data?.postHoldingPercentage ??
      0,
    secondaryAmount: data?.blendedSecondaries?.secondaryInvestmentAmount,
    secondaryPercent:
      data?.blendedSecondaries?.secondaryInvestmentHoldingPercentage,
    state: InvestorState.Update,
  };

  const [selectedShareholder, setSelectedShareholder] =
    useState<ShareholderDropDown>({
      name: data?.investorName || "",
      type: data?.investorType || "",
      id: data?.investorId || "",
    });

  const shareholderDetail = useMemo(
    () =>
      roundCreationState.roundCreation.newInvestorDetails?.investorDetails.find(
        (sh: InvestorDetails) => sh.id === selectedShareholder.id
      ),
    [roundCreationState.roundCreation?.newInvestorDetails, selectedShareholder]
  );
  const [blended, setBlended] = useState(
    shareholderDetail?.hasBlendedSecondaries ?? false
  );

  const investorValidationSchema = Yup.object({
    shareholderId: Yup.string().label("Shareholder Id"),
    shareholderName: Yup.string().required().label("Shareholder Name"),
    postHolding: Yup.number()
      .required()
      .moreThan(0)
      .max(100)
      .label("Post Holding"),
    investmentAmount: Yup.number()
      .required()
      .moreThan(0)
      .label("Investment Amount"),
    shareholderType: Yup.string().required().label("Shareholder Type"),
    state: Yup.string().label("State"),
    targetPercent: blended
      ? Yup.number().required().moreThan(0).label("Share Price")
      : Yup.number().moreThan(0).label("Share Price"),
    secondaryAmount: blended
      ? Yup.number().required().moreThan(0).label("Secondary Amount")
      : Yup.number().moreThan(0).label("Secondary Amount"),
  });

  const formik = useFormik({
    initialValues: investorInitialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      handleSubmit(values);
    },
    validationSchema: investorValidationSchema,
  });

  const presettingStateForEdit = () => {
    setBlended(true);
    setIsEdit(true);
    if (data) {
      setAmount(`${data.blendedSecondaries?.finalInvestmentAmount}` ?? 0);
      setIsAmtLocked(data.unLockedInputFeild.hasToCalculateAmt);
    }
  };
  const [targetPercentage, setTargetPercentage] = useState(
    data?.blendedSecondaries?.targetHoldingPercentage ?? 0
  );

  function buildInvestorDetailsForEdit() {
    if (data)
      return {
        investorId: data?.investorId ?? "",
        amountToInvest: data.blendedSecondaries?.finalInvestmentAmount ?? 0,
        postHoldingPercentage: data.postHoldingPercentage ?? 0,
        investorName: data.investorName ?? "",
        unLockedInputFeild: {
          hasToCalculateAmt: data.unLockedInputFeild.hasToCalculateAmt ?? false,
          value: data.unLockedInputFeild.value ?? 0,
        },
        investorType: data?.investorType ?? "",
        hasBlendedSecondaries: data.hasBlendedSecondaries ?? true,
        blendedSecondaries: data.blendedSecondaries,
        state: InvestorState.Update,
        optedInvestorRightType: data.optedInvestorRightType ?? "",
      };

    return undefined;
  }

  const buildInvestorDetails = () => {
    const values = formik.values;
    return {
      investorId: values.shareholderId,
      investorName: values.shareholderName,
      amountToInvest: values.investmentAmount ?? 0,
      postHoldingPercentage: values.postHolding ?? 0,
      hasBlendedSecondaries: isEdit
        ? data?.hasBlendedSecondaries ?? false
        : roundCreationState.roundCreation.existingInvestorDetails
            ?.ongoingExistingInvestorDetails?.hasBlendedSecondaries ?? false,
      blendedSecondaries: isEdit
        ? data?.blendedSecondaries
        : roundCreationState.roundCreation.existingInvestorDetails
            ?.ongoingExistingInvestorDetails?.blendedSecondaries,
      investorType: values.shareholderType,
      unLockedInputFeild: {
        hasToCalculateAmt: isAmtLocked,
        value: isAmtLocked ? Number(holding) || 0 : Number(amount) || 0,
      },
      optedInvestorRightType: "None",
      state: InvestorState.Update,
    };
  };

  const showErrorMessage = () => {
    const data = roundCreationState.roundCreation;
    if (data.errorMessage) {
      toast(data.errorMessage, {
        type: "error",
        autoClose: 2000,
      });
    }
    roundCreationState.setRoundCreation({
      ...data,
      errorMessage: "",
    });
  };

  useEffect(() => {
    if (data?.hasBlendedSecondaries && !isEdit && actionMode === "Edit") {
      presettingStateForEdit();
      const investorDetails = buildInvestorDetailsForEdit();
      hitSocketApi(investorDetails);
    } else {
      setIsEdit(true);
      const investorDetails: OngoingInvestorDetails = buildInvestorDetails();
      hitSocketApi(investorDetails);
    }
  }, [
    formik.values.postHolding,
    formik.values.investmentAmount,
    formik.values.shareholderType,
  ]);

  useEffect(() => {
    showErrorMessage();
  }, []);

  const updateLocalStates = () => {
    setIsAmtLocked((prev) => !prev);
    setAmount(
      (
        roundCreationState.roundCreation.newInvestorDetails
          ?.ongoingExistingInvestorDetails?.amountToInvest || 0
      ).toFixed(2)
    );
    setHolding(
      (
        roundCreationState.roundCreation.newInvestorDetails
          ?.ongoingExistingInvestorDetails?.postHoldingPercentage || 0
      ).toFixed(2)
    );
  };

  const hitSocketApi = (
    ongoingExistingInvestorDetails?: OngoingInvestorDetails
  ) => {
    const data: ModelBuilder = {
      ...roundCreationState.roundCreation,
      newInvestorDetails: {
        investorDetails:
          roundCreationState.roundCreation.newInvestorDetails
            ?.investorDetails ?? [],
        ongoingExistingInvestorDetails,
      },
    };
    onNext(data);
  };
  useEffect(() => {
    formik.setFieldValue(
      "targetPercent",
      roundCreationState.roundCreation.newInvestorDetails
        ?.ongoingExistingInvestorDetails?.blendedSecondaries
        ?.targetHoldingPercentage
    );
  }, [
    roundCreationState.roundCreation.newInvestorDetails
      ?.ongoingExistingInvestorDetails?.blendedSecondaries
      ?.targetHoldingPercentage,
  ]);

  useEffect(() => {
    const ongoing =
      roundCreationState.roundCreation.newInvestorDetails
        ?.ongoingExistingInvestorDetails;
    hitSocketApi({
      ...ongoing,
      investorId: ongoing?.investorId ?? "",
      amountToInvest: ongoing?.amountToInvest ?? 0,
      postHoldingPercentage: ongoing?.postHoldingPercentage ?? 0,
      investorName: ongoing?.investorName ?? "",
      unLockedInputFeild: {
        hasToCalculateAmt:
          ongoing?.unLockedInputFeild.hasToCalculateAmt ?? false,
        value: ongoing?.unLockedInputFeild.value ?? 0,
      },
      investorType: ongoing?.investorType ?? "",
      hasBlendedSecondaries: true,
      blendedSecondaries: {
        primaryInvestmentAmount:
          ongoing?.blendedSecondaries?.primaryInvestmentAmount ?? 0,
        discountPercentage:
          ongoing?.blendedSecondaries?.discountPercentage ?? 0,
        finalInvestmentAmount:
          ongoing?.blendedSecondaries?.finalInvestmentAmount ?? 0,
        finalInvestmentHoldingPercentage:
          ongoing?.blendedSecondaries?.finalInvestmentHoldingPercentage ?? 0,
        primaryInvestmentHoldingPercentage:
          ongoing?.blendedSecondaries?.primaryInvestmentHoldingPercentage ?? 0,
        secondaryInvestmentAmount:
          ongoing?.blendedSecondaries?.secondaryInvestmentAmount ?? 0,
        secondaryInvestmentHoldingPercentage:
          ongoing?.blendedSecondaries?.secondaryInvestmentHoldingPercentage ??
          0,
        sharePrice: ongoing?.blendedSecondaries?.sharePrice ?? 0,
        targetHoldingPercentage: Number(targetPercentage) ?? 0,
      },
      state:
        roundCreationState.roundCreation.newInvestorDetails
          ?.ongoingExistingInvestorDetails?.state ?? InvestorState.Update,
      optedInvestorRightType:
        roundCreationState.roundCreation.newInvestorDetails
          ?.ongoingExistingInvestorDetails?.optedInvestorRightType ?? "",
    });
  }, [targetPercentage]);
  const currencyType = getCurrencyType();
  useEffect(() => {
    if (
      data?.hasBlendedSecondaries ||
      roundCreationState.roundCreation.newInvestorDetails
        ?.ongoingExistingInvestorDetails?.hasBlendedSecondaries
    ) {
      const ongoing =
        roundCreationState.roundCreation.newInvestorDetails
          ?.ongoingExistingInvestorDetails;
      hitSocketApi({
        ...ongoing,
        investorId: ongoing?.investorId ?? "",
        amountToInvest: ongoing?.amountToInvest ?? 0,
        postHoldingPercentage: ongoing?.postHoldingPercentage ?? 0,
        investorName: ongoing?.investorName ?? "",
        unLockedInputFeild: {
          hasToCalculateAmt:
            ongoing?.unLockedInputFeild.hasToCalculateAmt ?? false,
          value: ongoing?.unLockedInputFeild.value ?? 0,
        },
        investorType: ongoing?.investorType ?? "",
        hasBlendedSecondaries: true,
        blendedSecondaries: {
          primaryInvestmentAmount:
            ongoing?.blendedSecondaries?.primaryInvestmentAmount ?? 0,
          discountPercentage:
            ongoing?.blendedSecondaries?.discountPercentage ?? 0,
          finalInvestmentAmount:
            ongoing?.blendedSecondaries?.finalInvestmentAmount ?? 0,
          finalInvestmentHoldingPercentage:
            ongoing?.blendedSecondaries?.finalInvestmentHoldingPercentage ?? 0,
          primaryInvestmentHoldingPercentage:
            ongoing?.blendedSecondaries?.primaryInvestmentHoldingPercentage ??
            0,
          secondaryInvestmentAmount:
            ongoing?.blendedSecondaries?.secondaryInvestmentAmount ?? 0,
          secondaryInvestmentHoldingPercentage:
            ongoing?.blendedSecondaries?.secondaryInvestmentHoldingPercentage ??
            0,
          sharePrice: ongoing?.blendedSecondaries?.sharePrice ?? 0,
          targetHoldingPercentage: Number(targetPercentage) ?? 0,
        },
        state:
          roundCreationState.roundCreation.newInvestorDetails
            ?.ongoingExistingInvestorDetails?.state ?? InvestorState.Update,
        optedInvestorRightType:
          roundCreationState.roundCreation.newInvestorDetails
            ?.ongoingExistingInvestorDetails?.optedInvestorRightType ?? "",
      });
    }
  }, [targetPercentage]);
  return (
    <div className="w-full">
      <Box className="px-4 text-lg font-medium border-b pt-4 pb-2">
        <h6 className="flex justify-between">
          <VStack>
            <p className="font-medium text-lg">
              {actionMode.toLowerCase() === "edit"
                ? "Edit New Shareholders"
                : "Add New Shareholders"}
            </p>
          </VStack>
          <XMarkIcon
            className="h-6 w-6 cursor-pointer"
            onClick={() => {
              onBack();
            }}
          />
        </h6>
      </Box>
      <VStack className="w-full py-7 gap-9">
        <VStack className="w-full px-10">
          <HStack className="gap-4">
            <VStack className="flex-1 mb-12">
              <Label className="font-medium text-sm whitespace-nowrap mr-4">
                Shareholder Name
              </Label>
              <Input
                type="text"
                placeholder="Enter Name"
                className="w-72"
                value={formik.values.shareholderName}
                disabled={mode.toLowerCase() === "edit"}
                onChange={(e) => {
                  formik.setFieldValue("shareholderName", e.target.value);
                }}
              />
              {formik.touched.shareholderName &&
                formik.errors.shareholderName && (
                  <Error text={formik.errors.shareholderName} />
                )}
            </VStack>
            <VStack className="flex-1 mb-12">
              <Label className="font-medium text-sm whitespace-nowrap mr-4">
                Shareholder Type
              </Label>
              <Select
                options={shareholderTypeLabelValue}
                valueGetter={(o) => o.label}
                textGetter={(o) => o.value}
                placeholder="Select Shareholder Type"
                className="w-72"
                value={formik.values.shareholderType}
                onChange={(e) => {
                  formik.setFieldValue("shareholderType", e.target.value);
                }}
              />
              {formik.touched.shareholderType &&
                formik.errors.shareholderType && (
                  <Error text={formik.errors.shareholderType} />
                )}
            </VStack>
          </HStack>
          <HStack className="gap-4">
            <VStack className="flex-1">
              <HStack>
                <Label className="font-medium text-sm whitespace-nowrap mr-4">
                  Investment Amount {` (${currencySymbol})`}
                </Label>
                {!isAmtLocked ? (
                  <Icon
                    onClick={updateLocalStates}
                    icon="material-symbols:lock-open-outline-rounded"
                    width={20}
                    className="text-[#E75935]"
                    height={20}
                  />
                ) : (
                  <Icon
                    onClick={updateLocalStates}
                    icon="ic:outline-lock"
                    width={20}
                    className="text-gray-400"
                    height={20}
                  />
                )}
              </HStack>

              <Input
                type="number"
                placeholder={isAmtLocked ? "" : "Enter Amount"}
                readOnly={isAmtLocked}
                className="w-72"
                value={
                  isAmtLocked
                    ? roundCreationState.roundCreation.newInvestorDetails
                        ?.ongoingExistingInvestorDetails?.amountToInvest
                    : amount
                }
                onChange={(e) => {
                  formik.setFieldValue("investmentAmount", e.target.value);
                  setAmount(e.target.value);
                }}
              />
              {formik.touched.investmentAmount &&
                formik.errors.investmentAmount && (
                  <Error text={formik.errors.investmentAmount} />
                )}
              {!isAmtLocked && (
                <p className="text-xxs text-[#A6A6A6] font-medium pt-1">
                  ~calculated based on entered amount and share price:
                  {roundCreationState.roundCreation.newInvestorDetails?.ongoingExistingInvestorDetails?.amountToInvest?.toFixed(
                    2
                  )}
                </p>
              )}
            </VStack>
            <VStack className="flex-1">
              <HStack>
                <Label className="font-medium text-sm whitespace-nowrap mr-4">
                  Holding %
                </Label>
                {isAmtLocked ? (
                  <Icon
                    onClick={updateLocalStates}
                    icon="material-symbols:lock-open-outline-rounded"
                    width={20}
                    className="text-[#E75935]"
                    height={20}
                  />
                ) : (
                  <Icon
                    onClick={updateLocalStates}
                    icon="ic:outline-lock"
                    width={20}
                    className="text-gray-400"
                    height={20}
                  />
                )}
              </HStack>

              <Input
                type="number"
                placeholder={!isAmtLocked ? "" : "Enter Holding %"}
                readOnly={!isAmtLocked}
                className="w-72"
                value={
                  !isAmtLocked
                    ? roundCreationState.roundCreation.newInvestorDetails
                        ?.ongoingExistingInvestorDetails?.postHoldingPercentage
                    : holding
                }
                onChange={(e) => {
                  formik.setFieldValue("postHolding", Number(e.target.value));
                  setHolding(e.target.value);
                }}
              />
              {!roundCreationState.roundCreation.newInvestorDetails
                ?.ongoingExistingInvestorDetails?.hasBlendedSecondaries ? (
                <p className="text-xs text-[#A6A6A6] font-medium pt-1">
                  to enable blended secondary,
                  <span
                    role="button"
                    className="text-blue-600 underline"
                    onClick={() => {
                      setBlended(true);
                      const data =
                        roundCreationState.roundCreation.newInvestorDetails
                          ?.ongoingExistingInvestorDetails
                          ?.postHoldingPercentage ?? 0;
                      const dataNum = data;
                      setTargetPercentage(dataNum);
                      hitSocketApi({
                        ...roundCreationState.roundCreation.newInvestorDetails
                          ?.ongoingExistingInvestorDetails,
                        investorId:
                          roundCreationState.roundCreation.newInvestorDetails
                            ?.ongoingExistingInvestorDetails?.investorId ?? "",
                        amountToInvest:
                          roundCreationState.roundCreation.newInvestorDetails
                            ?.ongoingExistingInvestorDetails?.amountToInvest ??
                          0,
                        postHoldingPercentage:
                          roundCreationState.roundCreation.newInvestorDetails
                            ?.ongoingExistingInvestorDetails
                            ?.postHoldingPercentage ?? 0,
                        investorName:
                          roundCreationState.roundCreation.newInvestorDetails
                            ?.ongoingExistingInvestorDetails?.investorName ??
                          "",
                        unLockedInputFeild: {
                          hasToCalculateAmt:
                            roundCreationState.roundCreation.newInvestorDetails
                              ?.ongoingExistingInvestorDetails
                              ?.unLockedInputFeild.hasToCalculateAmt ?? false,
                          value:
                            roundCreationState.roundCreation.newInvestorDetails
                              ?.ongoingExistingInvestorDetails
                              ?.unLockedInputFeild.value ?? 0,
                        },
                        investorType:
                          roundCreationState.roundCreation.newInvestorDetails
                            ?.ongoingExistingInvestorDetails?.investorType ??
                          "",
                        hasBlendedSecondaries: true,
                        blendedSecondaries:
                          roundCreationState.roundCreation.newInvestorDetails
                            ?.ongoingExistingInvestorDetails
                            ?.blendedSecondaries,
                        state:
                          roundCreationState.roundCreation.newInvestorDetails
                            ?.ongoingExistingInvestorDetails?.state ??
                          InvestorState.Update,
                        optedInvestorRightType:
                          roundCreationState.roundCreation.newInvestorDetails
                            ?.ongoingExistingInvestorDetails
                            ?.optedInvestorRightType ?? "",
                      });
                    }}
                  >
                    click here
                  </span>
                </p>
              ) : (
                <p className="text-xs text-[#A6A6A6] font-medium pt-1">
                  to discard blended secondary,
                  <span
                    role="button"
                    className="text-blue-600 underline"
                    onClick={() => {
                      setBlended(false);
                      hitSocketApi({
                        ...roundCreationState.roundCreation.newInvestorDetails
                          ?.ongoingExistingInvestorDetails,
                        investorId:
                          roundCreationState.roundCreation.newInvestorDetails
                            ?.ongoingExistingInvestorDetails?.investorId ?? "",
                        amountToInvest:
                          roundCreationState.roundCreation.newInvestorDetails
                            ?.ongoingExistingInvestorDetails?.amountToInvest ??
                          0,
                        postHoldingPercentage:
                          roundCreationState.roundCreation.newInvestorDetails
                            ?.ongoingExistingInvestorDetails
                            ?.postHoldingPercentage ?? 0,
                        investorName:
                          roundCreationState.roundCreation.newInvestorDetails
                            ?.ongoingExistingInvestorDetails?.investorName ??
                          "",
                        unLockedInputFeild: {
                          hasToCalculateAmt:
                            roundCreationState.roundCreation.newInvestorDetails
                              ?.ongoingExistingInvestorDetails
                              ?.unLockedInputFeild.hasToCalculateAmt ?? false,
                          value:
                            roundCreationState.roundCreation.newInvestorDetails
                              ?.ongoingExistingInvestorDetails
                              ?.unLockedInputFeild.value ?? 0,
                        },
                        investorType:
                          roundCreationState.roundCreation.newInvestorDetails
                            ?.ongoingExistingInvestorDetails?.investorType ??
                          "",
                        hasBlendedSecondaries: false,
                        blendedSecondaries:
                          roundCreationState.roundCreation.newInvestorDetails
                            ?.ongoingExistingInvestorDetails
                            ?.blendedSecondaries,
                        state:
                          roundCreationState.roundCreation.newInvestorDetails
                            ?.ongoingExistingInvestorDetails?.state ??
                          InvestorState.Update,
                        optedInvestorRightType:
                          roundCreationState.roundCreation.newInvestorDetails
                            ?.ongoingExistingInvestorDetails
                            ?.optedInvestorRightType ?? "",
                      });
                    }}
                  >
                    click here
                  </span>
                </p>
              )}
              {isAmtLocked && (
                <p className="text-xxs text-[#A6A6A6] font-medium pt-1">
                  ~final holding considering existing holding and round size :
                  {roundCreationState.roundCreation.newInvestorDetails?.ongoingExistingInvestorDetails?.postHoldingPercentage?.toFixed(
                    2
                  )}
                </p>
              )}
              {formik.touched.postHolding && formik.errors.postHolding && (
                <Error text={formik.errors.postHolding} />
              )}
            </VStack>
          </HStack>
        </VStack>
        {blended && (
          <div className="border border-borderColor mx-4 shadow-lg rounded-lg py-2">
            <VStack>
              <HStack>
                <p>Blended Secondary</p>
                <div></div>
              </HStack>
              <HStack className="justify-between">
                <VStack>
                  <Label className="font-medium text-sm whitespace-nowrap mt-3 mr-4">
                    Target Percentage
                  </Label>
                  <Input
                    type="number"
                    placeholder={"Enter Target Percent"}
                    className="w-72"
                    value={Number(targetPercentage.toFixed(4)) ?? 0}
                    onChange={(e) => {
                      formik.setFieldValue(
                        "targetPercent",
                        e.target.value ?? 0
                      );
                      setTargetPercentage(Number(e.target.value));
                    }}
                  />

                  {formik.touched.investmentAmount &&
                    formik.errors.investmentAmount && (
                      <Error text={formik.errors.investmentAmount} />
                    )}
                </VStack>
                <VStack>
                  <Label className="font-medium text-sm whitespace-nowrap mt-3 mr-4">
                    Secondary Share Price
                  </Label>
                  <HStack className="font-medium text-base whitespace-nowrap mt-3 mr-4 items-center">
                    <p>{currencySymbol}</p>
                    <FormatNumberSpan
                      value={
                        roundCreationState.roundCreation.newInvestorDetails
                          ?.ongoingExistingInvestorDetails?.blendedSecondaries
                          ?.sharePrice ?? 0
                      }
                      format={currencyType}
                    />
                  </HStack>
                  <HStack className="font-medium text-xs whitespace-nowrap mt-3 mr-4 items-center">
                    <p className="text-descriptionColor text-xs mr-1">{`Discount on the FDB Share price is `}</p>
                    <FormatNumberSpan
                      value={
                        roundCreationState.roundCreation.newInvestorDetails
                          ?.ongoingExistingInvestorDetails?.blendedSecondaries
                          ?.discountPercentage ?? 0
                      }
                      format={currencyType}
                    />
                    <p className="ml-0.5">%</p>
                  </HStack>
                </VStack>
              </HStack>
              <HStack className="w-full justify-between">
                <VStack>
                  <VStack>
                    <Label className="font-medium text-sm whitespace-nowrap mt-3 mr-4">
                      Secondary Investment
                    </Label>
                    <Input
                      type="number"
                      placeholder={"Enter Secondary Investment"}
                      className="w-72"
                      value={formik.values.secondaryAmount}
                      onChange={(e) => {
                        formik.setFieldValue(
                          "secondaryAmount",
                          e.target.value ?? 0
                        );
                        const ongoing =
                          roundCreationState.roundCreation.newInvestorDetails
                            ?.ongoingExistingInvestorDetails;
                        hitSocketApi({
                          ...ongoing,
                          investorId: ongoing?.investorId ?? "",
                          amountToInvest: ongoing?.amountToInvest ?? 0,
                          postHoldingPercentage:
                            ongoing?.postHoldingPercentage ?? 0,
                          investorName: ongoing?.investorName ?? "",
                          unLockedInputFeild: {
                            hasToCalculateAmt:
                              ongoing?.unLockedInputFeild.hasToCalculateAmt ??
                              false,
                            value: ongoing?.unLockedInputFeild.value ?? 0,
                          },
                          investorType: ongoing?.investorType ?? "",
                          hasBlendedSecondaries: true,
                          blendedSecondaries: {
                            primaryInvestmentAmount:
                              ongoing?.blendedSecondaries
                                ?.primaryInvestmentAmount ?? 0,
                            discountPercentage:
                              ongoing?.blendedSecondaries?.discountPercentage ??
                              0,
                            finalInvestmentAmount:
                              ongoing?.blendedSecondaries
                                ?.finalInvestmentAmount ?? 0,
                            finalInvestmentHoldingPercentage:
                              ongoing?.blendedSecondaries
                                ?.finalInvestmentHoldingPercentage ?? 0,
                            primaryInvestmentHoldingPercentage:
                              ongoing?.blendedSecondaries
                                ?.primaryInvestmentHoldingPercentage ?? 0,
                            secondaryInvestmentAmount:
                              Number(e.target.value ?? 0) ?? 0,
                            secondaryInvestmentHoldingPercentage:
                              ongoing?.blendedSecondaries
                                ?.secondaryInvestmentHoldingPercentage ?? 0,
                            sharePrice:
                              ongoing?.blendedSecondaries?.sharePrice ?? 0,
                            targetHoldingPercentage:
                              ongoing?.blendedSecondaries
                                ?.targetHoldingPercentage ?? 0,
                          },
                          state:
                            roundCreationState.roundCreation.newInvestorDetails
                              ?.ongoingExistingInvestorDetails?.state ??
                            InvestorState.Update,
                          optedInvestorRightType:
                            roundCreationState.roundCreation.newInvestorDetails
                              ?.ongoingExistingInvestorDetails
                              ?.optedInvestorRightType ?? "",
                        });
                      }}
                    />
                    <SuggestionBox
                      heading={`Amount should not exceed final investment`}
                    />
                  </VStack>
                  <HStack className="font-medium text-base whitespace-nowrap mt-3 mr-4 items-center">
                    <FormatNumberSpan
                      value={
                        roundCreationState.roundCreation.newInvestorDetails
                          ?.ongoingExistingInvestorDetails?.blendedSecondaries
                          ?.secondaryInvestmentHoldingPercentage ?? 0
                      }
                      format={currencyType}
                    />
                    <p className="ml-0.5">%</p>
                  </HStack>
                </VStack>

                <VStack>
                  <Label className="font-medium text-sm whitespace-nowrap mt-3 mr-4">
                    Primary Investment
                  </Label>
                  <HStack className="font-medium text-base whitespace-nowrap mt-4 mr-4 h-10 items-center">
                    <div className="mr-0.5">{`${currencySymbol} `}</div>
                    <FormatNumberSpan
                      value={
                        roundCreationState.roundCreation.newInvestorDetails
                          ?.ongoingExistingInvestorDetails?.blendedSecondaries
                          ?.primaryInvestmentAmount ?? 0
                      }
                      format={currencyType}
                    />
                  </HStack>

                  <HStack className="font-medium text-base whitespace-nowrap mt-3 mr-4 items-center">
                    <FormatNumberSpan
                      value={
                        roundCreationState.roundCreation.newInvestorDetails
                          ?.ongoingExistingInvestorDetails?.blendedSecondaries
                          ?.primaryInvestmentHoldingPercentage ?? 0
                      }
                      format={currencyType}
                    />
                    <p className="ml-0.5">%</p>
                  </HStack>
                </VStack>
                <VStack>
                  <Label className="font-medium text-sm whitespace-nowrap mt-3 mr-4">
                    Final Investment
                  </Label>
                  <HStack className="font-medium text-base whitespace-nowrap mt-4 mr-4 h-10 items-center">
                    <div className="mr-0.5">{`${currencySymbol} `}</div>
                    <FormatNumberSpan
                      value={
                        roundCreationState.roundCreation.newInvestorDetails
                          ?.ongoingExistingInvestorDetails?.blendedSecondaries
                          ?.finalInvestmentAmount ?? 0
                      }
                      format={currencyType}
                    />
                  </HStack>
                  <HStack className="font-medium text-base whitespace-nowrap mt-3 mr-4 items-center">
                    <FormatNumberSpan
                      value={
                        roundCreationState.roundCreation.newInvestorDetails
                          ?.ongoingExistingInvestorDetails?.blendedSecondaries
                          ?.finalInvestmentHoldingPercentage ?? 0
                      }
                      format={currencyType}
                    />
                    <p className="ml-0.5">%</p>
                  </HStack>
                </VStack>
              </HStack>
            </VStack>
          </div>
        )}
        {blended && (
          <div className="mx-4 -mt-8">
            <HStack className="px-4">
              <SuggestionBox
                heading={`~ The money raise will be updated with respect to the primary investment amount once saved`}
              />
            </HStack>
          </div>
        )}
        <HStack className="justify-between mt-4 px-10">
          <SecondaryCTAButton
            event-name="Cancel Add New Investor Modal"
            onClick={() => {
              onBack();
            }}
          >
            Cancel
          </SecondaryCTAButton>
          <PrimaryCTAButton
            event-name="Save Add New Investor Modal"
            className="p-1"
            type="submit"
            onClick={() => {
              formik.handleSubmit();
            }}
          >
            {!loading && <span>Save</span>}

            {loading && (
              <HStack>
                <p className="indicator-progress" style={{ display: "block" }}>
                  Please wait...
                </p>
                <Icon
                  className="animate-spin"
                  icon="lucide:loader-2"
                  width={18}
                />
              </HStack>
            )}
          </PrimaryCTAButton>
        </HStack>
      </VStack>
    </div>
  );
};

export default CreateNewnvestorModal;
