import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { HStack, VStack } from "../../components/utils";
import { Label } from "../../components/shared/InputField";
import { getCurrencyType } from "../../utils/currencyFormatter";
import CardDetailHeader from "./CardDetailHeader";
import { useRMCreationStore } from "../../store/roundCreationStore";
import { formatDisplayDate } from "../../utils/date";
import { ShareHolderCaptableChartModel } from "../newQuickTransaction/CapTableBarChart";
import {
  ButtonSize,
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../quickRound/CTAButtonComponents";
import { queryClient } from "../../queries";
import AGGridRoundTransactionTable from "../rounds/RoundsTransactionAgTable";
import AGGridRoundsSummaryTable from "./ShareholderSummaryRoundTable";
import { useShareholderInstrumentStore } from "../../store/shareholderInstrumentStore";
import { useGetInstrumentAndShareHolder } from "../../queries/quickRound";
import { useAuthStore } from "../../store";

export default function RoundCreationSummary() {
  const {
    roundCreation: data,
    reset: disCardRoundCreation,
    sendMessage: createRound,
    setRoundCreation: setRoundState,
  } = useRMCreationStore();
  const [summary, setSummary] = useState(data.tab7);
  useEffect(() => {
    setSummary(data.tab7);
  }, [data.tab7]);
  const currency = getCurrencyType();
  const [chartData, setChartData] = useState<ShareHolderCaptableChartModel[]>(
    []
  );
  function scrollToTop() {
    const scroller = document.querySelector("#outlet");
    scroller?.scrollTo(0, 0);
  }

  const extractChartData = () => {
    const shareholderChartData: ShareHolderCaptableChartModel[] = [];
    data.tab7?.summaryCaptable?.forEach((shareholder) => {
      shareholderChartData.push({
        name: shareholder.name,
        preHoldingFdbPercentage: shareholder.preHoldingFdbPercentage,
        postHoldingFdbPercentage: shareholder.postHoldingFdbPercentage,
      });
    });
    setChartData(shareholderChartData);
  };

  useEffect(() => {
    extractChartData();
  }, [data.tab7?.summaryCaptable]);

  const companyId = useAuthStore().companyId || "";

  const { isPlaceholderData, data: instrumentShareholderInfoData } =
    useGetInstrumentAndShareHolder(companyId);
  const shareholderAndInstrumentstore = useShareholderInstrumentStore();

  useEffect(() => {
    if (!isPlaceholderData) {
      shareholderAndInstrumentstore.setInstrumentClass(
        instrumentShareholderInfoData?.instrumentClasses || []
      );
      shareholderAndInstrumentstore.setShareholders(
        instrumentShareholderInfoData?.shareholders || []
      );
      shareholderAndInstrumentstore.setInstrumentClassSubTypes(
        instrumentShareholderInfoData?.instrumentClassSubTypes || {}
      );
    }
  }, [instrumentShareholderInfoData, isPlaceholderData]);
  const navigate = useNavigate();
  useEffect(() => {
    if (
      !data.isLoader &&
      data.notifications?.includes("Successfully Converted To Round") &&
      (data.notifications || []).length === 1
    ) {
      queryClient.invalidateQueries("getInstrumentAndShareHolder");
      queryClient.refetchQueries("getInstrumentAndShareHolder");

      queryClient.invalidateQueries("getAllEvents");
      queryClient.refetchQueries("getAllEvents");

      queryClient.invalidateQueries("getSecurityOverviewDetails");
      queryClient.refetchQueries("getSecurityOverviewDetails");

      queryClient.invalidateQueries("getShareholderOverview");
      queryClient.refetchQueries("getShareholderOverview");

      queryClient.invalidateQueries("getAllTransactions");
      queryClient.refetchQueries("getAllTransactions");
      setRoundState({
        ...data,
        notifications: [],
      });
      toast("Converted to Round Successfully!", {
        type: "success",
        autoClose: 2000,
      });
      disCardRoundCreation(data);
      navigate("/landing/dashboard");
    }
  }, [data]);

  return (
    <VStack className=" bg-white rounded-lg px-8 py-4 mt-2 border border-borderColor">
      <Label className="text-xl font-semibold text-gray-dark">Summary</Label>

      <p className="font-medium text-gray-light mb-4">
        See the final summary to confirm
      </p>
      <VStack className="border border-borderColor rounded-md">
        <HStack className="justify-evenly mb-8 mt-8">
          {[
            {
              name: "Round Name",
              value: summary?.roundDetailSummary.roundName || "-",
            },
            {
              name: "Round Date",
              value:
                formatDisplayDate(summary?.roundDetailSummary.roundDate) || "-",
            },
            {
              name: "Price Round",
              value: summary?.roundDetailSummary.isPriceRound ? "Yes" : "No",
            },
            {
              name: "Amount Raised",
              value: summary?.roundDetailSummary.amountRaised || 0,
              currency,
            },
          ].map((element) => (
            <CardDetailHeader
              key={element.name}
              name={element.name}
              value={element.value}
              currency={element.currency}
              className=""
            />
          ))}
        </HStack>
        <HStack className="justify-evenly mb-8">
          {[
            {
              name: "Valuation (Pre)",
              value: summary?.roundDetailSummary.preValuation || "-",
              currency,
            },
            {
              name: "Valuation (Post)",
              value: summary?.roundDetailSummary.postValuation || "-",
              currency,
            },
            {
              name: "PPS",
              value: summary?.roundDetailSummary.pps || "-",
              currency,
            },
            {
              name: "Dilution (%)",
              value: summary?.roundDetailSummary.dilution || "-",
            },
          ].map((element) => (
            <CardDetailHeader
              key={element.name}
              name={element.name}
              value={element.value}
              currency={element.currency}
            />
          ))}
        </HStack>
        <HStack className="justify-evenly mb-8">
          {[
            {
              name: "Round Security",
              value: `${summary?.roundDetailSummary.roundSecurity}` || "-",
            },
            {
              name: "No of Nominal Equity Shares",
              value: summary?.roundDetailSummary.nominalEquityShare || 0,
              currency: undefined,
            },
          ].map((element) => (
            <CardDetailHeader
              key={element.name}
              name={element.name}
              value={element.value}
              currency={element.currency}
            />
          ))}
        </HStack>
      </VStack>
      <div className="pt-8">
        <AGGridRoundTransactionTable
          isPreviewOnly={true}
          isModeling={true}
          secondaryTransactionProps={data.tab7?.secondaryTransaction || []}
          primaryTransactionProps={data.tab7?.primaryTransaction || []}
        />
      </div>

      {data.tab7 && (
        <AGGridRoundsSummaryTable
          capTableData={data.tab7?.summaryCaptable || []}
        />
      )}
      <HStack className="justify-between mt-8">
        <HStack className="gap-4">
          <SecondaryCTAButton
            type="reset"
            event-name="Back from Modeling Summary"
            buttonSize={ButtonSize.SMALL}
            className="text-red-500 shadow-box"
            onClick={() => {
              createRound({ ...data, currentTab: 6 });
              scrollToTop();
            }}
          >
            Back
          </SecondaryCTAButton>
          <SecondaryCTAButton
            event-name="Discard from Modeling Summary"
            buttonSize={ButtonSize.SMALL}
            onClick={() => {
              disCardRoundCreation(data);
            }}
          >
            Discard
          </SecondaryCTAButton>
        </HStack>

        <HStack>
          <PrimaryCTAButton
            event-name="Convert to round"
            buttonSize={ButtonSize.SMALL}
            onClick={() => {
              setRoundState({
                ...data,
                isLoader: true,
              });
              createRound({ ...data, isConvertRound: true });
              queryClient.invalidateQueries("getInstrumentAndShareHolder");
              queryClient.refetchQueries("getInstrumentAndShareHolder");
            }}
            loading={data.isLoader}
            className="min-w-max ml-2"
            type="submit"
          >
            Convert to Round
          </PrimaryCTAButton>
        </HStack>
      </HStack>
    </VStack>
  );
}
