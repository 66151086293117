import { Icon } from "@iconify/react";
import { HStack, VStack } from "../../components/utils";
import { downloadS3File } from "../../utils/DownloadFile";
import { formatDisplayDate } from "../../utils/date";

export interface NameValuePair {
  name: string;
  value: string;
  type: string;
}

export default function RightsCard({
  rightsCardDetail,
  handleModifyAction,
}: {
  rightsCardDetail: NameValuePair[];
  handleModifyAction: (action: string, data: any) => void;
}) {
  return (
    <div className="pb-8 mx-auto max-w-7xl">
      <dl className="grid grid-cols-1 gap-8 mt-5 sm:grid-cols-2 lg:grid-cols-4">
        {rightsCardDetail.map((cardDetail) => (
          <div
            key={cardDetail.name}
            className="relative px-4 py-2 overflow-hidden bg-white rounded-lg shadow-box"
          >
            <dt>
              <p className=" truncate text-sm font-medium text-[#464E5F]">
                {cardDetail.name}
              </p>
            </dt>
            <HStack className="justify-center items-center py-2 text-lg text-center font-medium text-[#464E5F]">
              {cardDetail.type === "text" ? (
                <p className=" py-4">{cardDetail.value}</p>
              ) : cardDetail.type === "date" ? (
                <p className=" py-4">{formatDisplayDate(cardDetail.value)}</p>
              ) : (
                <HStack className="items-center border  border-gray-201 px-4 py-2 w-80 rounded-md">
                  <div
                    onClick={() => {
                      handleModifyAction("view", cardDetail.value);
                    }}
                    className=" cursor-pointer"
                  >
                    <img
                      src="https://upload.wikimedia.org/wikipedia/commons/8/87/PDF_file_icon.svg"
                      alt="pdf_icon"
                      width={24}
                      height={24}
                    />
                  </div>

                  <div
                    onClick={() => {
                      handleModifyAction("view", cardDetail.value);
                    }}
                    className="text-sm flex-1 text-center font-medium text-[#464E5F] cursor-pointer"
                  >
                    {"AOA Document"}
                  </div>
                  <div className="text-sm text-center font-medium text-[#464E5F]">
                    <Icon
                      onClick={() => downloadS3File(cardDetail.value)}
                      icon="lucide:download"
                      className="items-center m-auto bottom-2 cursor-pointer"
                      height={18}
                      width={18}
                    />
                  </div>
                </HStack>
              )}
            </HStack>
          </div>
        ))}
      </dl>
    </div>
  );
}
