import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import BCHeader from "../../shared/BCHeader";
import { Description, Label } from "../../components/shared/InputField";
import { BoxContainer, HStack, VStack } from "../../components/utils";
import {
  ButtonSize,
  PrimaryCTAButton,
} from "../quickRound/CTAButtonComponents";
import { Select } from "../../components/shared/Select";
import { RoundCreationModel } from "./RoundCreationModel";
import { useAuthStore } from "../../store";
import { useRMCreationStore } from "../../store/roundCreationStore";
import { useGetAllEvents } from "../../queries/captable";
import { Headings } from "../../components/Headings";

export default function RoundCreation() {
  const companyId = useAuthStore.getState().companyId || "";
  const roundCreationStore = useRMCreationStore();
  const { data: allEventsDetail } = useGetAllEvents(companyId);
  const [captableId, setCaptableId] = useState("");

  useEffect(() => {
    if (allEventsDetail) {
      const id =
        allEventsDetail[(allEventsDetail?.length ?? 0) - 1].newCapTableId ?? "";
      setCaptableId(id);
    }
  }, [allEventsDetail]);

  return (
    <BoxContainer className="mt-2">
      <VStack className="justify-between">
        <VStack className="mb-2 items-start">
          <Headings
            text={"Round Creation"}
            subText="Round creation is a tool to help the investors and management of the
            company view the dilution of the company’s cap table."
          />
        </VStack>
        <VStack className="w-64 hidden">
          <Label>Select a Cap Table to model on top of</Label>
          <Select
            className="w-64 border-gray-800 rounded-lg"
            value={captableId}
            valueGetter={(o) => o.id}
            textGetter={(o) => o.name}
            options={
              allEventsDetail?.map((ele) => ({
                name: ele.roundSummary.name,
                id: ele.id,
              })) ?? []
            }
            onChange={(e) => {
              setCaptableId(e.target.value);
            }}
          />
        </VStack>
        <VStack className="items-start pt-8">
          <PrimaryCTAButton
            className="leading"
            buttonSize={ButtonSize.MEDIUM}
            loading={roundCreationStore.roundCreation.isLoader}
            onClick={() => {
              roundCreationStore.setRoundCreation({
                ...roundCreationStore.roundCreation,
                isLoader: true,
              });
              roundCreationStore.sendMessage({
                ...roundCreationStore.roundCreation,
                baseCapTableId: captableId,
                companyId,
              });
            }}
          >
            Create a round
          </PrimaryCTAButton>

          <Description
            className="text-left"
            text="* Creating a round will automatically create a new captable model"
          />
        </VStack>
      </VStack>
    </BoxContainer>
  );
}
