import { Dialog } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import AlertDialog from "../../components/shared/AlertDialog";
import { HStack, VStack, Box } from "../../components/utils";
import {
  useDeleteOnGoingTransaction,
  useDiscardQuickRound,
} from "../../queries/transactionRound";
import { useAuthStore } from "../../store";
import { useShareholderInstrumentStore } from "../../store/shareholderInstrumentStore";
import { extractBonusTransaction } from "./ExtractionUtility";
import { useNewShareholderInstrumentStore } from "../../store/newShareholderAndInstrumentStore";

import { useQuickBonusTransactionStore } from "../../store/useQuickTransactionStore";
import { PrimaryCTAButton } from "../quickRound/CTAButtonComponents";

import AddBonus from "./AddBonus";
import AddBonusPopup from "./AddBonusPopup";
import AgGridQuickBonusTable from "./AddBonusAgtable";

export default function AddTransactionBonus() {
  const companyId = useAuthStore.getState().companyId || "";
  const {
    state: template,
    setState: setTemplate,
    reset: resetTemplate,
  } = useQuickBonusTransactionStore();

  const bonusTransactionStore = useNewShareholderInstrumentStore();
  const shareholderInstruments = useShareholderInstrumentStore();

  const { mutate: deleteOnGoingTransaction } =
    useDeleteOnGoingTransaction(companyId);

  const pathName = window.location.href.split("/");
  const roundType = pathName[4];

  const [dialog, setDialog] = useState<{
    open: boolean;
    message?: string;
  }>({
    open: false,
  });

  useEffect(() => {
    if (template.quickBonusTransactions) {
      const bonusSecurityDetails = extractBonusTransaction(
        template.quickBonusTransactions,
        shareholderInstruments.instrumentClasses,
        shareholderInstruments.shareholders
      );
      bonusTransactionStore.setBonus(bonusSecurityDetails || []);
      setTemplate((prev: any) => ({ ...prev, stepNo: 1 }));
    }
  }, []);

  const navigate = useNavigate();
  const { refetch: discardQuickRound } = useDiscardQuickRound();

  const handleDiscard = () => {
    setDialog({ open: false });
    discardQuickRound();
    resetTemplate();
    deleteOnGoingTransaction(companyId);
    navigate("/transactions/allTransactions");
    bonusTransactionStore.setBonus([]);
  };

  const [addDialog, setAddDialog] = useState<{
    open: boolean;
    name?: string;
    data?: any;
  }>({
    open: false,
  });

  return (
    <VStack>
      <Box className="bg-white rounded-lg px-8 ">
        <HStack className="pt-10 pb-4">
          <Dialog open={addDialog.open} maxWidth="md">
            {addDialog.open && addDialog.name === "bonus" && (
              <AddBonusPopup
                onPrimaryAction={() =>
                  setAddDialog({ open: false, name: "bonus" })
                }
                onSecondaryAction={() =>
                  setAddDialog({ open: false, name: "bonus" })
                }
                data={addDialog.data}
                mode={"Add"}
              />
            )}
          </Dialog>
          <HStack className="w-full gap-4">
            <p className={"text-lg"}>Bonus Security details</p>
          </HStack>

          <HStack className="gap-4">
            <PrimaryCTAButton
              event-name="Add Bonus Button"
              className="text-xs2 h-8 whitespace-nowrap"
              onClick={() => {
                setAddDialog({ open: true, name: "bonus" });
              }}
            >
              Add Bonus
            </PrimaryCTAButton>
          </HStack>
        </HStack>
        <Dialog open={dialog.open} maxWidth="md">
          <AlertDialog
            onClose={() => setDialog({ open: false })}
            open={dialog.open}
            message={dialog.message}
            onPrimaryAction={handleDiscard}
            onSecondaryAction={() => setDialog({ open: false })}
          />
        </Dialog>

        <AgGridQuickBonusTable />
      </Box>
    </VStack>
  );
}
