import { QueryFunctionContext } from "react-query";
import { IndividualRoundsModel } from "../types/AllTransactionModel";
import sharedResourceApi from "./sharedResourceApi";
import { ShareholderInstrumentModel } from "../types/quickRound";
import { PreviewQuickRoundResponseModel } from "../types/previewQuickRound";
import { DashBoardModel } from "../types/DashboardModel";

export async function getSharedRoundsTransactions(
  context: QueryFunctionContext
): Promise<IndividualRoundsModel> {
  const eventId = context.queryKey[1];
  const companyId = context.queryKey[2];
  return sharedResourceApi
    .get("v1/company/sharedResource/transaction/round", {
      params: { eventId, companyId },
    })
    .then((res) => res.data.data);
}

export async function getSharedInstrumentAndShareHolder(
  context: QueryFunctionContext
): Promise<ShareholderInstrumentModel> {
  const companyId = context.queryKey[1];
  const resourceId = context.queryKey[2];
  const id = context.queryKey[3];
  return sharedResourceApi
    .get("v1/company/sharedResource/shareholdersAndInstruments", {
      params: { companyId, resourceId, id },
    })
    .then((res) => res.data.data);
}

export async function getSharedInstrumentAndShareHolders(
  context: QueryFunctionContext
): Promise<ShareholderInstrumentModel> {
  const companyId = context.queryKey[1];
  const resourceId = context.queryKey[2];
  const id = context.queryKey[3];
  return sharedResourceApi
    .get("v1/company/sharedResource/shareShareholdersAndInstruments", {
      params: { companyId, resourceId, id },
    })
    .then((res) => res.data.data);
}

export async function getSharedQuickRoundPreview(
  context: QueryFunctionContext
): Promise<{ message: string; data: PreviewQuickRoundResponseModel }> {
  const eventId = context.queryKey[1];
  return sharedResourceApi
    .get(`v1/company/sharedResource/proformaEventSummary/?eventId=${eventId}`)
    .then((res) => res.data);
}

export async function getSharedEvents(): Promise<DashBoardModel[]> {
  return sharedResourceApi
    .get("/v1/company/sharedResource/dashboard/events")
    .then((res) => res.data.data);
}

export async function getSharedCompanyDetail(): Promise<{
  message: string;
  data: {
    cinNumber: string;
    name: string;
  };
}> {
  return sharedResourceApi
    .get(`v1/company/sharedResource/company`)
    .then((res) => res.data);
}

export async function getSharedNonPricedRoundModel(
  context: QueryFunctionContext
): Promise<any> {
  const id = context.queryKey[1];

  return sharedResourceApi
    .get(`/v1/company/sharedResource/modeling/nonPricedRound`, {
      params: { modelId: id },
    })
    .then((res) => res.data.data);
}

export async function getSharedPricedRoundModel(
  context: QueryFunctionContext
): Promise<any> {
  const id = context.queryKey[1];

  return sharedResourceApi
    .get(`/v1/company/sharedResource/modeling/pricedRoundSummary`, {
      params: { modelId: id },
    })
    .then((res) => res.data.data);
}
