import { useNavigate } from "react-router";
import { redirectToHissa } from "../utils";

export default function RedirectPage() {
  const navigate = useNavigate();
  return (
    <>
      <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
        <div className="text-center">
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            Something went wrong!
          </h1>
          <p className="mt-6 text-base leading-7 text-gray-600">
            Sorry, we couldn’t find the company you’re looking for.
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <a
              href="#"
              onClick={() => {
                redirectToHissa("landing/onboarding/historical-round-summary");
              }}
              className="rounded-md bg-orange-501 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-orange-501 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-501"
            >
              Go to onboarding
            </a>
            <a
              href="#"
              event-name="Refresh"
              onClick={() => {
                navigate("/landing/dashboard");
                window.location.reload();
              }}
              className="rounded-md bg-orange-501 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-orange-501 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-501"
            >
              Refresh
            </a>
          </div>
        </div>
      </main>
    </>
  );
}
