import hissaApi from "./hissaApi";
import api from "./capTableApi";
import { ModelBuilder } from "../types/Modeling";

export async function getAllTransactionBasedReport(
  fromDate: string,
  toDate: string
): Promise<any> {
  return api
    .get(
      `v1/company/report/transactions?startDate=${fromDate}&endDate=${toDate}`
    )
    .then((res) => res.data.data);
}

export async function getAllotmentWindowReport(
  companyId: string,
  fromDate: string,
  toDate: string
): Promise<any> {
  return api
    .get(
      `v1/company/report/newAllotment?companyId=${companyId}&startDate=${fromDate}&endDate=${toDate}`
    )
    .then((res) => res.data.data);
}

export async function exportSecurityBasedReport(date: string): Promise<string> {
  return api
    .get(`v1/company/report/security?date=${date}`)
    .then((res) => res.data.data);
}

export async function exportIndividualSecuritySummaryReport(
  securityType: string
): Promise<string> {
  return api
    .get(
      `v1/company/report/individualSecuritySummary?securityType=${securityType}`
    )
    .then((res) => res.data.data);
}

export async function exportRoundReport(date: string): Promise<string> {
  return api
    .get(`v1/company/report/round?date=${date}`)
    .then((res) => res.data.data);
}

export async function exportRoundBasedReport(eventId: string): Promise<string> {
  return api
    .get(`v1/company/report/roundBased?eventId=${eventId}`)
    .then((res) => res.data.data);
}

export async function exportCaptableSummaryReport(
  tillDate: string
): Promise<string> {
  return api
    .get(`v1/company/report/captableSummary?date=${tillDate}`)
    .then((res) => res.data.data);
}

export async function exportRoundSummaryReport(
  eventId: string
): Promise<string> {
  return api
    .get(`v1/company/report/roundSummary?eventId=${eventId}`)
    .then((res) => res.data.data);
}

export async function exportModelSummaryReport(
  modelId: string
): Promise<string> {
  return api
    .get(
      `/v1/company/report/nonPricedRoundModel?nonPricedRoundModelId=${modelId}`
    )
    .then((res) => res.data.data);
}

export async function exportIndividualRoundSummaryReport(
  eventId: string
): Promise<string> {
  return api
    .get(`v1/company/report/individualRoundSummary?eventId=${eventId}`)
    .then((res) => res.data.data);
}

export async function exportPAS4Report(
  eventId: string,
  roundName: string
): Promise<string> {
  return api
    .get(`v1/company/report/pas4?roundName=${roundName}`)
    .then((res) => res.data.data);
}

export async function exportSharecertificateReport(): Promise<{
  base64?: string;
  signedUrl?: string;
  isCustomShareCertificate: boolean;
}> {
  return api
    .get(`v1/company/report/sharecertificate`)
    .then((res) => res.data.data);
}

export async function exportShareholderReport(): Promise<string> {
  return api.get(`v1/company/report/shareholder`).then((res) => res.data.data);
}

export async function exportSecuritySummaryReport(): Promise<string> {
  return api
    .get(`v1/company/report/securitySummary`)
    .then((res) => res.data.data);
}

export async function exportSecondarySummaryReport(): Promise<string> {
  return api
    .get(`v1/company/report/secondarySummary`)
    .then((res) => res.data.data);
}

export async function exportSSA(eventId: string): Promise<string> {
  return api
    .get(`v1/company/report/ssa?eventId=${eventId}`)
    .then((res) => res.data.data);
}

export async function exportSHA(eventId: string): Promise<string> {
  return api
    .get(`v1/company/report/sha?eventId=${eventId}`)
    .then((res) => res.data.data);
}

export async function exportHorizontalShaSsa(eventId: string): Promise<string> {
  return api
    .get(`v1/company/report/horizontalShaSsa?eventId=${eventId}`)
    .then((res) => res.data.data);
}

export async function exportNonPricedRound(eventId: string): Promise<string> {
  return api
    .get(`v1/company/report/nonPricedRound?eventId=${eventId}`)
    .then((res) => res.data.data);
}

export async function exportAOCReport(): Promise<string> {
  return api.get(`v1/company/report/aoc`).then((res) => res.data.data);
}
export async function exportMGT7Report(): Promise<string> {
  return api.get(`v1/company/report/mgt7`).then((res) => res.data.data);
}

export async function exportFla(): Promise<string> {
  return api.get(`v1/company/report/fla`).then((res) => res.data.data);
}

export async function exportPricedRoundModelReport(
  model: ModelBuilder
): Promise<string> {
  return api
    .post(`v1/company/report/roundModel`, { model })
    .then((res) => res.data.data);
}
