import React from "react";
import AGGridLiquidityRatiosTable from "./LiquidityRatiosAGTable";
import AGGridLeverageRatiosTable from "./LeverageRatiosAGTable";
import AGGridProfitabilityRatiosTable from "./ProfitabilityRatiosAGTable";
import { VStack } from "../../../../components/utils";

export default function RatiosAGTable({
  data,
  timelineType,
}: {
  data: any;
  timelineType: string;
}) {
  return (
    <VStack>
      <div>
        <p className="font-semibold text-sm pt-4 px-6">Liquidity Ratios</p>
        <AGGridLiquidityRatiosTable data={data} timelineType={timelineType} />
      </div>
      <div>
        <p className="font-semibold text-sm pt-4 px-6">Leverage Ratios</p>
        <AGGridLeverageRatiosTable data={data} timelineType={timelineType} />
      </div>
      <div>
        <p className="font-semibold text-sm pt-4 px-6">Profitability Ratios</p>
        <AGGridProfitabilityRatiosTable
          data={data}
          timelineType={timelineType}
        />
      </div>
    </VStack>
  );
}
