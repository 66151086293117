import { useState } from "react";
import { NavigateFunction } from "react-router";
import randomColor from "randomcolor";
import Tooltip from "../components/shared/Tooltip";
import { HStack } from "../components/utils";
import StatusLabelColors from "../constants/StatusLabelColors";
import { RoundDetailModel } from "../types/GenericCaptableModel";

function RoundIdentifiers({
  roundNames,
  itemsToDisplay = 2,
  navigate,
  stage,
}: {
  roundNames: RoundDetailModel[];
  itemsToDisplay?: number;
  navigate: (url: string) => void;
  stage?: string;
}) {
  const totalItems = roundNames.length;
  const [expanded, setExpanded] = useState(false);

  const listToDisplay = roundNames.slice(0, itemsToDisplay);

  const remaining = totalItems - itemsToDisplay;

  return (
    <>
      {expanded ? (
        <HStack className="gap-2" onClick={() => setExpanded(false)}>
          {roundNames.map((round, index) => {
            const seed = round.roundIdentifier;
            const backgroundColor1 = randomColor({
              luminosity: "dark",
              format: "rgba",
              alpha: 0.2,
              seed,
            });
            const color1 = randomColor({
              luminosity: "dark",
              format: "rgba",
              alpha: 1,
              seed,
            });
            return (
              <Tooltip
                key={round.roundId}
                text={round.roundName}
                _className={`text-black whitespace-nowrap`}
              >
                <div
                  className="h-fit capitalize rounded-sm font-medium text-center text-[11px] leading-4 px-2 mt-4"
                  navigation-name="Round Tag"
                  onClick={() =>
                    round.roundType.toLowerCase() !== "conversion" &&
                    round.roundType.toLowerCase() !== "redemption" &&
                    round.roundType.toLowerCase() !== "update_class" &&
                    round.roundType.toLowerCase() !== "esoppool" &&
                    stage !== "Three"
                      ? navigate(
                          `/transactions/${round.roundType.toLowerCase()}/${
                            round.roundId
                          }`
                        )
                      : null
                  }
                  style={{
                    color: color1,
                    backgroundColor: backgroundColor1,
                  }}
                >
                  {round.roundIdentifier.length > 3 ? (
                    <p className="items-center py-1.5 cursor-pointer">
                      {round.roundIdentifier.slice(0, 2)}
                    </p>
                  ) : (
                    <p className="items-center py-1.5 cursor-pointer">
                      {round.roundIdentifier}
                    </p>
                  )}
                </div>
              </Tooltip>
            );
          })}
        </HStack>
      ) : (
        <>
          {listToDisplay.map((round, _listIndex) => {
            const seed = round.roundIdentifier;
            const backgroundColor1 = randomColor({
              luminosity: "dark",
              format: "rgba",
              alpha: 0.2,
              seed,
            });
            const color1 = randomColor({
              luminosity: "dark",
              format: "rgba",
              alpha: 1,
              seed,
            });
            return (
              <Tooltip
                _className={`whitespace-nowrap`}
                key={round.roundId}
                text={round.roundName}
              >
                <div
                  className="h-fit capitalize rounded-sm font-medium text-center text-[11px] leading-4 px-2 "
                  navigation-name="Round Tag"
                  onClick={() =>
                    round.roundType.toLowerCase() !== "conversion" &&
                    round.roundType.toLowerCase() !== "redemption" &&
                    round.roundType.toLowerCase() !== "update_class" &&
                    round.roundType.toLowerCase() !== "esoppool" &&
                    stage !== "Three"
                      ? navigate(
                          `/transactions/${round.roundType.toLowerCase()}/${
                            round.roundId
                          }`
                        )
                      : null
                  }
                  style={{
                    color: color1,
                    backgroundColor: backgroundColor1,
                  }}
                >
                  {round.roundIdentifier.length > 3 ? (
                    <p className="items-center py-1.5 cursor-pointer">
                      {round.roundIdentifier.slice(0, 3)}
                    </p>
                  ) : (
                    <p className="items-center py-1.5 cursor-pointer">
                      {round.roundIdentifier}
                    </p>
                  )}
                </div>
              </Tooltip>
            );
          })}
          {remaining > 0 && (
            <div className="cursor-pointer" onClick={() => setExpanded(true)}>
              <div
                className="h-fit capitalize rounded-sm font-medium text-center text-[11px] leading-4 px-2 "
                style={{
                  color: StatusLabelColors?.ROUND?.color,
                  backgroundColor: StatusLabelColors?.ROUND?.backgroundColor,
                }}
              >
                <p className="items-center py-1.5">+ {remaining} </p>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}
export default RoundIdentifiers;
