export default function TransactionDetailsAGTable(
  financialReportDetailsProps: any
) {
  const armsLengthTableHeaders = [
    "Name Of Related Party",
    "Nature Of Relationship",
    "Nature Of Contracts",
    "Duration Of Contracts",
    "Date Of Approval",
    "Amount Paid",
  ];

  const notArmsLengthTableHeaders = [
    "Name Of Related Party",
    "Nature Of Relationship",
    "Nature Of Contracts",
    "Duration Of Contracts",
    "Date Of Approval",
    "Amount Paid",
    "Date On Special Resolution",
  ];

  const armsLengthTransactionDetailsData: any = {};
  const notArmsLengthTransactionDetailsData: any = {};

  if (financialReportDetailsProps.selectedKey === "Arms Length") {
    financialReportDetailsProps.tableData
      ?.slice(1)
      .forEach((transactionDetails: any, index: number) => {
        armsLengthTransactionDetailsData[armsLengthTableHeaders[index]] =
          transactionDetails.split(",");
      });
  } else {
    financialReportDetailsProps.tableData
      ?.slice(1)
      .forEach((transactionDetails: any, index: number) => {
        notArmsLengthTransactionDetailsData[notArmsLengthTableHeaders[index]] =
          transactionDetails.split(",");
      });
  }

  return financialReportDetailsProps.selectedKey === "Arms Length" ? (
    <table className="table-fixed bg-white cursor-pointer">
      <thead className="text-left">
        <tr>
          {armsLengthTableHeaders.map((header, index) => (
            <th
              key={index}
              className="border-b border-b-gray-500 bg-gray-300 m-12 py-3 px-2"
            >
              <p>{header}</p>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {armsLengthTransactionDetailsData &&
          Object.values(armsLengthTransactionDetailsData)?.map(
            (_: any, rowIndex: number) => (
              <tr key={rowIndex}>
                {Object.values(armsLengthTransactionDetailsData).map(
                  (columnData: any, colIndex) => (
                    <td key={colIndex} className="p-2 whitespace-normal">
                      {columnData[rowIndex]}
                    </td>
                  )
                )}
              </tr>
            )
          )}
      </tbody>
    </table>
  ) : (
    <table className="table-fixed bg-white cursor-pointer">
      <thead className="text-left">
        <tr>
          {notArmsLengthTableHeaders.map((header, index) => (
            <th
              key={index}
              className="border-b border-b-gray-500 bg-gray-300 m-12 py-3 px-2"
            >
              <p>{header}</p>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {notArmsLengthTransactionDetailsData &&
          Object.values(notArmsLengthTransactionDetailsData)?.map(
            (_: any, rowIndex: number) => (
              <tr key={rowIndex}>
                {Object.values(notArmsLengthTransactionDetailsData).map(
                  (columnData: any, colIndex) => (
                    <td key={colIndex} className="p-2 whitespace-normal">
                      {columnData[rowIndex]}
                    </td>
                  )
                )}
              </tr>
            )
          )}
      </tbody>
    </table>
  );
}
