import { QueryFunctionContext } from "react-query";
import { PostBonusModel } from "../types/PostBonusModel";
import { RoundResponseDataModel } from "../types/PostModel";
import {
  CompanyAndEventIdModel,
  CurrentCompanyDetail,
  QuickBonusTransactionModel,
  QuickBuybackTransactionModel,
  QuickRoundTransactionModel,
  QuickSecondaryTransactionModel2,
  QuickSplitTransactionModel,
} from "../pages/newQuickTransaction/RoundTransactionModel";
import { SplitPostResponseDataModel } from "../types/SplitPostModel";
import api from "./capTableApi";
import {
  AllTransactionModel,
  IndividualBonusModel,
  IndividualBuybackModel,
  IndividualRoundsModel,
  IndividualSecondaryModel,
  IndividualSplitModel,
  SecondaryInvestors,
} from "../types/AllTransactionModel";
import {
  IndividualConversionModel,
  IndividualRedemptionModel,
} from "../types/ConversionRedemptionModel";
import { PpsAndValuation } from "../types/Shareholder";
import { PreviewQuickRoundResponseModel } from "../types/previewQuickRound";

export async function postBulkCompanyInvestor(transaction: any): Promise<void> {
  return api
    .post(`v1/company/companyInvestor`, transaction)
    .then((res) => res.data.data);
}

export async function postBulkInstruments(transaction: any): Promise<void> {
  return api
    .post(
      `v1/company/instrumentClass/quick?companyId=${transaction.companyId}`,
      transaction
    )
    .then((res) => res.data);
}

export async function getOnGoingTransaction(
  context: QueryFunctionContext
): Promise<{ message: string; data: any }> {
  const companyID = context.queryKey[1];
  return api
    .get(`v1/utils/cache?key=onGoingTransaction${companyID}`)
    .then((res) => res.data);
}

export async function postOnGoingTransaction(
  transaction: any
): Promise<{ message: string; data: any }> {
  return api
    .put(
      `v1/utils/cache?key=onGoingTransaction${transaction.companyId}`,
      transaction
    )
    .then((res) => res.data);
}

export async function deleteOnGoingTransaction(
  companyId: string
): Promise<{ message: string; data: any }> {
  return api
    .delete(`v1/utils/cache?key=onGoingTransaction${companyId}`)
    .then((res) => res.data);
}

export async function getAllTransactions(): Promise<AllTransactionModel[]> {
  return api
    .get("v1/company/transaction/allTransactions")
    .then((res) => res.data.data);
}

export async function getRoundsTransactions(
  context: QueryFunctionContext
): Promise<IndividualRoundsModel> {
  const eventId = context.queryKey[1];
  return api
    .get("v1/company/transaction/round", {
      params: { eventId },
    })
    .then((res) => res.data.data);
}
export async function getOngoingNonPricedRound(
  context: QueryFunctionContext
): Promise<IndividualRoundsModel[]> {
  const eventId = context.queryKey[1];
  return api
    .get("v1/company/transaction/nonPricedRound", {
      params: { eventId },
    })
    .then((res) => res.data.data);
}

export async function deleteNonPricedRoundModel(
  modelId: string
): Promise<{ message: string; data: any }> {
  return api
    .delete("/v1/company/model/nonPricedRound", {
      params: { modelId },
    })
    .then((res) => res.data.data);
}

export async function getEsopExerciseTransactions(
  context: QueryFunctionContext
): Promise<IndividualRoundsModel> {
  const eventId = context.queryKey[1];
  return api
    .get("v1/company/transaction/esopExercise", {
      params: { eventId },
    })
    .then((res) => res.data.data);
}

export async function getSecondaryTransactions(
  context: QueryFunctionContext
): Promise<IndividualSecondaryModel> {
  const eventId = context.queryKey[1];
  return api
    .get("v1/company/transaction/secondary", {
      params: { eventId },
    })
    .then((res) => res.data.data);
}

export async function getSecondaryInvestors(
  context: QueryFunctionContext
): Promise<SecondaryInvestors[]> {
  return api
    .get("v1/company/transaction/secondaryInvestors")
    .then((res) => res.data.data);
}

export async function getBuybackTransactions(
  context: QueryFunctionContext
): Promise<IndividualBuybackModel> {
  const eventId = context.queryKey[1];
  return api
    .get("v1/company/transaction/buyback", {
      params: { eventId },
    })
    .then((res) => res.data.data);
}

export async function getBonusTransactions(
  context: QueryFunctionContext
): Promise<IndividualBonusModel> {
  const eventId = context.queryKey[1];
  return api
    .get("v1/company/transaction/bonus", {
      params: { eventId },
    })
    .then((res) => res.data.data);
}

export async function getConversionTransactions(
  context: QueryFunctionContext
): Promise<IndividualConversionModel> {
  const eventId = context.queryKey[1];
  return api
    .get("v1/company/transaction/conversion", {
      params: { eventId },
    })
    .then((res) => res.data.data);
}

export async function getRedemptionTransactions(
  context: QueryFunctionContext
): Promise<IndividualRedemptionModel> {
  const eventId = context.queryKey[1];
  return api
    .get("v1/company/transaction/redemption", {
      params: { eventId },
    })
    .then((res) => res.data.data);
}

export async function getSplitTransactions(
  context: QueryFunctionContext
): Promise<IndividualSplitModel> {
  const eventId = context.queryKey[1];
  return api
    .get("v1/company/transaction/split", {
      params: { eventId },
    })
    .then((res) => res.data.data);
}

export async function postTransactionRoundDetail(
  transaction: QuickRoundTransactionModel
): Promise<RoundResponseDataModel> {
  return api
    .post(`v1/company/transaction/round`, transaction)
    .then((res) => res.data.data);
}

export async function roundExcelUpload(
  transaction: any
): Promise<QuickRoundTransactionModel> {
  return api
    .post(`v1/company/transaction/roundExcelUpload`, transaction)
    .then((res) => res.data.data);
}
//TODO: why any here?
export async function addQuickShareholder(transaction: any): Promise<any> {
  return api
    .post(`v1/company/companyInvestor`, transaction)
    .then((res) => res.data.data);
}

export async function ongoingTransactionRound(transaction: any): Promise<any> {
  return api
    .get(`v1/company/transaction/onGoingTransaction`, transaction)
    .then((res) => res.data.data);
}

export async function postTransactionSecondaryDetail(
  transaction: QuickSecondaryTransactionModel2
): Promise<any> {
  return api
    .post(`v1/company/transaction/secondary`, transaction)
    .then((res) => res.data.data);
}

export async function postTransactionBonusDetail(
  transaction: QuickBonusTransactionModel
): Promise<PostBonusModel> {
  return api
    .post(`v1/company/transaction/bonus`, transaction)
    .then((res) => res.data.data);
}

export async function postTransactionBuybackDetail(
  transaction: QuickBuybackTransactionModel
): Promise<any> {
  return api
    .post(`v1/company/transaction/createBuyback`, transaction)
    .then((res) => res.data.data);
}

export async function postTransactionSplitDetail(
  transaction: QuickSplitTransactionModel
): Promise<SplitPostResponseDataModel> {
  return api
    .post(`v1/company/transaction/split`, transaction)
    .then((res) => res.data.data);
}

export async function approveTransactionPreview(
  quickRoundDetail: CompanyAndEventIdModel
): Promise<void> {
  return api
    .post(`v1/company/transaction/approve`, quickRoundDetail)
    .then((res) => res.data);
}

export async function updatePpsAndValuation(
  ppsValuationDetail: PpsAndValuation
): Promise<any> {
  return api
    .put(`v1/company/capitalDetails`, ppsValuationDetail)
    .then((res) => res.data);
}

export async function getDiscardEventRound(
  context: QueryFunctionContext
): Promise<PreviewQuickRoundResponseModel> {
  return api
    .delete(`v1/company/transaction/discardAllProformaEvents`)
    .then((res) => res.data);
}

export async function getDiscardEventRound2(): Promise<PreviewQuickRoundResponseModel> {
  return api
    .delete(`v1/company/transaction/discardAllProformaEvents`)
    .then((res) => res.data);
}

export async function getDiscardEventIdRound(
  context: QueryFunctionContext
): Promise<PreviewQuickRoundResponseModel> {
  const eventId = context.queryKey[1];
  return api
    .delete(`v1/company/transaction/discard`, {
      params: { eventId },
    })
    .then((res) => res.data);
}

export async function deleteFinancialData(
  id: string,
  isPrivate: boolean
): Promise<any> {
  return api
    .delete(`v1/finance/deleteFinancialData`, { params: { id, isPrivate } })
    .then((res) => res.data);
}

export async function calculateSharePrice(
  context: QueryFunctionContext
): Promise<{ pps: number }> {
  const valuation = context.queryKey[1];
  const roundSize = context.queryKey[2];
  const autoDiluteEsop = context.queryKey[3];
  const postEsop = context.queryKey[4];
  return api
    .get(
      `v1/company/transaction/calculateSharePrice?valuation=${valuation}&roundSize=${roundSize}&autoDiluteEsop=${autoDiluteEsop}&postEsop=${postEsop}`
    )
    .then((res) => res.data.data);
}
