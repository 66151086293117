import { CaptableTag } from "../../components/AllotmentTag";
import {
  tableEntryStyle,
  tableFirstColStyle,
} from "../../components/TableComponent";
import { formatToReadableNumber } from "../../utils/currencyRoboto";
import { limitString } from "../../utils/string";
import Avatar from "../Utility/Avatar";

export function ShareholderRender(data: any) {
  return (
    <td className="flex flex-col text-sm text-left pb-2">
      <div className="flex">
        <div className="text-left pr-2">
          {data.value.name !== "Total" ? (
            <Avatar name={data.value.name || ""} />
          ) : (
            ""
          )}
        </div>
        <div
          className={` ${
            data.value.name === "Total"
              ? "pt-2 cursor-pointer"
              : "ml-2 cursor-pointer"
          } `}
        >
          {data.value.name?.length > 24 ? (
            <p
              data-event-name="captableShareholderDrilling"
              className={`${tableFirstColStyle}`}
            >
              {limitString(data.value.name || "", 24)}
            </p>
          ) : (
            <p
              data-event-name="captableShareholderDrilling"
              className={`${tableFirstColStyle}`}
            >
              {limitString(data.value.name || "", 24)}
            </p>
          )}

          {data.value.name !== "Total" ? (
            <CaptableTag tagName={data.value.type ?? "Others"} />
          ) : (
            ""
          )}
        </div>
      </div>
    </td>
  );
}

export function InvestmentRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-3">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.data?.investment || 0,
          format: data.data.currencyType,
        })}
      </p>
    </div>
  );
}

export function PreRoundRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-3">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.data?.preRound || 0,
          format: data.data.currencyType,
        })}
      </p>
    </div>
  );
}

export function PostRoundRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-3">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.data?.postRound || 0,
          format: data.data.currencyType,
        })}
      </p>
    </div>
  );
}
