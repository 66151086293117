import _ from "lodash";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useEffect, useRef, useState } from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { HStack, Error, VStack } from "../components/utils";
import {
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../pages/quickRound/CTAButtonComponents";
import { Input, Label, TextArea } from "../components/shared/InputField";
import {
  useSplitShareRaanges,
  useUpdateCertificateFdb,
} from "../queries/shareholders";
import { getCurrencyType } from "../utils/currencyFormatter";
import SplitShareRangesAgTable, {
  SplitShareRangeRecord,
} from "../pages/shareholders/SplitShareRangesAgTable";

type SplitShareCertificate = {
  certificateId: string;
  existingRanges: number[][];
  onPrimaryAction: () => void;
  onSecondaryAction: () => void;
};

export function SplitShareCertificateRanges({
  certificateId,
  existingRanges,
  onPrimaryAction = () => {},
  onSecondaryAction = () => {},
}: SplitShareCertificate) {
  const { mutate: splitShareRanges } = useSplitShareRaanges();

  const gridApi = useRef<any>(null);

  const [ranges, setRanges] = useState<number[][]>([]);

  const handleSubmit = () => {
    const currentData: number[][] = [];
    gridApi.current.api.forEachNode((node: any) => {
      currentData.push([node.data.from, node.data.to]);
    });

    if (currentData.length === 0) {
      toast("No Share Numbers to update", {
        type: "error",
        autoClose: 2000,
      });
      return;
    }
    splitShareRanges(
      {
        certificateId,
        ranges: currentData,
      },
      {
        onSuccess: () => {
          toast("Split Ranges Updated Successfully", {
            type: "success",
            autoClose: 2000,
          });
          onPrimaryAction();
        },
        onError: (err: any) => {
          toast(err.response.data.errorMessage, {
            type: "error",
            autoClose: 2000,
          });
        },
      }
    );
  };

  return (
    <div className="w-full p-4">
      <HStack className="justify-between border-b">
        <p className="font-semibold text-lg mb-3 ml-4 mr-5">
          Split Share Numbers for ShareCertificate
        </p>
        <XMarkIcon
          className="cursor-pointer h-5 font-medium"
          onClick={() => onSecondaryAction()}
        />
      </HStack>
      <VStack className="p-5">
        <HStack className="m-2 gap-5">
          <Label className="text-sm font-normal items-center pr-10 m-2">
            Current Share Numbers :
          </Label>
          <div
            className={`grid ${
              existingRanges.length > 1 ? "grid-cols-3 gap-2" : ""
            }`}
          >
            {existingRanges
              .map((range) => `${range[0]}-${range[1]}`)
              .map((range, index) => (
                <p
                  key={index}
                  className={`text-xs font-semibold text-center cursor-pointer border rounded-lg border-black px-1.5 mt-1 ${
                    existingRanges.length === 1 ? "text-center" : ""
                  }`}
                >
                  {range}
                </p>
              ))}
          </div>
        </HStack>
        <div className="m-2">
          <SplitShareRangesAgTable ranges={ranges} gridApi={gridApi} />
        </div>
        <HStack className="justify-between mt-10">
          <SecondaryCTAButton
            event-name="Back Split Sharecertificate Ranges"
            onClick={() => {
              onSecondaryAction();
            }}
          >
            Back
          </SecondaryCTAButton>
          <PrimaryCTAButton
            event-name="Save Split Sharecertificate Ranges"
            onClick={handleSubmit}
          >
            Save
          </PrimaryCTAButton>
        </HStack>
      </VStack>
    </div>
  );
}
