import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { string } from "yup";
import { Input, Label } from "../components/shared/InputField";
import { Select } from "../components/shared/Select";
import { SwitchButton } from "../components/shared/SwitchButton";
import { HStack, VStack, Error, Box } from "../components/utils";
import {
  CapTableBarChart,
  ShareHolderCaptableChartModel,
} from "../pages/newQuickTransaction/CapTableBarChart";
import { ShareHolderCapTableQuickRound } from "../pages/newQuickTransaction/ShareHolderCapTableQuickRound";
import { useGetQuickRoundPreview } from "../queries/quickRound";
import {
  useGetRedemption,
  useUpdateRedemptionDetails,
} from "../queries/shareholders";
import { useApproveTransactionPreview } from "../queries/transactionRound";
import { useAuthStore } from "../store";

import {
  SecurityRedemptionModel,
  SecurityRedemptionUpdateModel,
  ShareholderSecurityModel,
} from "../types/Shareholder";
import { getCurrencySymbol, getCurrencyType } from "../utils/currencyFormatter";
import {
  ButtonSize,
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../pages/quickRound/CTAButtonComponents";

export function AddRedemptionDetails({
  shareholderSecurity,
  onPrimaryAction = () => {},
  onSecondaryAction = () => {},
  shareholderId,
  certificateId,
}: {
  shareholderSecurity: ShareholderSecurityModel;
  onPrimaryAction: () => void;
  onSecondaryAction: () => void;
  shareholderId: string;
  certificateId: string;
}) {
  const [isApprove, setIsApprove] = useState(false);

  const companyId = useAuthStore.getState().companyId || "";
  const currencySymbol = getCurrencySymbol();
  const currencyType = getCurrencyType();

  const [eventId, setEventId] = useState<string>();

  const { mutate: updateRedemption, data: redemptionDetails } =
    useUpdateRedemptionDetails();

  const { refetch: getQuickRoundPreview, data: previewData } =
    useGetQuickRoundPreview(eventId || "");

  const { mutate: approveShareholderRedemption } =
    useApproveTransactionPreview();

  const [showChart, setShowChart] = useState(false);
  const [roundShares, setRoundShare] = useState<number>(0);
  const [chartData, setChartData] = useState<ShareHolderCaptableChartModel[]>(
    []
  );

  const handleSave = (values: SecurityRedemptionUpdateModel) => {
    setIsApprove(true);
    updateRedemption(values, {
      onSuccess: async (data) => {
        setEventId(data.id);
        setIsApprove(true);
        setRedmeptionFormValues(values);
        toast("Redemption Details Saved Successfully!", {
          type: "success",
          autoClose: 2000,
        });
      },

      onError: (err: any) => {
        toast(err.response.data.reason, {
          type: "error",
          autoClose: 2000,
        });
      },
    });
  };

  useEffect(() => {
    getQuickRoundPreview();
  }, [redemptionDetails]);

  const { data: securityRedmeptionDetails } = useGetRedemption(
    shareholderSecurity.securityClassId || "",
    shareholderId || "",
    certificateId || ""
  );

  const [amountLeftToRedeem, setAmountLeftToRedeem] = useState(
    (securityRedmeptionDetails?.investmentAmount || 0) -
      (securityRedmeptionDetails?.redeemedAmount || 0)
  );

  useEffect(() => {
    setAmountLeftToRedeem(
      (securityRedmeptionDetails?.investmentAmount || 0) -
        (securityRedmeptionDetails?.redeemedAmount || 0)
    );
  }, [securityRedmeptionDetails]);

  const [interest, setInterest] = useState(0);

  const getInterest = (
    redemption: SecurityRedemptionModel | undefined,
    amount: number
  ) => {
    if (!redemption) return 0;
    const interestType = redemption.interestType;
    const n = redemption.interestPeriod;
    if (interestType === "Simple")
      return (redemption.interestRate * redemption.interestTime * amount) / 100;
    return (
      amount *
        (1 + redemption.interestRate / n) ** (n * redemption.interestTime) -
      amount
    );
  };

  const handleSubmit = () => {};

  const [redemptionFormValues, setRedmeptionFormValues] =
    useState<SecurityRedemptionUpdateModel>({
      companyId,
      securityClassId: shareholderSecurity.securityClassId,
      shareholderId,
      redemptionDate: "",
      redeemedAmount: 0,
    });

  return (
    <div className="w-full bg-white rounded-md border-dashed border-2">
      <VStack className="text-left font-medium">
        <VStack className="text-lg font-medium border-b p-5">
          <HStack className="justify-between">
            <p className="font-semibold text-lg mb-3">Redemption</p>
            <span
              onClick={() => onSecondaryAction()}
              className="cursor-pointer"
            >
              X
            </span>
          </HStack>
          <HStack className="justify-start font-medium text-base">
            <p className="mr-3">
              {shareholderSecurity?.name}({shareholderSecurity?.securityType})
            </p>
            <p className="mr-3">Rounds - {shareholderSecurity?.round}</p>
            <p>Security - {shareholderSecurity?.securityType}</p>
          </HStack>
        </VStack>
        {securityRedmeptionDetails && (
          <AddRedemptionCardDetails
            securityRedemption={securityRedmeptionDetails}
            currencyType={currencyType}
          />
        )}
        {!isApprove ? (
          <div>
            <Formik
              key="addQuickBuybackParticipant"
              enableReinitialize={true}
              initialValues={redemptionFormValues}
              onSubmit={(values) => {
                handleSave(values);
              }}
            >
              {(formik) => (
                <Form key="shareholder">
                  <VStack className="w-full p-8 gap-6">
                    <HStack className="gap-5 px-10 py-3">
                      <div className="w-2/5">
                        <Label className="text-sm font-medium">
                          Redemption Date
                        </Label>
                        <Input
                          type="date"
                          placeholder="Eg:DD/MM/YYYY"
                          className="w-80"
                          {...formik.getFieldProps("redemptionDate")}
                        />
                      </div>
                      <div className="w-1/5"></div>
                      <div className="w-2/5">
                        <HStack className="justify-between">
                          <Label className="text-sm font-medium whitespace-nowrap">
                            Amount To Be Redeemed
                          </Label>
                          <Label className="text-xxs text-green-500">
                            {currencySymbol} {amountLeftToRedeem} Left
                          </Label>
                        </HStack>
                        <Input
                          type="number"
                          placeholder="Enter Shares to Redeem"
                          className="w-80"
                          {...formik.getFieldProps("redeemedAmount")}
                          onChange={(e) => {
                            formik.handleChange(e);
                            if (e.target.value) {
                              setAmountLeftToRedeem(
                                (securityRedmeptionDetails?.investmentAmount ||
                                  0) -
                                  (securityRedmeptionDetails?.redeemedAmount ||
                                    0) -
                                  Number(e.target.value)
                              );
                              setInterest(
                                getInterest(
                                  securityRedmeptionDetails,
                                  Number(e.target.value)
                                )
                              );
                            } else {
                              setAmountLeftToRedeem(
                                (securityRedmeptionDetails?.investmentAmount ||
                                  0) -
                                  (securityRedmeptionDetails?.redeemedAmount ||
                                    0)
                              );
                              setInterest(0);
                            }
                          }}
                        />
                      </div>
                    </HStack>
                    <div>
                      {interest !== 0 && formik.values.redeemedAmount !== 0 && (
                        <div className="border-2 border-dashed pt-3 px-5 pb-5">
                          <Label>
                            {" "}
                            Interest &quot;{currencySymbol}
                            {interest}&quot; is assumed to have been paid for
                            amount &quot;{currencySymbol}
                            {formik.values.redeemedAmount}&quot;{" "}
                          </Label>
                        </div>
                      )}
                    </div>
                    <HStack className="justify-between mt-5 h-8">
                      <SecondaryCTAButton
                        event-name="Back Redemption Modal"
                        buttonSize={ButtonSize.SMALL}
                        onClick={() => {
                          onSecondaryAction();
                        }}
                      >
                        Back
                      </SecondaryCTAButton>
                      <PrimaryCTAButton
                        event-name="Save Redemption Modal"
                        buttonSize={ButtonSize.SMALL}
                        type="submit"
                      >{`Save & Continue`}</PrimaryCTAButton>
                    </HStack>
                  </VStack>
                </Form>
              )}
            </Formik>
          </div>
        ) : (
          <VStack className="p-5">
            <VStack className="gap-4 my-4 mx-2 bg-white shadow-box rounded-lg">
              <div>
                {showChart && chartData && (
                  <div className="flex-1">
                    <CapTableBarChart
                      shareHolderChartData={chartData}
                      dilutedPercentage={
                        roundShares /
                        ((previewData?.preEventFdb || 0) + roundShares)
                      }
                      toggleChartAndToggle={() => {
                        setShowChart(!showChart);
                      }}
                    />
                  </div>
                )}
                {!showChart && (
                  <HStack className="w-full">
                    {previewData?.holdingDifference && (
                      <ShareHolderCapTableQuickRound
                        data={previewData?.holdingDifference || []}
                        toggle={() => {
                          setShowChart(!showChart);
                        }}
                      />
                    )}
                  </HStack>
                )}
              </div>
            </VStack>
            <HStack className="justify-between mt-10 h-8">
              <SecondaryCTAButton
                event-name="Back from Approve Redemption Modal"
                buttonSize={ButtonSize.SMALL}
                onClick={() => {
                  setIsApprove(false);
                }}
              >
                Back
              </SecondaryCTAButton>
              <PrimaryCTAButton
                event-name="Approve Redemption Modal"
                buttonSize={ButtonSize.SMALL}
                onClick={() => {
                  approveShareholderRedemption(
                    {
                      companyId,
                      eventId: eventId || "",
                      valuation: previewData?.eventInfo?.valuation || 0,
                      pricePerShare: previewData?.eventInfo?.pps || 0,
                    },
                    {
                      onSuccess: (data) => {
                        onPrimaryAction();
                        toast("Conversion Details Updated Successfully!", {
                          type: "success",
                          autoClose: 2000,
                        });
                      },
                      onError: (err: any) => {
                        toast(err.response.data.reason, {
                          type: "error",
                          autoClose: 2000,
                        });
                      },
                    }
                  );
                }}
              >
                Approve
              </PrimaryCTAButton>
            </HStack>
          </VStack>
        )}
      </VStack>
    </div>
  );
}

function RedemptionDetailsCard({
  value,
  label,
}: {
  value: number | string;
  label: string;
}) {
  return (
    <Box className="py-4 shadow-box bg-white rounded-md mt-3 flex-wrap mx-6">
      <VStack className="px-5">
        <div className="text-center text-sm font-semibold">{value}</div>
        <div className="text-center text-xs font-medium">{label}</div>
      </VStack>
    </Box>
  );
}

function AddRedemptionCardDetails({
  securityRedemption,
  currencyType,
}: {
  securityRedemption: SecurityRedemptionModel;
  currencyType: string;
}) {
  const currencySymbol = getCurrencySymbol();
  return (
    <Box className="border-b">
      <VStack className="px-5 py-7 justify-start">
        <HStack className="justify-start">
          <div className="mr-15">
            <RedemptionDetailsCard
              value={securityRedemption.investmentAmount.toLocaleString(
                currencyType
              )}
              label={`Amount Invested (${currencySymbol})`}
            />
          </div>
          <div>
            <RedemptionDetailsCard
              value={securityRedemption.redeemedAmount.toLocaleString(
                currencyType
              )}
              label={`Redeemed (${currencySymbol})`}
            />
          </div>
        </HStack>
      </VStack>
    </Box>
  );
}
