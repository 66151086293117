import { useMutation, useQuery } from "react-query";
import {
  addGlobalInvestor,
  generateInvestorCredentials,
  getGlobalInvestors,
} from "../api/globalInvestor";
import { handleEventForTracking } from "../amplitudeAnalytics";
import { queryClient } from "./client";

export function useGetGlobalInvestors() {
  return useQuery({
    queryKey: "getGlobalInvestors",
    queryFn: getGlobalInvestors,
  });
}

export function useAddGlobalInvestor() {
  return useMutation({
    mutationKey: "addGlobalInvestor",
    mutationFn: addGlobalInvestor,
    onSuccess: () => {
      queryClient.invalidateQueries("getGlobalInvestors");
      handleEventForTracking({
        eventName: "addGlobalInvestor",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "addGlobalInvestor",
        success: false,
        eventType: "API",
      });
    },
  });
}

export function useGenerateInvestorCredentials() {
  return useMutation({
    mutationKey: "generateInvestorCredentials",
    mutationFn: generateInvestorCredentials,
    onSuccess: () => {
      handleEventForTracking({
        eventName: "generateCredentials",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "generateCredentials",
        success: false,
        eventType: "API",
      });
    },
  });
}
