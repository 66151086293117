import { useRef } from "react";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  ChartType,
} from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";
import { Chart, getElementAtEvent } from "react-chartjs-2";
import { Box } from "../../components/utils";
import { useShareHolderChartStore } from "../../store/shareholderChart";
import {
  EventComparisonModel,
  InvestmentShareHolderModel,
} from "../../types/DashboardModel";
import { limitString } from "../../utils/string";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  zoomPlugin
);

export function DashboardShareHolderChart({
  investmentData,
  shareHoldersDetail,
}: {
  investmentData: InvestmentShareHolderModel[];
  shareHoldersDetail: EventComparisonModel[];
}) {
  const shareHolderChartState = useShareHolderChartStore();
  const labels = investmentData.map((investment) =>
    limitString(investment?.name, 20)
  );
  const chartData: any[] = [];
  shareHoldersDetail.forEach((shareholder) => {
    if (shareholder.isVisible) {
      chartData.push({
        label: shareholder.name,
        type: "line" as ChartType,
        data: shareholder.listValue,
        backgroundColor: shareholder.color,
        borderColor: shareholder.color,
        borderWidth: 2,
        stepped: true,
      });
    }
  });

  const data = {
    labels: investmentData.map((element) => {
      const roundName = element?.name.split(" ");
      if (roundName.length >= 2) return roundName.slice(0, 2);
      return roundName || "";
    }),
    datasets: chartData,
  };

  const chartRef = useRef<ChartJS>(null);

  const onClick = (event: any) => {
    const { current: chart } = chartRef;
    if (chart) {
      const selectedChart = getElementAtEvent(chart, event);

      if (selectedChart[0]) {
        chart.ctx.save();
        chart.update();
        shareHolderChartState.setName(labels[selectedChart[0].index]);
      }
    }
  };

  const dashboardShareholderOption = {
    responsive: true,
    maintainAspectRatio: false,
    categoryPercentage: 0.5,
    barPercentage: 0.9,
    borderRadius: 10,
    legend: {
      display: true,
      position: "bottom" as const,
      labels: {
        boxWidth: 10,
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "bottom" as const,
        labels: {
          boxWidth: 10,
        },
      },
      zoom: {
        pan: {
          enabled: true,
          mode: "x",
        },
        zoom: {
          pinch: {
            enabled: true, // Enable pinch zooming
          },
          wheel: {
            enabled: true, // Enable wheel zooming
            speed: 0.05,
          },
          mode: "x",
        },
      },
      tooltip: {
        usePointStyle: true,
        bodyFont: {
          weight: "bold",
          size: 14,
        },
        titleColor: "#464E5F",
        bodyColor: "#464E5F",
        displayColors: false,
        backgroundColor: "#FFFFFF",

        callbacks: {
          title: (context: any) => context[0].label.replace(",", " "),
          label: (tooltipItem: any) => {
            const chartData = tooltipItem.chart.config._config
              .data as unknown as any;
            const label: string[] = [];

            for (let i = 0; i < chartData.datasets.length; i++) {
              const total = (
                chartData.datasets[i].data[tooltipItem.dataIndex] || 0
              ).toFixed(2);
              label.push(
                `${chartData.datasets[i].label.split(" ")[0]}: ${total} %`
              );
            }
            return label.map((item: string) => item);
          },
        },
        legend: {
          display: false,
          position: "bottom" as const,
        },
        title: {
          display: false,
          text: "Chart.js Bar Chart",
        },
      },
    },
    scales: {
      x: {
        display: true,
        grid: {
          display: false,
          offset: true,
        },
        ticks: {
          font: { size: 12 },
          padding: 10,
          maxRotation: 0,
          minRotation: 0,
        },
      },
      y: {
        display: true,

        beginAtZero: true,
        grid: {
          borderDash: [1, 3],
          color: "#e8e8e8",
        },
        ticks: {
          beginAtZero: false,
          min: 0,
          font: { size: 12 },
          callback(value: any, index: any, ticks: any) {
            return `${value}%`;
          },
        },
      },
    },
  };

  return (
    <Box className="w-full h-80 align-center">
      <Chart
        type="line"
        id="chart"
        ref={chartRef}
        onClick={onClick}
        options={dashboardShareholderOption as unknown as any}
        data={data}
      />
    </Box>
  );
}
