import { getDougnutTagColor, hexToRGBA } from "../utils/colorUtils";
import { HStack, VStack } from "./utils";
import { limitString } from "../utils/string";
import Tooltip from "./shared/Tooltip";
import HalfDoughnutChart from "../pages/shareholders/HalfDoughnutChart";
import { FormatNumberSpan } from "../utils/currencyRoboto";

type PercentageCardProps = {
  name: string;
  type: string;
  percentage: number;
  keyValue: KeyValuePair[];
};

type KeyValuePair = {
  key: string;
  value: number;
  isAmount: boolean;
};

export default function PercentageCardDesign({
  data,
  currencySymbol,
  currencyType,
  onClick,
  type = "Shareholder",
}: {
  data: PercentageCardProps;
  onClick: () => void;
  currencySymbol: string;
  currencyType: string;
  type: "Shareholder" | "Security";
}) {
  //   const numberOfShares =
  //     shareholderFilter.securityType !== "All"
  //       ? data.sharesPerSecurity.find(
  //           (security) => security.security === shareholderFilter.securityType
  //         )?.shares || data.shares
  //       : data.shares;

  const color = getDougnutTagColor(data.type.toUpperCase());
  const rgbaColor = hexToRGBA(color.text, 0.2);

  const seriesChartData = {
    display: false,
    labels: [],
    datasets: [
      {
        label: "% of Holding",
        data: [data.percentage, 100 - data.percentage],
        backgroundColor: [color.border, rgbaColor],
        borderColor: [color.border, rgbaColor],
        borderWidth: 0, // Set the desired border width
        borderRadius: 0,
      },
    ],
  };

  return (
    <div
      className={`h-40 my-2 w-80 bg-gradient-to-b shadow-box  border border-borderColor rounded-lg hover:cursor-pointer flex flex-col   bg-white  hover:-translate-y-1 hover:duration-500`}
      onClick={onClick}
      navigation-name={`${type} Card`}
    >
      <HStack className="bg-gradient-to-b rounded-lg shadow-box justify-between h-auto  bg-[#F2F2F2]/50 border border-borderColor">
        <div>
          <Tooltip text={data.name}>
            <p className="px-4 pt-4 text-base font-semibold">
              {limitString(data.name, 17)}
            </p>
          </Tooltip>
          <p
            className={`ml-4 px-2 py-1 text-xxs font-medium mb-2 w-fit h-fit rounded-xl ${color.bgColor} text-[${color.text}]`}
          >
            {data.type}
          </p>
        </div>
        <Tooltip text={`${data.percentage.toFixed(8)}`}>
          <VStack>
            <HalfDoughnutChart data={seriesChartData} type={data.type} />
            <p className="text-center text-xxs pb-3 pt-2 text-gray-500">
              % of Holding
            </p>
          </VStack>
        </Tooltip>
      </HStack>
      <VStack className="py-3 w-80 gap-2">
        {data.keyValue.map((element) => (
          <HStack key={element.key} className="px-4 justify-between text-xs">
            <p className="text-gray-500">
              {element.key}
              {element.isAmount && (
                <span
                  className="px-2"
                  style={{ fontFamily: "'Roboto', sans-serif" }}
                >
                  ({currencySymbol})
                </span>
              )}
            </p>

            <p className="text-right gap-2  justify-end font-semibold">
              <FormatNumberSpan
                value={element.value ?? 0}
                format={currencyType}
              />
            </p>
          </HStack>
        ))}
      </VStack>
      {/* <VStack className="gap-2 text-gray-500">
        <p>No.of Shares</p>
        <p>
          Net Worth
          <span className="px-2" style={{ fontFamily: "'Roboto', sans-serif" }}>
            ({currencySymbol})
          </span>
        </p>
      </VStack>
      <VStack className="text-right gap-2  justify-end font-semibold">
        <p>
          <FormatNumberSpan value={234} format={currencyType} />
        </p>
        <p>
          <FormatNumberSpan value={234234} format={currencyType} />
        </p>
      </VStack> */}
    </div>
  );
}
