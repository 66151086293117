import Tooltip from "../components/shared/Tooltip";
import { useAuthStore } from "../store";
import { checkVoid } from "./transactionUtils";

export function formatCurrencyRoboto(num: number, format: string, symbol: any) {
  return (
    <span style={{ fontFamily: "'Roboto', sans-serif" }}>
      {symbol}{" "}
      {num.toLocaleString(format, {
        maximumFractionDigits: 2,
      })}
    </span>
  );
}

export function getCurrencySymbolRoboto() {
  const { user } = useAuthStore();
  const companyCurrency =
    user?.company?.companyCurrency || user?.companyCurrency;
  const res = companyCurrency?.split("-")[1]?.trim() || "₹";
  return <span style={{ fontFamily: "'Roboto', sans-serif" }}>{res}</span>;
}

export function getRoundCurrencySymbol(roundCurrency: string) {
  const res = roundCurrency?.split("-")[1]?.trim() || "₹";
  return <span style={{ fontFamily: "'Roboto', sans-serif" }}>{res}</span>;
}

export function formatNumberCroreAndLakhInNegativeAndPositive(
  value?: number,
  format?: string
) {
  if ((value ?? 0) >= 0) return `${formatToReadableNumber({ value, format })}`;
  else
    return `- ${formatToReadableNumber({
      value: Math.abs(value ?? 0),
      format,
    })}`;
}

export function FormatNumberSpan({
  value,
  format,
  position,
}: {
  value?: number;
  format?: string;
  position?:
    | "right-0"
    | "left-0"
    | "right-1/2"
    | "bottom-0"
    | "left-1/2"
    | "bottom-1"
    | "bottom-4"
    | "top-6 right-0";
}) {
  const formatCurrency = format || "en-IN";

  const num = value || 0;
  const numValue = num.toLocaleString(formatCurrency, {
    maximumFractionDigits: 2,
  });
  const toolTipNumValue = num.toLocaleString(formatCurrency, {
    maximumFractionDigits: 4,
  });
  if (num >= 10000000000 && formatCurrency === "en-IN") {
    return (
      <Tooltip text={toolTipNumValue} _position={position}>
        <span>{`${(num / 10000000000).toLocaleString(formatCurrency, {
          maximumFractionDigits: 2,
        })}K Cr`}</span>
      </Tooltip>
    );
  } else if (num >= 10000000 && formatCurrency === "en-IN") {
    return (
      <Tooltip text={toolTipNumValue} _position={position}>
        <span>{`${(num / 10000000).toLocaleString(formatCurrency, {
          maximumFractionDigits: 2,
        })} Cr`}</span>
      </Tooltip>
    );
  } else if (num >= 100000 && formatCurrency === "en-IN") {
    return (
      <Tooltip key="format" text={toolTipNumValue} _position={position}>
        <span>{`${(num / 100000).toLocaleString(formatCurrency, {
          maximumFractionDigits: 2,
        })} L`}</span>
      </Tooltip>
    );
  } else if (num >= 1000000000 && formatCurrency !== "en-IN") {
    return (
      <Tooltip text={toolTipNumValue} _position={position}>
        <span>{`${(num / 1000000000).toLocaleString(formatCurrency, {
          maximumFractionDigits: 2,
        })} B`}</span>
      </Tooltip>
    );
  } else if (num >= 1000000 && formatCurrency !== "en-IN") {
    return (
      <Tooltip text={toolTipNumValue} _position={position}>
        <span>{`${(num / 1000000).toLocaleString(formatCurrency, {
          maximumFractionDigits: 2,
        })} M`}</span>
      </Tooltip>
    );
  } else {
    return (
      <Tooltip text={toolTipNumValue} _position={position}>
        <span>{numValue}</span>
      </Tooltip>
    );
  }
}

export function formatToReadableNumber({
  value,
  format,
}: {
  value?: number;
  format?: string;
}) {
  if (checkVoid(value)) return "-";
  const formatCurrency = format || "en-IN";
  const num = value || 0;
  const numValue = num.toLocaleString(formatCurrency, {
    maximumFractionDigits: 2,
  });
  if (num >= 10000000000 && formatCurrency === "en-IN") {
    return `${(num / 10000000000).toLocaleString(formatCurrency, {
      maximumFractionDigits: 2,
    })}K Cr`;
  } else if (num >= 10000000 && formatCurrency === "en-IN") {
    return `${(num / 10000000).toLocaleString(formatCurrency, {
      maximumFractionDigits: 2,
    })} Cr`;
  } else if (num >= 100000 && formatCurrency === "en-IN") {
    return `${(num / 100000).toLocaleString(formatCurrency, {
      maximumFractionDigits: 2,
    })} L`;
  } else if (num >= 1000000000 && formatCurrency !== "en-IN") {
    return `${(num / 1000000000).toLocaleString(formatCurrency, {
      maximumFractionDigits: 2,
    })} B`;
  } else if (num >= 1000000 && formatCurrency !== "en-IN") {
    return `${(num / 1000000).toLocaleString(formatCurrency, {
      maximumFractionDigits: 2,
    })} M`;
  } else {
    return numValue;
  }
}
