import _ from "lodash";
import { RoundHeaderType } from "../constants/RoundsConstant";
import { AllTransactionModel } from "../types/AllTransactionModel";
import { IndividualRoundDetailModel } from "../types/RoundsModel";

export const getHeaderType = (type: string) => {
  switch (type.toLowerCase()) {
    case "bonus":
      return RoundHeaderType.RoundBonusHeader;
    case "buyback":
      return RoundHeaderType.RoundBuyBackHeader;
    case "esopPool":
      return RoundHeaderType.RoundEsopShareHeader;
    case "split":
      return RoundHeaderType.RoundSplitShareHeader;
    default:
      return RoundHeaderType.RoundDetailHeader;
  }
};

export function compareTransactions(a: any, b: any) {
  // Ensure dates are valid Date objects and in format yyyy-mm-dd
  const dateA = a.dateOfTransaction;
  const dateB = b.dateOfTransaction;
  if (dateB < dateA) {
    return -1;
  } else if (dateB > dateA) {
    return 1;
  }
  return 0;
}

export function compareSecurityDate(a: any, b: any) {
  // Ensure dates are valid Date objects and in format yyyy-mm-dd
  const dateA = a.date;
  const dateB = b.date;
  if (dateB < dateA) {
    return -1;
  } else if (dateB > dateA) {
    return 1;
  }
  return 0;
}

export const checkVoid = (data: any) =>
  data === undefined || data === "" || data === null;

export const extractAllTransaction = (
  alltransactions: AllTransactionModel[]
) => {
  const transactions = _.groupBy(alltransactions, "transactionType");

  const getLatestTransaction = (transactions: AllTransactionModel[]) =>
    _.maxBy(transactions, (transaction) => transaction.dateOfTransaction);
  const headers: IndividualRoundDetailModel[] = [];
  Object.keys(transactions).forEach((roundType) => {
    const latestTransaction = getLatestTransaction(transactions[roundType]);

    if (
      latestTransaction &&
      (latestTransaction.transactionType?.toLowerCase() === "round" ||
        latestTransaction.transactionType?.toLowerCase() === "secondary" ||
        latestTransaction.transactionType?.toLowerCase() === "bonus" ||
        latestTransaction.transactionType?.toLowerCase() === "split" ||
        latestTransaction.transactionType?.toLowerCase() === "buyback")
    ) {
      const transactionDetails: IndividualRoundDetailModel = {
        id: latestTransaction.id,
        name: latestTransaction.name,
        identifier: latestTransaction.identifier,
        dateOfTransaction: latestTransaction.dateOfTransaction,
        postMoney: 0,
        preMoney: 0,
        numberOfShares: latestTransaction?.noOfShares || 0,
        pps: latestTransaction?.pps || 0,
        dilution: latestTransaction?.dilution || 0,
        isPricedRound: false,
        amountRaised: latestTransaction.amount,
        splitRatio: latestTransaction.splitRatio,
        bonusRatio: latestTransaction.bonusRatio,
        capTable: [],
        type:
          latestTransaction.transactionType.charAt(0).toUpperCase() +
          latestTransaction.transactionType.slice(1),
        transactions: [],
        securityTypes:
          latestTransaction?.securityTypes.map(
            (element) => element.securityType
          ) || 0,
        numberOfBuyer: 0,
        numberOfSeller: 0,
        newShares: 0,
        newFdbShares: 0,
      };

      headers.push(transactionDetails);
    }
  });
  return headers;
};
