import _ from "lodash";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";

type WarningModalModel = {
  onPrimaryAction: () => void;
  onSecondaryAction: () => void;
  data?: string;
  title?: string;
  buttonTitle?: string;
};

export default function WarningModal({
  onPrimaryAction = () => {},
  onSecondaryAction = () => {},
  data,
  title,
  buttonTitle,
}: WarningModalModel) {
  return (
    <div className="flex flex-col items-end justify-center min-h-full p-4 text-center w-128 sm:items-center sm:p-0">
      <div className="pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
        <div className="sm:flex sm:items-start">
          <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-yellow-100 rounded-full sm:mx-0 sm:h-10 sm:w-10">
            <ExclamationTriangleIcon
              className="w-6 h-6 text-yellow-400"
              aria-hidden="true"
            />
          </div>
          <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
            <h3 className="text-base font-semibold leading-6 text-gray-900">
              {title}
            </h3>
            <div className="mt-2">
              <p className="text-sm text-gray-500">{data}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full px-4 py-3 bg-gray-50 sm:flex sm:flex-row-reverse sm:px-6">
        <button
          type="button"
          className="inline-flex justify-center w-full px-3 py-2 text-sm font-semibold text-white bg-red-600 rounded-md shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
          onClick={() => {
            onPrimaryAction();
          }}
        >
          {buttonTitle}
        </button>
        <button
          type="button"
          className="inline-flex justify-center w-full px-3 py-2 mt-3 text-sm font-semibold text-gray-900 bg-white rounded-md shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
          onClick={() => {
            onSecondaryAction();
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  );
}
