import { InformationCircleIcon } from "@heroicons/react/24/solid";
import Avatar from "../Utility/Avatar";
import {
  ButtonSize,
  SecondaryCTAButton,
} from "../quickRound/CTAButtonComponents";
import { useShareAuthStore } from "../../store/useShareAuthStore";
import {
  clearSharedCredentials,
  redirectToSharedHissa,
} from "../../components/utils";
import { queryClient } from "../../queries";

function Navbar({ companyName, cin }: { companyName: string; cin: string }) {
  const { setShareAuth, shareId, shareUserMail, shareResourceType } =
    useShareAuthStore();
  return (
    <div>
      <div className="sticky top-0 z-40 justify-between bg-secondary flex h-16 shrink-0 items-center gap-x-4 shadow-sm border-gray-200 sm:gap-x-6 px-4 sm:px-6 lg:px-8">
        <div className="sm:w-full sm:max-w-md flex-1">
          <img
            className="h-6 w-auto"
            src={"https://equion-dev.s3.us-west-2.amazonaws.com/logonew.svg"}
            alt="hissa_logo"
          />
        </div>
        <div className="text-white flex-1 text-center mx-auto w-full">
          <p className="text-lg font-bold text-center">{companyName}</p>
          <p className="text-xxs1 text-gray-300 font-normal py-0 my-0 leading-3">{`${shareResourceType} PAGE`}</p>
        </div>
        <div className="justify-end flex items-center gap-x-4 lg:gap-x-6 flex-1">
          <div className="hidden"></div>
          <div
            className="hidden lg:block lg:h-6 lg:w-px lg:bg-white"
            arisa-hidden="true"
          />
          <SecondaryCTAButton
            buttonSize={ButtonSize.SMALL}
            onClick={() => {
              redirectToSharedHissa(shareId ?? "");
              localStorage.removeItem("sharedAccesstoken");
              queryClient.clear();
              setShareAuth({
                isShareAuthenticated: false,
                shareAccessToken: undefined,
                shareId: undefined,
                shareResourceId: undefined,
                shareCompanyId: undefined,
                shareResourceType: undefined,
                shareUserMail: undefined,
                shareUserId: undefined,
              });
            }}
          >
            Log Out
          </SecondaryCTAButton>
          <Avatar name={shareUserMail ?? "U"} isDark={true} />
        </div>
      </div>
    </div>
  );
}

export default Navbar;
