import create from "zustand";
import { configurePersist } from "zustand-persist";
import { InstrumentClassSubTypes, Shareholder } from "../types/quickRound";
import { InstrumentClass } from "../types/DashboardModel";

const { persist: _persist } = configurePersist({
  storage: localStorage,
  rootKey: "root",
});

const persist = _persist as any;

type ShareholderInstrumentState = {
  instrumentClasses: InstrumentClass[];
  shareholders: Shareholder[];
  instrumentClassSubTypes: InstrumentClassSubTypes;
  setInstrumentClass: (instruments: InstrumentClass[]) => void;
  setShareholders: (shareHolders: Shareholder[]) => void;
  setInstrumentClassSubTypes: (
    instrumentClassSubTypes: InstrumentClassSubTypes
  ) => void;
  reset: () => void;
};

const initialState = {
  instrumentClasses: [],
  shareholders: [],
  instrumentClassSubTypes: {},
};

const shareholderInstrumentStore = create<ShareholderInstrumentState>(
  persist(
    {
      key: "shareholderInstruments",
    },
    (set: any) => ({
      ...initialState,
      setInstrumentClass: (instruments: InstrumentClass[]) => {
        set((state: InstrumentClass[]) => ({ instrumentClasses: instruments }));
      },
      setShareholders: (shareholders: Shareholder[]) => {
        set((state: Shareholder[]) => ({
          shareholders,
        }));
      },
      setInstrumentClassSubTypes: (
        instrumentClassSubTypes: InstrumentClassSubTypes
      ) => {
        set((state: InstrumentClassSubTypes) => ({
          instrumentClassSubTypes,
        }));
      },
      reset: () => {
        set(() => initialState);
      },
    })
  )
);

export const useShareholderInstrumentStore = shareholderInstrumentStore;
