import _ from "lodash";
import { useNavigate } from "react-router";
import {
  tableEntryStyle,
  tableFirstColStyle,
} from "../../components/TableComponent";
import { limitString } from "../../utils/string";
import { getSecurityColor } from "../../utils/historicUtilities";
import { formatToReadableNumber } from "../../utils/currencyRoboto";
import Avatar from "../Utility/Avatar";
import { CaptableTag } from "../../components/AllotmentTag";
import RoundTag from "../../shared/RoundTag";
import { HStack } from "../../components/utils";

export function SecurityNameRender(data: any) {
  return (
    <td className="flex flex-col pb-2 text-sm text-left">
      <div className="pt-2 whitespace-nowrap">
        <HStack>
          <p className={tableEntryStyle}>{data.data.securityName}</p>
          {data.data.model && (
            <p className="px-2 py-0 mx-2 text-xs text-center text-green-500 border border-green-500 rounded w-fit">
              New
            </p>
          )}
        </HStack>
        <div className="py-2">
          {data.data.excluded && (
            <span className="w-full px-2 py-1 mt-3 text-xs text-center text-red-500 border border-red-500 rounded-sm">
              Not Converting
            </span>
          )}
        </div>
      </div>
    </td>
  );
}

export function SecurityTypeRender(data: any) {
  return (
    <td className=" text-sm text-left py-2.5">
      <div className="">
        <p
          className={`${getSecurityColor(
            data.data.securityType
          )}  text-xs text-center w-24 align-middle font-semibold leading-5 rounded-sm mb-1`}
        >
          {data.data.securityType}
        </p>
      </div>
    </td>
  );
}

export function MethodRender(data: any) {
  return (
    <td className=" text-sm text-left py-2.5">
      <div className="">
        <p className={tableEntryStyle}>{data.data.method}</p>
      </div>
    </td>
  );
}

export function InvestmentRender(data: any) {
  return (
    <div className="pt-3 whitespace-nowrap">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.data?.investment,
          format: data.data.currencyType,
        })}
      </p>
    </div>
  );
}

export function ConversionRatioRender(data: any) {
  return (
    <div className="pt-3 whitespace-nowrap">
      <p className={tableEntryStyle}>
        {data.data?.conversionRatio ? `1 : ${data.data?.conversionRatio}` : "-"}
      </p>
    </div>
  );
}

export function PARValueRender(data: any) {
  return (
    <div className="pt-3 whitespace-nowrap">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.data?.parValue,
          format: data.data.currencyType,
        })}
      </p>
    </div>
  );
}

export function valuationCapRender(data: any) {
  return (
    <div className="pt-3 whitespace-nowrap">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.data?.valuationCap,
          format: data.data.currencyType,
        })}
      </p>
    </div>
  );
}

export function DiscountRender(data: any) {
  const navigate = useNavigate();
  const onClickNavigate = (url: string) => navigate(url);
  return (
    <div className="pt-2">
      {formatToReadableNumber({
        value: data.data?.discount,
        format: data.data.currencyType,
      })}
    </div>
  );
}

export function ConversionMethodRender(data: any) {
  return (
    <div className="pt-3 whitespace-nowrap">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.data?.currentValue,
          format: data.data.currencyType,
        })}
      </p>
    </div>
  );
}
