import { PencilIcon, PencilSquareIcon } from "@heroicons/react/24/solid";
import Tooltip from "../../components/shared/Tooltip";
import { HStack, VStack } from "../../components/utils";
import { ModelHeaders } from "../../types/Modeling";
import { getCurrency, getCurrencyType } from "../../utils/currencyFormatter";
import {
  FormatNumberSpan,
  getCurrencySymbolRoboto,
  getRoundCurrencySymbol,
} from "../../utils/currencyRoboto";
import { formatDisplayDate } from "../../utils/date";
import { limitString } from "../../utils/string";
import {
  ButtonSize,
  SecondaryCTAButton,
} from "../quickRound/CTAButtonComponents";
import { TransactionRoundInputModel } from "./RoundTransactionModel";
import DarkProgressBar from "../newModeling/DarkProgressBar";

const QuickRoundStack = ({
  currentCompanyDetail,
  moneyRaised,
  onEdit,
}: {
  currentCompanyDetail?: TransactionRoundInputModel;
  moneyRaised: number;
  onEdit?: any;
}) => {
  const symbol =
    getRoundCurrencySymbol(currentCompanyDetail?.roundCurrency || "INR - ₹") ||
    getCurrencySymbolRoboto();
  const currency = getCurrency(
    currentCompanyDetail?.roundCurrency || getCurrencyType()
  );

  return (
    <div className="relative px-4 transition duration-1000 ease-linear rounded-md shadow-sm bg-secondary placeholderLoader">
      <HStack className="gap-6">
        <VStack className="justify-between w-1/4 gap-2 px-2 py-3 rounded-md shadow-box">
          <HStack className="items-center justify-between h-6 gap-2">
            <p className="text-xs font-normal text-white whitespace-nowrap">
              Round Name:
            </p>
            <HStack className="items-baseline text-white">
              <Tooltip text={currentCompanyDetail?.roundName ?? ""}>
                <p className="text-base font-semibold whitespace-nowrap">
                  {limitString(currentCompanyDetail?.roundName ?? "", 16)}
                </p>
              </Tooltip>
            </HStack>
          </HStack>

          <HStack className="items-center justify-between gap-2">
            <p className="text-xs font-normal text-white whitespace-nowrap">
              Close Date:
            </p>
            <HStack className="items-baseline text-white">
              <p className="text-base font-semibold whitespace-nowrap">
                {formatDisplayDate(currentCompanyDetail?.closeDate)}
              </p>
            </HStack>
          </HStack>
        </VStack>
        <div className="bg-slate-400  w-0.5 h-10 my-auto"></div>
        <VStack className="justify-between w-1/4 gap-2 px-2 py-3 rounded-md shadow-box">
          <HStack className="items-center justify-between h-6 gap-2">
            <p className="text-xs font-normal text-white whitespace-nowrap">
              Round Size
            </p>
            <HStack className="items-baseline text-white">
              <span className="pr-1 text-xs font-normal">{symbol}</span>
              <p className="text-lg font-medium whitespace-nowrap">
                <FormatNumberSpan
                  value={currentCompanyDetail?.roundSize ?? 0}
                  format={currency}
                />
              </p>
            </HStack>
          </HStack>
          <HStack className="items-center justify-between gap-2">
            <p className="text-xs font-normal text-white whitespace-nowrap">
              {currentCompanyDetail?.pricedRound
                ? "Pre-Money Valuation"
                : "Base Valuation"}
            </p>
            <HStack className="items-baseline text-white">
              <span className="pr-1 text-xs font-normal">{symbol}</span>
              <p className="text-lg font-medium whitespace-nowrap">
                <FormatNumberSpan
                  value={currentCompanyDetail?.valuation ?? 0}
                  format={currency}
                />
              </p>
            </HStack>
          </HStack>
        </VStack>
        <div className="bg-slate-400  w-0.5 h-10 my-auto"></div>
        <VStack className="justify-between w-1/4 gap-2 px-2 py-3 rounded-md shadow-box">
          <HStack className="items-center justify-between h-6 gap-2 ">
            <p className="text-xs font-normal text-white whitespace-nowrap">
              Post-Esop:
            </p>
            <HStack className="items-baseline text-white ">
              {currentCompanyDetail?.pricedRound ? (
                <>
                  <p className="text-lg font-medium whitespace-nowrap">
                    <FormatNumberSpan
                      value={currentCompanyDetail?.postRoundEsop ?? 0}
                      format={currency}
                    />
                  </p>
                  <span className="pl-1 font-normal">%</span>
                </>
              ) : (
                <p className="text-lg font-medium whitespace-nowrap">N/A</p>
              )}
            </HStack>
          </HStack>
          <HStack className="items-center justify-between gap-2">
            <p className="text-xs font-normal text-white whitespace-nowrap">
              Dilution
            </p>
            <HStack className="items-baseline text-white">
              {currentCompanyDetail?.pricedRound ? (
                <>
                  <p className="text-lg font-medium whitespace-nowrap">
                    <FormatNumberSpan
                      value={
                        ((currentCompanyDetail?.roundSize ?? 0) /
                          ((currentCompanyDetail?.valuation ?? 0) +
                            (currentCompanyDetail?.roundSize ?? 0))) *
                          100 ?? 0
                      }
                      format={currency}
                    />
                  </p>
                  <span className="pl-1 font-normal">%</span>
                </>
              ) : (
                <p className="text-lg font-medium whitespace-nowrap">N/A</p>
              )}
            </HStack>
          </HStack>
        </VStack>
        <div className="bg-slate-400  w-0.5 h-10 my-auto"></div>
        <div className="w-1/4">
          <DarkProgressBar
            percentage={
              ((moneyRaised ?? 0) / (currentCompanyDetail?.roundSize ?? 0)) *
              100
            }
            raisedAmount={moneyRaised}
            leftToRaise={(currentCompanyDetail?.roundSize ?? 0) - moneyRaised}
            currency={currentCompanyDetail?.roundCurrency || "INR - ₹"}
          />
        </div>
      </HStack>
    </div>
  );
};

export default QuickRoundStack;
