import _ from "lodash";
import { HStack, VStack } from "../../components/utils";
import { limitString, _trimAll } from "../../utils/string";
import { CaptableTag } from "../../components/AllotmentTag";
import {
  getCurrencySymbol,
  getCurrencyType,
} from "../../utils/currencyFormatter";
import { GenericCumulativeHolding } from "../../types/GenericCaptableModel";
import { tableFirstColStyle } from "../../components/TableComponent";
import { ShareholderHoverChart } from "./ShareholderHoverChart";
import { getCurrencySymbolRoboto } from "../../utils/currencyRoboto";

export default function CaptableHoverCard({
  shareholder,
}: {
  shareholder: GenericCumulativeHolding;
}) {
  const currency = getCurrencyType();
  const currencySymbol = getCurrencySymbol();
  const symbol = getCurrencySymbolRoboto();
  return (
    <div className="relative group ">
      <p
        data-event-name="captableShareholderDrilling"
        className={tableFirstColStyle}
      >
        {limitString(
          shareholder.name?.toLowerCase() === "esop pool"
            ? "ESOP Pool"
            : shareholder.name || "",
          24
        )}
      </p>

      <div className="absolute flex-col items-center hidden mb-6 rounded-md group-hover:flex">
        <span className="relative z-10 p-5 text-xs leading-none bg-white shadow-box">
          <VStack className="gap-4">
            <HStack className="my-auto item-center justify-between">
              <p
                data-event-name="captableChartShareholderDrilling"
                className="my-auto text-lg whitespace-normal"
              >
                {shareholder.name?.toLowerCase() === "esop pool"
                  ? "ESOP Pool"
                  : shareholder.name || "Others"}
              </p>
              <div className="my-auto mt-1 item-center">
                <CaptableTag
                  tagName={shareholder.type ?? "Others"}
                  dataEventName={"shareholderHoverChart"}
                />
              </div>
            </HStack>
            <VStack className="gap-2">
              {shareholder?.fdbPercentage ? (
                <HStack>
                  <p className="font-medium text-blue-800">
                    Holding Percentage :
                  </p>
                  <p className="font-medium text-red-800">
                    {(shareholder.fdbPercentage * 100).toFixed(2)}%
                  </p>
                </HStack>
              ) : (
                <div></div>
              )}
              {shareholder?.investedAmount ? (
                <HStack>
                  <p className="font-medium text-blue-800">Investment : </p>
                  <p className="font-medium text-red-800">
                    {symbol}{" "}
                    {shareholder.investedAmount.toLocaleString(currency)}
                  </p>
                </HStack>
              ) : (
                <div></div>
              )}
              {shareholder?.shares ? (
                <HStack>
                  <p className="font-medium text-blue-800">Shares : </p>
                  <p className="font-medium text-red-800">
                    {shareholder.shares.toLocaleString(currency)}
                  </p>
                </HStack>
              ) : (
                <div></div>
              )}
            </VStack>
          </VStack>
          {shareholder.fdbPercentage ? (
            <ShareholderHoverChart
              chartModel={[
                {
                  name: shareholder.name || "",
                  value: shareholder.fdbPercentage,
                },
                {
                  name: "Others",
                  value: 1 - shareholder.fdbPercentage,
                },
              ]}
            />
          ) : (
            <div></div>
          )}
        </span>
      </div>
    </div>
  );
}
