import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { ChartRef, ColDef, GridReadyEvent } from "ag-grid-community";
import { agConfigDashboardSideBar } from "../dashboardPage/AgGridConfig";
import { formatDisplayDate } from "../../utils/date";
import { HStack, VStack } from "../../components/utils";
import SearchInput from "../../components/shared/SearchInput";
import { IconCTAButton } from "../quickRound/CTAButtonComponents";

export default function GridExample({ data }: { data: any }) {
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "500px", width: "100%" }), []);
  const [rowData, setRowData] = useState<any[]>();
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);
  const gridApi = useRef<any>(null);

  function isNumber(value: any) {
    return Number(value);
  }
  function isString(value: any) {
    return typeof value === "string";
  }
  function comparator(dateFromFilter: Date, cellValue: any) {
    if (cellValue == null) {
      return 0;
    }
    const dateParts = cellValue.split("-");
    const day = Number(dateParts[2]);
    const month = Number(dateParts[1]) - 1;
    const year = Number(dateParts[0]);
    const cellDate = new Date(year, month, day);
    if (cellDate < dateFromFilter) {
      return -1;
    } else if (cellDate > dateFromFilter) {
      return 1;
    }
    return 0;
  }

  useEffect(() => {
    const colDef = data[0].map((value: any, index: any) => {
      let filterType;

      if (isNumber(data[1][index])) {
        filterType = "agNumberColumnFilter";
      } else {
        filterType = "agMultiColumnFilter";
      }
      return filterType === "agDateColumnFilter"
        ? index === 0
          ? {
              field: value,
              width: 150,
              chartDataType: "category",
              filter: filterType,
              filterParams: {
                comparator,
              },
            }
          : {
              field: value,
              chartDataType: "series",
              filter: filterType,
              filterParams: {
                comparator,
              },
            }
        : index === 0
        ? {
            field: value,
            width: 150,
            chartDataType: "category",
            filter: filterType,
          }
        : {
            field: value,
            chartDataType: "series",
            filter: filterType,
          };
    });
    setColumnDefs(colDef);

    const convertedData = convertMedalData(data);
    setRowData(convertedData);
  }, [data]);
  const popupParent = useMemo<HTMLElement | null>(() => document.body, []);
  function convertMedalData(data: any) {
    const headers = data[0]; // Get the header row
    const result = [];

    // Iterate through the remaining rows (medal data)
    for (let i = 1; i < data.length; i++) {
      const row = data[i];
      const countryData: any = {};

      // Match headers with values for each country
      for (let j = 0; j < headers.length; j++) {
        countryData[headers[j]] = isNumber(row[j]) ? Number(row[j]) : row[j];
      }

      result.push(countryData);
    }

    return result;
  }

  const [isColumnOpen, setIsColumnOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      autoHeight: true,
      wrapHeaderText: true,
      suppressColumnVirtualisation: true,
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      filterParams: {
        buttons: ["reset"],
        maxNumConditions: 5,
      },
      filter: true,
      flex: 1,
      resizable: true,
    }),
    []
  );

  // Function for creating the chart container
  const createChartContainer = useCallback((chartRef: ChartRef): void => {
    const eChart = chartRef.chartElement;
    const themeName =
      document.documentElement?.getAttribute("data-default-theme") ||
      "ag-theme-material";
    const eParent = document.querySelector("#container") as HTMLElement;
    const chartWrapperHTML = `
    <div class="chart-wrapper ${themeName} sm:w-1/2 w-full px-8 py-4">
      <div class="chart-wrapper-top flex flex-row w-full justify-between bg-white round-t-lg py-2 px-4">
        <h2 class="chart-wrapper-title">Chart created ${formatDisplayDate(
          new Date()
        )}</h2>
        
        <button class="chart-wrapper-close">X</button>
      </div>
      <div class="chart-wrapper-body"></div>
    </div>
  `;
    eParent.insertAdjacentHTML("beforeend", chartWrapperHTML);
    const eChartWrapper = eParent.lastElementChild as HTMLElement;
    eChartWrapper.querySelector(".chart-wrapper-body")!.appendChild(eChart);
    eChartWrapper
      .querySelector(".chart-wrapper-close")!
      .addEventListener("click", () => {
        chartRef.destroyChart();
        eParent.removeChild(eChartWrapper);
      });
  }, []);

  const openToolPanel = (key: any) => {
    if (key === "columns") {
      if (gridApi) {
        if (!isColumnOpen) gridApi?.current?.api?.openToolPanel(key);
        else gridApi?.current?.api?.closeToolPanel();
        setIsColumnOpen((state) => !state);
        setIsFilterOpen(false);
      }
    } else if (key === "filters") {
      if (gridApi) {
        if (!isFilterOpen) gridApi?.current?.api?.openToolPanel(key);
        else gridApi?.current?.api?.closeToolPanel();
        setIsFilterOpen((state) => !state);
        setIsColumnOpen(false);
      }
    }
  };

  return (
    <VStack>
      <HStack className="justify-end px-8 pt-4 gap-4">
        <div className="w-72 ">
          <SearchInput
            className="h-10"
            placeholder={`Search`}
            onChange={(e: any) => {
              gridApi.current.api.setQuickFilter(e.target.value);
            }}
          />
        </div>
        <IconCTAButton
          value={"Columns"}
          onClick={() => openToolPanel("columns")}
          iconName={"fluent:column-triple-edit-20-regular"}
          className={`px-4 font-medium h-9 items-center flex flex-row ${
            isColumnOpen ? "text-orange-501" : "text-gray-400"
          }`}
          selected={isColumnOpen}
        />
        <IconCTAButton
          value={"Filters"}
          onClick={() => openToolPanel("filters")}
          iconName={"material-symbols:filter-alt"}
          className={`px-4 font-medium  h-9 items-center flex flex-row ${
            isFilterOpen ? "text-orange-501" : "text-gray-400"
          }`}
          selected={isFilterOpen}
        />
      </HStack>
      <div style={containerStyle} className="ag-theme-material">
        <div id="container" className="flex flex-wrap justify-between">
          <div style={gridStyle} className="px-8 py-4">
            <AgGridReact
              alwaysMultiSort
              onGridReady={(params) => {
                gridApi.current = params;
              }}
              animateRows={true}
              sideBar={agConfigDashboardSideBar}
              rowData={rowData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              enableRangeSelection={true}
              enableCharts={true}
              popupParent={popupParent}
              createChartContainer={createChartContainer}
            />
          </div>
        </div>
      </div>
    </VStack>
  );
}
