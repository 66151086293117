import _ from "lodash";
import { tableEntryStyle } from "../../components/TableComponent";
import { formatToReadableNumber } from "../../utils/currencyRoboto";
import { getSecurityColor } from "../../utils/historicUtilities";
import { limitString } from "../../utils/string";

export function AmountRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-2">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.data?.amount,
          format: data.data.currencyType,
        })}
      </p>
    </div>
  );
}

export function SecurityClassRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-2">
      <p
        className={`inline-flex px-2 ${getSecurityColor(
          data.data.security
        )} text-xs  font-semibold leading-5`}
      >
        {data.data.securityClass}
      </p>
    </div>
  );
}

export function NumberOfSharesRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-2">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.data?.numberOfShares,
          format: data.data.currencyType,
        })}
      </p>
    </div>
  );
}

export function NameRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-2">
      <p className={tableEntryStyle}>{limitString(data.data.name, 24)}</p>
    </div>
  );
}
