import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    mode: "index" as const,
    intersect: false,
  },
  categoryPercentage: 0.5,
  barPercentage: 0.9,
  borderRadius: 1,

  legend: {
    display: true,
  },
  plugins: {
    legend: {
      display: false,
      position: "top" as const,
    },
    title: {
      display: false,
      text: "Chart.js Bar Chart",
    },
  },
  elements: {
    bar: {
      borderWidth: 0,
      padding: 0,
    },
  },
  scales: {
    x: {
      display: true,
      stacked: true,
      grid: {
        display: false,
        offset: true,
      },
      ticks: {
        font: { size: 12 },
        padding: 10,
      },
    },
    y: {
      display: true,
      stacked: true,

      grid: {
        borderDash: [1, 3],
        color: "#e8e8e8",
      },
      ticks: {
        beginAtZero: false,
        min: 0,
        stepSize: 2,
        font: { size: 12 },
        callback(value: any, index: any, ticks: any) {
          return `${value}%`;
        },
      },
    },
  },
};

export function StackedBarChart({ chartData }: { chartData: any }) {
  const config = {
    type: "bar",
    data: chartData,
    options: {
      plugins: {
        title: {
          display: true,
          text: "Chart.js Bar Chart - Stacked",
        },
      },
      responsive: true,
      scales: {
        x: {
          stacked: true,
        },
        y: {
          stacked: true,
        },
      },
    },
  };
  return <Bar options={options} data={chartData} />;
}
