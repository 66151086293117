import { useState } from "react";
import { Dialog } from "@mui/material";
import { getDougnutTagColor, hexToRGBA } from "../utils/colorUtils";
import { HStack, VStack } from "./utils";
import { limitString } from "../utils/string";
import Tooltip from "./shared/Tooltip";
import HalfDoughnutChart from "../pages/shareholders/HalfDoughnutChart";
import { FormatNumberSpan } from "../utils/currencyRoboto";
import { getShareholderValue } from "../constants/ShareholderConstants";
import { ModelingListModel } from "../types/Modeling";
import { CTADropdown } from "./shared/Dropdown";
import { getCurrencySymbol } from "../utils/currencyFormatter";
import ProgressBar from "../pages/newModeling/ProgressBar";
import DeleteModal from "../pages/modeling/DeleteModal";

export default function ModelCardDesign({
  data,
  currencySymbol,
  currencyType,
  onClick,
}: Readonly<{
  data: ModelingListModel;
  onClick: () => void;
  currencySymbol: string;
  currencyType: string;
}>) {
  const [dialog, setDialog] = useState<{
    open: boolean;
    mode?: string;
    data?: string;
  }>({
    open: false,
  });

  const onDeleteFunction = (id: string) => {
    setDialog({
      open: true,
      mode: "Delete",
      data: id,
    });
  };

  return (
    <div className="sm:w-[46%] md:w-[31%] w-full pb-10">
      <Dialog open={dialog.open} maxWidth="lg">
        {dialog.mode === "Delete" && (
          <DeleteModal
            onPrimaryAction={() => {
              setDialog({ open: false });
            }}
            onSecondaryAction={() => setDialog({ open: false })}
            data={
              "Are you sure you want to delete the model? Data will be permanently removed. This action cannot be undone."
            }
          />
        )}
      </Dialog>

      <div
        className={`h-56  bg-gradient-to-b  border border-borderColor shadow-box rounded-lg hover:cursor-pointer flex flex-col bg-white`}
      >
        <VStack className="bg-gradient-to-b rounded-lg shadow-sm justify-between h-auto  bg-[#F2F2F2]/20">
          <HStack className="justify-between">
            <div onClick={onClick} className="w-full">
              <Tooltip text={data.modelName}>
                <p className="px-4 pt-4 text-base font-semibold">
                  {limitString(data.modelName, 17)}
                </p>
              </Tooltip>
            </div>
            <div className="px-4 pt-2 ">
              {/* <CTADropdown
                actions={[
                  {
                    name: "Delete",
                    disabled: false,
                  },
                ]}
                onAction={(action) => {
                  if (action.name === "Delete") {
                    onDeleteFunction(data.modelId);
                  }
                }}
              /> */}
            </div>
          </HStack>
          <HStack className="px-4 py-4 justify-between" onClick={onClick}>
            {[
              {
                name: "Round Size",
                value: data.tab1Details.roundSize,
                isMoney: true,
                isPercent: false,
              },
              {
                name: "Border",
                value: data.tab1Details.dilution,
                isMoney: false,
                isPercent: true,
              },
              {
                name: "Post Money",
                value: data.tab1Details.postMoney,
                isMoney: true,
                isPercent: false,
              },
              {
                name: "Border",
                value: data.tab1Details.dilution,
                isMoney: false,
                isPercent: true,
              },
              {
                name: "Dilution",
                value: data.tab1Details.dilution,
                isMoney: false,
                isPercent: true,
              },
            ].map((element) =>
              element.name === "Border" ? (
                <div
                  key={element.name}
                  className="bg-gray-500  w-[0.2px] h-10 "
                ></div>
              ) : (
                <VStack key={element.name} className="justify-start ">
                  <p className="text-gray-500 text-xs font-medium">
                    {element.name}

                    {element.isMoney && (
                      <span
                        className="pl-1"
                        style={{ fontFamily: "'Roboto', sans-serif" }}
                      >
                        {`(${currencySymbol})`}
                      </span>
                    )}

                    {element.isPercent && (
                      <span
                        className="pl-1"
                        style={{ fontFamily: "'Roboto', sans-serif" }}
                      >
                        {"%"}
                      </span>
                    )}
                  </p>
                  <p className="text-left gap-2 text-base justify-end font-semibold">
                    <FormatNumberSpan
                      value={element.value ?? 0}
                      format={currencyType}
                    />
                  </p>
                </VStack>
              )
            )}
          </HStack>
        </VStack>
        <VStack className="py-3 gap-2" onClick={onClick}>
          {[
            {
              key: "Pre-money ",
              isAmount: true,
              isPercent: false,
              value: data.tab1Details.preMoney,
            },
            {
              key: "Post ESOP percentage",
              isAmount: false,
              isPercent: true,
              value: data.tab1Details.postEsopPercentage,
            },
          ].map((element) => (
            <HStack key={element.key} className="px-4 justify-between text-xs">
              <p className="text-gray-500">
                {element.key}
                {element.isAmount && (
                  <span
                    className="pl-1"
                    style={{ fontFamily: "'Roboto', sans-serif" }}
                  >
                    ({currencySymbol})
                  </span>
                )}
                {element.isPercent && (
                  <span
                    className="pl-1"
                    style={{ fontFamily: "'Roboto', sans-serif" }}
                  >
                    ({"%"})
                  </span>
                )}
              </p>

              <p className="text-right gap-2  justify-end font-semibold">
                <FormatNumberSpan
                  value={element.value ?? 0}
                  format={currencyType}
                />
              </p>
            </HStack>
          ))}
          <ProgressBar
            percentage={
              (data.tab1Details.moneyRaised / data.tab1Details.roundSize) * 100
            }
            raisedAmount={data.tab1Details.moneyRaised}
            leftAmount={data.tab1Details.leftToRaise}
          />
        </VStack>
      </div>
    </div>
  );
}
