import { PlusIcon } from "@heroicons/react/20/solid";
import { useNavigate } from "react-router";
import { useCanUserAccess } from "../../store/useRoleGrantStore";
import BCHeader from "../../shared/BCHeader";

export default function EmptyRightsPage({
  isInvestorPortal = true,
}: {
  isInvestorPortal?: boolean;
}) {
  const canEditRights = useCanUserAccess("update", "rights");
  const navigate = useNavigate();
  return (
    <div className=" w-full mb-96 bg-transparent px-2 sm:px-4 lg:px-6 rounded pb-8 py-6">
      {isInvestorPortal && (
        <BCHeader
          className="items-baseline mb-2"
          bcTitle="Transaction Dashboard"
          bcSubTitle="Rights And Transfer Restrictions"
        />
      )}
      <div
        className={`text-center py-32 bg-white ${
          !isInvestorPortal ? "justify-center  " : ""
        }`}
      >
        <svg
          className="mx-auto h-12 w-12 text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            vectorEffect="non-scaling-stroke"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
          />
        </svg>
        <h3 className="mt-2 text-sm font-semibold text-gray-900">
          No Rights Found
        </h3>
        {isInvestorPortal && (
          <p className="mt-1 text-sm text-gray-500">
            Get started by uploading the rights.
          </p>
        )}
        {isInvestorPortal && canEditRights && (
          <div className="mt-6">
            <button
              type="button"
              event-name="Upload New Rights"
              onClick={() => {
                navigate("/rights/new");
              }}
              className="inline-flex items-center rounded-md bg-orange-501 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-501 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-501"
            >
              <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
              New Rights
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
