import { useEffect, useState } from "react";
import { Dialog } from "@mui/material";
import { toast } from "react-toastify";
import { Box, HStack, VStack } from "../../components/utils";
import { Label } from "../../components/shared/InputField";
import { ExistingInvestorDetail } from "./RoundCreationModel";
import InvestorTable from "./InvestorTable";
import {
  ButtonSize,
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../quickRound/CTAButtonComponents";
import AddNewInvestors from "./AddNewInvestors";
import { useRMCreationStore } from "../../store/roundCreationStore";
import { EmptyTable } from "../quickRound/EmptyTableComponents";

export default function ExistingInvestor() {
  const {
    roundCreation: data,
    sendMessage: onNext,
    reset: disCardRoundCreation,
    setRoundCreation: setRoundState,
  } = useRMCreationStore();
  const [dialog, setDialog] = useState<{
    open: boolean;
    mode?: "Add";
    data?: ExistingInvestorDetail;
  }>({
    open: false,
  });
  useEffect(() => {
    if (data.errors && data.errors.length > 0) {
      for (const error of data.errors) {
        toast(error, {
          type: "error",
          autoClose: 2000,
        });
      }
      setRoundState({
        ...data,
        errors: [],
      });
    }
  }, []);

  function scrollToTop() {
    const scroller = document.querySelector("#outlet");
    scroller?.scrollTo(0, 0);
  }

  return (
    <VStack className=" bg-white justify-between rounded-lg px-4 py-4 min-h-full">
      <Dialog open={dialog.open} maxWidth="lg">
        {dialog.mode === "Add" && (
          <AddNewInvestors
            onBack={() => {
              setDialog({ open: false });
            }}
            onNext={onNext}
          />
        )}
      </Dialog>
      <div>
        <Box className="border-b mb-4">
          <HStack className="justify-between">
            <VStack>
              <Label className="text-xl font-semibold text-gray-dark">
                Existing Investor
              </Label>

              <p className="font-medium text-gray-light">
                Fill in the Existing Investor Details
              </p>
            </VStack>
            <SecondaryCTAButton
              className="h-8"
              buttonSize={ButtonSize.SMALL}
              onClick={() => {
                setDialog({ open: true, mode: "Add" });
              }}
              disabled={
                data.headers?.leftToRaise !== undefined &&
                data.headers.leftToRaise <= 0
              }
            >
              + Add
            </SecondaryCTAButton>
          </HStack>
        </Box>

        <VStack className="">
          <InvestorTable
            captableData={data.tab3?.existingInvestors || []}
            onNext={onNext}
          />
        </VStack>
      </div>
      <HStack className="justify-between gap-4">
        <HStack className="gap-4">
          <SecondaryCTAButton
            type="reset"
            event-name="Exisiting Investor Back"
            buttonSize={ButtonSize.SMALL}
            onClick={() => {
              onNext({
                ...data,
                currentTab: data.tab2?.convertibleTransaction?.length ? 2 : 1,
                isNavigate: true,
              });
              scrollToTop();
            }}
          >
            Back
          </SecondaryCTAButton>
          <SecondaryCTAButton
            event-name="Exisiting Investor Discard"
            buttonSize={ButtonSize.SMALL}
            onClick={() => {
              disCardRoundCreation(data);
            }}
          >
            Discard
          </SecondaryCTAButton>
        </HStack>
        <PrimaryCTAButton
          event-name="Exisiting Investor Next"
          className="min-w-max"
          buttonSize={ButtonSize.SMALL}
          loading={data.isLoader}
          type="submit"
          onClick={() => {
            setRoundState({
              ...data,
              isLoader: true,
            });
            onNext({
              ...data,
              currentTab: 4,
              tab4: {
                newInvestors: data.tab4?.newInvestors ?? [],
              },
            });
            scrollToTop();
          }}
        >
          Next
        </PrimaryCTAButton>
      </HStack>
    </VStack>
  );
}
