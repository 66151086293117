import { QueryFunctionContext } from "react-query";
import api from "./capTableApi";
import { RoundModel, SubsidiaryCompanyDetails } from "../types/CapTable";
import {
  CumulativeHolding,
  DashBoardModel,
  ListOfNewInvestorModel,
} from "../types/DashboardModel";

export async function getEvents(
  id?: string,
  cinNumber?: string | null
): Promise<DashBoardModel[]> {
  return api
    .get("/v1/company/dashboard/events", {
      params: { companyId: id, cinNumber },
    })
    .then((res) => res.data.data);
}

export async function getAllRoundModelNames(
  context: QueryFunctionContext
): Promise<RoundModel[]> {
  const roundModelState = context.queryKey[1];
  return api
    .get("/v1/company/roundModel", {
      params: { state: roundModelState || null },
    })
    .then((res) => res.data.data);
}

export async function getRoundModelCaptable(
  context: QueryFunctionContext
): Promise<CumulativeHolding[]> {
  const modelId = context.queryKey[1];
  return api
    .get(`v1/company/roundModel/${modelId}/capTable`)
    .then((res) => res.data.data);
}

export async function getSubsidiaryCompanies(): Promise<
  SubsidiaryCompanyDetails[]
> {
  return api.get(`v1/company/subsidiary/company`).then((res) => res.data.data);
}

export async function getSubsidiaryCompanyCaptable(
  subsidiaryCompanyId: string
): Promise<CumulativeHolding[]> {
  return api
    .get(`v1/company/subsidiary/captable?companyId=${subsidiaryCompanyId}`)
    .then((res) => res.data);
}

export async function getListOfNewInvestorModels(
  context: QueryFunctionContext
): Promise<ListOfNewInvestorModel[]> {
  const investorId = context.queryKey[1];

  return api
    .get(`/v1/company/model/newInvestorModels`, {
      params: { cInvestorId: investorId || null },
    })
    .then((res) => res.data.data);
}
