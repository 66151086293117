import { toast } from "react-toastify";
import { useFormikContext, Formik, Form } from "formik";
import { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { Icon } from "@iconify/react";
import debounce from "lodash/debounce";
import { isEqual } from "lodash";
import { useNavigate } from "react-router";

import { HStack, VStack, Error } from "../../components/utils";
import { Input, Label } from "../../components/shared/InputField";
import {
  ButtonSize,
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../quickRound/CTAButtonComponents";
import { getCurrencyType } from "../../utils/currencyFormatter";
import {
  FormatNumberSpan,
  getCurrencySymbolRoboto,
} from "../../utils/currencyRoboto";
import { SwitchButton } from "../../components/shared/SwitchButton";
import {
  ModelBuilder,
  ModelState,
  RoundInputDetails,
} from "../../types/Modeling";
import RoundModelHeaderDetailCard from "./ModelHeaderCard";
import { useRoundModelStore } from "../../store/roundModelingStore";
import Tooltip from "../../components/shared/Tooltip";
import AlertMessage, { AlertType } from "./Alert";
import AlertTextMessage from "./AlertTextMessage";
import { ShowNumberInWords } from "../../components/shared/UiElement";
import { queryClient } from "../../queries";
import GenericTableHeader from "../../shared/TableHeader";

export default function RoundModelCreationComponent() {
  const currency = getCurrencyType();
  const symbol = getCurrencySymbolRoboto();

  const {
    roundCreation: data,
    sendMessage: onNext,
    setRoundCreation: setRoundState,
    setCalculateMode,
    calculateMode,
    saveModel,
    connect,
  } = useRoundModelStore();

  const navigate = useNavigate();

  const [isPremoneyLocked, setIsPremoneyLocked] = useState(false);
  const [preMoneyValuation, setPreMoneyValuation] = useState(
    data.roundDetailsTab?.preMoneyValuation?.toString() || ""
  );

  const [pps, setPps] = useState(
    data.roundDetailsTab?.fdbSharePrice?.toString() || ""
  );

  const [onHoverRoundSize, setOnHoverRoundSize] = useState(false);

  const [onHoverPreMoney, setOnHoverPreMoney] = useState(false);

  const [onHoverFDBSharePrice, setOnHoverFDBSharePrice] = useState(false);

  const debouncedSubmit = debounce((values) => {
    onNext({
      ...data,
      roundDetailsTab: {
        ...data.roundDetailsTab,
        roundName: values.roundName || "",
        roundIdentifier: values.roundIdentifier || "",
        pricedRound: true,
        autoDiluteEsop: values.autoDiluteEsop ?? false,
        roundSize: values.roundSize || 0,
        preMoneyValuation: isPremoneyLocked
          ? data.roundDetailsTab?.preMoneyValuation
          : values.preMoneyValuation,
        fdbSharePrice: !isPremoneyLocked
          ? data.onGoingRoundHeaders?.fdbSharePrice
          : values.pps,
        postRoundEsopPercentage: values.postRoundEsop,
        hasToCalculatePps: !isPremoneyLocked,
        hasToRecalculateOtherTabs: false,
      },
    });
  }, 10);

  const AutoSubmitToken = () => {
    // Grab values and submitForm from context
    const { values, submitForm } = useFormikContext<RoundInputDetails>();
    const prevValuesRef = useRef(values);
    useEffect(() => {
      // Call the debounced submit function
      if (!isEqual(prevValuesRef.current, values)) {
        debouncedSubmit(values);
      }

      // Cleanup function to cancel the debounced call if the component unmounts
      return () => debouncedSubmit.cancel();
    }, [values]);
    return null;
  };

  const initialRoundInputDetail = {
    roundName: data.roundDetailsTab?.roundName || "",
    roundIdentifier: data.roundDetailsTab?.roundIdentifier || "",
    pricedRound: data.roundDetailsTab?.pricedRound ?? true,
    autoDiluteEsop: data.roundDetailsTab?.autoDiluteEsop ?? true,
    postRoundEsop: data.roundDetailsTab?.postRoundEsopPercentage || 0,
    roundSize: data.roundDetailsTab?.roundSize || 0,
    preMoneyValuation: data.roundDetailsTab?.preMoneyValuation || 0,
    pps: data.roundDetailsTab?.fdbSharePrice || 0,
    hasToCalcuatePps: data.roundDetailsTab?.hasToCalculatePps || true,
    postMoneyValuation:
      (data.roundDetailsTab?.preMoneyValuation || 0) +
      (data.roundDetailsTab?.roundSize || 0),
    dilution:
      ((data.roundDetailsTab?.roundSize || 0) /
        ((data.roundDetailsTab?.preMoneyValuation || 0) +
          (data.roundDetailsTab?.roundSize || 0))) *
      100,
  };
  const [isPricedRound, setIsPricedRound] = useState(
    data.roundDetailsTab?.pricedRound ?? true
  );

  const roundDetailsValidationSchema = Yup.object({
    roundName: Yup.string().required().label("Round Name"),
    roundIdentifier: Yup.string().required().label("Round Identifier"),
    pricedRound: Yup.boolean().required().label("pricedRound Type"),
    autoDiluteEsop: Yup.string().required().label("AutoDiluteEsop"),
    roundSize: Yup.number().min(1).required().label("Round Size"),
    postRoundEsop: Yup.number().max(95).label("Post Round ESOP"),
    preMoneyValuation:
      (isPremoneyLocked
        ? data.roundDetailsTab?.preMoneyValuation ?? 0
        : Number(preMoneyValuation) ?? 0) > 0
        ? Yup.number().label("Valuation")
        : Yup.number().min(1).label("Valuation"),
    hasToCalcuatePps: Yup.boolean().required().label("hasToCalcuatePps"),
    pps:
      (!isPremoneyLocked
        ? data.roundDetailsTab?.fdbSharePrice ?? 0
        : Number(pps) ?? 0) > 0
        ? Yup.number().required().label("PPS")
        : Yup.number().moreThan(0).required().label("PPS"),
  });

  useEffect(() => {
    if (data.errorMessage) {
      toast(data.errorMessage, {
        type: "error",
        autoClose: 2000,
      });
    }
    setRoundState({
      ...data,
      errorMessage: "",
    });
  }, []);

  useEffect(() => {
    if (preMoneyValuation || pps)
      onNext({
        ...data,
        roundDetailsTab: {
          ...data.roundDetailsTab,
          preMoneyValuation: Number(preMoneyValuation),
          fdbSharePrice: Number(pps),
          hasToCalculatePps: !isPremoneyLocked,
        },
      });
  }, [preMoneyValuation, pps]);

  function scrollToTop() {
    const scroller = document.querySelector("#outlet");
    scroller?.scrollTo(0, 0);
  }

  const updateLocalStates = (currentLockStatus: boolean) => {
    if (currentLockStatus) {
      setPreMoneyValuation(
        data.roundDetailsTab?.preMoneyValuation?.toString() || ""
      );
    } else {
      setPps(data.roundDetailsTab?.fdbSharePrice?.toString() || "");
    }
  };
  const currencyType = getCurrencyType();

  const checkForChange = ({
    fdbSharePrice,
    postRoundEsopPercentage,
    preMoneyValuation,
    roundSize,
  }: {
    fdbSharePrice: number;
    postRoundEsopPercentage: number;
    preMoneyValuation: number;
    roundSize: number;
  }) =>
    calculateMode.fdbSharePrice !== fdbSharePrice ||
    calculateMode.postRoundEsopPercentage !== postRoundEsopPercentage ||
    calculateMode.preMoneyValuation !== preMoneyValuation ||
    calculateMode.roundSize !== roundSize;
  return (
    <>
      <div className="py-4 bg-white rounded-lg ">
        <GenericTableHeader
          heading={" Round Details"}
          subHeading={"Fill in the Round Details"}
        />
      </div>
      <VStack>
        <div className="px-4 py-4 bg-white border border-borderColor shadow-box">
          <div className="border-b border-solid border-[#EBEBEB] mb-8"></div>
          {data.preCaptableHeaders && (
            <RoundModelHeaderDetailCard
              currentCompanyDetail={data.preCaptableHeaders}
            />
          )}

          <VStack className="justify-between">
            <Formik
              key="addEditRoundCreation"
              initialValues={initialRoundInputDetail}
              validationSchema={roundDetailsValidationSchema}
              onSubmit={(values) => {
                setRoundState({
                  ...data,
                  isNavigating: true,
                });
                const inputValues: RoundInputDetails = {
                  roundIdentifier: values.roundIdentifier || "",
                  roundName: values.roundName || "",
                  pricedRound: values.pricedRound,
                  autoDiluteEsop: values.autoDiluteEsop ?? false,
                  postRoundEsopPercentage: values.postRoundEsop || 0,
                  roundSize: values.roundSize || 0,
                  preMoneyValuation: isPremoneyLocked
                    ? data.roundDetailsTab?.preMoneyValuation
                    : values.preMoneyValuation,
                  postMoneyValuation:
                    values.preMoneyValuation + values.roundSize || 0,
                  dilution:
                    (values.roundSize /
                      (values.preMoneyValuation + values.roundSize)) *
                      100 || 0,
                  fdbSharePrice: !isPremoneyLocked
                    ? data.onGoingRoundHeaders?.fdbSharePrice
                    : values.pps,
                  hasToCalculatePps: !isPremoneyLocked,
                  hasToRecalculateOtherTabs:
                    checkForChange({
                      fdbSharePrice: !isPremoneyLocked
                        ? data.onGoingRoundHeaders?.fdbSharePrice ?? 0
                        : values.pps,
                      postRoundEsopPercentage: values.postRoundEsop || 0,
                      preMoneyValuation: values.preMoneyValuation || 0,
                      roundSize: values.roundSize || 0,
                    }) ?? false,
                  postDiluatedEsopHoldingPercentage:
                    data?.roundDetailsTab?.postDiluatedEsopHoldingPercentage ??
                    0,
                };
                onNext({
                  ...data,
                  currentTab:
                    data?.convertibles?.convertibles.length! > 0 ? 2 : 3,
                  roundDetailsTab: inputValues,
                } as ModelBuilder);
                setCalculateMode({
                  fdbSharePrice: data.roundDetailsTab?.fdbSharePrice ?? 0,
                  postRoundEsopPercentage:
                    data.roundDetailsTab?.postRoundEsopPercentage ?? 0,
                  preMoneyValuation:
                    data.roundDetailsTab?.preMoneyValuation ?? 0,
                  roundSize: data.roundDetailsTab?.roundSize ?? 0,
                });
                queryClient.invalidateQueries("listofmodeling");
                scrollToTop();
              }}
            >
              {(formik) => (
                <Form key="addEditRoundCreation">
                  <HStack className="items-center flex-1 mb-8">
                    <Label className="w-48 mr-16 text-sm font-medium whitespace-nowrap">
                      Round Name
                    </Label>
                    <VStack>
                      <Input
                        className="w-64 mr-16 text-sm font-medium whitespace-nowrap"
                        type="text"
                        placeholder="Enter the round name"
                        {...formik.getFieldProps("roundName")}
                      />

                      {formik.touched?.roundName &&
                        formik.errors?.roundName && (
                          <Error text={formik.errors?.roundName} />
                        )}
                    </VStack>
                  </HStack>
                  <HStack className="items-center flex-1 mb-8">
                    <Label className="w-48 mr-16 text-sm font-medium whitespace-nowrap">
                      Round Identifier
                    </Label>
                    <VStack>
                      <Input
                        className="w-64 mr-16 text-sm font-medium whitespace-nowrap"
                        type="text"
                        placeholder="Enter the round identifier"
                        {...formik.getFieldProps("roundIdentifier")}
                      />

                      {formik.touched?.roundIdentifier &&
                        formik.errors?.roundIdentifier && (
                          <Error text={formik.errors?.roundIdentifier} />
                        )}
                    </VStack>
                  </HStack>

                  <HStack className="items-center mb-8">
                    <HStack className="items-center flex-1">
                      <Label className="w-40 mr-16 text-sm font-medium whitespace-nowrap">
                        Auto Dilute ESOP (%)
                      </Label>
                      <SwitchButton
                        className="ml-8 text-sm font-medium"
                        label=""
                        value={formik.values.autoDiluteEsop ?? false}
                        onClick={() => {
                          formik.setFieldValue(
                            "autoDiluteEsop",
                            !formik.getFieldProps("autoDiluteEsop").value
                          );
                        }}
                      />

                      {formik.touched?.autoDiluteEsop &&
                        formik.errors?.autoDiluteEsop && (
                          <Error text={formik.errors?.autoDiluteEsop} />
                        )}
                    </HStack>
                    {!formik.values.autoDiluteEsop && (
                      <HStack className="items-center flex-1">
                        <Label className="w-48 mr-16 text-sm font-medium whitespace-nowrap">
                          Post Round ESOP (&#37;)
                        </Label>

                        <VStack>
                          <Input
                            className="w-64 mr-16 text-sm font-medium whitespace-nowrap"
                            type="number"
                            readOnly={formik.values.autoDiluteEsop}
                            placeholder="Enter the post round ESOP &#37;"
                            {...formik.getFieldProps("postRoundEsop")}
                          />
                          {formik.touched?.postRoundEsop &&
                            formik.errors?.postRoundEsop && (
                              <Error text={formik.errors?.postRoundEsop} />
                            )}
                        </VStack>
                      </HStack>
                    )}
                  </HStack>
                  <HStack className="items-center flex-1 mb-8">
                    <Label className="w-48 mr-16 text-sm font-medium whitespace-nowrap">
                      Round Size ({symbol})
                    </Label>
                    <VStack>
                      <Input
                        onMouseEnter={() => {
                          setOnHoverRoundSize(true);
                        }}
                        onMouseLeave={() => {
                          setOnHoverRoundSize(false);
                        }}
                        className="w-64 mr-16 text-sm font-medium whitespace-nowrap"
                        type="text"
                        placeholder="Enter the round size"
                        {...formik.getFieldProps("roundSize")}
                        onChange={(e: any) => {
                          const values = parseInt(
                            e.target.value?.replaceAll(",", "") || 0,
                            10
                          );
                          formik.setFieldValue("roundSize", values);
                          formik.handleChange("roundSize");
                        }}
                        value={Intl.NumberFormat(currencyType).format(
                          formik.values.roundSize
                        )}
                      />
                      {onHoverRoundSize && (
                        <ShowNumberInWords
                          value={formik.values.roundSize}
                          currency={currencyType}
                        />
                      )}
                      <p className="hidden text-xxs1">
                        The current round is in (INR), click{" "}
                        <span className="text-[#E85936] text-xxs1 cursor-pointer">
                          here
                        </span>{" "}
                        to change the currency
                      </p>
                      {formik.touched?.roundSize &&
                        formik.errors?.roundSize && (
                          <Error text={formik.errors?.roundSize} />
                        )}
                    </VStack>
                  </HStack>

                  <HStack className="items-center flex-1 mb-8 ">
                    <Label className="w-48 mr-16 text-sm font-medium whitespace-nowrap">
                      {isPricedRound ? "Pre-money Valuation" : "Base Valuation"}{" "}
                      ({symbol})
                    </Label>

                    <VStack>
                      <Input
                        onMouseEnter={() => {
                          setOnHoverPreMoney(true);
                        }}
                        onMouseLeave={() => {
                          setOnHoverPreMoney(false);
                        }}
                        className="w-64 mr-2 text-sm font-medium whitespace-nowrap"
                        type="text"
                        //Dirty commit disabled={isPremoneyLocked}
                        placeholder={
                          isPricedRound
                            ? "Enter Pre-money Valuation"
                            : "Enter Base Valuation"
                        }
                        onFocus={() => {
                          setIsPremoneyLocked(false);
                          formik.setFieldValue(
                            "preMoneyValuation",
                            data.roundDetailsTab?.preMoneyValuation
                          );
                          setPreMoneyValuation(
                            `${data.roundDetailsTab?.preMoneyValuation}`
                          );
                        }}
                        value={Intl.NumberFormat(currencyType).format(
                          isPremoneyLocked
                            ? data.roundDetailsTab?.preMoneyValuation ?? 0
                            : Number(preMoneyValuation)
                        )}
                        onChange={(e) => {
                          const values = parseInt(
                            e.target.value?.replaceAll(",", ""),
                            10
                          );
                          const newValue = Number.isNaN(values)
                            ? undefined
                            : values;
                          formik.setFieldValue(
                            "preMoneyValuation",
                            newValue ?? 0
                          );
                          formik.handleChange("preMoneyValuation");
                          setPreMoneyValuation(`${newValue ?? 0}`);
                        }}
                      />
                      {onHoverPreMoney && (
                        <ShowNumberInWords
                          value={
                            isPremoneyLocked
                              ? data.roundDetailsTab?.preMoneyValuation ?? 0
                              : Number(preMoneyValuation)
                          }
                          currency={currencyType}
                        />
                      )}
                      {isPremoneyLocked && (
                        <AlertTextMessage
                          heading="auto calculated based on the entered FDB Share Price"
                          type={AlertType.INSIGHT}
                        />
                      )}
                      {formik.touched?.preMoneyValuation &&
                        formik.errors?.preMoneyValuation && (
                          <Error text={formik.errors?.preMoneyValuation} />
                        )}
                    </VStack>
                  </HStack>
                  <HStack className="items-center flex-1 mb-8 ">
                    <Label className="w-48 mr-16 text-sm font-medium whitespace-nowrap">
                      {"FDB Share Price"} ({symbol})
                    </Label>
                    <VStack>
                      <Input
                        onMouseEnter={() => {
                          setOnHoverFDBSharePrice(true);
                        }}
                        onMouseLeave={() => {
                          setOnHoverFDBSharePrice(false);
                        }}
                        className="w-64 mr-2 text-sm font-medium whitespace-nowrap"
                        type="text"
                        onFocus={() => {
                          setIsPremoneyLocked(true);
                          setPps(`${data.roundDetailsTab?.fdbSharePrice}`);
                          formik.setFieldValue(
                            "pps",
                            data.roundDetailsTab?.fdbSharePrice
                          );
                        }}
                        readOnly={!isPremoneyLocked}
                        placeholder={"Enter FDB Share Price"}
                        // disabled={!isPremoneyLocked}
                        value={Intl.NumberFormat(currencyType).format(
                          !isPremoneyLocked
                            ? data.roundDetailsTab?.fdbSharePrice ?? 0
                            : Number(pps)
                        )}
                        onChange={(e) => {
                          const values = parseInt(
                            e.target.value?.replaceAll(",", ""),
                            10
                          );
                          const newValue = Number.isNaN(values)
                            ? undefined
                            : values;
                          formik.setFieldValue("pps", Number(newValue ?? 0));
                          setPps(`${newValue ?? 0}`);
                        }}
                      />
                      {onHoverFDBSharePrice && (
                        <ShowNumberInWords
                          value={
                            !isPremoneyLocked
                              ? data.roundDetailsTab?.fdbSharePrice ?? 0
                              : Number(pps)
                          }
                          currency={currencyType}
                        />
                      )}
                      {!isPremoneyLocked && (
                        <AlertTextMessage
                          heading="auto calculated based on the entered pre-money"
                          type={AlertType.INSIGHT}
                        />
                      )}
                      {formik.touched?.pps && formik.errors?.pps && (
                        <Error text={formik.errors?.pps} />
                      )}
                    </VStack>
                  </HStack>

                  <HStack className="justify-start mb-4">
                    <HStack className="items-center flex-1 mb-8">
                      <Label className="w-48 text-sm font-medium whitespace-nowrap">
                        Post-Money Valuation ({symbol}):
                      </Label>

                      <Label className="ml-6 mr-16 text-sm font-medium text-orange-500 whitespace-nowrap">
                        <FormatNumberSpan
                          value={Number(
                            data.onGoingRoundHeaders?.postMoneyValuation ?? 0
                          )}
                          format={currency}
                        />
                      </Label>
                    </HStack>
                    <HStack className="items-center flex-1 gap-2 mb-8">
                      <Label className="text-sm font-medium whitespace-nowrap">
                        Dilution (&#37;):
                      </Label>
                      <Label className="ml-2 text-sm font-medium text-orange-500 whitespace-nowrap">
                        {
                          <FormatNumberSpan
                            value={Number(
                              ((data.onGoingRoundHeaders?.roundSize || 0) /
                                ((data.onGoingRoundHeaders?.valuation || 0) +
                                  (data.onGoingRoundHeaders?.roundSize || 0))) *
                                100 || 0.0
                            )}
                            format={currency}
                          />
                        }
                      </Label>
                      <p>%</p>
                      {checkForChange({
                        fdbSharePrice: !isPremoneyLocked
                          ? data.onGoingRoundHeaders?.fdbSharePrice ?? 0
                          : formik.values.pps,
                        postRoundEsopPercentage:
                          formik.values.postRoundEsop || 0,
                        preMoneyValuation: formik.values.preMoneyValuation || 0,
                        roundSize: formik.values.roundSize || 0,
                      }) && (data.highestVisitedTabLevel ?? 0) > 2 ? (
                        <AlertMessage
                          heading="Minor Changes"
                          data="Minor changes has been made for the entered transaction due to the change in either round size, pre-money, fdb share price or esop percentage."
                        />
                      ) : (
                        <div></div>
                      )}{" "}
                    </HStack>
                  </HStack>
                  <HStack className="items-end justify-between">
                    <div></div>
                  </HStack>
                  <HStack className="items-end justify-between">
                    <div>
                      <SecondaryCTAButton
                        buttonSize={ButtonSize.SMALL}
                        onClick={() => {
                          navigate("/modeling/overview");
                        }}
                      >
                        Back
                      </SecondaryCTAButton>
                    </div>
                    <HStack className="justify-end">
                      <div className="pr-4">
                        <PrimaryCTAButton
                          className="h-8"
                          event-name="Save Model Button"
                          onClick={() => {
                            connect();
                            saveModel(data);

                            toast(
                              `Saved ${data?.roundDetailsTab?.roundName} round model successfully`,
                              {
                                type: "success",
                                autoClose: 2000,
                              }
                            );
                            queryClient.invalidateQueries("listofmodeling");
                          }}
                          buttonSize={ButtonSize.SMALL}
                        >
                          Save Model
                        </PrimaryCTAButton>
                      </div>
                      <VStack className="items-end gap-4">
                        <PrimaryCTAButton
                          event-name="Round Details Next"
                          loading={data.isNavigating}
                          buttonSize={ButtonSize.SMALL}
                          type="submit"
                          className="h-8 w-fit"
                        >
                          Next
                        </PrimaryCTAButton>
                      </VStack>
                    </HStack>
                  </HStack>
                  <AutoSubmitToken />
                </Form>
              )}
            </Formik>
          </VStack>
        </div>
      </VStack>
    </>
  );
}
