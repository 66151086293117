import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { Input, Label } from "../components/shared/InputField";
import { Select } from "../components/shared/Select";
import SortingComponent from "../components/SortingVariation";
import { HStack, VStack, Error, Box } from "../components/utils";
import {
  CapTableBarChart,
  ShareHolderCaptableChartModel,
} from "../pages/newQuickTransaction/CapTableBarChart";
import { ShareHolderCapTableQuickRound } from "../pages/newQuickTransaction/ShareHolderCapTableQuickRound";
import { useGetQuickRoundPreview } from "../queries/quickRound";
import {
  useGetSecurityRedemption,
  useUpdateSecurityRedemptionDetails,
} from "../queries/securities";
import { useAuthStore } from "../store";
import {
  RedemptionDetails,
  SecurityTableDetail,
  ShareholderRedemptionSubmitDetails,
} from "../types/SecurityModel";
import { SecurityRedemptionModel } from "../types/Shareholder";
import { getCurrencySymbol, getCurrencyType } from "../utils/currencyFormatter";
import { formatDisplayDate } from "../utils/date";
import RoundIdentifiers from "../shared/RoundIdentifer";
import { useApproveTransactionPreview } from "../queries/transactionRound";
import {
  ButtonSize,
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../pages/quickRound/CTAButtonComponents";

type ShareholderRedemption = {
  shareholderId: string;
  name: string;
  redemptionDate: string;
  redeemedAmount: number;
  amountLeftToRedeem: number;
};

export function AddSecurityRedemption({
  securityDetails,
  onPrimaryAction = () => {},
  onSecondaryAction = () => {},
}: {
  securityDetails: SecurityTableDetail;
  onPrimaryAction: () => void;
  onSecondaryAction: () => void;
}) {
  const [isApprove, setIsApprove] = useState(false);

  const companyId = useAuthStore.getState().companyId || "";
  const currencySymbol = getCurrencySymbol();
  const currencyType = getCurrencyType();

  const [eventId, setEventId] = useState<string>();

  const { mutate: updateRedemption, data: redemptionDetails } =
    useUpdateSecurityRedemptionDetails();

  const { refetch: getQuickRoundPreview, data: previewData } =
    useGetQuickRoundPreview(eventId || "");

  const { mutate: approveShareholderRedemption } =
    useApproveTransactionPreview();

  const [showChart, setShowChart] = useState(false);
  const [roundShares, setRoundShare] = useState<number>(0);
  const [chartData, setChartData] = useState<ShareHolderCaptableChartModel[]>(
    []
  );

  const handleSaveAndSubmit = () => {
    const submitValues: ShareholderRedemptionSubmitDetails = {
      companyId,
      securityClassId: securityDetails.id,
      shareholderRedemptions: shareholderRedemptions.map((redemption) => ({
        shareholderId: redemption.shareholderId,
        redeemedAmount: redemption.redeemedAmount,
        redemptionDate: redemption.redemptionDate,
      })),
    };
    setIsApprove(true);
    updateRedemption(submitValues, {
      onSuccess: async (data) => {
        setEventId(data.id);
        setIsApprove(true);
        toast("Redemption Details Saved Successfully!", {
          type: "success",
          autoClose: 2000,
        });
      },

      onError: (err: any) => {
        toast(err.response.data.reason, {
          type: "error",
          autoClose: 2000,
        });
      },
    });
  };

  const handleUpdateTable = (values: ShareholderRedemption) => {
    const updatedRedemptions = [...shareholderRedemptions];
    const redemption = updatedRedemptions.find(
      (shareholder) => shareholder.name === values.name
    );
    if (redemption) {
      redemption.redemptionDate = values.redemptionDate;
      redemption.redeemedAmount = values.redeemedAmount;
      redemption.amountLeftToRedeem = amountLeftToRedeem;
    }
    setShareholderRedemptions(updatedRedemptions);
  };

  useEffect(() => {
    getQuickRoundPreview();
  }, [redemptionDetails]);

  const { data: securityRedemptionDetails } = useGetSecurityRedemption(
    securityDetails.id || ""
  );

  const [amountLeftToRedeem, setAmountLeftToRedeem] = useState(
    (securityRedemptionDetails?.investmentAmount || 0) -
      (securityRedemptionDetails?.redeemedAmount || 0)
  );

  const [interest, setInterest] = useState(0);

  const getInterest = (
    redemption: SecurityRedemptionModel | RedemptionDetails | undefined,
    amount: number
  ) => {
    if (!redemption) return 0;
    const interestType = redemption.interestType;
    const n = redemption.interestPeriod;
    if (interestType === "Simple")
      return (redemption.interestRate * redemption.interestTime * amount) / 100;
    return (
      amount *
        (1 + redemption.interestRate / n) ** (n * redemption.interestTime) -
      amount
    );
  };

  const handleSubmit = () => {};

  const initialRedemptionDetails = {
    shareholderId: "",
    name: "",
    redemptionDate: "",
    redeemedAmount: 0,
    amountLeftToRedeem: 0,
  };

  const [sortField, setSortField] = useState<{
    field: keyof ShareholderRedemption;
    ascending: boolean;
  }>();

  const [shareholderRedemptions, setShareholderRedemptions] = useState<
    ShareholderRedemption[]
  >([]);

  useEffect(() => {
    if (
      securityRedemptionDetails?.shareholderRedemptionDetails &&
      shareholderRedemptions.length === 0
    )
      setShareholderRedemptions(
        securityRedemptionDetails.shareholderRedemptionDetails.map(
          (redemption) =>
            ({
              ...redemption,
              redemptionDate: "",
              redeemedAmount: 0,
            } as ShareholderRedemption)
        )
      );
  }, [securityRedemptionDetails]);

  const navigate = useNavigate();
  const onClickNavigate = (url: string) => navigate(url);
  return (
    <div className="w-full bg-white rounded-md border-dashed border-2">
      <VStack className="text-left font-medium">
        <VStack className="text-lg font-medium border-b p-5">
          <HStack className="justify-between">
            <p className="font-semibold text-lg mb-3">Redemption</p>
            <span
              onClick={() => onSecondaryAction()}
              className="cursor-pointer"
            >
              X
            </span>
          </HStack>
          <HStack className="justify-start font-medium text-base">
            <p className="mr-3">({securityDetails?.type})</p>
            <p className="mr-3">
              Rounds -{" "}
              <RoundIdentifiers
                roundNames={securityDetails.roundIdentifierDetails || []}
                navigate={onClickNavigate}
              />
            </p>
            <p>Security - {securityDetails?.type}</p>
          </HStack>
        </VStack>
        {securityRedemptionDetails && (
          <AddRedemptionCardDetails
            securityRedemption={securityRedemptionDetails}
            currencyType={currencyType}
          />
        )}
        {!isApprove ? (
          <div>
            <Formik
              key="addShareholderRedemption"
              enableReinitialize={true}
              initialValues={initialRedemptionDetails}
              onSubmit={(values) => {
                handleUpdateTable(values);
              }}
            >
              {(formik) => (
                <Form key="addShareholderRedemption">
                  <VStack className="w-full p-8 gap-6">
                    <HStack className="gap-5 py-3">
                      <div className="w-2/7 mr-4">
                        <Label className="text-sm font-medium">
                          Shareholder
                        </Label>
                        <Select
                          options={shareholderRedemptions.map(
                            (shareholder) => shareholder.name
                          )}
                          placeholder="Select Shareholder"
                          className="w-80"
                          {...formik.getFieldProps("name")}
                          onChange={(e) => {
                            const shareholder = shareholderRedemptions.find(
                              (shareholder) =>
                                shareholder.name === e.target.value
                            );
                            if (shareholder) {
                              formik.setFieldValue(
                                "redemptionDate",
                                shareholder.redemptionDate
                              );
                              formik.setFieldValue(
                                "redeemedAmount",
                                shareholder.redeemedAmount
                              );
                            }
                            formik.handleChange(e);
                          }}
                        />
                        {formik.touched?.name && formik.errors?.name && (
                          <Error text={formik.errors?.name} />
                        )}
                      </div>
                      <div className="w-2/7 mr-4">
                        <Label className="text-sm font-medium">
                          Redemption Date
                        </Label>
                        <Input
                          type="date"
                          placeholder="Eg:DD/MM/YYYY"
                          className="w-45"
                          {...formik.getFieldProps("redemptionDate")}
                        />
                        {formik.touched?.redemptionDate &&
                          formik.errors?.redemptionDate && (
                            <Error text={formik.errors?.redemptionDate} />
                          )}
                      </div>
                      <div className="w-3/7">
                        <HStack className="justify-between">
                          <Label className="text-sm font-medium whitespace-nowrap mr-2">
                            Amount To Be Redeemed
                          </Label>
                          <Label className="text-xxs text-green-500">
                            {currencySymbol} {amountLeftToRedeem} Left
                          </Label>
                        </HStack>
                        <Input
                          type="number"
                          placeholder="Enter Shares to Redeem"
                          className="w-full"
                          {...formik.getFieldProps("redeemedAmount")}
                          onChange={(e) => {
                            formik.handleChange(e);
                            if (e.target.value) {
                              setAmountLeftToRedeem(
                                (securityRedemptionDetails?.investmentAmount ||
                                  0) -
                                  (securityRedemptionDetails?.redeemedAmount ||
                                    0) -
                                  Number(e.target.value)
                              );
                              setInterest(
                                getInterest(
                                  securityRedemptionDetails,
                                  Number(e.target.value)
                                )
                              );
                            } else {
                              setAmountLeftToRedeem(
                                (securityRedemptionDetails?.investmentAmount ||
                                  0) -
                                  (securityRedemptionDetails?.redeemedAmount ||
                                    0)
                              );
                              setInterest(0);
                            }
                          }}
                        />
                        {formik.touched?.redeemedAmount &&
                          formik.errors?.redeemedAmount && (
                            <Error text={formik.errors?.redeemedAmount} />
                          )}
                      </div>
                    </HStack>
                    <div className="w-full justify-end">
                      <PrimaryCTAButton
                        event-name="Update Redemption Table Modal"
                        buttonSize={ButtonSize.SMALL}
                        type="submit"
                      >{`Update Table`}</PrimaryCTAButton>
                    </div>
                    <div>
                      {interest !== 0 && formik.values.redeemedAmount !== 0 && (
                        <div className="border-2 border-dashed pt-3 px-5 pb-5">
                          <Label>
                            {" "}
                            Interest &quot;{currencySymbol}
                            {interest}&quot; is assumed to have been paid for
                            amount &quot;{currencySymbol}
                            {formik.values.redeemedAmount}&quot;{" "}
                          </Label>
                        </div>
                      )}
                    </div>
                  </VStack>
                </Form>
              )}
            </Formik>
            <div className="w-full p-4">
              <table className="min-w-full bg-white">
                <thead>
                  <tr className="text-left text-xs2 font-normal text-gray-600 whitespace-nowrap">
                    <th
                      scope="col"
                      key="name"
                      className="py-3.5 pl-4 pr-3 sm:pl-6 hover:cursor-pointer"
                    >
                      <HStack className="items-center">
                        <p className="whitespace-no-wrap font-medium">
                          Shareholder
                        </p>
                        <SortingComponent
                          fieldName="name"
                          selectedFieldName={sortField?.field || ""}
                          isAscending={sortField?.ascending || false}
                          onChangeSort={() => {
                            setSortField({
                              field: "name",
                              ascending: !sortField?.ascending,
                            });
                          }}
                        />
                      </HStack>
                    </th>
                    <th scope="col" className="py-3">
                      <HStack className="items-center">
                        <p className="whitespace-no-wrap font-medium">Date</p>
                        <SortingComponent
                          fieldName={"redemptionDate"}
                          selectedFieldName={sortField?.field || ""}
                          isAscending={sortField?.ascending || false}
                          onChangeSort={() => {
                            setSortField({
                              field: "redemptionDate",
                              ascending: !sortField?.ascending,
                            });
                          }}
                        />
                      </HStack>
                    </th>

                    <th scope="col" className="py-3">
                      <HStack className="items-center">
                        <p className="whitespace-no-wrap font-medium">
                          Amt. to Be Redeemed ({currencySymbol})
                        </p>
                        <SortingComponent
                          fieldName={"redeemedAmount"}
                          selectedFieldName={sortField?.field || ""}
                          isAscending={sortField?.ascending || false}
                          onChangeSort={() => {
                            setSortField({
                              field: "redeemedAmount",
                              ascending: !sortField?.ascending,
                            });
                          }}
                        />
                      </HStack>
                    </th>
                    <th scope="col" className="py-3">
                      <HStack className="items-center">
                        <p className="whitespace-no-wrap font-medium">
                          Amt. Left ({currencySymbol})
                        </p>
                        <SortingComponent
                          fieldName={"amountLeftToRedeem"}
                          selectedFieldName={sortField?.field || ""}
                          isAscending={sortField?.ascending || false}
                          onChangeSort={() => {
                            setSortField({
                              field: "amountLeftToRedeem",
                              ascending: !sortField?.ascending,
                            });
                          }}
                        />
                      </HStack>
                    </th>
                  </tr>
                </thead>
                <tbody className="">
                  {shareholderRedemptions.map((shareholder) => (
                    <tr
                      key={`${shareholder.shareholderId}`}
                      className="border-t whitespace-nowrap border-dashed cursor-pointer hover:bg-slate-50 text-gray-600 text-xs2 font-medium"
                    >
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                        <div className="flex items-center">
                          <div className="ml-4">
                            <p>{shareholder.name}</p>
                          </div>
                        </div>
                      </td>

                      <td className="py-4 align-top">
                        <p className="text-xs font-medium text-gray-dark">
                          {shareholder.redemptionDate
                            ? formatDisplayDate(shareholder.redemptionDate)
                            : ""}
                        </p>
                      </td>
                      <td className="py-4 align-top">
                        <p className="text-xs font-medium text-gray-dark">
                          {shareholder.redeemedAmount.toLocaleString(
                            currencyType
                          )}
                        </p>
                      </td>
                      <td className="py-4 align-top">
                        <p className="text-xs font-medium text-gray-dark">
                          {shareholder.amountLeftToRedeem.toLocaleString(
                            currencyType
                          )}
                        </p>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <HStack className="justify-between mt-5 p-8">
              <SecondaryCTAButton
                event-name="Back Security Redemption Modal"
                buttonSize={ButtonSize.SMALL}
                onClick={() => {
                  onSecondaryAction();
                }}
              >
                Back
              </SecondaryCTAButton>
              <PrimaryCTAButton
                event-name="Save Security Redemption Modal"
                buttonSize={ButtonSize.SMALL}
                onClick={() => handleSaveAndSubmit()}
              >{`Save & Continue`}</PrimaryCTAButton>
            </HStack>
          </div>
        ) : (
          <VStack className="p-5">
            <VStack className="gap-4 my-4 mx-2 bg-white shadow-box rounded-lg">
              <div>
                {showChart && chartData && (
                  <div className="flex-1">
                    <CapTableBarChart
                      shareHolderChartData={chartData}
                      dilutedPercentage={
                        roundShares /
                        ((previewData?.preEventFdb || 0) + roundShares)
                      }
                      toggleChartAndToggle={() => {
                        setShowChart(!showChart);
                      }}
                    />
                  </div>
                )}
                {!showChart && (
                  <HStack className="w-full">
                    {previewData?.holdingDifference && (
                      <ShareHolderCapTableQuickRound
                        data={previewData?.holdingDifference || []}
                        toggle={() => {
                          setShowChart(!showChart);
                        }}
                      />
                    )}
                  </HStack>
                )}
              </div>
            </VStack>
            <HStack className="justify-between mt-10 h-8">
              <SecondaryCTAButton
                buttonSize={ButtonSize.SMALL}
                onClick={() => {
                  setIsApprove(false);
                }}
              >
                Back
              </SecondaryCTAButton>
              <PrimaryCTAButton
                event-name="Approve Redemption Modal"
                buttonSize={ButtonSize.SMALL}
                onClick={() => {
                  approveShareholderRedemption(
                    {
                      companyId,
                      eventId: eventId || "",
                      valuation: previewData?.eventInfo?.valuation || 0,
                      pricePerShare: previewData?.eventInfo?.pps || 0,
                    },
                    {
                      onSuccess: (data) => {
                        onPrimaryAction();
                        toast("Conversion Details Updated Successfully!", {
                          type: "success",
                          autoClose: 2000,
                        });
                      },
                      onError: (err: any) => {
                        toast(err.response.data.reason, {
                          type: "error",
                          autoClose: 2000,
                        });
                      },
                    }
                  );
                }}
              >
                Approve
              </PrimaryCTAButton>
            </HStack>
          </VStack>
        )}
      </VStack>
    </div>
  );
}

function RedemptionDetailsCard({
  value,
  label,
}: {
  value: number | string;
  label: string;
}) {
  return (
    <Box className="py-4 shadow-box-lg bg-white rounded-md mt-3 flex-wrap mx-6">
      <VStack className="px-5">
        <div className="text-center text-sm font-semibold">{value}</div>
        <div className="text-center text-xs font-medium">{label}</div>
      </VStack>
    </Box>
  );
}

function AddRedemptionCardDetails({
  securityRedemption,
  currencyType,
}: {
  securityRedemption: RedemptionDetails;
  currencyType: string;
}) {
  const currencySymbol = getCurrencySymbol();
  return (
    <Box className="border-b">
      <VStack className="px-5 py-7 justify-start">
        <HStack className="justify-start">
          <div className="mr-15">
            <RedemptionDetailsCard
              value={securityRedemption.investmentAmount.toLocaleString(
                currencyType
              )}
              label={`Amount Invested (${currencySymbol})`}
            />
          </div>
          <div>
            <RedemptionDetailsCard
              value={securityRedemption.redeemedAmount.toLocaleString(
                currencyType
              )}
              label={`Redeemed (${currencySymbol})`}
            />
          </div>
        </HStack>
      </VStack>
    </Box>
  );
}
