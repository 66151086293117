import { Form, Formik } from "formik";
import { getNames } from "country-list";
import { toast } from "react-toastify";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { HStack, VStack, Error } from "../../components/utils";
import { Input, Label } from "../../components/shared/InputField";
import {
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../quickRound/CTAButtonComponents";
import { Select } from "../../components/shared/Select";
import { SwitchButton } from "../../components/shared/SwitchButton";
import { initialInvestorData } from "./newTransactionInitialValue";
import { newShareholderValidationSchema } from "./newTransactionValidationSchema";
import { useAuthStore } from "../../store";
import { shareholderTypeLabelValue } from "../../constants/ShareholderConstants";
import { useAddShareholder } from "../../queries/transactionRound";

type AddShareholderPopupProps = {
  onPrimaryAction: () => void;
  onSecondaryAction: () => void;
  data: any;
};

export default function AddShareholderPopup({
  onPrimaryAction,
  onSecondaryAction,
  data,
}: AddShareholderPopupProps) {
  const companyId = useAuthStore.getState().companyId ?? "";
  const { mutate: addShareholder } = useAddShareholder(companyId);

  return (
    <VStack>
      <HStack className="justify-between px-8 border-b py-4">
        <p className="text-base font-medium">Add Shareholder</p>
        <XMarkIcon className="h-6 w-6" onClick={() => onSecondaryAction()} />
      </HStack>

      <div className="bg-white rounded-lg px-4 py-4">
        <VStack className="justify-between">
          <Formik
            initialValues={{
              ...initialInvestorData,
              companyId: companyId || "",
            }}
            key="addNewInvestors"
            enableReinitialize={true}
            onSubmit={(values) => {
              const shareholderData = {
                companyId: values.companyId,
                name: values.name,
                email: values.email,
                pan: values.pan,
                type: values.type,
                isPromoter: values.isPromoter,
                residence: values.residence,
              };

              addShareholder([shareholderData]);
              onPrimaryAction();
            }}
            validationSchema={newShareholderValidationSchema}
          >
            {(formik) => (
              <>
                <Form key="addShareholderProfile" className="px-2 py-4 gap-6">
                  <VStack className="w-full gap-8">
                    <HStack className="w-full gap-8 mx-auto grid grid-cols-1 md:grid-cols-2">
                      <div className="flex-1">
                        <Label className="text-sm text-[#464E5F] font-medium">
                          Shareholder Name
                        </Label>
                        <Input
                          type="text"
                          placeholder="Enter Shareholder Name"
                          className="w-72"
                          {...formik.getFieldProps("name")}
                        />
                        {formik.touched.name && formik.errors.name && (
                          <Error text={formik.errors.name} />
                        )}
                      </div>
                      <div className="flex-1">
                        <Label className="text-sm text-[#464E5F] font-medium">
                          Shareholder Type
                        </Label>

                        <Select
                          options={shareholderTypeLabelValue}
                          valueGetter={(o) => o.label}
                          textGetter={(o) => o.value}
                          placeholder="Select Shareholder Type"
                          className="w-72"
                          {...formik.getFieldProps("type")}
                        />
                        {formik.touched.type && formik.errors.type && (
                          <Error text={formik.errors.type} />
                        )}
                      </div>
                    </HStack>
                    <HStack className="w-full gap-8 mx-auto grid grid-cols-1 md:grid-cols-2">
                      <div className="flex-1">
                        <Label className="text-sm text-[#464E5F] font-medium ">
                          Shareholder Email
                        </Label>
                        <Input
                          type="email"
                          placeholder="Enter Email"
                          className="w-72"
                          {...formik.getFieldProps("email")}
                        />
                        {formik.touched.email && formik.errors.email && (
                          <Error text={formik.errors.email} />
                        )}
                      </div>
                      <div className="flex-1">
                        <Label className="text-sm text-[#464E5F] font-medium">
                          PAN
                        </Label>
                        <Input
                          type="text"
                          placeholder="Enter PAN"
                          className="w-72"
                          {...formik.getFieldProps("pan")}
                        />
                        {formik.touched.pan && formik.errors.pan && (
                          <Error text={formik.errors.pan} />
                        )}
                      </div>
                    </HStack>
                    <HStack className="w-full gap-8 mx-auto grid grid-cols-1 md:grid-cols-2">
                      <div className="flex-1">
                        <Label className="text-sm text-[#464E5F] font-medium">
                          Country Of Residence
                        </Label>
                        <Select
                          options={getNames().sort()}
                          placeholder="Enter Country Of Residence"
                          className="w-72"
                          {...formik.getFieldProps("residentialStatus")}
                        />
                        {formik.touched.residence &&
                          formik.errors.residence && (
                            <Error text={formik.errors.residence} />
                          )}
                      </div>
                      <HStack className="flex-1 w-72">
                        <div className="flex flex-row-reverse items-center  text-sm text-gray-600 font-medium ">
                          <SwitchButton
                            className="text-sm text-[#464E5F] font-medium items-center m-1"
                            value={formik.values.isPromoter}
                            label="Is Promoter?"
                            onClick={() => {
                              formik.setFieldValue(
                                "isPromoter",
                                !formik.values.isPromoter
                              );
                            }}
                          />
                        </div>
                      </HStack>
                    </HStack>
                    <HStack className="justify-between mt-10">
                      <SecondaryCTAButton
                        event-name="Cancel Shareholder Quick Transaction"
                        onClick={() => {
                          onSecondaryAction();
                        }}
                        type="reset"
                        className="text-red-500"
                      >
                        Cancel
                      </SecondaryCTAButton>
                      <PrimaryCTAButton
                        event-name="Save Shareholder Quick Transaction"
                        type="submit"
                      >
                        Add +
                      </PrimaryCTAButton>
                    </HStack>
                  </VStack>
                </Form>
              </>
            )}
          </Formik>
        </VStack>
      </div>
    </VStack>
  );
}
