import _ from "lodash";
import { tableEntryStyle } from "../../components/TableComponent";
import {
  formatToReadableNumber,
  getRoundCurrencySymbol,
} from "../../utils/currencyRoboto";
import { getSecurityColor } from "../../utils/historicUtilities";
import { limitString } from "../../utils/string";
import { VStack } from "../../components/utils";
import { CaptableTag } from "../../components/AllotmentTag";
import {
  formatCurrency,
  getCurrency,
  getCurrencyType,
  getKeyByValue,
} from "../../utils/currencyFormatter";
import { currencyTypes } from "../../constants/CompanyCurrencyType";

export function AmountRender(data: any) {
  return (
    <td className="whitespace-nowrap pt-2 ">
      <p className="">
        <span className="pr-1">
          {getRoundCurrencySymbol(
            data.data.amountInvested.currency || data.data.currencySymbol
          )}
        </span>
        {data.data.amountInvested.amount.toLocaleString(
          getCurrency(
            data.data.amountInvested.currency || data.data.currencyType
          )
        )}
      </p>
      {data.data.amountInvested?.roundCurrency !==
        data.data.amountInvested.currency && (
        <div className="text-gray-500 ">
          <span className="pr-1">
            {getRoundCurrencySymbol(data.data.amountInvested?.roundCurrency) ||
              data.data.currencySymbol}
          </span>
          {data.data.amountInvested.amountInCurrency.toLocaleString(
            getCurrency(data.data.amountInvested.roundCurrency) ||
              data.data.currencyType
          )}
          <span>
            (1{" "}
            {getKeyByValue(
              currencyTypes,
              getCurrency(data.data.amountInvested.currency || "INR") ||
                data.data.currencyType
            )}{" "}
            = {data.data.amountInvested.exchangeRate}{" "}
            {getKeyByValue(
              currencyTypes,
              getCurrency(data.data.amountInvested?.roundCurrency) ||
                data.data.currencyType
            )}
            )
          </span>
        </div>
      )}
    </td>
  );
}

export function SharePriceRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-2">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.data?.sharePrice,
          format: data.data.currencyType,
        })}
      </p>
    </div>
  );
}

export function SecurityRender(data: any) {
  return (
    <td className="whitespace-nowrap pt-1">
      <div className="">
        <p
          className={`inline-flex px-2 ${getSecurityColor(
            data.data.security
          )} text-xs  font-semibold leading-5`}
        >
          {data.data.security}
        </p>
      </div>

      <div className="text-gray-500 justify-center  text-xs1">
        <span>
          PAR :{formatCurrency(data.data.par || 0, data.data.currency)}
        </span>
        {data.data.conversionRatio !== "1: 1" && (
          <span className="text-black"> | </span>
        )}
        {data.data.conversionRatio !== "1: 1" && (
          <span>CR : {data.data.conversionRatio}</span>
        )}
      </div>
    </td>
  );
}

export function NumberOfSharesRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-2">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.data?.numberOfShares,
          format: data.data.currencyType,
        })}
      </p>
    </div>
  );
}

export function NameRender(data: any) {
  return (
    <td className="whitespace-nowrap pb-2">
      <VStack>
        {(data?.data?.name ?? "").length > 24 ? (
          <div className="whitespace-normal w-fit ">
            {limitString(data?.data?.name ?? "", 24)}
          </div>
        ) : (
          <div className="whitespace-normal w-fit ">
            {limitString(data?.data?.name ?? "", 24)}
          </div>
        )}
        <CaptableTag tagName={data?.data?.investorType ?? "Others"} />
      </VStack>
    </td>
  );
}
