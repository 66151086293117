import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Input, Label } from "../components/shared/InputField";
import { Select } from "../components/shared/Select";
import { HStack, VStack, Error } from "../components/utils";
import { QuickBuybackParticipantDetails } from "../types/quickRound";
import { useShareholderInstrumentStore } from "../store/shareholderInstrumentStore";
import { useQuickBuybackParticipantDetailsStore } from "../store/QuickBuyBackParticipantsStore";
import {
  ButtonSize,
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../pages/quickRound/CTAButtonComponents";

type AddBuybackParticipantDetailsProps = {
  initialValue?: QuickBuybackParticipantDetails;
  onPrimaryAction: () => void;
  onSecondaryAction: () => void;
  mode: "Add" | "Edit";
};

const AddBuybackParticipantDetails = ({
  initialValue,
  mode,
  onPrimaryAction = () => {},
  onSecondaryAction = () => {},
}: AddBuybackParticipantDetailsProps) => {
  const shareholderInstruments = useShareholderInstrumentStore();
  const shareHolders: string[] = shareholderInstruments.shareholders
    .filter((shareholder) => shareholder.name.toLowerCase() !== "esop pool")
    .map((shareHolderDetail) => shareHolderDetail.name);
  const instruments: string[] = shareholderInstruments.instrumentClasses.map(
    (instrument) => instrument.name
  );

  const initialBuybackParticipantValues: QuickBuybackParticipantDetails = {
    companyInvestorId: initialValue?.companyInvestorId || "",
    name: initialValue?.name || "",
    numberOfShares: initialValue?.numberOfShares || 0,
    amount: initialValue?.amount || 0,
    instrumentClassId: initialValue?.instrumentClassId || "",
    instrumentClassName: initialValue?.instrumentClassName || "",
  };

  const quickBuybackParticipantInputSchema = Yup.object({
    name: Yup.string().required().label("Investor Name"),
    numberOfShares: Yup.number()
      .integer()
      .positive()
      .min(1)
      .required()
      .label("No.of Shares"),
    amount: Yup.number().min(0).required().label("Amount"),
    instrumentClassName: Yup.string().required().label("Instrument Class Name"),
  });

  const quickBuybackParticipantsStore =
    useQuickBuybackParticipantDetailsStore();

  const handleSubmit = (values: QuickBuybackParticipantDetails) => {
    const quickBuybackParticipant: QuickBuybackParticipantDetails = {
      companyInvestorId: values?.companyInvestorId || "",
      name: values?.name || "",
      numberOfShares: values?.numberOfShares || 0,
      amount: values?.amount || 0,
      instrumentClassId: values?.instrumentClassId || "",
      instrumentClassName: values?.instrumentClassName || "",
    };
    quickBuybackParticipantsStore.setBuyBackParticipantDetails(
      quickBuybackParticipant
    );
    onPrimaryAction();
  };

  return (
    <Formik
      key="addQuickBuybackParticipant"
      enableReinitialize={true}
      initialValues={initialBuybackParticipantValues}
      onSubmit={(values) => {
        handleSubmit(values);
      }}
      validationSchema={quickBuybackParticipantInputSchema}
    >
      {(formik) => (
        <Form key="shareholder">
          <div className="w-full bg-white rounded-md px-2 border-dashed border-2">
            <HStack className="text-lg text-left font-medium border-b py-4">
              <h6 className="flex-1">Add Buyback Participant Details</h6>
            </HStack>
            <VStack className="w-full  px-2 py-4 gap-6">
              <VStack className="gap-8">
                <HStack className="gap-4 md:flex-row flex-col">
                  <div className="flex-1">
                    <Label className="text-sm font-normal">
                      Share Holder Name
                    </Label>
                    <Select
                      options={shareHolders}
                      {...formik.getFieldProps("name")}
                    />
                    {formik.touched?.name && formik.errors?.name && (
                      <Error text={formik.errors?.name} />
                    )}
                  </div>
                  <div className="flex-1">
                    <Label className="text-sm font-normal">No.of Shares</Label>
                    <Input
                      type="number"
                      {...formik.getFieldProps("numberOfShares")}
                    />
                    {formik.touched?.numberOfShares &&
                      formik.errors?.numberOfShares && (
                        <Error text={formik.errors?.numberOfShares} />
                      )}
                  </div>
                </HStack>
                <HStack className="gap-4  md:flex-row flex-col">
                  <div className="flex-1">
                    <Label className="text-sm font-normal">Amount</Label>
                    <Input type="number" {...formik.getFieldProps("amount")} />
                    {formik.touched.amount && formik.errors?.amount && (
                      <Error text={formik.errors?.amount} />
                    )}
                  </div>
                  <div className="flex-1">
                    <Label className="text-sm font-normal">
                      Security Class
                    </Label>
                    <Select
                      options={instruments}
                      {...formik.getFieldProps("instrumentClassName")}
                    />
                    {formik.touched.instrumentClassName &&
                      formik.errors?.instrumentClassName && (
                        <Error text={formik.errors?.instrumentClassName} />
                      )}
                  </div>
                </HStack>
              </VStack>

              <HStack className="justify-between h-8">
                <SecondaryCTAButton
                  event-name="Cancel Buyback Modal"
                  buttonSize={ButtonSize.SMALL}
                  onClick={() => {
                    if (mode === "Edit") {
                      handleSubmit({
                        companyInvestorId:
                          initialValue?.companyInvestorId || "",
                        name: initialValue?.name || "",
                        numberOfShares: initialValue?.numberOfShares || 0,
                        amount: initialValue?.amount || 0,
                        instrumentClassId:
                          initialValue?.instrumentClassId || "",
                        instrumentClassName:
                          initialValue?.instrumentClassName || "",
                      });
                    } else {
                      onSecondaryAction();
                    }
                  }}
                >
                  Cancel
                </SecondaryCTAButton>

                <PrimaryCTAButton
                  event-name="Save Buyback Modal"
                  buttonSize={ButtonSize.SMALL}
                  type="submit"
                >
                  Save
                </PrimaryCTAButton>
              </HStack>
            </VStack>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddBuybackParticipantDetails;
