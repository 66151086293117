import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { Icon } from "@iconify/react";
import {
  useGetArticleDescription,
  useGetInvArticleDescription,
  useUpdateArticleDescription,
  useUpdateInvArticleDescription,
} from "../../queries/investorPortal";
import { useAuthStore } from "../../store";
import { useRightsStore } from "../../store/rightsStore";
import BCHeader from "../../shared/BCHeader";
import { Box, HStack } from "../../components/utils";
import { sort } from "../../utils/arrays";
import Loader from "../../shared/Loader";
import { EmptyTable } from "../../components/shared/EmptyTable";
import { useGetCompanyDetails } from "../../queries/termsheet";

interface ArticleData {
  id: string;
  articleNumber: string;
  articleDescription: string;
  isEditable?: boolean;
  isExpanded?: boolean;
}

export interface ArticleModel {
  rightsId?: string;
  cin?: string;
  companyId?: string;
  article: ArticleData[];
}

const RightsArticle = () => {
  const { cin, id, token } = useParams();
  const navigate = useNavigate();
  const rightsId = useRightsStore.getState().rightsId || id;
  const [data, setData] = useState<ArticleData[]>([]);
  const companyId = useAuthStore.getState().companyId || "";
  const { data: nodeCompanyDetail } = useGetCompanyDetails();
  const cinNumber = nodeCompanyDetail?.companyDetails.cinNumber || "";
  const [searchQuery, setSearchQuery] = useState<string>("");
  const featureHidden = false;

  const handleDoubleClick = (index: number) => {
    handleExpandRow(index);
    setData((prevState) =>
      prevState.map((row, idx) => ({
        ...row,
        isEditable: index === idx ? !row.isEditable : row.isEditable,
      }))
    );
  };

  const { data: articleData } = cin
    ? useGetInvArticleDescription(rightsId, companyId, cin)
    : useGetArticleDescription(rightsId, companyId, cinNumber);

  useEffect(() => {
    if (articleData?.article) {
      const sortedArray = sortArticle(articleData.article);
      setData(sortedArray);
    }
  }, [articleData]);

  const sortArticle = (article: ArticleData[]) => {
    const sortedArray = article.sort((a, b) => {
      const parts1 = a.articleNumber.split(".").map(Number);
      const parts2 = b.articleNumber.split(".").map(Number);
      for (let i = 0; i < Math.max(parts1.length, parts2.length); i++) {
        const part1 = parts1[i] || 0;
        const part2 = parts2[i] || 0;

        if (part1 < part2) return -1;
        if (part1 > part2) return 1;
      }
      return 0;
    });
    return sortedArray;
  };

  const { mutate: updateInvArticleDescription } = cin
    ? useUpdateInvArticleDescription()
    : useUpdateArticleDescription();

  const handleExpandRow = (index: number) => {
    setData((prevState) =>
      prevState.map((row, idx) => ({
        ...row,
        isExpanded: index === idx ? !row.isExpanded : row.isExpanded,
        isEditable: index === idx ? row.isEditable : false,
      }))
    );
  };

  const handleInputArticleNumberChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    setData((prevState) =>
      prevState.map((row, idx) => ({
        ...row,
        articleNumber: index === idx ? value : row.articleNumber.trim(),
        isEditable: index === idx,
        isExpanded: index === idx,
      }))
    );
  };

  const handleAddEntry = () => {
    setData((prevState) => [
      ...prevState,
      {
        id: `${Math.floor(Math.random() * 10) + 1}`,
        articleNumber: "",
        articleDescription: "",
        isEditable: true,
      },
    ]);
  };

  const handleDelete = (index: number) => {
    setData((prevState) => prevState.filter((_, idx) => idx !== index));
  };

  const handleInputArticleDescriptionChange = (
    index: number,
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { value } = event.target;
    setData((prevState) =>
      prevState.map((row, idx) => ({
        ...row,
        articleDescription: index === idx ? value : row.articleDescription,
        isEditable: index === idx,
        isExpanded: index === idx,
      }))
    );
  };

  const handleSubmit = () => {
    let error = "";
    data.forEach((element) => {
      if (!element.articleNumber) error = "Article Number cant be Empty";
    });
    if (error === "")
      updateInvArticleDescription(
        {
          article: {
            rightsId,
            companyId,
            cin: !cin ? cinNumber : cin,
            article: data,
          },
          token,
        },
        {
          onSuccess: () => {
            toast("Article Updated Successfully!", {
              type: "success",
              autoClose: 2000,
            });
          },
          onError: () => {
            toast("Something went wrong", { type: "error", autoClose: 2000 });
          },
        }
      );
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const filteredData = data.filter(
    (row) =>
      row.articleNumber.toLowerCase().includes(searchQuery.toLowerCase()) ||
      row.articleDescription.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div>
      {!cin && (
        <BCHeader
          className="items-baseline mb-2"
          bcTitle="Rights Shareholder Mapping"
          bcSubTitle="Articles"
        />
      )}

      {!articleData ? (
        <Box className="my-auto bg-white rounded-lg h-full w-full shadow-box">
          <Loader />
        </Box>
      ) : (
        <div className="w-full p-4 bg-white">
          <div className="flex justify-between items-center mb-4 ">
            <div className="text-xl font-bold text-[#464E5F]">ARTICLE</div>
            <div className="flex items-center">
              <input
                type="text"
                placeholder="Search..."
                className="p-2 border border-gray-300 bg-slate-50 rounded-md"
                onChange={handleSearch}
              />
              {featureHidden && (
                <button
                  className="ml-4 border-orange-501 border text-orange-501 font-medium py-2 px-4 rounded"
                  onClick={handleAddEntry}
                >
                  Add New Entry
                </button>
              )}
              {!cin && (
                <button
                  className="ml-4 border-orange-501 text-orange-501 border font-semibold py-1.5 px-3 rounded"
                  event-name="Back From Article"
                  onClick={() => {
                    navigate("/rights/shareholder");
                  }}
                >
                  Back
                </button>
              )}
              <button
                className="ml-4 bg-orange-501 text-white font-semibold py-2 px-4 rounded"
                onClick={handleSubmit}
              >
                Save
              </button>
            </div>
          </div>
          <div className="flex flex-col justify-between items-center mb-4">
            <table className="w-full table-fixed">
              <thead>
                <tr className="bg-slate-50">
                  <th className="p-2 w-32 border font-semibold border-gray-300 text-[#464E5F]">
                    Article Number
                  </th>
                  <th className="p-2 flex-1 border font-semibold border-gray-300 text-[#464E5F]">
                    Article Description
                  </th>
                  <th className="p-2 w-16 border border-gray-300"></th>
                </tr>
              </thead>
              <tbody>
                {filteredData.map((row, index) => (
                  <>
                    <tr
                      key={row.id}
                      className={
                        index % 2
                          ? "bg-slate-50 hover:bg-slate-100"
                          : "bg-inherit  hover:bg-slate-100"
                      }
                    >
                      <td
                        className={`p-2 w-32 border border-gray-300 ${
                          row.isEditable ? "bg-white" : "cursor-pointer"
                        }`}
                        onClick={() => handleExpandRow(index)}
                      >
                        {row.isEditable ? (
                          <input
                            type="text"
                            name="articleNumber"
                            value={row.articleNumber}
                            onChange={(event) =>
                              handleInputArticleNumberChange(index, event)
                            }
                            className="w-full"
                          />
                        ) : (
                          row.articleNumber
                        )}
                      </td>
                      <td
                        className={`p-2 flex-1 border border-gray-300 ${
                          row.isEditable ? "bg-white" : "cursor-pointer"
                        }`}
                      >
                        {row.isEditable ? (
                          <textarea
                            name="articleDescription"
                            value={row.articleDescription}
                            onChange={(event) =>
                              handleInputArticleDescriptionChange(index, event)
                            }
                            rows={4}
                            className="w-full resize-y border border-gray-300"
                          />
                        ) : (
                          <div
                            onClick={() => handleExpandRow(index)}
                            className="w-full break-words h-6 overflow-clip"
                          >
                            {row.articleDescription}
                          </div>
                        )}
                      </td>
                      <td className="w-16  border border-gray-300">
                        <HStack className="justify-evenly">
                          {row.isEditable ? (
                            <Icon
                              icon="mdi:tick"
                              className="hover:cursor-pointer text-orange-501 scale-125 rounded"
                              height={18}
                              width={18}
                              onClick={() => handleDoubleClick(index)}
                            />
                          ) : (
                            <Icon
                              icon="mi:edit"
                              className="hover:cursor-pointer text-orange-501 scale-125 rounded"
                              height={18}
                              width={18}
                              onClick={() => handleDoubleClick(index)}
                            />
                          )}
                          {featureHidden && (
                            <Icon
                              icon="mi:delete"
                              onClick={() => handleDelete(index)}
                              className={`hover:cursor-pointer text-orange-501 scale-125 rounded `}
                              height={18}
                              width={18}
                            />
                          )}
                        </HStack>
                      </td>
                    </tr>

                    {row.isExpanded && (
                      <tr>
                        <td
                          colSpan={3}
                          className={`p-2 flex-1  border border-gray-300`}
                        >
                          <div className="w-full break-words">
                            {row.articleDescription}
                          </div>
                        </td>
                      </tr>
                    )}
                  </>
                ))}
              </tbody>
            </table>
            {filteredData.length === 0 && (
              <EmptyTable header="No Article Found" subHeader="" />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default RightsArticle;
