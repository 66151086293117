import create from "zustand";

interface StateStore<T> {
  state: T;
  setState: (state: T | ((state: T) => T)) => void;
  reset: () => void;
}

export function zustandState<T>(initialState: T) {
  return create<StateStore<T>>((set: any) => ({
    state: initialState,
    setState: (input) => {
      if (typeof input === "function") {
        const _input = input as (state?: T) => T;
        return set((store: any) => ({ ...store, state: _input(store.state) }));
      } else {
        return set((store: any) => ({ ...store, state: input }));
      }
    },
    reset: () => {
      set((store: any) => ({ ...store, state: initialState }));
    },
  }));
}
