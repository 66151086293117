import axios, { AxiosRequestHeaders } from "axios";
import { useAuthStore } from "../store";

const oldHissaApi = axios.create({
  baseURL:
    localStorage.getItem("apiUrl") ||
    process.env.REACT_APP_API ||
    "http://localhost:8080",
});

oldHissaApi.defaults.headers.post["Content-Type"] = "application/json";
oldHissaApi.interceptors.request.use((config) => {
  const accesstoken =
    localStorage.getItem("accesstoken") || useAuthStore.getState().accessToken;
  if (accesstoken && !config.url?.endsWith("/login")) {
    (config.headers as AxiosRequestHeaders).AccessToken = accesstoken;
    (config.headers as AxiosRequestHeaders).Companyid = "null";
  }

  return config;
});

export default oldHissaApi;
