import React from "react";
import { Icon } from "@iconify/react";
import numToWords from "../../utils/numtowords";
import {
  currencyTypes,
  getLocaleByCompanyShortName,
} from "../../constants/CompanyCurrencyType";
import { getCompanyCurrency } from "../../utils/currencyFormatter";

export function ShowNumberInWords({
  width,
  currency,
  value,
}: {
  width?: number;
  currency: string;
  value: number;
}): React.ReactElement {
  const isDecimal = value % 1 > 0;
  return (
    <div
      className={`justify-start ${
        width && width > 0 ? `w-${width}` : "w-72"
      } text-left text-xxs text-secondaryText`}
    >
      {isDecimal
        ? `${numToWords(Math.floor(value), currency)} point ${numToWords(
            Number(((value % 1) * 100).toFixed(2)),
            currency
          )}`
        : numToWords(value, currency)}
    </div>
  );
}

export const Optional = () => (
  <span className="form-label text-tertiaryText text-xxs font-medium">
    (Optional)
  </span>
);
