import { useState } from "react";
import Box from "@mui/material/Box";
import { Icon } from "@iconify/react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { toast } from "react-toastify";
import {
  HStack,
  VStack,
  Error,
  useGetCompanyName,
} from "../../components/utils";
import {
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../quickRound/CTAButtonComponents";

import { Input, Label } from "../../components/shared/InputField";
import {
  OutstandingConvertibleModelTransaction,
  RoundCreationModel,
} from "./RoundCreationModel";
import { FormatNumberSpan } from "../../utils/currencyRoboto";
import {
  getCurrencySymbol,
  getCurrencyType,
} from "../../utils/currencyFormatter";
import { Select } from "../../components/shared/Select";
import { SwitchButton } from "../../components/shared/SwitchButton";
import { useRMCreationStore } from "../../store/roundCreationStore";
import { useAuthStore } from "../../store/useAuthStore";
import {
  IndividualConvertiblesState,
  ModelBuilder,
} from "../../types/Modeling";
import { useRoundModelStore } from "../../store/roundModelingStore";
import { useShareholderInstrumentStore } from "../../store/shareholderInstrumentStore";
import { useQuickRoundTransactionStore } from "../../store/useQuickTransactionStore";
import { ConvertibleDetails } from "../newQuickTransaction/RoundTransactionModel";

type OutstandingConvertibleProps = {
  onPrimaryAction: () => void;
  onSecondaryAction: () => void;
  data: ConvertibleDetails;
  onClose: () => void;
};

type ConversionModel = {
  enableConversion: boolean;
  convertTo: string;
  conversionDate: string;
};

const OutstandingConvertibleSecondPopup = ({
  onPrimaryAction,
  onSecondaryAction,
  data,
  onClose,
}: OutstandingConvertibleProps) => {
  const [loading, setLoading] = useState(false);
  const companyId = useAuthStore().companyId || "";
  const shareholderInstruments = useShareholderInstrumentStore();

  const { state: template, setState: setTemplate } =
    useQuickRoundTransactionStore();

  const securityDetails = shareholderInstruments.instrumentClasses.find(
    (instrument) => instrument.id === data.instrumentClassId
  );

  const handleSubmit = async (values: ConversionModel) => {
    setLoading(true);

    if (
      template.newRoundDetails?.closeDate &&
      new Date(values.conversionDate) >
        new Date(template.newRoundDetails?.closeDate)
    ) {
      toast("Conversion Date should be before or on the round closing date", {
        type: "error",
        autoClose: 2000,
      });
      setLoading(false);
      return;
    }

    const convertible = template.newRoundInput?.convertibles?.find(
      (convertible) => convertible.instrumentClassId === data.instrumentClassId
    );
    if (convertible) {
      convertible.conversionDate = values.conversionDate;
      convertible.updateConversionRatio = values.enableConversion;
      convertible.newConversionSecurityId = values.convertTo;
      setTemplate(template);
    }

    onPrimaryAction();
  };
  const convertibleInitialValues = {
    enableConversion: true,
    convertTo: "",
    conversionDate: data?.conversionDate,
  };

  const isDisabled = true;

  const convertibleValidationSchema = Yup.object({
    conversionDate: Yup.date().required().label("Conversion Date"),
    enableConversion: Yup.boolean().label("Update Convertion Ratio"),
    convertTo: Yup.string().when(["enableConversion"], {
      is: (enableConversion: boolean) => enableConversion === false,
      then: Yup.string().required().label("Convert To"),
      otherwise: Yup.string().notRequired(),
    }),
  });

  const symbol = getCurrencySymbol();
  const currency = getCurrencyType();
  return (
    <div className="w-full">
      <Formik
        initialValues={convertibleInitialValues}
        key="convertibleForm"
        onSubmit={(values) => {
          handleSubmit(values);
        }}
        validationSchema={convertibleValidationSchema}
      >
        {(formik) => (
          <>
            <Form key="convertibleForm">
              <div>
                <Box className="px-4 pt-4 pb-2 text-lg font-medium border-b">
                  <h6 className="flex justify-between">
                    <div></div>
                    <VStack>
                      <p className="text-lg font-medium">
                        Update Convertible Security
                      </p>
                    </VStack>
                    <XMarkIcon
                      className="h-5 font-medium cursor-pointer"
                      onClick={() => onClose()}
                    />
                  </h6>
                </Box>
                <HStack className="items-center justify-start w-full px-10 pt-4">
                  <p className="w-56 text-sm font-medium">Security Name</p>
                  <p className="text-sm font-medium">{securityDetails?.name}</p>
                </HStack>
                <VStack className="w-full py-7 gap-9">
                  <div className="pb-10 border-b ">
                    <HStack className="gap-4 px-10 ">
                      {(securityDetails?.conversionRatio || 0) > 0 && (
                        <VStack className="p-2 bg-white rounded-md shadow-box">
                          <p className="text-sm text-[#464E5F] font-medium whitespace-nowrap pb-2">
                            Original Conv. Ratio
                          </p>
                          <HStack className="items-center">
                            <p className="text-lg font-medium">
                              {`1: ${securityDetails?.conversionRatio}`}
                            </p>
                          </HStack>
                        </VStack>
                      )}

                      <HStack className="gap-8 p-2 bg-white rounded-md shadow-box">
                        <VStack className="">
                          <p className="text-sm text-[#464E5F] font-medium whitespace-nowrap pb-2">
                            Base Valuation
                          </p>
                          <HStack className="items-center">
                            <span className="pr-1 text-xs font-normal">
                              {symbol}
                            </span>
                            <p className="text-lg font-medium">
                              <FormatNumberSpan
                                value={securityDetails?.baseValuation}
                                format={currency}
                              />
                            </p>
                          </HStack>
                        </VStack>
                        <VStack className="items-center justify-center">
                          <p className="text-sm text-[#464E5F] font-medium whitespace-nowrap text-center">
                            {(
                              data &&
                              (securityDetails?.discountCap || 0)
                            )?.toLocaleString()}
                            %
                          </p>
                          <p className="text-sm text-[#464E5F] font-medium whitespace-nowrap  text-center">
                            Discount
                          </p>
                        </VStack>
                        <VStack className="">
                          <p className="text-sm text-[#464E5F] font-medium whitespace-nowrap pb-2">
                            Cap Valuation
                          </p>
                          <HStack className="items-center">
                            <span className="pr-1 text-xs font-normal">
                              {symbol}
                            </span>
                            <p className="text-lg font-medium">
                              <FormatNumberSpan
                                value={securityDetails?.valuationCap}
                                format={currency}
                              />
                            </p>
                          </HStack>
                        </VStack>
                      </HStack>
                      <VStack className="p-2 bg-white rounded-md shadow-box">
                        <p className="text-sm text-[#464E5F] font-medium whitespace-nowrap pb-2">
                          Pre Money Valuation
                        </p>
                        <HStack className="items-center">
                          <span className="pr-1 text-xs font-normal">
                            {symbol}
                          </span>
                          <p className="text-lg font-medium">
                            <FormatNumberSpan
                              value={template.newRoundDetails?.valuation}
                              format={currency}
                            />
                          </p>
                        </HStack>
                      </VStack>
                    </HStack>
                  </div>

                  <VStack className="w-full gap-6 px-10">
                    <HStack className="gap-4">
                      <VStack className="flex-1">
                        <HStack className="items-center">
                          <Label className="mt-3 mr-4 text-sm font-medium whitespace-nowrap">
                            Update Conversion Ratio
                          </Label>
                          <SwitchButton
                            className={`text-xs font-normal items-center m-1}`}
                            value={formik.values.enableConversion}
                            label=""
                            onClick={() => {
                              formik.setFieldValue(
                                "enableConversion",
                                !formik.values.enableConversion
                              );
                              formik.setFieldValue("convertTo", "");
                            }}
                          />
                        </HStack>
                        <p className=" whitespace-no-nowrap">
                          Currently the security is not being converted but only
                          the conversion ratio is being updated, to convert the
                          shares please select the post conversion security type
                          from the provided drop down.
                        </p>
                      </VStack>
                      {!formik.values.enableConversion && (
                        <HStack className="gap-4 w-52">
                          <VStack className="flex-1">
                            <Label className="mt-3 mr-4 text-sm font-medium whitespace-nowrap">
                              Convert To
                            </Label>
                            <Select
                              options={
                                shareholderInstruments.instrumentClasses?.map(
                                  (securityClass) => securityClass
                                ) || []
                              }
                              placeholder={"--Select--"}
                              className="w-45"
                              textGetter={(option) => option.name}
                              valueGetter={(option) => option.id}
                              disabled={formik.values.enableConversion}
                              {...formik.getFieldProps("convertTo")}
                              onChange={(e) => {
                                const securityId = e.target.value;
                                formik.setFieldValue("convertTo", securityId);
                              }}
                            />
                          </VStack>
                        </HStack>
                      )}
                    </HStack>
                    <HStack className="gap-4">
                      <VStack className="flex-1">
                        <Label className="mt-3 mr-4 text-sm font-medium whitespace-nowrap">
                          Conversion Date
                        </Label>
                        <Input
                          type="date"
                          placeholder="Enter To Date"
                          className="w-72"
                          {...formik.getFieldProps("conversionDate")}
                        />
                        {formik.touched.conversionDate &&
                          formik.errors.conversionDate && (
                            <Error text={formik.errors.conversionDate} />
                          )}
                      </VStack>
                    </HStack>
                  </VStack>
                </VStack>
                <HStack className="items-center justify-start w-full px-10 pt-4">
                  <p className="w-56 text-sm font-medium">
                    New Post Discount Valuation ({symbol})
                  </p>
                  <p className="text-sm font-medium">
                    <FormatNumberSpan
                      value={data.valuationApplied || 0}
                      format={currency}
                    />
                  </p>
                </HStack>
                <HStack className="items-center justify-start w-full px-10 pt-4">
                  <p className="w-56 text-sm font-medium">
                    New Conversion Ratio
                  </p>
                  <p className="text-sm font-medium">
                    {data?.newConversionRatio
                      ? `1 : ${data?.newConversionRatio}`
                      : "-"}
                  </p>
                </HStack>
                <HStack className="justify-between px-10 mt-10 mb-10">
                  <SecondaryCTAButton onClick={() => onSecondaryAction()}>
                    Back
                  </SecondaryCTAButton>
                  <PrimaryCTAButton className="p-1" type="submit">
                    {!loading && <span>Save</span>}

                    {loading && (
                      <HStack>
                        <p
                          className="indicator-progress"
                          style={{ display: "block" }}
                        >
                          Please wait...
                        </p>
                        <Icon
                          className="animate-spin"
                          icon="lucide:loader-2"
                          width={18}
                        />
                      </HStack>
                    )}
                  </PrimaryCTAButton>
                </HStack>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
};

export default OutstandingConvertibleSecondPopup;
