import { AssociatedCompany, UserDetailModel } from "../types/UserDetailModel";
import api from "./capTableApi";

const optionsPrefixUrl = "/v1/auth";

export const getUserRoleGrants = () =>
  api.get(`${optionsPrefixUrl}/role/grants`).then((res) => res.data.data);

export const getUserCompanies = (): Promise<AssociatedCompany[]> =>
  api.get(`${optionsPrefixUrl}/userCompanies`).then((res) => res.data.data);

export const switchCompanyApi = (companyId: bigint | number | string) =>
  api
    .put(`${optionsPrefixUrl}/company?companyId=${companyId}`)
    .then((res) => res.data);

export const getUserContext = (): Promise<UserDetailModel> =>
  api.get(`${optionsPrefixUrl}/userContext`).then((res) => res.data.data);
