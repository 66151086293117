import { Form, Formik } from "formik";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Input, Label } from "../components/shared/InputField";
import { Select } from "../components/shared/Select";
import { SwitchButton } from "../components/shared/SwitchButton";
import { HStack, VStack, Error, Box } from "../components/utils";
import {
  CapTableBarChart,
  ShareHolderCaptableChartModel,
} from "../pages/newQuickTransaction/CapTableBarChart";
import { ShareHolderCapTableQuickRound } from "../pages/newQuickTransaction/ShareHolderCapTableQuickRound";
import { useGetQuickRoundPreview } from "../queries/quickRound";
import {
  useGetSecurityConversion,
  useGetUpdatedConversionDetails,
  useUpdateSecurityConversionDetails,
} from "../queries/securities";
import { useAuthStore } from "../store";
import {
  SecurityConversionDetails,
  SecurityConversionUpdateDetails,
  SecurityTableDetail,
  UpdatedConversionDetails,
} from "../types/SecurityModel";
import { formatCurrency, getCurrencyType } from "../utils/currencyFormatter";
import { getSecurityColor } from "../utils/historicUtilities";
import RoundIdentifiers from "../shared/RoundIdentifer";
import { useApproveTransactionPreview } from "../queries/transactionRound";
import {
  ButtonSize,
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../pages/quickRound/CTAButtonComponents";

export function AddSecurityConversion({
  securityDetails,
  onPrimaryAction = () => {},
  onSecondaryAction = () => {},
}: {
  securityDetails: SecurityTableDetail;
  onPrimaryAction: () => void;
  onSecondaryAction: () => void;
}) {
  const [isApprove, setIsApprove] = useState(false);
  const currencyType = getCurrencyType();

  const companyId = useAuthStore.getState().companyId || "";

  const [eventId, setEventId] = useState();

  const { mutate: updateConversion, data: conversionDetails } =
    useUpdateSecurityConversionDetails();

  const { mutate: getUpdatedConversion } = useGetUpdatedConversionDetails();

  const { refetch: getQuickRoundPreview, data: previewData } =
    useGetQuickRoundPreview(eventId || "");

  const { mutate: approveSecurityConversion } = useApproveTransactionPreview();

  const [updatedConversionDetails, setUpdatedConversionDetails] =
    useState<UpdatedConversionDetails>();

  const handleSave = (values: SecurityConversionUpdateDetails) => {
    const conversionDetails: SecurityConversionUpdateDetails = {
      securityClassId: securityDetails?.id || "",
      conversionDate: values.conversionDate,
      conversionType: values.conversionType,
      newSharePrice: values.newSharePrice,
      updateConversionRatio: values.updateConversionRatio,
      conversionSecurityClass: values.conversionSecurityClass,
      convertibleSecurityClassId:
        securityClasses?.find(
          (securityClass) =>
            securityClass.securityClassName === values.conversionSecurityClass
        )?.securityClassId || "",
      useValuationAtConversion: values.useValuationAtConversion,
      valuation: values.valuation,
    };

    updateConversion(conversionDetails, {
      onSuccess: async (data) => {
        setEventId(data.id);
        setIsApprove(true);
        setConversionFormValues(values);
        toast("Conversion Details Saved Successfully!", {
          type: "success",
          autoClose: 2000,
        });
      },

      onError: (err: any) => {
        toast(err.response.data.errorMessage, {
          type: "error",
          autoClose: 2000,
        });
      },
    });
  };

  useEffect(() => {
    getQuickRoundPreview();
  }, [conversionDetails]);

  const { data: securityConversionModel } = useGetSecurityConversion(
    securityDetails.id
  );

  const securityTypes = securityConversionModel?.securityType.map(
    (security) => security.securityType
  );
  const [selectedSecurityType, setSelectedSecurityType] = useState<string>("");
  const [securityClasses, setSecurityClasses] = useState<
    {
      securityClassId: string;
      securityClassName: string;
    }[]
  >();

  useEffect(() => {
    setSecurityClasses(
      securityConversionModel?.securityType.find(
        (security) => security.securityType === selectedSecurityType
      )?.securityList || []
    );
  }, [selectedSecurityType]);

  const [conversionFormValues, setConversionFormValues] =
    useState<SecurityConversionUpdateDetails>({
      conversionDate: "",
      conversionType: "",
      updateConversionRatio: false,
      conversionSecurityClass: "",
      convertibleSecurityClassId: "",
      securityClassId: "",
      useValuationAtConversion: true,
      valuation: 0,
      newSharePrice: 0,
      securityType: "",
    });

  const [showChart, setShowChart] = useState(false);
  const [roundShares, setRoundShare] = useState<number>(0);
  const [chartData, setChartData] = useState<ShareHolderCaptableChartModel[]>(
    []
  );

  useEffect(() => {
    previewData?.holdingDifference?.forEach((shareholder) => {
      setChartData((state) => [
        ...state,
        {
          name: shareholder.name,
          preHoldingFdbPercentage: shareholder.preHoldingFdbPercentage,
          postHoldingFdbPercentage: shareholder.postHoldingFdbPercentage,
        },
      ]);
    });
  }, [previewData, isApprove]);

  const securityConversionInputSchema = Yup.object({
    conversionDate: Yup.string().required().label("Conversion Date"),
    conversionType: Yup.string().required().label("conversion Type"),
    useValuationAtConversion: Yup.boolean().label("useValuationAtConversion"),
    updateConversionRatio: Yup.boolean().label("Update ConversionRatio"),
    valuation: Yup.number().when("useValuationAtConversion", {
      is: (val: boolean) => val === true,
      then: Yup.number().required().label("Valuation"),
      otherwise: Yup.number().label("Valuation"),
    }),
    newSharePrice: Yup.number().when("useValuationAtConversion", {
      is: (val: boolean) => val === false,
      then: Yup.number().required().label("Share Price"),
      otherwise: Yup.number().label("Share Price"),
    }),
    conversionSecurityClass: Yup.string().when("updateConversionRatio", {
      is: (val: boolean) => val === false,
      then: Yup.string().required().label("Security Class"),
      otherwise: Yup.string().label("Security Class"),
    }),
    securityType: Yup.string().when("updateConversionRatio", {
      is: (val: boolean) => val === false,
      then: Yup.string().required().label("Security Type"),
      otherwise: Yup.string().label("Security Type"),
    }),
  });

  const navigate = useNavigate();
  const onClickNavigate = (url: string) => navigate(url);

  return (
    <div className="w-full bg-white rounded-md">
      <VStack className="font-medium text-left">
        <VStack className="p-5 text-lg font-medium border-b">
          <HStack className="justify-between">
            <p className="mb-3 text-lg font-semibold">Conversion</p>
            <span
              onClick={() => onSecondaryAction()}
              className="cursor-pointer"
            >
              X
            </span>
          </HStack>
          <HStack className="justify-start text-base font-medium">
            <p className="mr-10">{securityDetails?.className}</p>
            <HStack className="mr-2">
              <p className="mr-3">Rounds - </p>
              <div className="mt-1">
                <RoundIdentifiers
                  roundNames={securityDetails.roundIdentifierDetails || []}
                  navigate={onClickNavigate}
                />
              </div>
            </HStack>
            <p>
              Security -
              <span
                className={`inline-flex px-2 ${getSecurityColor(
                  securityDetails.type
                )} text-xs font-semibold leading-5`}
              >
                {securityDetails.type}
              </span>
            </p>
          </HStack>
        </VStack>
        {securityConversionModel && (
          <AddSecurityConversionDetailCards
            securityConversion={securityConversionModel}
            securityDetails={securityDetails}
            currencyType={currencyType}
          />
        )}

        {!isApprove ? (
          <div>
            <Formik
              key="securityConversion"
              enableReinitialize={true}
              initialValues={conversionFormValues}
              validationSchema={securityConversionInputSchema}
              onSubmit={(values) => {
                handleSave(values);
              }}
            >
              {(formik) => (
                <Form key="securityConversion">
                  <VStack className="w-full gap-6 px-5 py-4">
                    <HStack className="gap-5 px-20">
                      <div className="w-2/5">
                        <Label className="text-sm font-medium">
                          Conversion Date
                        </Label>
                        <Input
                          type="date"
                          placeholder="Eg:DD/MM/YYYY"
                          className="w-38"
                          {...formik.getFieldProps("conversionDate")}
                        />
                        {formik.touched?.conversionDate &&
                          formik.errors?.conversionDate && (
                            <Error text={formik.errors?.conversionDate} />
                          )}
                      </div>
                      <div className="w-1/5"></div>

                      <div className="w-2/5">
                        <Label className="text-sm font-normal">
                          Conversion Type
                        </Label>
                        <Select
                          className="w-45"
                          options={[
                            "Preferential Allotment",
                            "Private Placement",
                          ]}
                          {...formik.getFieldProps("conversionType")}
                        />
                        {formik.touched?.conversionType &&
                          formik.errors?.conversionType && (
                            <Error text={formik.errors?.conversionType} />
                          )}
                      </div>
                      <div className="hidden w-2/5"></div>
                    </HStack>
                    <div>
                      <HStack>
                        <div className="flex flex-row-reverse items-center text-sm font-medium text-gray-600 ">
                          <SwitchButton
                            className="items-center m-1 text-sm font-medium"
                            value={formik.values.useValuationAtConversion}
                            label="Use Valuation At Conversion"
                            onClick={() => {
                              formik.setFieldValue(
                                "useValuationAtConversion",
                                !formik.values.useValuationAtConversion
                              );
                            }}
                          />
                        </div>
                      </HStack>
                    </div>
                    <HStack className="gap-5 px-20">
                      <div className="w-2/5">
                        <Label className="text-sm font-medium">Valuation</Label>
                        <Input
                          type="number"
                          placeholder="Enter Valuation"
                          className="w-45"
                          {...formik.getFieldProps("valuation")}
                          disabled={!formik.values.useValuationAtConversion}
                        />
                        {formik.touched?.valuation &&
                          formik.errors?.valuation && (
                            <Error text={formik.errors?.valuation} />
                          )}
                      </div>
                      <div className="w-1/5"></div>
                      <div className="w-2/5">
                        <Label className="text-sm font-medium">
                          Share Price
                        </Label>
                        <Input
                          type="number"
                          placeholder="Enter Share Price"
                          className="w-45"
                          {...formik.getFieldProps("newSharePrice")}
                          disabled={formik.values.useValuationAtConversion}
                        />
                        {formik.touched?.newSharePrice &&
                          formik.errors?.newSharePrice && (
                            <Error text={formik.errors?.newSharePrice} />
                          )}
                      </div>
                    </HStack>
                    <div>
                      <HStack>
                        <div className="flex flex-row-reverse items-center text-sm font-medium text-gray-600 ">
                          <SwitchButton
                            className="items-center m-1 text-sm font-medium"
                            value={formik.values.updateConversionRatio}
                            label="Update Conversion Ratio"
                            onClick={() => {
                              formik.setFieldValue(
                                "updateConversionRatio",
                                !formik.values.updateConversionRatio
                              );
                            }}
                          />
                        </div>
                      </HStack>
                    </div>
                    <HStack className="gap-5 px-20">
                      <div className="w-2/5">
                        <Label className="text-sm font-normal">
                          Security Type
                        </Label>
                        <Select
                          options={securityTypes || []}
                          placeholder="Select Security Type"
                          className="w-45"
                          {...formik.getFieldProps("securityType")}
                          onChange={(e) => {
                            setSelectedSecurityType(e.target.value);
                            formik.handleChange(e);
                          }}
                          disabled={formik.values.updateConversionRatio}
                        />
                        {formik.touched?.securityType &&
                          formik.errors?.securityType && (
                            <Error text={formik.errors?.securityType} />
                          )}
                      </div>
                      <div className="w-1/5"></div>
                      <div className="w-2/5">
                        <Label className="text-sm font-normal">
                          Security Class
                        </Label>
                        <Select
                          options={
                            securityClasses?.map(
                              (securityClass) => securityClass.securityClassName
                            ) || []
                          }
                          placeholder="select Security Class"
                          className="w-45"
                          {...formik.getFieldProps("conversionSecurityClass")}
                          disabled={formik.values.updateConversionRatio}
                        />
                        {formik.touched?.conversionSecurityClass &&
                          formik.errors?.conversionSecurityClass && (
                            <Error
                              text={formik.errors?.conversionSecurityClass}
                            />
                          )}
                      </div>
                    </HStack>

                    <VStack>
                      <HStack className="justify-end h-8">
                        <SecondaryCTAButton
                          buttonSize={ButtonSize.SMALL}
                          onClick={() => {
                            const conversionDetails: SecurityConversionUpdateDetails =
                              {
                                securityClassId: securityDetails?.id || "",
                                conversionDate: formik.values.conversionDate,
                                conversionType: formik.values.conversionType,
                                newSharePrice: formik.values.newSharePrice,
                                updateConversionRatio:
                                  formik.values.updateConversionRatio,
                                conversionSecurityClass:
                                  formik.values.conversionSecurityClass,
                                convertibleSecurityClassId:
                                  securityClasses?.find(
                                    (securityClass) =>
                                      securityClass.securityClassName ===
                                      formik.values.conversionSecurityClass
                                  )?.securityClassId || "",
                                useValuationAtConversion:
                                  formik.values.useValuationAtConversion,
                                valuation: formik.values.valuation,
                              };
                            getUpdatedConversion(conversionDetails, {
                              onSuccess: async (data) => {
                                setUpdatedConversionDetails(data);
                              },

                              onError: (err: any) => {
                                toast(err.response.data.errorMessage, {
                                  type: "error",
                                  autoClose: 2000,
                                });
                              },
                            });
                          }}
                        >
                          Calculate
                        </SecondaryCTAButton>
                      </HStack>
                      <div className="justify-center">
                        {updatedConversionDetails && (
                          <UpdatedConversionDetailsCards
                            currencyType={currencyType}
                            updatedConversionDetails={updatedConversionDetails}
                          />
                        )}
                      </div>
                    </VStack>

                    <HStack className="justify-between h-8 mt-10">
                      <SecondaryCTAButton
                        event-name="Back Security Conversion Modal"
                        buttonSize={ButtonSize.SMALL}
                        onClick={() => {
                          onSecondaryAction();
                        }}
                      >
                        Back
                      </SecondaryCTAButton>
                      <PrimaryCTAButton
                        event-name="Save Security Conversion Modal"
                        buttonSize={ButtonSize.SMALL}
                        type="submit"
                      >
                        Save & Continue
                      </PrimaryCTAButton>
                    </HStack>
                  </VStack>
                </Form>
              )}
            </Formik>
          </div>
        ) : (
          <VStack className="p-5">
            <VStack className="gap-4 mx-2 my-4 bg-white rounded-lg shadow">
              <div>
                {showChart && chartData && (
                  <div className="flex-1">
                    <CapTableBarChart
                      shareHolderChartData={chartData}
                      dilutedPercentage={
                        roundShares /
                        ((previewData?.preEventFdb || 0) + roundShares)
                      }
                      toggleChartAndToggle={() => {
                        setShowChart(!showChart);
                      }}
                    />
                  </div>
                )}
                {!showChart && (
                  <HStack className="w-full">
                    {previewData?.holdingDifference && (
                      <ShareHolderCapTableQuickRound
                        data={previewData?.holdingDifference || []}
                        toggle={() => {
                          setShowChart(!showChart);
                        }}
                      />
                    )}
                  </HStack>
                )}
              </div>
            </VStack>
            <HStack className="justify-between h-8 mt-10">
              <SecondaryCTAButton
                event-name="Back from Approve Security Conversion Modal"
                buttonSize={ButtonSize.SMALL}
                onClick={() => {
                  setIsApprove(false);
                }}
              >
                Back
              </SecondaryCTAButton>
              <PrimaryCTAButton
                event-name="Approve Security Conversion Modal"
                buttonSize={ButtonSize.SMALL}
                onClick={() => {
                  approveSecurityConversion(
                    {
                      companyId,
                      eventId: eventId || "",
                      valuation: previewData?.eventInfo?.valuation || 0,
                      pricePerShare: previewData?.eventInfo?.pps || 0,
                    },
                    {
                      onSuccess: (data) => {
                        onPrimaryAction();
                        toast("Conversion Details Updated Successfully!", {
                          type: "success",
                          autoClose: 2000,
                        });
                      },
                      onError: (err: any) => {
                        toast(err.response.data.reason, {
                          type: "error",
                          autoClose: 2000,
                        });
                      },
                    }
                  );
                }}
              >
                Approve
              </PrimaryCTAButton>
            </HStack>
          </VStack>
        )}
      </VStack>
    </div>
  );
}

function ConversionDetailsCard({
  value,
  label,
}: {
  value: number | string;
  label: string;
}) {
  return (
    <Box className="flex-wrap px-2 py-4 mx-10 mt-3 bg-white rounded-md shadow-lg">
      <VStack>
        <div className="text-sm font-semibold text-center">{value}</div>
        <div className="text-xs font-medium text-center">{label}</div>
      </VStack>
    </Box>
  );
}

function AddSecurityConversionDetailCards({
  securityConversion,
  securityDetails,
  currencyType,
}: {
  securityConversion: SecurityConversionDetails;
  securityDetails: SecurityTableDetail;
  currencyType: string;
}) {
  return (
    <Box className="border-b">
      <HStack className="px-3 m-4">
        <div className="w-1/4">
          <ConversionDetailsCard
            value={`1:${securityConversion.originalConversionRatio.toLocaleString(
              currencyType
            )}`}
            label="Original conversion Ratio"
          />
        </div>
        <div className="w-1/4">
          <ConversionDetailsCard
            value={formatCurrency(
              securityConversion.baseValuation,
              currencyType
            )}
            label="Base Valuation"
          />
        </div>
        <div className="w-1/4">
          <ConversionDetailsCard
            value={formatCurrency(securityConversion.sharePrice, currencyType)}
            label="Original Share Price"
          />
        </div>
        <div className="w-1/4">
          <ConversionDetailsCard
            value={formatCurrency(
              securityConversion.valuationCap,
              currencyType
            )}
            label="Valuation Cap"
          />
        </div>
      </HStack>
    </Box>
  );
}

function UpdatedConversionDetailsCards({
  currencyType,
  updatedConversionDetails,
}: {
  currencyType: string;
  updatedConversionDetails: any;
}) {
  return (
    <Box className="border-b">
      <HStack className="px-3 m-4">
        <ConversionDetailsCard
          value={`1:${updatedConversionDetails.updatedConversionRatio.toLocaleString(
            currencyType
          )}`}
          label="Updated conversion Ratio"
        />

        <ConversionDetailsCard
          value={formatCurrency(
            updatedConversionDetails.conversionPPS,
            currencyType
          )}
          label="Conversion Share Price"
        />
      </HStack>
    </Box>
  );
}
