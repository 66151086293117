import { useState } from "react";
import { VStack, HStack } from "../utils";
import { Input, Label, TextArea } from "./InputField";
import numToWords from "../../utils/numtowords";
import {
  ButtonSize,
  ExportImportButton,
  IconButtonType,
  IconCTAButton,
  IconPlacement,
  LoaderCTAButton,
  PrimaryCTAButton,
  SecondaryCTAButton,
  TertiaryCTAButton,
} from "../../pages/quickRound/CTAButtonComponents";
import { Optional, ShowNumberInWords } from "./UiElement";
import { getCurrencyType } from "../../utils/currencyFormatter";

const DesignPage = () => {
  const [selected, setSelected] = useState(false);
  const [input, setInput] = useState("Name");
  const changeFunction = () => {
    const data = numToWords(123);
  };

  return (
    <VStack className="bg-white h-screen">
      <HStack className="mx-auto py-4 font-medium text-lg">
        Design System
      </HStack>
      <ButtonExample />
      <InputExample />
      {/*Dirty Commit <InputExample2 /> */}
      {/*Dirty Commit <NewInput3
        label="Product Name 3"
        helper={
          input ? "A product name is required and recommended to be unique" : ""
        }
        type="text"
        placeholder="Product name"
        onChange={(e: any) => {
          setInput(e.target.value);
        }}
        value={input}
      />
      <NewInput3
        label="Product"
        error={
          input ? "A product name is required and recommended to be unique" : ""
        }
        type="text"
        isRequired={true}
        placeholder="Product name"
        onChange={(e: any) => {
          setInput(e.target.value);
        }}
        value={input}
      />
      <NewInput3
        label="Product Name gdfjgkdnfj "
        helper={
          input ? "A product name is required and recommended to be unique" : ""
        }
        type="text"
        placeholder="Product name"
        onChange={(e: any) => {
          setInput(e.target.value);
        }}
        value={input}
      /> */}
      {/*Dirty Commit <InputExample3 /> */}
      {/*Dirty Commit  <SelectExample /> */}
      {/*Dirty Commit  <SelectExample2 /> */}
    </VStack>
  );
};

//Dirty Commit const InputExample2 = () => {
//   const [input, setInput] = useState<number>(12.12);
//   return (
//     <HStack className="justify-evenly gap-2">
//       <NewInput
//         label="Product Name"
//         helper="A product name is required and recommended to be unique"
//         type="text"
//         placeholder="Product name"
//         onChange={(e: any) => {
//           setInput(e.target.value);
//         }}
//         value={input}
//       />
//       <NewInput
//         isRequired={true}
//         label="Product Name"
//         type="text"
//         placeholder="Product name"
//         error="Product Name is Invalid"
//         onChange={(e: any) => {
//           setInput(e.target.value);
//         }}
//         value={input}
//       />
//       <NewInput
//         label="Email"
//         helper="email of the founder is recommended"
//         type="text"
//         onChange={(e: any) => {
//           setInput(e.target.value);
//         }}
//         value={input}
//       />
//     </HStack>
//   );
// };

//Dirty Commit const InputExample3 = () => {
//   const [input, setInput] = useState<number>(12.12);
//   return (
//     <HStack className="justify-evenly gap-2">
//       <NewInput3
//         label="Product Name 3"
//         helper={
//           input ? "A product name is required and recommended to be unique" : ""
//         }
//         type="text"
//         placeholder="Product name"
//         onChange={(e: any) => {
//           setInput(e.target.value);
//         }}
//         value={input}
//       />
//       <NewInput3
//         isRequired={true}
//         label="Product Name 4"
//         type="text"
//         placeholder="Product name"
//         error={input ? "Product Name is Invalid" : ""}
//         onChange={(e: any) => {
//           setInput(e.target.value);
//         }}
//         value={input}
//       />
//       <NewInput3
//         label="Email"
//         type="text"
//         onChange={(e: any) => {
//           setInput(e.target.value);
//         }}
//         value={input}
//       />
//     </HStack>
//   );
// };

export default DesignPage;

const InputExample = () => {
  const [input, setInput] = useState<number>(12.12);
  const [inputText, setInputText] = useState("");
  const currencyType = getCurrencyType();
  return (
    <VStack>
      <HStack className="px-14 py-4 font-medium text-lg">Inputs</HStack>
      <HStack className="justify-evenly">
        <div>
          <Label>Text Input</Label>
          <Input
            type="text"
            onChange={(e: any) => {
              setInputText(e.target.value);
            }}
            value={inputText}
          />
        </div>
        <div>
          <Label>Number</Label>
          <Input
            type="text"
            onChange={(e: any) => {
              //Dirty Commit  formik.handleChange(e)
              const values = parseInt(
                e.target.value?.replaceAll(",", "") || 0,
                10
              );
              setInput(values);
              //Dirty Commit  formik.setFieldValue(props.variable || '', Math.round(values))
            }}
            value={Intl.NumberFormat(currencyType).format(input)}
          />
          <ShowNumberInWords value={input} currency={currencyType} />
        </div>
        <div>
          {/*Dirty Commit <Label>Percentage</Label> */}
          {/*Dirty Commit <InputPercentage
            type="text"
            onChange={(e: any) => {
              // formik.handleChange(e)
              const values = parseInt(
                e.target.value?.replaceAll(",", "") || 0,
                10
              );
              setInput(values);
              // formik.setFieldValue(props.variable || '', Math.round(values))
            }}
            value={Intl.NumberFormat(currencyType).format(input)}
          /> */}
        </div>
        <div>
          {/*Dirty Commit <HStack className="items-center gap-2">
            <Label>Input Amount</Label>
            <Optional />
          </HStack> */}
          {/*Dirty Commit <InputAmount
            type="text"
            onChange={(e: any) => {
              // formik.handleChange(e)
              const values = parseInt(
                e.target.value?.replaceAll(",", "") || 0,
                10
              );
              setInput(values);
              // formik.setFieldValue(props.variable || '', Math.round(values))
            }}
            value={Intl.NumberFormat(currencyType).format(input)}
          /> */}
        </div>
      </HStack>
    </VStack>
  );
};

const ButtonExample = () => (
  <VStack className="gap-2">
    <HStack className="px-14 py-4 font-medium text-lg">Bottons</HStack>
    <HStack className="justify-evenly">
      <LoaderCTAButton />
      <PrimaryCTAButton>Primary</PrimaryCTAButton>
      <SecondaryCTAButton>Secondary</SecondaryCTAButton>
      <TertiaryCTAButton>Tertiary</TertiaryCTAButton>
      <IconCTAButton
        value={"Icon Button"}
        onClick={() => {}}
        iconName={"uil:setting"}
        className={``}
      />
      <IconCTAButton
        value={"Selected Button"}
        selected={true}
        onClick={() => {}}
        iconName={"uil:setting"}
        className={``}
      />
      <IconCTAButton
        buttonType={IconButtonType.FILLED}
        value={"Icon Button"}
        onClick={() => {}}
        iconName={"uil:setting"}
        className={``}
      />
      <IconCTAButton
        buttonType={IconButtonType.FILLED}
        onClick={() => {}}
        iconName={"uil:setting"}
      />
      <IconCTAButton
        buttonType={IconButtonType.OUTLINED}
        onClick={() => {}}
        iconName={"uil:setting"}
      />
      {/*Dirty Commit <ExportImportButton
        actions={[
          {
            name: "Option 1",
          },
          {
            name: "Option 2",
          },
          {
            name: "Option 3",
          },
        ]}
        onAction={(action) => {}}
      /> */}
    </HStack>
    <HStack className="justify-evenly">
      <PrimaryCTAButton buttonSize={ButtonSize.SMALL}>Primary</PrimaryCTAButton>
      <SecondaryCTAButton buttonSize={ButtonSize.SMALL}>
        Secondary
      </SecondaryCTAButton>
      <TertiaryCTAButton buttonSize={ButtonSize.SMALL}>
        Tertiary
      </TertiaryCTAButton>
      <IconCTAButton
        value={"Icon Button"}
        onClick={() => {}}
        buttonSize={ButtonSize.SMALL}
        iconName={"uil:setting"}
        className={``}
      />
      <IconCTAButton
        value={"Selected Button"}
        selected={true}
        onClick={() => {}}
        buttonSize={ButtonSize.SMALL}
        iconName={"uil:setting"}
        className={``}
      />
      <IconCTAButton
        buttonType={IconButtonType.FILLED}
        value={"Icon Button"}
        onClick={() => {}}
        buttonSize={ButtonSize.SMALL}
        iconName={"uil:setting"}
        className={``}
      />
      <IconCTAButton
        buttonType={IconButtonType.FILLED}
        buttonSize={ButtonSize.SMALL}
        onClick={() => {}}
        iconName={"uil:setting"}
      />
      <IconCTAButton
        buttonType={IconButtonType.FILLED}
        buttonSize={ButtonSize.SMALL}
        onClick={() => {}}
        value={"Upload Excel Sheet"}
        iconPlacement={IconPlacement.RIGHT}
        iconName={"ic:outline-file-upload"}
      />
      <IconCTAButton
        buttonType={IconButtonType.OUTLINED}
        buttonSize={ButtonSize.SMALL}
        onClick={() => {}}
        iconName={"uil:setting"}
      />
    </HStack>
    <Optional />
    <HStack className="justify-evenly">
      {/*Dirty Commit  <LoaderCTAButton /> */}
      <PrimaryCTAButton buttonSize={ButtonSize.SMALL}>Primary</PrimaryCTAButton>
      <SecondaryCTAButton buttonSize={ButtonSize.SMALL}>
        Secondary
      </SecondaryCTAButton>

      <ExportImportButton
        actions={[
          {
            name: "Option 1",
          },
          {
            name: "Option 2",
          },
          {
            name: "Option 3",
          },
        ]}
        onAction={(action: any) => {}}
      />
    </HStack>
  </VStack>
);
