import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getUserContext, switchCompanyApi } from "../../api/auth";
import {
  checkTokenValidity,
  isTokenValid,
  redirectToHissa,
} from "../../components/utils";
import { queryClient } from "../../queries/client";
import { useAuthStore } from "../../store/useAuthStore";

export default function DashboardRedirectPage() {
  const { companyId, token } = useParams() as {
    companyId: string;
    token: string;
  };

  const { setAuth } = useAuthStore();

  const navigate = useNavigate();

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const isValid = isTokenValid(token);

    if (isValid) {
      setIsAuthenticated(true);
      localStorage.setItem("accessToken", token);
      setAuth({
        isAuthenticated: false,
        accessToken: token,
        user: undefined,
        companyId,
      });

      localStorage.setItem("companyId", companyId);
      setAuth({
        companyId,
      });
      switchCompanyApi(companyId)
        .then(async (_) => {
          await queryClient.refetchQueries("userContext");
          setAuth({
            isAuthenticated: true,
          });
          navigate("/landing/dashboard");
        })
        .catch(async (err) => {
          navigate("/404");
        });
    } else {
      navigate("/404");
    }
  }, []);

  return <div />;
}
