import api from "./capTableApi";

export async function uploadCompanyExcel({
  formData,
}: {
  formData: FormData;
}): Promise<void> {
  return api
    .post(`v1/company/onboarding`, formData)
    .then((res) => res.data.data);
}
