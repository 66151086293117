import { memo, useEffect, useMemo, useRef, useState } from "react";
import _ from "lodash";
import {
  CellClickedEvent,
  ColDef,
  SideBarDef,
  GridApi,
} from "ag-grid-community";
import { Box, Dialog } from "@mui/material";
import { Icon } from "@iconify/react";
import { AgGridReact } from "ag-grid-react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { tableCellStyle } from "../../components/TableComponent";
import { useGetCompanyName, HStack } from "../../components/utils";

import ExportMultidateSheetDetails from "../../modals/ExportMultidateSheetDetails";
import GenericTableHeader from "../../shared/TableHeader";
import { useAuthStore } from "../../store";
import { useAgGridTableState } from "../../store/agGridTableStore";
import {
  getCurrencySymbol,
  getCurrencyType,
} from "../../utils/currencyFormatter";
import {
  useGetOnFilterState,
  usePostOnFilterState,
} from "../dashboardPage/AgGridCacheQuery";
import { agConfigDashboardSideBar } from "../dashboardPage/AgGridConfig";
import { IconCTAButton } from "../quickRound/CTAButtonComponents";
import {
  NameRender,
  TypeRender,
  UpdatedValueRender,
  DateRender,
} from "./SecurityTransactionAgComponent";
import { SecurityTransaction } from "../../types/SecurityModel";

function AGGridSecurityTransactionTable({
  securityTransactionTableData,
}: {
  securityTransactionTableData: SecurityTransaction[];
}) {
  const currencySymbol = getCurrencySymbol();
  const companyId = useAuthStore().companyId ?? "";
  const userId = useAuthStore().user?.userId ?? 0;
  const gridApi = useRef<any>(null);
  const companyName = useGetCompanyName();
  const [dialog, setDialog] = useState<{
    open: boolean;
    data?: string;
  }>({
    open: false,
  });

  const displayedRowCount = gridApi.current?.api.getDisplayedRowCount();

  const { refetch, data: filterState } = useGetOnFilterState(
    `${userId}`,
    companyId
  );
  const agTableStore = useAgGridTableState();
  useEffect(() => {
    refetch().then((data) => {
      if (data.data) agTableStore.setState(data.data);
      setColumnSetting(data.data?.securityTransctionColumnModel);
      setFilterSetting(data.data?.securityTransactionFilterModel);
    });
  }, []);

  const { mutate: postOnFilter } = usePostOnFilterState();

  function getColumnSetting() {
    if (gridApi.current) return gridApi.current.columnApi?.getColumnState();
    return {};
  }

  function setColumnSetting(model: any) {
    if (gridApi.current)
      gridApi.current.columnApi.applyColumnState({ state: model });
    return {};
  }

  // Gets filter model via the grid API
  const getFilterSetting = () => {
    if (gridApi.current) return gridApi.current.api?.getFilterModel();
    return {};
  };

  const setFilterSetting = (model: any) => {
    if (gridApi.current) return gridApi.current.api?.setFilterModel(model);
    return {};
  };

  const uploadFilterAndColumn = async () => {
    const columnState = await getColumnSetting();
    const filterState = await getFilterSetting();
    postOnFilter({
      userId: `${userId}`,
      companyId,
      filterData: {
        ...agTableStore.state,
        securityTransctionColumnModel: columnState,
        securityTransactionFilterModel: filterState,
      },
    });
  };

  const componentsRegistery = useMemo(
    () => ({
      name: memo(NameRender),
      transactionType: memo(TypeRender),
      updatedValue: memo(UpdatedValueRender),
      date: memo(DateRender),
    }),
    []
  );

  const navigate = useNavigate();

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      autoHeight: true,
      wrapHeaderText: true,
      suppressColumnVirtualisation: true,
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      filterParams: {
        buttons: ["reset"],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
      flex: 1,
    }),
    []
  );

  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        headerName: "Name",
        field: "name",
        filter: "agTextColumnFilter",
        getQuickFilterText: (params) => params.value.name,
        comparator(valueA: any, valueB: any, ..._: any[]) {
          return valueA.name > valueB.name ? 1 : -1;
        },
        cellRenderer: "name",
        initialWidth: 300,
        sortable: true,
        autoHeight: true,
        cellStyle: tableCellStyle,
        wrapText: true,
        suppressSizeToFit: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipShareholderName",
      },

      {
        headerName: "Type",
        field: "transactionType",
        filter: "agTextColumnFilter",
        cellRenderer: "transactionType",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipPPS",
      },
      {
        headerName: "Updated Value",
        field: "updatedValue",
        filter: "agNumberColumnFilter",
        cellRenderer: "updatedValue",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipPPS",
      },
      {
        headerName: "Date",
        field: "date",
        filter: "agDateColumnFilter",

        filterParams: {
          comparator: (dateFromFilter: Date, cellValue: any) => {
            if (cellValue == null) {
              return 0;
            }
            const dateParts = cellValue.split("-");
            const day = Number(dateParts[2]);
            const month = Number(dateParts[1]) - 1;
            const year = Number(dateParts[0]);
            const cellDate = new Date(year, month, day);
            if (cellDate < dateFromFilter) {
              return -1;
            } else if (cellDate > dateFromFilter) {
              return 1;
            }
            return 0;
          },
        },
        cellRenderer: "date",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
      },
    ],
    []
  );

  const gridOptions = {
    suppressRowVirtualisation: true, // Add this line to disable row virtualization
    paginationAutoPageSize: false, // Optional: Set to false if using custom pagination
    suppressScrollOnNewData: true, // Optional: Set to true to prevent scrolling to the top on
  };

  const currencyType = getCurrencyType();

  const rowData = useMemo(
    () =>
      securityTransactionTableData?.map((template) => ({
        name: template.name,
        securityType: template.securityType,
        transactionType:
          template.transactionType.charAt(0).toUpperCase() +
          template.transactionType.slice(1),
        updatedValue:
          template.transactionType === "update ratio"
            ? `1:${Number(template.updatedValue).toFixed(2)}`
            : template.updatedValue,
        date: template.date,
        currencySymbol,
        currencyType,
      })),
    [securityTransactionTableData]
  );

  function setTableHeight() {
    if (displayedRowCount === 1) {
      return (displayedRowCount + 2) * 60;
    } else if (displayedRowCount === 2) {
      return (displayedRowCount + 2) * 60;
    } else if (displayedRowCount === 3) {
      return (displayedRowCount + 2) * 60;
    } else if (displayedRowCount === 4) {
      return (displayedRowCount + 2) * 60;
    } else if (displayedRowCount === 5) {
      return (displayedRowCount + 2) * 60;
    } else if (displayedRowCount === 6) {
      return (displayedRowCount + 2) * 60;
    } else if (displayedRowCount === 7) {
      return (displayedRowCount + 2) * 60;
    } else if (displayedRowCount === 8) {
      return (displayedRowCount + 2) * 60;
    } else if (displayedRowCount === 9) {
      return (displayedRowCount + 2) * 60;
    } else {
      return 10 * 30;
    }
  }

  const [isColumnOpen, setIsColumnOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const openToolPanel = (key: any) => {
    if (key === "columns") {
      if (gridApi) {
        if (!isColumnOpen) gridApi?.current?.api?.openToolPanel(key);
        else gridApi?.current?.api?.closeToolPanel();
        setIsColumnOpen((state) => !state);
        setIsFilterOpen(false);
      }
    } else if (key === "filters") {
      if (gridApi) {
        if (!isFilterOpen) {
          gridApi?.current?.api?.openToolPanel(key);
        } else gridApi?.current?.api?.closeToolPanel();
        setIsFilterOpen((state) => !state);
        setIsColumnOpen(false);
      }
    }
  };

  const [filteredRowData, setFilteredRowData] = useState<any>([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const onAgGridFilterChanged = (grid: any) => {
    const filtersApplied = grid.api.isAnyFilterPresent();
    setIsFilterApplied(filtersApplied);

    const filteredData = grid.api
      .getModel()
      .rowsToDisplay.map((node: any) => node.data);
    setFilteredRowData(filteredData);
    uploadFilterAndColumn();
  };

  return (
    <>
      <HStack className="flex-col lg:flex-row justify-start lg:justify-between  py-2 lg:items-center items-start  bg-white rounded-md">
        <GenericTableHeader
          heading={"Security Transactions"}
          subHeading={"Transactions"}
          count={
            isFilterApplied === true ? filteredRowData.length : rowData.length
          }
        />
        <HStack className="w-full lg:w-auto lg:justify-end py-2 items-center gap-4 px-8">
          <IconCTAButton
            value={"Columns"}
            onClick={() => openToolPanel("columns")}
            iconName={"fluent:column-triple-edit-20-regular"}
            className={`px-4 font-medium items-center flex flex-row ${
              isColumnOpen ? "text-orange-501" : "text-gray-400"
            }`}
          />
          <IconCTAButton
            value={"Filters"}
            onClick={() => openToolPanel("filters")}
            iconName={"material-symbols:filter-alt"}
            className={`px-4 font-medium items-center flex flex-row ${
              isFilterOpen ? "text-orange-501" : "text-gray-400"
            }`}
          />
        </HStack>
      </HStack>
      <Dialog open={dialog.open} maxWidth="md">
        <ExportMultidateSheetDetails
          reportName={dialog.data || ""}
          onPrimaryAction={() => setDialog({ open: false })}
          onSecondaryAction={() => setDialog({ open: false })}
        />
      </Dialog>
      <HStack className="justify-between w-full">
        <Box
          style={{
            height: setTableHeight(),
          }}
          className="w-full max-h-full overflow-x-auto ag-theme-material h-full "
        >
          <AgGridReact
            sideBar={agConfigDashboardSideBar}
            onGridReady={(params) => {
              // Store the grid API referen
              gridApi.current = params;
            }}
            components={componentsRegistery}
            alwaysShowHorizontalScroll={false}
            alwaysMultiSort
            animateRows={true}
            defaultColDef={defaultColDef}
            onFilterChanged={onAgGridFilterChanged}
            onColumnEverythingChanged={uploadFilterAndColumn}
            rowData={rowData}
            columnDefs={columnDefs}
            pagination={false}
            enableCharts={true}
            enableRangeSelection={true}
            suppressRowTransform={true}
            suppressCopyRowsToClipboard={true}
            suppressCopySingleCellRanges={true}
            suppressCellFocus={true}
            suppressMenuHide={false}
            onColumnResized={uploadFilterAndColumn}
            tooltipShowDelay={1000}
            tooltipInteraction={true}
            rowClass={"border-t border-dashed"}
            gridOptions={gridOptions}
            overlayNoRowsTemplate={
              '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow; margin-top: 50px;">No Rows To Show</span>'
            }
            getRowStyle={(params) => {
              if (params.rowIndex % 2 === 0) {
                return { background: "#f8f8f8" };
              } else {
                return { background: "#ffffff" };
              }
            }}
          ></AgGridReact>
        </Box>
      </HStack>
    </>
  );
}

export default AGGridSecurityTransactionTable;
