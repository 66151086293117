import _ from "lodash";
import {
  FinancialDataModel,
  FinancialResponseModel,
  FinancialType,
} from "../pages/finance/FinanceWrapper";

export const randomDataFinancial = (): FinancialResponseModel => ({
  data: [
    {
      cin: "x".padStart(_.random(6, 8), "x"),
      companyId: "x".padStart(_.random(6, 8), "x"),
      financialType: FinancialType.StandAlone,
      data: {
        shareCapital: _.random(1000000, 2000000),
        reservesAndSurplus: _.random(1000000, 2000000),
        moneyReceivedAgainstWarrant: _.random(1000000, 2000000),
        totalEquity: _.random(1000000, 2000000),
        longTermBorrowing: _.random(1000000, 2000000),
        shortTermBorrowing: _.random(1000000, 2000000),
        unsecuredLong: _.random(1000000, 2000000),
        unsecuredShort: _.random(1000000, 2000000),
        totalBorrowings: _.random(1000000, 2000000),
        tradePayables: _.random(1000000, 2000000),
        provisions: _.random(1000000, 2000000),
        otherLiabilities: _.random(1000000, 2000000),
        totalOtherLiabilities: _.random(1000000, 2000000),
        tangibleAsset: _.random(1000000, 2000000),
        inTangibleAsset: _.random(1000000, 2000000),
        totalFixedAssets: _.random(1000000, 2000000),
        totalLiabilities: _.random(1000000, 2000000),
        capitalWorkInProgress: _.random(1000000, 2000000),
        investments: _.random(1000000, 2000000),
        loansAndAdvances: _.random(1000000, 2000000),
        cashAndCashEquivalents: _.random(1000000, 2000000),
        tradeReceivables: _.random(1000000, 2000000),
        inventories: _.random(1000000, 2000000),
        otherAssets: _.random(1000000, 2000000),
        totalAssets: _.random(1000000, 2000000),
        domesticTurnover: _.random(1000000, 2000000),
        exportTurnover: _.random(1000000, 2000000),
        totalRevenueFromOperations: _.random(1000000, 2000000),
        costOfMaterialsConsumed: _.random(1000000, 2000000),
        employeeBenefitExpense: _.random(1000000, 2000000),
        financeCosts: _.random(1000000, 2000000),
        depreciationAndAmortization: _.random(1000000, 2000000),
        others: _.random(1000000, 2000000),
        totalExpenses: _.random(1000000, 2000000),
        currentTax: _.random(1000000, 2000000),
        deferredTax: _.random(1000000, 2000000),
        totalTaxExpense: _.random(1000000, 2000000),
        basic: _.random(1000000, 2000000),
        diluted: _.random(1000000, 2000000),
        profitBeforeTax: _.random(1000000, 2000000),
        profitOrLoss: _.random(1000000, 2000000),
        otherIncome: _.random(1000000, 2000000),
        totalRevenue: _.random(1000000, 2000000),
        currentRatio: _.random(1000000, 2000000),
        quickRatio: _.random(1000000, 2000000),
        cashRatio: _.random(1000000, 2000000),
        debtToEquityRatio: _.random(1000000, 2000000),
        debtToAssetRatio: _.random(1000000, 2000000),
        assetToEquityRatio: _.random(1000000, 2000000),
        netProfitMarginRatio: _.random(1000000, 2000000),
        returnOnEquity: _.random(1000000, 2000000),
        returnOnCapitalEmployed: _.random(1000000, 2000000),
        numberOfMaterialContracts: _.random(1000000, 2000000),
        numberOfContracts: _.random(1000000, 2000000),
        grossTransactionValue: _.random(1000000, 2000000),
        armsLengthNameOfRelatedParty: "xxxxxxxxxxxxx",
        armsLengthNatureOfRelationship: "xxxxxxxxxxxxx",
        armsLengthNatureOfContract: "xxxxxxxxxxxxx",
        armsLengthDurationOfContract: "xxxxxxxxxxxxxxx",
        armsLengthDateOfApproval: "xxxxxxxxxxxxx",
        armsLengthAmountPaid: _.random(1000000, 2000000),
        notArmsLengthNameOfRelatedParty: _.random(1000000, 2000000),
        notArmsLengthNatureOfRelationship: _.random(1000000, 2000000),
        notArmsLengthNatureOfContract: _.random(1000000, 2000000),
        notArmsLengthDurationOfContract: _.random(1000000, 2000000),
        notArmsLengthDateOfApproval: _.random(1000000, 2000000),
        notArmsLengthAmountPaid: _.random(1000000, 2000000),
        notArmsLengthDateOfSpecialResolution: _.random(1000000, 2000000),
        badDebts: _.random(1000000, 2000000),
        longTermLoansAndAdvances: _.random(1000000, 2000000),
        shortTermLoansAndAdvances: _.random(1000000, 2000000),
        totalUnsecuredBorrowings: _.random(1000000, 2000000),
        directorsUnsecured: _.random(1000000, 2000000),
        directorsDoubtful: _.random(1000000, 2000000),
        othersUnsecured: _.random(1000000, 2000000),
        othersDoubtful: _.random(1000000, 2000000),
        provisionsUnsecured: _.random(1000000, 2000000),
        provisionsDoubtful: _.random(1000000, 2000000),
        totalProvisions: _.random(1000000, 2000000),
      },
      isPrivate: false,
      misData: {},
      name: "x".padStart(_.random(6, 8), "x"),
      publicCompanyname: "x".padStart(_.random(6, 8), "x"),
      s3Url: "x".padStart(_.random(6, 8), "x"),
      timeline: "x".padStart(_.random(6, 8), "x"),
      timelineType: "x".padStart(_.random(6, 8), "x"),
      updatedDate: "2012-03-31T18:30:00.000+0000",
      year: "x".padStart(_.random(6, 8), "x"),
      id: "xxxxxxxxxxxxx",
    },
  ],
  showRPT: {
    Monthly: false,
    Quarterly: false,
    Year: false,
  },
});
