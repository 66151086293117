import _ from "lodash";
import { tableEntryStyle } from "../../components/TableComponent";
import { formatToReadableNumber } from "../../utils/currencyRoboto";
import { getSecurityColor } from "../../utils/historicUtilities";
import { limitString } from "../../utils/string";
import { useShareholderInstrumentStore } from "../../store/shareholderInstrumentStore";

export function TypeRender(data: any) {
  const instrumentStore = useShareholderInstrumentStore();
  const instruments = instrumentStore.instrumentClasses;
  return (
    <div className="whitespace-nowrap pt-2">
      <span
        className={`inline-flex px-2 ${getSecurityColor(
          instruments.find(
            (instrument) => instrument.name === data.data.security
          )?.subType || ""
        )} text-xs font-semibold leading-5`}
      >
        {data.data.type}
      </span>
    </div>
  );
}

export function SecurityRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-2">
      <p className={tableEntryStyle}>{data.data.security}</p>
    </div>
  );
}

export function NewSharesRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-2">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.data?.newShares,
          format: data.data.currencyType,
        })}
      </p>
    </div>
  );
}

export function PostConversionRatioRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-2">
      <p className={tableEntryStyle}> {data.data.postConversionSecurity}</p>
    </div>
  );
}

export function ConversionRatioRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-2">
      <p className={tableEntryStyle}> {data.data.conversionRatio}</p>
    </div>
  );
}

export function InvestorNameRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-2">
      <p className={tableEntryStyle}>
        {limitString(data.data.investorName, 24)}
      </p>
    </div>
  );
}
