import { tableEntryStyle } from "../components/TableComponent";
import { formatToReadableNumber } from "../utils/currencyRoboto";
import { formatDisplayDate } from "../utils/date";

export function PaidUpSharePriceRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-2">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.data?.paidUpSharePrice,
          format: data.data.currencyType,
        })}
      </p>
    </div>
  );
}

export function TotalPaidRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-2">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.data?.totalPaid,
          format: data.data.currencyType,
        })}
      </p>
    </div>
  );
}

export function ScheduledDateRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-2">
      <p className={tableEntryStyle}>
        {formatDisplayDate(data.data.scheduledDate)}
      </p>
    </div>
  );
}

export function PaidUpDateRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-2">
      <p className={tableEntryStyle}>
        {formatDisplayDate(data.data.paidUpDate)}
      </p>
    </div>
  );
}
