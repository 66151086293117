import { uniqueId } from "lodash";
import {
  PrimaryTransaction,
  PrimaryTransactionDataModel,
  SecondaryTransactionDataModel,
  Shareholder,
  QuickBonusSecurityModel,
  QuickBonusTransactionDetails,
  IConvertible,
} from "../../types/quickRound";
import {
  BuybackTransaction,
  SecondaryTransaction,
} from "./RoundTransactionModel";
import { InstrumentClass } from "../../types/DashboardModel";
import { IndividualRoundsModel } from "../../types/AllTransactionModel";
import { BuybackTransactionDataModel } from "./AddBuybackAgTable";
import { OutstandingConvertible } from "../../types/Modeling";

export const extractTransaction = (
  primaryTransactions: PrimaryTransaction[],
  shareholders: Shareholder[],
  instrumentClasses: InstrumentClass[],
  companyCurrency: String,
  roundDetail?: IndividualRoundsModel
): PrimaryTransactionDataModel[] => {
  const transaction =
    primaryTransactions?.map((primaryTransaction) => {
      const shareholder = shareholders.find(
        (shareHolderDetail) =>
          shareHolderDetail.id === primaryTransaction.companyInvestorId
      );
      const instrument = instrumentClasses.find(
        (instrument) => instrument.id === primaryTransaction.instrumentClassId
      );

      return {
        uuid: primaryTransaction.uuid ?? uniqueId(shareholder?.name) ?? "",
        name: shareholder?.name,
        investorType: shareholder?.type || "",
        security: instrument?.name || "",
        image: "",
        sharePrice: primaryTransaction.sharePrice || 0,
        instrumentClassId: instrument?.id || "",
        par: instrument?.parValue || 0,
        conversionRatio: `1: ${instrument?.conversionRatio || 0}`,
        investorId: shareholder?.id,
        numberOfShares: primaryTransaction.numberOfShares,
        amountInvested: primaryTransaction.amount,
        amountInvestedInCurrency:
          primaryTransaction.amountInvestedInCurrency || 0,
        currency: primaryTransaction.currency || companyCurrency,
        exchangeRate: primaryTransaction.exchangeRate || 1,
        amountInRoundCurrency: roundDetail?.amountInvestedInCurrency || 0,
        roundCurrency: roundDetail?.currency || companyCurrency,
        exchangeRateForRound: roundDetail?.exchangeRate || 1,
      };
    }) || [];
  return transaction as PrimaryTransactionDataModel[];
};

export const extractSecondaryTransaction = (
  secondaryTransactions: SecondaryTransaction[],
  shareholders: Shareholder[],
  instrumentClasses: InstrumentClass[]
): SecondaryTransactionDataModel[] => {
  const secondary =
    secondaryTransactions?.map((secondaryTransaction) => {
      const buyerShareholder = shareholders.find(
        (shareHolderDetail) =>
          shareHolderDetail.id === secondaryTransaction.companyBuyerInvestorId
      );
      const sellerShareholder = shareholders.find(
        (shareHolderDetail) =>
          shareHolderDetail.id === secondaryTransaction.companySellerInvestorId
      );
      const instrument = instrumentClasses.find(
        (instrument) => instrument.id === secondaryTransaction.instrumentClassId
      );
      const sharePrice: number =
        (secondaryTransaction?.amount || 0) /
        (secondaryTransaction.numberOfShares || 0);
      return {
        uuid:
          secondaryTransaction.uuid ?? uniqueId(buyerShareholder?.name) ?? "",
        buyerName: buyerShareholder?.name || "",
        amountInCurrency: secondaryTransaction.amountInCurrency,
        exchangeRate: secondaryTransaction.exchangeRate,
        transactionCurrency: secondaryTransaction.currency,
        sellerName: sellerShareholder?.name || "",
        investorType: buyerShareholder?.type || "",
        security: instrument?.subType || "",
        image: "",
        sharePrice,
        instrumentClassId: instrument?.id || "",
        buyerInvestorId: buyerShareholder?.id || "",
        sellerInvestorId: sellerShareholder?.id || "",
        numberOfShares: secondaryTransaction.numberOfShares,
        amountInvested: secondaryTransaction.amount,
        par: instrument?.parValue || 0,
        conversionRatio: `1: ${instrument?.conversionRatio || 0}`,
        date: secondaryTransaction.date || "",
      };
    }) || [];

  return secondary as SecondaryTransactionDataModel[];
};

export const extractConvertibles = (
  convertibleDetails: IConvertible[],
  shareholders: Shareholder[],
  instrumentClasses: InstrumentClass[]
): OutstandingConvertible[] => {
  const convertibles =
    convertibleDetails?.map((convertible) => {
      const shareholder = shareholders.find(
        (shareHolderDetail) => shareHolderDetail.id === convertible.investorId
      );
      const instrument = instrumentClasses.find(
        (instrument) => instrument.id === convertible.securityId
      );

      return {
        investorId: convertible.investorId,
        instrumentClassId: convertible.securityId,
        securityName: shareholder?.name || "",
        securityType: instrument?.subType || "",
        numberOfShares: convertible.numberOfShares,
        fdbShares: convertible.totalFdbShares,
        amountInvested: convertible.investmentAmount,

        discountedValuation: convertible.valuationApplied,
        discountedPPS: convertible.conversionPrice,
      };
    }) || [];
  return convertibles as OutstandingConvertible[];
};

export const extractBonusTransaction = (
  quickBonusTransactions: QuickBonusTransactionDetails[],
  instrumentClasses: InstrumentClass[],
  shareholders: Shareholder[]
) =>
  quickBonusTransactions.map((transaction) => {
    const bonusSecurity: QuickBonusSecurityModel = {
      type:
        instrumentClasses?.find(
          (instrumentClass) =>
            instrumentClass.id === transaction.instrumentClassId
        )?.subType || "",
      sourceSecurity:
        instrumentClasses?.find(
          (instrumentClass) =>
            instrumentClass.id === transaction.instrumentClassId
        )?.name || "",
      bonusRatio: transaction.bonusRatio,
      newShares: transaction.newNumberOfShares,
      conversionRatio: transaction.bonusRatio,
      finalSecurity:
        instrumentClasses?.find(
          (instrumentClass) =>
            instrumentClass.id === transaction.finalConversionInstrumentClassId
        )?.name || "",
      investorName:
        shareholders?.find(
          (shareholder) => shareholder.id === transaction.investorId
        )?.name || "",
      investorId: transaction.investorId,
      instrumentClassId: transaction.instrumentClassId,
      finalConversionInstrumentClassId:
        transaction.finalConversionInstrumentClassId,
    };
    return bonusSecurity;
  });

export const extractBuybackTransaction = (
  buyback: BuybackTransaction[],
  instrumentClasses: InstrumentClass[],
  shareholders: Shareholder[]
) =>
  buyback?.map((element) => {
    const name =
      shareholders.find(
        (shareHolderDetail) =>
          shareHolderDetail.id === element.companyInvestorId
      )?.name || "";
    const investorType =
      shareholders.find(
        (shareHolderDetail) =>
          shareHolderDetail.id === element.companyInvestorId
      )?.type || "";
    const security =
      instrumentClasses.find(
        (instrument) => instrument.id === element.instrumentClassId
      )?.type || "";
    const securityClass =
      instrumentClasses.find(
        (instrument) => instrument.id === element.instrumentClassId
      )?.name || "";
    const sharePrice =
      instrumentClasses.find(
        (instrument) => instrument.id === element.instrumentClassId
      )?.parValue || 0;
    const transaction: BuybackTransactionDataModel = {
      name,
      investorType,
      security,
      securityClass,
      sharePrice,
      instrumentClassId: element.instrumentClassId,
      investorId: element.companyInvestorId,
      numberOfShares: element.numberOfShares,
      amountInvested: element.amount,
      par:
        instrumentClasses.find(
          (instrument) => instrument.id === element.instrumentClassId
        )?.parValue || 0,
      conversionRatio: `1: ${
        instrumentClasses.find(
          (instrument) => instrument.id === element.instrumentClassId
        )?.conversionRatio || 0
      }`,
    };
    return transaction;
  });
