import { useEffect, useMemo, useState } from "react";
import { Dialog } from "@mui/material";
import _ from "lodash";
import { useNavigate } from "react-router";
import Tooltip from "../../components/shared/Tooltip";
import { Box, VStack } from "../../components/utils";
import { globalFilter, sort } from "../../utils/arrays";
import BasicMenu from "../../components/shared/Menu";
import Pagination from "../../components/shared/Pagination";
import { limitString, _trimAll } from "../../utils/string";
import { EmptyTable } from "../quickRound/EmptyTableComponents";
import { ColumnOptions } from "../../types/DashboardModel";
import { getCurrencyType } from "../../utils/currencyFormatter";
import {
  FormatNumberSpan,
  getCurrencySymbolRoboto,
} from "../../utils/currencyRoboto";
import {
  THead,
  TableHeader,
  tableEntryStyle,
  tableFirstColStyle,
} from "../../components/TableComponent";
import {
  ExistingInvestorDetail,
  NewInvestorState,
  OngoingExistingInvestorDetails,
  RoundCreationModel,
} from "./RoundCreationModel";
import { Action, CTADropdown } from "../../components/shared/Dropdown";
import DeleteModal from "./DeleteModal";
import { useRMCreationStore } from "../../store/roundCreationStore";
import AddNewInvestors from "./AddNewInvestors";
import NewInvestors from "./NewInvestors";

export default function InvestorTable({
  captableData,
  onNext,
}: {
  captableData: ExistingInvestorDetail[];
  onNext: (data: RoundCreationModel) => void;
}) {
  let _captableData: ExistingInvestorDetail[] = captableData;
  const currency = getCurrencyType();
  const [globalFilterText, setGlobalFilterText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [sortField, setSortField] = useState<{
    field: keyof ExistingInvestorDetail | "" | undefined;
    ascending: boolean;
  }>({ field: "name", ascending: false });

  const [dialog, setDialog] = useState<{
    open: boolean;
    data?: ExistingInvestorDetail;
    mode?: "Edit" | "Delete";
  }>({
    open: false,
  });

  _captableData = useMemo(() => {
    try {
      if (!_captableData) return [];
      const filterResult = globalFilter(_captableData, globalFilterText);

      const sortResult = sort(
        filterResult,
        sortField?.field,
        sortField?.ascending
      );
      return sortResult;
    } catch (e) {
      return _captableData;
    }
  }, [_captableData, globalFilterText, sortField]);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return _captableData.slice(firstPageIndex, lastPageIndex);
  }, [_captableData, currentPage, pageSize]);

  useEffect(() => {
    setCurrentPage(1);
  }, [globalFilterText]);

  const currencySymbol = getCurrencySymbolRoboto();

  function handleAction(action: Action, data: ExistingInvestorDetail) {
    if (action.name === "Edit" && !action.disabled) {
      setDialog({
        open: true,
        data,
        mode: "Edit",
      });
    } else if (action.name === "Delete" && !action.disabled) {
      setDialog({
        open: true,
        data,
        mode: "Delete",
      });
    }
  }

  const roundCreationState = useRMCreationStore();

  const handleSubmit = async (id: string) => {
    const ongoingExistingInvestorDetails: OngoingExistingInvestorDetails = {
      id,
      name: "",
      type: "",
      totalAmountToInvest: 0,
      postholdingPercentage: 0,
      state: NewInvestorState.Delete,
      unLockedInputFeild: {
        hasToCalculateAmt: false,
        value: 0,
      },
      currencyType: "",
      investorRightsType: "",
    };
    const newList =
      roundCreationState.roundCreation.currentTab === 3
        ? {
            ...roundCreationState.roundCreation,
            tab3: {
              existingInvestors:
                roundCreationState.roundCreation.tab3?.existingInvestors || [],
              ongoingExistingInvestorDetails,
            },
          }
        : {
            ...roundCreationState.roundCreation,
            tab4: {
              newInvestors:
                roundCreationState.roundCreation.tab4?.newInvestors || [],
              ongoingExistingInvestorDetails,
            },
          };
    onNext(newList);
    setDialog({ open: false });
  };

  return (
    <div className="bg-white rounded-lg">
      <Dialog open={dialog.open} maxWidth="lg">
        {dialog.mode === "Delete" && (
          <DeleteModal
            onPrimaryAction={() => {
              handleSubmit(dialog.data?.id || "");
            }}
            onSecondaryAction={() => setDialog({ open: false })}
            data={
              "Are you sure you want to delete the entry? Data will be permanently removed. This action cannot be undone."
            }
          />
        )}
        {dialog.mode === "Edit" && (
          <AddNewInvestors
            actionMode="Edit"
            data={dialog.data}
            onBack={() => {
              setDialog({ open: false });
            }}
            onNext={onNext}
          />
        )}
      </Dialog>
      <div className="flex flex-col">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="md:rounded-lg">
              <table className="min-w-full bg-white">
                <thead>
                  <TableHeader>
                    <THead
                      fieldName="name"
                      fieldDisplayName="Name"
                      isFirstFeild={true}
                      selectedFieldName={sortField?.field || ""}
                      isAscending={sortField?.ascending || false}
                      isColumnFilter={false}
                      onChangeSort={() => {
                        setSortField({
                          field: "name",
                          ascending: !sortField?.ascending,
                        });
                      }}
                    />
                    <THead
                      fieldName="amount"
                      fieldDisplayName="Amount Invested"
                      showCurrency={true}
                      showSortingIcon={false}
                    />
                    <THead
                      fieldName="proRata"
                      fieldDisplayName="Holding %"
                      showSortingIcon={false}
                    />
                    <th></th>
                  </TableHeader>
                </thead>
                <tbody>
                  {currentTableData.map(
                    (existingInvestor: ExistingInvestorDetail) => (
                      <tr
                        key={`${existingInvestor.id}`}
                        className="border-t whitespace-nowrap border-dashed hover:bg-slate-50 text-[#030303] text-xs2 font-medium "
                      >
                        <td className="py-4 pr-3 text-sm whitespace-nowrap ">
                          <VStack className="flex items-start">
                            {existingInvestor.name.length > 24 ? (
                              <Tooltip text={existingInvestor.name}>
                                <p className={tableFirstColStyle}>
                                  {limitString(existingInvestor.name || "", 24)}
                                </p>
                              </Tooltip>
                            ) : (
                              <p className={tableFirstColStyle}>
                                {existingInvestor.name &&
                                  existingInvestor.name[0].toUpperCase() +
                                    existingInvestor.name.slice(1)}
                              </p>
                            )}

                            <p className="text-xxs1 font-medium text-[#C2C2C2]">
                              {existingInvestor.type}
                            </p>
                          </VStack>
                        </td>

                        <td className="px-3 py-4 whitespace-nowrap">
                          <VStack className="items-center">
                            <p className={tableEntryStyle}>
                              {existingInvestor?.totalAmountToInvest && (
                                <FormatNumberSpan
                                  value={existingInvestor.totalAmountToInvest}
                                  format={currency}
                                />
                              )}
                            </p>
                          </VStack>
                        </td>
                        <td className="px-3 py-4 whitespace-nowrap">
                          <VStack className="items-center">
                            <p className={tableEntryStyle}>
                              {existingInvestor?.postholdingPercentage?.toFixed(
                                3
                              ) ?? 0}
                            </p>
                          </VStack>
                        </td>

                        <td className="px-2 py-4 align-top">
                          <CTADropdown
                            dropdownClassName="bottom-auto"
                            actions={[
                              {
                                name: "Edit",
                                disabled: false,
                              },
                              {
                                name: "Delete",
                                disabled: false,
                              },
                            ]}
                            onAction={(action) =>
                              handleAction(action, existingInvestor)
                            }
                          />
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
              {currentTableData.length === 0 && (
                <EmptyTable
                  header="No Entry Found"
                  subHeader="start by adding new entry or click next to skip this step."
                />
              )}
            </div>
          </div>
        </div>
        <Box className="flex justify-between my-4 ">
          {_captableData.length >= 5 && (
            <BasicMenu
              defaultValue={pageSize}
              options={[5, 10, 20, 50, 100]}
              onOptionChange={(value) => {
                setCurrentPage(1);
                setPageSize(value);
              }}
            />
          )}
          <Pagination
            currentPage={currentPage}
            totalCount={_captableData?.length}
            pageSize={pageSize}
            onPageChange={(page: number) => setCurrentPage(page)}
          />
        </Box>
      </div>
    </div>
  );
}
