import { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Icon } from "@iconify/react";

import Dropzone, {
  getFilesFromEvent,
  IInputProps,
  ILayoutProps,
  IPreviewProps,
  IUploadParams,
} from "react-dropzone-uploader";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { toast } from "react-toastify";
import { Select } from "../../../components/shared/Select";
import { Box, HStack, VStack } from "../../../components/utils";
import { months, quarters, years } from "../../../constants/financeConstants";
import { SelectOptionType } from "../../../types/finance/Finance";
import { useAuthStore } from "../../../store/useAuthStore";
import { useGetFinancialData } from "../../../queries/finance";
import { queryClient } from "../../../queries/client";
import { Label } from "../../../components/shared/InputField";
import { downloadS3File } from "../../../utils/DownloadFile";

type Props = {
  handleClose: Function;
  show?: boolean;
  companyId: string;
  timelineType?: string;
  yearValue?: string;
  timelineValue?: string;
  isEdit?: boolean;
};

const FileUpload = (props: Props) => {
  const { companyId, accessToken } = useAuthStore.getState() || "";
  const { data, refetch } = useGetFinancialData(props.companyId || "");
  const [disable, setDisable] = useState<boolean>(true);
  const [loading, setLoading] = useState(false);
  const [isXbrl, setIsXbrl] = useState(false);
  const [financialType, setFinancialType] = useState<string>();
  const getUploadParams = async ({ meta, file }: any) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("file", file);
      const ret: IUploadParams = {
        url: (process.env.REACT_APP_NEW_CAPTABLE_API || "http://localhost:4000")
          .concat("v1/finance/postData?year=")
          .concat(formik.values.yearField.slice(-4))
          .concat("&timelineField=")
          .concat(timeline || "")
          .concat("&monthOrQtrField=")
          .concat(String(formik.values.monthOrQtrField))
          .concat(`&companyId=${props.companyId}`)
          .concat(`&financialType=${financialType || "StandAlone"}`),
        body: formData,
        headers: {
          Accesstoken: accessToken || "",
        },
      };
      return ret;
    } catch (err) {
      const ret: IUploadParams = {
        url: "",
      };
      setLoading(false);
      return ret;
    }
  };

  const getUploadXbrlParams = async ({ meta, file }: any) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("file", file);
      const ret: IUploadParams = {
        url: (process.env.REACT_APP_NEW_CAPTABLE_API || "http://localhost:4000")
          .concat("v1/finance/postXbrlData?year=")
          .concat(formik.values.yearField.slice(-4))
          .concat("&timelineField=")
          .concat(timeline || "")
          .concat("&monthOrQtrField=")
          .concat(String(formik.values.monthOrQtrField))
          .concat(`&companyId=${props.companyId}`)
          .concat(`&financialType=${financialType || "StandAlone"}`),
        body: formData,
        headers: {
          Companyid: companyId || "",
          Accesstoken: accessToken || "",
        },
      };

      return ret;
    } catch (err) {
      const ret: IUploadParams = {
        url: "",
      };
      setLoading(false);
      return ret;
    }
  };
  const options: SelectOptionType[] = [
    { label: "Year", value: 0 },
    { label: "Quarterly", value: 1 },
    { label: "Monthly", value: 2 },
  ];

  const financialTypeList: SelectOptionType[] = [
    { label: "StandAlone", value: 0 },
    { label: "Consolidated", value: 1 },
  ];

  const init = {
    financialType: "",
    timelineField: props.timelineType ?? "",
    monthOrQtrField:
      props.timelineType === "Monthly"
        ? months.find((o) => o.label === props.timelineValue)?.value || ""
        : quarters.find((o) => o.label === props.timelineValue)?.value || "",
    yearField: props.yearValue ?? "",
    xbrl: false,
    nonXbrl: true,
  };
  const validateSchema = Yup.object().shape({
    timelineField: Yup.number().required(),
    financialType: Yup.number().required().label("Financial Type"),
    monthOrQtrField: Yup.string().when("timelineField", {
      is: (timelineField: number) => timelineField !== 0,
      then: Yup.string().required("This field is required"),
      otherwise: Yup.string().notRequired(),
    }),
    yearField: Yup.string().required("This field is required"),
  });
  const formik = useFormik({
    initialValues: init,
    validationSchema: validateSchema,
    enableReinitialize: true,
    onSubmit: () => {},
  });

  useEffect(() => {
    if (Object.keys(formik.errors).length > 0) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [formik.errors]);

  useEffect(() => {
    if (Object.keys(formik.errors).length === 0) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, []);

  const handleChangeStatus = ({ xhr }: any) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.status === 200) {
          setTimeout(() => {
            queryClient.refetchQueries("getFinancialData");
          }, 3000);

          props.handleClose();
          setLoading(false);
        }
        if (xhr.status > 200) {
          const errorObject = JSON.parse(xhr?.response);
          toast(errorObject.errorMessage || "Something went wrong", {
            type: "error",
            autoClose: 2000,
          });
          props.handleClose();
        }
      };
    }
    refetch();
  };

  const handleSubmit = (files: any[], allFiles: any[]) => {
    const acceptedFile = files[0];
    allFiles.forEach((f: { remove: () => any }) => f.remove());
    acceptedFile.restart();
  };

  const handleSubmitXbrl = (files: any[], allFiles: any[]) => {
    const acceptedFile = files[0];
    allFiles.forEach((f: { remove: () => any }) => f.remove());
    acceptedFile.restart();
  };

  const Preview = ({ meta }: IPreviewProps) => {
    const { name, percent, status, previewUrl } = meta;
    if (status !== "done") {
      setLoading(false);
    }
    return (
      <div className="preview-box w-full">
        <img src={previewUrl} /> <span className="name">{name}</span> -{" "}
        {
          <>
            <span className="status">{status}</span>
          </>
        }
        {status !== "done" && (
          <span className="percent">({Math.round(percent)}%)</span>
        )}
        <div className="h-1 w-full bg-gray-300">
          <div
            style={{ width: `${percent}%` }}
            className={`h-full ${
              percent < 70 ||
              status === "error_upload" ||
              status === "exception_upload"
                ? "bg-red-600"
                : "bg-green-600"
            }`}
          ></div>
        </div>
        {loading && (
          <HStack className="text-orange-600 text-center w-full justify-center">
            <p className="indicator-progress" style={{ display: "block" }}>
              Please wait...
            </p>
            <Icon className="animate-spin" icon="lucide:loader-2" width={18} />
          </HStack>
        )}
      </div>
    );
  };

  const Input = ({
    accept,
    onFiles,
    files,
    getFilesFromEvent,
  }: IInputProps) => {
    const text = files.length > 0 ? "Add more files" : "Choose files";

    files.map((file) => (
      <h6 key={file.file.name}>
        {file.file.name} -{" "}
        {file.file.size > 1000000
          ? (file.file.size / 1000000).toFixed(2).concat(" MB")
          : (file.file.size / 1000).toFixed(2).concat(" KB")}
      </h6>
    ));

    return (
      <div>
        <div className="text-center p-4">
          {!props.isEdit ? (
            <h6 className="text-gray-600 text-lg font-semibold">
              Upload Either AOC4 file or Excel
            </h6>
          ) : (
            <h6 className="text-gray-600 text-lg font-semibold">
              Upload Either AOC4 file or Excel for{" "}
              {props.timelineType !== "Year" ? `${props.timelineValue}-` : ""}
              {props.yearValue}
            </h6>
          )}
        </div>
        <label>
          <input
            className="invisible"
            type="file"
            accept={accept}
            disabled={props.isEdit ? false : disable}
            onChange={async (e) => {
              const file = await getFilesFromEvent(e);
              onFiles(file);
            }}
          />

          <div className="flex flex-row mx-auto w-72 border-dashed border-2  text-center bg-pink-100 hover:border-orange-600 items-center cursor-pointer">
            <HStack className="py-4 px-20 flex">
              <div>
                <Icon icon="material-symbols:cloud-upload" height="30" />
              </div>
              <h3 className="py-1 px-2">Select File</h3>
            </HStack>
          </div>
          <p className="text-xs pt-1 text-center ">
            download the excel template{" "}
            <span
              onClick={() =>
                downloadS3File(
                  "https://equion-dev.s3.us-west-2.amazonaws.com/template-financials.xlsx"
                )
              }
              className="underline text-blue-600 font-medium cursor-pointer"
            >
              here
            </span>
          </p>
        </label>
      </div>
    );
  };

  const Layout = ({
    input,
    previews,
    submitButton,
    dropzoneProps,
    files,
    extra: { maxFiles },
  }: ILayoutProps) => (
    <div className="my-2">
      <div {...dropzoneProps} className="border-dashed border-hover-primary">
        <div>{input}</div>
      </div>
      {previews}
    </div>
  );

  const [year, setYear] = useState<number>(Number(props.yearValue!));
  const [month, setMonth] = useState<string>(props.timelineValue!);
  const [quarter, setQuarter] = useState<string>(props.timelineValue!);
  const [timeline, setTimeline] = useState<string>(props.timelineType!);
  function handleTimelineSelection(e: any, timeline?: string) {
    if (timeline === "timeline") {
      const index = options.findIndex(
        (item) => Number(e.target.value) === Number(item.value)
      );

      setTimeline(options[index].label);
    } else if (timeline === "Quarterly") {
      const index = quarters.findIndex(
        (item) => Number(e.target.value) === Number(item.value)
      );
      setQuarter(quarters[index].label);
    } else if (timeline === "year") {
      const index = years.findIndex(
        (item) => Number(e.target.value) === Number(item.value)
      );
      setYear(years[index].value);
    } else if (timeline === "Monthly") {
      const index = months.findIndex(
        (item) => Number(e.target.value) === Number(item.value)
      );
      setMonth(months[index].label);
      setTimeline("Monthly");
    }
  }

  return (
    <form className="min-w-full w-200">
      <Box className="px-10 text-lg font-medium border-b pt-4 pb-2 w-200">
        <h6 className="flex justify-between">
          <VStack>
            <p className="font-medium text-lg">Upload Financial data</p>
          </VStack>
          <XMarkIcon
            className="cursor-pointer h-6"
            onClick={() => {
              props.handleClose();
            }}
          />
        </h6>
      </Box>

      {props.isEdit ? (
        <div></div>
      ) : (
        <>
          <div className="px-10 flex flex-row w-200">
            <h3 className="text-base py-6">Select Financial Type</h3>

            <div className="p-4">
              <div className="hover:border-orange-500 w-36 rounded-md">
                <Select
                  className="px-2 py-1 border rounded-md"
                  value={
                    financialTypeList.find((o) => o.label === financialType)
                      ?.value
                  }
                  options={financialTypeList}
                  name="financialType"
                  placeholder={"--Select--"}
                  textGetter={(o) => o.label}
                  valueGetter={(o) => o.value}
                  onChange={(e) => {
                    if (e.target.value) {
                      formik.setFieldValue("financialType", e.target.value);
                      const index = options.findIndex(
                        (item) => Number(e.target.value) === Number(item.value)
                      );
                      setFinancialType(financialTypeList[index].label);
                    }
                  }}
                />
              </div>
              <div className="text-red-600">{formik.errors.financialType}</div>
            </div>
          </div>

          <div className="px-10 flex flex-row w-200">
            <h3 className="text-base py-6">Select Timeline</h3>

            <div className="p-4">
              <div className="hover:border-orange-500 w-36  rounded-md">
                <Select
                  className="px-2 py-1 border rounded-md"
                  value={options.find((o) => o.label === timeline)?.value}
                  options={options}
                  name="timelineField"
                  placeholder={"--Select--"}
                  textGetter={(o) => o.label}
                  valueGetter={(o) => o.value}
                  onChange={(e) => {
                    if (e.target.value) {
                      formik.setFieldValue("timelineField", e.target.value);
                      handleTimelineSelection(e, "timeline");
                    }
                  }}
                />
              </div>
            </div>

            {timeline && timeline !== "Year" && (
              <div className="p-4">
                <div className="hover:border-orange-500  w-36 rounded-md">
                  <Select
                    className="px-2 py-1 border rounded-md"
                    value={
                      timeline === "Monthly"
                        ? months.find((o) => o.label === month)?.value
                        : quarters.find((o) => o.label === quarter)?.value
                    }
                    placeholder={"--Select--"}
                    textGetter={(o) => o.label}
                    valueGetter={(o) => o.value}
                    options={timeline === "Monthly" ? months : quarters}
                    onChange={(e) => {
                      if (e.target.value) {
                        formik.handleChange(e);
                        formik.setFieldValue("monthOrQtrField", e.target.value);
                        handleTimelineSelection(e, timeline);
                      }
                    }}
                  />
                </div>

                <div className="text-red-600">
                  {formik.errors.monthOrQtrField}
                </div>
              </div>
            )}
            {timeline && (
              <div className="p-4">
                <div className="hover:border-orange-500 w-36  rounded-md">
                  <Select
                    className="bg-orange"
                    value={year}
                    name="yearField"
                    textGetter={(o) => o.label}
                    valueGetter={(o) => o.value}
                    options={years}
                    onChange={(e) => {
                      if (e.target.value) {
                        formik.setFieldValue("yearField", e.target.value);
                        handleTimelineSelection(e, "year");
                      }
                    }}
                  />
                </div>

                <div className="text-red-600">{formik.errors.yearField}</div>
              </div>
            )}
          </div>
        </>
      )}

      <div className="w-200">
        {!isXbrl && (
          <div className="m-10 border-dashed border-2  hover:border-orange-600">
            <Dropzone
              getUploadParams={getUploadParams}
              onChangeStatus={handleChangeStatus}
              multiple={false}
              maxFiles={1}
              accept={
                "application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              }
              PreviewComponent={Preview}
              maxSizeBytes={100000000}
              InputComponent={Input}
              autoUpload={true}
              LayoutComponent={Layout}
              onSubmit={handleSubmit}
            />
          </div>
        )}
        {isXbrl && (
          <div className="m-10 border-dashed border-2  hover:border-orange-600">
            <Dropzone
              getUploadParams={getUploadXbrlParams}
              onChangeStatus={handleChangeStatus}
              multiple={false}
              maxFiles={1}
              accept={"text/xml"}
              PreviewComponent={Preview}
              maxSizeBytes={100000000}
              InputComponent={Input}
              autoUpload={true}
              LayoutComponent={Layout}
              onSubmit={handleSubmitXbrl}
            />
          </div>
        )}
      </div>
      {/* )} */}
    </form>
  );
};

export default FileUpload;
