import { useRef } from "react";
import _ from "lodash";
import { HStack, VStack } from "../../components/utils";
import {
  tableEntryStyle,
  tableFirstColStyle,
} from "../../components/TableComponent";
import { formatDisplayDate } from "../../utils/date";
import { limitString } from "../../utils/string";
import { getSecurityColor } from "../../utils/historicUtilities";
import {
  FormatNumberSpan,
  formatCurrencyRoboto,
  formatToReadableNumber,
  getCurrencySymbolRoboto,
  getRoundCurrencySymbol,
} from "../../utils/currencyRoboto";
import {
  formatCurrency,
  getCurrency,
  getCurrencyType,
  getKeyByValue,
} from "../../utils/currencyFormatter";
import { CaptableTag } from "../../components/AllotmentTag";
import Avatar from "../Utility/Avatar";
import { currencyTypes } from "../../constants/CompanyCurrencyType";
import { checkVoid } from "../../utils/transactionUtils";

export function TransactionNameRender(data: any) {
  return (
    <td className="flex flex-col text-sm text-left">
      <div className="flex">
        <div className="text-left pr-2">
          {data.value.name !== "Total" ? (
            <Avatar name={data.value.name || ""} />
          ) : (
            ""
          )}
        </div>
        <div
          className={` ${
            data.value.name === "Total"
              ? "pt-2 cursor-pointer"
              : "ml-2 cursor-pointer"
          } `}
        >
          <div>
            <p className="text-left font-semibold text-xs2 text-[#464E5F]">
              {limitString(data.value.name || "", 24)}
            </p>
            {data.value.name !== "Total" ? (
              <CaptableTag
                tagName={data.value.type ?? "Others"}
                dataEventName={"captableShareholderTypeDrilling"}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </td>
  );
}

export function SecurityTypeRender(data: any) {
  return (
    <div className="whitespace-nowrap py-1">
      <p
        className={`${getSecurityColor(
          data.data.securityType
        )}  text-xs text-center w-fit align-middle overflow-hidden font-semibold leading-5 rounded-md mb-1`}
      >
        {data.data.securityType}
      </p>
      {data.data.transactionName.name !== "Total" && (
        <HStack className="text-gray-500  text-xs1">
          <HStack>
            PAR :
            {formatCurrencyRoboto(
              data.data.par || 0,
              data.data.currencyType,
              data.data.currencySymbol
            )}
          </HStack>
          {data.data.conversionRatio !== "1: 1" &&
            data.data.securityType !== "Equity" && (
              <p className="text-black"> | </p>
            )}
          {data.data.conversionRatio !== "1: 1" &&
            data.data.securityType !== "Equity" && (
              <p>
                CR :{data.data.conversionRatio?.split(":")[0]} :{" "}
                {parseFloat(data.data.conversionRatio?.split(":")[1]).toFixed(
                  2
                )}
              </p>
            )}
        </HStack>
      )}
    </div>
  );
}

export function SharePriceRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-2">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.data?.sharePrice,
          format: data.data.currencyType,
        })}
      </p>
    </div>
  );
}

export function NumberOfSharesRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-2">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.data?.numberOfShares,
          format: data.data.currencyType,
        })}
      </p>
    </div>
  );
}

export function AmountInvestedRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-2">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.data?.amountInvested,
          format: data.data.currencyType,
        })}
      </p>
    </div>
  );
}

export function AmountInInvestmentRender(data: any) {
  const currency = getCurrencyType();
  const roundSymbol = getRoundCurrencySymbol(
    data.data.amountInInvestment.roundCurrency
  );
  const invSymbol = getRoundCurrencySymbol(
    data.data.amountInInvestment.investmentCurrency
  );
  const roundCurrency = getCurrency(data.data.amountInInvestment.roundCurrency);
  const invCurrency = getCurrency(
    data.data.amountInInvestment.investmentCurrency
  );
  return (
    <div className="whitespace-nowrap pt-2">
      {(currency !== invCurrency || currency !== roundCurrency) &&
        data.data.amountInInvestment.investmentAmount &&
        data.data.amountInInvestment.investmentAmount !==
          data.data.amountInvested && (
          <VStack className="items-left">
            <HStack>
              <span className={`${tableEntryStyle} pr-1`}>{invSymbol}</span>
              <p className={tableEntryStyle}>
                <FormatNumberSpan
                  value={data.data.amountInInvestment.investmentAmount}
                  format={invCurrency}
                  position="bottom-0"
                />
              </p>
            </HStack>
            <HStack className="text-gray-500  text-xs1">
              <span className={`${tableEntryStyle} pr-1`}>{roundSymbol}</span>
              <FormatNumberSpan
                value={data.data.amountInInvestment.roundAmount}
                format={roundCurrency}
                position="bottom-4"
              />
              (1 {getKeyByValue(currencyTypes, invCurrency)} ={" "}
              {data.data.amountInInvestment.exchangeRate}{" "}
              {getKeyByValue(currencyTypes, roundCurrency)})
            </HStack>
          </VStack>
        )}
      {(currency !== invCurrency || currency !== roundCurrency) &&
        data.data.amountInInvestment.investmentAmount ===
          data.data.amountInvested && (
          <VStack className="items-left">
            <HStack>
              <span className={`${tableEntryStyle} pr-1`}>{invSymbol}</span>
              <p className={tableEntryStyle}>
                <FormatNumberSpan
                  value={data.data.amountInvested}
                  format={invCurrency}
                />
              </p>
            </HStack>
          </VStack>
        )}
      {(currency === invCurrency || currency === roundCurrency) && (
        <HStack>
          <span className={`${tableEntryStyle} pr-1`}>{invSymbol}</span>
          {formatToReadableNumber({
            value: data.data?.amountInvested,
            format: data.data.currencyType,
          })}
        </HStack>
      )}
    </div>
  );
}

export function BuyerNameRender(data: any) {
  return (
    <td className="flex flex-col text-sm text-left">
      <div className="flex">
        <div className="text-left pr-2">
          {data.value.name !== "Total" ? (
            <Avatar name={data.value.name || ""} />
          ) : (
            ""
          )}
        </div>
        <div
          className={` ${
            data.value.name === "Total"
              ? "pt-2 cursor-pointer"
              : "ml-2 cursor-pointer"
          } `}
        >
          <div>
            <p className="text-left font-semibold text-xs2 text-[#464E5F]">
              {limitString(data.value.name || "", 24)}
            </p>
            {data.value.name !== "Total" ? (
              <CaptableTag
                tagName={data.value.type ?? "Others"}
                dataEventName={"captableShareholderTypeDrilling"}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </td>
  );
}

export function SecondarySharePriceRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-2">
      <p className={tableEntryStyle}>
        {checkVoid(data.data.sharePrice)
          ? "-"
          : formatToReadableNumber({
              value:
                (data.data.amountInvested || 0) /
                (data.data.numberOfShares || 0),
              format: data.data.currencyType,
            })}
      </p>
    </div>
  );
}

export function SecondaryNumberOfSharesRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-2">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.data?.numberOfShares,
          format: data.data.currencyType,
        })}
      </p>
    </div>
  );
}

export function SellerNameRender(data: any) {
  return (
    <td className="whitespace-nowrap pt-2">
      <div>
        {data.data.sellerName?.length > 24 ? (
          <p className={`${tableFirstColStyle} cursor-pointer`}>
            {limitString(data.data.sellerName || "", 24)}
          </p>
        ) : (
          <p className={`${tableFirstColStyle} cursor-pointer`}>
            {limitString(data.data.sellerName || "", 24)}
          </p>
        )}
      </div>
    </td>
  );
}

export function SecondarySecurityTypeRender(data: any) {
  return (
    <td className="whitespace-nowrap  py-1 text-sm text-gray-500">
      <div>
        {data.data.securityType?.length > 24 ? (
          <p className="text-gray-500  ">
            <span
              className={`inline-flex px-2 ${getSecurityColor(
                data.data.securityType
              )} text-xs  w-24 align-middle font-semibold leading-5 rounded-sm mb-1 text-center justify-center`}
            >
              {limitString(data.data.securityType || "", 40)}
            </span>
          </p>
        ) : (
          <p className="text-gray-500  ">
            <span
              className={`inline-flex px-2 ${getSecurityColor(
                data.data.securityType
              )} text-xs  w-24 align-middle font-semibold leading-5 rounded-sm mb-1 text-center justify-center`}
            >
              {limitString(data.data.securityType || "", 40)}
            </span>
          </p>
        )}
      </div>
      {data.data.buyerName.name !== "Total" && (
        <p className="text-gray-500 text-center  text-xs1">
          <span>
            PAR : {formatCurrency(data.data.par || 0, data.data.currency)}
          </span>
          {data.data.conversionRatio !== "1: 1" &&
            data.data.securityType !== "Equity" && (
              <span className="text-black"> | </span>
            )}
          {data.data.conversionRatio !== "1: 1" &&
            data.data.securityType !== "Equity" && (
              <span>CR : {data.data.conversionRatio}</span>
            )}
        </p>
      )}
    </td>
  );
}

export function SecondaryAmountInvestedRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-2">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.data?.amountInvested,
          format: data.data.currencyType,
        })}
      </p>
    </div>
  );
}

export function SecondaryAmountInInvestmentRender(data: any) {
  const symbol = getCurrencySymbolRoboto();
  const currency = getCurrencyType();

  const invSymbol = getRoundCurrencySymbol(
    data.data.amountInInvestment.investmentCurrency
  );
  const invCurrency = getCurrency(
    data.data.amountInInvestment.investmentCurrency
  );
  return (
    <div className="whitespace-nowrap pt-2">
      {currency !== invCurrency &&
        data.data.amountInInvestment.exchangeRate !== 1 && (
          <VStack className="items-left">
            <HStack>
              <span className={`${tableEntryStyle} pr-1`}>{invSymbol}</span>
              <p className={tableEntryStyle}>
                <FormatNumberSpan
                  value={data.data.amountInInvestment.investmentAmount}
                  format={invCurrency}
                  position="bottom-0"
                />
              </p>
            </HStack>
            <HStack className="text-gray-500  text-xs1">
              <span className={`${tableEntryStyle} pr-1`}>{symbol}</span>
              <FormatNumberSpan
                value={data.data.amountInInvestment.amountInvested}
                format={currency}
                position="bottom-4"
              />
              (1 {getKeyByValue(currencyTypes, invCurrency)} ={" "}
              {data.data.amountInInvestment?.exchangeRate}{" "}
              {getKeyByValue(currencyTypes, currency)})
            </HStack>
          </VStack>
        )}

      {currency === invCurrency && (
        <HStack>
          <span className={`${tableEntryStyle} pr-1`}>{invSymbol}</span>
          {formatToReadableNumber({
            value: data.data?.amountInvested,
            format: data.data.currencyType,
          })}
        </HStack>
      )}
    </div>
  );
}

export function SecondaryDateRender(data: any) {
  return (
    <div className="whitespace-nowrap pt-2">
      <p className={tableEntryStyle}>
        {formatDisplayDate(data.data?.dateOfTransaction)}
      </p>
    </div>
  );
}
