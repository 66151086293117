import { Icon } from "@iconify/react";
import { Formik, Form, useFormik } from "formik";
import * as Yup from "yup";
import { Dialog } from "@mui/material";
import { useNavigate } from "react-router";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { Input, Label } from "../../components/shared/InputField";
import { SwitchButton } from "../../components/shared/SwitchButton";
import { Box, Error, HStack, VStack } from "../../components/utils";
import {
  useDeleteOnGoingTransaction,
  usePostOnGoingTransaction,
  useRoundExcelUpload,
  useOngoingTransactionRound,
  usePostTransactionSecondaryDetail,
  useDiscardQuickRound2,
} from "../../queries/transactionRound";
import {
  ButtonSize,
  LoaderCTAButton,
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../quickRound/CTAButtonComponents";
import {
  SecondaryTransaction,
  QuickSecondaryTransactionModel,
} from "./RoundTransactionModel";
import { useAuthStore } from "../../store";
import { useQuickSecondaryTransactionStore } from "../../store/useQuickTransactionStore";
import AlertDialog from "../../components/shared/AlertDialog";
import { useNewShareholderInstrumentStore } from "../../store/newShareholderAndInstrumentStore";
import convertToBase64 from "../../utils/convertToBase64";
import AddTransactionSecondary from "./AddTransactionSecondary";
import FileUploadedUI from "../shareHolderRights/FileUploadedUI";
import { downloadS3File } from "../../utils/DownloadFile";
import { useGetInstrumentAndShareHolder } from "../../queries/quickRound";
import { useShareholderInstrumentStore } from "../../store/shareholderInstrumentStore";
import { getCompanyCurrency } from "../../utils/currencyFormatter";

const transactionSchema = Yup.object().shape({
  name: Yup.string().required().label("Secondary Name"),
  date: Yup.string().required().label("Date"),
});

function QuickAddSecondary() {
  const companyId = useAuthStore.getState().companyId || "";
  const {
    state: template,
    setState: setTemplate,
    reset: resetTemplate,
  } = useQuickSecondaryTransactionStore();

  const [minimum, setMinimum] = useState(false);
  const { mutate: deleteOnGoingTransaction } =
    useDeleteOnGoingTransaction(companyId);
  const { mutate: postOnGoingTransaction } = usePostOnGoingTransaction();
  const { mutate: postSecondaryTransaction } =
    usePostTransactionSecondaryDetail(companyId);
  const { mutate: secondaryExcelUpload } = useRoundExcelUpload(companyId);

  const pathName = window.location.href.split("/");
  const roundType = pathName[4];

  const [dialog, setDialog] = useState<{
    open: boolean;
    message?: string;
  }>({
    open: false,
  });
  const { data: ongoingEvent } = useOngoingTransactionRound();
  const navigate = useNavigate();
  const { mutate: discardQuickRound } = useDiscardQuickRound2();
  const displayDiscardQuickRoundPopUp = () => {
    setDialog({
      open: true,
      message: `Do you want to discard the current ongoing quick ${roundType} ?`,
    });
  };

  const fileRef2 = useRef() as React.MutableRefObject<HTMLInputElement>;

  const onClickAoaFunction = () => {
    fileRef2.current.click();
  };

  const initialVal = {
    name: "",
    size: 0,
    type: "",
  };

  const onClickAoaDelete = () => {
    fileRef2.current.value = "";
    setTemplate({ ...template, fileType: initialVal });
  };

  const handleDiscard = () => {
    setDialog({ open: false });
    discardQuickRound();
    resetTemplate();
    deleteOnGoingTransaction(companyId);
    navigate("/transactions/allTransactions");
    secondaryTransactionStore.setSecondary([]);
  };

  const secondaryTransactionStore = useNewShareholderInstrumentStore();
  const shareholderAndInstrumentstore = useShareholderInstrumentStore();

  const initialValues: QuickSecondaryTransactionModel = {
    eventId: template.eventId ?? "",
    stepNo: template.stepNo ?? 1,
    transactionType: template.transactionType ?? "secondary",
    name: template.name ?? "",
    date: template.date ?? "",
    secondaryTransactions: template.secondaryTransactions ?? [],
    companyId: template.companyId ?? "",
  };

  const {
    refetch: getInstrumentAndShareHolder,
    data: instrumentShareholderInfoData,
  } = useGetInstrumentAndShareHolder(companyId);

  useEffect(() => {
    if (
      instrumentShareholderInfoData?.shareholders.length !==
      shareholderAndInstrumentstore.shareholders.length
    )
      shareholderAndInstrumentstore.setShareholders(
        instrumentShareholderInfoData?.shareholders || []
      );
    if (
      instrumentShareholderInfoData?.instrumentClasses.length !==
      shareholderAndInstrumentstore.instrumentClasses.length
    )
      shareholderAndInstrumentstore.setInstrumentClass(
        instrumentShareholderInfoData?.instrumentClasses || []
      );
  }, [instrumentShareholderInfoData]);

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: transactionSchema,
    initialValues,
    onSubmit: (values) => {
      const secondaryTransactions: SecondaryTransaction[] =
        secondaryTransactionStore.secondary.map((element) => ({
          date: element.date || "",
          amount: element.amountInvested || 0,
          numberOfShares: element.numberOfShares || 0,
          //Dirty commit shareNumber: element.distinctNumberShares || "",
          companyBuyerInvestorId: element.buyerInvestorId || "",
          companySellerInvestorId: element.sellerInvestorId || "",
          instrumentClassId: element.instrumentClassId || "",
          currency: element.transactionCurrency || getCompanyCurrency(),
          exchangeRate: element.exchangeRate || 1,
        })) || [];

      const finalData: QuickSecondaryTransactionModel = {
        name: values.name,
        date: values.date,
        companyId,
        secondaryTransactions,
      };

      postSecondaryTransaction(finalData, {
        onSuccess: (data) => {
          postOnGoingTransaction({
            ...finalData,
            eventId: data.id,
          });

          setTemplate({
            ...finalData,
            stepNo: 2,
            eventId: data.id,
          });
          toast("Secondary Detail Uploaded Successfully!", {
            type: "success",
            autoClose: 2000,
          });
        },

        onError: (err: any) => {
          toast(err.response.data.errorMessage, {
            type: "error",
            autoClose: 2000,
          });
        },
      });
    },
  });

  //   async function handleFileUpload(e: React.ChangeEvent<HTMLInputElement>) {
  //     if (!e?.target?.files?.[0]) return;
  //     const file = e.target.files[0];
  //     const base64 = await convertToBase64(file);
  //     roundExcelUpload(
  //       { file: base64 },
  //       {
  //         onSuccess: (data) => {
  //           setTemplate({
  //             stepNo: 2,
  //             transactionType: "secondary",
  //             eventId: data.id,
  //           });
  //           toast("Excel Uploaded Successfully", {
  //             type: "success",
  //             autoClose: 2000,
  //           });
  //         },
  //         onError: (err: any) => {
  //           toast(err.response.data.errorMessage, {
  //             type: "error",
  //             autoClose: 2000,
  //           });
  //         },
  //       }
  //     );
  //   }

  function updateAoa(e: React.ChangeEvent<HTMLInputElement>) {
    if (!e?.target?.files?.[0]) return;
    const file = e.target.files[0];
    const allowedFileExtensions = [".xls", ".xlsx"];
    if (
      allowedFileExtensions.some((extension) => file.name.endsWith(extension))
    ) {
      setAoaFile(file);
      setTemplate({
        ...template,
        fileType: {
          name: file.name,
          size: file.size,
          type: file.type,
        },
      });
    } else {
      toast(
        `Invalid file type, allowed file types are ${allowedFileExtensions.join(
          ", "
        )}`,
        { type: "error" }
      );
    }
  }

  const [loading, setLoading] = useState(false);
  const [aoaFile, setAoaFile] = useState<File>();
  return (
    <VStack>
      <Box className="">
        {/* <Form> */}
        <VStack className="bg-white rounded-lg p-6">
          <HStack className="justify-between pb-8">
            <VStack>
              <p className="text-xl font-semibold text-gray-dark">
                Secondary Details
              </p>
              <Box className="text-xs font-medium text-gray-light">
                Fill the secondary details to create a quick round
              </Box>
            </VStack>
          </HStack>

          <Dialog open={dialog.open} maxWidth="md">
            <AlertDialog
              onClose={() => setDialog({ open: false })}
              open={dialog.open}
              message={dialog.message}
              onPrimaryAction={handleDiscard}
              onSecondaryAction={() => setDialog({ open: false })}
            />
          </Dialog>
          <VStack
            className={`mb-4 ${template.isManual ? "gap-6" : " "} hidden`}
          >
            <Label className="text-sm font-medium">
              Select method of secondary creation
            </Label>
            <HStack className="flex items-center gap-4">
              <Icon
                onClick={() => {
                  setTemplate({
                    ...template,
                    isManual: false,
                  });
                }}
                icon={
                  !template.isManual
                    ? "ic:round-radio-button-checked"
                    : "ic:round-radio-button-unchecked"
                }
                className=" text-primary"
                width="18"
                height="18"
              />

              <Label className="text-sm font-medium">
                Upload Excel with secondary details
              </Label>
              <input
                ref={fileRef2}
                onChange={updateAoa}
                multiple={false}
                type="file"
                accept=".xls, .xlsx"
                hidden
              />
              {/* {!template.isManual && ( */}
              <VStack className={`${template.isManual ? "hidden" : ""}`}>
                <FileUploadedUI
                  uploadText={"Upload Excel Sheet"}
                  fileDetail={
                    template.fileType ?? {
                      name: "",
                      size: 0,
                      type: "",
                    }
                  }
                  callBack={onClickAoaFunction}
                  onClickDelete={onClickAoaDelete}
                  // callBack={() => {}}
                  // onClickDelete={() => {}}
                />
                <p className="text-xs pt-1">
                  download the template{" "}
                  <span
                    onClick={() =>
                      downloadS3File(
                        "https://equion-dev.s3.us-west-2.amazonaws.com/RoundExcelTemplate.xlsx"
                      )
                    }
                    className="underline text-blue-600 font-medium cursor-pointer"
                  >
                    here
                  </span>
                </p>
              </VStack>
              {/* )} */}

              {/* https://hissa-prod.s3.ap-south-1.amazonaws.com/Round+template.xlsx */}

              {/* {!excelUpload && (
                <FileInput
                  accept=".xls, .xlsx"
                  file={"upload round excel"}
                  onChange={(e) => {
                    handleFileUpload(e);
                  } */}
              {/* } */}
              {/* /> */}
              {/* )} */}
            </HStack>
            <HStack className=" py-2 items-center mb-4 justify-between gap-8">
              <HStack className=" py-2 items-center mb-4">
                <Icon
                  onClick={() =>
                    setTemplate({
                      ...template,
                      isManual: true,
                      fileName: "",
                      stepNo: 1,
                    })
                  }
                  icon={
                    template.isManual
                      ? "ic:round-radio-button-checked"
                      : "ic:round-radio-button-unchecked"
                  }
                  className=" text-primary"
                  width="18"
                  height="18"
                />
                <Label className="px-4 text-sm font-medium">
                  Enter Manually
                </Label>
              </HStack>
            </HStack>
          </VStack>

          {!template.isManual && (
            <VStack className="gap-8 -mt-4">
              <HStack className="justify-start gap-8 flex-wrap">
                <VStack className="">
                  <Label className=" w-48 text-sm font-medium whitespace-nowrap">
                    Secondary Name
                  </Label>
                  <VStack>
                    <Input
                      type="text"
                      placeholder="Enter Secondary Name"
                      className="w-60"
                      {...formik.getFieldProps("name")}
                    />
                    {formik.touched?.name && formik.errors?.name && (
                      <Error text={formik.errors?.name} />
                    )}
                  </VStack>
                </VStack>

                <VStack className="">
                  <Label className="w-48 text-sm font-medium whitespace-nowrap">
                    Close Date
                  </Label>
                  <VStack>
                    <Input
                      type="date"
                      className="w-60"
                      placeholder="12-12-2022"
                      {...formik.getFieldProps("date")}
                    />
                    {formik.touched?.date && formik.errors?.date && (
                      <Error text={formik.errors?.date!} />
                    )}
                  </VStack>
                </VStack>
              </HStack>
            </VStack>
          )}
        </VStack>
        {!template.isManual && (
          <div className="mt-8">
            <AddTransactionSecondary />
          </div>
        )}
        <HStack className="justify-between gap-4 mt-8">
          <SecondaryCTAButton
            type="reset"
            event-name="Discard Secondary"
            buttonSize={ButtonSize.SMALL}
            onClick={displayDiscardQuickRoundPopUp}
          >
            Discard
          </SecondaryCTAButton>

          {!loading && (
            <PrimaryCTAButton
              loading={loading}
              event-name="Create Secondary"
              buttonSize={ButtonSize.SMALL}
              onClick={async () => {
                if (!template.isManual && aoaFile) {
                  const base64 = await convertToBase64(aoaFile);
                  setLoading(true);
                  secondaryExcelUpload(
                    { file: base64 },
                    {
                      onSuccess: async (data) => {
                        await getInstrumentAndShareHolder().then((data) => {
                          shareholderAndInstrumentstore.setInstrumentClass(
                            data?.data?.instrumentClasses || []
                          );
                          shareholderAndInstrumentstore.setShareholders(
                            data?.data?.shareholders || []
                          );
                          shareholderAndInstrumentstore.setInstrumentClassSubTypes(
                            data?.data?.instrumentClassSubTypes || {}
                          );
                        });
                        setLoading(false);

                        setTemplate({
                          ...template,
                          stepNo: 2,
                          isManual: false,
                          fileType: template.fileType,
                          transactionType: "secondary",
                        });
                        toast("Excel Uploaded Successfully", {
                          type: "success",
                          autoClose: 2000,
                        });
                      },
                      onError: (err: any) => {
                        setLoading(false);
                        toast(err.response.data.errorMessage, {
                          type: "error",
                          autoClose: 2000,
                        });
                      },
                    }
                  );
                } else if (
                  secondaryTransactionStore.secondary.length > 0 &&
                  formik.errors
                ) {
                  setLoading(true);
                  setMinimum(false);
                  formik.handleSubmit();
                } else {
                  setMinimum(true);
                }
                setLoading(false);
              }}
              type="submit"
            >
              Create Secondary
            </PrimaryCTAButton>
          )}
        </HStack>

        {template.isManual && minimum && (
          <p className="text-red-500 text-xs text-right font-medium ">
            * Required minimum 1 secondary transaction
          </p>
        )}
      </Box>
    </VStack>
  );
}

export default QuickAddSecondary;
