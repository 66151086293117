import {
  QuickBonusTransactionModel,
  QuickBuybackTransactionModel,
  QuickRoundTransactionModel,
  QuickSecondaryTransactionModel,
  QuickSplitTransactionModel,
} from "../pages/newQuickTransaction/RoundTransactionModel";
import { zustandState } from "./zustandState";

export const initialRoundState: QuickRoundTransactionModel = {
  eventId: "",
  stepNo: 1,
  roundType: "EXCEL",
  transactionType: "round",
  companyId: "",
  fileName: "",
  fileType: {
    name: "",
    size: 0,
    type: "",
  },
  newRoundDetails: undefined,
  newRoundInput: undefined,
  conversion: [],
};

const initialSecondaryState: QuickSecondaryTransactionModel = {
  eventId: "",
  stepNo: 1,
  companyId: "",
  transactionType: "secondary",
  name: undefined,
  date: undefined,
  secondaryTransactions: [],
};
const initialBonusState: QuickBonusTransactionModel = {
  eventId: "",
  stepNo: 1,
  transactionType: "bonus",
  companyId: "",
  bonusName: "",
  targetPPS: "",
  bonusRatio: "",
  applicableForAllSecurities: true,
  date: "",
  quickBonusTransactions: [],
};

const initialBuybackState: QuickBuybackTransactionModel = {
  eventId: "",
  companyId: "",
  quickBuybackInput: undefined,
  stepNo: 1,
  transactionType: "buyback",
};
const initialSplitState: QuickSplitTransactionModel = {
  eventId: "",
  stepNo: 1,
  transactionType: "split",
  companyId: "",
  quickSplitInput: undefined,
};

export const useQuickRoundTransactionStore = zustandState(initialRoundState);
export const useQuickSecondaryTransactionStore = zustandState(
  initialSecondaryState
);
export const useQuickBonusTransactionStore = zustandState(initialBonusState);
export const useQuickBuybackTransactionStore =
  zustandState(initialBuybackState);
export const useQuickSplitTransactionStore = zustandState(initialSplitState);
