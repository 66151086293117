import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { PlusIcon } from "@heroicons/react/24/solid";
import { Label } from "../../components/shared/InputField";
import { Select } from "../../components/shared/Select";
import { Box, HStack, VStack } from "../../components/utils";
import {
  useLiquidationPreference,
  useSecurityOverviewDetails,
} from "../../queries/securities";
import BCHeader from "../../shared/BCHeader";
import { useAuthStore } from "../../store";
import { useSelectedSecurityTypeStoreStore } from "../../store/selectedSecurityTypeStore";
import { useCanUserAccess } from "../../store/useRoleGrantStore";
import { LiquidationStack, SecurityDetail } from "../../types/SecurityModel";
import { PrimaryCTAButton } from "../quickRound/CTAButtonComponents";
import {
  getCurrencySymbol,
  getCurrencyType,
} from "../../utils/currencyFormatter";

import {
  useInvLiquidationPreference,
  useInvSecurityOverviewDetails,
} from "../../queries/investorPortal";
import AGGridSecurityClassTable from "./SecurityClassAgTable";
import PercentageCardDesign from "../../components/PercentageCardDesign";
import DashboardSecurityBarChart from "../dashboardPage/DashboardSecurityBarChart";
import AGGridSecurityTypeTable from "./SecurityTypeAgTable";
import AgGridLpTable from "./LiquidationPreferenceAgGridTable";

export default function SecurityPage() {
  const { cId, token } = useParams();
  const companyId = useAuthStore.getState().companyId || "";
  const { isPlaceholderData, data: _securityDetail } = cId
    ? useInvSecurityOverviewDetails(cId, token || "")
    : useSecurityOverviewDetails();
  const canUserAddSecurity = useCanUserAccess("create", "instrumentclass");
  const { data: _liquidation } = cId
    ? useInvLiquidationPreference(cId, token || "")
    : useLiquidationPreference();
  const securityTypeList = [];
  const securityTypeStore = useSelectedSecurityTypeStoreStore();
  const navigate = useNavigate();

  const currencySymbol = getCurrencySymbol();
  const currencyType = getCurrencyType();

  const securityList = useMemo(
    () => _securityDetail?.securityDetails,
    [_securityDetail]
  );

  useEffect(() => {
    _securityDetail?.securityDetails.map((securityType) =>
      securityTypeList.push(securityType)
    );
  }, [_securityDetail, isPlaceholderData]);

  const filteredLiquidation = _liquidation?.filter(
    (item) => item.rank > 0 && item.lpValue > 0
  );

  return (
    <div
      className={`${cId ? "bg-white px-4" : ""} ${
        isPlaceholderData ? "loading" : "min-h-full"
      }`}
    >
      {(securityList?.length || 0) > 0 && (
        <VStack className="gap-8">
          <HStack className="items-center justify-between">
            <BCHeader
              className="items-baseline"
              bcTitle="Security"
              cin={cId ?? ""}
              token={token ?? ""}
            />
            {canUserAddSecurity && !cId && (
              <PrimaryCTAButton
                event-name="Add New Security Class"
                className="h-8 text-xs2"
                onClick={() => {
                  navigate("/security/addNewSecurity");
                }}
              >
                New Security
              </PrimaryCTAButton>
            )}
          </HStack>

          <HStack className="gap-8 pb-1 overflow-auto scrollbar-hide">
            {securityTypeStore.securityType === "All" &&
              _securityDetail?.securityDetails
                .sort((a, b) => {
                  const aIndex = a.holding;
                  const bIndex = b.holding;
                  return bIndex - aIndex;
                })
                .map((security) => (
                  <PercentageCardDesign
                    key={security.securityType + security.noOfShares}
                    type="Security"
                    data={{
                      name: security.securityType,
                      type: security.securityType,
                      percentage: security.holding * 100,
                      keyValue: [
                        {
                          key: "No. of Securities",
                          value: security.noOfShares,
                          isAmount: false,
                        },
                        {
                          key: "No. of Classes",
                          value: security.noOfClassess,
                          isAmount: false,
                        },
                      ],
                    }}
                    onClick={() => {
                      if (cId) {
                        navigate(
                          `/inv/security/individual/${security.securityType}/${cId}/${token}`
                        );
                      } else {
                        navigate(
                          `/security/individual/${security.securityType}`
                        );
                      }
                    }}
                    currencySymbol={currencySymbol}
                    currencyType={currencyType}
                  />
                ))}
          </HStack>

          {filteredLiquidation?.length! > 0 && (
            <AgGridLpTable data={_liquidation!} />
          )}
          {_securityDetail && (
            <AGGridSecurityTypeTable
              isInvestorPortal={!!cId}
              cid={cId ?? ""}
              token={token ?? ""}
              securityType={securityTypeStore.securityType}
              securityList={securityList || []}
            />
          )}
        </VStack>
      )}
    </div>
  );
}
