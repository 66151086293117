import Tooltip from "../../components/shared/Tooltip";
import { HStack, VStack } from "../../components/utils";
import { ModelHeaders } from "../../types/Modeling";
import { getCurrencyType } from "../../utils/currencyFormatter";
import {
  FormatNumberSpan,
  getCurrencySymbolRoboto,
} from "../../utils/currencyRoboto";
import { limitString } from "../../utils/string";
import DarkProgressBar from "./DarkProgressBar";

const ModelingHeadersStack = ({
  currentCompanyDetail,
  roundName,
  newPPS,
}: {
  currentCompanyDetail: ModelHeaders;
  roundName: string;
  newPPS?: number;
}) => {
  const symbol = getCurrencySymbolRoboto();
  const currency = getCurrencyType();

  return (
    <div className="bg-secondary rounded-md px-4 shadow-sm placeholderLoader">
      <HStack className="gap-6">
        <VStack className="px-2 py-3 rounded-md shadow-box w-1/4 justify-between  gap-2">
          <HStack className="items-center gap-2 h-6">
            <p className="text-xs text-white font-normal whitespace-nowrap">
              Round Name:
            </p>
            <HStack className="items-baseline text-white">
              <Tooltip text={roundName}>
                <p className="text-base whitespace-nowrap font-semibold">
                  {limitString(roundName, 16)}
                </p>
              </Tooltip>
            </HStack>
          </HStack>

          <HStack className="items-center gap-2">
            <p className="text-xs text-white font-normal whitespace-nowrap">
              Lead Investor:
            </p>
            <HStack className="items-baseline text-white">
              <Tooltip text={currentCompanyDetail.leadInvestor}>
                <p className="text-base whitespace-nowrap font-semibold">
                  {limitString(currentCompanyDetail.leadInvestor, 16)}
                </p>
              </Tooltip>
            </HStack>
          </HStack>
        </VStack>
        <div className="bg-slate-400  w-0.5 h-10 my-auto"></div>
        <VStack className="px-2 py-3 rounded-md shadow-box w-1/4 justify-between  gap-2">
          <HStack className="items-center gap-2 h-6">
            <p className="text-xs text-white font-normal whitespace-nowrap">
              Round Size:
            </p>
            <HStack className="items-baseline text-white">
              <span className="pr-1 text-xs font-normal">{symbol}</span>
              <p className="text-lg font-medium  whitespace-nowrap">
                <FormatNumberSpan
                  value={currentCompanyDetail.roundSize}
                  format={currency}
                />
              </p>
            </HStack>
          </HStack>
          <HStack className="items-center gap-2">
            <p className="text-xs text-white font-normal whitespace-nowrap">
              FDB Share Price:
            </p>
            <HStack className="items-baseline text-white">
              <span className="pr-1 text-xs font-normal">{symbol}</span>
              <p className="text-lg font-medium  whitespace-nowrap">
                <FormatNumberSpan
                  value={newPPS || currentCompanyDetail.fdbSharePrice}
                  format={currency}
                />
              </p>
            </HStack>
          </HStack>
        </VStack>
        <div className="bg-slate-400  w-0.5 h-10 my-auto"></div>
        <VStack className="px-2 py-3 rounded-md shadow-box w-1/4 justify-between  gap-2">
          <HStack className="items-center gap-2 h-6">
            <p className="text-xs text-white font-normal whitespace-nowrap">
              Post-money Valuation:
            </p>
            <HStack className="items-baseline text-white ">
              <span className="pr-1 text-xs font-normal">{symbol}</span>
              <p className="text-lg font-medium whitespace-nowrap">
                <FormatNumberSpan
                  value={currentCompanyDetail.postMoneyValuation}
                  format={currency}
                />
              </p>
            </HStack>
          </HStack>
          <HStack className="items-center gap-2">
            <p className="text-xs text-white font-normal whitespace-nowrap">
              Dilution:
            </p>
            <HStack className="items-baseline text-white">
              <p className="text-lg font-medium whitespace-nowrap">
                <FormatNumberSpan
                  value={currentCompanyDetail.dilution}
                  format={currency}
                />
              </p>
              <span className="pl-1 font-normal">%</span>
            </HStack>
          </HStack>
        </VStack>
        <div className="bg-slate-400  w-0.5 h-10 my-auto"></div>
        <div className="w-1/4">
          <DarkProgressBar
            percentage={
              (currentCompanyDetail.moneyRaised /
                currentCompanyDetail.roundSize) *
              100
            }
            raisedAmount={currentCompanyDetail.moneyRaised}
            leftToRaise={currentCompanyDetail.leftToRaise}
            currency={"INR - ₹"}
          />
        </div>
      </HStack>
    </div>
  );
};

export default ModelingHeadersStack;
