import { useMutation, useQuery } from "react-query";
import { useAuthStore } from "../store/useAuthStore";
import { getUserDetails, setDefaultProduct } from "../api/Esop";
import { useAuthorizationStore } from "../store";
import { handleEventForTracking } from "../amplitudeAnalytics";

export function useUserDetails() {
  return useQuery({
    queryKey: "userDetails",
    queryFn: getUserDetails,
    onSuccess: (data) => {
      useAuthorizationStore.setState((store) => ({
        ...store,
        ...data?.authorities[0],
      }));
      useAuthStore.setState((store) => ({
        ...store,
      }));
    },
  });
}

export function useSetDefaultProduct() {
  return useMutation({
    mutationKey: "setDefaultProduct",
    mutationFn: setDefaultProduct,
    onSuccess: () => {
      handleEventForTracking({
        eventName: "setDefaultProduct",
        success: true,
        eventType: "API",
      });
    },
    onError: () => {
      handleEventForTracking({
        eventName: "setDefaultProduct",
        success: false,
        eventType: "API",
      });
    },
  });
}
