import { tableEntryStyle } from "../../components/TableComponent";
import { HStack } from "../../components/utils";
import { formatToReadableNumber } from "../../utils/currencyRoboto";
import editLogo from "../../utils/assets/edit.svg";

export function FromRender(data: any) {
  return (
    <HStack className="whitespace-nowrap pt-3">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.data?.from,
          format: data.data.currencyType,
        })}
      </p>
    </HStack>
  );
}

export function ToRender(data: any) {
  return (
    <HStack className="whitespace-nowrap pt-3">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.data?.to || 0,
          format: data.data.currencyType,
        })}
      </p>
    </HStack>
  );
}
