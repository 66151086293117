import { Dialog } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import _ from "lodash";
import AlertDialog from "../../components/shared/AlertDialog";
import { HStack, VStack, Box } from "../../components/utils";
import {
  useCalculateSharePrice,
  useDeleteOnGoingTransaction,
  useDiscardQuickRound,
} from "../../queries/transactionRound";
import { useAuthStore } from "../../store";
import { useShareholderInstrumentStore } from "../../store/shareholderInstrumentStore";
import {
  extractSecondaryTransaction,
  extractTransaction,
} from "./ExtractionUtility";
import { useNewShareholderInstrumentStore } from "../../store/newShareholderAndInstrumentStore";
import { useQuickRoundTransactionStore } from "../../store/useQuickTransactionStore";
import {
  ButtonSize,
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../quickRound/CTAButtonComponents";
import AddPrimaryPopup from "./AddPrimaryPopup";
import AddSecondaryPopup from "./AddSecondaryPopup";
import AddShareholderPopup from "./AddShareholderPopup";
import AddInstrumentPopup from "./AddInstrumentPopup";
import AgGridQuickSecondaryTable from "./AddSecondaryAgTable";
import AgGridQuickPrimaryTable from "./AddPrimaryAgTable";
import AgGridOutstandingConvertibleTable from "./OutstandingConvertibleAgGridTable";
import AgGridQuickRoundConvertibleTable from "./QuickRoundConvertibleAgGridTable";
import { getCurrencyType } from "../../utils/currencyFormatter";

interface AddTransactionRoundProps {
  roundData: any;
  roundType: string;
}

export default function AddTransactionRound({
  roundData,
  roundType,
}: AddTransactionRoundProps) {
  const [selected, setSelected] = useState("Primary");
  const [totalAmountInvested, setTotalAmountInvested] = useState<number>(0);

  const companyId = useAuthStore.getState().companyId || "";
  const { reset: resetTemplate } = useQuickRoundTransactionStore();
  const primaryTransactionStore = useNewShareholderInstrumentStore();

  const { mutate: deleteOnGoingTransaction } =
    useDeleteOnGoingTransaction(companyId);

  const pathName = window.location.href.split("/");

  const [dialog, setDialog] = useState<{
    open: boolean;
    message?: string;
  }>({
    open: false,
  });

  const navigate = useNavigate();
  const { refetch: discardQuickRound } = useDiscardQuickRound();

  const handleDiscard = () => {
    setDialog({ open: false });
    discardQuickRound();
    resetTemplate();
    deleteOnGoingTransaction(companyId);
    navigate("/transactions/allTransactions");
  };

  const [addDialog, setAddDialog] = useState<{
    open: boolean;
    name?: string;
    data?: any;
  }>({
    open: false,
  });

  const { refetch: calculateSharePrice, data: ppsDetails } =
    useCalculateSharePrice(
      roundData.valuation,
      roundData.roundSize,
      roundData.autoDiluteEsop,
      roundData.postRoundEsop
    );

  useEffect(() => {
    const totalAmountInvested = _.sumBy(
      primaryTransactionStore.primary,
      "amountInvested"
    );
    setTotalAmountInvested(totalAmountInvested);
  }, [primaryTransactionStore.primary]);
  return (
    <VStack>
      <Box className="px-8 bg-white rounded-lg ">
        <HStack className="pt-6 pb-4">
          <Dialog open={addDialog.open} maxWidth="md">
            {addDialog.open && addDialog.name === "Primary" && (
              <AddPrimaryPopup
                roundCurrency={roundData.roundCurrency}
                pricedRound={roundData.pricedRound}
                onPrimaryAction={() =>
                  setAddDialog({ open: false, name: "Primary" })
                }
                onSecondaryAction={() =>
                  setAddDialog({ open: false, name: "Primary" })
                }
                mode={"Add"}
                pps={ppsDetails?.pps}
                totalAmountInvested={totalAmountInvested}
                roundSize={roundData.roundSize}
              />
            )}
            {addDialog.open && addDialog.name === "Secondary" && (
              <AddSecondaryPopup
                pricedRound={roundData.pricedRound}
                onPrimaryAction={() =>
                  setAddDialog({ open: false, name: "Secondary" })
                }
                onSecondaryAction={() =>
                  setAddDialog({ open: false, name: "Secondary" })
                }
                data={addDialog.data}
                mode={"Add"}
              />
            )}
            {addDialog.open && addDialog.name === "Shareholder" && (
              <AddShareholderPopup
                onPrimaryAction={() =>
                  setAddDialog({ open: false, name: "Shareholder" })
                }
                onSecondaryAction={() =>
                  setAddDialog({ open: false, name: "Shareholder" })
                }
                data={addDialog.data}
              />
            )}
            {addDialog.open && addDialog.name === "Security" && (
              <AddInstrumentPopup
                onPrimaryAction={() =>
                  setAddDialog({ open: false, name: "Security" })
                }
                onSecondaryAction={() =>
                  setAddDialog({ open: false, name: "Security" })
                }
                data={addDialog.data}
                isPricedRound={roundData.pricedRound}
              />
            )}
          </Dialog>
          <HStack className="w-full gap-8">
            <p
              onClick={() => {
                setSelected("Primary");
              }}
              className={`${
                selected === "Primary"
                  ? "text-primary font-medium border-b border-primary"
                  : "text-gray-500 font-medium"
              } text-base cursor-pointer`}
            >
              Primary
            </p>
            {roundData.pricedRound && (
              <p
                onClick={() => {
                  setSelected("Secondary");
                }}
                className={`${
                  selected !== "Secondary"
                    ? " text-gray-500 font-medium"
                    : "text-primary font-medium border-b border-primary"
                } text-base  cursor-pointer`}
              >
                Secondary
              </p>
            )}
            {roundData.pricedRound && (
              <p
                onClick={() => {
                  setSelected("Convertible");
                }}
                className={`${
                  selected !== "Convertible"
                    ? " text-gray-500 font-medium"
                    : "text-primary font-medium border-b border-primary"
                } text-base  cursor-pointer`}
              >
                Convertible
              </p>
            )}
          </HStack>
          {selected === "Primary" ? (
            <HStack className="gap-4">
              <SecondaryCTAButton
                buttonSize={ButtonSize.SMALL}
                className="h-8 text-xs2 whitespace-nowrap"
                onClick={() => {
                  setAddDialog({ open: true, name: "Shareholder" });
                }}
              >
                Add Shareholder
              </SecondaryCTAButton>
              <SecondaryCTAButton
                className="h-8 text-xs2 whitespace-nowrap"
                buttonSize={ButtonSize.SMALL}
                onClick={() => {
                  setAddDialog({ open: true, name: "Security" });
                }}
              >
                Add Security
              </SecondaryCTAButton>
              <PrimaryCTAButton
                className="h-8 text-xs2 whitespace-nowrap"
                onClick={() => {
                  setAddDialog({ open: true, name: "Primary" });
                }}
              >
                Add Primary
              </PrimaryCTAButton>
            </HStack>
          ) : selected === "Secondary" ? (
            <HStack className="gap-4">
              <SecondaryCTAButton
                event-name="Add Buyer Button"
                className="h-8 text-xs2 whitespace-nowrap"
                buttonSize={ButtonSize.SMALL}
                onClick={() => {
                  setAddDialog({ open: true, name: "Shareholder" });
                }}
              >
                Add Buyer
              </SecondaryCTAButton>
              <PrimaryCTAButton
                event-name="Add Secondary Button"
                className="h-8 text-xs2 whitespace-nowrap"
                onClick={() => {
                  setAddDialog({ open: true, name: "Secondary" });
                }}
              >
                Add Secondary
              </PrimaryCTAButton>
            </HStack>
          ) : (
            <></>
          )}
        </HStack>
        <Dialog open={dialog.open} maxWidth="md">
          <AlertDialog
            onClose={() => setDialog({ open: false })}
            open={dialog.open}
            message={dialog.message}
            onPrimaryAction={handleDiscard}
            onSecondaryAction={() => setDialog({ open: false })}
          />
        </Dialog>

        {selected === "Primary" ? (
          <AgGridQuickPrimaryTable roundDetails={roundData} />
        ) : selected === "Secondary" ? (
          <AgGridQuickSecondaryTable />
        ) : (
          <AgGridQuickRoundConvertibleTable />
        )}
      </Box>
    </VStack>
  );
}
