import { memo, useEffect, useMemo, useRef, useState } from "react";
import { CellClickedEvent, ColDef } from "ag-grid-community";
import { Dialog } from "@mui/material";
import { useNavigate } from "react-router";
import ReactDOMServer from "react-dom/server";
import { AgGridReact } from "ag-grid-react";
import { toast } from "react-toastify";
import { Action, CTADropdown } from "../../components/shared/Dropdown";
import { agConfigDashboardSideBar } from "../dashboardPage/AgGridConfig";
import {
  getCurrencySymbol,
  getCurrencyType,
} from "../../utils/currencyFormatter";
import { Box, HStack, useGetCompanyName } from "../../components/utils";
import { useAuthStore } from "../../store";
import {
  useGetOnFilterState,
  usePostOnFilterState,
} from "../dashboardPage/AgGridCacheQuery";
import { tableCellStyle } from "../../components/TableComponent";
import { useAgGridTableState } from "../../store/agGridTableStore";
import { useNewShareholderInstrumentStore } from "../../store/newShareholderAndInstrumentStore";
import { SecondaryTransactionDataModel } from "../../types/quickRound";
import {
  BuyerNameRender,
  DateRender,
  NumberOfSharesRender,
  SecurityRender,
  SellerNameRender,
  TransactionAmountRender,
} from "./AddSecondaryAgComponent";
import { EmptyTable } from "../../components/shared/EmptyTable";
import AddSecondaryPopup from "./AddSecondaryPopup";
import { handleEventForTracking } from "../../amplitudeAnalytics";

function AgGridQuickSecondaryTable() {
  const currencySymbol = getCurrencySymbol();
  const companyName = useGetCompanyName();
  const companyId = useAuthStore().companyId ?? "";
  const userId = useAuthStore().user?.userId ?? 0;
  const gridApi = useRef<any>(null);
  const [displayedRowCount, setDisplayedRowCount] = useState<number>(0);

  const secondaryTransactionStore = useNewShareholderInstrumentStore();

  useEffect(() => {
    const count = gridApi.current?.api.getDisplayedRowCount();

    if (count !== undefined) {
      setDisplayedRowCount(count);
    }
  }, [secondaryTransactionStore.secondary]);
  const [addDialog, setAddDialog] = useState<{
    open: boolean;
    name?: string;
    data?: any;
  }>({
    open: false,
  });
  const handleAction = (
    action: any,
    secondary: SecondaryTransactionDataModel,
    index: number
  ) => {
    if (action.name === "Delete") {
      toast("Transaction deleted Successfully!", {
        type: "error",
        autoClose: 2000,
      });
      const secondaryTransactions = secondaryTransactionStore.secondary.slice();
      secondaryTransactions.splice(index, 1);
      secondaryTransactionStore.setSecondary(secondaryTransactions);
    } else if (action.name === "Edit") {
      setAddDialog({
        open: true,
        name: "Secondary",
        data: secondary,
      });
    }
  };

  const { refetch, data: filterState } = useGetOnFilterState(
    `${userId}`,
    companyId
  );
  const agTableStore = useAgGridTableState();
  useEffect(() => {
    refetch().then((data) => {
      if (data.data) agTableStore.setState(data.data);
      setColumnSetting(data.data?.quickSecondaryColumnModel);
      setFilterSetting(data.data?.quickSecondaryFilterModel);
    });
  }, []);

  const { mutate: postOnFilter } = usePostOnFilterState();

  function getColumnSetting() {
    if (gridApi.current) return gridApi.current.columnApi?.getColumnState();
    return {};
  }

  function setColumnSetting(model: any) {
    if (gridApi.current)
      gridApi.current.columnApi.applyColumnState({ state: model });
    return {};
  }

  // Gets filter model via the grid API
  const getFilterSetting = () => {
    if (gridApi.current) return gridApi.current.api?.getFilterModel();
    return {};
  };

  const setFilterSetting = (model: any) => {
    if (gridApi.current) return gridApi.current.api?.setFilterModel(model);
    return {};
  };

  const uploadFilterAndColumn = async () => {
    const columnState = await getColumnSetting();
    const filterState = await getFilterSetting();
    postOnFilter({
      userId: `${userId}`,
      companyId,
      filterData: {
        ...agTableStore.state,
        quickSecondaryColumnModel: columnState,
        quickSecondaryFilterModel: filterState,
      },
    });
  };

  const cin = "";

  const componentsRegistery = useMemo(
    () => ({
      buyerName: memo(BuyerNameRender),
      sellerName: memo(SellerNameRender),
      security: memo(SecurityRender),
      numberOfShares: memo(NumberOfSharesRender),
      transactionAmount: memo(TransactionAmountRender),
      date: memo(DateRender),
    }),
    []
  );

  const navigate = useNavigate();

  const onClickShareholderName = (id: string) => {
    handleEventForTracking({ eventName: `Shareholder Name` });
    navigate(`/shareholders/individual/${id}`);
  };

  const isValidDate = (date: string) =>
    Number.isNaN(date) &&
    new Date(date).toString() !== "Invalid Date" &&
    !Number.isNaN(Date.parse(date));

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      autoHeight: true,
      wrapHeaderText: true,
      suppressColumnVirtualisation: true,
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      filterParams: {
        buttons: ["reset"],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
      flex: 1,
    }),
    []
  );

  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        headerName: "Buyer Name",
        field: "buyerName",
        filter: "agMultiColumnFilter",
        getQuickFilterText: (params) => params.value.name,
        comparator(valueA: any, valueB: any, ..._: any[]) {
          return valueA.name > valueB.name ? 1 : -1;
        },
        filterParams: {
          maxNumConditions: 5,
          buttons: ["reset"],
          filters: [
            {
              title: "Name",
              display: "subMenu",
              filter: "agTextColumnFilter",
              buttons: ["reset"],
              filterValueGetter: (params: any) => params.value.buyername,
              filterParams: {
                buttons: ["reset"],
                valueGetter: (params: any) => params.data.buyerName,
              },
            },
            {
              title: "Type",
              filter: "agSetColumnFilter",
              display: "subMenu",
              buttons: ["reset"],
              filterParams: {
                buttons: ["reset"],

                valueGetter: (params: any) => {
                  const investorType = params.data.investorType;
                  return investorType;
                },
              },
            },
          ],
        },
        cellRenderer: "buyerName",
        initialWidth: 250,
        sortable: true,
        autoHeight: true,
        cellStyle: tableCellStyle,
        wrapText: true,
        suppressSizeToFit: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipBuyerName",
      },
      {
        headerName: "Seller Name",
        field: "sellerName",
        cellRenderer: "sellerName",
        initialWidth: 250,
        cellStyle: tableCellStyle,
        sortable: false,
        filter: "agSetColumnFilter",
        tooltipField: "tooltipSellerName",
        menuTabs: ["filterMenuTab"],
      },
      {
        headerName: "Security",
        field: "security",
        filter: "agSetColumnFilter",
        cellRenderer: "security",
        cellStyle: tableCellStyle,
        sortable: false,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipSecurity",
      },
      {
        headerName: "No. of Securities",
        field: "numberOfShares",
        filter: "agNumberColumnFilter",
        cellRenderer: "numberOfShares",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipNumberOfShares",
      },
      {
        headerName: `Transaction Amount (${currencySymbol})`,
        field: "transactionAmount",
        filter: "agMultiColumnFilter",
        cellRenderer: "transactionAmount",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
        tooltipField: "tooltipTransactionAmount",
        filterParams: {
          maxNumConditions: 5,
          buttons: ["reset"],
          filters: [
            {
              title: "Amount in Company Currency",
              display: "subMenu",
              filter: "agTextColumnFilter",
              buttons: ["reset"],
              filterValueGetter: (params: any) => params.value.amountInCurrency,
              filterParams: {
                buttons: ["reset"],
                valueGetter: (params: any) =>
                  params.getValue("transactionAmount").amountInCurrency,
              },
            },
            {
              title: "Amount in Transaction Currency",
              display: "subMenu",
              filter: "agTextColumnFilter",
              buttons: ["reset"],
              filterValueGetter: (params: any) => params.value.amountInvested,
              filterParams: {
                buttons: ["reset"],
                valueGetter: (params: any) =>
                  params.getValue("transactionAmount").amountInvested,
              },
            },
          ],
        },
      },
      {
        headerName: "Date",
        field: "date",
        filter: "agDateColumnFilter",
        filterParams: {
          comparator: (dateFromFilter: Date, cellValue: any) => {
            if (cellValue == null) {
              return 0;
            }
            const dateParts = cellValue.split("-");
            const day = Number(dateParts[2]);
            const month = Number(dateParts[1]) - 1;
            const year = Number(dateParts[0]);
            const cellDate = new Date(year, month, day);
            if (cellDate < dateFromFilter) {
              return -1;
            } else if (cellDate > dateFromFilter) {
              return 1;
            }
            return 0;
          },
        },
        cellRenderer: "date",
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ["filterMenuTab"],
      },

      {
        headerName: "",
        field: "actions",
        pinned: "right",
        hide: false,
        width: 80,
        maxWidth: 80,
        filter: false,
        menuTabs: [],
        colId: "action-column",
        suppressNavigable: true,
        suppressColumnsToolPanel: true,
        cellStyle: { "padding-top": "16px", "line-height": "12px" },
        resizable: false,
        sortable: false,
        cellRendererParams: ({ value }: { value: any }) => value?.props,
        cellRenderer: CTADropdown,
      },
    ],
    []
  );

  const gridOptions = {
    suppressRowVirtualisation: true,
    paginationAutoPageSize: false,
    suppressScrollOnNewData: true,
  };

  const currencyType = getCurrencyType();

  const rowData = useMemo(
    () =>
      secondaryTransactionStore?.secondary.map((template, index) => ({
        uuid: template.uuid,
        buyerName: template.buyerName,
        tooltipBuyerName: template.buyerName,
        par: template.par,
        conversionRatio: template.conversionRatio,
        investorType: template.investorType,
        sellerName: template.sellerName,
        tooltipSellerName: template.sellerName,
        security: template.security,
        numberOfShares: Number(template.numberOfShares),
        tooltipNumberOfShares: Number(template.numberOfShares).toLocaleString(
          currencyType
        ),
        transactionCurrency: template.transactionCurrency,
        transactionAmount: {
          amountInvested: template.amountInvested,
          amountInCurrency:
            template.amountInvested * (template.exchangeRate || 1),
          exchangeRate: template.exchangeRate,
          currency: template.transactionCurrency,
          currencySymbol,
        },
        tooltipTransactionAmount: (
          template.amountInvested * (template.exchangeRate || 1)
        )?.toLocaleString(currencyType),
        exchangeRate: template.exchangeRate,
        date: template.date,
        currencySymbol,
        currencyType,
        actions: (
          <CTADropdown
            actions={[
              {
                name: "Edit",
                disabled: false,
              },

              {
                name: "Delete",
                disabled: false,
              },
            ]}
            onAction={(action) => handleAction(action, template, index)}
          />
        ),
      })),
    [secondaryTransactionStore.secondary]
  );

  function setTableHeight() {
    if (displayedRowCount === 1) {
      return (displayedRowCount + 2.0) * 60;
    } else if (displayedRowCount === 2) {
      return (displayedRowCount + 2.0) * 60;
    } else if (displayedRowCount === 3) {
      return (displayedRowCount + 2.0) * 60;
    } else if (displayedRowCount === 4) {
      return (displayedRowCount + 2.0) * 60;
    } else if (displayedRowCount === 5) {
      return (displayedRowCount + 2.0) * 60;
    } else if (displayedRowCount === 6) {
      return (displayedRowCount + 2.0) * 60;
    } else if (displayedRowCount === 7) {
      return (displayedRowCount + 2.0) * 60;
    } else if (displayedRowCount === 8) {
      return (displayedRowCount + 2.0) * 60;
    } else if (displayedRowCount === 9) {
      return (displayedRowCount + 2.0) * 60;
    } else {
      return 10 * 60;
    }
  }

  const handleCellClick = (cellParams: CellClickedEvent<any, any>) => {
    if (cellParams.column.getColId() !== "action-column") {
      const template = cellParams.data;
      if (cellParams.column.getColId() === "shareholderName") {
        onClickShareholderName(template.id);
      }
    }
  };

  const [filteredRowData, setFilteredRowData] = useState<any>([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const onAgGridFilterChanged = (grid: any) => {
    const filtersApplied = grid.api.isAnyFilterPresent();
    setIsFilterApplied(filtersApplied);

    const filteredData = grid.api
      .getModel()
      .rowsToDisplay.map((node: any) => node.data);
    setFilteredRowData(filteredData);
    uploadFilterAndColumn();
  };
  return (
    <div>
      <HStack className="flex-col items-start justify-start py-2 bg-white rounded-md lg:flex-row lg:justify-between lg:items-center"></HStack>
      <HStack className="justify-between w-full">
        <Dialog open={addDialog.open} maxWidth="md">
          <AddSecondaryPopup
            pricedRound={null}
            onPrimaryAction={() =>
              setAddDialog({ open: false, name: "Secondary" })
            }
            onSecondaryAction={() =>
              setAddDialog({ open: false, name: "Secondary" })
            }
            data={addDialog.data}
            mode={"Edit"}
          />
        </Dialog>
        <Box
          style={{
            height: setTableHeight() + 120,
          }}
          className="w-full h-full max-h-full overflow-x-auto ag-theme-material "
        >
          <AgGridReact
            sideBar={agConfigDashboardSideBar}
            onGridReady={(params) => {
              // Store the grid API referen
              gridApi.current = params;
            }}
            components={componentsRegistery}
            alwaysShowHorizontalScroll
            alwaysMultiSort
            animateRows={true}
            enableCharts={true}
            enableRangeSelection={true}
            defaultColDef={defaultColDef}
            onFilterChanged={onAgGridFilterChanged}
            onColumnEverythingChanged={uploadFilterAndColumn}
            rowData={rowData}
            onCellClicked={handleCellClick}
            columnDefs={columnDefs}
            pagination={false}
            suppressRowTransform={true}
            suppressCopyRowsToClipboard={true}
            suppressCopySingleCellRanges={true}
            suppressCellFocus={true}
            suppressMenuHide={false}
            onColumnResized={uploadFilterAndColumn}
            tooltipShowDelay={1000}
            tooltipInteraction={true}
            rowClass={"border-t border-dashed "}
            gridOptions={gridOptions}
            overlayNoRowsTemplate={ReactDOMServer.renderToString(
              <EmptyTable
                header="No Transaction"
                subHeader="Get started by adding a new transaction"
              />
            )}
            getRowStyle={(params) => {
              if (params.rowIndex % 2 === 0) {
                return { background: "#f8f8f8" };
              } else {
                return { background: "#ffffff" };
              }
            }}
          ></AgGridReact>
        </Box>
      </HStack>
    </div>
  );
}

export default AgGridQuickSecondaryTable;
