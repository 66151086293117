import _ from "lodash";
import { useNavigate } from "react-router";
import {
  tableEntryStyle,
  tableFirstColStyle,
} from "../../components/TableComponent";
import { limitString } from "../../utils/string";
import { getSecurityColor } from "../../utils/historicUtilities";
import { formatToReadableNumber } from "../../utils/currencyRoboto";
import Avatar from "../Utility/Avatar";
import { CaptableTag } from "../../components/AllotmentTag";
import RoundTag from "../../shared/RoundTag";

export function ShareholderNameRender(data: any) {
  return (
    <td className="flex flex-col pb-2 text-sm text-left">
      <div className="flex">
        <div className="pr-2 text-left">
          {data.value.name !== "Total" ? (
            <Avatar name={data.value.name || ""} />
          ) : (
            ""
          )}
        </div>
        <div
          className={` ${
            data.value.name === "Total"
              ? "pt-2 cursor-pointer"
              : "ml-2 cursor-pointer"
          } `}
        >
          {data.value.name?.length > 24 ? (
            <p
              data-event-name="captableShareholderDrilling"
              className={`${tableFirstColStyle}`}
            >
              {limitString(data.value.name || "", 24)}
            </p>
          ) : (
            <p
              data-event-name="captableShareholderDrilling"
              className={`${tableFirstColStyle}`}
            >
              {limitString(data.value.name || "", 24)}
            </p>
          )}

          {data.value.name !== "Total" ? (
            <CaptableTag tagName={data.value.type ?? "Others"} />
          ) : (
            ""
          )}
        </div>
      </div>
    </td>
  );
}

export function PreHoldingPercentageRender(data: any) {
  return (
    <div className="pt-3 whitespace-nowrap">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.data?.preHoldingPercentage || 0,
          format: data.data.currencyType,
        })}
      </p>
    </div>
  );
}

export function PostHoldingPercentageRender(data: any) {
  return (
    <div className="pt-3 whitespace-nowrap">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.data?.postHoldingPercentage || 0,
          format: data.data.currencyType,
        })}
      </p>
    </div>
  );
}

export function PreHoldingFDBSharesRender(data: any) {
  return (
    <div className="pt-3 whitespace-nowrap">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.data?.preHoldingFDBShares,
          format: data.data.currencyType,
        })}
      </p>
    </div>
  );
}

export function PostHoldingFDBSharesRender(data: any) {
  return (
    <div className="pt-3 whitespace-nowrap">
      <p className={tableEntryStyle}>
        {formatToReadableNumber({
          value: data.data?.postHoldingFDBShares,
          format: data.data.currencyType,
        })}
      </p>
    </div>
  );
}
