import { useState } from "react";
import Box from "@mui/material/Box";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { Icon } from "@iconify/react";
import { getNames } from "country-list";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { HStack, VStack, Error } from "../components/utils";
import { Input, Label, TextArea } from "../components/shared/InputField";
import { Select } from "../components/shared/Select";
import { ShareholderProfile } from "../types/Shareholder";
import { SwitchButton } from "../components/shared/SwitchButton";
import {
  useAddNewShareHolder,
  useEditShareholder,
  useGetShareholderGroups,
} from "../queries/shareholders";
import { useAuthStore } from "../store";
import {
  ShareholderSubTypes,
  shareholderTypeLabelValue,
} from "../constants/ShareholderConstants";
import {
  ButtonSize,
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../pages/quickRound/CTAButtonComponents";
import { queryClient } from "../queries";

type AddShareholderProfileProps = {
  initialValue?: ShareholderProfile;
  onPrimaryAction: () => void;
  onSecondaryAction: () => void;
  mode: "Add" | "Edit" | "View";
};

const AddShareholderProfile = ({
  initialValue,
  onPrimaryAction = () => {},
  onSecondaryAction = () => {},
  mode,
}: AddShareholderProfileProps) => {
  const companyId = useAuthStore.getState().companyId || "";

  const viewOnly = mode === "View";

  const { mutate: addNewShareHolder } = useAddNewShareHolder();
  const { mutate: editShareholder } = useEditShareholder();

  const intialValues: ShareholderProfile = {
    id: initialValue?.id || "",
    name: initialValue?.name || "",
    address: initialValue?.address || "",
    residence: initialValue?.residence || "",
    nationality: initialValue?.nationality || "",
    depositoryName: initialValue?.depositoryName || "",
    isPromoter: initialValue?.isPromoter || false,
    type: initialValue?.type || "",
    groupName: initialValue?.groupName || "",
    email: initialValue?.email || "",
    contactNumber: initialValue?.contactNumber || "",
    dpId: initialValue?.dpId || "",
    clientId: initialValue?.clientId || "",
    uniqueId: initialValue?.uniqueId || "",
    groupDetails: initialValue?.groupDetails || [],
    investmentVehicleType: initialValue?.investmentVehicleType || "",
    isRelativeOfDirector: initialValue?.isRelativeOfDirector || false,
  };

  const handleSubmit = (values: ShareholderProfile) => {
    values.companyId = companyId;
    if (selectedGroupName) values.groupDetails = [{ name: selectedGroupName }];
    if (mode === "Add") {
      addNewShareHolder(values, {
        onSuccess: () => {
          queryClient.invalidateQueries("getShareholderOverview");
          toast("Added Shareholder successfully!", {
            type: "success",
            autoClose: 2000,
          });
          onPrimaryAction();
        },
        onError: () => {
          toast("Something went wrong", { type: "error", autoClose: 2000 });
          onPrimaryAction();
        },
      });
    } else {
      editShareholder(values, {
        onSuccess: () => {
          toast("Edited Shareholder successfully!", {
            type: "success",
            autoClose: 2000,
          });
          onPrimaryAction();
        },
        onError: () => {
          toast("Something went wrong", { type: "error", autoClose: 2000 });
          onPrimaryAction();
        },
      });
    }
  };

  const shareholderProfileSchema = Yup.object({
    id: Yup.string().label("Shareholder Profile ID"),
    name: Yup.string().required().label("Legal Name"),
    address: Yup.string().label("Registered Address"),
    residence: Yup.string().required().label("Country of Residence"),
    nationality: Yup.string().required().label("Nationality"),
    depositoryName: Yup.string().label("Depository Name"),
    isPromoter: Yup.boolean().label("Is Promoter"),
    type: Yup.string().required().label("Type of Shareholder"),
    groupName: Yup.string().when(["type"], {
      is: (typeOfShareholder: string) =>
        ["AngelNetwork", "VC", "PE", "FinancialInstitution"].includes(
          typeOfShareholder
        ),
      then: Yup.string().label("Group Name"),
    }),
    email: Yup.string().email().label("Email"),
    contactNumber: Yup.string().label("Contact Number"),
    dpId: Yup.string().label("DP ID"),
    clientId: Yup.string().label("Client ID"),
    uniqueId: Yup.string().label("PAN"),
    investmentVehicleType: Yup.string().label("Shareholder Sub Type"),
    isRelativeOfDirector: Yup.boolean().label("Is Relative Of Director"),
  });

  const [selectedGroupName, setSelectedGroupName] = useState(
    initialValue?.groupName || ""
  );

  const { data: shareholderGroups } = useGetShareholderGroups();

  return (
    <div>
      <Box className="px-10 text-lg font-medium border-b py-7">
        <h6 className="flex justify-between">
          {mode === "Add"
            ? "Add Shareholder Profile"
            : mode === "View"
            ? "Shareholder Profile"
            : "Edit Shareholder Profile"}
          <span onClick={() => onSecondaryAction()} className="cursor-pointer">
            X
          </span>
        </h6>
      </Box>
      <VStack className="w-full px-10 py-7 gap-9">
        <Formik
          initialValues={intialValues}
          key="addShareholderProfile"
          enableReinitialize={true}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
          validationSchema={shareholderProfileSchema}
        >
          {(formik) => (
            <>
              <Form key="addShareholderProfile">
                <VStack className="w-full gap-4">
                  <HStack className="gap-8">
                    <div className="flex-1">
                      <Label className="text-sm font-normal">
                        Type Of Shareholder
                      </Label>
                      <Select
                        {...formik.getFieldProps("type")}
                        placeholder="Enter type of shareholder"
                        className="w-72"
                        options={shareholderTypeLabelValue}
                        valueGetter={(o) => o.label}
                        textGetter={(o) => o.value}
                        disabled={viewOnly}
                      />
                      {formik.touched.type && formik.errors.type && (
                        <Error text={formik.errors.type} />
                      )}
                    </div>
                    <div className="flex-1">
                      {formik.values.type.toLowerCase() ===
                      "financialinstitution" ? (
                        <div>
                          <Label className="text-sm font-normal">
                            Shareholder Sub Type
                          </Label>
                          <Select
                            {...formik.getFieldProps("investmentVehicleType")}
                            placeholder="Enter Shareholder Sub Type"
                            className="w-72"
                            options={ShareholderSubTypes}
                            valueGetter={(o) => o.value}
                            textGetter={(o) => o.text}
                            disabled={viewOnly}
                          />
                          {formik.touched.investmentVehicleType &&
                            formik.errors.investmentVehicleType && (
                              <Error
                                text={formik.errors.investmentVehicleType}
                              />
                            )}
                        </div>
                      ) : ["Founder", "Angel"].includes(formik.values.type) ? (
                        <div>
                          <Label className="text-sm font-normal">
                            Is Relative Of Director
                          </Label>
                          <SwitchButton
                            className={`text-xs font-normal items-center m-1 ${
                              viewOnly ? "cursor-not-allowed" : ""
                            }`}
                            value={formik.values.isRelativeOfDirector}
                            label=""
                            onClick={() => {
                              if (!viewOnly) {
                                formik.setFieldValue(
                                  "isRelativeOfDirector",
                                  !formik.values.isRelativeOfDirector
                                );
                              }
                            }}
                          />
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </div>
                  </HStack>
                  <HStack className="gap-10">
                    <div className="flex-1">
                      <Label className="text-sm font-normal">Group Name</Label>
                      <Autocomplete
                        id="autocomplete-search"
                        className={`${viewOnly ? "cursor-not-allowed" : ""}`}
                        popupIcon={
                          <Icon
                            icon="akar-icons:chevron-down"
                            className="text-slate-600 p-1"
                          />
                        }
                        options={
                          shareholderGroups?.map((group) => group.name) || []
                        }
                        onChange={(e, value) => {
                          if (value) setSelectedGroupName(value);
                        }}
                        value={selectedGroupName}
                        getOptionLabel={(option) => option}
                        sx={{
                          "& .MuiInput-input": {
                            padding: "4px 4px 4px 14px !important",
                            fontFamily: "Inter",
                            color: "rgb(102, 107, 128, 1)",
                          },
                          "& .MuiSvgIcon-root": {
                            fill: "none",
                          },
                        }}
                        renderInput={(params) => (
                          <div>
                            <TextField
                              hiddenLabel
                              type="text"
                              className={`border-0 form-input px-20 outline-none border-none rounded-lg form-input items-center`}
                              placeholder={"Enter Group Name"}
                              variant="standard"
                              {...params}
                              InputProps={{
                                disableUnderline: true,
                                ...params.InputProps,
                              }}
                              value={selectedGroupName}
                              onChange={(e) =>
                                setSelectedGroupName(e.target.value)
                              }
                            />
                          </div>
                        )}
                        disabled={viewOnly}
                      />
                      {formik.touched.groupName && formik.errors.groupName && (
                        <Error text={formik.errors.groupName} />
                      )}
                    </div>
                    <div className="flex-1">
                      <Label className="text-sm font-normal">Legal Name</Label>
                      <Input
                        type="text"
                        placeholder="Enter Legal Name"
                        className={`w-72 ${
                          viewOnly ? "cursor-not-allowed" : ""
                        }`}
                        {...formik.getFieldProps("name")}
                        disabled={viewOnly}
                      />
                      {formik.touched.name && formik.errors.name && (
                        <Error text={formik.errors.name} />
                      )}
                    </div>
                  </HStack>
                  <HStack className="gap-8">
                    <div className="flex-1">
                      <Label className="text-sm font-normal">
                        Registered Address
                      </Label>
                      <TextArea
                        placeholder="Enter Registered Address"
                        className={`w-72 ${
                          viewOnly ? "cursor-not-allowed" : ""
                        }`}
                        rows={3}
                        {...formik.getFieldProps("address")}
                        disabled={viewOnly}
                      />
                      {formik.touched.address && formik.errors.address && (
                        <Error text={formik.errors.address} />
                      )}
                    </div>
                    <div className="flex-1">
                      <Label className="text-sm font-normal">
                        Contact Number
                      </Label>
                      <Input
                        type="contact"
                        className={`w-72 ${
                          viewOnly ? "cursor-not-allowed" : ""
                        }`}
                        placeholder="Enter Contact Number"
                        {...formik.getFieldProps("contactNumber")}
                        disabled={viewOnly}
                      />
                      {formik.touched.contactNumber &&
                        formik.errors.contactNumber && (
                          <Error text={formik.errors.contactNumber} />
                        )}
                    </div>
                  </HStack>
                  <HStack className="gap-8">
                    <div className="flex-1">
                      <Label className="text-sm font-normal">PAN</Label>
                      <Input
                        type="text"
                        className={`w-72 ${
                          viewOnly ? "cursor-not-allowed" : ""
                        }`}
                        placeholder="Enter PAN"
                        {...formik.getFieldProps("uniqueId")}
                        disabled={viewOnly}
                      />
                      {formik.touched.uniqueId && formik.errors.uniqueId && (
                        <Error text={formik.errors.uniqueId} />
                      )}
                    </div>
                    <div className="flex-1">
                      <Label className="text-sm font-normal">Email</Label>
                      <Input
                        type="email"
                        className={`w-72 ${
                          viewOnly ? "cursor-not-allowed" : ""
                        }`}
                        placeholder="Enter Email"
                        {...formik.getFieldProps("email")}
                        disabled={viewOnly}
                      />
                      {formik.touched.email && formik.errors.email && (
                        <Error text={formik.errors.email} />
                      )}
                    </div>
                  </HStack>
                  <HStack className="gap-8">
                    <div className="flex-1">
                      <Label className="text-sm font-normal">
                        Country Of Residence
                      </Label>
                      <Select
                        options={getNames().sort()}
                        placeholder="Enter Country Of Residence"
                        className={`w-72 ${
                          viewOnly ? "cursor-not-allowed" : ""
                        }`}
                        {...formik.getFieldProps("residence")}
                        disabled={viewOnly}
                      />
                      {formik.touched.residence && formik.errors.residence && (
                        <Error text={formik.errors.residence} />
                      )}
                    </div>
                    <div className="flex-1">
                      <Label className="text-sm font-normal">Nationality</Label>
                      <Select
                        options={getNames().sort()}
                        placeholder="Enter Nationality"
                        className={`w-72 ${
                          viewOnly ? "cursor-not-allowed" : ""
                        }`}
                        {...formik.getFieldProps("nationality")}
                        disabled={viewOnly}
                      />
                      {formik.touched.nationality &&
                        formik.errors.nationality && (
                          <Error text={formik.errors.nationality} />
                        )}
                    </div>
                  </HStack>
                  <HStack className="gap-8 ">
                    <div className="flex-1">
                      <Label className="text-sm font-normal">
                        Depository Name
                      </Label>
                      <Input
                        type="text"
                        placeholder="Enter Depository Name"
                        className={`w-72 ${
                          viewOnly ? "cursor-not-allowed" : ""
                        }`}
                        {...formik.getFieldProps("depositoryName")}
                        disabled={viewOnly}
                      />
                      {formik.touched.depositoryName &&
                        formik.errors.depositoryName && (
                          <Error text={formik.errors.depositoryName} />
                        )}
                    </div>
                    <div className="flex-1">
                      <Label className="text-sm font-normal">Is Promoter</Label>
                      <SwitchButton
                        className={`text-xs font-normal items-center m-1 ${
                          viewOnly ? "cursor-not-allowed" : ""
                        }`}
                        value={formik.values.isPromoter}
                        label=""
                        onClick={() => {
                          if (!viewOnly) {
                            formik.setFieldValue(
                              "isPromoter",
                              !formik.values.isPromoter
                            );
                          }
                        }}
                      />
                      {formik.touched.isPromoter &&
                        formik.errors.isPromoter && (
                          <Error text={formik.errors.isPromoter} />
                        )}
                    </div>
                  </HStack>
                  <HStack className="gap-8 ">
                    <div className="flex-1">
                      <Label className="text-sm font-normal">DP ID</Label>
                      <Input
                        type="text"
                        className={`w-72 ${
                          viewOnly ? "cursor-not-allowed" : ""
                        }`}
                        placeholder="Enter DP ID"
                        {...formik.getFieldProps("dpId")}
                        disabled={viewOnly}
                      />
                      {formik.touched.dpId && formik.errors.dpId && (
                        <Error text={formik.errors.dpId} />
                      )}
                    </div>
                    <div className="flex-1">
                      <Label className="text-sm font-normal">Client ID</Label>
                      <Input
                        type="text"
                        className={`w-72 ${
                          viewOnly ? "cursor-not-allowed" : ""
                        }`}
                        placeholder="Enter Client ID"
                        {...formik.getFieldProps("clientId")}
                        disabled={viewOnly}
                      />
                      {formik.touched.clientId && formik.errors.clientId && (
                        <Error text={formik.errors.clientId} />
                      )}
                    </div>
                  </HStack>
                  <HStack className="justify-between mt-10">
                    <SecondaryCTAButton
                      event-name="Back Shareholder Profile"
                      buttonSize={ButtonSize.SMALL}
                      type="reset"
                      onClick={onSecondaryAction}
                    >
                      Back
                    </SecondaryCTAButton>
                    {!viewOnly && (
                      <PrimaryCTAButton
                        event-name="Save Shareholder Profile"
                        buttonSize={ButtonSize.SMALL}
                        type="submit"
                      >
                        Submit
                      </PrimaryCTAButton>
                    )}
                  </HStack>
                </VStack>
              </Form>
            </>
          )}
        </Formik>
      </VStack>
    </div>
  );
};

export default AddShareholderProfile;
