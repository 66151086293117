import { UserDetailModel } from "../types/UserDetailModel";
import newCapTableApi from "./capTableApi";
import oldHissaApi from "./captableReportDownload";
import hissaApi from "./hissaApi";
import newEsopApi from "./newEsopApi";

export async function getUserDetails(): Promise<UserDetailModel> {
  return hissaApi.get("/user").then((res) => res?.data);
}

export async function doLogout(): Promise<{}> {
  return hissaApi.get("exit").then((res) => res);
}

export async function getCompanyExists(esopCompanyId: string): Promise<String> {
  return oldHissaApi
    .get(`/user/companyExists?companyId=${esopCompanyId}`, {
      headers: {
        Companyid: esopCompanyId,
      },
    })
    .then((res) => res?.data)
    .catch((err) => "No ESOP Company Exists");
}

export async function setDefaultProduct(
  esopCompanyId: bigint | null
): Promise<String> {
  return oldHissaApi
    .put(`/user/setDefaultProduct?productType=ESOP`)
    .then((res) => res?.data);
}

export async function checkAccessToEsopCompany(
  esopCompanyId: string
): Promise<String> {
  return newEsopApi
    .get(`/company/checkAccessToCompany?id=${esopCompanyId}`)
    .then((res) => res?.data?.data)
    .catch((err) => "No ESOP Company Exists");
}
