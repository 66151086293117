import { HStack, VStack } from "../../components/utils";
import { getCurrencyType } from "../../utils/currencyFormatter";
import {
  FormatNumberSpan,
  getCurrencySymbolRoboto,
} from "../../utils/currencyRoboto";
import { formatDisplayDate } from "../../utils/date";
import { Tab1RoundHeader } from "./RoundCreationModel";

const RoundHeaderDetailCard = ({
  currentCompanyDetail,
}: {
  currentCompanyDetail: Tab1RoundHeader;
}) => {
  const symbol = getCurrencySymbolRoboto();
  const currency = getCurrencyType();
  return (
    <>
      <HStack className="-mx-2 flex flex-wrap justify-between mb-8 gap-4">
        <VStack className="p-2 flex-1 rounded-md shadow-box bg-white border border-borderColor">
          <p className="text-sm text-[#464E5F] font-medium whitespace-nowrap pb-2">
            Amount Raised{" "}
            <span> ({currentCompanyDetail.numberOfRounds} Round)</span>
          </p>
          <HStack className="items-center">
            <span className="pr-1 text-xs font-normal">{symbol}</span>
            <p className="text-lg font-medium">
              <FormatNumberSpan
                value={currentCompanyDetail.moneyRaised}
                format={currency}
              />
            </p>
          </HStack>
        </VStack>
        <VStack className="p-2 flex-1 flex rounded-md shadow-box  bg-white border border-borderColor">
          <p className="text-sm text-[#464E5F] font-medium whitespace-nowrap pb-2">
            Equity Shares
          </p>
          <p className="text-lg font-medium">
            <FormatNumberSpan
              value={currentCompanyDetail.equityShares}
              format={currency}
            />
          </p>
        </VStack>
        <VStack className="p-2 flex flex-1 rounded-md shadow-box  bg-white border border-borderColor">
          <p className="text-sm text-[#464E5F] font-medium whitespace-nowrap pb-2">
            Pref. Shares
          </p>
          <p className="text-lg font-medium">
            <FormatNumberSpan
              value={currentCompanyDetail.prefShares}
              format={currency}
            />
          </p>
        </VStack>

        <VStack className="p-2 flex-1 rounded-md shadow-box bg-white border border-borderColor">
          <p className="text-sm text-[#464E5F] font-medium whitespace-nowrap pb-2">
            Valuation (Post)
          </p>
          <HStack className="items-center whitespace-nowrap">
            <span className="pr-1 text-xs font-normal">{symbol}</span>
            <p className="text-lg font-medium">
              <FormatNumberSpan
                value={currentCompanyDetail.valuation.lastValuation}
                format={currency}
              />
            </p>
            <p className="w-1"></p>
            <p className="text[#A1A5B7] text-xxs1 whitespace-nowrap">
              {formatDisplayDate(currentCompanyDetail.valuation.date)}
            </p>
          </HStack>
        </VStack>
        <VStack className="p-2 flex-1 rounded-md shadow-box bg-white border border-borderColor">
          <p className="text-sm text-[#464E5F] font-medium whitespace-nowrap pb-2">
            Last Raise
          </p>
          <HStack className="items-center">
            <span className="pr-1 text-xs font-normal">{symbol}</span>
            <div className="text-lg font-medium">
              <FormatNumberSpan
                value={currentCompanyDetail.lastRaise.lastRaise}
                format={currency}
              />
              <p className="text[#A1A5B7] text-xxs1 whitespace-nowrap">
                {currentCompanyDetail.lastRaise.date}
              </p>
            </div>
          </HStack>
        </VStack>
      </HStack>
    </>
  );
};

export default RoundHeaderDetailCard;
