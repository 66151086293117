export function numberWithCommas(x: string, currencyType: string) {
  const newValue = x?.replaceAll(",", "") ?? 0;
  const parts = newValue.toString().split(".");
  parts[0] = Number(parts[0]).toLocaleString(currencyType);
  if (parts[1]) parts[1] = parts[1].substring(0, 2);
  return parts.join(".");
}

export function convertToNumber(value: string) {
  return Number(Number((value ?? 0)?.replaceAll(",", "") ?? 0).toFixed(2));
}

export function filterOnlyNumbers(value: string) {
  return value.replace(/[^0-9,.]/g, "");
}
