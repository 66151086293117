import { decodeCaptableAuthToken } from "../../components/utils";
import { useGetInvPortalIframeToken } from "../../queries/investorPortal";
import { useGetCompanyDetails } from "../../queries/termsheet";
import BCHeader from "../../shared/BCHeader";
import { useAuthStore } from "../../store";

function DocumentVoultView() {
  const { data: nodeCompanyDetail } = useGetCompanyDetails();
  const companyName = nodeCompanyDetail?.companyDetails?.name;
  const cin = nodeCompanyDetail?.companyDetails?.cinNumber;

  const accesstoken = useAuthStore.getState().accessToken || "";

  const userId = decodeCaptableAuthToken(accesstoken).id;

  const { data: token } = useGetInvPortalIframeToken();

  return (
    <div className="h-screen">
      <BCHeader className="items-baseline mb-4" bcTitle="Company Documents" />

      <iframe
        src={`${process.env.REACT_APP_INV_UI_URL}/documentViewer/${token}`}
        title="Company Documents"
        loading="eager"
        width="100%"
        height="85%"
      ></iframe>
    </div>
  );
}

export default DocumentVoultView;
