import create from "zustand";
import { io } from "socket.io-client";
import { configurePersist } from "zustand-persist";
import { RoundCreationModel } from "../pages/modeling/RoundCreationModel";
import { useAuthStore } from ".";
import {
  ConvertibleType,
  IndividualConvertiblesState,
  ModelBuilder,
  ModelState,
} from "../types/Modeling";
import Convertible from "../pages/modeling/Convertible";

const { persist: _persist } = configurePersist({
  storage: localStorage,
  rootKey: "root",
});

const persist = _persist as any;
type RoundDetailReCalculateModel = {
  postRoundEsopPercentage: number;
  roundSize: number;
  preMoneyValuation: number;
  fdbSharePrice: number;
};

type RoundModelingState = {
  roundCreation: ModelBuilder;
  calculateMode: RoundDetailReCalculateModel;
  setRoundCreation: (data: ModelBuilder) => void;
  setCalculateMode: (data: RoundDetailReCalculateModel) => void;
  connect: () => void;
  sendMessage: (message: ModelBuilder) => void;
  recieveMessage: (key: string) => void;
  getMessage: (key: string, callbackFn: Function) => void;
  disconnect: () => void;
  reset: (data: ModelBuilder) => void;
  reset1: () => void;
  saveModel: (data: ModelBuilder) => void;
};

const initialState = {
  roundCreation: {
    modelId: "",
    companyId: "",
    baseCaptableId: "",
    currentTab: 0,
    highestVisitedTabLevel: 0,
    modelState: ModelState,
    notificationMessage: "",
    errorMessage: "",
    isNavigating: false, // Indicates whether navigation is in progress
    preCaptableHeaders: {},
    onGoingRoundHeaders: {},
    roundDetailsTab: {}, // Tab1
    convertibles: {},
    existingInvestorDetails: {},
    newInvestorDetails: {},
    onGoingCaptable: {},
    secondaries: {},
  },
  calculateMode: {
    postRoundEsopPercentage: 0,
    roundSize: 0,
    preMoneyValuation: 0,
    fdbSharePrice: 0,
  },
};

const URL =
  process.env.REACT_APP_NEW_CAPTABLE_SOCKET_API || "ws://localhost:4000/";
let socket: any;

const roundModelStore = create<RoundModelingState>(
  persist(
    {
      key: "roundMdoeling",
    },
    (set: any) => ({
      ...initialState,
      connect: () => {
        if (
          useAuthStore.getState().accessToken &&
          useAuthStore.getState().companyId &&
          !socket
        ) {
          socket = io(URL, {
            extraHeaders: {
              accesstoken: useAuthStore.getState().accessToken || "-",
              companyId: useAuthStore.getState().companyId || "-",
            },
          });
          socket.connect();
        }
      },
      disconnect: () => {
        set(() => initialState);
        socket.disconnect();
      },
      sendMessage: async (message: any) => {
        if (socket) {
          socket.emit("message", message);
          socket.off("message");
          socket.on("message", (data: any) => {
            set((state: any) => ({ roundCreation: JSON.parse(data) }));
          });
        } else {
          socket = io(URL, {
            extraHeaders: {
              accesstoken: useAuthStore.getState().accessToken || "-",
              companyId: useAuthStore.getState().companyId || "-",
            },
          });
          socket.connect();
          socket.emit("message", message);
          socket.off("message");
          socket.on("message", (data: any) => {
            set((state: any) => ({ roundCreation: JSON.parse(data) }));
          });
        }
      },
      recieveMessage: async (key: any) => {
        if (socket) {
          await socket.on(key, (data: any) => {
            set((state: any) => ({ roundCreation: JSON.parse(data) }));
          });
        }
      },
      getMessage: async (key: any, callbackFn: Function) => {
        if (socket) {
          await socket.on(key, callbackFn);
        }
      },
      reset: (message: any) => {
        const discardData = {
          ...message,
          modelState: ModelState.ToBeDiscarded,
        };
        socket.emit("message", discardData);
        set(() => initialState);
      },
      reset1: (message: any) => {
        set(() => initialState);
      },
      saveModel: (message: any) => {
        const saveData = {
          ...message,
          modelState: ModelState.ToBeSaved,
        };
        socket.emit("message", saveData);
        socket.off("message");
        socket.on("message", (data: any) => {
          set((state: any) => ({ roundCreation: JSON.parse(data) }));
        });
        set(() => initialState);
      },
      setRoundCreation: (data: any) => {
        set((state: any) => ({ roundCreation: data }));
      },
      setCalculateMode: (data: any) => {
        set((state: any) => ({ calculateMode: data }));
      },
    })
  )
);

export const useRoundModelStore = roundModelStore;
