import axios, { AxiosError, AxiosRequestHeaders } from "axios";
import {
  clearSharedCredentials,
  redirectToSharedHissa,
  revalidateSharedAuth,
} from "../components/utils";
import { queryClient } from "../queries";
import { useShareAuthStore } from "../store/useShareAuthStore";

const sharedCapTableApi = axios.create({
  baseURL: process.env.REACT_APP_ONBOARDING_API || "http://localhost:4040",
});

sharedCapTableApi.defaults.headers.post["Content-Type"] = "application/json";

sharedCapTableApi.interceptors.request.use((config) => {
  const companyId = useShareAuthStore.getState().shareCompanyId || "";
  const accesstoken =
    localStorage.getItem("sharedAccesstoken") ||
    useShareAuthStore.getState().shareAccessToken;
  revalidateSharedAuth();
  if (accesstoken && !config.url?.endsWith("/login")) {
    (config.headers as AxiosRequestHeaders).AccessToken = accesstoken;
    (config.headers as AxiosRequestHeaders).companyId = companyId;
  }
  if (config.url === "exit") {
    delete (config.headers as AxiosRequestHeaders).AccessToken;
  }
  return config;
});

sharedCapTableApi.interceptors.response.use(
  (res) => {
    if (res.status === 401) {
      redirectToSharedHissa(useShareAuthStore.getState().shareId ?? "");
      clearSharedCredentials();
      queryClient.clear();
      useShareAuthStore.setState((store) => ({
        ...store,
        isShareAuthenticated: false,
      }));
      return Promise.reject(res);
    }
    return res;
  },
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      clearSharedCredentials();
      useShareAuthStore.setState((store) => ({
        ...store,
        isShareAuthenticated: false,
      }));
    }
    return Promise.reject(error);
  }
);

export default sharedCapTableApi;
