import Box from "@mui/material/Box";
import { useFormikContext } from "formik";
import { getNames } from "country-list";
import { Input, Label, TextArea } from "../components/shared/InputField";
import { Select } from "../components/shared/Select";
import { typeOfDirectorDropDownValues } from "../constants/DropDownValues";
import { VStack, HStack, Error } from "../components/utils";
import {
  DirectorDetailModel,
  CompanyDetailsPostModel,
} from "../types/OnBoardDataModel";
import { PopupHeader } from "../components/Headings";
import {
  ButtonSize,
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../pages/quickRound/CTAButtonComponents";

type AddEditDirectorProps = {
  director?: DirectorDetailModel;
  directorIndex: number;
  onPrimaryAction?: () => void;
  onSecondaryAction?: () => void;
  mode?: string;
};

const AddEditDirector = ({
  director,
  directorIndex,
  onPrimaryAction = () => {},
  onSecondaryAction = () => {},
  mode,
}: AddEditDirectorProps) => {
  const formik = useFormikContext<CompanyDetailsPostModel>();
  const {
    values,
    touched,
    getFieldProps,
    setFieldValue,
    setFieldTouched,
    resetForm,
    initialErrors,
  } = formik;
  let { errors } = formik;
  const fieldTouched = touched?.directors?.[directorIndex];
  const err = errors?.directors?.[directorIndex] as DirectorDetailModel;
  const basicDetailErrors =
    err?.fullName ||
    err?.emailId ||
    err?.nationality ||
    err?.din ||
    err?.typeOfDirector;

  const handleCancel = () => {
    if (mode === "Add") {
      if (!director) {
        setFieldValue(
          "directors",
          values?.directors?.filter((_, index) => index !== directorIndex)
        );
        resetForm();
      }
    }
    onSecondaryAction();
    errors = initialErrors;
  };
  const handleClose = () => {
    onSecondaryAction();
  };

  function doPartialTouch() {
    setFieldTouched(`directors[${directorIndex}].fullName`);
    setFieldTouched(`directors[${directorIndex}].emailId`);
    setFieldTouched(`directors[${directorIndex}].din`);
    setFieldTouched(`directors[${directorIndex}].nationality`);
    setFieldTouched(`directors[${directorIndex}].typeOfDirector`);
  }

  const handleSubmit = () => {
    if (director) {
      setFieldValue(`directors[${directorIndex}].isEdit`, true);
    }
    onPrimaryAction();
  };

  return (
    <div className="transition ease-in duration-2000 opacity-100 enter:opacity-0">
      <PopupHeader
        text={!director ? "Add Director Details" : "Edit Director Details"}
        onClick={() => {
          handleClose();
        }}
      />
      <VStack className="w-full px-10 py-7 gap-9">
        <VStack className="w-full gap-9">
          <HStack className="gap-8 ">
            <div className="flex-1">
              <Label className="text-sm font-normal">
                Director Name<span className="text-rose-400">*</span>
              </Label>
              <Input
                type="text"
                placeholder="Enter the director name"
                className="w-96"
                {...getFieldProps(`directors[${directorIndex}].fullName`)}
              />
              {fieldTouched?.fullName && err?.fullName && (
                <Error text={err?.fullName} />
              )}
            </div>
            <div className="flex-1">
              <Label className="text-sm font-normal">
                Type of Director<span className="text-rose-400">*</span>
              </Label>
              <Select
                options={typeOfDirectorDropDownValues}
                {...getFieldProps(`directors[${directorIndex}].typeOfDirector`)}
              />
              {fieldTouched?.typeOfDirector && err?.typeOfDirector && (
                <Error text={err?.typeOfDirector} />
              )}
            </div>
          </HStack>
          <HStack className="gap-8">
            <div className="flex-1">
              <Label className="text-sm font-normal">
                Email Id
                <span className="text-rose-400">*</span>
              </Label>
              <Input
                type="email"
                placeholder="Eg: example@domain.com"
                className="w-96"
                {...getFieldProps(`directors[${directorIndex}].emailId`)}
              />
              {fieldTouched?.emailId && err?.emailId && (
                <Error text={err?.emailId} />
              )}
            </div>

            <div className="flex-1">
              <Label className="text-sm font-normal">
                DIN Number
                <span className="text-rose-400">*</span>
              </Label>
              <Input
                type="text"
                placeholder="Enter DIN Number"
                className="w-96"
                {...getFieldProps(`directors[${directorIndex}].din`)}
              />
              {fieldTouched?.din && err?.din && <Error text={err?.din} />}
            </div>
          </HStack>
          <HStack className="gap-8">
            <div className="flex-1">
              <Label className="text-sm font-normal">Address</Label>
              <TextArea
                {...getFieldProps(`directors[${directorIndex}].address`)}
              />
            </div>
            <div className="flex-1">
              <Label className="text-sm font-normal">Contact Number</Label>
              <Input
                type="text"
                placeholder="Eg:9093409093"
                className="w-96"
                {...getFieldProps(`directors[${directorIndex}].contactNumber`)}
              />
            </div>
          </HStack>
          <HStack className="gap-8">
            <div className="flex-1">
              <Label className="text-sm font-normal">
                Nationality<span className="text-rose-400">*</span>
              </Label>
              <Select
                className="w-96"
                options={getNames().sort()}
                {...getFieldProps(`directors[${directorIndex}].nationality`)}
              />
              {fieldTouched?.nationality && err?.nationality && (
                <Error text={err?.nationality} />
              )}
            </div>
            <div className="flex-1">
              <Label className="text-sm font-normal">Date of Birth</Label>
              <Input
                type="date"
                placeholder="Eg:DD/MM/YYYY"
                className="w-96"
                {...getFieldProps(`directors[${directorIndex}].dateOfBirth`)}
              />
              {fieldTouched?.dateOfBirth && err?.dateOfBirth && (
                <Error text={err?.dateOfBirth} />
              )}
            </div>
          </HStack>
          <HStack className="gap-8">
            <div className="flex-1">
              <Label className="text-sm font-normal">
                Date of Board Resolution for Appointment
              </Label>
              <Input
                type="date"
                placeholder="Eg:DD/MM/YYYY"
                className="w-96"
                {...getFieldProps(
                  `directors[${directorIndex}].dateOfBoardResoluitonForAppointement`
                )}
              />
              {fieldTouched?.dateOfBoardResoluitonForAppointement &&
                err?.dateOfBoardResoluitonForAppointement && (
                  <Error text={err?.dateOfBoardResoluitonForAppointement} />
                )}
            </div>
            <div className="flex-1">
              <Label className="text-sm font-normal">Date of Appointment</Label>
              <Input
                type="date"
                placeholder="Eg:DD/MM/YYYY"
                className="w-96"
                {...getFieldProps(
                  `directors[${directorIndex}].dateOfAppointement`
                )}
              />
              {fieldTouched?.dateOfAppointement && err?.dateOfAppointement && (
                <Error text={err?.dateOfAppointement} />
              )}
            </div>
          </HStack>
          <HStack className="justify-between h-8">
            <SecondaryCTAButton
              event-name="Cancel Director Modal"
              buttonSize={ButtonSize.SMALL}
              onClick={() => {
                handleCancel();
              }}
            >
              Cancel
            </SecondaryCTAButton>
            <PrimaryCTAButton
              event-name="Save Director Modal"
              buttonSize={ButtonSize.SMALL}
              onClick={() => {
                if (basicDetailErrors) {
                  doPartialTouch();
                } else {
                  handleSubmit();
                }
              }}
            >
              Save
            </PrimaryCTAButton>
          </HStack>
        </VStack>
      </VStack>
    </div>
  );
};

export default AddEditDirector;
