import { useMutation, useQuery } from "react-query";
import {
  approveQuickBuyback,
  approveQuickRoundPreview,
  approveQuickSplitRoundPreview,
  approveQuickSecondary,
  getInstrumentAndShareHolder,
  getQuickRoundPreview,
} from "../api/quickRound";
import { shareholderInstrumentDummyData } from "./randomPlaceholderData";

export function useGetInstrumentAndShareHolder(
  companyId: string,
  cinNumber?: string | null
) {
  return useQuery({
    queryKey: ["getInstrumentAndShareHolder", companyId, cinNumber],
    queryFn: () => getInstrumentAndShareHolder(companyId, cinNumber),
    placeholderData: shareholderInstrumentDummyData(),
  });
}

export function useGetQuickRoundPreview(eventId: string) {
  return useQuery({
    queryKey: ["proformaEventSummary", eventId],
    queryFn: getQuickRoundPreview,
    select: (data) => data.data,
    enabled: false,
  });
}
