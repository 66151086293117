import React, { useMemo, useState } from "react";
import { Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Chart, ArcElement, Tooltip, Legend } from "chart.js";
import { Box, HStack } from "../../components/utils";
import { prefrenceColorList } from "../../constants/ShareholderSecurityTypes";
import {
  getCurrencySymbol,
  getCurrencyType,
} from "../../utils/currencyFormatter";
import { ConvertibleHoldings, NonPricedRoundModel } from "../../types/Modeling";

Chart.register(Tooltip, Legend);

export const PieChartSlider = ({
  securityData,
  holdingData,
  sliderValue,
  setSliderValue,
  currencyType,
  pps,
}: {
  securityData: any;
  holdingData: ConvertibleHoldings[];
  sliderValue: number;
  setSliderValue: Function;
  currencyType: string;
  pps: number;
}) => {
  const handleSliderChange = (e: any) => {
    const value = Number(e.target.value);
    if (!Number.isNaN(value)) setSliderValue(value);
  };

  const currencySymbol = getCurrencySymbol();

  const options: any = {
    responsive: true,
    maintainAspectRatio: false,
    categoryPercentage: 0.5,
    barPercentage: 0.5,
    borderRadius: 0,
    interaction: {
      mode: "point",
      intersect: false,
      includeInvisible: false,
    },
    plugins: {
      datalabels: {
        color: "#fff",
        formatter: (value: number, context: any) => {
          const index = context.dataIndex;
          const labels = context.chart.data.labels;

          const category = labels[index];
          let sum = 0;
          const dataArr = context.chart.data.datasets[0].data;
          dataArr.forEach((data: number) => {
            sum += data;
          });
          const percentage = `${((value / sum) * 100).toFixed(2)} %`;
          return percentage;
        },
      },
      mode: "label",
      tooltip: {
        callbacks: {
          label: (context: any) => {
            const dataset = context.dataset;
            const data = dataset.data;
            const conversionPrices = dataset.conversionPrices;
            const index = context.dataIndex;
            const value = data[index];
            const percentage = `${(
              (value / data.reduce((acc: any, val: any) => acc + val, 0)) *
              100
            ).toFixed(2)} %`;
            const conversionPrice = conversionPrices[index];

            // Create multi-line text with \n
            return [
              `${context.label}: ${percentage}`,
              conversionPrice !== null
                ? `Conversion Price: ${currencySymbol} ${conversionPrice.toFixed(
                    2
                  )}`
                : "",
            ];
          },
        },
      },
      legend: {
        display: true,
        position: "bottom" as const,
        labels: {
          boxWidth: 10,
        },
      },
    },
    tooltips: {
      mode: "index",
      intersect: false,
    },
    hover: {
      mode: "index",
      intersect: false,
    },
  };

  const data = useMemo(() => {
    const labels: string[] = [];
    const dataset: number[] = [];
    const conversionPrices: (number | null)[] = [];

    holdingData.forEach((holdingData) => {
      labels.push(holdingData.category);
      dataset.push(holdingData.percentage);
      conversionPrices.push(holdingData.conversionPrice ?? null); // Add conversion price
    });
    return {
      labels,
      plugins: [ChartDataLabels],

      datasets: [
        {
          label: "# of Votes",
          data: dataset,
          backgroundColor: prefrenceColorList.map((element, index) => {
            const colorIndex = index % prefrenceColorList.length;
            return prefrenceColorList[colorIndex];
          }),
          borderWidth: 1,
          conversionPrices,
        },
      ],
    };
  }, [holdingData]);

  return (
    <Box className="w-full px-2 pb-8 h-80 align-center">
      <p className="pb-2 text-sm font-medium">
        Ownership for different pre-money valuation
      </p>
      <input
        className="w-full"
        type="range"
        min={Math.floor(securityData.preMoneyValuation / 2)}
        max={Math.floor(securityData.preMoneyValuation * 2)}
        value={sliderValue}
        onChange={handleSliderChange}
      />
      <HStack>
        <p className="pb-4 text-sm font-medium text-gray-400">
          Selected Valuation ({currencySymbol}):{" "}
          {sliderValue.toLocaleString(currencyType)}
        </p>
        <p className="pb-4 pl-4 text-sm font-medium text-gray-400">
          PPS ({currencySymbol}): {pps.toFixed(2)}
        </p>
      </HStack>
      {options ? (
        <Pie options={options} data={data} plugins={[ChartDataLabels as any]} />
      ) : (
        <p></p>
      )}
    </Box>
  );
};

export default PieChartSlider;
