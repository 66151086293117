import React, { useState } from "react";
import { NavigateFunction } from "react-router";
import { ShareholderDetails } from "../types/SecurityModel";
import { Center } from "../components/utils";
import { useSelectedShareholderFilterStore } from "../store/selectedShareholderTypeStore";
import ImpactedEmployees from "../pages/Utility/ImpactedEmployees";
import Tooltip from "../components/shared/Tooltip";
import { limitString } from "../utils/string";

const ShareholderIdentifier = ({
  shareholderName,
  type,
  itemsToDisplay = 2,
  navigate,
}: {
  shareholderName: ShareholderDetails[];
  type: string;
  itemsToDisplay?: number;
  navigate: (url: string) => void;
}) => {
  const roundNames = shareholderName;
  const totalItems = roundNames.length;
  const listToDisplay = roundNames.slice(0, itemsToDisplay);
  const remaining = totalItems - itemsToDisplay;
  const shareholderFilter = useSelectedShareholderFilterStore();

  return (
    <div className="h-fit flex flex-row capitalize rounded-sm font-medium text-center text-[11px] leading-4 -space-x-1.5 ">
      {listToDisplay.map((shareholder, _listIndex) => (
        <div
          key={shareholder.shareholderId}
          navigation-name="Shareholder Tag"
          onClick={() => {
            shareholderFilter.setSecurityType(type);
            navigate(`/shareholders/individual/${shareholder.shareholderId}`);
          }}
        >
          <Tooltip text={shareholder.shareholderName}>
            <ImpactedEmployees
              name={shareholder.shareholderName}
              impactedEmployees={roundNames
                ?.slice(0, 3)
                .map((shareholder) => shareholder.shareholderName)}
            />
          </Tooltip>
        </div>
      ))}
      {remaining > 0 && (
        <div
          navigation-name="Shareholder Tag"
          onClick={() => {
            shareholderFilter.setSecurityType(type);
            navigate(`/shareholders/overview`);
          }}
          className="relative w-8 h-8 transition duration-300 ease-in-out delay-100 border-2 border-black border-solid rounded-full cursor-pointer hover:-translate-y-1 hover:scale-100 ring-2 ring-white hover:ring-blue-800 hover:z-10 hover:ease-in"
        >
          <Center className="py-1.5 text-black">
            <p>+ {remaining}</p>
          </Center>
        </div>
      )}
    </div>
  );
};

export default ShareholderIdentifier;
